<template>
    <div class="form-group" :class="clazz">
        <div id="form-datepicker" v-on:click="activeLabel($event)">
            <slot></slot>
        </div>

        <div class="input-label" :class="{ 'active': labelActive }">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i aria-hidden="true" v-bind:class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>
</template>

<script>
    export default {
        name: 'InputDatepicker',
        props: {
            clazz: String,
            labelFor: String,
            label: String,
            required: {type: Boolean, default: false},
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            helpText: String,
            helpIcon: String,
            warningIcon: String,
            warningText: String,

        },
        data () {
            return {
                labelActive: false
            }
        },
        methods: {
            activeLabel: function() {
                this.labelActive = !this.labelActive;
            },
            changeActive(event) {
              const header = document.querySelector("#form-datepicker");
              if(header){
                  const isClickInside = header.contains(event.target);

                  if (!isClickInside) {
                      this.labelActive = false;
                  } else {
                      this.labelActive = true;
                  }
              }

            }
        },
        created () {
          window.addEventListener('click', this.changeActive);
        }
    }
</script>
