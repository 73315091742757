export const STORE = 'USERS_SIGNUP_LEVEL_ONE';
export const FETCH_ACTUAL_USER = 'FETCH_ACTUAL_USER';
export const FETCH_OCUPATIONS = 'FETCH_OCUPATIONS';
export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const VERIFY_CODE = 'VERIFY_CODE';
export const SET_ACTUAL_USER = 'SET_ACTUAL_USER';
export const SET_SECURITY_PHRASE = 'SET_SECURITY_PHRASE';
export const SET_ID_IMAGE_SECURITY = 'SET_ID_IMAGE_SECURITY';
export const GET_ACTUAL_USER = 'GET_ACTUAL_USER';
export const GET_IS_LOAD_USER = 'GET_IS_LOAD_USER';
export const GET_IS_VALID_STEP_FROM_SAVE = 'GET_IS_VALID_STEP_FROM_SAVE';
export const SET_NATIONALITIE_ACTUAL_USER = 'SET_NATIONALITIE_ACTUAL_USER';
export const SET_GENDER_ACTUAL_USER = 'SET_GENDER_ACTUAL_USER';
export const SET_NAME_ACTUAL_USER = 'SET_NAME_ACTUAL_USER';
export const SET_LAST_NAME_ACTUAL_USER = 'SET_LAST_NAME_ACTUAL_USER';
export const SET_BIRTHDAY_ACTUAL_USER = 'SET_BIRTHDAY_ACTUAL_USER';
export const SET_LAST_NAME_MOTHER_ACTUAL_USER = 'SET_LAST_NAME_MOTHER_ACTUAL_USER';
export const SET_STATE_ACTUAL_USER = 'SET_STATE_ACTUAL_USER';
export const GET_ERROR_NEXT_STEP = 'GET_ERROR_NEXT_STEP';
export const GET_ERROR_NEXT_STEP_MESSAGE = 'GET_ERROR_NEXT_STEP_MESSAGE';
export const SET_OCUPACION_ACTUAL_USER = 'SET_OCUPACION_ACTUAL_USER';
export const SET_PHONE_USER = 'SET_PHONE_USER';
export const SET_IS_VALID_STEP_THREE_LEVEL_ONE = 'SET_IS_VALID_STEP_THREE_LEVEL_ONE';
export const GET_IS_VALID_STEP_THREE_LEVEL_ONE = 'GET_IS_VALID_STEP_THREE_LEVEL_ONE';
export const SET_EMAIL_ACTUAL_USER = 'SET_EMAIL_ACTUAL_USER';
export const GET_HAS_EMAIL = 'GET_HAS_EMAIL';
