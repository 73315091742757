import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'payment-links';


export default {
    list: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list`, params)
            .then(onSuccess)
            .catch(onError);
    },
    activeDeactive: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/active-deactive`, params)
            .then(onSuccess)
            .catch(onError);
    },
    delete: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete`, params)
            .then(onSuccess)
            .catch(onError);
    },
    deleteMany: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete-many`, params)
            .then(onSuccess)
            .catch(onError);
    },
    share: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/share`, params)
            .then(onSuccess)
            .catch(onError);
    },
    save: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save`, params)
            .then(onSuccess)
            .catch(onError);
    },
    byId: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/by-id`, params)
            .then(onSuccess)
            .catch(onError);
    },
    payments: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments`, params)
            .then(onSuccess)
            .catch(onError);
    },
    promos: (params) => {
        return axios.post(
            `${base.baseUrlPayment}/pagando/promotions/get-terminal-promotions-checklink-create`, params)
    },
}
