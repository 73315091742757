import adminDashboardApi from '@/api/admin/adminDashboard.api';
import userStore from '@/store/modules/clients/users.store';


const stateGlobal = {
    cards: {
        averageLast30Days: {
            now: 0.00,
            then: 0.00,
            percentage: 0
        },
        day: {
            now: 0.00,
            then: 0.00,
            percentage: 0
        },
        week: {
            now: 0.00,
            then: 0.00,
            percentage: 0
        },
        month: {
            now: 0.00,
            then: 0.00,
            percentage: 0
        },
    },
    bestServices: {},
    currentInvoice: {
        organization: null,
        fromDate: null,
        toDate: null,
        month: null,
        year: null,
        xml: null,
        pdf: null
    },
    invoiceExists: false,
    customChartData: [],
    isLoadingCardInfo: false,
    data: {},
    isLoadingGraf: false,
    isLoadingMostSelled: false,
    total: 0
};

const getters = {
    getBestServices: (state) => state.bestServices,
    getCurrentInvoice: (state) => state.currentInvoice,
    getInvoiceExistence: (state) => state.invoiceExists,
    getIsLoadingCards: (state) => state.isLoadingCardInfo,
    getIsLoadingGraf: (state) => state.isLoadingGraf,
    getIsLoadingMostSelled: (state) => state.isLoadingMostSelled,
};

const actions = {

    retrieveDataForDashboard({commit}, params){
        commit("SET_IS_LOADING_CARDS", true);
        adminDashboardApi.retrieveDashboardCardsInfo(params,
            function (result) {
                commit("SET_CARDS_INFO", result.data.object);
                commit("SET_IS_LOADING_CARDS", false);
            });
    },

    async retrieveDataForInvoice({commit}, params){
        await adminDashboardApi.getCurrentInvoice(params, function (result) {
                if(result.data.object !== null){
                    commit("SET_CURRENT_INVOICE_INFO", result.data.object);
                    commit("SET_EXISTS_INVOICE_DATA", true);
                } else {
                    commit("SET_EXISTS_INVOICE_DATA", false);
                }

            }, function () {
                commit("SET_EXISTS_INVOICE_DATA", false);
            })
    },
    getInfoForChart({commit},params) {
        commit("SET_IS_LOADING_GRAF", true);
        adminDashboardApi.dataForChart(
            params,
            (result) => {
                const aggregationData = result.data.object;

                commit('updateDataChart', {data: aggregationData});
                if(result?.data?.object?.total){
                    commit('updateTotal',result.data.object.total);
                }
                commit("SET_IS_LOADING_GRAF", false);
            },
            (error) => {
                console.error("Error", error);
                commit("SET_IS_LOADING_GRAF", false);
            }
        )
    },
    // eslint-disable-next-line no-empty-pattern
    getBestServiceInfo: ({commit}, params) => {
        commit("SET_IS_LOADING_MOST_SELLED", true);
        return new Promise((resolve, reject) => {
            adminDashboardApi.bestServiceInfo(params,(result) => {
                if (result.data.error) {
                    return reject(result.data.error);
                }
                commit("SET_IS_LOADING_MOST_SELLED", false);
                return resolve(result);
            }, (err) => {
                commit("SET_IS_LOADING_MOST_SELLED", false);
                return reject(err);
            });
        });
    },

    // eslint-disable-next-line no-empty-pattern
    getLastClosingTransaction: (context, params) => {
        return new Promise((resolve, reject) => {
            adminDashboardApi.lastClosingTransaction(params,(result) => {
                if (result.data.error) {
                    return reject(result.data.error);
                }
                return resolve(result.data);
            }, (err) => {
                return reject(err);
            });
        });
    },

};

const mutations = {

    SET_CARDS_INFO(state, {average, day, week, month, averageLast30Days}) {
        state.cards.average = average;
        state.cards.day = day;
        state.cards.week = week;
        state.cards.month = month;
        state.cards.averageLast30Days = averageLast30Days;
    },

    SET_CURRENT_INVOICE_INFO(state, {organization, fromDate, toDate, month, year, xml, pdf}) {
        state.currentInvoice.organization = organization;
        state.currentInvoice.fromDate = fromDate;
        state.currentInvoice.toDate = toDate;
        state.currentInvoice.month = month;
        state.currentInvoice.year = year;
        state.currentInvoice.xml = xml;
        state.currentInvoice.pdf = pdf;
    },

    SET_EXISTS_INVOICE_DATA(state, invoiceExists) {
        state.invoiceExists = invoiceExists;
    },
    SET_IS_LOADING_CARDS(state, loadingCards) {
        state.isLoadingCardInfo = loadingCards;
    },
    SET_IS_LOADING_GRAF(state, loadingGraf) {
        state.isLoadingGraf = loadingGraf;
    },
    SET_IS_LOADING_MOST_SELLED(state, isLoadingMostSelled) {
        state.isLoadingMostSelled = isLoadingMostSelled;
    },
    updateDataChart(state, {data}) {
        state.data = data;
    },
    updateTotal(state,total){
        state.total = total;
    }
};

export default {
    namespaced: true,
    state: {
        ...userStore.state,
        ...stateGlobal
    },
    getters: {
        ...userStore.getters,
        ...getters
    },
    actions: {
        ...userStore.actions,
        ...actions

    },
    mutations: {
        ...userStore.mutations,
        ...mutations
    }
}
