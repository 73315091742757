import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'banks';

export default {
    all: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/all`, params)
            .then(onSuccess)
            .catch(onError);
    },
};
