<template>
    <div class="box-container" :class="clazzContainer" @click="onClick">
        <label class="box-title" :class="titleClass"> {{ titleBox }} </label>

        <div class="box-upload" :class="{ 'b-dashed': showLoadBox, 'b-solid': showLoadProgress || showLoadStatus }" v-show="showLoadBox">
            <!-- Load image / document -->
            <div class="upload-file" v-bind:class="fileType">
                <div class="bgm-images">
                    <img class="img-fluid back" :src="imgUrl"  alt="illustration" />

                    <img  class="img-fluid front" :src="imgUrlUpload" alt="cloud" />
                </div>
                <p class="info-text">{{ loadText }}</p>
            </div>

            <!-- Loading circle progress -->
            <div>
                <div v-if="showLoadProgress" class="progress-loading">
                    <LoadingBar loadingText="Verificando INE..."></LoadingBar>
                </div>

                <!-- Error / Re-try / Success loading -->
                <div v-if="showLoadStatus" class="status-loading" >
                    <img v-if="showLoadStatus && retry" class="img-fluid" src="@/assets/images/Icons/icon-load-normal.svg" alt="retry"/>
                    <img v-if="showLoadStatus && success" class="img-fluid" src="@/assets/images/Icons/icon-check-normal.svg" alt="success"/>
                    <label> {{ statusTitle }} </label>
                    <p> {{ statusText }} </p>
                </div>
            </div>

            <input v-if="!disableUploading" :id="inputId" :ref="inputId" type="file" class="input-file" value="" :name="inputId" @change="fileUpload()" :accept="inputFileAccept">
        </div>

        <div class="image-preview" v-if="!showLoadBox">
            <slot name="imagePreview"></slot>
        </div>

        <div v-if="!showLoadProgress" class="buttons-container">
            <slot name="button"></slot>
        </div>

        <slot name="noteText"></slot>
    </div>
</template>
<script>

    import LoadingBar from '@/components/LoadingBar'
    import Imagen from '@/assets/images/Illustrations/illustration-card-id.svg'
    import ImageUpload from '@/assets/images/Illustrations/illustration-cloud-icon.svg'

    export default {
        name: 'UploadBox',
        props: {
            inputId: {type: String, default: 'upload-box-input'},
            clazzContainer: String,
            titleClass: String,
            titleBox: String,
            fileType: String,
            imgUrl: {
                type: String,
                default: Imagen
            },
            imgUrlUpload: {
                type: String,
                default: ImageUpload
            },
            showLoadBox: {type: Boolean, default: false},
            showLoadProgress: {type: Boolean, default: false},
            showLoadStatus: {type: Boolean, default: false},
            retry: {type: Boolean, default: false},
            success: {type: Boolean, default: false},
            loadText: String,
            statusTitle: String,
            statusText: String,
            disableUploading: {type: Boolean, default: false},
            inputFileAccept: {type: String, default: ''}
        },
        data() {
            return {
                defaultUrl: Imagen,
                defaultUrlUpload: ImageUpload
            }
        },
        components: {
            LoadingBar
        },
        methods: {
            fileUpload() {
                this.$emit('change', this.$refs[this.inputId].files);
            },
            setView(){

            },
            onClick(){
                if(this.disableUploading){
                    this.$emit('click');
                }

            }
        }
    }
</script>

<style lang="scss">
    .box-container {
        min-width: 30%;
        max-width: 100%;
        margin-bottom: 20px;

        > .image-preview {
            position: relative;
            border-radius: 4px;
            transition: 0.5s ease-in-out;
        }

        > .image-preview .img-card {
            width: 100%;
            height: 100%;
            .portada {
                max-height: 160px;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border: none;
                margin: 0;
            }
        }
    }

    .box-title {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.2;
        display: block;
        margin-bottom: 12px;
    }

    .buttons-container {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -15px 10px;

        .button {
            white-space: nowrap;
        }
    }

    .box-upload {
        position: relative;
        width: 100%;
        min-height: 100px;//240px;
        max-height: 240px;
        border-width: 2px;
        border-radius: 4px;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;

        &:hover .upload-file .bgm-images .front {
            animation: pulse 1s infinite linear;
        }

        &.b-dashed {
            border-style: dashed;
        }
        &.b-solid {
            border-style: solid;
        }

        .upload-file {
            width: 100%;
            height: 100%;

            .bgm-images {
                max-width: 255px;
                max-height: 168px;
                min-height: 165px;
                width: 100%;
                height: 100%;
                position: relative;
                margin: 0 auto 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .back {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    object-fit: contain;
                }

                .front {
                    max-height: 82px;
                    transition: 0.5s;
                    position: relative;
                    object-fit: contain;
                }
            }

            .info-text {
                font-size: 13px;
                font-weight: normal;
                line-height: 1.38;
                text-align: center;
                display: block;
                margin-bottom: 0;
            }

            &.no-img-back .bgm-images {
                .back {
                    display: none;
                }
            }

            &.no-img-back > .bgm-images {
                min-height: 0px;
            }
        }

        .progress-loading {
            position: absolute;
            // min-height: 240px;
            width: 100%;
            height: 100%;
        }

        .status-loading {
            max-width: 300px;

            img {
                max-height: 120px;
                margin: 0 auto 25px;
                display: flex;
            }

            label {
                font-size: 22px;
                font-weight: 600;
                line-height: 1.09;
                text-align: center;
                display: block;
                margin-bottom: 6px;
            }

            p {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.43;
                text-align: center;
                display: block;
                margin-bottom: 0;
            }
        }
    }

    .box-container.box-logo-container {
        display: grid;
        grid-template-columns: 170px 1fr;
        grid-auto-rows: minmax(30px, auto);

        .box-title {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .box-upload,
        .image-preview {
            grid-column: 1 / 2;
            grid-row: 2;
            max-width: 140px;
            max-height: 140px;
            width: 140px;
            height: 140px;
            min-height: 0;
            min-width: 0;
        }

        .box-upload:hover {
            & + .image-preview {
                opacity: 0;
            }
        }

        .image-preview {
            overflow: hidden;
            background-color: #fff;

            .img-card img {
                max-height: 140px;
                object-fit: cover;
                width: 100%;
                height: 100%;
                margin: 0;
                border: none;
            }
        }

        .buttons-container,
        .simple-note {
            grid-column: 2 / 2;
            grid-row: 2;
            height: fit-content;
        }

        .buttons-container {
            margin-top: 20px;
        }

        .simple-note {
            margin-top: 68px;
        }
    }

    .box-container.box-portada-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: inherit;

        .box-title {
            grid-column: 1 / 1;
            grid-row: 1;
        }

        .box-upload,
        .image-preview{
            grid-column: 1 / 1;
            grid-row: 2;
        }

        .box-upload:hover {
            & + .image-preview {
                opacity: 0;
            }
        }
    }

    .box-container.box-logo-container,
    .box-container.box-portada-container {
        &.readOnly {
            .box-upload:hover {
                & + .image-preview {
                    opacity: 1;
                }
            }
        }
    }
</style>
