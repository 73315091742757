<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout
                :msgSmall="$t('administration.title')"
                :title="$t('administration.my-organization')"
            />
        </div>
        <div class="col-12">
            <OrganizationInfoCoverLogo></OrganizationInfoCoverLogo>
        </div>
        <div class="col-12">
            <b-tabs
                pills
                vertical
                class="vertical-tabs-custom"
            >
                <b-tab
                    :active="activeSection === OrganizationSection.GENERAL"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.GENERAL}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-info-general.svg"
                                    alt="Info general"
                                />
                            </div>
                            <span>
                                <label>{{$t('organizaciones.informacion-general.title')}}</label>
                                <p>{{$t('organizaciones.informacion-general.text')}}</p>
                            </span>
                        </div>
                    </template>
                    <GeneralInformation />
                </b-tab>
                <b-tab
                    :active="activeSection === OrganizationSection.CONTACT"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.CONTACT}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-contact.svg"
                                    alt="Info general"
                                />
                            </div>
                            <span>
                                <label> {{$t('organizaciones.contact-info.title')}} </label>
                                <p> {{$t('organizaciones.contact-info.text')}} </p>
                            </span>
                        </div>
                    </template>
                    <ContactInfo />
                </b-tab>
                <b-tab
                    :active="activeSection === OrganizationSection.NOTIFICATION"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.NOTIFICATION}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-notification.svg"
                                    alt="Info general"
                                />
                            </div>
                            <span>
                                <label> {{$t('organizaciones.notifications-info.title')}} </label>
                                <p> {{$t('organizaciones.notifications-info.text')}} </p>
                            </span>
                        </div>
                    </template>
                    <NotificationInfo />
                </b-tab>
                <b-tab
                    :active="activeSection === OrganizationSection.FISCAL"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.FISCAL}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-fiscal.svg"
                                    alt="Info general"
                                />
                            </div>
                            <span>
                                <label>{{$t('organizaciones.datos-fiscales.title')}}</label>
                                <p>{{$t('organizaciones.datos-fiscales.text')}}</p>
                            </span>
                        </div>
                    </template>
                    <FiscalData />
                </b-tab>
                <!-- <b-tab
                    :active="activeSection === OrganizationSection.BILLING"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.BILLING}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-facturacion.svg"
                                    alt="Info general"
                                />
                            </div>
                            <span>
                                <label> {{$t('organizaciones.billing-info.title')}} </label>
                                <p> {{$t('organizaciones.billing-info.text')}} </p>
                            </span>
                        </div>
                    </template>
                    <BillingInfo />
                </b-tab> -->
                <b-tab
                    :active="activeSection === OrganizationSection.BANK"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.BANK}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-bank.svg"
                                    alt="Info general"
                                />
                            </div>
                            <span>
                                <label>{{$t('organizaciones.bank-data.title')}}</label>
                                <p>{{$t('organizaciones.bank-data.text')}}</p>
                            </span>
                        </div>
                    </template>
                    <BankInfo />
                </b-tab>
                <b-tab
                    :active="activeSection === OrganizationSection.PAYMENTS_CONFIG"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.PAYMENTS_CONFIG}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-setup.svg"
                                    alt="Info general"
                                />
                            </div>
                            <span>
                                <label> {{$t('organizaciones.payments-configuration.title')}} </label>
                                <p> {{$t('organizaciones.payments-configuration.text')}} </p>
                            </span>
                        </div>
                    </template>
                    <PaymentsConfiguration />
                </b-tab>
                <b-tab
                    :active="activeSection === OrganizationSection.WEBHOOK"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.WEBHOOK}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-webhook.svg"
                                    alt="Configuración de WebHooks"
                                />
                            </div>
                            <span>
                                <label> Configuración de WebHooks </label>
                                <p> Agrega y configura tus WebHooks. </p>
                            </span>
                        </div>
                    </template>
                    <WebHooksConfig />
                </b-tab>
                <b-tab
                    :active="activeSection === OrganizationSection.CONFIG_DETAILS"
                    @click="$router.push(`/client-admin/organization-info?section=${OrganizationSection.CONFIG_DETAILS}`)"
                >
                    <template v-slot:title>
                        <div class="vertical-tab-custom">
                            <div class="icon-img">
                                <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Tabs-icons/icon-contrato.svg"
                                    alt="Límites, responsabilidades, riesgos y beneficios"
                                />
                            </div>
                            <span>
                                <label>{{$t('organizaciones.config-details.tab-title')}}</label>
                                <p>{{$t('organizaciones.config-details.tab-desc')}}</p>
                            </span>
                        </div>
                    </template>
                    <AccountLimits />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
import OrganizationInfoCoverLogo from '@/components/OrganizationInfoCoverLogo.vue'
import GeneralInformation from '@/views/Organizations/GeneralInformation.vue';
import FiscalData from '@/views/Organizations/FiscalData.vue';
import BankInfo from '@/views/Organizations/BankInfo.vue';
import ContactInfo from '@/views/Organizations/ContactInfo.vue';
import NotificationInfo from '@/views/Organizations/NotificationInfo.vue';
// import BillingInfo from '@/views/Organizations/BillingInfo.vue';
import PaymentsConfiguration from '@/views/Organizations/PaymentsConfiguration.vue';
import WebHooksConfig from '@/views/Organizations/WebHooksConfig.vue';
import AccountLimits from '@/views/Organizations/AccountLimits.vue';
import * as OrganizationSection from '@/enums/organizationSection.enum';

export default {
    name: "OrganizationInfo",
    components: {
        FloatingTitleLayout,
        ContactInfo,
        OrganizationInfoCoverLogo,
        GeneralInformation,
        FiscalData,
        BankInfo,
        NotificationInfo,
        // BillingInfo,
        PaymentsConfiguration,
        WebHooksConfig,
        AccountLimits
    },
    data () {
        return {
            OrganizationSection,
            editingBankInfo: false,
            editingBankInfoForm: {
                bankId: '',
                accountNumber: '',
                clabe: '',
            },
            file: '',
            loading: false,
            valueDivisa: [],
            optionsDivisa: [
                { name: "MXN - Peso Mexicano" }
            ],
        }
    },
    computed: {
        activeSection () {
            if (this.$route.query.section) {
                switch (this.$route.query.section) {
                    case OrganizationSection.IMAGES:
                    case OrganizationSection.GENERAL:
                    case OrganizationSection.DOCUMENTS:
                        return OrganizationSection.GENERAL;
                    default:
                        return this.$route.query.section;
                }
            }
            return OrganizationSection.GENERAL;
        }
    }
};
</script>
