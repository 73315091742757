<template>
    <div
        class="webhooks-config"
        :class="clazz"
    >
        <span>
            <BasicCheckbox
                clazz="m-b-0 w-fit"
                :index="0"
                :currentValue="true"
            ></BasicCheckbox>
        </span>
        <span>
            <label> {{title}} </label>
            <p> {{link}} </p>
            <small :class="clazzStatus"> <i
                    aria-hidden="true"
                    class="banana"
                    :class="iconStatus"
                ></i> {{textStatus}} </small>
        </span>
        <span>
            <h3> {{ $t('organizaciones.webhooks-config.modal.inputs.sandbox.upper') }} </h3>
            <slot name="badgeSandbox"></slot>
        </span>
        <span>
            <h3> {{ $t('organizaciones.webhooks-config.modal.inputs.production.upper') }} </h3>
            <slot name="badgeProd"></slot>
        </span>
        <span>
            <b-dropdown
                id="dropdown-optionsOpen"
                right
                class="m-0-auto"
                toggle-class=""
            >
                <template v-slot:button-content>
                    <button
                        type="button"
                        name=""
                        class="button-circle xs button-ghost button-info_text"
                    > <i
                            aria-hidden="true"
                            class="banana banana-options-other-vertical"
                        ></i> </button>
                </template>
                <b-dropdown-group :header="$t('organizaciones.webhooks-config.modal.select')">
                    <b-dropdown-item-button @click="editWebhook(id)">
                        <i
                            aria-hidden="true"
                            class="banana banana-edit"
                        ></i> {{ $t('pagos.rows.actions.edit') }}
                    </b-dropdown-item-button>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                        variant=" c-error"
                        @click="deleteWebhook(id)"
                    >
                        <i
                            aria-hidden="true"
                            class="banana banana-delete"
                        ></i> {{ $t('pagos.rows.actions.delete') }}
                    </b-dropdown-item-button>
                </b-dropdown-group>
            </b-dropdown>
        </span>
    </div>
</template>

<script>
import BasicCheckbox from '@/components/BasicCheckbox.vue'

export default {
    name: 'WebhooksConfig',
    components: {
        BasicCheckbox
    },
    props: {
        clazz: String,
        title: String,
        link: String,
        clazzStatus: String,
        iconStatus: String,
        textStatus: String
    },
    data () {
        return {
            id: ''
        }
    },
    methods: {
        showWebhook: function (id) {
            this.$emit('show-wh', id);
        },
        deleteWebhook: function (id) {
            this.$emit('delete-wh', id);
        },
        editWebhook: function (id) {
            this.$emit('edit-wh', id);
        }
    }
}
</script>


<style lang="scss">
.webhooks-config {
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(20, 34, 102, 0.15);
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;

    > span {
        display: flex;
        flex-wrap: wrap;

        label {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.29;
            display: block;
            margin: 0;
            width: 100%;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.29;
            display: block;
            margin-bottom: 0px;
            width: 100%;
        }

        small {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.5;
            width: 100%;

            > i {
                margin-right: 4px;
                font-size: 16px;
            }
        }

        h3 {
            opacity: 0.4;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.33;
            text-align: center;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
            width: 100%;
        }

        .badge {
            margin: 0 auto;
        }

        &:nth-of-type(2) {
            flex: 0 0 40%;
            max-width: 40%;
            padding: 0 10px;
        }

        &:nth-of-type(3),
        &:nth-of-type(4) {
            padding: 0 10px;
            flex: 1;
        }

        &:nth-of-type(1),
        &:nth-of-type(5) {
            flex: 0 0 30px;
        }
    }
}
</style>
