const ICONS = {
    _EMPTY: '',
    PLUS: 'banana banana-symbol-plus',
    MESSAGE: 'banana banana-message',
    MOVE: 'banana banana-move',
    CROSS_NORMAL: 'banana banana-cross-normal',
    IMAGE: 'banana banana-image',
    ARROW_LEFT: 'banana banana-arrow-left',
    TRIANGLE_UP: 'banana banana-triangle-up',
    TRIANGLE_DOWN: 'banana banana-triangle-down',
    DOWNLOAD: 'banana banana-download',
    SETTINGS: 'banana banana-settings',
    TROPHY: 'banana banana-trophy',
    CAMERA: 'banana banana-camera',
};

ICONS._VALUES = [
    ICONS._EMPTY,
    ICONS.PLUS,
    ICONS.MESSAGE,
    ICONS.MOVE,
    ICONS.CROSS_NORMAL,
    ICONS.IMAGE,
    ICONS.ARROW_LEFT,
    ICONS.TRIANGLE_UP,
    ICONS.TRIANGLE_DOWN,
    ICONS.DOWNLOAD,
    ICONS.SETTINGS,
    ICONS.TROPHY,
];

export default ICONS;
