<template>
    <div v-if="level" class="password-input">
        <div class="strong-level" v-bind:class="clazz">
        <div class="bar"></div>
        <div class="separation-one"></div>
        <div class="separation-two"></div>
        <div class="separation-three"></div>
        <div class="text">{{ levelText }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StrongLevel',
        props: {
            clazz: String,
            levelText: String,
            level: {
                type: Boolean,
                default: false
            }
        }

    }
</script>
