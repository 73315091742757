import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'permissions';

export default {
    list: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list-of-collaborators`, params)
            .then(onSuccess)
            .catch(onError);
    },
    addCollaborators: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/add-collaborator-to-organization`, params)
            .then(onSuccess)
            .catch(onError);
    },
    saveCollaboratorProfiles: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-collaborator-profiles`, params)
            .then(onSuccess)
            .catch(onError);
    },
    deleteCollaborator: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete-collaborator-from-organization`, params)
            .then(onSuccess)
            .catch(onError);
    }
};
