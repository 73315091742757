import axios from 'axios'
const namespace = "payments";

export default {
    get: (payment, onSuccess, onError) => {
        return axios
            .get(`${namespace}/P/${payment._id || payment}`,)
            .then(onSuccess)
            .catch(onError);
    },
    create: (payment, onSuccess, onError) => {
        return axios
            .post(`${namespace}/create/P`, payment)
            .then(onSuccess)
            .catch(onError);
    },
    testWSPaymentRequest: (payment, onSuccess, onError) => {
        return axios.post(`${namespace}/ws/check/service/new`, payment)
            .then(onSuccess)
            .catch(onError);
    },
};
