/* eslint-disable sonarjs/no-identical-functions */
import signUpOrganizationApi from '@/api/signUpOrganization.api';
import images from '@/common/images';
import i18n from '@/plugins/i18n';
import userApi from "../../api/user/users.api";
import categories from '@/enums/categories';
import generalApi from "@/api/general.api";

const ERROR_SAVING_ORG_KEY = "signup-organization.general.saving.error";
/**
 *
 * Store del Sign Up de una organización
 */

const stateGlobal = {
    formStep: 1,
    user: {},
    actualUser: {
        phoneVerified: false,
        country: {},
        state: {},
    },
    actualPotentialOrganization: {
        idPotentialOrganization: "",
        name: "",
        description: "",
        category: "", //    Define a category to see the default cover from Unsplash
        isPhysicalPerson: true,
        hasInvoiceData: false,
        rfc: '',
        invoiceData: {
            name: '',
            lastName: '',
            secondLastName: '',
            rfc: '',
            curp: '',
            streetAddress: '',
            interiorNumber: '',
            exteriorNumber: '',
            district: '',
            city: '',
            state: '',
            zipCode: '',
            officialId: null
        },
        logo: {
            fullSize: null,
            cropped: null,
        },
        pattern: {
            kind: null,
            color: null,
            text: '',
        },
        cover: {
            options: [],
            localCover: null,
            fullSizeCover: null,
            thirdPartyCoverUrl: "",
            cropping: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
            userNameThirdPartyCover: "",
            authorNameThirdPartyCover: "",
        },
        bank: "",
        accountNumber: null,
        clabe: null,
        bankName: "",
        bankCover: null,
        occupation: '',
        occupationName: '',
        profession: '',
        professionName: ''
        ,
        collaborators: function(){
            return [];
        },
        requestedFiles: [],
        salesManCode: '',
        branch: null
    },
    errorNextStepMessage: '',
    errorNextStep: false,
    showBackButtonForm: false,
    showNextButtonForm: false,
    isValidStepFormSave: false,
    modalPreviewActive: false,
    temporalCover: {
        options: [],
        localCover: null,
        fullSizeCover: null,
        thirdPartyCoverUrl: "",
        cropping: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        userNameThirdPartyCover: "",
        authorNameThirdPartyCover: "",
    },
    isLoadActualOrganization: false,
    cover: {
        options: []
    },
    isValidStepOne: false,
    isValidStepTwo: false,
    isValidStepThree: false,
    isValidStepFour: false,
    isValidStepFive: false,
    isValidStepSix: false,
    isValidStepSeven: false,
    contract: {},
    loadingContract: false,
    isLoadUser: false,
    professions: [],
    occupations: [],
    isPersonalInformationComplete: false,
    isValidSecurityMeasure: false,
    activeTabInStepOne: "",
    registerUserSteps: {
        personalInformation: {
            isValid: false, // IsValid means that the step is already completed
            isReady: false // IsReady means that the step is ready to save and continue the process
        },
        phoneVerification: {
            isValid: false,
            isReady: false
        },
        securityMeasures: {
            isValid: false,
            isReady: false
        }
    },
    fileDocuments: [],
    country: {},
    state: {},
    countries: [],
    states: [],
    isOnboarding: true
};

const gettersGlobal = {
    getFormStep: (state) => state.formStep,
    getActualUser: (state) => state.actualUser,
    getIsValidStepFormSave: (state) => state.isValidStepFormSave,
    getPotentialOrganization: (state) => state.actualPotentialOrganization,
    coverImg(state) {
        return state.actualPotentialOrganization.cover ? images.getImageUrl(state.actualPotentialOrganization.cover.cropped) : '';
    },
    logoImg(state) {
        return state.actualPotentialOrganization.logo ? images.getImageUrl(state.actualPotentialOrganization.logo.cropped) : '';
    },
    temporalCoverImg(state) {
        if (state.temporalCover.localCover) {
            return images.getImageUrl(state.temporalCover.localCover)
        } else if (state.temporalCover.fullSizeCover) {
            return images.getImageUrl(state.temporalCover.fullSizeCover)
        } else if (state.temporalCover.thirdPartyCoverUrl) {
            return state.temporalCover.thirdPartyCoverUrl
        }
        return gettersGlobal.coverImg(state);

    },
    getTemporalCover: state => state.temporalCover,
    hasCustomCover: state => !!((state.actualPotentialOrganization.cover !== undefined && state.actualPotentialOrganization.cover !== null)
        && (state.actualPotentialOrganization.cover.localCover || state.actualPotentialOrganization.cover.fullSizeCover)),
    getHasCover: (state, getters) => getters.coverImg && getters.coverImg.length,
    getCoverOptions: (state) => state.cover,
    getHasCoverOptions: (state) => (state.cover !== undefined && state.cover !== null) && state.cover.options && state.cover.options.length,
    modalPreviewActive: state => state.modalPreviewActive,
    getErrorNextStep: (state) => state.errorNextStep,
    getErrorNextStepMessage: (state) => state.errorNextStepMessage,
    getShowBackButtonForm: (state) => state.showBackButtonForm,
    getShowNextButtonForm: (state) => state.showNextButtonForm,
    getHasInvoiceData: (state) => state.actualPotentialOrganization.hasInvoiceData,
    getIsLoadActualOrganization: (state) => state.isLoadActualOrganization,
    getCategorie: (state) => categories.getCategorie(state.actualPotentialOrganization.category),
    isValidStepOne: (state) => state.isValidStepOne,
    isValidStepTwo: (state) => state.isValidStepTwo,
    isValidStepThree: (state) => state.isValidStepThree,
    isValidStepFour: (state) => state.isValidStepFour,
    isValidStepFive: (state) => state.isValidStepFive,
    isValidStepSix: (state) => state.isValidStepSix,
    isValidStepSeven: (state) => state.isValidStepSeven,
    getContract: (state) => state.contract,
    getLoadingContract: (state) => state.loadingContract,
    getIsLoadUser: (state) => state.isLoadUser,
    getIsValidStepFourWizard: (state) => state.isValidStepFourWizard,
    getIsPersonalInformationComplete: (state) => state.isPersonalInformationComplete,
    isPhoneVerified: (state) => state.actualUser.phoneVerified,
    hasNewRegisterFinish: (state) => state.actualUser ? state.actualUser.hasNewRegisterFinish : false,
    getActiveTabInStepOne: (state) => state.activeTabInStepOne,
    registerUserStepsStatus: (state) => state.registerUserSteps,
    getFileDocuments: (state) => state.fileDocuments,
    getRequestedFiles: (state) => state.actualPotentialOrganization.requestedFiles,
    getFileByKey: (state) => (fileIndex) => {
        const index = state.actualPotentialOrganization.requestedFiles.findIndex(element => element.key === fileIndex);
        return state.actualPotentialOrganization.requestedFiles[index].file
    },
    getBranch: (state) => state.actualPotentialOrganization.branch,
    getState: (state) => state.state,
    getCountry: (state) => state.country,
    getStates: (state) => state.states,
    getCountries: (state) => state.countries,
    getOnboardingFlag: (state) => state.isOnboarding
};

const actions = {
    // ------------------------------------ Step User Registration --------------
    savePersonalInformation({state, commit}) {
      const occupationId = state.occupations.filter(val => val.name === state.actualUser.ocupacion);
      const professionId = state.professions.filter(val => val.description === state.actualUser.profession);
      commit("UPDATE_OCCUPATION_ACTUAL_USER", occupationId[0]._id);
      commit("UPDATE_PROFESSION_ACTUAL_USER", professionId[0]._id);

      const payload = state.actualUser;
      payload.hasEmail = true;
      return new Promise((resolve, reject)=>{
        userApi.savePersonalInformation(payload, (response)=>{
             if(response.data && response.data.error) {
                 return reject(response.data)
             }

             return resolve(response.data);
         }, (error) => {
             reject(error);
         });
      });

    },
    // eslint-disable-next-line no-empty-pattern
    sendVerificationCode(context,params){
        return new Promise((resolve, reject) => {
            userApi.sendVerificationCode(params,
                function(result) {
                    if(result.data.error){
                        reject(result.data);
                    } else {
                        resolve(result.data);
                    }
                })
        });

    },
    /**
     *  Verifica el código recibido por sms.
     **/
     verifyCode({commit},params){
        return new Promise((resolve) => {
            userApi.verifyCode(params,
                function(result){
                    if(result.data.error){
                        commit('SET_PHONE_VERIFIED_STATUS', false);
                        resolve(false);
                    } else {
                        commit('SET_PHONE_VERIFIED_STATUS', true);
                        resolve(true);
                    }
                });
        });
    },

    confirmWizardRegisterUser({state, commit}){
        const payload = {
            securityPhrase: state.actualUser.securityPhrase,
            securityImageId: state.actualUser.securityImageId
        };

        return new Promise((resolve) => {
            userApi.confirmWizardRegisterUser(payload,
                function(result){
                    if(result.data.error){
                        resolve(result.data);
                    } else {
                        commit('SET_REGISTER_USER_STEPS',{step: 'securityMeasures', prop: 'isValid', value: true} );
                        commit('SET_REGISTER_USER_STEPS',{step: 'personalInformation', prop: 'isValid', value: true} );
                        commit('SET_REGISTER_USER_STEPS',{step: 'phoneVerification', prop: 'isValid', value: true} );
                        resolve(result.data);
                    }
                });
        });
    },


    // ------------------------------------ Step One Actions --------------
    async getCurrentUserSession({commit, dispatch}) {

        const response = await userApi.getFullUser();

        if (response.data.error) {
            // TODO: Alert message
            commit(`SET_ALERT_MESSAGE`, {
                message: response.data.message
            });
            commit('SET_LOADING_CONFIRM', false);
        } else {
            const user = response.data.user;
            const personalInformationComplete = user.name && user.lastName && user.secondLastName && user.gender &&
            user.birthDay && user.nationalitie && user.state && user.ocupacion;

            commit('SET_PERSONAL_INFORMATION_STATUS', personalInformationComplete);
            commit('ACTUAL_USER', user);

            dispatch("fetchCountries").then((result) => {
                let country;
                if (stateGlobal.actualUser.nationalitieOnboarding) {
                    country = result.find(c => c._id === stateGlobal.actualUser.nationalitieOnboarding);
                } else {
                    country = result.find(c => c.countryKey === "004");
                }
                commit("SET_COUNTRIES", result);
                commit("SET_COUNTRY", country);
                commit("UPDATE_COUNTRY_ACTUAL_USER", country);
                dispatch("FETCH_STATES", country);
            })
        }
    },

    /**
     * Guarda el primer paso del wizard.
     * Obtiene la informacion del store.
     */
    saveStartStep: ({ state}) => {
        const payload = state.actualPotentialOrganization;

        return new Promise((resolve, reject)=>{
            signUpOrganizationApi.saveStartStep(payload,(response)=>{
                if(response.data && response.data.error) {
                    return reject(response.data);
                }
                return resolve(response.data);
            },(error) => {
                reject(error);
            });
        })
    },
    /**
     * Guarda el segundo paso del wizard.
     * Obtiene la informacion del store.
     */
    saveOperationSchemeStep: ({state}) => {
        const payload = state.actualPotentialOrganization;
        return new Promise((resolve, reject)=>{

            signUpOrganizationApi.saveOperationSchemeStep(payload,(response)=>{
                if(response.data && response.data.error) {
                    return reject(response.data);
                }
                return resolve(response.data);

            },(error) => {
                reject(error);
            });
        })
    },

    stepLogoUpdateLogo ({commit, state}, params) {
        params = {
            idPotentialOrganization: state.actualPotentialOrganization.idPotentialOrganization,
            ...params,
        };
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.stepLogoUpdateLogo(params, (response) => {
                if(response.error){
                    return reject(response.message, true);
                // eslint-disable-next-line no-else-return
                } else {
                    commit('SET_LOGO', response.data.object.logo);
                    return resolve(response);
                }
            }, () => {
                return reject('signup-organization.step-three.logo-saved-error', false);
            });
        });
    },
    stepCoverUpdateCover ({commit, state}, params) {
        params = {
            idPotentialOrganization: state.actualPotentialOrganization.idPotentialOrganization,
            ...params,
        };
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.stepCoverUpdateCover(params, (response) => {
                commit('SET_COVER', response.data.object.cover);
                return resolve(response);
            }, (err) => {
                return reject(err);
            });
        });
    },
    stepLogoUpdatePattern({commit}, params) {
        params = {
            idPotentialOrganization: stateGlobal.actualPotentialOrganization.idPotentialOrganization,
            ...params,
        };
        signUpOrganizationApi.stepLogoUpdatePattern(params, (result) => {
            commit('SET_PATTERN', result.data.data);
        }, () => {

        });
    },
    getCoverImageOptions: ({commit, state}, {organization, search}) => {
        return new Promise((resolve, reject) => {
            if (!organization && organization !== null) {
                search = state.actualPotentialOrganization !== undefined ? state.actualPotentialOrganization.category : "TOURISM";
            } else {
                search = search || organization.category;
            }
            signUpOrganizationApi.getCoverImageOptions(search,(result) => {
                if (result.data.error) {
                    return reject(result.data.error);
                }
                commit('SET_COVER_IMG_OPTIONS', result.data.data);
                return resolve(result);
            }, (err) => {
                return reject(err);
            });
        });
    },

    /**
     * Retrieves banks from DB to make them available at selection
     */
    getBanks(){
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.retrieveBanks({},
                (result) => {
                    if (result.data.error) {
                        return reject(result.data.error);
                    }
                    return resolve(result.data.data);
                }, (err) => {
                    return reject(err);
                })
        });
    },

    saveBanking: ({state}) => {
        return new Promise((resolve, reject) => {
            const bankingInfo = {
                bank: state.actualPotentialOrganization.bank,
                accountNumber: state.actualPotentialOrganization.accountNumber,
                clabe: state.actualPotentialOrganization.clabe,
                bankName: state.actualPotentialOrganization.bankName
                // bankCover: state.actualPotentialOrganization.bankCover
            };
            signUpOrganizationApi.saveBanking({
                idPotentialOrganization: state.actualPotentialOrganization.idPotentialOrganization,
                organizationBanking: bankingInfo
            }, (result) => {
                if (result.data.error) {
                    return reject(result.data.error);
                }
                return resolve(result.data);
            }, (err) => {
                return reject(err);
            })
        });
    },

    /**
     * Retrieves professions from DB to make them available at selection
     */
    getProfessions({commit}){
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.retrieveProfessions({},
                (result) => {
                    if (result.data.error) {
                        return reject(result.data.error);
                    }
                    commit('SET_PROFESSIONS',result.data.data);
                    return resolve(result.data.data);
                }, (err) => {
                    return reject(err);
                })
        });
    },

    /**
     * Retrieves occupations from DB to make them available at selection
     */
    getOccupations({commit}){
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.retrieveOccupations({},
                (result) => {
                    if (result.data.error) {
                        return reject(result.data.error);
                    }
                    commit('SET_OCCUPATIONS',result.data.data);
                    return resolve(result.data.data);
                }, (err) => {
                    return reject(err);
                })
        });
    },

    saveLastStep: ({state}) => {
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.saveLastStep({
                idPotentialOrganization: state.actualPotentialOrganization.idPotentialOrganization,
                salesManCode: state.actualPotentialOrganization.salesManCode,
            }, (result) => {
                if (result.data.error) {
                    return reject(result.data);
                }
                return resolve(result.data);
            }, (err) => {
                return reject(err);
            })
        });
    },
        /**
     * Retrieves occupations from DB to make them available at selection
     */
    fetchCountries: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            userApi.getCountries({},
                (result) => {
                    if (result.data.error) {
                        return reject(result.data.error);
                    }
                    commit("SET_COUNTRIES", result.data.object);
                    let country;
                    if (stateGlobal.actualUser.nationalitieOnboarding) {
                        country = result.find(c => c._id === stateGlobal.actualUser.nationalitieOnboarding);
                    } else {
                        country = result.find(c => c.countryKey === "004");
                    }
                    commit("SET_COUNTRIES", result);
                    commit("SET_COUNTRY", country);
                    commit("UPDATE_COUNTRY_ACTUAL_USER", country);
                    dispatch("FETCH_STATES", country);
                    return resolve(result.data.object);
                },
                (err) => {
                    return reject(err);
                })
        });
    },
    FETCH_STATES: ({commit, state}, country) => {
        commit("SET_STATE", {});
        commit("UPDATE_STATE_ACTUAL_USER", {});
        commit("UPDATE_BIRTH_STATE_ACTUAL_USER", {});
        generalApi.stateList({countryKey: country.countryKey}).then(response => {
            commit("SET_STATES", response.data.object);
            let countryState;
            let countryBirthState;
            if (state.actualUser.birthState && response && response.data && response.data.object) {
                countryState = response.data.object.find(c => c._id === state.actualUser.birthState);
            }
            if (state.actualUser.birthState && response && response.data && response.data.object) {
                countryBirthState = response.data.object.find(c => c._id === state.actualUser.birthState);
            }
            if(countryState) {
                commit("SET_STATE", countryState);
                commit("UPDATE_STATE_ACTUAL_USER", countryState);
            }
            if(countryBirthState) {
                commit("UPDATE_BIRTH_STATE_ACTUAL_USER", countryBirthState);
            }
        }).catch(() => {

        });
    },
    saveOrganizationFileStep: ({state}) => {
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.saveOrganizationFileStep({
                idPotentialOrganization: state.actualPotentialOrganization.idPotentialOrganization,
                occupation: state.actualPotentialOrganization.occupation,
                profession: state.actualPotentialOrganization.profession,
                requestedFiles: state.actualPotentialOrganization.requestedFiles
            }, (result) => {
                if (result.data.error) {
                    return reject(result.data);
                }
                return resolve(result.data);
            }, (err) => {
                return reject(err);
            })
        });
    },
    saveLocationStep: ({state}) => {
      return new Promise((resolve, reject) => {
        signUpOrganizationApi.saveStepLocation({
            idPotentialOrganization: state.actualPotentialOrganization.idPotentialOrganization,
            branch: state.actualPotentialOrganization.branch
        }, (result) => {
            if (result.data.error) {
                return reject(result.data);
            }
            return resolve(result.data);
        }, (err) => {
            return reject(err);
        })
      })
    },
    async loadPotentialOrganization({commit, state}, data){
            const actualOrganization = await signUpOrganizationApi.getSummaryOrganization(data);
            if(actualOrganization.error){
                // TODO:ERROR
            } else {
                commit('SET_POTENTIAL_ORGANIZATION', actualOrganization.data.object);
                commit('SET_FILE_DOCUMENTS_POTENTIAL_ORGANIZATION', state.fileDocuments);
            }
    },
    async loadContractOrganization({commit, state}){
        const potentialOrganization = state.actualPotentialOrganization
        const data = {
            idPotential: potentialOrganization.idPotentialOrganization
        }
        const actualContract = await signUpOrganizationApi.getActualContractPreview(data);
        if(actualContract.data.error){
            // TODO:ERROR
        } else {
            commit('SET_ACTUAL_CONTRACT', actualContract.data.object);
        }
        commit('SET_LOADING_CONTRACT', false);
    },
    async acceptPreContract({state}){
        const potentialOrganization = state.actualPotentialOrganization
        const data = {
            idPotential: potentialOrganization.idPotentialOrganization
        }
        return signUpOrganizationApi.acceptPreContract(data);
    },
    uploadingCoverImg: ({commit}, isUploadingCover) => {
        commit('SET_UPLOADING_COVER', isUploadingCover);
    },
    creatingOrganization({state}, params = {}){
        return new Promise((resolve) => {
            params.idPotentialOrganization = state.actualPotentialOrganization.idPotentialOrganization;
            signUpOrganizationApi.organizationCreate(params,
                function(response){
                    if(response.data && response.data.error){
                        return resolve({errCreate: response.data});
                    }
                        return resolve({errCreate: null, responseCreate: response.data});

                },() => {
                    resolve({errCreate: { error: true, message: i18n.t(ERROR_SAVING_ORG_KEY)}});
                })
        });
    },
    creatingContractAccepted({state}, params = {}){
        return new Promise((resolve) => {
            params.idPotentialOrganization = state.actualPotentialOrganization.idPotentialOrganization;
            signUpOrganizationApi.contractCreate(params,
                function(response){
                    if(response.data && response.data.error){
                        return resolve({errCreate: response.data});
                    }
                        return resolve({errCreate: null, responseCreate: response.data});

                },() => {
                    resolve({errCreate: { error: true, message: i18n.t(ERROR_SAVING_ORG_KEY)}});
                })
        });
    },
    configureOrganization(context, params){
        return new Promise((resolve) => {
            signUpOrganizationApi.accessConfigCreate(params,
                function(response){
                    if(response.data && response.data.error){
                        return resolve({errConfigure: response.data});
                    }
                        return resolve({errConfigure: null, responseConfigure: response.data});

                },() => {
                    resolve({errCreate: { error: true, message: i18n.t(ERROR_SAVING_ORG_KEY)}});
                })
        });
    },
    saveCollaboratorsInOrganization(context, params){
        return new Promise((resolve) => {
            signUpOrganizationApi.collaboratorsCreate(params,
                function(response){
                    if(response.data && response.data.error){
                        return resolve({errConfigure: response.data});
                    }
                        return resolve({errConfigure: null, responseConfigure: response.data});

                },() => {
                    resolve({errCreate: { error: true, message: i18n.t(ERROR_SAVING_ORG_KEY)}});
                })
        });
    },
    initializeOrganization(context, params){
        return new Promise((resolve) => {
            signUpOrganizationApi.accessConfigSelect(params,
                function(response){
                    if(response.data && response.data.error){
                        return resolve({errInit: response.data});
                    }
                        return resolve({errInit: null, responseInit: response.data});

                },() => {
                    resolve({errCreate: { error: true, message: i18n.t(ERROR_SAVING_ORG_KEY)}});
                })
        });
    },
    async agendUserCall( { state } , dataAgend ) {
        return new Promise((resolve,reject)=>{
            dataAgend.idPotentialOrganization = state.actualPotentialOrganization.idPotentialOrganization;
            userApi.agendUserCall(dataAgend, (response)=>{
                resolve(response.data);
            },(error)=>{
                reject(error);
            });
        });
    },

    /**
     *  Deletes a collaborator from the list
     * @param index the index of the collaborator within the array base 0
     */
    deleteCollaborator({commit}, index){
        commit("DELETE_COLLABORATOR",index);
    },
    saveCollaborators: ({state, commit}, collaborators) => {
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.addCollaborator({
                idPotentialOrganization: state.actualPotentialOrganization.idPotentialOrganization,
                collaboratorsEmail: collaborators
            }, (result) => {
                if (result.data.error) {
                    return reject(result.data);
                }
                    commit("RELOAD_COLLABORATORS", collaborators);
                    return resolve(result.data);

            }, (err) => {
                return reject(err);
            })
        });
    },
    fetchFileDocumentsRequested: ({state, commit}) => {
        const category = state.actualPotentialOrganization.category || "ALL";
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.fetchFileDocuments({
                processOrCategory: category,
                applyFor: "CLIENT",
                personType: "FISICA"
            }, (result) => {
               if(result.data.error) {
                   return reject(result.data);
               }
               commit("SET_FILE_DOCUMENTS", result.data.object);
               commit("SET_FILE_DOCUMENTS_POTENTIAL_ORGANIZATION", result.data.object);
               return resolve(result.data);
            }, (err) => {
                return reject(err);
            });
        });
    }

};

const mutations = {
    //  ------ STEP 1 --------------
    UPDATE_NAME_POTENTIAL_ORGANIZATION(state, value){
        state.actualPotentialOrganization.name = value
    },
    UPDATE_SALESMAN_CODE(state, value){
        state.actualPotentialOrganization.salesManCode = value
    },
    UPDATE_CATEGORY_POTENTIAL_ORGANIZATION(state, value){
        state.actualPotentialOrganization.category = value
    },
    UPDATE_DESCRIPTION_POTENTIAL_ORGANIZATION(state, value){
        state.actualPotentialOrganization.description = value;
    },
    // ------- STEP 2 --------------
    UPDATE_IS_PHYSICAL_PERSON(state, value){
        state.actualPotentialOrganization.isPhysicalPerson = value.params.isPhysicalPerson;
        state.actualPotentialOrganization.hasInvoiceData = value.params.hasInvoiceData;
    },
    UPDATE_RFC_ORGANIZATION(state, value){
        state.actualPotentialOrganization.rfc = value;
    },
    UPDATE_INVOICE_DATA_RFC(state, value){
        if(state.actualPotentialOrganization.invoiceData === undefined){
            state.actualPotentialOrganization.invoiceData = {};
        }
        state.actualPotentialOrganization.invoiceData.rfc = value;
    },
    UPDATE_INVOICE_DATA_CURP(state, value){
        if(state.actualPotentialOrganization.invoiceData === undefined){
            state.actualPotentialOrganization.invoiceData = {};
        }
        state.actualPotentialOrganization.invoiceData.curp = value;
    },
    UPDATE_INVOICE_DATA_ADDRESS(state, value){
        state.actualPotentialOrganization.invoiceData.streetAddress = value;
    },
    UPDATE_INVOICE_DATA_EXTERIOR_NUMBER(state, value){
        state.actualPotentialOrganization.invoiceData.exteriorNumber = value;
    },
    UPDATE_INVOICE_DATA_INTERIOR_NUMBER(state, value){
        state.actualPotentialOrganization.invoiceData.interiorNumber = value;
    },
    UPDATE_INVOICE_DATA_DISTRICT(state, value){
        state.actualPotentialOrganization.invoiceData.district = value;
    },
    UPDATE_INVOICE_DATA_CITY(state, value){
        state.actualPotentialOrganization.invoiceData.city = value;
    },
    UPDATE_INVOICE_DATA_STATE(state, value){
        state.actualPotentialOrganization.invoiceData.state = value;
    },
    UPDATE_INVOICE_DATA_ZIP_CODE(state, value){
        state.actualPotentialOrganization.invoiceData.zipCode = value;
    },
    UPDATE_INVOICE_DATA_OFFICIAL_ID(state, value){
         state.actualPotentialOrganization.invoiceData.officialId = value;
    },
    UPDATE_EMPTY_INVOICE_DATA(state){
        state.actualPotentialOrganization.invoiceData.name = '';
        state.actualPotentialOrganization.invoiceData.lastName = '';
        state.actualPotentialOrganization.invoiceData.secondLastName = '';
        state.actualPotentialOrganization.invoiceData.rfc = '';
        state.actualPotentialOrganization.invoiceData.streetAddress = '';
        state.actualPotentialOrganization.invoiceData.interiorNumber = '';
        state.actualPotentialOrganization.invoiceData.exteriorNumber = '';
        state.actualPotentialOrganization.invoiceData.district = '';
        state.actualPotentialOrganization.invoiceData.city = '';
        state.actualPotentialOrganization.invoiceData.state = '';
        state.actualPotentialOrganization.invoiceData.zipCode = '';
        state.actualPotentialOrganization.invoiceData.officialId = null;
    },
    // ----- Step 4 ---
    UPDATE_BANK_POTENTIAL_ORGANIZATION(state, value){
        state.actualPotentialOrganization.bank = value.value;
        state.actualPotentialOrganization.bankName = value.name;
    },
    UPDATE_BANK_ACCOUNT_NUMBER(state, value){
        state.actualPotentialOrganization.accountNumber = value;
    },
    UPDATE_BANK_CLABE(state, value){
        state.actualPotentialOrganization.clabe = value;
    },
    UPDATE_BANK_COVER(state, value){
        state.actualPotentialOrganization.bankCover = value;
    },
    UPDATE_OCCUPATION_POTENTIAL_ORGANIZATION(state, value){
        state.actualPotentialOrganization.occupation = value.value;
        state.actualPotentialOrganization.occupationName = value.name;
    },
    UPDATE_PROFESSION_POTENTIAL_ORGANIZATION(state, value){
        state.actualPotentialOrganization.profession = value.value;
        state.actualPotentialOrganization.professionName = value.name;
    },
    SET_DEFAULT_COVER_IMG: (state,coverResponse) => {
        if(coverResponse && coverResponse.length){
            const coverImg = coverResponse[0];
            if(state.actualPotentialOrganization.cover === undefined){
                state.actualPotentialOrganization.cover = {};
            }
            state.actualPotentialOrganization.cover.thirdPartyCoverUrl = coverImg.urls.custom;
            state.actualPotentialOrganization.cover.userNameThirdPartyCover = coverImg.user.username;
            state.actualPotentialOrganization.cover.authorNameThirdPartyCover = `${coverImg.user.first_name} ${coverImg.user.last_name}`;
        }
    },
    SET_COVER_IMG_OPTIONS: (state, {docs}) => {
        if(docs && docs.length > 0){
            state.cover.options = docs;
        } else {
            state.cover.options = [];
        }
    },
    SET_COVER_IMG_SEARCH: (state, {search}) => {
        state.actualPotentialOrganization.cover.search = search;
    },
    SET_THIRD_PARTY_COVER_URL: (state, url) => {
        state.actualPotentialOrganization.cover.thirdPartyCoverUrl = url;
        state.actualPotentialOrganization.cover.localCover = null;
        state.actualPotentialOrganization.cover.fullSizeCover = null;
    },
    CLEAR_TEMPORAL_THIRD_PARTY_COVER: (state) => {
        state.temporalCover.thirdPartyCoverUrl = null;
        state.temporalCover.authorNameThirdPartyCover = null;
        state.temporalCover.userNameThirdPartyCover = null;
    },
    SET_THIRD_PARTY_COVER_AUTHOR: (state, user) => {
        state.actualPotentialOrganization.cover.userNameThirdPartyCover = user.username;
        if(user.first_name && user.last_name){
            state.actualPotentialOrganization.cover.authorNameThirdPartyCover = `${user.first_name} ${user.last_name}`;
        } else if(user.authorNameThirdPartyCover){
            state.actualPotentialOrganization.cover.authorNameThirdPartyCover = user.authorNameThirdPartyCover;
        }
    },
    SET_TEMPORAL_THIRD_PARTY_COVER_URL: (state, url) => {
        state.temporalCover.thirdPartyCoverUrl = url;
        state.temporalCover.localCover = null;
        state.temporalCover.fullSizeCover = null;
    },
    SET_TEMPORAL_THIRD_PARTY_COVER_AUTHOR: (state, user) => {
        state.temporalCover.userNameThirdPartyCover = user.username;
        state.temporalCover.authorNameThirdPartyCover = `${user.first_name} ${user.last_name}`;
    },
    SET_COVER: (state, cover) => {
        state.actualPotentialOrganization.cover = cover;
    },
    SET_UPDATE_COVER: (state, doc) => {
        state.actualPotentialOrganization.cover = doc.cover
    },
    SET_LOGO: (state, logo) => {
        state.actualPotentialOrganization.logo = logo;
    },
    SET_PATTERN: (state, {pattern}) => {
        state.actualPotentialOrganization.pattern = {...state.actualPotentialOrganization.pattern, ...pattern};
    },
    SET_UPLOADING_COVER: (state, isUploadingCover) => {
        state.uploadingCover = isUploadingCover
    },
    SET_POTENTIAL_ORGANIZATION: (state, payload) => {


        state.actualPotentialOrganization.idPotentialOrganization = payload._id;
        state.actualPotentialOrganization.name = payload.name;
        state.actualPotentialOrganization.description = payload.description;
        state.actualPotentialOrganization.category = payload.category;
        state.actualPotentialOrganization.rfc = payload.RFC;
        state.actualPotentialOrganization.hasInvoiceData = payload.hasInvoiceData;
        if(payload.hasInvoiceData) {
            state.actualPotentialOrganization.invoiceData = payload.invoiceData;
            state.actualPotentialOrganization.invoiceData.officialId = payload.invoiceData.officialId;
        }
        state.actualPotentialOrganization.isPhysicalPerson = payload.isPhysicalPerson;
        state.actualPotentialOrganization.logo = payload.logo;
        state.actualPotentialOrganization.cover = payload.cover;
        state.actualPotentialOrganization.occupation = payload.occupation;
        state.actualPotentialOrganization.profession = payload.profession;
        state.actualPotentialOrganization.accountNumber = payload.accountNumber;
        state.actualPotentialOrganization.clabe = payload.clabe;
        if(payload.bank){
            state.actualPotentialOrganization.bankName = payload.bank.name;
            state.actualPotentialOrganization.bank = payload.bank;
            state.actualPotentialOrganization.bankCover = payload.bankCover;
        }
        state.isLoadActualOrganization = true;
        state.actualPotentialOrganization.collaborators = payload.collaborators;
        state.actualPotentialOrganization.requestedFiles = payload.requestedFiles;
        state.actualPotentialOrganization.branch = payload.branch;
    },
    SET_ERROR_NEXT_STEP(state, value){
        state.errorNextStep = value
    },
    SET_ERROR_MESSAGE_NEXT(state, message){
        state.errorNextStepMessage = message
    },
    SET_IS_VALID_STEP_FORM_SAVE(state, value){
        state.isValidStepFormSave = value
    },
    SET_IS_VALID_STEP_ONE(state, value){
        state.isValidStepOne = value
    },
    SET_IS_VALID_STEP_TWO(state, value){
        state.isValidStepTwo = value
    },
    SET_IS_VALID_STEP_THREE(state, value){
        state.isValidStepThree = value
    },
    SET_IS_VALID_STEP_FOUR(state, value){
        state.isValidStepFour = value
    },
    SET_IS_VALID_STEP_FIVE(state, value){
        state.isValidStepFive = value
    },
    SET_IS_VALID_STEP_SIX(state, value){
        state.isValidStepSix = value
    },
    SET_IS_VALID_STEP_SEVEN(state, value){
        state.isValidStepSeven = value
    },
    UPDATE_BRANCH(state, value){
        state.actualPotentialOrganization.branch = value;
    },
    UPDATE_BRANCH_DIRECTIONS(state, value){
        if(state.actualPotentialOrganization.branch){
            state.actualPotentialOrganization.branch.directions = value
        } else {
            state.actualPotentialOrganization.branch = {};
            state.actualPotentialOrganization.branch.directions = value;
        }
    },
    ACTUAL_USER(state, value){
        state.actualUser = value;
        state.isLoadUser = true;
    },
    RELOAD_COLLABORATORS(state, collaborators){
        state.actualPotentialOrganization.collaborators = collaborators
    },
    DELETE_COLLABORATOR(state, index){
        state.actualPotentialOrganization.collaborators.splice(index, 1);
    },
    SET_ACTUAL_CONTRACT(state, contract){
        state.contract = contract;
    },
    SET_LOADING_CONTRACT(state, value){
        state.loadingContract = value;
    },
    SET_IS_VALID_USER_SAVE_FORM(state, value){
        state.isValidStepFormSave = value;
    },
    UPDATE_NAME_ACTUAL_USER(state, value){
        state.actualUser.name = value
    },
    UPDATE_LAST_NAME_ACTUAL_USER(state, value){
        state.actualUser.lastName = value
    },
    UPDATE_LAST_NAME_MOTHER_ACTUAL_USER(state, value){
        state.actualUser.secondLastName = value
    },
    UPDATE_COUNTRY_ACTUAL_USER(state, value){
        state.actualUser.country = value;
        if(value.countryKey === "004"){
            state.isMexican = true;
        } else {
            state.isMexican = false;
        }
    },
    UPDATE_GENDER_ACTUAL_USER(state, value){
        state.actualUser.gender = value
    },
    UPDATE_BIRTHDAY_ACTUAL_USER(state, value){
        state.actualUser.birthDay = value
    },
    UPDATE_OCCUPATION_ACTUAL_USER(state, value){
        state.actualUser.ocupacion = value
    },
    UPDATE_PROFESSION_ACTUAL_USER(state, value){
        state.actualUser.profession = value
    },
    UPDATE_STATE_ACTUAL_USER(state, value){
        state.actualUser.state = value;
    },
    UPDATE_BIRTH_STATE_ACTUAL_USER(state, value){
        state.actualUser.birthState = value;
    },
    SET_PROFESSIONS(state, value){
        state.professions = value.docs;
    },
    SET_OCCUPATIONS(state, value){
        state.occupations = value.docs;
    },
    SET_PERSONAL_INFORMATION_STATUS(state, value){
        state.isPersonalInformationComplete = value;
    },
    UPDATE_PHONE_USER(state, value){
        state.actualUser.phone = value;
    },
    SET_PHONE_VERIFIED_STATUS(state, value){
        state.actualUser.phoneVerified = value;
    },
    SET_SECURITY_PHRASE(state, value){
        state.actualUser.securityPhrase = value
    },
    SET_ID_IMAGE_SECURITY(state, value){
        state.actualUser.securityImageId = value
    },
    // Borrar cuando se pruebe la estructura de registerUserSteps
    // SET_IS_VALID_SECURITY_MEASURE_STEP(state, value){
    //     state.isValidSecurityMeasure = value;
    // },
    SET_ACTIVE_COMPONENT_STEP_ONE(state, value){
        state.activeTabInStepOne = value
    },
     SET_COUNTRIES: (state, value)=>{
        state.countries = value
    },
    SET_STATES: (state, value)=>{
        state.states = value
    },
    SET_COUNTRY: (state, value)=>{
        state.country = value
    },
    SET_STATE: (state, value)=>{
        state.state = value
    },
    SET_REGISTER_USER_STEPS(state,{step, prop, value}){
        state.registerUserSteps[step][prop] = value;
    },
    SET_FILE_DOCUMENTS(state, value){
        state.fileDocuments = value;
    },
    SET_FILE_DOCUMENTS_POTENTIAL_ORGANIZATION(state, values = []){
        if(!state.actualPotentialOrganization.requestedFiles || state.actualPotentialOrganization.requestedFiles.length === 0 ){
            state.actualPotentialOrganization.requestedFiles = [];
            let requestedDocument = {};
            for(const value of values) {
                requestedDocument = {};
                requestedDocument.name = value.title;
                requestedDocument.key = value.key;
                requestedDocument.fileDocument = value._id;
                requestedDocument.isRequired = value.isRequired;
                state.actualPotentialOrganization.requestedFiles.push(requestedDocument);
            }
        }
    },
    UPDATE_FILES(state, values){
        // Y recuerden amiguitos si actualizan un valor que no se encuentra declarado en el state no se actualizarán los getters que hayan definido de ese valor :)
        const tempArray = state.actualPotentialOrganization.requestedFiles;
        const index = state.actualPotentialOrganization.requestedFiles.findIndex(element => element.key === values.fileKey);
        state.actualPotentialOrganization.requestedFiles[index].file = values.tempFile;
        state.actualPotentialOrganization.requestedFiles[index].isLoaded = values.tempFile ? true : false ;
        const tempObj = state.actualPotentialOrganization.requestedFiles[index];
        tempArray.splice(index, 1, tempObj);
        state.actualPotentialOrganization.requestedFiles = tempArray;
    },
    SET_ONBOARDING_FLAG(state, value){
        state.isOnboarding = value;
    }
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    }
};
