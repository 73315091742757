<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/payment-link-list"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit p-0"
                    tabindex=""
                >
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i> Regresar al listado de Links de pago
                </router-link>
                <FloatingTitleLayout :title="edit ? $t('paymentLinks.editTitle') : $t('paymentLinks.new')" />
            </div>

            <div class="col-12" v-if="!loading">
                <CardSimple :title="$t('paymentLinks.createTitle2')"
                :subtitle="$t('paymentLinks.createSubtitle2')">
                    <div class="row">
                        <div class="col-12">
                            <BasicTextarea
                                required
                                :maxCount="80"
                                labelFor="titleID"
                                textareaId="titleID"
                                v-model="concept"
                                :label="$t('paymentLinks.fields.conceptTitle')"
                                :textareaPlaceholder="$t('paymentLinks.fields.conceptPlaceholder')"
                            >
                            </BasicTextarea>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                required
                                clazz=""
                                :label="$t('paymentLinks.fields.amountTitle')"
                                inputType="text"
                                v-model="amount"
                                @input="amountChange"
                                inputPlaceholder="$0.00">
                            </BasicInput>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicSelect
                                required
                                :label="$t('paymentLinks.fields.paymentType')"
                                clazz="select-description"
                            >
                                <multiselect
                                    v-model="valuePaymentType"
                                    :options="optionsPaymentType"
                                    :close-on-select="true"
                                    :preserve-search="true"
                                    :placeholder="$t('paymentLinks.fields.selectPaymentType')"
                                    label="title"
                                    track-by="title"
                                    :preselect-first="false"
                                >
                                    <span slot="noResult">No se encontraron resultados</span>
                                    <template slot="option" slot-scope="props">
                                        <strong>{{ props.option.title }}</strong><br>
                                        <p>{{ props.option.desc }}</p>
                                    </template>
                                </multiselect>
                            </BasicSelect>
                        </div>
                        <div class="col-12 m-b-40">
                            <UploadBox titleClass="whitespace-nowrap" :titleBox="$t('paymentLinks.fields.addPhoto')" :showLoadBox="!imageToUpload" inputFileAccept=".png,.jpg"
                                clazzContainer="box-logo-container m-b-0"
                                fileType="no-img-back" @change="uploadImage">
                                <template v-slot:imagePreview>
                                    <div class="img-card">
                                        <img class="img-fluid logo"
                                            :src="getImageUrl()"
                                            alt="imagen"/>
                                    </div>
                                </template>

                                <template v-slot:button>
                                    <div class="col-12">
                                        <a class="button xs button-stroke button-accent2 p-relative w-100"
                                        tabindex="">
                                            <input type="file" class="input-file" @change="uploadImage()" ref="fileUploadInput" accept=".png,.jpg">
                                            <i aria-hidden="true" class="banana banana-camera"></i> {{$t('paymentLinks.fields.uploadImage')}}
                                        </a>
                                    </div>
                                </template>

                                <template v-slot:noteText>
                                    <SimpleNote clazz="info" icon="banana-info-circle1">
                                        <p class="m-b-0">{{$t('paymentLinks.fields.imageType')}}</p>
                                    </SimpleNote>
                                </template>
                            </UploadBox>
                        </div>
                        <div class="col-12">
                            <CollapseInfo
                            clazz="form-collapsible"
                            :checkboxRight="false"
                            collapseId="info"
                            :permissionName="$t('paymentLinks.moreOptions')"
                            >
                                <div class="p-t-30">
                                    <div class="row">
                                        <div class="col-12">
                                            <ReadOnlyInfo
                                                titleClazz="f-18 body-font-bold"
                                                :title="$t('paymentLinks.fields.expDateTitle')"
                                                :text="$t('paymentLinks.fields.expDateSubtitle')"
                                            ></ReadOnlyInfo>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <InputDatepicker
                                                labelFor="date"
                                                :label="$t('paymentLinks.fields.startDate')"
                                                required
                                            >
                                                <datepicker
                                                    :language="es"
                                                    placeholder="dd / mm / aaaa"
                                                    wrapper-class="form-datepicker"
                                                    input-class="form-control"
                                                    calendar-class="window-datepicker"
                                                    calendar-button
                                                    @input="dateChange"
                                                    v-model="expDate.startDate.date"
                                                    calendar-button-icon="banana banana-calendar"
                                                    typeable
                                                />
                                            </InputDatepicker>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <InputTimepicker
                                                labelFor="time"
                                                label="Hora"
                                            >
                                                <vue-clock-picker
                                                    ref="timeStartClock"
                                                    mode="12"
                                                    :defaultHour="expDate.startDate.hour"
                                                    :defaultMinute="expDate.startDate.minutes"
                                                    :onTimeChange="timeChangeHandler"
                                                    class="clock-picker"
                                                ></vue-clock-picker>
                                            </InputTimepicker>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <InputDatepicker
                                                labelFor="date"
                                                :label="$t('paymentLinks.fields.endDate')"
                                                required
                                            >
                                                <datepicker
                                                    :language="es"
                                                    placeholder="dd / mm / aaaa"
                                                    wrapper-class="form-datepicker"
                                                    input-class="form-control"
                                                    calendar-class="window-datepicker"
                                                    calendar-button
                                                    @input="dateEndChange"
                                                    v-model="expDate.endDate.date"
                                                    calendar-button-icon="banana banana-calendar"
                                                    typeable
                                                />
                                            </InputDatepicker>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <InputTimepicker
                                                labelFor="time"
                                                label="Hora"
                                            >
                                                <vue-clock-picker
                                                    mode="24"
                                                    ref="timeEndClock"
                                                    :defaultHour="expDate.endDate.hour"
                                                    :defaultMinute="expDate.endDate.minutes"
                                                    :onTimeChange="timeChangeHandlerEndDate"
                                                    class="clock-picker"
                                                ></vue-clock-picker>
                                            </InputTimepicker>
                                        </div>
                                        <div class="col-12">
                                            <ReadOnlyInfo
                                                clazz="m-t-30"
                                                titleClazz="f-18 body-font-bold"
                                                :title="$t('paymentLinks.paymentMethodTitle')"
                                                :text="$t('paymentLinks.paymentMethodSubtitle')"
                                            ></ReadOnlyInfo>
                                        </div>
                                        <div class="col-12">
                                            <div class="row m-b-30">
                                                <div class="col-12 col-md-8">
                                                    <BasicCheckbox
                                                        inputClass="min-w-20px"
                                                        clazz="m-0 w-100% di-flex align-items-initial"
                                                        textClass="f-14 c-primary_1 principal-font-bold"
                                                        v-model="creditDebit"
                                                        :disabled="true"
                                                    >
                                                        <strong class="f-14 c-title">{{$t('paymentLinks.creditDebitCard')}}</strong>
                                                        <p class="m-0 f-12 c-info_text" v-html="$t('paymentLinks.creditDebitCardSubtitle')"></p>
                                                        <BasicSelect v-if="optionsPromo.length > 0"
                                                            clazz="m-0 m-t-30 select-checkbox"
                                                            required
                                                            :label="$t('paymentLinks.promosAvailables')"
                                                            labelFor
                                                            :warningText="$t('paymentLinks.promosAvailablesWarning')"
                                                        >
                                                            <multiselect
                                                                v-model="valuePromo"
                                                                :options="optionsPromo"
                                                                :close-on-select="false"
                                                                :preserve-search="true"
                                                                :placeholder="$t('paymentLinks.selectPromo')"
                                                                label="name"
                                                                track-by="name"
                                                                :preselect-first="false"
                                                                :multiple="true"
                                                                group-values="payments"
                                                                group-label="promo"
                                                                :group-select="true"
                                                            >
                                                                <span slot="noResult">No se encontraron resultados</span>
                                                                <template
                                                                    slot="selection"
                                                                    slot-scope="{ values, isOpen }"
                                                                >
                                                                    <span
                                                                        class="multiselect__single"
                                                                        v-if="values.length &amp;&amp; isOpen"
                                                                    >{{ values.length }} promociones seleccionadas</span>
                                                                    <span class="multiselect__single" slot="tag" v-if="values.length &amp;&amp; !isOpen">
                                                                        {{ values.length }} promociones seleccionadas
                                                                    </span>
                                                                </template>
                                                            </multiselect>
                                                        </BasicSelect>
                                                    </BasicCheckbox>
                                                </div>
                                                <div class="col-12 col-md-4 text-md-right text-center p-t-15">
                                                    <img
                                                        height="25"
                                                        width="180"
                                                        class="img-fluid"
                                                        src="@/assets/images/Logos/logos-payments.svg"
                                                        alt="Logo tarjetas de debito credito"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-12">
                                            <div class="row m-b-30">
                                                <div class="col-12 col-md-8">
                                                    <BasicCheckbox
                                                        inputClass="min-w-20px"
                                                        clazz="m-0 w-100% di-flex align-items-initial"
                                                        textClass="f-14 c-primary_1 principal-font-bold"
                                                    >
                                                        <strong class="f-14 c-title">Cuenta Pagando</strong>
                                                        <p class="m-0 f-12 c-info_text">
                                                            El cliente iniciara sesión en su cuenta Pagando y realizara
                                                            el pago de forma segura con alguna de sus cuentas. El pago se acredita inmediatamente.
                                                        </p>
                                                    </BasicCheckbox>
                                                </div>
                                                <div class="col-12 col-md-4 text-md-right text-center p-t-15">
                                                    <img
                                                        height="27"
                                                        width="128"
                                                        class="img-fluid"
                                                        src="@/assets/images/Logos/logo-color.svg"
                                                        alt="Logo pagando"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row m-b-30">
                                                <div class="col-12 col-md-8">
                                                    <BasicCheckbox
                                                        inputClass="min-w-20px"
                                                        clazz="m-0 w-100% di-flex align-items-initial"
                                                        textClass="f-14 c-primary_1 principal-font-bold"
                                                    >
                                                        <strong class="f-14 c-title">Transferencia electrónica</strong>
                                                        <p class="m-0 f-12 c-info_text">
                                                            Al cliente se le proporciona una CLABE y una referencia para que
                                                            realice una transferencia desde el banco de su preferencia.
                                                            El pago puede tardar unas horas en reflejarse..
                                                        </p>
                                                    </BasicCheckbox>
                                                </div>
                                                <div class="col-12 col-md-4 text-md-right text-center p-t-15">
                                                    <img
                                                        height="20"
                                                        width="65"
                                                        class="img-fluid"
                                                        src="@/assets/images/Logos/logo-spei.svg"
                                                        alt="Logo SPEI"
                                                    />
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </CollapseInfo>
                        </div>
                        <div class="col-12 m-t-40 vertical-center justify-content-end">
                            <button type="button"
                                    class="button xs button-ghost button-plain_text">
                                    {{$t('paymentLinks.cancel')}}
                            </button>
                            <button type="button"
                                    class="button xs button-filled button-accent1 m-l-10" :disabled="!checkLink()" @click="saveLink()">
                                    {{$t('paymentLinks.shareSave')}}
                            </button>
                        </div>
                    </div>
                </CardSimple>
            </div>
            <div class="col-12" v-if="loading">
                <LoadingBar ></LoadingBar>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue'
    import BasicInput from "@/components/BasicInput.vue"
    import BasicSelect from "@/components/BasicSelect.vue"
    import Multiselect from "vue-multiselect"
    import CardSimple from '@/components/CardSimple.vue'
    import BasicTextarea from '@/components/BasicTextarea.vue'
    import UploadBox from '@/components/UploadBox.vue'
    import SimpleNote from '@/components/SimpleNote.vue'
    import CollapseInfo from '@/components/CollapseInfo.vue'
    import ReadOnlyInfo from '@/components/ReadOnlyInfo.vue'
    import InputDatepicker from '@/components/InputDatepicker.vue'
    import Datepicker from "vuejs-datepicker"
    import {en, es} from 'vuejs-datepicker/dist/locale'
    import InputTimepicker from "@/components/InputTimepicker.vue"
    import VueClockPicker from "vue-clock-picker"
    import BasicCheckbox from "@/components/BasicCheckbox.vue"
    import PaymentLinksApi from "@/api/paymentLinks/paymentLinks.api";
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {mapGetters} from 'vuex';
    // import utils from '@/common/utils';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import ImageApi from '@/api/images/image.api';
    import images from '@/common/images';
    import LoadingBar from '@/components/LoadingBar.vue';
    // import moment from 'moment';

    export default {
        name: "PaymentLinkDetail",
        components: {
            FloatingTitleLayout,
            BasicInput,
            BasicSelect,
            Multiselect,
            CardSimple,
            BasicTextarea,
            UploadBox,
            SimpleNote,
            CollapseInfo,
            ReadOnlyInfo,
            InputDatepicker,
            Datepicker,
            InputTimepicker,
            VueClockPicker,
            BasicCheckbox,
            LoadingBar
        },
        mixins: [
            ToastSnotify
        ],
        data() {
            return {
                // Date Picker
                en: en,
                es: es,
                // Multiselect
                valuePaymentType: null,
                optionsPaymentType: [
                    {title: this.$i18n.t('paymentLinks.promos.unique.title'),
                    desc: this.$i18n.t('paymentLinks.promos.unique.subtitle'), value: 'UNIQUE'},
                    {title: this.$i18n.t('paymentLinks.promos.multiple.title'),
                    desc: this.$i18n.t('paymentLinks.promos.multiple.subtitle'), value: 'MULTIPLE'},
                ],
                valuePromo: [],
                optionsPromo: [],
                concept: null,
                amount: null,
                creditDebit: true,
                expDate: {
                    startDate: {
                        date: null,
                        hour: '0',
                        minutes: '0',
                        finalDate: null
                    },
                    endDate: {
                        date: null,
                        hour: '0',
                        minutes: '0',
                        finalDate: null
                    }
                },
                paymentMethods: [
                    'CREDIT_DEBIT_CARD'
                ],
                imagePreview1: null,
                imageToUpload: null,
                edit: false,
                id: null,
                loading: false,
                active: false,
                timeoutAmount: null,
                amountNotValid: false
            };
        },
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
        },
        methods: {
            dateChange(evt) {
                this.expDate.startDate.date = evt;
                const newDate = new Date(this.expDate.startDate.date);
                newDate.setHours(this.expDate.startDate.hour);
                newDate.setMinutes(this.expDate.startDate.minutes);

                this.expDate.startDate.finalDate = newDate;
            },
            dateEndChange(evt) {
                this.expDate.endDate.date = evt;
                const newDate = new Date(this.expDate.endDate.date);
                newDate.setHours(this.expDate.endDate.hour);
                newDate.setMinutes(this.expDate.endDate.minutes);

                this.expDate.endDate.finalDate = newDate;
            },
            getImageUrl() {
                if(this.imageToUpload) {
                    return images.getImageUrl(this.imageToUpload);
                }

                return null;
            },
            _getDescription(data) {
                return data === 'MSI' ? this.$i18n.t('paymentLinks.msi') : this.$i18n.t('paymentLinks.fixPay')
            },
            async getPromos() {
                const payLater = "PAY_LATER_NORMAL";
                this.optionsPromo = [];
                const response = await PaymentLinksApi.promos({organizationId: this.currentOrganization._id, amount: this.amount});
                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.promos'));
                } else {
                  const promos = response.data.data || {};
                  delete promos[payLater];

                  for (const key of Object.keys(promos)) {
                    const description = this._getDescription(key);
                    const newPromo = {
                      promo: description,
                      payments: []
                    }

                    delete promos[key].maxTime;

                    for (const time of Object.keys(promos[key])) {
                      newPromo.payments.push({
                        name: `${promos[key][time].time} ${description}`,
                        value: {type: key, time: promos[key][time].time}
                      })
                    }

                    this.optionsPromo.push(newPromo);
                  }
                }
            },
            amountChange(value) {
                clearTimeout(this.timeoutAmount);
                this.timeoutAmount = setTimeout(async () => {
                    if(this.currentOrganization.expressPaymentMinAmount && this.currentOrganization.expressPaymentMaxAmount) {
                        if(+value >= +this.currentOrganization.expressPaymentMinAmount && +value <= +this.currentOrganization.expressPaymentMaxAmount) {
                            this.amountNotValid = false;
                            await this.getPromos();
                        } else {
                            this.amountNotValid = true;
                            this.optionsPromo = [];
                            this.displayNotificationError(`El monto a cobrar debe de ser mayor a
                            ${this.currentOrganization.expressPaymentMinAmount} y menor a ${this.currentOrganization.expressPaymentMaxAmount}`);
                        }
                    } else {
                            this.amountNotValid = true;
                            this.optionsPromo = [];
                            this.displayNotificationError(`No se encuentran configurados los limites operacionales para la organización.`);
                    }
                }, 1000);
            },
            checkLink() {
                if(!this.amount || !this.concept || !this.valuePaymentType || (this.edit && this.id === null) || this.amountNotValid) {
                    return false;
                }

                return true;
            },
            async saveLink() {
                this.loading = true;
                if(this.checkLink()) {
                    const objToSend = {
                        concept: this.concept,
                        amount: this.amount,
                        type: this.valuePaymentType.value,
                        paymentMethod: this.paymentMethods,
                        active: this.active,
                        organization: this.currentOrganization._id
                    };

                    if(this.edit) {
                        objToSend.id = this.id;
                    }

                    if(this.valuePromo.length > 0) {
                        objToSend.promos = this.valuePromo;
                    }

                    if(this.expDate.startDate.finalDate !== null && this.expDate.endDate.finalDate !== null) {
                        objToSend.expDate = {
                            startDate: this.expDate.startDate.finalDate,
                            endDate: this.expDate.endDate.finalDate,
                        };
                    }

                    if(this.imageToUpload) {
                        objToSend.image = this.imageToUpload._id;
                    }

                    const response = await PaymentLinksApi.save(objToSend);

                    if(response.data.error) {
                        this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.save'));
                    } else {
                        this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.save'));
                        this.$router.push({name: 'PaymentLinkList'});
                    }
                }
                this.loading = false;

            },
            timeChangeHandler(event) {
                this.expDate.startDate.hour = event.hour;
                this.expDate.startDate.minutes = event.minute;
                const newDate = new Date(this.expDate.startDate.date);
                newDate.setHours(event.hour);
                newDate.setMinutes(event.minute);

                this.expDate.startDate.finalDate = newDate;
            },
            timeChangeHandlerEndDate(event) {
                this.expDate.endDate.hour = event.hour;
                this.expDate.endDate.minutes = event.minute;

                const newDate = new Date(this.expDate.endDate.date);
                newDate.setHours(event.hour);
                newDate.setMinutes(event.minute);

                this.expDate.endDate.finalDate = newDate;
            },
            methodCreditDebitCardChange(event) {
                if(event) {
                    this.paymentMethods.push('CREDIT_DEBIT_CARD');
                } else {
                    const index = this.paymentMethods.findIndex('CREDIT_DEBIT_CARD');
                    this.paymentMethods.splice(index, 1);
                }
            },
            async uploadImage(file) {
                let finalFile = null;

                if(file) {
                    finalFile = file[0];
                } else {
                    finalFile = this.$refs.fileUploadInput.files[0];
                }

                this.imagePreview1 = finalFile;
                const response = await ImageApi.upload(finalFile, {temp: true});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.image'));
                } else {
                    this.imageToUpload = response.data.object;
                    this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.image'));
                }
            },
            async getLink(id) {
                this.loading = true;
                const response = await PaymentLinksApi.byId({id: id});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.get'));
                } else {
                    this.concept = response.data.object.concept;
                    this.amount = response.data.object.amount;
                    this.active = response.data.object.active;
                    this.valuePaymentType = this.optionsPaymentType.filter(o => o.value === response.data.object.type)[0];
                    this.imageToUpload = response.data.object.image;
                    this.paymentMethods = response.data.object.paymentMethod;

                    if(response.data.object.expDate) {
                        const startDate = new Date(response.data.object.expDate.startDate);
                        const endDate = new Date(response.data.object.expDate.endDate);
                        this.expDate.startDate.date = startDate;
                        this.expDate.startDate.finalDate = startDate;
                        this.expDate.startDate.hour = startDate.getHours().toString();
                        this.expDate.startDate.minutes = startDate.getMinutes().toString();
                        this.expDate.endDate.hour = endDate.getHours().toString();
                        this.expDate.endDate.minutes = endDate.getMinutes().toString();
                        this.expDate.endDate.date = endDate;
                        this.expDate.endDate.finalDate = startDate;
                        setTimeout(() => {
                            this.$refs.timeStartClock.hour = this.expDate.startDate.hour;
                            this.$refs.timeStartClock.minute = this.expDate.startDate.minutes;
                            this.$refs.timeEndClock.hour = this.expDate.endDate.hour;
                            this.$refs.timeEndClock.minute = this.expDate.endDate.minutes;
                        }, 3000);
                    }


                    await this.getPromos();

                    const promos = response.data.object.promosAvailable;
                    this.getInternalPromos(promos);

                }

                this.loading = false;
            },
            getInternalPromos(promos) {
                if(promos) {
                    if(promos.msi.length > 0) {

                        for(const pay of promos.msi) {
                                const obj = {
                                name: `${pay} ${this.$i18n.t('paymentLinks.msi')}`,
                                value: {
                                    time: pay,
                                    type: 'MSI'
                                }
                            };

                            this.valuePromo.push(obj);
                        }

                    }

                    if(promos.fixedPayments.length > 0) {

                        for(const pay of promos.fixedPayments) {
                                const obj = {
                                name: `${pay} ${this.$i18n.t('paymentLinks.fixPay')}`,
                                value: {
                                    time: pay,
                                    type: 'FIX_PAY'
                                }
                            };

                            this.valuePromo.push(obj);
                        }

                    }
                }

            }
        },
        async created() {
            this.id = this.$route.query.id;

            if(this.id) {
                this.edit = true;
                await this.getLink(this.id);
            }

        }
    };
</script>
