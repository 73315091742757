<template>
    <div>
        <SignUpOrganizationBody stepThree>
            <template v-slot:formSectionBody>
                <GenericTitle id="dct-orgStepTwo" clazz="p-t-20"
                              :title="getPotentialOrganization.hasInvoiceData ? $t('signup-organization.step-two.invoice-data.title') : $t('signup-organization.step-two.title') "
                              :titleStrong="getPotentialOrganization.hasInvoiceData ?
                               $t('signup-organization.step-two.invoice-data.title-strong') :
                               $t('signup-organization.step-two.title-strong')"
                              :subtitle="getPotentialOrganization.hasInvoiceData ?
                               $t('signup-organization.step-two.invoice-data.subtitle') :
                                $t('signup-organization.step-two.subtitle')">

                </GenericTitle>
                <StepValidation :text="getErrorNextStepMessage" icon="banana-cross-normal" status="error"
                                v-if="getErrorNextStep"></StepValidation>

                <div v-show="!getPotentialOrganization.hasInvoiceData">
                    <label class="f-18 c-title body-font-semibold f-600 d-block m-b-10">{{
                        $t('signup-organization.step-two.label') }}</label>
                    <div class="persona-fisica-moral">
                        <div>
                            <ClientType :fisica="true" idClientType="dct-personTypeF" radioId="radioFisica"
                                        :titleList="$t('signup-organization.step-two.physical-person.title')"
                                        @click.native="physicalPerson = true" ref="radioFisica">
                                <li>{{$t('signup-organization.step-two.physical-person.content-1')}}</li>
                                <li>{{$t('signup-organization.step-two.physical-person.content-2')}}</li>
                                <li>{{$t('signup-organization.step-two.physical-person.content-3')}}</li>
                            </ClientType>

                            <ClientType idClientType="dct-personTypeM" radioId="radioMoral"
                                        :titleList="$t('signup-organization.step-two.moral-person.title')"
                                        @click.native="setMoralPerson()" ref="radioMoral">
                                <li>{{$t('signup-organization.step-two.moral-person.content-1')}}</li>
                                <li>{{$t('signup-organization.step-two.moral-person.content-2')}}</li>
                                <li>{{$t('signup-organization.step-two.moral-person.content-3')}}</li>
                            </ClientType>
                        </div>
                    </div>

                    <div v-show="physicalPerson">
                        <p class="f-16 c-plain_text body-font-regular d-block m-b-15">{{
                            $t('signup-organization.step-two.physical-person.cuestion') }}</p>
                        <b-form-radio-group id="radio-group" v-model="selected" name="invoiceDataRadio">
                            <b-form-radio
                                          @click.native="updateIsPhysicalPerson(true, true)"
                                          class="answer"> {{
                                $t('signup-organization.step-two.physical-person.answer.yes') }}
                            </b-form-radio>
                            <b-form-radio
                                          @click.native="updateIsPhysicalPerson(true, false); $bvModal.show('modal-onboardingContact')"
                                          class="answer"> {{
                                $t('signup-organization.step-two.physical-person.answer.no') }}
                            </b-form-radio>
                        </b-form-radio-group>
                    </div>

                </div>
                <ModalDefault modalId="modal-onboardingContact" :originalFooter="false" size="lg"
                    :title="successCallScheduled ? $t('signup-organization.step-two.modal.title-success') : $t('signup-organization.step-two.modal.title')"
                    :subtitle="successCallScheduled ? $t('signup-organization.step-two.modal.subtitle-success') : $t('signup-organization.step-two.modal.subtitle')">
                    <div class="row">
                        <div class="col-12 m-b-5">
                            <TelInput
                                    labelFor="numCountry"
                                    :label="$t('signup-organization.step-two.modal.telinput.label')"
                                    required
                                    idInput="numCountry"
                                    :inputPlaceholder="$t('signup-organization.step-two.modal.telinput.placeholder')"
                                    v-model="phone"
                                    :class="clazzPhone"
                                    v-on:country-changed="changeCountry"
                                    v-on:blur="phoneValidator"
                                    v-if="!successCallScheduled"
                            ></TelInput>
                        </div>
                        <div class="col-12 m-b-35">
                            <img class="img-fluid d-flex m-0-auto" v-if="!successCallScheduled"
                                 src="@/assets/images/Illustrations/ilustracion-llamada-ventas.svg" alt=""/>
                            <img class="img-fluid d-flex m-0-auto" v-if="successCallScheduled"
                                 src="@/assets/images/Illustrations/ilustracion-feedback-llamada-ventas.svg" alt=""/>
                        </div>
                        <div class="col-12 col-sm-8" v-if="!successCallScheduled">
                            <InputDatepicker
                                    labelFor="calldate"
                                    :label="$t('signup-organization.step-two.modal.datepicker.label')">
                                <datepicker v-model="callDate"
                                            :placeholder="$t('signup-organization.step-two.modal.datepicker.placeholder')"
                                            wrapper-class="form-datepicker datepicker-up"
                                            input-class="form-control"
                                            :disabled-dates="{ to: new Date()}"
                                            calendar-class="window-datepicker"
                                            calendar-button
                                            calendar-button-icon="banana banana-calendar"
                                            typeable/>
                            </InputDatepicker>
                        </div>
                        <div class="col-12 col-sm-4" v-if="!successCallScheduled">
                            <InputTimepicker
                                    labelFor="calltime"
                                    :label="$t('signup-organization.step-two.modal.hour-picker.label')"
                                    help
                                    idHelpTooltip="callTime"
                                    helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="callTime" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                {{$t('signup-organization.step-two.modal.hour-picker.sub-title')}}
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                                <vue-clock-picker
                                        mode="12"
                                        :defaultHour="defaultHour"
                                        :defaultMinute="defaultMinute"
                                        :onTimeChange="timeChangeHandler"
                                        v-model="callTime"
                                        class="clock-picker timepicker-up"></vue-clock-picker>
                            </InputTimepicker>
                        </div>
                    </div>
                    <template v-slot:customFooter>
                        <button class="button xs button-filled button-accent1 w-100" type="button"
                                @click="agendUserCall()" v-if="!successCallScheduled && !loadingAgenda">
                            {{$t('signup-organization.step-two.modal.button')}}
                        </button>
                        <button class="button xs button-filled button-accent1 w-100" type="button" v-if="loadingAgenda">
                            <LoadingSimple clazz="small-load no-text"/>
                        </button>
                        <button class="button sm button-filled button-accent1 w-100" type="button"
                                @click="goToDashboard()" v-if="successCallScheduled">
                            {{$t('signup-organization.step-two.modal.button-success')}}
                        </button>
                    </template>
                </ModalDefault>

                <div v-show="getPotentialOrganization.hasInvoiceData">
                    <InvoiceDataForm></InvoiceDataForm>
                </div>

            </template>
        </SignUpOrganizationBody>

    </div>
</template>

<script>
    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue';
    import InvoiceDataForm from './InvoiceDataForm.vue';
    import LoadingSimple from '@/components/LoadingSimple.vue';
    import GenericTitle from '@/components/GenericTitle.vue';
    import ModalDefault from '@/components/ModalDefault.vue';
    import InputDatepicker from '@/components/InputDatepicker.vue';
    import InputTimepicker from '@/components/InputTimepicker.vue';
    import TelInput from '@/components/TelInputOld.vue';
    import ClientType from '@/components/ClientType.vue';
    import StepValidation from '@/components/StepValidation.vue';
    import {required, helpers} from 'vuelidate/lib/validators';
    import {mapGetters} from 'vuex';
    import VueClockPicker from 'vue-clock-picker';
    import Datepicker from 'vuejs-datepicker';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import config from '@/config';

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    const RFC = helpers.regex('RFC', /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/)
    const storeModule = "signUpOrganization";

    export default {
        name: "StepOperationScheme",
        data() {
            return {
                user: "<username here>",
                phone: "",
                countryPhone: "MX",
                showCuestion: false,
                physicalPerson: false,
                picked: "",
                hasInvoiceData: false,
                selected: '',
                callDate: new Date(),
                callTime: new Date(),
                showRfc: false,
                rfc: '',
                defaultTime: new Date(),
                defaultHour: new Date().getHours(),
                defaultMinute: new Date().getMinutes(),
                selectedIsPhysical: false,
                selectedIsNotPhysical: false,
                clazzPhone: "",
                successCallScheduled: false,
                loadingAgenda: false
            }
        },
        mixins: [
            ToastSnotify
        ],
        computed: {
            ...mapGetters(storeModule, ['getPotentialOrganization', 'getActualUser', 'getIsValidStepFormSave', 'getErrorNextStep', 'getErrorNextStepMessage']),
            fullNameUser() {
                return `${this.getActualUser.name} ${this.getActualUser.lastName} ${this.getActualUser.secondLastName}`
            }
        },
        components: {
            SignUpOrganizationBody,
            GenericTitle,
            InvoiceDataForm,
            ClientType,
            StepValidation,
            ModalDefault,
            InputDatepicker,
            LoadingSimple,
            VueClockPicker,
            InputTimepicker,
            TelInput,
            Datepicker
        },
        methods: {
            getErrorClass(propertie) {
                return this.$v[propertie].$error ? "has-error" : "";
            },
            updateRFC: debounce(function (rfc) {
                this.rfc = rfc;
                this.$v.rfc.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_RFC_ORGANIZATION`, rfc);
            }, 800, false),
            updateIsPhysicalPerson: debounce(function (isPhysicalPerson, hasInvoiceData) {

                const params = {
                    isPhysicalPerson: isPhysicalPerson,
                    hasInvoiceData: hasInvoiceData
                };
                this.$store.commit(`${storeModule}/UPDATE_IS_PHYSICAL_PERSON`, {params});
                if (!this.selected) {
                    this.showRfc = true;
                    this.validateStep();
                    this.$store.commit(`${storeModule}/UPDATE_EMPTY_INVOICE_DATA`);
                }
                this.validateStep();

            }, 0, false),
            setMoralPerson() {
                this.physicalPerson = false;
                this.updateIsPhysicalPerson(false, false);
                this.$bvModal.show('modal-onboardingContact');
            },
            goToDashboard() {
                window.location.href = `${config.server.host}:${config.server.port}/home`;
            },
            async initializeValues() {
                if (!this.getPotentialOrganization.name) {
                    await this.$store.dispatch('signUpOrganization/loadPotentialOrganization');
                }
                this.hasInvoiceData = this.getPotentialOrganization.hasInvoiceData;
                this.physicalPerson = this.getPotentialOrganization.isPhysicalPerson;
                this.selected = !this.getPotentialOrganization.isPhysicalPerson;
                this.showRfc = this.getPotentialOrganization.isPhysicalPerson;
                this.rfc = this.getPotentialOrganization.rfc;
                this.validateStep();
            },
            validateStep() {
                let isValid = true;

                if (!this.hasInvoiceData && this.showRfc) {
                    isValid = !this.$v.rfc.$error && this.rfc;
                } else {
                    isValid = this.getPotentialOrganization.invoiceData.name &&
                        this.getPotentialOrganization.invoiceData.lastName &&
                        this.getPotentialOrganization.invoiceData.streetAddress &&
                        this.getPotentialOrganization.invoiceData.exteriorNumber &&
                        this.getPotentialOrganization.invoiceData.district &&
                        this.getPotentialOrganization.invoiceData.city &&
                        this.getPotentialOrganization.invoiceData.state &&
                        this.getPotentialOrganization.invoiceData.zipCode &&
                        this.getPotentialOrganization.invoiceData.officialId;
                }

                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_TWO`, isValid);
            },
            timeChangeHandler(time) {
                this.defaultHour = time.hour;
                this.defaultMinute = time.minute;
            },
            changeCountry(event) {
                this.countryPhone = event.iso2;
            },
            phoneValidator() {
                // eslint-disable-next-line no-useless-escape
                if (/^[\+]?[(]?[0-9]{3,5}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.phone.replace(/\s+/g, ""))) {
                    this.clazzPhone = "";
                    return true;
                }
                this.clazzPhone = "has-error";
                return false;

            },
            userValidation() {
                return this.getActualUser._id === undefined ? false : true;
            },
            agendUserCall() {
                this.loadingAgenda = true;
                if (this.phoneValidator() && this.userValidation()) {
                    const dataAgend = {
                        userId: this.getActualUser._id,
                        phone: this.phone.replace(" ", ""),
                        countryPhone: this.countryPhone
                    };

                    if (this.callDate !== "") {
                        dataAgend.callDate = this.callDate;
                    }
                    if (this.defaultHour !== this.defaultTime.getHours() ||
                        this.defaultMinute !== this.defaultTime.getMinutes()) {
                        dataAgend.callTime = new Date();
                        dataAgend.callTime.setHours(this.defaultHour);
                        dataAgend.callTime.setMinutes(this.defaultMinute);
                    }
                    dataAgend.hour = this.defaultHour;
                    dataAgend.minute = this.defaultMinute;
                    this.$store.dispatch(`${storeModule}/agendUserCall`, dataAgend)
                        .then(
                            (response) => {
                                if (!response.error) {
                                    this.successCallScheduled = true;
                                    this.loadingAgenda = false;
                                }
                            }
                        ).catch(() => {
                        this.loadingAgenda = false;
                        this.displayNotificationError("Hubo un problema al agendar la llamada, por favor contacta a soporte.");
                    });
                } else {
                    this.displayNotificationError("Por favor complete los campos");
                    this.loadingAgenda = false;
                }
            }
        },
        validations: {
            rfc: {
                required: helpers.withParams(
                    {message: 'Ingrese un RFC válido'},
                    required
                ),
                rfc: RFC
            },
        },
        mounted() {
            this.initializeValues();
        }
    }
</script>
