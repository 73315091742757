<template>
  <div class="loading-info">
    <div class="loading" v-bind:class="clazz">
      <div class="text-align-c">
        <svg class="svg-circle-load" id="load" x="0px" y="0px" viewBox="0 0 150 150">
          <circle id="loading-inner" class="no-cpu" cx="75" cy="75" r="60" />
        </svg>
      </div>
      <p>{{label}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSimple",
  props: {
    clazz: String,
    label: String
  }
};
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
.loading-info {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    margin: 0 auto;
    width: 100%;

    > p {
      text-align: center;
      font-size: 14px;
      margin-top: 20px;
      font-weight: 600;
    }
  }

  #load {
    width: 70px;
    animation: loading 3s linear infinite;
    #loading-inner {
      stroke: {
        dashoffset: 0;
        dasharray: 300;
        width: 10;
        miterlimit: 10;
        linecap: round;
      }
      animation: loading-circle 2s linear infinite;
      fill: transparent;
    }
  }

  .big-load {
    #load {
      width: 110px;
    }

    p {
      font-size: 16px;
    }
  }

  .medium-load {
    #load {
      width: 60px;
    }
  }

  .small-load {
    #load {
      width: 25px;
    }

    p {
      font-size: 11px;
      margin-top: 3px;
    }
  }

  .no-text {
    > p {
      display: none;
    }
  }
}

.fullpage-loading {
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>
