import SigninPackMixin from "@/mixins/signinPack.mixin.js";

export default {
    mixins: [SigninPackMixin],
    data () {
        return {
            passwordMatchAlert: false
        }
    },
    methods: {
        getPositivePoints (password) {
            const containsLowercase = /[a-z]/.test(password);
            const containsNumber = /\d/.test(password);
            const containsUppercase = /[A-Z]/.test(password);
            const containsSimbols = /[.?¿!¡*_=+&%$#@]/.test(password);
            let securityLevel = 0;

            if (password.length >= 6) {
                securityLevel++;
            }
            if (containsLowercase) {
                securityLevel++;
            }
            if (containsUppercase) {
                securityLevel++;
            }
            if (containsNumber) {
                securityLevel++;
            }
            if (containsSimbols) {
                securityLevel++;
            }

            return securityLevel;
        },
        onPasswordChangeRegister (password) {
            this.registerPassword = password;
            const positivePoints = this.getPositivePoints(password);

            if (positivePoints === 0) {
                this.clazzPasswordStatus = "";
                this.labelPasswordStatus = "";
                this.showStatusBar = false;
            } else if (positivePoints === 2) {
                this.clazzPasswordStatus = "weak";
                this.labelPasswordStatus = "Muy débil";
                this.showStatusBar = true;
            } else if (positivePoints === 3) {
                this.clazzPasswordStatus = "regular";
                this.labelPasswordStatus = "Regular";
                this.showStatusBar = true;
            } else if (positivePoints === 4) {
                this.clazzPasswordStatus = "good";
                this.labelPasswordStatus = "Buena";
                this.showStatusBar = true;
            } else if (positivePoints === 5) {
                this.clazzPasswordStatus = "excellent";
                this.labelPasswordStatus = "Excellente";
                this.showStatusBar = true;
            }
        }
    }
};
