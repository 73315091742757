export const IDENTIFICATION = "IDENTIFICATION";
export const LEGAL = "LEGAL";
export const BANKING = "BANKING";
export const FISCAL = "FISCAL";
export const RESIDENCY = "RESIDENCY";

export const values = [
    IDENTIFICATION,
    LEGAL,
    BANKING,
    FISCAL,
    RESIDENCY,
];

export function icon(type) {
    switch(type) {
        case IDENTIFICATION: return 'banana-profile';
        case LEGAL: return 'banana-profile';
        case RESIDENCY: return 'banana-home';
        case BANKING:
        default: return 'banana-document-normal';
    }
}
