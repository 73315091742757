<template>
    <div class="toggle-switch">
        <div class="switch-border"></div>
        <input v-bind:id="id" type="checkbox" hidden="hidden" v-model="switchValueComp" :true-value="true"
               :false-value="false" value="" @change="onBasicSwitchChange" :disabled="disabled">
        <label v-bind:for="labelFor" class="ts-helper"></label>
    </div>
</template>

<script>
    export default {
      name: 'BasicSwitch',
      data(){
          return{
              switchValueComp: this.useVModel ? this.value : this.switchValue
          }
      },
      props: {
        id: String,
        labelFor: String,
        switchValue: Boolean,
        value: Boolean,
        useVModel: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
      },
        methods: {
            onBasicSwitchChange(){
                this.$emit('onBasicSwitchChange', this.switchValueComp)
                this.$emit('input', this.switchValueComp)
            },
            forceFalse(){
                this.switchValueComp = false
            },
            forceValue(value){
                this.switchValueComp = value;
            }
        }

    }
</script>
