<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <FloatingTitleLayout
                        :msgSmall="$t('api-configuration.smallMessage')"
                        :title="$t('api-configuration.title')"
                />
            </div>

            <div class="col-12">
                <CardSimple
                        :title="$t('api-configuration.card-title')"
                        :subtitle="$t('api-configuration.card-subtitle')"
                >
                    <div class="row max-w-786px">
                        <div class="col-12 col-md-6 m-b-30">
                            <CardCampaign
                                :imgUrl="imgApi"
                                :title="$t('api-configuration.api-card.title')"
                                :text="$t('api-configuration.api-card.subtitle')"
                                :btnText="$t('api-configuration.cards-button')"
                                v-on:clickButton="goToView('api-rest')"
                            ></CardCampaign>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <CardCampaign
                                    :imgUrl="imgBtnCheckout"
                                    :title="$t('api-configuration.checkout-card.title')"
                                    :text="$t('api-configuration.checkout-card.subtitle')"
                                    :btnText="$t('api-configuration.cards-button')"
                                    v-on:clickButton="goToView('apiWeb/api-select-checkout')"
                            ></CardCampaign>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <div class="layer-disabled"></div>
                            <CardCampaign
                                :imgUrl="imgFormulario"
                                :title="$t('api-configuration.formulario-card.title')"
                                :text="$t('api-configuration.formulario-card.subtitle')"
                                btnText="Próximamente"
                                v-on:clickButton="goToView('api-list')"
                            ></CardCampaign>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>
    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import CardCampaign from '@/components/CardCampaign.vue';
    import imgApi from '@/assets/images/Icons/icon-code-lap.svg';
    import imgFormulario from '@/assets/images/Icons/icon-form.svg';
    import imgBtnCheckout from '@/assets/images/Icons/icon-checkout.svg';

    export default {
        name: "ApiList",
        components: {
            FloatingTitleLayout,
            CardSimple,
            CardCampaign
        },
        data() {
            return {
                imgApi: imgApi,
                imgFormulario: imgFormulario,
                imgBtnCheckout: imgBtnCheckout
            }
        },
        methods: {
            /*
            This function change the current view to the desired view
             */
            goToView(desiredRoute) {
                this.$router.push(desiredRoute)
            }
        }
    }
</script>

