export const webClient = 'WEB_CLIENT';
export const mobileAndroid = 'MOBILE_ANDROID';
export const mobileIOS = 'MOBILE_IOS';
export const unknown = 'UNKNOWN';
export const internalRequest = 'INTERNAL_REQUEST';
export const values = [
    webClient,
    mobileAndroid,
    mobileIOS,
    unknown,
    internalRequest,
];
