<template>
    <div id="" class="title-number-container" :class="clazz">
        <div class="vertical-center">
            <h1 class="title-number"><span>{{num}}</span> {{title}} </h1>
            <span class="title-extra-content">
                <slot name="extraContent"></slot>
            </span>
        </div>
        <label class="subtitle"> {{subtitle}} </label>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'TitleNumber',
        props: {
            clazz: String,
            num: String,
            title: String,
            subtitle: String
        }
    }
</script>

<style lang="scss">
.title-number-container {
    margin-bottom: 30px;

    > div {
        margin-bottom: 10px;
    }

    .title-number {
        font-size: 20px;
        font-weight: bold;
        line-height: 1;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0px;

        > span {
            width: 30px;
            height: 30px;
            min-width: 30px;
            border-radius: 50%;
            font-size: 20px;
            font-weight: bold;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            padding-top: 3px;
        }
    }

    .title-extra-content {
        margin: 0 0 0 auto;
    }

    .subtitle {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        display: block;
        margin-bottom: 0;
        padding-left: 40px;
    }
}
</style>
