<template>
    <div class="col-12">
        <b-modal id="modal-fullUpload" ref="modal-fullUpload" @hide="initializeModalProgress" hide-header hide-footer
                 no-close-on-esc hide-header-close>
            <div class="p-20">
                <ProgressCircle :filename="imageName" :filesize="imageSize" v-if="loadingImage">
                    <radial-progress-bar :diameter="diam" :totalSteps="totalPrct" :completedSteps="completedPrct"
                                         startColor="#c16bf0" stopColor="#6992ea" innerStrokeColor="#FFF"
                                         :strokeWidth="strokeW" :animateSpeed="2000" timingFunc="ease-in-out"><p
                            class="percentage"><strong>{{completedPrct}}</strong>%</p></radial-progress-bar>
                </ProgressCircle>

                <ProgressCircle progressDone :filename="imageName" :filesize="imageSize"
                                v-if="uploadSuccess"></ProgressCircle>

                <ProgressCircle progressRetry v-if="uploadFailed"></ProgressCircle>
            </div>
        </b-modal>

        <div class="w-100">
            <UploadBox :titleBox="$t('organizaciones.images.logo.label') + ':'"
                       :showLoadBox="!hasLogo"
                       clazzContainer="box-logo-container m-b-0"
                       fileType="no-img-back"
                       @change="logoUploaded($event)"
            >
                <template v-slot:imagePreview>
                    <div v-if="hasLogo" class="img-card">
                        <img class="img-fluid logo" :src="imageToDisplay" alt="Logotipo"/>
                    </div>
                </template>

                <template v-slot:button>
                    <div class="col-12">
                        <FileUploadButton
                                id="button_upload_logo"
                                :text="$t('organizaciones.images.logo.input-place-holder')"
                                _ref="uploadLogoRef"
                                :_class="'button xs button-stroke button-accent2 p-relative w-100 m-b-15'"
                                icon="banana banana-camera"
                                :accept="FILES.MIME_TYPES.ACCEPT_IMAGE"
                                v-if="!readOnly"
                                ref="fileUpload"
                                @change="logoUploaded($event)"
                        >
                        </FileUploadButton>
                    </div>
                </template>
                <template v-slot:noteText>
                    <SimpleNote clazz="info" icon="banana-info-circle1">
                        <p class="m-b-0"> {{$t('organizaciones.images.logo.info')}}</p>
                    </SimpleNote>
                </template>
            </UploadBox>
        </div>

        <ModalDefault :modalId="modalCropImageId" :customRef="modalCropImageId" icon="banana-move"
                      :originalFooter="false" size="lg" :title="$t('organizaciones.images.logo.modal.title')"
                      :subtitle="$t('organizaciones.images.logo.modal.subtitle')">
            <div>
                <CropImage clazz="container-lg logo m-b-0" :ref="imageCroppieRef" @crop="croppieResult"></CropImage>
            </div>
            <template v-slot:customFooter>
                <FileUploadButton
                        :id="'button_upload_logo'"
                        :text="$t('organizaciones.images.logo.modal.input-place-holder')"
                        _ref="uploadLogoRef"
                        :accept="FILES.MIME_TYPES.ACCEPT_IMAGE"
                        @change="logoUploaded($event)"
                        @click="closeModal()"
                        :kind="BUTTONS.KINDS.STROKE"
                        :color="BUTTONS.COLORS.ACCENT1"
                        :shape="BUTTONS.SHAPES.BUTTON"
                        :size="BUTTONS.SIZES.EXTRA_SMALL"
                        :icon="ICONS._EMPTY"
                        ref="fileUploadAfter"
                        divClass="m-0-auto-0-0"
                        _class="m-0-auto-0-0"></FileUploadButton>
                <BaseButton
                        type="button"
                        id="modal_crop_image_cancel"
                        _class="m-r-20"
                        :text="$t('general.actions.cancel.title')"
                        :shape="BUTTONS.SHAPES.BUTTON"
                        :kind="BUTTONS.KINDS.GHOST"
                        :color="BUTTONS.COLORS.PLAIN_TEXT"
                        :size="BUTTONS.SIZES.EXTRA_SMALL"
                        @click="closeModal()"></BaseButton>
                <BaseButton
                        type="button"
                        id="modal_crop_image_save"
                        :text="$t('general.actions.save')"
                        :shape="BUTTONS.SHAPES.BUTTON"
                        :kind="BUTTONS.KINDS.FILLED"
                        :color="BUTTONS.COLORS.ACCENT1"
                        :size="BUTTONS.SIZES.EXTRA_SMALL"
                        @click="cropImage"
                        v-if="!savingCropped"></BaseButton>

                <button v-if="savingCropped" type="button" class="button xs button-filled button-accent1">
                    <LoadingSimple clazz="small-load no-text"/>
                </button>
            </template>
        </ModalDefault>
    </div>
</template>

<script>
    import FileUploadButton from '@/components/signUpOrganizations/file/FileUploadButton.vue';
    import UploadBox from '@/components/UploadBox';
    import ModalDefault from '@/components/ModalDefault';
    import LoadingSimple from '@/components/LoadingSimple';
    import SimpleNote from '@/components/SimpleNote';
    import CropImage from '@/components/CropImage';
    import ProgressCircle from '@/components/ProgressCircle';
    import BaseButton from '@/components/signUpOrganizations/base/BaseButton';
    import {mapGetters, mapState} from 'vuex';
    import RadialProgressBar from 'vue-radial-progress';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';

    import images from '@/common/images';
    import FILES from '@/common/consts/files.consts';
    import ICONS from '@/common/consts/icons.consts';
    import BUTTONS from '@/common/consts/buttons.consts';

    import {EventBus} from "@/main";

    const async = require('async');

    const storeModule = "signUpOrganization";
    const imageCroppieRef = "image-croppie";

    const MIN_IMAGE_WIDTH = 400;
    const MIN_IMAGE_HEIGHT = 400;

    const IMAGE_MAX_SIZE = 300000; // 300KB
    const imageClassification = "organization/logo";

    export default {
        name: "UploadLogo",
        data() {
            return {
                FILES,
                ICONS,
                BUTTONS,
                logoFile: null,
                logoUrl: '',
                modalCropImageButtonId: 'modal_crop_image_button',
                modalCropImageId: 'modal_crop_image',
                imageCroppieRef: imageCroppieRef,
                logoImage: {
                    name: "",
                    size: "",
                    image: "",
                    type: ""
                },
                imageUploaded: true,
                savingCropped: false,
                imageClassification: imageClassification,
                imageNamePrefix: "logo",
                loadingImage: false,
                diam: 120,
                totalPrct: 100,
                completedPrct: 0,
                strokeW: 4,
                uploadSuccess: false,
                uploadFailed: false,
                imageName: "",
                imageSize: ""
            }
        },
        components: {
            FileUploadButton,
            CropImage,
            BaseButton,
            UploadBox,
            SimpleNote,
            ModalDefault,
            LoadingSimple,
            ProgressCircle,
            RadialProgressBar
        },
        mixins: [
            ToastSnotify
        ],
        props: {
            useVuex: {type: Boolean, default: true},
            urlImagePreview: {type: String, default: null},
            readOnly: {type: Boolean, default: false}
        },
        methods: {
            // eslint-disable-next-line sonarjs/cognitive-complexity
            logoUploaded(files) {
                if (!files || !files[0]) {
                    this.loadingImage = false;
                    this.uploadFailed = true;
                    return;
                }
                const logoFile = files[0];
                this.imageName = logoFile.name; // Solo para mostrar en el radial-progress
                this.imageSize = `${logoFile.size / 1000} KB`; // Solo para mostrar en el radial-progress
                const logoUrl = URL.createObjectURL(logoFile);
                const img = new Image();
                const _this = this;
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;
                    const size = logoFile.size;
                    const fileType = logoFile.type;

                    this.loadingImage = true;
                    this.$refs['modal-fullUpload'].show();
                    setTimeout(() => {
                        if (width < MIN_IMAGE_WIDTH || height < MIN_IMAGE_HEIGHT) {
                            this.loadingImage = false;
                            this.uploadFailed = true;
                            this.displayNotificationWarning(this.$t('organizaciones.images.logo.errors.width-height'))
                            if (this.$refs.fileUpload !== undefined) {
                                this.$refs.fileUpload.clearFiles();
                            }
                            if (this.$refs.fileUploadAfter !== undefined) {
                                this.$refs.fileUploadAfter.clearFiles()
                            }
                            return;
                        }
                        this.completedPrct = 5;
                        setTimeout(() => {
                            if (size > IMAGE_MAX_SIZE) {
                                this.loadingImage = false;
                                this.uploadFailed = true;
                                this.displayNotificationWarning(this.$t('organizaciones.images.logo.errors.size'))
                                if (this.$refs.fileUpload !== undefined) {
                                    this.$refs.fileUpload.clearFiles();
                                }
                                if (this.$refs.fileUploadAfter !== undefined) {
                                    this.$refs.fileUploadAfter.clearFiles()
                                }
                                return;
                            }
                            this.completedPrct = 30;
                            setTimeout(() => {
                                if (!FILES.MIME_TYPES.IMAGE.includes(fileType)) {
                                    this.loadingImage = false;
                                    this.uploadFailed = true;
                                    this.displayNotificationWarning(this.$t('organizaciones.images.logo.errors.format'));
                                    if (this.$refs.fileUpload !== undefined) {
                                        this.$refs.fileUpload.clearFiles();
                                    }
                                    if (this.$refs.fileUploadAfter !== undefined) {
                                        this.$refs.fileUploadAfter.clearFiles()
                                    }
                                    return;
                                }
                                this.completedPrct = 60;
                                setTimeout(() => {
                                    _this.logoImage.size = logoFile.size;
                                    _this.logoImage.type = logoFile.type;

                                    _this.logoImage.name = `${this.imageNamePrefix}-${Math.floor(Math.random() * 100000)}`;
                                    _this.logoImage.classification = this.imageClassification;
                                    _this.imageUploaded = true;
                                    _this.logoFile = logoFile;
                                    _this.logoUrl = logoUrl;
                                    this.completedPrct = 80;
                                    setTimeout(() => {
                                        if (this.imageName.length > 20) {
                                            this.loadingImage = false;
                                            this.uploadFailed = true;
                                            if (this.$refs.fileUpload !== undefined) {
                                                this.$refs.fileUpload.clearFiles();
                                            }
                                            if (this.$refs.fileUploadAfter !== undefined) {
                                                this.$refs.fileUploadAfter.clearFiles()
                                            }
                                            this.displayNotificationWarning(this.$t('organizaciones.images.logo.errors.image-name'));
                                            return;
                                        }
                                        this.completedPrct = 95;
                                        _this.initCropImage(logoUrl);
                                    }, 1300);
                                }, 1100);
                            }, 900);
                        }, 700);
                    }, 500);

                };
                img.src = logoUrl;

            },
            closeModal() {
                EventBus.$emit(`MODAL-CLOSE-${this.modalCropImageId}`)
            },
            initCropImage(url) {
                this.completedPrct = 100;
                this.loadingImage = false;
                this.$refs['modal-fullUpload'].hide();
                EventBus.$emit(`MODAL-OPEN-${this.modalCropImageId}`);
                // Esperar a que aparezca el modal para que muestre la referencia del componente CropImage
                setTimeout(() => {
                    this.$refs[imageCroppieRef].initCroppie(url);
                }, 100);
            },
            cropImage() {
                this.savingCropped = true;
                this.$refs[imageCroppieRef].crop();
            },
            croppieResult(result) {
                const _this = this;
                _this.logoImage.image = result;
                _this.imageStatus = true;
                _this.updateStoreAndPrepareToFinish()
            },
            updateStoreAndPrepareToFinish() {
                const _this = this;
                let urlMode = false;
                let logoFullSize;

                const reader = new FileReader();
                const readerCropped = new FileReader();

                if (!_this.logoFile) {
                    urlMode = true;
                    logoFullSize = {};
                    logoFullSize.name = this.logo.fullSize.name;
                    logoFullSize.type = this.logo.fullSize.type;
                    logoFullSize.size = this.logo.fullSize.size;
                } else {
                    logoFullSize = _this.logoFile;
                }

                const logoCropped = new File([_this.logoImage.image], `cropped_${logoFullSize.name}`, {
                    lastModified: new Date().getTime(),
                    type: _this.logoImage.type,
                });

                const tasks = {};

                if (!urlMode) {
                    reader.readAsDataURL(logoFullSize);
                    tasks.fullSize = function (callback) {
                        reader.onload = () => {
                            callback(null, reader.result);
                        };
                    };
                }

                readerCropped.readAsDataURL(logoCropped);
                tasks.cropped = function (callback) {
                    readerCropped.onload = () => {
                        callback(null, readerCropped.result);
                    };
                };

                async.parallel(
                    tasks,
                    function (error, results) {
                        const params = {};
                        if (!urlMode) {
                            params.logoFullSize = {
                                name: logoFullSize.name,
                                type: logoFullSize.type,
                                size: logoFullSize.size,
                                image: results.fullSize,
                            };
                        }
                        params.logoCropped = {
                            name: logoCropped.name,
                            type: logoCropped.type,
                            size: logoCropped.size,
                            image: _this.logoImage.image
                        };
                        _this.$emit('finish', params);
                        if (_this.useVuex) {
                            _this.$store.dispatch(`${storeModule}/stepLogoUpdateLogo`, params).then((/* response*/) => {
                                setTimeout(() => {
                                    _this.savingCropped = false;
                                    _this.closeModal();
                                }, 1000);
                            }).error((message, interMessage) => {
                                if (interMessage) {
                                    this.displayNotificationWarning(message)
                                } else {
                                    this.displayNotificationWarning(this.$i18n.t(message))
                                }

                            });
                        } else {
                            _this.savingCropped = false;
                            _this.closeModal();
                        }
                    });

            },
            updateLogoImageStatus() {
                if (this.useVuex) {
                    this.$store.dispatch(`${storeModule}/updateLogoImageStatus`, this.imageUploaded);
                }
                this.$emit('change');
            },
            initializeModalProgress() {
                this.loadingImage = false;
                this.uploadFailed = false;
                this.uploadSuccess = false;
                this.completedPrct = 0;
            }

        },
        computed: {
            ...mapGetters(storeModule, ['getPotentialOrganization', 'getErrorNextStep', 'getErrorNextStepMessage']),
            ...mapState({
                name: state => state[storeModule].actualPotentialOrganization.name,
                logo: state => state[storeModule].actualPotentialOrganization.logo
            }),
            hasPattern() {
                return this.pattern !== undefined && this.pattern !== null && this.pattern.kind !== null;
            },
            hasLogo() {
                return !!this.urlImagePreview || (this.logo !== undefined ? !!this.logo.fullSize : false);
            },
            logoFullSizeSrc() {
                return images.getImageUrl(this.logo.fullSize);
            },
            logoCroppedSrc() {
                return images.getImageUrl(this.getPotentialOrganization.logo.cropped);
            },
            nameFirstLetter() {
                if (!this.getPotentialOrganization.name || !this.getPotentialOrganization.name.length) {
                    return '';
                }
                return this.getPotentialOrganization.name.substr(0, 1);
            },
            patternText() {
                if (this.pattern && this.pattern.text) {
                    return this.pattern.text;
                }
                return this.nameFirstLetter;
            },
            imageToDisplay() {
                return this.urlImagePreview || this.logoCroppedSrc;
            }
        },
        mounted() {
            if (!this.hasPattern) {
                const params = {
                    pattern: images.getRandomImagePattern(this.patternText)
                };
                if (this.useVuex) {
                    this.$store.dispatch(`${storeModule}/stepLogoUpdatePattern`, params)
                }
                this.$emit('pattern', params);
            }
        }
    };

</script>
