<template>
    <div :class="clazz">
        <carousel
            :perPageCustom="itemsCarousel"
            :mouse-drag="false"
            navigationEnabled
            navigationNextLabel=" "
            navigationPrevLabel=" "
            :navigateTo="startPage"
        >
            <slot></slot>
        </carousel>
    </div>
</template>

<script>
export default {
    name: "SimpleCarousel",
    props: {
        clazz: String,
        itemsCarousel: Array,
        startPage: {
            type: Number, default: 0
        }
    }
};
</script>

