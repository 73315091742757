<template>
    <div class="floating-title-layout" v-bind:class="clazz">
        <h1 :class="titleClass">
            <small>{{ msgSmall }}</small>
            {{ title }}
        </h1>

        <slot name="buttonsLeft"></slot>

        <div class="buttons">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FloatingTitleLayout',
        props: {
            msgSmall: String,
            title: String,
            clazz: String,
            titleClass: String
        }
    }
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
    .floating-title-layout {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 40px;

        > h1 {
            font-size: 26px;
            font-weight: bold;
            line-height: 1.15;
            display: block;
            margin: 0 auto 0 0;
            padding-right: 40px;
            width: fit-content;

            small {
                opacity: 0.5;
                font-size: 13px;
                font-weight: bold;
                line-height: 1.31;
                display: block;
                margin-bottom: 2px;
            }
        }

        > .buttons {
            margin: 0 0 0 auto;
            justify-content: flex-end;
            display: flex;
            flex-wrap: wrap;
        }
    }
</style>
