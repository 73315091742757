<template>
  <div>
    <b-modal :id="modalId" :ref="customRef" :size="size" modal-class="modal-default" no-close-on-esc no-close-on-backdrop :content-class="clazz">
      <template v-slot:modal-header>
        <button class="close-modal" @click="onClickClose();hideModal()"><i aria-hidden="true" class="banana banana-cross-normal"></i></button>
        <h1><i aria-hidden="true" v-if="icon" class="banana" :class="icon"></i>{{title}}</h1>
        <label>{{subtitle}}</label>
      </template>

      <template v-slot:default>
        <slot></slot>
      </template>

      <template v-slot:modal-footer>
          <div v-if="originalFooter" class="w-100">
            <button v-if="!hideCancelButton"
             :id="closeModalDef" class="button xs button-ghost button-plain_text"
             @click="onClickClose();hideModal()">{{buttonClose}}</button>
            <button v-if="!hideAcceptButton"
            :id="actionModalDef" class="button xs button-filled button-accent1"
            @click="onClickAccept()" :disabled="disabled">{{buttonText}}</button>
          </div>
          <div v-if="!originalFooter" class="custom-footer">
              <slot name="customFooter"></slot>
          </div>
      </template>
    </b-modal>
  </div>
</template>


<script>
  import {EventBus} from "@/main";

  export default {
      name: 'ModalDefault',
      props: {
          modalId: String,
          title: String,
          subtitle: String,
          icon: String,
          buttonClose: {type: String, default: 'Cerrar'},
          buttonText: String,
          disabled: {type: Boolean, default: false},
          originalFooter: {type: Boolean, default: false},
          size: String,
          closeModalAfterAccept: {
            type: Boolean,
            default: true
          },
          customRef: {
            type: String,
            default: "modalDefault"
          },
          actionModalDef: String,
          closeModalDef: String,
          clazz: String,
          hideAcceptButton: {
            type: Boolean,
            default: false,
          },
          hideCancelButton: {
            type: Boolean,
            default: false,
          }
      },
      methods: {
          hideModal() {
              this.$refs[this.customRef].hide();
          },
          showModal() {
              this.$refs[this.customRef].show()
          },
          onClickAccept(){
            this.$emit('clickAccepted');
            if(this.closeModalAfterAccept){
              this.$refs[this.customRef].hide();
            }

          },
          onClickClose(){
            this.$emit('clickClose');
          }
      },
      created(){
        EventBus.$on(`MODAL-OPEN-${this.customRef}`, ()=>{
            this.$refs[this.customRef].show()
        });
        EventBus.$on(`MODAL-CLOSE-${this.customRef}`, ()=>{
            this.$refs[this.customRef].hide()
        });
      },
      mounted(){
      },

      beforeDestroy(){
        EventBus.$off(`MODAL-OPEN-${this.customRef}`);
        EventBus.$off(`MODAL-CLOSE-${this.customRef}`);
      }
  }
</script>
