<template>
    <div :class="wrapperClass">
        <div :class="inputWrapperClass" v-for="o in options" :key="o.value">
            <RadioInput
                :id="id"
                :for="id"
                :text="o.label"
                :value="o.value"
                @selected="selected(o)"
                :checked="value === o.value"
            >
                <template v-if="$scopedSlots.inputBody">
                    <slot
                        name="inputBody"
                        v-bind="o"
                    ></slot>
                </template>
            </RadioInput>
        </div>
    </div>
</template>
<script>
import RadioInput from "@/components/RadioInput.vue";
export default {
    name: 'RadioInputGroup',
    components: {
        RadioInput
    },
    props: {
        wrapperClass: {
            type: String,
            default: ''
        },
        inputWrapperClass: {
            type: String,
            default: 'float-left m-r-30'
        },
        id: {
            type: String,
            default: 'radio'
        },
        value: {
            type: [Number, String, Object, Boolean],
            default: null,
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        selected({value}) {
            this.$emit('input', value);
        }
    }
}
</script>
