<template>
    <div class="container-right">
        <img class="img-fluid max-h-300px d-flex m-0-auto"
            src="@/assets/images/Illustrations/illustration-psw.svg"
            width="520"
            height="240"
            alt="Crea una contraseña"/>
        <TitlePrincipal
            :title="$t('hire.password.title')"
            clazz="text-center m-t-20">
            <p
                class="subtitle"
                v-html="$t('hire.password.subtitle-html')"
            ></p>
        </TitlePrincipal>
        <PasswordInput
            required
            inputType="password"
            labelFor=""
            inputId="password"
            :label="$t('hire.password.password.label')"
            :inputPlaceholder="$t('hire.password.password.placeholder')"
            v-model="password"
            @passwordStrengthChange="passwordStrengthChange"
        ></PasswordInput>
        <PasswordInput
            required
            inputType="password"
            labelFor=""
            inputId="password-confirm"
            :label="$t('hire.password.password-confirm.label')"
            :inputPlaceholder="$t('hire.password.password-confirm.placeholder')"
            v-model="confirmPassword"
        ></PasswordInput>

        <b-alert class="alert-note" show variant="info">
            <p class="text-container c-text_title f-10 d-block">
                {{$t('hire.password.tips')}}
                <ul class="m-t-5 m-0 p-0 list-style-none">
                    <li
                        class="f-10 poppins-font-semibold line-height-1 m-b-5 d-flex"
                        :class="{'c-info': passwordLongerThan6, 'c-error': !passwordLongerThan6}"
                    >
                        <i
                            aria-hidden="true"
                            class="banana f-12 m-r-10"
                            :class="{'banana-check-normal': passwordLongerThan6, 'banana-cross-normal': !passwordLongerThan6}"
                        ></i>
                        {{$t('hire.password.tip1')}}
                    </li>
                    <li
                        class="f-10 poppins-font-semibold line-height-1 m-b-5 d-flex"
                        :class="{'c-info': passwordContainsNumber, 'c-error': !passwordContainsNumber}"
                    >
                        <i
                            aria-hidden="true"
                            class="banana f-12 m-r-10"
                            :class="{'banana-check-normal': passwordContainsNumber, 'banana-cross-normal': !passwordContainsNumber}"
                        ></i>
                        {{$t('hire.password.tip2')}}
                    </li>
                    <li
                        class="f-10 poppins-font-semibold line-height-1 m-b-5 d-flex"
                        :class="{'c-info': passwordContainsUppercase, 'c-error': !passwordContainsUppercase}"
                    >
                        <i
                            aria-hidden="true"
                            class="banana f-12 m-r-10"
                            :class="{'banana-check-normal': passwordContainsUppercase, 'banana-cross-normal': !passwordContainsUppercase}"
                        ></i>
                        {{$t('hire.password.tip3')}}
                    </li>
                </ul>
            </p>
        </b-alert>
        <b-alert class="alert-note" show variant="info">
            <p class="text-container">
                <i
                    aria-hidden="true"
                    class="icon banana banana-comment"
                    ></i>
                <span v-html="$t('hire.password.reminder-html')"></span>
            </p>
        </b-alert>

        <div class="footer-buttons right">
            <button
                type="button"
                class="button button-ghost button-plain_text"
                name="Regresar"
                @click="returnClicked"
                v-text="$t('general.actions.back.title')"
            ></button>
            <button
                type="button"
                class="button button-filled button-primary"
                name="Continuar"
                :disabled="!canSave"
                @click="save"
                v-text="$t('general.actions.continue.title')"
            ></button>
        </div>

        <LoadingBar
            clazz="fullcontent-loading"
            v-if="loading"
        />
    </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import OrganizationApi from "@/api/organization/organizations.api.js";
import LoadingBar from "@/components/LoadingBar.vue";

export default {
    name: 'WalletHirePassword',
    components: {
        TitlePrincipal,
        PasswordInput,
        LoadingBar,
    },
    data() {
        return {
            loading: false,
            password: null,
            confirmPassword: null,
            passwordStrength: {
                id: 0,
                value: 'too weak',
                contains: [],
                length: 0,
            },
        };
    },
    computed: {
        passwordLongerThan6() {
            return this.passwordStrength.length >= 6;
        },
        passwordContainsNumber() {
            return !!this.passwordStrength.contains.find((c) => c === 'number')
        },
        passwordContainsUppercase() {
            return !!this.passwordStrength.contains.find((c) => c === 'uppercase')
        },
        canSave() {
            return this.passwordStrength.id > 0 && this.password === this.confirmPassword;
        },
    },
    methods: {
        passwordStrengthChange(value) {
            this.passwordStrength = value;
        },
        returnClicked() {
            this.$emit('returnClicked');
        },
        async save() {
            try {
                this.loading = true;
                const payload = {
                    password: this.password,
                    confirmPassword: this.confirmPassword,
                };
                await OrganizationApi.hirePassword(payload);
                this.$emit('finished', {password: this.password});
            } catch(error) {
                this.toastErrorDefault(error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
