<template>
    <!-- DOCUMENTATION - SidebarSide Customizable (Right or Left)

        sidebarLeft:         Shows sidebar side type LEFT (Boolean).
        isSidebarLeftOpen:   To add class 'show' to sidebar LEFT, when click to open (Boolean).
        titleLeft:           Text title for sidebar LEFT (String).
        subtitleLeft:        Text subtitle for sidebar LEFT (String).
        v-slot:headLeft:     Head slot content, for sidebar LEFT.
        v-slot:bodyLeft:     Body slot content, for sidebar LEFT.
        v-slot:footerLeft:   Footer slot content, for sidebar LEFT.

        sidebarRight:        Shows sidebar side type RIGHT (Boolean).
        isSidebarRightOpen:  To add class 'show' to sidebar RIGHT, when click to open (Boolean).
        v-slot:contentRight: Full slot content, for sidebar RIGHT.
    -->
    <aside id="sidebarSide" class="sidebar-side" :class="{ 'side-left' : sidebarLeft, 'side-right' : sidebarRight, 'show': isSidebarLeftOpen || isSidebarRightOpen }">
        <div v-if="sidebarLeft">
            <div class="sidebar-left-header">
                <h1>{{titleLeft}}</h1>
                <p>{{subtitleLeft}}</p>
                <br>
                <slot name="headLeft"></slot>
            </div>
            <div class="sidebar-left-body">
                <slot name="bodyLeft"></slot>
            </div>
            <div class="sidebar-left-footer">
                <slot name="footerLeft"></slot>
            </div>
        </div>
        <div v-if="sidebarRight">
            <slot name="contentRight"></slot>
        </div>
    </aside>
</template>

<script>
    export default {
        name: 'SidebarSide',
        components: {
        },
        props: {
            titleLeft: String,
            subtitleLeft: String,
            isSidebarLeftOpen: {type: Boolean, default: false},
            isSidebarRightOpen: {type: Boolean, default: false},
            sidebarLeft: {type: Boolean, default: false},
            sidebarRight: {type: Boolean, default: false}
        }
    }
</script>

<style lang="scss">
    .sidebar-side {
        height: 100%;
        max-height: 100vh;
        position: fixed;
        top: 0;
        transition: all 0.5s;

        &.side-left {
            width: 300px;
            left: -325px;
            padding: 30px 0;
            z-index: 3;
            box-shadow: 2px 0 4px 0 rgba(56, 73, 99, 0.2);
            border-left: 1px solid rgba(56, 73, 99, 0.15);

            &.show {
                left: 80px;
            }
        }

        &.side-right {
            width: 320px;
            right: -325px;
            padding: 70px 30px 20px;
            z-index: 1;
            box-shadow: 0 0 4px 0 rgba(56, 73, 99, 0.2);
            overflow-y: auto;

            &.show {
                right: 0;
            }
        }
    }
</style>
