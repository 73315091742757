const FILES = {
    MIME_TYPES: {
        IMAGE: [
            'image/png',
            'image/jpeg',
            'image/svg+xml',
        ]
    }
};

FILES.MIME_TYPES.ACCEPT_IMAGE = FILES.MIME_TYPES.IMAGE.join(',');

export default FILES;
