<template>
    <div class="row">
        <LoadingBar v-if="loading"></LoadingBar>
        <div
            class="col-12"
            v-for="doc in requiredDocumentsForFiscalSituation"
            :key="doc._id"
        >
            <UploadOrganizationFile
                :refreshing="refreshing"
                :ref="`UploadFile_${doc._id}`"
                :loadedFile="documentsLoadedById[doc._id]"
                :loadingFile="documentsLoading[doc._id] || documentsDeleting[doc._id]"
                :icon="FileDocumentType.icon(doc.fileDocumentType)"
                :title="doc.title"
                :text="doc.description"
                :note="doc.fileSpecs"
                :tooltipId="`tooltip_${doc._id}`"
                :id="doc._id"
                @input="fileSelect(doc, $event)"
                @delete="fileDelete(doc)"
                :downloadUrl="downloadUrl(doc)"
                :mimetype="fileMimeType(doc)"
                :acceptedFormats="doc.allowedFormats"
                :hideDelete="readOnly"
                :disabled="readOnly"
            >
                <template v-slot:tooltipContent>
                    <div>
                        <p class="f-13 body-font-regular c-info_text m-b-0">{{doc.toolTipText}}</p>
                    </div>
                </template>
            </UploadOrganizationFile>
        </div>
    </div>
</template>
<script>
import OrganizationApi from '@/api/organization/organizations.api.js';
import ToastSnotify from '@/mixins/toastSnotify.mixin';
import LoadingBar from '@/components/LoadingBar';
import UploadOrganizationFile from "@/components/UploadOrganizationFile.vue";
import * as FileDocumentType from '@/enums/fileDocumentType.enum';
import FilesApi from '@/api/files/files.api';
export default {
    name: "OrganizationDocuments",
    mixins: [ToastSnotify],
    components: {
        LoadingBar,
        UploadOrganizationFile,
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => {
                return {
                    requestedFiles: []
                }
            }
        },
        filterFunction: {
                type: Function,
                default: () => true
            }
    },
    data () {
        return {
            FileDocumentType,
            organization: {
                requestedFiles: []
            },
            documents: [],
            documentsLoading: {},
            documentsDeleting: {},
            documentsLoadedById: {},
            refreshing: false,
        }
    },
    computed: {
        invoiceData() {
            return this.organization.invoiceData || {};
        },
        requiredDocumentsForFiscalSituation() {
            if(!this.documents){
                return [];
            }
            return this.documents.filter(d => {
                if (
                    (d.personType === 'FISICA' && !this.invoiceData.isMoralPerson) ||
                    (d.personType === 'MORAL' && this.invoiceData.isMoralPerson) ||
                    (d.personType === 'BOTH'))
                {
                    return true;
                }
                return false;
            }).filter(this.filterFunction);

        },
    },
    methods: {
        handleError (error) {
            this.loading = false;
            this.toastErrorDefault(error);
        },
        fetchDocuments () {
            this.loading = true;
            OrganizationApi.requestedDocuments().then((data) => {
                this.loading = false;
                this.documents = data.data.object;
                this.allDocumentsReady();
            }).catch(this.handleError);
        },
        refreshDocuments(){
            setTimeout(()=>{
                if(!this.refreshing){
                    this.refreshing = true;
                    setTimeout(()=> {
                        this.refreshing = false;
                    },100);
                }
            }, 300);
        },
        loadedDocument(doc) {
            if (this.organization && this.organization.requestedFiles) {
                return this.organization.requestedFiles.find(d => {
                    return (d.fileDocument._id || d.fileDocument) === (doc._id || doc);
                });
            }
            return false;
        },
        downloadUrl(doc) {
            if (this.loadedDocument(doc) && !this.documentsDeleting[doc._id]) {
                this.setDocumentLoading(doc, false);
            }
            /*
                This function returns a function with no parametters so it can be called in the component admin/src/components/UploadFile.vue.
                Please check said component's inline documentation for more info on that parametter.
            */
           return () => {
               const loadedDocument = this.loadedDocument(doc);
               if (loadedDocument) {
                   return FilesApi.download(loadedDocument.file, {base64: true}).then((data) => data.data.object);
               }
               return null;
           }
        },
        fileMimeType(doc) {
            const loadedDocument = this.loadedDocument(doc);
            if (loadedDocument) {
                return loadedDocument.file.mimetype;
            }
            return null;
        },
        allDocumentsReady() {
            const allReady = !this.requiredDocumentsForFiscalSituation.find(d => !this.loadedDocument(d));
            this.$emit('allDocumentsReady', allReady);
            this.refreshDocuments();
            return allReady;
        },
        // For external interaction
        fileSelect(document, file) {
            this.$emit('fileSelected', {document, file});
        },
        fileDelete(document) {
            this.$emit('deleteFile', document);
        },
        setDocumentLoading(document, loading) {
            this.$set(this.documentsLoading, document._id, loading)
        },
        setDocumentDeleting(document, deleting) {
            this.$set(this.documentsDeleting, document._id, deleting)
        },
        pushDocumentToOrganization(requestedFile) {
            this.organization.requestedFiles.push(requestedFile);
            const url = FilesApi.download(requestedFile.file, {base64: true}).then((data) => data.data.object);
            const ref = this.$refs[`UploadFile_${requestedFile.fileDocument._id}`];
            if (ref) {
                if (ref.updateActualFileUrl) {
                    ref.updateActualFileUrl(url, requestedFile.file.mimetype);
                } else {
                    ref.forEach(r => r.updateActualFileUrl(url, requestedFile.file.mimetype));
                }
            }
            this.documentsLoadedById[(requestedFile.fileDocument._id || requestedFile.fileDocument)] = true;
            this.$forceUpdate();
            this.allDocumentsReady();
        },
        spliceDocumentToOrganization(requestedFile) {
            const inArray = this.organization.requestedFiles.find((r) => {
                return r.fileDocument === requestedFile.fileDocument || r.fileDocument._id === requestedFile.fileDocument;
            });
            if (inArray) {
                const index = this.organization.requestedFiles.indexOf(inArray)
                if (index >= 0) {
                    this.organization.requestedFiles.splice(index, 1);
                    this.$forceUpdate();
                }
            }
            this.documentsLoadedById[(requestedFile.fileDocument._id || requestedFile.fileDocument)] = false;
            this.$forceUpdate();
            this.allDocumentsReady();
        },
    },
    beforeMount () {
        this.organization = this.value;
        this.fetchDocuments();
        if (!this.organization.requestedFiles) {
            this.organization.requestedFiles = [];
        }
        this.organization.requestedFiles.forEach(d => {
            this.documentsLoading[(d.fileDocument._id || d.fileDocument)] = true;
        });
    },
    mounted(){
    },
    watch: {
        'organization': {
            handler() {
                this.organization.requestedFiles.forEach(d => {
                    this.documentsLoadedById[(d.fileDocument._id || d.fileDocument)] = true;
                });
            },
            deep: true
        }
    }
}
</script>
