<template>
    <LoadingBar></LoadingBar>
</template>

<script>
    // @ is an alias to /src
    import LoadingBar from "@/components/LoadingBar.vue";
    import {
        STORE as UserSignUpStore
    } from '@/store/modules/signin/signin.types'

    export default {
        name: "SignIn",
        data(){
            return {
            }
        },
        created(){
            this.$store.commit(`${UserSignUpStore}/SET_QUERY_RED_SOCIAL`, this.$route.query);
            this.$store.dispatch(`${UserSignUpStore}/loginSocialMediaGoogle`);
        },
        components: {
            LoadingBar
        }
    }
</script>
