<template>
    <div class="preview-logo-portada" :class="clazz">
      <label>{{title}}</label>
      <div>
        <img class="img-fluid portada" :src="coverSrc" alt="Portada"/>
        <span>
          <img class="img-fluid logo" :src="logoSrc" alt="Logo"/>
          <p> {{nombreEmp}} </p>
          <small> {{numServ}} Servicios</small>
        </span>
      </div>
      <small>{{mnsj}}</small>
    </div>
</template>

<script>
    export default {
        name: 'PreviewLogoPortada',
        props: {
            clazz: String,
            title: String,
            nombreEmp: String,
            numServ: String,
            mnsj: String,
            logoSrc: String,
            coverSrc: String
        }
    }
</script>

<style lang="scss">
    .preview-logo-portada {
        position: relative;
        display: block;
        margin-bottom: 30px;

        > label {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.06;
            display: block;
            margin-bottom: 14px;
        }

        > div {
            width: 100%;
            height: 200px;
            max-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            .portada {
                border-radius: 4px;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            > span {
                position: absolute;
                margin: auto;

                .logo {
                    width: 82px;
                    height: 82px;
                    border-radius: 4px;
                    object-fit: cover;
                    display: flex;
                    margin: 0 auto 10px;
                    overflow: hidden;
                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.29;
                    text-align: center;
                    display: block;
                    margin-bottom: 5px;
                }

                small {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 1.29;
                    text-align: center;
                    display: block;
                }
            }
        }

        > small {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.29;
            display: block;
            margin-top: 20px;
        }
    }
</style>
