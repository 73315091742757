<template>
    <div class="portada-select-preview" :class="clazz">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-5 m-b-30">
                <BasicSelect
                        required
                        :label="$t('signup-organization.step-three.preview-modal-cover')"
                        labelFor="categoria"
                        help
                        id="dct-catOrg"
                >
                    <multiselect id="txt-catOrgType" v-model="category" :options="categories" @select="onSearch"
                                 :close-on-select="true" :preserve-search="true"
                                 :placeholder="$t('signup-organization.step-one.category.placeholder')" label="name"
                                 track-by="name" :preselect-first="false">
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single"
                                  v-if="values.length && !isOpen">
                                {{ values.length }} {{$t('signup-organization.step-one.category.options-selected')}}</span>
                        </template>
                    </multiselect>
                    <template v-slot:tooltipHelp>
                        <b-tooltip target="categoryHelp" placement="top" custom-class="white-popover">
                            <div>
                                <p class="m-b-0">
                                    {{$t('signup-organization.step-one.category.help-tooltip')}}
                                </p>
                            </div>
                        </b-tooltip>
                    </template>
                </BasicSelect>
                <ul class="portadas-list" v-if="options.length > 0">
                    <li :class="{'selected': isSelected(option)}" @click="selectOption(option)"
                        v-for="option in options" :key="option.id">
                        <img class="img-fluid" :src="getOptionSrc(option)" alt="image"/>
                        <span class="badge badge-accent_2"
                              :class="{'badge-accent_1': !isSelected(option), 'badge-accent_2': isSelected(option)}"><i
                                aria-hidden="true" v-if="isSelected(option)" class="banana banana-check-normal"></i> {{isSelected(option) ? selectedText : selectText}} </span>
                    </li>
                </ul>
                <EmptyState v-else
                            size="xs"
                            :emptyStateDefault="true"
                            :title="$t('signup-organization.step-three.empty-state.cover-photos.title')"
                            :text="$t('signup-organization.step-three.empty-state.cover-photos.text')"
                ></EmptyState>
                <i aria-hidden="true" class="divider"></i>
            </div>
            <div class="col-12 col-md-12 col-lg-7 m-b-30">
                <LoadingBar v-if="loadingPreviewCover" loadingText="Loading text..."></LoadingBar>
                <span class="preview-portada">
                    <img class="img-fluid img-preview" v-if="temporalCoverSrc === '' || temporalCoverSrc === null"
                         :src="DefaultCover" alt="image"/>
                    <img class="img-fluid img-preview" v-else :src="temporalCoverSrc" alt="image"/>
                    <img class="img-fluid img-skeleton" :src="MicrositioSckeleton" alt=""/>
                </span>
                <Badge type="badge-accent_2" text="Vista previa"></Badge>
            </div>
            <div class="layer-uploading" v-if="uploadFailed || loading ">
                <ProgressCircle :filename="namePhoto" :filesize="sizePhoto" v-if="loading">
                    <radial-progress-bar :diameter="120"
                                         :totalSteps="100"
                                         :completedSteps="percentage"
                                         startColor="#c16bf0"
                                         stopColor="#6992ea"
                                         innerStrokeColor="#FFF"
                                         :strokeWidth="4"
                                         timingFunc="ease-in-out"
                    >

                        <p class="percentage"><strong>{{percentage}}</strong>%</p>
                    </radial-progress-bar>
                </ProgressCircle>
                <ProgressCircle progressRetry v-if="uploadFailed"></ProgressCircle>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingBar from '@/components/LoadingBar.vue'
    import ProgressCircle from '@/components/ProgressCircle.vue'
    import RadialProgressBar from 'vue-radial-progress';
    import BasicSelect from '@/components/BasicSelect.vue'
    import EmptyState from '@/components/EmptyState.vue'
    import utils from '@/common/utils';
    import {EventBus} from "@/main";
    import categories from '@/enums/categories'
    import Multiselect from 'vue-multiselect'
    import Badge from '@/components/Badge.vue'
    import DefaultCover from "@/assets/images/Default/cover.svg";
    import MicrositioSckeleton from "@/assets/images/Illustrations/Ilustracion-Skeleton-Micrositio.svg"

    export default {
        name: 'PortadaSelectPreview',
        components: {
            LoadingBar,
            ProgressCircle,
            RadialProgressBar,
            BasicSelect,
            Multiselect,
            EmptyState,
            Badge
        },
        data() {
            return {
                DefaultCover,
                MicrositioSckeleton,
                selectedOptionsMap: {},
                search: '',
                loadingPreviewCover: false,
                categories: categories.getAllCategories(),
                category: categories.getCategorie('TOURISM')
            }
        },
        props: {
            clazz: String,
            storeModule: {
                type: String,
                required: true,
                default: 'portada'
            },
            loading: {
                type: Boolean,
                default: false
            },
            uploadFailed: {
                type: Boolean,
                default: false
            },
            percentage: {
                type: Number,
                default: 0
            },
            namePhoto: {
                type: String,
                default: ""
            },
            sizePhoto: {
                type: String,
                default: ""
            },
            options: {
                type: Array,
                default: () => [],
            },
            srcPropName: {
                type: String,
                required: true,
            },
            idPropName: {
                type: String,
                default: "_id",
            },
            selectText: {
                type: String,
                required: true,
            },
            selectedText: {
                type: String,
                required: true,
            },
            searchPlaceholder: {
                type: String,
                required: true,
                default: 'Realiza una búsqueda'
            },
            temporalCoverSrc: {
                type: String,
                default: ''
            },
            temporalCoverUser: {
                type: String,
                default: ''
            }
        },
        created() {
            EventBus.$on('SET-IS-LOADING-PREVIEW-IMAGE', (value) => {
                this.loadingPreviewCover = value;
            });
        },
        methods: {
            getOptionSrc(option) {
                return utils.getNestedProperty(option, this.srcPropName);
            },
            isSelected(option) {
                return this.selectedOptionsMap[utils.getNestedProperty(option, this.idPropName)]
            },
            selectOption(option) {
                if (!this.isSelected(option)) {
                    this.loadingPreviewCover = true;
                    this.deselect();
                    this.selectedOptionsMap[utils.getNestedProperty(option, this.idPropName)] = true;
                    this.$emit('select', {option, deselect: this.deselect});
                }
            },
            deselect() {
                this.selectedOptionsMap = {};
            },
            onSearch(search) {
                utils.debounceFixed(() => {
                    this.$emit('search', search.value);
                })
            },
        },
        beforeDestroy() {
            EventBus.$off(`SET-IS-LOADING-PREVIEW-IMAGE`)
        }
    }
</script>

<style lang="scss">
    .portada-select-preview {
        .form-group {
            margin-bottom: 16px !important;
        }

        .portadas-list {
            position: relative;
            display: block;
            margin: 0;
            padding: 0 6px 0 0;
            list-style: none;
            max-height: 360px;

            li {
                position: relative;
                width: 100%;
                height: 100%;
                min-height: 128px;
                max-height: 128px;
                margin-bottom: 8px;
                display: block;
                overflow: hidden;
                border-radius: 4px;
                transition: 0.5s;

                &:hover {
                    > img {
                        transform: scale(1.1) rotate(1deg);
                        filter: blur(1px);
                    }

                    &::before {
                        z-index: 1;
                    }

                    > .badge {
                        z-index: 2;
                    }
                }

                &::before {
                    content: " ";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: transparent;
                    transition: 0.5s;
                }

                > img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: flex;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 4px;
                    transition: 0.5s;
                }

                > .badge {
                    position: absolute;
                    z-index: -1;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    box-shadow: 0 2px 4px 0 rgba(36, 36, 36, 0.25);

                    i {
                        font-size: 14px;
                        margin-top: -2px;
                        margin-right: 4px;
                    }
                }

                &.selected {
                    &::before {
                        z-index: 1;
                    }

                    > img {
                        border-radius: 0;
                    }

                    > .badge {
                        z-index: 2;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .divider {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 95%;
            height: 30px;
        }

        .preview-portada {
            position: relative;
            display: block;
            width: 100%;
            height: 85%;
            min-height: 360px;
            padding: 0 9%;
            z-index: 1;

            > .author-credit {
                margin-right: 9%;
            }

            > .img-skeleton {
                height: 100%;
                display: flex;
                margin: 0 auto;
                z-index: 1;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                width: 82%;
            }

            > .img-preview {
                display: flex;
                margin: 0 auto;
                left: 0;
                right: 0;
                top: 0;
                object-fit: cover;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                z-index: -1;
                max-height: 180px;
                width: 100%;
            }

            & + .badge {
                margin: 30px auto;
                display: flex !important;
            }
        }

        .layer-uploading {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
