import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import config from '../config';

Vue.use(VueGoogleMaps, {
  load: {
    //   CAMBIAR API KEY
    key: config.googleMaps.key,
    //   CAMBIAR API KEY
    libraries: config.googleMaps.libraries,
  },

  installComponents: true
})
