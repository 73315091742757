<template>
    <div>
        <SignUpOrganizationBody stepTwo first>
            <template v-slot:formSectionBody>
                <GenericTitle clazz="p-t-20" id="dct-StepTwoOnb"
                    :title="$t('sign-in.onboarding.title')"
                    :titleStrong="$t('sign-in.onboarding.title-strong')"
                    :subtitle="$t('sign-in.onboarding.desc')"
                ></GenericTitle>

                <form>
                    <LoadingBar v-if="loading"></LoadingBar>
                    <b-tabs
                        v-else
                        class="tabs-pills"
                        content-class="tabs-pills-body p-t-20">
                        <b-tab
                            v-for="(t, k, index) in businessTabs"
                            :key="k"
                            :title="$t(`sign-in.onboarding.${k}`)"
                            :active="k === currentTab || index === 0"
                            @click="currentTab = k"
                        >
                            <ul class="checked-list">
                                <li v-for="(opt, i) in t" :key="i">
                                    {{opt.description}}
                                </li>
                            </ul>
                        </b-tab>
                    </b-tabs>
                </form>
            </template>
        </SignUpOrganizationBody>
    </div>
</template>

<script>
    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue';
    import GenericTitle from '@/components/GenericTitle.vue';
    import LoadingBar from '@/components/LoadingBar.vue';
    import PagandoConfigApi from '@/api/pagandoConfig.api.js';

    export default {
        name: "StepOnboarding",
        components: {
            SignUpOrganizationBody,
            GenericTitle,
            LoadingBar
        },
        data() {
            return {
                businessTabs: [],
                loading: true,
                currentTab: ''
            }
        },
        created() {
            this.getTabsInfo();
        },
        methods: {
            getTabsInfo(){
                PagandoConfigApi.getBusinessConfig().then(response => {
                    this.businessTabs = response.data.object;
                    this.loading = false;
                }).catch(error => this.toastErrorDefault(error));
            }
        }
    };
</script>
