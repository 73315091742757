<template lang="html">

  <CardSimple
    :title="$t('pagos.conceptos.title')"
    :subtitle="$t('pagos.conceptos.subtitle')"
  >
    <div class="row m-b-20">
      <div class="col-12">
        <div class=" justify-content-end d-flex">
        </div>
      </div>
    </div>

    <div class="o-auto">
      <div class="min-w-600px">
        <div v-for="(i, c) in payment.items" :key="c"  class="info-item">
          <div class="content">
            <div class="concept-name">
              <BasicInput
                required
                v-model="payment.items[c].name"
                labelFor="concepto"
                inputId="concepto"
                @input="update(payment.items[c])"
                :label="$t('pagos.conceptos.table.name.title')"
                :inputPlaceholder="$t('pagos.conceptos.table.name.placeholder')"
              >
              </BasicInput>

            </div>
            <div class="concept-cant">
              <BasicInput
                required
                v-model="payment.items[c].quantity"
                @input="update(payment.items[c])"
                inputType="number"
                :min='0'
                labelFor="cant"
                inputId="cant"
                :label="$t('pagos.conceptos.table.quantity')"
                inputPlaceholder="0"
              ></BasicInput>
            </div>
            <div class=" concept-amount">
              <BasicInput
                required
                :min='0'
                inputType="number"
                v-model="payment.items[c].unitPrice"
                @input="update(payment.items[c])"
                labelFor="concepto"
                inputId="concepto"
                :label="$t('pagos.conceptos.table.amount')"
                inputPlaceholder="0"
              >
              </BasicInput>
            </div>
            <div class=" concept-subtotal">
              <h2 class="f-14 c-title body-font-regular">{{$t('pagos.conceptos.table.subtotal')}}</h2>
              <div class="f-14 c-title body-font-semibold">
                {{(payment.items[c].unitPrice * payment.items[c].quantity) | currency}} {{currencyCode}}
              </div>
            </div>
          </div>
          <div class="delete-item">
            <button class="button-circle button-ghost button-error sm f-20" @click="removeConcept(c)">
              <i aria-hidden="true" class="banana banana-delete"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <a
      class="button-square-add"
      tabindex
      @click="addConcept"
    >
      <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{$t('pagos.conceptos.table.add')}}
    </a>

    <div class="total-container">
      <div class="total-text body-font-medium">{{$t('pagos.conceptos.table.total')}}</div>
      <div class="total-number body-font-medium"> {{ total | currency}} {{currencyCode}}</div>
    </div>

    <div class="row">
        <div class="col-12" v-if="isTotalInvalid">
          <b-alert
              class="alert-note"
              show
              variant="danger"
          >
              <p class="text-container">
                  <img
                      class="img-fluid"
                      src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                      alt="warning"
                  />
                  {{ $t("general.paymentAmountInvalid", valuesForAlert) }}
              </p>
          </b-alert>
        </div>

        <div class="col-12 vertical-center justify-content-end m-t-40">
          <button
              v-if="!editMode"
              class="button button-ghost sm button-plain_text m-0-auto-0-0"
              @click="prev"
          >
              <i aria-hidden="true" class="banana banana-chevron-left"></i>
              {{$t('general.actions.back.title')}}
          </button>
          <div class="vertical-center">
              <button class="button button-ghost sm button-plain_text" @click="cancel">
                  {{$t('general.actions.cancel.title')}}
              </button>
              <button
                  v-if="!editMode"
                  class="button button-filled sm button-accent1 m-l-10"
                  :disabled="hasError"
                  @click="next"
                  :class="{disabled: hasError}"
              >
                  {{$t('general.actions.next.title')}}
              </button>

              <button
                  v-if="editMode"
                  class="button button-filled sm button-accent1 m-l-10"
                  :class="{disabled: hasError}"
                  :disabled="hasError"
                  @click="finish"
              >
                  {{ $t('general.actions.save') }}
              </button>
          </div>
        </div>
    </div>

  </CardSimple>

</template>

<script lang="js">
  import BasicInput from "@/components/BasicInput.vue";
  import CardSimple from '@/components/CardSimple.vue';
  import { required, minLength, minValue } from 'vuelidate/lib/validators';
  import utils from '@/common/utils';

  export default {
    name: 'concepts',
    props: {
        editMode: {type: Boolean, default: false},
        payment: {
            type: Object,
            default: function() {
                return {
                    items: []
                };
            }
        }
    },
    components: {
      BasicInput,
      CardSimple
    },
    mounted () {

    },
    data () {
      return {
      }
    },
    methods: {
        removeConcept(index) {
            this.payment.items.splice(index, 1);
            this.update();
        },
        addConcept(e) {
            const newConcept = {
                name: '',
                quantity: '',
                unitPrice: ''
            };
            this.payment.items.push(newConcept);
            e.preventDefault();
            this.update();
        },
        update(item) {
            if (item) {
                this.$set(item, 'total', utils.jsNumFix(item.quantity * item.unitPrice))
            }
        },
        prev() {
            this.$emit('prev');
        },
        next() {
            this.$emit('next');
        },
        cancel() {
            this.$emit('cancel');
        },
        finish() {
            this.$emit('finish');
        }
    },
    computed: {
        currencyCode() {
            if (this.payment && this.payment.currencyObject) {
                return this.payment.currencyObject.code;
            }
            return '';
        },
        maxAmount() {
            return this.payment &&
                    this.payment.organization &&
                    this.payment.organization.transactionMaxAmount ?
                this.payment.organization.transactionMaxAmount : 9900;
        },
        minAmount() {
            return this.payment &&
                    this.payment.organization &&
                    this.payment.organization.transactionMinAmount ?
                this.payment.organization.transactionMinAmount : 0;
        },
        total() {
            let total = 0;
            this.payment.items.forEach(concept => {
                total += concept.total;
            });
            return utils.jsNumFix(total);
        },
        hasError () {
             if ((this.payment.items.length <= 0)) {
                return true;
            }
            if (this.payment.items.find(i => !i.name || !i.quantity || !i.unitPrice || !i.total)) {
                return true;
            }

            if (this.isTotalInvalid) {
                return true;
            }

            return this.$v.$invalid;
        },
        isTotalInvalid() {
            if (this.payment && this.payment.items) {
                return this.payment.items.length > 0 &&
                    (this.total < this.minAmount || (this.maxAmount > 0 && this.maxAmount < this.total ));
            }
            return false;
        },
        valuesForAlert() {
            return {
                transactionMaxAmount: utils.numberToMoneyString(this.maxAmount),
                transactionMinAmount: utils.numberToMoneyString(this.minAmount),
            }
        }
    },
    validations: {
        payment: {
            items: {
                $each: {
                    name: {
                        required,
                        minLength: minLength(2)
                    },
                    quantity: {
                        required,
                        minValue: minValue(0)
                    },
                    unitPrice: {
                        required,
                        minValue: minValue(0)
                    }
                }
            }
        }
    }
}
</script>
