import {
    SET_TERMS_CONDITIONS,
    GET_TERMS_CONDITIONS,
    SET_PRIVACY_AGREEMENT,
    GET_PRIVACY_AGREEMENT,
    SET_CONTRACT,
    GET_CONTRACT,
    FIND_CONTRACT,
    FIND_PRIVACY_AGREEMENT,
    FIND_TERMS_CONDITIONS,
    LOADING_AGGREMENT,
    TERMS_EXISTS,
    GET_LOADING_AGGREMENT,
    GET_TERMS_EXISTS
} from './documents.types';
import api from '@/api/documents/documents.api'
import ToastSnotify from "@/components/ToastSnotify.vue";
import i18n from '@/plugins/i18n';
import utils from '@/common/utils'

const stateGlobal = {
    termsConditions: {},
    privacyAgreement: {},
    contract: {},
    loadingAggreement: false,
    termsExists: false
};


const getters = {
    [GET_TERMS_CONDITIONS]: (state) => state.termsConditions,
    [GET_CONTRACT]: (state) => state.contract,
    [GET_PRIVACY_AGREEMENT]: (state) => state.privacyAgreement,
    [GET_LOADING_AGGREMENT]: (state) => state.loadingAggreement,
    [GET_TERMS_EXISTS]: (state) => state.termsExists
};

const actions = {
    /**
     * Encuentra los terminos y condiciones aceptados por el usuario en caso de que exista sesión
     */
    [FIND_TERMS_CONDITIONS]: async ({commit}, idUser) => {
        const params = {
            type: "TERMS_CONDITIONS",
            id: idUser
        }
        const response = await api.findDocumentAcceptedByParams(params);
        if(response.data.error){
            this.displayNotificationError(response.data.message);
        } else {
            commit(SET_TERMS_CONDITIONS, response.data.object)
            commit(TERMS_EXISTS, true)
        }
    },

    /**
     * Encuentra el acuerdo de privacidad actual para ser mostrado.
     **/
    [FIND_PRIVACY_AGREEMENT]: async ({commit})=>{
        commit(LOADING_AGGREMENT, true);
        const params = {
            type: "PRIVACY_AGREEMENT"
        }
        const response = await api.findDocumentAcceptedByParams(params);
        if(response.data.error){
            this.displayNotificationError(response.data.message);
        } else {
            commit(SET_PRIVACY_AGREEMENT, response.data.object)
            commit(LOADING_AGGREMENT, false);
        }

    },

    /**
     * Encuentra el contrato aceptado por la organización en caso
     * de que se encuentre aceptado.
     **/
    [FIND_CONTRACT]: async ({commit}, idOrganization) => {
        const params = {
            type: "CONTRACT",
            id: idOrganization
        }
        const response = await api.findDocumentAcceptedByParams(params);
        if(response.data.error){
            this.displayNotificationError(response.data.message);
        } else {
            commit(SET_CONTRACT, response.data.object)
        }
    }
};

const mutations = {
    [SET_TERMS_CONDITIONS]: (state, terms) => {
        terms.documentAcceptedContent = terms.documentAcceptedContent.replace(/<p>/g,
            '<p class="f-16 c-plain_text body-font-light text-align-j line-height-1-5 d-block">');
        terms.documentAcceptedContent = terms.documentAcceptedContent.replace(/<h[0-9]>/g,
            '<h1 class="f-24 c-primary_1 body-font-regular d-block m-b-15">');
        const titleContent = `<h1 class="f-35 c-accent_2 principal-font-bold d-block m-b-15">${ terms.document.name }</h1>`+
                            `<small class="f-15 c-info_text body-font-light d-block"><strong class="body-font-regular f-normal">${
         i18n.t('terms.last-update') }</strong>${ utils.toFormatDate(terms.updatedAt,"DD/MM/YYYY") }</small><div class="divider-disable m-t-20 m-b-20 w-20px"></div>`
        terms.documentAcceptedContent = titleContent + terms.documentAcceptedContent;
        state.termsConditions = terms;
    },
    [SET_PRIVACY_AGREEMENT]: (state, privacyAgreement) =>{
        privacyAgreement.content = privacyAgreement.content.replace(/<p>/g,
            '<p class="f-16 c-plain_text body-font-light text-align-j line-height-1-5 d-block">');
        privacyAgreement.content = privacyAgreement.content.replace(/<h[0-9]>/g,
            '<h1 class="f-24 c-primary_1 body-font-regular d-block m-b-15">');
        const titleContent = `<h1 class="f-35 c-accent_2 principal-font-bold d-block m-b-15">${ privacyAgreement.name }</h1>`+
            `<small class="f-15 c-info_text body-font-light d-block"><strong class="body-font-regular f-normal">${
         i18n.t('terms.last-update') }</strong>${ utils.toFormatDate(privacyAgreement.updatedAt,"DD/MM/YYYY") }</small><div class="divider-disable m-t-20 m-b-20 w-20px"></div>`
        privacyAgreement.content = titleContent + privacyAgreement.content;
        state.privacyAgreement = privacyAgreement;
    },
    [SET_CONTRACT](state, contract){
        state.contract = contract;
    },
    [LOADING_AGGREMENT](state, loadingAggreement){
        state.loadingAggreement = loadingAggreement;
    },
    [TERMS_EXISTS](state, termsExists){
        state.termsExists = termsExists;
    },
};


export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    },
    mixins: [
        ToastSnotify
    ]
};
