<template>
    <div>
        <BaseButton
            type="button"
            :_class="_class"
            :id="id"
            :shape="BUTTONS.SHAPES.BUTTON"
            :kind="BUTTONS.KINDS.STROKE"
            :color="BUTTONS.COLORS.ACCENT2"
            :size="BUTTONS.SIZES.EXTRA_SMALL"
            :text="text"
            @click="onClick()"
            data-toggle="modal"
            :data-target="`#${modalId}`"
            :icon="icon"
        >
        </BaseButton>
    </div>
</template>

<script>
    import BUTTONS from '@/common/consts/buttons.consts';
    import BaseButton from '@/components/signUpOrganizations/base/BaseButton';

    export default {
        data () {
            return {
                BUTTONS,
            }
        },
        components: {
            BaseButton
        },
        props: {
            id: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },
            modalId: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
            },
            _class: {
                type: String,
            },
        },
        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>
