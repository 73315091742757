<template>
    <ModalDefault
        ref="modalShowRefund"
        size="lg"
        :title="$t('pagos-recaudados.refunds.start')"
        :subtitle="$t('pagos-recaudados.refunds.subtitle')"
    >
        <div class="m-t-10">
            <LoadingBar v-if="loadingRefund"></LoadingBar>
            <BasicSelectComplete
                v-model="refund.reason"
                :validations="$v.refund.reason"
                labelFor="refund_reason"
                inputId="refund_reason"
                :label="$t('pagos-recaudados.refunds.reason')"
                :inputPlaceholder="$t('pagos-recaudados.refunds.reason-placeholder')"
                :options="refundReasons"
                :optionLabel="(o) => o.name"
                :actualValue="(o) => o._id"
                required
            ></BasicSelectComplete>
            <RadioInputGroup
                wrapperClass="row p-l-0"
                inputWrapperClass="col-md-12 col-12 p-t-5"
                :options="[{value: 'FULL'}, {value: 'PARTIAL'}]"
                v-model="refund.type"
                @input="refundTypeChange"
                :validations="$v.refund.type"
            >
                <template v-slot:inputBody="o">
                    <div
                        class="c-plain_text f-14 body-font-regular"
                        v-html="$t(`pagos-recaudados.refunds.type.${o.value}`)">
                    </div>
                </template>
            </RadioInputGroup>
            <BasicInput
                v-if="!loadingRefund"
                clazz="m-t-10"
                v-model="refund.amount"
                :validations="$v.refund.amount"
                labelFor="refund_amount"
                inputId="refund_amount"
                :label="$t('pagos-recaudados.refunds.amount')"
                :inputPlaceholder="$t('pagos-recaudados.refunds.amount-placeholder')"
                :disabled="refund.type !== 'PARTIAL'"
                required
            ></BasicInput>
        </div>
        <br>
        <template v-slot:customFooter>
            <div class=" w-100">
                <div class="row">
                    <div class="col-sm-4 col-12 d-flex align-items-c"></div>
                    <div class="col-sm-8 col-12">
                        <div class="d-sm-flex justify-content-end">
                            <button
                                class="button xs button-ghost button-plain_text m-r-20"
                                @click="close()"
                            >
                                {{$t('general.close')}}
                            </button>
                            <button
                                class="button xs button-filled button-accent1"
                                @click="startRefund"
                                :disabled="$v.refund.$invalid"
                            >
                                {{$t('general.accept')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ModalDefault>
</template>
<script>
import BasicSelectComplete from '@/components/BasicSelectComplete.vue';
import RadioInputGroup from '@/components/RadioInputGroup.vue';
import LoadingBar from '@/components/LoadingBar.vue';
import ConektaApi from '@/api/payments/conekta.api';
import RefundReasons from '@/api/payments/refundReasons.api';
import ModalDefault from '@/components/ModalDefault.vue';
import BasicInput from "@/components/BasicInput.vue";
import {helpers, minValue, required} from 'vuelidate/lib/validators';

const decimalNumber = helpers.regex('number', /^[0-9]*(\.[0-9]+)?$/)
export default {
    components: {
        RadioInputGroup,
        LoadingBar,
        BasicSelectComplete,
        ModalDefault,
        BasicInput,
    },
    data() {
        return {
            transaction: {},
            refund: {
                amount: 0,
                reason: null,
                type: null,
            },
            loadingRefund: false,
            refundReasons: [],
        };
    },
    methods: {
        showRefundModal(transaction) {
            this.transaction = transaction;
            if(transaction.dispersionDetail.totalWithoutCommision != null && transaction.dispersionDetail.totalWithoutCommision !== undefined){
                this.refund.amount = transaction.dispersionDetail.totalWithoutCommision;
            } else {
                this.refund.amount = transaction.dispersionDetail.userPaymentTotal;
            }
            this.refund.type = 'FULL';
            this.loadingRefund = false;
            this.$refs.modalShowRefund.showModal();
            this.loadingRefund = true;
            RefundReasons.list()
                .then((reasons) => {
                    this.refundReasons = reasons;
                    this.loadingRefund = false;
                }).catch((error) => this.toastErrorDefault(error));
        },
        refundTypeChange() {
            if(this.transaction.total != null && this.transaction.total !== undefined){
                this.refund.amount = this.transaction.total;
            } else {
                this.refund.amount = this.transaction.dispersionDetail.userPaymentTotal;
            }
        },
        startRefund() {
            this.loadingRefund = true;
            ConektaApi
                .refund(this.transaction.number, this.refund.reason, this.refund.amount)
                .then(() => {
                    this.toastSuccessDefault();
                    this.close(true);
                    this.loadingRefund = false;
                    this.refreshList();
                }).catch((error) => {
                    this.loadingRefund = false;
                    this.toastErrorDefault(error);
                });
        },
        close(refunded = false) {
            this.$refs.modalShowRefund.hideModal();
            this.$emit('closed', refunded);
        },
        refreshList(){
            this.$emit("refreshList");
        },
    },
    watch: {
        'refund.type': {
            handler() {
                if (this.refund.type === 'FULL') {
                    if (this.transaction.total != null && this.transaction.total !== undefined) {
                        return this.transaction.total;
                    }
                    return this.transaction.dispersionDetail.userPaymentTotal;
                }
                return this.refund.amount;
            }
        }
    },
    validations: {
        refund: {
            type: {
                required,
            },
            reason: {
                required,
            },
            amount: {
                required,
                numeric: decimalNumber,
                minValue: minValue(0),
                max(value) {
                    if (this.transaction.total != null && this.transaction.total !== undefined) {
                        return value <= this.transaction.total;
                    }
                    return value <= this.transaction.dispersionDetail.userPaymentTotal;
                }
            }
        }
    }
}
</script>
