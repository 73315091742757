<template>
    <div class="social-container" v-bind:class="clazz">
        <div class="continue" v-bind:class="clazzSpan">
            <span>{{span}}</span>
        </div>
        <div class="social-buttons">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SocialButtons',
        props: {
            clazz: String,
            clazzSpan: String,
            span: String
        }
    }
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
    .social-container {
        > div {
            width: 100%;
            position: relative;
        }

        .continue {
            margin-bottom: 20px;
            height: 25px;

            span {
                font-size: 16px;
                font-weight: normal;
                line-height: normal;
                text-align: center;
                display: block;
                padding: 5px 8px;
                width: fit-content;
                margin: 0 auto;
                position: absolute;
                left: 0;
                right: 0;
                min-width: 204px;
            }

            &::before {
                content: " ";
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                opacity: 0.6;
            }
        }

        .social-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .social-btn {
                min-height: 36px;
                min-width: calc(50% - 10px);
                border-radius: 4px;
                padding: 6px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px 10px;
                border: 1px solid;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.43;
                text-align: center;
                transition: 0.5s;

                strong {
                    margin-left: 2px;
                }

                img {
                    max-height: 24px;
                    object-fit: content;
                    margin-right: 4px;
                }

                &.apple {
                    img {
                        max-height: 20px;
                    }
                }
            }
        }
    }
</style>
