let facebook;

export function init({facebookId}){
    window.fbAsyncInit = function() {
        facebook = window.FB;
        facebook.init({
        appId: facebookId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v5.0'
        });
    };

    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // Twitter API
    window.twttr = (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0],
            t = window.twttr || {};
        if (d.getElementById(id)) {
            return t;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://platform.twitter.com/widgets.js";
        fjs.parentNode.insertBefore(js, fjs);

        t._e = [];
        t.ready = function(f) {
            t._e.push(f);
        };

        return t;
    }(document, "script", "twitter-wjs"));
}

export function shareFacebook(url) {
    facebook.ui({
        method: 'share',
        href: url,
        quote: "Pagando",
    });
}

export function shareTwitter(url) {
    window.open(`https://twitter.com/intent/tweet?hashtags=Pagando%2CM%C3%A9xico&text=Pagando&url=${encodeURIComponent(url)}`);
}
