import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'documents';

export default {
    findDocumentAcceptedByParams: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/find-document-accepted`, params)
            .then(onSuccess)
            .catch(onError);
    }
}
