import terminalsApi from '@/api/terminals/terminals.api';
import catalog from '@/store/modules/base/catalog.store';
import {EventBus} from "@/main";

import {
    STORE as TerminalsStore,
    GET_TERMINALS,
    SET_TERMINALS,
    ERROR_LOAD_TERMINALS,
    SUCCESS_LOAD_TERMINALS,
    GET_LOADING_TERMINALS,
    SET_LOADING_TERMINALS
} from './terminals.types';
const terminalsCatalog = catalog(terminalsApi, TerminalsStore);

// Objetos
const stateGlobal = {
    terminals: {},
    loadingTerminals: false
};

// Métodos para obtener valores del state. Se les llama con getters
const getters = {
    [GET_TERMINALS]: (state) => state.terminals,
    [GET_LOADING_TERMINALS]: (state) => state.loadingTerminals,
};

// Métodos síntronos que afectan el state. Se llaman con commit
const mutations = {
    [SET_TERMINALS]: (state, terminals) => {state.terminals = terminals},
    [SET_LOADING_TERMINALS]: (state, loadingTerminals) => {state.loadingTerminals = loadingTerminals},
};

// Métodos asíncronos que hacen cosas y luego hacen commit. Se llaman con dispatch
const actions = {
    listTerminals({commit}, params) {
        commit(SET_LOADING_TERMINALS, true);
        return terminalsApi.list(
            params,
            (result) => {
                commit(SET_LOADING_TERMINALS, false);
                if(result.data.error) {
                    EventBus.$emit(`${TerminalsStore}${ERROR_LOAD_TERMINALS}`, result.data.message);
                } else {
                    commit(SET_TERMINALS, result.data.object);
                    EventBus.$emit(`${TerminalsStore}${SUCCESS_LOAD_TERMINALS}`, result.data.message);
                }
                return result
            },
            (err) => {
                commit(SET_LOADING_TERMINALS, false);
                EventBus.$emit(`${TerminalsStore}${ERROR_LOAD_TERMINALS}`, err);
                return err
            }
        );
    }
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal,
        ...terminalsCatalog.state
    },
    getters: {
        ...getters,
        ...terminalsCatalog.getters
    },
    actions: {
        ...actions,
        ...terminalsCatalog.actions
    },
    mutations: {
        ...mutations,
        ...terminalsCatalog.mutations
    }
};
