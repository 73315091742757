<template lang="html">

  <CardSimple
    :title="$t('pagos.descuentos.title')"
    :subtitle="$t('pagos.descuentos.subtitle')"
  >
    <div class="row m-b-20">
      <div class="col-12">
        <div class=" justify-content-end d-flex">
        </div>
      </div>
    </div>

    <div class="big-info-item" v-for="(discount, d) in payment.discounts" :key="d">
      <div class="row">
        <div class="col-md-6">
          <BasicInput
            required
            labelFor="descuento"
            inputId="descuento"
            @input="update"
            :label="$t('pagos.descuentos.table.name.title')"
            :inputPlaceholder="$t('pagos.descuentos.table.name.placeholder')"
            v-model="payment.discounts[d].description"
          >
          </BasicInput>
        </div>
        <div class="col-md-3">
          <BasicSelect
            required
            :label="$t('pagos.descuentos.table.type.title')"
            labelFor="Tipo"
          >

            <multiselect
                @input="typeChange(payment.discounts[d])"
                v-model="payment.discounts[d].type"
                :options="typeSelect"
                :close-on-select="true"
                :preserve-search="true"
                :placeholder="$t('pagos.descuentos.table.type.placeholder')"
                label="name"
                track-by="name"
                :preselect-first="true"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span>
              </template>
            </multiselect>
          </BasicSelect>

        </div>
        <div class="col-md-3">
          <BasicInput
            required
            inputType="number"
            labelFor="perc"
            inputId="perc"
            @input="update"
            :label="payment.discounts[d].type ? payment.discounts[d].type.name : ''"
            inputPlaceholder="0"
            v-model="payment.discounts[d].discount"
          ></BasicInput>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-4 d-flex align-items-c">

          <InputDatepicker
            v-if="payment.discounts[d].expires"
            clazz="m-b-0"
            required
            :label="$t('pagos.descuentos.table.rango-fecha.title')"
            labelFor="date"
          >
            <date-range-picker
              ref="picker"
              :opens="opens"
              :showDropdowns="showDropdowns"
              :autoApply="autoApply"
              format="dd/mm/yyyy"
              @update="dateRangeChange(payment.discounts[d])"
              v-model="payment.discounts[d].dateRange"
              class="form-daterangepicker daterangepicker-up daterangepicker-left"
            >
              <!-- eslint-disable -->
              <div
                slot="input"
                slot-scope="picker"
                v-if="!payment.discounts[d].to"
              >
                <span class="placeholder">{{$t('pagos.descuentos.table.rango-fecha.placeholder')}}</span> <!-- This must be hidden when date selected -->
              </div>
              <!-- eslint-enable -->
            </date-range-picker>
            <i aria-hidden="true" class="banana banana-calendar icon-rangepicker"></i>
          </InputDatepicker>

          <InputDatepicker
            v-else
            labelFor="date"
            :label="$t('pagos.descuentos.table.fecha.title')"
            required
            clazz="m-0"
          >
            <datepicker
              v-model="payment.discounts[d].from"
              @input="dateChange(payment.discounts[d])"
              :placeholder="$t('pagos.descuentos.table.fecha.placeholder')"
              wrapper-class="form-datepicker datepicker-up datepicker-left"
              input-class="form-control"
              calendar-class="window-datepicker"
              calendar-button
              calendar-button-icon="banana banana-calendar"
              typeable
            />
          </InputDatepicker>
        </div>

        <div class="col-lg-2 col-md-3 d-flex align-items-c p-t-15">
          <BasicCheckbox
            clazz="m-b-0"
            :text="$t('pagos.descuentos.table.vigencia')"
            @input="expiresChange(payment.discounts[d])"
            v-model="payment.discounts[d].expires"
            :ref="`expires_${d}`"
          ></BasicCheckbox>
        </div>

        <div class="col-lg-3 col-md-3 d-flex align-items-c p-t-15">
          <div class="d-flex align-items-c">
            <p class="m-0 c-plain_text f-14 body-font-regular">{{$t('pagos.descuentos.table.status')}}</p>
            <Badge
              :type="discountStatus(payment.discounts[d]).type"
              :text="discountStatus(payment.discounts[d]).text"
            ></Badge>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 d-flex justify-content-end align-items-c p-t-15">
          <button @click="removeDiscount(d)" class="button-circle button-ghost button-error sm f-20">
            <i aria-hidden="true" class="banana banana-delete"></i>
          </button>
        </div>
      </div>
    </div>

    <a
      @click="addDiscount"
      class="button-square-add"
      tabindex
    >
      <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{ $t('pagos.descuentos.table.add') }}
    </a>

    <div class="row m-t-40">
      <div class="col-12 vertical-center justify-content-end">
        <button class="button button-ghost sm button-plain_text m-0-auto-0-0" @click="prev" v-if="!editMode">
          <i aria-hidden="true" class="banana banana-chevron-left"></i>
          {{$t('general.actions.back.title')}}
        </button>
        <div class="vertical-center">
          <button class="button button-ghost sm button-plain_text" @click="cancel">
            {{$t('general.actions.cancel.title')}}
          </button>
          <button class="button button-filled sm button-accent1" @click="next" :class="{disabled: !isValid()}" v-if="!editMode">
            {{$t('general.actions.next.title')}}
          </button>
          <button class="button button-filled sm button-accent1 m-l-10" @click="finish" :class="{disabled: !isValid()}" v-if="editMode">
            {{$t('general.actions.save')}}
          </button>
        </div>
      </div>
    </div>

  </CardSimple>

</template>

<script lang="js">
  import Badge from "@/components/Badge";
  import BasicCheckbox from "@/components/BasicCheckbox.vue";
  import BasicInput from "@/components/BasicInput.vue";
  import BasicSelect from "@/components/BasicSelect.vue";
  import CardSimple from '@/components/CardSimple.vue';
  import Datepicker from "vuejs-datepicker";
  import DateRangePicker from 'vue2-daterange-picker';
  import InputDatepicker from "@/components/InputDatepicker.vue";
  import Multiselect from "vue-multiselect";

  export default {
    name: 'discounts-debtors',
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        payment: {
            type: Object,
            default: function () {
                return {
                    discounts: []
                };
            }
        }
    },
    mounted () {
        this.payment.discounts.forEach((d, index) => {
            if (d.from && typeof d.from === 'string') {
                d.from = new Date(d.from);
            }
            if (d.to && typeof d.to === 'string') {
                d.to = new Date(d.to);
            }
            if (d.to) {
                d.expires = true;
                this.$refs[`expires_${index}`][0].checkStoreValue(true);
            }
        });
    },
    components: {
      Badge,
      BasicCheckbox,
      BasicInput,
      BasicSelect,
      CardSimple,
      Datepicker,
      DateRangePicker,
      InputDatepicker,
      Multiselect
    },
    data () {
      return {
        typeSelect: [
          { name: 'Cantidad' },
          { name: 'Porcentaje' }
        ],
        value: [],
        // Date Range Picker
        opens: 'left',
        showDropdowns: true,
        autoApply: true,
      }
    },
    methods: {
        update() {
            this.$forceUpdate();
        },
        typeChange(discount) {
            switch(discount.type.name) {
                case 'Cantidad':
                    discount.isPercentage = false;
                    break;
                case 'Porcentaje':
                default:
                    discount.isPercentage = true;
            }
            this.$forceUpdate();
        },
        expiresChange(discount) {
            if (discount.expires) {
                discount.range = 'DEFINED';
            } else {
                discount.range = 'START';
                discount.to = null;
            }
            this.$forceUpdate();
        },
        dateRangeChange(discount) {
            if (discount.dateRange) {
                discount.from = discount.dateRange.startDate;
                discount.to = discount.dateRange.endDate;
                this.$forceUpdate();
            }
        },
        dateChange(discount) {
            discount.to = null;
            this.$forceUpdate();
        },
        discountStatus(discount) {
          const now = new Date();
          if (!(discount.from && typeof discount.from === 'string') &&
                  !(discount.to && typeof discount.to === 'string') && discount.from && discount.from.getTime() < now.getTime()) {
            if (discount.to && discount.to.getTime() < now.getTime()) {
              return {type: 'badge-error m-l-20', text: this.$t('pagos.descuentos.table.expired')};
            }
            return {type: 'badge-info m-l-20', text: this.$t('pagos.descuentos.table.active')};
          }
          return {type: 'badge-disable m-l-20', text: this.$t('pagos.descuentos.table.inactive')};
        },
        removeDiscount(index) {
            this.payment.discounts.splice(index, 1);
            this.$forceUpdate();
        },
        addDiscount(e) {
            const newDiscount = {
                description: '',
                type: { name: 'Porcentaje' },
                isPercentage: true,
                discount: '0',
                expires: false,
                range: 'START',
                dateRange: {startDate: new Date(), endDate: null},
                from: new Date(),
                to: null
            };
            this.payment.discounts.push(newDiscount);
            e.preventDefault();
            this.$forceUpdate();
        },
        isValid() {
            if (this.payment.discounts.length > 0) {
                return !this.payment.discounts.find(d => !this.isDiscountValid(d));
            }
            return true;
        },
        isDiscountValid(discount) {
            if (!discount.description || discount.description.length <= 0) {
                return false;
            }
            if (!discount.discount || discount.discount <= 0) {
                return false;
            }
            if (!discount.from) {
                return false;
            }
            if (discount.expires && !discount.to) {
                return false;
            }
            return true;
        },
        cancel() {
            this.$emit('cancel');
        },
        next() {
            this.$emit('next');
        },
        prev() {
            this.$emit('prev');
        },
        finish() {
            this.$emit('finish');
        }
    },
    computed: {
    },
    watch: {
        "payment.discounts": function() {
            this.payment.discounts.forEach(d => {
                if (d.range === 'DEFINED') {
                    d.expires = true;
                }
                if (!d.dateRange) {
                    d.dateRange = {startDate: d.from, endDate: d.to};
                }
            });
            this.$forceUpdate();
        }
    }
}
</script>
