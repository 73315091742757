<template>
    <div>
        <!-- TOAST -->
        <vue-snotify></vue-snotify>

        <!-- LEFT SIDEBAR MENU -->
        <SidebarMenu
            :isSidebarSmall="isSidebarSmall"
            :isSidebarLeftOpen="isSidebarLeftOpen"
        >
            <template v-slot:closeBtn>
                <button
                    class="hamburger hamburger--arrowalt js-hamburger"
                    :class="{'is-active' : !isSidebarSmall}"
                    type="button"
                    @click="menuSidebar"
                >
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </template>
            <template v-slot:footerBtn>
                <div
                    class="actual-org"
                    @click="openSidebarLeft"
                >
                    <div class="img-org">
                        <img
                            class="img-fluid"
                            :src="organizationLogo(currentOrganization)"
                            alt="organization"
                        />
                    </div>
                    <span>
                        <label>{{$t('dashboard.organization')}}</label>
                        <p>{{currentOrganization.name}}</p>
                    </span>
                    <i
                        aria-hidden="true"
                        class="banana banana-triangle-down"
                    ></i>
                </div>
            </template>
        </SidebarMenu>

        <!-- TOP HEADER ADMIN -->
        <TopbarHeader v-on:openSideBar="openSidebarLeft">
            <li>
                <a
                    class="p-relative d-flex p-t-5 p-b-5 p-l-5 p-r-5 hover"
                    @click="toogleSidebarRight"
                    tabindex=""
                >
                    <i
                        aria-hidden="true"
                        class="banana f-30 c-accent_2"
                        v-bind:class="[isSidebarRightOpen ? 'banana-light-on': 'banana-light']"
                    ></i>
                    <span
                        v-if="evaluationHasErrors"
                        class="badge-circle badge-white"
                    >
                        <i
                            aria-hidden="true"
                            class="banana banana-info-circle2 c-error f-20"
                        ></i>
                    </span>
                </a>
            </li>
        </TopbarHeader>

        <!-- LEFT SIDEBAR OPTIONS -->
        <SidebarSide
            sidebarLeft
            :isSidebarLeftOpen="isSidebarLeftOpen"
            :titleLeft="$t('dashboard.admin-another-org')"
            :subtitleLeft="$t('dashboard.select-organization')"
        >
            <template v-slot:headLeft>
                <a
                    class="close-sidebar-left-header"
                    @click="openSidebarLeft"
                    tabindex=""
                >
                    <i
                        aria-hidden="true"
                        class="banana banana-cross-normal"
                    ></i>
                </a>
                <BasicInput
                    @input="setSearchText"
                    inputType="text"
                    :inputPlaceholder="$t('dashboard.search-organization')"
                    inputClazz="icon-left"
                    iconInput="banana-search-normal"
                    clazz="noLabel"
                />
            </template>

            <template v-slot:bodyLeft>

                <template v-if="activeOrganizations && activeOrganizations.length > 0">
                    <h1 class="title-group-org">{{$t('dashboard.active-organizations')}}</h1>
                    <OrgInfo
                        activeOrg
                        v-for="(org, index) in activeOrganizations"
                        v-bind:key="org._id"
                        :clazz="currentOrganization._id === org._id ? 'selected' : ''"
                        :orgName="org.name"
                        :status="getActualStatusOrganization(org)"
                        :numServices="getAllServices(index)"
                        :orgLogo="organizationLogo(org)"
                        @click="selectOrganization(org)"
                    />
                </template>

                <template v-if="inactiveOrganizations && inactiveOrganizations.length > 0">
                    <h1 class="title-group-org m-t-50">{{$t('dashboard.inactive-organizations')}}</h1>
                    <!-- Please add clazz: disabled-org -->
                    <OrgInfo
                        inactiveOrg
                        v-for="(org, index) in inactiveOrganizations"
                        v-bind:key="org._id"
                        :clazz="currentOrganization._id === org._id ? 'selected hover' : 'hover'"
                        :orgName="org.name"
                        :status="getActualStatusOrganization(org)"
                        :numServices="getAllServices(index)"
                        :orgLogo="organizationLogo(org)"
                        @click="selectOrganization(org)"
                    />
                </template>
                <template v-if="comingSoonOrganizations && comingSoonOrganizations.length > 0">
                    <h1 class="title-group-org m-t-50">{{$t('dashboard.coming-organizations')}}</h1>
                    <template
                        v-for="(org, index) in comingSoonOrganizations"
                        :v-bind:key="index"
                    >
                        <OrgInfo
                            :orgLogo="organizationLogo(org)"
                            waitingOrg
                            v-bind:key="`${org._id}-COMMING`"
                            :clazz="currentOrganization._id === org._id ? 'selected' : ''"
                            :status="getActualStatusOrganization(org)"
                            :orgName="org.name"
                            :numServices="getAllServices(index)"
                            @click="selectOrganization(org)"
                        />
                    </template>
                </template>

                <template v-if="blockedOrganizations && blockedOrganizations.length > 0">
                    <h1 class="title-group-org m-t-50">{{$t('dashboard.blocked-organizations')}}</h1>
                    <template
                        v-for="(org, index) in blockedOrganizations"
                        :v-bind:key="index"
                    >
                        <OrgInfo
                            :orgLogo="organizationLogo(org)"
                            waitingOrg
                            v-bind:key="org._id"
                            :status="getActualStatusOrganization(org)"
                            :orgName="org.name"
                            :numServices="getAllServices(index)"
                            @click="selectOrganization(org)"
                        />
                    </template>
                </template>

            </template>

            <template v-slot:footerLeft>
                <router-link
                    to="/register/organizations"
                    class="button-square-add"
                    tabindex=""
                ><i
                        aria-hidden="true"
                        class="banana banana-symbol-plus"
                    ></i> {{$t('dashboard.create-organization')}}
                </router-link>
            </template>
        </SidebarSide>

        <!-- RIGHT SIDEBAR -->
        <SidebarSide
            sidebarRight
            :isSidebarRightOpen="isSidebarRightOpen"
        >
            <template v-slot:contentRight>
                <div class="p-t-20">

                    <ChartProgressInfo
                        v-if="organizationEvaluationSections.length > 0 || currentOrganization.activeService"
                        :progress="organizationTotalAcceptedFields"
                        :doneProgress="organizationTotalEvaluatedFields"
                        :label="$t('layout.chart.label')"
                        :labelComplete="$t('layout.chart.label-complete')"
                    ></ChartProgressInfo>

                    <h1 class="f-13 c-primary_1 principal-font-bold f-bold text-uppercase d-block m-b-10 m-t-40">
                        {{$t(`layout.organization-status.${currentOrganization.status}`)}}
                    </h1>
                    <p class="f-13 c-plain_text body-font-regular f-normal d-block m-b-40">
                        {{organizationActivationMessage}}
                    </p>

                    <SimpleCollapse
                        v-for="section in organizationEvaluationSections"
                        :key="section.section"
                        v-b-toggle="section.section"
                        :idCollapse="section.section"
                        :title="$t(`organization.sections.${section.section}`)"
                        :showBadge="true"
                        :badgeType="rightMenuBadgeType(section)"
                        :badgeText="section.fields.length.toString()"
                    >
                        <b-alert
                            v-if="section && section.note && section.note.length > 0"
                            class="alert-note"
                            show
                            variant="info"
                        >
                            <p class="text-container p-r-0">
                                <span>
                                    <strong class="body-font-semibold f-600 c-accent_2">{{$t('layout.organization-activation-message.comment')}}</strong>
                                    <br />
                                    {{section.note}}
                                </span>
                            </p>
                        </b-alert>
                        <ul
                            class="timeline-verified"
                            v-for="field in section.fields"
                            :key="field.field"
                        >
                            <li :class="{check: field.score === 'GREEN', missing: field.score !== 'GREEN'}">
                                <!-- Field name in DOCUMENTS section are dynamic, thus it isn't i18ned -->
                                {{ section.section !== 'DOCUMENTS' ? $t(`organization.fields.${section.section}.${field.field}`) : field.field}}
                                <p
                                    class="f-13 c-info_text m-b-0"
                                    v-if="field.score !== 'GREEN'"
                                >
                                    {{$t(`layout.organization-activation-message.${ fieldsContact.indexOf(field.field) >= 0 ? 'link-contact' : 'link-edit'}`)}}
                                </p>
                                    <div v-if="fieldsContact.indexOf(field.field) === -1">
                                        <span v-if="section.section == 'DOCUMENTS'" class="c-accent_2 f-bold hover" @click="openFilesModal()">
                                            {{$t('layout.organization-activation-message.link-click')}}
                                        </span>
                                        <span v-else class="c-accent_2 f-bold hover" @click="goToOrganizationEdit(section)">
                                            {{$t('layout.organization-activation-message.link-click')}}
                                        </span>
                                    </div>

                                    <a
                                        v-else
                                        class="c-accent_2 f-bold hover"
                                        @click="openZendesk"
                                    >
                                        {{$t('layout.organization-activation-message.link-click')}}
                                    </a>
                            </li>
                        </ul>
                    </SimpleCollapse>

                    <LoadingBar v-if="loading"></LoadingBar>

                    <button
                        v-if="organizationEvaluationSections.length === 0"
                        type="button"
                        class="button xs button-filled button-accent1 w-100 m-b-30"
                        @click="toogleSidebarRight(false)"
                    >
                        {{$t('layout.organization-activation-message.accept')}}
                    </button>

                    <button
                        v-else
                        type="button"
                        class="button xs button-filled button-accent1 w-100 m-b-30"
                        @click="submitChanges()"
                    >
                        {{$t('layout.organization-activation-message.submit')}}
                    </button>


                </div>

                <div
                    v-if="showUpdates"
                    class="card-updates"
                >
                    <div>
                        <label>Qué hay de nuevo</label>
                        <p>
                            Revisa las últimas novedades lanzadas en la
                            <strong class>Versión 1.1</strong> del mes de enero.
                        </p>
                        <div class="vertical-center">
                            <Badge
                                type="badge-accent_2"
                                text="3 actualizaciones"
                            ></Badge>
                            <button
                                @click="closeUpdates"
                                class="button xs button-ghost button-primary1 f-12 p-t-0 p-b-0 p-l-5 p-r-5 m-0-0-0-auto"
                            >Ocultar
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </SidebarSide>

        <section id="main-view">
            <div class="page-container">
                <b-alert
                    v-if="currentOrganization.comingSoon"
                    class="alert-note"
                    show
                    :variant="organizationAlertVariant"
                >
                    <p class="text-container">
                        <img
                            class="img-fluid"
                            src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                            alt="info"
                        />
                        <span> {{organizationAlertMessage}}  <a class="c-accent_2 f-bold hover" @click="toogleSidebarRight()"> {{$t('layout.organization-status-alert.link')}} </a>
                        </span>
                    </p>
                </b-alert>
                <router-view />
            </div>
        </section>


        <ModalDefault
                modalId="modal-files"
                size=""
                :subtitle="organizationEvaluationFilesSection ? organizationEvaluationFilesSection.note : ''"
                originalFooter
                :buttonText="$t('general.accept')"
                :title="$t('layout.files-modal.title')"
                :hideCancelButton="true"
                @clickAccepted="closeFilesModal"
            >

            <OrganizationDocuments
                ref="OrganizationDocuments"
                v-model="organizationWithFiles"
                @deleteFile="deleteFile"
                @fileSelected="fileSelected"
                :filterFunction="(od => this.organizationEvaluationRedFiles.find(f => f.field === od.title))"
            ></OrganizationDocuments>
        </ModalDefault>

        <!-- BUTTON SCROLL TOP -->
        <ButtonScrollTop idScroll="#main-view"></ButtonScrollTop>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
// @ is an alias to /src
import TopbarHeader from '@/components/TopbarHeader.vue'
import SidebarMenu from '@/components/SidebarMenu.vue'
import SidebarSide from '@/components/SidebarSide.vue'
import OrgInfo from '@/components/OrgInfo.vue'
import BasicInput from '@/components/BasicInput.vue'
import ButtonScrollTop from '@/components/ButtonScrollTop.vue'
import Badge from '@/components/Badge.vue';
import ChartProgressInfo from '@/components/ChartProgressInfo.vue'
import utils2 from '@/common/utils';
import DefaultBlue from '@/assets/images/Backgrounds/Default-blue.svg';
import ToastSnotify from '@/mixins/toastSnotify.mixin';
import ModalDefault from '@/components/ModalDefault.vue';
import OrganizationDocuments from '@/views/Organizations/OrganizationDocuments.vue';

import {
    STORE as BanksStore,
    FETCH_BANKS
} from '@/store/modules/banks/banks.types';

import {
    STORE as AccessConfigStore,
    GET_ACTIVE_ORGANIZATIONS,
    GET_COMING_SOON_ORGANIZATIONS,
    GET_INACTIVE_ORGANIZATIONS,
    GET_BLOCKED_ORGANIZATIONS,
    SET_CURRENT_ORGANIZATION,
    GET_CURRENT_ORGANIZATION,
    FETCH_ORGANIZATIONS,
    FETCH_CURRENT_ORGANIZATION,
    GET_ORGANIZATIONS,
    SET_SEARCH_TEXT,
    GET_CURRENT_ORGANIZATION_WITH_DATA,
} from '@/store/modules/accessconfig/accessConfig.types';
import {
    STORE as SessionStore,
    CHANGE_OTHER_PROFILE_JWT,
    GET_USER,
} from '@/store/modules/session/session.types';
import { mapGetters } from 'vuex';
import { utils } from '@/api/utils';
import SimpleCollapse from "@/components/SimpleCollapse.vue";
import * as OrganizationStatus from "@/enums/organizationStatus.enum";
import OrganizationsApi from "@/api/organization/organizations.api";
import LoadingBar from '@/components/LoadingBar';
import abilityBuilder from "@/permissions/abilities";

export default {
    name: "LayoutClient",
    components: {
        SidebarMenu,
        TopbarHeader,
        SidebarSide,
        OrgInfo,
        BasicInput,
        ChartProgressInfo,
        ButtonScrollTop,
        SimpleCollapse,
        Badge,
        ModalDefault,
        OrganizationDocuments,
        LoadingBar,
    },
    mixins: [ToastSnotify],
    props: {},
    data () {
        return {
            isSidebarLeftOpen: false,
            isSidebarRightOpen: true,
            isSidebarSmall: false,
            noSmall: false,
            organizationWithFiles: {},
            window: {
                width: 0
            },
            showUpdates: false,
            // Campos que requieren contacto a soporte para cambiarse
            fieldsContact: [
                "fiscalSituation",
                "legalName",
                "rfc",
                "streetAddress",
                "exteriorNumber",
                "interiorNumber",
                "district",
                "state",
                "city",
                "zipCode",
            ],
            loading: false,
        }
    },
    mounted () {
        // Init function
        this.handleResize();
        // Always watch window resize
        window.addEventListener('resize', this.handleResize);
        this.$store.dispatch(`${AccessConfigStore}/${FETCH_ORGANIZATIONS}`);
        this.$store.dispatch(`${BanksStore}/${FETCH_BANKS}`);
    },
    destroyed () {
        // Always remove window watch resize
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        getAllServices (index) {
            const organization = this.getOrganizations[index] || {};
            const paymentsSize = organization.paymentsSize;
            const debtsSize = organization.debtsSize;
            const expressPaymentsSize = organization.expressPaymentsSize;
            const webServicePaymentsSize = organization.webServicePaymentsSize;
            let totalOfServices = paymentsSize + debtsSize + expressPaymentsSize + webServicePaymentsSize;
            totalOfServices = totalOfServices > 0 ? totalOfServices : 0;
            return this.$tc('layout.organization-services-count', totalOfServices);
        },
        organizationLogo (organization) {
            return (organization && organization.logo && organization.logo.cropped) ? utils.imageDownloadLink(organization.logo.cropped) : DefaultBlue;
        },
        setSearchText: utils2.debounce(function (value) {
            this.$store.commit(`${AccessConfigStore}/${SET_SEARCH_TEXT}`, value)
        }, 400, false),
        // Open / Close component 'SidebarSide' Left
        openSidebarLeft () {
            this.isSidebarLeftOpen = !this.isSidebarLeftOpen;
        },
        closeSidebarLeft () {
            this.isSidebarLeftOpen = !this.isSidebarLeftOpen;
        },
        // Open / Close component 'SidebarSide' Right
        toogleSidebarRight (open) {
            this.isSidebarRightOpen = (open === true || open === false) ? open : !this.isSidebarRightOpen;
        },
        // Toggle class for component 'SidebarMenu'
        menuSidebar () {
            this.isSidebarSmall = !this.isSidebarSmall;
        },
        getActualStatusOrganization (organization) {
            if (organization.adminSwitch === false) {
                return 'blocked'
            }
            if (organization.comingSoon) {
                return 'comingSoon';
            }
            if (organization.activeService) {
                return 'active'
            }
            return "inactive";
        },
        // When window changes
        handleResize () {
            this.window.width = window.innerWidth;
            // Change 'SidebarMenu' to small
            if (window.innerWidth < 1281) {
                this.noSmall = true;
                this.isSidebarSmall = true;
            } else {
                this.noSmall = false;
                this.isSidebarSmall = false;
            }
        },
        // Close div 'card-updates' from 'SidebarSide' Right
        closeUpdates () {
            this.showUpdates = false;
        },
        selectOrganization (organization) {
            const profile = this.getOrganizations.find(o => o.organization._id === organization._id)
            if (organization.adminSwitch === false) {
                this.displayNotificationError(this.$t("organizaciones.general.error.blocked-organization-select"));
                return;
            }
            this.$store.dispatch(`${SessionStore}/${CHANGE_OTHER_PROFILE_JWT}`, { idProfile: profile._id }).then((resultData) => {
              const builder = abilityBuilder.buildAbilities({
                profile: resultData.profile,
                permissions: resultData.permissions
              });
              this.$router.app.$ability.update(builder);
                this.$store.commit(`${AccessConfigStore}/${SET_CURRENT_ORGANIZATION}`, organization);
                this.$store.dispatch(`${AccessConfigStore}/${FETCH_CURRENT_ORGANIZATION}`);
            });
            this.closeSidebarLeft();
        },
        rightMenuBadgeType (section) {
            if (section && section.fields) {
                const fieldWithError = section.fields.find(f => f.score !== 'GREEN');
                if (fieldWithError) {
                    return '';
                }
            }
            return 'badge-accent_2';
        },
        openZendesk () {
            const launcher = window.document.getElementById('launcher')
            const buttons = launcher.contentDocument.getElementsByClassName('wrapper-AtBcr');
            if (buttons !== undefined && buttons.length > 0) {
                buttons[0].click();
            }
        },
        fileSelected({document, file}) {
            const ref = this.$refs.OrganizationDocuments;
            ref.setDocumentLoading(document, true);
            OrganizationsApi.uploadDocument(document, file).then((data) => {
                ref.pushDocumentToOrganization(data.data.object);
                ref.setDocumentLoading(document, false);
            }).catch((error) => {
                this.toastErrorDefault(error);
            });
        },
        deleteFile(document) {
            const ref = this.$refs.OrganizationDocuments;
            ref.setDocumentDeleting(document, true);
            ref.refreshDocuments();
            OrganizationsApi.deleteDocument({documentId: document._id}).then((data) => {
                ref.spliceDocumentToOrganization(data.data.object);
                ref.setDocumentDeleting(document, false);
            }).catch((error) => {
                this.toastErrorDefault(error);
            });
        },
        openFilesModal(){
            OrganizationsApi.getOrganizationSavedFiles((result)=> {
                this.organizationWithFiles = result.data.object;
                this.$bvModal.show('modal-files')
            },
            (error) => {
                this.toastErrorDefault(error);
            })
        },
        goToOrganizationEdit(section){
            this.$router.push({name: 'OrganizationInfo', query: {section: section.section}});
        },
        closeFilesModal(){
            this.$bvModal.hide('modal-files')
        },
        submitChanges() {
            this.loading = true;
            OrganizationsApi.submitForReview()
            .then(() => this.$store.dispatch(`${AccessConfigStore}/${FETCH_CURRENT_ORGANIZATION}`))
            .then(() => {
                this.toastSuccessDefault();
                this.toogleSidebarRight(false);
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                this.toastErrorDefault(error);
            });
        }
    },
    computed: {
        ...mapGetters(AccessConfigStore, {
            activeOrganizations: GET_ACTIVE_ORGANIZATIONS,
            comingSoonOrganizations: GET_COMING_SOON_ORGANIZATIONS,
            inactiveOrganizations: GET_INACTIVE_ORGANIZATIONS,
            blockedOrganizations: GET_BLOCKED_ORGANIZATIONS,
            currentOrganization: GET_CURRENT_ORGANIZATION,
            getOrganizations: GET_ORGANIZATIONS,
            currentOrganizationWithData: GET_CURRENT_ORGANIZATION_WITH_DATA,
        }),
        ...mapGetters(SessionStore, { user: GET_USER }),
        organizationAlertVariant () {
            if (this.currentOrganization && this.currentOrganization.status === OrganizationStatus.infoRequest) {
                return 'warning';
            }
            return 'info';
        },
        organizationAlertMessage () {
            if (this.currentOrganization && this.currentOrganization.status === OrganizationStatus.infoRequest) {
                return this.$t('layout.organization-status-alert.info-request', { userName: this.user.name });
            }
            return this.$t('layout.organization-status-alert.coming-soon', { userName: this.user.name });
        },
        organizationActivationMessage () {
            if (
                !this.currentOrganization.activeService &&
                this.currentOrganizationWithData &&
                this.currentOrganizationWithData.statusActivity &&
                this.currentOrganizationWithData.statusActivity.note &&
                this.currentOrganizationWithData.statusActivity.note.length > 0) {
                return this.currentOrganizationWithData.statusActivity.note;
            }
            return this.$t(`layout.organization-activation-message.${this.currentOrganization.status}`)
        },
        organizationEvaluationSections () {
            if (
                !this.currentOrganization.activeService &&
                this.currentOrganization.status === OrganizationStatus.infoRequest &&
                this.currentOrganizationWithData &&
                this.currentOrganizationWithData.evaluationActivity &&
                this.currentOrganizationWithData.evaluationActivity.evaluation &&
                this.currentOrganizationWithData.evaluationActivity.evaluation.sections &&
                this.currentOrganizationWithData.evaluationActivity.evaluation.sections.length > 0) {
                return this.currentOrganizationWithData.evaluationActivity.evaluation.sections.filter(s => s && s.fields && s.fields.length > 0);
            }
            return [];
        },
        organizationEvaluationFilesSection () {
            if (
                !this.currentOrganization.activeService &&
                this.currentOrganizationWithData &&
                this.currentOrganizationWithData.evaluationActivity &&
                this.currentOrganizationWithData.evaluationActivity.evaluation &&
                this.currentOrganizationWithData.evaluationActivity.evaluation.sections &&
                this.currentOrganizationWithData.evaluationActivity.evaluation.sections.length > 0) {
                return this.currentOrganizationWithData.evaluationActivity.evaluation.sections
                    .filter(s => s && s.fields && s.fields.length > 0).find(s => s.section === "DOCUMENTS")
            }
            return {};
        },
        organizationEvaluationRedFiles () {
            if (this.organizationEvaluationFilesSection && this.organizationEvaluationFilesSection.fields){
                return this.organizationEvaluationFilesSection.fields.filter(f => f.score === "RED")
            }
            return [];
        },
        organizationTotalEvaluatedFields () {
            // To force the chart to be complete
            if (this.currentOrganization.activeService) {
                return 1;
            }
            let fields = 0;
            this.organizationEvaluationSections.forEach(s => s.fields.forEach(() => fields++));
            return fields;
        },
        organizationTotalAcceptedFields () {
            // To force the chart to be complete
            if (this.currentOrganization.activeService) {
                return 1;
            }
            let fields = 0;
            this.organizationEvaluationSections.forEach(s => s.fields.filter(f => f.score === 'GREEN').forEach(() => fields++));
            return fields;
        },
        evaluationHasErrors () {
            return !!this.organizationEvaluationSections.find(s => s && s.fields && !!s.fields.find(f => f && f.score === 'RED'));
        },
    },
    watch: {
        '$route': {
            handler () {
                this.toogleSidebarRight(false);
            },
            deep: true
        },
        'isSidebarRightOpen': {
            handler() {
                if (this.isSidebarRightOpen) {
                    // Update data
                    this.$store.dispatch(`${AccessConfigStore}/${FETCH_CURRENT_ORGANIZATION}`);
                }
            }
        }
    }
};
</script>
