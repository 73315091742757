import {EventBus} from "@/main";
import WizardForm from '@/components/WizardForm.vue';
import CardSimple from '@/components/CardSimple.vue';

export default {
    components: {
        WizardForm,
        CardSimple
    },
    computed: {},
    methods: {
        nextStep(currentStep) {
            EventBus.$emit(`wizard#goTo`, {from: currentStep - 1, to: currentStep});
        },
        previousStep(currentStep){
            EventBus.$emit(`wizard#goTo`, { from: currentStep - 1, to: currentStep - 2});
        },
        goToApi(){
            this.$router.replace("/client-admin/apiWeb/api-checkoutbutton/PRESTASHOP");
        }
    }
};
