<template>
    <p class="required-Fields"><span class="text-style-1" >*</span> {{text}}
    </p>
</template>

<script>
    export default {
        data () {
            return {
            }
        },
        components: {
        },
        props: {
            text: {
                type: String,
                required: true
            },
        }
    }
</script>
