<template>
    <li>
        <label> {{label}} </label>
        <p :id="idPgh">{{content}}</p>
        <div class="extra-content">
            <slot></slot>
        </div>
    </li>
</template>

<script>
    export default {
        name: 'SimpleTimelineInfoDot',
        props: {
            idPgh: String,
            label: String,
            content: String,
        }
    }
</script>

<style lang="scss">
    .simple-timeline-info {
        li {
            position: relative;
            display: block;
            padding: 0 10px 15px 15px;

            &::before {
                content: " ";
                height: 100%;
                width: 1px;
                position: absolute;
                left: 0;
                top: 6px;
            }

            &::after {
                content: " ";
                height: 9px;
                width: 9px;
                border-radius: 50%;
                position: absolute;
                top: 4px;
                left: -4px;
            }

            &:last-of-type::before {
                height: 0;
            }

            > label {
                font-size: 13px;
                font-weight: normal;
                line-height: 1.31;
                display: block;
                margin-bottom: 0px;
            }

            > p {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.29;
                display: block;
                margin-bottom: 0px;
            }
        }
    }
</style>
