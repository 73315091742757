import api from '@/api/user/users.api'


const stateGlobal = {
    actualUser: {},
    isLoadUser: false
};


const getters = {
    getActualUser: (state) => state.actualUser
};

const actions = {
    async getCurrentUserSession({commit}) {

        const response = await api.getCurrentUserSession();

        if (response.data.error) {
            // TODO: Alert message
        } else {
            commit('ACTUAL_USER', response.data.user);

        }
    },

};

const mutations = {
    ACTUAL_USER(state, value){
        state.actualUser = value;
        state.isLoadUser = true;
    },
};


export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
