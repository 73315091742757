<template>
    <div>
        <a
            href=""
            class="button xs button-ghost button-primary1 m-b-40 p-l-0 p-r-5"
            tabindex=""
        > <i aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('recovery-password-success.return-login')}} </a>

        <GenericTitle
            :title="$t('recovery-password-success.title')"
            :titleStrong="$t('recovery-password-success.title-strong')"
            :subtitle="$t('recovery-password-success.subtitle')"
        >
        </GenericTitle>

        <img
            class="img-fluid d-flex m-0-auto m-b-40"
            src="@/assets/images/Illustrations/key.svg"
            alt="Imagen recuperar contraseña"
        />

        <router-link :to="{name : 'Signin'}">
            <button
                id="restorePasswordSuccess"
                class="button xs button-filled button-accent1 m-b-40 w-100"
                type="submit"
            > {{$t('recovery-password-success.return-login-button')}} </button>
        </router-link>
    </div>
</template>

<script>
// @ is an alias to /src
import GenericTitle from '@/components/GenericTitle.vue'
import {
    STORE as UserSignUpStore,
} from '@/store/modules/signin/signin.types'

export default {
    name: "SignIn",
    components: {
        GenericTitle,
    },
    created () {
        this.$store.commit(`${UserSignUpStore}/SET_RIGHT_SIDE_BODY`, 'DONT_WORRY');
    }
};
</script>
