<template>
    <CardSimple
        :title="$t('organizaciones.contact-info.title')"
        :subtitle="$t('organizaciones.contact-info.description')"
    >
        <div class="row">
            <LoadingBar
                v-if="loading"
                :loadingText="$t('general.info.loading')"
            ></LoadingBar>
            <div class="col-12">
                <BasicInput
                    v-model="contactInfoToEdit.address"
                    inputType="text"
                    labelFor="address"
                    inputId="address"
                    :label="$t('organizaciones.contact-info.address')"
                    :inputPlaceholder="$t('organizaciones.contact-info.address-placeholder')"
                ></BasicInput>
            </div>
            <div class="col-12">
                <TelInput
                    v-model="contactInfoToEdit.phone"
                    labelFor="telContact"
                    :label="$t('organizaciones.contact-info.contact-phone')"
                    help
                    :helpText="$t('organizaciones.contact-info.whats-this')"
                    helpIcon="banana banana-question-circle1"
                    idHelpTooltip="tel-contact-tooltip"
                    idInput="telContact"
                    :inputPlaceholder="$t('organizaciones.contact-info.contact-phone-placeholder')"
                >
                    <template v-slot:tooltipHelp>
                        <b-tooltip
                            target="tel-contact-tooltip"
                            placement="top"
                            custom-class="white-tooltip"
                        >
                            <div>
                                <p class="m-b-0">{{$t('organizaciones.contact-info.contact-phone-help-text')}}</p>
                            </div>
                        </b-tooltip>
                    </template>
                </TelInput>
            </div>
            <div class="col-12">
                <TelInput
                    v-model="contactInfoToEdit.organizationPhone"
                    labelFor="organizationPhone"
                    :label="$t('organizaciones.contact-info.organization-phone')"
                    help
                    :helpText="$t('organizaciones.contact-info.whats-this')"
                    helpIcon="banana banana-question-circle1"
                    idHelpTooltip="org-tel-tooltip"
                    idInput="organizationPhone"
                    :inputPlaceholder="$t('organizaciones.contact-info.organization-phone-placeholder')"
                >
                    <template v-slot:tooltipHelp>
                        <b-tooltip
                            target="org-tel-tooltip"
                            placement="top"
                            custom-class="white-tooltip"
                        >
                            <div>
                                <p class="m-b-0">{{$t('organizaciones.contact-info.org-phone-help-text')}}</p>
                            </div>
                        </b-tooltip>
                    </template>
                </TelInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    v-model="contactInfoToEdit.email"
                    inputType="email"
                    labelFor="email"
                    inputId="email"
                    :label="$t('organizaciones.contact-info.email')"
                    :inputPlaceholder="$t('organizaciones.contact-info.email-placeholder')"
                    help
                    :helpText="$t('organizaciones.contact-info.who-see-this')"
                    helpIcon="banana banana-question-circle1"
                    idHelpTooltip="email-tooltip"
                    :validations="$v.contactInfoToEdit.email"
                    validationsNamespace="general.validations"
                >
                    <template v-slot:tooltipHelp>
                        <b-tooltip
                            target="email-tooltip"
                            placement="top"
                            custom-class="white-tooltip"
                        >
                            <div>
                                <p class="m-b-0">{{$t('organizaciones.contact-info.contact-email-help-text')}}</p>
                            </div>
                        </b-tooltip>
                    </template>
                </BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <div class="vertical-center flex-initial">
                    <BasicInput
                        v-model="contactInfoToEdit.urlAlias"
                        inputType="text"
                        labelFor="urlAlias"
                        inputId="urlAlias"
                        :label="$t('organizaciones.informacion-general.url-alias.label')"
                        :inputPlaceholder="$t('organizaciones.informacion-general.url-alias.input-place-holder')"
                        help
                        :helpText="$t('organizaciones.contact-info.whats-this')"
                        helpIcon="banana banana-question-circle1"
                        idHelpTooltip="email-alias-tooltip"
                        :validations="$v.contactInfoToEdit.urlAlias"
                        validationsNamespace="general.validations"
                        :warningText="aliasInputInfo.aliasUrlWarningMessage"
                        :hasError="aliasInputInfo.hasError"
                        @input="checkAliasUrl"
                    >
                        <template v-slot:tooltipHelp>
                            <b-tooltip
                                target="email-alias-tooltip"
                                placement="top"
                                custom-class="white-tooltip"
                            >
                                <div>
                                    <p class="m-b-0">{{$t('organizaciones.contact-info.url-alias-help-text')}}</p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicInput>
                </div>
            </div>
            <div class="col-12">
                <BasicInput
                    v-model="contactInfoToEdit.site"
                    inputType="text"
                    labelFor="site"
                    inputId="site"
                    :label="$t('organizaciones.contact-info.site')"
                    :inputPlaceholder="$t('organizaciones.contact-info.site-placeholder')"
                    help
                    :helpText="$t('organizaciones.contact-info.who-see-this')"
                    helpIcon="banana banana-question-circle1"
                    idHelpTooltip="org-address-tooltip"
                    :validations="$v.contactInfoToEdit.site"
                    validationsNamespace="general.validations"
                >
                    <template v-slot:tooltipHelp>
                        <b-tooltip
                            target="org-address-tooltip"
                            placement="top"
                            custom-class="white-tooltip"
                        >
                            <div>
                                <p class="m-b-0">{{$t('organizaciones.contact-info.website-help-text')}}</p>
                            </div>
                        </b-tooltip>
                    </template>
                </BasicInput>
            </div>
            <div class="col-12">
                <div class="vertical-center m-b-15">
                    <label class="f-14 c-title body-font-regular d-block m-0-auto-0-0"> {{$t('organizaciones.contact-info.private')}} </label>
                    <label id="tooltip-privacidad"  class="f-13 c-info body-font-regular m-0-0-0-auto vertical-center">
                        {{$t('organizaciones.contact-info.private-help')}}
                        <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                    </label>
                    <b-tooltip
                        target="tooltip-privacidad"
                        placement="top"
                        custom-class="white-tooltip">
                        <div>
                            <p class="m-b-0"> {{$t('organizaciones.contact-info.private-tooltip')}} </p>
                        </div>
                    </b-tooltip>
                </div>
                <RadioInputGroup
                        wrapperClass=""
                        :options="[{value: false, label: $t('pagos.general.advanced.privacy.public')}, {value: true, label: $t('pagos.general.advanced.privacy.private')}]"
                        v-model="contactInfoToEdit.private"
                ></RadioInputGroup>
            </div>
            <div class="col-12 m-t-80">
                <div class="title-card">
                    <h1>
                        {{$t('organizaciones.social-networks.title')}}
                        <label>{{$t('organizaciones.social-networks.description')}}</label>
                    </h1>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                        <BasicInput
                            labelFor="facebook"
                            inputId="facebook"
                            v-model="contactInfoToEdit.facebook"
                            inputType="text"
                            :label="$t('general.social-networks.facebook')"
                            :inputPlaceholder="$t('organizaciones.social-networks.facebook-placeholder')"
                            inputClazz="icon-left"
                            iconInput="banana-shape-circle bgm-facebook brd-50prc c-white"
                            :validations="$v.contactInfoToEdit.facebook"
                            validationsNamespace="general.validations"
                        ></BasicInput>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <BasicInput
                            labelFor="twitter"
                            inputId="twitter"
                            v-model="contactInfoToEdit.twitter"
                            inputType="text"
                            :label="$t('general.social-networks.twitter')"
                            :inputPlaceholder="$t('organizaciones.social-networks.twitter-placeholder')"
                            inputClazz="icon-left"
                            iconInput="banana-shape-circle bgm-twitter brd-50prc c-white bana"
                            :validations="$v.contactInfoToEdit.twitter"
                            validationsNamespace="general.validations"
                        ></BasicInput>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <BasicInput
                            labelFor="instagram"
                            inputId="instagram"
                            v-model="contactInfoToEdit.instagram"
                            inputType="text"
                            :label="$t('general.social-networks.instagram')"
                            :inputPlaceholder="$t('organizaciones.social-networks.instagram-placeholder')"
                            inputClazz="icon-left"
                            iconInput="banana-shape-circle bgm-instagram brd-50prc c-white"
                            :validations="$v.contactInfoToEdit.instagram"
                            validationsNamespace="general.validations"
                        ></BasicInput>
                    </div>
                </div>
            </div>
            <div class="col-12 m-t-40 vertical-center justify-content-end">
                <button
                    type=""
                    class="button xs button-ghost button-plain_text"
                    @click="cancel"
                >{{$t('general.actions.cancel.title')}}</button>
                <button
                    type=""
                    class="button xs button-filled button-accent1 m-l-10"
                    @click="updateContactInfo"
                    :disabled="loading || hasError"
                >{{$t('general.actions.save-changes')}}</button>
            </div>
        </div>
        <ModalDefault
            modalId="modal-compartir"
            originalFooter
            :title="$t('organizaciones.contact-info.modal.share.title')"
            :subtitle="$t('organizaciones.contact-info.modal.share.subtitle')"
            :buttonClose="$t('general.actions.cancel.title')"
            :buttonText="$t('general.actions.done.title')"
            @clickAccepted="saveEmails"
            @clickClose="clearTags"
            :disabled="!isValid"
        >
            <div class="row">
                <div class="col-12 vertical-center m-b-30">
                    <LoadingBar
                        v-if="loading"
                        :loadingText="$t('general.info.loading')"
                    ></LoadingBar>
                    <div class="w-90">
                        <BasicInput
                            clazz="m-b-0"
                            labelFor="direct-link"
                            inputId="direct-link"
                            inputType="text"
                            readOnly
                            disabled
                            :label="$t('organizaciones.contact-info.modal.direct-link')"
                            :inputPlaceholder="$t('organizaciones.informacion-general.url-alias.input-place-holder')"
                            v-model="micrositeUrl"
                        ></BasicInput>
                    </div>
                    <div class="w-10 vertical-center justify-content-end m-t-15">
                        <button
                            class="button-circle sm button-ghost button-accent2"
                            v-b-tooltip.hover
                            :title="$t('organizaciones.contact-info.buttons.copy-link')"
                            @click="copyClipboard"
                        >
                            <i aria-hidden="true" class="banana banana-copy"></i>
                        </button>
                    </div>
                </div>
                <div class="col-12" v-if="copiedToClipboard">
                    <b-alert
                        class="alert-note"
                        show
                        dismissible
                        variant="success"
                    >
                        <p class="text-container">
                            <i aria-hidden="true" class="icon banana banana-check-normal"></i>
                            <span v-html="$t('share.copied')"></span>
                        </p>
                    </b-alert>
                </div>
                <div class="col-12">
                    <BasicSelect
                        :label="$t('organizaciones.contact-info.modal.people.title')"
                        labelFor="people"
                    >
                        <multiselect
                            v-model="valueTags"
                            :placeholder="$t('organizaciones.contact-info.modal.people.placeholder')"
                            :options="optionTags"
                            tag-placeholder="Agregar como nuevo tag"
                            label="email"
                            track-by="email"
                            :preselect-first="false"
                            :multiple="true"
                            :taggable="true"
                            @search-change="tagAdded"
                        >
                        </multiselect>
                    </BasicSelect>
                </div>
                <div class="col-12" v-if="$v.valueTags.$error && valueTags.length > 0">
                    <b-alert
                        class="alert-note"
                        show
                        dismissible
                        variant="danger"
                    >
                        <p class="text-container">
                            <i aria-hidden="true" class="icon banana banana-check-normal"></i>
                            <span v-html="$t('general.validations.email')"></span>
                        </p>
                    </b-alert>
                </div>
                <div class="col-md-12">
                    <SocialButtons>
                        <div class="row">
                            <div class="col-md-12">
                                <p>{{ $t('organizaciones.social-networks.title') }}</p>
                            </div>
                            <div class="col-md-6">
                                <a class="social-btn fb" tabindex @click="shareFB">
                                    <img
                                        class="img-fluid"
                                        src="@/assets/images/Icons/facebook-icon.svg"
                                        alt="facebook"
                                    />
                                    {{ $t('general.social-networks.share') }}
                                    <strong>{{ $t('general.social-networks.facebook') }}</strong>
                                </a>
                            </div>
                            <div class="col-md-6">
                                <a class="social-btn tw" tabindex @click="shareTW">
                                    <img
                                        class="img-fluid"
                                        src="@/assets/images/Icons/twitter-icon.svg"
                                        alt="twitter"
                                    />
                                    {{ $t('general.social-networks.share') }}
                                    <strong>{{ $t('general.social-networks.twitter') }}</strong>
                                </a>
                            </div>
                        </div>
                    </SocialButtons>
                </div>
            </div>
        </ModalDefault>
    </CardSimple>
</template>
<script>
import CardSimple from '@/components/CardSimple.vue';
import BasicInput from '@/components/BasicInput.vue';
import TelInput from '@/components/TelInput.vue';
import RadioInputGroup from '@/components/RadioInputGroup.vue';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION, FETCH_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import { STORE as ORGANIZATION_STORE, GET_CONTACT_INFO, FETCH_CONTACT_INFO, UPDATE_CONTACT_INFO } from "@/store/modules/organization/organization.types";
import { mapGetters } from 'vuex';
import { utils } from '@/api/utils';
import utilsCommon from '@/common/utils';
import { email, alphaNum, url, required, minLength, maxLength } from 'vuelidate/lib/validators';
import LoadingBar from '@/components/LoadingBar.vue';
import ModalDefault from '@/components/ModalDefault.vue';
import SocialButtons from '@/components/SocialButtons.vue';
import BasicSelect from '@/components/BasicSelect.vue';
import multiselect from "vue-multiselect";
import config from '@/config';
import OrganizationsAPI from '@/api/organization/organizations.api.js';
import { shareFacebook, shareTwitter} from '@/common/SocialNetworks';

const SUCCESS_TITLE_KEY = 'general.actions.success.title';
const ERROR_TITLE_KEY = 'general.actions.error.title';
const ERROR_TEXT_KEY = 'general.actions.error.text';

export default {
    components: {
        CardSimple,
        BasicInput,
        TelInput,
        LoadingBar,
        ModalDefault,
        SocialButtons,
        BasicSelect,
        multiselect,
        RadioInputGroup
    },
    data() {
        return {
            copiedToClipboard: false,
            loading: false,
            phone: {},
            organizationPhone: {},
            contactInfoToEdit: {
                address: "",
                phone: "",
                email: "",
                urlAlias: "",
                organizationPhone: "",
                site: "",
                facebook: "",
                instragram: "",
                twitter: "",
                private: false
            },
            valueTags: [],
            optionTags: [],
            searchTag: '',
            serverError: false,
            serverErrorMessage: '',
            serverSuccess: false,
            serverSuccessMessage: '',
            aliasInputInfo: {
                aliasUrlWarningMessage: "",
                hasError: false
            }
        };
    },
    created() {
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_CONTACT_INFO}`, this.currentOrganization._id).then(() => {
            this.prepareData();
            this.loading = false;
        });
    },
    methods: {
        prepareData() {
            this.contactInfoToEdit = JSON.parse(JSON.stringify(this.contactInfoComputed));
            this.loading = false;
        },
        cancel() {
            this.prepareData();
            utils.toast(this.$snotify, 'info', this.$t('general.actions.cancel.title'), this.$t('general.actions.cancel.text'));
        },
        updateContactInfo() {
            this.loading = true;
            const toSend = JSON.parse(JSON.stringify(this.contactInfoToEdit));
            this.$store.dispatch(`${ORGANIZATION_STORE}/${UPDATE_CONTACT_INFO}`, {_id: this.currentOrganization._id, body: toSend}).then(() => {
                utils.toast(this.$snotify, 'success', this.$t(SUCCESS_TITLE_KEY), this.$t('general.actions.success.text'));
                this.$store.dispatch(`${ACCESS_STORE}/${FETCH_CURRENT_ORGANIZATION}`);
            }).catch((error) => {
                this.loading = false;
                if (error.response && error.response.data && error.response.data.message) {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        copyClipboard() {
            navigator.clipboard.writeText(this.micrositeUrl).then(() => {
                utils.toast(this.$snotify, 'success', this.$t('general.actions.copy-clipboard.success'), this.$t(SUCCESS_TITLE_KEY));
                this.copiedToClipboard = true;
            }, () => {
                utils.toast(this.$snotify, 'error', this.$t('general.actions.copy-clipboard.error'), this.$t(ERROR_TITLE_KEY));
            });
        },
        shareFB() {
            shareFacebook(this.micrositeUrl);
        },
        shareTW() {
            shareTwitter(this.micrositeUrl);
        },
        tagAdded(query){
            if(query.length > 0) {
                this.searchTag = query;
            } else if (this.searchTag.length > 0 && !this.valueTags.find(t => t.email === this.searchTag)) {
                const emails = this.searchTag.split(',');
                emails.forEach(e => this.valueTags.push({email: e.split(' ').join('')}));
                this.searchTag = '';
            }
        },
        saveEmails() {
            this.loading = true;
            OrganizationsAPI.share(this.valueTags).then(() => {
                this.loading = false;
                this.clearTags();
                utils.toast(this.$snotify, 'success', this.$t('general.actions.people-emails.success'), this.$t(SUCCESS_TITLE_KEY));
            }).catch((error) => {
                this.clearTags();
                this.loading = false;
                if (error.response && error.response.data && error.response.data.message) {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        clearTags() {
            this.valueTags.length = 0;
        },
        searchUrlAlias(){
            if(!this.contactInfoToEdit.urlAlias || this.contactInfoToEdit.urlAlias.length < 3){
                return;
            }
            this.loading = true;
            OrganizationsAPI.searchUrlAlias({
                urlAlias: this.contactInfoToEdit.urlAlias,
                id: this.currentOrganization._id
            }).then(() => {
                this.loading = false;
                this.serverSuccess = true;
                this.serverSuccessMessage = this.$t('general.actions.url-alias.success');
                this.aliasInputInfo.aliasUrlWarningMessage = this.serverSuccessMessage;
            }).catch((error) => {
                this.loading = false;
                if (error.response && error.response.data && error.response.data.message) {
                    this.serverError = true;
                    this.serverErrorMessage = error.response.data.message;
                    this.aliasInputInfo.aliasUrlWarningMessage = this.serverErrorMessage;
                    this.aliasInputInfo.hasError = true;

                } else {
                    this.serverError = true;
                    this.serverErrorMessage = this.$t(ERROR_TEXT_KEY);
                    this.aliasInputInfo.aliasUrlWarningMessage = this.serverErrorMessage;
                    this.aliasInputInfo.hasError = true;
                }
            });
        },
        checkAliasUrl(){
            this.aliasInputInfo.hasError = false;
            this.aliasInputInfo.aliasUrlWarningMessage = "";
            this.debouncedSearchAlias()
        },
        debouncedSearchAlias: utilsCommon.classicDebounce(
            function(){
                this.searchUrlAlias()
            }, 1000, false
        )
    },
    computed: {
        hasError () { return this.$v.contactInfoToEdit.$invalid || this.aliasInputInfo.hasError},
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
        ...mapGetters(ORGANIZATION_STORE, {
            contactInfo: GET_CONTACT_INFO
        }),
        contactInfoComputed() {
            return this.contactInfo || {};
        },
        isValid() {
            if(this.valueTags.length <= 0){
                return false;
            }
            return !this.$v.valueTags.$error;
        },
        micrositeUrl() {
            if (config.users.host === "http://localhost") {
                return `${config.users.host}:${config.users.port}/${this.contactInfo.urlAlias}`
            }
            return `${config.users.host}/${this.contactInfo.urlAlias}`
        }
    },
    watch: {
        currentOrganization () {
            if (this.currentOrganization) {
                this.loading = true;
                this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_CONTACT_INFO}`, this.currentOrganization._id).then(() => {
                    this.prepareData();
                });
            }
        }
    },
    validations: {
        contactInfoToEdit: {
            address: {},
            phone: {},
            contactPhone: {},
            email: { email },
            urlAlias: {
                alphaNum,
                minLength: minLength(3),
                maxLength: maxLength(30)
            },
            organizationPhone: {},
            site: { url },
            facebook: { },
            instagram: { },
            twitter: { }
        },
        valueTags: {
            required,
            $each: {
                email
            }
        }
    }
}
</script>
