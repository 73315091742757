<template>
    <div class="progress-circle" v-bind:class="[progressDone ? 'loading-done': '', clazz]">
      <div v-if="!progressDone && !progressRetry">
          <slot></slot>
          <label class="text">Subiendo archivo...</label>
          <p class="file-name"><strong>{{filename}}</strong> ({{filesize}})</p>
      </div>
      <div v-if="progressDone">
        <img class="img-fluid" src="@/assets/images/Default/icon-check-normal.svg" alt="success" />
        <label class="text">Carga completa</label>
        <p class="file-name"><strong>{{filename}}</strong> ({{filesize}})</p>
      </div>
      <div v-if="progressRetry" class="retry-load">
        <img class="img-fluid" src="@/assets/images/Default/icon-retry-normal.svg" alt="retry" />
        <label class="text">¡Ups! Algo salió mal</label>
        <p class="file-name">Revisa que la imagen cumpla con los requisitos que se pidieron y haz clic fuera del recuadro para intentar de nuevo</p>
      </div>
    </div>
</template>

<script>
    export default {
        name: 'ProgressCircle',
        props: {
            clazz: String,
            filename: String,
            filesize: String,
            progressDone: {type: Boolean, default: false},
            progressRetry: {type: Boolean, default: false}
        }
    }
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
    .progress-circle {
        margin-top: 20px;

        .text {
            display: block;
            margin: 26px 0 6px;
            font-size: 13px;
            font-weight: normal;
            line-height: 1.54;
            text-align: center;
        }

        .file-name {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.43;
            text-align: center;
            display: block;
            margin-bottom: 20px;

            strong {
                font-weight: bold;
            }
        }

        .radial-progress-container {
            margin: 0 auto;

            .radial-progress-inner .percentage {
                font-size: 34px;
                font-weight: normal;
                line-height: 1.18;
                text-align: center;
                margin-bottom: 0;
            }
        }

        &.loading-done {
            img {
                margin: auto;
                display: flex;
                max-height: 120px;
                object-fit: contain;
                animation: tada 1s ease-in-out;
            }

            .text {
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        .retry-load {
            img {
                display: flex;
                margin: 0 auto;
                animation: tada 1s ease-in-out;
            }

            .text {
                font-size: 22px;
                font-weight: 600;
                line-height: 1.09;
                text-align: center;
            }
        }
    }
</style>
