<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <a
                        class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                        tabindex=""
                ><i aria-hidden="true" class="banana banana-arrow-left"></i> Regresar a Tipos de Pagos</a>
                <FloatingTitleLayout title="Pago con Requisitos"/>
            </div>

            <WizardForm clazz="col-12">
                <template v-slot:wizardView>
                    <tab-content
                            title="Datos generales"
                            icon="one"
                    >
                        PASO 1

                    </tab-content>
                    <tab-content
                            title="Campos informativos"
                            icon="two"
                    >
                        PASO 2
                    </tab-content>
                    <tab-content
                            title="Campos y referencias"
                            icon="three"
                    >
                        PASO 3
                    </tab-content>
                    <tab-content
                            title="Descuentos"
                            icon="four"
                    >
                        PASO 4
                    </tab-content>
                    <tab-content
                            title="Resumen"
                            icon="five"
                    >
                        PASO 5
                    </tab-content>
                </template>
            </WizardForm>

            <div class="col-12">
                <b-alert
                        class="alert-note"
                        show
                        variant="info"
                >
                    <p class="text-container">
                        <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                alt="info"
                        />
                        Con el Pago con Requisitos configura la información general de un servicio y después, en la
                        Configuración de Deudores, registra el detalle de los pagos con los conceptos y montos
                        personalizados para cada deudor.
                    </p>
                </b-alert>

                <!-- PASO 1 -->
                <CardSimple
                        title="Datos generales del servicio"
                        subtitle="Establece las especificaciones básicas de tu pago. Esta configuración ayudará a que tus clientes encuentren tu servicio
          e identifiquen la información más relevante.">

                    <BasicInput
                            required
                            labelFor="example1"
                            inputId="example1"
                            label="Nombre del servicio"
                            inputPlaceholder="¿Con qué nombre quieres que la gente vea tu servicio?"
                    >
                    </BasicInput>
                    <BasicTextarea
                            labelFor="examplet2"
                            textareaId="examplet2"
                            label="Descripción del servicio"
                            textareaPlaceholder="Escribe de manera breve de qué trata tu servicio y qué es lo que ofrece…"
                    >
                    </BasicTextarea>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <BasicSelect
                                    required
                                    label="Estatus"
                                    labelFor
                                    help
                                    idHelpTooltip="ejGenerico4"
                                    helpText="  ¿Cuál elegir?"
                                    helpIcon="banana banana-question-circle1"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico4"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>

                                <multiselect
                                        v-model="value"
                                        :options="options"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Elige cómo quieres publicar tu servicio"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                >
                                    <template
                                            slot="selection"
                                            slot-scope="{ values, search, isOpen }"
                                    >
                    <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>

                        </div>
                        <div class="col-md-6 col-12">
                            <BasicSelect
                                    required
                                    label="Divisa"
                                    labelFor
                                    help
                                    idHelpTooltip="ejGenerico5"
                                    helpText="¿Qué es?"
                                    helpIcon="banana banana-question-circle1"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico5"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>

                                <multiselect
                                        v-model="value"
                                        :options="options2"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Selecciona con cuál divisa quieres cobrar"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                >
                                    <template
                                            slot="selection"
                                            slot-scope="{ values, search, isOpen }"
                                    >
                    <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>

                        </div>
                    </div>
                    <div class="row m-b-10">
                        <div class="col-md-6 col-6">
                            <h1 class="f-14 c-title body-font-regular m-0">Agrega imagen(es) del servicio <span
                                    class="c-error">*</span></h1>
                        </div>
                        <div class="col-md-6 col-6">
                            <div class=" justify-content-end d-flex">
                                <h1
                                        class="m-0 c-accent_2 f-13 body-font-regular c-help d-flex align-middle "
                                        id="helpImg"
                                >
                                    ¿Dónde se muestran estas imágenes?
                                    <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                </h1>
                                <b-tooltip
                                        target="helpImg"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                        maiores nam voluptas dolorem distinctio voluptates veniam quod quis fugit
                                        commodi facilis alias accusamus nemo reiciendis, culpa sit inventore illum.</p>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                    <UploadArea
                            clazz="m-b-30"
                            clazzArea="col-6"
                    >
                        <SimpleNote clazz="info m-b-0 m-l-30">
                            <p class="m-b-0">
                                El tamaño de las imágenes debe ser <strong>250px por 140px</strong>.
                                Puedes subir hasta 5 imágenes tipo: <strong>JPG, PNG y SVG</strong>.
                            </p>
                        </SimpleNote>
                    </UploadArea>

                    <div class="vertical-upload-list">
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="1"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="2"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="3"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="4"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea disableButtons>
                            <UploadArea></UploadArea>
                        </ListUploadArea>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h1 class="f-14 c-title body-font-regular m-0">Selecciona la categoría del servicio</h1>
                        </div>
                    </div>

                    <SimpleCarousel :itemsCarousel="[[0, 2],[600, 3],[991, 4],[1230, 4]]"
                                    clazz="simpleCarousel-container">
                        <slide>
                            <CardItem clazz="category-card selected">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-food.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Alimentos</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-health.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Belleza y Cuidado Personal</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-sports.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Deportes</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-school.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Educación y Capacitación</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-place.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Espacios y Vivienda</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-party.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Fiestas y Eventos</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-home.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Hogar</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-garden.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Jardinería</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-pets.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Mascotas</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-profesionals.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Profesionales</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-plane.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Recreación y Turismo</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-tools.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Reparación y Mantenimiento</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Ropa y Accesorios</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-services.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Servicios</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Transporte</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-others.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Otros</h1>
                                </div>
                            </CardItem>
                        </slide>
                    </SimpleCarousel>

                    <div class="m-b-30">
                        <BasicSelect
                                label="Tags"
                                labelFor="bannerNum"
                                help
                                idHelpTooltip="tagHelp"
                                helpText="¿Cómo funcionan los tags?"
                                helpIcon="banana banana-question-circle1"
                        >
                            <template v-slot:tooltipHelp>
                                <b-tooltip
                                        target="tagHelp"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <div>
                                        <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </b-tooltip>
                            </template>
                            <multiselect
                                    v-model="valueTags"
                                    :options="optionsTags"
                                    placeholder="Elige los tags a incluir"
                                    tag-placeholder="Agregar como nuevo tag"
                                    label="name"
                                    track-by="name"
                                    :preselect-first="false"
                                    class="select-up"
                                    :multiple="true"
                                    :taggable="true"
                            ></multiselect>
                        </BasicSelect>
                    </div>
                    <div>
                        <button
                                id
                                class="collapse-btn"
                                type="button"
                                v-b-toggle.collapse-config
                        >Configuración avanzada
                            <i aria-hidden="true" class="banana banana-chevron-down"></i></button>
                        <b-collapse
                                id="collapse-config"
                                class="m-t-40"
                        >

                            <BasicInput
                                    labelFor="identificador"
                                    inputId="identificador"
                                    label="Identificador externo"
                                    inputPlaceholder="Introduce el identificador de este servicio en notificaciones de WebHooks, consultas en la API, etc."
                                    help
                                    helpText="¿Qué es un ID externo?"
                                    helpIcon="banana banana-question-circle1"
                                    idHelpTooltip="ejGenerico3"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico3"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>

                        </b-collapse>
                        <div class="row m-t-60">
                            <div class=" col-12">
                                <div class="d-flex justify-content-end">
                                    <button class="button button-ghost sm button-plain_text">
                                        cancelar
                                    </button>
                                    <button class="button button-filled sm button-accent1 m-l-15 disabled">
                                        siguiente
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                </CardSimple>

                <!-- PASO 2 -->
                <CardSimple
                        title="Campos informativos"
                        subtitle="Agrega información adicional sobre este servicio que pueda ser importante que tus clientes conozcan
                         (vigencias, restricciones, horarios, etc.). Estas notas se mostrarán al cliente en la pantalla del proceso de pago,
                         al igual que en el comprobante.">

                    <div class="row m-b-20">
                        <div class="col-12">
                            <div class=" justify-content-end d-flex">
                                <h1
                                        class="m-0 c-accent_2 f-13 body-font-regular c-help d-flex align-middle "
                                        id="exampletoolt2"
                                >
                                    Ver ejemplo
                                    <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                </h1>
                                <b-tooltip
                                        target="exampletoolt2"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                        maiores nam voluptas dolorem distinctio voluptates veniam quod quis fugit
                                        commodi facilis alias accusamus nemo reiciendis, culpa sit inventore illum.</p>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-c m-b-30">
                        <div class="w-100 p-r-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="servicio"
                                    inputId="servicio"
                                    label="Campo informativo"
                                    inputPlaceholder="Introduce información adicional sobre el servicio"
                            >
                            </BasicInput>

                        </div>
                        <button class="button-circle button-ghost button-error sm f-20 m-t-15">
                            <i aria-hidden="true" class="banana banana-delete"></i>
                        </button>
                    </div>

                    <div class="d-flex align-items-c m-b-30">
                        <div class="w-100 p-r-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="servicio"
                                    inputId="servicio"
                                    label="Campo informativo"
                                    inputPlaceholder="Introduce información adicional sobre el servicio"
                            >
                            </BasicInput>

                        </div>
                        <button class="button-circle button-ghost button-error sm f-20 m-t-15">
                            <i aria-hidden="true" class="banana banana-delete"></i>
                        </button>
                    </div>

                    <div class="d-flex align-items-c m-b-30">
                        <div class="w-100 p-r-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="servicio"
                                    inputId="servicio"
                                    label="Campo informativo"
                                    inputPlaceholder="Introduce información adicional sobre el servicio"
                            >
                            </BasicInput>

                        </div>
                        <button class="button-circle button-ghost button-error sm f-20 m-t-15">
                            <i aria-hidden="true" class="banana banana-delete"></i>
                        </button>
                    </div>

                    <a
                            href
                            class="button-square-add"
                            tabindex
                    >
                        <i aria-hidden="true" class="banana banana-symbol-plus"></i> Agregar campo informativo
                    </a>

                    <div class="row m-t-60">
                        <div class="col-md-6 col-12">
                            <button class="button button-ghost sm button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                regresar
                            </button>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="d-flex justify-content-end">
                                <button class="button button-ghost sm button-plain_text">
                                    cancelar
                                </button>
                                <button class="button button-filled sm button-accent1 m-l-15 disabled">
                                    siguiente
                                </button>

                            </div>
                        </div>
                    </div>

                </CardSimple>

                <!-- PASO 3 -->
                <CardSimple
                        title="Campos y referencias"
                        subtitle="Los campos son la información que se solicitará a cada deudor para consultar los conceptos y montos
                         que puede pagar (cada deudor debe conocer estos datos). Las referencias contienen información adicional del deudor
                          que le será mostrada después de introducir correctamente la información de los campos. Estas son principalmente
                           útiles para que el deudor pueda verificar que está realizando el pago correcto.">

                    <div class="row m-b-30">
                        <div class="col-sm-8">
                            <h2 class="m-0 c-info_text f-20 principal-font-regular">Campos</h2>
                        </div>
                        <div class="col-sm-4">
                            <div class=" justify-content-end d-flex">
                                <h1
                                        class="m-0 c-accent_2 f-13 body-font-regular c-help d-flex align-middle "
                                        id="exampletoolt3"
                                >
                                    Ver ejemplos de campos
                                    <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                </h1>
                                <b-tooltip
                                        target="exampletoolt3"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                        maiores nam voluptas dolorem distinctio voluptates veniam quod quis fugit
                                        commodi facilis alias accusamus nemo reiciendis, culpa sit inventore illum.</p>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-c m-b-30">
                        <div class="w-100 p-r-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="servicio"
                                    inputId="servicio"
                                    label="Campo"
                                    inputPlaceholder="Introduce información adicional sobre el servicio"
                            >
                            </BasicInput>

                        </div>
                        <button class="button-circle button-ghost button-error sm f-20 m-t-15">
                            <i aria-hidden="true" class="banana banana-delete"></i>
                        </button>
                    </div>

                    <a
                            href
                            class="button-square-add"
                            tabindex
                    >
                        <i aria-hidden="true" class="banana banana-symbol-plus"></i> Agregar campo
                    </a>

                    <b-alert
                            class="alert-note m-t-30"
                            show
                            dismissible
                            variant="warning"
                    >
                        <p class="text-container">
                            <i aria-hidden="true" class="icon banana banana-warning-circle2"></i> Solo es posible actualizar los campos del
                            Pago con Requisitos si no hay deudores registrados. Los deudores se administran en
                            Configuración de Deudores del servicio en tu lista de Tipos de Pagos.
                        </p>
                    </b-alert>

                    <div class="row m-b-30 m-t-40">
                        <div class="col-sm-8">
                            <h2 class="m-0 c-info_text f-20 principal-font-regular">Referencias</h2>
                        </div>
                        <div class="col-sm-4">
                            <div class=" justify-content-end d-flex">
                                <h1
                                        class="m-0 c-accent_2 f-13 body-font-regular c-help d-flex align-middle "
                                        id="exampletoolt4"
                                >
                                    Ver ejemplos de referencias
                                    <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                </h1>
                                <b-tooltip
                                        target="exampletoolt4"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                        maiores nam voluptas dolorem distinctio voluptates veniam quod quis fugit
                                        commodi facilis alias accusamus nemo reiciendis, culpa sit inventore illum.</p>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-c m-b-30">
                        <div class="w-100 p-r-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="servicio"
                                    inputId="servicio"
                                    label="Referencia"
                                    inputPlaceholder="Introduce información adicional sobre el servicio"
                            >
                            </BasicInput>

                        </div>
                        <button class="button-circle button-ghost button-error sm f-20 m-t-15">
                            <i aria-hidden="true" class="banana banana-delete"></i>
                        </button>
                    </div>

                    <a
                            href
                            class="button-square-add"
                            tabindex
                    >
                        <i aria-hidden="true" class="banana banana-symbol-plus"></i> Agregar referencia
                    </a>

                    <div class="row m-t-60">
                        <div class="col-md-6 col-12">
                            <button class="button button-ghost sm button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                regresar
                            </button>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="d-flex justify-content-end">
                                <button class="button button-ghost sm button-plain_text">
                                    cancelar
                                </button>
                                <button class="button button-filled sm button-accent1 m-l-15 disabled">
                                    siguiente
                                </button>

                            </div>
                        </div>
                    </div>

                </CardSimple>

                <!-- PASO 4 -->
                <CardSimple
                        title="Descuentos"
                        subtitle="Puedes agregar descuentos al servicio que se aplicarán a todos tus deudores. Los descuentos pueden ser una cantidad fija
                         o bien, un porcentaje sobre el subtotal del pago. También es posible configurar una vigencia en la cual el descuento será aplicable.
                          Para asignar descuentos específicos a cada deudor deberás ingresar a la Configuración de Deudores.">

                    <div class="row m-b-20">
                        <div class="col-12">
                            <div class=" justify-content-end d-flex">
                                <h1
                                        class="m-0 c-accent_2 f-13 body-font-regular c-help d-flex align-middle "
                                        id="desc"
                                >
                                    Ver ejemplo
                                    <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                </h1>
                                <b-tooltip
                                        target="desc"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                        maiores nam voluptas dolorem distinctio voluptates veniam quod quis fugit
                                        commodi facilis alias accusamus nemo reiciendis, culpa sit inventore illum.</p>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>

                    <div class="big-info-item">
                        <div class="row">
                            <div class="col-md-6">
                                <BasicInput
                                        required
                                        labelFor="descuento"
                                        inputId="descuento"
                                        label="Nombre del descuento"
                                        inputPlaceholder="¿Cómo quieres identificar este descuento?"
                                >
                                </BasicInput>
                            </div>
                            <div class="col-md-3">
                                <BasicSelect
                                        required
                                        label="Tipo"
                                        labelFor="Tipo"
                                >

                                    <multiselect
                                            v-model="value"
                                            :options="typeSelect"
                                            :close-on-select="true"
                                            :preserve-search="true"
                                            placeholder="Elige uno"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false"
                                    >
                                        <template
                                                slot="selection"
                                                slot-scope="{ values, search, isOpen }"
                                        >
                      <span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span>
                                        </template>
                                    </multiselect>
                                </BasicSelect>

                            </div>
                            <div class="col-md-3">
                                <BasicInput
                                        required
                                        inputType="number"
                                        labelFor="perc"
                                        inputId="perc"
                                        label="Porcentaje"
                                        inputPlaceholder="0"
                                ></BasicInput>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-4 d-flex align-items-c">

                                <InputDatepicker
                                        clazz="m-b-0"
                                        required
                                        labelFor="date"
                                        label="Fecha de Creación"
                                >
                                    <date-range-picker
                                            ref="picker"
                                            :opens="opens"
                                            :showDropdowns="showDropdowns"
                                            :autoApply="autoApply"
                                            v-model="dateRange"
                                            class="form-daterangepicker daterangepicker-up daterangepicker-left"
                                    >
                                        <!-- eslint-disable -->
                                        <div
                                                slot="input"
                                                slot-scope="picker"
                                        >
                                            <span class="placeholder">DD/MM/AA - DD/MM/AA</span>
                                            <!-- This must be hidden when date selected -->
                                        </div>
                                        <!-- eslint-enable -->
                                    </date-range-picker>
                                    <i aria-hidden="true" class="banana banana-calendar icon-rangepicker"></i>
                                </InputDatepicker>

                            </div>
                            <div class="col-lg-2 col-md-3 d-flex align-items-c p-t-15">
                                <BasicCheckbox
                                        clazz="m-b-0"
                                        text="Vigencia"
                                ></BasicCheckbox>
                            </div>
                            <div class="col-lg-3 col-md-3 d-flex align-items-c p-t-15">
                                <div class="d-flex align-items-c">
                                    <p class="m-0 c-plain_text f-14 body-font-regular">Estatus de descuento</p>
                                    <Badge
                                            type="badge-error m-l-20"
                                            text="expirado"
                                    ></Badge>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 d-flex justify-content-end align-items-c p-t-15">
                                <button class="button-circle button-ghost button-error sm f-20">
                                    <i aria-hidden="true" class="banana banana-delete"></i>
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="big-info-item">
                        <div class="row">
                            <div class="col-md-6">
                                <BasicInput
                                        required
                                        labelFor="descuento"
                                        inputId="descuento"
                                        label="Nombre del descuento"
                                        inputPlaceholder="¿Cómo quieres identificar este descuento?"
                                >
                                </BasicInput>
                            </div>
                            <div class="col-md-3">
                                <BasicSelect
                                        required
                                        label="Tipo"
                                        labelFor="Tipo"
                                >

                                    <multiselect
                                            v-model="value"
                                            :options="typeSelect"
                                            :close-on-select="true"
                                            :preserve-search="true"
                                            placeholder="Elige uno"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false"
                                    >
                                        <template
                                                slot="selection"
                                                slot-scope="{ values, search, isOpen }"
                                        >
                      <span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span>
                                        </template>
                                    </multiselect>
                                </BasicSelect>

                            </div>
                            <div class="col-md-3">
                                <BasicInput
                                        required
                                        inputType="number"
                                        labelFor="perc"
                                        inputId="perc"
                                        label="Porcentaje"
                                        inputPlaceholder="0"
                                ></BasicInput>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-4 d-flex align-items-c">
                                <InputDatepicker
                                        labelFor="date"
                                        label="Fecha de inicio"
                                        required
                                        clazz="m-0"
                                >
                                    <datepicker
                                            placeholder="DD / MM / AAAA"
                                            wrapper-class="form-datepicker datepicker-up datepicker-left"
                                            input-class="form-control"
                                            calendar-class="window-datepicker"
                                            calendar-button
                                            calendar-button-icon="banana banana-calendar"
                                            typeable
                                    />
                                </InputDatepicker>

                            </div>
                            <div class="col-lg-2 col-md-3 d-flex align-items-c p-t-15">
                                <BasicCheckbox
                                        clazz="m-b-0"
                                        text="Vigencia"
                                ></BasicCheckbox>
                            </div>
                            <div class="col-lg-3 col-md-3 d-flex align-items-c p-t-15">
                                <div class="d-flex align-items-c">
                                    <p class="m-0 c-plain_text f-14 body-font-regular">Estatus de descuento</p>
                                    <Badge
                                            type="badge-accent_2 m-l-20"
                                            text="Activo"
                                    ></Badge>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 d-flex justify-content-end align-items-c p-t-15">
                                <button class="button-circle button-ghost button-error sm f-20">
                                    <i aria-hidden="true" class="banana banana-delete"></i>
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="big-info-item">
                        <div class="row">
                            <div class="col-md-6">
                                <BasicInput
                                        required
                                        labelFor="descuento"
                                        inputId="descuento"
                                        label="Nombre del descuento"
                                        inputPlaceholder="¿Cómo quieres identificar este descuento?"
                                >
                                </BasicInput>
                            </div>
                            <div class="col-md-3">
                                <BasicSelect
                                        required
                                        label="Tipo"
                                        labelFor="Tipo"
                                >

                                    <multiselect
                                            v-model="value"
                                            :options="typeSelect"
                                            :close-on-select="true"
                                            :preserve-search="true"
                                            placeholder="Elige uno"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false"
                                    >
                                        <template
                                                slot="selection"
                                                slot-scope="{ values, search, isOpen }"
                                        >
                      <span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span>
                                        </template>
                                    </multiselect>
                                </BasicSelect>

                            </div>
                            <div class="col-md-3">
                                <BasicInput
                                        required
                                        inputType="number"
                                        labelFor="perc"
                                        inputId="perc"
                                        label="Porcentaje"
                                        inputPlaceholder="0"
                                ></BasicInput>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5 col-md-4 d-flex align-items-c">
                                <InputDatepicker
                                        labelFor="date"
                                        label="Fecha de inicio"
                                        required
                                        clazz="m-0"
                                >
                                    <datepicker
                                            placeholder="DD / MM / AAAA"
                                            wrapper-class="form-datepicker datepicker-up datepicker-left"
                                            input-class="form-control"
                                            calendar-class="window-datepicker"
                                            calendar-button
                                            calendar-button-icon="banana banana-calendar"
                                            typeable
                                    />
                                </InputDatepicker>

                            </div>
                            <div class="col-lg-2 col-md-3 d-flex align-items-c p-t-15">
                                <BasicCheckbox
                                        clazz="m-b-0"
                                        text="Vigencia"
                                ></BasicCheckbox>
                            </div>
                            <div class="col-lg-3 col-md-3 d-flex align-items-c p-t-15">
                                <div class="d-flex align-items-c">
                                    <p class="m-0 c-plain_text f-14 body-font-regular">Estatus de descuento</p>
                                    <Badge
                                            type="badge-disable m-l-20"
                                            text="inactivo"
                                    ></Badge>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 d-flex justify-content-end align-items-c p-t-15">
                                <button class="button-circle button-ghost button-error sm f-20">
                                    <i aria-hidden="true" class="banana banana-delete"></i>
                                </button>
                            </div>
                        </div>

                    </div>

                    <a
                            href
                            class="button-square-add"
                            tabindex
                    >
                        <i aria-hidden="true" class="banana banana-symbol-plus"></i> Agregar descuento
                    </a>

                    <div class="row m-t-60">
                        <div class="col-md-6 col-12">
                            <button class="button button-ghost sm button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                regresar
                            </button>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="d-flex justify-content-end">
                                <button class="button button-ghost sm button-plain_text">
                                    cancelar
                                </button>
                                <button class="button button-filled sm button-accent1 m-l-15 disabled">
                                    siguiente
                                </button>

                            </div>
                        </div>
                    </div>

                </CardSimple>

                <!-- PASO 5 -->
                <CardSimple
                        title="Resumen"
                        subtitle="Has terminado de configurar tu servicio. Aquí se muestra un resumen de la información ingresada para que puedas verificar
                         que sea correcta. Si encuentras algún error puedes editar lo que sea necesario.">
                    <div class="m-b-10">
                        <h1 class="c-title f-20 principal-font-bold m-b-30">
                            Datos generales
                        </h1>
                        <div class="row">
                            <div class="col-md-5 col-12 m-b-30">
                                <b-carousel
                                        id="OneItemCarousel"
                                        fade
                                        indicators
                                        controls
                                        :interval="10000"
                                >
                                    <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/A1E74CED-4B97-4F9C-A7DF-D43FEAA54454.png"></b-carousel-slide>
                                    <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/8A0B51A8-7BAA-487B-9755-E71C709D4C11.png"></b-carousel-slide>
                                    <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/39B68F24-72B4-45B1-A25E-FA557AAE8C57.png"></b-carousel-slide>
                                </b-carousel>
                            </div>
                            <div class="col-md-7 col-12 m-b-30">
                                <Badge
                                        type="badge-accent_2 m-b-10"
                                        text="Activo"
                                ></Badge>
                                <h2 class="c-title f-20 body-font-regular m-b-10">Tijeras para cortar el césped</h2>
                                <h3 class="c-info_text f-14 body-font-regular f-bold m-0 m-b-15">
                                    JARDINERÍA
                                </h3>
                                <p class="c-plain_text f-14 body-font-regular m-0">
                                    ¡Nuevo modelo de tijeras con diseño ergonómico! Mango de plástico con almohadillas y
                                    navajas de acero inoxidable.
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Divisa</h4>
                                <p class="c-plain_text f-14 body-font-regular m-0">
                                    Pesos mexicanos (MXN)
                                </p>
                            </div>
                            <div class="col-md-7 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Tags</h4>
                                <div>
                                    <Badge
                                            type="badge-accent_2 m-r-5 m-b-5"
                                            text="Flores"
                                            closeBtnShow
                                    ></Badge>
                                    <Badge
                                            type="badge-accent_2 m-r-5 m-b-5"
                                            text="Arreglos"
                                            closeBtnShow
                                    ></Badge>
                                    <Badge
                                            type="badge-accent_2 m-r-5 m-b-5"
                                            text="Accesorios para auto"
                                            closeBtnShow
                                    ></Badge>
                                </div>
                            </div>
                            <div class="col-md-5 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">ID externo</h4>
                                <p class="c-placeholder f-italic f-14 body-font-regular m-0">
                                    No ingresaste ID externo.
                                </p>
                            </div>
                        </div>
                    </div>

                    <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-10">
                        Editar sección
                    </button>
                    <div class="m-b-40">
                        <h1 class="c-title f-20 principal-font-bold m-b-20">
                            Campos informativos
                        </h1>
                        <ul class="m-0 p-0">
                            <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- Al realizar tu pago favor
                                de enviar tu comprobante por correo a administrativo@mail.com
                            </li>
                            <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- Si desconoces tu número de
                                contrato comunícate al 614 307 66 58
                            </li>
                        </ul>
                    </div>
                    <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40">
                        Editar sección
                    </button>

                    <div class="m-b-30">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <h1 class="c-title f-20 principal-font-bold m-b-20">
                                    Campos
                                </h1>
                                <ul class="m-0 p-0">
                                    <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- Número de
                                        contrato
                                    </li>
                                    <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- CURP del titular
                                        del contrato
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6 col-12">
                                <h1 class="c-title f-20 principal-font-bold m-b-20">
                                    Referencias
                                </h1>
                                <ul class="m-0 p-0">
                                    <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- Titular del
                                        contrato
                                    </li>
                                    <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- Modelo de la casa
                                    </li>
                                    <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- Número exterior
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40">
                        Editar sección
                    </button>
                    <div class="m-b-40">
                        <h1 class="c-title f-20 principal-font-bold m-b-20">
                            Descuentos
                        </h1>
                        <div class="basic-table">
                            <b-table
                                    responsive
                                    hover
                                    :fields="fields2"
                                    :items="items2"
                            >
                                <template v-slot:estatus>
                                    <Badge
                                            type="badge-accent_2"
                                            text="Activo"
                                    ></Badge>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40">
                        Editar sección
                    </button>

                    <div class="row m-t-60">
                        <div class="col-md-6 col-12">
                            <button class="button button-ghost sm button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                regresar
                            </button>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="d-flex justify-content-end">
                                <button class="button button-ghost sm button-plain_text">
                                    cancelar
                                </button>
                                <button class="button button-filled sm button-accent1 m-l-15">
                                    Guardar
                                </button>

                            </div>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import SimpleNote from "@/components/SimpleNote.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import BasicTextarea from "@/components/BasicTextarea.vue";
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import InputDatepicker from "@/components/InputDatepicker.vue";
    import BasicSelect from "@/components/BasicSelect.vue";
    import Multiselect from "vue-multiselect";
    import Datepicker from "vuejs-datepicker";
    import ListUploadArea from '@/components/ListUploadArea.vue';
    import UploadArea from '@/components/UploadArea.vue';
    import CardItem from "@/components/CardItem.vue";
    import SimpleCarousel from "@/components/SimpleCarousel.vue";
    import Badge from "@/components/Badge";
    import CardSimple from '@/components/CardSimple.vue'
    import WizardForm from "@/components/WizardForm.vue";
    import DateRangePicker from 'vue2-daterange-picker';

    const TEXT_ALIGN_C_CLASS = "text-align-c";

    export default {
        name: "RequirementsPayment",
        components: {
            FloatingTitleLayout,
            SimpleNote,
            BasicInput,
            BasicTextarea,
            BasicCheckbox,
            InputDatepicker,
            BasicSelect,
            Multiselect,
            Datepicker,
            ListUploadArea,
            UploadArea,
            CardItem,
            SimpleCarousel,
            Badge,
            WizardForm,
            CardSimple,
            DateRangePicker
        },
        data() {
            return {
                // Date Range Picker
                opens: 'left',
                showDropdowns: true,
                autoApply: true,
                dateRange: [],
                // Multiselect
                checkedSwitch: false,
                value: [],
                options: [
                    {name: "Activo"},
                    {name: "Inactivo"},
                    {name: "Próximamente"}
                ],
                options2: [
                    {name: "Pesos mexicanos (MXN)"}
                ],
                typeSelect: [
                    {name: 'Cantidad'},
                    {name: 'Porcentaje'}
                ],
                valueTags: [],
                optionsTags: [
                    {name: 'Flores'},
                    {name: 'Arreglos'},
                    {name: 'Comida china'},
                    {name: 'Ropa de invierno'},
                    {name: 'Accesorios para auto'},
                    {name: 'Telefonía'}
                ],
                fields: [
                    {
                        key: "nombre",
                        label: "Nombre"
                    },
                    {
                        key: "cantidad",
                        label: "Cantidad",
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "precioUnitario",
                        label: "Precio Unitario",
                        class: "text-align-r"
                    },
                    {
                        key: "subtotal",
                        label: "Subtotal",
                        class: "text-align-r f-bold w-150px"
                    }
                ],
                items: [
                    {
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                        nombre: "Tijeras para cortar el césped",
                        cantidad: "14",
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                        precioUnitario: "$ 450.00 MXN",
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                        subtotal: "$ 450.00 MX"
                    },
                    {
                        nombre: "Tijeras para cortar el césped",
                        cantidad: "14",
                        precioUnitario: "$ 450.00 MXN",
                        subtotal: "$ 450.00 MX"
                    },
                    {
                        nombre: "Tijeras para cortar el césped",
                        cantidad: "14",
                        precioUnitario: "$ 450.00 MXN",
                        subtotal: "$ 450.00 MX"
                    },
                ],
                fields2: [
                    {
                        key: "nombre",
                        label: "Nombre"
                    },
                    {
                        key: "inicio",
                        label: "Inicio",
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "fin",
                        label: "Fin",
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "estatus",
                        label: "Estatus",
                        class: "text-align-c w-85px"
                    },
                    {
                        key: "descuento",
                        label: "Descuento",
                        class: "text-align-r f-bold c-success w-150px"
                    }
                ],
                items2: [
                    {
                        nombre: "Buen fin",
                        inicio: "10/11/19",
                        fin: "15/11/19",
                        descuento: "- 30%",
                    },
                    {
                        nombre: "Buen fin",
                        inicio: "10/11/19",
                        fin: "-",
                        descuento: "- $ 50.00 MXN",
                    },
                ],
                fields3: [
                    {
                        key: "text",
                        label: "",
                        class: "text-align-r"
                    },
                    {
                        key: "number",
                        label: "",
                        class: "text-align-r f-bold  w-150px"
                    }
                ],
                items3: [
                    {
                        text: "Descuento Buen Fin",
                        number: "- 30%",
                    },
                    {
                        text: "Descuento Usuarios Pagando",
                        number: "- $ 50.00 MXN",
                    },
                    {
                        text: "Impuestos",
                        number: "$ 0.00 MXN",
                    },
                    {
                        text: "Comisión Pagando (Inc. IVA)",
                        number: "$ 9.00 MXN",
                    },
                ],

            };
        }
    };
</script>
