<template>
    <div>
        <!-- EMAIL ERROR -->
        <div class="fullpage-container-body" v-if="getShowAlert && !getIsLoadingConfirm">
            <GenericTitle clazz="text-align-c" :title="$t('local-verification.ohno-title')"
                          :titleStrong="$t('local-verification.ohno-title-strong')">
                <p class="f-18 c-plain_text body-font-light text-align-c">
                    <strong class="body-font-bold">{{$t('local-verification.im-sorry-subtitle')}}</strong><br>
                    {{$t('local-verification.experiencing-problems')}}
                </p>
            </GenericTitle>

            <img class="img-fluid center-item d-flex m-b-40" :src="errorIllustration" alt="illustration"/>

            <span class="f-14 c-info_text body-font-regular text-align-c d-block m-t-30 m-b-30">
              {{$t('local-verification.technical-assistance1')}} <br> <strong>{{$t('local-verification.technical-assistance2')}}</strong>
              {{$t('local-verification.technical-assistance3')}}<strong>{{$t('local-verification.technical-assistance4')}}</strong>
              {{$t('local-verification.technical-assistance5')}} </span>
        </div>
        <div class="fullpage-container-body" v-if="getIsLoadingConfirm">
            <GenericTitle clazz="text-align-c" :title="$t('local-verification.validating')"
                          :titleStrong="$t('local-verification.info')">
                <p class="f-18 c-plain_text body-font-light text-align-c">
                    <strong class="body-font-bold">{{$t('local-verification.validating-info-title')}}</strong><br>
                    {{$t('local-verification.validating-info-description')}}
                </p>
            </GenericTitle>

            <LoadingSimple clazz="big-load" :label="$t('local-verification.verifying')"></LoadingSimple>
        </div>
        <!-- EMAIL SUCCESS CONGRATS -->
        <div class="fullpage-container-body" v-if="!getShowAlert && !getIsLoadingConfirm">
            <GenericTitle clazz="text-align-c" :title="$t('local-verification.congrats')"
                          :subtitle="$t('local-verification.congrats-description')"/>

            <img class="img-fluid center-item d-flex m-b-40"
                 src="@/assets/images/Illustrations/illustration-mail-validation.svg" alt="illustration"/>

            <router-link to="" @click.native="continueRegister()" id="congratsSignIn"
                         class="button xs button-filled button-accent1" type="submit">{{$t('local-verification.login')}}
            </router-link>

            <span class="f-14 c-info_text body-font-regular text-align-c d-block m-t-30 m-b-30">
              {{$t('local-verification.technical-assistance1')}}<br> <strong>{{$t('local-verification.technical-assistance2')}}</strong>
              {{$t('local-verification.technical-assistance3')}} <strong>{{$t('local-verification.technical-assistance4')}}</strong>
               {{$t('local-verification.technical-assistance5')}} </span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import GenericTitle from '@/components/GenericTitle.vue';
    import LoadingSimple from '@/components/LoadingSimple.vue';
    import errorIllustration from '@/assets/images/Illustrations/illustration-error.svg';
    import {
        STORE as VerificationEmail,
        GET_IS_LOADING_CONFIRM,
        GET_SHOW_ALERT,
        GET_ALERT_MESSAGE,
        CONFIRM_EMAIL,
        GET_COMPLETE_NEW_REGISTER
    } from '@/store/modules/users/verificationEmail.types'

    export default {
        name: "SignInFullPage",
        components: {
            GenericTitle,
            LoadingSimple
        },
        props: {},
        data() {
            return {
                errorIllustration: errorIllustration
            }
        },
        created() {
            const token = this.$route.params.token;
            this.$store.dispatch(`${VerificationEmail}/${CONFIRM_EMAIL}`, {token});
        },
        computed: {
            ...mapGetters(VerificationEmail, {
                getShowAlert: GET_SHOW_ALERT,
                getAlertMessage: GET_ALERT_MESSAGE,
                getIsLoadingConfirm: GET_IS_LOADING_CONFIRM,
                completeNewRegister: GET_COMPLETE_NEW_REGISTER
            })
        },
        methods: {
            continueRegister() {
                this.$router.push("/register/organizations");
            }
        }
    }
</script>
