<template>
  <div
          class="google-map"
          :class="clazz"
  >
    <LoadingBar v-if="loading"></LoadingBar>
    <div v-if="searchBar">
      <div class="input-container">
        <gmap-autocomplete ref="autocomplete" @place_changed="changeLocation"></gmap-autocomplete>
      </div>
    </div>
    <div v-if="searchBarButton">
      <div class="input-container with-button">
        <gmap-autocomplete @place_changed="changeLocation"></gmap-autocomplete>
        <button @click="geolocate">
          <i aria-hidden="true" class="banana banana-location"></i>
        </button>
      </div>
    </div>
    <div v-if="locationOption && ($scopedSlots.locationOption || $slots.locationOption)">
      <div
              :id="id"
              class="edit-location hide"
      >
        <div class="edit-location-inner">
          <button
                  @click="toggleLocationOption"
                  class="collapse-button"
          >
            <i aria-hidden="true" class="banana banana-triangle-left"></i>
          </button>
          <div class="info-location">
            <slot name="locationOption"></slot>
          </div>
        </div>
      </div>
    </div>
    <gmap-map
            :center="center"
            :markers="center"
            :zoom="15"
            :style="height"
            :options="gmapOptions"
            @click="mapClick"
    >
      <gmap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker"
              :icon="markerOptions"
              @click="markerClick(marker, index)"
              @drag="markerDrag(marker, index, $event)"
              clickable
              :draggable="!readOnly"
      ></gmap-marker>
      <div v-if="tooltipMark">
        <gmap-info-window
                :position="center"
                :options="{
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                    }"
        >
          {{tooltipText}}
        </gmap-info-window>
      </div>
    </gmap-map>
  </div>
</template>

<script>
    import { gmapApi } from 'vue2-google-maps';
    import utils from '@/common/utils';
    import LoadingBar from '@/components/LoadingBar.vue';
    const mapMarker = require('@/assets/images/Icons/location.svg');

    export default {
        name: "GoogleMap",
        components: {
            LoadingBar,
        },
        props: {
            id: {
                type: String,
                default: 'editLocation'
            },
            clazz: String,
            height: String,
            // Disables adding, removing and dragging makers
            readOnly: { type: Boolean, default: false },
            tooltipText: String,
            // Whether to display the searchbar alone or not
            searchBar: { type: Boolean, default: false },
            // Whether to display the searchbar with reset button or not
            searchBarButton: { type: Boolean, default: false },
            tooltipMark: { type: Boolean, default: false },
            hideCenterButton: {type: Boolean, default: false},
            /**
             * The list of markers to edit.
             * Must have the format:
             * {
             *      lat: Number,
             *      lng: Number,
             *      address: String
             * }
             * More values can be added for ease of use and data structure customizations. See admin/src/views/organization/OrganizationLocationInfo.vue
             */
            value: {
                type: Array,
                default: () => []
            },
            gmapOptions: {
                type: Object,
                default: () => {
                    return {
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: false
                    }
                }
            },
            markerClick: {
                type: Function,
                default(marker, index) {
                    this.removeMarker(marker, index);
                }
            },
            markerAdded: {
                type: Function,
                default: () => {}
            },
            markerDragged: {
                type: Function,
                default: () => {}
            },
            locationOption: { type: Boolean, default: false },
            // Disables toggling the markerOptions bar by clicking the arrow
            locationOptionDisableToggle: { type: Boolean, default: false },
            stepLocation: { type: Boolean, default: false },
        },
        data () {
            return {
                loading: false,
                center: { lat: 28.6619928, lng: -106.1290746 },
                markers: [],
                places: [],
                markerOptions: {
                    url: mapMarker,
                    size: { width: 40, height: 40, f: 'px', b: 'px', },
                    scaledSize: { width: 40, height: 40, f: 'px', b: 'px', },
                },
                infowindow: { lat: 28.6619928, lng: -106.1290746 },
                window_open: true,
                // Used to circle markers when the organge button is pressed
                currentMarkerIndex: 0,
                address: "",
                addressHelper: ""
            };
        },
        mounted () {
            this.markers = this.value;
            this.geolocate();
        },
        methods: {
            addressFromCoordinates (coordinates, callback) {
                this.loading = true;
                new this.google.maps.Geocoder().geocode({ location: coordinates }, (places, res) => {
                    this.loading = false;
                    if (res !== 'OK' || !places || places.length === 0 || !places[0].formatted_address) {
                        callback(true);
                    } else {
                        callback(null, places[0].formatted_address);
                    }
                });
            },
            geolocate () {
                if (!this.markers || this.markers.length === 0) {
                    navigator.geolocation.getCurrentPosition(position => {
                        this.center = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    });
                } else {
                    if (this.currentMarkerIndex >= this.markers.length) {
                        this.currentMarkerIndex = 0;
                    }
                    const markerLocated = this.markers[this.currentMarkerIndex];
                    this.center = {
                        lat: markerLocated.lat,
                        lng: markerLocated.lng,
                    }
                    this.$emit('markerLocated', {marker: markerLocated, index: this.currentMarkerIndex});
                    if (this.markers.length > 1 && this.markers.length > (this.currentMarkerIndex + 1)) {
                        this.currentMarkerIndex++;
                    } else {
                        this.currentMarkerIndex = 0;
                    }
                }
            },
            mapClick (event) {
                if (!this.readOnly) {
                    this.addressFromCoordinates(event.latLng, (error, address) => {
                        if(this.stepLocation){
                            this.markers = [];
                        }
                        if (!this.readOnly) {
                            const marker = { lat: event.latLng.lat(), lng: event.latLng.lng(), address };
                            this.markers.push(marker);
                            this.$emit('input', this.markers);
                            this.markerAdded(marker, this.markers.length - 1);
                            if (
                                this.$refs
                                && this.$refs.autocomplete
                                && this.$refs.autocomplete.$refs
                                && this.$refs.autocomplete.$refs.input
                            ) {
                                this.$refs.autocomplete.$refs.input.value = address;
                            }
                        }
                    });
                }
            },
            removeMarker (marker, index) {
                if (!this.readOnly) {
                    this.markers.splice(index, 1);
                    this.$emit('input', this.markers);
                }
            },
            markerDrag (marker, index, event) {
                if (!this.readOnly) {
                    utils.debounceFixed(() => {
                        this.addressFromCoordinates(event.latLng, (error, address) => { // lat: 28.6635512634477, lng: -106.124777180505
                            this.$set(marker, 'lat', event.latLng.lat());
                            this.$set(marker, 'lng', event.latLng.lng());
                            this.$set(marker, 'address', address);

                            marker.lat = event.latLng.lat();
                            marker.lng = event.latLng.lng();
                            marker.address = address;

                            this.$emit('input', this.markers);
                            this.markerDragged(marker, index);
                            if (
                                this.$refs
                                && this.$refs.autocomplete
                                && this.$refs.autocomplete.$refs
                                && this.$refs.autocomplete.$refs.input
                            ) {
                                this.$refs.autocomplete.$refs.input.value = address;
                            }
                        });
                    });
                }
            },
            changeLocation (location) {
                this.center = {
                    lat: location.geometry.location.lat,
                    lng: location.geometry.location.lng,
                }
                if(this.stepLocation){
                    const marker = {
                        lat: location.geometry.location.lat(),
                        lng: location.geometry.location.lng(),
                        address: location.formatted_address
                    };
                    this.address = location.formatted_address;
                    this.markers = [];
                    this.markers[0]= {};
                    Object.assign(this.markers[0], marker);
                    this.$emit('input', this.markers);
                }
            },
            toggleLocationOption (open) {
                var element = document.getElementById(this.id);
                if (element) {
                    switch (open) {
                        case true:
                            element.classList.remove("hide");
                            break;
                        case false:
                            element.classList.add("hide");
                            break;
                        default:
                            if (!this.locationOptionDisableToggle) {
                                element.classList.toggle("hide");
                            }
                            break;
                    }
                }
            },
            updateMarker(marker, index) {
                if(!this.marker[index]){
                    this.markers = [];
                    this.markers[0]= {};
                }
                Object.assign(this.markers[index], marker);
                this.$emit('input', this.markers);
            },
        },
        computed: {
            google: gmapApi
        }
    };
</script>
