<template>
    <CardSimple
        :title="$t('organizaciones.notifications-info.title')"
        :subtitle="$t('organizaciones.notifications-info.subtitle')"
    >

        <div class="row">
            <LoadingBar
                v-if="loading"
                :loadingText="$t('general.info.loading')"
            ></LoadingBar>
            <div class="col-12">
                <div class="vertical-center m-b-30" v-for="(address, index) in infoToEdit.notificationEmails" v-bind:key="index">
                    <div class="w-70">
                        <BasicInput
                            :id="index"
                            v-model="address.address"
                            clazz="m-b-0"
                            inputType="email"
                            labelFor="email-notif"
                            :label="$t('organizaciones.notifications-info.email')"
                            :inputPlaceholder="$t('organizaciones.notifications-info.email-placeholder')"
                            :validations="$v.infoToEdit.notificationEmails.$each[index].address"
                            validationsNamespace="general.validations"
                        >
                        </BasicInput>
                    </div>
                    <div class="w-20">
                        <BasicSelect
                            clazz="m-b-0 m-l-20"
                            :label="$t('organizaciones.notifications-info.email-notifications-type')"
                            :inputPlaceholder="$t('organizaciones.notifications-info.email-notifications-type')"
                        >
                            <multiselect
                                v-model="address.notficationTypes"
                                :options="EmailNotificationTypes"
                                :close-on-select="true"
                                :preserve-search="true"
                                :placeholder="$t('tax-receipts.filter-month')"
                                label="label"
                                track-by="name"
                                :preselect-first="false"
                                multiple
                            >
                                <template slot="selection" slot-scope="{ values, isOpen }">
                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                        {{$tc('organizaciones.notifications-info.notification-types-selected', values.length)}}
                                    </span>
                                </template>
                            </multiselect>
                        </BasicSelect>
                    </div>
                    <div class="w-10 vertical-center justify-content-center">
                        <button
                            type="button"
                            class="button-circle sm button-ghost button-error m-t-20 m-l-20"
                            @click="deleteAddress(index)"
                        ><i aria-hidden="true" class="banana banana-delete"></i></button>
                    </div>
                </div>

                <button
                    type="button"
                    class="button-square-add text-inherit p-t-20 p-b-20"
                    @click="addAddress"
                ><i aria-hidden="true" class="banana banana-symbol-plus"></i> {{$t('organizaciones.notifications-info.add-email')}}</button>
            </div>
            <div class="col-12 vertical-center justify-content-end m-t-40">
                <button
                    type=""
                    class="button xs button-ghost button-plain_text"
                    @click="cancel"
                >{{$t('general.actions.cancel.title')}}</button>
                <button
                    type=""
                    class="button xs button-filled button-accent1 m-l-10"
                    @click="updateInfo"
                    :disabled="loading || hasError"
                >{{$t('general.actions.save-changes')}}</button>
            </div>
        </div>
    </CardSimple>
</template>
<script>
import CardSimple from '@/components/CardSimple.vue';
import BasicInput from '@/components/BasicInput.vue';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION, FETCH_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import { STORE as ORGANIZATION_STORE, GET_NOTIFICATION_INFO, FETCH_NOTIFICATION_INFO, UPDATE_NOTIFICATION_INFO } from "@/store/modules/organization/organization.types";
import { mapGetters } from 'vuex';
import { utils } from '@/api/utils';
import { email } from 'vuelidate/lib/validators';
import LoadingBar from '@/components/LoadingBar.vue';
import multiselect from "vue-multiselect";
import BasicSelect from '@/components/BasicSelect.vue';

export default {
    components: {
        CardSimple,
        BasicInput,
        LoadingBar,
        multiselect,
        BasicSelect,
    },
    data() {
        return {
            loading: false,
            EmailNotificationTypes: [
                {name: 'PAYMENT', label: this.$t('organizaciones.notifications-info.notification-types.PAYMENT')},
                {name: 'INVOICE', label: this.$t('organizaciones.notifications-info.notification-types.INVOICE')},
                {name: 'DISPERSION', label: this.$t('organizaciones.notifications-info.notification-types.DISPERSION')},
            ],
            infoToEdit: {
                cco: "",
                webhooksErrorsEmail: "",
                notificationEmails: []
            }
        };
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_NOTIFICATION_INFO}`, this.currentOrganization._id).then(() => {
            this.prepareData();
            this.loading = false;
        });
    },
    methods: {
        prepareData() {
            const notificationEmails = this.infoComputed.notificationEmails.map(({address, notficationTypes}) => {
                return {
                    address,
                    notficationTypes: notficationTypes.map((nt) => this.EmailNotificationTypes.find(({name}) => nt === name)),
                };
            });

            this.infoToEdit = JSON.parse(JSON.stringify({...this.infoComputed, notificationEmails,}));
            this.loading = false;
        },
        cancel() {
            this.prepareData();
            utils.toast(this.$snotify, 'info', this.$t('general.actions.cancel.title'), this.$t('general.actions.cancel.text'));
        },
        updateInfo() {
            this.loading = true;
            const body = {
                ...this.infoToEdit,
                notificationEmails: this.notificationEmails,
            };
            this.$store.dispatch(`${ORGANIZATION_STORE}/${UPDATE_NOTIFICATION_INFO}`, {_id: this.currentOrganization._id, body}).then(() => {
                utils.toast(this.$snotify, 'success', this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                this.$store.dispatch(`${ACCESS_STORE}/${FETCH_CURRENT_ORGANIZATION}`);
            }).catch((error) => {
                this.loading = false;
                if (error.response && error.response.data && error.response.data.message) {
                    utils.toast(this.$snotify, 'error', this.$t('general.actions.error.title'), error.response.data.message);
                } else {
                    utils.toast(this.$snotify, 'error', this.$t('general.actions.error.title'), this.$t('general.actions.error.text'));
                }
            });
        },
        deleteAddress(index) {
            this.infoToEdit.notificationEmails.splice(index, 1);
        },
        addAddress() {
            this.infoToEdit.notificationEmails.push({address: ""});
        }
    },
    computed: {
        hasError () { return this.$v.$invalid },
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
        ...mapGetters(ORGANIZATION_STORE, {
            notificationInfo: GET_NOTIFICATION_INFO
        }),
        infoComputed() {
            return this.notificationInfo || {};
        },
        notificationEmails() {
            return this.infoToEdit?.notificationEmails?.map(({address, notficationTypes}) => ({address, notficationTypes: notficationTypes.map(({name}) => name)})) || [];
        }
    },
    watch: {
        currentOrganization () {
            if (this.currentOrganization) {
                this.loading = true;
                this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_NOTIFICATION_INFO}`, this.currentOrganization._id).then(() => {
                    this.prepareData();
                });
            }
        }
    },
    validations: {
        infoToEdit: {
            cco: { email },
            webhooksErrorsEmail: { email },
            notificationEmails: {
                $each: {
                    address: { email }
                }
            },
        }
    }
}
</script>
