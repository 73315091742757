<template>
    <div>
        <GenericTitle clazz="p-t-20" :title="$t('phone-verification.title')"
                      :titleStrong="$t('phone-verification.title-strong')"
                      :subtitle="$t('phone-verification.subtitle')"></GenericTitle>
        <!--Acomodar este pedo para que no aparezcan juntos alv-->
        <div v-show="!smsSend || verified">
            <TelInput
                    clazz=""
                    model-name="user.phone"
                    v-model="user.phone"
                    labelFor="number"
                    :label="$t('phone-verification.number-phone')"
                    :verified="verified"
                    required
                    idInput="numCountry"
                    :inputPlaceholder="$t('phone-verification.number-phone-placeholder')"
                    badge
                    :badgeType="verified ? 'badge-success' : ''"
                    :badgeText="verified ? 'verificado' : ''"
                    @country-changed=countryChanged
            ></TelInput>
            <b-alert class="alert-note" show dismissible variant="danger" v-if="showErrorCode">
                <p class="text-container"><img class="img-fluid"
                                               src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg" alt=""/>
                    {{errorCodeText}} </p>
            </b-alert>
            <b-alert class="alert-note" show dismissible variant="success" v-if="smsSendShowMsg">
                <p class="text-container"><i aria-hidden="true" class="icon banana banana-check-normal"></i>{{$t('phone-verification.code-sent')}}
                </p>
            </b-alert>
            <button id="sendVerificationCodeDisabled" class="button xs button-filled button-accent1 m-0-0-0-auto"
                    v-if="user.phone.length < 10 && !smsSend && !verified" disabled>
                {{$t('phone-verification.send-code-button')}}
            </button>
            <button id="sendVerificationCode" class="button xs button-filled button-accent1 m-0-0-0-auto"
                    @click="sendVerificationCode()" v-if="user.phone.length >= 10 && !smsSend && !verified">
                {{$t('phone-verification.send-code-button')}}
            </button>
            <div v-show="verified">
                <span class="verified-check m-t-10"><i aria-hidden="true" class="banana banana-check-circle1"></i> {{$t('phone-verification.verification-success')}} </span>
                <p class="f-16 c-plain_text font-body-regular d-block m-t-10 m-b-20">
                    {{$t('phone-verification.verification-description1')}} <strong
                        class="font-body-bold">{{phoneStr}}</strong>
                    {{$t('phone-verification.verification-description2')}} </p>
            </div>
        </div>


        <div class="p-relative d-block" v-if="smsSend && !verified">
            <label class="f-18 c-title font-body-semibold f-bold d-block m-t-30 m-b-5">
                {{$t('phone-verification.enter-security-code')}} </label>
            <p class="f-16 c-plain_text font-body-regular d-block m-b-15">
                {{$t('phone-verification.security-code-sent')}} <strong class="font-body-semibold">{{phoneStr}}</strong>
            </p>
            <CodeInput v-on:verificationCode="getVerificationCode" :showWarning="verificationError"
                       :warningText="verificationError ? verificationErrorText : '' " clazz=""></CodeInput>

            <p class="f-14 c-info_text font-body-regular d-block m-t-30 m-b-15">
                {{$t('phone-verification.resubmit-code1')}} <br>
                {{$t('phone-verification.resubmit-code2')}}
                <router-link id="resendVerificationCode" to="" @click.native="sendVerificationCode()"
                             class="c-accent_2 body-font-bold text-underline">
                    {{$t('phone-verification.resubmit-code3')}}
                </router-link>
            </p>
            <button id="verificationCode" class="button xs button-filled button-accent1 m-0-0-0-auto"
                    @click="verifyCode()">Verificar
            </button>
        </div>
        <LoadingBar :loadingText="loadingText" v-if="loading"></LoadingBar>
    </div>
</template>

<script>
    import TelInput from '@/components/TelInputOld.vue'
    import GenericTitle from '@/components/GenericTitle.vue'
    import CodeInput from '@/components/CodeInput.vue'
    import LoadingBar from '@/components/LoadingBar.vue'
    import {mapGetters} from 'vuex';
    import {EventBus} from '@/main';

    const storeModule = "signUpOrganization";


    export default {
        name: "PhoneVerification",
        components: {
            GenericTitle,
            TelInput,
            CodeInput,
            LoadingBar
        },
        created() {
            this.$store.commit(`${storeModule}/SET_ACTIVE_COMPONENT_STEP_ONE`, "PHONE_VERIFICATION");
            this.$store.commit(`SIGNIN_UP/SET_RIGHT_SIDE_BODY`, 'STEP_FOUR');
        },
        data() {
            return {
                user: {
                    phone: ''
                },
                verificationCode: '',
                smsSend: false,
                smsSendShowMsg: false,
                phoneStr: '',
                verificationError: false,
                verificationErrorText: '',
                verified: false,
                loading: false,
                showErrorCode: false,
                errorCodeText: '',
                loadingText: '',
                ocupacion: null
            }
        },
        methods: {
            /**
             * Envia el código de verificación por sms.
             */
            async sendVerificationCode() {
                this.loadingText = this.$i18n.t('phone-verification.sending-code');
                this.loading = true;
                this.smsSendShowMsg = false;
                this.showErrorCode = false;
                // Elimina todo lo que no sea un caracter numérico del teléfono.
                const phoneSimple = this.user.phone.replace(/[^0-9]/g, '');
                this.$store.commit(`${storeModule}/UPDATE_PHONE_USER`, phoneSimple);
                this.phoneStr = phoneSimple.slice(-10);

                const payload = {
                    idUser: this.getActualUser._id,
                    contactPhone: `+${this.user.phoneCountry.dialCode}${this.phoneStr}`,
                    checkDuplicatePhone: true
                };
                await this.$store.dispatch(`${storeModule}/sendVerificationCode`, payload).then((result) => {
                    this.$store.commit(`${storeModule}/UPDATE_PHONE_USER`, `+${this.user.phoneCountry.dialCode}${this.phoneStr}`);
                    if (result.error) {
                        this.showErrorCode = true;
                        this.errorCodeText = result.message;
                    } else {
                        this.smsSend = true;
                        this.smsSendShowMsg = true;
                    }
                }).catch((err) => {
                    this.showErrorCode = true;
                    this.errorCodeText = err.message;
                });
                this.loading = false;
            },

            /**
             * Obtiene el valor ingresado del código mediante el componente CodeInput ya que son varios input y los agrupa en una sola cadena.
             * @param value con el código de verificación.
             */
            getVerificationCode(value) {
                this.verificationCode = value;
            },

            /**
             *  Verifica que el código de seguridad ingresado coincida con el que se le envio por sms al usuario.
             */
            verifyCode() {
                this.smsSendShowMsg = false;
                this.showErrorCode = false;
                if (this.verificationCode.length < 5) {
                    this.verificationError = true;
                    this.verificationErrorText = this.$i18n.t('phone-verification.code-must-be-5');
                } else {
                    this.loadingText = this.$i18n.t('phone-verification.validating-code');
                    this.loading = true;
                    setTimeout(() => {
                        this.verificationError = false;
                        const payload = {
                            idUser: this.$session.get('userId'),
                            code: this.verificationCode
                        };

                        this.$store.dispatch(`${storeModule}/verifyCode`, payload).then((result) => {

                            if (!result) {
                                this.verificationError = true;
                                this.verificationErrorText = this.$i18n.t('phone-verification.code-not-valid');
                            } else {
                                this.verificationError = false;
                                this.verified = true;
                            }
                            this.loading = false;
                            this.isValidStepFour();
                        }).catch((err) => {
                            this.showErrorCode = true;
                            this.errorCodeText = err.message;
                            this.loading = false;
                            this.isValidStepFour();
                        });
                    }, 1000)
                }
            },


            /**
             * Verifica si se completaron los campos requeridos para éste paso.
             */
            isValidStepFour() {
                const isValid = this.verified;
                this.$store.commit(`${storeModule}/SET_IS_VALID_USER_SAVE_FORM`, isValid);
                this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                    step: 'phoneVerification',
                    prop: 'isReady',
                    value: isValid
                });
                EventBus.$emit('REGISTER_USER_READY_STEP');

            },

            /**
             * Inicializa los valores requeridos para el paso
             * @param event con la ocupación seleccionada.
             */
            initializeUser() {
                this.user.phone = this.getActualUser.phone !== undefined ? this.getActualUser.phone : '';
                if (this.user.phone && this.getActualUser.phoneVerified) {
                    this.phoneStr = this.user.phone.slice(-10);
                    this.verified = true;
                }
                this.isValidStepFour();
            },
            countryChanged(value) {
                this.user.phoneCountry = value;
            },
        },
        mounted() {
            this.initializeUser();
        },
        computed: {
            ...mapGetters(storeModule, ['getActualUser', 'isPhoneVerified']),
            ...mapGetters('SIGNIN_UP', ['getRightSide'])
        }
    }
</script>
