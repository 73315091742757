<template>
    <div>
        <WizardForm activeVertical clazz="full-view form-wizard-white">
            <template v-slot:wizardView>
                <tab-content title="Paso 1" icon="one">
<!--                   // Deleted commented code, check git to recover it-->
                </tab-content>

                <tab-content title="Paso 2" icon="two">
<!--                  // Deleted commented code, check git to recover it-->
                </tab-content>

                <tab-content title="Paso 3" icon="three">
<!--                  // Deleted commented code, check git to recover it-->
                </tab-content>

                <tab-content title="Paso 4" icon="four">
                    <SignInBody stepFour>
                        <template v-slot:formSectionBody>
<!--                          // Deleted commented code, check git to recover it-->
                        </template>
                    </SignInBody>
                </tab-content>

                <tab-content title="Paso 5" icon="five">
                    <SignInBody stepFive>
                        <template v-slot:formSectionBody></template>
                    </SignInBody>
                    <b-modal
                            id="modal-termsCond"
                            size="xl"
                            hide-header
                            hide-footer
                            no-close-on-esc
                            no-close-on-backdrop
                            hide-header-close
                    >
                        <div class="modalTermsCond">
                            <h1 class="title">
                                <strong>Términos y Condiciones</strong> de uso de Pagando
                            </h1>
                            <span class="date-update">
                <small>Última actualización:</small> 04 marzo, 2019
              </span>
                            <div class="text-container">
                                <h1>Aviso legal y Términos de uso de Pagando v 1.0.1</h1>
                                <p class="m-b-0">
                                    <strong>Términos y condiciones del uso mediante este aviso.</strong>
                                    <br/>PAGANDO informa a los usuarios de la página web de su política de protección de
                                    datos de carácter personal. La utilización del espacio web de PAGANDO y de
                                    cualquiera de los servicios que se incorporan presupone la plena aceptación de las
                                    condiciones que se manifiestan en la política de privacidad que se expone.
                                    <br/>
                                    <br/>
                                    <strong>Recogida de datos.</strong>
                                    <br/>Dando cumplimiento a la Ley 15/1999, de 13 de diciembre, de protección de datos
                                    de carácter personal, se informa que los datos de carácter personal que se solicitan
                                    en nuestros formularios se incluirán en un fichero de datos personales el
                                    responsable y titular del cual es PAGANDO. Así mismo, cuando una persona rellena
                                    cualquiera de los formularios con los datos personales que se solicitan y acepta el
                                    envío, autoriza expresamente a PAGANDO a tratar o incorporar en el fichero
                                    automatizado de su propiedad los datos personales facilitados en el formulario
                                    mencionado y todos los datos que se generen en relación con su participación o uso
                                    de los diferentes eventos que se ofrecen en esta página web.
                                    <br/>
                                    <br/>
                                    <strong>Uso de datos.</strong>
                                    <br/>Dando cumplimiento a la Ley 15/1999, de 13 de diciembre, de protección de datos
                                    de carácter personal, se informa que los datos de carácter personal que se solicitan
                                    en nuestros formularios se incluirán en un fichero de datos personales el
                                    responsable y titular del cual es PAGANDO. Así mismo, cuando una persona rellena
                                    cualquiera de los formularios con los datos personales que se solicitan y acepta el
                                    envío, autoriza expresamente a PAGANDO a tratar o incorporar en el fichero
                                    automatizado de su propiedad los datos personales facilitados en el formulario
                                    mencionado y todos los datos que se generen en relación con su participación o uso
                                    de los diferentes eventos que se ofrecen en esta página web.
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                            <button
                                    id
                                    class="button xs button-filled button-accent1"
                                    @click="$bvModal.hide('modal-termsCond')"
                                    type="button"
                            >Cerrar
                            </button>
                        </div>
                    </b-modal>
                </tab-content>
            </template>
        </WizardForm>
        <!-- Modal for showing 'info-section' on responsive -->
        <b-modal id="modal-info-responsive" hide-header hide-footer>
            <button
                    id
                    class="button-circle sm button-stroke button-info_text m-0-0-0-auto"
                    @click="$bvModal.hide('modal-info-responsive')"
                    type="button"
            >
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <SignInBody stepFive/>
        </b-modal>
    </div>
</template>

<script>
    // @ is an alias to /src
    import WizardForm from "@/components/WizardForm.vue";
    import SignInBody from "@/components/SignInBody.vue";
    import axios from "axios";
    import {mapGetters} from "vuex";

    const storeModule = "usersSignupWizard";

    export default {
        name: "RegisterWizard",
        props: {
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false}
        },
        components: {
            WizardForm,
            SignInBody,
        },
        created() {
            const sessionExists = this.$session.exists();
            if (sessionExists) {
                const token = this.$session.get("jwt");
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                const userId = this.$session.get("userId");
                this.$store.dispatch(`${storeModule}/getCurrentUserSession`, {userId});
            }
        },
        computed: {
            ...mapGetters(storeModule, ["getIsPhoto", "getActualUser", "getIsLoadUser"])
        }
    };
</script>
