<template lang="html">

  <CardSimple
    :title="$t('pagos.general.title')"
    :subtitle="$t('pagos.general.subtitle')"
  >
    <BasicInput
      required
      v-model="payment.name"
      labelFor="name"
      inputId="name"
      :label="$t('pagos.general.servicio.name.title')"
      :inputPlaceholder="$t('pagos.general.servicio.name.placeholder')"
    >
    </BasicInput>
    <BasicTextarea
        required
        v-model="payment.description"
        labelFor="description"
        textareaId="description"
        :label="$t('pagos.general.servicio.description.title')"
        :textareaPlaceholder="$t('pagos.general.servicio.description.placeholder')"
    >
    </BasicTextarea>
    <div class="row">
        <div class="col-md-6 col-12">
            <BasicSelect
                :label="$t('pagos.general.servicio.status.title')"
                labelFor
                help
                idHelpTooltip="ejGenerico4"
            >
                <multiselect
                    :disabled="payment.type === 'WS'"
                    v-model="status"
                    :options="statusOptions"
                    :close-on-select="true"
                    :preserve-search="true"
                    :placeholder="$t('pagos.general.servicio.status.placeholder')"
                    label="name"
                    track-by="name"
                    :preselect-first="false"
                >
                    <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                    >
                        <span
                            class="multiselect__single"
                            v-if="values.length && !isOpen"
                            >{{ values.length }} options selected
                        </span>
                    </template>
                </multiselect>
            </BasicSelect>
        </div>

      <div class="col-md-6 col-12">
        <BasicSelect
          required
          :label="$t('pagos.general.servicio.currency.title')"
          labelFor
          help
          idHelpTooltip="ejGenerico5"
        >
          <multiselect
            v-model="currency"
            :options="currencies"
            :close-on-select="true"
            :preserve-search="true"
            :placeholder="$t('pagos.general.servicio.currency.placeholder')"
            label="description"
            :custom-label="currencyLabel"
            track-by="name"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen" >
                    {{ values.length }} options selected
                </span>
            </template>
          </multiselect>
        </BasicSelect>

      </div>
    </div>
    <div class="row m-b-10">
      <div class="col-md-6 col-6">
        <h1 class="f-14 c-title body-font-regular m-0">{{ $t('pagos.general.servicio.image.title') }} <span class="c-error">*</span></h1>
      </div>
    </div>
    <div>
        <UploadArea
            clazz="m-b-30"
            clazzArea="col-6"
            @change="fileSelected"
        >
            <SimpleNote clazz="info m-b-0 m-l-30">
                <p class="m-b-0" v-html="$t('pagos.general.servicio.image.subtitle', { 'interpolation': {'escapeValue': false} })"></p>
            </SimpleNote>
        </UploadArea>

        <div class="vertical-upload-list">
            <draggable v-model="payment.images" class="d-flex">
                 <ListUploadArea v-for="(img, index) in payment.images" v-bind:key="img._id" @clickDelete="deleteImage(img)">
                    <UploadArea :imgUploaded="imageUrl(img)" hideButtonClose :numList="index + 1"></UploadArea>
                </ListUploadArea>
            </draggable>
        </div>
        <LoadingBar v-if="loadingImages"></LoadingBar>
    </div>

    <div class="row">
      <div class="col-12">
        <h1 class="f-14 c-title body-font-regular m-0">{{ $t('pagos.general.servicio.category.title') }} <span class="c-error">*</span></h1>
      </div>
    </div>

    <SimpleCarousel :itemsCarousel="[[0, 2],[600, 3],[991, 4],[1230, 4]]" clazz="simpleCarousel-container" :startPage="startPage()">
      <slide v-for="category in categories" v-bind:key="category.name">
        <CardItem :clazz="categoryCarouselClass(category)" @click.native="changeCategory(category)">
          <div class="img-container">
            <img class="background" src="@/assets/images/Default/dots-grid.svg" :alt="category.name"/>
            <img class="icon" :src="category.icon" :alt="category.name"/>
          </div>
          <div class="text-container">
            <h1>{{$t(category.label)}}</h1>
          </div>
        </CardItem>
      </slide>
    </SimpleCarousel>

    <div class="m-b-30">
      <BasicSelect
        required
        :label="$t('pagos.general.servicio.tags.title')"
        labelFor="bannerNum"
      >
        <multiselect
          v-model="valueTags"
          :options="optionsTags"
          :placeholder="$t('pagos.general.servicio.tags.placeholder')"
          tag-placeholder="Agregar como nuevo tag"
          label="name"
          track-by="name"
          :preselect-first="false"
          class="select-up"
          :multiple="true"
          :taggable="true"
          @search-change="tagAdded"
        ><span slot="noOptions">{{$t('pagos.general.servicio.tags.emptyList')}}</span>
        </multiselect>
      </BasicSelect>
    </div>

    <b-alert
        v-if="payment.tags.length === 0"
        class="alert-note"
        show
        variant="warning"
    >
        <p class="text-container">
            <img
                class="img-fluid icon"
                src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                alt="info"
            />
            {{$t('pagos.general.servicio.tags.no-tags')}}
        </p>
    </b-alert>

    <div>
      <button
        id
        class="collapse-btn"
        type="button"
        v-b-toggle.collapse-config
      >
        Configuración avanzada
        <i aria-hidden="true" class="banana banana-chevron-down"></i></button>
      <b-collapse
        id="collapse-config"
        class="m-t-40"
      >
        <slot name="advancedSettings"></slot>
      </b-collapse>
      <div class="row m-t-40">
        <div class="col-12 vertical-center justify-content-end">
            <button class="button button-ghost sm button-plain_text" @click="cancel">
                {{$t('general.actions.cancel.title')}}
            </button>

            <button v-if="!editMode && blackMagic" class="button button-filled sm button-accent1 m-l-10" :class="{disabled: !isValid()}" @click="next">
                {{$t('general.actions.next.title')}}
            </button>

            <button v-if="editMode && blackMagic" class="button button-filled sm button-accent1 m-l-10" :class="{disabled: !isValid()}" @click="finish">
                {{$t('general.actions.save')}}
            </button>
        </div>
      </div>
    </div>

  </CardSimple>

</template>

<script lang="js">
    import BasicInput from "@/components/BasicInput.vue";
    import CardSimple from '@/components/CardSimple.vue';
    import CardItem from "@/components/CardItem.vue";
    import BasicTextarea from "@/components/BasicTextarea.vue";
    import BasicSelect from "@/components/BasicSelect.vue";
    import multiselect from "vue-multiselect";
    import UploadArea from '@/components/UploadArea.vue';
    import SimpleNote from "@/components/SimpleNote.vue";
    import ListUploadArea from '@/components/ListUploadArea.vue';
    import SimpleCarousel from "@/components/SimpleCarousel.vue";
    import CurrenciesApi from '@/api/currencies/currency.api';
    import ImageApi from '@/api/images/image.api';
    import {utils} from '@/api/utils.js';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {values as Categories} from '@/common/consts/categories.consts';
    import LoadingBar from '@/components/LoadingBar';
    import draggable from 'vuedraggable';

    const ERROR_TITLE_KEY = 'general.actions.error.title';
    const ERROR_TEXT_KEY = "general.actions.error.text";
    const PAYMENT_ACTIVE_STATUS_KEY = 'pagos.general.servicio.status.active';
    const PAYMENT_INACTIVE_STATUS_KEY = 'pagos.general.servicio.status.inactive';
    const PAYMENT_COMMING_STATUS_KEY = 'pagos.general.servicio.status.comingSoon';

    export default {
    name: 'general',
    mixins: [ToastSnotify],
    props: {
        editMode: {type: Boolean, default: false},
        slotValid: {type: Boolean, default: true},
        payment: {
            type: Object,
            default: function () {
                return {
                    title: '',
                    name: '',
                    description: '',
                    allowMultiplication: false,
                    allowClientAdditionalNotes: false,
                    multiplication: 1,
                    clasification: '',
                    tags: [],
                    additionalNotesConfig: {
                        notesAsRequired: false,
                        title: '',
                        instructions: ''
                    }
                }
            }
        }
    },
    components: {
        CardSimple,
        CardItem,
        BasicInput,
        BasicSelect,
        BasicTextarea,
        multiselect,
        UploadArea,
        SimpleNote,
        ListUploadArea,
        SimpleCarousel,
        LoadingBar,
        draggable

    },
    mounted () {
        CurrenciesApi.get().then((data) => {
            this.currencies = data.data.object;
            this.setCurrencyFromPayment();
            const inLocalStorage = JSON.parse(localStorage.getItem('valueTags') || '[]');
            this.optionsTags = inLocalStorage;

            this.setStatusFromPayment();
            this.setTagsFromPayment();
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.message) {
              this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
            } else {
              this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
            }
        });
    },
    watch: {
        "payment.allowMultiplication": function (newValue) {
            if(!newValue) {
                this.payment.multiplication = 1;
            }
        },
        "payment.allowClientAdditionalNotes": function (newValue) {
            if(!newValue) {
                this.payment.additionalNotesConfig.title = '';
                this.payment.additionalNotesConfig.instructions = '';
            }
        },
        "payment.activeService": function() {
            this.setStatusFromPayment();
        },
        "payment.tags": function() {
            this.setTagsFromPayment();
        },
        "payment.sandBoxMode": function() {
            this.setStatusFromPayment();
        },
        "payment.comingSoon": function() {
            this.setStatusFromPayment();
        },
        "payment.currency": function() {
            this.setCurrencyFromPayment();
        },
        "currency": function (newValue) {
            this.payment.currency = newValue._id;
            this.payment.currencyObject = newValue; // Valor dummy para poder obtener en otros componentes el código de divisa
        },
        "status": function (newValue) {
            switch(newValue.name) {
                case this.$t(PAYMENT_ACTIVE_STATUS_KEY):
                    this.$set(this.payment, 'activeService', true);
                    this.$set(this.payment, 'comingSoon', false);
                    this.$set(this.payment, 'sanBoxMode', false);
                    break;
                case this.$t(PAYMENT_INACTIVE_STATUS_KEY):
                    this.$set(this.payment, 'activeService', false);
                    this.$set(this.payment, 'comingSoon', false);
                    this.$set(this.payment, 'sanBoxMode', true);
                    break;
                case this.$t(PAYMENT_COMMING_STATUS_KEY):
                default:
                    this.$set(this.payment, 'activeService', false);
                    this.$set(this.payment, 'comingSoon', true);
                    this.$set(this.payment, 'sanBoxMode', true);
                    break;
            }
            this.$forceUpdate();
        },
        "valueTags": function(newValue) {
            let inLocalStorage = JSON.parse(localStorage.getItem('valueTags') || '[]');
            const newTags = newValue.filter(n => !inLocalStorage.find(l => l.name === n.name));
            inLocalStorage = inLocalStorage.concat(newTags);
            this.optionsTags = inLocalStorage;
            localStorage.setItem('valueTags', JSON.stringify(inLocalStorage));
            this.payment.tags = newValue.map(t => t.name);
            this.$forceUpdate();
      }
    },
    data () {
      return {
        // Date Range Picker
        blackMagic: true,
        currency: '',
        opens: 'left',
        showDropdowns: true,
        autoApply: true,
        dateRange: [],
        valid: false,
        checkedSwitch: false,
        value: [],
        status: this.$t(PAYMENT_ACTIVE_STATUS_KEY),
        statusOptions: [
          { name: this.$t(PAYMENT_ACTIVE_STATUS_KEY) },
          { name: this.$t(PAYMENT_INACTIVE_STATUS_KEY) },
          { name: this.$t(PAYMENT_COMMING_STATUS_KEY) }
        ],
        categories: Categories,
        currencies: [],
        typeSelect: [
          { name: 'Cantidad' },
          { name: 'Porcentaje' }
        ],
        valueTags: [],
        optionsTags: [],
        searchTag: '',
        loadingImages: false,
      }
    },
    methods: {
        setTagsFromPayment() {
            if (this.payment.tags && this.valueTags && this.payment.tags.length > 0 && this.valueTags.length === 0) {
                this.valueTags = this.payment.tags.map((t) => {return {name: t}});
            }
        },
        setCurrencyFromPayment() {
            if (this.payment.currency) {
                const currentCurrency = this.currencies.find(c => c._id === (this.payment.currency._id || this.payment.currency));
                if (currentCurrency) {
                    this.currency = currentCurrency;
                    this.payment.currencyObject = currentCurrency;
                    this.$forceUpdate();
                }
            }
        },
        setStatusFromPayment() {
            if (this.payment.activeService) {
                this.status = {name: this.$t(PAYMENT_ACTIVE_STATUS_KEY)};
            } else if (this.payment.comingSoon) {
                this.status = {name: this.$t(PAYMENT_COMMING_STATUS_KEY)};
            } else {
                this.status = {name: this.$t(PAYMENT_INACTIVE_STATUS_KEY)};
            }
        },
        cancel() {
            this.$emit('cancel');
        },
        next() {
            this.$emit('next');
        },
        finish() {
            this.$emit('finish');
        },
        currencyLabel(currency) {
            return `${currency.description} (${currency.code})`;
        },
        fileSelected(files) {
            if(this.payment.images.length >= 4){
                this.displayNotificationError(this.$t("pagos.general.actions.images.error.limit-exceeded"), this.$t(ERROR_TITLE_KEY));
                return;
            }
            this.loadingImages = true;
            if (!this.payment.images){
                this.payment.images = [];
            }

            if(this.payment.images.length > 3){
                this.loadingImages = false;
                this.displayNotificationWarning("Solo se permite un maximo de 4 imagenes");
            }else{
                ImageApi.upload(files[0], {temp: true}).then((data) => {
                    this.loadingImages = false;
                    this.payment.images.push(data.data.object._id);
                    localStorage.setItem('editingPayment', JSON.stringify(this.payment));
                    this.$forceUpdate();
                }).catch((error) => {
                    this.loadingImages = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            }
        },
        imageUrl(img) {
            return utils.imageDownloadLink(img);
        },
        deleteImage(img) {
            this.loadingImages = true;
            ImageApi.delete(img).then(() => {
                const index = this.payment.images.indexOf(img);
                if (index >= 0) {
                    this.payment.images.splice(index, 1);
                    localStorage.setItem('editingPayment', JSON.stringify(this.payment));
                }
                this.loadingImages = false;
                this.$forceUpdate();
            }).catch((error) => {
                this.loadingImages = false;
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else if (error && error.response && error.response.status === 404) {
                    const index = this.payment.images.indexOf(img);
                    if (index >= 0) {
                        this.payment.images.splice(index, 1);
                        localStorage.setItem('editingPayment', JSON.stringify(this.payment));
                    }
                    this.$forceUpdate();
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        changeCategory(category) {
            this.payment.clasification = category.name;
            this.$forceUpdate();
        },
        categoryCarouselClass(category) {
            return `category-card ${category.name === this.payment.clasification ? 'selected' : ''}`;
        },
        tagAdded(query) {
             if (query.length > 0) {
                this.searchTag = query;
            } else if (this.searchTag.length > 0 && !this.valueTags.find(t => t.name === this.searchTag)) {
                let name = this.searchTag;
                if (name.indexOf('#') !== 0) {
                    // no tiene gato
                    if(name.indexOf(',') > -1){
                        name.trim().split(',').forEach(word => {
                            name = "";
                            name = `#${ word}`;
                            this.valueTags.push({name});
                        })
                    } else {
                            // sin comas ni gato
                        // eslint-disable-next-line sonarjs/no-identical-functions
                            name.trim().split(' ').forEach(word => {
                            name = "";
                            name = `#${ word}`;
                            this.valueTags.push({name});
                        })
                    }
                } else if (name.indexOf('#') > -1) {
                    // tiene comas pero no gato
                    if (name.indexOf(',') > -1) {
                        name.split(',').forEach(word => {
                            name = "";
                            name = word.trim();

                            this.valueTags.push({name});
                        })
                    } else
                        // si no tiene comas
                    if (name.split('#').length - 1 > 1) {
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        name.trim().split('#').filter(Boolean).forEach(word => {
                            name = "";
                            name = `#${word}`;

                            this.valueTags.push({name});
                        })
                    }
                }
                this.searchTag = '';
            }
            this.$forceUpdate();
        },
        isValid() {
            if (!this.payment.name || this.payment.name.length <= 0) {
                return false;
            }
            if(!this.payment.description || this.payment.description.length <= 0) {
              return false;
            }
            if (!this.payment.currency || this.payment.currency.length <= 0) {
              return false;
            }
            if (!this.payment.images || this.payment.images.length <= 0) {
              return false;
            }
            if (!this.payment.clasification) {
              return false;
            }
            if (!this.payment.tags || this.payment.tags.length <= 0) {
              return false;
            }
            return this.slotValid;
        },
        startPage() {
            if (this.payment && this.payment.clasification) {
                const category = this.categories.find(c => c.name === this.payment.clasification);
                if (category){
                    const index = this.categories.indexOf(category);
                    if (index >= 0 && index < 4) {
                        return 0;
                    } else if (index >= 4 && index < 8) {
                        return 1;
                    } else if (index >= 8 && index < 12) {
                        return 2;
                    } else if (index >= 0 && index < 3) {
                        return 3;
                    }
                }
            }
            return 0;
        }
    }
}
</script>
