import GenericTitle from "@/components/GenericTitle.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import SimpleNote from "@/components/SimpleNote.vue";

export default {
    data () {
        return {
            registerPassword: "",
            loginPassword: "",
            error: "",
            clazzPasswordStatus: "",
            labelPasswordStatus: "",
            passwordInputType: "password",
            showStatusBar: false
        }
    },
    components: {
        GenericTitle,
        PasswordInput,
        SimpleNote
    },
    methods: {
        onPasswordChangeLogin (password) {
            this.loginPassword = password;
        },
        changeTypePassword (isVisibleOn) {
            if (isVisibleOn) {
                this.passwordInputType = "password";
            } else {
                this.passwordInputType = "text";
            }
        },
        getRepeatCharacter (password) {
            const pattern = /(.+)(?=.*?\1)/g;
            let score = 0;
            if (pattern.test(password)) {
                const matches = password.match(pattern);
                const maxResultLength = this.sortByLength(matches)[0].length;
                let ratio = 0;
                if (maxResultLength >= 1 && maxResultLength <= 5) {
                    ratio = -8;
                }
                if (maxResultLength >= 6 && maxResultLength <= 10) {
                    ratio = -5;
                }
                if (maxResultLength >= 11) {
                    ratio = -2;
                }
                score =
                    ratio * maxResultLength + (password.length - maxResultLength * 2);
                if (score > 0) {
                    score = 0;
                }
            }
            return score;
        },
        nextChar (c) {
            return String.fromCharCode(c.charCodeAt(0) + 1);
        },
        prevChar (c) {
            return String.fromCharCode(c.charCodeAt(0) - 1);
        },
        sortByLength (arr, limit) {
            arr.sort(function (a, b) {
                return b.length - a.length;
            });
            const list = [];
            for (let index = 0; index < arr.length; index++) {
                if (limit) {
                    if (arr[index].length >= limit) {
                        list.push(arr[index]);
                    }
                } else {
                    list.push(arr[index]);
                }
            }
            return list;
        }
    }
};
