// ability.js
import { AbilityBuilder, Ability } from '@casl/ability'


 const buildAbilities = function({profile, permissions}){
    const { can, rules } = new AbilityBuilder(Ability);
    if(profile.organization){
        can("manage" , profile.organization)
    }
    for(const permission of permissions) {
        can("do" , permission)
    }
    return rules;
}


export default {
    buildAbilities
}
