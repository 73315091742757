<template>
    <!-- SOCIAL MEDIA SUCCESS CONGRATS -->
    <div class="fullpage-container-body">
        <GenericTitle clazz="text-align-c" :title="$t('social-media-verification.congratulations')"
                      :subtitle="$t('social-media-verification.congratulations-subtitle')"/>

        <img class="img-fluid center-item d-flex m-b-40"
             src="@/assets/images/Illustrations/illustration-social-media-validation.svg" alt="illustration"/>

        <button id="" class="button xs button-filled button-accent1" type="submit">
            {{$t('social-media-verification.continue-registration')}}
        </button>

        <span class="f-14 c-info_text body-font-regular text-align-c d-block m-t-30 m-b-30">{{$t('social-media-verification.technical-assistance1')}}
          <br> <strong>{{$t('social-media-verification.technical-assistance2')}}</strong>{{$t('social-media-verification.technical-assistance3')}}
          <strong>{{$t('social-media-verification.technical-assistance4')}} </strong> {{$t('social-media-verification.technical-assistance5')}} </span>
    </div>
</template>

<script>
    // @ is an alias to /src
    import GenericTitle from '@/components/GenericTitle.vue'

    export default {
        name: "SignInFullPage",
        components: {
            GenericTitle
        },
        props: {}
    };
</script>
