<template>
    <a :href="idScroll" class="btn-scroll-bottom" :class="clazz" tabindex="">
        {{btnText}}
        <i aria-hidden="true" class="banana banana-chevron-down"></i>
    </a>
</template>

<script>
    export default {
        name: 'ButtonScrollBottom',
        props: {
            clazz: String,
            idScroll: String,
            btnText: String
        }
    }
</script>

<style lang="scss">
.btn-scroll-bottom {
    position: relative;
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    z-index: 2;
    margin-top: -5px;

    &:hover {
        opacity: 0.8;

        i {
            animation: rubberBand 1s ease-in-out;
        }
    }

    > i {
        margin: 12px auto 0;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        min-width: 30px;
        align-content: center;
        justify-content: center;
        font-size: 22px;
        transition: 0.5s;

        &::before {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            min-width: 30px;
        }
    }
}
</style>
