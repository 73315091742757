<template>
    <div>
        <SignUpOrganizationBody stepFive>
            <template v-slot:formSectionBody>
                <GenericTitle id="dct-orgStepFour" clazz="p-t-20" :title="$t('signup-organization.step-four.title')"
                              :subtitle="$t('signup-organization.step-four.sub-title')"></GenericTitle>

                <StepValidation :text="getErrorNextStepMessage" icon="banana-cross-normal" status="error"
                                v-if="getErrorNextStep"></StepValidation>
                <form>
                    <div class="row">
                        <div class="col-12">
                            <BasicSelect
                                    id="dct-selectBank"
                                    :required="true"
                                    :label="$t('signup-organization.step-four.bank')"
                                    labelFor="bank"
                                    :clazz="getErrorClass('bank')"
                                    help
                                    idHelpTooltip="bankHelp"
                                    helpIcon="banana banana-question-circle1"
                                    :warningText="$v.bank.$error ? $t('signup-organization.step-four.bank-validation') : ''"
                            >
                                <multiselect id="txt-orgBankName"
                                             v-model="bank"
                                             :options="banksForSelect"
                                             @select="updateBank"
                                             @input="updateBank"
                                             :close-on-select="true"
                                             :preserve-search="true"
                                             :placeholder="$t('signup-organization.step-four.bank-drop-down.placeholder')"
                                             label="name" track-by="name" :preselect-first="false">
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }}
                                            {{$t('general.drop-down.opt-selected')}}</span>
                                    </template>
                                </multiselect>
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="bankHelp" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                {{$t('signup-organization.step-four.bank-help.if-you')}} <strong>{{$t('general.support-MXN-number')}}</strong>
                                                {{$t('general.support-MXN-number-for-mexico')}} <strong>{{$t('general.support-US-number')}}</strong>
                                                {{$t('general.support-US-number-for-US')}}
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicSelect>
                        </div>

                        <div class="col-12">
                            <BasicInput
                                    required
                                    :inputValue="getPotentialOrganization.accountNumber"
                                    inputType="number"
                                    labelFor="accountNumber"
                                    inputId="accountNumber"
                                    :label="$t('signup-organization.step-four.account-number')"
                                    :clazz="getErrorClass('accountNumber')"
                                    v-on:basicInput="updateAccountNumber"
                                    :inputPlaceholder="$t('signup-organization.step-four.account-number.placeholder')"
                                    :warningText="$v.accountNumber.$error ? $t('signup-organization.step-four.account-number.error') : ''"
                                    help
                                    idForm="dct-emptyAcc"
                                    idHelpTooltip="accountNumberHelp"
                                    helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="accountNumberHelp" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                {{$t('signup-organization.step-four.account-number.help')}}
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>

                        <div class="col-12">
                            <BasicInput
                                    required
                                    :inputValue="getPotentialOrganization.clabe"
                                    inputType="number"
                                    labelFor="clabe"
                                    inputId="clabe"
                                    :label="$t('signup-organization.step-four.clabe')"
                                    :clazz="getErrorClass('clabe')"
                                    v-on:basicInput="updateClabe"
                                    :inputPlaceholder="$t('signup-organization.step-four.clabe.placeholder')"
                                    :warningText="$v.clabe.$error ? $t('signup-organization.step-four.clabe.error') : ''"
                                    help
                                    idForm="dct-emptyClabe"
                                    idHelpTooltip="clabeHelp"
                                    helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="clabeHelp" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                {{$t('signup-organization.step-four.clabe.help')}}
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>

                        <div class="col-12">
                            <BaseFormAnnotation :text="$t('general.required-fields')"></BaseFormAnnotation>
                        </div>
                    </div>
                </form>

            </template>
        </SignUpOrganizationBody>

    </div>
</template>

<script>
    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue';
    import GenericTitle from '@/components/GenericTitle.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import BasicSelect from '@/components/BasicSelect.vue';
    import Multiselect from 'vue-multiselect';
    import StepValidation from '@/components/StepValidation.vue';
    import BaseFormAnnotation from '@/components/signUpOrganizations/base/form/BaseFormAnnotation.vue';
    import {required, minLength} from 'vuelidate/lib/validators';

    import {mapGetters} from 'vuex';

    const storeModule = "signUpOrganization";

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    const clabeValidation = val => {
        return /^\d{18}$/.test(val);
    };

    const accountValidation = val => {
        return /^\d{10}$/.test(val) || /^\d{11}$/.test(val);
    };

    export default {
        name: "StepBanking",
        data() {
            return {
                banks: [],
                banksForSelect: [],
                bank: '',
                accountNumber: '',
                clabe: ''
            }
        },
        components: {
            SignUpOrganizationBody,
            GenericTitle,
            BasicInput,
            BasicSelect,
            Multiselect,
            StepValidation,
            BaseFormAnnotation
        },
        computed: {
            ...mapGetters(storeModule, ['getPotentialOrganization', 'getErrorNextStep', 'getErrorNextStepMessage', 'getIsValidStepFormSave'])
        },
        methods: {
            getErrorClass(propertie) {
                return this.$v[propertie].$error ? "has-error" : "";
            },
            getBanksForSelect() {
                for (let i = 0; i < this.banks.length; i++) {
                    const newBank = {
                        name: this.banks[i].name,
                        value: this.banks[i]._id,
                        disabled: false
                    };
                    this.banksForSelect.push(newBank);
                }
            },
            validateStep() {
                let isValid = true;

                isValid = !this.$v.bank.$error &&
                    !this.$v.accountNumber.$error &&
                    !this.$v.clabe.$error;

                // Se verifican los campos tambien para que no muestre en un inicio todos los errores de validacion, sino que sea conforme va llenando el formulario.
                isValid = isValid && this.bank &&
                    this.accountNumber &&
                    this.clabe;

                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_FOUR`, isValid);
            },
            updateBank: debounce(function (event) {
                this.bank = event;
                this.$v.bank.$touch();
                this.validateStep();
                if(event !== null){
                    this.$store.commit(`${storeModule}/UPDATE_BANK_POTENTIAL_ORGANIZATION`, event);
                }
            }, 800, false),
            updateAccountNumber: debounce(function (accountNumber) {
                this.accountNumber = accountNumber;
                this.$v.accountNumber.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_BANK_ACCOUNT_NUMBER`, accountNumber);
            }, 800, false),
            updateClabe: debounce(function (clabe) {
                this.clabe = clabe;
                this.$v.clabe.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_BANK_CLABE`, clabe);
            }, 800, false),
            readFile(imgFile) {
                return new Promise((resolve) => {
                    const fr = new FileReader();
                    fr.onload = (e) => {
                        const actualUserImg = {};
                        const dataUrl = e.target.result;
                        actualUserImg.name = imgFile.name;
                        actualUserImg.type = imgFile.type;
                        actualUserImg.size = imgFile.size;
                        actualUserImg.data = dataUrl;
                        resolve(actualUserImg)
                    };
                    fr.readAsDataURL(imgFile);
                });
            },
            async initializeValues() {
                // Solo para verificar si se cargo el potential organization
                await this.$store.dispatch('signUpOrganization/loadPotentialOrganization');

                this.clabe = this.getPotentialOrganization.clabe ? this.getPotentialOrganization.clabe : '';
                this.bank = this.getPotentialOrganization.bankName ? {name: this.getPotentialOrganization.bankName} : '';
                this.accountNumber = this.getPotentialOrganization.accountNumber ? this.getPotentialOrganization.accountNumber : '';

                this.validateStep();
            }
        },
        validations: {
            bank: {
                required,
                minLength: minLength(3)
            },
            accountNumber: {
                required,
                accountValidation
            },
            clabe: {
                required,
                clabeValidation
            }
        },
        created() {
            this.$store.dispatch(`${storeModule}/getBanks`).then((result) => {
                this.banks = result.docs;
                this.getBanksForSelect();

            });

            this.initializeValues();
        }
    }
</script>
