<template>
    <div class="row">
        <LoadingBar v-if="loading"></LoadingBar>
        <div class="col-12">
            <router-link
                class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                tabindex=""
                to="/client-admin/payments"
            ><i aria-hidden="true" class="banana banana-arrow-left"></i>{{$t('debtors.return-to-payments')}}</router-link>
            <FloatingTitleLayout :title="$t('debtors.config')" />
        </div>
        <div class="col-12">
            <CardSimple clazzTitle="d-none">
                <div class="row">
                    <div class="col-md-6 col-12 m-b-20">
                        <h1 class="f-20 c-primary_1 principal-font-bold m-0">
                            {{$t("debtors.service-general-data")}}
                        </h1>
                    </div>
                    <div class="col-md-6 col-12 m-b-20">
                        <div class="row">
                            <div class="col-lg-6 col-12 d-flex mb-lg-0 mb-2">
                                <span class="m-r-20 f-14 body-font-bold c-title">{{$t('debtors.status')}}</span>
                                <Badge
                                    v-if="service.comingSoon"
                                    type="badge-warning"
                                    :text="$t('debtors.coming-soon')"
                                ></Badge>
                                <Badge
                                    v-else-if="service.activeService"
                                    type="badge-accent_2"
                                    :text="$t('debtors.active-service')"
                                ></Badge>
                                <Badge
                                    v-else
                                    type="badge-placeholder"
                                    :text="$t('debtors.inactive')"
                                ></Badge>
                            </div>
                            <div class="col-lg-6 col-12 d-flex">
                                <span class="m-r-20 f-14 body-font-bold c-title">{{$t('debtors.currency')}}</span>
                                <span class="f-14 body-font-regular c-plain_text">{{ (service.currency || {}).description + ' (' + (service.currency || {}).code + ')' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 ">
                        <h1 class="f-20 c-title body-font-regular m-0 m-b-10">
                            {{ service.name }}
                        </h1>
                        <h2 class="f-14 body-font-regular f-600 c-info_text mb-lg-0 mb-2">
                            {{$t(`organizaciones.category.options.${service.clasification}`)}}
                        </h2>
                    </div>
                    <div class="col-md-6 col-12 ">
                        <p class="c-plain_text f-14 body-font-regular m-0">
                            {{ service.description }}
                        </p>
                    </div>
                </div>
            </CardSimple>
        </div>
        <div
            class="col-12"
            v-if="!foundValues"
        >
            <CardSimple
                hideTitle
                clazz="p-0"
                clazzTitle="d-none"
            >
                <EmptyState
                    :title="$t('debtors.empty-state.title')"
                    :text="$t('debtors.empty-state.text')"
                    :imgUrl="EmptyStateGroupImg"
                >
                    <button
                        class="button sm button-filled button-accent1"
                        @click="addDebtor"
                    >{{$t('debtors.new')}}</button>
                </EmptyState>
            </CardSimple>
        </div>

        <div
            class="col-12"
            v-if="!service || !service.fields || service.fields.length === 0"
        >
            <CardSimple
                hideTitle
                clazz="p-0"
                clazzTitle="d-none"
            >
                <EmptyState
                    :title="$t('debtors.error-state.title')"
                    :text="$t('debtors.error-state.text')"
                    :imgUrl="EmptyStateGroupImg"
                >
                </EmptyState>
            </CardSimple>
        </div>

        <div
            class="col-12"
            v-if="foundValues && service && service.fields && service.fields.length > 0"
        >
            <SearchBar
                @searchTextChange="search"
                @routerPush="addDebtor"
                :noOptions="true"
                :columValues="columnValues"
                @filterSelected="filterSelected"
                @changeListLimit="changeListLimit"
                :perPage="perPage"
                ref="searchBar"
            ></SearchBar>

            <CardSimple
                clazzTitle="d-none"
                clazz="w-100 title-padding p-0"
            >
                <div
                    class="basic-table"
                    id="tableOne"
                >
                    <b-table-simple
                        responsive
                        hover
                        ref="selectableTable"
                        selectable
                        :select-mode="selectMode"
                        selected-variant="active"
                        table-variant="xl"
                    >
                        <b-thead>
                            <b-tr :class="headTableRowClass">

                                <b-th sortable>
                                    <div class="align-items-c d-flex">
                                        <BasicCheckbox
                                            ref="selectAll"
                                            v-model="selectAll"
                                            clazz="m-0 w-fit di-flex"
                                        ></BasicCheckbox>

                                        <span
                                            v-show="columnValue(0).value"
                                            class="open-filters"
                                            @click="headerFilters()"
                                        >{{ tableColumns[0].name }}</span>

                                        <strong
                                            class="float-r m-l-5"
                                            v-show="columnValue(0).value"
                                        >
                                            <div
                                                @click="updateSort(tableColumns[0].nameForMatch, 1)"
                                                class="h-10px align-items-c d-flex c-pointer"
                                            >
                                                <i aria-hidden="true" class="banana banana-triangle-up f-14"></i>
                                            </div>
                                            <div
                                                @click="updateSort(tableColumns[0].nameForMatch, -1)"
                                                class="h-10px align-items-c d-flex c-pointer"
                                            >
                                                <i aria-hidden="true" class="banana banana-triangle-down f-14"></i>
                                            </div>
                                        </strong>

                                    </div>
                                    <div
                                        v-show="columnValue(0).value"
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            v-model="match[tableColumns[0].nameForMatch]"
                                            :labelFor="tableColumns[0].name"
                                            :inputId="tableColumns[0].name"
                                            @input="headFilterChange"
                                            :inputPlaceholder="tableColumns[0].name"
                                            clazz="noLabel m-l-30 p-r-30"
                                        ></BasicInput>
                                    </div>
                                </b-th>

                                <template v-for="(col) in tableColumns.length - 1">
                                    <b-th
                                        v-show="columnValue(col).value"
                                        sortable
                                        :key="col.name"
                                    >
                                        <div class="align-items-c d-flex">
                                            <span
                                                class="open-filters"
                                                @click="headerFilters()"
                                            >{{ tableColumns[col].name }}</span>

                                            <strong class="float-r m-l-5">
                                                <div
                                                    @click="updateSort(tableColumns[col].nameForMatch, 1)"
                                                    class="h-10px align-items-c d-flex c-pointer"
                                                >
                                                    <i aria-hidden="true" class="banana banana-triangle-up f-14"></i>
                                                </div>
                                                <div
                                                    @click="updateSort(tableColumns[col].nameForMatch, - 1)"
                                                    class="h-10px align-items-c d-flex c-pointer"
                                                >
                                                    <i aria-hidden="true" class="banana banana-triangle-down f-14"></i>
                                                </div>
                                            </strong>
                                        </div>
                                        <div
                                            class="filters"
                                            :class="[filtersHeader ? 'show' : '']"
                                        >
                                            <BasicInput
                                                @input="headFilterChange"
                                                v-model="match[tableColumns[col].nameForMatch]"
                                                :labelFor="tableColumns[col].name"
                                                :inputId="tableColumns[col].name"
                                                :inputPlaceholder="tableColumns[col].name"
                                                clazz="noLabel"
                                            ></BasicInput>
                                        </div>
                                    </b-th>
                                </template>

                                <b-th class="text-align-c">
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    >{{$t('debtors.payed')}}</span>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicSelect>
                                            <multiselect
                                                v-model="paidOptionSelected"
                                                :options="paidOptions"
                                                :close-on-select="true"
                                                :preserve-search="true"
                                                :placeholder="$t('debtors.payment')"
                                                label="name"
                                                track-by="name"
                                                :preselect-first="false"
                                            >
                                                <template
                                                    slot="selection"
                                                    slot-scope="{ values, search, isOpen }"
                                                >
                                                    <span
                                                        class="multiselect__single"
                                                        v-if="values.length &amp;&amp; !isOpen"
                                                    >{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>

                                <b-th class="text-align-c">
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    >{{$t('debtors.status')}}</span>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicSelect>
                                            <multiselect
                                                v-model="statusOptionSelected"
                                                :options="statusOptions"
                                                :close-on-select="true"
                                                :preserve-search="true"
                                                :placeholder="$t('debtors.status')"
                                                label="name"
                                                track-by="name"
                                                :preselect-first="false"
                                            >
                                                <template
                                                    slot="selection"
                                                    slot-scope="{ values, search, isOpen }"
                                                >
                                                    <span
                                                        class="multiselect__single"
                                                        v-if="values.length &amp;&amp; !isOpen"
                                                    >{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>

                                <b-th class="text-align-c">
                                    <span>{{$t('general.actions.actions')}}</span>
                                </b-th>

                                <b-th class="row-options">
                                    <div class="vertical-center">
                                        <BasicCheckbox
                                            ref="selectAllHeader"
                                            v-model="selectAllHeader"
                                            clazz="m-0 w-fit di-flex"
                                            :text="$tc('general.selected-elements', totalSelectedRows)"
                                            textClass="f-14 c-primary_1 principal-font-bold"
                                        ></BasicCheckbox>
                                        <div>
                                            <a
                                                class="opt-row"
                                                tabindex
                                                @click="startDeleteMulti"
                                            >
                                                <i aria-hidden="true" class="banana banana-delete"></i> {{$t('general.actions.delete.title')}}
                                            </a>
                                            <a
                                                @click="selectAll = false"
                                                class="opt-row"
                                                tabindex
                                            >{{$t('general.actions.cancel.title')}}</a>
                                        </div>
                                    </div>
                                </b-th>
                            </b-tr>
                        </b-thead>

                        <b-tbody v-if="convertedDebtors.length === 0">
                            <b-tr
                                role="row"
                                class="b-table-busy-slot"
                            >
                                <b-td
                                    :colspan="tableColumns.length + 3"
                                    role="cell"
                                    class=""
                                >
                                    <EmptyState
                                        emptyStateDefault
                                        size="sm"
                                        title="No se encontraron resultados con la búsqueda actual"
                                    >
                                        <button
                                            @click="resetFilters"
                                            type="button"
                                            class="button xs button-stroke button-accent1 m-0-auto m-t-30"
                                        >Reiniciar filtros</button>
                                    </EmptyState>
                                </b-td>
                            </b-tr>
                        </b-tbody>

                        <b-tbody v-if="convertedDebtors.length > 0">
                            <b-tr
                                v-for="(debtor, index) in convertedDebtors"
                                :class="bodyTableRowClass(index)"
                                :key="index"
                            >
                                <template v-for="(col, cIndex) in tableColumns">
                                    <b-td
                                        v-show=" cIndex === 0 || columnValue(cIndex).value"
                                        :key="col.name"
                                        class
                                    >
                                        <div
                                            v-if="cIndex === 0"
                                            class="d-flex align-items-c"
                                        >
                                            <BasicCheckbox
                                                v-model="selectedRows[index]"
                                                clazz="m-0 w-fit di-flex"
                                                @click.native="updateCheckboxes"
                                                :ref="checkboxRefName(index)"
                                            ></BasicCheckbox>
                                            <router-link
                                                to
                                                class="c-primary_1"
                                                v-show="columnValue(cIndex).value"
                                            >
                                                <strong>{{(debtor[col.name] || {}).value || ''}}</strong>
                                            </router-link>
                                        </div>
                                        <div v-show="cIndex > 0 && columnValue(cIndex).value">
                                            {{(debtor[col.name] || {}).value || ''}}
                                        </div>
                                    </b-td>
                                </template>

                                <b-td class="text-align-c">
                                    <Badge
                                        v-if="debtor.transaction && debtor.transaction.length > 0"
                                        type="badge-success"
                                        :text="$t('debtors.paid')"
                                    ></Badge>
                                    <Badge
                                        v-else
                                        type="badge-error"
                                        :text="$t('debtors.pending')"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-c">
                                    <Badge
                                        v-if="debtors[index].activeService"
                                        type="badge-accent_2"
                                        :text="$t('debtors.active-service')"
                                    ></Badge>
                                    <Badge
                                        v-else-if="debtors[index].comingSoon"
                                        type="badge-warning"
                                        :text="$t('debtors.comingSoon')"
                                    ></Badge>
                                    <Badge
                                        v-else
                                        type="badge-placeholder"
                                        :text="$t('debtors.inactive')"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-c">
                                    <b-dropdown
                                        @show="showDropdown"
                                        @hide="hideDropdown"
                                        id="dropdown-table"
                                        right
                                        class="dropdown-table"
                                    >
                                        <template v-slot:button-content>
                                            <button class="button-circle xs button-ghost button-info_text">
                                                <i aria-hidden="true" class="banana banana-options-other-vertical"></i>
                                            </button>
                                        </template>
                                        <b-dropdown-group :header="$t('general.actions.more')">

                                            <b-dropdown-item-button v-if="debtor.transaction && debtor.transaction.lenth > 0">
                                                <router-link
                                                    :to="`/client-admin/debtors/${service._id}/${debtor._id}=editMode=true`"
                                                    tabindex
                                                >
                                                    <i aria-hidden="true" class="banana banana-visibility-on"></i> {{$t('pagos.rows.actions.show')}}
                                                </router-link>
                                            </b-dropdown-item-button>

                                            <b-dropdown-item-button v-else>
                                                <router-link
                                                    :to="`/client-admin/debtors/${service._id}/${debtor._id}`"
                                                    tabindex
                                                >
                                                    <i aria-hidden="true" class="banana banana-edit"></i> {{$t('general.actions.edit.title')}}
                                                </router-link>
                                            </b-dropdown-item-button>

                                            <b-dropdown-divider></b-dropdown-divider>

                                            <b-dropdown-item-button
                                                @click="showDeleteDebtorModal(debtor._id)"
                                                variant=" c-error"
                                            >
                                                <router-link
                                                    to
                                                    class="c-error"
                                                    tabindex
                                                >
                                                    <i aria-hidden="true" class="banana banana-delete"></i> {{$t('general.actions.delete.title')}}
                                                </router-link>
                                            </b-dropdown-item-button>

                                        </b-dropdown-group>
                                    </b-dropdown>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </CardSimple>

            <div class="pagination-custom">
                <span class="pagination-count">{{$tc('debtors.page-count', rows)}}</span>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    next-text
                    prev-text
                    hide-goto-end-buttons
                ></b-pagination>
            </div>
        </div>

        <ModalDefault
            modalId="modal-Compartir"
            originalFooter
            :title="$t('share.title')"
            :subtitle="$t('share.subtitle')"
            :buttonClose="$t('general.actions.cancel.title')"
            :buttonText="$t('general.actions.done.title')"
            @clickAccepted="saveEmails"
            @clickClose="clearTags"
            :disabled="!isValid"
        >
            <div class="row">
                <div class="col-12 vertical-center m-b-30">
                    <div class="w-90">
                        <BasicInput
                            clazz="m-b-0"
                            inputType="text"
                            labelFor="enlace"
                            inputId="enlace"
                            :label="$t('share.link')"
                            :inputPlaceholder="$t('share.link')"
                            disabled
                            :inputValue="shareLink()"
                        ></BasicInput>
                    </div>
                    <div class="w-10 vertical-center justify-content-end m-t-15">
                        <button
                            type="button"
                            class="button-circle sm button-ghost button-accent2"
                            @click="copyClipboard"
                        ><i aria-hidden="true" class="banana banana-copy"></i></button>
                    </div>
                </div>
                <div
                    class="col-12"
                    v-if="copiedToClipboard"
                >
                    <b-alert
                        class="alert-note"
                        show
                        dismissible
                        variant="success"
                    >
                        <p class="text-container">
                            <i aria-hidden="true" class="icon banana banana-check-normal"></i>
                            <span v-html="$t('share.copied')"></span>
                        </p>
                    </b-alert>
                </div>
                <div class="col-12">
                    <BasicSelect
                        :label="$t('organizaciones.contact-info.modal.people.title')"
                        labelFor="people"
                    >
                        <Multiselect
                            v-model="valueTags"
                            :placeholder="$t('organizaciones.contact-info.modal.people.placeholder2')"
                            :options="optionTags"
                            tag-placeholder="Agregar como nuevo tag"
                            label="email"
                            track-by="email"
                            :preselect-first="false"
                            :multiple="true"
                            :taggable="true"
                            @search-change="tagAdded"
                        >
                        </Multiselect>
                    </BasicSelect>
                </div>
                <div
                    class="col-12"
                    v-if="$v.valueTags.$error && valueTags.length > 0"
                >
                    <b-alert
                        class="alert-note"
                        show
                        dismissible
                        variant="danger"
                    >
                        <p class="text-container">
                            <i aria-hidden="true" class="icon banana banana-check-normal"></i>
                            <span v-html="$t('general.validations.email')"></span>
                        </p>
                    </b-alert>
                </div>
                <div class="col-12">
                    <SocialButtons>
                        <div class="row">
                            <div class="col-12">
                                <p>{{ $t('organizaciones.social-networks.title') }}</p>
                            </div>
                            <div class="col-6">
                                <a
                                    class="social-btn fb"
                                    tabindex
                                    @click="shareFB"
                                >
                                    <img
                                        class="img-fluid"
                                        src="@/assets/images/Icons/facebook-icon.svg"
                                        alt="facebook"
                                    />
                                    {{ $t('general.social-networks.share') }}
                                    <strong>{{ $t('general.social-networks.facebook') }}</strong>
                                </a>
                            </div>
                            <div class="col-6">
                                <a
                                    class="social-btn tw"
                                    tabindex
                                    target="_blank"
                                    @click="shareTW"
                                >
                                    <img
                                        class="img-fluid"
                                        src="@/assets/images/Icons/twitter-icon.svg"
                                        alt="twitter"
                                    />
                                    {{ $t('general.social-networks.share') }}
                                    <strong>{{ $t('general.social-networks.twitter') }}</strong>
                                </a>
                            </div>
                        </div>
                    </SocialButtons>
                </div>
            </div>
        </ModalDefault>

        <!-- Modal Danger -->
        <ModalAlert
            modalId="modal-danger"
            buttonType="button-error"
            :imgUrl="imageModalDelete"
            :title="$t('debtors.delete')"
            :text="$t('debtors.delete-warning')"
            :buttonText="$t('general.actions.delete.title2')"
            @submit="deleteDebtor(selectedDeleteDebtor)"
        ></ModalAlert>
    </div>
</template>
<script>
import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
import EmptyState from '@/components/EmptyState.vue';
import CardSimple from '@/components/CardSimple.vue';
import EmptyStateGroupImg from '@/assets/images/Emptystates/emptystate-debtors.svg';
import Badge from "@/components/Badge";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import BasicInput from "@/components/BasicInput.vue";
import SearchBar from '@/components/SearchBar.vue';
import ModalDefault from '@/components/ModalDefault.vue';
import ModalAlert from "@/components/ModalAlert.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import Multiselect from "vue-multiselect";
import imageModalDelete from "@/assets/images/Illustrations/illustration-modal-delete.svg";
import DebtorsApi from '@/api/debt/debt.api.js';
import PaymentsCRUDApi from '@/api/payments/crud.api.js';
import config from '@/config';
import { utils } from '@/api/utils';
import CommonUtils from '@/common/utils';
import ToastSnotify from '@/mixins/toastSnotify.mixin';
import LoadingBar from '@/components/LoadingBar.vue';
import SocialButtons from '@/components/SocialButtons.vue';
import { shareFacebook, shareTwitter } from '@/common/SocialNetworks';
import { email, required } from 'vuelidate/lib/validators';

    const ERROR_TITLE_KEY = 'general.actions.error.title';
    const ERROR_TEXT_KEY = 'general.actions.error.text';
    const MODAL_DANGER_ID = 'modal-danger';

    export default {
    name: "DebtorsList",
    mixins: [ToastSnotify],
    components: {
        FloatingTitleLayout,
        EmptyState,
        CardSimple,
        Badge,
        BasicCheckbox,
        BasicInput,
        SearchBar,
        ModalDefault,
        ModalAlert,
        BasicSelect,
        Multiselect,
        LoadingBar,
        SocialButtons
    },
    data () {
        return {
            // Tables
            loading: false,
            imageModalDelete: imageModalDelete,
            filtersHeader: false,
            // Select
            value: [],
            selectMode: 'multi',
            paidOptionSelected: null,
            paidOptions: [
                { name: this.$t('debtors.paid'), paid: true },
                { name: this.$t('debtors.pending'), paid: false }
            ],
            statusOptionSelected: null,
            statusOptions: [
                { name: this.$t('debtors.coming-soon'), match: { comingSoon: true, activeService: false } },
                { name: this.$t('debtors.active-service'), match: { comingSoon: false, activeService: true } },
                { name: this.$t('debtors.inactive'), match: { comingSoon: false, activeService: false } },
            ],
            selectedDeleteDebtor: '',
            debtors: [],
            currentPage: 1,
            rows: 0,
            forPages: 0,
            perPage: 10,
            EmptyStateGroupImg: EmptyStateGroupImg,
            service: {},
            copiedToClipboard: false,
            selectedRows: [],
            columnValues: [],
            selectAll: false,
            selectAllHeader: false,
            badProgramingFlagForSelectAll: false,
            badProgramingFlagForSelectAllHeader: false,
            searchQuery: '',
            match: {},
            sort: null,
            foundValues: false,
            selectedDebtor: null,
            // Modal
            valueTags: [],
            optionTags: [],
            searchTag: ''
        }
    },
    mounted () {
        this.loading = true;
        PaymentsCRUDApi.get('D', this.$route.params.id).then((data) => {
            this.service = data.data.object;
            this.columnValues = this.tableColumns.map(c => {
                return {
                    name: c.name,
                    label: c.name,
                    value: true,
                }
            });
            this.$forceUpdate();
            return this.refreshDebtors();
        });
    },
    methods: {
        resetFilters () {
            this.searchQuery = '';
            this.match = {};
            this.$refs.searchBar.resetInput();
        },
        refreshDebtors () {
            this.loading = true;
            return DebtorsApi.getPaymentsForDebt({
                _id: this.service._id,
                page: this.currentPage,
                perPage: this.perPage,
                search: this.searchQuery,
                match: this.match,
                sort: this.sort,
                paid: (this.paidOptionSelected || {}).paid
            }).then((debtsList) => {
                this.debtors = debtsList.data.object.debts;
                this.rows = debtsList.data.object.total;
                this.forPages = (debtsList.data.object.pages * this.perPage);
                this.loading = false;
                if (this.rows > 0) {
                    this.foundValues = true;
                }
                this.selectAll = false;
            }
            ).catch((error) => {
                this.loading = false;
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        showDropdown () {
            document
                .getElementById("tableOne")
                .classList.add("dropdown-table-overflow");
        },
        hideDropdown () {
            document
                .getElementById("tableOne")
                .classList.remove("dropdown-table-overflow");
        },
        headerFilters () {
            this.filtersHeader = !this.filtersHeader;
        },
        showDeleteDebtorModal (debtorId) {
            this.selectedDeleteDebtor = debtorId;
            this.$bvModal.show(MODAL_DANGER_ID);
        },
        deleteDebtor (debtorId) {
            this.$bvModal.hide(MODAL_DANGER_ID);
            this.loading = true;
            DebtorsApi.deleteDebtPayment(this.service._id, debtorId).then(() => {
                this.displayNotificationSuccess(this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                this.loading = false;
                return this.refreshDebtors();
                // eslint-disable-next-line sonarjs/no-identical-functions
            }).catch((error) => {
                this.loading = false;
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        addDebtor () {
            this.$router.push(`/client-admin/debtors/${this.service._id}`);
        },
        showShareModal (debtor) {
            this.selectedDebtor = debtor;
            this.$bvModal.show('modal-Compartir');
            this.copiedToClipboard = false;
        },
        shareLink () {
            // TODO verificar url
            if(config.users.host === "http://localhost"){
              return `${config.users.host}:${config.users.port}/payment-process/DP/${(this.selectedDebtor || {})._id}`;
            }
            return `${config.users.host}/payment-process/DP/${(this.selectedDebtor || {})._id}`;
        },
        copyClipboard () {
            navigator.clipboard.writeText(this.shareLink()).then(() => {
                this.copiedToClipboard = true;
            }, () => {
                utils.toast(this.$snotify, 'error', this.$t('general.actions.copy-clipboard.error'), this.$t(ERROR_TITLE_KEY));
            });
        },
        bodyTableRowClass (index) {
            if (this.selectedRows[index]) {
                return 'b-table-row-selected table-active';
            }
            return '';
        },
        checkboxRefName (index) {
            return `checkbox_${index}`;
        },
        startDeleteMulti () {
            const ids = [];
            this.selectedRows.forEach((selected, index) => {
                if (selected) {
                    ids.push(this.debtors[index]._id);
                }
            });

            this.selectedDeleteDebtor = ids;
            this.$bvModal.show(MODAL_DANGER_ID);
        },
        search (text) {
            this.searchQuery = text;
            CommonUtils.debounceFixed(this.refreshDebtors);
        },
        filterSelected (val) {
            this.columnValue(val.index).value = val.checkValue;
        },
        updateCheckboxes () {
            setTimeout(() => {
                const val = this.totalSelectedRows > 1;
                this.selectAllHeader = val;
                this.selectAll = val;
                this.badProgramingFlagForSelectAllHeader = true;
                this.badProgramingFlagForSelectAll = true;
                if (this.$refs.selectAll) {
                    if (this.$refs.selectAll.checkStoreValue) {
                        this.$refs.selectAll.checkStoreValue(val);
                    } else if (this.$refs.selectAll.length) {
                        this.$refs.selectAll[0].checkStoreValue(val);
                    }

                }
                if (this.$refs.selectAllHeader) {
                    if (this.$refs.selectAllHeader.checkStoreValue) {
                        this.$refs.selectAllHeader.checkStoreValue(val);
                    } else if (this.$refs.selectAllHeader.length) {
                        this.$refs.selectAllHeader[0].checkStoreValue(val);
                    }

                }
            }, 100);
        },
        changeListLimit (limit) {
            CommonUtils.debounceFixed(() => {
                this.perPage = limit;
                this.refreshDebtors();
            });
        },
        headFilterChange () {
            CommonUtils.debounceFixed(this.refreshDebtors);
        },
        updateSort (field, order) {
            if (!this.sort) {
                this.sort = {};
            }
            this.sort[field] = order;
            this.refreshDebtors();
        },
        shareFB () {
            shareFacebook(this.shareLink());
        },
        shareTW () {
            shareTwitter(this.shareLink());
        },
        tagAdded (query) {
            if (query.length > 0) {
                this.searchTag = query;
            } else if (this.searchTag.length > 0 && !this.valueTags.find(t => t.email === this.searchTag)) {
                const emails = this.searchTag.split(',');
                emails.forEach(e => this.valueTags.push({ email: e.split(' ').join('') }));
                this.searchTag = '';
            }
        },
        clearTags () {
            this.valueTags.length = 0;
        },
        saveEmails () {
            this.loading = true;
            DebtorsApi.share(this.selectedDebtor, this.valueTags).then(() => {
                this.loading = false;
                this.clearTags();
                utils.toast(this.$snotify, 'success', this.$t('general.actions.people-emails.success'), this.$t('general.actions.success.title'));
            }).catch((error) => {
                this.loading = false;
                this.clearTags();
                if (error.response && error.response.data && error.response.data.message) {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        columnValue (index) {
            if (this.columnValues && this.columnValues[index]) {
                return this.columnValues[index];
            }
            return {};
        }
    },
    computed: {
        tableColumns () {
            this.service.fields.forEach((f, i) => {
                f.nameForMatch = `fields.${i}.value`;
            });
            this.service.references.forEach((r, i) => {
                r.nameForMatch = `references.${i}.value`;
            });
            return [...this.service.fields, ...this.service.references];
        },
        convertedDebtors () {
            return this.$_.map(this.debtors, (d) => {
                const temp = this.$_.mapValues(this.$_.keyBy([...d.fields, ...d.references], 'name'));
                temp._id = d._id;
                temp.transaction = d.transaction;
                return temp;
            })
        },
        totalSelectedRows () {
            return this.selectedRows.filter(r => r).length;
        },
        headTableRowClass () {
            if (this.totalSelectedRows > 1) {
                return 'header-options-full';
            }
            return '';
        },
        isValid () {
            if (this.valueTags.length <= 0) {
                return false;
            }
            return !this.$v.valueTags.$error;
        }
    },
    watch: {
        selectAll (val) {
            if (!this.badProgramingFlagForSelectAll) {
                this.selectedRows = [];
                this.selectAllHeader = val;
                let i;
                for (i = 0; i < this.debtors.length; i++) {
                    this.selectedRows[i] = val;
                    this.$refs[this.checkboxRefName(i)][0].checkStoreValue(val);
                }
            }
            this.badProgramingFlagForSelectAll = false;
            if (this.$refs.selectAll) {
                if (this.$refs.selectAll.checkStoreValue) {
                    this.$refs.selectAll.checkStoreValue(val);
                } else if (this.$refs.selectAll.length) {
                    this.$refs.selectAll[0].checkStoreValue(val);
                }
            }
            if (this.$refs.selectAllHeader) {
                if (this.$refs.selectAllHeader.checkStoreValue) {
                    this.$refs.selectAllHeader.checkStoreValue(val);
                } else if (this.$refs.selectAllHeader.length) {
                    this.$refs.selectAllHeader[0].checkStoreValue(val);
                }
            }
        },
        selectAllHeader (val) {
            if (!this.badProgramingFlagForSelectAllHeader) {
                this.selectedRows = [];
                this.selectAll = val;
                let i;
                for (i = 0; i < this.debtors.length; i++) {
                    this.selectedRows[i] = val;
                    this.$refs[this.checkboxRefName(i)][0].checkStoreValue(val);
                }
            }
            this.badProgramingFlagForSelectAllHeader = false;
            if (this.$refs.selectAll) {
                if (this.$refs.selectAll.checkStoreValue) {
                    this.$refs.selectAll.checkStoreValue(val);
                } else if (this.$refs.selectAll.length) {
                    this.$refs.selectAll[0].checkStoreValue(val);
                }

            }
            if (this.$refs.selectAllHeader) {
                if (this.$refs.selectAllHeader.checkStoreValue) {
                    this.$refs.selectAllHeader.checkStoreValue(val);
                } else if (this.$refs.selectAllHeader.length) {
                    this.$refs.selectAllHeader[0].checkStoreValue(val);
                }

            }
        },
        currentPage () {
            this.refreshDebtors();
        },
        paidOptionSelected () {
            this.refreshDebtors();
        },
        statusOptionSelected (value) {
            if (!this.match) {
                this.match = {};
            }
            this.match = { ... this.match, ...(value.match || {}) };
            this.refreshDebtors();
        }
    },
    validations: {
        valueTags: {
            required,
            $each: {
                email
            }
        }
    }
};
</script>
