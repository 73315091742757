export const STORE = 'TERMS_CONDITIONS';
export const SET_TERMS_CONDITIONS = 'SET_TERMS_CONDITIONS';
export const FIND_TERMS_CONDITIONS = 'FIND_TERMS_CONDITIONS';
export const SET_PRIVACY_AGREEMENT = 'SET_PRIVACY_AGREEMENT';
export const FIND_PRIVACY_AGREEMENT = 'FIND_PRIVACY_AGREEMENT';
export const SET_CONTRACT = 'SET_CONTRACT';
export const FIND_CONTRACT = 'FIND_CONTRACT';
export const GET_TERMS_CONDITIONS = 'GET_TERMS_CONDITIONS';
export const GET_PRIVACY_AGREEMENT = 'GET_PRIVACY_AGREEMENT';
export const GET_CONTRACT = 'GET_CONTRACT';
export const LOADING_AGGREMENT = 'LOADING_AGGREMENT';
export const GET_LOADING_AGGREMENT = 'GET_LOADING_AGGREMENT';
export const GET_TERMS_EXISTS = 'GET_TERMS_EXISTS';
export const TERMS_EXISTS = 'TERMS_EXISTS';
