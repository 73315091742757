<template>
    <div class="title-landingpage" :class="clazz">
        <small v-if="showSmallText" class="small-text"> {{textSmall}} </small>
        <h1 v-if="!hideOriginaltitle" class="title-text" :class="titleClass"> {{textTitle}} </h1>
        <p v-if="!hideOriginalSubtitle" class="subtitle-text" :class="subtitleClass"> {{textSubtitle}} </p>

        <h1 v-if="hideOriginaltitle" class="title-text" :class="titleClass"> <slot name="titleContent"></slot> </h1>
        <p v-if="hideOriginalSubtitle" class="subtitle-text" :class="subtitleClass"> <slot name="subtitleContent"></slot> </p>

        <slot name="inputText"></slot>
    </div>
</template>

<script>
    export default {
        name: 'TitleLandingpage',
        props: {
            clazz: String,
            textSmall: String,
            showSmallText: {type: Boolean, default: false},
            textTitle: String,
            titleClass: String,
            textSubtitle: String,
            subtitleClass: String,
            hideOriginaltitle: {type: Boolean, default: false},
            hideOriginalSubtitle: {type: Boolean, default: false}
        },
        components: {}
    }
</script>

<style lang="scss">
    .title-landingpage {
        margin-bottom: 50px;
        transition: 0.5s;

        &.left-text {
            .title-text,
            .subtitle-text,
            .small-text {
                text-align: left;
            }

            .subtitle-text {
                margin: 0 auto 0 0;
            }
        }

        &.right-text {
            .title-text,
            .subtitle-text,
            .small-text {
                text-align: right;
            }

            .subtitle-text {
                margin: 0 0 0 auto;
            }
        }

        .small-text {
            font-size: 15px;
            font-weight: 500;
            line-height: normal;
            text-align: center;
            letter-spacing: -0.5px;
            display: block;
            margin: 0;
            transition: 0.5s;
        }

        .title-text {
            font-size: 40px;
            font-weight: bold;
            line-height: normal;
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom: 20px;
            position: relative;
            transition: 0.5s;
        }

        .subtitle-text {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.67;
            text-align: center;
            width: 100%;
            max-width: 560px;
            display: block;
            margin: 0 auto;
            transition: 0.5s;
        }

        > .form-group {
            box-shadow: 0 2px 8px 0 rgba(20, 34, 102, 0.1);
            max-width: 540px;
            margin: 0 auto !important;
            border-radius: 4px;

            > .input-label,
            > .form-text-info {
                display: none;
            }

            > .form-control {
                border: none;
                border-radius: 4px;
            }
        }

        .title-divider-type {
            padding: 0 40px;
            position: relative;
            z-index: 1;

            & + .divider {
                width: 100%;
                height: 1px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto 0;
            }
        }
    }
</style>
