export const STORE = 'TAX_RECEIPTS';
export const GET_TAX_RECEIPTS = 'GET_TAX_RECEIPTS';
export const GET_ACTUAL_ITEM = 'GET_ACTUAL_ITEM';
export const SET_TAX_RECEIPTS = 'SET_TAX_RECEIPTS';
export const SET_LOADING_TAX_RECEIPTS = 'SET_LOADING_TAX_RECEIPTS';
export const SET_NAME_TAX_RECEIPT = 'SET_NAME_TAX_RECEIPT';
export const SET_CREATION_DATE = 'SET_CREATION_DATE';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_DISCONTINUED = 'SET_DISCONTINUED';
