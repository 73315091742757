<template>
    <div>
        <SignUpOrganizationBody stepTwo>
            <template v-slot:formSectionBody>

                <GenericTitle id="dct-orgStepOne" clazz="p-t-20" :title="$t('signup-organization.step-one.title')"
                              :titleStrong="$t('signup-organization.step-one.title-strong')"
                              :subtitle="$t('signup-organization.step-one.subtitle')"></GenericTitle>

                <StepValidation :text="getErrorNextStepMessage" icon="banana-cross-normal" status="error"
                                v-if="getErrorNextStep"></StepValidation>
                <form>
                    <div class="row" v-if="getFormStep == 1">
                        <div class="col-12">
                            <BasicInput
                                    required
                                    :inputValue="getPotentialOrganization.name"
                                    inputType="text"
                                    labelFor="names"
                                    inputId="names"
                                    :label="$t('signup-organization.step-one.name.label')"
                                    :clazz="getErrorClass('name')"
                                    v-on:basicInput="updateName"
                                    :inputPlaceholder="$t('signup-organization.step-one.name.placeholder')"
                                    :warningText="$v.name.$error ? $t('signup-organization.step-one.name.warning-text') : ''"
                                    idForm="dct-emptyOrg"
                                    help
                                    idHelpTooltip="nameHelp"
                                    helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="nameHelp" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                {{$t('signup-organization.step-one.name.help-tooltip')}}
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>

                        <div class="col-12">
                            <BasicSelect
                                    :required="true"
                                    :label="$t('signup-organization.step-one.category.label')"
                                    labelFor="categoria"
                                    :clazz="getErrorClass('category')"
                                    help
                                    id="dct-catOrg"
                                    idHelpTooltip="categoryHelp"
                                    helpIcon="banana banana-question-circle1"
                                    :warningText="$v.category.$error ? $t('signup-organization.step-one.category.warning-text') : ''"
                            >
                                <multiselect id="txt-catOrgType"
                                             @input="updateCategory"
                                             v-model="category"
                                             :options="categories"
                                             @select="updateCategory"
                                             :close-on-select="true"
                                             :preserve-search="true"
                                             :placeholder="$t('signup-organization.step-one.category.placeholder')"
                                             label="name" track-by="name" :preselect-first="false">
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }}
                                            {{$t('signup-organization.step-one.category.options-selected')}}</span>
                                    </template>
                                </multiselect>
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="categoryHelp" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                {{$t('signup-organization.step-one.category.help-tooltip')}}
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicSelect>
                        </div>
                        <div class="col-12">
                            <BasicTextarea
                                    required
                                    idForm="dct-emptyOrgDesc"
                                    textareaId="txa-orgWhatDo"
                                    :inputValue="getPotentialOrganization.description"
                                    inputType="text"
                                    labelFor="description"
                                    inputId="description"
                                    :label="$t('signup-organization.step-one.description.label')"
                                    v-on:basicInput="updateDescription"
                                    :clazz="getErrorClass('description')"
                                    :warningText="$v.description.$error ? $t('signup-organization.step-one.description.warning-text') : ''"
                                    :textareaPlaceholder="$t('signup-organization.step-one.description.placeholder')"
                                    help
                                    idHelpTooltip="descriptionHelp"
                                    smallId="warn-emptyOrgDesc"
                                    helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="descriptionHelp" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                {{$t('signup-organization.step-one.description.help-tooltip')}}
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicTextarea>
                        </div>
                        <div class="col-12">
                            <BaseFormAnnotation :text="$t('general.required-fields')"></BaseFormAnnotation>
                        </div>
                    </div>
                </form>

            </template>
        </SignUpOrganizationBody>
    </div>
</template>

<script>
    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue';
    import GenericTitle from '@/components/GenericTitle.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import BasicSelect from '@/components/BasicSelect.vue';
    import BasicTextarea from '@/components/BasicTextarea.vue';
    import BaseFormAnnotation from '@/components/signUpOrganizations/base/form/BaseFormAnnotation.vue';
    import StepValidation from '@/components/StepValidation.vue';
    import {mapGetters} from 'vuex';
    import categories from '@/enums/categories';
    import Multiselect from 'vue-multiselect';
    import {required, minLength, maxLength} from 'vuelidate/lib/validators';

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    const storeModule = "signUpOrganization";

    export default {
        name: "StepStart",
        props: {
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false}
        },
        data() {
            return {
                name: '',
                category: '',
                description: '',
                contactPhone: '',
                categories: categories.getAllCategories()
            }
        },
        components: {
            SignUpOrganizationBody,
            GenericTitle,
            BasicInput,
            BasicSelect,
            BasicTextarea,
            Multiselect,
            BaseFormAnnotation,
            StepValidation
        },
        methods: {
            getErrorClass(propertie) {
                return this.$v[propertie].$error ? "has-error" : "";
            },
            updateName: debounce(function (name) {
                this.name = name;
                this.name = this.name.trim();
                this.$v.name.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_NAME_POTENTIAL_ORGANIZATION`, this.name);
            }, 800, false),
            updateCategory: debounce(function (event) {
                this.category = event;
                this.$v.category.$touch();
                this.validateStep();
                if(event){
                    this.$store.commit(`${storeModule}/UPDATE_CATEGORY_POTENTIAL_ORGANIZATION`, event.value);
                }
            }, 800, false),
            updateDescription: debounce(function (description) {
                this.description = description;
                this.description = this.description.trim()
                this.$v.description.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_DESCRIPTION_POTENTIAL_ORGANIZATION`, this.description);
            }, 800, false),
            async initializeValues() {
                if (!this.getPotentialOrganization.name) {
                    const userId = this.$session.get('userId');
                    await this.$store.dispatch('signUpOrganization/loadPotentialOrganization', {userId});
                }
                if (this.getPotentialOrganization.category) {
                    const selectedCategory = categories.getCategorie(this.getPotentialOrganization.category);
                    this.category = selectedCategory;
                }
                this.name = this.getPotentialOrganization.name;
                this.description = this.getPotentialOrganization.description;

                this.validateStep();
            },
            validateStep() {
                let isValid = true;

                isValid = !this.$v.name.$error &&
                    !this.$v.category.$error &&
                    !this.$v.description.$error;

                // Se verifican los campos en lugar de hacerles touch a los campos para que no muestre en un inicio todos los errores de validacion pero que valide el Step.
                isValid = isValid && this.name &&
                    this.category &&
                    this.description;
                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_ONE`, isValid);
            }
        },
        validations: {
            name: {
                required,
                minLength: minLength(3)
            },
            category: {
                required
            },
            description: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(200)
            }
        },
        computed: {
            ...mapGetters(storeModule, ['getPotentialOrganization', 'getErrorNextStep', 'getErrorNextStepMessage', 'getFormStep', 'getIsValidStepFormSave'])
        },
        created() {
            this.initializeValues();
        }
    };
</script>
