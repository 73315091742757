<template>
    <div>
        <!--  FAVOR DE NO MODIFICAR NINGUN COMPONENTE DE ESTA VISTA, SOLO ES DE EJEMPLO -->
        <div class="register-container">
            <div class="left-side-content">
                <div class="container-left">
                    <img class="img-fluid logo" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" width="166" height="60" alt="Logo Pagando Check"/>
                    <span class="d-block w-100">
                        <p class="info-text">
                            Digitaliza tu negocio aceptando <strong>pagos con terminal</strong>,
                            integrando nuestra <strong>API</strong> a tu sitio web o usando nuestros
                            <strong>plugins de pago</strong> disponibles en las soluciones
                            de e-commerce más populares.
                        </p>
                        <div class="border-container">
                            <p class="info-text m-b-15 text-left">
                                Para realizar la contratación de este servicio, solo
                                es necesario que completes la siguiente información:
                            </p>
                            <ul class="info-text-list">
                                <li>Datos de contacto de tu negocio</li>
                                <li>Subir documentos</li>
                                <li>Ubicación de tu negocio desde un mapa</li>
                            </ul>
                        </div>
                        <p class="info-text m-b-0">
                            Nuestro equipo de soporte se comunicará contigo al correo
                            <strong class="c-info">andreaguerra@gmail.com</strong> para notificarte
                            sobre la aprobación de tu solicitud.
                        </p>
                    </span>
                    <div class="footer-help">
                        <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
                            <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" width="365" height="153" alt="Chat de soporte" />
                        </a>
                        <p class="help-num">
                            También puedes contactarnos a los teléfonos:
                            <br/>
                            <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="right-side-content">
                <div class="container-right">
                    <!-- DATOS DE TU NEGOCIO -->
                    <img class="img-fluid max-h-300px d-flex m-0-auto"
                        src="@/assets/images/Illustrations/illustration-psw.svg"
                        width="520"
                        height="240"
                        alt="Crea una contraseña"/>
                    <TitlePrincipal
                        title="Crea una contraseña" clazz="text-center m-t-20">
                        <p class="subtitle">
                            Para iniciar sesión más adelante en tu cuenta
                            <strong>Pagando Check,</strong> debes crear
                            y confirmar una contraseña nueva.
                        </p>
                    </TitlePrincipal>
                    <PasswordInput
                        required
                        inputType="password"
                        labelFor=""
                        inputId=""
                        label="Contraseña"
                        inputPlaceholder="Ingresa una contraseña siguiendo las recomendaciones de abajo"
                        level
                        clazzPassword
                        status="Muy débil">
                    </PasswordInput>
                    <PasswordInput
                        required
                        inputType="password"
                        labelFor=""
                        inputId=""
                        label="Confirmar contraseña"
                        inputPlaceholder="Confirma tu contraseña"
                        level
                        clazzPassword
                        status="Muy débil">
                    </PasswordInput>
                    <b-alert class="alert-note" show variant="info">
                        <p class="text-container c-text_title f-10 d-block">
                            Si deseas una contraseña segura sigue estas recomendaciones:
                            <ul class="m-t-5 m-0 p-0 list-style-none">
                                <li class="f-10 c-info poppins-font-semibold line-height-1 m-b-5 d-flex">
                                    <i aria-hidden="true" class="banana banana-check-normal f-12 m-r-10"></i>
                                    Usar 6 o más caracteres
                                </li>
                                <li class="f-10 c-error poppins-font-semibold line-height-1 m-b-5 d-flex">
                                    <i aria-hidden="true" class="banana banana-cross-normal f-12 m-r-10"></i>
                                    Usar al menos un número
                                </li>
                                <li class="f-10 c-error poppins-font-semibold line-height-1 m-b-5 d-flex">
                                    <i aria-hidden="true" class="banana banana-cross-normal f-12 m-r-10"></i>
                                    Usar al menos una letra mayúscula
                                </li>
                            </ul>
                        </p>
                    </b-alert>
                    <b-alert class="alert-note" show variant="info">
                        <p class="text-container">
                            <i
                                aria-hidden="true"
                                class="icon banana banana-comment"
                                ></i>
                            <span>
                                <strong>RECUERDA</strong> que para administrar tu nueva
                                    cuenta en Pagando Check deberás ingresar tu correo
                                    y esta contraseña en el sitio <strong>app.pagandocheck.com/signin</strong>
                            </span>
                        </p>
                    </b-alert>

                    <div class="footer-buttons right">
                        <button type="button" class="button button-ghost button-plain_text" name="Regresar"> Regresar </button>
                        <button type="button" class="button button-filled button-primary" name="Continuar" disabled> Continuar </button>
                    </div>
                </div>
            </div>
            <div class="footer-help responsive">
                <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
                    <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" width="365" height="153" alt="Chat de soporte" />
                </a>
                <p class="help-num">
                    También puedes contactarnos a los teléfonos:
                    <br/>
                    <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import TitlePrincipal from "@/components/TitlePrincipal.vue";
    import PasswordInput from "@/components/PasswordInput.vue";

    export default {
        name: "PagandoCheckPswUx",
        components: {
            TitlePrincipal,
            PasswordInput,
        },
        data() {
            return {
            }
        },
        methods: {
        }
    };
</script>
