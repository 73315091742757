<template>
    <div class="form-group" :class="clazz">
        <div id="form-inputPhone" v-on:click="activeLabel($event)">
            <vue-tel-input v-model="inputValue" :inputId="idInput" enabledCountryCode inputClasses="form-control"
                           :placeholder="inputPlaceholder" @input="updateValue($event)"
                           @country-changed="countryChanged"/>
        </div>

        <div v-if="badge" class="badge" :class="badgeType">{{badgeText}}</div>

        <div class="input-label" :class="{ 'active': labelActive }">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i aria-hidden="true" v-bind:class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>
</template>

<script>
    import inputValueMixin from '@/mixins/inputValue.mixin';

    export default {
        name: 'TelInput',
        mixins: [
            inputValueMixin
        ],
        props: {
            clazz: String,
            labelFor: String,
            label: String,
            required: {type: Boolean, default: false},
            help: {type: Boolean, default: false},
            helpText: String,
            helpIcon: String,
            idHelpTooltip: String,
            idInput: String,
            inputPlaceholder: String,
            badge: {type: Boolean, default: false},
            badgeType: String,
            badgeText: String,
            warningIcon: String,
            warningText: String
        },
        data() {
            return {
                labelActive: false
            }
        },
        methods: {
            countryChanged(value) {
                this.$emit('country-changed', value);
            },
            updateValue(value) {
                this.$emit('input', value);
            },
            activeLabel: function () {
                this.labelActive = !this.labelActive;
            },
            changeActive(event) {
                const header = document.querySelector("#form-inputPhone");
                if (header) {
                    const isClickInside = header.contains(event.target);

                    if (!isClickInside) {
                        this.labelActive = false;
                    } else {
                        this.labelActive = true;
                    }
                }

            }
        },
        created() {
            window.addEventListener('click', this.changeActive);
        }
    }
</script>
