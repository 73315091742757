export const STORE = "ORGANIZATION";
export const GET_BANK_INFO = "GET_BANK_INFO";
export const SET_BANK_INFO = "SET_BANK_INFO";
export const UPDATE_BANK_DATA = "UPDATE_BANK_DATA";
export const FETCH_BANK_DATA = "FETCH_BANK_DATA";
export const GET_GENERAL_INFO = "GET_GENERAL_INFO";
export const SET_GENERAL_INFO = "SET_GENERAL_INFO";
export const FETCH_GENERAL_INFO = "FETCH_GENERAL_INFO";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION";
export const UPLOAD_GENERAL_INFO = "UPLOAD_GENERAL_INFO";
export const FETCH_BILLING_DATA = "FETCH_BILLING_DATA";
export const GET_BILLING_DATA = "GET_BILLING_DATA";
export const SET_BILLING_DATA = "SET_BILLING_INFO";
export const FETCH_FILE = "FETCH_FILE";
export const GET_FILE = "GET_FILE";
export const UPLOAD_IMAGE_INFO = "UPLOAD_IMAGE_INFO";
export const SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const GET_IMAGE_DATA = "GET_IMAGE_DATA";
export const FETCH_IMAGE_DATA = "FETCH_IMAGE_DATA";
export const GET_CAN_UPDATE_BANK_INFO = "GET_CAN_UPDATE_BANK_INFO";
export const FETCH_CAN_UPDATE_BANK_INFO = "FETCH_CAN_UPDATE_BANK_INFO";
export const SET_CAN_UPDATE_BANK_INFO = "SET_CAN_UPDATE_BANK_INFO";
export const UPLOAD_COVER_INFO = "UPLOAD_COVER_INFO";

export const GET_CONTACT_INFO = "GET_CONTACT_INFO";
export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
export const FETCH_CONTACT_INFO = "FETCH_CONTACT_INFO";

export const GET_NOTIFICATION_INFO = "GET_NOTIFICATION_INFO";
export const SET_NOTIFICATION_INFO = "SET_NOTIFICATION_INFO";
export const UPDATE_NOTIFICATION_INFO = "UPDATE_NOTIFICATION_INFO";
export const FETCH_NOTIFICATION_INFO = "FETCH_NOTIFICATION_INFO";

export const GET_BILLING_INFO = "GET_BILLING_INFO";
export const SET_BILLING_INFO = "SET_BILLING_INFO";
export const UPDATE_BILLING_INFO = "UPDATE_BILLING_INFO";
export const FETCH_BILLING_INFO = "FETCH_BILLING_INFO";

export const GET_PAYMENTS_CONFIGURATION = "GET_PAYMENTS_CONFIGURATION";
export const SET_PAYMENTS_CONFIGURATION = "SET_PAYMENTS_CONFIGURATION";
export const UPDATE_PAYMENTS_CONFIGURATION = "UPDATE_PAYMENTS_CONFIGURATION";
export const FETCH_PAYMENTS_CONFIGURATION = "FETCH_PAYMENTS_CONFIGURATION";
