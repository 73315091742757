<template>
    <div
        class="org-info-top-cover-logo"
        :class="clazz"
    >
        <input
            hidden
            id="file"
            type="file"
            @change="fileSelected"
        />
        <LoadingBar
            v-if="loading"
            :loadingText="$t('general.info.loading')"
        ></LoadingBar>
        <div class="top-portada">
            <img
                class="img-fluid portada"
                :src="coverCroppedBase64 || coverUrlInDatabase || DefaultCover"
                alt="cover"
            />
            <button
                type="button"
                class="button xs button-ghost button-white p-l-10 p-r-10"
                @click="openModalCover"
            ><i aria-hidden="true" class="banana banana-camera"></i> {{$t('organizaciones.images.edit-cover')}}</button>
        </div>

        <div class="bottom-info">
            <span class="logo-img">
                <img
                    class="img-fluid logo"
                    :src="logoCroppedBase64 || logoUrlInDatabase || DefaultCover"
                    alt="logo"
                />
                <button
                    type="button"
                    class="button-circle sm button-filled button-accent2"
                    @click="selectFile(true)"
                ><i aria-hidden="true" class="banana banana-camera f-22"></i></button>
            </span>

            <span class="info">
                <label>{{currentOrganization.name}}</label>
                <p>{{currentOrganization.description}}</p>
            </span>
            <a
                type="button"
                class="button sm button-stroke button-accent1 ml-auto"
                :href="seePreview()"
            ><i aria-hidden="true" class="banana banana-visibility-on"></i> {{$t('organizaciones.images.see-preview')}}</a>
        </div>

        <ModalDefault
            :modalId="modalCropImageId"
            :customRef="modalCropImageId"
            icon="banana-move"
            :originalFooter="false"
            size="lg"
            :title="$t('organizaciones.images.logo.modal.title')"
            :subtitle="$t('organizaciones.images.logo.modal.subtitle')"
        >
            <div>
                <CropImage
                    :clazz="croppieClass"
                    :ref="imageCroppieRef"
                    @crop="croppieResult"
                ></CropImage>
            </div>
            <template v-slot:customFooter>
                <BaseButton
                    type="button"
                    id="modal_crop_image_change"
                    _class="m-r-20"
                    :text="$t('organizaciones.images.logo.modal.input-place-holder')"
                    :kind="BUTTONS.KINDS.STROKE"
                    :color="BUTTONS.COLORS.ACCENT1"
                    :shape="BUTTONS.SHAPES.BUTTON"
                    :size="BUTTONS.SIZES.EXTRA_SMALL"
                    :icon="ICONS._EMPTY"
                    divClass="m-0-auto-0-0"
                    @click="changeLogoSelected"
                ></BaseButton>
                <BaseButton
                    type="button"
                    id="modal_crop_image_cancel"
                    _class="m-r-20"
                    :text="$t('general.actions.cancel.title')"
                    :shape="BUTTONS.SHAPES.BUTTON"
                    :kind="BUTTONS.KINDS.GHOST"
                    :color="BUTTONS.COLORS.PLAIN_TEXT"
                    :size="BUTTONS.SIZES.EXTRA_SMALL"
                    @click="cancelCrop()"
                ></BaseButton>
                <BaseButton
                    type="button"
                    id="modal_crop_image_save"
                    :text="$t('general.actions.save')"
                    :shape="BUTTONS.SHAPES.BUTTON"
                    :kind="BUTTONS.KINDS.FILLED"
                    :color="BUTTONS.COLORS.ACCENT1"
                    :size="BUTTONS.SIZES.EXTRA_SMALL"
                    @click="cropImage"
                    v-if="!savingCropped"
                ></BaseButton>

                <button
                    v-if="savingCropped"
                    type="button"
                    class="button xs button-filled button-accent1"
                >
                    <LoadingSimple clazz="small-load no-text" />
                </button>
            </template>
        </ModalDefault>

        <ModalDefault
            modalId="modal-selectPortada"
            ref="modal-selectPortada"
            :customRef="'coverpage'"
            icon="banana-image"
            :originalFooter="false"
            size="xl"
            :title="$t('organizaciones.images.cover.modal.title')"
            :subtitle="$t('organizaciones.images.cover.modal.subtitle')"
        >
            <PortadaSelectPreview
                :store-module="storeModule"
                :options="getCoverOptions ? getCoverOptions.options : []"
                src-prop-name="fullUrl"
                id-prop-name="_id"
                :select-text="$t('general.actions.select.title')"
                :selected-text="$t('organizaciones.images.cover.modal.select.cover')"
                @select="onCoverSelect($event)"
                :search-placeholder="$t('organizaciones.images.cover.modal.select.search-image')"
                @search="onSearch($event)"
                :temporalCoverUser="getTemporalCover.authorNameThirdPartyCover"
                :temporalCoverSrc="coverCroppedBase64"
                :loading="loadingCoverPhoto"
                :percentage="loadingCoverPhotoPercentage"
                :namePhoto="loadingCoverPhotoName"
                :sizePhoto="loadingCoverPhotoSize"
            >
            </PortadaSelectPreview>
            <template v-slot:customFooter>
                <BaseButton
                    id="modal_upload_cover"
                    :text="$t('organizaciones.images.cover.modal.upload.image')"
                    _ref="uploadCoverRef"
                    :_class="'button xs button-stroke button-accent1 m-0-auto-0-0'"
                    :accept="FILES.MIME_TYPES.ACCEPT_IMAGE"
                    @change="coverUploaded($event)"
                    :shape="BUTTONS.SHAPES.BUTTON"
                    :kind="BUTTONS.KINDS.STROKE"
                    :color="BUTTONS.COLORS.ACCENT1"
                    :size="BUTTONS.SIZES.EXTRA_SMALL"
                    :isCoverUpload="true"
                    @click="selectFile(false)"
                    divClass="m-0-auto-0-0"
                >
                </BaseButton>
                <button
                    id="btn-cancelCover"
                    class="button xs button-ghost button-plain_text m-r-20"
                    type="button"
                    @click="closeModal('coverpage')"
                > {{ $t('general.actions.cancel.title') }} </button>
                <button
                    id="btn-selectCover"
                    class="button xs button-filled button-accent1 m-r-20"
                    type="button"
                    @click="upload()"
                > {{ $t('general.actions.select.title') }} </button>
            </template>
        </ModalDefault>

    </div>
</template>

<script>
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION, FETCH_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import { STORE as OrganizationsStore, UPLOAD_IMAGE_INFO, GET_IMAGE_DATA, FETCH_IMAGE_DATA } from '@/store/modules/organization/organization.types';
import { mapGetters } from 'vuex';
import { utils } from '@/api/utils.js';
import LoadingBar from '@/components/LoadingBar.vue';
import CropImage from '@/components/CropImage';
import ModalDefault from '@/components/ModalDefault';
import { EventBus } from "@/main";
import FILES from '@/common/consts/files.consts';
import ICONS from '@/common/consts/icons.consts';
import BUTTONS from '@/common/consts/buttons.consts';
import LoadingSimple from '@/components/LoadingSimple';
import BaseButton from '@/components/signUpOrganizations/base/BaseButton';
import PortadaSelectPreview from '@/components/PortadaSelectPreview.vue';
import config from "@/config";
import DefaultCover from "@/assets/images/Default/cover.svg";
const storeModule = 'signUpOrganization';
const errorTitleKey = 'general.actions.error.title';
const errorTextKey = 'general.actions.error.text';

export default {
    name: 'OrganizationInfoCoverLogo',
    props: {
        clazz: String
    },
    components: {
        LoadingBar,
        CropImage,
        ModalDefault,
        BaseButton,
        LoadingSimple,
        PortadaSelectPreview,
    },
    data () {
        return {
            DefaultCover,
            FILES,
            ICONS,
            BUTTONS,
            editingLogo: false,
            savingCropped: false,

            logoFullSizeFile: null,
            logoCroppedFile: null,
            logoFullSizeBase64: null,
            logoCroppedBase64: null,

            coverFullSizeFile: null,
            coverCroppedFile: null,
            coverFullSizeBase64: null,
            coverCroppedBase64: null,

            loading: false,
            imageCroppieRef: "image-croppie",
            modalCropImageButtonId: 'modal_crop_image_button',
            modalCropImageId: 'modal_crop_image',
            storeModule,
            temporalCoverSelected: null,
            loadingCoverPhoto: false,
            loadingCoverPhotoPercentage: 0,
            loadingCoverPhotoName: "",
            loadingCoverPhotoSize: "0kb",
            uploadFailed: false,
            loadingCropingCover: false
        }
    },
    mounted () {
        this.changeCurrentOrg();
    },
    methods: {
        onSearch(search) {
            this.loadImageOptions(null, search);
        },
        loadImageOptions(organization, search) {
            const _this = this;
            this.$store.dispatch(`${this.storeModule}/getCoverImageOptions`, {organization: organization , search: search}).then(() => {
                _this.loadingCoverOptions = false;
            });
        },
        openModalCover() {
            this.editingLogo = false;
            EventBus.$emit(`MODAL-OPEN-coverpage`)
        },
        closeModalCover() {
            this.$refs['modal-selectPortada'].hideModal();
            this.editingLogo = false;
            EventBus.$emit(`MODAL-CLOSE-coverpage`)
        },
        cancelCrop () {
            this.closeModal();
            document.getElementById('file').value = null;
        },
        changeLogoSelected () {
            this.closeModal();
            this.selectFile();
        },
        closeModal () {
            this.$refs['modal-selectPortada'].hideModal();
            EventBus.$emit(`MODAL-CLOSE-${this.modalCropImageId}`)
        },
        cropImage () {
            this.savingCropped = true;
            this.$refs[this.imageCroppieRef].crop();
            this.closeModal();
        },
        urltoFile (url, filename, mimeType) {
            return (fetch(url, { cache: 'no-cache' })
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            );
        },
        cancel () {

        },
        selectFile (editingLogo) {
            this.editingLogo = editingLogo;
            document.getElementById('file').value = null;
            document.getElementById('file').click();
        },
        fileSelected (event) {
            const file = event.target ? event.target.files[0] : event;
            if(this.editingLogo) {
                this.logoFullSizeFile = file;
            } else {
                this.coverFullSizeFile = file;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                EventBus.$emit(`MODAL-OPEN-${this.modalCropImageId}`);
                if(this.editingLogo) {
                    this.logoFullSizeBase64 = reader.result;
                } else {
                    this.coverFullSizeBase64 = reader.result;
                }
                setTimeout(() => {
                    this.$refs[this.imageCroppieRef].initCroppie(reader.result);
                }, 100);
            }
            reader.readAsDataURL(file);
        },
        onCoverSelect(options){
            this.loading = true;
            this.closeModalCover();
            this.urltoFile(options.option.fullUrl, options.option.name, options.option.type).then((fullSizeFile) => {
                this.coverFullSizeFile = fullSizeFile;
                this.fileSelected(fullSizeFile);
            }).catch(() => {
                utils.toast(this.$snotify, 'error', this.$t(errorTitleKey), this.$t(errorTextKey));
                this.loading = false;
            });
        },
        croppieResult (result) {
            let fileUsed;
            if(this.editingLogo) {
                this.logoCroppedBase64 = result;
                fileUsed = this.logoFullSizeFile;
            } else {
                this.coverCroppedBase64 = result;
                fileUsed = this.coverFullSizeFile;
                this.openModalCover();
            }
            this.loading = true;
            this.urltoFile(result, `cropped-${ fileUsed.name}`, fileUsed.type).then((croppedFile) => {
                if(this.editingLogo) {
                    this.logoCroppedFile = croppedFile;
                    this.upload();
                } else {
                    this.loading = false;
                    this.coverCroppedFile = croppedFile;
                }
            }).catch(() => {
                utils.toast(this.$snotify, 'error', this.$t(errorTitleKey), this.$t(errorTextKey));
                this.loading = false;
            });
        },
        upload () {
            this.closeModalCover();
            this.closeModal();
            this.loading = true;
            const payLoad = {
                organization: this.currentOrganization,
                logoFullSize: this.logoFullSizeFile,
                logoCropped: this.logoCroppedFile,
                coverFullSize: this.coverFullSizeFile,
                coverCropped: this.coverCroppedFile,
            };

            this.$store.dispatch(`${OrganizationsStore}/${UPLOAD_IMAGE_INFO}`, payLoad)
                .then(() => {
                    this.loading = false;
                    this.savingCropped = false;
                    utils.toast(this.$snotify, 'success', this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                    this.$store.dispatch(`${OrganizationsStore}/${FETCH_IMAGE_DATA}`, this.currentOrganization).then(() => {
                        this.logoFullSizeBase64 = null;
                        this.logoCroppedBase64 = null;
                        this.coverFullSizeBase64 = null;
                        this.coverCroppedBase64 = null;
                    });
                    this.$store.dispatch(`${ACCESS_STORE}/${FETCH_CURRENT_ORGANIZATION}`);
                }).catch((error) => {
                    this.loading = false;
                    this.savingCropped = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        utils.toast(this.$snotify, 'error', this.$t(errorTitleKey), error.response.data.message);
                    } else {
                        utils.toast(this.$snotify, 'error', this.$t(errorTitleKey), this.$t(errorTextKey));
                    }
                });
        },
        seePreview() {
            if (config.users.host === "http://localhost") {
                return `${config.users.host}:${config.users.port}/landing/microsite/${this.currentOrganization._id}`
            }
            return `${config.users.host}/landing/microsite/${this.currentOrganization._id}`;
        },
        changeCurrentOrg(){
            this.loading = true;
            this.$store.dispatch(`${OrganizationsStore}/${FETCH_IMAGE_DATA}`, this.currentOrganization).then(() => {
                this.loading = false;
                this.cancel(false);
            });
        }
    },
    computed: {
        croppieClass() {
            return this.editingLogo ? 'container-lg logo m-b-0' : 'container-lg portada m-b-0';
        },
        logoUrlInDatabase: function () {
            if (this.organizationImages && this.organizationImages.logo && this.organizationImages.logo.cropped) {
                return utils.imageDownloadLink(this.organizationImages.logo.cropped);
            }
            return null;
        },
        coverUrlInDatabase: function () {
            if (this.organizationImages && this.organizationImages.cover && this.organizationImages.cover.cropped) {
                return utils.imageDownloadLink(this.organizationImages.cover.cropped);
            }
            return null;
        },
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
        ...mapGetters(OrganizationsStore, {
            organizationImages: GET_IMAGE_DATA
        }),
        ...mapGetters(storeModule, [
            'getPotentialOrganization',
            'getCoverOptions',
            'getTemporalCover',
            'coverImg'
        ]),
    },
    watch: {
        currentOrganization() {
            this.changeCurrentOrg();
        }
    }
}
</script>

<style lang="scss">
.org-info-top-cover-logo {
    border-radius: 3px;
    width: 100%;
    max-height: 440px;
    display: block;
    margin-bottom: 40px;
    position: relative;

    .top-portada {
        max-height: 300px;
        width: 100%;
        overflow: hidden;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .portada {
            width: 100%;
            height: 100%;
            max-height: 300px;
            object-fit: cover;
            object-position: center;
        }

        .button {
            position: absolute;
            bottom: 5px;
            right: 5px;
        }
    }

    .bottom-info {
        padding: 30px 40px;
        display: flex;
        align-items: center;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        .logo-img {
            width: 80px;
            height: 80px;
            min-width: 80px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 40px;
            position: relative;

            > img {
                width: 80px;
                height: 80px;
                border-radius: 7px;
                object-position: center;
                object-fit: cover;
            }

            .button-circle {
                position: absolute;
                bottom: -8px;
                right: -8px;
            }
        }

        .info {
            position: relative;
            padding-right: 20px;

            label {
                font-size: 26px;
                font-weight: bold;
                line-height: 1.23;
                display: block;
                margin-bottom: 5px;
            }

            p {
                font-size: 13px;
                font-weight: normal;
                line-height: 1.23;
                margin-bottom: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
