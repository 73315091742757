<template>
    <span class="sort" :class="clazz">
        <i aria-hidden="true" class="banana banana-triangle-up" @click="onUpIconClick"></i>
        <i aria-hidden="true" class="banana banana-triangle-down" @click="onDownIconClick"></i>
    </span>
</template>

<script>
    export default {
        name: 'SortIcon',
        props: {
			clazz: String,
			typeSort: {
				type: String,
				default: ''
			},
		},
		methods: {
			onUpIconClick(){
				this.$emit('sortUp');
			},
			onDownIconClick(){
				this.$emit('sortDown');
			}
		}
	}

</script>

<style lang="scss">
.sort {
	display: inline-flex;
    flex-wrap: wrap;
    max-width: 10px;
	margin-left: 10px;

	&:hover {
		cursor: pointer;
	}

	> i {
		font-size: 18px;
		width: 10px;
		height: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
        transition: 0.5s;

		&:hover {
			opacity: 0.7;
		}
	}
}
</style>
