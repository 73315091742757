<template>
    <div class="row p-b-100">
        <div class="col-12">
            <section id="main-section" class="main-section-landingpage">
                <div class="width-responsive">
                    <img class="img-fluid home-img" src="@/assets/images/LandingPage/HeaderImg.png" alt=""/>
                    <span class="home-main-text">
                <h2 class="home-title"><p v-html="$t('landing.home.title')"> </p></h2>
                <p class="home-subtitle"> {{$t('landing.home.subtitle')}} </p>
              </span>
                </div>
            </section>
        </div>
        <div class="col-12">
            <ButtonScrollBottom idScroll="#text-section" btnText="Descubre más"></ButtonScrollBottom>
        </div>
        <div class="col-12">
            <section id="text-section" class="text-section-landingpage">
                <div class="width-responsive">
                    <p v-html="$t('landing.home.section-1')"></p>
                </div>
            </section>
        </div>
        <div class="col-12">
            <section id="comision-section" class="comision-section-landingpage">
                <div class="width-responsive">
                    <TitleLandingpage :textTitle="$t('landing.home.commision-title')"
                                      :textSubtitle="$t('landing.home.commision-subtitle')"></TitleLandingpage>
                    <div class="row">
                        <div class="col-12 m-b-40">
                            <b-form-radio-group
                                id="status"
                                class="radio-options-btn"
                                v-model="radioSelected1"
                                :options="radioOptions1"
                                buttons
                                name=""
                            ></b-form-radio-group>
                        </div>
                        <div class="col-md-6 col-12 ">
                            <div class="left-side">
                                <h1 class="">
                                    {{$t('landing.home.commision-amount')}}
                                </h1>
                                <div class="money-range">
                                    <div class="input-container">
                                        <currency-input v-model="amount" currency="MXN" locale="es"/>
                                    </div>
                                    <vue-slider :min="1" :max="65000" v-model="amount" class="slide-bar"/>
                                    <div class="label-range">
                                        <span>$1.00</span>
                                        <span>$65,000.00</span>
                                    </div>
                                </div>
                                <h1 class="">
                                    {{$t('landing.home.type-cards')}}
                                </h1>
                                <div class="cards-container">
                                    <img src="@/assets/images/LandingPage/Icons/icon-visa.svg" alt="card"
                                         @click="changeCardType('VISA')">
                                    <img src="@/assets/images/LandingPage/Icons/icon-master.jpg" alt="card"
                                         @click="changeCardType('MASTERCARD')">
                                    <img src="@/assets/images/LandingPage/Icons/icon-amex.svg" alt="card"
                                         @click="changeCardType('AMERICANEXPRESS')">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="comision-card">
                                <h1>{{$t('landing.home.amount-deposit')}}</h1>
                                <div class="number">
                                    {{commissionCalculator.totalWithoutCommission}}
                                </div>
                                <b-progress :value="97" striped :animated="animate" class=""></b-progress>
                                <div class="info-container">
                                    <div>
                                        <h2 class="c-accent_2">{{100 - commissionCalculator.percent}}%</h2>
                                        <p>
                                            {{$t('landing.home.amount-gain')}}
                                        </p>
                                    </div>
                                    <div>
                                        <h2>{{commissionCalculator.percent}}% + IVA</h2>
                                        <p>
                                            {{$t('landing.home.amount-commision-bank')}}
                                        </p>
                                    </div>
                                    <div>
                                        <h2 class="c-accent_1">- {{commissionCalculator.commission}}</h2>
                                        <p>
                                            {{$t('landing.home.amount-commision-total')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-12">
            <section id="features-section" class="features-section-landingpage">
                <div class="width-responsive">
                    <div class="row landing-feature">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div class="max-w-450px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Monitoreoyadministracion.jpg"
                                     alt="feature">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <TitleLandingpage clazz="left-text m-b-0 max-w-450px"
                                              :textTitle="$t('landing.home.benefits-1-title')"
                                              :textSubtitle="$t('landing.home.benefits-1-subtitle')"></TitleLandingpage>

                        </div>
                    </div>
                    <div class="row landing-feature">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <TitleLandingpage clazz="left-text m-b-0 max-w-450px" showSmallText
                                              :textSmall="$t('landing.home.benefits-comming-soon')"
                                              :textTitle="$t('landing.home.benefits-2-title')"
                                              :textSubtitle="$t('landing.home.benefits-2-subtitle')"></TitleLandingpage>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c justify-content-end">
                            <div class="max-w-400px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Recibepagosexpress.jpg"
                                     alt="feature">
                            </div>
                        </div>
                    </div>
                    <div class="row landing-feature">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div class="max-w-450px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Sinpreocupaciones.jpg"
                                     alt="feature">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <TitleLandingpage clazz="left-text m-b-0 max-w-450px"
                                              :textTitle="$t('landing.home.benefits-3-title')"
                                              :textSubtitle="$t('landing.home.benefits-3-subtitle')"></TitleLandingpage>
                        </div>
                    </div>
                    <div class="row landing-feature">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <TitleLandingpage clazz="left-text m-b-0 max-w-450px" showSmallText
                                              :textSmall="$t('landing.home.benefits-comming-soon')"
                                              :textTitle="$t('landing.home.benefits-4-title')"
                                              :textSubtitle="$t('landing.home.benefits-4-subtitle')"></TitleLandingpage>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c justify-content-end">
                            <div class="max-w-400px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Accededondesea.jpg"
                                     alt="feature">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-12">
            <section id="how-section" class="how-section-landingpage">
                <div class="width-responsive">
                    <TitleLandingpage hideOriginaltitle hideOriginalSubtitle
                                      titleClass="c-title principal-font-regular f-normal">
                        <template v-slot:titleContent>
                            <span class="title-divider-type" v-html="$t('landing.home.how-it-works')"></span>
                            <div class="divider"></div>
                        </template>

                        <template v-slot:subtitleContent>
                            <p v-html="$t('landing.home.join-pagando')"></p>
                        </template>
                    </TitleLandingpage>
                    <div class="row work-card-container">
                        <div class="col-lg-3 col-md-6 col-12 d-grid">
                            <div class="work-card">
                                <div class="number-card">
                                    <span>
                                        1
                                    </span>
                                </div>
                                <img class="img-fluid icon-img"
                                     src="@/assets/images/LandingPage/Icons/icon-register.svg" alt="imagHow">
                                <h1>{{$t('landing.home.steps-1-title')}}</h1>
                                <p>{{$t('landing.home.steps-1-subtitle')}}</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12 d-grid">
                            <div class="work-card">
                                <div class="number-card">
                                    <span>
                                        2
                                    </span>
                                </div>
                                <img class="img-fluid icon-img p-l-5 p-r-5 p-t-10 p-b-0"
                                     src="@/assets/images/LandingPage/Icons/icon-services.svg" alt="imagHow">
                                <h1>{{$t('landing.home.steps-2-title')}}</h1>
                                <p>{{$t('landing.home.steps-2-subtitle')}}</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12 d-grid">
                            <div class="work-card">
                                <div class="number-card">
                                <span>
                                    3
                                </span>
                                </div>
                                <img class="img-fluid icon-img" src="@/assets/images/LandingPage/Icons/icon-sales.svg"
                                     alt="imagHow">
                                <h1>{{$t('landing.home.steps-3-title')}}</h1>
                                <p>{{$t('landing.home.steps-3-subtitle')}}</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12 d-grid">
                            <div class="work-card">
                                <div class="number-card">
                                <span>
                                    4
                                </span>
                                </div>
                                <img class="img-fluid icon-img p-l-10 p-r-10 p-t-20 p-b-0"
                                     src="@/assets/images/LandingPage/Icons/icon-collab.svg" alt="imagHow">
                                <h1>{{$t('landing.home.steps-4-title')}}</h1>
                                <p>{{$t('landing.home.steps-4-subtitle')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-12">
            <section id="try-section" class="try-section-landingpage">
                <div class="width-responsive">
                    <TitleLandingpage hideOriginaltitle hideOriginalSubtitle
                                      titleClass="c-title principal-font-regular f-normal">
                        <template v-slot:titleContent>
                            <p v-html="$t('landing.home.try-now')"></p>
                        </template>
                        <template v-slot:inputText>
                            <BasicInput
                                    clazz="noLabel"
                                    inputType="text"
                                    labelFor="nameOrg"
                                    inputId="nameOrg"
                                    label=""
                                    v-model="registerText"
                                    @basicInput="changeTitle"
                                    :inputPlaceholder="$t('landing.home-place-holder-text')"></BasicInput>
                        </template>
                    </TitleLandingpage>

                    <div class="micrositio-content">
                        <b-carousel
                                id="carousel-micrositio"
                                class="carousel-landing-micrositio"
                                fade
                                indicators
                                controls
                                :interval="100000">

                            <b-carousel-slide :caption="title">
                                <template v-slot:img>
                                    <img class="img-fluid w-100 d-block"
                                         src="@/assets/images/LandingPage/Micrositio/LaptopMicrositeServicios.png"
                                         alt="image slot"/>
                                </template>
                            </b-carousel-slide>

                            <b-carousel-slide :caption="title">
                                <template v-slot:img>
                                    <img class="img-fluid w-100 d-block"
                                         src="@/assets/images/LandingPage/Micrositio/LaptopMicrositeTurismo.png"
                                         alt="image slot"/>
                                </template>
                            </b-carousel-slide>

                            <b-carousel-slide :caption="title">
                                <template v-slot:img>
                                    <img class="img-fluid w-100 d-block"
                                         src="@/assets/images/LandingPage/Micrositio/LaptopMicrositeJardineria.png"
                                         alt="image slot"/>
                                </template>
                            </b-carousel-slide>

                            <b-carousel-slide :caption="title">
                                <template v-slot:img>
                                    <img class="img-fluid w-100 d-block"
                                         src="@/assets/images/LandingPage/Micrositio/LaptopMicrositeBelleza.png"
                                         alt="image slot"/>
                                </template>
                            </b-carousel-slide>
                        </b-carousel>
                    </div>

                    <router-link to="/register/organizations-client"
                                 class="button button-filled button-accent1 m-0-auto w-100">
                        {{$t('landing.home.continue-register')}}
                    </router-link>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import ButtonScrollBottom from "@/components/ButtonScrollBottom.vue";
    import TitleLandingpage from "@/components/TitleLandingpage.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import {CurrencyInput} from 'vue-currency-input';
    import {
        STORE as LandingStore,
        SET_TAB_ACTIVE
    } from "@/store/modules/landing/landing.types";

    export default {
        name: "LandingHome",
        components: {
            ButtonScrollBottom,
            TitleLandingpage,
            BasicInput,
            CurrencyInput
        },
        data() {
            return {
                animate: true,
                amount: 2,
                cardType: "VISA",
                registerText: "",
                title: "Servicios profesionales",
                radioSelected1: 'radio1',
                radioOptions1: [
                    { text: 'Pagos por internet', value: 'radio1' }
                ],
            }
        },
        computed: {
            commissionCalculator() {
                const cardType = this.cardType;
                const amount = this.amount;
                let percent = 0.0;
                const IVA = 1.16;

                switch (cardType) {
                    case "VISA":
                    case "MASTERCARD":
                    case "AMERICANEXPRESS":
                    default:
                        percent = 3 / 100;
                }
                const commission = amount * percent * IVA;
                const totalWithoutCommission = amount - commission
                const finalPercent = percent * 100;
                return {
                    percent: finalPercent.toFixed(2),
                    commission: `$ ${commission.toFixed(2)}`,
                    totalWithoutCommission: `$ ${totalWithoutCommission.toFixed(2)}`
                };
            }
        },
        methods: {
            changeCardType(cardType) {
                this.cardType = cardType;
            },
            changeTitle(title) {
                this.title = title;
            }
        },
        created() {
            this.$store.commit(`${LandingStore}/${SET_TAB_ACTIVE}`, 'HOME');
        }
    }
</script>
