import axios from 'axios'
import base from '@/api/base.api';
import config from '@/config';

const namespace = 'sign-up-organization';

export default {
    saveCurrentStepForm: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-step-form`, params, {headers: {'Content-Type': 'application/json'}})
            .then(onSuccess)
            .catch(onError);
    },

    // ------------ FEAT/VUE -----------
    // Step start
    saveStartStep: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save/start-step`, params)
            .then(onSuccess)
            .catch(onError);
    },
    saveOperationSchemeStep: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save/operation-scheme-step`, params)
            .then(onSuccess)
            .catch(onError);
    },
    login: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/login`, params)
            .then(onSuccess)
            .catch(onError);
    },
    createAccountByEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/create-account/mail`, params)
            .then(onSuccess)
            .catch(onError);
    },

    // Step logo
    stepLogoUpdateLogo: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/logo/update`, params)
            .then(onSuccess)
            .catch(onError);
    },
    stepLogoUpdatePattern: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/pattern/update`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getCoverImageOptions: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/cover/options?category=${params}`)
            .then(onSuccess)
            .catch(onError);
    },
    stepCoverUpdateCover: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/cover/update`, params)
            .then(onSuccess)
            .catch(onError);
    },
    // Step Banking
    retrieveBanks: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/retrieve/banks`, params )
            .then(onSuccess)
            .catch(onError);
    },
    saveBanking: (params, onSucces, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save/bankingStep`, params)
            .then(onSucces)
            .catch(onError);
    },
    saveLastStep: (params, onSucces, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save/fiveStep`, params)
            .then(onSucces)
            .catch(onError);
    },
    saveOrganizationFileStep: (params, onSucces, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save/organization-file`, params)
            .then(onSucces)
            .catch(onError);
    },
    // Step Location
    saveStepLocation: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save/location`, params)
            .then(onSuccess)
            .catch(onError);
    },
    // Step Summary
    retrieveProfessions: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/retrieve/professions`, params )
            .then(onSuccess)
            .catch(onError);
    },
    retrieveOccupations: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/retrieve/occupations`, params )
            .then(onSuccess)
            .catch(onError);
    },
    getSummaryOrganization: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/summary/organization`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getActualContractPreview: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/documents/organization/actual-contract/${params.idPotential}`)
            .then(onSuccess)
            .catch(onError);
    },
    acceptPreContract: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/documents/accept-precontract`, params)
            .then(onSuccess)
            .catch(onError);
    },
     getPotentialOrganizationByUser: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/organization/user`,params)
            .then(onSuccess)
            .catch(onError);
    },
    organizationCreate: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/organization/create`, params)
            .then(onSuccess)
            .catch(onError);
    },
    contractCreate: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/contract-accepted/create`, params)
            .then(onSuccess)
            .catch(onError);
    },
    accessConfigCreate: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/access-config/create`, params)
            .then(onSuccess)
            .catch(onError);
    },
    collaboratorsCreate: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/collaborators/create`, params)
            .then(onSuccess)
            .catch(onError);
    },
    accessConfigSelect: (params, onSuccess, onError) => {
        axios.defaults.headers.common.Authorization = `Bearer ${params.jwt}`;
        return axios.get(`${config.server.host}:${config.server.port}/api-v2/select/organization?user=${params.user}&profile=${params.profile}`)
            .then(onSuccess)
            .catch(onError);
    },

    addCollaborator: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/sign-up-organization/add-collaborator`, params)
            .then(onSuccess)
            .catch(onError)
    },
    fetchFileDocuments: (params, onSuccess, onError) => {
        let url = `${base.baseUrl}/file-document/fetch-by-account-level?processOrCategory=${params.processOrCategory}&applyFor=${params.applyFor}`;
        if(params.personType){
            url = `${url}&personType=${params.personType}`
        }
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    }

}
