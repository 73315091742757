<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <a
                        class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                        tabindex=""
                ><i aria-hidden="true" class="banana banana-arrow-left"></i> Regresar a Tipos de Pagos</a>
                <FloatingTitleLayout title="Pago con Servicios Web"/>
            </div>

            <WizardForm clazz="col-12">
                <template v-slot:wizardView>
                    <tab-content
                            title="Datos generales"
                            icon="one"
                    >
                        PASO 1

                    </tab-content>
                    <tab-content
                            title="Configuración web"
                            icon="two"
                    >
                        PASO 2
                    </tab-content>
                    <tab-content
                            title="Resumen"
                            icon="three"
                    >
                        PASO 3
                    </tab-content>
                </template>
            </WizardForm>

            <div class="col-12">
                <b-alert
                        class="alert-note"
                        show
                        variant="info"
                >
                    <p class="text-container">
                        <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                alt="info"
                        />
                        El Pago con Servicios Web se utiliza para consultar los conceptos y montos a pagar desde un
                        servicio web externo. Se recomienda que este tipo de pago lo configure una persona con
                        conocimientos técnicos.
                    </p>
                </b-alert>

                <CardSimple
                        title="Datos generales del servicio"
                        subtitle="Establece las especificaciones básicas de tu pago. Esta configuración ayudará a que tus clientes encuentren
                         tu servicio e identifiquen la información más relevante."
                >

                    <BasicInput
                            required
                            labelFor="example1"
                            inputId="example1"
                            label="Nombre del servicio"
                            inputPlaceholder="¿Con qué nombre quieres que la gente vea tu servicio?"
                    >
                    </BasicInput>
                    <BasicTextarea
                            labelFor="examplet2"
                            textareaId="examplet2"
                            label="Descripción del servicio"
                            textareaPlaceholder="Escribe de manera breve de qué trata tu servicio y qué es lo que ofrece…"
                    >
                    </BasicTextarea>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <BasicSelect
                                    required
                                    label="Estatus"
                                    labelFor
                                    help
                                    idHelpTooltip="ejGenerico4"
                                    helpText="  ¿Cuál elegir?"
                                    helpIcon="banana banana-question-circle1"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico4"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>

                                <multiselect
                                        v-model="value"
                                        :options="options"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Elige cómo quieres publicar tu servicio"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                >
                                    <template
                                            slot="selection"
                                            slot-scope="{ values, search, isOpen }"
                                    >
                    <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>

                        </div>
                        <div class="col-md-6 col-12">
                            <BasicSelect
                                    required
                                    label="Divisa"
                                    labelFor
                                    help
                                    idHelpTooltip="ejGenerico5"
                                    helpText="¿Qué es?"
                                    helpIcon="banana banana-question-circle1"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico5"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>

                                <multiselect
                                        v-model="value"
                                        :options="options2"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Selecciona con cuál divisa quieres cobrar"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                >
                                    <template
                                            slot="selection"
                                            slot-scope="{ values, search, isOpen }"
                                    >
                    <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>

                        </div>
                    </div>
                    <div class="row m-b-10">
                        <div class="col-md-6 col-6">
                            <h1 class="f-14 c-title body-font-regular m-0">Agrega imagen(es) del servicio <span
                                    class="c-error">*</span></h1>
                        </div>
                        <div class="col-md-6 col-6">
                            <div class=" justify-content-end d-flex">
                                <h1
                                        class="m-0 c-accent_2 f-13 body-font-regular c-help d-flex align-middle "
                                        id="helpImg"
                                >
                                    ¿Dónde se muestran estas imágenes?
                                    <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                </h1>
                                <b-tooltip
                                        target="helpImg"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                        maiores nam voluptas dolorem distinctio voluptates veniam quod quis fugit
                                        commodi facilis alias accusamus nemo reiciendis, culpa sit inventore illum.</p>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>

                    <b-alert
                            class="alert-note"
                            show
                            variant="warning"
                    >
                        <p class="text-container">
                            <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                    alt="info"
                            />
                            Para que tus clientes puedan visualizar correctamente tu servicio es necesario que cargues
                            al menos una imagen con las características especificadas.
                        </p>
                    </b-alert>
                    <UploadArea
                            clazz="m-b-30"
                            clazzArea="col-6"
                    >
                        <SimpleNote clazz="info m-b-0 m-l-30">
                            <p class="m-b-0">
                                El tamaño de las imágenes debe ser <strong>1040px por 500px</strong>.
                                Puedes subir hasta 5 imágenes tipo: <strong>JPG, PNG y SVG</strong>.
                            </p>
                        </SimpleNote>
                    </UploadArea>

                    <div class="vertical-upload-list">
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="1"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="2"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="3"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea>
                            <UploadArea
                                    imgUploaded
                                    hideButtonClose
                                    numList="4"
                            ></UploadArea>
                        </ListUploadArea>
                        <ListUploadArea disableButtons>
                            <UploadArea></UploadArea>
                        </ListUploadArea>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <h1 class="f-14 c-title body-font-regular m-0">Selecciona la categoría del servicio</h1>
                        </div>
                    </div>

                    <SimpleCarousel :itemsCarousel="[[0, 2],[600, 3],[991, 4],[1230, 4]]"
                                    clazz="simpleCarousel-container">
                        <slide>
                            <CardItem clazz="category-card selected">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-food.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Alimentos</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-health.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Belleza y Cuidado Personal</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-sports.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Deportes</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-school.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Educación y Capacitación</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-place.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Espacios y Vivienda</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-party.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Fiestas y Eventos</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-home.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Hogar</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-garden.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Jardinería</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-pets.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Mascotas</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-profesionals.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Profesionales</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-plane.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Recreación y Turismo</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-tools.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Reparación y Mantenimiento</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Ropa y Accesorios</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-services.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Servicios</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Transporte</h1>
                                </div>
                            </CardItem>
                        </slide>
                        <slide>
                            <CardItem clazz="category-card">
                                <div class="img-container">
                                    <img
                                            class="background"
                                            src="@/assets/images/Default/dots-grid.svg"
                                            alt
                                    />
                                    <img
                                            class="icon"
                                            src="@/assets/images/Illustrations/categories/Illustration-others.svg"
                                            alt
                                    />
                                </div>
                                <div class="text-container">
                                    <h1>Otros</h1>
                                </div>
                            </CardItem>
                        </slide>
                    </SimpleCarousel>

                    <div class="m-b-30">
                        <BasicSelect
                                required
                                label="Tags"
                                labelFor="bannerNum"
                                help
                                idHelpTooltip="tagHelp"
                                helpText="¿Cómo funcionan los tags?"
                                helpIcon="banana banana-question-circle1"
                        >
                            <template v-slot:tooltipHelp>
                                <b-tooltip
                                        target="tagHelp"
                                        placement="top"
                                        custom-class="white-tooltip"
                                >
                                    <div>
                                        <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </b-tooltip>
                            </template>
                            <multiselect
                                    v-model="valueTags"
                                    :options="optionsTags"
                                    placeholder="Asignar tags a este servicio"
                                    tag-placeholder="Agregar como nuevo tag"
                                    label="name"
                                    track-by="name"
                                    :preselect-first="false"
                                    class="select-up"
                                    :multiple="true"
                                    :taggable="true"
                            ></multiselect>
                        </BasicSelect>
                    </div>
                    <div>
                        <button
                                id
                                class="collapse-btn"
                                type="button"
                                v-b-toggle.collapse-config
                        >Configuración avanzada
                            <i aria-hidden="true" class="banana banana-chevron-down"></i></button>
                        <b-collapse
                                id="collapse-config"
                                class="m-t-40"
                        >
                            <div class="m-b-20">
                                <div class="row m-b-30">
                                    <div class="col-md-7 col-7">
                                        <h1 class="c-title f-14 body-font-regular m-0">
                                            Privacidad del servicio
                                        </h1>
                                    </div>
                                    <div class="col-md-5 col-5">
                                        <div class=" justify-content-end d-flex">
                                            <h1
                                                    class="m-0 c-info f-13 body-font-regular c-help d-flex align-middle "
                                                    id="helpMult"
                                            >
                                                ¿Cuál se adapta mejor a mi servicio?
                                                <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                            </h1>
                                            <b-tooltip
                                                    target="helpMult"
                                                    placement="top"
                                                    custom-class="white-tooltip"
                                            >
                                                <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing
                                                    elit. Ab quasi maiores nam voluptas dolorem distinctio voluptates
                                                    veniam quod quis fugit commodi facilis alias accusamus nemo
                                                    reiciendis, culpa sit inventore illum.</p>
                                            </b-tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <div class="float-left m-r-30">
                                            <RadioInput
                                                    id="one"
                                                    for="one"
                                                    text="Público"
                                            ></RadioInput>
                                        </div>
                                        <div class="float-left">
                                            <RadioInput
                                                    id="two"
                                                    for="two"
                                                    text="Privado"
                                            ></RadioInput>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <BasicInput
                                    labelFor="identificador"
                                    inputId="identificador"
                                    label="Identificador externo"
                                    inputPlaceholder="Introduce el identificador de este servicio en notificaciones de WebHooks, consultas en la API, etc."
                                    help
                                    helpText="¿Qué es un ID externo?"
                                    helpIcon="banana banana-question-circle1"
                                    idHelpTooltip="ejGenerico3"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico3"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                            <div class="m-b-30">
                                <div class="row">
                                    <div class="col-md-8 col-8">
                                        <BasicCheckbox
                                                clazz="m-0"
                                                text="Pedir al cliente que ingrese notas adicionales"
                                                :index="0"
                                        ></BasicCheckbox>
                                    </div>
                                    <div class="col-md-4 col-4">
                                        <div class=" justify-content-end d-flex">
                                            <h1
                                                    class="m-0 c-info f-13 body-font-regular c-help d-flex align-middle "
                                                    id="helpCheck"
                                            >
                                                ¿Para qué sirve esto?
                                                <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                                            </h1>
                                            <b-tooltip
                                                    target="helpCheck"
                                                    placement="top"
                                                    custom-class="white-tooltip"
                                            >
                                                <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing
                                                    elit. Ab quasi maiores nam voluptas dolorem distinctio voluptates
                                                    veniam quod quis fugit commodi facilis alias accusamus nemo
                                                    reiciendis, culpa sit inventore illum.</p>
                                            </b-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BasicCheckbox
                                    clazz="m-b-30"
                                    text="Establecer el campo de notas adicionales como obligatorio"
                                    :index="0"
                            ></BasicCheckbox>
                            <BasicInput
                                    required
                                    labelFor="adicionales"
                                    inputId="adicionales"
                                    label="Título del campo de notas adicionales"
                                    inputPlaceholder="P. ej: Notas adicionales, talla, color, comentarios, dirección, etc."
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico3"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                            <BasicInput
                                    required
                                    labelFor="Instrucciones"
                                    inputId="Instrucciones"
                                    label="Instrucciones de cómo deben ser ingresadas las notas adicionales"
                                    inputPlaceholder="P. ej: Ingrese aquí la talla que requiere (XS, S, M, L, XL, XXL)"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="ejGenerico3"
                                            placement="top"
                                            custom-class="white-tooltip"
                                    >
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>

                        </b-collapse>
                        <div class="row m-t-60">
                            <div class=" col-12">
                                <div class="d-flex justify-content-end">
                                    <button class="button button-ghost sm button-plain_text">
                                        cancelar
                                    </button>
                                    <button class="button button-filled sm button-accent1 m-l-15 disabled">
                                        siguiente
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                </CardSimple>

                <CardSimple
                        title="Configuración web"
                        subtitle="Define los parámetros REST correspondientes para vincular la plataforma de Pagando con el servicio web de tu organización."
                >
                    <b-alert
                            class="alert-note"
                            show
                            variant="warning"
                    >
                        <p class="text-container">
                            <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                    alt="info"
                            />
                            Es importante que una persona con conocimientos técnicos sea la que se encargue de
                            configurar los datos de esta sección para evitar futuros errores.
                        </p>
                    </b-alert>
                    <p class="f-14 c-plain_text body-font-regular m-b-20">
                        Recuerda que tu URL debe regresar un objeto tipo JSON como el del ejemplo con los siguientes
                        valores:
                    </p>
                    <div class="row m-b-30">
                        <div class="col-md-4 col-12">
                            <img
                                    class="img-fluid max-w-260px"
                                    src="@/assets/images/Default/code-example.jpg"
                                    alt="code"
                            >
                        </div>
                        <div class="col-md-8 col-12">
                            <ul class="p-t-10">
                                <li class="body-font-regular f-14 c-plain-text">
                                    <strong class="c-title">name (String)</strong> <br>
                                    El nombre de tu servicio
                                </li>
                                <li class="body-font-regular f-14 c-plain-text">
                                    <strong class="c-title">items (object Array)</strong> <br>
                                    Un arreglo de artículos con el siguiente formato: <br>
                                    { name: (string), isTax: (boolean), unitPrice: (float), quantity: (integer)}
                                </li>
                                <li class="body-font-regular f-14 c-plain-text">
                                    <strong class="c-title">references (object Array)</strong> <br>
                                    Un arreglo de referencias con el siguiente formato: <br>
                                    { name: (string), value: (string)}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <BasicSelect
                            required
                            label="Método HTTP"
                            labelFor="metodo"
                    >

                        <multiselect
                                v-model="value"
                                :options="options3"
                                :close-on-select="true"
                                :preserve-search="true"
                                placeholder="Elige uno"
                                label="name"
                                track-by="name"
                                :preselect-first="false"
                        >
                            <template
                                    slot="selection"
                                    slot-scope="{ values, search, isOpen }"
                            >
                <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>

                    <div class="d-flex align-items-c">
                        <div class="w-100 ">
                            <div class="row">
                                <div class="col-md-4 m-b-30">
                                    <BasicInput
                                            clazz="m-b-0"
                                            required
                                            labelFor="parametro"
                                            inputId="parametro"
                                            label="Parámetro"
                                            inputPlaceholder="Introduce información adicional sobre el servicio"
                                    >
                                    </BasicInput>
                                </div>
                                <div class="col-md-4 m-b-30">
                                    <BasicInput
                                            clazz="m-b-0"
                                            required
                                            labelFor="parametro"
                                            inputId="parametro"
                                            label="Parámetro"
                                            inputPlaceholder="Introduce información adicional sobre el servicio"
                                    >
                                    </BasicInput>
                                </div>
                                <div class="col-6 col-md-3 d-flex align-items-bottom p-b-10 m-b-30">
                                    <BasicCheckbox
                                            clazz="m-0"
                                            text="Query String"
                                            :index="0"
                                    ></BasicCheckbox>
                                </div>
                                <div class="col-6 col-md-1 m-b-30">
                                    <button class="button-circle button-ghost button-error sm f-20 m-t-20">
                                        <i aria-hidden="true" class="banana banana-delete"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 m-b-30">
                                    <BasicInput
                                            clazz="m-b-0"
                                            required
                                            labelFor="parametro"
                                            inputId="parametro"
                                            label="Parámetro"
                                            inputPlaceholder="Introduce información adicional sobre el servicio"
                                    >
                                    </BasicInput>
                                </div>
                                <div class="col-md-4 m-b-30">
                                    <BasicInput
                                            clazz="m-b-0"
                                            required
                                            labelFor="parametro"
                                            inputId="parametro"
                                            label="Parámetro"
                                            inputPlaceholder="Introduce información adicional sobre el servicio"
                                    >
                                    </BasicInput>
                                </div>
                                <div class="col-6 col-md-3 d-flex align-items-bottom p-b-10 m-b-30">
                                    <BasicCheckbox
                                            clazz="m-0"
                                            text="Query String"
                                            :index="0"
                                    ></BasicCheckbox>
                                </div>
                                <div class="col-6 col-md-1 m-b-30">
                                    <button class="button-circle button-ghost button-error sm f-20 m-t-20">
                                        <i aria-hidden="true" class="banana banana-delete"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a
                            href
                            class="button-square-add m-b-30"
                            tabindex
                    >
                        <i aria-hidden="true" class="banana banana-symbol-plus"></i> Agregar parámetro
                    </a>

                    <div class="d-flex align-items-c m-b-30">
                        <div class="w-100 p-r-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="servicio"
                                    inputId="servicio"
                                    label="Vista previa del URL"
                                    inputPlaceholder="Introduce tu URL y agrega los parámetros correspondientes en el botón +"
                            >
                            </BasicInput>

                        </div>
                        <b-dropdown id="dropdown-add" right>
                            <template v-slot:button-content>
                                <button
                                        type="button"
                                        class="button-circle button-filled button-accent1 sm m-t-15 disabled"
                                >
                                    <i aria-hidden="true" class="banana banana-symbol-plus"></i>
                                </button>
                            </template>
                            <b-dropdown-group>
                                <b-dropdown-item-button>
                                    id
                                </b-dropdown-item-button>
                                <b-dropdown-item-button>
                                    noContrato
                                </b-dropdown-item-button>
                            </b-dropdown-group>
                        </b-dropdown>
                    </div>
                    <BasicSelect
                            required
                            label="Content Type"
                            labelFor="type"
                    >

                        <multiselect
                                v-model="value"
                                :options="options4"
                                :close-on-select="true"
                                :preserve-search="true"
                                placeholder="Selecciona el Content Type"
                                label="name"
                                track-by="name"
                                :preselect-first="false"
                        >
                            <template
                                    slot="selection"
                                    slot-scope="{ values, search, isOpen }"
                            >
                <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>

                    <div class="d-flex align-items-c m-b-30">
                        <div class="w-100 p-r-30">
                            <BasicTextarea
                                    hideCounter
                                    required
                                    labelFor="body"
                                    textareaId="body"
                                    label="Body"
                                    textareaPlaceholder="Introduce el Body y agrega los parámetros correspondientes en el botón +"
                            >
                            </BasicTextarea>

                        </div>
                        <b-dropdown id="dropdown-add" right>
                            <template v-slot:button-content>
                                <button
                                        type="button"
                                        class="button-circle button-filled button-accent1 sm m-t-15 "
                                >
                                    <i aria-hidden="true" class="banana banana-symbol-plus"></i>
                                </button>
                            </template>
                            <b-dropdown-group>
                                <b-dropdown-item-button>
                                    id
                                </b-dropdown-item-button>
                                <b-dropdown-item-button>
                                    noContrato
                                </b-dropdown-item-button>
                            </b-dropdown-group>
                        </b-dropdown>
                    </div>

                    <div class="row m-t-60">
                        <div class="col-md-6 col-12">
                            <button class="button button-ghost sm button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                regresar
                            </button>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="d-flex justify-content-end">
                                <button class="button button-ghost sm button-plain_text">
                                    cancelar
                                </button>
                                <button class="button button-filled sm button-accent1 m-l-15 disabled">
                                    siguiente
                                </button>

                            </div>
                        </div>
                    </div>

                </CardSimple>

                <CardSimple>
                    <EmptyState
                            emptyStateDefault
                            :imgUrl="ImagenEmptyStateDefault"
                            size="sm"
                            title="Te invitamos a llenar todos los datos de tu servicio web y hacer clic en Guardar,"
                            text="al hacerlo podrás probarlo para verificar que funciona correctamente y activarlo"
                    ></EmptyState>
                </CardSimple>

                <div class="p-relative">
                    <LoadingBar
                            loadingText="Tu servicio web está siendo probado. Esto puede tardar unos minutos…"></LoadingBar>
                    <CardSimple
                            title="Prueba tu servicio web"
                            subtitle="El servicio web está listo para realizar una prueba antes de ser activado.
                             Puedes hacerlo aquí o en el listado de tipo de pagos después de guardarlo."
                    >

                        <div class="m-b-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="id"
                                    inputId="id"
                                    label="id"
                                    inputPlaceholder="Ingresa valor"
                            >
                            </BasicInput>
                        </div>
                        <div class="m-b-30">
                            <BasicInput
                                    clazz="m-b-0"
                                    required
                                    labelFor="noContrato"
                                    inputId="noContrato"
                                    label="noContrato"
                                    inputPlaceholder="Ingresa valor"
                            >
                            </BasicInput>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <h3 class="m-0 m-b-15 f-14 font-regular-bold c-title">
                                    Resultados de la prueba
                                </h3>
                                <div class="dots-table m-b-30">
                                    <ul>
                                        <li>
                                            <div class="item-row">
                                                <div class="left-side">
                                                    Request
                                                </div>
                                                <div class="right-side">
                                                    <Badge
                                                            type="badge-success"
                                                            text="aprobado"
                                                    ></Badge>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="item-row">
                                                <div class="left-side">
                                                    Response
                                                </div>
                                                <div class="right-side">
                                                    <Badge
                                                            type="badge-error"
                                                            text="error"
                                                    ></Badge>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="item-row">
                                                <div class="left-side">
                                                    Data
                                                </div>
                                                <div class="right-side">
                                                    <Badge
                                                            type="badge-success"
                                                            text="aprobado"
                                                    ></Badge>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <BasicSelect
                                        required
                                        label="Estatus"
                                        labelFor="status"
                                >

                                    <multiselect
                                            v-model="value"
                                            :options="options5"
                                            :close-on-select="true"
                                            :preserve-search="true"
                                            placeholder="Elige uno"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false"
                                    >
                                        <template
                                                slot="selection"
                                                slot-scope="{ values, search, isOpen }"
                                        >
                      <span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span>
                                        </template>
                                    </multiselect>
                                </BasicSelect>
                                <Badge
                                        type="badge-accent_2"
                                        text="activo"
                                ></Badge>
                            </div>
                        </div>

                        <b-alert
                                class="alert-note"
                                show
                                variant="danger"
                        >
                            <p class="text-container">
                                <img
                                        class="img-fluid"
                                        src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                        alt="error"
                                />
                                Se ha detectado un error al probar tu servicio web. Verifica que la información
                                ingresada sea correcta y haz clic en Guardar para probar de nuevo, o corrige el motivo
                                de error marcado.
                            </p>
                        </b-alert>

                        <div class="row m-t-60">
                            <div class="col-md-6 col-12">
                                <button class="button button-stroke sm button-accent1">
                                    probar después
                                </button>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="d-flex justify-content-end">
                                    <button class="button button-ghost sm button-plain_text">
                                        cancelar
                                    </button>
                                    <button class="button button-filled sm button-accent1 m-l-15 disabled">
                                        probar ahora
                                    </button>

                                </div>
                            </div>
                        </div>

                    </CardSimple>
                </div>

                <CardSimple
                        title="Resumen"
                        subtitle="Has terminado de configurar tu servicio. Aquí se muestra un resumen de la información ingresada para que puedas
                         verificar que sea correcta. Si encuentras algún error puedes editar lo que sea necesario."
                >
                    <div class="m-b-10">
                        <h1 class="c-title f-20 principal-font-bold m-b-30">
                            Datos generales
                        </h1>
                        <div class="row">
                            <div class="col-md-5 col-12 m-b-30">
                                <b-carousel
                                        id="OneItemCarousel"
                                        fade
                                        indicators
                                        controls
                                        :interval="10000"
                                >
                                    <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/A1E74CED-4B97-4F9C-A7DF-D43FEAA54454.png"></b-carousel-slide>
                                    <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/8A0B51A8-7BAA-487B-9755-E71C709D4C11.png"></b-carousel-slide>
                                    <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/39B68F24-72B4-45B1-A25E-FA557AAE8C57.png"></b-carousel-slide>
                                </b-carousel>
                            </div>
                            <div class="col-md-7 col-12 m-b-30">
                                <Badge
                                        type="badge-accent_2 m-b-10 m-r-10"
                                        text="Activo"
                                ></Badge>
                                <Badge
                                        type="badge-accent_1 m-b-10"
                                        text="Publico"
                                ></Badge>
                                <h2 class="c-title f-20 body-font-regular m-b-10">Tijeras para cortar el césped</h2>
                                <h3 class="c-info_text f-14 body-font-regular f-bold m-0 m-b-15">
                                    JARDINERÍA
                                </h3>
                                <p class="c-plain_text f-14 body-font-regular m-0">
                                    ¡Nuevo modelo de tijeras con diseño ergonómico! Mango de plástico con almohadillas y
                                    navajas de acero inoxidable.
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Divisa</h4>
                                <p class="c-plain_text f-14 body-font-regular m-0">
                                    Pesos mexicanos (MXN)
                                </p>
                            </div>
                            <div class="col-md-7 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Tags</h4>
                                <div>
                                    <Badge
                                            type="badge-accent_2 m-r-5 m-b-5"
                                            text="Flores"
                                            closeBtnShow
                                    ></Badge>
                                    <Badge
                                            type="badge-accent_2 m-r-5 m-b-5"
                                            text="Arreglos"
                                            closeBtnShow
                                    ></Badge>
                                    <Badge
                                            type="badge-accent_2 m-r-5 m-b-5"
                                            text="Accesorios para auto"
                                            closeBtnShow
                                    ></Badge>
                                </div>
                            </div>
                            <div class="col-md-5 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">ID externo</h4>
                                <p class="c-placeholder f-italic f-14 body-font-regular m-0">
                                    No ingresaste ID externo.
                                </p>
                            </div>
                            <div class="col-md-7 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Canal favorito</h4>
                                <p class="c-placeholder f-14 body-font-regular m-0">
                                    Ingresa aquí tu canal favorito del mes
                                </p>
                            </div>
                        </div>
                    </div>

                    <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-10">
                        Editar sección
                    </button>
                    <div class="m-b-40">
                        <h1 class="c-title f-20 principal-font-bold m-b-20">
                            Configuración web
                        </h1>
                        <div class="row">
                            <div class="col-md-4 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Método HTTP</h4>
                                <p class="c-placeholder f-14 body-font-regular m-0">
                                    Método POST
                                </p>
                            </div>
                            <div class="col-md-8 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Resultados de la
                                    prueba</h4>
                                <Badge
                                        type="badge-success m-r-5 m-b-5"
                                        text="aprobada"
                                ></Badge>
                            </div>
                            <div class="col-md-12 col-12 m-b-30">
                                <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">URL del servicio web</h4>
                                <p class="c-placeholder f-14 body-font-regular m-0">
                                    www.izzi.mx/api?{{id}}noContrato={{noContrato}}
                                </p>
                            </div>
                        </div>
                        <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Lista de parámetros</h4>
                        <ul class="m-0 p-0">
                            <li class="f-14 c-plain_text body-font-regular d-block m-b-10">
                                <div class="row">
                                    <div class="col-md-4 col-12 m-b-20">
                                        <p class="c-placeholder f-14 body-font-regular m-0">
                                            - id
                                        </p>
                                    </div>
                                    <div class="col-md-4 col-12 m-b-20">
                                        <p class="c-placeholder f-14 body-font-regular m-0">
                                            Identificador del cliente
                                        </p>
                                    </div>
                                    <div class="col-md-4 col-12 m-b-20">
                                        <p class="c-placeholder f-14 body-font-regular m-0">
                                            id
                                        </p>
                                    </div>
                                    <div class="col-md-4 col-12 m-b-20">
                                        <p class="c-placeholder f-14 body-font-regular m-0">
                                            - noContrato
                                        </p>
                                    </div>
                                    <div class="col-md-4 col-12 m-b-20">
                                        <p class="c-placeholder f-14 body-font-regular m-0">
                                            Número de contrato
                                        </p>
                                    </div>
                                    <div class="col-md-4 col-12 m-b-20">
                                        <p class="c-placeholder f-14 body-font-regular m-0">
                                            noContrato={{noContrato}}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <div class="m-b-30">
                            <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0 ">Content Type</h4>
                            <p class="c-placeholder f-14 body-font-regular m-0">
                                application/json
                            </p>
                        </div>

                        <div class="m-b-30">
                            <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">Body</h4>
                            <p class="c-placeholder f-14 body-font-regular m-0">
                                { <br>
                                “id” <br>
                                "noContrato”;{noContrato} <br>
                                } <br>
                            </p>
                        </div>
                    </div>

                    <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40">
                        Editar sección
                    </button>

                    <div class="row m-t-60">
                        <div class="col-md-6 col-12">
                            <button class="button button-ghost sm button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                regresar
                            </button>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="d-flex justify-content-end">
                                <button class="button button-ghost sm button-plain_text">
                                    cancelar
                                </button>
                                <button class="button button-filled sm button-accent1 m-l-15">
                                    Guardar
                                </button>

                            </div>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import SimpleNote from "@/components/SimpleNote.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import BasicTextarea from "@/components/BasicTextarea.vue";
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import BasicSelect from "@/components/BasicSelect.vue";
    import Multiselect from "vue-multiselect";
    import ListUploadArea from '@/components/ListUploadArea.vue';
    import UploadArea from '@/components/UploadArea.vue';
    import CardItem from "@/components/CardItem.vue";
    import SimpleCarousel from "@/components/SimpleCarousel.vue";
    import Badge from "@/components/Badge";
    import CardSimple from '@/components/CardSimple.vue'
    import WizardForm from "@/components/WizardForm.vue";
    import RadioInput from "@/components/RadioInput.vue";
    import EmptyState from "@/components/EmptyState.vue";
    import ImagenEmptyStateDefault from '@/assets/images/Emptystates/emptystate-webservice.svg';
    import LoadingBar from "@/components/LoadingBar.vue";

    export default {
        name: "WebServicePayment",
        components: {
            FloatingTitleLayout,
            SimpleNote,
            BasicInput,
            BasicTextarea,
            BasicCheckbox,
            BasicSelect,
            Multiselect,
            ListUploadArea,
            UploadArea,
            CardItem,
            SimpleCarousel,
            Badge,
            WizardForm,
            CardSimple,
            RadioInput,
            EmptyState,
            LoadingBar
        },
        data() {
            return {
                ImagenEmptyStateDefault: ImagenEmptyStateDefault,
                // Date Range Picker
                opens: 'left',
                showDropdowns: true,
                autoApply: true,
                dateRange: [],
                // Multiselect
                checkedSwitch: false,
                value: [],
                options: [
                    {name: "Activo"},
                    {name: "Inactivo"},
                    {name: "Próximamente"}
                ],
                options2: [
                    {name: "Pesos mexicanos (MXN)"}
                ],
                options3: [
                    {name: 'Método GET'},
                    {name: 'Método POST'}
                ],
                options4: [
                    {name: 'application/json'},
                    {name: 'application/x-wwww-form-urlencoded'},
                    {name: 'text/plain'},
                ],
                options5: [
                    {name: 'Activo'},
                    {name: 'Inactivo'},
                ],
                valueTags: [],
                optionsTags: [
                    {name: 'Flores'},
                    {name: 'Arreglos'},
                    {name: 'Comida china'},
                    {name: 'Ropa de invierno'},
                    {name: 'Accesorios para auto'},
                    {name: 'Telefonía'}
                ],

            };
        }
    };
</script>
