import {values, allValues} from './../common/consts/categories.consts'
import i18n from '@/plugins/i18n';
function _getCategories(isAdmin) {
    const valuestToUser = isAdmin ? allValues : values;
    const categoriesArray = [];
    valuestToUser.forEach(c =>{
        categoriesArray.push({
            value: c.name,
            name: i18n.t(c.label),
            disabled: false
        })
    })
    return categoriesArray;
}

export default {
    getAllCategories(isAdmin = false) {
        return _getCategories(isAdmin);
    },
    getCategorie(categorieValue){
        const categories = _getCategories(true);

        for (let i = 0; i < categories.length; i++) {
            const categorie = categories[i];
            if (categorie.value === categorieValue) {
                return categorie;
            }
        }
        return "";
    }
};
