<template>
    <div class="upload-file-container">
        <div id="dct-uploadFile" class="upload-file-component"
             :class="{'loading' : loadingFile, 'loaded' : loadedFile, clazz}">
            <span class="square">
                <i aria-hidden="true" v-if="!loadingFile" class="defaultIcon banana" :class="icon"></i>
                <LoadingSimple v-if="loadingFile" clazz="small-load"></LoadingSimple>
                <i aria-hidden="true" class="iconAdd banana banana-symbol-plus"></i>
                <input id="file-uploading" type="file" :ref="inputId" class="input-file" name="" @change="loadFile" :disabled="disabled"/>
            </span>
            <span class="info">
                <label v-if="!loadingFile" class="title"> {{title}} </label>
                <b-progress v-if="loadingFile" :value="valueProgressBar" :max="maxProgressBar"
                            class="bar-progress m-t-0 m-b-10"></b-progress>
                <small class="type"> {{text}} </small>
                <p v-if="!loadedFile" class="note"> {{note}} </p>
                    <!-- Add functionaliti when knows how to useit -->
                <a v-if="!fileDownloadSrc" @click="$bvModal.show('modal-aprobSol-' + fileKey)" tabindex=""><Badge
                        v-if="loadedFile" type="badge-accent_2" text="Ver Archivo"></Badge></a>
                <a v-if="fileDownloadSrc" @click="getSignedUrl()" tabindex="">
                    <Badge v-if="loadedFile" type="badge-accent_2" text="Descargar Archivo"></Badge>
                </a>
            </span>
            <span>
                <button v-if="loadedFile && !hideTrashCan" @click="deleteFile(fileKey)" id="" type="button" name=""
                    class="button-circle xs button-ghost button-placeholder"><i
                    aria-hidden="true" class="banana banana-delete"></i></button>
                <div v-if="!loadedFile" class="tooltipHelp">
                    <i aria-hidden="true" :id="tooltipId" class="banana banana-question-circle1"></i>
                    <b-tooltip :target="tooltipId" triggers="hover" placement="top" custom-class="white-tooltip">
                        <slot name="tooltipContent"></slot>
                    </b-tooltip>
                </div>
            </span>
        </div>

        <ModalDefault
                :modalId="`modal-aprobSol-${fileKey}`"
                :customRef="customRef"
                size="lg"
                bodyClass="p-0"
                title="Visor de archivos">

            <div class="bgm-gray p-r-15 border-top-10 border-bottom-10">
                <div class="col-12 y-scroll o-auto p-50 max-h-410px">
                    <div class="w-100">
                        <!-- Add style "min-width: 999px;" to the image for the resize -->
                        <embed :src="fileViewSrc" :type="fileViewType" width="625px" height="410px"/>
                    </div>
                </div>

            </div>

            <template v-slot:customFooter>
                <div class="d-flex w-100 justify-space-between align-item-c">
                    <div class="vertical-center">
                        <button type="button" class="button xs button-ghost button-plain_text m-r-10"
                                data-dismiss="modal" @click="onClose()"> Cerrar
                        </button>
                    </div>
                </div>
            </template>
        </ModalDefault>
    </div>
</template>

<script>


    import LoadingSimple from "@/components/LoadingSimple.vue";
    import ModalDefault from "@/components/ModalDefault.vue";
    import Badge from "@/components/Badge.vue";
    import {EventBus} from '@/main';
    import fileApi from "@/api/files/files.api";

    export default {
        name: 'UploadFile',
        props: {
            inputId: {type: String, default: 'upload-file-input'},
            clazz: String,
            icon: String,
            title: String,
            text: String,
            note: String,
            tooltipId: String,
            fileKey: String,
            fileByKey: {},
            disabled: { type: Boolean, default: false},
            hideTrashCan: { type: Boolean, default: false}
        },
        components: {
            LoadingSimple,
            ModalDefault,
            Badge
        },
        data() {
            return {
                // Progress Bar
                loadingFile: false,
//                loadedFile: false,
                valueProgressBar: 80,
                maxProgressBar: 100,
                downloadFile: false,
                customRef: "modalVisor"
            }
        },
        computed: {

            fileViewSrc() {
                return this.fileByKey ? this.fileByKey.data : "";
            },
            fileViewType() {
                return this.fileByKey ? this.fileByKey.type : "";
            },
            fileDownloadSrc() {
                return this.fileByKey && this.fileByKey._id ? this.fileByKey._id : "";
            },
            loadedFile() {
                return this.fileByKey
            }
        },
        methods: {
            loadFile(ev) {
                this.$emit("loadFile", ev.target.files[0], this.$props.fileKey);

            },
            deleteFile(fileKey) {
                this.$emit("deleteFile", fileKey);
            },
            onClose() {
                EventBus.$emit(`MODAL-CLOSE-${this.customRef}`)
            },
            getSignedUrl(){
                fileApi.getSignedUrlFile({id: this.fileDownloadSrc}, (response) => {
                    if (response.data.error) {
                        this.displayNotificationInfo("Ocurrio un error al intentar descargar el documento");
                    } else {
                        if (response.data.object !== undefined) {
                            const link = document.createElement("a");
                            const url = response.data.object;
                            link.setAttribute("href", url);
                            link.setAttribute("download", name);
                            link.setAttribute("_target", "_blank");
                            link.click();
                        }
                    }
                }, () => {
                    this.displayNotificationInfo("Ocurrio un error al intentar descargar el documento");
                });
            }
        },
        mounted() {
            setTimeout(() => {

                EventBus.$on(`loadingFile${this.fileKey}`, (value) => {
                    this.loadingFile = value
                });
            }, 2000);
        },
        beforeDestroy() {
            EventBus.$off(`loadingFile${this.fileKey}`);
        }
    }
</script>

<style lang="scss">
    .upload-file-container {
        width: 100%;
    }

    .upload-file-component {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .square {
            position: relative;
            width: 70px;
            min-width: 70px;
            height: 70px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 30px;
            transition: all 0.5s ease-in-out;

            &:hover {
                .defaultIcon {
                    opacity: 0;
                }

                .iconAdd {
                    opacity: 1;
                    animation: spin 1s ease-in-out;
                }
            }
        }

        .info {
            width: 100%;
            padding-right: 40px;
        }

        .defaultIcon,
        .iconAdd {
            font-size: 40px;
            max-width: fit-content;
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: fit-content;
            transition: 0.3s ease-in-out;
        }

        .loading-info {
            > .loading {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .small-load {
                #load {
                    width: 40px;
                }
            }
        }

        .defaultIcon {
            opacity: 1;
        }

        .iconAdd {
            opacity: 0;
        }

        .title {
            font-size: 17px;
            font-weight: 600;
            line-height: 1.18;
            display: block;
            margin-bottom: 4px;
        }

        .type {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.14;
            display: block;
            margin-bottom: 4px;
        }

        .note {
            font-size: 11px;
            font-weight: normal;
            line-height: 1.64;
            display: block;
            margin-bottom: 0;
        }

        .tooltipHelp {
            font-size: 14px;
            position: absolute;
            top: 0;
            right: 0;
        }

        &.loading {
            .square {
                cursor: not-allowed;

                .iconAdd {
                    display: none !important;
                    opacity: 0 !important;
                }

                .input-file {
                    z-index: -1;
                }
            }
        }

        &.loaded {
            .square {
                cursor: not-allowed;

                .iconAdd {
                    display: none !important;
                    opacity: 0 !important;
                }

                .defaultIcon {
                    opacity: 1;
                }

                .input-file {
                    z-index: -1;
                }
            }
        }
    }
</style>
