<template>
    <div>
        <SignUpOrganizationBody stepFour>
            <template v-slot:formSectionBody>

                <GenericTitle id="dct-orgStepThree" clazz="p-t-20" title="Personaliza tu " titleStrong="Organización"
                              subtitle="Sube tu logotipo y complementalo con la imagen de portada. Si no tienes aún puedes usar los que hemos hecho para ti. ">

                </GenericTitle>

                <b-alert class="alert-note" show dismissible variant="danger" v-if="getErrorNextStep">
                    <p class="text-container"><img class="img-fluid"
                                                   src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                   alt=""/> {{getErrorNextStepMessage}} </p>
                </b-alert>
                <div class="row">
                    <UploadLogo></UploadLogo>
                    <UploadCover></UploadCover>
                </div>
            </template>
        </SignUpOrganizationBody>


    </div>
</template>

<script>
    // @ is an alias to /src
    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue';
    import GenericTitle from '@/components/GenericTitle.vue';
    import UploadLogo from './UploadLogo';
    import UploadCover from './UploadCover';
    import {mapGetters, mapState} from 'vuex';

    const storeModule = "signUpOrganization";

    export default {
        name: "StepLogo",
        props: {
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false}
        },
        data() {
            return {}
        },
        components: {
            SignUpOrganizationBody,
            GenericTitle,
            UploadLogo,
            UploadCover,
        },
        computed: {
            ...mapGetters(storeModule, ['getPotentialOrganization', 'getIsMexican', 'getFormStep', 'getErrorNextStep', 'getErrorNextStepMessage']),
            ...mapState({
                user: state => state[storeModule].actualPotentialOrganization.user,
                idPotentialOrganization: state => state[storeModule].actualPotentialOrganization.idPotentialOrganization

            }),
            firstLetterDemo() {
                return this.getPotentialOrganization.name.substring(0, 1);
            }

        },
    };
</script>
