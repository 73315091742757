<template>
    <div class="m-0-auto-0-0">
        <BaseButton
                type="button"
                :_class="_class"
                :id="id"
                :shape="shape"
                :kind="kind"
                :color="color"
                :size="size"
                :text="text"
                :icon="icon"
                @click="onClick()"
        >
            <BaseInputFile
                    :ref="_ref"
                    :id="inputId"
                    _class="d-none"
                    :_ref="_ref"
                    :accept="accept"
                    @change="onChange($event)"
            >
            </BaseInputFile>
        </BaseButton>
        <ButtonModalOpen
                :id="progressModalButtonId"
                text=""
                :modal-id="progressModalId"
                _class="d-none"
        >
        </ButtonModalOpen>
        <BaseModalWrapper
                :id="progressModalId"
        >
            <div class="modal-header p-t-20 p-b-0">
                <button :id="progressModalCloseButtonId" type="button" class="close" data-dismiss="modal"
                        aria-label="Close">
                    <i aria-hidden="true" class="banana banana banana-cross-normal c-placeholder "></i>
                </button>
            </div>

            <b-modal id="modal-fullUpload" ref="modal-fullUpload" hide-header hide-footer no-close-on-esc
                     no-close-on-backdrop hide-header-close>
                <div class="p-20">
                    <ProgressCircle filename="DSC10298.jpg" filesize="300 KB" v-if="loadingImage">
                        <radial-progress-bar :diameter="diam" :totalSteps="totalPrct" :completedSteps="completedPrct"
                                             startColor="#c16bf0" stopColor="#6992ea" innerStrokeColor="#FFF"
                                             :strokeWidth="strokeW" timingFunc="ease-in-out"><p class="percentage">
                            <strong>59</strong>%</p></radial-progress-bar>
                    </ProgressCircle>
                </div>
            </b-modal>

        </BaseModalWrapper>
    </div>
</template>

<script>
    import ButtonModalOpen from '@/components/signUpOrganizations/buttons/ButtonModalOpen';
    import BaseButton from '@/components/signUpOrganizations/base/BaseButton';
    import BaseInputFile from '@/components/signUpOrganizations/base/input/BaseInputFile';
    import BaseModalWrapper from '@/components/signUpOrganizations/base/modal/BaseModalWrapper';
    // import ProgressBar from '@/components/utils/ProgressBar';
    import RadialProgressBar from 'vue-radial-progress'
    import ProgressCircle from '@/components/ProgressCircle'

    import BUTTONS from '@/common/consts/buttons.consts';
    import ICONS from '@/common/consts/icons.consts'

    import $ from 'jquery';

    export default {
        data() {
            return {
                BUTTONS,
                ICONS,
                loaded: false,
                fileName: 'file',
                fileSize: 0,
                startProgressBar: false,
                loadingImage: false,
                diam: 120,
                totalPrct: 100,
                completedPrct: 0,
                strokeW: 4
            }
        },
        components: {
            ButtonModalOpen,
            BaseButton,
            BaseInputFile,
            BaseModalWrapper,
            RadialProgressBar,
            ProgressCircle
            // ProgressBar
        },
        methods: {
            onClick() {
                $(`#${this.inputId}`).click();
                this.startProgressBar = false;
                this.$emit('click');
            },
            onChange(refs) {
                let files = null;
                this.completedPrct = 2;

                if (refs[this._ref].files && refs[this._ref].files.length) {
                    files = refs[this._ref].files;
                } else {
                    return;
                }

                this.fileName = files[0].name;
                this.fileSize = files[0].size;

                if (!this.isCoverUpload) {
                    this.startProgressBar = true;

                    // $(`#${this.progressModalButtonId}`).click();

                    // Simulate upload processing
                    // setTimeout(() => {
                    this.loadingImage = true;
                    // setTimeout(() => {
                    // $(`#${this.progressModalCloseButtonId}`).click();
                    this.$emit('change', files);
                    // }, 1500);
                    // }, 500);

                } else {
                    this.$emit('change', files);
                }
            },
            clearFiles() {
                this.$refs[this._ref].clear();
            }
        },
        computed: {
            inputId() {
                return `${this.id}_input_file`;
            },
            progressModalButtonId() {
                return `${this.id}_progress_modal_button`;
            },
            progressModalCloseButtonId() {
                return `${this.id}_progress_modal_close_button`;
            },
            progressModalId() {
                return `${this.id}_progress_modal`;
            },
        },
        props: {
            text: {
                type: String,
                required: true
            },
            id: {
                type: String,
            },
            _ref: {
                type: String,
                default: "file_upload_button_ref",
            },
            accept: {
                type: String,
            },

            _class: {
                type: String,
                default: 'float-right',
            },

            shape: {
                type: String,
                default: BUTTONS.SHAPES.BUTTON,
            },
            kind: {
                type: String,
                default: BUTTONS.KINDS.STROKE,
            },
            color: {
                type: String,
                default: BUTTONS.COLORS.ACCENT2,
            },
            size: {
                type: String,
                default: BUTTONS.SIZES.EXTRA_SMALL,
            },
            icon: {
                type: String,
                default: ICONS.PLUS,
            },
            isCoverUpload: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
