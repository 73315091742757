<template>
  <div class="loader-container" v-bind:class="clazz">
    <div class="center-both">
      <div class="infinite-load m-auto">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <p>{{loadingText}}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingBar",
  props: {
    clazz: String,
    loadingText: String,

  }
};
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
.loader-container {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    position: absolute;
    p {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        margin-top: 25px;
        margin-bottom: 0;
        text-align: center;
    }
}

.infinite-load {
    width: 160px;
    height: 8px;
    border-radius: 5px;
    position: relative;
    display: flex;

    .line {
        margin: 0 2px;
        display: inline-block;
        width: 15px;
        height: 8px;
        border-radius: 5px;

        &:nth-last-child(1) {
            animation: loadingBarInfinite 2.5s 1s infinite;
        }

        &:nth-last-child(2) {
            animation: loadingBarInfinite 2.5s .5s infinite;
        }

        &:nth-last-child(3) {
            animation: loadingBarInfinite 2.5s 0s infinite;
        }

    }
}
</style>
