import axios from 'axios'
const namespace = "webhooks";

export default {
    create: (webhook, onSuccess, onError) => {
        return axios
            .post(`${namespace}/create`, {webhook})
            .then(onSuccess)
            .catch(onError);
    },
    delete: (id, onSuccess, onError) => {
        return axios
            .delete(`${namespace}/delete/${id}`)
            .then(onSuccess)
            .catch(onError);
    },
    edit: (id, webhook, onSuccess, onError) => {
        return axios
            .post(`${namespace}/edit/${id}`, {webhook})
            .then(onSuccess)
            .catch(onError);
    },
    search: ({page = 1, size = 10}, onSuccess, onError) => {
        return axios
            .post(`${namespace}/search`, {page, size})
            .then(onSuccess)
            .catch(onError);
    },
    searchById: (id, onSuccess, onError) => {
        return axios
            .get(`${namespace}/search/${id}`)
            .then(onSuccess)
            .catch(onError);
    }
}
