<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout
                    :msgSmall="$t('chargebacks.list.control-panel')"
                    :title="$t('chargebacks.list.chargebacks')"
            ></FloatingTitleLayout>
        </div>
        <div class="col-12">
            <b-tabs
                    class="simple-tabs group-tabs"
                    content-class="simple-tabs-body p-t-10">
                <b-tab active @click="changeTabStatus('ACTIVE')">
                    <template v-slot:title>
                        <div class="d-flex align-items-c">
                            <span class="m-r-5">
                                {{$t('chargebacks.list.active')}}
                            </span>
                            <Badge
                                    type="badge-accent_2"
                                    :text="stats.activeCount | toString"
                            ></Badge>
                        </div>
                    </template>
                    <ChargebackTable :status="currentTab" :chargebacks-list="chargebackList" :loading="loading"
                                     :total="total" :per-page="perPage" :current-page="currentPage" :current-organization="currentOrganization"
                                     @pageChange="pageChange"></ChargebackTable>
                </b-tab>
                <b-tab @click="changeTabStatus('INACTIVE')">
                    <template v-slot:title>
                        <div class="d-flex align-items-c">
                            <span class="m-r-5">
                                {{$t('chargebacks.list.inactive')}}
                            </span>
                            <Badge
                                    type="badge-accent_2"
                                    :text="stats.inactiveCount | toString"
                            ></Badge>
                        </div>
                    </template>
                    <ChargebackTable :status="currentTab" :chargebacks-list="chargebackList" :loading="loading"
                                     :total="total" :per-page="perPage" :current-page="currentPage" :current-organization="currentOrganization"
                                     :active-table="false" @pageChange="pageChange"></ChargebackTable>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import Badge from "@/components/Badge.vue";
    import ChargebackTable from "@/views/chargebacks/ChargebackTable.vue";
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {mapGetters} from 'vuex';
    import {EventBus} from "@/main";

    import {
        STORE as ChargebackStore,
        GET_CURRENT_TAB,
        GET_STATS
    } from '@/store/modules/chargebacks/chargebacks.types';
    import {
        STORE as AccessConfigStore,
        GET_CURRENT_ORGANIZATION,
    } from '@/store/modules/accessconfig/accessConfig.types';

    import catalog from '@/mixins/catalog.mixin';
    import {
        GET_DOCS,
        GET_DOCS_LENGTH,
        GET_CURRENT_PAGE,
        GET_OBJECT_PAGE,
        SET_CURRENT_PAGE,
        SET_SORT,
        SET_LOADING_ITEMS,
        GET_LOADING_ITEMS,
        GET_FILTERS_COUNT,
        SET_TYPE_SORT,

    } from '@/store/modules/base/catalog.types';

    import {
        SUCCESS_SAVE,
        ERROR_SAVING,
        ERROR_DELETING,
        SUCCESS_DELETE
    } from '@/store/events';

    const ChargebackCatalog = catalog.configure({
        storeModule: ChargebackStore,
        docName: "ChargebackList",
        manualLoad: true
    });

    export default {
        name: 'ChargebacksList',
        data() {
            return {}
        },
        computed: {
            ...mapGetters(ChargebackStore, {
                chargebackList: GET_DOCS,
                loading: GET_LOADING_ITEMS,
                getFiltersCount: GET_FILTERS_COUNT,
                currentTab: GET_CURRENT_TAB,
                total: GET_DOCS_LENGTH,
                currentPage: GET_CURRENT_PAGE,
                perPage: GET_OBJECT_PAGE,
                stats: GET_STATS
            }),
            ...mapGetters(AccessConfigStore, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
        },
        components: {
            FloatingTitleLayout,
            Badge,
            ChargebackTable
        },
        methods: {
            changeTabStatus(status) {
                this.$store.commit(`${ChargebackStore}/${SET_CURRENT_PAGE}`, 1);
                this.$store.dispatch(`${ChargebackStore}/selectTab`, {
                    status,
                    organizationId: this.currentOrganization._id
                });
            }
        },
        filters: {
            toString: function (value) {
                if (!value) {
                    return "0"
                }
                return value.toString();
            }
        },
        mixins: [ToastSnotify, ChargebackCatalog],
        mounted() {
            this.$store.commit(`${ChargebackStore}/${SET_LOADING_ITEMS}`, true);
            this.$store.commit(`${ChargebackStore}/${SET_SORT}`, "min");
            this.$store.commit(`${ChargebackStore}/${SET_TYPE_SORT}`, "desc");
            this.$store.dispatch(`${ChargebackStore}/list`, this.currentOrganization._id).then(() => {
            }).catch((error) => {
                console.error(error);
            });
            this.$store.dispatch(`${ChargebackStore}/statsChargebacks`, this.currentOrganization._id);
        },
        beforeDestroy() {
            EventBus.$off(ChargebackStore + SUCCESS_DELETE)
            EventBus.$off(ChargebackStore + SUCCESS_SAVE)
            EventBus.$off(ChargebackStore + ERROR_DELETING)
            EventBus.$off(ChargebackStore + ERROR_SAVING);
        },
        created() {
            const _this = this;
            EventBus.$on(ChargebackStore + SUCCESS_DELETE, (message) => {
                _this.displayNotificationSuccess(message);
            });
            EventBus.$on(ChargebackStore + SUCCESS_SAVE, (message) => {
                _this.displayNotificationSuccess(message);
            });

            const errorHandler = function (message) {
                _this.displayNotificationError(message);
                _this.$refs(this.modalDeleteId).hideModal();
                _this.cancelDelete();
            }

            EventBus.$on(ChargebackStore + ERROR_DELETING, errorHandler);

            EventBus.$on(ChargebackStore + ERROR_SAVING, errorHandler);

        }
    }
</script>
