import SignIn from "@/views/signin/SignIn.vue";
import SignInTemplate from "@/pages/signin/SigninTemplate.vue";
import SigninAfterLoginSocialMedia from "@/views/signin/SigninAfterLoginSocialMediaGoogle.vue";
import RecoveryEmailBySMS from "@/views/signin/RecoveryEmailBySMS.vue";
import SigninAfterLoginSocialMediaFace from "@/views/signin/SigninAfterLoginSocialMediaFace.vue";
import OrganizationsDeactivated from "@/views/signin/OrganizationsDeactivatedError.vue";
import RecoveryPassword from "@/views/signin/RecoveryPassword.vue";
import RecoveryEmail from "@/views/signin/RecoveryEmail.vue";
import NewPassword from "@/views/signin/NewPassword.vue";
import RecoveryPasswordSuccess from "@/views/signin/RecoveryPasswordSuccess.vue";


export default [
    {
        path: "/",
        component: SignInTemplate,
        children: [
            {
                path: "/",
                name: "Signin",
                component: SignIn
            },
            {
                path: "auth-google-callback",
                name: "SigninSocialMedia",
                component: SigninAfterLoginSocialMedia
            },
            {
                path: "auth-facebook-callback/:token",
                name: "SigninSocialMediaFacebook",
                component: SigninAfterLoginSocialMediaFace
            },
            {
                path: 'recovery-email',
                name: 'SigninRecoveryEmail',
                component: RecoveryEmail,
                props: true
            },
            {
                path: 'password-recovery/set-new/:token',
                name: 'SigninNewPassword',
                component: NewPassword
            },
            {
                path: 'password-recovery/set-new/wallet/:token',
                name: 'SigninNewPasswordWallet',
                component: NewPassword
            },
            {
                path: 'password-recovery',
                name: 'SigninPasswordRecovery',
                component: RecoveryPassword,
            },
            {
                path: 'password-recovery/success',
                name: 'PasswordRecoverySuccess',
                component: RecoveryPasswordSuccess
            },
            {
                path: 'user-recovery',
                name: 'RecoveryEmailBySMS',
                component: RecoveryEmailBySMS
            },
            {
                path: 'recovery-email',
                name: 'SigninRecoveryEmail',
                component: RecoveryEmail,
                props: true

            }
        ]
    },
    {
        path: "organizations-deactivated",
        name: "OrganizationsDeactivated",
        component: OrganizationsDeactivated
    }
]
