export default {
    id: function (value) {
        return value.match(/^[_a-z]+$/);
    },
    enum: function (enumValues = []) {
        return function (value) {
            return enumValues.indexOf(value) !== -1;
        }
    }
}
