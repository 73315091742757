<template>
    <CardSimple
        :title="$t('organizaciones.bank-data.title')"
        :subtitle="$t('organizaciones.bank-data.subtitle')"
    >
        <div class="row">
            <LoadingBar
                v-if="loading"
                :loadingText="$t('general.info.loading')"
            ></LoadingBar>
            <div
                class="col-12"
                v-if="!editingBankInfo"
            >
                <SimpleTimelineInfo>
                    <SimpleTimelineInfoDot
                        :label="$t('organizaciones.bank-data.bank.label')"
                        :content="actualBankData.bankId ? actualBankData.bankId.name : ''"
                    ></SimpleTimelineInfoDot>
                    <SimpleTimelineInfoDot
                        :label="$t('organizaciones.bank-data.account.label')"
                        :content="actualBankData.accountNumber"
                    ></SimpleTimelineInfoDot>
                    <SimpleTimelineInfoDot
                        :label="$t('organizaciones.bank-data.clabe.label')"
                        :content="actualBankData.clabe"
                    ></SimpleTimelineInfoDot>
                </SimpleTimelineInfo>
            </div>
            <div
                class="col-12 m-b-40"
                v-if="!editingBankInfo"
            >
                <b-alert
                    class="alert-note m-b-30"
                    show
                    variant="warning"
                >
                    <p class="text-container">
                        <img
                            class="img-fluid"
                            src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                            alt="warning"
                        />
                        <span v-html="$t('organizaciones.bank-data.edit-warning')"></span>
                    </p>
                </b-alert>
            </div>


            <div class="title-card p-l-15" v-if="!editingBankInfo">
                <h1 class="f-25">
                    {{$t(`organizaciones.bank-data.commissions.header`)}}
                    <label> {{$t(`organizaciones.bank-data.commissions.sub-header`)}} </label>
                </h1>
            </div>

            <template v-if="!editingBankInfo && actualBankData.bankCommissions">
                <div
                    class="col-12 title-card m-b-0"
                    v-if="actualBankData.unfairChargebackPayment !== null && actualBankData.unfairChargebackPayment !== undefined"
                >
                    <h1 class="f-20 c-primary_1 principal-font-bold d-block m-b-30">
                        {{$t(`organizaciones.bank-data.product-type.unfairChargebackPayment`)}}
                        <label>
                            {{actualBankData.unfairChargebackPayment | currency}} MXN
                        </label>
                    </h1>
                </div>
                <div
                    class="col-12"
                    v-for="productType in bankCommissionsProducts"
                    :key="productType"
                >
                    <h1 class="f-20 c-primary_1 principal-font-bold d-block m-b-30">
                        {{$t(`organizaciones.bank-data.product-type.${productType}`)}}
                    </h1>
                    <div class="panel-mini-info">
                        <PanelMiniInfo
                            v-for="(commission, i) in actualBankData.bankCommissions.filter((c) => c.productType === productType)" :key="i"
                            :title="$t(`organizaciones.bank-data.${commission.cardBrand}`)"
                        >
                            <template v-if="commission.commisionPercent"> {{commission.commisionPercent}}% + </template>
                            <template v-if="commission.commisionAmount"> {{commission.commisionAmount | currency}} + </template>
                            IVA
                        </PanelMiniInfo>
                    </div>
                </div>
            </template>

            <div
                class="col-12 m-t-40 vertical-center justify-content-end"
                v-if="canUpdateBankInfo && !editingBankInfo && $can('do', 'BANK_INFO_ORGANIZATION_EDIT')"
            >
                <button
                    @click="startEditBankInfo"
                    type="button"
                    class="button sm button-stroke button-accent2"
                >{{$t('organizaciones.bank-data.edit')}}</button>
            </div>
            <div
                class="col-12 col-md-6"
                v-if="editingBankInfo"
            >
                <BasicSelect
                    required
                    :label="$t('organizaciones.bank-data.bank.label')"
                    labelFor="banco"
                >
                    <multiselect
                        v-model="editingBankInfoForm.bankId"
                        :options="banks"
                        :close-on-select="true"
                        :preserve-search="true"
                        :placeholder="$t('organizaciones.bank-data.bank.input-place-holder')"
                        label="name"
                        track-by="name"
                        :preselect-first="false"
                    >
                    </multiselect>
                </BasicSelect>
            </div>
            <div
                class="col-12 col-md-6"
                v-if="editingBankInfo"
            >
                <BasicInput
                    v-model="editingBankInfoForm.accountNumber"
                    required
                    inputType="text"
                    labelFor="numCuenta"
                    inputId="numCuenta"
                    :label="$t('organizaciones.bank-data.account.label')"
                    :validations="$v.editingBankInfoForm.accountNumber"
                    validationsNamespace="general.validations"
                    :inputPlaceholder="$t('organizaciones.bank-data.bank.input-place-holder')"
                >
                </BasicInput>
            </div>
            <div
                class="col-12 col-md-6"
                v-if="editingBankInfo"
            >
                <BasicInput
                    v-model="editingBankInfoForm.clabe"
                    required
                    inputType="text"
                    labelFor="numClabe"
                    inputId="numClabe"
                    :validations="$v.editingBankInfoForm.clabe"
                    validationsNamespace="general.validations"
                    :label="$t('organizaciones.bank-data.clabe.label')"
                    :inputPlaceholder="$t('organizaciones.bank-data.clabe.input-place-holder')"
                >
                </BasicInput>
            </div>
            <div v-if="editingBankInfo"
                class="col-12 vertical-center justify-content-end m-t-40">
                <span class="f-14 c-info_text m-0-auto-0-0"><i aria-hidden="true" class="c-error">*</i> {{$t('general.required-fields')}}</span>
                <div class="vertical-center">
                    <button
                        :disabled="loading"
                        @click="cancel"
                        type=""
                        class="button xs button-ghost button-plain_text"
                    >{{$t('general.actions.cancel.title')}}</button>
                    <button
                        :disabled="loading || hasError"
                        @click="updateBankInfo"
                        type="button"
                        class="button sm button-filled button-accent1 m-l-10"
                    >{{$t('general.actions.save-changes')}}</button>
                </div>
            </div>
        </div>
    </CardSimple>
</template>
<script>
    import {
        GET_BANK_INFO,
        UPDATE_BANK_DATA,
        STORE,
        GET_BILLING_DATA,
        FETCH_BANK_DATA,
        FETCH_CAN_UPDATE_BANK_INFO,
        GET_CAN_UPDATE_BANK_INFO
    } from "@/store/modules/organization/organization.types";
import { mapGetters } from 'vuex';
import CardSimple from '@/components/CardSimple.vue';
import SimpleTimelineInfo from '@/components/SimpleTimelineInfo.vue';
import SimpleTimelineInfoDot from '@/components/SimpleTimelineInfoDot.vue';
import { utils } from '@/api/utils';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import { STORE as BANKS_STORE, GET_BANKS } from "@/store/modules/banks/banks.types";
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import BasicInput from '@/components/BasicInput.vue';
import BasicSelect from '@/components/BasicSelect.vue';
import Multiselect from 'vue-multiselect';
import LoadingBar from '@/components/LoadingBar.vue';
import PanelMiniInfo from '@/components/PanelMiniInfo.vue'

export default {
    components: {
        CardSimple,
        SimpleTimelineInfo,
        SimpleTimelineInfoDot,
        BasicInput,
        BasicSelect,
        Multiselect,
        LoadingBar,
        PanelMiniInfo
    },
    mounted () {
        this.getBankInfo();
    },
    data () {
        return {
            loading: false,
            editingBankInfo: false,
            editingBankInfoForm: {
                bankId: '',
                accountNumber: '',
                clabe: '',
            },
            file: '',
        }
    },
    methods: {
        startEditBankInfo () {
            this.editingBankInfo = true;
            this.editingBankInfoForm = JSON.parse(JSON.stringify(this.actualBankData));
            delete this.editingBankInfoForm.clabe;
            delete this.editingBankInfoForm.accountNumber;
            if (this.editingBankInfoForm.bankId && this.editingBankInfoForm.bankId._id) {
                this.editingBankInfoForm.bankId = {
                    _id: this.editingBankInfoForm.bankId._id,
                    name: this.editingBankInfoForm.bankId.name,
                    code: this.editingBankInfoForm.bankId.code
                };
            }
        },
        getBankInfo () {
            this.$store.dispatch(`${STORE}/${FETCH_BANK_DATA}`, this.currentOrganization._id).then(() => {
                this.loading = false;
                this.$store.dispatch(`${STORE}/${FETCH_CAN_UPDATE_BANK_INFO}`, this.currentOrganization._id);
            });
        },
        updateBankInfo () {
            const bankCover = this.file;
            const dataToSend = JSON.parse(JSON.stringify(this.editingBankInfoForm));

            if (dataToSend.bankId && dataToSend.bankId._id) {
                dataToSend.bankId = dataToSend.bankId._id;
            }
            dataToSend.bankCover = bankCover;
            this.loading = true;
            this.$store.dispatch(`${STORE}/${UPDATE_BANK_DATA}`, { organization: this.currentOrganization, params: dataToSend })
                .then(() => {
                    this.$store.dispatch(`${STORE}/${FETCH_BANK_DATA}`, this.currentOrganization._id);
                    this.$store.dispatch(`${STORE}/${FETCH_CAN_UPDATE_BANK_INFO}`, this.currentOrganization._id);
                    utils.toast(this.$snotify, 'success', this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                    this.editingBankInfo = false;
                }).catch((error) => {
                    utils.toast(this.$snotify, 'error', this.$t('general.actions.error.title'), error.response.data.message || this.$t('general.actions.error.text'));
                    this.loading = false;
                });
        }, cancel () {
            this.editingBankInfo = false;
            utils.toast(this.$snotify, 'info', this.$t('general.actions.cancel.title'), this.$t('general.actions.cancel.text'));
            document.getElementById('file').value = null;
            this.file = null;
        }
    },
    computed: {
        hasError () { return this.$v.$invalid },
        ...mapGetters(STORE, {
            actualBankData: GET_BANK_INFO,
            getBillingData: GET_BILLING_DATA,
            canUpdateBankInfo: GET_CAN_UPDATE_BANK_INFO
        }),
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
        ...mapGetters(BANKS_STORE, {
            banks: GET_BANKS
        }),
        bankCommissionsProducts() {
            const products = [];
            this.actualBankData.bankCommissions.forEach((c) => {
                if (!products.includes(c.productType) && c.productType !== "PAYMENT") {
                    products.push(c.productType);
                }
            });
            return products;
        }
    },
    watch: {
        currentOrganization () {
            if (this.currentOrganization) {
                this.loading = true;
                this.getBankInfo();
            }
        }
    },
    validations: {
        editingBankInfoForm: {
            bankId: {
                required,
            },
            accountNumber: {
                required,
                numeric,
                maxLength: maxLength(11),
                minLength: minLength(10)
            },
            clabe: {
                required,
                numeric,
                maxLength: maxLength(18),
                minLength: minLength(18),
            }
        }
    },
}
</script>
