<template>
    <div :class="clazz">
        <form-wizard ref="wizard" shape="circle" stepSize="xs" color="#557cf1"
                     v-bind:class="[activeVertical && verticalWizard ? 'vertical': 'horizontal']">
            <slot name="wizardView">
            </slot>

            <!-- To comunicate with the original lib, this slot needs to be here, inside our component 'WizardForm' -->
            <template slot="footer" slot-scope="props">
                <div class="footer-content">
                    <div class="wizard-footer-left">

                        <div v-if="props.activeTabIndex == 0 && getActiveTabInStepOne !== 'SIGN_IN'">

                            <wizard-button
                                v-if="getActiveTabInStepOne !== 'PHONE_VERIFICATION' && getActiveTabInStepOne !== 'SECURITY_MEASURES'"
                                @click.native="redirectClient" class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{actualUser.profile === "USER" ?
                                $t('general.actions.logout.title') : $t('general.actions.cancel.title')}}
                            </wizard-button>
                            <wizard-button
                                v-if="getActiveTabInStepOne === 'PHONE_VERIFICATION' || getActiveTabInStepOne === 'SECURITY_MEASURES'"
                                @click.native="backStepOne" class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{actualUser.profile === "USER" ?
                                $t('general.actions.logout.title') : $t('general.actions.back.title')}}
                            </wizard-button>
                        </div>

                        <div v-if="props.activeTabIndex == 1">
                            <wizard-button @click.native="getOnboardingFlag ? redirectClient() : backToOnboarding()"
                                class="button sm button-ghost button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                {{actualUser.profile === "USER" && getOnboardingFlag ? $t('general.actions.logout.title')
                                : actualUser.profile === "USER" && !getOnboardingFlag ? $t('general.actions.back.title')
                                : $t('general.actions.cancel.title')}}
                            </wizard-button>
                        </div>

                        <div v-if="props.activeTabIndex == 2">
                            <wizard-button @click.native="backToOperationSchemeStep(props)"
                                class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('general.actions.cancel.title')}}
                            </wizard-button>
                        </div>

                        <div v-if="props.activeTabIndex == 3">
                            <wizard-button @click.native="props.prevTab()"
                                class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('general.actions.back.title')}}
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 4">
                            <wizard-button @click.native="props.prevTab()"
                                class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('general.actions.back.title')}}
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 5">
                            <wizard-button v-if="props.activeTabIndex > 0 && !getShowBackButtonForm"
                                @click.native="props.prevTab()"
                                class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('general.actions.back.title')}}
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 6">
                            <wizard-button v-if="props.activeTabIndex > 0 && !getShowBackButtonForm"
                                @click.native="props.prevTab()"
                                class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('general.actions.back.title')}}
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 7">
                            <wizard-button v-if="props.activeTabIndex > 0 && !getShowBackButtonForm"
                                @click.native="props.prevTab()"
                                class="button sm button-ghost button-plain_text"><i
                                aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('general.actions.back.title')}}
                            </wizard-button>
                        </div>
                    </div>
                    <div class="wizard-footer-right">
                        <div v-if="loading">
                            <button type="button" class="button sm button-filled button-accent1">
                                <LoadingSimple clazz="small-load no-text"/>
                            </button>
                        </div>

                        <div v-if="!loading">
                            <!--Button to save user personal information-->
                            <div v-if="props.activeTabIndex == 0 && getActiveTabInStepOne !== 'SIGN_IN'">
                                <wizard-button
                                        id="btn-orgNextStep1"
                                        @click.native="continueStepOne(props)"
                                        class="button sm button-filled button-accent1"
                                        :disabled="isButtonDisabled"
                                >
                                    Continuar
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 1 && getOnboardingFlag">
                                <wizard-button
                                    id="btn-orgNextStep1"
                                    @click.native="goToSecondStep"
                                    class="button sm button-filled button-accent1"
                                >
                                    {{$t('general.actions.continue.title')}}
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 1 && isValidStepOne && !getOnboardingFlag">
                                <wizard-button
                                    id="btn-orgNextStep1"
                                    @click.native="saveStepOne(props)"
                                    class="button sm button-filled button-accent1"
                                >
                                    {{$t('general.actions.continue.title')}}
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 2 && isValidStepTwo">
                                <wizard-button
                                        id="btn-orgNextStep2"
                                        @click.native="saveStepTwo(props)"
                                        class="button sm button-filled button-accent1"
                                >
                                    {{$t('general.actions.continue.title')}}
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 3">
                                <wizard-button
                                        id="btn-orgNextStep3"
                                        @click.native="saveStepThree(props)"
                                        class="button sm button-filled button-accent1"
                                >
                                    {{$t('general.actions.continue.title')}}
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 4 && isValidStepFour">
                                <wizard-button
                                        id="btn-orgNextStep4"
                                        @click.native="saveStepFour(props)"
                                        class="button sm button-filled button-accent1"
                                >
                                    {{$t('general.actions.continue.title')}}
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 5 && isValidStepSix">
                                <wizard-button
                                        id="btn-orgNextToFinish"
                                        @click.native.prevent="saveOrganizationFileStep(props)"
                                        class="button sm button-filled button-accent1"
                                >
                                    {{$t('general.actions.continue.title')}}
                                </wizard-button>

                            </div>
                            <div v-if="props.activeTabIndex == 6 && isValidStepSeven">
                                <wizard-button
                                               @click.native.prevent="saveStepLocation(props);"
                                               class="button sm button-filled button-accent1">{{props.isLastStep ?
                                    $t('general.actions.finish.title') : $t('general.actions.continue.title')}}
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 7">
                                <wizard-button v-if="props.isLastStep"
                                               @click.native="saveStepFive(props.activeTabIndex);"
                                               class="button sm button-filled button-accent1">{{props.isLastStep ?
                                    $t('general.actions.finish.title') : $t('general.actions.continue.title')}}
                                </wizard-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- THIS PART SHOWS ON WIZARD + REGISTER -->
                <div class="footer-container"><small class="text-footer" v-html="$t('general.copyright', {year: currentYear})"></small></div>
            </template>
        </form-wizard>
    </div>
</template>

<script>
    const storeModule = "signUpOrganization";
    const LOAD_POT_ORG_DISPATCH = 'signUpOrganization/loadPotentialOrganization';
    import {mapGetters} from 'vuex';
    import {EventBus} from '@/main';
    import LoadingSimple from '@/components/LoadingSimple.vue';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {
        STORE as SESSION_STORE,
        GET_USER
    } from '@/store/modules/session/session.types';
    import {
        STORE as SignInStore
    } from '@/store/modules/signin/signin.types';

    export default {
        name: 'WizardFormOrganization',
        props: {
            clazz: String,
            activeVertical: {type: Boolean, default: false},
            originalFooter: {type: Boolean, default: false}
        },
        components: {
            LoadingSimple
        },
        data() {
            return {
                verticalWizard: false,
                window: {
                    width: 0
                },
                loading: false,
                isButtonDisabled: true,
                currentYear: 0
            }
        },
        mixins: [
            ToastSnotify
        ],
//        beforeCreate() {
//            this.$store.dispatch(`${storeModule}/getCurrentUserSession`);
//        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            EventBus.$on("NEXT_STEP_WIZARD", (actualStep, paramsStep) => {
                this.$store.dispatch(`${storeModule}/makeActionForStep`, {actualStep, paramsStep});
            });
        },
        mounted() {
            this.currentYear = new Date().getFullYear();
            EventBus.$on("REGISTER_USER_READY_STEP", () => {
                this.enableStepOneButton();
            });

            EventBus.$on("REGISTER_COMPLETE", () => {
                if (this.$refs && this.$refs.wizard && this.$refs.wizard.activeTabIndex === 0) {
                    this.$refs.wizard.nextTab();
                }
            });
        },
        beforeDestroy() {
            EventBus.$off("REGISTER_USER_READY_STEP");
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
                if (window.innerWidth < 992) {
                    this.verticalWizard = false;
                } else {
                    this.verticalWizard = true;
                }
            },
            saveUserPersonalInformation() {
                this.loading = true;
                this.$store.dispatch(`${storeModule}/savePersonalInformation`).then((result) => {
                    if (!result.error) {
                        this.displayNotificationSuccess(result.message);
                        this.loading = false;
                        const userId = this.actualUser._id;
                        this.$store.dispatch(LOAD_POT_ORG_DISPATCH, {userId})
                        this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                            step: 'personalInformation',
                            prop: 'isValid',
                            value: true
                        });
                    } else {
                        this.errorNextStep(result.error);
                    }
                }).catch((err) => {
                    this.errorNextStep(err.message);
                });

            },
            validatePhoneVerificationStep() {
                const isValid = this.getActualUser.phoneVerified;
                this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                    step: 'phoneVerification',
                    prop: 'isValid',
                    value: isValid
                });
            },
            returnPersonalInfoStep() {
                this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                    step: 'phoneVerification',
                    prop: 'isValid',
                    value: false
                });
                this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                    step: 'personalInformation',
                    prop: 'isValid',
                    value: false
                });
            },
            returnPhoneVerificationStep() {
                this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                    step: 'phoneVerification',
                    prop: 'isValid',
                    value: false
                });
            },
            saveWizardRegisterUser(props) {
                this.loading = true;
                this.$store.dispatch(`${storeModule}/confirmWizardRegisterUser`).then((result) => {
                    if (result.data.error) {
                        this.errorNextStep(result.error);
                    } else {
                        this.loading = false;
                        this.$store.dispatch(LOAD_POT_ORG_DISPATCH);
                        this.successNextStep(result.message, props);
                    }

                });
            },

            continueStepOne(props) {
                switch (this.getActiveTabInStepOne) {
                    case "PERSONAL_INFORMATION":
                        this.saveUserPersonalInformation(props);
                        break;
                    case "PHONE_VERIFICATION":
                        this.validatePhoneVerificationStep(props);
                        break;
                    case "SECURITY_MEASURES":
                        this.saveWizardRegisterUser(props);
                        break;
                    default:
                        this.saveUserPersonalInformation(props);
                        break;
                }
            },
            backStepOne(props) {
                switch (this.getActiveTabInStepOne) {
                    case "PHONE_VERIFICATION":
                        this.returnPersonalInfoStep(props);
                        break;
                    case "SECURITY_MEASURES":
                        this.returnPhoneVerificationStep(props);
                        break;
                    default:
                        this.returnPersonalInfoStep(props);
                        break;
                }
            },
            enableStepOneButton() {
                switch (this.getActiveTabInStepOne) {
                    case "PERSONAL_INFORMATION":
                        this.isButtonDisabled = !this.registerUserStepsStatus.personalInformation.isReady;
                        break;
                    case "PHONE_VERIFICATION":
                        this.isButtonDisabled = !this.registerUserStepsStatus.phoneVerification.isReady;
                        break;
                    case "SECURITY_MEASURES":
                        this.isButtonDisabled = !this.registerUserStepsStatus.securityMeasures.isReady;
                        break;
                    default:
                        this.isButtonDisabled = true;
                }
            },
            saveStepOne(props) {
                this.loading = true;
                this.$store.dispatch(`${storeModule}/saveStartStep`).then((result) => {
                    if (!result.error) {
                        this.$store.dispatch(`${storeModule}/fetchFileDocumentsRequested`);
                        this.successNextStep(result.message, props);
                    } else {
                        this.errorNextStep(result.error);
                    }
                }).catch((err) => {
                    this.errorNextStep(err.message);
                });
            },
            saveStepTwo(props) {
                this.loading = true;
                this.$store.dispatch(`${storeModule}/saveOperationSchemeStep`).then((result) => {
                    if (!result.error) {
                        const userId = this.actualUser._id;
                        this.$store.dispatch(LOAD_POT_ORG_DISPATCH, {userId});
                        EventBus.$emit('INIT_REQUESTED_FILES_STEP');
                        this.successNextStep(result.message, props);
                    } else {
                        this.errorNextStep(result.error.message);
                    }
                }).catch((err) => {
                    this.errorNextStep(err.message);
                });
            },
            saveStepThree(props) {
                this.successNextStep(this.$i18n.t('signup-organization.step-three.success-step'), props);
            },
            saveStepFour(props) {
                this.loading = true;
                this.$store.dispatch(`${storeModule}/saveBanking`).then((result) => {
                    if (!result.error) {
                        this.successNextStep(result.message, props);
                    } else {
                        this.errorNextStep(result.error);
                    }
                }).catch(() => {
                    this.errorNextStep(this.$i18n.t('signup-organization.step-four.error-step'));
                });
            },
            saveOrganizationFileStep(props) {
                this.loading = true;
                this.$store.dispatch(`${storeModule}/saveOrganizationFileStep`).then((result) => {

                    if (!result.error) {
                        this.successNextStep(result.message, props, true);
                    } else {
                        this.errorNextStep(result.message);
                    }
                }).catch((err) => {
                    this.errorNextStep(err.message);
                });
            },
            saveStepFive(props) {
                this.$store.dispatch(`${storeModule}/saveLastStep`).then((result) => {
                    if (!result.error) {
                        this.successNextStep(result.message, props, false);
                        this.$router.push('/digitalcontract');
                    } else {
                        this.errorNextStep(result.message);
                    }
                }).catch((err) => {
                    this.errorNextStep(err.message);
                });
            },
            saveStepLocation(props){
                this.loading = true;
                this.$store.dispatch(`${storeModule}/saveLocationStep`).then((res) => {
                    if (!res.error) {
                        this.successNextStep(res.message, props);
                    } else {
                        this.errorNextStep(res.message);
                    }
                }).catch((err) => {
                    this.errorNextStep(err.message);
                });
            },
            successNextStep(message, props, nextStep = true) {
                this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, false);
                this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, '');
                this.displayNotificationSuccess(message);
                this.loading = false;
                const step = props.activeTabIndex + 1;
                this.validateStep(step);
                if (nextStep) {
                    props.nextTab();
                }
            },
            goToSecondStep(){
                this.$store.commit(`${storeModule}/SET_ONBOARDING_FLAG`, false);
            },
            backToOnboarding(){
                this.$store.commit(`${storeModule}/SET_ONBOARDING_FLAG`, true);
            },
            errorNextStep(message) {
                this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, true);
                this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, message);
                this.loading = false;
            },
            validateStep(step) {
                let isValid = true;

                switch(step){
                    case 1:
                        isValid = this.getIsPersonalInformationComplete && this.getActualUser.phoneVerified &&
                            this.getActualUser.securityImageId && this.getActualUser.securityPhrase;
                        this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_ONE`, isValid);
                        break;
                    case 2:
                        isValid = this.getPotentialOrganization.category &&
                                this.getPotentialOrganization.description &&
                                this.getPotentialOrganization.name;
                        this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_ONE`, isValid);
                        break;
                    case 3:
                        isValid = this.getPotentialOrganization.invoiceData.streetAddress &&
                            this.getPotentialOrganization.invoiceData.exteriorNumber &&
                            this.getPotentialOrganization.invoiceData.district &&
                            this.getPotentialOrganization.invoiceData.city &&
                            this.getPotentialOrganization.invoiceData.state &&
                            this.getPotentialOrganization.invoiceData.zipCode
                        this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_TWO`, isValid);
                        break;
                    case 4:
                        isValid = this.getPotentialOrganization.bank &&
                            this.getPotentialOrganization.accountNumber &&
                            this.getPotentialOrganization.clabe
                        this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_FOUR`, isValid);
                        break;
                    case 5:
                        this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_FIVE`, true);
                        break;
                    case 6: {
                        let stepSixValid = true;
                        for (const document of this.getPotentialOrganization.requestedFiles) {
                            if (!document.isLoaded) {
                                stepSixValid = false
                                break;
                            }
                        }
                        isValid = stepSixValid;
                        this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_SIX`, stepSixValid);
                        break;
                    }
                    case 7: {
                        const stepSevenValid = !!this.getPotentialOrganization.branch;
                        isValid = stepSevenValid;
                        this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_SEVEN`, stepSevenValid);
                        break;
                    }
                    default:
                        break;

                }
                return isValid;
            },
            backToOperationSchemeStep(props) {
                if (this.getHasInvoiceData) {
                    const params = {
                        isPhysicalPerson: true,
                        hasInvoiceData: false
                    };
                    this.$store.commit(`${storeModule}/UPDATE_IS_PHYSICAL_PERSON`, {params});
                } else {
                    props.prevTab();
                }
                this.loading = false;
            },
            redirectClient() {

                if (this.actualUser.profile === "USER") {
                    this.$store.commit(`${SignInStore}/CLEAR_STORE`);
                    this.$router.push('/logout');
                } else {
                    this.$router.push('/client-admin/dashboard');
                }

            },
            makeBackFormStep() {
                const newStep = this.getFormStep - 1;
                this.$store.commit(`${storeModule}/SET_FORM_STEP`, newStep);
            },
            makeBack(actualStep) {
                const paramsStep = {
                    isPhoto: this.getIsPhoto
                }
                this.$store.dispatch(`${storeModule}/makeBack`, {actualStep, paramsStep});
                this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, false);
                this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, '');
            },
            editInfo() {
                this.$refs.wizard.maxStep = 1;
                this.$refs.wizard.navigateToTab(1);
                this.$store.commit(`${storeModule}/IS_PHOTO`, false);
                this.$store.commit(`${storeModule}/SET_FORM_STEP`, 1);
            }
        },
        computed: {
            ...mapGetters(storeModule, [
                'getShowBackButtonForm',
                'getShowNextButtonForm',
                'getIsValidStepFormSave',
                'getPotentialOrganization',
                'getHasInvoiceData',
                'getIsValidStepFormSave',
                'getErrorNextStep',
                'getFormStep',
                'isValidStepOne',
                'isValidStepTwo',
                'isValidStepThree',
                'isValidStepFour',
                'isValidStepFive',
                'isValidStepSix',
                'isValidStepSeven',
                'getIsValidStepFormSave',
                'getIsPersonalInformationComplete',
                'isPhoneVerified',
                'hasNewRegisterFinish',
                'getActiveTabInStepOne',
                'registerUserStepsStatus',
                'getActualUser',
                'getOnboardingFlag'

            ]),
            ...mapGetters(SESSION_STORE, {
                actualUser: GET_USER
            }),
            ...mapGetters(SignInStore, ['getHasValidSession']),
        }
    }
</script>
