<template>
    <div class="full-page-content">
        <LoadingBar
                v-if="getLoadingContract"
                :loadingText="$t('signup-organization.step-contract.loading-contract')"
        ></LoadingBar>
        <div v-if="!getLoadingContract" class="d-block">
            <vue-snotify></vue-snotify>
            <div class="top-bar-single-logo">
                <img class="img-fluid" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" alt="Pagando"/>
            </div>

            <div class="max-w-920">
                <h1 class="title">
                    {{$t('signup-organization.step-contract.title')}}
                    <strong>{{$t('signup-organization.step-contract.title-strong')}}</strong>
                </h1>
                <p class="subtitle">{{$t('signup-organization.step-contract.sub-title')}}</p>

                <span class="date-update">
          <small>{{$t('signup-organization.step-contract.last-update')}}</small>
          {{formatDate}}
        </span>
                <div class="text-container" v-html="getContract.content">
                    <p class="m-b-0">
                        CONTRATO DE SERVICIO DE FONDOS DE PAGO ELECTRÓNICO (EL
                        <strong>“CONTRATO”</strong>), QUE CELEBRAN POR UNA PARTE
                        <strong>“XC NETWORKS, S.A. DE C.V.”</strong> REPRESENTADA EN ESTE ACTO POR
                        <strong>C. HUGO BENITO NAVA BERNAL</strong> (EN LO SUCESIVO “PAGANDO”) Y POR LA OTRA PARTE
                        BAZZARI REPRESENTADA EN ESTE ACTO POR EL
                        <strong>C. MIGUEL GÓMEZ HERNÁNDEZ</strong> (EN LO SUCESIVO EL “USUARIO”) QUIENES EN CONJUNTO
                        SERÁN REFERIDAS COMO
                        <strong>“LAS PARTES”</strong>, LAS CUALES SE OBLIGAN AL TENOR DE LAS SIGUIENTES DECLARACIONES Y
                        CLÁUSULAS:
                        <br/>
                        <br/>
                        <u>
                            <strong>DECLARACIONES</strong>
                        </u>
                        <br/>
                        <br/>
                        <strong>I. Declara Pagando, por conducto de su apoderado:</strong>
                        <br/>
                        <br/>a. Que su representada es una sociedad debidamente constituida y existente de conformidad
                        con las leyes de los Estados Unidos Mexicanos según se hace constar en la escritura pública
                        número 51, otorgada el 11 de junio de 1997, ante la fe del Licenciado Oscar Cayetano Becerra
                        Tucker, titular de la Notaría Pública número 28 del Distrito de Bravos, Ciudad Juárez,
                        Chihuahua, misma que quedó inscrita en el Registro Público de la Propiedad y del Comercio el 21
                        de julio de 1997, bajo el número 71 a folios 117, del libro primero, volumen 24.
                        <br/>
                        <br/>b. Que sus facultades se desprenden de la escritura pública número 16,757 de fecha 30 de
                        enero de 2019, otorgada ante la fe de la Lic. Josefina Sosa Ramírez, notario público número 32
                        de Ciudad Juárez, Estado de Chihuahua, e inscrita en el registro público de comercio de Ciudad
                        Juárez bajo el folio mercantil electrónico número 9386 de fecha 20 de febrero de 2019, mismas
                        que no le han sido limitadas ni revocadas en forma alguna.atos personales facilitados en el
                        formulario mencionado y todos los datos que se generen en relación con su participación o uso de
                        los diferentes eventos que se ofrecen en esta página web.
                        <br/>
                        <br/>
                    </p>
                </div>
                <BasicCheckbox
                        checkId="chk-digitalContract"
                        ref="CHECKBOX_ACCEPT_CONTRACT"
                        :text="$t('signup-organization.step-contract.checkbox-contract')"
                        :currentValue="acceptTermsAndConditions"
                        @change="checkAccepContract"
                ></BasicCheckbox>

                <div class="vertical-center m-t-40">
                    <button
                            id
                            class="button xs button-ghost button-plain_text m-0-auto-0-0"
                            @click="back"
                            type="button"
                    >
                        <i aria-hidden="true" class="banana banana-chevron-left"></i>
                        {{$t('general.actions.back.title')}}
                    </button>
                    <a
                            id
                            @click="pathFileToContractFile"
                            class="button xs button-stroke button-accent1"
                            type="button"
                    >
                        <i aria-hidden="true" class="banana banana-document-normal"></i>
                        {{$t('general.actions.print.title')}}
                    </a>
                    <button
                            id="btn-digitalContract"
                            v-if="acceptTermsAndConditions && !isAcceptingContract"
                            @click="acceptPreContract"
                            class="button xs button-filled button-accent1 m-l-40"
                            type="button"
                    >{{$t('general.actions.finish.title')}}
                    </button>
                    <button
                            id
                            v-if="isAcceptingContract"
                            class="button xs button-filled button-accent1 m-l-40"
                            type="button"
                    >
                        <LoadingSimple clazz="small-load no-text"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import LoadingSimple from "@/components/LoadingSimple.vue";
    import axios from "axios";
    import {utils} from "@/api/utils";
    import fileApi from "@/api/files/files.api";
    import {mapGetters} from "vuex";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import {
        STORE as SessionStore,
        GET_USER,
        GET_TOKEN
    } from '@/store/modules/session/session.types';

    const storeModule = "signUpOrganization";

    export default {
        name: "DigitalContract",
        components: {
            BasicCheckbox,
            LoadingBar,
            LoadingSimple
        },
        props: {
            contractHidden: {type: Boolean, default: false}
        },
        mixins: [ToastSnotify],
        data() {
            return {
                acceptTermsAndConditions: false,
                isAcceptingContract: false
            };
        },
        methods: {
            back() {
                this.$router.back();
            },
            checkAccepContract(value) {
                this.acceptTermsAndConditions = value;
            },
            async acceptPreContract() {
                this.isAcceptingContract = true;
                const response = await this.$store.dispatch(
                    "signUpOrganization/acceptPreContract"
                );
                if (response.data.error) {
                    this.displayNotificationError(response.data.message);
                    this.isAcceptingContract = false;
                } else {
                    this.displayNotificationSuccess(response.data.message);
                    this.$router.push("/finish");
                }
            },
            pathFileToContractFile() {
                if (this.getContract !== undefined) {
                    fileApi.getSignedUrlFile({id: this.getContract.documentPDF}, (response) => {
                        if (response.data.error) {
                            this.displayNotificationInfo(this.$i18n.t('signup-organization.step-contract.print-error'));
                        } else {
                            const link = document.createElement("a");
                            const url = response.data.object;
                            const name = "Contrato"
                            link.setAttribute("href", url);
                            link.setAttribute("download", name);
                            link.click();
                        }
                    }, () => {
                        this.displayNotificationInfo(this.$i18n.t('signup-organization.step-contract.print-error'));
                    });
                }
            }
        },
        async created() {
            const token = this.token
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            const userId = this.user._id;
            await this.$store.commit(`${storeModule}/SET_LOADING_CONTRACT`, true);
            await this.$store.dispatch("signUpOrganization/loadPotentialOrganization", {
                userId
            });
            await this.$store.dispatch(`${storeModule}/loadContractOrganization`);
        },
        computed: {
            ...mapGetters(storeModule, [
                "getPotentialOrganization",
                "getContract",
                "getLoadingContract"
            ]),
            ...mapGetters(SessionStore, {user: GET_USER, token: GET_TOKEN}),
            formatDate() {
                if (this.getContract !== undefined) {
                    return utils.toFormatDate(
                        this.getContract.document.updatedAt,
                        "DD/MM/YYYY"
                    );
                }
                return utils.toFormatDate(new Date(), "DD/MM/YYYY");
            }
        }
    };
</script>
