<template>
    <div>
        <SignUpOrganizationBody :bodyProp="bodyRight">
            <template v-slot:formSectionBody>
                <div v-if="!getHasValidSession">
                    <SignIn></SignIn>
                </div>
                <div v-if="getHasValidSession" v-cloak>
                    <RegisterUser></RegisterUser>
                </div>
            </template>
        </SignUpOrganizationBody>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue';
    import SignIn from '@/views/signUpOrganization/userRegistration/SignIn.vue';
    import RegisterUser from '@/views/signUpOrganization/userRegistration/RegisterUser.vue';
    import { EventBus } from '@/main';
    const storeModule = "SIGNIN_UP";
    const signUpOrganizationStore = "signUpOrganization";

    export default {
        name: 'StepUserRegistration',
        computed: {
            ...mapGetters(storeModule, [
                "getHasValidSession"
            ]),
            ...mapGetters(signUpOrganizationStore, [
                "hasNewRegisterFinish",
                "getActiveTabInStepOne"
            ]),
            bodyRight(){
                if(this.getHasValidSession){
                    switch(this.getActiveTabInStepOne){
                        case "PERSONAL_INFORMATION":
                        case "PHONE_SECURITY":
                            return "personalInformationStep";
                        case "SECURITY_MEASURES":
                            return "securityMeasuresStep";

                        default:
                            return "personalInformationStep";
                    }
                } else {
                    return "signInRegisterStep"
                }
            }
        },
        components: {
            SignUpOrganizationBody,
            RegisterUser,
            SignIn
        },
        created(){
            const userId = this.$session.get('userId');
            let validSession = false;
            const token = localStorage.getItem('currentToken');
            const expireTime = localStorage.getItem('expireDate');

            if (token && expireTime) {
                const today = new Date();
                const expireDate = new Date(0);
                expireDate.setUTCSeconds(parseInt(JSON.parse(expireTime), 10));
                if (expireDate > today) {
                    validSession = true;
                }
            }
            this.$store.dispatch(`${storeModule}/setHasValidSession`, validSession);
            if(userId){
                this.$store.dispatch('signUpOrganization/loadPotentialOrganization', {userId});
            }
            this.goToNextStep(this.hasNewRegisterFinish);
        },
        watch: {
            hasNewRegisterFinish(value){
                this.goToNextStep(value);
            }
        },
        methods: {
            goToNextStep(value){
                if(value){
                    EventBus.$emit('REGISTER_COMPLETE')
                }
            }
        }
    }
</script>
