<template>
    <div class="card-selection-container" :class="clazz">
        <span class="icon-card">
            <slot name="icon"></slot>
        </span>
        <span>
            <label class="title">{{title}}</label>
            <p class="subtitle">{{subtitle}}</p>
            <slot name="extraMiddleContent"></slot>
        </span>
        <span>
            <slot name="extraFinalContent"></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'CardSelection',
        props: {
            clazz: String,
            title: String,
            subtitle: String
        },
        methods: {},
    }
</script>
