<template>
    <LoadingBar></LoadingBar>
</template>

<script>
    // @ is an alias to /src
    import {mapGetters} from "vuex";
    import LoadingBar from "@/components/LoadingBar.vue";
    import {
        STORE as SESSION_STORE,
        GET_USER
    } from '@/store/modules/session/session.types';
    import {
        STORE as UserSignUpStore
    } from '@/store/modules/signin/signin.types'
    export default {
        name: "SignInSocialMediaFace",
        data(){
            return {
            }
        },
        created(){
            this.$store.commit(`${UserSignUpStore}/SET_RIGHT_SIDE_BODY`, 'EASY_PAY');
            this.$store.dispatch(`${UserSignUpStore}/loadUserForSocialMedia`, this.$route.params.token);
        },
        components: {
            LoadingBar
        },
        computed: {
            ...mapGetters(SESSION_STORE, {
                actualUser: GET_USER
            }),
        }
    }
</script>
