const clientHost = process.env.VUE_APP_CLIENT_PROD_HOST || undefined
const clientPort = process.env.VUE_APP_CLIENT_PROD_PORT || undefined
const LOCALHOST_URL = 'http://localhost';

export default {
    server: {
        host: process.env.API_HOST || process.env.VUE_APP_API_HOST || LOCALHOST_URL,
        port: process.env.API_PORT || process.env.VUE_APP_API_PORT || 3000,
        paymentHost: process.env.API_PAYMENT_HOST || process.env.VUE_APP_API_PAYMENT_HOST || LOCALHOST_URL,
        paymentPort: process.env.API_PAYMENT_PORT || process.env.VUE_APP_API_PAYMENT_PORT || 5001
    },
    clients: {
        host: clientHost || process.env.HOST_CLIENT || process.env.VUE_APP_HOST_CLIENT || LOCALHOST_URL,
        port: clientPort || process.env.PORT_CLIENT || process.env.VUE_APP_PORT_CLIENT || 8000,
        endpoint: process.env.ENDPOINT_CLIENT || process.env.VUE_APP_ENDPOINT_CLIENT || ''
    },
    users: {
        host: process.env.HOST_USER || process.env.VUE_APP_HOST_USER || LOCALHOST_URL,
        port: process.env.PORT_USER || process.env.VUE_APP_PORT_USER || 8080,
        endpoint: process.env.ENDPOINT_USER || process.env.VUE_APP_ENDPOINT_USER || ''
    },
    admin: {
        host: process.env.HOST_ADMIN || process.env.VUE_APP_HOST_ADMIN || LOCALHOST_URL,
        port: process.env.PORT_ADMIN || process.env.VUE_APP_PORT_ADMIN || 8001,
        endpoint: process.env.ENDPOINT_ADMIN || process.env.VUE_APP_ENDPOINT_ADMIN || ''
    },
    walletWeb: {
        host: process.env.HOST_WALLET_WEB|| process.env.VUE_APP_HOST_WALLET_WEB || LOCALHOST_URL,
        port: process.env.PORT_WALLET_WEB || process.env.VUE_APP_PORT_WALLET_WEB || 8080,
        endpoint: process.env.ENDPOINT_WALLET_WEB || process.env.VUE_APP_ENDPOINT_WALLET_WEB || ''
    },
    googleRecapcha: {
        secretKey: process.env.RECAPTCHA_SECRET || "",
        publicKey: "6LdtXjMfAAAAAP8SYEUEEYw03S473jwE1jW3R9Wt"
    },
    sentry: {
        enabled: true,
        dsn: 'https://de06534614de4b129ebdd457c2af7cad@sentry.blacklabs.mx/3'
    },
    googleMaps: {
        // Si se usa en local quitar los ==
        key: process.env.VUE_APP_KEY_MAPS || 'AIzaSyDy2aPF52Mg == GhoUk_PNtThp2QyxIPDhmWc',
        libraries: process.env.VUE_APP_LIBRARY_MAPS || 'places'
    },
    trackingTags: {
        googleTagManagerId: 'GTM-5LGB749',
        googleAnalyticsId: 'UA-129970649-1',
        facebookPixelId: '169573313612564'
    }
}
