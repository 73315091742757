<template>
    <div :class="_class" :id="id" tabindex="-1" role="dialog" :aria-labelledby="id" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content o-hidden">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import propValidators from '@/common/validations/form/propValidators';

    export default {
        data () {
            return {
            }
        },
        components: {
        },
        props: {
            id: {
                type: String,
                required: true,
                validation: propValidators.id,
            },
            _class: {
                type: String,
                default: 'modal'
            }
        }
    }
</script>
