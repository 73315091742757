<template>
    <div class="secure-code" :class="[showWarning ? 'has-error': '', clazz]">
        <div class="secure-code-content" id="number-code" :class="containerClass">
            <div>
                <input
                    autocomplete="off"
                    :type="typeInput"
                    placeholder="Código de seguridad"
                    class="input-space"
                    maxlength="1"
                    v-model="verificationCode.pos1"
                    aria-label=""
                    ref="pos1"
                    v-on:keyup="codeChange($event,1)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input
                    autocomplete="off"
                    :type="typeInput"
                    placeholder="Código de seguridad"
                    class="input-space"
                    maxlength="1"
                    v-model="verificationCode.pos2"
                    aria-label=""
                    ref="pos2"
                    v-on:keyup="codeChange($event,2)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input
                    autocomplete="off"
                    :type="typeInput"
                    placeholder="Código de seguridad"
                    class="input-space"
                    maxlength="1"
                    v-model="verificationCode.pos3"
                    aria-label=""
                    ref="pos3"
                    v-on:keyup="codeChange($event,3)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input
                    autocomplete="off"
                    :type="typeInput"
                    placeholder="Código de seguridad"
                    class="input-space"
                    maxlength="1"
                    v-model="verificationCode.pos4"
                    aria-label=""
                    ref="pos4"
                    v-on:keyup="codeChange($event,4)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input
                    autocomplete="off"
                    :type="typeInput"
                    placeholder="Código de seguridad"
                    class="input-space"
                    maxlength="1"
                    v-model="verificationCode.pos5"
                    aria-label=""
                    ref="pos5"
                    v-on:keyup="codeChange($event,5)">
                <span class="caret-underscore"></span>
            </div>
        </div>
        <small class="form-text-info" v-if="showWarning">
            <i aria-hidden="true" :class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>

</template>

<script>
    export default {
        name: 'CodeInput',
        data() {
            return {
                noBackspace: 0,
                verificationCode: {
                    pos1: "",
                    pos2: "",
                    pos3: "",
                    pos4: "",
                    pos5: ""
                },
                verificationError: false,
            }
        },
        props: {
            warningText: String,
            warningIcon: String,
            clazz: String,
            showWarning: {
                type: Boolean,
                default: false
            },
            typeInput: {type: String, default: 'phone'},
        },
        methods: {
            codeChange(event,position){
                const positionName = `pos${position}`;
                if(event && event.target && event.target.value !== "" && event.target.value !== ' ' && !isNaN(event.target.value)){
                    let positionFocus = `pos${position + 1}`;
                    this.verificationError = false;
                    this.verificationCode[positionName] = event.target.value;
                    const codeVerificationArray = Object.values(this.verificationCode);
                    const codeVerificationString = codeVerificationArray.join('');
                    this.$emit('verificationCode', codeVerificationString);
                    if(position === 5)
                        {positionFocus = `pos1`;}
                    this.$refs[positionFocus].focus();
                    if(position < 5)
                        {this.$refs[positionFocus].select();}
                } else {
                    const codeVerificationArray = Object.values(this.verificationCode);
                    const codeVerificationString = codeVerificationArray.join('');
                    this.$emit('verificationCode', codeVerificationString);
                    this.verificationCode[positionName] = "";
                }
            }
        }

    }
</script>
