const errorMessageProsa1 = "Problema con el emisor de la tarjeta. Contacte a su banco.";
const errorMessageProsa2 = "Situación especial, recoger tarjeta.";
const errorMessageProsa3 = "Error con tarjeta utilizada. Por favor intente con otra tarjeta.";

// NEW CODES AGREGADOR
export const PROSA_01 = {type: "AGREGADOR_PROSA", key: "PROSA_01", message: errorMessageProsa1, code: '01'};
export const PROSA_02 = {type: "AGREGADOR_PROSA", key: "PROSA_02", message: errorMessageProsa1, code: '02'};
export const PROSA_04 = {type: "AGREGADOR_PROSA", key: "PROSA_04", message: errorMessageProsa2, code: '04'};
export const PROSA_06 = {type: "AGREGADOR_PROSA", key: "PROSA_06", message: errorMessageProsa1, code: '06'};
export const PROSA_07 = {type: "AGREGADOR_PROSA", key: "PROSA_07", message: errorMessageProsa2, code: '07'};
export const PROSA_14 = {type: "AGREGADOR_PROSA", key: "PROSA_14", message: errorMessageProsa3, code: '14'};
export const PROSA_15 = {type: "AGREGADOR_PROSA", key: "PROSA_15", message: errorMessageProsa1, code: '15'};
export const PROSA_31 = {type: "AGREGADOR_PROSA", key: "PROSA_31", message: "Banco no soportado. Por favor intente con otra tarjeta.", code: '31'};
export const PROSA_33 = {type: "AGREGADOR_PROSA", key: "PROSA_33", message: "Tarjeta expirada.", code: '33'};
export const PROSA_34 = {type: "AGREGADOR_PROSA", key: "PROSA_34", message: errorMessageProsa2, code: '34'};
export const PROSA_36 = {type: "AGREGADOR_PROSA", key: "PROSA_36", message: errorMessageProsa1, code: '36'};
export const PROSA_38 = {type: "AGREGADOR_PROSA", key: "PROSA_38", message: errorMessageProsa3, code: '38'};
export const PROSA_39 = {type: "AGREGADOR_PROSA", key: "PROSA_39", message: errorMessageProsa3, code: '39'};
export const PROSA_41 = {type: "AGREGADOR_PROSA", key: "PROSA_41", message: "Recoger tarjeta", code: '41'};
export const PROSA_43 = {type: "AGREGADOR_PROSA", key: "PROSA_43", message: "Recoger tarjeta", code: '43'};
export const PROSA_51 = {type: "AGREGADOR_PROSA", key: "PROSA_51", message: "Fondos insuficientes", code: '51'};
export const PROSA_54 = {type: "AGREGADOR_PROSA", key: "PROSA_54", message: "Tarjeta expirada", code: '54'};
export const PROSA_56 = {type: "AGREGADOR_PROSA", key: "PROSA_56", message: errorMessageProsa3, code: '56'};
export const PROSA_57 = {type: "AGREGADOR_PROSA", key: "PROSA_57", message: errorMessageProsa1, code: '57'};
export const PROSA_58 = {type: "AGREGADOR_PROSA", key: "PROSA_58", message: "Transacción no permitida para esta terminal.", code: '58'};
export const PROSA_61 = {type: "AGREGADOR_PROSA", key: "PROSA_61", message: errorMessageProsa1, code: '61'};


// NEW CODES SWITCH
export const SWITCH_4011 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4011", message: "Ya existe una orden de cancelación para la referencia.", code: "4011"};
export const SWITCH_4012 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4012", message: "El monto del reembolso no puede ser mayor que el monto en el pedido original.", code: "4012"};
export const SWITCH_4013 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4013", message: "Ya existe una orden de reembolsado para la referencia.", code: "4013"};
export const SWITCH_4014 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4014", message: "Esta operación de Check-in no tiene una operación de Check-out.", code: "4014"};
export const SWITCH_4015 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4015",
    message: "Esta referencia no se puede reembolsar, asegúrese de que está proporcionando una transacción de venta (normal o diferida), QPS o check-in.", code: "4015"};
export const SWITCH_4017 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4017",
    message: "El tipo de comerciante (MCC) proporcionado no está disponible para esta operación.", code: "4017"};
export const SWITCH_4018 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4018", message: "El monto debe ser menor que el monto de la transacción original más el 20%.", code: "4018"};
export const SWITCH_4020 = {type: "AGREGADOR_SWITCH", key: "SWITCH_4020", message: "El monto debe ser menor que el monto de la transacción original más el 20%.", code: "4020"};
export const SWITCH_5010 = {type: "AGREGADOR_SWITCH", key: "SWITCH_5010", message: "Contactless no está permitido por el momento, favor de intentar con CHIP.", code: "5010"}

export const values = [
    PROSA_01,
    PROSA_02,
    PROSA_04,
    PROSA_06,
    PROSA_07,
    PROSA_14,
    PROSA_15,
    PROSA_31,
    PROSA_33,
    PROSA_34,
    PROSA_36,
    PROSA_38,
    PROSA_39,
    PROSA_41,
    PROSA_43,
    PROSA_51,
    PROSA_54,
    PROSA_56,
    PROSA_57,
    PROSA_58,
    PROSA_61,
    SWITCH_4011,
    SWITCH_4012,
    SWITCH_4013,
    SWITCH_4014,
    SWITCH_4015,
    SWITCH_4017,
    SWITCH_4018,
    SWITCH_4020,
    SWITCH_5010,
];

export function getCode(code = '') {
    return values.find(v => v.code.toString() === (code || '').toString());
}
