<template>
    <div class="cropper-container" v-bind:class="clazz">
        <label class="cropbox-title"> {{ titleCropbox }} </label>

        <div class="img-content">
            <vue-croppie
                    ref="croppieRef"
                    :enableResize="false"
                    :enableOrientation="true"
                    >
            </vue-croppie>

            <p class="img-note"> {{ imgNote }} </p>
        </div>

        <div v-if="showPreview" class="img-cropped">
            <img class="img-fluid" :src="cropped" alt="image">
        </div>

        <div v-if="showButtons" class="img-buttons">
            <button class="button xs button-filled button-primary1" @click="rotate(+90)"><i
                    aria-hidden="true" class="banana banana-do-undo"></i>Rotate Left
            </button>
            <button class="button xs button-filled button-primary1" @click="rotate(-90)"><i
                    aria-hidden="true" class="banana banana-do-redo"></i>Rotate Right
            </button>
            <button class="button xs button-filled button-primary2" @click="crop()"><i
                    aria-hidden="true" class="banana banana-player-fullscreen-open"></i>Crop
            </button>
        </div>

        <div v-if="!showButtons" class="img-buttons">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CropImage',
        props: {
            clazz: String,
            titleCropbox: String,
            imgNote: String,
            showPreview: {type: Boolean, default: false},
            showButtons: {type: Boolean, default: false}
        },
        mounted() {
            // Upon mounting of the component, we accessed the .bind()function to put an initial image on the canvas.
            // this.$refs.croppieRef.bind({
            // url: 'https://i.imgur.com/74HtSJK.jpg'
            // })
            // setTimeout(() => {
            //   this.crop()
            // }, 1000)
        },
        data() {
            return {
                cropped: null
            }
        },
        methods: {
            crop() {
                // console.log("init cropping..");
                // Here we are getting the result via callback function
                // and set the result to this.cropped which is being
                // used to display the result above.
                const options = {
                    format: 'jpeg',
                    size: 'original'
                };
                this.$refs.croppieRef.result(options, (output) => {
                    this.cropped = output;
                    this.result(this.cropped)
                });
            },
            result(output) {
                this.cropped = output;
                this.$emit('crop', this.cropped);
            },
            rotate(rotationAngle) {
                this.$refs.croppieRef.rotate(rotationAngle);
            },
            initCroppie(url) {
                // This method will be executed when the croppie is initialized
                // You can use it to set the image
                this.$refs.croppieRef.bind({
                    url: url ? url : 'https://i.imgur.com/Fv2YOM6.jpg',
                });
            }
        }
    }
</script>
