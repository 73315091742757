<template>
    <div>
        <b-tabs
            class="simple-tabs group-tabs"
            content-class="simple-tabs-body p-t-30 min-height-300px"
            active-tab-class="primary-bg"
        >
            <b-tab
                :title="$t('statements.accounting.title')"
                active
            >
                <StatementsAccounting></StatementsAccounting>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import StatementsAccounting from '@/views/statements/StatementsAccounting';
export default {
    name: 'StatementsTabs',
    components: {
        StatementsAccounting,
    }
}
</script>
