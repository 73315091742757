<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                        to="/client-admin/api-list"
                        class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                        tabindex="">
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i>
                    {{$t('api-configuration.checkout.selected.return-api')}}
                </router-link>
                <FloatingTitleLayout title="Botón checkout"/>
            </div>

            <div class="col-12">
                <CardSimple hideTitle>
                    <div v-if="loadingTestKeyInfo === true || loadingTestUserInfo === true" class="progress-loading">
                        <LoadingBar :loadingText="$t('api-configuration.api-keys.loading')"></LoadingBar>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <TitleNumber num="1"
                                         :title="$t('api-configuration.api-keys.second-step.title')"
                                         :subtitle="$t('api-configuration.api-keys.second-step.subtitle')"
                            ></TitleNumber>
                        </div>
                        <div class="col-12">
                            <label class="f-14 c-title body-font-bold d-block m-b-10">
                                {{ $t('api-configuration.api-keys.second-step.user') }}
                            </label>
                            <p class="f-14 c-plain_text body-font-regular d-block m-b-30"> {{ getApiUserTest }} </p>

                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <BasicInput
                                            clazz="m-b-0"
                                            required
                                            :disabled="stepNumber < 2"
                                            labelFor="testPublicKey"
                                            inputId="testPublicKey"
                                            :label="$t('api-configuration.api-keys.second-step.input-title')"
                                            :inputValue="showTestKey ? getApiTestKey : ''"
                                            :inputPlaceholder="$t('api-configuration.api-keys.second-step.input-placeholder')"
                                            help
                                            helpIcon="banana banana-question-circle1"
                                            idHelpTooltip="tooltip-testKey"
                                            inputClazz="icon-right"
                                            iconInput="banana-copy"
                                            v-on:onIconClick="copyClipboard(getApiTestKey)">
                                        <template v-slot:tooltipHelp>
                                            <b-tooltip
                                                    target="tooltip-testKey"
                                                    placement="top"
                                                    custom-class="white-tooltip">
                                                <div>
                                                    <p class="m-b-0">{{$t('api-configuration.api-keys.second-step.tooltip')}}</p>
                                                </div>
                                            </b-tooltip>
                                        </template>
                                    </BasicInput>
                                </div>
                                <div class="col-12 col-lg-4 vertical-center justify-content-end">
                                    <button
                                            type="button"
                                            class="button sm p-l-10 p-r-10 m-t-15 m-r-5"
                                            :class="stepNumber < 2 ? 'button-disable button-filled' : 'button-filled button-accent1'"
                                            @click="getApiTestKey === '' ? getKey('Test', true, true) : getKey('Test', false, true)"
                                            :disabled="showTestKey === true"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.primary-button')}}
                                    </button>
                                    <button
                                            type="button"
                                            class="button sm  p-l-10 p-r-10 m-t-15"
                                            :class="stepNumber < 2 ? 'button-stroke button-disable' : 'button-stroke button-accent1'"
                                            @click="getKey('Test', true, true)"
                                            :disabled="getApiTestKey === ''"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.secondary-button')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardSimple>
                <CardSimple hideTitle>
                    <div v-if="loadingDomainsInfo === true" class="progress-loading">
                        <LoadingBar></LoadingBar>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <TitleNumber num="2"
                                         :title="$t(title)"
                                         :subtitle="$t(subtitle)">
                            </TitleNumber>
                        </div>
                        <div class="col-12" v-if="type === typesCheckout.PRESTASHOP || type === typesCheckout.WOOCOMERCE || type === typesCheckout.MAGENTO">
                            <BasicInput
                                    required
                                    labelFor="dominioTienda"
                                    inputId="dominioTienda"
                                    label="Dominio tienda"
                                    inputPlaceholder="Ingrese aquí el dominio de su tienda"
                                    help
                                    helpIcon="banana banana-question-circle1"
                                    idHelpTooltip="tooltip-dominio-tienda"
                                    v-model="configuration.domain"
                                    @input="setNewRedirectRoutes"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                            target="tooltip-dominio-tienda"
                                            placement="top"
                                            custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">
                                                Esta dirección servirá para configurar el redireccionamiento de la plataforma.
                                            </p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>
                        <div class="col-12" v-if="type === typesCheckout.JAVASCRIPT">
                            <div class="col-12">
                                <BasicInput
                                        required
                                        labelFor="rutaRetorno"
                                        inputId="rutaRetorno"
                                        :label="$t('api-configuration.checkout.selected.general.return-link')"
                                        :inputPlaceholder="$t('api-configuration.checkout.selected.general.return-link-holder')"
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-rutaPublica"
                                        v-model="configuration.returnLink">
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                                target="tooltip-rutaPublica"
                                                placement="top"
                                                custom-class="white-tooltip">
                                            <div>
                                                <p class="m-b-0">
                                                    {{$t('api-configuration.checkout.selected.general.return-link-help')}}</p>
                                            </div>
                                        </b-tooltip>
                                    </template>
                                </BasicInput>
                            </div>
                            <div class="col-12">
                                <BasicInput
                                        required
                                        labelFor="rutaDestino"
                                        inputId="rutaDestino"
                                        :label="$t('api-configuration.checkout.selected.general.confirmation-link')"
                                        :inputPlaceholder="$t('api-configuration.checkout.selected.general.confirmation-link-holder')"
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-rutaDestino"
                                        v-model="configuration.confirmationLink">
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                                target="tooltip-rutaDestino"
                                                placement="top"
                                                custom-class="white-tooltip">
                                            <div>
                                                <p class="m-b-0">
                                                    {{$t('api-configuration.checkout.selected.general.confirmation-link-help')}}</p>
                                            </div>
                                        </b-tooltip>
                                    </template>
                                </BasicInput>
                            </div>
                        </div>
                        <div class="col-12 m-b-30">
                            <div class="col-12">
                                <BasicCheckbox
                                        checkId="chk-guest"
                                        ref="CHECKBOX_GUEST"
                                        v-model="configuration.guestForm"
                                        :text="$t('api-configuration.checkout.selected.general.allowed-payment.no-client')"
                                        inputClass="m-r-0"
                                        textClass="m-l-10"
                                        clazz="m-b-0 w-20px ml-auto di-initial"
                                        @change="checkGuestForm"
                                />
                            </div>
                            <div class="col-12">
                                <BasicCheckbox
                                        checkId="chk-user"
                                        ref="CHECKBOX_USER"
                                        v-model="configuration.userForm"
                                        :text="$t('api-configuration.checkout.selected.general.allowed-payment.client')"
                                        inputClass="m-r-0"
                                        textClass="m-l-10"
                                        clazz="m-b-0 w-20px ml-auto di-initial"
                                        @change="checkUserForm"
                                />
                            </div>
                          <div class="col-12">
                            <BasicCheckbox
                                checkId="chk-pag-mail"
                                ref="CHECKBOX_EMAIL"
                                v-model="configuration.pagandoEmail"
                                :text="$t('api-configuration.checkout.selected.general.allowed-payment.email')"
                                inputClass="m-r-0"
                                textClass="m-l-10"
                                clazz="m-b-0 w-20px ml-auto di-initial"
                                @change="checkPagandoEmail"
                            />
                          </div>
                        </div>

                        <div class="col-12">
                            <button v-if="editing" :disabled="disableSave" @click="saveConfig" type="button"
                                    class="button sm button-filled button-accent1 m-0-0-0-auto">
                                {{$t('api-configuration.checkout.selected.general.btnConfirm')}}
                            </button>

                            <button v-else type="button"
                                    class="button sm button-filled button-accent1">
                                <LoadingSimple clazz="small-load no-text"/>
                            </button>
                        </div>

                    </div>
                </CardSimple>
                <CardSimple hideTitle>
                    <div class="row">
                        <div class="col-12">
                            <TitleNumber num="3"
                                         :title="$t(titleInstallation)"
                                         :subtitle="$t(subTitleInstallation)">
                            </TitleNumber>
                        </div>
                        <div class="col-12">
                            <b-alert
                                    class="alert-note"
                                    show
                                    variant="warning">
                                <p class="text-container">
                                    <img
                                            class="img-fluid"
                                            src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                            alt="warning"
                                    />
                                    {{$t('api-configuration.checkout.selected.general.info-developer')}}
                                </p>
                            </b-alert>

                            <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                               v-if="type === typesCheckout.JAVASCRIPT">
                                Copia y pega el código en el archivo HTML de tu sitio web después de la etiqueta “HEAD”,
                                como se muestra a continuación. Si no estás seguro de poder realizar este proceso,
                                te recomendamos <a href="" class="c-accent_2 body-font-bold hover" tabindex="">contactar al equipo de
                                soporte</a> de Pagando.
                            </p>
                        </div>
                        <JavaScriptInstructions v-if="type === typesCheckout.JAVASCRIPT"></JavaScriptInstructions>
                        <WoocommerceInstructions v-if="type === typesCheckout.WOOCOMERCE"></WoocommerceInstructions>
                        <MagentoInstructions v-if="type === typesCheckout.MAGENTO"></MagentoInstructions>
                        <PrestaShopInstructions v-if="type === typesCheckout.PRESTASHOP"></PrestaShopInstructions>
                    </div>
                </CardSimple>
                <CardSimple hideTitle>
                    <div v-if="loadingTestInfo === true" class="progress-loading">
                        <LoadingBar :loadingText="$t('api-configuration.api-keys.loading')"></LoadingBar>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <TitleNumber num="4"
                                         :title="$t('api-configuration.api-keys.third-step.title')">
                                <label v-html="$t('api-configuration.api-keys.third-step-prestashop.subtitle')" class="subtitle"> {{subtitle}} </label>
                            </TitleNumber>
                        </div>
                        <div class="col-12 m-b-10 m-l-40 m-r-40">
                            <GenericTitle clazz="text-align-l" subtitle="¡Usuarios de prueba!" >
                            </GenericTitle>
                            <div class="row m-r-40" v-if="!showUserTest">
                                <div class="col-12 col-md-12 d-flex justify-content-center m-b-30">
                                    <button
                                            v-if="showButtonUserTest"
                                            id
                                            class="button xs button-stroke button-accent1"
                                            type="button"
                                            @click="makeUserTest"
                                    >
                                        {{$t("api-configuration.checkout.user-test.makeUserTest")}}
                                    </button>
                                    <button
                                            v-else
                                            id
                                            class="button xs button-filled button-accent1"
                                            type="button"

                                    >
                                        <LoadingSimple clazz="small-load no-text"/>
                                    </button>
                                </div>
                            </div>
                            <div class="row m-r-40" v-if="showUserTest">
                                <div class="col-6 col-md-6">

                                    <BasicInput
                                            :readOnly="true"
                                            labelFor="userSuccess"
                                            inputId="userSuccess"
                                            :label="$t('api-configuration.checkout.user-test.user-success')"
                                            help
                                            helpIcon="banana banana-question-circle1"
                                            idHelpTooltip="tooltip-userSuccess"
                                            inputClazz="icon-right"
                                            iconInput="banana-copy"
                                            v-model="usersTest.userSuccess.email"
                                            v-on:onIconClick="copyClipboard(usersTest.userSuccess.email)">
                                        <template v-slot:tooltipHelp>
                                            <b-tooltip
                                                    target="tooltip-userSuccess"
                                                    placement="top"
                                                    custom-class="white-tooltip">
                                                <div>
                                                    <p class="m-b-0">
                                                        {{$t('api-configuration.checkout.user-test.user-success-help')}}</p>
                                                </div>
                                            </b-tooltip>
                                        </template>
                                    </BasicInput>
                                </div>
                                <div class="col-6 col-md-6">
                                    <BasicInput
                                            :readOnly="true"
                                            labelFor="userSuccessPassword"
                                            inputId="userSuccessPassword"
                                            :label="$t('api-configuration.checkout.user-test.password')"
                                            help
                                            helpIcon="banana banana-question-circle1"
                                            inputClazz="icon-right"
                                            iconInput="banana-copy"
                                            v-model="usersTest.userSuccess.password"
                                            v-on:onIconClick="copyClipboard(usersTest.userSuccess.password)">
                                    </BasicInput>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="f-14 c-plain_text body-font-regular d-block m-b-40 m-l-40">
                                Una vez realizada la compra solo es necesario hacer clic en el botón <strong>Verificar pruebas</strong> para activar la llave de producción.
                            </p>
                        </div>
                        <div class="col-12 m-l-40 m-b-40">
                            <div class="list-testing-results">
                                <label> {{ $t('api-configuration.api-keys.third-step.test-title') }} </label>
                                <ul>
                                    <li>
                                        Validación de configuración de checkout ....................
                                        <Badge :type="getBadgeColor(getTestVerification)" :text="getApiText(getTestVerification)"></Badge>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="button"
                                    class="button sm button-filled button-accent1 p-l-10 p-r-10 m-t-15 w-100"
                                    @click="verifyAllTests"
                            >
                                {{$t('api-configuration.api-keys.third-step.secondary-button')}}
                            </button>
                        </div>
                    </div>
                </CardSimple>
                <CardSimple hideTitle v-if="getApiKeysEnabled">
                    <div v-if="loadingLiveKeyInfo === true || loadingLiveUserInfo === true" class="progress-loading">
                        <LoadingBar :loadingText="$t('api-configuration.api-keys.loading')"></LoadingBar>
                    </div>
                    <b-alert
                            class="alert-note"
                            show
                            variant="warning">
                        <p class="text-container p-r-0">
                            <img
                                    class="img-fluid"
                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                    alt="warning"
                            />
                            {{$t('api-configuration.api-keys.fourth-step.warning')}}
                        </p>
                    </b-alert>
                    <div class="row" :class="'p-relative' ? stepNumber < 4 : ''"> <!-- PLEASE REMOVE CLASS 'p-relative' WHEN 'layer-disabled' HIDDEN -->
                        <div class="layer-disabled" v-if="stepNumber < 4"></div>

                        <div class="col-12">
                            <TitleNumber num="5"
                                         :title="$t('api-configuration.api-keys.fourth-step.title')"
                                         :subtitle="$t('api-configuration.api-keys.fourth-step.subtitle')"
                            ></TitleNumber>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <label class="f-14 c-title body-font-bold d-block m-b-10">{{$t('api-configuration.api-keys.fourth-step.user')}}</label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-30"> {{ getApiUserLive }} </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <BasicInput
                                            clazz="m-b-0"
                                            required
                                            :disabled="stepNumber < 4"
                                            labelFor="publicKey"
                                            inputId="publicKey"
                                            :label="$t('api-configuration.api-keys.fourth-step.input-title')"
                                            :inputValue="showLiveKey ? getApiLiveKey: ''"
                                            :inputPlaceholder="$t('api-configuration.api-keys.fourth-step.input-placeholder')"
                                            help
                                            helpIcon="banana banana-question-circle1"
                                            idHelpTooltip="tooltip-liveKey"
                                            inputClazz="icon-right"
                                            iconInput="banana-copy"
                                            v-on:onIconClick="copyClipboard(getApiLiveKey)">
                                        <template v-slot:tooltipHelp>
                                            <b-tooltip
                                                    target="tooltip-liveKey"
                                                    placement="top"
                                                    custom-class="white-tooltip">
                                                <div>
                                                    <p class="m-b-0">{{$t('api-configuration.api-keys.fourth-step.tooltip')}}</p>
                                                </div>
                                            </b-tooltip>
                                        </template>
                                    </BasicInput>
                                </div>
                                <div class="col-12 col-lg-4 p-l-0 vertical-center justify-content-end">
                                    <button type="button" class="button sm p-l-10 p-r-10 m-t-15 m-r-10"
                                            :class="stepNumber < 4 ? 'button-disable button-filled' : 'button-filled button-accent1'"
                                            @click="getApiLiveKey === '' ? getKey('Live', true, true) : getKey('Live', false, true)"
                                            :disabled="showLiveKey === true || !getApiKeysEnabled"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.primary-button')}}
                                    </button>
                                    <button type="button"
                                            class="button sm  p-l-10 p-r-10 m-t-15"
                                            :class="stepNumber < 4 ? 'button-stroke button-disable' : 'button-stroke button-accent1'"
                                            @click="$bvModal.show('modal-danger')"
                                            :disabled="getApiLiveKey === '' || !getApiKeysEnabled"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.secondary-button')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 m-t-30">
                            <p class="f-14 c-plain_text body-font-regular d-block m-b-0">
                                {{$t('api-configuration.api-keys.fourth-step.instructions-first-part')}}
                                <a href="" class="c-accent_2 body-font-bold f-bold text-underline hover" tabindex="">
                                    {{$t('api-configuration.api-keys.fourth-step.instructions-second-part')}}
                                </a>
                                {{$t('api-configuration.api-keys.fourth-step.instructions-third-part')}}
                            </p>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>
        <ModalAlert
                modalId="modal-danger"
                buttonType="button-error"
                :imgUrl="imageModalWarning"
                :title="$t('api-configuration.api-keys.fourth-step.modal-alert.title')"
                :text="$t('api-configuration.api-keys.fourth-step.modal-alert.text')"
                :buttonText="$t('api-configuration.api-keys.fourth-step.modal-alert.button-accept')"
                @submit="getLiveKey()"
        ></ModalAlert>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue'
    import CardSimple from '@/components/CardSimple.vue'
    import TitleNumber from '@/components/TitleNumber.vue'
    import BasicInput from '@/components/BasicInput.vue'
    import LoadingSimple from '@/components/LoadingSimple.vue'
    import ModalAlert from '@/components/ModalAlert.vue'
    import imageModalWarning from "@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg";
    import GenericTitle from '@/components/GenericTitle.vue'
    import PrestaShopInstructions from '@/views/apiWeb/checkouts/prestaShopInstructions'
    import JavaScriptInstructions from '@/views/apiWeb/checkouts/JavaScriptInstructions'
    import MagentoInstructions from '@/views/apiWeb/checkouts/magentoInstructions'
    import WoocommerceInstructions from '@/views/apiWeb/checkouts/woocommerceInstructions'
    import {JAVASCRIPT, SHOPIFY, PRESTASHOP, WOOCOMERCE, MAGENTO} from '@/views/apiWeb/checkouts/paymentMethods';
    import apiConfig from '@/api/apiWeb/apiConfigurationApi.api';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {mapGetters} from 'vuex';
    import axios from 'axios'
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {
        STORE,
        GET_USER,
        GET_TOKEN
    } from '@/store/modules/session/session.types';
    import {EventBus} from "@/main";
    import {utils} from '@/api/utils';


    const storeModule = `apiRest`;
    const SUCCESS_TITLE_KEY = 'general.actions.success.title';
    const ERROR_TITLE_KEY = 'general.actions.error.title';
    import LoadingBar from '@/components/LoadingBar'
    import Badge from '@/components/Badge.vue'
    import BasicCheckbox from "@/components/BasicCheckbox.vue"

    export default {
        name: "ApiCheckoutButton",
        mixins: [ToastSnotify],
        components: {
            FloatingTitleLayout,
            CardSimple,
            TitleNumber,
            BasicInput,
            LoadingSimple,
            PrestaShopInstructions,
            MagentoInstructions,
            JavaScriptInstructions,
            WoocommerceInstructions,
            LoadingBar,
            Badge,
            BasicCheckbox,
            GenericTitle,
            ModalAlert
        },
        data() {
            return {
                type: "",
                title: '',
                subtitle: '',
                titleInstallation: '',
                subTitleInstallation: '',
                configuration: {
                    returnLink: '',
                    confirmationLink: '',
                    guestForm: null,
                    userForm: null,
                    domain: "",
                    pagandoEmail: null
                },
                defaultConfig: {
                    returnLink: '',
                    confirmationLink: '',
                    guestForm: true,
                    userForm: true,
                    domain: "",
                    pagandoEmail: false
                },
                typesCheckout: {
                    JAVASCRIPT: JAVASCRIPT,
                    SHOPIFY: SHOPIFY,
                    PRESTASHOP: PRESTASHOP,
                    WOOCOMERCE: WOOCOMERCE,
                    MAGENTO: MAGENTO,
                },
                editing: true,
                // Codemirror
                codeExample: 'const str = "hello world"',
                loadingDomainsInfo: true,
                loadingTestKeyInfo: true,
                loadingTestUserInfo: true,
                loadingTestInfo: true,
                loadingLiveKeyInfo: true,
                loadingLiveUserInfo: true,
                showTestKey: false,
                showLiveKey: false,
                errorNotifications: 0,
                showUserTest: false,
                usersTest: {
                    userSuccess: {
                        email: '',
                        password: ''
                    },
                    userReject: {
                        email: '',
                        password: ''
                    }
                },
                showButtonUserTest: true,
                scriptCheckout: '',
                imageModalWarning: imageModalWarning
            }
        },
        computed: {
            disableSave() {
                return this.defaultConfig.returnLink === this.configuration.returnLink && this.defaultConfig.confirmationLink === this.configuration.confirmationLink &&
                    this.defaultConfig.guestForm === this.configuration.guestForm && this.defaultConfig.userForm === this.configuration.userForm &&
                    this.defaultConfig.pagandoEmail === this.configuration.pagandoEmail
            },
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            ...mapGetters(STORE, {user: GET_USER, token: GET_TOKEN}),
            ...mapGetters(storeModule,[
                'getConfigurationStep',
                'getDomainName',
                'getOriginRoute',
                'getResponseRoute',
                'getDomainsList',
                'getApiTestKey',
                'getApiLiveKey',
                'getApiUserLive',
                'getApiUserTest',
                'getTestsInfo',
                'getCheckout',
                'getDomain',
                'getReturnLink',
                'getConfirmationLink',
                'getGuestForm',
                'getUserForm',
                'getPagandoEmail',
                'getTestVerification',
                'getApiKeysEnabled'
            ]),
            /*
            This function is use to control which sections are disable
            base on process step
             */
            stepNumber(){
                const stepDictionary = {
                    "DOMAINS_CONFIGURATION": 1,
                    "TEST_KEY_CONFIGURATION": 2,
                    "VERIFICATION_TESTS": 3,
                    "PRODUCTION_KEY_GENERATION": 4
                };

                return stepDictionary[this.getConfigurationStep];
            }
        },
        methods: {
            setDefaultValues(type) {
                let apiType;
                switch (type) {
                    case SHOPIFY:
                        apiType = 'shopify';
                        break;
                    case PRESTASHOP:
                        apiType = 'prestaShop';
                        break;
                    case MAGENTO:
                        apiType = 'magento';
                        break;
                    case WOOCOMERCE:
                        apiType = 'woocommerce';
                        break;
                    default:
                        apiType = 'javascript'; break;
                }

                this.title = `api-configuration.checkout.selected.${apiType}.second-step-title`;
                this.subtitle = `api-configuration.checkout.selected.${apiType}.subtitle`;
                this.titleInstallation = `api-configuration.checkout.selected.${apiType}.installation.title`;
                this.subTitleInstallation = `api-configuration.checkout.selected.${apiType}.installation.subtitle`;
            },

            async getConfig(type) {
                const response = await apiConfig.getCheckoutData({type: type}).catch((err) => {
                    return {
                        data: err.response.data ? err.response.data : {
                            error: true,
                            message: 'not config found'
                        }
                    }
                });
                this.setDefaultValues(type);
                if (response.data.error) {
                    this.displayNotificationError(this.$i18n.t('api-configuration.checkout.selected.general.error.get-info'))
                } else {
                    this.configuration.returnLink = response.data.object.returnLink;
                    this.configuration.confirmationLink = response.data.object.confirmationLink;
                }
                this.defaultConfig.returnLink = this.configuration.returnLink;
                this.defaultConfig.confirmationLink = this.configuration.confirmationLink;
            },

            async saveConfig() {
                this.editing = false;
                const toSend = {
                    type: this.type,
                    domain: this.configuration.domain,
                    returnLink: this.configuration.returnLink,
                    confirmationLink: this.configuration.confirmationLink,
                    guestForm: this.configuration.guestForm,
                    userForm: this.configuration.userForm,
                    pagandoEmail: this.configuration.pagandoEmail
                };
                this.defaultConfig.guestForm = this.configuration.guestForm;
                this.defaultConfig.userForm = this.configuration.userForm;
                this.defaultConfig.returnLink = this.configuration.returnLink;
                this.defaultConfig.domain = this.configuration.domain;
                this.defaultConfig.confirmationLink = this.configuration.confirmationLink;
                this.defaultConfig.pagandoEmail = this.configuration.pagandoEmail;
                const response = await apiConfig.saveCheckoutData(toSend).catch((err) => {
                    return {
                        data: err.response.data ? err.response.data : {
                            error: true,
                            message: 'no save data'
                        }
                    }
                });

                if (response.data.error) {
                    let message = "api-configuration.checkout.selected.general.error.";
                    message += response.status === 500 ? 'update-config' : 'save-checkout';
                    this.displayNotificationError(this.$i18n.t(message));
                } else {
                    this.displayNotificationSuccess(this.$i18n.t("api-configuration.checkout.selected.general.success.updating-checkout"))
                }
                this.editing = true;
            },
            /*
            This function gets all general info for api configuration as process step and domains info
             */
            getApiConfigurationInfo() {
                this.setDefaultValues(this.type);
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Selected organization
                    user: this.user._id,
                    type: this.type
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.$store.dispatch(`${storeModule}/getOrganizationApiInfo`, params);
            },
            /*
            This function gets a summary of test information as:
            - Test name
            - Test status
             */
            getTestInfo() {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Selected organization
                    user: this.user._id,
                    type: this.type
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.$store.dispatch(`${storeModule}/getTestInformation`, params);
            },
            /*
            This function is used to gets key information and regenerate if it is needed

            NOTE: This key is used for jwt authentication
             */
            getKey(type, generate, ifFromButton) {
                const params = {
                    id: this.currentOrganization._id.toString(), // Selected organization
                    type: type,
                    generate: generate,
                    checkoutType: this.type,
                    category: this.currentOrganization.category
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                this.$store.dispatch(`${storeModule}/getApiKey`, params);

                if (ifFromButton && type === 'Test') {
                    this.showTestKey = true;
                } else if(ifFromButton && type === 'Live') {
                    this.showLiveKey = true;
                }
            },
            /*
            This function is used to gets user identifier used in jwt authentication
             */
            getUser(type) {
                const params = {
                    id: this.currentOrganization._id.toString(), // Selected organization
                    type: type
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                this.$store.dispatch(`${storeModule}/getApiUser`, params);
            },

            /*
            This function send the key text to clipboard
             */
            async copyClipboard(text) {
                try {
                    await navigator.clipboard.writeText(text);
                    utils.toast(this.$snotify, 'success', this.$t('general.actions.copy-clipboard.success'), this.$t(SUCCESS_TITLE_KEY));
                } catch(err) {
                    utils.toast(this.$snotify, 'error', this.$t('general.actions.copy-clipboard.error'), this.$t(ERROR_TITLE_KEY));
                }
            },
            /*
            This function gets the correct color for test status
             */
            getBadgeColor(testStatus) {
                const statusBadges = {
                    "PENDENT": "badge-placeholder",
                    "APPROVED": "badge-success",
                    "ERROR": "badge-error"
                };

                return statusBadges[testStatus];
            },
            /*
            This function gets the correct text for test status
             */
            getApiText(testStatus) {
                const statusBadges = {
                    "PENDENT": this.$i18n.t('api-configuration.api-keys.third-step.pendent-test-status'),
                    "APPROVED": this.$i18n.t('api-configuration.api-keys.third-step.approved-test-status'),
                    "ERROR": this.$i18n.t('api-configuration.api-keys.third-step.error-test-status')
                };

                return statusBadges[testStatus];
            },
            checkGuestForm (value) {
                this.configuration.guestForm = value;
            },
            checkUserForm (value) {
                this.configuration.userForm = value;
            },
            checkPagandoEmail (value) {
                this.configuration.pagandoEmail = value;
            },
            verifyAllTests(){
                const params = {
                    organizationId: this.currentOrganization._id.toString(),
                    checkout: this.type
                };
                this.$store.dispatch(`${storeModule}/verifyAllTests`, params);
            },

            async makeUserTest() {
                this.showButtonUserTest = false;
              const response = await apiConfig.getUserTest({organizationId: this.currentOrganization._id.toString(), checkout: this.type}).catch((err) => {
                  return {
                      data: err.response.data ? err.response.data : {
                          error: true,
                          message: ''
                      }
                  }
              });

              if (response.data.error) {
                  this.showUserTest = false;
              } else {
                  this.usersTest = response.data.object;
                  this.showUserTest = true;
              }
                this.showButtonUserTest = true;
            },
            setNewRedirectRoutes(domainRoute){
                // TODO: url regex validator
                let returnLink;
                let confirmationLink;
                switch (this.type) {
                case this.typesCheckout.PRESTASHOP:
                    returnLink = 'module/pagandopayment/redirectorder';
                    confirmationLink = 'module/pagandopayment/confirm';
                    break;
                case this.typesCheckout.WOOCOMERCE:
                    returnLink = '';
                    confirmationLink = 'wc-api/wc_pagando_gateway';
                    break;
                case 'MAGENTO':
                    returnLink = '/pagando/checkout/cancel';
                    confirmationLink = '/pagando/checkout/success';
                    break;
                default:
                    returnLink = '';
                    confirmationLink = '';
                    break;
                }

                this.configuration.returnLink = `${domainRoute}/${returnLink}`.replace(/(https?:\/\/)|(\/){2,}/g, "$1$2")
                this.configuration.confirmationLink = `${domainRoute}/${confirmationLink}`.replace(/(https?:\/\/)|(\/){2,}/g, "$1$2")
            },
            async getLiveKey() {

                const params= {
                    previousKey: this.getApiLiveKey,
                    user: this.user._id,
                    organization: this.currentOrganization._id.toString(),
                    checkoutType: this.type
                }

                await this.$store.dispatch(`${storeModule}/saveKeyProductionHistory`, params);
                this.getKey('Live', true, true);
                this.$bvModal.hide('modal-danger');
            },
            async checkApiKeysEnabled() {
                const params= {organizationId: this.currentOrganization._id.toString()};

                await this.$store.dispatch(`${storeModule}/checkApiKeysEnabled`, params);
            }
        },
        created() {
            this.type = this.$route.params.type;
            this.getApiConfigurationInfo();

            EventBus.$on('apiConfigurationInfoLoaded', () =>{
                this.defaultConfig.returnLink = this.getReturnLink;
                this.defaultConfig.domain = this.getDomain;
                this.defaultConfig.confirmationLink = this.getConfirmationLink;
                this.defaultConfig.guestForm = this.getGuestForm;
                this.defaultConfig.userForm = this.getUserForm;
                this.defaultConfig.pagandoEmail = this.getPagandoEmail;
                this.configuration.domain = this.getDomain;
                this.configuration.returnLink = this.getReturnLink;
                this.configuration.confirmationLink = this.getConfirmationLink;
                this.configuration.guestForm = this.getGuestForm;
                this.configuration.userForm = this.getUserForm;
                this.configuration.pagandoEmail = this.getPagandoEmail;
                this.$refs.CHECKBOX_GUEST.checkStoreValue(this.getGuestForm);
                this.$refs.CHECKBOX_USER.checkStoreValue(this.getUserForm);
                this.$refs.CHECKBOX_EMAIL.checkStoreValue(this.getPagandoEmail);
                this.loadingDomainsInfo = false;
            });
            this.getKey("Live", false, false);
            this.getUser("Live");
            this.getKey("Test", false, false);
            this.getUser("Test");
            this.getTestInfo();
            this.checkApiKeysEnabled();

            EventBus.$on("testKeyLoaded", () => {
                this.loadingTestKeyInfo = false;
            });
            EventBus.$on("verificationTestsDone", () => {
                this.displayNotificationSuccess(this.$i18n.t("api-configuration.checkout.selected.prestaShop.verification.success"));
                this.getApiConfigurationInfo();
                this.getTestInfo();
            });

            EventBus.$on("testUserLoaded", () => {
                this.loadingTestUserInfo = false;
            });

            EventBus.$on("testInfoLoadedAR", () => {
                this.loadingTestInfo = false;
                this.$store.dispatch(`${storeModule}/getTestsVerification`);
            });

            EventBus.$on("liveKeyLoaded", () => {
                this.loadingLiveKeyInfo = false;
            });

            EventBus.$on("liveUserLoaded", () => {
                this.loadingLiveUserInfo = false;
            });

            EventBus.$on("verificationTestsPending", () => {
                this.displayNotificationWarning(this.$i18n.t("api-configuration.checkout.selected.prestaShop.verification.incomplete"));
            });

            EventBus.$on("verificationTestsError", () => {
                this.display(this.$i18n.t("api-configuration.checkout.selected.prestaShop.verification.error"));
            });

            EventBus.$on("ApiError", (problemName) => {
                const vueScope = this;

                // Avoid multiple toast error at same time
                vueScope.errorNotifications += 1;

                if (vueScope.errorNotifications === 1) {
                    if (problemName){
                        utils.toast(
                            this.$snotify,
                            'error',
                            vueScope.$i18n.t('api-configuration.toast-error-title'),
                            vueScope.$i18n.t('api-configuration.toast-error-text', [vueScope.$i18n.t(problemName)])
                        );
                    } else {
                        utils.toast(this.$snotify, 'error', vueScope.$i18n.t('api-configuration.toast-error-title'), vueScope.$i18n.t('api-configuration.toast-error-text'));
                    }
                }

                // Enable to receive toast errors
                setTimeout(function() {
                    vueScope.errorNotifications = 0;
                }, 1000);
            });
        },
        async beforeMount() {
          await this.makeUserTest();
        },
        beforeDestroy() {
            EventBus.$off('apiConfigurationInfoLoaded');
            EventBus.$off("testKeyLoaded");
            EventBus.$off("testUserLoaded");
            EventBus.$off("testInfoLoadedAR");
            EventBus.$off("liveKeyLoaded");
            EventBus.$off("liveUserLoaded");
            EventBus.$off("ApiError");
            EventBus.$off("verificationTestsDone");
            EventBus.$off("verificationTestsPending");
            EventBus.$off("verificationTestsError");
        }
    }
</script>

