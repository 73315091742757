import userApi from '../../../api/user/users.api';

const stateGlobal = {
    isLoadingConfirm: true,
    alertMessage: "",
    showAlert: false,
    completeNewRegister: false
};

import {
    GET_IS_LOADING_CONFIRM,
    GET_SHOW_ALERT,
    GET_ALERT_MESSAGE,
    CONFIRM_EMAIL,
    SET_LOADING_CONFIRM,
    SET_ALERT_MESSAGE,
    SET_COMPLETE_NEW_REGISTER,
    GET_COMPLETE_NEW_REGISTER
} from './verificationEmail.types'

const getters = {
    [GET_IS_LOADING_CONFIRM]: (state) => state.isLoadingConfirm,
    [GET_SHOW_ALERT]: (state) => state.showAlert,
    [GET_ALERT_MESSAGE]: (state) => state.alertMessage,
    [GET_COMPLETE_NEW_REGISTER]: (state) => state.completeNewRegister,
};

const actions = {

    [CONFIRM_EMAIL]: async ({commit}, {token}) =>{
        commit('SET_LOADING_CONFIRM', true);
        const response = await userApi.confirmEmail({id: token});
        if (response.data.error) {
            commit(SET_ALERT_MESSAGE, {
                message: response.data.message
            });
            commit(SET_LOADING_CONFIRM, false);
        } else {
            commit(SET_LOADING_CONFIRM, false);
        }
    },
};

const mutations = {
    [SET_LOADING_CONFIRM]: (state, value)=>{
        state.isLoadingConfirm = value;
    },
    [SET_ALERT_MESSAGE]: (state, data)=>{
        state.showAlert = true;
        state.alertMessage = data.message;
    },
    [SET_COMPLETE_NEW_REGISTER]: (state, data)=>{
        state.completeNewRegister = data;
    },
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    }
};
