

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
// Plugins
import sentryManager from '@/plugins/sentry';
import '@/plugins/vue-multiselect';
import '@/plugins/bootstrap';
import '@/plugins/vue-snotify';
import '@/plugins/vueapexcharts';
import '@/plugins/vue-scrollto';
import '@/plugins/vue-tel-input';
import '@/plugins/vue-croppie';
import '@/plugins/vue-radial-progress';
import '@/plugins/vue-form-wizard';
import '@/plugins/vueSession';
import '@/plugins/vuelidate';
import '@/plugins/vue-textresize';
import '@/plugins/vue-slider';
import '@/plugins/vue-clockpicker';
import '@/plugins/vuejs-daterangepicker';
import '@/plugins/currencyFilter';
import '@/plugins/vue2-google-maps.js';
import '@/plugins/vue-carousel';
import VueI18n from 'vue-i18n';
import es from '@/plugins/i18n/es.js'
import base from '@/api/base.api';
import axios from 'axios';
import { abilitiesPlugin } from '@casl/vue';
import usersApi from '@/api/user/users.api';
import { Ability } from "@casl/ability";
let ability = new Ability();
import config from '@/config';
import VueGtm from "vue-gtm";
import ToastSnotifyMixin from "@/mixins/toastSnotify.mixin";
import lodash from 'lodash';
import * as requestsOrigins from '@/enums/requestsOrigins.enum';
Vue.prototype.$_ = lodash;

// Conekta.setPublicKey(config.conekta.publicKey);
axios.defaults.baseURL = base.baseUrl;
Vue.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  });
Vue.use(axios);
Vue.use(VueI18n);
Vue.mixin(ToastSnotifyMixin);

axios.defaults.headers.common['X-Request-Platform'] = requestsOrigins.webClient;
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('currentToken');
        if(token !== null ){
            config.headers.Authorization = `Bearer ${token.replace(/"/g, "")}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// check any authorization errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const routesToIgnore = [
            '/api-v2/sign-up-organization/summary/organization',
        ];
        if (!routesToIgnore.includes(error.config.url) && (error.response.status === 401 || error.response.status === 403)) {
            router.push('/logout');
        }
        return Promise.reject(error);
    }
);

// Only for vendors css
import '@/plugins/vendors-css';

import {
    STORE as SessionStore,
    SET_USER,
    SET_TOKEN,
    SET_TOKEN_EXPIRE_DATE,
} from '@/store/modules/session/session.types';

import {
    STORE as AccessConfigStore,
    GET_CURRENT_ORGANIZATION,
    SET_CURRENT_ORGANIZATION
} from '@/store/modules/accessconfig/accessConfig.types';

export const EventBus = new Vue();

import {init as InitSocialNetworks} from '@/common/SocialNetworks';
InitSocialNetworks({
    facebookId: '2061471037406007'
});

// Sentry initialize
sentryManager.init();

Vue.use(
    VueGtm,
    {
        id: config.trackingTags.googleTagManagerId,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: router
    }
);

const i18n = new VueI18n({
    locale: 'es', // set locale
    messages: {es}, // set locale messages
})

const beforeMount = function() {
    const routesToBypass = ['PagandoCheckHireLayout'];
    if (routesToBypass.includes(this.$route.name)) {
        return;
    }
    const currentUser = localStorage.getItem('currentUser');
    const currentToken = localStorage.getItem('currentToken');
    // const currentPermissions = localStorage.getItem('currentPermissions');
    const expireDate = localStorage.getItem('expireDate');
    const currentOrganization = localStorage.getItem(GET_CURRENT_ORGANIZATION);

    if (currentToken && expireDate) {
        this.$store.commit(`${SessionStore}/${SET_TOKEN}`, JSON.parse(currentToken));
        this.$store.commit(`${SessionStore}/${SET_TOKEN_EXPIRE_DATE}`, JSON.parse(expireDate));
        if (currentUser) {
            store.commit(`${SessionStore}/${SET_USER}`, JSON.parse(currentUser));
        } else {
            usersApi.getCurrentUserSession({}).then((data) => {
                localStorage.setItem('currentUser', JSON.stringify(data.data.user));
                store.commit(`${SessionStore}/${SET_USER}`, data.data.user);
            });
        }
        if (currentOrganization && currentOrganization !== 'undefined') {
            this.$store.commit(`${AccessConfigStore}/${SET_CURRENT_ORGANIZATION}`, JSON.parse(currentOrganization));
        }
    }
};

Vue.config.productionTip = false;
new Vue({
    router,
    i18n: i18n,
    store,
    beforeMount,
    render: h => h(App)
}).$mount("#app");
