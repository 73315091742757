import Vue from 'vue';
import vueScrollto from 'vue-scrollto';

/*
* Use Vue ScrollTo.js
*/
const VueScrollTo = vueScrollto;
Vue.use(VueScrollTo, {
    duration: 50,
    easing: "ease",
});
