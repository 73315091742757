<template>
    <div>
        <!--  FAVOR DE NO MODIFICAR NINGUN COMPONENTE DE ESTA VISTA, SOLO ES DE EJEMPLO -->
        <div class="register-container">
            <div class="left-side-content">
                <div class="container-left">
                    <img class="img-fluid logo" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" width="166" height="60" alt="Logo Pagando Check"/>
                    <span class="d-block w-100">
                        <p class="info-text">
                            Digitaliza tu negocio aceptando <strong>pagos con terminal</strong>,
                            integrando nuestra <strong>API</strong> a tu sitio web o usando nuestros
                            <strong>plugins de pago</strong> disponibles en las soluciones
                            de e-commerce más populares.
                        </p>
                        <div class="border-container">
                            <p class="info-text m-b-15 text-left">
                                Para realizar la contratación de este servicio, solo
                                es necesario que completes la siguiente información:
                            </p>
                            <ul class="info-text-list">
                                <li>Datos de contacto de tu negocio</li>
                                <li>Subir documentos</li>
                                <li>Ubicación de tu negocio desde un mapa</li>
                            </ul>
                        </div>
                        <p class="info-text m-b-0">
                            Nuestro equipo de soporte se comunicará contigo al correo
                            <strong class="c-info">andreaguerra@gmail.com</strong> para notificarte
                            sobre la aprobación de tu solicitud.
                        </p>
                    </span>
                    <div class="footer-help">
                        <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
                            <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" width="365" height="153" alt="Chat de soporte" />
                        </a>
                        <p class="help-num">
                            También puedes contactarnos a los teléfonos:
                            <br/>
                            <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="right-side-content">
                <div class="container-right">
                    <TitlePrincipal
                        title="Procesando tu solicitud" clazz="text-center">
                        <p class="subtitle">
                            Toda tu información está siendo validada,
                            <strong>recuerda estar pendiente de tu correo</strong>
                            ya que nuestro equipo de soporte se contactará contigo
                            para ver los pasos a seguir una vez validada tu solicitud.
                        </p>
                    </TitlePrincipal>
                    <img class="img-fluid max-h-300px d-flex m-0-auto"
                        src="@/assets/images/Illustrations/illustration-procesando.svg"
                        width="520"
                        height="300"
                        alt="Procesando tu solicitud"/>
                    <span>
                        <label class="f-14 c-text_title poppins-font-medium f-500 text-center d-block m-t-20 m-b-5">
                            Estatus actual:
                        </label>
                        <div class="vertical-center justify-content-center">
                            <p class="f-24 c-primary poppins-font-bold f-700 text-center m-b-0">
                                Solicitud en proceso
                            </p>
                            <span class="loading-note no-cpu"></span>
                        </div>
                    </span>

                    <div class="footer-buttons center">
                        <button type="button" class="button sm button-filled button-primary" name="Ir a Pagando Check">
                            <i aria-hidden="true" class="banana banana-arrow-right m-r-10"></i>
                            Ir a Pagando Check
                        </button>
                    </div>
                </div>
            </div>
            <div class="footer-help responsive">
                <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
                    <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" width="365" height="153" alt="Chat de soporte" />
                </a>
                <p class="help-num">
                    También puedes contactarnos a los teléfonos:
                    <br/>
                    <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import TitlePrincipal from "@/components/TitlePrincipal.vue";

    export default {
        name: "PagandoCheckProcessingUx",
        components: {
            TitlePrincipal,
        },
        data() {
            return {
            }
        },
        methods: {
        }
    };
</script>
