<template>
    <div class="loading-full-page">
        <div class="content-loading">
            <div class="logo">
                    <img class="" src="https://cucumber.blacklabs.mx/images/Pagando/logos/pagando-logo-small.svg" alt="">
            </div>
            <div class="title">
                <h1>
                    {{title}}
                    <strong>{{strongTitle}}</strong>
                </h1>

                <p v-if="textComplete">{{text}}</p>
            </div>
            <slot></slot>
            <button :id="pagandoBisId" v-if="btnContinue" class="button xs button-filled button-accent1 m-auto" @click="btnClick">{{btnText}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProcessLoadingPage',
        props: {
            title: String,
            strongTitle: String,
            text: String,
            btnText: String,
            btnContinue: {type: Boolean, default: false},
            textComplete: {type: Boolean, default: false},
            pagandoBisId: String
        },
        methods: {
            btnClick(){
                this.$emit('btnClick');
            }
        }
    }
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
.icon-rotate {
    height: 16px;
    width: 16px;
    margin: 0px;
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
    i {
        margin-right: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.loading-full-page {
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    .button {
        margin-top: 80px !important;
    }
    .content-loading {
        text-align: center;
        padding: 0px 20px;
        .logo {
            img {
                width: 100px;
                height: auto;
            }
            margin-bottom: 30px;
        }
        p {
            font-size: 20px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 80px;
            margin-top: -25px;
        }
        .title {
            margin-bottom: 30px;
            h1 {
                font-size: 36px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
            }
            p {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: center;
                margin: 0px;
            }
        }
        .item {
            display: flex;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 20px;
            align-items: center;
            justify-content: center;
            &.passed {
                opacity: 0.3;
                span {
                    display: none;
                }
                .icon-rotate {
                    display: none;
                }
                .c-success {
                    display: block;
                }
            }
            .c-success {
                display: none;
            }
            .icon-rotate {
                position: relative;
            }
        }
    }
}
</style>
