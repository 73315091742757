<template>
    <div class="organization-info" :class="computedClass" @click="click">
        <div class="org-img">
            <img class="img-fluid" :src="orgLogo" alt="organization"/>
            <i aria-hidden="true" class="banana banana-locker-locked"></i>
        </div>
        <span class="org-info">
            <Badge v-if="status ==='active'" type="badge-success" text="Activa"></Badge>
            <Badge v-if="status ==='inactive'" type="badge-error" text="Inactiva"></Badge>
            <Badge v-if="status ==='comingSoon'" type="badge-accent_2" text="Próximamente"></Badge>
            <Badge v-if="status ==='blocked'" type="badge-error" text="Bloqueada"></Badge>

            <label>{{orgName}}</label>
            <small>{{numServices}}</small>
        </span>
    </div>
</template>

<script>
    import Badge from '@/components/Badge.vue';

    export default {
        name: 'OrgInfo',
        components: {
            Badge
        },
        computed: {
            computedClass: function(){
                if(this.status === "blocked"){
                    return `${this.$props.clazz} disabled-org`
                }
                return this.$props.clazz
            }
        },
        props: {
            clazz: String,
            orgName: String,
            numServices: String,
            activeOrg: {type: Boolean, default: false},
            inactiveOrg: {type: Boolean, default: false},
            waitingOrg: {type: Boolean, default: false},
            orgLogo: {type: String, default: '/img/logo-color-xs.b84420c7.svg'},
            status: {type: String, default: 'comingSoon'}
        },
        methods: {
            click() {
                this.$emit('click')
            }
        }
    }
</script>

<style lang="scss">
    .organization-info {
        position: relative;
        width: calc(100% + 20px);
        min-height: 66px;
        height: fit-content;
        margin: 0 -10px 5px;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: solid 1.5px transparent;
        background-color: transparent;
        transition: all 0.5s;

        &::before {
            content: "\EA1B";
            font-size: 14px;
            font-weight: bold;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -8px;
            right: -8px;
            opacity: 0;
            transition: all 0.5s;
        }

        &:hover .org-img img {
            transform: rotate(-10deg) scale(1.1);
        }

        &.selected {
            &::before {
                opacity: 1;
            }
        }

        .org-img {
            border-radius: 6px;
            position: relative;
            width: 50px;
            min-width: 50px;
            height: 50px;
            max-height: 50px;
            margin-right: 12px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                min-width: 50px;
                min-height: 50px;
                object-fit: contain;
                object-position: center;
                margin: auto;
                display: flex;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                position: absolute;
                transition: all 0.5s;
            }

            > .banana-locker-locked {
                display: none;
            }
        }

        .org-info {
            display: block;
            overflow: hidden;

            > label {
                font-size: 12px;
                font-weight: bold;
                line-height: 1.5;
                display: block;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            > small {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.17;
                display: block;
                margin-bottom: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.disabled-org {
            .org-img {
                > img {
                    z-index: -1;
                }

                > .banana-locker-locked {
                    font-size: 25px;
                    position: absolute;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
    }
</style>
