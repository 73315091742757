import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'users';

export default {
    save: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/register`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getCurrentUserSession: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-current-user-session`)
            .then(onSuccess)
            .catch(onError);
    },
    getFullUser: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-full-user`)
            .then(onSuccess)
            .catch(onError);
    },
    agendUserCall: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/agend-user-call`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getActualContractPreviewClient: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/documents/organization/actual-contract-client/${params.idOrganization}`)
            .then(onSuccess)
            .catch(onError);
    },
    acceptPreContractClient: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/documents/accept-precontract-client`, params)
            .then(onSuccess)
            .catch(onError);
    },
    changeProfileSettings: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/change-profile`, params)
            .then(onSuccess)
            .catch(onError);
    },
    profileTypeUser: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-profile-type-user`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getUserByEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-user-by-email`, params)
            .then(onSuccess)
            .catch(onError);
    },
    login: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/login-v2`, params)
            .then(onSuccess)
            .catch(onError);
    },
    logout: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/logout-v2`, params)
            .then(onSuccess)
            .catch(onError);
    },
    loginSocialMedia: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/auth/google/callback/client`, {params: params})
            .then(onSuccess)
            .catch(onError);
    },
    confirmFaceookLogin: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmSocialMediaLoginClient`, params)
            .then(onSuccess)
            .catch(onError);
    },
    verifyCode: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/verify-code`, params)
            .then(onSuccess)
            .catch(onError);
    },
    sendVerificationCode: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/send-code`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmEmail`, params)
            .then(onSuccess)
            .catch(onError);
    },
    savePersonalInformation: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/personal-information/save`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmWizardRegisterUser: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-one/finish-user-wizard`, params)
            .then(onSuccess)
            .catch(onError);
    },
    resendVerificationEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/resend-verification-email`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getActualTermsPreview: (params, onSuccess, onError) => {
        const user = JSON.parse(localStorage.currentUser);
        return axios.get(`${base.baseUrl}/documents/actual-terms/${user._id}`, params)
            .then(onSuccess)
            .catch(onError);
    },
    acceptPreTerms: (params, onSuccess, onError) => {
        const user = JSON.parse(localStorage.currentUser);
        return axios.post(`${base.baseUrl}/documents/accept-preterms`,{userId: user._id, profileType: "OWNER"}, params)
            .then(onSuccess)
            .catch(onError);
    },
    getCountries: (params, onSuccess, onError) => {
        return axios({
            url: `${base.baseUrl}/${namespace}/get/countries`,
            data: params,
            method: 'GET',
        }).then(onSuccess).catch(onError);
    },
    checkActiveSessions: (onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/check-access`)
            .then(onSuccess)
            .catch(onError);
    },
    disableAccess: (onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/disable-access`)
            .then(onSuccess)
            .catch(onError);
    },
    recoveryPassword: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/recoveryPassword`, params)
            .then(onSuccess)
            .catch(onError);
    },
    resendRestorePasswordEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/resendRestorePasswordEmail`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmPasswordRestore: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmRestorePassword`, params)
            .then(onSuccess)
            .catch(onError)
    },
    recoveryUserBySMS: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/recovery-user-phone`, params )
            .then(onSuccess)
            .catch(onError);
    },
}
