<template>
    <div>
        <!-- ONBOARDING MENU -->
        <OnboardingComponent
            :activeOnboarding="false"
            title="¿Necesitas un recorrido?"
            subtitle="Para ayudarte a sacar el máximo provecho a Pagando, hemos preparado un recorrido rápido
                            para que conozcas las funciones principales disponibles para tu Organización."
            closeModal="Después"
            beginOnboarding="Comenzar"
        ></OnboardingComponent>

        <!-- LEFT SIDEBAR MENU -->
        <SidebarMenu
            :isSidebarSmall="isSidebarSmall"
            :isSidebarLeftOpen="isSidebarLeftOpen"
        >
            <template v-slot:closeBtn>
                <button
                    class="hamburger hamburger--arrowalt js-hamburger"
                    :class="{'is-active' : !isSidebarSmall}"
                    type="button"
                    @click="menuSidebar"
                >
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </template>

            <template v-slot:footerBtn>
                <div
                    class="actual-org"
                    @click="openSidebarLeft"
                >
                    <div class="img-org">
                        <img
                            class="img-fluid"
                            src="@/assets/images/Logos/logo-color-xs.svg"
                            alt="organization"
                        />
                    </div>
                    <span>
                        <label>Organización</label>
                        <p>Transtelco SA. de CV.</p>
                    </span>
                    <i
                        aria-hidden="true"
                        class="banana banana-triangle-down"
                    ></i>
                </div>
            </template>
        </SidebarMenu>

        <!-- TOP HEADER ADMIN -->
        <TopbarHeader>
            <li>
                <a
                    class="hover"
                    @click="openSidebarRight"
                    tabindex
                >
                    <i
                        aria-hidden="true"
                        class="banana f-30 c-primary_1"
                        v-bind:class="[isSidebarRightOpen ? 'banana-light-on': 'banana-light']"
                    ></i>
                </a>
            </li>
        </TopbarHeader>

        <!-- LEFT SIDEBAR -->
        <SidebarSide
            sidebarLeft
            :isSidebarLeftOpen="isSidebarLeftOpen"
            titleLeft="Administrar otra Organización"
            subtitleLeft="Selecciona la Organización que deseas administrar:"
        >
            <template v-slot:headLeft>
                <a
                    class="close-sidebar-left-header"
                    @click="openSidebarLeft"
                    tabindex
                >
                    <i
                        aria-hidden="true"
                        class="banana banana-cross-normal"
                    ></i>
                </a>
                <BasicInput
                    inputType="text"
                    inputPlaceholder="Buscar organización..."
                    inputClazz="icon-left"
                    iconInput="banana-search-normal"
                    clazz="noLabel"
                />
            </template>
            <template v-slot:bodyLeft>
                <h1 class="title-group-org">Organizaciones Activas</h1>
                <OrgInfo
                    activeOrg
                    clazz="selected"
                    orgName="Transtelco SA. de CV."
                    numServices="10 Servicios"
                />
                <OrgInfo
                    activeOrg
                    orgName="Black Labs"
                    numServices="2 Servicios"
                />
                <OrgInfo
                    activeOrg
                    orgName="Semillas Sembrando Sueños"
                    numServices="7 Servicios"
                />

                <h1 class="title-group-org m-t-50">Organizaciones Inactivas</h1>
                <OrgInfo
                    inactiveOrg
                    orgName="La Rodadora"
                    numServices="3 Servicios"
                />

                <h1 class="title-group-org m-t-50">Esperando Activación</h1>
                <OrgInfo
                    waitingOrg
                    orgName="Agencia 19"
                    numServices="1 Servicios"
                />
            </template>
            <template v-slot:footerLeft>
                <a
                    href
                    class="button-square-add"
                    tabindex
                >
                    <i
                        aria-hidden="true"
                        class="banana banana-symbol-plus"
                    ></i> Crear Organización
                </a>
            </template>
        </SidebarSide>

        <!-- RIGHT SIDEBAR -->
        <SidebarSide
            sidebarRight
            :isSidebarRightOpen="isSidebarRightOpen">
            <template v-slot:contentRight>
                <div class="p-t-30">
                    <!-- EN PROCESO DE VALIDACION -->
                    <ChartProgressInfo
                        :progress="70"
                        :doneProgress="100"
                        :customText="true"
                        :halfComplete="true"
                        upText="Solicitud"
                        downText="Validación con inconsistencias">
                    </ChartProgressInfo>
                    <label class="f-13 c-primary_1 principal-font-bold f-bold text-align-c text-uppercase d-block m-b-10">
                        CONTRATACIÓN DE SERVICIO
                    </label>
                    <p class="f-13 c-plain_text body-font-regular f-normal text-align-c d-block m-b-20">
                        Tu solicitud presenta algunas inconsistencias con
                        los datos que proporcionaste. <br/> ¡Pero no te preocupes!,
                        nuestro equipo de soporte se contactará contigo.
                        Si deseas contactar directamente a soporte puedes hacerlo desde aquí.
                    </p>
                    <button id=""
                            type="button"
                            class="button xs button-stroke button-accent1 w-100 m-b-30">
                            CONTACTAR A SOPORTE
                    </button>
                    <SimpleCollapse
                        v-b-toggle.collapse-validacion-inconsistencias
                        idCollapse="collapse-validacion-inconsistencias"
                        title="Datos solicitados">
                        <ul class="timeline-verified">
                            <li class="missing"> Datos de contacto de tu negocio </li>
                            <li class="missing"> Selección de la forma de cobrar </li>
                            <li class="missing"> Subida de documentos </li>
                            <li class="missing"> Ubicación de tu negocio </li>
                        </ul>
                    </SimpleCollapse>

                    <!-- VALIDACION CON INCONSISTENCIAS -->
                    <ChartProgressInfo
                        :progress="40"
                        :doneProgress="100"
                        :customText="true"
                        :no-coplete="true"
                        upText="Solicitud"
                        downText="En proceso de validación">
                    </ChartProgressInfo>
                    <label class="f-13 c-primary_1 principal-font-bold f-bold text-align-c text-uppercase d-block m-b-10">
                        CONTRATACIÓN DE SERVICIO
                    </label>
                    <p class="f-13 c-plain_text body-font-regular f-normal text-align-c d-block m-b-20">
                        Tu solicitud para contratar Pagando Check, está siendo validada por nuestro equipo de soporte.
                        <br/>
                        El proceso puede tardar algunas horas, pero una vez comprobados tus datos,
                        se activará tu cuenta y podrás cobrar por tus  productos y servicios.
                        <br/>
                        Si deseas contactar directamente a soporte puedes hacerlo desde aquí.
                    </p>
                    <button id=""
                            type="button"
                            class="button xs button-stroke button-accent1 w-100 m-b-30">
                            CONTACTAR A SOPORTE
                    </button>
                    <SimpleCollapse
                        v-b-toggle.collapse-proceso-validacion
                        idCollapse="collapse-proceso-validacion"
                        title="Datos solicitados">
                        <ul class="timeline-verified">
                            <li class="re-do"> Datos de contacto de tu negocio </li>
                            <li class="re-do"> Selección de la forma de cobrar </li>
                            <li class="re-do"> Subida de documentos </li>
                            <li class="re-do"> Ubicación de tu negocio </li>
                        </ul>
                    </SimpleCollapse>

                    <!-- SOLICITUD APROBADA -->
                    <ChartProgressInfo
                        :progress="100"
                        :doneProgress="100"
                        :customText="true"
                        :fullComplete="true"
                        upText="¡FELICIDADES!"
                        downText="Solicitud aprobada">
                    </ChartProgressInfo>
                    <label class="f-13 c-primary_1 principal-font-bold f-bold text-align-c text-uppercase d-block m-b-10">
                        CONTRATACIÓN DE SERVICIO
                    </label>
                    <p class="f-13 c-plain_text body-font-regular f-normal text-align-c d-block m-b-20">
                        Tu solicitud ha sido aprobada exitosamente.
                        A partir de ahora puedes cobrar por tus
                        productos y servicios a tus clientes.
                    </p>
                    <SimpleCollapse
                        v-b-toggle.collapse-datos-solicitados
                        idCollapse="collapse-datos-solicitados"
                        title="Datos solicitados">
                        <ul class="timeline-verified">
                            <li class="check"> Datos de contacto de tu negocio </li>
                            <li class="check"> Selección de la forma de cobrar </li>
                            <li class="check"> Subida de documentos </li>
                            <li class="check"> Ubicación de tu negocio </li>
                        </ul>
                    </SimpleCollapse>

                    <!-- DEPRECATED -->
                    <ChartProgressInfo
                        :progress="100"
                        :doneProgress="100"
                    ></ChartProgressInfo>

                    <label class="f-13 c-primary_1 principal-font-bold f-bold text-uppercase d-block m-b-10">
                        Activación de Empresa Finalizada
                    </label>
                    <p class="f-13 c-plain_text body-font-regular f-normal d-block m-b-20">
                        <strong>¡Felicidades!</strong> Terminaste de configurar al 100% tu Organización.
                        Ahora estás totalmente listo para poder iniciar tus ventas por Pagando.
                    </p>
                    <a href=""
                        class="f-13 c-accent_2 body-font-regular text-underline d-block m-b-60"
                        tabindex="">
                        Si deseas saber cómo puedes incrementar las ventas de tu organización dentro de Pagando haz clic aquí.
                    </a>
                    <button type="button"
                            class="button xs button-filled button-accent1 w-100 m-b-30">
                        Aceptar y cerrar panel
                    </button>

                    <ChartProgressInfo
                        :progress="60"
                        :doneProgress="100"
                    ></ChartProgressInfo>

                    <ChartProgressInfo
                        :progress="20"
                        :doneProgress="100"
                    ></ChartProgressInfo>

                    <h1 class="f-13 c-primary_1 principal-font-bold f-bold text-align-c text-uppercase d-block m-b-10">
                        Activación de empresa
                    </h1>
                    <p class="f-13 c-plain_text body-font-regular f-normal d-block m-b-40">
                        Para que completes el proceso de verificación de tu empresa,
                        te pedimos que actualices la información que te solicitamos a continuación.
                    </p>
                    <b-alert
                        class="alert-note"
                        show
                        variant="info">
                        <p class="text-container">
                            <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                alt="info"
                            />
                            Tus datos han sido enviados a revisión.
                            El equipo de Pagando se pondrá en contacto
                            para actualizarte sobre el estado de verificación de tu empresa.
                        </p>
                    </b-alert>

                    <SimpleCollapse
                        v-b-toggle.collapse-logo
                        idCollapse="collapse-logo"
                        title="Logotipo"
                        showBadge
                        badgeType="badge-accent_2"
                        badgeText="1">
                        No example
                    </SimpleCollapse>

                    <SimpleCollapse
                        v-b-toggle.collapse-portada
                        idCollapse="collapse-portada"
                        title="Foto de portada"
                        showBadge
                        badgeType="badge-accent_2"
                        badgeText="1">
                        No example
                    </SimpleCollapse>

                    <SimpleCollapse
                        v-b-toggle.collapse-datosGrales
                        idCollapse="collapse-datosGrales"
                        title="Datos generales"
                        showBadge
                        badgeType="badge-placeholder"
                        badgeText="6">
                        No example
                    </SimpleCollapse>

                    <SimpleCollapse
                        v-b-toggle.collapse-datosContacto
                        idCollapse="collapse-datosContacto"
                        title="Foto de contacto"
                        showBadge
                        badgeType="badge-placeholder"
                        badgeText="6">
                        No example
                    </SimpleCollapse>

                    <SimpleCollapse
                        v-b-toggle.collapse-datosFis
                        idCollapse="collapse-datosFis"
                        title="Datos fiscales"
                        showBadge
                        badgeType="badge-placeholder"
                        badgeText="7">
                        No example
                    </SimpleCollapse>

                    <SimpleCollapse
                        v-b-toggle.collapse-datosBanc
                        idCollapse="collapse-datosBanc"
                        title="Datos bancarios"
                        showBadge
                        badgeType="badge-placeholder"
                        badgeText="3">
                        <b-alert
                            class="alert-note"
                            show
                            variant="info">
                            <p class="text-container p-r-0">
                                <span>
                                    <strong class="body-font-semibold f-600 c-accent_2"> Comentarios generales: </strong>
                                    <br />
                                    “Te sugerimos consultar con tu banco la información que te solicitamos“.
                                </span>
                            </p>
                        </b-alert>

                        <ul class="timeline-verified">
                            <li class="missing"> Nombre de banco </li>
                            <li class="missing">
                                No. de Cuenta
                                <p class="f-13 c-info_text m-b-0">
                                    Edita este campo dando
                                    <a href="" class="c-accent_2 f-bold hover" tabindex="">clic aquí.</a>
                                </p>
                            </li>
                            <li class="missing">
                                CLABE
                                <p class="f-13 c-info_text m-b-0">
                                    Para editar este campo contacta a soporte dando
                                    <a href="" class="c-accent_2 f-bold hover" tabindex="">clic aquí.</a>
                                </p>
                            </li>
                        </ul>

                        <ul class="timeline-verified">
                            <li class="checking">
                                Nombre de banco
                                <p class="f-13 c-info_text m-b-0">
                                    Este campo se ha editado y enviado a revisión
                                </p>
                            </li>
                            <li class="checking">
                                No. de Cuenta
                                <p class="f-13 c-info_text m-b-0">
                                    Este campo se ha editado y enviado a revisión
                                </p>
                            </li>
                            <li class="checking">
                                CLABE
                                <p class="f-13 c-info_text m-b-0">
                                    Este campo se ha editado y enviado a revisión
                                </p>
                            </li>
                        </ul>
                    </SimpleCollapse>

                    <SimpleCollapse
                        v-b-toggle.collapse-docs
                        idCollapse="collapse-docs"
                        title="Documentos"
                        showBadge
                        badgeType="badge-accent_2"
                        badgeText="4">
                        <ul class="timeline-verified">
                            <li class="check"> Identificación </li>
                            <li class="check"> CURP </li>
                            <li class="check"> Comprobante de domicilio </li>
                            <li class="check"> Comprobante ingresos </li>
                        </ul>
                    </SimpleCollapse>

                    <SimpleCollapse
                        v-b-toggle.collapse-ubic
                        idCollapse="collapse-ubic"
                        title="Ubicación"
                        showBadge
                        badgeType="badge-placeholder"
                        badgeText="2">
                        No example
                    </SimpleCollapse>

                    <button type="button" class="button xs button-filled button-accent1 w-100 m-b-30">
                        Enviar a revisión
                    </button>
                </div>
                <div
                    v-if="showUpdates"
                    class="card-updates">
                    <div>
                        <label>Qué hay de nuevo</label>
                        <p>Revisa las últimas novedades lanzadas en la <strong class="">Versión 1.1</strong> del mes de
                            enero.</p>
                        <div class="vertical-center">
                            <Badge
                                type="badge-accent_2"
                                text="3 actualizaciones"
                            ></Badge>
                            <button
                                @click="closeUpdates"
                                class="button xs button-ghost button-primary1 f-12 p-t-0 p-b-0 p-l-5 p-r-5 m-0-0-0-auto"
                            >
                                Ocultar
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </SidebarSide>

        <!-- MAIN CONTENT PAGE -->
        <section id="main-view">
            <div class="page-container">
                <div>
                    <!-- 'content-centered' Class to center all the content -->
                    <div class="row">
                        <div class="col-12">
                            <FloatingTitleLayout
                                msgSmall="Pagando Client Components"
                                title="Full list of components and how to use them."
                            />
                        </div>

                        <!-- ALERT NOTES -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Alert Notes</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>class:</strong> Extra class for component (String).
                                    <br />
                                    <strong>dismissible:</strong> Indicates if alert can close.
                                    <br />
                                    <strong>show:</strong> Indicates if alert shows.
                                    <br />
                                    <strong>variant:</strong> Indicates alert type (String).
                                    <br />
                                    <strong>slot:</strong> Aditional content for text.
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/alert"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-alertNotes
                                    >Show Alerts
                                    </button>
                                    <b-collapse
                                        id="collapse-alertNotes"
                                        class="m-t-20">
                                        <b-alert class="alert-note" show dismissible variant="success">
                                            <p class="text-container">
                                                <i
                                                    aria-hidden="true"
                                                    class="icon banana banana-check-circle2"
                                                    ></i>
                                                Alert variant Success.
                                            </p>
                                        </b-alert>
                                        <b-alert class="alert-note" show dismissible variant="danger">
                                            <p class="text-container">
                                                <i
                                                    aria-hidden="true"
                                                    class="icon banana banana-cross-circle2"
                                                    ></i>
                                                Alert variant Danger.
                                            </p>
                                        </b-alert>
                                        <b-alert class="alert-note" show dismissible variant="warning">
                                            <p class="text-container">
                                                <i
                                                    aria-hidden="true"
                                                    class="icon banana banana-warning-circle2"
                                                    ></i>
                                                Alert variant Warning.
                                            </p>
                                        </b-alert>
                                        <b-alert class="alert-note" show dismissible variant="info">
                                            <p class="text-container">
                                                <i
                                                    aria-hidden="true"
                                                    class="icon banana banana-info-circle2"
                                                    ></i>
                                                Alert variant Info.
                                            </p>
                                        </b-alert>
                                        <b-alert class="alert-note" show dismissible variant="accent2">
                                            <p class="text-container">
                                                <i
                                                    aria-hidden="true"
                                                    class="icon banana banana-comment"
                                                    ></i>
                                                Alert variant Accent2.
                                            </p>
                                        </b-alert>

                                        <b-alert
                                            class="alert-note"
                                            show
                                            dismissible
                                            variant="success"
                                        >
                                            <p class="text-container">
                                                <img
                                                    class="img-fluid"
                                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-success.svg"
                                                    width="38"
                                                    height="38"
                                                    alt="Alerta exito"
                                                />
                                                Alert variant Success w/image.
                                            </p>
                                        </b-alert>
                                        <b-alert
                                            class="alert-note"
                                            show
                                            dismissible
                                            variant="danger"
                                        >
                                            <p class="text-container">
                                                <img
                                                    class="img-fluid"
                                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                    width="38"
                                                    height="38"
                                                    alt="Alerta error"
                                                />
                                                Alert variant Danger w/image.
                                            </p>
                                        </b-alert>
                                        <b-alert
                                            class="alert-note"
                                            show
                                            dismissible
                                            variant="warning"
                                        >
                                            <p class="text-container">
                                                <img
                                                    class="img-fluid"
                                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                                    width="38"
                                                    height="38"
                                                    alt="Alerta cuidado"
                                                />
                                                <span>Recuerda que Pagando siempre protegerá tu información bancaria y nunca lo compartirá con terceros. <a
                                                        href=""
                                                        class="body-font-bold c-warning"
                                                        tabindex=""
                                                    >Más información.</a></span>
                                            </p>
                                        </b-alert>
                                        <b-alert
                                            class="alert-note"
                                            show
                                            dismissible
                                            variant="info"
                                        >
                                            <p class="text-container">
                                                <img
                                                    class="img-fluid"
                                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                                    width="38"
                                                    height="38"
                                                    alt="Alerta información"
                                                />
                                                <span>Para poder guardar los cambios, todos los campos utilizados deben ser capturados. Por favor actualiza la descripción
                                                    o agrega el valor de los siguientes elementos: <strong>#url, #email, #phone</strong></span>
                                            </p>
                                        </b-alert>

                                        <b-alert class="alert-note" show variant="accent2">
                                            <p class="text-container c-plain_text f-10 d-block">
                                                Si deseas una contraseña segura sigue estas recomendaciones:
                                                <ul class="m-t-5 m-0 p-0 list-style-none">
                                                    <li class="f-10 c-accent_2 poppins-font-semibold line-height-1 m-b-5 d-flex">
                                                        <i aria-hidden="true" class="banana banana-check-normal f-12 m-r-10"></i>
                                                        Usar 6 o más caracteres
                                                    </li>
                                                    <li class="f-10 c-error poppins-font-semibold line-height-1 m-b-5 d-flex">
                                                        <i aria-hidden="true" class="banana banana-cross-normal f-12 m-r-10"></i>
                                                        Usar al menos un número
                                                    </li>
                                                    <li class="f-10 c-error poppins-font-semibold line-height-1 m-b-5 d-flex">
                                                        <i aria-hidden="true" class="banana banana-cross-normal f-12 m-r-10"></i>
                                                        Usar al menos una letra mayúscula
                                                    </li>
                                                </ul>
                                            </p>
                                        </b-alert>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- BANANA ICONS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Banana Icons</h3>
                                <p>
                                    <strong>Always should be inside a "i" tag, and class="banana
                                        banana-[icon-name]"</strong>
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-icons
                                    >Show Icons
                                    </button>
                                    <b-collapse
                                        id="collapse-icons"
                                        class="m-t-20"
                                    >
                                        <ul class="glyph">
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-3D banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">3D</span>
                                                <span class="glyph-codepoint">EA01</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-align-center banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">align-center</span>
                                                <span class="glyph-codepoint">EA02</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-align-justify banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">align-justify</span>
                                                <span class="glyph-codepoint">EA03</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-align-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">align-left</span>
                                                <span class="glyph-codepoint">EA04</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-align-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">align-right</span>
                                                <span class="glyph-codepoint">EA05</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-angle-double-down banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">angle-double-down</span>
                                                <span class="glyph-codepoint">EA06</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-angle-double-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">angle-double-left</span>
                                                <span class="glyph-codepoint">EA07</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-angle-double-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">angle-double-right</span>
                                                <span class="glyph-codepoint">EA08</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-angle-double-up banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">angle-double-up</span>
                                                <span class="glyph-codepoint">EA09</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-arrow-down banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">arrow-down</span>
                                                <span class="glyph-codepoint">EA0A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-arrow-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">arrow-left</span>
                                                <span class="glyph-codepoint">EA0B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-arrow-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">arrow-right</span>
                                                <span class="glyph-codepoint">EA0C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-arrow-up banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">arrow-up</span>
                                                <span class="glyph-codepoint">EA0D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-bell banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">bell</span>
                                                <span class="glyph-codepoint">EA0E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-blocked banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">blocked</span>
                                                <span class="glyph-codepoint">EA0F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-book banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">book</span>
                                                <span class="glyph-codepoint">EA10</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-bookmark banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">bookmark</span>
                                                <span class="glyph-codepoint">EA11</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-building banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">building</span>
                                                <span class="glyph-codepoint">EA12</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-bullhorn banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">bullhorn</span>
                                                <span class="glyph-codepoint">EA13</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-calendar banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">calendar</span>
                                                <span class="glyph-codepoint">EA14</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-camera banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">camera</span>
                                                <span class="glyph-codepoint">EA15</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-car banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">car</span>
                                                <span class="glyph-codepoint">EA16</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-charts banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">charts</span>
                                                <span class="glyph-codepoint">EA17</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-check-circle1 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">check-circle1</span>
                                                <span class="glyph-codepoint">EA18</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-check-circle2 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">check-circle2</span>
                                                <span class="glyph-codepoint">EA19</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-check-double-check banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">check-double-check</span>
                                                <span class="glyph-codepoint">EA1A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-check-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">check-normal</span>
                                                <span class="glyph-codepoint">EA1B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-chevron-down banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">chevron-down</span>
                                                <span class="glyph-codepoint">EA1C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-chevron-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">chevron-left</span>
                                                <span class="glyph-codepoint">EA1D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-chevron-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">chevron-right</span>
                                                <span class="glyph-codepoint">EA1E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-chevron-up banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">chevron-up</span>
                                                <span class="glyph-codepoint">EA1F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-clipboard-check banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">clipboard-check</span>
                                                <span class="glyph-codepoint">EA20</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-comment banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">comment</span>
                                                <span class="glyph-codepoint">EA21</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-copy banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">copy</span>
                                                <span class="glyph-codepoint">EA22</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-coupons banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">coupons</span>
                                                <span class="glyph-codepoint">EA23</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-credit-card-generic banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">credit-card-generic</span>
                                                <span class="glyph-codepoint">EA24</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-credit-card banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">credit-card</span>
                                                <span class="glyph-codepoint">EA25</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-cross-circle1 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">cross-circle1</span>
                                                <span class="glyph-codepoint">EA26</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-cross-circle2 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">cross-circle2</span>
                                                <span class="glyph-codepoint">EA27</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-cross-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">cross-normal</span>
                                                <span class="glyph-codepoint">EA28</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-currencies banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">currencies</span>
                                                <span class="glyph-codepoint">EA29</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-delete banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">delete</span>
                                                <span class="glyph-codepoint">EA2A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-device-computer banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">device-computer</span>
                                                <span class="glyph-codepoint">EA2B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-device-hololens banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">device-hololens</span>
                                                <span class="glyph-codepoint">EA2C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-device-smartphone banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">device-smartphone</span>
                                                <span class="glyph-codepoint">EA2D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-device-tablet banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">device-tablet</span>
                                                <span class="glyph-codepoint">EA2E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-device-tv banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">device-tv</span>
                                                <span class="glyph-codepoint">EA2F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-dispersions banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">dispersions</span>
                                                <span class="glyph-codepoint">EA30</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-do-redo banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">do-redo</span>
                                                <span class="glyph-codepoint">EA31</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-do-undo banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">do-undo</span>
                                                <span class="glyph-codepoint">EA32</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-document-locked banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">document-locked</span>
                                                <span class="glyph-codepoint">EA33</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-document-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">document-normal</span>
                                                <span class="glyph-codepoint">EA34</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-document-percentage banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">document-percentage</span>
                                                <span class="glyph-codepoint">EA35</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-doodle banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">doodle</span>
                                                <span class="glyph-codepoint">EA36</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-download banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">download</span>
                                                <span class="glyph-codepoint">EA37</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-dropdown banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">dropdown</span>
                                                <span class="glyph-codepoint">EA38</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-edit banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">edit</span>
                                                <span class="glyph-codepoint">EA39</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-emoji banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">emoji</span>
                                                <span class="glyph-codepoint">EA3A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-filter banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">filter</span>
                                                <span class="glyph-codepoint">EA3B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-fire banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">fire</span>
                                                <span class="glyph-codepoint">EA3C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-folder banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">folder</span>
                                                <span class="glyph-codepoint">EA3D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-foul banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">foul</span>
                                                <span class="glyph-codepoint">EA3E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-funnel banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">funnel</span>
                                                <span class="glyph-codepoint">EA3F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-closed-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-closed-left</span>
                                                <span class="glyph-codepoint">EA40</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-closed-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-closed-right</span>
                                                <span class="glyph-codepoint">EA41</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-dislike banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-dislike</span>
                                                <span class="glyph-codepoint">EA42</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-l-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-l-left</span>
                                                <span class="glyph-codepoint">EA43</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-l-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-l-right</span>
                                                <span class="glyph-codepoint">EA44</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-left</span>
                                                <span class="glyph-codepoint">EA45</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-like banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-like</span>
                                                <span class="glyph-codepoint">EA46</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hand-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hand-right</span>
                                                <span class="glyph-codepoint">EA47</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-health banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">health</span>
                                                <span class="glyph-codepoint">EA48</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-heart banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">heart</span>
                                                <span class="glyph-codepoint">EA49</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-help banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">help</span>
                                                <span class="glyph-codepoint">EA4A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-hide banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">hide</span>
                                                <span class="glyph-codepoint">EA4B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-home banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">home</span>
                                                <span class="glyph-codepoint">EA4C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-image banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">image</span>
                                                <span class="glyph-codepoint">EA4D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-info-circle1 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">info-circle1</span>
                                                <span class="glyph-codepoint">EA4E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-info-circle2 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">info-circle2</span>
                                                <span class="glyph-codepoint">EA4F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-input-drag banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">input-drag</span>
                                                <span class="glyph-codepoint">EA50</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-internet banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">internet</span>
                                                <span class="glyph-codepoint">EA51</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-light-on banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">light-on</span>
                                                <span class="glyph-codepoint">EA52</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-light banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">light</span>
                                                <span class="glyph-codepoint">EA53</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-loading banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">loading</span>
                                                <span class="glyph-codepoint">EA54</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-location banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">location</span>
                                                <span class="glyph-codepoint">EA55</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-locker-locked banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">locker-locked</span>
                                                <span class="glyph-codepoint">EA56</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-locker-unlocked banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">locker-unlocked</span>
                                                <span class="glyph-codepoint">EA57</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-log-out banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">log-out</span>
                                                <span class="glyph-codepoint">EA58</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-menu banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">menu</span>
                                                <span class="glyph-codepoint">EA59</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-message banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">message</span>
                                                <span class="glyph-codepoint">EA5A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-microphone-off banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">microphone-off</span>
                                                <span class="glyph-codepoint">EA5B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-microphone-on banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">microphone-on</span>
                                                <span class="glyph-codepoint">EA5C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-move banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">move</span>
                                                <span class="glyph-codepoint">EA5D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-options-all banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">options-all</span>
                                                <span class="glyph-codepoint">EA5E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-options-other-horizontal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">options-other-horizontal</span>
                                                <span class="glyph-codepoint">EA5F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-options-other-vertical banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">options-other-vertical</span>
                                                <span class="glyph-codepoint">EA60</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-payment-conciliation banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">payment-conciliation</span>
                                                <span class="glyph-codepoint">EA61</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-percentage banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">percentage</span>
                                                <span class="glyph-codepoint">EA62</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-phone-call-incoming-missed banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">phone-call-incoming-missed</span>
                                                <span class="glyph-codepoint">EA63</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-phone-call-incoming-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">phone-call-incoming-normal</span>
                                                <span class="glyph-codepoint">EA64</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-phone-call-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">phone-call-normal</span>
                                                <span class="glyph-codepoint">EA65</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-phone-call-outgoing-missed banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">phone-call-outgoing-missed</span>
                                                <span class="glyph-codepoint">EA66</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-phone-call-outgoing-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">phone-call-outgoing-normal</span>
                                                <span class="glyph-codepoint">EA67</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-phone-calling banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">phone-calling</span>
                                                <span class="glyph-codepoint">EA68</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-phone-hangup banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">phone-hangup</span>
                                                <span class="glyph-codepoint">EA69</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-piggy-bank banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">piggy-bank</span>
                                                <span class="glyph-codepoint">EA6A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-pin banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">pin</span>
                                                <span class="glyph-codepoint">EA6B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-placeholder banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">placeholder</span>
                                                <span class="glyph-codepoint">EA6C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-player-fullscreen-exit banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">player-fullscreen-exit</span>
                                                <span class="glyph-codepoint">EA6D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-player-fullscreen-open banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">player-fullscreen-open</span>
                                                <span class="glyph-codepoint">EA6E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-player-pause banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">player-pause</span>
                                                <span class="glyph-codepoint">EA6F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-player-play banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">player-play</span>
                                                <span class="glyph-codepoint">EA70</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-player-step-backward banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">player-step-backward</span>
                                                <span class="glyph-codepoint">EA71</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-player-step-forward banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">player-step-forward</span>
                                                <span class="glyph-codepoint">EA72</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-player-stop banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">player-stop</span>
                                                <span class="glyph-codepoint">EA73</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-pricing-plans banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">pricing-plans</span>
                                                <span class="glyph-codepoint">EA74</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-profile banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">profile</span>
                                                <span class="glyph-codepoint">EA75</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-question-circle1 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">question-circle1</span>
                                                <span class="glyph-codepoint">EA76</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-question-circle2 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">question-circle2</span>
                                                <span class="glyph-codepoint">EA77</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-refresh banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">refresh</span>
                                                <span class="glyph-codepoint">EA78</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-screenshot-camera banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">screenshot-camera</span>
                                                <span class="glyph-codepoint">EA79</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-screenshot banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">screenshot</span>
                                                <span class="glyph-codepoint">EA7A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-scroll banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">scroll</span>
                                                <span class="glyph-codepoint">EA7B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-search-minus banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">search-minus</span>
                                                <span class="glyph-codepoint">EA7C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-search-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">search-normal</span>
                                                <span class="glyph-codepoint">EA7D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-search-plus banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">search-plus</span>
                                                <span class="glyph-codepoint">EA7E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-send banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">send</span>
                                                <span class="glyph-codepoint">EA7F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-settings banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">settings</span>
                                                <span class="glyph-codepoint">EA80</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-shape-arrow banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">shape-arrow</span>
                                                <span class="glyph-codepoint">EA81</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-shape-circle banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">shape-circle</span>
                                                <span class="glyph-codepoint">EA82</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-shape-line banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">shape-line</span>
                                                <span class="glyph-codepoint">EA83</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-shape-square banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">shape-square</span>
                                                <span class="glyph-codepoint">EA84</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-share banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">share</span>
                                                <span class="glyph-codepoint">EA85</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-sort-horizontal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">sort-horizontal</span>
                                                <span class="glyph-codepoint">EA86</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-sort-vertical banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">sort-vertical</span>
                                                <span class="glyph-codepoint">EA87</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-special-payments banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">special-payments</span>
                                                <span class="glyph-codepoint">EA88</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-sport-american-football banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">sport-american-football</span>
                                                <span class="glyph-codepoint">EA89</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-sport-basketball banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">sport-basketball</span>
                                                <span class="glyph-codepoint">EA8A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-sport-football banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">sport-football</span>
                                                <span class="glyph-codepoint">EA8B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-star-outline banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">star-outline</span>
                                                <span class="glyph-codepoint">EA8C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-star banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">star</span>
                                                <span class="glyph-codepoint">EA8D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-streaming banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">streaming</span>
                                                <span class="glyph-codepoint">EA8E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-symbol-minus banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">symbol-minus</span>
                                                <span class="glyph-codepoint">EA8F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-symbol-plus banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">symbol-plus</span>
                                                <span class="glyph-codepoint">EA90</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-t-shirt banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">t-shirt</span>
                                                <span class="glyph-codepoint">EA91</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-tag banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">tag</span>
                                                <span class="glyph-codepoint">EA92</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-theater banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">theater</span>
                                                <span class="glyph-codepoint">EA93</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-time banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">time</span>
                                                <span class="glyph-codepoint">EA94</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-triangle-down banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">triangle-down</span>
                                                <span class="glyph-codepoint">EA95</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-triangle-left banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">triangle-left</span>
                                                <span class="glyph-codepoint">EA96</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-triangle-right banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">triangle-right</span>
                                                <span class="glyph-codepoint">EA97</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-triangle-up banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">triangle-up</span>
                                                <span class="glyph-codepoint">EA98</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-trophy banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">trophy</span>
                                                <span class="glyph-codepoint">EA99</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-updating banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">updating</span>
                                                <span class="glyph-codepoint">EA9A</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-user-add-user banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">user-add-user</span>
                                                <span class="glyph-codepoint">EA9B</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-user-group banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">user-group</span>
                                                <span class="glyph-codepoint">EA9C</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-user-man banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">user-man</span>
                                                <span class="glyph-codepoint">EA9D</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-user-woman banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">user-woman</span>
                                                <span class="glyph-codepoint">EA9E</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-videocamera-normal banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">videocamera-normal</span>
                                                <span class="glyph-codepoint">EA9F</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-videocamera-off banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">videocamera-off</span>
                                                <span class="glyph-codepoint">EAA0</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-videocamera-starred banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">videocamera-starred</span>
                                                <span class="glyph-codepoint">EAA1</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-visibility-off banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">visibility-off</span>
                                                <span class="glyph-codepoint">EAA2</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-visibility-on banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">visibility-on</span>
                                                <span class="glyph-codepoint">EAA3</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-volume-down banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">volume-down</span>
                                                <span class="glyph-codepoint">EAA4</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-volume-off banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">volume-off</span>
                                                <span class="glyph-codepoint">EAA5</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-volume-up banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">volume-up</span>
                                                <span class="glyph-codepoint">EAA6</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-warning-circle1 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">warning-circle1</span>
                                                <span class="glyph-codepoint">EAA7</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-warning-circle2 banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">warning-circle2</span>
                                                <span class="glyph-codepoint">EAA8</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-webhooks banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">webhooks</span>
                                                <span class="glyph-codepoint">EAA9</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-wifi banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">wifi</span>
                                                <span class="glyph-codepoint">EAAA</span>
                                            </li>
                                            <li>
                                                <i
                                                    aria-hidden="true"
                                                    class="banana banana-wrench banana-4x"
                                                >
                                                    <span></span>
                                                </i>
                                                <span class="glyph-name">wrench</span>
                                                <span class="glyph-codepoint">EAAB</span>
                                            </li>
                                        </ul>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- BADGE  -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Badge</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>type:</strong> For type of badge <strong>"badge-success", "badge-error",
                                        "badge-warning", "badge-info", "badge-default", "badge-accent_2", "badge-accent_1",
                                        "badge-disable" </strong> (String).
                                    <br />
                                    <strong>text:</strong> Text fot badge (String).
                                    <br />
                                    <strong>closeBtnShow:</strong> To show close button (Boolean).
                                    <br />
                                    <strong>icon:</strong> Icon's class (String).
                                </p>
                                <div class="vertical-center">
                                    <Badge
                                        type="badge-success"
                                        text="Badge Success"
                                    ></Badge>
                                    <Badge
                                        type="badge-error"
                                        text="Badge Error"
                                    ></Badge>
                                    <Badge
                                        type="badge-warning"
                                        text="Badge Warning"
                                    ></Badge>
                                    <Badge
                                        type="badge-info"
                                        text="Badge Info"
                                    ></Badge>
                                    <Badge
                                        type="badge-disable"
                                        text="Badge Disable"
                                    ></Badge>
                                </div>
                                <br />
                                <div class="vertical-center">
                                    <Badge
                                        icon="banana-edit"
                                        type="badge-defaults"
                                        text="Badge Default"
                                    ></Badge>
                                    <Badge
                                        type="badge-placeholder"
                                        text="Badge Accent 1"
                                    ></Badge>
                                    <Badge
                                        type="badge-accent_1"
                                        text="Badge Accent 1"
                                    ></Badge>
                                    <Badge
                                        type="badge-accent_2"
                                        text="Badge Accent 2"
                                        closeBtnShow
                                    ></Badge>
                                </div>
                            </div>
                        </div>

                        <!-- BUTTONS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Button</h3>
                                <p>
                                    <strong>Can be used for html tag 'button', tag 'a' and 'router-link'.</strong>
                                    <br/><br/>
                                    Buttons have shape, type, size and color. This is the structure:
                                    <br />
                                    .shapeButton + .sizeButton + .typeButton + .colorButton
                                    <br />
                                    Example:
                                    <br />
                                    class="button xs button-filled button-success"
                                    <br />
                                    <button class="button xs button-filled button-success">
                                        I'm a button
                                    </button>
                                    <br /><br />

                                    class="button xs button-stroke button-success"
                                    <br />
                                    <button class="button xs button-stroke button-success">
                                        I'm a button
                                    </button>
                                    <br /><br />

                                    class="button xs button-ghost button-success"
                                    <br />
                                    <a href="#" class="button xs button-ghost button-success" tabindex="">
                                        I'm a button
                                    </a>
                                    <br /><br />

                                    <span class="c-error"
                                        ><strong>NOTE: </strong
                                        ><strong>There iS NO default button</strong>, classes must be
                                        applied for a good result.</span
                                    >
                                    <br /><br />
                                    <strong> SHAPE BUTTONS </strong><br />
                                    .button<br />
                                    .button-circle<br />
                                    <br />

                                    <strong> SIZE BUTTONS </strong><br />
                                    .xs<br />
                                    .sm<br />
                                    <br />

                                    <strong> TYPE BUTTON </strong><br />
                                    .button-filled<br />
                                    .button-stroke<br />
                                    .button-ghost<br />
                                    <br />

                                    <strong> COLOR BUTTONS </strong><br />
                                    <span class="c-primary_1">.button-primary1</span>
                                    <br />
                                    <span class="c-primary_2">.button-primary2</span>
                                    <br />
                                    <span class="c-accent_1">.button-accent1</span>
                                    <br />
                                    <span class="c-accent_2">.button-accent2</span>
                                    <br />
                                    <span class="c-title">.button-title</span>
                                    <br />
                                    <span class="c-plain_text">.button-plain_text</span>
                                    <br />
                                    <span class="c-info_text">.button-info_text</span>
                                    <br />
                                    <span class="c-placeholder">.button-placeholder</span>
                                    <br />
                                    <span class="c-black">.button-black</span>
                                    <br />
                                    <span class="c-gray">.button-gray</span>
                                    <br />
                                    <span class="c-disable">.button-disable</span>
                                    <br />
                                    <span class="c-light_gray">.button-light_gray</span>
                                    <br />
                                    <span class="c-white">.button-white</span>
                                    <br />
                                    <span class="c-error">.button-error</span>
                                    <br />
                                    <span class="c-success">.button-success</span>
                                    <br />
                                    <span class="c-warning">.button-warning</span>
                                    <br />
                                    <span class="c-info">.button-info</span>
                                    <br />
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-buttons
                                    >Show Buttons Example
                                    </button>
                                    <b-collapse
                                        id="collapse-buttons"
                                        class="m-t-20"
                                    >
                                        <!-- LOADING BUTTON -->
                                        <h4 class="m-t-30 m-b-30">LOADING BUTTON</h4>
                                        <button
                                            type="button"
                                            class="button button-filled button-primary1"
                                        >
                                            <LoadingSimple clazz="small-load no-text" />
                                        </button>

                                        <br />
                                        <br />

                                        <!-- NORMAL BUTTON -->
                                        <h4 class="m-t-30 m-b-30">NORMAL BUTTON</h4>

                                        <h5 class="m-b-20 m-t-20">Button background</h5>
                                        <button
                                            type="button"
                                            class="button button-filled button-primary1"
                                        >Button
                                        </button>
                                        <br />
                                        <h5 class="m-b-20 m-t-20">Button stroke</h5>
                                        <button
                                            type="button"
                                            class="button button-stroke button-primary1"
                                        >Button
                                        </button>
                                        <br />
                                        <h5 class="m-b-20 m-t-20">Button no background</h5>
                                        <button
                                            type="button"
                                            class="button button-ghost button-primary1"
                                        >Button
                                        </button>

                                        <br />
                                        <br />

                                        <!-- CIRCLE BUTTON -->
                                        <h4 class="m-t-30 m-b-30">CIRCLE BUTTON</h4>
                                        <h5 class="m-b-20 m-t-20">Button background</h5>
                                        <button
                                            type="button"
                                            class="button-circle button-filled button-primary2"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-arrow-up"
                                            ></i>
                                        </button>
                                        <br />
                                        <h5 class="m-b-20 m-t-20">Button stroke</h5>
                                        <button
                                            type="button"
                                            class="button-circle button-stroke button-primary2"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-menu"
                                            ></i>
                                        </button>
                                        <br />
                                        <h5 class="m-b-20 m-t-20">Button no background</h5>
                                        <button
                                            type="button"
                                            class="button-circle button-ghost button-primary2"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-symbol-plus"
                                            ></i>
                                        </button>

                                        <br />
                                        <br />

                                        <!-- SQUARE BUTTON -->
                                        <h4 class="m-t-30 m-b-30">SQUARE BUTTON</h4>
                                        <h5 class="m-b-20 m-t-20">Button background</h5>
                                        <button
                                            type="button"
                                            class="button-square button-filled button-primary2"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-arrow-up"
                                            ></i>
                                        </button>
                                        <br />
                                        <h5 class="m-b-20 m-t-20">Button stroke</h5>
                                        <button
                                            type="button"
                                            class="button-square button-stroke button-primary2"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-menu"
                                            ></i>
                                        </button>
                                        <br />
                                        <h5 class="m-b-20 m-t-20">Button no background</h5>
                                        <button
                                            type="button"
                                            class="button-square button-ghost button-primary2"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-symbol-plus"
                                            ></i>
                                        </button>

                                        <br />
                                        <br />

                                        <!-- SOCIAL BUTTONS LOGIN -->
                                        <h4 class="m-t-30 m-b-30">SOCIAL BUTTONS LOGIN</h4>
                                        <p class="f-14">
                                            <strong>Parameters:</strong>
                                            <br />
                                            <br />
                                            <strong>clazz:</strong> String, extra class for component.
                                            <br />
                                            <strong>clazzSpan:</strong> String, extra class for section "continue", to
                                            hide the element.
                                            <br />
                                            <strong>span:</strong> String, text divider.
                                        </p>
                                        <SocialButtons span="¿Quieres ahorrar tiempo?">
                                            <a
                                                class="social-btn fb"
                                                tabindex
                                            >
                                                <img
                                                    class="img-fluid"
                                                    src="@/assets/images/Icons/facebook-icon.svg"
                                                    alt="facebook"
                                                />
                                                Ingresa con
                                                <strong>Facebook</strong>
                                            </a>
                                            <a
                                                class="social-btn tw"
                                                tabindex
                                            >
                                                <img
                                                    class="img-fluid"
                                                    src="@/assets/images/Icons/twitter-icon.svg"
                                                    alt="twitter"
                                                />
                                                Ingresa con
                                                <strong>Twitter</strong>
                                            </a>
                                            <a
                                                class="social-btn goo"
                                                tabindex
                                            >
                                                <img
                                                    class="img-fluid"
                                                    src="@/assets/images/Icons/google-icon.svg"
                                                    alt="google"
                                                />
                                                Ingresa con
                                                <strong>Google</strong>
                                            </a>
                                        </SocialButtons>

                                        <br />
                                        <br />

                                        <!-- SOCIAL BUTTONS LOGIN -->
                                        <h4 class="m-t-30 m-b-30">BUTTON ADD SQUARE</h4>
                                        <a
                                            href
                                            class="button-square-add"
                                            tabindex
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-symbol-plus"
                                            ></i> Crear Organización
                                        </a>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- BANK CARD PREVIEW -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Bank Card Preview</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>emptyInfo:</strong> To show default info (Boolean).
                                    <br />
                                    <strong>cardNum:</strong> Text for card's number (Number).
                                    <br />
                                    <strong>cardName:</strong> Text for card's owner name (String).
                                    <br />
                                    <strong>cardDate:</strong> Text for card's date (String).
                                    <br />
                                    <strong>backCardInfo:</strong> To show card's back numbers (Boolean).
                                </p>
                                <BankCardPreview
                                    clazz="m-t-40 m-b-40"
                                    emptyInfo
                                ></BankCardPreview>
                                <BankCardPreview
                                    clazz="m-t-40 m-b-40"
                                    cardNum="4576  2465  9758  6748"
                                    cardName="Francisco Castro Gomez"
                                    cardDate="11/22"
                                    cardBrand="visa"
                                ></BankCardPreview>
                                <BankCardPreview
                                    backCardInfo
                                    clazz="m-t-40 m-b-40"
                                    cardNum="4576  2465  9758  6748"
                                    cardName="Francisco Castro Gomez"
                                    cardDate="11/22"
                                    cardBrand="visa"
                                ></BankCardPreview>
                            </div>
                        </div>

                        <!-- BASIC CAMERA COMPONENT -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Camera Component</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>ref:</strong> Component reference used to call innner methods
                                    <br />
                                    <strong>width:</strong> Camera video width
                                    <br />
                                    <strong>height:</strong> Camera video height
                                    <br />
                                    <strong>camId:</strong> ???
                                    <br />
                                    <strong>canvasWidth:</strong> Canvas where taken photo is drawed width
                                    <br />
                                    <strong>canvasHeight:</strong> Canvas where taken photo is drawed height
                                    <br />
                                    <strong>v-slot:cameraButtons</strong> Slot to add buttons to container.
                                    <br />
                                    <strong>v-slot:note</strong> Slot to add a custom Note at component's bottom.
                                    <br />
                                    <strong>imgTaken</strong> Shows picture taken (Boolean).
                                    <br />
                                    <strong>verified</strong> Shows message success, when picture taken (Boolean).
                                    <br />
                                    <strong>Mixin '@/mixins/CameraMixin'</strong> must be implemented on the father
                                    component giving the same ref given to the Camera Component.
                                    <br />
                                </p>
                                <p>
                                    This Buttons are for
                                    <strong>demonstration only</strong>
                                </p>
                                <br />
                                <p>Note: The function attached to SnapPhoto returns the base 64 png</p>
                                <div>
                                    <br />
                                    <button
                                        class="button xs button-filled button-success"
                                        id="stop-capture"
                                        @click="stopVideoCapture"
                                    >Stop!
                                    </button>
                                    <br />
                                    <button
                                        class="button xs button-filled button-success"
                                        id="init-capture"
                                        @click="initCamera"
                                    >Init Camera
                                    </button>
                                    <br />
                                    <button
                                        class="button xs button-filled button-success"
                                        id="play-capture"
                                        @click="retakePhoto"
                                    >Unpause
                                    </button>
                                    <br />
                                    <button
                                        class="button xs button-filled button-success"
                                        id="play-capture"
                                        @click="nextVideoSource"
                                    >Switch Camera
                                    </button>
                                </div>
                                <CameraVue
                                    ref="ux-test-camera"
                                    imgTaken
                                    verified
                                    camId="omg"
                                    imageClassification="users/test-delete-in-anyCase"
                                    imageNamePrefix="junk-"
                                >
                                    <template v-slot:cameraButtons>
                                        <button
                                            class="button xs button-stroke button-white"
                                            id="snap"
                                            @click="takePicture"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-camera"
                                            ></i>
                                            Tomar fotografía
                                        </button>
                                    </template>

                                    <template v-slot:note>
                                        <p class="f-14 c-info_text body-font-regular text-align-c m-t-10 m-b-0">
                                            ¿No cuentas con una webcam?
                                            <strong class="c-accent_2 body-font-bold">Puedes cargar tu fotografía más tarde.</strong> Te recordamos que
                                            <strong class="c-info_text body-font-bold">no podrás realizar pagos, movimientos o consultas si tu información no está
                                                completa.</strong>
                                        </p>
                                    </template>
                                </CameraVue>
                            </div>
                        </div>

                        <!-- CODEMIRROR  -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Codemirror</h3>
                                <a
                                    href="https://github.com/cnu4/vue-codemirror-lite"
                                    class="f-14 f-bold c-error d-block m-b-30"
                                ><i
                                        aria-hidden="true"
                                        class=""
                                    ></i> See Documentation </a>
                                <div>

                                </div>
                                <codemirror :value="code"></codemirror>
                            </div>
                        </div>

                        <!-- CARD CAMPAIN -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Card Campain</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br><br>
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br>
                                    <strong>imgCode / imgCoupon / imgList:</strong> To show image depending on type of
                                    info you need to show (Boolean).
                                    <br>
                                    <strong>title:</strong> Text for title (String).
                                    <br>
                                    <strong>text:</strong> Text for description (String).
                                    <br>
                                    <strong>btnText:</strong> Text for button (String).
                                    <br>
                                </p>
                                <div>
                                    <button
                                        id=""
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-cardCampain
                                    > Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-cardCampain"
                                        class="m-t-20"
                                    >
                                        <div class="row max-w-786px">
                                            <div class="col-12 col-md-6 m-b-30">
                                                <CardCampaign
                                                    title="Código"
                                                    text="Genera aleatoriamente una lista de códigos en cantidad y longitud que necesites. ¡Tú pones las reglas!"
                                                    btnText="Crear campaña"
                                                ></CardCampaign>
                                            </div>
                                            <div class="col-12 col-md-6 m-b-30">
                                                <CardCampaign
                                                    title="Cupón"
                                                    text="Crea un cupón con clave única que los usuarios podrán canjear por descuentos y promociones exclusivas en tiendas."
                                                    btnText="Crear campaña"
                                                ></CardCampaign>
                                            </div>
                                            <div class="col-12 col-md-6 m-b-30">
                                                <CardCampaign
                                                    title="Lista"
                                                    text="Carga una gran lista de códigos generados en Excel o cualquier hoja de cálculo (Formatos XLS ó CSV)."
                                                    btnText="Crear campaña"
                                                ></CardCampaign>
                                            </div>

                                            <div class="col-12 col-md-6 m-b-30">
                                                <CardCampaign
                                                    title="Términos y condiciones"
                                                    text="Crea un nuevo documento..."
                                                    btnText="Ver documentos"
                                                ></CardCampaign>
                                            </div>
                                            <div class="col-12 col-md-6 m-b-30">
                                                <CardCampaign
                                                    title="Contrato"
                                                    text="Crea un nuevo contrato..."
                                                    btnText="Ver documentos"
                                                ></CardCampaign>
                                            </div>
                                            <div class="col-12 col-md-6 m-b-30">
                                                <CardCampaign
                                                    title="Aviso de Privacidad"
                                                    text="Crea un nuevo documento ..."
                                                    btnText="Ver documentos"
                                                ></CardCampaign>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- CARD SELECTION -->
                        <div class="col-12">
                            <CardSimple
                                    title="Card Selection">
                                    <button
                                        id=""
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-card-selection>
                                        Show Card selection types
                                    </button>
                                    <b-collapse id="collapse-card-selection" class="m-t-20">
                                        <CardSelection title="Aviso de Privacidad"
                                                        subtitle="Acerca de la privacidad de tus datos."
                                                        clazz="icon-bgm-accent2">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-locker-locked"></i>
                                            </template>
                                            <template v-slot:extraMiddleContent>
                                                <a href="#" class="f-14 c-accent_2 body-font-bold text-underline d-block m-t-5 c-pointer" tabindex="">
                                                    Leer Aviso
                                                </a>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                            <BasicSwitch idInput="bs-aviso-privacidad"></BasicSwitch>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Términos y Condiciones"
                                                        subtitle="Sobre las políticas de uso de Pagando."
                                                        clazz="icon-bgm-warning">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-document-normal"></i>
                                            </template>
                                            <template v-slot:extraMiddleContent>
                                                <a href="#" class="f-14 c-accent_2 body-font-bold text-underline d-block m-t-5 c-pointer" tabindex="">
                                                    Leer Aviso
                                                </a>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                            <BasicSwitch idInput="bs-terminos-condiciones"></BasicSwitch>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Permiso de acceso a tu ubicación"
                                                        subtitle="Por disposiciones oficiales,
                                                        conocer tu ubicación cuando Pagando esté
                                                        en uso nos permite garantizar la seguridad de tu cuenta."
                                                        clazz="icon-bgm-success">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-location"></i>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <BasicSwitch idInput="bs-ubicacion"></BasicSwitch>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Credencial de elector"
                                                        subtitle="Identificación INE o IFE"
                                                        clazz="icon-bgm-accent2">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-profile"></i>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <button type="button" class="button-circle button-ghost button-info_text" aria-label="Ir a" name="Ir a">
                                                    <i aria-hidden="true" class="banana banana-chevron-right"></i>
                                                </button>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Pasaporte Mexicano"
                                                        subtitle="Vigente, expedido por la SRE"
                                                        clazz="icon-bgm-pink">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-profile"></i>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <button type="button" class="button-circle button-ghost button-info_text" aria-label="Ir a" name="Ir a">
                                                    <i aria-hidden="true" class="banana banana-chevron-right"></i>
                                                </button>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Tarjeta de residencia"
                                                        subtitle="Temporal o permanente (FM2/FM3)"
                                                        clazz="icon-bgm-warning">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-profile"></i>
                                            </template>
                                            <template v-slot:extraMiddleContent>
                                                <a href="#" class="f-14 c-accent_2 body-font-bold text-underline d-block m-t-5 c-pointer" tabindex="">
                                                    ¿Que es una forma FM2 y FM3?
                                                </a>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <button type="button" class="button-circle button-ghost button-info_text" aria-label="Ir a" name="Ir a">
                                                    <i aria-hidden="true" class="banana banana-chevron-right"></i>
                                                </button>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Credencial de elector"
                                                        subtitle="Vista frontal (Max 15 mb, pdf, jpg, png)"
                                                        clazz="border-card icon-bgm-info_text">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-profile"></i>
                                            </template>
                                            <template v-slot:extraMiddleContent>
                                                <b-progress
                                                    :max="maxProgressBar"
                                                    class="bar-progress percent-color"
                                                    :class="{'success' : valueProgressBar0 == 100 }"
                                                >
                                                    <b-progress-bar :value="valueProgressBar0">
                                                        <div class="label-progress">
                                                            <span>{{ valueProgressBar0 }}%</span>
                                                        </div>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <button type="button" class="button xs button-filled button-primary p-relative">
                                                    Subir
                                                    <input type="file" class="input-file" value="" name=""/>
                                                </button>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Comprobante de domicilio"
                                                        subtitle="Agua, Luz, Teléfono (No mayor a 3 meses)"
                                                        clazz="border-card icon-bgm-accent2">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-document-normal"></i>
                                            </template>
                                            <template v-slot:extraMiddleContent>
                                                <b-progress
                                                    :max="maxProgressBar"
                                                    class="bar-progress percent-color"
                                                    :class="{'success' : valueProgressBar == 100 }"
                                                >
                                                    <b-progress-bar :value="valueProgressBar">
                                                        <div class="label-progress">
                                                            <span>{{ valueProgressBar }}%</span>
                                                        </div>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <button type="button" class="button xs button-filled button-primary" disabled>
                                                    Subiendo...
                                                </button>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Comprobante de domicilio"
                                                        subtitle="Agua, Luz, Teléfono (No mayor a 3 meses)"
                                                        clazz="border-card icon-bgm-error">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-document-normal"></i>
                                            </template>
                                            <template v-slot:extraMiddleContent>
                                                <p class="f-12 c-error poppins-font-semibold d-block m-0">Hubo un problema al subir el documento</p>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <button type="button" class="button xs button-filled button-primary p-relative">
                                                    Reintentar
                                                    <input type="file" class="input-file" value="" name=""/>
                                                </button>
                                            </template>
                                        </CardSelection>
                                        <CardSelection title="Credencial de elector"
                                                        subtitle="Vista trasera (Max 15 mb, pdf, jpg, png)"
                                                        clazz="border-card icon-bgm-success">
                                            <template v-slot:icon>
                                                <i aria-hidden="true" class="banana banana-credit-card"></i>
                                            </template>
                                            <template v-slot:extraMiddleContent>
                                                <b-progress
                                                    :max="maxProgressBar"
                                                    class="bar-progress percent-color"
                                                    :class="{'success' : valueProgressBar100 == 100 }"
                                                >
                                                    <b-progress-bar :value="valueProgressBar100">
                                                        <div class="label-progress">
                                                            <span>{{ valueProgressBar100 }}%</span>
                                                        </div>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </template>
                                            <template v-slot:extraFinalContent>
                                                <i aria-hidden="true" class="banana banana-check-normal f-30 c-success"></i>
                                            </template>
                                        </CardSelection>
                                    </b-collapse>
                            </CardSimple>
                        </div>

                        <!-- CAROUSEL  -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Carousels</h3>
                                <p>
                                    <strong>Note</strong> This cards has many illustrations, here is the list:
                                    <br />illustration-clothes.svg
                                    <br />illustration-food.svg
                                    <br />Illustration-garden.svg
                                    <br />illustration-health.svg
                                    <br />illustration-home.svg
                                    <br />illustration-others.svg
                                    <br />illustration-party.svg
                                    <br />illustration-pets.svg
                                    <br />illustration-place.svg
                                    <br />illustration-plane.svg
                                    <br />Illustration-school.svg
                                    <br />illustration-services.svg
                                    <br />illustration-shipping.svg
                                    <br />Illustration-sports.svg
                                    <br />illustration-tools.svg
                                    <br />
                                </p>
                                <p>
                                    <strong>Vue Carousel:</strong>
                                    <br />
                                    <a
                                        href="https://github.com/SSENSE/vue-carousel"
                                        class="f-14 f-bold c-error m-b-30"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class
                                        ></i> See Documentation
                                    </a>
                                    <br />
                                    <br />
                                </p>
                                <SimpleCarousel
                                    :itemsCarousel="[[0, 2],[600, 3],[991, 4],[1230, 4]]"
                                    clazz="simpleCarousel-container"
                                >
                                    <slide>
                                        <CardItem clazz="category-card selected">
                                            <div class="img-container">
                                                <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                                />
                                                <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-food.svg"
                                                    alt
                                                />
                                            </div>
                                            <div class="text-container">
                                                <h1>Alimentos</h1>
                                            </div>
                                        </CardItem>
                                    </slide>
                                    <slide>
                                        <CardItem clazz="category-card">
                                            <div class="img-container">
                                                <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                                />
                                                <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                                    alt
                                                />
                                            </div>
                                            <div class="text-container">
                                                <h1>Recreación y Turismo</h1>
                                            </div>
                                        </CardItem>
                                    </slide>
                                    <slide>
                                        <CardItem clazz="category-card">
                                            <div class="img-container">
                                                <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                                />
                                                <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                                    alt
                                                />
                                            </div>
                                            <div class="text-container">
                                                <h1>Recreación y Turismo</h1>
                                            </div>
                                        </CardItem>
                                    </slide>
                                    <slide>
                                        <CardItem clazz="category-card">
                                            <div class="img-container">
                                                <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                                />
                                                <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                                    alt
                                                />
                                            </div>
                                            <div class="text-container">
                                                <h1>Recreación y Turismo</h1>
                                            </div>
                                        </CardItem>
                                    </slide>
                                    <slide>
                                        <CardItem clazz="category-card">
                                            <div class="img-container">
                                                <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                                />
                                                <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                                    alt
                                                />
                                            </div>
                                            <div class="text-container">
                                                <h1>Recreación y Turismo</h1>
                                            </div>
                                        </CardItem>
                                    </slide>
                                </SimpleCarousel>
                                <br>
                                <p>
                                    <strong>Bottom link Carousel:</strong>
                                    <br>
                                    All the carousels have a bottom link
                                    <br />
                                    <strong>link:</strong> The link on the bottom (String).
                                    <br />
                                    <strong>text:</strong> The text of the link.
                                </p>
                                <BottomLink
                                    text="Ver más servicios →"
                                    link="#"
                                />

                                <h4 class="m-t-30 m-b-30">Small Carousel and empty state</h4>

                                <div class="col-12 m-b-30">
                                    <b-carousel
                                        id="OneItemCarousel"
                                        fade
                                        indicators
                                        controls
                                        :interval="10000"
                                    >
                                        <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/A1E74CED-4B97-4F9C-A7DF-D43FEAA54454.png"
                                        >
                                        </b-carousel-slide>
                                        <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/8A0B51A8-7BAA-487B-9755-E71C709D4C11.png">
                                        </b-carousel-slide>
                                        <b-carousel-slide
                                            img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/39B68F24-72B4-45B1-A25E-FA557AAE8C57.png">
                                        </b-carousel-slide>
                                    </b-carousel>
                                </div>

                                <div class="col-12 m-b-30">
                                    <b-carousel>
                                        <b-carousel-slide img-src="https://api.pagando.mx/img/default/cover.svg"></b-carousel-slide>
                                    </b-carousel>
                                </div>
                            </div>
                        </div>

                        <!-- CROP / ZOOM IMAGES  -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Crop / Zoom Images</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>titleCropbox:</strong> Text for title component (String).
                                    <br />
                                    <strong>imgNote:</strong> Text for bottom note (String).
                                    <br />
                                    <strong>showPreview:</strong> To show image preview cropped (Boolean).
                                    <br />
                                    <strong>showButtons:</strong> To show default buttons 'rotate' and 'crop' (Boolean).
                                    <br />
                                    <strong>slot:</strong> For adding custom buttons.
                                    <br />
                                </p>
                                <a
                                    href="https://github.com/jofftiquez/vue-croppie"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <CropImage
                                    titleCropbox="Fotografía frontal:"
                                    imgNote="Ajusta el tamaño de la imagen dentro de los márgenes seguros con el deslizador
                                         de zoom. Al terminar haz clic en el botón 'Verificar'."
                                >
                                    <button class="button xs button-filled button-accent1 f-12 m-0-0-0-auto">Guardar
                                    </button>
                                </CropImage>

                                <CropImage
                                    titleCropbox="Fotografía frontal:"
                                    showPreview
                                    showButtons
                                />
                            </div>
                        </div>

                        <!-- EMPTYSTATES -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Empty states</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>size:</strong> Extra class for component's size: 'xs', 'sm' (String).
                                    <br />
                                    <strong>title:</strong> Text for title (String).
                                    <br />
                                    <strong>text:</strong> Text for strong subtitle (String).
                                    <br />
                                    <strong>emptyStateDefault:</strong> To show default empty state vertical (Boolean).
                                    <br />
                                    <strong>emptyStateSearching:</strong> To show empty state for search status
                                    (Boolean).
                                    <br />
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-alertNotes
                                    >Show Emptystates
                                    </button>
                                    <b-collapse
                                        id="collapse-alertNotes"
                                        class="m-t-20"
                                    >
                                        <h3>Empty state Horizontal</h3>
                                        <EmptyState
                                            title="¡Crea tu primer Documento!"
                                            text="Comienza añadiendo los primeros documentos para comunicar a los
                                                usuarios los “Terminos y Condiciones”, “Contratos” y “Avisos de Privacidad” de Pagando."
                                        >
                                            <b-dropdown
                                                id="dropdown-add"
                                                dropright
                                                class="dropdown-image-list"
                                            >
                                                <template v-slot:button-content>
                                                    <button class="button xs button-filled button-accent1">
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-symbol-plus"
                                                        ></i> Nuevo Documento
                                                    </button>
                                                </template>
                                                <b-dropdown-group header="Selecciona el tipo de documento que deseas crear:">
                                                    <b-dropdown-item-button>
                                                        <div class="options-list">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/icon-lista.svg"
                                                                alt
                                                            />
                                                            <!-- icon-terminos.svg  icon-contrato.svg -->
                                                            <span>
                                                                <label>Aviso de Privacidad</label>
                                                                <p>
                                                                    Crea un nuevo documento para informar al usuario sobre la responsable recopilación y
                                                                    tratamiento de datos personales.
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button>
                                                        <div class="options-list">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/icon-contrato.svg"
                                                                alt
                                                            />
                                                            <span>
                                                                <label>Contrato</label>
                                                                <p>
                                                                    Crea un nuevo contrato para crear acuerdos digitales que se comprometen a respetar y cumplir
                                                                    recíprocamente el usuario y Pagando.
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button>
                                                        <div class="options-list">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/icon-terminos.svg"
                                                                alt
                                                            />
                                                            <span>
                                                                <label>Terminos y condiciones</label>
                                                                <p>
                                                                    Crea un nuevo documento para informar al usuario sobre acceso de contenido y servicios que
                                                                    Pagando pone a su disposición.
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </b-dropdown-item-button>
                                                </b-dropdown-group>
                                            </b-dropdown>
                                        </EmptyState>
                                        <h3>Empty state Searching</h3>
                                        <EmptyState
                                            emptyStateSearching
                                            title
                                            text="Buscando documentos"
                                        ></EmptyState>
                                        <h3>Empty state Vertical (Default size)</h3>
                                        <EmptyState
                                            emptyStateDefault
                                            title="No se encontraron resultados con la busqueda"
                                            text="Aviso de privacidad 2018"
                                        ></EmptyState>
                                        <h3>Medium size</h3>
                                        <EmptyState
                                            emptyStateDefault
                                            size="sm"
                                            title="No se encontraron resultados con la busqueda"
                                            text="Aviso de privacidad 2018"
                                        ></EmptyState>
                                        <h3>Small size</h3>
                                        <EmptyState
                                            emptyStateDefault
                                            size="xs"
                                            title="No se encontraron resultados con la busqueda"
                                            text="Aviso de privacidad 2018"
                                        ></EmptyState>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- FORM FOOTER BUTTONS -->
                        <div class="col-12 m-b-50">
                            <CardSimple title="Form Footer Buttons"
                                        subtitle="This is an example of how footer buttons must be at the end of a card form">
                                        <div class="row">
                                            <div class="col-12">
                                                Some content right here...
                                            </div>
                                            <div class="col-12 m-t-40 vertical-center justify-content-end">
                                                <button type="button"
                                                        class="button xs button-ghost button-plain_text">
                                                        Btn Right Float 1
                                                </button>
                                                <button type="button"
                                                        class="button xs button-filled button-accent1 m-l-10">
                                                        Btn Right Float 2
                                                </button>
                                            </div>
                                            <div class="col-12 m-t-40 vertical-center justify-content-end">
                                                <button type="button"
                                                        class="button xs button-stroke button-accent1 m-0-auto-0-0">
                                                        Btn Left Float
                                                </button>
                                                <div class="vertical-center">
                                                    <button type="button"
                                                            class="button xs button-ghost button-plain_text">
                                                            Btn Right Float 1
                                                    </button>
                                                    <button type="button"
                                                            class="button xs button-filled button-accent1 m-l-10">
                                                            Btn Right Float 2
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-12 m-t-40 vertical-center">
                                                <button type="button"
                                                        class="button xs button-ghost button-plain_text m-0-auto-0-0">
                                                        Btn Left Float
                                                </button>
                                                <button type="button"
                                                        class="button xs button-filled button-accent1 m-0-0-0-auto">
                                                        Btn Right Float
                                                </button>
                                            </div>
                                            <div class="col-12 m-t-40 vertical-center justify-content-end">
                                                <span class="f-14 c-info_text m-0-auto-0-0">
                                                    <i aria-hidden="true" class="c-error">*</i>
                                                    Campos Requeridos
                                                </span>
                                                <div class="vertical-center">
                                                    <button type="button"
                                                            class="button xs button-ghost button-plain_text">
                                                            Btn Right Float 1
                                                    </button>
                                                    <button type="button"
                                                            class="button xs button-filled button-accent1 m-l-10">
                                                            Btn Right Float 2
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                            </CardSimple>
                        </div>

                        <!-- FILE UPLOADED INFO -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2"> File Uploaded Info </h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>icon:</strong> Icon for component (String).
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>fileName:</strong> Text for file's name (String).
                                    <br />
                                    <strong>fileSize:</strong> Text for file's size (String).
                                    <br />
                                </p>
                                <FileUploadedInfo
                                    icon="banana-document-normal"
                                    fileName="Comprobante-fiscal.xml"
                                    fileSize="25KB"
                                ></FileUploadedInfo>
                            </div>
                        </div>

                        <!-- FORM ELEMENTS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Form elements</h3>

                                <h4 class="m-t-30 m-b-30"> Read Only Text </h4>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>title:</strong> Text for title (String).
                                    <br />
                                    <strong>text:</strong> Text for subtitle text (String).
                                    <br />
                                </p>
                                <ReadOnlyInfo
                                    title="Descripción:"
                                    text="Lanzamiento de campaña del mes de Noviembre.
                                    Incluye descuento de un 10% de descuento en tiendas con productos
                                    y/o servicios  relacionados al día de muertos."
                                ></ReadOnlyInfo>

                                <h4 class="m-t-30 m-b-30">Input Tags</h4>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>required:</strong> Shows an * if the input is required (Boolean).
                                    <br />
                                    <strong>inputType:</strong> It refers to the input type (String).
                                    <br />
                                    <strong>clazz:</strong> Add an extra class to the input container, for example
                                    .has-error if the input has an error (String).
                                    <br />
                                    <strong>labelFor:</strong> It refers to the for of the label, must be same as
                                    inputId (String).
                                    <br />
                                    <strong>inputId:</strong> It refers to the input ID, must be same as labelFor
                                    (String).
                                    <br />
                                    <strong>label:</strong> It refers to the input label (String).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon (String).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message's text (String).
                                    <br />
                                    <strong>inputPlaceholder:</strong> It refers to the input placeholder (String).
                                    <br />
                                    <strong>inputClazz:</strong> It refers to the icon position inside the input
                                    (.icon-right or .icon-left) (String).
                                    <br />
                                </p>
                                <InputTags
                                    inputType="text"
                                    labelFor="enlace"
                                    inputId="enlace"
                                    label="Input with tags on inside"
                                    inputPlaceholder="Agregar más personas..."
                                ></InputTags>

                                <h4 class="m-t-30 m-b-30">Basic Input</h4>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>required:</strong> Shows an * if the input is required (Boolean).
                                    <br />
                                    <strong>inputType:</strong> It refers to the input type (String).
                                    <br />
                                    <strong>clazz:</strong> Add an extra class to the input container, for example
                                    .has-error if the input has an error (String).
                                    <br />
                                    <strong>labelFor:</strong> It refers to the for of the label, must be same as
                                    inputId (String).
                                    <br />
                                    <strong>inputId:</strong> It refers to the input ID, must be same as labelFor
                                    (String).
                                    <br />
                                    <strong>label:</strong> It refers to the input label (String).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon (String).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message's text (String).
                                    <br />
                                    <strong>inputPlaceholder:</strong> It refers to the input placeholder (String).
                                    <br />
                                    <strong>inputClazz:</strong> It refers to the icon position inside the input
                                    (.icon-right or .icon-left) (String).
                                    <br />
                                    <strong>iconInput:</strong> It refers to the icon inside the input (String).
                                    <br />
                                </p>
                                <BasicInput
                                    required
                                    labelFor="example2"
                                    inputId="example2"
                                    label="Input with help text and warning text"
                                    inputPlaceholder="Placeholder"
                                    help
                                    helpText="Help text"
                                    helpIcon="banana banana-question-circle1"
                                    idHelpTooltip="ejGenerico"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                >
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                            target="ejGenerico"
                                            placement="top"
                                            custom-class="white-tooltip"
                                        >
                                            <div>
                                                <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </template>
                                </BasicInput>
                                <BasicInput
                                    required
                                    clazz="has-error"
                                    inputType="text"
                                    labelFor="examplei1"
                                    inputId="examplei1"
                                    label="Input error"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                    inputPlaceholder="Placeholder"
                                />
                                <BasicInput
                                    inputType="text"
                                    label="Input label right"
                                    inputPlaceholder="Placeholder"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                    inputClazz="icon-right"
                                    iconInput="banana-search-normal"
                                ></BasicInput>
                                <BasicInput
                                    required
                                    inputType="number"
                                    labelFor="versionNumber"
                                    inputId="versionNumber"
                                    label="Input type = number"
                                    inputPlaceholder="Versión de documento"
                                ></BasicInput>

                                <h4 class="m-t-30 m-b-30">Input File</h4>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>required:</strong> Shows an * if the input is required (Boolean).
                                    <br />
                                    <strong>clazz:</strong> Add an extra class to the input container, for example
                                    .has-error if the input has an error (String).
                                    <br />
                                    <strong>labelFor:</strong> It refers to the for of the label, must be same as the
                                    input ID (String).
                                    <br />
                                    <strong>label:</strong> It refers to the input label (String).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message's text (String).
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon (String).
                                    <br />
                                    <strong>slot:</strong> Content to insert component b-form-file.
                                    <br />
                                    <strong>placeholder:</strong> Placeholder text (String).
                                    <br />
                                    <strong>drop-placeholder:</strong> Placeholder drop text (String).
                                    <br />
                                    <strong>downloadFile:</strong> To show download file button (Boolean).
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/form-file"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <InputFile
                                    required
                                    labelFor="fileife"
                                    label="Identificación Oficial"
                                    help
                                    helpText="Help text"
                                    helpIcon="banana banana-question-circle1"
                                    idHelpTooltip="ejGenerico2"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                >
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                            target="ejGenerico2"
                                            placement="top"
                                            custom-class="white-tooltip"
                                        >
                                            <div>
                                                <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </template>

                                    <b-form-file
                                        id="fileife"
                                        placeholder="Selecciona el archivo"
                                        drop-placeholder="Arratra tu archivo aquí..."
                                        name
                                    />
                                </InputFile>

                                <InputFile
                                    required
                                    downloadFile
                                    labelFor="fileife"
                                    label="Identificación Oficial"
                                >
                                    <b-form-file
                                        id="fileife"
                                        disabled
                                        placeholder="Selecciona el archivo"
                                        drop-placeholder="Arratra tu archivo aquí..."
                                        name
                                    />
                                </InputFile>

                                <h4 class="m-t-30 m-b-30">Input Telephone Country</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>labelFor:</strong> 'for' text for label (String).
                                    <br />
                                    <strong>label:</strong> Text for label (String).
                                    <br />
                                    <strong>required:</strong> To show required if needed (Boolean).
                                    <br />
                                    <strong>help:</strong> To show help if needed (Boolean).
                                    <br />
                                    <strong>helpText:</strong> Text for help component (String).
                                    <br />
                                    <strong>helpIcon:</strong> Icon for help component (String).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>idInput:</strong> ID for input (String).
                                    <br />
                                    <strong>inputPlaceholder:</strong> Placeholder text for input 2(String).
                                    <br />
                                    <strong>badge:</strong> To show badge if needed (Boolean).
                                    <br />
                                    <strong>badgeType:</strong> Type class of badge (String).
                                    <br />
                                    <strong>badgeText:</strong> Text for badge (String).
                                    <br />
                                    <strong>warningIcon:</strong> Icon for warning component (String).
                                    <br />
                                    <strong>warningText:</strong> Text for warning component (String).
                                    <br />
                                </p>
                                <a
                                    href="https://www.npmjs.com/package/vue-tel-input"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <TelInput
                                    labelFor="numCountry"
                                    label="Número de celular"
                                    required
                                    help
                                    helpText="help here"
                                    helpIcon="banana banana-question-circle1"
                                    idInput="numCountry"
                                    inputPlaceholder="Ej. (000) 000 00 00"
                                    badge
                                    badgeType="badge-success"
                                    badgeText="verificado"
                                    warningIcon="banana banana-warning-circle1"
                                    warningText="Warning"
                                />
                                <TelInput
                                    clazz="has-error"
                                    labelFor="number"
                                    label="Número de celular"
                                    required
                                    idInput
                                    inputPlaceholder="Ej. (000) 000 00 00"
                                />

                                <h4 class="m-t-30 m-b-30">Input for editing text</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class form div (String).
                                    <br />
                                    <strong>inputId:</strong> Text id for input (String).
                                    <br />
                                    <strong>inputValue:</strong> Value for input (String).
                                    <br />
                                    <strong>inputType:</strong> Type for input (String).
                                    <br />
                                    <strong>inputPlaceholder:</strong> Text placeholder for input (String).
                                    <br />
                                    <strong>disabled</strong> To (Boolean).
                                </p>
                                <InputEdit
                                    inputType="text"
                                    inputPlaceholder="Input edit"
                                />

                                <h4 class="m-t-30 m-b-30">Textarea</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>required:</strong> Shows an * if the textarea is required (Boolean).
                                    <br />
                                    <strong>textareaType:</strong> It refers to the textarea type (String).
                                    <br />
                                    <strong>clazz:</strong> Add an extra class to the textarea container, for example
                                    .has-error if the textarea has an error (String).
                                    <br />
                                    <strong>labelFor:</strong> It refers to the for of the label (String).
                                    <br />
                                    <strong>textareaId:</strong> It refers to the textarea ID (String).
                                    <br />
                                    <strong>label:</strong> It refers to the textarea label (String).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon (String).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message's text (String).
                                    <br />
                                    <strong>textareaPlaceholder:</strong> It refers to the textarea placeholder
                                    (String).
                                    <br />
                                    <strong>textAreaClazz:</strong>It refers to the extra class to add to textarea
                                    (String). Add class "resize-active" to show resize.
                                    <br />
                                    <strong>v-slot:strongLevel:</strong> Extra content for 'StrongLevel' component.
                                    <br />
                                    <strong>hideCounter:</strong> To hide bottom counter (Boolean).
                                </p>
                                <BasicTextarea
                                    labelFor="examplet1"
                                    textareaId="examplet1"
                                    label="textarea label"
                                    help
                                    idHelpTooltip="ejGenerico3"
                                    helpText="Help text"
                                    helpIcon="banana banana-question-circle1"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                    textareaPlaceholder="Placeholder"
                                >
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                            target="ejGenerico3"
                                            placement="top"
                                            custom-class="white-tooltip"
                                        >
                                            <div>
                                                <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </template>
                                </BasicTextarea>

                                <BasicTextarea
                                    required
                                    clazz="has-error"
                                    labelFor="examplet2"
                                    textareaId="examplet2"
                                    label="textarea label"
                                    helpText="Help text"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                    textareaPlaceholder="Placeholder"
                                    helpIcon="banana banana-question-circle1"
                                />

                                <BasicTextarea
                                    required
                                    textAreaClazz="resize-active"
                                    labelFor="examplet2"
                                    textareaId="examplet2"
                                    label="textarea label"
                                    helpText="Help text"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                    textareaPlaceholder="Placeholder"
                                    helpIcon="banana banana-question-circle1"
                                    hideCounter
                                />

                                <h4 class="m-t-30 m-b-30">Password input</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>required:</strong> Shows an * if the input is required (Boolean).
                                    <br />
                                    <strong>inputType:</strong> It refers to the input type (String).
                                    <br />
                                    <strong>clazz:</strong> Add an extra class to the input container, for example
                                    .has-error if the input has an error (String).
                                    <br />
                                    <strong>labelFor:</strong> It refers to the for of the label, must be same as the ID
                                    (String).
                                    <br />
                                    <strong>inputId:</strong> It refers to the input ID, must be same as labelFor
                                    (String).
                                    <br />
                                    <strong>label:</strong> It refers to the input label (String).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>inputPlaceholder:</strong> It refers to the input placeholder (String).
                                    <br />
                                </p>
                                <form>
                                    <PasswordInput
                                        required
                                        inputType="password"
                                        labelFor="examplep1"
                                        inputId="examplep1"
                                        label="Input label"
                                        inputPlaceholder="Placeholder"
                                        help
                                        helpText="Help text"
                                        helpIcon="banana banana-question-circle1"
                                        level
                                        clazzPassword="good"
                                        status="Buena Contraseña"
                                    />
                                    <PasswordInput
                                        required
                                        inputType="password"
                                        clazz="has-error"
                                        labelFor="examplep2"
                                        inputId="examplep2"
                                        label="Input label"
                                        inputPlaceholder="Placeholder"
                                        level
                                        clazzPassword
                                        status="Muy débil"
                                    />
                                </form>

                                <h4 class="m-t-30 m-b-30">Password Strong levels</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>level:</strong> Shows 'StrongLevel' component.
                                    <br />
                                    <strong>levelText:</strong> Text to indicate the level (String).
                                    <br />
                                    <strong>clazz:</strong> Class to indicate strong level: 'weak, regular, good,
                                    excellent' (String).
                                </p>
                                <StrongLevel
                                    level
                                    clazz
                                    levelText="Muy débil"
                                ></StrongLevel>
                                <StrongLevel
                                    level
                                    clazz="weak"
                                    levelText="Malo"
                                ></StrongLevel>
                                <StrongLevel
                                    level
                                    clazz="regular"
                                    levelText="Regular"
                                ></StrongLevel>
                                <StrongLevel
                                    level
                                    clazz="good"
                                    levelText="Bueno"
                                ></StrongLevel>
                                <StrongLevel
                                    level
                                    clazz="excellent"
                                    levelText="Excelente"
                                ></StrongLevel>

                                <h4 class="m-t-30 m-b-30">Code input</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>showWarning</strong> Shows text bottom (Boolean).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message text (the text on the
                                    bottom).
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon.
                                    <br />
                                    <strong>clazz:</strong> Add an extra class to the textarea container, for example
                                    .has-error if the textarea has an error.
                                    <br />
                                </p>
                                <CodeInput />
                                <CodeInput
                                    showWarning
                                    warningText="El código de seguridad que escribiste no es válido, escríbelo nuevamente para intentarlo otra vez."
                                    clazz="has-error"
                                ></CodeInput>

                                <h4 class="m-t-30 m-b-30">Selects</h4>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>required:</strong> Shows an * if the input is required (Boolean).
                                    <br />
                                    <strong>clazz:</strong> Add an extra class to the input container, for example
                                    .has-error if the input has an error (String).
                                    <br />
                                    <strong>labelFor:</strong> It refers to the for of the label, must be same as
                                    inputId (String).
                                    <br />
                                    <strong>label:</strong> It refers to the input label (String).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon (String).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message's text (String).
                                    <br />
                                    <strong>slot:</strong> Content to insert component 'multiselect'.
                                    <br />
                                    <strong>disabled</strong> Must be added to the components 'BasicSelect' and
                                    'multiselect' to show a disabled component (Boolean).
                                    <br />
                                </p>
                                <a
                                    href="https://vue-multiselect.js.org/#sub-getting-started"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <BasicSelect
                                    required
                                    label="Label Title"
                                    labelFor
                                    help
                                    idHelpTooltip="ejGenerico4"
                                    helpText="Help text"
                                    helpIcon="banana banana-question-circle1"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                >
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                            target="ejGenerico4"
                                            placement="top"
                                            custom-class="white-tooltip"
                                        >
                                            <div>
                                                <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </template>

                                    <multiselect
                                        v-model="value"
                                        :options="options"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Select placeholder"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ values, search, isOpen }"
                                        >
                                            <span
                                                class="multiselect__single"
                                                v-if="values.length &amp;&amp; !isOpen"
                                            >{{ values.length }} options selected</span>
                                        </template>
                                    </multiselect>
                                </BasicSelect>
                                <BasicSelect
                                    clazz="has-error"
                                    required
                                    label="Label Title"
                                    labelFor
                                >
                                    <multiselect
                                        v-model="value"
                                        :options="options"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Select placeholder"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ values, search, isOpen }"
                                        >
                                            <span
                                                class="multiselect__single"
                                                v-if="values.length &amp;&amp; !isOpen"
                                            >{{ values.length }} options selected</span>
                                        </template>
                                    </multiselect>
                                </BasicSelect>
                                <BasicSelect
                                    label="Tags"
                                    labelFor="bannerNum"
                                >
                                    <multiselect
                                        v-model="valueTags"
                                        :options="optionsTags"
                                        placeholder="Elige los tags a incluir"
                                        tag-placeholder="Agregar como nuevo tag"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                        class="select-up"
                                        :multiple="true"
                                        :taggable="true"
                                    ></multiselect>
                                </BasicSelect>

                                <h4 class="m-t-30 m-b-30">Datepicker</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>labelFor:</strong> 'for' text for label (String).
                                    <br />
                                    <strong>label:</strong> Text for label (String).
                                    <br />
                                    <strong>required:</strong> To show required if needed (Boolean).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon (String).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message's text (String).
                                    <br />
                                    <strong>slot:</strong> Content to insert component 'datepicker'.
                                    <br />
                                </p>
                                <a
                                    href="https://www.npmjs.com/package/vuejs-datepicker"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <InputDatepicker
                                    labelFor="date"
                                    label="Fecha de Nacimiento"
                                    required
                                    help
                                    idHelpTooltip="ejGenerico5"
                                    helpText="Help text"
                                    helpIcon="banana banana-question-circle1"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                >
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                            target="ejGenerico5"
                                            placement="top"
                                            custom-class="white-tooltip"
                                        >
                                            <div>
                                                <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </template>
                                    <datepicker
                                        placeholder="DD / MM / AAAA"
                                        wrapper-class="form-datepicker"
                                        input-class="form-control"
                                        calendar-class="window-datepicker"
                                        calendar-button
                                        calendar-button-icon="banana banana-calendar"
                                        typeable
                                    />
                                </InputDatepicker>
                                <InputDatepicker
                                    clazz="has-error"
                                    required
                                    labelFor="date"
                                    label="Fecha de Nacimiento"
                                >
                                    <datepicker
                                        placeholder="DD / MM / AAAA"
                                        wrapper-class="form-datepicker"
                                        input-class="form-control"
                                        calendar-class="window-datepicker"
                                        calendar-button
                                        calendar-button-icon="banana banana-calendar"
                                        typeable
                                    />
                                </InputDatepicker>

                                <a
                                    href="https://innologica.github.io/vue2-daterange-picker/#usage"
                                    class="f-14 f-bold c-error d-block m-b-30"
                                ><i
                                        aria-hidden="true"
                                        class=""
                                    ></i> See Documentation Date
                                    range picker </a>
                                <InputDatepicker
                                    clazz="m-b-0"
                                    required
                                    labelFor="date"
                                    label="Fecha de Creación"
                                >
                                    <date-range-picker
                                        ref="picker"
                                        :opens="opens"
                                        :showDropdowns="showDropdowns"
                                        :autoApply="autoApply"
                                        v-model="dateRange"
                                        class="form-daterangepicker"
                                    >
                                        <!-- eslint-disable -->
                                        <template v-slot:input="picker">
                                            <span class="placeholder">DD/MM/AA - DD/MM/AA</span>
                                            <!-- This must be hidden when date selected -->
                                            <span>22/05/19 - 20/09/20</span> <!-- Show this when date selected -->
                                        </template>
                                        <!-- eslint-enable -->
                                    </date-range-picker>
                                    <i
                                        aria-hidden="true"
                                        class="banana banana-calendar icon-rangepicker"
                                    ></i>
                                </InputDatepicker>

                                <h4 class="m-t-30 m-b-30">Timepicker</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>labelFor:</strong> 'for' text for label (String).
                                    <br />
                                    <strong>label:</strong> Text for label (String).
                                    <br />
                                    <strong>required:</strong> To show required if needed (Boolean).
                                    <br />
                                    <strong>help</strong> Shows help component (Boolean).
                                    <br />
                                    <strong>idHelpTooltip:</strong> ID for tooltip on help component (String).
                                    <br />
                                    <strong>v-slot:tooltipHelp:</strong> Extra content for tooltip on help component.
                                    <br />
                                    <strong>helpText:</strong> It refers to the help message text (the text floating on
                                    the right) (String).
                                    <br />
                                    <strong>helpIcon:</strong> It refers to the help message's icon (String).
                                    <br />
                                    <strong>warningIcon:</strong> It refers to the warning message's icon (String).
                                    <br />
                                    <strong>warningText:</strong> It refers to the warning message's text (String).
                                    <br />
                                    <strong>slot:</strong> Content to insert component 'vue-clock-picker'.
                                    <br />
                                </p>
                                <a
                                    href="https://github.com/DomonJi/vue-clock-picker"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <InputTimepicker
                                    labelFor="time"
                                    label="Hora de inicio"
                                    required
                                    help
                                    idHelpTooltip="ejGenerico50"
                                    helpText="Help text"
                                    helpIcon="banana banana-question-circle1"
                                    warningText="Warning Text"
                                    warningIcon="banana banana-warning-circle1"
                                >
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip
                                            target="ejGenerico50"
                                            placement="top"
                                            custom-class="white-tooltip"
                                        >
                                            <div>
                                                <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </template>
                                    <vue-clock-picker
                                        mode="12"
                                        :defaultHour="defaultHour"
                                        :defaultMinute="defaultMinute"
                                        :onTimeChange="timeChangeHandler"
                                        class="clock-picker"
                                    ></vue-clock-picker>
                                </InputTimepicker>

                                <h4 class="m-t-30 m-b-30">Switch</h4>
                                <p class="f-14">
                                    Switch used on avocado.
                                    <br />
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>id:</strong> It refers to the Switch ID, must be same as labelFor.
                                    <br />
                                    <strong>label:</strong> It refers to the Switch label.
                                    <br />
                                    <strong>labelFor</strong> It refers to the label For, must be same as id.
                                </p>
                                <BasicSwitch
                                    id="ts1"
                                    labelFor="ts1"
                                ></BasicSwitch>
                                <br />
                                <BasicSwitch
                                    id="ts2"
                                    labelFor="ts2"
                                ></BasicSwitch>

                                <h4 class="m-t-30 m-b-30">Radio input</h4>
                                <p class="f-14">
                                    Radio input used on avocado.
                                    <br />
                                    <strong>Parameters:</strong>
                                    <br />
                                    <br />
                                    <strong>id:</strong> It refers to the Radio input ID.
                                    <br />
                                    <strong>for:</strong> It refers to the Radio input for.
                                    <br />
                                    <strong>text:</strong> It refers to the Radio input description.
                                    <br />
                                </p>
                                <RadioInput
                                    id="one"
                                    for="one"
                                    text="Opcion 1"
                                ></RadioInput>
                                <RadioInput
                                    id="two"
                                    for="two"
                                    text="Opcion 2"
                                ></RadioInput>

                                <h4 class="m-t-30 m-b-30">Checkbox</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <strong>text:</strong> It refers to the Checkbox text.
                                    <br />
                                    <strong>textClass:</strong> Extra class for text.
                                </p>
                                <BasicCheckbox text="Text sin index"></BasicCheckbox>
                                <BasicCheckbox
                                    text="Text index 0"
                                    :index="0"
                                    :currentValue="true"
                                ></BasicCheckbox>
                                <BasicCheckbox
                                    text="Text index 1"
                                    :index="1"
                                ></BasicCheckbox>

                                <h4 class="m-t-30 m-b-30">Radio options for type of client</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <strong>radioId:</strong> Input ID for radio (String).
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>fisica:</strong> To show image for type of client(Boolean).
                                    <br />
                                    <strong>titleList:</strong> Text for list title (String).
                                    <br />
                                    <strong>slot</strong> List content, must be on tag 'li'.
                                    <br />
                                </p>
                                <label class="f-18 c-title body-font-semibold f-600 d-block m-b-10">Selecciona si eres:</label>
                                <div class="persona-fisica-moral">
                                    <div>
                                        <ClientType
                                            radioId="radioFisica"
                                            fisica
                                            titleList="Persona Física"
                                        >
                                            <li>Soy una persona con derechos y obligaciones.</li>
                                            <li>Mi registro es con mi nombre.</li>
                                            <li>No necesito presentar contabilidad.</li>
                                        </ClientType>
                                        <ClientType
                                            radioId="radioMoral"
                                            titleList="Persona Moral"
                                        >
                                            <li>Soy una empresa o un grupo de personas.</li>
                                            <li>Mi registro es con una razón social.</li>
                                            <li>Necesito presentar contabilidad electrónica.</li>
                                        </ClientType>
                                    </div>
                                </div>

                                <h4 class="m-t-30 m-b-30">User Add</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>icon:</strong> Class for custom icon, default 'banana-user-add-user'
                                    (String).
                                    <br />
                                    <strong>title:</strong> Text for title (String).
                                    <br />
                                    <strong>subtitle:</strong> Text for subtitle (String).
                                    <br />
                                    <strong>slot</strong> List content, must be on tag 'li'.
                                    <br />
                                </p>
                                <div>
                                    <AddUser
                                        title="Añadir colaboradores a mi empresa"
                                        subtitle="Podrán administrar la cuenta junto a ti."
                                    >
                                        <li>
                                            baduel@bazzari.com.mx -
                                            <strong class="c-success">Invitación enviada</strong>
                                        </li>
                                        <li>
                                            pancho@bazzari.com.mx -
                                            <strong class="c-success">Invitación enviada</strong>
                                        </li>
                                        <li>
                                            dvidales@bazzari.com.mx -
                                            <strong class="c-success">Invitación enviada</strong>
                                        </li>
                                    </AddUser>
                                </div>

                                <!-- QUANTITY -->
                                <h4 class="m-t-30 m-b-30">Quantity</h4>
                                <p>
                                    <strong>Params:</strong>
                                    <br /><br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>title:</strong> Text for title's component (String).
                                    <br />
                                </p>
                                <Quantity title="Cantidad"></Quantity>
                                <Quantity
                                    title="Cantidad"
                                    clazz="xs float-left"
                                ></Quantity>
                            </div>
                        </div>

                        <!-- GRAPHS APEXCHARTS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Graphs APEXCHARTS</h3>
                                <a
                                    href="https://github.com/apexcharts/vue-apexcharts"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <br />
                                <a
                                    href="https://apexcharts.com/vue-chart-demos/line-charts/basic/"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Examples
                                </a>
                                <br />
                                <br />

                                <h3 class="f-20 f-bold c-accent_2">Chart Progress Information for Sidebarside Right</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>progress:</strong> Progress number completed (Number).
                                    <br />
                                    <strong>doneProgress:</strong> Progress number final (Number).
                                    <br />
                                </p>
                                <ChartProgressInfo
                                    :progress="88"
                                    :doneProgress="100"
                                ></ChartProgressInfo>

                                <h3 class="f-20 f-bold c-accent_2">Chart Bar for Dashboard</h3>
                                <div class="graph-container">
                                    <svg
                                        style="width:0;height:0;position:absolute;"
                                        aria-hidden="true"
                                        focusable="false"
                                    >
                                        <linearGradient
                                            id="gradient-fill"
                                            x2="1"
                                            y2="1"
                                        >
                                            <stop
                                                offset="20%"
                                                stop-color="#6992ea"
                                            />
                                            <stop
                                                offset="100%"
                                                stop-color="#c16bf0"
                                            />
                                        </linearGradient>
                                    </svg>
                                    <apexchart
                                        width="100%"
                                        height="300px"
                                        type="bar"
                                        :options="chartPagosBar"
                                        :series="seriesPagosBar"
                                        class="gradient-error-color"
                                    ></apexchart>
                                </div>
                            </div>
                        </div>

                        <!--  ICON LIST -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Icon list</h3>
                                <p>
                                    <strong>text:</strong> Principal text.
                                    <br />
                                    <strong>icon:</strong> Icon inside the square
                                </p>
                                <IconList text="(614) 349-2990" icon="banana-phone-call-normal"></IconList>
                                <IconList text="(614) 349-2990" icon="banana-phone-call-normal"></IconList>
                                <IconList text="(614) 349-2990" icon="banana-phone-call-normal"></IconList>
                            </div>
                        </div>

                        <!-- LOAD IMAGES / DOCUMENTS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Load images / documents</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazzContainer:</strong> Extra class for component CONTAINER (String).
                                    <br />
                                    <strong>titleBox:</strong> Text title for component (String).
                                    <br />
                                    <strong>showLoadBox:</strong> Shows 'upload-file' box (Boolean).
                                    <br />
                                    <strong>fileType</strong> Extra class to indicate (String).
                                    <br />
                                    <strong>loadText:</strong> Text to show on 'upload-file' box (String).
                                    <br />
                                    <strong>showLoadProgress:</strong> Shows 'progress-loading' box (Boolean).
                                    <br />
                                    <strong>v-slot:componentProgress:</strong> Extra content to add the component
                                    Progress Circle.
                                    <br />
                                    <strong>showLoadStatus:</strong> Shows 'status-loading' box (Boolean).
                                    <br />
                                    <strong>retry:</strong> To show 'retry' image (Boolean).
                                    <br />
                                    <strong>success:</strong> To show 'success' image (Boolean).
                                    <br />
                                    <strong>statusTitle:</strong> Text title for 'status-loading' box (Boolean).
                                    <br />
                                    <strong>statusText:</strong> Text subtitle for 'status-loading' box (Boolean).
                                    <br />
                                    <strong>imagePreview</strong> Slot to add preview image content.
                                    <br />
                                    <strong>v-slot:button:</strong> Slot to add buttons if needed.
                                    <br />
                                    <strong>v-slot:noteText:</strong> Slot to add a bottom Note if needed.
                                    <br />
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-boxUpload
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-boxUpload"
                                        class="m-t-20"
                                    >
                                        <h5 class="f-14 f-bold c-accent_1">Ej. BOX 'upload-file' w/front image ID</h5>
                                        <UploadBox
                                            titleBox="Fotografía frontal:"
                                            showLoadBox
                                            loadText="Puedes arrastrar tu fotografía aquí o también puedes:"
                                            :imgUrl="imagenCard"
                                        >
                                            <template v-slot:button>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 p-relative w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-image"
                                                        ></i> Seleccionar imagen
                                                        <input
                                                            id
                                                            type="file"
                                                            class="input-file"
                                                            value
                                                            name
                                                        />
                                                    </a>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-camera"
                                                        ></i> Tomar con webcam
                                                    </a>
                                                </div>
                                            </template>

                                            <template v-slot:noteText>
                                                <SimpleNote
                                                    clazz="info m-t-10"
                                                    icon="d-none"
                                                >
                                                    <p class="m-b-0">
                                                        El tamaño mínimo de la imagen debe ser
                                                        <strong>600px</strong> por
                                                        <strong>300px</strong> y un peso menor de
                                                        <strong>1 MB</strong>. Puedes subir imágenes de tipo
                                                        <strong>.JPG, .PNG y .PDF.</strong>
                                                    </p>
                                                </SimpleNote>
                                            </template>
                                        </UploadBox>

                                        <h5 class="f-14 f-bold c-accent_1">Ej. BOX 'upload-file' back image ID</h5>
                                        <UploadBox
                                            titleBox="Fotografía frontal:"
                                            showLoadBox
                                            loadText="Puedes arrastrar tu fotografía aquí o también puedes:"
                                            :imgUrl="imageBackCard"
                                        >
                                            <template v-slot:button>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 p-relative w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-image"
                                                        ></i> Seleccionar imagen
                                                        <input
                                                            id
                                                            type="file"
                                                            class="input-file"
                                                            value
                                                            name
                                                        />
                                                    </a>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-camera"
                                                        ></i> Tomar con webcam
                                                    </a>
                                                </div>
                                            </template>

                                            <template v-slot:noteText>
                                                <SimpleNote
                                                    clazz="info m-t-10"
                                                    icon="d-none"
                                                >
                                                    <p class="m-b-0">
                                                        El tamaño mínimo de la imagen debe ser
                                                        <strong>600px</strong> por
                                                        <strong>300px</strong> y un peso menor de
                                                        <strong>1 MB</strong>. Puedes subir imágenes de tipo
                                                        <strong>.JPG, .PNG y .PDF.</strong>
                                                    </p>
                                                </SimpleNote>
                                            </template>
                                        </UploadBox>

                                        <h5 class="f-14 f-bold c-accent_1">Ej.</h5>
                                        <UploadBox
                                            titleBox="Fotografía frontal:"
                                            showLoadProgress
                                        >
                                            <template v-slot:componentProgress>
                                                <ProgressCircle
                                                    filename="DSC10298.jpg"
                                                    filesize="286 KB"
                                                >
                                                    <radial-progress-bar
                                                        :diameter="diam"
                                                        :totalSteps="totalPrct"
                                                        :completedSteps="completedPrct"
                                                        startColor="#c16bf0"
                                                        stopColor="#6992ea"
                                                        innerStrokeColor="#FFF"
                                                        :strokeWidth="strokeW"
                                                        timingFunc="ease-in-out"
                                                    >
                                                        <p class="percentage">
                                                            <strong>59</strong>%
                                                        </p>
                                                    </radial-progress-bar>
                                                </ProgressCircle>
                                            </template>

                                            <template v-slot:noteText>
                                                <SimpleNote
                                                    clazz="info m-t-10"
                                                    icon="d-none"
                                                >
                                                    <p class="m-b-0">
                                                        El tamaño mínimo de la imagen debe ser
                                                        <strong>600px</strong> por
                                                        <strong>300px</strong> y un peso menor de
                                                        <strong>1 MB</strong>. Puedes subir imágenes de tipo
                                                        <strong>.JPG, .PNG y .PDF.</strong>
                                                    </p>
                                                </SimpleNote>
                                            </template>
                                        </UploadBox>

                                        <h5 class="f-14 f-bold c-accent_1">Ej. BOX 'status-loading' RETRY</h5>
                                        <UploadBox
                                            titleBox="Fotografía frontal:"
                                            showLoadStatus
                                            retry
                                            statusTitle="¡Ups! Algo salió mal"
                                            statusText="Reintenta revisando que la imagen cumpla con los requisitos de abajo."
                                        >
                                            <template v-slot:button>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 p-relative w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-image"
                                                        ></i> Seleccionar imagen
                                                        <input
                                                            id
                                                            type="file"
                                                            class="input-file"
                                                            value
                                                            name
                                                        />
                                                    </a>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-camera"
                                                        ></i> Tomar con webcam
                                                    </a>
                                                </div>
                                            </template>

                                            <template v-slot:noteText>
                                                <SimpleNote
                                                    clazz="info m-t-10"
                                                    icon="d-none"
                                                >
                                                    <p class="m-b-0">
                                                        El tamaño mínimo de la imagen debe ser
                                                        <strong>600px</strong> por
                                                        <strong>300px</strong> y un peso menor de
                                                        <strong>1 MB</strong>. Puedes subir imágenes de tipo
                                                        <strong>.JPG, .PNG y .PDF.</strong>
                                                    </p>
                                                </SimpleNote>
                                            </template>
                                        </UploadBox>

                                        <h5 class="f-14 f-bold c-accent_1">Ej. BOX 'status-loading' SUCCESS</h5>
                                        <UploadBox
                                            titleBox="Fotografía frontal:"
                                            showLoadStatus
                                            success
                                            statusTitle="Carga Completa"
                                            statusText="Se han subido y verificado las fotografías."
                                        >
                                            <template v-slot:button>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 p-relative w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-image"
                                                        ></i> Seleccionar imagen
                                                        <input
                                                            id
                                                            type="file"
                                                            class="input-file"
                                                            value
                                                            name
                                                        />
                                                    </a>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <a
                                                        class="button xs button-stroke button-accent2 w-100"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-camera"
                                                        ></i> Tomar con webcam
                                                    </a>
                                                </div>
                                            </template>

                                            <template v-slot:noteText>
                                                <SimpleNote
                                                    clazz="info m-t-10"
                                                    icon="d-none"
                                                >
                                                    <p class="m-b-0">
                                                        El tamaño mínimo de la imagen debe ser
                                                        <strong>600px</strong> por
                                                        <strong>300px</strong> y un peso menor de
                                                        <strong>1 MB</strong>. Puedes subir imágenes de tipo
                                                        <strong>.JPG, .PNG y .PDF.</strong>
                                                    </p>
                                                </SimpleNote>
                                            </template>
                                        </UploadBox>

                                        <h5 class="f-14 f-bold c-accent_1">Ej. BOX 'upload-file' generic image</h5>
                                        <UploadBox
                                            showLoadBox
                                            loadText="Puedes arrastrar tu fotografía aquí o también puedes:"
                                            :imgUrl="imageProfile"
                                        >
                                            <template v-slot:button>
                                                <div class="col-12">
                                                    <a
                                                        class="button xs button-stroke button-accent2 m-0-auto"
                                                        tabindex
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="banana banana-camera"
                                                        ></i> Tomar con webcam
                                                    </a>
                                                </div>
                                            </template>

                                            <template v-slot:noteText>
                                                <p class="f-14 c-info_text body-font-regular text-align-c m-b-0">
                                                    ¿No cuentas con una webcam?
                                                    <strong class="c-accent_2 body-font-bold">Puedes cargar tu fotografía más tarde.</strong> Te recordamos que
                                                    <strong class="c-info_text body-font-bold">no podrás realizar pagos, movimientos o consultas si tu información
                                                        no está completa.</strong>
                                                </p>
                                            </template>
                                        </UploadBox>

                                        <h5 class="f-14 f-bold c-accent_1">Ej. BOX Logo/Portada</h5>
                                        <div class="row">
                                            <div class="col-12">
                                                <UploadBox titleBox="Logotipo:" showLoadBox
                                                           clazzContainer="box-logo-container m-b-0"
                                                           fileType="no-img-back">
                                                            <template v-slot:imagePreview>
                                                                <div class="img-card">
                                                                    <img class="img-fluid logo"
                                                                        src=""
                                                                        alt="Logotipo"/>
                                                                </div>
                                                            </template>

                                                            <template v-slot:button>
                                                                <div class="col-12">
                                                                    <a class="button xs button-stroke button-accent2 p-relative w-100"
                                                                    tabindex="">
                                                                        <i aria-hidden="true" class="banana banana-camera"></i> Subir Logotipo
                                                                    </a>
                                                                </div>
                                                            </template>

                                                            <template v-slot:noteText>
                                                                <SimpleNote clazz="info" icon="banana-info-circle1">
                                                                    <p class="m-b-0">El tamaño de la imagen debe ser 200px por
                                                                        200px y el peso máximo debe ser 300kb. Puedes subir
                                                                        imagenes tipo: JPG, PNG y SVG. </p>
                                                                </SimpleNote>
                                                            </template>
                                                </UploadBox>
                                            </div>
                                            <div class="col-12 m-t-30">
                                                <UploadBox titleBox="Foto de Portada:" showLoadBox
                                                           clazzContainer="box-portada-container m-b-0"
                                                           fileType="no-img-back">
                                                            <template v-slot:imagePreview>
                                                                <div class="img-card">
                                                                    <img class="img-fluid portada"
                                                                        src=""
                                                                        alt="Logotipo"/>
                                                                </div>
                                                            </template>

                                                            <template v-slot:button>
                                                                <div class="col-12">
                                                                    <a class="button xs button-stroke button-accent2 p-relative w-100"
                                                                    tabindex="">
                                                                        <i aria-hidden="true" class="banana banana-image"></i> Cambia la imagen de
                                                                        portada
                                                                    </a>
                                                                </div>
                                                            </template>
                                                </UploadBox>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!--  LOADING PAGE -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Loading page</h3>
                                <p>
                                    <strong class="c-warning">The component is commented on the code</strong>
                                    <br />
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>title:</strong> Principal text.
                                    <br />
                                    <strong>strongTitle:</strong> Strong part of the text.
                                    <br />
                                    <strong>text:</strong> Text below the title.
                                    <br />
                                    <strong>btnText:</strong> The continue button.
                                    <br />
                                    <strong>btnContinue:</strong> Boolean
                                    <br />
                                    <strong>textComplete:</strong> Boolean
                                    <br />
                                </p>
                            </div>
                        </div>

                        <!-- LOADING SIMPLE -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Loading Simple</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for size and text: "small-load / medium-load /
                                    big-load / no-text" (String).
                                    <br />
                                    <strong>label:</strong> Text for loding (String).
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-loading
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-loading"
                                        class="m-t-20"
                                    >
                                        <h2>Default Size</h2>
                                        <LoadingSimple label="Loading..."></LoadingSimple>
                                        <br />

                                        <h2>Small</h2>
                                        <LoadingSimple
                                            clazz="small-load"
                                            label="Loading..."
                                        ></LoadingSimple>
                                        <br />

                                        <h2>Medium</h2>
                                        <LoadingSimple
                                            clazz="medium-load"
                                            label="Loading..."
                                        ></LoadingSimple>
                                        <br />

                                        <h2>Big</h2>
                                        <LoadingSimple
                                            clazz="big-load"
                                            label="Loading..."
                                        ></LoadingSimple>
                                        <br />

                                        <h2>NO Text</h2>
                                        <LoadingSimple clazz="no-text"></LoadingSimple>
                                        <br />
                                    </b-collapse>
                                </div>

                                <h2 class="m-b-20 m-t-20">Loading Bar</h2>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Add an extra class.
                                    <br />
                                    <strong>loadingText:</strong> Text.
                                    <br />
                                </p>
                                <div class="col-12">
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem eveniet iste
                                        architecto vitae ad repudiandae molestias ullam modi, provident, quos veniam?
                                        Recusandae, natus totam eligendi vero accusamus impedit minima perspiciatis.
                                        <br />Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem eveniet
                                        iste architecto vitae ad repudiandae molestias ullam modi, provident, quos
                                        veniam? Recusandae, natus totam eligendi vero accusamus impedit minima
                                        perspiciatis.
                                        <br />Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem eveniet
                                        iste architecto vitae ad repudiandae molestias ullam modi, provident, quos
                                        veniam? Recusandae, natus totam eligendi vero accusamus impedit minima
                                        perspiciatis.
                                        <br />Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem eveniet
                                        iste architecto vitae ad repudiandae molestias ullam modi, provident, quos
                                        veniam? Recusandae, natus totam eligendi vero accusamus impedit minima
                                        perspiciatis.
                                        <br />
                                    </p>
                                    <LoadingBar loadingText="Loading text..."></LoadingBar>
                                </div>
                            </div>
                        </div>

                        <!-- MAP -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Map</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br><br>
                                    <strong>clazz:</strong> Extra class.
                                    <br>
                                    <strong>height:</strong> Map height.
                                    <br>
                                    <strong>searchBar (boolean):</strong> Searchbar full width (100%).
                                    <br>
                                    <strong>searchBarButton (boolean):</strong> Search bar with location button at the end.
                                    <br>
                                    <strong>locationOption (boolean):</strong> Left menu with location options.
                                    <br>
                                    <strong>tooltipMark (boolean):</strong> Add a tooltip to the mark.
                                    <br>
                                    <strong>tooltipText (string):</strong> Tooltip text.
                                    <br>
                                    <br>
                                    <strong class="c-error f-25">NOTA A DESARROLLADORES: CAMBIAR API KEY de
                                        vue2-google-maps.js</strong>

                                </p>
                                <a
                                    href="https://github.com/xkjyeah/vue-google-maps"
                                    class="f-14 f-bold c-error d-block m-b-30"
                                ><i
                                        aria-hidden="true"
                                        class=""
                                    ></i> See Documentation </a>

                                <!-- CAMBIAR API KEY -->
                                <!-- CAMBIAR API KEY -->
                                <!-- CAMBIAR API KEY -->
                                <GoogleMap
                                    clazz="m-b-30"
                                    height="height:550px"
                                    searchBarButton
                                    locationOption
                                    tooltipMark
                                    tooltipText="Arrastra para cambiar la ubicación o haz clic para obtener información."
                                >
                                </GoogleMap>
                                <!-- CAMBIAR API KEY -->
                                <!-- CAMBIAR API KEY -->
                                <!-- CAMBIAR API KEY -->

                            </div>
                        </div>

                        <!-- MODALS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">
                                    Modals
                                    <strong>Various</strong>
                                </h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>id:</strong> Modal's ID (String).
                                    <br />
                                    <strong>size:</strong> Modal's size: sm, lg, xl (String).
                                    <br />
                                    <strong>hide-header / hide-footer:</strong> Params to hide header and footer.
                                    <br />
                                    <strong>no-close-on-esc / no-close-on-backdrop / hide-header-close:</strong>Params
                                    to stop default closing with ESC, click backdrop and click close icon.
                                    <br />
                                    <strong>slot</strong> Aditional content for modal's body.
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/modal"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <div class="horizontal-center m-b-20">
                                    <button
                                        class="button xs button-filled button-accent1 m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-notification')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Notification</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-accent2 m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-termsCond')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Terminos y Condiciones</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-primary1 m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-newUpdates')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>NEW UPDATES</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-primary2 m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-resumeSteps')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>RESUME STEPS</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-title m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-fullUpload')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>UPLOADING FILE, SUCCESS, RETRY</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-accent1 m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-acceso')"
                                        type="button"
                                    >
                                        Autoriza acceso
                                    </button>

                                    <button
                                        class="button xs button-filled button-success m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-aprobSol')"
                                        type="button"
                                    >
                                        Visor de archivos
                                    </button>
                                    <button
                                        id=""
                                        class="button xs button-filled button-warning m-r-10 m-b-10"
                                        @click="$bvModal.show('modal-detailsService')"
                                        type="button"
                                    >
                                        Service Details
                                    </button>
                                </div>

                                <!-- First modal / next -->
                                <b-modal
                                    id="modal-notification"
                                    size="lg"
                                    hide-header
                                    hide-footer
                                    no-close-on-esc
                                    no-close-on-backdrop
                                    hide-header-close
                                >
                                    <div class="modalNotification">
                                        <h1 class="title">¿Qué pasa si no actualizo mi información?</h1>
                                        <img
                                            class="img-fluid"
                                            src="@/assets/images/Illustrations/illustration-modal-delete.svg"
                                            alt="illustration"
                                        />
                                        <p class="subtitle">
                                            Estamos trabajando en brindarte un mejor servicio y para ello necesitaremos
                                            que nos proporciones más datos para poder verificar tu cuenta. Por
                                            regulaciones de la entrada en vigor de la
                                            <strong class="c-accent_2">Ley Fintech</strong> y Prevención de Lavado de
                                            Dinero.
                                        </p>
                                        <button
                                            id
                                            class="button xs button-filled button-accent1"
                                            type="button"
                                        >Actualizar mi información
                                        </button>
                                        <a
                                            class="f-14 f-600 c-accent_2 body-font-semibold text-align-c d-block w-100 c-pointer"
                                            @click="$bvModal.show('modal-notificationNext')"
                                            tabindex
                                        >
                                            <u>¿Por qué debo hacer esto?</u>
                                        </a>
                                    </div>
                                </b-modal>
                                <b-modal
                                    id="modal-notificationNext"
                                    size="lg"
                                    hide-header
                                    hide-footer
                                    no-close-on-esc
                                    no-close-on-backdrop
                                    hide-header-close
                                >
                                    <div class="modalNotification">
                                        <h1 class="title">¿Qué pasa si no actualizo mi información?</h1>
                                        <img
                                            class="img-fluid"
                                            src="@/assets/images/Illustrations/illustration-modal-delete.svg"
                                            alt="illustration"
                                        />
                                        <p class="subtitle">Al no actualizar tu información, tu cuenta permanecerá inactiva y con acceso
                                            restringido, es decir: no podrás acceder para realizar pagos o consultar
                                            información relacionada a tu cuenta.</p>
                                        <button
                                            id
                                            class="button xs button-filled button-accent1"
                                            type="button"
                                        >Actualizar mi información
                                        </button>
                                        <button
                                            id
                                            class="button xs button-stroke button-accent1 m-t-30"
                                            type="button"
                                        >Salir de pagando
                                        </button>
                                        <small class="f-14 c-info_text body-font-regular text-align-c m-t-15 d-block w-100">
                                            Si requieres asistencia técnica, por favor contáctanos a los teléfonos:
                                            <br />
                                            <strong class="body-font-bold">01 800 461 0119</strong> para México.
                                        </small>
                                    </div>
                                </b-modal>
                                <!-- Terms and Conditions modal -->
                                <b-modal
                                    id="modal-termsCond"
                                    size="xl"
                                    hide-header
                                    hide-footer
                                    no-close-on-esc
                                    no-close-on-backdrop
                                    hide-header-close
                                >
                                    <div class="modalTermsCond">
                                        <h1 class="title">
                                            <strong>Términos y Condiciones</strong> de uso de Pagando
                                        </h1>
                                        <span class="date-update">
                                            <small>Última actualización:</small> 04 marzo, 2019
                                        </span>
                                        <div class="text-container">
                                            <h1>Aviso legal y Términos de uso de Pagando v 1.0.1</h1>
                                            <p>
                                                <strong>Términos y condiciones del uso mediante este aviso.</strong>
                                                <br />PAGANDO informa a los usuarios de la página web de su política de
                                                protección de datos de carácter personal. La utilización del espacio web
                                                de PAGANDO y de cualquiera de los servicios que se incorporan presupone
                                                la plena aceptación de las condiciones que se manifiestan en la política
                                                de privacidad que se expone.
                                                <br />
                                                <br />
                                                <strong>Recogida de datos.</strong>
                                                <br />Dando cumplimiento a la Ley 15/1999, de 13 de diciembre, de
                                                protección de datos de carácter personal, se informa que los datos de
                                                carácter personal que se solicitan en nuestros formularios se incluirán
                                                en un fichero de datos personales el responsable y titular del cual es
                                                PAGANDO. Así mismo, cuando una persona rellena cualquiera de los
                                                formularios con los datos personales que se solicitan y acepta el envío,
                                                autoriza expresamente a PAGANDO a tratar o incorporar en el fichero
                                                automatizado de su propiedad los datos personales facilitados en el
                                                formulario mencionado y todos los datos que se generen en relación con
                                                su participación o uso de los diferentes eventos que se ofrecen en esta
                                                página web.
                                                <br />
                                                <br />
                                                <strong>Uso de datos.</strong>
                                                <br />Dando cumplimiento a la Ley 15/1999, de 13 de diciembre, de
                                                protección de datos de carácter personal, se informa que los datos de
                                                carácter personal que se solicitan en nuestros formularios se incluirán
                                                en un fichero de datos personales el responsable y titular del cual es
                                                PAGANDO. Así mismo, cuando una persona rellena cualquiera de los
                                                formularios con los datos personales que se solicitan y acepta el envío,
                                                autoriza expresamente a PAGANDO a tratar o incorporar en el fichero
                                                automatizado de su propiedad los datos personales facilitados en el
                                                formulario mencionado y todos los datos que se generen en relación con
                                                su participación o uso de los diferentes eventos que se ofrecen en esta
                                                página web.
                                                <br />
                                                <br />
                                            </p>
                                        </div>
                                        <button
                                            id
                                            class="button xs button-filled button-accent1"
                                            @click="$bvModal.hide('modal-termsCond')"
                                            type="button"
                                        >Cerrar
                                        </button>
                                    </div>
                                </b-modal>
                                <!-- New Updates -->
                                <b-modal
                                    id="modal-newUpdates"
                                    size="lg"
                                    hide-header
                                    hide-footer
                                    no-close-on-backdrop
                                >
                                    <b-button @click="$bvModal.hide('modal-newUpdates')">Cerrar</b-button>
                                    <div class="modalNewUpdates">
                                        <b-carousel
                                            id="carousel-1"
                                            v-model="slide"
                                            :interval="100000"
                                            controls
                                            indicators
                                            @sliding-start="onSlideStart"
                                            @sliding-end="onSlideEnd"
                                        >
                                            <div v-if="showHorizontalImg">
                                                <b-carousel-slide img-src="https://cucumber.blacklabs.mx/images/Pagando/backgrounds/modals/whats-new.jpg"></b-carousel-slide>
                                                <b-carousel-slide img-src="https://i.pinimg.com/originals/2a/48/d1/2a48d14eea1a9a5ebd1970437c86e1f6.jpg"></b-carousel-slide>
                                            </div>
                                            <div v-if="showVerticalImg">
                                                <b-carousel-slide img-src="https://cucumber.blacklabs.mx/images/Pagando/backgrounds/modals/whats-new-vertical.jpg">
                                                </b-carousel-slide>
                                                <b-carousel-slide img-src="http://0.media.dorkly.cvcdn.com/52/73/db6be9ff2adbb87dde9d2e3114ea3c41.jpg">
                                                </b-carousel-slide>
                                            </div>
                                        </b-carousel>
                                    </div>
                                </b-modal>
                                <!-- Resume steps -->
                                <b-modal
                                    id="modal-resumeSteps"
                                    size="lg"
                                    hide-header
                                    hide-footer
                                >
                                    <div class="p-40">
                                        <h1 class="f-35 c-accent_2 principal-font-bold d-block m-b-20">Resumen</h1>
                                        <b-tabs
                                            class="simple-tabs group-tabs"
                                            content-class="simple-tabs-body p-t-20"
                                        >
                                            <b-tab
                                                title="Datos Generales"
                                                active
                                            >
                                                <SimpleTimelineInfo
                                                    label="Dirección"
                                                    content="Ave. Independencia #9102-A, Col. Centro, CP 31000, Chihuahua, Chih. México"
                                                ></SimpleTimelineInfo>
                                            </b-tab>
                                            <b-tab title="Datos Fiscales">
                                                <SimpleTimelineInfo
                                                    label="Datos de Facturación"
                                                    content="Miguel Bernal Cuevas Calle Oaxaca #3721 Col. Las Cruces Mazatlan,
                                                        Sinaloa. México CP. 82030 Tel. (669)323 2541 facturación@bazzari.com.mx"
                                                ></SimpleTimelineInfo>
                                            </b-tab>
                                            <b-tab title="Logotipo y Portada">
                                                <PreviewLogoPortada
                                                    title="Vista Previa de Portada y Logotipo"
                                                    mnsj="Recuerda que siempre puedes cambiar tu logotipo y foto de portada desde la sección “Mi Organización” en tu perfil."
                                                    nombreEmp="Bazzari"
                                                    numServ="10"
                                                ></PreviewLogoPortada>
                                            </b-tab>
                                            <b-tab title="Cuenta Bancaria">
                                                <SimpleTimelineInfo
                                                    label="Clabe Interbancaria"
                                                    content="**************2020"
                                                ></SimpleTimelineInfo>
                                            </b-tab>
                                        </b-tabs>
                                        <button
                                            class="button xs button-filled button-accent1 m-t-30 m-0-0-0-auto"
                                            type="button"
                                            @click="$bvModal.hide('modal-resumeSteps')"
                                        >Cerrar
                                        </button>
                                    </div>
                                </b-modal>
                                <!-- MODAL UPLOAD FILE CARGANDO / SUCCESS / RETRY-->
                                <b-modal
                                    id="modal-fullUpload"
                                    hide-header
                                    hide-footer
                                    hide-header-close
                                >
                                    <div class="p-20">
                                        <ProgressCircle
                                            filename="DSC10298.jpg"
                                            filesize="286 KB"
                                        >
                                            <radial-progress-bar
                                                :diameter="diam"
                                                :totalSteps="totalPrct"
                                                :completedSteps="completedPrct"
                                                startColor="#c16bf0"
                                                stopColor="#6992ea"
                                                innerStrokeColor="#FFF"
                                                :strokeWidth="strokeW"
                                                timingFunc="ease-in-out"
                                            >
                                                <p class="percentage">
                                                    <strong>59</strong>%
                                                </p>
                                            </radial-progress-bar>
                                        </ProgressCircle>

                                        <ProgressCircle
                                            progressDone
                                            filename="promo-cinepolis_Agosto2019.xls"
                                            filesize="286 KB"
                                        ></ProgressCircle>

                                        <ProgressCircle progressRetry></ProgressCircle>
                                    </div>
                                </b-modal>

                                <!-- acceso modal -->
                                <ModalDefault
                                    :originalFooter="false"
                                    modalId="modal-acceso"
                                    title="Autoriza tu acceso"
                                    subtitle="Por la seguridad de tu cuenta, te pediremos que verifiques tu número telefónico con un Código que te enviaremos vía SMS."
                                    buttonText="VERIFICAR">
                                    <BasicInput
                                        badge
                                        inputType="text"
                                        labelFor="celular"
                                        inputId="celular"
                                        label="Número de celular"
                                        inputPlaceholder="Número con terminación: ****6948"
                                        badgeType="badge badge-success"
                                        badgeText="Verificado"
                                    />
                                    <h2 class="f-18 c-title body-font-regular text-center f-700 m-b-10">
                                        Ingresa tu código de seguridad
                                    </h2>
                                    <p class="f-14 c-plain_text body-font-regular text-center m-0 m-b-20">
                                        Te enviamos tu código por mensaje de texto al número celular con terminación:
                                        <strong>****6948</strong>.
                                    </p>
                                    <CodeInput
                                        showWarning
                                        warningText="El código de seguridad que escribiste no es válido, escríbelo nuevamente para intentarlo otra vez."
                                        clazz="has-error"
                                    ></CodeInput>
                                    <div class="verified-check justify-content-center m-b-25">
                                        <i
                                            aria-hidden="true"
                                            class="banana banana-check-circle1"
                                        ></i>
                                        <span>
                                            Verificado Correctamente
                                        </span>
                                    </div>

                                    <p class="f-14 c-plain_text body-font-regular text-center m-b-30">
                                        Tu código de seguridad puede tardar unos minutos en llegar.
                                        ¿No recibiste ningún mensaje? <a
                                            class="c-accent_2 text-underline f-bold"
                                            href="#"
                                        >Haz clic aquí para reenviar el código
                                            de seguridad.</a>
                                    </p>
                                    <p class="f-14 c-info_text body-font-regular text-center m-0">
                                        Si requieres asistencia técnica, por favor contáctanos a los teléfonos: <strong>800
                                            461 0119</strong> para México y <strong>800 392 6948</strong> para Estados
                                        Unidos. O bien, envía un correo a <strong>soporte@pagando.mx</strong>.
                                    </p>
                                    <template v-slot:customFooter>
                                        <button
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            type="button"
                                        >Verificar
                                        </button>
                                    </template>

                                </ModalDefault>

                                <!-- VISOR DE ARCHIVOS -->
                                <ModalDefault
                                    modalId="modal-aprobSol"
                                    size="lg"
                                    bodyClass="p-0"
                                    title="Visor de archivos"
                                >

                                    <div class="bgm-gray p-r-15 border-top-10 border-bottom-10">
                                        <div class="col-12 y-scroll o-auto p-50 max-h-410px">
                                            <div class="w-100">
                                                <!-- Add style "min-width: 999px;" to the image for the resize -->
                                                <img
                                                    class="img-fluid w-100"
                                                    src="https://image.isu.pub/171031034439-1b5ca30cd6a6a18866d875c4e9e78880/jpg/page_1.jpg"
                                                    alt=""
                                                >
                                            </div>
                                        </div>

                                    </div>

                                    <template v-slot:customFooter>
                                        <div class="d-flex w-100 justify-space-between align-item-c">
                                            <div class="d-flex align-items-c">
                                                <button
                                                    type="button"
                                                    class="button-square xs button-filled button-accent2 m-r-10"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        class="banana banana-search-minus"
                                                    ></i>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="button-square xs button-filled button-accent2"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        class="banana banana-search-plus"
                                                    ></i>
                                                </button>
                                            </div>

                                            <div class="vertical-center">
                                                <button
                                                    type="button"
                                                    class="button xs button-ghost button-plain_text m-r-10"
                                                > Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </ModalDefault>

                                <!-- SERVICE DETAILS -->
                                <ModalDefault
                                    modalId="modal-detailsService"
                                    size="lg">
                                    <PaymentInfoRow
                                        organization="Colegio Montessori"
                                        paymentName="Inscripción escolar Montessori"
                                        date="7 Marzo, 2019 - 11:36 a.m."
                                        exchange="MXN"
                                        cant="$3,000.00"
                                        category="educacion"
                                        showCategory
                                        clazz="m-b-40 m-t--10"
                                    >
                                    </PaymentInfoRow>

                                    <b-tabs
                                        class="simple-tabs group-tabs"
                                        content-class="simple-tabs-body p-t-30 min-height-300px"
                                        active-tab-class="primary-bg">
                                        <b-tab
                                            title="Datos del Usuario"
                                            active
                                        >

                                            <div class="row">
                                                <div class="col-sm-6 col-12 m-b-30">
                                                    <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                                        Usuario
                                                    </h1>
                                                    <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                                        Carlos Alfredo Perez
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12 m-b-30">
                                                    <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                                        Correo electrónico
                                                    </h1>
                                                    <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                                        carlos_pe@gmail.com
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12 m-b-30">
                                                    <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                                        Teléfono
                                                    </h1>
                                                    <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                                        <strong>+52</strong> (614)-198-8471
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Datos del Pago">
                                            <div class="row">
                                                <div class="col-sm-6 col-12 m-b-30">
                                                    <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                                        Tipo de Pago
                                                    </h1>
                                                    <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                                        Pago con Requisitos
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12 m-b-30">
                                                    <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                                        Matrícula
                                                    </h1>
                                                    <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                                        12345678
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-12 m-b-30">
                                                    <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                                        CURP
                                                    </h1>
                                                    <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                                        CGSA150487HCHNMS05
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Notas Adicionales">
                                            <div class="row">
                                                <div class="col-sm-6 col-12 m-b-30">
                                                    <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                                        Ingresa el taller de tu preferencia
                                                    </h1>
                                                    <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                                        Danza contemporánea
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Detalles de Transacción">
                                            <div class="dots-table m-b-30">
                                                <div class="item-row">
                                                    <div class="left-side">
                                                        Tipo de Tarjeta
                                                    </div>
                                                    <div class="right-side">
                                                        Tarjeta de Crédito
                                                    </div>
                                                </div>
                                                <div class="item-row">
                                                    <div class="left-side">
                                                        Subtotal
                                                    </div>
                                                    <div class="right-side f-bold">
                                                        $3,000.00
                                                    </div>
                                                </div>
                                                <div class="item-row">
                                                    <div class="left-side">
                                                        Descuentos
                                                    </div>
                                                    <div class="right-side c-success f-bold">
                                                        - $500.00
                                                    </div>
                                                </div>
                                                <div class="item-row">
                                                    <div class="left-side">
                                                        Total
                                                    </div>
                                                    <div class="right-side f-bold">
                                                        $2,500.00
                                                    </div>
                                                </div>
                                                <div class="item-row">
                                                    <div class="left-side">
                                                        Comisión bancaria
                                                    </div>
                                                    <div class="right-side f-bold">
                                                        $87.00
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-lg-flex align-items-c justify-space-between">
                                                <div class="d-flex align-items-c ">
                                                    <div class="c-primary_1 principal-font-bold f-13">
                                                        Estatus Transacción
                                                    </div>
                                                    <Badge
                                                        type="badge-error m-l-10"
                                                        text="fraudulenta"
                                                    ></Badge>
                                                </div>
                                                <div class="d-flex align-items-c ">
                                                    <div class="principal-font-bold f-13 c-primary_1 m-r-20">
                                                        TOTAL A DEPOSITAR
                                                    </div>
                                                    <div class="body-font-bold f-28 c-accent_2">
                                                        $2,413.00
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                    </b-tabs>

                                    <template v-slot:customFooter>
                                        <div class="w-100">
                                            <div class="row">
                                                <div class="col-sm-4 col-12 d-flex align-items-c">
                                                    <div class="c-title d-14 body-font-regular">
                                                        Folio: <strong>23129</strong>
                                                    </div>
                                                </div>
                                                <div class="col-sm-8 col-12">
                                                    <div class="d-sm-flex justify-content-end">
                                                        <button class="button xs button-ghost button-plain_text m-r-20">CERRAR</button>
                                                        <button class="button xs button-filled button-accent1">DESCARGAR COMPROBANTE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </ModalDefault>

                                <br />
                                <br />

                                <h3 class="f-20 f-bold c-accent_2">
                                    Modal
                                    <strong>Default</strong>
                                </h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>modalId:</strong> ID for modal (String).
                                    <br />
                                    <strong>icon:</strong> Extra icon for title (String).
                                    <br />
                                    <strong>title:</strong> Text title(String).
                                    <br />
                                    <strong>subtitle:</strong> Text subtitle (String).
                                    <br />
                                    <strong>buttonClose:</strong> Text for button ghost, default text: 'Cerrar'
                                    (String).
                                    <br />
                                    <strong>buttonText:</strong> Text for button filled (String).
                                    <br />
                                    <strong>slot</strong> Content for body modal.
                                </p>
                                <div class="horizontal-center m-b-20">
                                    <button
                                        class="button xs button-filled button-accent1 m-r-10"
                                        @click="$bvModal.show('modal-default')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Default</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-accent2 m-r-10"
                                        @click="$bvModal.show('modal-onboardingContact')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Onboarding Client CONTACT</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-primary1 m-r-10"
                                        @click="$bvModal.show('modal-cropLogo')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>CROP LOGOTIPO</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-primary2 m-r-10"
                                        @click="$bvModal.show('modal-cropPortada')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>CROP PORTADA</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-title m-r-10"
                                        @click="$bvModal.show('modal-selectPortada')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>SELECT PORTADA</strong>
                                    </button>
                                </div>
                                <!-- Default modal -->
                                <ModalDefault
                                    modalId="modal-default"
                                    originalFooter
                                    icon="banana-image"
                                    title="Lorem ipsum dolor sit amet"
                                    subtitle="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                                    buttonText="Continuar"
                                >
                                    <p>Sed ut perspiciatis unde omnis iste natus" text="Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.</p>
                                </ModalDefault>
                                <!-- Onboarding Contact modal -->
                                <ModalDefault
                                    modalId="modal-onboardingContact"
                                    :originalFooter="false"
                                    size="lg"
                                    title="Ayúdanos a contactarte"
                                    subtitle="Selecciona la fecha y hora que tengas disponible para que nos comuniquemos contigo."
                                >
                                    <div class="row">
                                        <div class="col-12 m-b-5">
                                            <TelInput
                                                labelFor="numCountry"
                                                label="¿A qué número quieres que te llamemos?"
                                                required
                                                idInput="numCountry"
                                                inputPlaceholder="Ej. (000) 000 00 00"
                                            ></TelInput>
                                        </div>
                                        <div class="col-12 m-b-35">
                                            <img
                                                class="img-fluid d-flex m-0-auto"
                                                src="@/assets/images/Illustrations/ilustracion-llamada-ventas.svg"
                                                alt
                                            />
                                        </div>
                                        <div class="col-12 col-sm-8">
                                            <InputDatepicker
                                                labelFor="calldate"
                                                label="Fecha de la llamada"
                                            >
                                                <datepicker
                                                    placeholder="Selecciona una fecha que te convenga"
                                                    wrapper-class="form-datepicker datepicker-up"
                                                    input-class="form-control"
                                                    calendar-class="window-datepicker"
                                                    calendar-button
                                                    calendar-button-icon="banana banana-calendar"
                                                    typeable
                                                />
                                            </InputDatepicker>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <InputTimepicker
                                                labelFor="calltime"
                                                label="Hora de la llamada"
                                                help
                                                idHelpTooltip="callTime"
                                                helpIcon="banana banana-question-circle1"
                                            >
                                                <template v-slot:tooltipHelp>
                                                    <b-tooltip
                                                        target="callTime"
                                                        placement="top"
                                                        custom-class="white-tooltip"
                                                    >
                                                        <div>
                                                            <p class="m-b-0">Elige a que hora te gustaría recibir la
                                                                llamada.</p>
                                                        </div>
                                                    </b-tooltip>
                                                </template>
                                                <vue-clock-picker
                                                    mode="12"
                                                    :defaultHour="defaultHour"
                                                    :defaultMinute="defaultMinute"
                                                    :onTimeChange="timeChangeHandler"
                                                    class="clock-picker timepicker-up"
                                                ></vue-clock-picker>
                                            </InputTimepicker>
                                        </div>
                                    </div>
                                    <template v-slot:customFooter>
                                        <button
                                            class="button xs button-filled button-accent1 w-100"
                                            type="button"
                                        >Quiero que se comuniquen conmigo
                                        </button>
                                    </template>
                                </ModalDefault>
                                <!-- MODAL CROP LOGO -->
                                <ModalDefault
                                    modalId="modal-cropLogo"
                                    icon="banana-move"
                                    :originalFooter="false"
                                    size="lg"
                                    title="Ajusta tu logotipo"
                                    subtitle="Ajusta el tamaño de la imagen de tu logotipo como más te guste."
                                >
                                    <div>
                                        <CropImage clazz="container-lg logo m-b-0"></CropImage>
                                    </div>
                                    <template v-slot:customFooter>
                                        <button
                                            class="button xs button-stroke button-accent1 m-0-auto-0-0"
                                            type="button"
                                        >Cambiar logotipo
                                        </button>
                                        <button
                                            class="button xs button-ghost button-plain_text m-r-20"
                                            type="button"
                                        >Cancelar
                                        </button>
                                        <button
                                            class="button xs button-filled button-accent1"
                                            type="button"
                                        >Guardar
                                        </button>
                                    </template>
                                </ModalDefault>
                                <!-- MODAL CROP PORTADA -->
                                <ModalDefault
                                    modalId="modal-cropPortada"
                                    icon="banana-move"
                                    :originalFooter="false"
                                    size="lg"
                                    title="Ajusta tu imagen de portada"
                                    subtitle="Ajusta el tamaño de la imagen de tu portada como más te guste."
                                >
                                    <div>
                                        <CropImage clazz="container-lg portada m-b-0"></CropImage>
                                    </div>
                                    <template v-slot:customFooter>
                                        <button
                                            class="button xs button-stroke button-accent1 m-0-auto-0-0"
                                            type="button"
                                        >Cambiar portada
                                        </button>
                                        <button
                                            class="button xs button-ghost button-plain_text m-r-20"
                                            type="button"
                                        >Cancelar
                                        </button>
                                        <button
                                            class="button xs button-filled button-accent1 m-r-20"
                                            type="button"
                                        >Guardar
                                        </button>
                                    </template>
                                </ModalDefault>
                                <!-- MODAL SELECT/PREVIEW PORTADA -->
                                <ModalDefault
                                    modalId="modal-selectPortada"
                                    icon="banana-image"
                                    :originalFooter="false"
                                    size="xl"
                                    title="Selecciona tu imagen de portada"
                                    subtitle="Selecciona una imagen de la galería que más se adapte a tu Organización o sube tu imagen propia."
                                >
                                    <PortadaSelectPreview></PortadaSelectPreview>
                                    <template v-slot:customFooter>
                                        <button
                                            class="button xs button-stroke button-accent1 m-0-auto-0-0"
                                            type="button"
                                        >Subir Imagen
                                        </button>
                                        <button
                                            class="button xs button-ghost button-plain_text m-r-20"
                                            type="button"
                                        >Cancelar
                                        </button>
                                        <button
                                            class="button xs button-filled button-accent1 m-r-20"
                                            type="button"
                                        >Seleccionar
                                        </button>
                                    </template>
                                </ModalDefault>

                                <br />
                                <br />

                                <h3 class="f-20 f-bold c-accent_2">
                                    Modal
                                    <strong>Alert</strong>
                                </h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>modalId:</strong> ID for modal, should be: 'modal-success', 'modal-danger',
                                    'modal-info', 'modal-warning' (String).
                                    <br />
                                    <strong>clazz:</strong> Extra class for component 'content-alert' (String).
                                    <br />
                                    <strong>title:</strong> Text title (String).
                                    <br />
                                    <strong>text:</strong> Text for subtitle (String).
                                    <br />
                                    <strong>buttonCancel:</strong> Text for button ghost, default text: 'Cancelar'
                                    (String).
                                    <br />
                                    <strong>buttonType:</strong> Class for button filled (String).
                                    <br />
                                    <strong>buttonText:</strong> Text for button filled (String).
                                    <br />
                                    <strong>imgUrl:</strong> Text URL for modal (String).
                                    <br />
                                </p>
                                <div class="horizontal-center">
                                    <button
                                        class="button xs button-filled button-success m-r-10"
                                        @click="$bvModal.show('modal-success')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Success</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-error m-r-10"
                                        @click="$bvModal.show('modal-danger')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Danger</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-info m-r-10"
                                        @click="$bvModal.show('modal-info')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Info</strong>
                                    </button>
                                    <button
                                        class="button xs button-filled button-warning m-r-10"
                                        @click="$bvModal.show('modal-warning')"
                                        type="button"
                                    >
                                        Modal
                                        <strong>Warning</strong>
                                    </button>
                                </div>
                                <!-- Modal Success -->
                                <ModalAlert
                                    modalId="modal-success"
                                    buttonType="button-success"
                                    :imgUrl="imageModalGeneric"
                                    title="Sed ut perspiciatis unde omnis iste natus"
                                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                        ut labore et dolore magna aliqua.
                                         Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                         commodo consequat. Duis aute irure dolor in
                                         reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                    buttonText="Guardar"
                                ></ModalAlert>
                                <!-- Modal Danger -->
                                <ModalAlert
                                    modalId="modal-danger"
                                    buttonType="button-error"
                                    :imgUrl="imageModalDelete"
                                    title="Estas a punto de borrar un usuario"
                                    text="El usuario seleccionado se borrará de tu catálogo, recuerda que esta operacion no se puede deshacer. ¿Estas seguro?"
                                    buttonText="Eliminar"
                                ></ModalAlert>
                                <!-- Modal Info -->
                                <ModalAlert
                                    modalId="modal-info"
                                    buttonType="button-info"
                                    :imgUrl="imageModalGeneric"
                                    title="Sed ut perspiciatis unde omnis iste natus"
                                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                                        in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                        sunt in culpa qui officia deserunt mollit anim id est laborum."
                                    buttonText="Seguir"
                                ></ModalAlert>
                                <!-- Modal Warning -->
                                <ModalAlert
                                    modalId="modal-warning"
                                    buttonType="button-warning"
                                    :imgUrl="imageModalGeneric"
                                    title="Estas a punto de borrar un usuario"
                                    text="El usuario seleccionado se borrará de tu catálogo, recuerda que esta operacion no se puede deshacer. ¿Estas seguro?"
                                    buttonText="Desactivar"
                                ></ModalAlert>
                            </div>
                        </div>

                        <!-- NOTE SIMPLE -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Note Simple</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>icon:</strong> Class for icon.
                                    <br />
                                    <strong>clazz:</strong> Class for type of note and aditional class if needed.
                                    <br />
                                    <strong>slot:</strong> Aditional content for text.
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-notesimple
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-notesimple"
                                        class="m-t-20"
                                    >
                                        <SimpleNote
                                            icon="banana-info-circle1"
                                            clazz="info"
                                        >
                                            <p class="m-b-0">
                                                Tu contraseña debe tener al menos
                                                <strong>6 caracteres</strong> con una combinación entre
                                                <strong>letras, números y símbolos</strong>. Evita usar "contraseña",
                                                "123456" o "acbdef".
                                            </p>
                                        </SimpleNote>
                                        <SimpleNote
                                            icon="banana-info-circle1"
                                            clazz="error"
                                        >
                                            <p class="m-b-0">
                                                Tu contraseña debe tener al menos
                                                <strong>6 caracteres</strong> con una combinación entre
                                                <strong>letras, números y símbolos</strong>. Evita usar "contraseña",
                                                "123456" o "acbdef".
                                            </p>
                                        </SimpleNote>
                                        <SimpleNote
                                            icon="banana-info-circle1"
                                            clazz="success"
                                        >
                                            <p class="m-b-0">
                                                Tu contraseña debe tener al menos
                                                <strong>6 caracteres</strong> con una combinación entre
                                                <strong>letras, números y símbolos</strong>. Evita usar "contraseña",
                                                "123456" o "acbdef".
                                            </p>
                                        </SimpleNote>
                                        <SimpleNote
                                            icon="banana-info-circle1"
                                            clazz="warning"
                                        >
                                            <p class="m-b-0">
                                                Tu contraseña debe tener al menos
                                                <strong>6 caracteres</strong> con una combinación entre
                                                <strong>letras, números y símbolos</strong>. Evita usar "contraseña",
                                                "123456" o "acbdef".
                                            </p>
                                        </SimpleNote>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- PAGINATION -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Pagination</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>v-model:</strong> Value bound.
                                    <br />
                                    <strong>total-rows:</strong> Number of pages.
                                    <br />
                                    <strong>per-page:</strong> Number of rows showed per page.
                                    <br />
                                    <strong>aria-controls:</strong> When controlling another component on page, set the
                                    aria-controls prop to the id of the controlling element.
                                    <br />
                                    <strong>hide-goto-end-buttons:</strong> Hides buttons 'goto first' and 'goto last'
                                    page.
                                    <br />
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/pagination"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <div>
                                    <div class="pagination-custom">
                                        <span class="pagination-count">15 Documentos</span>
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="rows"
                                            :per-page="perPage"
                                            aria-controls="my-table"
                                            next-text
                                            prev-text
                                            hide-goto-end-buttons
                                        ></b-pagination>
                                    </div>

                                    <p class="mt-3">Current Page: {{ currentPage }}</p>

                                    <b-table
                                        id="my-table"
                                        :items="items"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        small
                                    ></b-table>
                                </div>
                            </div>
                        </div>

                        <!-- PANELS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Panels</h3>
                                <br />
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-panelMoneyAvg
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-panelMoneyAvg"
                                        class="m-t-20"
                                    >
                                        <h3 class="f-20 f-bold c-accent_1">Panel Money Avg</h3>
                                        <p>
                                            <strong>Params:</strong>
                                            <br />
                                            <br />
                                            <strong>clazz:</strong> Class for component (String).
                                            <br />
                                            <strong>title:</strong> Text title, for panel (String).
                                            <br />
                                            <strong>total</strong> Text total number, for panel (String).
                                            <br />
                                            <strong>textAvg:</strong> Text bottom subtitle (String).
                                            <br />
                                            <strong>totalAvg:</strong> Text bottom total number average (String).
                                            <br />
                                            <strong>errorAvg:</strong> Error class for text bottom number percentage (Boolean).
                                            <br />
                                            <strong>numAvg:</strong> Text bottom number percentage (String).
                                        </p>
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-3 m-b-40">
                                                <PanelMoneyAvg
                                                    title="Pago promedio"
                                                    total="$3,000,000.00"
                                                    textAvg="Pago promedio anterior"
                                                    totalAvg="$2,000.00"
                                                    numAvg="50"
                                                ></PanelMoneyAvg>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3 m-b-40">
                                                <PanelMoneyAvg
                                                    title="Ingreso del día"
                                                    total="$2,550,000.00"
                                                    textAvg="Ingreso del día anterior"
                                                    totalAvg="$6,140.00"
                                                    errorAvg
                                                    numAvg="93"
                                                ></PanelMoneyAvg>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3 m-b-40">
                                                <PanelMoneyAvg
                                                    title="Ingreso de la semana"
                                                    total="$26,120.00"
                                                    textAvg="Ingreso de la semana anterior"
                                                    totalAvg="$22,600.00"
                                                    numAvg="15"
                                                ></PanelMoneyAvg>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3 m-b-40">
                                                <PanelMoneyAvg
                                                    title="Ingreso del mes"
                                                    total="$45,800,000.00"
                                                    textAvg="Ingreso del mes anterior"
                                                    totalAvg="$40,100.00"
                                                    numAvg="11"
                                                ></PanelMoneyAvg>
                                            </div>
                                        </div>

                                        <h3 class="f-20 f-bold c-accent_1">Panel Mini Info</h3>
                                        <p>
                                            <strong>Params:</strong>
                                            <br><br>
                                            <strong>clazz:</strong> Extra class for component (String).
                                            <br>
                                            <strong>title:</strong> Text title (String).
                                            <br>
                                            <strong>help:</strong> To show help tooltip icon (Boolean).
                                            <br>
                                            <strong>v-slot:helpContent:</strong> Extra content for help.
                                        </p>
                                        <div>
                                            <div class="panel-mini-info">
                                                <PanelMiniInfo title="Visa">
                                                    3% + IVA
                                                </PanelMiniInfo>
                                                <PanelMiniInfo title="Monto presupuestado" help>
                                                    $200,000.00
                                                    <template v-slot:helpContent>
                                                        <i aria-hidden="true" id="tooltip-helpMonto" class="banana banana-question-circle1"></i>
                                                        <b-tooltip
                                                            target="tooltip-helpMonto"
                                                            placement="top"
                                                            custom-class="white-tooltip">
                                                            <div>
                                                                <p class="m-b-0">
                                                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                </p>
                                                            </div>
                                                        </b-tooltip>
                                                    </template>
                                                </PanelMiniInfo>
                                                <PanelMiniInfo title="American Express">
                                                    <input
                                                        type="text"
                                                        class="input-comision-bancaria"
                                                        name=""
                                                        value=""
                                                    >%
                                                </PanelMiniInfo>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- PROGRESS CIRCLE -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Progress Circle</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>class:</strong> Extra class for component (String).
                                    <br />
                                    <strong>slot:</strong> Extra content for component to insert 'radial-progress-bar'
                                    external component.
                                    <br />
                                    <strong>filename:</strong> File's name (String).
                                    <br />
                                    <strong>filesize:</strong> File's size (String).
                                    <br />
                                </p>
                                <a
                                    href="https://github.com/wyzant-dev/vue-radial-progress "
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-progress
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-progress"
                                        class="m-t-20"
                                    >
                                        <ProgressCircle
                                            filename="DSC10298.jpg"
                                            filesize="286 KB"
                                        >
                                            <radial-progress-bar
                                                :diameter="diam"
                                                :totalSteps="totalPrct"
                                                :completedSteps="completedPrct"
                                                startColor="#c16bf0"
                                                stopColor="#6992ea"
                                                innerStrokeColor="#FFF"
                                                :strokeWidth="strokeW"
                                                timingFunc="ease-in-out"
                                            >
                                                <p class="percentage">
                                                    <strong>59</strong>%
                                                </p>
                                            </radial-progress-bar>
                                        </ProgressCircle>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- PROGRESS BAR -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Progress Bar</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br><br>
                                    <strong>class:</strong> Extra class for component, default 'bar-progress'. When
                                    custom color bar by percent progress, add class 'percent-color' (String).
                                    <br>
                                    <strong>value:</strong> Percet progress (Number).
                                    <br>
                                    <strong>max:</strong> Maximum progress (Number).
                                    <br>
                                    <strong>slot:</strong> Extra content to add 'label-progress'.
                                    <br>
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/progress"
                                    class="f-14 f-bold c-error d-block m-b-30"
                                ><i
                                        aria-hidden="true"
                                        class=""
                                    ></i> See Documentation </a>
                                <div>
                                    <button
                                        id=""
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-progress-bar
                                    > Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-progress-bar"
                                        class="m-t-20"
                                    >
                                        <h6>Simple bar, No label</h6>
                                        <div class="m-t-20 m-b-20">
                                            <b-progress
                                                :value="valueProgressBar"
                                                :max="maxProgressBar"
                                                class="bar-progress"
                                            ></b-progress>
                                        </div>

                                        <h6>Custom color bar, Custom Label</h6>
                                        <div class="m-t-20 m-b-20">
                                            <b-progress
                                                :max="maxProgressBar"
                                                class="bar-progress percent-color"
                                                :class="{ 'error' : valueProgressBar < 40, 'success' : valueProgressBar > 65 }"
                                            >
                                                <b-progress-bar :value="valueProgressBar">
                                                    <div class="label-progress">
                                                        <span :class="{ 'error' : valueProgressBar < 40, 'success' : valueProgressBar > 65 }">{{ valueProgressBar }}%</span>
                                                        <span>{{ maxProgressBar }}K</span>
                                                    </div>
                                                </b-progress-bar>
                                            </b-progress>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- PROFILE USER IMAGE -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Profile User Image</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>imgUser:</strong> To show user's image (Boolean).
                                    <br />
                                    <strong>imgUploaded:</strong> To show button 'Cambiar' (Boolean).
                                    <br />
                                    <strong>showInfoUser:</strong> To show user's info (Boolean).
                                    <br />
                                    <strong>userName:</strong> Text for user's name or email (String).
                                    <br />
                                </p>
                                <UploadProfileImage
                                    imgUser
                                    imgUploaded
                                    showInfoUser
                                    clazz="horizontal-info sm user-verification-login"
                                    userName="“La vida es mejor Pagando”"
                                ></UploadProfileImage>
                            </div>
                        </div>

                        <!-- SLIDER BAR -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Slide Bar</h3>
                                <a
                                    href="https://nightcatsama.github.io/vue-slider-component/#/"
                                    class="f-14 f-bold c-error d-block m-b-30"
                                ><i
                                        aria-hidden="true"
                                        class=""
                                    ></i> See Documentation </a>
                                <div>
                                    <vue-slider class="slide-bar" />
                                </div>
                            </div>
                        </div>

                        <!-- SIMPLE TABS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Simple Tabs</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>title:</strong> Tab's name (String).
                                    <br />
                                    <strong>active</strong> Indicates which tab is acive.
                                    <br />
                                    <strong>disabled</strong> Indicates which tab is disabled.
                                    <br />
                                    <strong>class:</strong>Extra class for component (String).
                                    <br />
                                    <strong>content-class:</strong> Extra class for component BODY (String).
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/tabs"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-tabs
                                    >Show Tabs Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-tabs"
                                        class="m-t-20"
                                    >
                                        <h4>Tabs NO GROUPED</h4>
                                        <b-tabs
                                            class="simple-tabs"
                                            content-class="simple-tabs-body p-t-40"
                                        >
                                            <b-tab
                                                title="Inicia Sesión"
                                                active
                                            >
                                                <p>I'm the first tab</p>
                                            </b-tab>
                                            <b-tab title="Registrarse">
                                                <p>I'm the second tab</p>
                                            </b-tab>
                                            <b-tab
                                                title="Disabled"
                                                disabled
                                            >
                                                <p>I'm a disabled tab!</p>
                                            </b-tab>
                                        </b-tabs>

                                        <h4>Tabs GROUPED, add class 'group-tabs'</h4>
                                        <b-tabs
                                            class="simple-tabs group-tabs"
                                            content-class="simple-tabs-body p-t-40"
                                        >
                                            <b-tab
                                                title="Información personal"
                                                active
                                            >
                                                <p>TAB 1</p>
                                            </b-tab>
                                            <b-tab title="Fotografía">
                                                <p>TAB 2</p>
                                            </b-tab>
                                            <b-tab title="Datos adicionales">
                                                <p>TAB 3</p>
                                            </b-tab>
                                        </b-tabs>

                                        <h4>Tabs GROUPED with badges</h4>
                                        <b-tabs
                                            class="simple-tabs group-tabs"
                                            content-class="simple-tabs-body p-t-40">
                                            <b-tab active>
                                                <template v-slot:title>
                                                    <div class="d-flex align-items-c">
                                                        <span class="m-r-5">
                                                            Tab 1
                                                        </span>
                                                        <Badge
                                                            type="badge-accent_2"
                                                            text="21"
                                                        ></Badge>
                                                    </div>
                                                </template>
                                                <p>Tab contents 1</p>
                                            </b-tab>
                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="d-flex align-items-c">
                                                        <span class="m-r-5">
                                                            Tab 2
                                                        </span>
                                                        <Badge
                                                            type="badge-accent_2"
                                                            text="21"
                                                        ></Badge>
                                                    </div>
                                                </template>
                                                <p>Tab contents 2</p>
                                            </b-tab>
                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="d-flex align-items-c">
                                                        <span class="m-r-5">
                                                            Tab 3
                                                        </span>
                                                        <Badge
                                                            type="badge-accent_2"
                                                            text="21"
                                                        ></Badge>
                                                    </div>
                                                </template>
                                                <p>Tab contents 3</p>
                                            </b-tab>
                                        </b-tabs>

                                        <h4>Tabs VERTICAL custom image</h4>
                                        <b-tabs
                                            pills
                                            vertical
                                            class="vertical-tabs-custom"
                                        >
                                            <b-tab active>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-custom">
                                                        <div class="icon-img">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/Tabs-icons/icon-notificaciones.svg"
                                                                alt="Información General"
                                                            />
                                                        </div>
                                                        <span>
                                                            <label>Información General</label>
                                                            <p>Información básica: nombre, descripción y categoría.</p>
                                                        </span>
                                                    </div>
                                                </template>
                                                CONTENT 1
                                            </b-tab>

                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-custom">
                                                        <div class="icon-img">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/Tabs-icons/icon-info-facturacion.svg"
                                                                alt="Datos Fiscales"
                                                            />
                                                        </div>
                                                        <span>
                                                            <label>Datos Fiscales</label>
                                                            <p>Actualiza la información fiscal de tu organización.</p>
                                                        </span>
                                                    </div>
                                                </template>
                                                CONTENT 2
                                            </b-tab>

                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-custom">
                                                        <div class="icon-img">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/Tabs-icons/icon-logo-portada.svg"
                                                                alt="Logotipo y Portada"
                                                            />
                                                        </div>
                                                        <span>
                                                            <label>Logotipo y Portada</label>
                                                            <p>Personaliza los elementos gráficos de tu micrositio.</p>
                                                        </span>
                                                    </div>
                                                </template>
                                                CONTENT 3
                                            </b-tab>

                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-custom">
                                                        <div class="icon-img">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/Tabs-icons/icon-datos-bancarios.svg"
                                                                alt="Datos Bancarios"
                                                            />
                                                        </div>
                                                        <span>
                                                            <label>Datos Bancarios</label>
                                                            <p>Configura la cuenta donde recibirás tus pagos.</p>
                                                        </span>
                                                    </div>
                                                </template>
                                                CONTENT 4
                                            </b-tab>

                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-custom">
                                                        <div class="icon-img">
                                                            <img
                                                                class="img-fluid"
                                                                src="@/assets/images/Icons/Tabs-icons/icon-perfil.svg"
                                                                alt="Más Configuraciones"
                                                            />
                                                        </div>
                                                        <span>
                                                            <label>Más Configuraciones</label>
                                                            <p>Información y configuraciones adicionales.</p>
                                                        </span>
                                                    </div>
                                                </template>
                                                CONTENT 5
                                            </b-tab>
                                        </b-tabs>

                                        <h4>Vertical Tabs Info</h4>
                                        <b-tabs
                                            pills
                                            vertical
                                            class="vertical-tabs-info"
                                        >
                                            <b-tab active>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-info">
                                                        <span>
                                                            <label> Administración </label>
                                                            <p> Una persona con este rol tendrá acceso a todas las funcionalidades dentro de la plataforma. </p>
                                                        </span>
                                                        <span>
                                                            <button
                                                                type="button"
                                                                class="button-circle xs button-ghost button-error"
                                                                disabled
                                                            ><i
                                                                    aria-hidden="true"
                                                                    class="banana banana-delete"
                                                                ></i></button>
                                                        </span>
                                                    </div>
                                                </template>
                                                <CardSimple
                                                    title="Tab 1"
                                                    subtitle="Text tab 1"
                                                >
                                                    <div class="row">
                                                        <div class="col-12"> Content 1 </div>
                                                    </div>
                                                </CardSimple>
                                            </b-tab>
                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-info">
                                                        <span>
                                                            <label> Contabilidad </label>
                                                            <p> Una persona con este rol tendrá acceso únicamente a los Pagos Recaudados y Facturación. </p>
                                                        </span>
                                                        <span>
                                                            <button
                                                                type="button"
                                                                class="button-circle xs button-ghost button-error"
                                                            ><i
                                                                    aria-hidden="true"
                                                                    class="banana banana-delete"
                                                                ></i></button>
                                                        </span>
                                                    </div>
                                                </template>
                                                <CardSimple
                                                    title="Tab 2"
                                                    subtitle="Text tab 2"
                                                >
                                                    <div class="row">
                                                        <div class="col-12"> Content 2 </div>
                                                    </div>
                                                </CardSimple>
                                            </b-tab>
                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-info">
                                                        <span>
                                                            <label> Administración </label>
                                                            <p> Una persona con este rol tendrá acceso a todas las funcionalidades dentro de la plataforma. </p>
                                                            <Badge
                                                                type="badge-accent_2"
                                                                text="Usuario con cuenta"
                                                            ></Badge>
                                                        </span>
                                                        <span>
                                                            <button
                                                                type="button"
                                                                class="button-circle xs button-ghost button-error"
                                                                disabled
                                                            ><i
                                                                    aria-hidden="true"
                                                                    class="banana banana-delete"
                                                                ></i></button>
                                                        </span>
                                                    </div>
                                                </template>
                                                <CardSimple
                                                    title="Tab 3"
                                                    subtitle="Text tab 3"
                                                >
                                                    <div class="row">
                                                        <div class="col-12"> Content 3 </div>
                                                    </div>
                                                </CardSimple>
                                            </b-tab>
                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-info">
                                                        <span>
                                                            <label> Administración </label>
                                                            <p> Una persona con este rol tendrá acceso a todas las funcionalidades dentro de la plataforma. </p>
                                                            <Badge
                                                                type="badge-placeholder"
                                                                text="Usuario sin cuenta"
                                                            ></Badge>
                                                        </span>
                                                        <span>
                                                            <button
                                                                type="button"
                                                                class="button-circle xs button-ghost button-error"
                                                                disabled
                                                            ><i
                                                                    aria-hidden="true"
                                                                    class="banana banana-delete"
                                                                ></i></button>
                                                        </span>
                                                    </div>
                                                </template>
                                                <CardSimple
                                                    title="Tab 4"
                                                    subtitle="Text tab 4"
                                                >
                                                    <div class="row">
                                                        <div class="col-12"> Content 4 </div>
                                                    </div>
                                                </CardSimple>
                                            </b-tab>
                                            <b-tab>
                                                <template v-slot:title>
                                                    <div class="vertical-tab-info">
                                                        <span>
                                                            <label> Ingreso Estimado </label>
                                                            <p> Es el que se aprueba anualmente en la ley de ingresos e incluye... </p>
                                                        </span>
                                                        <span>
                                                            <i
                                                                aria-hidden="true"
                                                                class="banana banana-check-circle2 checkedTab"
                                                            ></i>
                                                        </span>
                                                    </div>
                                                </template>
                                                <CardSimple
                                                    title="Tab 5"
                                                    subtitle="Text tab 5"
                                                >
                                                    <div class="row">
                                                        <div class="col-12"> Content 5 </div>
                                                    </div>
                                                </CardSimple>
                                            </b-tab>
                                        </b-tabs>

                                        <h4>Tabs Signup Benefits / Comisions/ Risks</h4>
                                        <!-- Img right panel rute: src/assets/images/Illustrations/illustration-summary.svg -->
                                        <b-tabs
                                            class="tabs-pills"
                                            content-class="tabs-pills-body p-t-20">
                                            <b-tab
                                                title="Beneficios"
                                                active>
                                                <ul class="checked-list">
                                                    <li>
                                                        No hagas filas, realiza todas tus compras desde la comodidad de tu casa.
                                                    </li>
                                                    <li>
                                                        Paga todos tus servicios básicos (Luz, agua, cable, internet), así como recargas telefónicas, todo desde un mismo lugar.
                                                    </li>
                                                    <li>
                                                        Contamos con los mejores estándares de seguridad para mantener tu información privada en todo momento.
                                                    </li>
                                                    <li>
                                                        Contarás con el catálogo de tus negocios locales favoritos.
                                                    </li>
                                                    <li>
                                                        Tendrás las mejores recomendaciones de productos basadas en tu 	ubicación actual.
                                                    </li>
                                                    <li>
                                                        Recibe notificaciones en tiempo real de los pagos realizados desde 	tu cuenta.
                                                    </li>
                                                </ul>
                                            </b-tab>
                                            <b-tab title="Comisiones">
                                                <ul class="checked-list">
                                                    <li>
                                                        Se cobra una comisión no mayor a $8.00 pesos M.N.
                                                        por el pago de servicios dentro de la plataforma,
                                                        esta comisión puede variar por el establecimiento.
                                                    </li>
                                                </ul>
                                            </b-tab>
                                            <b-tab
                                                title="Riesgos">
                                                <ul class="checked-list">
                                                    <li>
                                                        Intermitencia o suspensión temporal del servicio por causas de mantenimiento u otros incidentes fuera de nuestro control.
                                                    </li>
                                                    <li>
                                                        Posible perdida de información a consecuencia de desastres naturales que afecten directamente a nuestros servidores.
                                                    </li>
                                                    <li>
                                                        Inaccesibilidad de información por la falta de conexión a internet o intermitencias en la misma.
                                                    </li>
                                                    <li>
                                                        Cobros ajenos al cliente, causados por la vulneración de los factores de
                                                        autenticidad debido a la perdida o transmisión de los mismos por causa del cliente.
                                                    </li>
                                                </ul>
                                            </b-tab>
                                        </b-tabs>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- STEP VALIDATION -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Step validation</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>text</strong> Step text.
                                    <br />
                                    <strong>status</strong> Status of the step (warning, error, success)
                                    <br />
                                    <strong>icon</strong> Icon of the step
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-stepvalid
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-stepvalid"
                                        class="m-t-20"
                                    >
                                        <StepValidation
                                            text="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                            icon="banana-check-normal"
                                            status="success"
                                        ></StepValidation>
                                        <StepValidation
                                            text="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                            icon="banana-check-normal"
                                            status="error"
                                        ></StepValidation>
                                        <StepValidation
                                            text="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                            icon="banana-check-normal"
                                            status="warning"
                                        ></StepValidation>
                                        <StepValidation
                                            text="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                            icon="banana-check-normal"
                                            status
                                        ></StepValidation>
                                        <StepValidation
                                            text="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                                            icon="banana-check-normal"
                                            status="info"
                                        ></StepValidation>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- SEARCH / FILTER NEW BAR -->
                        <div class="col-12 m-b-50">
                            <div class="card w-100">
                                <h3 class="f-20 f-bold c-accent_2">Search / Filter Bar</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br><br>
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br>
                                    <strong>showDateRange:</strong> To show Date range picker (Boolean).
                                    <br>
                                </p>
                                <div>
                                    <SearchBar showDateRange></SearchBar>
                                </div>
                            </div>
                        </div>

                        <!-- Security Measures -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Security Measures</h3>
                                <br>
                                <div class="security-measures">
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img1.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img1.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img2.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img3.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card active">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img4.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img5.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img6.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img7.png"
                                            alt=""
                                        >
                                    </div>
                                    <div class="img-card">
                                        <div class="dark-cover"></div>
                                        <div class="selector">
                                            <i
                                                aria-hidden="true"
                                                class="banana banana-check-normal"
                                            ></i>
                                        </div>
                                        <img
                                            class="img-cover"
                                            src="@/assets/images/Backgrounds/Security/img8.png"
                                            alt=""
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- TABLES -->
                        <div class="col-12 m-b-50">
                            <h3 class="f-20 f-bold c-accent_2">Tables</h3>
                            <p>
                                <strong>Params:</strong>
                                <br><br>
                                <strong>SEE DOCUMENTATION</strong>
                            </p>
                            <a
                                href="https://bootstrap-vue.js.org/docs/components/table"
                                class="f-14 f-bold c-error d-block m-b-30"
                            ><i
                                    aria-hidden="true"
                                    class=""
                                ></i> See Documentation </a>
                            <div>
                                <button
                                    id=""
                                    class="button xs button-filled button-primary2"
                                    type="button"
                                    v-b-toggle.collapse-tables
                                > Show Examples
                                </button>
                                <b-collapse
                                    id="collapse-tables"
                                    class="m-t-20"
                                >
                                    <h3 class="f-20 c-warning m-b-20">Generic Table data with Automatic Column Sort</h3>
                                    <CardSimple
                                        clazz="w-100 p-0"
                                        hideTitle
                                    >
                                        <div class="basic-table">
                                            <b-table
                                                responsive
                                                hover
                                                :fields="fields"
                                                :items="items"
                                            ></b-table>
                                        </div>
                                    </CardSimple>

                                    <h3 class="f-20 c-warning m-b-20">Table Loading Content</h3>
                                    <CardSimple
                                        clazz="w-100 p-0"
                                        hideTitle
                                    >
                                        <div class="basic-table">
                                            <b-table
                                                responsive
                                                hover
                                                :fields="fields"
                                                :items="items"
                                                :busy="isLoading"
                                            >
                                                <template v-slot:table-busy>
                                                    <LoadingSimple
                                                        clazz="big-load m-t-35 m-b-35"
                                                        label="Cargando..."
                                                    ></LoadingSimple>
                                                </template>
                                            </b-table>
                                        </div>
                                    </CardSimple>

                                    <h3 class="f-20 c-warning m-b-20">Table Empty State</h3>
                                    <CardSimple
                                        clazz="w-100 p-0"
                                        hideTitle
                                    >
                                        <div class="basic-table">
                                            <b-table
                                                responsive
                                                :fields="fields"
                                                :items="items"
                                                :busy="isLoading"
                                            >
                                                <template v-slot:table-busy>
                                                    <EmptyState
                                                        emptyStateDefault
                                                        size="sm"
                                                        title="No se encontraron resultados con la busqueda"
                                                        text="Aviso de privacidad 2018"
                                                    >
                                                        <button
                                                            type="button"
                                                            class="button xs button-stroke button-accent1 m-0-auto m-t-30"
                                                        >
                                                            Regresar busqueda
                                                        </button>
                                                    </EmptyState>
                                                </template>
                                            </b-table>
                                        </div>
                                    </CardSimple>

                                    <h3 class="f-20 c-warning m-b-20">Table data Header FIXED</h3>
                                    <CardSimple
                                        clazz="w-100 p-0"
                                        hideTitle
                                    >
                                        <div class="basic-table">
                                            <b-table
                                                responsive
                                                hover
                                                :fields="fields"
                                                :items="items"
                                                sticky-header
                                            ></b-table>
                                        </div>
                                    </CardSimple>

                                    <h3 class="f-20 c-warning m-b-20">Table Campañas</h3>
                                    <CardSimple
                                        clazz="w-100 p-0"
                                        hideTitle
                                    >
                                        <div
                                            id="menuOverflowFix"
                                            class="basic-table"
                                        >
                                            <b-table-simple
                                                responsive
                                                selectable
                                                :select-mode="selectMode"
                                                selected-variant="active"
                                                table-variant="xl"
                                            >
                                                <!-- Add classes 'b-table-row-selected table-active' to 'b-tbody > b-tr' when a row checkbox selected.
                                        If MORE THAN ONE row CHECKBOX is selected, add to 'b-thead > b-tr' class 'header-options-full'. -->
                                                <b-thead>
                                                    <b-tr class="">
                                                        <b-th class="w-5"></b-th>
                                                        <b-th>
                                                            <div class="d-flex align-items-c">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <span
                                                                    class="open-filters"
                                                                    @click="headerFilters()"
                                                                >Nombre de campaña</span>
                                                                <SortIcon></SortIcon>
                                                            </div>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputPlaceholder="Ej: Pagando"
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th>
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            >Sponsor</span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputPlaceholder="Ej: Pagando"
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="text-align-c">
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            >Tipo</span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicSelect>
                                                                    <multiselect
                                                                        v-model="value"
                                                                        :options="options"
                                                                        :close-on-select="true"
                                                                        :preserve-search="true"
                                                                        placeholder="Selecciona..."
                                                                        label="name"
                                                                        track-by="name"
                                                                        :preselect-first="false"
                                                                    >
                                                                        <template
                                                                            slot="selection"
                                                                            slot-scope="{ values, search, isOpen }"
                                                                        >
                                                                            <span
                                                                                class="multiselect__single"
                                                                                v-if="values.length &amp;&amp; !isOpen"
                                                                            >{{ values.length }} options selected</span>
                                                                        </template>
                                                                    </multiselect>
                                                                </BasicSelect>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="text-align-c">
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            >Inicio</span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputPlaceholder="Buscar..."
                                                                    clazz="noLabel m-b-0"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="text-align-c">
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            >Status</span>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicSelect>
                                                                    <multiselect
                                                                        v-model="value"
                                                                        :options="options"
                                                                        :close-on-select="true"
                                                                        :preserve-search="true"
                                                                        placeholder="Activo"
                                                                        label="name"
                                                                        track-by="name"
                                                                        :preselect-first="false"
                                                                    >
                                                                        <template
                                                                            slot="selection"
                                                                            slot-scope="{ values, search, isOpen }"
                                                                        >
                                                                            <span
                                                                                class="multiselect__single"
                                                                                v-if="values.length &amp;&amp; !isOpen"
                                                                            >{{ values.length }} options selected</span>
                                                                        </template>
                                                                    </multiselect>
                                                                </BasicSelect>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="text-align-c w-5"> Acciones</b-th>
                                                        <b-th class="row-options">
                                                            <div class="vertical-center">
                                                                <BasicCheckbox
                                                                    clazz="m-0 w-fit di-flex"
                                                                    text="2 elementos seleccionados"
                                                                    textClass="f-14 c-primary_1 principal-font-bold"
                                                                ></BasicCheckbox>
                                                                <div>
                                                                    <a
                                                                        class="opt-row"
                                                                        tabindex=""
                                                                    ><i aria-hidden="true" class="banana banana-delete"></i>
                                                                        Eliminar </a>
                                                                    <a
                                                                        class="opt-row"
                                                                        tabindex=""
                                                                    ><i aria-hidden="true" class="banana banana-download"></i>
                                                                        Descargar documentos </a>
                                                                    <a
                                                                        class="opt-row"
                                                                        tabindex=""
                                                                    > Cancelar </a>
                                                                </div>
                                                            </div>
                                                        </b-th>
                                                    </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                    <b-tr>
                                                        <b-td class="w-5">
                                                            <button class="button-circle xs button-ghost button-plain_text m-0-auto">
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="banana banana-player-pause"
                                                                ></i></button>
                                                        </b-td>
                                                        <b-td>
                                                            <div class="vertical-center">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <router-link
                                                                    to=""
                                                                    class="c-primary_1"
                                                                ><strong>Pagando
                                                                        Promo Octubre'19</strong></router-link>
                                                            </div>
                                                        </b-td>
                                                        <b-td> Pagando</b-td>
                                                        <b-td class="text-align-c"> 1.0</b-td>
                                                        <b-td class="text-align-c"> 15/10/19</b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-accent_2"
                                                                text="Activo"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-c w-5">
                                                            <b-dropdown
                                                                @show="showDropdown"
                                                                @hide="hideDropdown"
                                                                id="dropdown-table"
                                                                right
                                                                class="dropdown-table"
                                                            >
                                                                <template v-slot:button-content>
                                                                    <button class="button-circle xs button-ghost button-info_text">
                                                                        <i
                                                                            aria-hidden="true"
                                                                            class="banana banana-options-other-vertical"
                                                                        ></i>
                                                                    </button>
                                                                </template>
                                                                <b-dropdown-group header="Mas Acciones">
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-visibility-on"></i>
                                                                            Ver Detalles
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-edit"></i> Edit
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-player-play"></i>
                                                                            Publicar Documento
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-download"></i>
                                                                            Descargar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-divider></b-dropdown-divider>
                                                                    <b-dropdown-item-button variant=" c-error">
                                                                        <router-link
                                                                            to=""
                                                                            class="c-error"
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-delete"></i>
                                                                            Eliminar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                </b-dropdown-group>
                                                            </b-dropdown>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="w-5">
                                                            <button class="button-circle xs button-ghost button-accent2 m-0-auto">
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="banana banana-player-play"
                                                                ></i></button>
                                                        </b-td>
                                                        <b-td>
                                                            <div class="vertical-center">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <router-link
                                                                    to=""
                                                                    class="c-primary_1"
                                                                ><strong>Pagando
                                                                        Promo Octubre'19</strong></router-link>
                                                            </div>
                                                        </b-td>
                                                        <b-td> Pagando</b-td>
                                                        <b-td class="text-align-c"> 1.0</b-td>
                                                        <b-td class="text-align-c"> 15/10/19</b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-placeholder"
                                                                text="Inactivo"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-c w-5">
                                                            <b-dropdown
                                                                @show="showDropdown"
                                                                @hide="hideDropdown"
                                                                id="dropdown-table"
                                                                right
                                                                class="dropdown-table"
                                                            >
                                                                <template v-slot:button-content>
                                                                    <button class="button-circle xs button-ghost button-info_text">
                                                                        <i
                                                                            aria-hidden="true"
                                                                            class="banana banana-options-other-vertical"
                                                                        ></i>
                                                                    </button>
                                                                </template>
                                                                <b-dropdown-group header="Mas Acciones">
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-visibility-on"></i>
                                                                            Ver Detalles
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i  aria-hidden="true" class="banana banana-edit"></i> Edit
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-player-play"></i>
                                                                            Publicar Documento
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-download"></i>
                                                                            Descargar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-divider></b-dropdown-divider>
                                                                    <b-dropdown-item-button variant=" c-error">
                                                                        <router-link
                                                                            to=""
                                                                            class="c-error"
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-delete"></i>
                                                                            Eliminar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                </b-dropdown-group>
                                                            </b-dropdown>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr class="b-table-row-selected table-active">
                                                        <b-td class="w-5"></b-td>
                                                        <b-td>
                                                            <div class="vertical-center">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <router-link
                                                                    to=""
                                                                    class="c-primary_1"
                                                                ><strong>Pagando
                                                                        Promo Octubre'19</strong></router-link>
                                                            </div>
                                                        </b-td>
                                                        <b-td class=""> Pagando</b-td>
                                                        <b-td class="text-align-c"> 1.0</b-td>
                                                        <b-td class="text-align-c"> 15/10/19</b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-success"
                                                                text="Finalizado"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-c w-5">
                                                            <b-dropdown
                                                                @show="showDropdown"
                                                                @hide="hideDropdown"
                                                                id="dropdown-table"
                                                                right
                                                                class="dropdown-table"
                                                            >
                                                                <template v-slot:button-content>
                                                                    <button class="button-circle xs button-ghost button-info_text">
                                                                        <i
                                                                            aria-hidden="true"
                                                                            class="banana banana-options-other-vertical"
                                                                        ></i>
                                                                    </button>
                                                                </template>
                                                                <b-dropdown-group header="Mas Acciones">
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-visibility-on"></i>
                                                                            Ver Detalles
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-edit"></i> Edit
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-player-play"></i>
                                                                            Publicar Documento
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-download"></i>
                                                                            Descargar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-divider></b-dropdown-divider>
                                                                    <b-dropdown-item-button variant=" c-error">
                                                                        <router-link
                                                                            to=""
                                                                            class="c-error"
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-delete"></i>
                                                                            Eliminar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                </b-dropdown-group>
                                                            </b-dropdown>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td class="w-5"></b-td>
                                                        <b-td>
                                                            <div class="vertical-center">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <router-link
                                                                    to=""
                                                                    class="c-primary_1"
                                                                ><strong>Pagando
                                                                        Promo Octubre'19</strong></router-link>
                                                            </div>
                                                        </b-td>
                                                        <b-td class=""> Pagando</b-td>
                                                        <b-td class="text-align-c"> 1.0</b-td>
                                                        <b-td class="text-align-c"> 15/10/19</b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-warning"
                                                                text="Borrador"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-c w-5">
                                                            <b-dropdown
                                                                id="dropdown-table"
                                                                @show="showDropdown"
                                                                @hide="hideDropdown"
                                                                right
                                                                class="dropdown-table"
                                                            >
                                                                <template v-slot:button-content>
                                                                    <button class="button-circle xs button-ghost button-info_text">
                                                                        <i
                                                                            aria-hidden="true"
                                                                            class="banana banana-options-other-vertical"
                                                                        ></i>
                                                                    </button>
                                                                </template>
                                                                <b-dropdown-group header="Mas Acciones">
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-visibility-on"></i>
                                                                            Ver Detalles
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-edit"></i> Edit
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-player-play"></i>
                                                                            Publicar Documento
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-item-button>
                                                                        <router-link
                                                                            to=""
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-download"></i>
                                                                            Descargar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                    <b-dropdown-divider></b-dropdown-divider>
                                                                    <b-dropdown-item-button variant=" c-error">
                                                                        <router-link
                                                                            to=""
                                                                            class="c-error"
                                                                            tabindex=""
                                                                        ><i aria-hidden="true" class="banana banana-delete"></i>
                                                                            Eliminar
                                                                        </router-link>
                                                                    </b-dropdown-item-button>
                                                                </b-dropdown-group>
                                                            </b-dropdown>
                                                        </b-td>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>
                                        </div>
                                    </CardSimple>

                                    <h3 class="f-20 c-warning m-b-20">Table Pagos Recaudados</h3>
                                    <CardSimple
                                        clazz="w-100 p-0"
                                        hideTitle
                                    >
                                        <div class="basic-table menu-overflow-fix">
                                            <b-table-simple
                                                responsive
                                                selectable
                                                :select-mode="selectMode"
                                                selected-variant="active"
                                                table-variant="xl"
                                            >
                                                <!-- Add classes 'b-table-row-selected table-active' to 'b-tbody > b-tr' when a row checkbox selected.
                                            If MORE THAN ONE row CHECKBOX is selected, add to 'b-thead > b-tr' class 'header-options-full'. -->
                                                <b-thead>
                                                    <b-tr class="">
                                                        <b-th>
                                                            <div class="d-flex align-items-c">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <span
                                                                    class="open-filters"
                                                                    @click="headerFilters()"
                                                                > Folio </span>
                                                                <SortIcon></SortIcon>
                                                            </div>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputPlaceholder="ej. 212200"
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th>
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            > Usuario </span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputPlaceholder="Ej: Luis Pérez"
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th>
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            > Concepto de Pago </span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputPlaceholder="Ej: Paquete de..."
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th>
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            > Fecha </span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputPlaceholder="Buscar..."
                                                                    clazz="noLabel m-b-0"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="text-align-c">
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            > Unidades </span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputType="number"
                                                                    inputPlaceholder="Ej: 1..."
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="text-align-c">
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            > Status </span>
                                                            <SortIcon></SortIcon>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputType="number"
                                                                    inputPlaceholder="Ej: 1..."
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="text-align-r">
                                                            <span
                                                                class="open-filters"
                                                                @click="headerFilters()"
                                                            >Cantidad</span>
                                                            <div
                                                                class="filters"
                                                                :class="[filtersHeader ? 'show' : '']"
                                                            >
                                                                <BasicInput
                                                                    labelFor=""
                                                                    inputId=""
                                                                    inputType="number"
                                                                    inputPlaceholder="Ej: $2,300.00"
                                                                    clazz="noLabel"
                                                                ></BasicInput>
                                                            </div>
                                                        </b-th>
                                                        <b-th class="w-5 text-align-c"> Detalle</b-th>
                                                        <b-th class="row-options">
                                                            <div class="vertical-center">
                                                                <BasicCheckbox
                                                                    clazz="m-0 w-fit di-flex"
                                                                    text="2 elementos seleccionados"
                                                                    textClass="f-14 c-primary_1 principal-font-bold"
                                                                ></BasicCheckbox>
                                                                <div>
                                                                    <a
                                                                        class="opt-row"
                                                                        tabindex=""
                                                                    ><i aria-hidden="true" class="banana banana-delete"></i>
                                                                        Eliminar </a>
                                                                    <a
                                                                        class="opt-row"
                                                                        tabindex=""
                                                                    ><i aria-hidden="true" class="banana banana-download"></i>
                                                                        Descargar documentos </a>
                                                                    <a
                                                                        class="opt-row"
                                                                        tabindex=""
                                                                    > Cancelar </a>
                                                                </div>
                                                            </div>
                                                        </b-th>
                                                    </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                    <b-tr class="b-table-row-selected table-active">
                                                        <b-td>
                                                            <div class="vertical-center">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <router-link
                                                                    to=""
                                                                    class="c-primary_1"
                                                                >
                                                                    <strong>23164</strong></router-link>
                                                            </div>
                                                        </b-td>
                                                        <b-td> Carlos Sanchez</b-td>
                                                        <b-td> Paquete Amigo Sin Limite Telcel</b-td>
                                                        <b-td> 24/04/2019 - 08:45 a.m.</b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-accent_2"
                                                                text="1"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-success"
                                                                text="aceptada"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-r"><strong class="c-accent_2"> $2,800.00
                                                                (MXN) </strong></b-td>
                                                        <b-td class="w-5 text-align-c">
                                                            <button class="button-circle xs button-ghost button-info_text m-0-auto">
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="banana banana-visibility-on"
                                                                ></i></button>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>
                                                            <div class="vertical-center">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <router-link
                                                                    to=""
                                                                    class="c-primary_1"
                                                                >
                                                                    <strong>23156</strong></router-link>
                                                            </div>
                                                        </b-td>
                                                        <b-td> Edgar Iram Gonzalez</b-td>
                                                        <b-td> Inscripcion escolar 2020 Montesori</b-td>
                                                        <b-td> 09/03/2019 - 20:26 p.m.</b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-accent_2"
                                                                text="100"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-error"
                                                                text="fraudulenta"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-r"><strong class="c-accent_2"> $9,700.00
                                                                (MXN) </strong></b-td>
                                                        <b-td class="w-5 text-align-c">
                                                            <button class="button-circle xs button-ghost button-info_text m-0-auto">
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="banana banana-visibility-on"
                                                                ></i></button>
                                                        </b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>
                                                            <div class="vertical-center">
                                                                <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                                                <router-link
                                                                    to=""
                                                                    class="c-primary_1"
                                                                >
                                                                    <strong>23156</strong></router-link>
                                                            </div>
                                                        </b-td>
                                                        <b-td> Edgar Iram Gonzalez</b-td>
                                                        <b-td> Inscripcion escolar 2020 Montesori</b-td>
                                                        <b-td> 09/03/2019 - 20:26 p.m.</b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-accent_2"
                                                                text="100"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-c">
                                                            <Badge
                                                                type="badge-warning"
                                                                text="rechazada"
                                                            ></Badge>
                                                        </b-td>
                                                        <b-td class="text-align-r"><strong class="c-accent_2"> $9,700.00
                                                                (MXN) </strong></b-td>
                                                        <b-td class="w-5 text-align-c">
                                                            <button class="button-circle xs button-ghost button-info_text m-0-auto">
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="banana banana-visibility-on"
                                                                ></i></button>
                                                        </b-td>
                                                    </b-tr>
                                                </b-tbody>
                                            </b-table-simple>
                                        </div>
                                    </CardSimple>
                                </b-collapse>
                            </div>
                        </div>

                        <!-- TITLES -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">General Titles for App</h3>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-titles
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-titles"
                                        class="m-t-20"
                                    >
                                        <p>
                                            <strong>Params:</strong>
                                            <br />
                                            <br />
                                            <strong>clazz:</strong> Extra class for component (String).
                                            <br />
                                            <strong>msgSmall:</strong> Text for subtitle small, at the top (String).
                                            <br />
                                            <strong>title:</strong> Text for the principal title big (String).
                                        </p>
                                        <FloatingTitleLayout
                                            msgSmall="Pagando Client"
                                            title="Components"
                                        />

                                        <p>
                                            <strong>Params:</strong>
                                            <br />
                                            <br />
                                            <strong>clazz:</strong> Extra class for component (String).
                                            <br />
                                            <strong>title:</strong> First text's part on dark color (String).
                                            <br />
                                            <strong>titleStrong:</strong> Second text's part on blue color (String).
                                            <br />
                                            <strong>subtitle:</strong> Subtitle's text small, at the bottom (String).
                                        </p>
                                        <GenericTitle
                                            title="Full List of"
                                            titleStrong="Components"
                                            subtitle="Full list of components and how to use them."
                                        ></GenericTitle>

                                        <h3>Title divider</h3>
                                        <div class="text-divider"><label> Requeridos (0 de 4) </label></div>

                                        <p>
                                            <strong>Params:</strong>
                                            <br />
                                            <br />
                                            <strong>clazz:</strong> Extra class for component (String).
                                            <br />
                                            <strong>num:</strong> Text number (String).
                                            <br />
                                            <strong>title:</strong> Text for title (String).
                                            <br />
                                            <strong>subtitle:</strong> Text for subtitle (String).
                                            <br />
                                            <strong>v-slot:extraContent:</strong> Extra content for title.
                                            <br />
                                            <strong>slot:</strong> Extra content for component.
                                            <br />
                                        </p>
                                        <TitleNumber num="1" title="Agregar dominios web" subtitle="Agregar dominios web">
                                            <template v-slot:extraContent>
                                                <Badge type="badge-accent_2" text="PESOS MEXICANOS (MXN)"></Badge>
                                            </template>
                                        </TitleNumber>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- TITLES LANDING PAGE -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2"> Titles for Landing Page </h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>textSmall:</strong> Small text on top (String).
                                    <br />
                                    <strong>showSmallText:</strong> To show small text on top (Boolean).
                                    <br />
                                    <strong>textTitle:</strong> Text for big title (String).
                                    <br />
                                    <strong>titleClass:</strong> Extra class for title (String).
                                    <br />
                                    <strong>textSubtitle:</strong> Text for subtitle (String).
                                    <br />
                                    <strong>subtitleClass:</strong> Extra class for subtitle (String).
                                    <br />
                                    <strong>hideOriginaltitle:</strong> To hide original Title text (Boolean).
                                    <br />
                                    <strong>hideOriginalSubtitle:</strong> to hide original Subtitle text (Boolean).
                                    <br />
                                </p>
                                <div>
                                    <TitleLandingpage
                                        textTitle="Baja comisión y grandes ganancias"
                                        textSubtitle="Formar parte de Pagando es gratis, además de que tiene una comisión
                                                       más baja que los bancos y las terminales tradicionales.
                                                       Usa la calculadora y compruébalo."
                                    ></TitleLandingpage>

                                    <TitleLandingpage
                                        clazz="left-text"
                                        textTitle="Monitoreo y administración"
                                        textSubtitle="Monitorea estadísticas de pagos recaudados de tu negocio y descarga
                                                      reportes de quién, cuándo y cuánto te han pagado."
                                    ></TitleLandingpage>

                                    <TitleLandingpage
                                        clazz="right-text"
                                        textTitle="Sin preocupaciones"
                                        textSubtitle="Contamos con los mejores estándares de seguridad, sistema antifraude
                                                      y mantenemos la integridad de todos tus datos."
                                    ></TitleLandingpage>

                                    <TitleLandingpage
                                        clazz="left-text"
                                        showSmallText
                                        textSmall="(PRÓXIMAMENTE)"
                                        textTitle="Recibe pagos express"
                                        textSubtitle="Comparte un código QR único de tus servicios o negocio con tus
                                                      clientes y recibe tus pagos al instante."
                                    ></TitleLandingpage>

                                    <TitleLandingpage
                                        hideOriginaltitle
                                        hideOriginalSubtitle
                                        titleClass="c-title principal-font-regular f-normal"
                                    >
                                        <template v-slot:titleContent>
                                            <span class="title-divider-type">¿Cómo <strong class="c-accent_2 principal-font-bold f-bold">funciona</strong>?</span>
                                            <div class="divider"></div>
                                        </template>

                                        <template v-slot:subtitleContent>
                                            Unirse a <strong class="c-title">Pagando</strong> es tan sencillo como
                                            prepararte un café. <br /> Podemos resumirte el proceso en 4 simples pasos:
                                        </template>
                                    </TitleLandingpage>

                                    <TitleLandingpage
                                        hideOriginaltitle
                                        hideOriginalSubtitle
                                        titleClass="c-title principal-font-regular f-normal"
                                    >
                                        <template v-slot:titleContent>
                                            ¡Pruébalo <strong class="c-title principal-font-bold f-bold">ahora!</strong>
                                        </template>
                                        <template v-slot:inputText>
                                            <BasicInput
                                                clazz="noLabel"
                                                inputType="text"
                                                labelFor="nameOrg"
                                                inputId="nameOrg"
                                                label=""
                                                inputPlaceholder="Escribe el nombre de tu organización"
                                            ></BasicInput>
                                        </template>
                                    </TitleLandingpage>

                                    <TitleLandingpage
                                        hideOriginaltitle
                                        hideOriginalSubtitle
                                        titleClass="c-title principal-font-regular f-normal"
                                    >
                                        <template v-slot:titleContent>
                                            ¿Qué opinan <strong class="c-accent_2 principal-font-bold f-bold">nuestros
                                                clientes</strong>?
                                        </template>
                                        <template v-slot:subtitleContent>
                                            Conoce historias reales de clientes y cómo <strong class="c-title">Pagando
                                                Check </strong> les ha ayudado a aumentar las ventas en sus negocios.
                                        </template>
                                    </TitleLandingpage>
                                </div>
                            </div>
                        </div>

                        <!-- TIMELINES -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Timeline Simple</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>title:</strong> Text for the title timeline (String).
                                    <br />
                                    <strong>text:</strong> Text for the subtitle timeline (String).
                                    <br />
                                    <strong class="f-20 c-error">This component needs to be check. The 'v-repeat' part goes inside the component on
                                        the 'li' tag.</strong>
                                </p>
                                <SimpleTimelineInfo>
                                    <SimpleTimelineInfoDot
                                        label="Dirección"
                                        content="Ave. Independencia #9102-A, Col. Centro, CP 31000, Chihuahua, Chih. México"
                                    ></SimpleTimelineInfoDot>
                                    <SimpleTimelineInfoDot
                                        label="Ocupación"
                                        content="131 Directores y gerentes en producción agropecuaria, industrial, construcción y mantenimiento"
                                    ></SimpleTimelineInfoDot>
                                    <SimpleTimelineInfoDot
                                        label="Teléfono de contacto"
                                        content="+52 | (614)-198-0945"
                                    ></SimpleTimelineInfoDot>
                                    <SimpleTimelineInfoDot
                                        label="Fecha de nacimiento"
                                        content="17 de Agosto de 1998"
                                    ></SimpleTimelineInfoDot>
                                </SimpleTimelineInfo>
                                <h4 class="m-t-30 m-b-30">Status Timeline</h4>
                                <p class="f-14">
                                    <strong>Parameters:</strong>
                                    <br>
                                    <br>
                                    <strong>status:</strong> Status of the item (error / success / info / warning / default / accent1 / accent2 / primary1)
                                    <br>
                                    <strong>icon:</strong> Icon in the circle
                                    <br>
                                    <strong>date:</strong> date of the timeline item
                                    <br>
                                    <strong>statusText:</strong> Status text
                                    <br>
                                    <strong>name:</strong> Name of the person in the timeline item
                                    <br>
                                    <strong>description:</strong> Description of the timeline item
                                    <br>
                                </p>
                                <div class="p-relative">
                                    <div class="status-timeline y-scroll">
                                        <StatusTimelineItem
                                            icon="banana-check-normal"
                                            status="success"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Aprobada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                        <StatusTimelineItem
                                            icon="banana-blocked"
                                            status="error"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Aprobada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                        <StatusTimelineItem
                                            icon="banana-check-normal"
                                            status="info"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Rechazada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                        <StatusTimelineItem
                                            icon="banana-check-normal"
                                            status="warning"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Aprobada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                        <StatusTimelineItem
                                            icon="banana-check-normal"
                                            status="default"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Aprobada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                        <StatusTimelineItem
                                            icon="banana-check-normal"
                                            status="accent1"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Aprobada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                        <StatusTimelineItem
                                            icon="banana-check-normal"
                                            status="accent2"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Aprobada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                        <StatusTimelineItem
                                            icon="banana-check-normal"
                                            status="primary1"
                                            date="24 Febrero, 2019"
                                            statusText="- Solicitud Aprobada"
                                            name="Ararat Mendez"
                                            description="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
                                        >
                                        </StatusTimelineItem>
                                    </div>
                                    <div class="bottom-timeline"></div>
                                </div>
                            </div>
                        </div>

                        <!-- TOAST ALERTS -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Toast Alerts</h3>
                                <a
                                    href="https://artemsky.github.io/vue-snotify/"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <ToastSnotify />
                            </div>
                        </div>

                        <!-- TOOLTIPS / POPOVER -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Tooltips</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>id:</strong> Should be the same as the 'target' (String).
                                    <br />
                                    <strong>target:</strong> id from button (String).
                                    <br />
                                    <strong>placement:</strong> Where to place the tooltip 'top, bottom, left, right'
                                    (String).
                                    <br />
                                    <strong>custom-class:</strong> To add a custom class (String).
                                    <br />
                                    <strong>To change tooltip to popover just have to change 'b-tooltip' to
                                        'b-popover.</strong>
                                    <br />
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/tooltip"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-toolpop
                                    >Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-toolpop"
                                        class="m-t-20"
                                    >
                                        <div class="d-flex">
                                            <div class="w-50 p-10">
                                                <button
                                                    id="button-tooltip"
                                                    class="button xs button-stroke button-accent1 w-100 p-relative"
                                                >Tooltip
                                                </button>
                                                <b-tooltip
                                                    target="button-tooltip"
                                                    placement="top"
                                                    custom-class="white-tooltip"
                                                >
                                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                                        maiores nam voluptas dolorem distinctio voluptates veniam quod
                                                        quis fugit commodi facilis alias accusamus nemo reiciendis,
                                                        culpa sit inventore illum.</p>
                                                </b-tooltip>
                                            </div>

                                            <div class="w-50 p-10">
                                                <button
                                                    id="button-popover"
                                                    class="button xs button-stroke button-accent2 w-100 p-relative"
                                                >Popover
                                                </button>
                                                <b-popover
                                                    target="button-popover"
                                                    placement="top"
                                                    custom-class="white-popover"
                                                >
                                                    <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi
                                                        maiores nam voluptas dolorem distinctio voluptates veniam quod
                                                        quis fugit commodi facilis alias accusamus nemo reiciendis,
                                                        culpa sit inventore illum.</p>
                                                </b-popover>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- UPLOAD AREA + LIST UPLOAD AREA -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Upload Area + List Upload Area</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>clazzArea:</strong> Extra class for container (String).
                                    <br />
                                    <strong>imgUploaded:</strong> To show image uploaded (Boolean).
                                    <br />
                                    <strong>hideButtonClose:</strong> To hide button close for image uploaded (Boolean).
                                    <br />
                                    <strong>numList:</strong> Text number for every item image on list uploaded
                                    (Number).
                                    <br />
                                </p>
                                <div>
                                    <UploadArea
                                        clazz="m-b-30"
                                        clazzArea="col-6"
                                    >
                                        <SimpleNote clazz="info m-b-0 m-l-30">
                                            <p class="m-b-0">
                                                El tamaño de las imágenes debe ser de <strong>1440px por 420px</strong>
                                                para que se visualicen correctamente. Puedes subir hasta 5 imágenes:
                                                <strong>.JPG y .PNG</strong>
                                            </p>
                                        </SimpleNote>
                                    </UploadArea>

                                    <div class="vertical-upload-list">
                                        <ListUploadArea>
                                            <UploadArea
                                                imgUploaded
                                                hideButtonClose
                                                numList="1"
                                            ></UploadArea>
                                        </ListUploadArea>
                                        <ListUploadArea>
                                            <UploadArea
                                                imgUploaded
                                                hideButtonClose
                                                numList="2"
                                            ></UploadArea>
                                        </ListUploadArea>
                                        <ListUploadArea>
                                            <UploadArea
                                                imgUploaded
                                                hideButtonClose
                                                numList="3"
                                            ></UploadArea>
                                        </ListUploadArea>
                                        <ListUploadArea disableButtons>
                                            <UploadArea></UploadArea>
                                        </ListUploadArea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- UPLOAD FILES -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Upload Files Component</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>loadingFile:</strong> To show when File is being loading (Boolean).
                                    <br />
                                    <strong>loadedFile:</strong> To show when File has been loaded (Boolean).
                                    <br />
                                    <strong>icon:</strong> Icon's class (String).
                                    <br />
                                    <strong>title:</strong> Title text (String).
                                    <br />
                                    <strong>text:</strong> Text description (String).
                                    <br />
                                    <strong>note:</strong> Note text (String).
                                    <br />
                                    <strong>tooltipId:</strong> Text ID for tooltip, must be unique (String).
                                    <br />
                                </p>
                                <div>
                                    <div class="text-divider"><label> Requeridos (0 de 4) </label></div>
                                    <!-- NORMAL -->
                                    <UploadFile
                                        icon="banana-profile"
                                        title="Identificación oficial"
                                        text="INE, IFE o Pasaporte"
                                        note="El tamaño y peso máximo de la imagen es 000px por 000px y 000kb."
                                        tooltipId="tooltip1"
                                    >
                                        <template v-slot:tooltipContent>
                                            <div>
                                                <p class="f-13 body-font-regular c-info_text m-b-0"> Sube la primer página de un estado de cuenta de tu banco. </p>
                                            </div>
                                        </template>
                                    </UploadFile>
                                    <!-- LOADING -->
                                    <UploadFile
                                        loadingFile
                                        icon="banana-home"
                                        text="Subiendo archivo - 32 KB de 57 KB"
                                        note="El tamaño de la imagen debe ser 200px por 200px y el peso máximo debe ser 300kb."
                                        tooltipId="tooltip2"
                                    >
                                        <template v-slot:tooltipContent>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </template>
                                    </UploadFile>
                                    <!-- LOADED -->
                                    <UploadFile
                                        loadedFile
                                        icon="banana-credit-card"
                                        title="IdentificaciónINE.pdf"
                                        text="(130 KB)"
                                    ></UploadFile>
                                </div>
                            </div>
                        </div>

                        <!-- WEBHOOKS CONFIGURATION -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2"> Webhooks Configuration </h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong>clazz:</strong> Extra class for component (String).
                                    <br />
                                    <strong>title:</strong> Text for webhook title (String).
                                    <br />
                                    <strong>link:</strong> Text for webhook URL (String).
                                    <br />
                                    <strong>clazzStatus:</strong> Text class color for Status (String).
                                    <br />
                                    <strong>iconStatus:</strong> Icons for Status (String).
                                    <br />
                                    <strong>textStatus:</strong> Text to show on Status (String).
                                    <br />
                                </p>
                                <div>
                                    <button
                                        id
                                        class="button xs button-filled button-primary2"
                                        type="button"
                                        v-b-toggle.collapse-webhooks
                                    > Show Examples
                                    </button>
                                    <b-collapse
                                        id="collapse-webhooks"
                                        class="m-t-20"
                                    >
                                        <WebhooksConfig
                                            title="Adenio Webhooks"
                                            link="http://adenio.com/webhooks"
                                            clazzStatus="c-success"
                                            iconStatus="banana-check-circle1"
                                            textStatus="Sin errores"
                                        >
                                            <template v-slot:badgeSandbox>
                                                <Badge
                                                    type="badge-success"
                                                    text="Activo"
                                                ></Badge>
                                            </template>
                                            <template v-slot:badgeProd>
                                                <Badge
                                                    type="badge-success"
                                                    text="Activo"
                                                ></Badge>
                                            </template>
                                        </WebhooksConfig>
                                        <WebhooksConfig
                                            title="Webhooks Black Labs"
                                            link="http://blacklabs.mx/webhooks-noti"
                                            clazzStatus="c-error"
                                            iconStatus="banana-warning-circle1"
                                            textStatus="Con errores"
                                        >
                                            <template v-slot:badgeSandbox>
                                                <Badge
                                                    type="badge-success"
                                                    text="Activo"
                                                ></Badge>
                                            </template>
                                            <template v-slot:badgeProd>
                                                <Badge
                                                    type="badge-error"
                                                    text="Inactivo"
                                                ></Badge>
                                            </template>
                                        </WebhooksConfig>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>

                        <!-- WIZARD / STEPPER -->
                        <div class="col-12 m-b-50">
                            <div class="card">
                                <h3 class="f-20 f-bold c-accent_2">Wizard / Stepper</h3>
                                <p>
                                    <strong>Params:</strong>
                                    <br />
                                    <br />
                                    <strong class="c-warning">To change the wizard position to HORIZONTAL: Remove 'activeVertical'.</strong>
                                    <br />
                                    <strong>clazz:</strong> Extra class for component container (String).
                                    <br />
                                    <strong>title:</strong> Title for the step, hidden by default on styles (String).
                                    <br />
                                    <strong>icon:</strong> Class text for icon (String).
                                    <br />
                                    <strong>v-slot:wizardView:</strong> To add the step's content to 'form-wizard'.
                                    <br />
                                </p>
                                <a
                                    href="https://binarcode.github.io/vue-form-wizard/#/"
                                    class="f-14 f-bold c-error m-b-30"
                                >
                                    <i
                                        aria-hidden="true"
                                        class
                                    ></i> See Documentation
                                </a>
                                <WizardForm clazz="form-wizard-white">
                                    <template v-slot:wizardView>
                                        <tab-content
                                            title="Tipo de campaña"
                                            icon="one"
                                        >PASO 1</tab-content>
                                        <tab-content
                                            title="Datos básicos"
                                            icon="two"
                                        >PASO 2</tab-content>
                                        <tab-content
                                            title="Configurar código"
                                            icon="three"
                                        >PASO 3</tab-content>
                                        <tab-content
                                            title="Cargar plantilla"
                                            icon="four"
                                        >PASO 4</tab-content>
                                        <tab-content
                                            title="Resumen"
                                            icon="five"
                                        >PASO 5</tab-content>
                                    </template>
                                </WizardForm>

                                <br />
                                <br />

                                <WizardForm activeVertical>
                                    <template v-slot:wizardView>
                                        <tab-content
                                            title="Tipo de campaña"
                                            icon="one"
                                        >PASO 1</tab-content>
                                        <tab-content
                                            title="Datos básicos"
                                            icon="two"
                                        >PASO 2</tab-content>
                                        <tab-content
                                            title="Configurar código"
                                            icon="three"
                                        >PASO 3</tab-content>
                                        <tab-content
                                            title="Cargar plantilla"
                                            icon="four"
                                        >PASO 4</tab-content>
                                        <tab-content
                                            title="Resumen"
                                            icon="five"
                                        >PASO 5</tab-content>
                                    </template>
                                </WizardForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- BUTTON SCROLL TOP -->
        <ButtonScrollTop idScroll="#main-view"></ButtonScrollTop>
    </div>
</template>

<script>
// @ is an alias to /src
import TopbarHeader from "@/components/TopbarHeader.vue";
import SidebarMenu from "@/components/SidebarMenu.vue";
import SidebarSide from "@/components/SidebarSide.vue";
import OrgInfo from "@/components/OrgInfo.vue";
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import SimpleCollapse from "@/components/SimpleCollapse.vue";
import GenericTitle from "@/components/GenericTitle.vue";
import SimpleNote from "@/components/SimpleNote.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import SocialButtons from "@/components/SocialButtons.vue";
import BasicInput from "@/components/BasicInput.vue";
import InputEdit from "@/components/InputEdit.vue";
import BasicTextarea from "@/components/BasicTextarea.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import InputFile from "@/components/InputFile.vue";
import StrongLevel from "@/components/StrongLevel.vue";
import CodeInput from "@/components/CodeInput.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import SimpleTimelineInfo from "@/components/SimpleTimelineInfo.vue";
import SimpleTimelineInfoDot from "@/components/SimpleTimelineInfoDot.vue";
import BasicSwitch from "@/components/BasicSwitch.vue";
import RadioInput from "@/components/RadioInput.vue";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import CameraVue from "@/components/CameraVue";
import TelInput from "@/components/TelInput.vue";
import InputDatepicker from "@/components/InputDatepicker.vue";
import UploadBox from "@/components/UploadBox.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import StepValidation from "@/components/StepValidation.vue";
import WizardForm from "@/components/WizardForm.vue";
import CropImage from "@/components/CropImage.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import ToastSnotify from "@/components/ToastSnotify.vue";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import RadialProgressBar from "vue-radial-progress";
import ChartProgressInfo from "@/components/ChartProgressInfo.vue";
import OnboardingComponent from "@/components/OnboardingComponent.vue";
import PanelMoneyAvg from "@/components/PanelMoneyAvg.vue";
import CardSimple from "@/components/CardSimple.vue";
import ButtonScrollTop from "@/components/ButtonScrollTop.vue";
import ModalAlert from "@/components/ModalAlert.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import CameraMixin from "@/mixins/CameraMixin";
import InputTimepicker from "@/components/InputTimepicker.vue";
import VueClockPicker from "vue-clock-picker";
import ClientType from "@/components/ClientType.vue";
import AddUser from "@/components/AddUser.vue";
import PreviewLogoPortada from "@/components/PreviewLogoPortada.vue";
import PortadaSelectPreview from "@/components/PortadaSelectPreview.vue";
import EmptyState from "@/components/EmptyState.vue";
import ListUploadArea from '@/components/ListUploadArea.vue';
import UploadArea from '@/components/UploadArea.vue';
import CardItem from "@/components/CardItem.vue";
import SimpleCarousel from "@/components/SimpleCarousel.vue";
import Quantity from "@/components/Quantity.vue";
import Badge from "@/components/Badge.vue";
import DateRangePicker from 'vue2-daterange-picker';
import InputTags from '@/components/InputTags.vue';
import SearchBar from '@/components/SearchBar.vue';
import UploadProfileImage from "@/components/UploadProfileImage.vue";
import FileUploadedInfo from "@/components/FileUploadedInfo.vue";
import WebhooksConfig from "@/components/WebhooksConfig.vue";
import UploadFile from "@/components/UploadFile.vue";
import TitleLandingpage from "@/components/TitleLandingpage.vue";
import imagenCard from "@/assets/images/Illustrations/illustration-card-id.svg";
import imageBackCard from "@/assets/images/Illustrations/illustration-card-id-back.svg";
import imageProfile from "@/assets/images/Illustrations/illustration-profile.svg";
import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
import imageModalDelete from "@/assets/images/Illustrations/illustration-modal-delete.svg";
import GoogleMap from '@/components/GoogleMap.vue';
import CardCampaign from '@/components/CardCampaign.vue';
import TitleNumber from '@/components/TitleNumber.vue';
import { codemirror } from 'vue-codemirror-lite';
import BankCardPreview from '@/components/BankCardPreview.vue';
import PanelMiniInfo from '@/components/PanelMiniInfo.vue';
import StatusTimelineItem from '@/components/StatusTimelineItem.vue';
import PaymentInfoRow from '@/components/PaymentInfoRow.vue';
import ReadOnlyInfo from '@/components/ReadOnlyInfo.vue';
import IconList from '@/components/IconList.vue';
import CardSelection from "@/components/CardSelection.vue";

export default {
    name: "UxComponents",
    components: {
        TopbarHeader,
        SidebarMenu,
        SidebarSide,
        OrgInfo,
        FloatingTitleLayout,
        SimpleCollapse,
        GenericTitle,
        SimpleNote,
        LoadingSimple,
        SocialButtons,
        BasicInput,
        InputFile,
        InputEdit,
        BasicTextarea,
        PasswordInput,
        StrongLevel,
        CodeInput,
        LoadingBar,
        SimpleTimelineInfo,
        SimpleTimelineInfoDot,
        BasicSwitch,
        RadioInput,
        BasicCheckbox,
        CameraVue,
        TelInput,
        InputDatepicker,
        UploadBox,
        ProgressCircle,
        StepValidation,
        WizardForm,
        CropImage,
        BasicSelect,
        ToastSnotify,
        Multiselect,
        Datepicker,
        RadialProgressBar,
        ChartProgressInfo,
        OnboardingComponent,
        PanelMoneyAvg,
        CardSimple,
        ButtonScrollTop,
        ModalAlert,
        ModalDefault,
        InputTimepicker,
        VueClockPicker,
        ClientType,
        AddUser,
        PreviewLogoPortada,
        PortadaSelectPreview,
        EmptyState,
        ListUploadArea,
        UploadArea,
        CardItem,
        SimpleCarousel,
        Quantity,
        Badge,
        DateRangePicker,
        InputTags,
        SearchBar,
        UploadProfileImage,
        FileUploadedInfo,
        WebhooksConfig,
        UploadFile,
        TitleLandingpage,
        GoogleMap,
        CardCampaign,
        TitleNumber,
        codemirror,
        BankCardPreview,
        PanelMiniInfo,
        StatusTimelineItem,
        PaymentInfoRow,
        ReadOnlyInfo,
        IconList,
        CardSelection
    },
    mixins: [CameraMixin("ux-test-camera")],
    data () {
        return {
            // Modal Alert
            imageModalGeneric: imageModalGeneric,
            imageModalDelete: imageModalDelete,
            // Date Range Picker
            opens: 'left',
            showDropdowns: true,
            autoApply: true,
            dateRange: [],
            // UploadBox
            imagenCard: imagenCard,
            imageBackCard: imageBackCard,
            imageProfile: imageProfile,
            // Multiselect
            checkedSwitch: false,
            harryPotterEmblem: "https://img.pngio.com/transparent-hogwarts-crest-file-from-a-harry-potter-letter-prop-hogwarts-transparent-612_720.png",
            harryPotterCastle: "https://images.ctfassets.net/usf1vwtuqyxm/3QQaEkThAnIAiXveGhJYD9/f79a571dbe9fd456d65e783040601fdc/hogwarts-castle-.jpg",
            value: [],
            options: [
                { name: "Option 1" },
                { name: "Option 2" },
                { name: "Option 3" }
            ],
            optionsName: [{ nameOp: "Process 1" }, { nameOp: "Process 2" }],
            valueTags: [],
            optionsTags: [
                { name: 'Flores' },
                { name: 'Arreglos' },
                { name: 'Comida china' },
                { name: 'Ropa de invierno' },
                { name: 'Accesorios para auto' },
                { name: 'Telefonía' }
            ],
            // ProgressCircle
            diam: 120,
            totalPrct: 100,
            completedPrct: 59,
            strokeW: 4,
            // Progress Bar
            valueProgressBar: 80,
            maxProgressBar: 100,
            valueProgressBar0: 0,
            valueProgressBar100: 100,
            // Pagination
            currentPage: 1,
            perPage: 1,
            totalRows: 1,
            // SidebarSide
            isSidebarLeftOpen: false,
            isSidebarRightOpen: true,
            isSidebarSmall: false,
            noSmall: false,
            window: {
                width: 0
            },
            // CardUpdates
            showUpdates: true,
            slide: 0,
            sliding: null,
            // Modal Carousel
            showHorizontalImg: true,
            showVerticalImg: false,
            // Tables
            filtersHeader: false,
            isLoading: true,
            fields: [
                {
                    key: "servicio",
                    label: "Servicio"
                },
                {
                    key: "noVentas",
                    label: "No. Ventas",
                    class: "text-align-c"
                },
                {
                    key: "total",
                    label: "Total",
                    class: "text-align-r",
                    sortable: true,
                    sortDirection: "desc"
                }
            ],
            items: [
                {
                    servicio: "Suplemento alimenticio sin esteroides",
                    noVentas: "14 ventas",
                    total: "$28,000.00 MXN"
                },
                {
                    servicio: "Suplemento Gold 2 kg",
                    noVentas: "10 ventas",
                    total: "$13,760.00 MXN"
                },
                {
                    servicio: "Creatina de 800 gr",
                    noVentas: "9 ventas",
                    total: "$9,450.00 MXN"
                },
                {
                    servicio: "Suplemento de Proteina Optimiun con 2 kg",
                    noVentas: "2 ventas",
                    total: "$1,280.00 MXN"
                },
                {
                    servicio: "Dieta con entrenamiento en video",
                    noVentas: "1 ventas",
                    total: "$400.00 MXN"
                }
            ],
            selectMode: "multi",
            // Chart Bar
            chartPagosBar: {
                chart: {
                    id: "vuechart-pagos",
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "20px"
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 4,
                    colors: ["transparent"]
                },
                xaxis: {
                    categories: [
                        "Lunes",
                        "Martes",
                        "Miercoles",
                        "Jueves",
                        "Viernes",
                        "Sabado",
                        "Domingo"
                    ],
                    labels: {
                        style: {
                            colors: [
                                "#547286",
                                "#547286",
                                "#547286",
                                "#547286",
                                "#547286",
                                "#547286",
                                "#547286"
                            ]
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                markers: {
                    size: 2
                }
            },
            seriesPagosBar: [
                {
                    name: "Transacciones exitosas",
                    data: ["8.5K", "7K", "9.5K", "8.5K", "2.5K", "5K", "7K"]
                },
                {
                    name: "Transacciones fallidas",
                    data: ["0.5K", "1K", "0K", "0.5K", "1K", "2K", "4K"]
                }
            ],
            // Vueclockpicker
            defaultHour: new Date().getHours(),
            defaultMinute: new Date().getMinutes(),
            // Codemirror
            code: 'const str = "hello world"'
        };
    },
    computed: {
        rows () {
            return this.items.length;
        }
    },
    mounted () {
        // Init function
        this.handleResize();
        // Always watch window resize
        window.addEventListener("resize", this.handleResize);
    },
    destroyed () {
        // Always remove window watch resize
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        showDropdown () {
            document.getElementById("menuOverflowFix").classList.add('dropdown-table-overflow');
        },
        hideDropdown () {
            document.getElementById("menuOverflowFix").classList.remove('dropdown-table-overflow');
        },
        // Vueclockpicker
        timeChangeHandler () {
            // ...
        },
        // Open / Close component 'SidebarSide' Left
        openSidebarLeft () {
            this.isSidebarLeftOpen = !this.isSidebarLeftOpen;
        },
        // Open / Close component 'SidebarSide' Right
        openSidebarRight () {
            this.isSidebarRightOpen = !this.isSidebarRightOpen;
        },
        // Toggle class for component 'SidebarMenu'
        menuSidebar () {
            this.isSidebarSmall = !this.isSidebarSmall;
        },
        // When window changes
        handleResize () {
            this.window.width = window.innerWidth;
            // Change 'SidebarMenu' to small
            if (window.innerWidth < 1281) {
                this.noSmall = true;
                this.isSidebarSmall = true;
            } else {
                this.noSmall = false;
                this.isSidebarSmall = false;
            }
            // Modal New Updates, shows/hides Vertical/Horizontal images
            if (window.innerWidth < 992) {
                this.showHorizontalImg = false;
                this.showVerticalImg = true;
            } else {
                this.showHorizontalImg = true;
                this.showVerticalImg = false;
            }
        },
        // Close div 'card-updates' from 'SidebarSide' Right
        closeUpdates () {
            this.showUpdates = false;
        },
        // Modal New Updates, scroll to prev
        onSlideStart () {
            this.sliding = true;
        },
        // Modal New Updates, scroll to next
        onSlideEnd () {
            this.sliding = false;
        },
        headerFilters () {
            this.filtersHeader = !this.filtersHeader;
        }
    }
};
</script>
