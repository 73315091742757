<template>
    <div>
        <SignUpOrganizationBody confirmEmailStep>
            <template v-slot:formSectionBody>
                <router-link to="/register/organizations"
                    class="button xs button-ghost button-primary1 m-b-40 p-l-0 p-r-5" tabindex="">
                    <i aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('register-success.return-to-login')}}
                </router-link>

                <GenericTitle :title="$t('register-success.title')" :titleStrong="$t('register-success.title-strong')">
                    <p class="f-18 c-plain_text f-300 body-font-light d-block m-t-40 m-b-60">
                        {{$t('register-success.subtitle')}} <strong class="c-accent_2">{{email}}</strong>
                        {{$t('register-success.subtitle2')}} </p>
                </GenericTitle>

                <b-alert v-if="getShowAlert" class="alert-note" show :variant="getAlertVariant">
                    <p class="text-container"><img class="img-fluid"
                                                   src="@/assets/images/Icons/Alerts-icons/icon-alert-success.svg"
                                                   alt=""/> {{getAlertMessage}} </p>
                </b-alert>

                <img class="img-fluid d-flex m-0-auto m-b-40" src="@/assets/images/Illustrations/mail.svg"
                     alt="Illustration"/>

                <button id="btn-resendEmail" v-if="!getIsLoadingVerification"
                        class="button xs button-stroke button-accent1 w-100 m-b-30 center-item" type="submit"
                        @click.prevent="resendVerification"> {{$t('register-success.resend-email-button')}}
                </button>

                <button type="button" v-if="getIsLoadingVerification"
                        class="button xs button-stroke button-accent1 w-100 m-b-30 center-item">
                    <LoadingSimple clazz="small-load no-text"/>
                </button>

                <small class="f-14 c-info_text body-font-regular text-align-c d-block">
                    {{$t('register-success.email-not-received')}} <strong>
                    {{$t('register-success.email-not-received2')}} </strong>
                    {{$t('register-success.email-not-received3')}} </small>
            </template>
        </SignUpOrganizationBody>

    </div>
</template>

<script>

    import {mapGetters} from 'vuex';

    // @ is an alias to /src
    import GenericTitle from '@/components/GenericTitle.vue'
    import LoadingSimple from '@/components/LoadingSimple.vue'
    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue'

    const storeModule = 'SIGNIN_UP';

    export default {
        name: "EmailVerification",
        props: {
            email: {
                type: String
            }
        },
        components: {
            GenericTitle,
            LoadingSimple,
            SignUpOrganizationBody
        },
        computed: {
            ...mapGetters(storeModule, ['getCurrentEmail', 'getIsLoadingVerification', 'getShowAlert', 'getAlertMessage', 'getAlertVariant']),
            currentEmail() {
                return this.email;
            }
        },
        methods: {
            resendVerification() {
                if (this.email !== undefined) {
                    this.$store.dispatch(`${storeModule}/resendVerificationEmail`, this.email);
                }
            }
        },
        created() {
            this.$store.commit(`${storeModule}/SET_RIGHT_SIDE_BODY`, 'EMAIL_VERIFY');
        }
    };
</script>
