<template>
    <div id="dct-UploadProfileImage" class="upload-profile-image" :class="clazz">
        <span class="profile-img">
            <img v-if="!imgUser" class="img-fluid" src="@/assets/images/Default/profile-photo.svg" alt="default" />
            <img v-if="imgUser" class="img-fluid" :src="imgUser" alt="user" />
        </span>
        <button v-if="!imgUser && !showInfoUser" type="submit" class="button xs button-stroke button-accent2">
            {{$t('general.actions.add')}}
            <input :id="id" :ref="id" type="file" class="input-file" @change="fileUpload()" />
        </button>
        <button v-if="imgUser && imgUploaded && !showInfoUser" type="submit" class="button xs button-stroke button-accent2">
            {{$t('general.actions.change')}}
            <input :id="id" :ref="id" type="file" class="input-file" @change="fileUpload()" />
        </button>
        <span v-if="showInfoUser" class="info-user">
            <label>{{userName}}</label>
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'UploadProfileImage',
        props: {
            clazz: String,
            imgUser: {type: String},
            showInfoUser: {type: Boolean, default: false},
            imgUploaded: {type: Boolean, default: false},
            userName: String,
            id: {type: String, default: 'file-imageUpload'}
        },
        methods: {
             fileUpload() {
                this.$emit('change', this.$refs[this.id].files);
            }
        }
    }
</script>


<style lang="scss">
    .upload-profile-image {
        margin: 0 5px 30px;
        position: relative;
        display: block;

        .profile-img {
            display: flex;
            position: relative;
            margin: 0 auto 20px;
            align-items: center;
            justify-content: center;

            img {
                width: 160px;
                height: 160px;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .button {
            margin: 0 auto;
            position: relative;
        }

        .info-user {
            margin-left: 10px;

            label {
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                display: block;
                margin-bottom: 0;
            }

            > a {
                cursor: pointer;
                transition: 0.5s;
            }
        }

        &.horizontal-info {
            width: fit-content;
            display: flex;
            align-items: center;
            margin: 0 10px 10px;

            .profile-img {
                margin-bottom: 0;
            }
        }

        &.xs {
            .profile-img img {
                width: 48px;
                height: 48px;
                min-width: 48px;
            }
        }

        &.sm {
            .profile-img img {
                width: 97px;
                height: 97px;
                min-width: 97px;
            }
        }

        &.user-verification-login {
            .info-user {
                margin-left: 30px;

                label {
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 1.47;
                }
            }
        }
    }
</style>
