<template>
    <div class="row p-b-100">
        <div class="col-12">
            <ButtonScrollBottom idScroll="#text-section" btnText="Descubre más"></ButtonScrollBottom>
        </div>
        <div class="col-12">
            <section id="text-section" class="text-section-landingpage">
                <div class="width-responsive">
                    <TitleLandingpage hideOriginaltitle hideOriginalSubtitle clazz="m-b-0"
                                      titleClass="c-title principal-font-regular f-normal">
                        <template v-slot:titleContent>
                            <h1 v-html="$t('landing.features.h1-title')"></h1>
                        </template>
                        <template v-slot:subtitleContent>
                            {{$t('landing.features.h1-subtitle')}} <strong>
                            {{$t('landing.features.h1-subtitle-1')}} </strong> {{$t('landing.features.h1-subtitle-2')}}
                        </template>
                    </TitleLandingpage>
                </div>
            </section>
        </div>
        <div class="col-12">
            <section id="features-section" class="features-section-landingpage">
                <div class="width-responsive">
                    <div class="row landing-feature list">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div class="max-w-450px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Paneldecontrol.svg"
                                     alt="feature">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div>
                                <TitleLandingpage clazz="left-text max-w-450px"
                                                  :textTitle="$t('landing.features.partial-1-title')"
                                                  :textSubtitle="$t('landing.features.partial-1-subtitle')"></TitleLandingpage>
                                <ul>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-1-dot-1')}}</span>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-1-dot-2')}}</span>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-1-dot-3')}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row landing-feature list">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div>
                                <TitleLandingpage clazz="left-text max-w-450px"
                                                  :textTitle="$t('landing.features.partial-2-title')"
                                                  :textSubtitle="$t('landing.features.partial-2-subtitle')"></TitleLandingpage>
                                <ul>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-2-dot-1')}}</span>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-2-dot-2')}}</span>>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div class="max-w-450px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Configuracion.jpg"
                                     alt="feature">
                            </div>
                        </div>
                    </div>
                    <div class="row landing-feature list">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div class="max-w-450px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Notificaciones.jpg"
                                     alt="feature">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div>
                                <TitleLandingpage clazz="left-text max-w-450px"
                                                  :textTitle="$t('landing.features.partial-3-title')"
                                                  :textSubtitle="$t('landing.features.partial-3-subtitle')"></TitleLandingpage>
                                <ul>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-3-dot-1')}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row landing-feature list">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div class="max-w-450px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Micrositiodetuorganizacion.jpg"
                                     alt="feature">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div>
                                <TitleLandingpage clazz="left-text max-w-450px"
                                                  :textTitle="$t('landing.features.partial-5-title')"
                                                  :textSubtitle="$t('landing.features.partial-5-subtitle')"></TitleLandingpage>
                                <ul>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-5-dot-1')}}</span>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-5-dot-2')}}</span>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                                        <span>{{$t('landing.features.partial-5-dot-3')}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import TitleLandingpage from "@/components/TitleLandingpage.vue";
    import ButtonScrollBottom from "@/components/ButtonScrollBottom.vue";
    import {
        STORE as LandingStore,
        SET_TAB_ACTIVE
    } from "@/store/modules/landing/landing.types";

    export default {
        name: "LandingFeatures",
        components: {
            TitleLandingpage,
            ButtonScrollBottom
        },
        created() {
            this.$store.commit(`${LandingStore}/${SET_TAB_ACTIVE}`, 'FEATURES');
        }
    }
</script>

