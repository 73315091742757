<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <FloatingTitleLayout
                        :msgSmall="$t('api-configuration.smallMessage')"
                        :title="$t('api-configuration.checkout.tittle')"
                />
            </div>

            <div class="col-12">
                <CardSimple
                        :title="$t('api-configuration.checkout.select.tittle')"
                        :subtitle="$t('api-configuration.checkout.select.subtittle')"
                >
                    <div class="row max-w-786px">
                        <div class="col-12 col-md-6 m-b-30">
                            <CardCampaign
                                    :imgUrl="imgApi"
                                    title="Html/Javascript"
                                    :text="$t('api-configuration.checkout-card.subtitle')"
                                    :btnText="$t('api-configuration.cards-button')"
                                    v-on:clickButton="goToView(typesCheckout.JAVASCRIPT)"
                            ></CardCampaign>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <CardCampaign
                                    imgUrl="https://cdn.iconscout.com/icon/free/png-256/prestashop-282269.png"
                                    title="PrestaShop"
                                    :text="$t('api-configuration.checkout-card.subtitle')"
                                    :btnText="$t('api-configuration.cards-button')"
                                    v-on:clickButton="goToView(typesCheckout.PRESTASHOP)"
                            ></CardCampaign>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <CardCampaign
                                    :imgUrl="imgMagento"
                                    title="Magento"
                                    :text="$t('api-configuration.checkout-card.subtitle')"
                                    :btnText="$t('api-configuration.cards-button')"
                                    v-on:clickButton="goToView(typesCheckout.MAGENTO)"
                            ></CardCampaign>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <div class="layer-disabled"></div>
                            <CardCampaign
                                    imgUrl="https://cdn.iconscout.com/icon/free/png-256/shopify-226579.png"
                                    title="Shopify"
                                    :text="$t('api-configuration.checkout-card.subtitle')"
                                    :btnText="$t('api-configuration.checkout.select.coming-soon')"
                                    v-on:clickButton="goToView(typesCheckout.SHOPIFY)"
                            ></CardCampaign>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <CardCampaign
                                    imgUrl="https://cdn.iconscout.com/icon/free/png-512/woocommerce-226060.png"
                                    title="Woocomerce"
                                    :text="$t('api-configuration.checkout-card.subtitle')"
                                    :btnText="$t('api-configuration.cards-button')"
                                    v-on:clickButton="goToView(typesCheckout.WOOCOMERCE)"
                            ></CardCampaign>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>
    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import CardCampaign from '@/components/CardCampaign.vue';
    import imgApi from '@/assets/images/Icons/icon-code-lap.svg';
    import imgMagento from '@/assets/images/magento/magento.png';
    import imgFormulario from '@/assets/images/Icons/icon-form.svg';
    import imgBtnCheckout from '@/assets/images/Icons/icon-checkout.svg';
    import {JAVASCRIPT, SHOPIFY, PRESTASHOP, WOOCOMERCE, MAGENTO} from '@/views/apiWeb/checkouts/paymentMethods';

    export default {
        mixins: [],
        name: "selectCheckout",
        components: {
            FloatingTitleLayout,
            CardSimple,
            CardCampaign
        },
        data() {
            return {
                // TODO: Change icons url for assets
                imgApi: imgApi,
                imgMagento: imgMagento,
                imgFormulario: imgFormulario,
                imgBtnCheckout: imgBtnCheckout,
                typesCheckout: {
                    JAVASCRIPT: JAVASCRIPT,
                    SHOPIFY: SHOPIFY,
                    PRESTASHOP: PRESTASHOP,
                    WOOCOMERCE: WOOCOMERCE,
                    MAGENTO: MAGENTO,
                }
            }
        },
        methods: {
            /*
            This function change the current view to the desired view
             */
            goToView(desiredRoute) {
                this.$router.replace(`/client-admin/apiWeb/api-checkoutbutton/${desiredRoute}`)
            }
        }
    }
</script>
