<template>
    <div :id="idForm" class="form-group" :class="`${clazz} ${errorClass}`">
        <input :id="inputId" :ref="customRef" :min="min" :max="max" :type="inputType" v-if="!readOnly" class="form-control"
               :class="inputClazz" :placeholder="inputPlaceholder" :value="value || inputValue" aria-label=""
               @input="onBasicInput" @change="onChangeInput" :disabled="disabled" :name="name" @focus="onFocus"/>
        <input :id="inputId" :ref="customRef" v-else readonly :type="inputType" class="form-control" :class="inputClazz"
               :placeholder="inputPlaceholder" :value="value || inputValue" aria-label="" @input="onBasicInput"
               :disabled="disabled" :name="name" @focus="onFocus"/>
        <button :id="extraInputId" class="extra-input-button" v-if="inputClazz" type="button" @click="onIconClick">
            <i aria-hidden="true" class="input-icon banana" :class="iconInput"></i>
        </button>

        <slot></slot>

        <div class="input-label">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i aria-hidden="true" :class="warningIcon || warningIconComputed"></i>
            {{warningText || warningTextComputed}}
        </small>
    </div>
</template>

<script>
    // TODO meter en utils
    const index = [];
    const debounceTime = 800;
    const debounce = function (func, ...params) {
        const id = func.toString() + JSON.stringify(params || []);
        if (!index[id]) {
            index[id] = true;
            setTimeout(function () {
                    index[id] = false
                }
                , debounceTime
            );
            return func(...params);
        }
            return null;
    };

    export default {
        name: 'BasicInput',
        props: {
            min: Number,
            max: Number,
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            required: {type: Boolean, default: false},
            label: String,
            helpText: String,
            warningText: String,
            inputPlaceholder: String,
            helpIcon: String,
            warningIcon: String,
            labelFor: String,
            inputId: String,
            extraInputId: String,
            clazz: String,
            inputType: String,
            disabled: {type: Boolean, default: false},
            readOnly: {type: Boolean, default: false},
            inputValue: {type: String, default: null},
            value: {type: [String, Number] , default: null},
            hasBlur: {type: Boolean, default: false},
            inputClazz: String,
            iconInput: String,
            name: {
                type: String,
                default: ""
            },
            customRef: {
                type: String,
                default: "basicInput"
            },
            validations: {},
            validationsNamespace: {
                type: String,
                default: "general.validations"
            },
            idForm: String,
            hasError: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            onBasicInput(event) {
                this.$emit('basicInput', event.target.value);
                this.$emit('input', event.target.value);
                if (this.validations) {
                    debounce(() => {
                        this.validations.$touch();
                    });
                }
            },
            onChangeInput(event) {
                this.$emit('onChangeInput', event.target.value);
            },
            onIconClick() {
                this.$emit('onIconClick')
            },
             onFocus () {
                this.$emit('focus');
            },
        }, computed: {
            errorClass() {
                return this.hasError || (this.validations && this.validations.$error) ? 'has-error' : '';
            },
            warningTextComputed() {
                if (this.validations) {
                    const errorKey = Object
                        .keys(this.validations)
                        .find(k => k && this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
                    if (errorKey) {
                        const params = this.validations.$params[errorKey];
                        return this.$t(`${this.validationsNamespace}${this.validationsNamespace ? '.' : ''}${errorKey}`, params);
                    }
                        return null;

                }
                    return null;

            },
            warningIconComputed() {
                if (this.validations) {
                    const errorKey = Object
                        .keys(this.validations)
                        .find(k => k && this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
                    return errorKey ? 'banana banana-warning-circle1' : null;
                }
                    return null;

            }
        }
    }
</script>
