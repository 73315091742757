<template>
    <div>
        <ModalDefault
            :modalId="modalId"
            :ref="modalId"
            size="lg"
        >
            <PaymentInfoRow
                :organization="type"
                :paymentName="transaction.paymentDetail ? transaction.paymentDetail.name : ''"
                :date="dateFormated"
                :showCoin="false"
                :cant="numberToMoneyString(transaction.paymentDetail.subTotal || transaction.total)"
                :category="category"
                showCategory
                clazz="m-b-40 m-t--10"
            ></PaymentInfoRow>

            <b-tabs
                class="simple-tabs group-tabs"
                content-class="simple-tabs-body p-t-30 min-height-300px"
                active-tab-class="primary-bg"
            >
                <b-tab
                    :title="$t('pagos-recaudados.user-info')"
                    active
                >
                    <div class="row" v-if="transaction.user && transaction.user.email">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('pagos-recaudados.user')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{transaction.user.name || ''}} {{transaction.user.lastName || ''}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('pagos-recaudados.email')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{transaction.user.email || ''}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('pagos-recaudados.phone')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{transaction.user.phone || ''}}
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('pagos-recaudados.user')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                Usuario de terminal bancaria
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab
                    :title="$t('pagos-recaudados.pay-detail')"
                    v-if="transaction.allFields && transaction.allFields.length >0"
                >
                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('pagos-recaudados.payment-type')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{type}}
                            </div>
                        </div>

                        <div
                            class="col-sm-6 col-12 m-b-30"
                            v-for="(ref, index) in transaction.allFields"
                            :key="index"
                        >
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{ref.name}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{ref.value}}
                            </div>
                        </div>

                    </div>
                </b-tab>
                <b-tab
                    :title="$t('pagos-recaudados.pay-detail')"
                    v-if="transaction.status === 'NOT_SUCCESSFUL'"
                >
                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('pagos-recaudados.reject-reason')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{type}}
                            </div>
                        </div>

                    </div>
                </b-tab>
                <b-tab
                    :title="$t('pagos-recaudados.aditional-notes')"
                    v-if="transaction.paymentDetail && transaction.paymentDetail.additionalNotes"
                >
                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{transaction.paymentDetail ? transaction.paymentDetail.additionalNotesConfig.label : '-'}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{transaction.paymentDetail ? transaction.paymentDetail.additionalNotes : '-'}}
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab :title="$t('pagos-recaudados.payment-detail')">
                    <div class="dots-table m-b-30">
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('pagos-recaudados.card-type')}}
                            </div>
                            <div class="right-side">
                                {{getCard()}}
                                <img
                                    class="img-fluid max-h-20px"
                                    :src="cardBrandLogo"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('pagos-recaudados.sub-total')}}
                            </div>
                            <div class="right-side f-bold">
                                {{numberToMoneyString(dispersionDetail.totalWithoutCommision || 0)}}
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('pagos-recaudados.discounts')}}
                            </div>
                            <div class="right-side c-success f-bold">
                                - {{numberToMoneyString(transaction.paymentDetail ? transaction.paymentDetail.totalDiscounts : 0)}}
                            </div>
                        </div>
                        <div
                            class="item-row"
                            v-if="taxes > 0"
                        >
                            <div class="left-side">
                                {{$t('pagos-recaudados.taxes')}}
                            </div>
                            <div class="right-side c-success f-bold">
                                {{numberToMoneyString(taxes)}}
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('pagos-recaudados.total')}}
                            </div>
                            <div class="right-side f-bold">
                                {{numberToMoneyString(dispersionDetail.totalWithoutCommision || dispersionDetail)}}
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('pagos-recaudados.bank-commision')}}
                            </div>
                            <div class="right-side f-bold">
                                {{numberToMoneyString(dispersionDetail.organizationBankCommision || 0)}}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-c justify-content-end">
                        <div class="principal-font-bold f-13 c-primary_1 m-r-20">
                            {{$t('pagos-recaudados.amount-to-user')}}
                        </div>
                        <div class="body-font-bold f-28 c-accent_2" v-if="hideActionButtons">
                            {{numberToMoneyString(0)}}
                        </div>
                        <div class="body-font-bold f-28 c-accent_2" v-else>
                            {{numberToMoneyString(dispersionDetail.organizationDeposit || 0)}}
                        </div>
                    </div>
                </b-tab>
            </b-tabs>

            <template v-slot:customFooter>
                <div class="w-100">
                    <div class="row">
                        <div class="col-sm-4 col-12 d-flex align-items-c">
                            <div
                                class="c-title d-14 body-font-regular"
                                v-if="canRefund"
                            >
                                <button
                                    v-if="!hideActionButtons"
                                    class="button xs"
                                    :class="{
                                        'button-ghost button-gray': isRefund,
                                        'button-stroke button-warning': !isRefund
                                    }"
                                    @click="startRefund(transaction)"
                                    :disabled="isRefund"
                                >
                                    {{$t(`pagos-recaudados.refunds.${isRefund ? 'is-refunded' : 'start'}`)}}
                                </button>
                            </div>
                        </div>
                        <div class="col-sm-8 col-12">
                            <div class="d-sm-flex justify-content-end">
                                <button
                                    @click="hide"
                                    class="button xs button-ghost button-plain_text m-r-20"
                                >{{$t('general.close')}}</button>
                                <button
                                    @click="downloadReceipt"
                                    class="button xs button-filled button-accent1"
                                >{{$t('pagos-recaudados.download-recipie')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ModalDefault>

        <Refund ref="modalRefund" @refreshList="refreshList" ></Refund>
    </div>
</template>

<script>
import ModalDefault from '@/components/ModalDefault.vue';
import PaymentInfoRow from '@/components/PaymentInfoRow.vue';
import {utils} from '@/api/utils';
import VisaLogo from '@/assets/images/Logos/Cards/visa.svg';
import AmExLogo from '@/assets/images/Logos/Cards/american-express.svg';
import BravosLogo from '@/assets/images/Logos/Cards/bravoscard.svg';
import PagandoLogo from '@/assets/images/Logos/Cards/pagandocard.svg';
import MCLogo from '@/assets/images/Logos/Cards/mastercard.svg';
import GenericCardLogo from '@/assets/images/Logos/Cards/generic.svg';
import transactionHistoryApi from "@/api/payments/transactionHistory.api";
import Refund from "@/views/payments/Refund.vue";
const debitTranslate = 'dashboard.debit-card';
const unknowCard = 'dashboard.unknow-card';
import {
  STORE as AccessConfigStore,
  GET_CURRENT_ORGANIZATION,
} from '@/store/modules/accessconfig/accessConfig.types';
import {mapGetters} from "vuex";

const transactionDefault = {
    paymentDetail: {
    },
    allFields: [],
    user: {},
    apiDetail: {
        charges: {
            _json: {
                data: [{
                    payment_method: {
                        type: '',
                        brand: '',
                    }
                }]
            }
        }
    },
};
export default {
    name: 'ModalTransactionInfo',
    components: {
        ModalDefault,
        PaymentInfoRow,
        Refund
    },
    props: {
        modalId: {type: String, default: "modal-transaction-info"}
    },
    data () {
        return {
            transaction: transactionDefault,
            transactionStatus: null,
            hideActionButtons: false,
        }
    },
    computed: {
        dateFormated () {
            return `${this.$t('pagos-recaudados.payment-date')}: ${this.formatDate(this.transaction.date, 'dddd DD MMMM, YYYY - hh:mm a')}`
        },
        type () {
            if(this.transaction.paymentDetail) {
                return this.$t(`pagos.rows.types-long.${this.transaction.paymentDetail.type}`);
            }

            return 'Desconocido';
        },
        category () {
            if(this.transaction.paymentType === "DEBT" || this.transaction.paymentType === "D"){
                return this.transaction.paymentDetail.debt.clasification;
            }
            return this.transaction.paymentDetail ? this.transaction.paymentDetail.clasification : 'other';
        },
        commision () {
            if (this.transaction.dispersionDetail){
                return this.transaction.dispersionDetail.organizationBankCommision;
            } else if (this.transaction.paymentDetail && this.transaction.paymentDetail.organizationBankCommision) {
                return this.transaction.paymentDetail.organizationBankCommision;
            } else if (this.transaction.paymentDetail && this.transaction.paymentDetail.commision) {
                return this.transaction.paymentDetail.commision;
            }
            return 0;
        },
        taxes () {
            return this.transaction.paymentDetail ? this.transaction.paymentDetail.totalTaxes : 0;
        },
        cardType () {
            const creditString = 'pagos-recaudados.credit-card';
            const unkwonCardString = 'pagos-recaudados.unknow-card';
            const debitCardString = 'pagos-recaudados.debit-card';
            switch (this.transaction.payHolder) {
                case "CONEKTA":
                    switch (this.transaction.apiDetail.charges._json.data[0].payment_method.type.toLowerCase()) {
                        case 'adit':
                            return this.$i18n.t(creditString);
                        case 'debit':
                            return this.$i18n.t(debitCardString);
                        default:
                            return this.$i18n.t(unkwonCardString);
                    }
                case "BL":
                    switch (this.transaction.apiDetail.transaction.paymentType) {
                    case 'CREDIT':
                        return this.$i18n.t(creditString);
                    case 'DEBIT':
                        return this.$i18n.t(debitCardString);
                    default:
                        return this.$i18n.t(unkwonCardString);
                    }
                case "BANREGIO":
                case "PAGANDO_GIFT":
                case "BZPAY":
                default:
                    if (
                        this.transaction.apiDetail &&
                        this.transaction.apiDetail.transaction &&
                        this.transaction.apiDetail.transaction.paymentType &&
                        this.transaction.apiDetail.transaction.paymentType.toLowerCase() === 'cc'
                    ) {
                        return this.$i18n.t(creditString);
                    }
                    return this.$i18n.t('pagos-recaudados.unknow-card');
            }
        },
        cardBrandName() {
            switch (this.transaction.payHolder) {
            case "CONEKTA":
                return this.transaction.apiDetail.charges._json.data[0].payment_method.brand.toLowerCase();
            case "BANREGIO":
            case "BZPAY":
            case "BL":
            case "PAGANDO_GIFT":
                return this.transaction.apiDetail.transaction && this.transaction.apiDetail.transaction.cardType ?
                    this.transaction.apiDetail.transaction.cardType.toLowerCase() :
                    'N/A';
            default:
                return this.transaction.apiDetail ? this.transaction.apiDetail.cardBrand : 'N/A';
            }
        },
        cardBrandLogo () {
            switch (this.cardBrandName) {
            case 'visa':
                return VisaLogo;
            case 'mastercard':
                return MCLogo;
            case 'carnet':
                return GenericCardLogo; // TODO: Generar logo de carnet
            case 'american_express':
                return AmExLogo;
            case 'pagando-electronic-gift':
                return this.checkIfBravoCard(this.transaction.apiDetail.transaction.first6) ? BravosLogo : PagandoLogo;
            default:
                return GenericCardLogo;
        }
        },
        dispersionDetail() {
            return this.transaction?.dispersionDetail || {};
        },
        isRefund() {
            return this.transaction.isRefound || this.transactionStatus === 'REFUND';
        },
      canRefund() {
        if (this.transaction.payHolder !== 'PAGANDO_GIFT') {
          if (this.transaction.paymentType ==='TERMINAL_PAYMENT') {
              return this.$can('do', 'REFUNDS_WITHOUT_CARD')
                  && this?.transaction?.apiDetail?.transaction?.operationType !== 'CHECK_IN'
                  && this?.currentOrganization?.canRefundWithoutCard;
          }
            return true;
        }
        return false;
      },
        ...mapGetters(AccessConfigStore, {
          currentOrganization: GET_CURRENT_ORGANIZATION
        }),
    },
    methods: {
        async show (transaction, status, hideActionButtons = false) {
            this.transaction = transaction;
            this.transactionStatus = status;
            this.hideActionButtons = hideActionButtons;
            this.$refs[this.modalId].showModal();
        },
        checkIfBravoCard(first6){
            return first6.indexOf('9001') !== -1;
        },
        numberToMoneyString(number){
            return utils.numberToMoneyString(number)
        },
        formatDate(date, format){
           return utils.formatDate(date, format)
        },
        hide () {
            this.transaction = transactionDefault;
            this.$refs[this.modalId].hideModal();
        },
        downloadReceipt () {
            transactionHistoryApi.downloadPDF(this.transaction.originalOrder).then((data) => {
                    if(!data.data.error){
                        window.open(data.data.object.renderUrl, "_blank");
                    }
            }).catch(() => {
                this.displayNotificationWarning(this.$i18n.t('pagos-recaudados.reciept-report-fail'));
            });
        },
        startRefund(transaction) {
            if (!this.isRefund) {
                this.$refs[this.modalId].hideModal();
                this.$refs.modalRefund.showRefundModal(transaction);
            }
        },
        refreshList(){
            this.$emit("refreshList");
        },
        getCard() {
            if(this.transaction?.apiDetail?.transaction?.last4 === null && this.transaction?.paymentDetail?.type === 'WS' ) {
                return this.$i18n.t(debitTranslate);
            }
            return `${this.returnSwitch(this.transaction?.apiDetail?.transaction?.paymentType)} ****${this.transaction?.apiDetail?.transaction?.last4}`
        },
        returnSwitch(opc){
            if (opc) {
                switch (opc.toLowerCase()) {
                    case "credit":
                        return this.$i18n.t('dashboard.credit-card');
                    case "debit":
                        return this.$i18n.t(debitTranslate);
                    default:
                        return this.$i18n.t(unknowCard);
                }
            }
            return this.$i18n.t(unknowCard);
        },
    },
    mounted() {
    }
}
</script>
