import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger'
import actions from '@/store/actions';
import state from '@/store/state';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import session from '@/store/modules/session/session.store';
import {STORE as SessionStore} from '@/store/modules/session/session.types';
import accessConfig from '@/store/modules/accessconfig/accessConfig.store';
import {STORE as AccessConfigStore} from '@/store/modules/accessconfig/accessConfig.types';
import organization from '@/store/modules/organization/organization.store';
import {STORE as OrganizationStore} from '@/store/modules/organization/organization.types';
import banks from '@/store/modules/banks/banks.store';
import {STORE as BanksStore} from '@/store/modules/banks/banks.types';
import {STORE as TransactionHistoryStore} from '@/store/modules/payments/transactionHistory.types';
import transactionHistory from '@/store/modules/payments/transactionHistory.store';
import taxReceipts from '@/store/modules/taxreceipts/taxReceipts.store';
import {STORE as TaxReceiptsStore} from '@/store/modules/taxreceipts/taxReceipts.types';
import paymentsTemplate from '@/store/modules/paymentstemplate/paymentsTemplate.store';
import {STORE as PaymentTemplateStore} from '@/store/modules/paymentstemplate/paymentsTemplate.types';
import {STORE as LandingStore} from '@/store/modules/landing/landing.types';
import {STORE as DocumentsStore} from '@/store/modules/documents/documents.types';
import documents from '@/store/modules/documents/documents.store';
import {STORE as ChargebackStore} from '@/store/modules/chargebacks/chargebacks.types';
import chargebacks from '@/store/modules/chargebacks/chargebacks.store';
import terminals from '@/store/modules/terminals/terminals.store';
import {STORE as TerminalsStore} from '@/store/modules/terminals/terminals.types';

import SingInUpStore from '@/store/modules/signin-up.store';
import {STORE as SignInStore} from '@/store/modules/signin/signin.types';

import VerificationEmailStore from '@/store/modules/users/verificationEmail.store.js';
import {STORE as VerificationEmail} from '@/store/modules/users/verificationEmail.types.js';

import usersSignupWizard from "@/store/modules/users/usersSignupWizard.store.js";
import landing from "@/store/modules/landing/landing.store.js";

import apiRest from "@/store/modules/apiWeb/apiRest.store.js";

// Client
import SignUpOrganizationStore from '@/store/modules/signUpOrganization.store';
// Admin
import UserStore from '@/store/modules/users.store';
import AdminDashboardStore from '@/store/modules/admin/adminDashboard.store';

import permissions from '@/store/modules/permissions/permissions.store';
import {STORE as PermissionsStore} from '@/store/modules/permissions/permissions.types';

import collaborators from '@/store/modules/permissions/collaborators.store';
import {STORE as CollaboratorsStore} from '@/store/modules/permissions/collaborators.types';

import RecoveryPasswordStore from '@/store/modules/recoveryPassword.store';


Vue.use(Vuex);

// TODO: a better way to obtain current env
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        [SessionStore]: session,
        [AccessConfigStore]: accessConfig,
        [OrganizationStore]: organization,
        [BanksStore]: banks,
        [SignInStore]: SingInUpStore,
        [TransactionHistoryStore]: transactionHistory,
        [TaxReceiptsStore]: taxReceipts,
        signUpOrganization: SignUpOrganizationStore,
        recoveryPassword: RecoveryPasswordStore,
        users: UserStore,
        adminDashboardStore: AdminDashboardStore,
        [VerificationEmail]: VerificationEmailStore,
        usersSignupWizard: usersSignupWizard,
        [PaymentTemplateStore]: paymentsTemplate,
        [LandingStore]: landing,
        [DocumentsStore]: documents,
        apiRest: apiRest,
        [DocumentsStore]: documents,
        [PermissionsStore]: permissions,
        [CollaboratorsStore]: collaborators,
        [ChargebackStore]: chargebacks,
        [TerminalsStore]: terminals
    },
    actions: actions,
    getters: getters,
    mutations: mutations,
    strict: debug,
    state: state,
    plugins: debug ? [createLogger()] : []
});
