<template>
  <div>
    <router-link
      to="/signin"
      class="button xs button-ghost button-primary1 m-b-40 p-l-0 p-r-5"
      tabindex
    >
      <i aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('recovery-password.return-login')}}
    </router-link>
    <GenericTitle :title="$t('recovery-password.title')" :titleStrong="$t('recovery-password.title-strong')">
      <p
        class="f-14 c-plain_text body-font-light d-block m-b-40"
      >{{$t('recovery-password.instructions')}}</p>
    </GenericTitle>
    <b-alert class="alert-note" show variant="danger" v-if="getRecoveryAlert">
      <p class="text-container">
        <img class="img-fluid" src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg" alt="Imagen alerta" /> {{$t('recovery-password.confirmation')}}
      </p>
    </b-alert>

    <BasicInput
      required
      inputType="text"
      labelFor="txt-emailRecovery"
      inputId="txt-emailRecovery"
      :label="$t('recovery-password.email-label')"
      :inputPlaceholder="$t('recovery-password.email-placeholder')"
      name="name"
      v-on:basicInput="onBasicInputRecovery"
    ></BasicInput>
    <div class="horizontal-center">
      <vue-recaptcha
        :sitekey="sitekey"
        type="checkbox"
        ref="recaptcha"
        @verify="onVerify"
        @expired="onExpired"
      ></vue-recaptcha>
    </div>
    <button
      id="btn-recoveryPassSubmit"
      class="button xs button-filled button-accent1 w-100 m-t-30"
      :class="{'button-disable':disabledRecoveryPassword}"
      :disabled="disabledRecoveryPassword"
      @click="send"
    >{{$t('recovery-password.recover-password-button')}}</button>
  </div>
</template>

<script>
// @ is an alias to /src
import GenericTitle from "@/components/GenericTitle.vue";
import BasicInput from "@/components/BasicInput.vue";
import config from "@/config";
import VueRecaptcha from "vue-recaptcha";
import {mapGetters} from "vuex";
import {
    STORE as UserSignUpStore,
} from '@/store/modules/signin/signin.types'
const storeModule = "recoveryPassword";

const publicKey = config.googleRecapcha.publicKey;
export default {
  name: "SignIn",
  data() {
    return {
      sitekey: publicKey,
      token: null,
      expired: false,
      registerEmail: "",
      correctEmail: false
    };
  },
  mounted () {
      window.onload = function() {
          window.scrollTo(0, -100);
      };
  },
  methods: {
    onVerify(response) {
      this.token = response;
      this.expired = false;
    },
    onExpired() {
      this.expired = true;
      this.displayNotificationError("reset-password.captcha.expired");
    },
    send() {
      const params = {
        email: this.registerEmail,
        token: this.token
      };
      this.$store.dispatch(`${storeModule}/recoveryPassword`, params);
    },

    onBasicInputRecovery(email) {
      const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gim;
      this.correctEmail = regexEmail.test(email);
      this.registerEmail = email;
    }
  },
  computed: {

    disabledRecoveryPassword() {
      return !(this.registerEmail.length >= 6 && this.correctEmail && this.token !== null && this.token !== undefined && this.token !== '' && this.expired === false);
    },

    ...mapGetters(storeModule, [
      "getRecoveryAlert",
    ])

  },
  components: {
    GenericTitle,
    BasicInput,
    VueRecaptcha
  },
  created() {
    this.$store.commit(`${UserSignUpStore}/SET_RIGHT_SIDE_BODY`, 'DONT_WORRY');
  }
};
</script>
