import axios from 'axios'
import base from "@/api/base.api";

export default {
    countryList: (params, onSuccess, onError) => {
        return axios
            .get(`${base.baseUrl}/countries/active-countries`, params)
            .then(onSuccess)
            .catch(onError);
    },
    stateList: (params, onSuccess, onError) => {
        return axios
            .post(`${base.baseUrl}/countries/states-country-key`, params)
            .then(onSuccess)
            .catch(onError);
    }
}
