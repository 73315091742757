<template>
    <div>
        <div class="webcam-photo-container">
            <div class="webcam-window"></div>
            <video class="image-inverted-x" :id="`video-${$props.camId}`" :width="width" :height="height" autoplay></video>
            <canvas class="d-none" :id="`canvas-${$props.camId}`" :width="canvasWidth" :height="canvasHeight"></canvas>
            <div class="photo-btn-container">
                <slot name="cameraButtons"></slot>
            </div>
        </div>

        <slot name="note"></slot>

        <div v-if="imgTaken">
            <div class="img-card">
                <img class="img-fluid" src="https://img1.looper.com/img/uploads/2019/02/harry-potter.jpg" alt="picture" />
            </div>
            <span v-if="verified" class="verified-check"><i aria-hidden="true" class="banana banana-check-circle1"></i> Verificado Correctamente </span>
        </div>
    </div>
</template>


<script>
    import {EventBus} from "@/main";

    // Elements for taking the snapshot
    let canvas;
    let context;
    let videoElement;
    import ToastSnotify from '@/mixins/toastSnotify.mixin'

    export default {
        name: "CameraTest",
        data() {
            return {
                videoSelectedIndex: 0,
                videoSources: [],
                videoSourceAvailable: false,
                videoTracks: []
            }
        },
        created() {
            EventBus.$on(`global.cameraVue.turn-off`, ()=>{
                this.stopVideoCapture()
            });
        },
        beforeDestroy() {
            this.stopVideoCapture()
            EventBus.$off(`global.cameraVue.turn-off`);
        },
        mixins: [
            ToastSnotify
        ],
        methods: {
            initCamera(callback) {
                canvas = document.getElementById(`canvas-${this.$props.camId}`);
                context = canvas.getContext('2d');
                const videoElementId = `video-${this.$props.camId}`;
                videoElement = document.getElementById(videoElementId);
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    // Not adding `{ audio: true }` since we only want video now
                    const promise = navigator.mediaDevices.enumerateDevices();
                    promise.then(this.gotDevices)
                    promise.then(this.getStream)
                    if(callback){
                        promise.then(callback)
                    }
                    promise.catch(this.handleError);
                }
            },
            pauseCamera(){
                videoElement.pause();
            },_pauseCamera() {
                this.pauseCamera();
                this.imagesStatus.photoTaken = true;
                this.updateIneImagesStatus();
            },
            takePicture() {
                videoElement.pause();
                // Trigger photo take
                context.drawImage(videoElement, 0, 0, 640, 480);
                const pngBase64 = canvas.toDataURL();

                const file = this.dataURLtoBlob(pngBase64);
                const size = file.size;

                return {
                    image: pngBase64,
                    size: size.toString(),
                    name: `${this.$props.imageNamePrefix}${Math.floor(Math.random() * 100000)}`,
                    type: "image/png",
                    classification: this.$props.imageClassification
                }
            },
            gotDevices(deviceInfos) {
                const VIDEO_INPUT_TYPE = "videoinput";
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];
                    if (deviceInfo.kind === VIDEO_INPUT_TYPE) {
                        this.videoSourceAvailable = true;
                        this.videoSources.push({
                            index: this.videoSources.length,
                            id: deviceInfo.deviceId,
                            name: deviceInfo.label || `camera ${ this.videoSources.length}`
                        })
                    }
                }
            },
            nextVideoSource() {
                if (this.videoSources && this.videoSources.length) {
                    if (this.videoSelectedIndex + 1 < this.videoSources.length) {
                        this.videoSelectedIndex++;
                    } else {
                        this.videoSelectedIndex = 0;
                    }
                    this.getStream()
                } else {
                    this.displayNotificationWarning(this.$i18n.t('camara-component.not-source-video'))
                }
            },

            getStream() {
                if (!this.videoSources || !this.videoSources.length) {
                    this.displayNotificationWarning(this.$i18n.t('camara-component.not-source-video'))
                    this.videoSourceAvailable = false;
                    return;
                }
                if (window.stream) {
                    window.stream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                }

                const constraints = {
                    video: {
                        deviceId: {exact: this.videoSources[this.videoSelectedIndex].id}
                    }
                };
                navigator.mediaDevices.getUserMedia(constraints)
                    .then(this.gotStream)
                    .catch(this.handleError);
            }, gotStream(stream) {
                this.videoTracks = [];
                window.stream = stream; // make stream available to console
                window.stream.getTracks().forEach((track) => {
                    this.videoTracks.push(track)
                });
                videoElement.srcObject = stream;
            }, handleError() {
                this.displayNotificationWarning(this.$i18n.t('camara-component.not-video-see'))
            },
            stopVideoCapture(callback) {
                if (window.stream) {
                    window.stream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                    if(callback){
                        callback()
                    }

                }
            },
            retakePhoto() {
                videoElement.play()
            },
            dataURLtoBlob(dataURL) {
                // Decode the dataURL
                const binary = atob(dataURL.split(',')[1]);
                // Create 8-bit unsigned array
                const array = [];
                for (let i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                // Return our Blob object
                return new Blob([new Uint8Array(array)], {type: 'image/png'});
            }
        },
        props: {
            camId: {
                type: String,
                required: true
            },
            width: {
                type: Number,
                default: 640
            },
            height: {
                type: Number,
                default: 480
            },
            canvasWidth: {
                type: Number,
                default: 640
            },
            canvasHeight: {
                type: Number,
                default: 480
            },
            imgTaken: {
                type: Boolean,
                default: false
            },
            verified: {
                type: Boolean,
                default: false
            },
            // Where the image must be placed within the Amazon S3
            imageClassification: {
                type: String,
                required: true
            },
            // A unique identifier for the image
            imageNamePrefix: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss">
    .image-inverted-x {
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        width: 100%;
        max-height: 335px;
        height: auto;
    }

    .webcam-photo-container {
        width: 100%;
        max-width: 100%;
        max-height: 340px;
        min-height: 340px;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .webcam-window {
        width: 91%;
        height: calc(100% - 90px);
        border-radius: 8px;
        opacity: .8;
        position: absolute;
        z-index: 9;
        transform: translate(-50%, 0px);
        left: 50%;
        top: 20px;
    }

    .photo-btn-container {
        position: absolute;
        bottom: 24px;
        z-index: 9;
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;

        > .button {
            margin: 0 5px 5px;
            padding-left: 10px !important;
            padding-right: 10px !important;

            &:first-of-type i,
            &:last-of-type i  {
                margin-right: 0;
            }
        }
    }

    .button {
        white-space: nowrap;
    }
</style>
