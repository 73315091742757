import BEAUTY_ICON from "@/assets/images/Illustrations/categories/Illustration-health.svg";
import CLOTHES_ICON from "@/assets/images/Illustrations/categories/Illustration-clothes.svg";
import EDUCATION_ICON from "@/assets/images/Illustrations/categories/Illustration-school.svg";
import FOOD_ICON from "@/assets/images/Illustrations/categories/Illustration-food.svg";
import GARDENING_ICON from "@/assets/images/Illustrations/categories/Illustration-garden.svg";
import HOME_ICON from "@/assets/images/Illustrations/categories/Illustration-home.svg";
import MAINTENANCE_ICON from "@/assets/images/Illustrations/categories/Illustration-tools.svg";
import OTHERS_ICON from "@/assets/images/Illustrations/categories/Illustration-others.svg";
import PARTIES_ICON from "@/assets/images/Illustrations/categories/Illustration-party.svg";
import PETS_ICON from "@/assets/images/Illustrations/categories/Illustration-pets.svg";
import PROFESSIONALS_ICON from "@/assets/images/Illustrations/categories/Illustration-profesionals.svg";
import SERVICES_ICON from "@/assets/images/Illustrations/categories/Illustration-services.svg";
import SPACES_ICON from "@/assets/images/Illustrations/categories/Illustration-place.svg";
import SPORTS_ICON from "@/assets/images/Illustrations/categories/Illustration-sports.svg";
import TOURISM_ICON from "@/assets/images/Illustrations/categories/Illustration-plane.svg";
import TRANSPORT_ICON from "@/assets/images/Illustrations/categories/Illustration-shipping.svg";
const QR_ICON = OTHERS_ICON;

export const BEAUTY = {
    name: "BEAUTY",
    icon: BEAUTY_ICON,
    label: "organizaciones.category.options.BEAUTY"
};
export const CLOTHES = {
    name: "CLOTHES",
    icon: CLOTHES_ICON,
    label: "organizaciones.category.options.CLOTHES"
};
export const EDUCATION = {
    name: "EDUCATION",
    icon: EDUCATION_ICON,
    label: "organizaciones.category.options.EDUCATION"
};
export const FOOD = {
    name: "FOOD",
    icon: FOOD_ICON,
    label: "organizaciones.category.options.FOOD"
};
export const GARDENING = {
    name: "GARDENING",
    icon: GARDENING_ICON,
    label: "organizaciones.category.options.GARDENING"
};
export const HOME = {
    name: "HOME",
    icon: HOME_ICON,
    label: "organizaciones.category.options.HOME"
};
export const MAINTENANCE = {
    name: "MAINTENANCE",
    icon: MAINTENANCE_ICON,
    label: "organizaciones.category.options.MAINTENANCE"
};
export const OTHERS = {
    name: "OTHERS",
    icon: OTHERS_ICON,
    label: "organizaciones.category.options.OTHER"
};
export const PARTIES = {
    name: "PARTIES",
    icon: PARTIES_ICON,
    label: "organizaciones.category.options.PARTIES"
};
export const PETS = {
    name: "PETS",
    icon: PETS_ICON,
    label: "organizaciones.category.options.PETS"
};
export const PROFESSIONALS = {
    name: "PROFESSIONALS",
    icon: PROFESSIONALS_ICON,
    label: "organizaciones.category.options.PROFESSIONALS"
};
export const SERVICES = {
    name: "SERVICES",
    icon: SERVICES_ICON,
    label: "organizaciones.category.options.SERVICES"
};
export const GENERAL_SERVICES = {
    name: "GENERAL_SERVICES",
    icon: SERVICES_ICON,
    label: "organizaciones.category.options.GENERAL_SERVICES"
};
export const SPACES = {
    name: "SPACES",
    icon: SPACES_ICON,
    label: "organizaciones.category.options.SPACES"
};
export const SPORTS = {
    name: "SPORTS",
    icon: SPORTS_ICON,
    label: "organizaciones.category.options.SPORTS"
};
export const TOURISM = {
    name: "TOURISM",
    icon: TOURISM_ICON,
    label: "organizaciones.category.options.TOURISM"
};
export const TRANSPORT = {
    name: "TRANSPORT",
    icon: TRANSPORT_ICON,
    label: "organizaciones.category.options.TRANSPORT"
};
export const QR = {
    name: "QR",
    icon: QR_ICON,
    label: "organizaciones.category.options.QR"
};
export const values = [
    BEAUTY,
    CLOTHES,
    EDUCATION,
    FOOD,
    GARDENING,
    HOME,
    MAINTENANCE,
    OTHERS,
    PARTIES,
    PETS,
    PROFESSIONALS,
    GENERAL_SERVICES,
    SPACES,
    SPORTS,
    TOURISM,
    TRANSPORT,
];

export const allValues = [
    BEAUTY,
    CLOTHES,
    EDUCATION,
    FOOD,
    GARDENING,
    HOME,
    MAINTENANCE,
    OTHERS,
    PARTIES,
    PETS,
    PROFESSIONALS,
    GENERAL_SERVICES,
    SERVICES,
    SPACES,
    SPORTS,
    TOURISM,
    TRANSPORT
];
