<template>
    <span :class="clazz">
        <label>{{title}}</label>
        <p>
            <slot></slot>
        </p>
        <div v-if="help" class="help-icon">
            <slot name="helpContent"></slot>
        </div>
    </span>
</template>

<script>
    export default {
        name: 'PanelMiniInfo',
        props: {
            clazz: String,
            title: String,
            help: {type: Boolean, default: false}
        }
    }
</script>

<style lang="scss">
    .panel-mini-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;//center;
        flex-wrap: wrap;
        width: calc(100% + 30px);
        margin: 0 -15px;

        span {
            border-radius: 6px;
            padding: 20px;
            max-width: calc(33.33% - 30px);
            width: 100%;
            min-width: calc(180px + 30px);
            height: fit-content;
            min-height: 100px;
            position: relative;
            display: inline-block;
            margin: 15px;

            label {
                font-size: 16px;
                font-weight: 800;
                line-height: 1.25;
                text-align: center;
                display: block;
                margin: 0;
                text-transform: uppercase;
            }

            p {
                font-size: 32px;
                font-weight: 900;
                line-height: 1.22;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
            }

            .input-comision-bancaria {
                height: 38px;
                width: 70px;
                border-radius: 2px;
                font-size: 32px;
                font-weight: 900;
                line-height: 1.22;
                text-align: center;
                padding: 0 8px;
                margin: -5px 6px 0 0;
            }

            .help-icon {
                position: absolute;
                top: 7px;
                right: 7px;
                font-size: 16px;
            }
        }
    }
</style>
