import StatementsTabs from '@/views/statements/StatementsTabs';

export default [
    {
        path: "/",
        name: "StatementsTabs",
        component: StatementsTabs,
        meta: {permission: 'STATEMENTS_READ',}
    },
];
