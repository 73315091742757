<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout :msgSmall="$t('tax-receipts.title')" :title="$t('tax-receipts.tax-receipts-title')"/>
        </div>

        <div class="col-12">
            <SearchBar
                    :columValues="fields"
                    :canCreate="hideCreate"
                    clazzFilter="m-r-0"
                    v-on:changeListLimit="changeListLimit"
                    v-on:searchTextChange="searchTextChange"
            ></SearchBar>
        </div>

        <div class="col-12">
            <CardSimple v-if="totalDocs <= 0">
                <EmptyState
                        emptyStateDefault
                        :imgUrl="ImagenEmptyState"
                        :title="$t('tax-receipts.no-tax-receipts-title')"
                        :text="$t('tax-receipts.no-tax-receipts-text')"
                ></EmptyState>
            </CardSimple>
            <CardSimple v-if="loadingTaxReceipts">
                <EmptyState
                        emptyStateDefault
                        :imgUrl="ImagenEmptyState"
                        :title="$t('tax-receipts.searching-tax-receipts')"
                ></EmptyState>
            </CardSimple>
        </div>

        <div class="col-12">
            <CardSimple clazz="p-0" hideTitle clazzTitle="m-0 d-none" v-if="!loadingTaxReceipts && totalDocs > 0">
                <div id="comprobantesFiscales" class="basic-table">
                    <b-table-simple
                            responsive
                            ref="selectableTable"
                            selectable
                            selected-variant="active"
                            table-variant="xl">

                        <b-thead>
                            <b-tr class="">
                                <b-th sortable v-show="fields[0].value">
                                    <div class="vertical-center">
                                        <span class="open-filters" @click="headerFilters()"> {{$t('tax-receipts.month-title-column')}} </span>
                                        <SortIcon
                                                @sortUp="setSortTaxReceipt('month', 'asc')"
                                                @sortDown="setSortTaxReceipt('month', 'desc')"
                                        ></SortIcon>
                                    </div>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicSelect clazz="m-b-0">
                                            <multiselect
                                                    v-model="valueType"
                                                    :options="optionsMonth"
                                                    :close-on-select="true"
                                                    :preserve-search="true"
                                                    :placeholder="$t('tax-receipts.filter-month')"
                                                    label="name"
                                                    track-by="name"
                                                    :preselect-first="false"
                                                    @input="setFilterMonthTaxReceipt">
                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single"
                                                          v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="fields[1].value">
                                    <span class="open-filters" @click="headerFilters()"> {{$t('tax-receipts.year-title-column')}} </span>
                                    <SortIcon
                                            @sortUp="setSortTaxReceipt('year', 'asc')"
                                            @sortDown="setSortTaxReceipt('year', 'desc')"
                                    ></SortIcon>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                labelFor="yearComp"
                                                inputId="yearComp"
                                                inputType="number"
                                                :inputPlaceholder="$t('tax-receipts.filter-year')"
                                                clazz="noLabel m-b-0"
                                                @input="setFilterYearTaxReceipt"></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="fields[2].value">
                                    <span class="open-filters" @click="headerFilters()"> {{$t('tax-receipts.period-title-column')}} </span>
                                    <SortIcon
                                            @sortUp="setSortTaxReceipt('fromDate', 'asc')"
                                            @sortDown="setSortTaxReceipt('fromDate', 'desc')"
                                    ></SortIcon>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                labelFor="periodComp"
                                                inputId="periodComp"
                                                inputType="text"
                                                :inputPlaceholder="$t('tax-receipts.filter-period')"
                                                clazz="noLabel m-b-0"
                                                @input="setFilterPeriodTaxReceipt">
                                        </BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="fields[3].value">
                                    {{$t('tax-receipts.xml-file')}}
                                </b-th>
                                <b-th class="text-align-c" v-show="fields[4].value">
                                    {{$t('tax-receipts.pdf-file')}}
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="taxReceipt in taxReceipts" v-bind:key="taxReceipt._id">
                                <b-td v-show="fields[0].value">
                                    <div class="vertical-center">
                                        <strong class="c-primary_1">
                                            {{changeNumberMonthToTextMonth(taxReceipt.month)}}</strong>
                                    </div>
                                </b-td>
                                <b-td v-show="fields[1].value" class="text-align-c"> {{taxReceipt.year}}</b-td>
                                <b-td v-show="fields[2].value" class="text-align-c">
                                    <strong class="c-primary_1">
                                        {{formatDate(taxReceipt.fromDate)}} - {{formatDate(taxReceipt.toDate)}}
                                    </strong>
                                </b-td>
                                <b-td v-show="fields[3].value" class="text-align-c">
                                    <a type="button" class="button xs button-stroke button-accent1 f-12 m-0-auto"
                                       @click="pathFile(taxReceipt.xml._id, 'xml')">
                                        {{$t('tax-receipts.download-xml')}} </a>
                                </b-td>
                                <b-td v-show="fields[4].value" class="text-align-c">
                                    <a type="button" class="button xs button-stroke button-accent1 f-12 m-0-auto"
                                       @click="pathFile(taxReceipt.pdf._id, 'pdf')">
                                        {{$t('tax-receipts.download-pdf')}} </a>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </CardSimple>
        </div>

        <div class="col-12">
            <div class="pagination-custom">
                <span class="pagination-count"> {{ taxReceipts.length }}
                    {{ taxReceipts.length  > 1 ? $tc('tax-receipts.tax-receipts', 2) : $tc('tax-receipts.tax-receipts', 1) }} </span>
                <b-pagination
                        v-if="!loadingTaxReceipts && totalDocs > 0"
                        aria-controls="my-table"
                        next-text
                        prev-text
                        hide-goto-end-buttons
                        @change="pageChange"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import ImagenEmptyState from '@/assets/images/Emptystates/emptystate-payment-cash.svg';
    import SearchBar from '@/components/SearchBar.vue';
    import SortIcon from '@/components/SortIcon.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import {mapGetters} from 'vuex';
    import {STORE as TaxReceiptsStore} from '@/store/modules/taxreceipts/taxReceipts.types';
    import utils from '@/common/utils';
    import catalog from './../mixins/catalog.mixin';
    import ToastSnotify from '@/mixins/toastSnotify.mixin'
    import base from '@/api/base.api';
    import BasicSelect from '@/components/BasicSelect.vue'
    import Multiselect from 'vue-multiselect'
    import fileApi from "@/api/files/files.api";
    import {STORE as SessionStore, GET_TOKEN} from '@/store/modules/session/session.types';
    import {
        GET_DOCS,
        GET_DOCS_LENGTH,
        GET_CURRENT_PAGE,
        GET_OBJECT_PAGE,
        SET_CURRENT_PAGE,
        SET_PER_PAGE,
        SET_SEARCH,
        GET_LOADING_ITEMS
    } from '@/store/modules/base/catalog.types';


    const catalogTaxReceipts = catalog.configure({
        storeModule: TaxReceiptsStore
    });

    const TEXT_ALIGN_C_CLASS = "text-align-c";

    export default {
        name: "TaxReceipts",
        components: {
            FloatingTitleLayout,
            CardSimple,
            EmptyState,
            SearchBar,
            SortIcon,
            BasicInput,
            BasicSelect,
            Multiselect
        },
        data() {
            return {
                baseUrl: base.baseUrl,
                ImagenEmptyState: ImagenEmptyState,
                hideCreate: false,
                fields: [
                    {
                        key: 'mes',
                        label: this.$i18n.t('tax-receipts.month-title-column'),
                        sortable: true,
                        class: 'text-align-l c-primary_1 principal-font-bold',
                        value: true
                    },
                    {
                        key: 'año',
                        label: this.$i18n.t('tax-receipts.year-title-column'),
                        sortable: true,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                    {
                        key: 'periodo',
                        label: this.$i18n.t('tax-receipts.period-title-column'),
                        sortable: true,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                    {
                        key: 'archivoXML',
                        label: this.$i18n.t('tax-receipts.xml-file'),
                        sortable: false,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                    {
                        key: 'archivoPDF',
                        label: this.$i18n.t('tax-receipts.pdf-file'),
                        sortable: false,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    }
                ],
                filtersHeader: false,
                opens: 'left',
                showDropdowns: true,
                autoApply: true,
                dateRange: [],
                searchText: "",
                optionsMonth: [
                    {name: this.$i18n.t('tax-receipts.no-filter'), value: 'NO_FILTER'},
                    {name: this.$i18n.t('tax-receipts.january'), value: '0'},
                    {name: this.$i18n.t('tax-receipts.february'), value: '1'},
                    {name: this.$i18n.t('tax-receipts.march'), value: '2'},
                    {name: this.$i18n.t('tax-receipts.april'), value: '3'},
                    {name: this.$i18n.t('tax-receipts.may'), value: '4'},
                    {name: this.$i18n.t('tax-receipts.june'), value: '5'},
                    {name: this.$i18n.t('tax-receipts.july'), value: '6'},
                    {name: this.$i18n.t('tax-receipts.august'), value: '7'},
                    {name: this.$i18n.t('tax-receipts.september'), value: '8'},
                    {name: this.$i18n.t('tax-receipts.october'), value: '9'},
                    {name: this.$i18n.t('tax-receipts.november'), value: '10'},
                    {name: this.$i18n.t('tax-receipts.december'), value: '11'},
                ],
                valueType: []
            }
        },
        mixins: [catalogTaxReceipts, ToastSnotify],
        methods: {
            setFilterPeriodTaxReceipt(fromDate) {
                if (this.searchTimeout){
                    clearTimeout(this.searchTimeout);
                }
                const that = this;
                this.searchTimeout = setTimeout(function () {
                    that.$store.dispatch(`${TaxReceiptsStore}/setFilterTaxReceipt`, {
                        fromDate
                    });
                }, 500);
            },
            setFilterMonthTaxReceipt(month) {
                if (month.value === "NO_FILTER") {
                    this.$store.dispatch(`${TaxReceiptsStore}/clearFilterTaxReceipt`, {
                        month: true
                    });
                } else {
                    this.$store.dispatch(`${TaxReceiptsStore}/setFilterTaxReceipt`, {
                        month: month.value
                    });
                }
            },
            setFilterYearTaxReceipt(year) {
                if (this.searchTimeout){
                    clearTimeout(this.searchTimeout);
                }
                const that = this;
                this.searchTimeout = setTimeout(function () {
                    that.$store.dispatch(`${TaxReceiptsStore}/setFilterTaxReceipt`, {
                        year
                    });
                }, 500);
            },
            setSortTaxReceipt(sort, typeSort) {
                this.$store.dispatch(`${TaxReceiptsStore}/setSortTaxReceipt`, {
                    sort,
                    typeSort
                });
            },
            changeNumberMonthToTextMonth(inputMonth) {
                const months = [this.$i18n.t('tax-receipts.january'),
                    this.$i18n.t('tax-receipts.february'),
                    this.$i18n.t('tax-receipts.march'),
                    this.$i18n.t('tax-receipts.april'),
                    this.$i18n.t('tax-receipts.may'),
                    this.$i18n.t('tax-receipts.june'),
                    this.$i18n.t('tax-receipts.july'),
                    this.$i18n.t('tax-receipts.august'),
                    this.$i18n.t('tax-receipts.september'),
                    this.$i18n.t('tax-receipts.october'),
                    this.$i18n.t('tax-receipts.november'),
                    this.$i18n.t('tax-receipts.december')];
                return months[inputMonth];
            },
            formatDate(inputDate) {
                if (utils.isDefined(inputDate)) {
                    return utils.toFormatDate(inputDate, "DD/MM/YYYY");
                }
                return "Loading..."
            },
            headerFilters() {
                if (this.filtersHeader) {
                    this.$store.dispatch(`${TaxReceiptsStore}/resetFilterTaxReceipt`);
                }
                this.filtersHeader = !this.filtersHeader;
            },
            showDropdown() {
                document.getElementById("comprobantesFiscales").classList.add('dropdown-table-overflow');
            },
            hideDropdown() {
                document.getElementById("comprobantesFiscales").classList.remove('dropdown-table-overflow');
            },
            pageChange(page) {
                this.$store.commit(`${TaxReceiptsStore}/${SET_CURRENT_PAGE}`, page);
                this.$store.dispatch(`${TaxReceiptsStore}/list`);
            },
            changeListLimit(limit) {
                utils.debounceFixed(() => {
                    if (limit <= 0) {
                        this.displayNotificationWarning(this.$i18n.t('general.list-limit-warn'));
                        this.$store.commit(`${TaxReceiptsStore}/${SET_PER_PAGE}`, 10)
                        this.$store.dispatch(`${TaxReceiptsStore}/list`)
                    } else {
                        this.$store.commit(`${TaxReceiptsStore}/${SET_PER_PAGE}`, limit)
                        this.$store.dispatch(`${TaxReceiptsStore}/list`)
                    }
                })
            },
            searchTextChange(searchText) {
                utils.debounceFixed(() => {
                    this.searchText = searchText;
                    this.$store.commit(`${TaxReceiptsStore}/${SET_SEARCH}`, searchText)
                    this.$store.dispatch(`${TaxReceiptsStore}/list`)
                })
            },
            pathFile(id) {
                if (id !== undefined) {
                    fileApi.getSignedUrlFile({id: id}, (response) => {
                        if (response.data.error) {
                            this.displayNotificationInfo(this.$i18n.t('signup-organization.step-contract.print-error'));
                        } else {
                            if (response.data.object !== undefined) {
                                const link = document.createElement("a");
                                const url = response.data.object;
                                const name = "Contrato"
                                link.setAttribute("href", url);
                                link.setAttribute("download", name);
                                link.click();
                            }
                        }
                    }, () => {
                        this.displayNotificationInfo(this.$i18n.t('signup-organization.step-contract.print-error'));
                    });
                }
            }
        },
        computed: {
            ...mapGetters(TaxReceiptsStore, {
                taxReceipts: GET_DOCS,
                totalDocs: GET_DOCS_LENGTH,
                currentPage: GET_CURRENT_PAGE,
                perPage: GET_OBJECT_PAGE,
                loadingTaxReceipts: GET_LOADING_ITEMS
            }),
            ...mapGetters(SessionStore, {
                token: GET_TOKEN,
            }),
        }
    }
</script>

