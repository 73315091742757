import paymentsTemplateApi from '@/api/paymentstemplate/paymentTemplate.api';
import catalog from '@/store/modules/base/catalog.store';
import {EventBus} from "@/main";
import i18n from '@/plugins/i18n';

import {
    STORE as PaymentTemplateStore,
    GET_ACTUAL_ITEM,
    SET_TAGS,
    SET_VALIDS,
    GET_VALIDS,
    GET_FILTERS_COUNT,
    SET_RECORDS,
    GET_RECORDS,
    XLS_SUCCESS,
    SET_LOADING_PROCESS_TEMPLATE,
    GET_LOADING_PROCESS_TEMPLATE,
    GET_PAYMENT_INFO,
    GET_PAYMENT_VALID,
    GET_PAYMENT_DEBTS,
    GET_DEBT_SELECTED,
    GET_DEBTS_LENGTH,
    GET_DEBTS_CURRENT_PAGE,
    GET_DEBTS_PER_PAGE,
    GET_LOADING_DETAIL

} from './paymentsTemplate.types';
import {
    GET_CURRENT_PAGE, GET_FILTERS,
    GET_OBJECT_PAGE,
    GET_SEARCH_TEXT,
    GET_SORT, GET_TYPE_SORT,
    SET_DOCS, SET_DOCS_LENGTH,
    SET_ERROR_LOADING,
    SET_LOADING_ITEMS
} from "../base/catalog.types";
import {
    SUCCESS_DELETE,
    WARNING_EVENT,
    ERROR_DELETING,
    ERROR_LOADING
} from '@/store/events';

const paymentsTemplateCatalog = catalog(paymentsTemplateApi, PaymentTemplateStore);
const storeName= PaymentTemplateStore;
const DELETE_ERROR_I18N_KEY = "general.delete.error";

// Objetos
const stateGlobal = {
    actualPaymentTemplate: {},
    tags: {},
    valids: 0,
    records: 0,
    loadingProcessTemplate: false,
    paymentSelected: {
        payment: {},
        isValid: true,
        debts: {}
    },
    isValid: true, // Si es valido el payment pending seleccionado.
    debtSelected: {},
    loadingDetail: false
};

// Métodos para obtener valores del state. Se les llama con getters
const gettersGlobal = {
    [GET_ACTUAL_ITEM]: (state) => state.actualPaymentTemplate,
    [GET_VALIDS]: (state) => state.valids,
    [GET_FILTERS_COUNT]: (state) => {
        let filtersCounted = 0;
        let iterator;
        for (iterator in state.filters) {
            if (state.filters.hasOwnProperty(iterator)) {
                filtersCounted++;
            }
        }
        return filtersCounted;
    },
    [GET_RECORDS]: (state) => state.records,
    [GET_LOADING_PROCESS_TEMPLATE]: (state) => state.loadingProcessTemplate,
    [GET_PAYMENT_INFO]: (state) => state.paymentSelected.payment,
    [GET_PAYMENT_VALID]: (state) => state.paymentSelected.isValid,
    [GET_PAYMENT_DEBTS]: (state) => state.paymentSelected.debts.docs,
    [GET_DEBTS_LENGTH]: (state) => state.paymentSelected.debts.totalDocs,
    [GET_DEBTS_CURRENT_PAGE]: (state) => state.paymentSelected.debts.page,
    [GET_DEBTS_PER_PAGE]: (state) => state.paymentSelected.debts.limit,
    [GET_LOADING_DETAIL]: (state) => state.loadingDetail,
    [GET_DEBT_SELECTED]: (state) => state.debtSelected
};

// Métodos síntronos que afectan el state. Se llaman con commit
const mutations = {
    SET_TAGS: (state, tags) =>{
        state.tags = tags;
    },
    SET_VALIDS: (state, valids) =>{
        state.valids = valids;
    },
    SET_RECORDS: (state, records) =>{
        state.records = records;
    },
    CLEAR_SELECTED_IDS: (state) =>{
        state.selectedIds= []
    },
    SET_LOADING_PROCESS_TEMPLATE: (state, value) =>{
        state.loadingProcessTemplate= value;
    },
    SET_PAYMENT_SELECTED: (state, payment) =>{
        state.paymentSelected= payment;
    },
    SET_PAYMENT_VALID: (state, isValid) =>{
        state.isValid= isValid;
    },
    SET_DEBT_SELECTED: (state, debt) =>{
        state.debtSelected= debt;
    },
    SET_LOADING_DETAIL: (state, value) =>{
        state.loadingDetail= value;
    },

};

const actions = {
    listPaymentsTemplate({commit, getters}){
        if (getters.getIsErrorLoading) {
            commit(SET_LOADING_ITEMS, true);
        }

        commit(SET_ERROR_LOADING,false);
        paymentsTemplateApi.list({
            currentPage: getters[GET_CURRENT_PAGE],
            perPage: getters[GET_OBJECT_PAGE],
            search: getters[GET_SEARCH_TEXT],
            filters: getters[GET_FILTERS],
            sort: getters[GET_SORT],
            typeSort: getters[GET_TYPE_SORT]
        }, (result)=>{
            if(result.data.error === true){
                EventBus.$emit(PaymentTemplateStore + ERROR_LOADING, "Ocurrio un problema al obtener el listado de la plantilla de pagos.")
            } else {
                const docs = result.data.object.docs;
                const finalDocs = docs.map(doc =>{
                    doc.selectedItem = false;
                    return doc;
                })

                const lengthDocs = result.data.object.totalDocs;
                commit(SET_DOCS, finalDocs);
                commit(SET_DOCS_LENGTH, lengthDocs);
                commit(SET_RECORDS, result.data.object.records);
                commit(SET_TAGS, result.data.object.tags);
                commit(SET_VALIDS, result.data.object.valids);
                commit(SET_LOADING_ITEMS, false);
            }
        }, () => {
            commit(SET_DOCS, []);
            commit(SET_DOCS_LENGTH, 0);
            commit(SET_RECORDS, 0);
            commit(SET_ERROR_LOADING,true);
            commit(SET_VALIDS, 0);
            commit(SET_LOADING_ITEMS, false);
            EventBus.$emit(PaymentTemplateStore + ERROR_LOADING, "Ocurrio un problema al obtener el listado de la plantilla de pagos.")
        });
    },


    resetFilterPaymentTemplate({state, commit, dispatch}) {
        if ((Object.keys(state.filters)).length > 0) {
            commit("SET_FILTERS", {})
            dispatch("listPaymentsTemplate")
        }
    },
    setFilterPaymentTemplate({state, commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const filters = JSON.parse(JSON.stringify(state.filters));
            if (typeof params.name !== "undefined"){
                filters.name = params.name
            }
            if (typeof params.type !== "undefined"){
                filters.type = params.type
            }
            if (typeof params.detail !== "undefined"){
                filters.detail = params.detail
            }
            if (typeof params.statusService !== "undefined"){
                filters.statusService = params.statusService
            }
            if (typeof params.statusPayment !== "undefined"){
                filters.statusPayment = params.statusPayment
            }
            commit("SET_FILTERS", filters);
            dispatch("listPaymentsTemplate", filters);
        }
    },
    clearFilterPaymentTemplate({state, commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const filters = JSON.parse(JSON.stringify(state.filters))
            if (typeof params.name !== "undefined"){
                delete filters.name;
            }
            if (typeof params.type !== "undefined"){
                delete filters.type;
            }
            if (typeof params.detail !== "undefined"){
                delete filters.detail;
            }
            if (typeof params.statusService !== "undefined"){
                delete filters.statusService;
            }
            if (typeof params.statusPayment !== "undefined"){
                delete filters.statusPayment;
            }
            commit("SET_FILTERS", filters);
            dispatch("listPaymentsTemplate", filters);
        }
    },
    setSortPaymentTemplate({commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const sort = `${ params.sort}`;
            const typeSort = `${ params.typeSort}`;
            commit("SET_SORT", sort);
            commit("SET_TYPE_SORT", typeSort);
            dispatch("listPaymentsTemplate");
        }
    },
    /* eslint-enable */

    uploadXlsFile({commit, dispatch}, params){
        commit(SET_LOADING_PROCESS_TEMPLATE, true);
        paymentsTemplateApi.uploadXlsFile(params,
            function(response){
                if(response.data && response.data.error){
                    commit(SET_LOADING_PROCESS_TEMPLATE, false);
                    EventBus.$emit(storeName + WARNING_EVENT, response.data.message);
                }else{
                    EventBus.$emit(storeName + XLS_SUCCESS, "Se ha procesado correctamente el archivo.");
                    commit(SET_LOADING_PROCESS_TEMPLATE, false);
                    dispatch("listPaymentsTemplate");
                }

            },() => {
                EventBus.$emit(storeName + WARNING_EVENT, "Ha ocurrido un error inesperado al intentar procesar el archivo. Por favor revise su archivo e inténtelo nuevamente.")
            })
    },

    confirmPayment({dispatch}, params){
        paymentsTemplateApi.confirmPayment(params,
            function(response){
                if(response.data && response.data.error){
                    EventBus.$emit(storeName + WARNING_EVENT, response.data.message)
                }
                dispatch("listPaymentsTemplate");

            },() => {
                EventBus.$emit(storeName + WARNING_EVENT, "Ha ocurrido un error inesperado al intentar confirmar el pago.")
            })
    },

    confirmPaymentList({dispatch}, params){

        paymentsTemplateApi.confirmListPayments({elementsToSave: params},
            // eslint-disable-next-line sonarjs/no-identical-functions
            function(response){
                if(response.data && response.data.error){
                    EventBus.$emit(storeName + WARNING_EVENT, response.data.message)
                }
                dispatch("listPaymentsTemplate");

            },() => {
                EventBus.$emit(storeName + WARNING_EVENT, "Ha ocurrido un error inesperado al intentar confirmar los pagos.")
            })
    },
    confirmAllPayments({dispatch}){
        paymentsTemplateApi.confirmAllPayments({},
            // eslint-disable-next-line sonarjs/no-identical-functions
            function(response){
                if(response.data && response.data.error){
                    EventBus.$emit(storeName + WARNING_EVENT, response.data.message)
                }
                dispatch("listPaymentsTemplate");

            },() => {
                EventBus.$emit(storeName + WARNING_EVENT, "Ha ocurrido un error inesperado al intentar confirmar los pagos.")
            })
    },

    deletePaymentPending({dispatch}, payment){
        const objectsIds= [];

        for (let i = 0; i < payment.payments.length; i++){
            objectsIds.push(payment.payments[i]._id);
        }

        paymentsTemplateApi.delete({
            id: objectsIds
        }, (result)=>{
            if(result.data.error === true){
                EventBus.$emit(storeName + WARNING_EVENT, result.data.message)
            } else{
                EventBus.$emit(storeName + SUCCESS_DELETE, result.data.message)
                dispatch("listPaymentsTemplate");
            }
        }, () => {
            EventBus.$emit(storeName + ERROR_DELETING, i18n.t(DELETE_ERROR_I18N_KEY))
        });
    },

    // Para los que han sido seleccionados en la tabla
    deleteManyPayments({dispatch}, objectsIds){
        paymentsTemplateApi.delete({
            id: objectsIds
            // eslint-disable-next-line sonarjs/no-identical-functions
        }, (result)=>{
            if(result.data.error === true){
                EventBus.$emit(storeName + WARNING_EVENT, result.data.message)
            } else{
                EventBus.$emit(storeName + SUCCESS_DELETE, result.data.message)
                dispatch("listPaymentsTemplate");
            }
        }, () => {
            EventBus.$emit(storeName + ERROR_DELETING, i18n.t(DELETE_ERROR_I18N_KEY))
        });
    },

    deleteAllPayments({dispatch}){

        // eslint-disable-next-line sonarjs/no-identical-functions
        paymentsTemplateApi.deleteAll({}, (result)=>{
            if(result.data.error === true){
                EventBus.$emit(storeName + WARNING_EVENT, result.data.message)
            } else{
                EventBus.$emit(storeName + SUCCESS_DELETE, result.data.message)
                dispatch("listPaymentsTemplate");
            }
        }, () => {
            EventBus.$emit(storeName + ERROR_DELETING, i18n.t(DELETE_ERROR_I18N_KEY))
        });
    },
    /* eslint-disable */
    downloadTemplate(){
        paymentsTemplateApi.downloadTemplate({},
            function (response) {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                }))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "layout-pagando-pagos.xlsx");
                document.body.appendChild(link);
                link.click();

            }, function () {
                EventBus.$emit(`coupons.campaign.wizard.detail.error`, "Ha ocurrido un error inesperado");
            }
        );
    },
    getDetailRegularPayment(context, findObject){
        return new Promise((resolve, reject)=>{
            paymentsTemplateApi.getDetailRegularPayment(findObject, (result)=>{

                if(result.data.error === true){
                    return reject(result.data.object);
                }

                return resolve(result.data);

            }, (error) => {
                return reject(error);
            });
        });


    }

};

export default {
    namespaced: true,
    state: {
        ...stateGlobal,
        ...paymentsTemplateCatalog.state
    },
    getters: {
        ...gettersGlobal,
        ...paymentsTemplateCatalog.getters
    },
    actions: {
        ...actions,
        ...paymentsTemplateCatalog.actions
    },
    mutations: {
        ...mutations,
        ...paymentsTemplateCatalog.mutations
    }
};
