import apiConfigurationApi from '@/api/apiWeb/apiConfigurationApi.api';
import PaymentsCRUDApi from '@/api/payments/crud.api.js';
import {EventBus} from "@/main";

const stateGlobal = {
    configurationStep: 'DOMAINS_CONFIGURATION',
    domainName: '',
    originRoute: '',
    responseRoute: '',
    domainsList: [""],
    apiTestKey: '',
    apiLiveKey: '',
    apiUserLive: '',
    apiUserTest: '',
    testsInfoArray: [],
    keyValueArray: [],
    checkout: '',
    domain: '',
    returnLink: '',
    confirmationLink: '',
    guestForm: null,
    userForm: null,
    pagandoEmail: false,
    testsVerification: false,
    apiKeysEnabled: false,
    cypherKey: '',
    aesKey: '',
    iv: '',
};

const gettersGlobal = {
    getConfigurationStep: (stateObject) => {
        return stateObject.configurationStep;
    },
    getDomainName: (stateObject) => {
        return stateObject.domainName;
    },
    getOriginRoute: (stateObject) => {
        return stateObject.originRoute;
    },
    getResponseRoute: (stateObject) => {
        return stateObject.responseRoute;
    },
    getDomainsList: (stateObject) => {
        return stateObject.domainsList;
    },
    getApiTestKey: (stateObject) => {
        return stateObject.apiTestKey;
    },
    getApiLiveKey: (stateObject) => {
        return stateObject.apiLiveKey;
    },
    getApiUserLive: (stateObject) => {
        return stateObject.apiUserLive;
    },
    getApiUserTest: (stateObject) => {
        return stateObject.apiUserTest;
    },
    getTestsInfo: (stateObject) => {
        return stateObject.testsInfoArray;
    },
    getKeyValueArray: (stateObject) => {
        return stateObject.keyValueArray;
    },
    getCheckout: (stateObject) => {
        return stateObject.checkout;
    },
    getReturnLink: (stateObject) => {
        return stateObject.returnLink;
    },
    getDomain: (stateObject) => {
        return stateObject.domain;
    },
    getConfirmationLink: (stateObject) => {
        return stateObject.confirmationLink;
    },
    getGuestForm: (stateObject) => {
        return stateObject.guestForm;
    },
    getUserForm: (stateObject) => {
        return stateObject.userForm;
    },
    getPagandoEmail: (stateObject) => {
        return stateObject.pagandoEmail;
    },
    getTestVerification: (stateObject) => {
        return stateObject.testsVerification;
    },
    getApiKeysEnabled: (stateObject) => {
        return stateObject.apiKeysEnabled;
    },
    getCypherKey: (stateObject) => {
        return stateObject.cypherKey;
    },
    getAesKey: (stateObject) => {
        return stateObject.aesKey;
    },
    getIV: (stateObject) => {
        return stateObject.iv;
    },
};

const actions = {
    /*
    This function gets all general info for api configuration as process step and domains info
     */
    async getOrganizationApiInfo({commit}, data){
        try {
            const result = await apiConfigurationApi.getApiConfigurationInfo(data);
            if(result && result.data && result.data.object) {
                commit('SET_API_CONFIGURATION_INFO', result.data.object);
                EventBus.$emit("apiConfigurationInfoLoaded");
            } else {
                commit('SET_API_CONFIGURATION_INFO', {});
            }
        } catch (e) {
            EventBus.$emit("ApiError", 'api-configuration.api-keys.first-step.title');
        }
    },
    /*
    This function gets a summary of test information as:
    - Test name
    - Test status
     */
    async getTestInformation({commit}, data) {
        try {
            const result = await apiConfigurationApi.getApiTestInfo(data);

            if(result && result.data && result.data.object) {
                commit('UPDATE_TEST_INFORMATION', result.data.object);
                EventBus.$emit("testInfoLoadedAR");
                EventBus.$emit("testInfoLoadedAV");
            } else {
                EventBus.$emit("ApiError", 'api-configuration.api-keys.third-step.title');
            }
        } catch (e) {
             EventBus.$emit("ApiError", 'api-configuration.api-keys.third-step.title');
        }
    },
    /*
    This function is used to gets key information and regenerate if it is needed

    NOTE: This key is used for jwt authentication
     */
    async getApiKey({commit, dispatch}, data){
        try {
            const result = await apiConfigurationApi.getApiKey(data);
            if(result && result.data && result.data.object) {
                if(data.type === "Live"){
                    commit('SET_API_LIVE_KEY', result.data.object);
                    EventBus.$emit("liveKeyLoaded");
                    dispatch("_createApiPayment", data.category);
                }else{
                    commit('SET_API_TEST_KEY', result.data.object);
                    EventBus.$emit("testKeyLoaded");
                }
            } else {
                if(data.type === "Live"){
                    commit('SET_API_LIVE_KEY', '');
                    EventBus.$emit("liveKeyLoaded");
                }else{
                    commit('SET_API_TEST_KEY', '');
                    EventBus.$emit("testKeyLoaded");
                }
            }

        } catch (e) {
            if(data.type === "Live"){
                EventBus.$emit("ApiError", 'api-configuration.api-keys.fourth-step.title');
            } else {
                EventBus.$emit("ApiError", 'api-configuration.api-keys.second-step.title');
            }
        }
    },

    async _createApiPayment({getters}, category){
        // Create api payment belonging to client account
        await PaymentsCRUDApi.saveApi("AP",
            {
                acceptsMultipleUnits: false,
                maxUnitsPerTransaction: 1,
                lastTestWasSuccessful: false,
                type: 'EP',
                activeService: true,
                comingSoon: false,
                sanBoxMode: false,
                description: `${getters.getDomainName } - pago`,
                clasification: category,
                subTotal: 0,
                totalTaxes: 0,
                totalDiscounts: 0,
                total: 8,
                totalWithoutCommision: 0,
                additionalNotesConfig: {
                    isEnabled: false,
                    label: '',
                    placeholder: '',
                    isRequired: false,
                },
            }
        ).catch((err) => {
            return {
                data: err.response.data ? err.response.data : {
                    error: true,
                    message: 'express could not create'
                }
            }
        });
    },
    /*
    This function is used to gets user identifier used in jwt authentication
     */
    async getApiUser({commit}, data){
        try {
            const result = await apiConfigurationApi.getApiUser(data);
            if(result && result.data && result.data.object) {
                if(data.type === "Live"){
                    commit('SET_API_USER_LIVE', result.data.object);
                    EventBus.$emit("liveUserLoaded");
                }else{
                    commit('SET_API_USER_TEST', result.data.object);
                    EventBus.$emit("testUserLoaded");
                }
            } else {
                if(data.type === "Live"){
                    commit('SET_API_USER_LIVE', '');
                }else{
                    commit('SET_API_USER_TEST', '');
                }
            }
        } catch (e) {
            if(data.type === "Live"){
                EventBus.$emit("ApiError", 'api-configuration.api-keys.fourth-step.title');
            } else {
                EventBus.$emit("ApiError", 'api-configuration.api-keys.second-step.title');
            }
        }
    },
    /*
    Function that save all form information about domains info
     */
    async saveDomainInformation({commit, state}, data) {
        try {
            const params = {
                domainName: state.domainName,
                originRoute: state.originRoute,
                responseRoute: state.responseRoute,
                domainList: state.domainsList,
                organizationId: data.organization, // Organizacion seleccionada
                user: data.user
            };
            await apiConfigurationApi.saveDomainInformation(params);
            commit('UPDATE_DOMAIN_INFORMATION', params);
            return {error: false, message: "La configuración de la API se guardo correctamente"};
        } catch (e) {
            return {error: false, message: "Error al guardar la configuración de la API"};
        }
    },
    async getIpFromDomain({commit}, data){
        try {
            const params = {domain: data.domain};
            const result = await apiConfigurationApi.getIpFromDomain(params);
            if(result && result.data && result.data.object) {
                commit('UPDATE_DOMAIN_INFO_KEY', {data: {domain: data.domain, ip: result.data.object}, index: data.index});
                return {error: false, data: result.data.object};
            }
            return {error: true, data: `El dominio ${data.domain} no es valido`};
        } catch (e) {
            return {error: true, data: `El dominio ${data.domain} no es valido`};
        }
    },
    /*
    Get the complete information of a test
     */
    async getCurrentTestInformation({commit}, data) {
        try {
            const result = await apiConfigurationApi.getApiCurrentTestInfo(data);

            if (result && result.data && result.data.object) {
                commit('UPDATE_CURRENT_TEST_INFORMATION', result.data.object);
                if (!data.currentStep) {
                    EventBus.$emit('change-step', result.data.object.testNumber);
                }
                EventBus.$emit(`currentTestInfoLoaded`);
            } else {
                EventBus.$emit(`apiErrorTest`);
            }
        } catch (e) {
            EventBus.$emit(`apiErrorTest`);
        }
    },
    async verifyTestInformation({commit}, data) {
        try {
            const result = await apiConfigurationApi.getApiCurrentTestInfo(data);

            if (result && result.data && result.data.object) {
                commit('VERIFY_TEST_INFORMATION', result.data.object);
                EventBus.$emit(`currentTestInfoLoaded`);
            } else {
                EventBus.$emit(`apiErrorTest`);
            }
        } catch (e) {
            EventBus.$emit(`apiErrorTest`);
        }
    },
    async checkApiKeysEnabled({commit}, data) {
        try {
            const result = await apiConfigurationApi.getApiKeysEnabled(data);

            if (result && result.data && result.data.object) {
                commit('SET_API_KEYS_ENABLED', true);
                EventBus.$emit(`checkedApiKeysEnabled`);
            } else {
                commit('SET_API_KEYS_ENABLED', false);
                EventBus.$emit(`checkedApiKeysEnabled`);
            }
        } catch (e) {
            EventBus.$emit(`ApiError`);
        }
    },
    async verifyAllTests({getters}, data){
        try {
            data.checkout = getters.getCheckout;
            const result = await apiConfigurationApi.checkVerificationTests(data);
            if (result && result.data && result.data.object && result.data.object.updated) {
                EventBus.$emit(`verificationTestsDone`);
            }else{
                EventBus.$emit(`verificationTestsPending`);
            }
        } catch (e) {
            EventBus.$emit(`verificationTestsError`);
        }
    },
    async getTestsVerification({getters, commit}){
        const testNotAproved = getters.getTestsInfo.filter(test => test.testStatus !== "APPROVED");
        commit('SET_TESTS_VERIFICATION', testNotAproved.length ? "PENDENT" : "APPROVED");
    },
    // eslint-disable-next-line no-empty-pattern
    async saveKeyProductionHistory({}, data) {

        const response= await apiConfigurationApi.saveKeyProductionHistory(data);
        if(response.data.error){
            // TODO:ERROR
        }

        return response;
    },
};

const mutations = {
    DELETE_DOMAIN(state, idx){
        state.domainsList.splice(idx, 1);
    },
    ADD_EMPTY_DOMAIN(state){
      state.domainsList.push({domain: "", ip: ""});
    },
    UPDATE_DOMAIN_INFO_KEY(state, data){
        state.domainsList.splice(data.index, 1, data.data);
    },
    SET_API_TEST_KEY(state, apiInfo){
        if (apiInfo === ''){
            state.apiTestKey = apiInfo;
        } else {
            state.apiTestKey = apiInfo.key;
            state.configurationStep = apiInfo.step;
        }
    },
    SET_API_LIVE_KEY(state, apiInfo){
        if (apiInfo === ''){
            state.apiLiveKey = apiInfo;
        } else {
            state.apiLiveKey = apiInfo.key;
            state.configurationStep = apiInfo.step;
        }
    },
    SET_API_USER_LIVE(state, apiUser){
        state.apiUserLive = apiUser;
    },
    SET_API_USER_TEST(state, apiUser){
        state.apiUserTest = apiUser;
    },
    SET_API_CONFIGURATION_INFO(stateObject, apiConfInfo){
        if(apiConfInfo.step){
            stateObject.configurationStep = apiConfInfo.step;
        }
        if(apiConfInfo.domainName){
            stateObject.domainName = apiConfInfo.domainName;
        }
        if(apiConfInfo.originRoute){
            stateObject.originRoute = apiConfInfo.originRoute;
        }
        if(apiConfInfo.responseRoute){
            stateObject.responseRoute = apiConfInfo.responseRoute;
        }
        if(apiConfInfo.domains){
            stateObject.domainsList = apiConfInfo.domains;
        }
        if(apiConfInfo.checkout){
            stateObject.checkout = apiConfInfo.checkout;
        }
        if(apiConfInfo.domain){
            stateObject.domain = apiConfInfo.domain;
        }
        if(apiConfInfo.returnLink){
            stateObject.returnLink = apiConfInfo.returnLink;
        }
        if(apiConfInfo.confirmationLink){
            stateObject.confirmationLink = apiConfInfo.confirmationLink;
        }
        if(apiConfInfo.guestForm){
            stateObject.guestForm = (apiConfInfo.guestForm === 'true');
        }
        if(apiConfInfo.userForm){
            stateObject.userForm = (apiConfInfo.userForm === 'true');
        }
        if(apiConfInfo.pagandoEmail){
            stateObject.pagandoEmail = apiConfInfo.pagandoEmail;
        }
        if(apiConfInfo.cypherKey){
            stateObject.cypherKey = apiConfInfo.cypherKey;
        }
        if(apiConfInfo.aesKey){
            stateObject.aesKey = apiConfInfo.aesKey;
        }
        if(apiConfInfo.ivKey){
            stateObject.iv = apiConfInfo.ivKey;
        }
    },
    UPDATE_DOMAIN_INFORMATION(stateObject, domainInfo){
        stateObject.domainName = domainInfo.domainName;
        stateObject.originRoute = domainInfo.originRoute;
        stateObject.responseRoute = domainInfo.responseRoute;
        stateObject.domainsList = domainInfo.domainList;
    },
    UPDATE_TEST_INFORMATION(stateObject, testsInfo){
        stateObject.testsInfoArray = testsInfo;
        stateObject.testsInfoArray.forEach(function(test){
            stateObject.keyValueArray.push(`TEST ${test.testNumber} ${Date.now()}`);
        });
        EventBus.$emit(`current-step`);
        EventBus.$emit(`test`);
    },
    UPDATE_CURRENT_TEST_INFORMATION(stateObject, testsInfo){
        stateObject.testsInfoArray[testsInfo.testNumber - 1] = testsInfo;
        stateObject.keyValueArray[testsInfo.testNumber - 1] = `TEST ${testsInfo.testNumber} ${Date.now()}`;
        EventBus.$emit(`test`);
    },
    VERIFY_TEST_INFORMATION(stateObject, testsInfo){
        stateObject.testsInfoArray[testsInfo.testNumber - 1] = testsInfo;
        stateObject.keyValueArray[testsInfo.testNumber - 1] = `TEST ${testsInfo.testNumber} ${Date.now()}`;
        EventBus.$emit(`verify-status`, testsInfo.testStatus);
        EventBus.$emit(`test`);
    },
    SET_DOMAIN_NAME(stateObject, data){
        stateObject.domainName = data;
    },
    SET_ORIGIN_ROUTE(stateObject, data){
        stateObject.originRoute = data;
    },
    SET_RESPONSE_ROUTE(stateObject, data){
        stateObject.responseRoute = data;
    },
    SET_TESTS_VERIFICATION(stateObject, data){
        stateObject.testsVerification = data;
    },
    SET_API_KEYS_ENABLED(stateObject, data){
        stateObject.apiKeysEnabled = data;
    }
};



export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
