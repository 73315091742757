<template>
    <div class="login-container" v-bind:class="clazz">
        <div class="body-container">
            <div class="left-section">
                <div class="form-elements">
                    <div class="header-content">
                        <div class="w-100 d-flex">
                            <img class="img-fluid" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" alt="Pagando Logo"/>

                            <button id="" class="button-circle sm button-filled button-info m-0-0-0-auto"
                                v-b-modal.modal-info-responsive type="button"><i
                                aria-hidden="true" class="banana banana-question-circle1"></i></button>
                        </div>
                    </div>

                    <div class="body-content">
                        <slot name="formSectionBody"></slot>
                    </div>

                    <div class="footer-content">
                        <p v-if="loginLeftFooter" class="link-to"> {{$t('signin-body.footer-already-have-account1')}}
                            <router-link to="/signin" class="hover" tabindex="">
                                {{$t('signin-body.footer-already-have-account1')}}
                            </router-link>
                        </p>
                        <p v-if="registerLeftFooter" class="link-to"> {{$t('signin-body.footer-no-account-yet1')}}
                            <router-link to="/signin" class="hover" tabindex="">
                                {{$t('signin-body.footer-no-account-yet2')}}
                            </router-link>
                        </p>
                    </div>
                    <div class="footer-content d-none"></div>
                </div>
            </div>

            <div class="info-section">
                <!-- LOGIN / REGISTER SECTION -->
                <div v-if="hideRightWizard">
                    <div class="top-info" v-if="rightSide == 'EASY_PAY'">
                        <h1> {{$t('signin-body.first-section-easypay1')}} </h1>
                        <p> {{$t('signin-body.first-section-easypay2')}} </p>
                        <img class="img-fluid m-0-auto m-b-30"
                             src="@/assets/images/Illustrations/illustration-login.png" alt="Illustration"/>
                    </div>

                    <div class="top-info" v-if="rightSide == 'EMAIL_VERIFY'">
                        <h1> {{$t('signin-body.first-section-emailverify1')}} </h1>
                        <p> {{$t('signin-body.first-section-emailverify2')}} </p>
                        <img class="img-fluid m-0-auto m-b-30"
                             src="@/assets/images/Illustrations/illustration-mailverify.svg" alt="Illustration"/>
                    </div>

                    <div class="top-info" v-if="rightSide == 'DONT_WORRY'">
                        <h1> {{$t('signin-body.first-section-dontworry1')}} </h1>
                        <p> {{$t('signin-body.first-section-dontworry2')}} </p>
                        <img class="img-fluid m-0-auto m-b-30"
                             src="@/assets/images/Illustrations/illustration-mailverify.svg" alt="Illustration"/>
                    </div>

                    <div class="top-info" v-if="rightSide == 'WELCOME_COLLABORATOR'">
                        <h1> {{$t('signin-body.first-section-welcome-collaborator1')}} </h1>
                        <p> {{$t('signin-body.first-section-welcome-collaborator2')}} </p>
                        <img class="img-fluid m-0-auto m-b-30"
                             src="@/assets/images/Illustrations/illustration-mailverify.svg" alt="Illustration"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-container">
            <small class="text-footer"> {{$t('signin-body.final-footer1')}} <strong>
                {{$t('signin-body.final-footer2')}} </strong>
            </small>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SignInBody',
        props: {
            clazz: String,
            // LOGIN / REGISTER SECTION
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false},
            easyPay: {type: Boolean, default: false},
            emailVerify: {type: Boolean, default: false},
            doNotWorry: {type: Boolean, default: false},
            hideRightWizard: {type: Boolean, default: false},
            rightSide: {type: String, default: "STEP_ONE"},
            // WIZARD SECTION
            stepOne: {type: Boolean, default: false},
            stepOneLevelOne: {type: Boolean, default: false},
            stepTwoLevelOne: {type: Boolean, default: false},
            stepThreeLevelOne: {type: Boolean, default: false},
            stepTwo: {type: Boolean, default: false},
            stepTwoForm: {type: Boolean, default: false},
            stepThree: {type: Boolean, default: false},
            stepFour: {type: Boolean, default: false},
            stepFive: {type: Boolean, default: false}
        }
    }
</script>
