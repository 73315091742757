<template>
  <div class="simple-note" v-bind:class="clazz">
    <i v-if="icon" aria-hidden="true" class="icon banana" v-bind:class="icon"></i>
    <span class="note-text">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "SimpleNote",
  props: {
    icon: String,
    clazz: String
  }
};
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
.simple-note {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;

  .icon {
    font-size: 16px;
    margin-right: 8px;
  }

  .note-text {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.38;
  }
}
</style>
