<template>
    <!-- DOCUMENTATION - TopbarHeader for ADMIN CLIENT

        clazz:        Extra class for component (String).
        showSearch:   Value to show Input search for window < 768px (Boolean).
        slot:         Extra content to add list options to the 'right-side'.
    -->
    <head title="" id="header" class="header-bar" v-bind:class="clazz">
        <title></title>
        <div class="left-side">
            <a class="show-resp" @click="showFixedSearch"><i
                    aria-hidden="true" class="banana banana-search-normal f-30 c-primary_1"></i></a>
            <div class="show-resp fixed-search" v-bind:class="{ 'show' : showSearch }">
                <BasicInput inputType="text" :inputPlaceholder="$t('side-bar-menu.search-placeholder')"
                            inputClazz="icon-left" iconInput="banana-search-normal" clazz="no-border"/>
                <a class="hover p-t-5" @click="hideFixedSearch"><i
                        aria-hidden="true" class="banana banana-cross-normal f-22 c-primary_1"></i></a>
            </div>
        </div>
        <ul class="right-side">
            <li>
                <b-dropdown id="dropdown-add" right class="dropdown-user" toggle-class="user-menu">
                    <template v-slot:button-content>
                        <img class="img-fluid" :src="user.profilePicture" alt="user"/>
                        <span>
                    <label> {{user ? user.name : ''}} </label>
                    <small> {{organization.name}}</small>
                  </span>
                        <i aria-hidden="true" class="banana banana-triangle-down"></i>
                    </template>
                    <b-dropdown-group :header="user.email">
                        <b-dropdown-item-button>
                            <router-link :to="{name: 'OrganizationInfo'}">
                                <i aria-hidden="true" class="banana banana-building"></i> {{$t('drop-down-user.admin-organization')}}
                            </router-link>
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="openSideBar">
                            <i aria-hidden="true" class="banana banana-updating"></i> {{$t('drop-down-user.change-organization')}}
                        </b-dropdown-item-button>
                    </b-dropdown-group>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-group>
                        <b-dropdown-item-button @click="openZendesk">
                            <i aria-hidden="true" class="banana banana-help"></i> {{$t('drop-down-user.help')}}
                        </b-dropdown-item-button>
                    </b-dropdown-group>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-group>
                        <b-dropdown-item-button variant=" c-error" @click="logout()">
                            <i aria-hidden="true" class="banana banana-log-out"></i> {{$t('general.actions.logout.title')}}
                        </b-dropdown-item-button>
                    </b-dropdown-group>
                </b-dropdown>
            </li>
            <slot></slot>
        </ul>
    </head>
</template>

<script>
    import BasicInput from '@/components/BasicInput.vue'
    import {mapGetters} from 'vuex';
    import config from "@/config";
    import {
        STORE,
        GET_USER,
        GET_TOKEN_EXPIRE_DATE,
        GET_TOKEN
    } from '@/store/modules/session/session.types';
    import {
        STORE as ACCESS_CONFIG_STORE,
        GET_CURRENT_ORGANIZATION
    } from "@/store/modules/accessconfig/accessConfig.types";

    export default {
        name: 'TopbarHeader',
        components: {
            BasicInput
            // Badge
        },
        props: {
            clazz: String,
            currentValue: {type: Boolean, default: false}
        },
        data() {
            return {
                showSearch: false
            }
        },
        methods: {
            // Add class 'show'
            showFixedSearch() {
                this.showSearch = !this.showSearch;
            },
            // Remove class 'show'
            hideFixedSearch() {
                this.showSearch = false;
            },
            logout() {
                this.$router.push('/logout')
            },
            openSideBar() {
                this.$emit('openSideBar')
            },
            goLandingPage() {
                return config.users.host === "http://localhost" ? `${config.users.host}:${config.users.port}/landing` : `${config.users.host}/landing`;

            },
            openZendesk() {
                const launcher = window.document.getElementById('launcher')
                const buttons = launcher.contentDocument.getElementsByClassName('wrapper-AtBcr');
                if (buttons !== undefined && buttons.length > 0) {
                    buttons[0].click();
                }
            },
        },

        computed: {
            ...mapGetters(STORE, {user: GET_USER, userToken: GET_TOKEN, expireDate: GET_TOKEN_EXPIRE_DATE}),
            ...mapGetters(ACCESS_CONFIG_STORE, {organization: GET_CURRENT_ORGANIZATION})
        },
        mounted() {

        }

    }
</script>

<style lang="scss">
    .header-bar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 40px 10px 140px;
        box-shadow: 0 2px 6px 0 rgba(56, 73, 99, 0.15);
        z-index: 2;
        transition: 0.8s;

        .left-side,
        .right-side {
            width: 50%;
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .right-side {
            justify-content: flex-end;

            > li {
                padding: 0 15px;

                &:last-of-type {
                    padding-right: 0;
                }
            }
        }

        .no-border {
            padding-top: 0;
            margin-bottom: 0 !important;
            width: 100%;

            .w-100,
            .form-text-info {
                display: none;
            }

            input {
                border: none !important;
                font-size: 14px;

                &:focus + .input-icon {
                    font-size: 24px;
                }
            }

            .input-icon {
                font-size: 20px;
                left: 10px;
                transition: 0.5s;
            }
        }

        .show-resp {
            display: none;
        }

        .fixed-search {
            position: absolute;
            width: 100%;
            height: 0;
            z-index: 1;
            left: 0;
            top: 0;
            align-items: center;
            justify-content: center;
            padding: 0 20px 0 60px;
            opacity: 0;
            box-shadow: 0 2px 6px 0 rgba(56, 73, 99, 0.2);
            transition: all 0.5s ease-in-out;

            &.show {
                height: 70px;
                opacity: 1;
            }
        }
    }
</style>
