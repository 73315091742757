<template lang="html">
    <div class="general-payment">
        <div class="m-b-20">
            <div class="row m-b-30">
                <div class="col-md-7 col-7">
                    <h1 class="c-title f-14 body-font-regular m-0">
                        {{ $t('pagos.general.advanced.privacy.title') }}
                    </h1>
                </div>
                <div class="col-md-5 col-5">
                    <div class=" justify-content-end d-flex">
                        <h1
                                class="m-0 c-info f-13 body-font-regular c-help d-flex align-middle "
                                id="helpMult"
                        >
                            {{ $t('pagos.general.advanced.privacy.help') }}
                            <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                        </h1>
                        <b-tooltip
                                target="helpMult"
                                placement="top"
                                custom-class="white-tooltip"
                        >
                            <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab quasi maiores
                                nam voluptas dolorem distinctio voluptates veniam quod quis fugit commodi facilis alias
                                accusamus nemo reiciendis, culpa sit inventore illum.</p>
                        </b-tooltip>
                    </div>
                </div>
            </div>
            <div class="row">
                <RadioInputGroup
                        wrapperClass="col-md-12 col-12"
                        :options="[{value: false, label: $t('pagos.general.advanced.privacy.public')}, {value: true, label: $t('pagos.general.advanced.privacy.private')}]"
                        v-model="payment.private"
                >
                </RadioInputGroup>
            </div>
        </div>
        <BasicInput
                v-model="payment.externalIdentifier"
                labelFor="identificador"
                inputId="identificador"
                :label="$t('pagos.general.advanced.externalIdentifier.label')"
                :inputPlaceholder="$t('pagos.general.advanced.externalIdentifier.placeholder')"
                help
                @change="refresh"
        >
        </BasicInput>
        <div class="m-b-30">
            <div class="row">
                <div class="col-md-8 col-8">
                    <BasicCheckbox
                            clazz="m-0"
                            v-model="payment.acceptsMultipleUnits"
                            @change="refresh"
                            :text="$t('pagos.general.advanced.multiplicacion.permitir.title')"
                            :index="0"
                    ></BasicCheckbox>
                </div>
                <div class="col-md-4 col-4">
                    <div class=" justify-content-end d-flex">
                    </div>
                </div>
            </div>
        </div>
        <div v-if="payment.acceptsMultipleUnits" class="m-b-30">
            <Quantity
                    clazz="m-t--15 m-b--15"
                    v-model="payment.maxUnitsPerTransaction"
                    :title="$t('pagos.general.advanced.multiplicacion.maximo-permitido')"
            ></Quantity>
        </div>
        <div class="m-b-30">
            <div class="row">
                <div class="col-md-8 col-8">
                    <BasicCheckbox
                            clazz="m-0"
                            v-model="payment.additionalNotesConfig.isEnabled"
                            @change="refresh"
                            :text="$t('pagos.general.advanced.notas-adicionales.permitir.title')"
                            :index="1"
                    ></BasicCheckbox>
                </div>
                <div class="col-md-4 col-4">
                    <div class=" justify-content-end d-flex">
                    </div>
                </div>
            </div>
        </div>
        <div class="m-b-30" v-if="payment.additionalNotesConfig.isEnabled">
            <BasicCheckbox
                    clazz="m-0"
                    v-model="payment.additionalNotesConfig.isRequired"
                    @change="refresh"
                    :text="$t('pagos.general.advanced.notas-adicionales.obligatorio.title')"
                    :index="2"
            ></BasicCheckbox>
        </div>

        <BasicInput v-if="payment.additionalNotesConfig.isEnabled"
                    required
                    labelFor="notas"
                    inputId="notas"
                    @input="refresh"
                    v-model="payment.additionalNotesConfig.label"
                    :label="$t('pagos.general.advanced.notas-adicionales.campos-adicionales.title')"
                    :inputPlaceholder="$t('pagos.general.advanced.notas-adicionales.campos-adicionales.placeholder')"
        >
        </BasicInput>

        <BasicInput v-if="payment.additionalNotesConfig.isEnabled"
                    required
                    labelFor="adicionales"
                    inputId="adicionales"
                    @input="refresh"
                    v-model="payment.additionalNotesConfig.placeholder"
                    :label="$t('pagos.general.advanced.notas-adicionales.instructions.title')"
                    :inputPlaceholder="$t('pagos.general.advanced.notas-adicionales.instructions.placeholder')"
        >
        </BasicInput>
    </div>

</template>

<script lang="js">
    import BasicInput from "@/components/BasicInput.vue";
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import Quantity from "@/components/Quantity.vue";
    import RadioInputGroup from "@/components/RadioInputGroup.vue";

    export default {
        name: 'general',
        props: {
            slotValid: {type: Boolean, default: true},
            payment: {
                type: Object,
                default: function () {
                    return {
                        private: false,
                        externalIdentifier: '',
                        acceptsMultipleUnits: false,
                        maxUnitsPerTransaction: 1,
                        additionalNotesConfig: {
                            isEnabled: false,
                            label: '',
                            placeholder: '',
                            isRequired: false,
                        }
                    }
                }
            }
        },
        components: {
            BasicCheckbox,
            BasicInput,
            Quantity,
            RadioInputGroup
        },
        mounted() {
        },
        watch: {
            'payment.maxUnitsPerTransaction': function () {
                this.refresh();
            },
            'payment.additionalNotesConfig.isEnabled': function () {
                if (!this.payment.additionalNotesConfig.isEnabled) {
                    this.payment.additionalNotesConfig.isRequired = false;
                }
            }
        },
        data() {
            return {}
        },
        methods: {
            refresh() {
                this.$forceUpdate();
                this.$nextTick(() => this.validityChange());
            },
            validityChange() {
                this.$emit('validityChange', this.isValid());
            },
            isValid() {
                if (this.payment.acceptsMultipleUnits && this.payment.maxUnitsPerTransaction <= 0) {
                    return false;
                }
                if (this.payment.additionalNotesConfig.isEnabled) {
                    return this.payment.additionalNotesConfig.label.length > 0 && this.payment.additionalNotesConfig.placeholder.length > 0;
                }
                return true;
            },
            checkPrivacy() {
                this.payment.private = true;
            }
        },
        computed: {}
    }

</script>
