<template>
    <div>
        <router-link
                to="/signin"
                class="button xs button-ghost button-primary1 m-b-40 p-l-0 p-r-5"
                tabindex
        >
            <i aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('user-recovery.return-login')}}
        </router-link>
        <GenericTitle
                :title="$t('user-recovery.title')"
                :titleStrong="$t('user-recovery.title-strong')"
                :subtitle="$t('user-recovery.instructions')">
        </GenericTitle>

        <TelInput
                clazz=""
                model-name="phone"
                v-model="phone"
                labelFor="number"
                :label="$t('step-four.number-phone')"
                :verified="sended"
                required
                v-on:country-changed="countryChanged"
                idInput="numCountry"
                :inputPlaceholder="$t('step-four.number-phone-placeholder')"
                badge
                :badgeType="sended ? 'badge-success' : ''"
                :badgeText="sended ? 'enviado' : ''"
        ></TelInput>
        <b-alert
                class="alert-note"
                show
                variant="danger"
                v-if="showErrorCode && !sended"
        >
            <p class="text-container"><img
                    class="img-fluid"
                    src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                    alt="Imagen alerta"
            /> {{errorCodeText}} </p>
        </b-alert>

        <b-alert
                class="alert-note"
                show
                variant="warning"
                v-if="showAttemptsWarning"
        >
            <p class="text-container"><i aria-hidden="true" class="icon banana banana-info-circle1"></i>{{$t('user-recovery.warning-attempts')}}</p>
        </b-alert>

        <p
                class="f-16 verified-check text-align-l"
                v-if="sended && !showErrorCode && !showAttemptsWarning"
        ><i aria-hidden="true" class="f-35 banana banana-check-normal text-align-l"></i>{{ $t('user-recovery.sended-sucess') }}</p>
        <p
                class="f-14 c-info_text body-font-regular d-block text-align-l m-b-30"
                v-if="sended && !showErrorCode"
        >{{ $t('user-recovery.take-a-while') }} <br /> {{ $t('general.didNotReceive') }} <a
                class="body-font-bold f-bold c-accent2 text-underline hover"
                tabindex=""
                @click="sendSMS"
        >{{ $t('user-recovery.resend') }}</a></p>

        <button
                id="btn-recoveryPassSubmit"
                class="button xs button-filled button-accent1 w-100 m-t-30"
                :class="{'button-disable':disabledRecoveryUser}"
                :disabled="disabledRecoveryUser"
                @click="sendSMS"
                v-if="!sended"
        >{{$t('user-recovery.recover-button')}}
        </button>

        <button
                id="btn-recovery-return-signin"
                class="button xs button-stroke button-accent1 w-100 m-t-30"
                @click="goToSignIn"
                v-if="sended"
        >{{$t('user-recovery.return-login')}}
        </button>

        <LoadingBar
                :loadingText="loadingText"
                v-if="loading"
        ></LoadingBar>

        <div class="footer-content">
            <p
                    class="link-to"
            > {{$t('signin-body.footer-no-account-yet1')}} <router-link
                    to="/signin"
                    class="hover"
                    tabindex=""
            > {{$t('signin-body.footer-no-account-yet2')}} </router-link>
            </p>
        </div>
    </div>
</template>

<script>
    import GenericTitle from "@/components/GenericTitle.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import TelInput from '@/components/TelInput.vue'
    import usersApi from "@/api/user/users.api";
    import {
        STORE as UserSignUpStore,
    } from '@/store/modules/signin/signin.types'

    export default {
        name: 'RecoveryEmailBySMS',
        data() {
            return {
                phone: "",
                phoneCountry: "",
                sended: false,
                smsSendShowMsg: false,
                showErrorCode: false,
                errorCodeText: "",
                loadingText: "Enviando mensaje...",
                loading: false,
                sendCounter: 0,
                showAttemptsWarning: false
            }
        },
        components: {
            GenericTitle,
            TelInput,
            LoadingBar
        },
        computed: {
            disabledRecoveryUser(){
                return this.phone === "" || this.phoneCountry === "" || this.phone.length < 10
            }
        },
        methods: {
            countryChanged(value) {
                this.phoneCountry = value;
            },
            sendSMS(){
                if(this.sendCounter >= 3) {
                    this.showAttemptsWarning = true;
                    return;
                }
                this.loading = true;
                const _this = this;
                const phoneUser = this.phone.slice(2,this.phone.length);
                const phoneNumber = `+${this.phoneCountry.dialCode}${phoneUser}`.replace(/\s/gi, "").trim();
                usersApi.recoveryUserBySMS({phone: phoneNumber})
                    .then(function(result){
                        _this.loading = false;
                        if(result.data.error){
                            _this.errorCodeText = result.data.message;
                            _this.showErrorCode = true;
                            _this.showAttemptsWarning = false;
                            _this.sended = false;
                        } else {
                            _this.sendCounter++;
                            _this.sended = true;
                            _this.smsSendShowMsg = true;
                            _this.showErrorCode = false;
                            _this.showAttemptsWarning = false;
                        }
                    }).catch(function(err){
                    _this.sended = false;
                    _this.loading = false;
                    _this.errorCodeText = err;
                    _this.showErorCode = true;
                    _this.showAttemptsWarning = false;
                })
            },
            goToSignIn(){
                this.$router.push({path: "/signin"})
            }
        },
        watch: {
          phone: function() {
              this.sendCounter = 0
          }
        },
        created() {
            this.$store.commit(`${UserSignUpStore}/SET_RIGHT_SIDE_BODY`, 'DONT_WORRY');
        }
    }
</script>
