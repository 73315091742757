<template>
    <div class="container-right">
        <TitlePrincipal
            :title="$t('hire.data.title')"
            :subtitle="$t('hire.data.subtitle')"
        ></TitlePrincipal>

        <div class="row">
            <div class="col-12">
                <BasicSelectComplete
                    required
                    :label="$t('hire.data.giro.label')"
                    v-model="organization.giro"
                    :options="giros"
                    :optionLabel="({description}) => description"
                    :validations="$v.organization.giro"
                    v-if="!loading"
                ></BasicSelectComplete>
            </div>
        </div>

        <div
            class="row"
            v-if="!isMoral"
        >
            <div class="col-12">
                <BasicInput
                    required
                    labelFor=""
                    inputId=""
                    :label="$t('hire.data.address.label')"
                    :inputPlaceholder="$t('hire.data.address.placeholder')"
                    v-model="organization.address"
                    :validations="$v.organization.address"
                ></BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    required
                    labelFor=""
                    inputId=""
                    :label="$t('hire.data.phone.label')"
                    :inputPlaceholder="$t('hire.data.phone.placeholder')"
                    v-model="organization.organizationPhone"
                    :validations="$v.organization.organizationPhone"
                ></BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    required
                    labelFor=""
                    inputId=""
                    :label="$t('hire.data.bussiness-phone.label')"
                    :inputPlaceholder="$t('hire.data.bussiness-phone.placeholder')"
                    v-model="organization.phone"
                    :validations="$v.organization.phone"
                ></BasicInput>
            </div>
            <div class="col-12">
                <BasicInput
                    required
                    labelFor=""
                    inputId=""
                    :label="$t('hire.data.email.label')"
                    :inputPlaceholder="$t('hire.data.email.placeholder')"
                    v-model="organization.email"
                    :validations="$v.organization.email"
                ></BasicInput>
            </div>
            <div class="col-12">
                <BasicInput
                    required
                    labelFor=""
                    inputId=""
                    :label="$t('hire.data.rfc.label')"
                    :inputPlaceholder="$t('hire.data.rfc.placeholder')"
                    v-model="organization.RFC"
                    :validations="$v.organization.RFC"
                ></BasicInput>
            </div>
        </div>
        <div
            class="row"
            v-else
        >
            <div class="col-12">
                <BasicInput
                    required
                    labelFor=""
                    inputId=""
                    :label="$t('hire.data.legal-representative.label')"
                    :inputPlaceholder="$t('hire.data.legal-representative.placeholder')"
                    v-model="organization.invoiceData.legalRepresentativeName"
                    :validations="$v.organization.invoiceData.legalRepresentativeName"
                ></BasicInput>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <BasicSelectComplete
                    required
                    :label="$t('hire.data.hire-schema.label')"
                    :options="HireSchema.values"
                    :optionLabel="(o) => $t(`hire-schema.${o}`)"
                    v-model="organization.hireSchema"
                    :validations="$v.organization.hireSchema"
                    v-if="!loading"
                ></BasicSelectComplete>
            </div>
        </div>

        <!-- SUBIR DOCUMENTOS -->
        <TitlePrincipal
            clazz="m-t-50"
            :title="$t('hire.data.documents-title')"
            :subtitle="$t('hire.data.documents-subtitle')"
            v-if="fileDocuments.length > 0"
        ></TitlePrincipal>

        <FileDocumentUpload
            v-for="fileDocument in fileDocuments"
            v-bind:key="fileDocument._id"
            :ref="fileDocument._id"
            :fileDocument="fileDocument"
            @change="(file) => upload(file, fileDocument, $refs[fileDocument._id][0])"
            :fixedAsSuccess="documentExists(fileDocument)"
        ></FileDocumentUpload>

        <!-- UBICACION -->
        <TitlePrincipal
            clazz="m-t-50"
            :title="$t('hire.data.location-title')"
            :subtitle="$t('hire.data.location-subtitle')"
        ></TitlePrincipal>

        <GoogleMap
            clazz="m-b-0"
            height="height:400px"
            searchBarButton
            locationOption
            tooltipMark
            :tooltipText="$t('hire.data.location-tooltip')"
            @input="updateMarkers"
            :value="markers"
            v-if="!loading"
            stepLocation
        ></GoogleMap>

        <div class="footer-buttons right">
            <button
                type="button"
                class="button button-ghost button-plain_text"
                name="Regresar"
                @click="returnToWallet"
                v-text="$t('general.actions.back.title')"
            ></button>
            <button
                type="button"
                class="button button-filled button-primary"
                name="Continuar"
                :disabled="!canContinue"
                @click="save"
                v-text="$t('general.actions.continue.title')"
            ></button>
        </div>

        <LoadingBar
            clazz="fullcontent-loading"
            v-if="loading"
        />
    </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import BasicInput from "@/components/BasicInput.vue";
import BasicSelectComplete from "@/components/BasicSelectComplete.vue";
import OrganizationApi from "@/api/organization/organizations.api.js";
import LoadingBar from "@/components/LoadingBar.vue";
import FileDocumentUpload from '@/components/FileDocumentUpload.vue';
import { email, alphaNum, required, helpers } from 'vuelidate/lib/validators';
import config from "@/config";
import LocationMixin from "@/mixins/location.mixin.js";

const requiredIfFisica = function (value) {
    return this.isMoral ? true : helpers.req(value);
};
const requiredIfMoral = function(value) {
    return this.isMoral ? helpers.req(value) : true;
};

// TODO validate organizatin has email for both persion types

export default {
    name: 'PagandoCheckHireData',
    mixins: [LocationMixin],
    components: {
        TitlePrincipal,
        GoogleMap,
        BasicInput,
        BasicSelectComplete,
        LoadingBar,
        FileDocumentUpload,
    },
    data() {
        return {
            addressString: null,
            mobilePhone: null,
            phone: null,
            email: null,
            rfc: null,
            legalRepresentative: null,
            // Progress Bar
            valueProgressBar: 80,
            maxProgressBar: 100,
            valueProgressBar0: 0,
            valueProgressBar100: 100,

            loading: false,
            organization: {
                invoiceData: {},
                branches: []
            },
            fileDocuments: [],
            marker: null,
            markers: [],
            HireSchema: {
                presentCard: 'PRESENT_CARD',
                nonPresentCard: 'NON_PRESENT_CARD',
                both: 'BOTH',
                values: ['PRESENT_CARD', 'NON_PRESENT_CARD', 'BOTH'],
            }
        };
    },
    computed: {
        isMoral() {
            return this.organization?.invoiceData?.isMoralPerson;
        },
        giros() {
            return this.organization?.giroFamily?.giroList || [];
        },
        canContinue(){
            return !this.$v.$invalid;
        },
    },
    methods: {
        async getInfo() {
            try {
                this.loading = true;
                const {organization, fileDocuments} = await OrganizationApi.hireData();
                if (organization.status !== 'CREATED') {
                    this.$router.push({name: 'dashboard'});
                }
                this.organization = organization;
                this.marker = {
                    address: this.organization?.branches[0]?.address,
                    lat: this.organization?.branches[0]?.location?.lat || this.location.lat || 0,
                    lng: this.organization?.branches[0]?.location?.lng || this.location.lon || 0,
                };
                this.markers = this.organization?.branches[0] ? [this.marker] : [];
                this.fileDocuments = fileDocuments;
            } catch(error) {
                this.toastErrorDefault(error);
            } finally {
                this.loading = false;
            }
        },
        async upload(file, fileDocument, ref) {
            const fn = () => {
                if (ref.percent < 100) {
                    ref.percent += 5;
                    if (ref.percent >= 100) {
                        ref.percent = 99;
                    }
                    setTimeout(fn, 50);
                }
            };
            try {
                const payload = {document: fileDocument._id, organization: this.organization._id};
                ref.setUploading();
                setTimeout(fn, 100);
                await OrganizationApi.hireDocument(payload, file);
                ref.setSuccess();
            } catch(error) {
                ref.setError();
            }
        },
        updateMarkers(markers){
            this.marker = markers[0];
        },
        documentExists(fileDocument) {
            return !!this.organization?.requestedFiles?.find((rf) => (rf?.fileDocument?._id || rf?.fileDocument) === fileDocument._id);
        },
        async save() {
            try {
                this.loading = true;
                const payload = {organization: this.organization, marker: this.marker};
                const organization = await OrganizationApi.hireDataSave(payload);
                this.$emit('finished', organization);
            } catch(error) {
                this.toastErrorDefault(error);
            } finally {
                this.loading = false;
            }
        },
        returnToWallet() {
            window.open(`${config.walletWeb.host}:${config.walletWeb.port}${config.walletWeb.endpoint}`);
        }
    },
    mounted() {
        this.getInfo();
    },
    validations: {
        organization: {
            giro: {
                _id: {
                    required: requiredIfFisica,
                    alphaNum,
                },
            },
            address: {
                required: requiredIfFisica,
            },
            organizationPhone: {
                required: requiredIfFisica,
            },
            phone: {
                required: requiredIfFisica,
            },
            email: {
                required: requiredIfFisica,
                email,
            },
            RFC: {
                required: requiredIfFisica,
            },
            invoiceData: {
                legalRepresentativeName: {
                    required: requiredIfMoral,
                }
            },
            hireSchema: {
                required
            }
        },
        marker: {
            address: {
                required,
            },
            lat: {
                required,
            },
            lng: {
                required,
            }
        }
    }
}
</script>
