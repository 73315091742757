<template>
    <div
      class="timeline-item"
      v-bind:class="status"
    >
      <div class="status-circle">
        <div class="circle">
          <i aria-hidden="true" class="banana" v-bind:class="icon"></i>
        </div>
      </div>
      <div class="info">
        <div class="m-b-5">
          <span class="date">
            {{date}}
          </span>
          <span class="status-text">
            {{statusText}}
          </span>
        </div>
        <p class="name">
          {{name}}
        </p>
        <p class="status-description">
          {{description}}
        </p>
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
  name: 'StatusTimelineItem',
  props: {
    status: String,
    icon: String,
    date: String,
    statusText: String,
    name: String,
    description: String
  }

}
</script>
<style lang="scss">
  .bottom-timeline {
    content: "";
    width: calc(100% - 27px);
    height: 5px;
    bottom: 0px;
    left: 0px;
    position: absolute;
  }
  .status-timeline {
    max-height: 250px;
    padding-left: 1px;
    padding-right: 15px;
    .timeline-item {
      display: flex;
      margin-bottom: 30px;
      &:last-child {
        .status-circle {
          &:after {
            content: none !important;
          }
        }
      }
      .status-circle {
        margin-right: 20px;
        position: relative;
        padding-top: 10px;
        &:after {
          content: "";
          width: 2px;
          height: 100%;
          border: dashed 2px;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0%);
          top: 41px;
        }
        .circle {
          min-width: 30px;
          min-height: 30px;
          max-width: 30px;
          max-height: 30px;
          border: solid 1px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 20px;
          }
        }
      }
      .info {
        font-size: 12px;
        line-height: 1;
        padding-top: 12px;
        .date {
          font-style: italic;
          font-weight: 400;
        }
        .status-text {
          font-weight: bold;
        }
        .status-description {
          margin-bottom: 0px;
          font-size: 13px;
          line-height: 1;
        }
        .name {
          margin-bottom: 5px;
          font-size: 13px;
          font-weight: bold;
          line-height: 1;
        }
      }
    }
  }
</style>
