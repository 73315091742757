import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'chargeback';


export default {
    list: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list`, params)
            .then(onSuccess)
            .catch(onError);
    },
    findChargeback: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/find/${params.id}`)
            .then(onSuccess)
            .catch(onError);
    },
    stats: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/stats?id=${params.organizationId}`)
            .then(onSuccess)
            .catch(onError);
    },
    uploadEvidence: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/upload-evidence`, params)
            .then(onSuccess)
            .catch(onError);
    },
    grantChargeback: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/grant`, params)
            .then(onSuccess)
            .catch(onError);
    },
}
