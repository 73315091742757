<template>
    <div class="content-centered">
        <LoadingBar v-if="loading" loadingText="Procesando contracargo"></LoadingBar>
        <div class="row">
            <div class="col-12">
                <router-link to="/client-admin/chargebacks"
                             class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-inherit"
                             tabindex="">
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i>
                    {{$t('chargebacks.show.return')}}
                </router-link>
                <FloatingTitleLayout
                        :title="$t('chargebacks.show.see')"
                ></FloatingTitleLayout>
            </div>
            <div class="col-12">
                <b-alert
                        class="alert-note"
                        show
                        :variant="chargeback.status === 'REVISION' ? 'warning' : 'info'">
                    <p class="text-container" v-if="chargeback.status === 'APPLIED'">
                        <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                alt="info"
                        />
                        {{getAlertDescription()}}
                    </p>
                    <p class="text-container" v-if="chargeback.status === 'WIN' || chargeback.status === 'LOST'">
                        <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                alt="info"
                        />
                        <span>
                            {{$t('chargebacks.show.description-inactive-desc')}}
                            <strong class="c-accent_2 text-underline hover" @click="openZendesk()">
                                {{$t('chargebacks.show.description-inactive-link')}}
                            </strong>
                        </span>
                    </p>
                    <p class="text-container" v-if="chargeback.status === 'REVISION'">
                        <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                alt="warning"
                        />
                        <span>
                            {{$t('chargebacks.show.description-revision-desc')}}
                            <strong class="c-accent_2 text-underline hover" @click="openZendesk()">
                                {{$t('chargebacks.show.description-revision-link')}}
                            </strong>
                        </span>
                    </p>
                </b-alert>
            </div>
            <div class="col-12">
                <CardSimple title="Detalles"
                            subtitle="Un contracargo ha sido generado en tu cuenta,
                            esto sucede debido a que el cliente que ha adquirido un
                            servicio contigo ha desconocido el cargo a su tarjeta.">

                    <div class="row">
                        <div class="col-12 col-md-6 m-b-30">
                            <label class="f-14 c-title body-font-bold d-block m-b-10"> {{$t('chargebacks.show.status')}} </label>
                            <Badge :type="getBadgeStatus(chargeback.status)"
                                   :text="$t(`chargebacks.badge.${chargeback.status}`)"></Badge>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <label class="f-14 c-title body-font-bold d-block m-b-10">
                                {{chargeback.status === "APPLIED" ? $t('chargebacks.show.remaining-time') : $t('chargebacks.show.email-evidence')}} </label>
                            <RemainingTime
                                    v-if="!loading"
                                    :evidence="chargeback.evidence"
                                    :limit-date="chargeback.uploadEvidenceLimitDate"
                                    :status="chargeback.status"
                                    :max-bar="getMaxBar(chargeback.uploadEvidenceLimitDate, chargeback.chargebackLogs)"
                            ></RemainingTime>
                        </div>
                        <div class="col-12">
                            <div class="text-divider text-primary_1"><label class="f-18 principal-font-bold">
                                {{$t('chargebacks.show.original-charge')}} </label></div>
                        </div>
                        <div class="col-12">
                            <div class="row" v-if="chargeback.transaction">
                                <span class="col-12 col-md-2 m-b-30">
                                    <label class="f-14 c-title body-font-bold d-block m-b-10"> {{$t('chargebacks.show.payment-number')}} </label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-0"> {{chargeback.transaction.number}} </p>
                                </span>
                                <span class="col-12 col-md-3 m-b-30">
                                            <label class="f-14 c-title body-font-bold d-block m-b-10"> {{$t('chargebacks.show.user')}} </label>
                                            <p class="f-14 c-plain_text body-font-regular d-block m-0"> {{chargeback.transaction.clientFullName}} </p>
                                        </span>
                                <span class="col-12 col-md-3 m-b-30">
                                            <label class="f-14 c-title body-font-bold d-block m-b-10"> {{$t('chargebacks.show.date')}} </label>
                                            <p class="f-14 c-plain_text body-font-regular d-block m-0"> {{formatDate(chargeback.transaction.date)}}</p>
                                        </span>
                                <span class="col-12 col-md-2 m-b-30">
                                            <label class="f-14 c-title body-font-bold d-block m-b-10"> {{$t('chargebacks.show.quantity')}} </label>
                                            <p class="f-16 c-accent_2 body-font-bold d-block m-0">
                                                {{chargeback.transaction.transactionId.paymentDetail.totalWithoutCommision | money}}
                                            </p>
                                        </span>
                                <span class="col-12 col-md-2 m-b-30">
                                            <label class="f-14 c-title body-font-bold d-block m-b-10"> {{$t('chargebacks.show.see-detail')}} </label>
                                            <button type="button"
                                                    class="button-circle xs button-ghost button-info_text m-l-15 m-r-15"
                                                    @click="$bvModal.show('modal-detailsService')">
                                                <i aria-hidden="true" class="banana banana-visibility-on"></i>
                                            </button>
                                        </span>
                            </div>
                        </div>
                        <div class="col-12 ">
                            <div class="text-divider text-primary_1">
                                <label class="f-18 principal-font-bold">{{$t('chargebacks.show.reason-notification')}} </label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 m-b-30" v-if="chargeback.reason">
                            <label class="f-14 c-title body-font-bold d-block m-b-10"> {{$t('chargebacks.show.reason')}} </label>
                            <p class="f-14 c-plain_text body-font-regular d-block m-0"> {{chargeback.reason.description}} </p>
                        </div>
                        <div class="col-12 col-md-6 m-b-30">
                            <label class="f-14 c-title body-font-bold d-block m-b-10">
                                {{$t('chargebacks.show.notification-date')}} </label>
                            <p class="f-14 c-plain_text body-font-regular d-block m-0">
                                {{formatDate(chargeback.notificationDate)}} </p>
                        </div>
                    </div>
                </CardSimple>

                <CardSimple :title="$t('chargebacks.show.evidence')"
                            :subtitle="$t('chargebacks.show.evidence-description')"
                            v-if="chargeback.status == 'APPLIED' && !timeExpired"
                >
                    <div>
                        <div class="text-divider col-md-12"><label> {{$t('chargebacks.show.label-divider', {
                            documentsLoaded,
                            numOfDocuments: fileDocuments.length
                        })}}</label></div>
                        <!-- NORMAL -->
                        <UploadFile v-for="(file, fileIndex) in fileDocuments" :key="fileIndex"
                                    :icon="file.icon ? file.icon : 'banana banana-document-normal'"
                                    :title="file.title" :text="file.description"
                                    :note="file.fileSpecs"
                                    :tooltipId="`tooltip${fileIndex}`"
                                    @loadFile="updateFile"
                                    :fileKey="file.key"
                                    @deleteFile="deleteFile"
                                    :file-by-key="getFileByKey(file.key)"
                                    :disabled="timeExpired"
                        >
                        <template v-slot:tooltipContent>{{file.toolTipText}}</template>
                        </UploadFile>
                    </div>
                </CardSimple>

                <CardSimple :title="$t('chargebacks.show.observation-title')"
                            :subtitle="$t('chargebacks.show.observation-subtitle')"
                            v-if="chargeback.status === 'APPLIED' && !timeExpired"
                >
                    <div class="row">
                        <div class="col-12">
                            <BasicTextarea
                                    labelFor="observ"
                                    textareaId="observ"
                                    :label="$t('chargebacks.show.observation-title')"
                                    :textareaPlaceholder="$t('chargebacks.show.observation-placeholder')"
                                    :value="extraComment"
                                    v-model="extraComment"
                                    :maxCount=300
                                    :read-only="timeExpired"
                            >
                            </BasicTextarea>
                        </div>
                        <div class="col-12 vertical-center justify-content-end">
                            <button type="button"
                                    class="button xs button-ghost button-plain_text m-0-auto-0-0">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                {{$t('chargebacks.return')}}
                            </button>

                            <button type="button"
                                    class="button xs button-filled button-accent1"
                                    :disabled="!evidenceValid"
                                    @click="$bvModal.show('modal-send-evidence')">
                                {{$t('chargebacks.show.reply-chargeback')}}
                            </button>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>

        <div class="col-12" v-if="chargeback.status !== 'APPLIED' || timeExpired">
            <b-tabs
                    class="simple-tabs group-tabs"
                    content-class="simple-tabs-body p-t-10"
                    v-model="tabIndex">
                <b-tab active>
                    <template v-slot:title>
                        <div class="d-flex align-items-c">
                            <span class="m-r-5">
                                Seguimiento
                            </span>
                        </div>
                    </template>
                    <CardSimple title="Seguimiento">
                        <div class="row">
                            <div class="col-12">
                                <div class="p-relative">
                                    <div class="status-timeline y-scroll">
                                        <StatusTimelineItem
                                                v-for="(log, index) in logsTimeLine"
                                                :key="index"
                                                :icon="STATUS_ENUM[log.status].logIcon"
                                                :status="STATUS_ENUM[log.status].logStatus"
                                                :date="formatDate(log.date, 'DD MMMM, YYYY hh:mm a')"
                                                :statusText="`- ${log.titleClient}`"
                                                :name="log.author"
                                                :description="`“${STATUS_ENUM[log.status].label ? STATUS_ENUM[log.status].label : log.description}”`"
                                        >
                                            <a v-if="log.status === 'REVISION'" @click="tabIndex++">
                                                <Badge type="badge-accent_2" text="Ver Documentación"></Badge>
                                            </a>
                                        </StatusTimelineItem>
                                    </div>
                                    <div class="bottom-timeline"></div>
                                </div>
                            </div>
                            <div class="col-12 vertical-center m-t-30">
                                <router-link to="/client-admin/chargebacks"
                                             class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                             tabindex="">
                                    <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                    {{$t('chargebacks.return')}}
                                </router-link>
                            </div>
                        </div>
                    </CardSimple>
                </b-tab>
                <b-tab>
                    <template v-slot:title>
                        <div class="d-flex align-items-c">
                            <span class="m-r-5">
                                {{$t('chargebacks.show.evidence')}}
                            </span>
                            <Badge
                                    type="badge-accent_2"
                                    :text="documentsLoaded | toString"
                            ></Badge>
                        </div>
                    </template>
                    <CardSimple :title="$t('chargebacks.show.evidence')">
                        <div class="row">
                            <div class="col-12" v-if="documentsLoaded === 0">
                                <EmptyState
                                        emptyStateDefault
                                        size="xs"
                                        title=""
                                        :text="$t('chargebacks.show.evidence-empty')"
                                ></EmptyState>
                            </div>
                            <div class="col-12" v-if="documentsLoaded > 0">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-25">
                                    {{$t('chargebacks.show.evidence-date')}}
                                    <strong class="c-title body-font-semibold">{{formatDate(chargeback.evidence.uploadedAt, "DD [de] MMMM [del] YYYY - hh:mm a")}}</strong>
                                </p>
                            </div>
                            <div class="col-12" v-if="documentsLoaded > 0">
                                <div class="text-divider"><label> {{$t('chargebacks.show.label-divider', {
                                    documentsLoaded,
                                    numOfDocuments: fileDocuments.length
                                    })}} </label></div>
                                <!-- LOADED -->
                                <UploadFile v-for="(file, fileIndex) in fileDocuments"
                                            :key="fileIndex"
                                            icon="banana banana-document-normal"
                                            :title="file.title" :text="formatSize(getFileByKey(file.key).size)" :note="file.fileSpecs"
                                            :mimetype="getFileByKey(file.key).mimetype"
                                            :tooltipId="`tooltip${fileIndex}`" @loadFile="updateFile"
                                            :fileKey="file.key" @deleteFile="deleteFile"
                                            :file-by-key="getFileByKey(file.key)"
                                            :hide-trash-can="true"
                                            disabled
                                            loadedFile
                                ></UploadFile>
                            </div>
                            <div class="col-12 vertical-center m-t-30">
                                <button type="button"
                                        class="button xs button-ghost button-plain_text m-0-auto-0-0">
                                    <i aria-hidden="true" class="banana banana-chevron-left"></i>
                                    {{$t('chargebacks.return')}}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </b-tab>
            </b-tabs>
        </div>

        <!-- MODAL SEE DETAILS -->
        <ModalDefault
                modalId="modal-detailsService"
                size="lg"
                v-if="chargebackTransaction && chargeback"
        >
            <PaymentInfoRow
                    v-if="chargeback.organization"
                    :organization="chargeback.organization.name"
                    :paymentName="paymentDetail.name"
                    :date="formatDate(chargebackTransaction.date, 'DD MMMM, YYYY - hh:mm a')"
                    exchange="MXN"
                    :cant="chargeback.transaction.transactionId.paymentDetail.totalWithoutCommision | money"
                    :category="chargeback.organization.category"
                    showCategory
                    clazz="m-b-40 m-t--10"
            >
            </PaymentInfoRow>

            <b-tabs
                    class="simple-tabs group-tabs"
                    content-class="simple-tabs-body p-t-30 min-height-300px"
                    active-tab-class="primary-bg">
                <b-tab
                        title="Datos del Usuario"
                        active
                >

                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('chargebacks.show.user')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{chargeback.transaction ? chargeback.transaction.clientFullName : ""}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('chargebacks.show.email')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{chargebackTransaction.user ? chargebackTransaction.user.email : ""}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('chargebacks.show.phone')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{chargebackTransaction.user ? chargebackTransaction.user.phone : ""}}
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Datos del Pago">
                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('chargebacks.show.payment-type')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{$t(`chargebacks.show.${paymentDetail.type}`)}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{$t('chargebacks.show.curp')}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{chargebackTransaction.user ? chargebackTransaction.user.curp : 'N/A'}}
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab :title="$t('chargebacks.show.additional-notes')">
                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                {{paymentDetail.additionalNotesConfig ? paymentDetail.additionalNotesConfig.label : ""}}
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                {{paymentDetail.additionalNotes}}
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Detalles del Pago">
                    <div class="dots-table m-b-30">
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('chargebacks.show.card-type')}}
                            </div>
                            <div class="right-side">
                                <img v-if="cardTypeAndBrand.brand === 'visa'"
                                     src="@/assets/images/Logos/Cards/visa.svg" alt="">
                                <img v-else-if="cardTypeAndBrand.brand === 'mastercard'"
                                     src="@/assets/images/Logos/Cards/mastercard.svg" alt="">
                                <img v-else-if="cardTypeAndBrand.brand === 'amex'"
                                     src="@/assets/images/Logos/Cards/american-express.svg" alt="">
                                <img v-else-if="cardTypeAndBrand.brand === 'pagando_electronic_gift'"
                                     src="@/assets/images/Logos/Cards/pagandocard.svg" alt="">
                                <img v-else src="@/assets/images/Logos/Cards/generic.svg" alt="">
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('chargebacks.show.subtotal')}}
                            </div>
                            <div class="right-side f-bold">
                                {{paymentDetail.subTotal | currency}}
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('chargebacks.show.discounts')}}
                            </div>
                            <div class="right-side c-success f-bold">
                                {{'-' + paymentDetail.totalDiscounts | currency}}
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('chargebacks.show.total')}}
                            </div>
                            <div class="right-side f-bold">
                                {{paymentDetail.totalWithoutCommision | currency}}
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                {{$t('chargebacks.show.bank-commision')}}
                            </div>
                            <div class="right-side f-bold">
                                {{chargebackTransaction.dispersionDetail ? chargebackTransaction.dispersionDetail.organizationBankCommision : 0 | currency}}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-c justify-content-end">
                        <div class="principal-font-bold f-13 c-primary_1 m-r-20">
                            {{$t('chargebacks.show.amount-to-user')}}
                        </div>
                        <div class="body-font-bold f-28 c-accent_2">
                            {{chargebackTransaction.dispersionDetail ? chargebackTransaction.dispersionDetail.organizationDeposit : "" | currency}}
                        </div>
                    </div>
                </b-tab>
            </b-tabs>

            <template v-slot:customFooter>
                <div class="w-100">
                    <div class="row">
                        <div class="col-sm-4 col-12 d-flex align-items-c">
                            <div class="c-title d-14 body-font-regular">
                                {{$t('chargebacks.show.number')}} <strong>{{chargebackTransaction.number}}</strong>
                            </div>
                        </div>
                        <div class="col-sm-8 col-12">
                            <div class="d-sm-flex justify-content-end">
                                <button class="button xs button-ghost button-plain_text m-r-20" @click="$bvModal.hide('modal-detailsService')">
                                    {{$t('chargebacks.show.close')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ModalDefault>

        <!-- MODAL VISOR DE ARCHIVOS -->
        <ModalDefault
                modalId="modal-seeFile"
                size="lg"
                bodyClass="p-0"
                title="Visor de archivos">
            <div class="bgm-gray p-r-15 border-top-10 border-bottom-10">
                <div class="col-12 y-scroll o-auto p-50 max-h-410px">
                    <div class="w-100">
                        <!-- Add style "min-width: 999px;" to the image for the resize -->
                        <img
                                class="img-fluid w-100"
                                src="https://image.isu.pub/171031034439-1b5ca30cd6a6a18866d875c4e9e78880/jpg/page_1.jpg"
                                alt="">
                    </div>
                </div>
            </div>
            <template v-slot:customFooter>
                <div class="d-flex w-100 justify-space-between align-item-c">
                    <div class="d-flex align-items-c">
                        <button
                                type="button"
                                class="button-square xs button-filled button-accent2 m-r-10"
                        >
                            <i
                                    aria-hidden="true"
                                    class="banana banana-search-minus"
                            ></i>
                        </button>
                        <button
                                type="button"
                                class="button-square xs button-filled button-accent2"
                        >
                            <i
                                    aria-hidden="true"
                                    class="banana banana-search-plus"
                            ></i>
                        </button>
                    </div>

                    <div class="vertical-center">
                        <button
                                type="button"
                                class="button xs button-ghost button-plain_text m-r-10"
                        > Cerrar
                        </button>
                    </div>
                </div>
            </template>
        </ModalDefault>

        <!-- MODAL SEND EVIDENCES -->
        <ModalAlert
                modalId="modal-send-evidence"
                buttonType="button-warning"
                :imgUrl="imageModalWarning"
                title="Enviar evidencias"
                text="Si ya verificaste que cuentas con los documentos
            necesarios y añadiste las observaciones pertinentes
            haz clic en el botón continuar."
                buttonText="Continuar"
                buttonCancel="Regresar"
                @submit="uploadEvidence"
        ></ModalAlert>
    </div>
</template>

<script>
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import Badge from "@/components/Badge.vue";
    import ModalDefault from "@/components/ModalDefault.vue";
    import PaymentInfoRow from "@/components/PaymentInfoRow.vue";
    import UploadFile from "@/components/UploadFile.vue";
    import BasicTextarea from "@/components/BasicTextarea.vue";
    import ModalAlert from "@/components/ModalAlert.vue";
    import imageModalWarning from "@/assets/images/Illustrations/illustration-contestar-contracargo.svg";
    import EmptyState from "@/components/EmptyState.vue";
    import StatusTimelineItem from "@/components/StatusTimelineItem.vue";
    import RemainingTime from "@/components/RemainingTime.vue";
    import LoadingBar from "@/components/LoadingBar.vue";

    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {EventBus} from "@/main";
    import {mapGetters} from "vuex";
    import utils from '@/common/utils';
    import moment from 'moment';
    const iconDocument = "banana-document-normal";

    moment.locale('es');

    import {
        STORE as ChargebackStore,
        GET_CHARGEBACK,
        GET_FILE_DOCUMENTS,
        GET_FILE_BY_KEY,
        UPDATE_FILES,
        UPDATE_EXTRA_COMMENT,
        GET_REQUESTED_FILES
    } from '@/store/modules/chargebacks/chargebacks.types';

    import {
        SUCCESS_SAVE,
        ERROR_SAVING
    } from '@/store/events';

    export default {
        name: 'ChargebackShow',
        components: {
            FloatingTitleLayout,
            CardSimple,
            Badge,
            ModalDefault,
            PaymentInfoRow,
            UploadFile,
            BasicTextarea,
            ModalAlert,
            EmptyState,
            StatusTimelineItem,
            RemainingTime,
            LoadingBar
        },
        data() {
            return {
                imageModalWarning: imageModalWarning,
                documentsLoaded: 0,
                tabIndex: 0,
                extraComment: "",
                fileEvidenceValid: false,
                loading: false,
                STATUS_ENUM: {
                    "APPLIED": {
                        badgeType: "badge-accent_2",
                        label: null,
                        logIcon: "banana-warning-circle1",
                        logStatus: "warning"
                    },
                    "REVISION": {
                        badgeType: "badge-warning",
                        label: null,
                        logIcon: iconDocument,
                        logStatus: "info"
                    },
                    "WIN": {
                        badgeType: "badge-success",
                        label: this.$t("chargebacks.show.label-win"),
                        logIcon: "banana-check-circle1",
                        logStatus: "success",
                        actions: []
                    },
                    "LOST": {
                        badgeType: "badge-error",
                        label: this.$t("chargebacks.show.label-lost"),
                        logIcon: "banana-cross-circle1",
                        logStatus: "error",
                        actions: []
                    },
                    "PRESENT_DOCUMENTS": {
                        badgeType: null,
                        label: null,
                        logIcon: iconDocument,
                        logStatus: "success"
                    },
                    "GRANT": {
                        badgeType: null,
                        label: null,
                        logIcon: iconDocument,
                        logStatus: "error"
                    },
                }
            }
        },
        mixins: [ToastSnotify],
        computed: {
            ...mapGetters(ChargebackStore, {
                chargeback: GET_CHARGEBACK,
                fileDocuments: GET_FILE_DOCUMENTS,
                getFileByKey: GET_FILE_BY_KEY,
                getRequestedFiles: GET_REQUESTED_FILES
            }),
            chargebackTransaction() {
                return this.chargeback && this.chargeback.transaction ? this.chargeback.transaction.transactionId : {};
            },
            paymentDetail() {
                return this.chargebackTransaction && this.chargebackTransaction.paymentDetail ? this.chargebackTransaction.paymentDetail : {};
            },
            logsTimeLine() {
                return this.chargeback && this.chargeback.chargebackLogs ?
                    this.chargeback.chargebackLogs.filter(chLog => chLog.status !== "NOTIFIED" && chLog.status !== "CREATED") : []
            },
            evidenceValid() {
                return this.fileEvidenceValid
            },
            timeExpired() {
                return new Date(this.chargeback.uploadEvidenceLimitDate).getTime() < Date.now();
            },
            defaultCardTypeAndBrand(){
                const container = {
                    ...this.chargebackTransaction.apiDetail.transaction,
                    ...this.chargebackTransaction.apiDetail
                };
                const cardType = container.type;
                const brand = container.cardType || container.cardBrand || container.brand;
                return {
                    cardType: cardType ? cardType.toLowerCase() : "other",
                    brand: brand ? brand.toLowerCase() : "other"
                };
            },
            cardTypeAndBrand(){
                if (this.chargebackTransaction && (this.chargebackTransaction.payHolder === "CONEKTA" || this.chargebackTransaction.payHolder === undefined)
                    && this.chargebackTransaction.apiDetail
                    && this.chargebackTransaction.apiDetail.charges
                    && this.chargebackTransaction.apiDetail.charges._json
                    && this.chargebackTransaction.apiDetail.charges._json.data
                    && this.chargebackTransaction.apiDetail.charges._json.data[0]
                    && this.chargebackTransaction.apiDetail.charges._json.data[0].payment_method
                    && this.chargebackTransaction.apiDetail.charges._json.data[0].payment_method.type) {
                    let cardType = this.chargebackTransaction.apiDetail.charges._json.data[0].payment_method.type.toLowerCase();
                    let brand = this.chargebackTransaction.apiDetail.charges._json.data[0].payment_method.brand.toLowerCase();
                    // Translate card type
                    switch (cardType) {
                        case 'credit':
                            cardType = this.$i18n.t('pagos-recaudados.credit-card');
                            break;
                        case 'debit':
                            cardType = this.$i18n.t('pagos-recaudados.debit-card');
                            break;
                        default:
                            cardType = this.$i18n.t('pagos-recaudados.unknow-card');
                    }
                    switch (brand) {
                        case 'visa':
                            brand = 'visa';
                            break;
                        case 'mastercard':
                            brand = 'mastercard';
                            break;
                        case 'american_express':
                            brand = 'amex';
                            break;
                        default:
                            brand = undefined;
                    }

                    return {cardType, brand};
                }
                if (this.chargebackTransaction && (this.chargebackTransaction.payHolder === 'BZPAY'
                        || this.chargebackTransaction.payHolder === 'BANREGIO'
                        || this.chargebackTransaction.payHolder === 'PAGANDO_GIFT')) {
                    let cardType = this.chargebackTransaction.apiDetail.transaction.paymentType.toLowerCase();
                    let brand = this.chargebackTransaction.apiDetail.transaction.cardType.toLowerCase();
                    // Translate card type

                    cardType = cardType === "cc" ? this.$i18n.t('pagos-recaudados.credit-card') :
                        this.$i18n.t('pagos-recaudados.unknow-card');


                    switch (brand) {
                        case 'visa':
                            brand = 'visa';
                            break;
                        case 'mastercard':
                            brand = 'mastercard';
                            break;
                        case 'american_express':
                            brand = 'amex';
                            break;
                        case 'pagando_electronic_gift':
                            brand = 'pagando_electronic_gift';
                            break;
                        default:
                            brand = undefined;
                    }

                    return {cardType, brand};
                }
                return this.defaultCardTypeAndBrand;
            }
        },
        methods: {
            updateFile: utils.debounce(async function (file, fileKey) {
                this.fileInProgress(fileKey, true);
                if (file) {
                    const tempFile = await this.readFile(file);
                    this.fileInProgress(fileKey, false);
                    this.$store.commit(`${ChargebackStore}/${UPDATE_FILES}`, {tempFile, fileKey});
                    setTimeout(() => {
                        this.fileIsLoaded(fileKey, true);
                    }, 2000)
                }
            }, 50, false),
            openZendesk() {
                const launcher = window.document.getElementById('launcher')
                if(launcher){
                    const buttons = launcher.contentDocument.getElementsByClassName('wrapper-AtBcr');
                    if (buttons !== undefined && buttons.length > 0) {
                        buttons[0].click();
                    }
                }
            },
            readFile(file) {
                return new Promise((resolve) => {
                    const fr = new FileReader();
                    fr.onload = (e) => {
                        const actualUserImg = {};
                        const dataUrl = e.target.result;
                        actualUserImg.name = file.name;
                        actualUserImg.type = file.type;
                        actualUserImg.size = file.size;
                        actualUserImg.data = dataUrl;
                        resolve(actualUserImg)
                    };
                    fr.readAsDataURL(file);
                });
            },
            fileInProgress(fileKey, value) {
                EventBus.$emit(`loadingFile${fileKey}`, value);
            },
            fileIsLoaded(fileKey, value) {
                EventBus.$emit(`loadedFile${fileKey}`, value);
                this.validateFileEvidence()
            },
            deleteFile(fileKey) {
                this.$store.commit(`${ChargebackStore}/${UPDATE_FILES}`, {tempFile: null, fileKey});
                this.fileIsLoaded(fileKey, false);
            },
            getBadgeStatus(status) {
                switch (status) {
                    case 'APPLIED':
                        return 'badge-accent_2';
                    case 'REVISION':
                        return 'badge-warning';
                    case 'WIN':
                        return 'badge-success';
                    case 'LOST':
                        return 'badge-error';
                    default:
                        return 'badge-placeholder';
                }
            },
            getAppliedDate(chargebackLogs) {
                if (!chargebackLogs) {
                    return Date.now();
                }
                const appliedDate = chargebackLogs.find(log => log.status === "APPLIED");
                return appliedDate ? new Date(appliedDate.date).getTime() : Date.now();
            },
            getMaxBar(limitDate, chargebackLogs) {
                if (!limitDate) {
                    return 0
                }
                const appliedDate = this.getAppliedDate(chargebackLogs);
                return new Date(limitDate).getTime() - appliedDate;
            },
            formatDate(date, format = "DD/MM/YYYY - hh:mm a") {
                return moment(date).format(format);
            },
            getAlertDescription() {
                switch (this.chargeback.status) {
                    case 'APPLIED':
                        return this.$t('chargebacks.show.description-active');
                    case 'REVISION':
                        return this.$t('chargebacks.show.description-revision');
                    case 'WIN':
                    case 'LOST':
                        return this.$t('chargebacks.show.description-inactive');
                    default:
                        return this.$t('chargebacks.show.description-active');
                }
            },
            validateFileEvidence() {
                let fileEvidenceValid = true;
                let documentsLoaded = 0;
                this.getRequestedFiles.every(function (element) {
                    if(element.hasOwnProperty('isRequired') && element.isRequired){
                        fileEvidenceValid = element.isLoaded || (element.file && element._id ) ? true : false;
                        if (fileEvidenceValid) {
                            documentsLoaded++
                        }
                        return fileEvidenceValid;
                    }
                    documentsLoaded++
                    return true

                });
                this.fileEvidenceValid = fileEvidenceValid;
                this.documentsLoaded = documentsLoaded;
            },
            uploadEvidence() {
                this.loading = true;
                this.$bvModal.hide("modal-send-evidence");
                this.$store.commit(`${ChargebackStore}/${UPDATE_EXTRA_COMMENT}`, this.extraComment);
                this.$store.dispatch(`${ChargebackStore}/uploadChargebackEvidence`).then(message =>{
                    this.loading = false;
                    this.displayNotificationSuccess(message);
                }).catch(message=>{
                    this.loading = false;
                    this.displayNotificationError(message);
                });
            },
            formatSize(size){
                if(!size){
                    return "0 KB"
                }
                if(size > 1000 && size < 1000000){
                    return `${Math.ceil(size/1000)} (KB)`
                }
                return `${Math.ceil(size/1000000)} (MB)`
            }
        },
        filters: {
            money: function (value) {
                if (!value) {
                    return "$0.00"
                }
                return `${new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(Number(value))}`
            },
            toString: function (value) {
                if (!value) {
                    return "0"
                }
                return value.toString();
            }
        },
        mounted() {
            this.loading = true;
            this.$store.dispatch(`${ChargebackStore}/findChargeback`, this.$route.params).then((result) => {
                const category = result ? result.organization.category : null;
                this.$store.dispatch(`${ChargebackStore}/fetchFileEvidenceRequested`, category)
                this.validateFileEvidence();
            });
        },
        beforeDestroy() {
            EventBus.$off(ChargebackStore + SUCCESS_SAVE);
            EventBus.$off(ChargebackStore + ERROR_SAVING);
        },
        created() {
            const _this = this;
            EventBus.$on(ChargebackStore + SUCCESS_SAVE, (message) => {
                _this.loading = false;
                _this.displayNotificationSuccess(message);
            });

            const errorHandler = function (message) {
                _this.loading = false;
                _this.displayNotificationError(message);
            };
            EventBus.$on(ChargebackStore + ERROR_SAVING, errorHandler);

            EventBus.$on(`${ChargebackStore}_SUCCESS_LOADING_CHARGEBACK`, function () {
                _this.loading = false;
            });
        }
    }
</script>
