<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/api-rest"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                    tabindex="">
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i>
                    {{$t('api-configuration.api-domains.return')}}
                </router-link>
                <FloatingTitleLayout
                        :title="getConfigurationStep === 'DOMAINS_CONFIGURATION' ? $t('api-configuration.api-domains.title') : $t('api-configuration.api-domains.second-title')"
                />
            </div>

            <div class="col-12">
                <CardSimple
                        :title="$t('api-configuration.api-domains.card-title')"
                        :subtitle="$t('api-configuration.api-domains.card-subtitle')"
                >
                    <div class="row">
                        <div class="col-12">
                            <h2 class="f-20 c-plain_text principal-font-bold d-block m-b-30">
                                {{$t('api-configuration.api-domains.first-section')}}
                            </h2>
                        </div>
                        <div class="col-12">
                            <BasicInput
                                required
                                labelFor="name"
                                inputId="name"
                                :label="$t('api-configuration.api-domains.name-input-label')"
                                :inputPlaceholder="$t('api-configuration.api-domains.name-input-placeholder')"
                                help
                                helpIcon="banana banana-question-circle1"
                                idHelpTooltip="tooltip-nombre"
                                :value="getDomainName"
                                v-on:basicInput="changeDomainName"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                        target="tooltip-nombre"
                                        placement="top"
                                        custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">{{ $t('api-configuration.api-domains.name-tootlip-text') }}</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>
                        <div class="col-12">
                            <BasicInput
                                required
                                labelFor="rutaResp"
                                inputId="rutaResp"
                                :label="$t('api-configuration.api-domains.answer-input-label')"
                                :inputPlaceholder="$t('api-configuration.api-domains.answer-input-placeholder')"
                                help
                                helpIcon="banana banana-question-circle1"
                                idHelpTooltip="tooltip-rutaResp"
                                :value="getResponseRoute"
                                v-on:basicInput="changeResponseRoute"
                            >
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                        target="tooltip-rutaResp"
                                        placement="top"
                                        custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">{{ $t('api-configuration.api-domains.answer-tootlip-text') }}</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>

                        <div class="col-12 vertical-center justify-content-end m-t-40">
                            <router-link
                                    to="/client-admin/api-rest"
                                    class="button xs button-ghost button-plain_text"
                            >
                                {{$t('api-configuration.api-domains.cancel-button')}}
                            </router-link>
                            <button
                                    :disabled="saveAvailable"
                                    type="button"
                                    class="button xs button-filled button-accent1 m-l-10"
                                    @click="saveDomainInformation()"
                            >
                                {{$t('api-configuration.api-domains.save-button')}}
                            </button>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>

        <ModalAlert
            modalId="modal-danger"
            buttonType="button-error"
            :imgUrl="imageModalDelete"
            :title="$t('api-configuration.api-domains.delete-domain-question')"
            text="$t('api-configuration.api-domains.delete-domain-instructions')"
            :buttonText="$t('api-configuration.api-domains.delete-domain-button')"
            @submit="deleteDomainElement(); $bvModal.hide('modal-danger')"
        ></ModalAlert>
    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import ModalAlert from '@/components/ModalAlert.vue';
    import imageModalDelete from "@/assets/images/Illustrations/illustration-modal-delete.svg";

    import {mapGetters} from 'vuex';
    import axios from 'axios';
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {
        STORE,
        GET_USER,
        GET_TOKEN
    } from '@/store/modules/session/session.types';
    import {utils} from '@/api/utils';
    const ERROR_TITLE_KEY = 'general.actions.error.title';
    const SUCCESS_TITLE_KEY = 'general.actions.success.title';
    const reWeburl = new RegExp(
        "^" +
        // protocol identifier (optional)
        // short syntax // still required
        "(?:(?:(?:https):)?\\/\\/)" +
        // user:pass BasicAuth (optional)
        "(?:\\S+(?::\\S*)?@)?" +
        "(?:" +
        // IP address exclusion
        // private & local networks
        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
        // IP address dotted notation octets
        // excludes loopback network 0.0.0.0
        // excludes reserved space >= 224.0.0.0
        // excludes network & broadcast addresses
        // (first & last IP address of each class)
        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
        "|" +
        // host & domain names, may end with dot
        // can be replaced by a shortest alternative
        // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
        "(?:" +
        "(?:" +
        "[a-z0-9\\u00a1-\\uffff]" +
        "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
        ")?" +
        "[a-z0-9\\u00a1-\\uffff]\\." +
        ")+" +
        // TLD identifier name, may end with dot
        "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
        ")" +
        // port number (optional)
        "(?::\\d{2,5})?" +
        // resource path (optional)
        "(?:[/?#]\\S*)?" +
        "$", "i"
    );

    const storeModule = `apiRest`;

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    export default {
        name: "ApiDomain",
        components: {
            FloatingTitleLayout,
            CardSimple,
            BasicInput,
            ModalAlert
        },
        data() {
            return {
                imageModalDelete: imageModalDelete,
                domainList: [{domain: "", ip: ""}],
                domainName: "",
                originRoute: "",
                responseRoute: "",
                elementToDelete: null
            }
        },
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            ...mapGetters(STORE, {user: GET_USER, token: GET_TOKEN}),
            ...mapGetters(storeModule,[
                'getConfigurationStep',
                'getDomainName',
                'getOriginRoute',
                'getResponseRoute',
                'getDomainsList'
            ]),
            saveAvailable(){
                return this.$store.getters[`${storeModule}/getDomainName`] === "" || this.$store.getters[`${storeModule}/getResponseRoute`] === "" ||
                this.$store.getters[`${storeModule}/getDomainName`] === undefined || this.$store.getters[`${storeModule}/getResponseRoute`] === undefined;
            }
        },
        methods: {
            /*
            This function gets all general info for api configuration as process step and domains info
             */
            getApiConfigurationInfo() {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Organizacion seleccionada
                    user: this.user._id
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.$store.dispatch(`${storeModule}/getOrganizationApiInfo`, params);
            },
            /*
            Auxiliary function to identify input field by its label
             */
            getDomainInputLabel(index){
                return `${this.$t('api-configuration.api-domains.domain-input-label')} ${index}`;
            },
            /*
            Auxiliary function to identify input field by its Id
             */
            getDomainInputId(index){
                return `dom${index}`;
            },
            /*
            Auxiliary function to identify tooltip by its Id
             */
            getDomainTooltipId(index){
                return `tooltip-dom${index}`;
            },
            /*
            Adds an input field for another domain
             */
            addEmptyElementToDomainsList(){
                this.$store.commit(`${storeModule}/ADD_EMPTY_DOMAIN`);
            },
            /*
            Defines which domain is going to be deleted
             */
            setDomainToDelete(index){
                this.elementToDelete = index;
            },
            /*
            Delete a domain base on elementToDelete
             */
            deleteDomainElement(){
                this.$store.commit(`${storeModule}/DELETE_DOMAIN`, this.elementToDelete);
                this.elementToDelete = null;
            },
            /*
            Change the value of one domain and ist ip
             */
            async changeDomain(newDomain, index) {
                if (newDomain !== "") {
                    const result = await this.$store.dispatch(`${storeModule}/getIpFromDomain`, {domain: newDomain, index: index});
                    if (result.error === true) {
                        utils.toast(this.$snotify, 'error', result.data, this.$t(ERROR_TITLE_KEY));
                    }
                }
            },
            /*
            Listener of input fields to validate formar
             */
            changeDomainName: debounce(function (newValue) {
                this.$store.commit(`${storeModule}/SET_DOMAIN_NAME`, newValue);
            }, 100, false),
            changeOriginRoute: debounce(function (newValue) {
                this.$store.commit(`${storeModule}/SET_ORIGIN_ROUTE`, newValue);
            }, 100, false),
            changeResponseRoute: debounce(function (newValue) {
                this.$store.commit(`${storeModule}/SET_RESPONSE_ROUTE`, newValue);
            }, 100, false),
            /*
            Function that save all form information about domains info
             */
            async saveDomainInformation(){
                this.$store.commit(`${storeModule}/SET_ORIGIN_ROUTE`, "");

                if(!this.validateUrl(this.$store.getters[`${storeModule}/getResponseRoute`])){
                    this.$store.commit(`${storeModule}/SET_RESPONSE_ROUTE`, "");
                } else {

                    if (this.$store.getters[`${storeModule}/getDomainName`] !== "" && this.$store.getters[`${storeModule}/getDomainName`] !== undefined) {
                        const data = {
                            organization: this.currentOrganization._id.toString(), // Organizacion seleccionada
                            user: this.user._id
                        };

                        const result = await this.$store.dispatch(`${storeModule}/saveDomainInformation`, data);
                        if(result.error === true){
                            utils.toast(this.$snotify, 'error', result.message, this.$t(ERROR_TITLE_KEY));
                        }else{
                            utils.toast(this.$snotify, 'success', result.message, this.$t(SUCCESS_TITLE_KEY));
                            this.$router.push('/client-admin/api-rest')
                        }
                    } else {
                        utils.toast(this.$snotify, 'error',this.$t('api-configuration.api-domains.missing-fields'), this.$t(ERROR_TITLE_KEY));
                    }
                }
            },
            validateUrl: function (value) {
                const validation = reWeburl.test(value);
                if (!validation) {
                    utils.toast(this.$snotify, 'error', `La URL ${value} no es valida`, this.$t(ERROR_TITLE_KEY));
                }
                return validation;
            }
        },
        mounted() {
            this.getApiConfigurationInfo();
        }
    }
</script>

