<template lang="html">
    <div>
        <CardSimple
            :title="$t('pagos.web-service.config.title')"
            :subtitle="$t('pagos.web-service.config.subtitle')"
        >
            <b-alert
                class="alert-note"
                show
                variant="warning"
            >
                <p class="text-container">
                    <img
                        class="img-fluid"
                        src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                        alt="info"
                    />
                    {{$t('pagos.web-service.config.warning')}}
                </p>
            </b-alert>

            <p class="f-14 c-plain_text body-font-regular m-b-20">
                {{$t('pagos.web-service.config.url-info')}}
            </p>

            <div class="row m-b-30">
                <div class="col-md-4 col-12">
                    <img
                        class="img-fluid max-w-260px"
                        src="@/assets/images/Default/code-example.jpg"
                        alt="code"
                    >
                </div>
                <div class="col-md-8 col-12">
                    <ul class="p-t-10">
                        <li class="body-font-regular f-14 c-plain-text">
                            <strong class="c-title">{{$t('pagos.web-service.config.doc.name')}}</strong> <br>
                            {{$t('pagos.web-service.config.doc.name-description')}}
                        </li>
                        <li class="body-font-regular f-14 c-plain-text">
                            <strong class="c-title">{{$t('pagos.web-service.config.doc.items')}}</strong> <br>
                            {{$t('pagos.web-service.config.doc.items-description')}} <br>
                            {{$t('pagos.web-service.config.doc.items-description-2')}}
                        </li>
                        <li class="body-font-regular f-14 c-plain-text">
                            <strong class="c-title">{{$t('pagos.web-service.config.doc.references')}}</strong> <br>
                            {{$t('pagos.web-service.config.doc.references-description')}} <br>
                            {{$t('pagos.web-service.config.doc.references-description-2')}}
                        </li>
                    </ul>
                </div>
            </div>

            <BasicSelect
                required
                :label="$t('pagos.web-service.config.method.label')"
                labelFor="metodo"
            >
                <multiselect
                    @input="updateMethodvalue"
                    v-model="method"
                    :options="methods"
                    :close-on-select="true"
                    :preserve-search="true"
                    :placeholder="$t('pagos.web-service.config.method.placeholder')"
                    label="name"
                    track-by="name"
                    :preselect-first="false"
                    :custom-label="customMethodLabel"
                >
                    <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                    >
                        <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} options selected</span>
                    </template>
                </multiselect>
            </BasicSelect>

            <div class="d-flex align-items-c">
                <div class="w-100 ">
                    <div class="row" v-for="(param, index) in payment.params" :key="index">
                        <div class="col-md-2 m-b-30">
                            <BasicInput
                                required
                                labelFor="parametro"
                                :inputId="`${index}_name`"
                                :label="$t('pagos.web-service.config.parameter.label')"
                                :inputPlaceholder="$t('pagos.web-service.config.parameter.placeholder')"
                                v-model="param.name"
                                @input="dataChanged"
                            >
                            </BasicInput>
                        </div>
                        <div class="col-md-3 m-b-30">
                            <BasicInput
                                clazz="m-b-0"
                                required
                                labelFor="parametro"
                                :inputId="`${index}_desc`"
                                :label="$t('pagos.web-service.config.parameter-description.label')"
                                :inputPlaceholder="$t('pagos.web-service.config.parameter-description.placeholder')"
                                v-model="param.description"
                            >
                            </BasicInput>
                        </div>
                        <div class="col-md-4 m-b-30">
                            <BasicSelectComplete
                                :required="true"
                                :options="paramTypes"
                                v-model="param.type"
                                :label="$t('pagos.web-service.config.parameter-type.label')"
                                :optionLabel="(o) => $t(`pagos.web-service.config.parameter-type.${o}`)"
                            ></BasicSelectComplete>
                        </div>

                        <div class="col-6 col-md-1 d-flex p-b-10 m-b-30">
                            <div>
                                <label class="" for="paramtype">{{$t('pagos.web-service.config.query-string')}} </label>
                                <BasicCheckbox
                                    id="paramtype"
                                    clazz="m-0"
                                    text=""
                                    :index="0"
                                    v-model="param.auto"
                                ></BasicCheckbox>
                            </div>
                        </div>
                        <div class="col-6 col-md-1 d-flex p-b-10 m-b-30">
                            <div>
                                <label class="" for="paramtype">{{$t('pagos.web-service.config.param-scan')}} </label>
                                <BasicCheckbox
                                    id="paramtype"
                                    clazz="m-0"
                                    text=""
                                    :index="0"
                                    v-model="param.scan"
                                ></BasicCheckbox>
                            </div>
                        </div>
                        <div class="col-6 col-md-1 m-b-30">
                            <button class="button-circle button-ghost button-error sm f-20 m-t-20" @click="removeParam(index)">
                                <i aria-hidden="true" class="banana banana-delete"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <template v-if="this.payment.method === 'POST' || this.payment.method === 'GET'">
    <div>
        <a
            class="button-square-add m-b-30"
            tabindex
            @click="addParam"
        >
            <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{$t('pagos.web-service.config.parameter.add')}}
        </a>
        <div class="d-flex align-items-c m-b-30">
            <div class="w-100 p-r-30">
                <BasicInput
                    clazz="m-b-0"
                    required
                    labelFor="servicio"
                    inputId="servicio"
                    :label="$t('pagos.web-service.config.url.label')"
                    :inputPlaceholder="$t('pagos.web-service.config.url.placeholder')"
                    v-model="payment.url_final"
                    @input="finalUrlUpdated"
                >
                </BasicInput>

            </div>
            <b-dropdown
                id="dropdown-add"
                right
            >
                <template v-slot:button-content>
                    <button
                        type="button"
                        :class="addParamButtonClass"
                    >
                        <i aria-hidden="true" class="banana banana-symbol-plus"></i>
                    </button>
                </template>
                <b-dropdown-group>
                    <b-dropdown-item-button
                        v-for="(param, index) in paramsToAdd"
                        :key="index"
                        @click="addParamToURL(param)"
                    >
                        {{param.name}}
                    </b-dropdown-item-button>
                </b-dropdown-group>
            </b-dropdown>
        </div>
    </div>
</template>

            <template v-if="this.payment.method === 'POST'">
    <div>
        <BasicSelect
            required
            :label="$t('pagos.web-service.config.content-type.label')"
            labelFor="type"
        >
            <multiselect
                v-model="contentType"
                :options="contentTypes"
                :close-on-select="true"
                :preserve-search="true"
                :placeholder="$t('pagos.web-service.config.content-type.placeholder')"
                label="name"
                track-by="name"
                :preselect-first="false"
                @input="dataChanged"
            >
                <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                >
                    <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected
                    </span>
                </template>
            </multiselect>
        </BasicSelect>
        <div class="d-flex align-items-c m-b-30">
            <div class="w-100 p-r-30">
                <BasicTextarea
                    hideCounter
                    required
                    labelFor="body"
                    textareaId="body"
                    :label="$t('pagos.web-service.config.body.label')"
                    :textareaPlaceholder="$t('pagos.web-service.config.body.placeholder')"
                    v-model="payment.body"
                    @input="dataChanged"
                >
                </BasicTextarea>
            </div>
            <b-dropdown
                id="dropdown-add"
                right
            >
                <template v-slot:button-content>
                    <button
                        type="button"
                        :class="addParamButtonClass"
                    >
                        <i aria-hidden="true" class="banana banana-symbol-plus"></i>
                    </button>
                </template>
                <b-dropdown-group>
                    <b-dropdown-item-button
                        v-for="(param, index) in paramsToAdd"
                        :key="index"
                        @click="addParamToBody(param)"
                    >
                        {{param.name}}
                    </b-dropdown-item-button>
                </b-dropdown-group>
            </b-dropdown>
        </div>
    </div>
</template>

            <div class="row m-t-40">
                <div class="col-12 vertical-center justify-content-end">
                    <button
                         v-if="!editMode"
                        class="button button-ghost sm button-plain_text m-0-auto-0-0"
                        @click="prev"
                    >
                        <i aria-hidden="true" class="banana banana-chevron-left"></i>
                        {{ $t('general.actions.back.title') }}
                    </button>
                    <div class="vertical-center">
                        <button
                            class="button button-ghost sm button-plain_text"
                            @click="cancel"
                        >
                            {{ $t('general.actions.cancel.title') }}
                        </button>
                        <button
                            v-if="!editMode"
                            class="button button-filled sm button-accent1 m-l-10"
                            :class="{disabled: (!testedAtLeastOnce && !payment.testedService) || !isValid}"
                            @click="next"
                        >
                            {{ $t('general.actions.next.title') }}
                        </button>
                        <button
                            v-if="editMode"
                            class="button button-filled sm button-accent1 m-l-10"
                            :class="{disabled: (!testedAtLeastOnce && !payment.testedService) || !isValid}"
                            @click="finish"
                        >
                            {{$t('general.actions.save')}}
                        </button>
                    </div>
                </div>
            </div>
        </CardSimple>

        <CardSimple v-if="!isValid">
            <EmptyState
                emptyStateDefault
                :imgUrl="ImagenEmptyStateDefault"
                size="sm"
                :title="$t('pagos.web-service.config.test.empty-title')"
                :text="$t('pagos.web-service.config.test.empty-text')"
            ></EmptyState>
        </CardSimple>

        <div class="p-relative" v-if="isValid">
            <LoadingBar v-if="testing" :loadingText="$t('pagos.web-service.config.test.testing')"></LoadingBar>
            <CardSimple
                :title="$t('pagos.web-service.config.test.title')"
                :subtitle="$t('pagos.web-service.config.test.subtitle')"
            >
                <div class="m-b-30" v-for="(param, index) in payment.params" :key="index">
                    <BasicInput
                        clazz="m-b-0"
                        required
                        labelFor="id"
                        inputId="id"
                        v-model="payment.params[index].value"
                        :label="param.description"
                        :inputPlaceholder="$t('pagos.web-service.config.test.param-placeholder', param)"
                    >
                    </BasicInput>
                </div>

                <div class="row" v-if="testedAtLeastOnce || this.payment.testedService">
                    <div class="col-md-6">
                        <h3 class="m-0 m-b-15 f-14 font-regular-bold c-title">
                            {{$t('pagos.web-service.config.test.results')}}
                        </h3>
                        <div class="dots-table m-b-30">
                            <ul>
                                <li>
                                    <div class="item-row">
                                        <div class="left-side">
                                            Request
                                        </div>
                                        <div class="right-side">
                                            <Badge
                                                :type="testResultBadgeData.request.class"
                                                :text="testResultBadgeData.request.text"
                                            ></Badge>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="item-row">
                                        <div class="left-side">
                                            Response
                                        </div>
                                        <div class="right-side">
                                            <Badge
                                                :type="testResultBadgeData.response.class"
                                                :text="testResultBadgeData.response.text"
                                            ></Badge>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="item-row">
                                        <div class="left-side">
                                            Data
                                        </div>
                                        <div class="right-side">
                                            <Badge
                                                :type="testResultBadgeData.data.class"
                                                :text="testResultBadgeData.data.text"
                                            ></Badge>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <BasicSelect
                            required
                            :label="$t('pagos.web-service.config.test.status.label')"
                            labelFor="status"
                        >
                            <multiselect
                                :disabled="!payment.testedService"
                                v-model="status"
                                :options="statusOptions"
                                :close-on-select="true"
                                :preserve-search="true"
                                :placeholder="$t('pagos.web-service.config.test.status.placeholder')"
                                label="name"
                                track-by="name"
                                :preselect-first="false"
                            >
                                <template
                                    slot="selection"
                                    slot-scope="{ values, search, isOpen }"
                                >
    <span
        class="multiselect__single"
        v-if="values.length &amp;&amp; !isOpen"
    >
        {{ values.length }} options selected
    </span>
</template>
                            </multiselect>
                        </BasicSelect>
                        <Badge
                            :type="status.badgeClass"
                            :text="status.name"
                        ></Badge>
                    </div>
                </div>

                <b-alert
                    class="alert-note"
                    show
                    variant="danger"
                    v-if="testedAtLeastOnce && !payment.testedService"
                >
                    <p class="text-container">
                        <img
                            class="img-fluid"
                            src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                            alt="error"
                        />
                        {{$t('pagos.web-service.config.test.error')}}
                    </p>
                </b-alert>

                <div class="row m-t-40">
                    <div class="col-12 vertical-center justify-content-end">
                        <button
                            class="button button-stroke sm button-accent1 m-0-auto-0-0"
                            @click="next"
                            v-if="!editMode"
                        >
                            {{$t('pagos.web-service.config.test.later')}}
                        </button>
                        <div class="vertical-center">
                            <button
                                class="button button-ghost sm button-plain_text"
                                @click="cancel"
                            >
                                {{ $t('general.actions.cancel.title') }}
                            </button>
                            <button
                                @click="testServiceRequest"
                                class="button button-filled sm button-accent1 m-l-10"
                                :class="{disabled: !canTest}"
                            >
                                {{$t('pagos.web-service.config.test.now')}}
                            </button>
                        </div>
                    </div>
                </div>

            </CardSimple>
        </div>
    </div>
</template>

<script lang="js">
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from '@/components/CardSimple.vue';
import ToastSnotify from '@/mixins/toastSnotify.mixin';
import BasicSelect from "@/components/BasicSelect.vue";
import Multiselect from "vue-multiselect";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import BasicTextarea from "@/components/BasicTextarea.vue";
import EmptyState from "@/components/EmptyState.vue";
import ImagenEmptyStateDefault from '@/assets/images/Emptystates/emptystate-webservice.svg';
import LoadingBar from "@/components/LoadingBar.vue";
import Badge from "@/components/Badge";
import PaymentsApi from '@/api/payments/payment.api.js';
import BasicSelectComplete from '@/components/BasicSelectComplete.vue';


const PAYMENT_INACTIVE_STATUS_KEY = 'pagos.general.servicio.status.inactive'

const BADGE_SUCCESS_CLASS = 'badge-success';
const BADGE_ERROR_CLASS = 'badge-error';
const BADGE_ACCENT_2_CLASS = 'badge-accent_2';
const CONTENT_TYPE_JSON = 'application/json';

const paramTypes = ["TEXT", "NUMBER", "CURRENCY", "BARCODE", "PHONE"];

export default {
    name: 'general',
    mixins: [ToastSnotify],
    components: {
        CardSimple,
        BasicInput,
        BasicSelect,
        Multiselect,
        BasicCheckbox,
        BasicTextarea,
        EmptyState,
        LoadingBar,
        BasicSelectComplete,
        Badge
    },
    props: {
        editMode: {type: Boolean, default: false},
        payment: {
            type: Object,
            default: function () {
                return {
                    name: '',
                    body: '',
                    url_final: '',
                    content_type: '',
                    url: '',
                    method: '',
                    organization: '',
                    externalIdentifier: '',
                    additionalNotesConfig: {
                        isEnabled: false,
                        isRequired: false,
                        label: '',
                        placeholder: ''
                    },
                    clasification: '',
                    testedService: true,
                    params: [],
                    type: 'WS',
                }
            },
        },
    },
    mounted () {
        if (this.payment) {
            const {activeService, sandBoxMode, comingSoon} = this.payment;
            this.status = this.statusOptions.find(o => o.active === activeService && o.sandBoxMode === sandBoxMode && o.comingSoon === comingSoon);

            this.method = this.methods.find(m => m.name === this.payment.method);
            this.contentType = this.contentTypes.find(m => m.name === this.payment.content_type);
        } else {
            this.status = { name: this.$t(PAYMENT_INACTIVE_STATUS_KEY), active: false, sandBoxMode: true, comingSoon: false, badgeClass: BADGE_ACCENT_2_CLASS };
        }
    },
    watch: {
        'status': function(status) {
            const {active, sandBoxMode, comingSoon} = (status || {});
            this.payment.activeService = active;
            this.payment.sandBoxMode = sandBoxMode;
            this.payment.comingSoon = comingSoon;
        },
        'method': function(method) {
            if (method) {
                this.payment.method = method.name;
            }
        },
        'contentType': function(contentType) {
            if (contentType) {
                this.payment.content_type = contentType.value;
            }
        }
    },
    data () {
      return {
            ImagenEmptyStateDefault,
            methods: [
                { label: 'Método GET', name: 'GET' },
                { label: 'Método POST', name: 'POST' }
            ],
            contentTypes: [
                { name: CONTENT_TYPE_JSON, value: CONTENT_TYPE_JSON },
                { name: 'application/x-www-form-urlencoded', value: 'application/x-www-form-urlencoded' },
                { name: 'text/plain', value: 'text/plain' },
            ],
            statusOptions: [
                { name: this.$t('pagos.general.servicio.status.active'), active: true, sandBoxMode: false, comingSoon: false, badgeClass: BADGE_ACCENT_2_CLASS },
                { name: this.$t(PAYMENT_INACTIVE_STATUS_KEY), active: false, sandBoxMode: true, comingSoon: false, badgeClass: BADGE_ACCENT_2_CLASS },
                { name: this.$t('pagos.general.servicio.status.comingSoon'), active: false, sandBoxMode: true, comingSoon: true, badgeClass: BADGE_ACCENT_2_CLASS }
            ],
            method: { label: 'Método GET', name: 'GET' },
            contentType: { name: CONTENT_TYPE_JSON, value: CONTENT_TYPE_JSON },
            testing: false,
            status: { name: this.$t(PAYMENT_INACTIVE_STATUS_KEY), active: false, sandBoxMode: true, comingSoon: false, badgeClass: BADGE_ACCENT_2_CLASS },
            fields: [],
            testedAtLeastOnce: false,
            tested: false,
            paramTypes,
            testResults: {
                request: false,
                response: false,
                data: false
            }
        }
    },
    methods: {
        prev () {
            this.$emit('prev');
        },
        cancel() {
            this.$emit('cancel');
        },
        next() {
            this.$emit('next');
        },
        finish() {
            this.$emit('finish');
        },
        update() {
            this.$forceUpdate();
        },
        updateMethodvalue(evt) {
            this.$forceUpdate();
            this.payment.method = evt.name;
        },
        addParam() {
            if (!this.payment.params) {
                this.payment.params = [];
            }
            this.payment.params.push({
                name: '',
                description: '',
                value: '',
                scan: false,
            });
            this.update();
        },
        removeParam(index) {
            this.payment.params.splice(index, 1);
        },
        testServiceRequest() {
            this.payment.tested = true;
            this.testing = true;
            const body = {...this.payment};
            body.fields = this.convertFieldsMapToObjects(body.params);

            PaymentsApi.testWSPaymentRequest(body).then((data) => {
                this.testing = false;
                this.$set(this, 'testResults', data.data.object);
                this.$set(this, 'testedAtLeastOnce', true);
                if (data.data.object.request && data.data.object.response && data.data.object.data) {
                    this.$set(this.payment, 'testedService', true);
                    this.$set(this.payment, 'lastTest', new Date());
                } else {
                    this.$set(this.payment, 'testedService', false);
                    this.$set(this.payment, 'lastTest', null);
                    this.$set(this, 'status', {
                        name: this.$t(PAYMENT_INACTIVE_STATUS_KEY),
                        active: false,
                        sandBoxMode: true,
                        comingSoon: false,
                        badgeClass: BADGE_ACCENT_2_CLASS
                    });
                }
            }).catch((error) => {
                this.testing = false;
                const GENERAL_ERROR_TITLE = 'general.actions.error.title';
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(GENERAL_ERROR_TITLE), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(GENERAL_ERROR_TITLE), this.$t('general.actions.error.text'));
                }
            });
        },
        customMethodLabel({label}) {
            return label;
        },
        finalUrlUpdated() {
            this.$set(this.payment, 'url', this.payment.url_final);
            this.dataChanged();
        },
        addParamToURL(param) {
            if (!this.payment.url_final) {
                this.payment.url_final = '';
            }
            if (param.auto) {
                const hasQuestionMark = this.payment.url_final.indexOf('?') >= 1;
                if (hasQuestionMark) {
                    this.$set(this.payment, 'url_final', `${this.payment.url_final}&${param.name}={{${param.name}}}`);
                } else {
                    this.$set(this.payment, 'url_final', `${this.payment.url_final}?${param.name}={{${param.name}}}`);
                }
            } else {
                this.$set(this.payment, 'url_final', `${this.payment.url_final}{{${param.name}}}`);
            }
            this.dataChanged()
            this.finalUrlUpdated();
            this.update();
        },
        addParamToBody(param) {
            if (!this.payment.body) {
                this.payment.body = '';
            }
            this.payment.body += `{{${param.name}}}`;
            this.dataChanged();
            this.update();
        },
        convertFieldsMapToObjects(params){
            return params.map(({name, value}) => {return {name, value}});
        },
        dataChanged() {
            // To make sure every change is properly tested and reset status to inactive
            this.$set(this, 'tested', false);
            this.$set(this.payment, 'testedService', false);
            this.$set(this.payment, 'lastTest', null);
            this.$set(this, 'status', { name: this.$t(PAYMENT_INACTIVE_STATUS_KEY), active: false, sandBoxMode: true, comingSoon: false });
        }
    },
    computed: {
        paramsToAdd() {
            return this.payment.params.filter(p => p.name && p.name.length > 0);
        },
        addParamButtonClass() {
            return this.paramsToAdd.length > 0 ? 'button-circle button-filled button-accent1 sm m-t-15' : 'button-circle button-filled button-accent1 sm m-t-15 disabled';
        },
        allParamsFilled() {
            return !this.payment.params.some(p => {
                return !p.name || !p.description || !p.type;
            })
        },
        isValid() {
            if (
                !this.payment ||
                !this.payment.method ||
                !this.payment.url_final ||
                this.payment.url_final.length === 0 ||
                !this.payment.url ||
                this.payment.url.length === 0 ||
                !this.allParamsFilled
            ) {
                return false;
            }
                  if (this.payment.method === 'POST' && !this.payment.content_type) {
                return false;
            }
            return true;
        },
        testResultBadgeData() {
            const approvedKey = 'general.approved';
            return {
                request: {
                    class: this.testResults.request || this.payment.testedService ? BADGE_SUCCESS_CLASS : BADGE_ERROR_CLASS,
                    text: this.testResults.request || this.payment.testedService ? this.$t(approvedKey) : 'Error'
                },
                response: {
                    class: this.testResults.response || this.payment.testedService ? BADGE_SUCCESS_CLASS : BADGE_ERROR_CLASS,
                    text: this.testResults.response || this.payment.testedService ? this.$t(approvedKey) : 'Error'
                },
                data: {
                    class: this.testResults.data || this.payment.testedService ? BADGE_SUCCESS_CLASS : BADGE_ERROR_CLASS,
                    text: this.testResults.data || this.payment.testedService ? this.$t(approvedKey) : 'Error'
                }
            }
        },
        atLeasOneParamWasUsed() {
            const regex = /{{[A-Za-z0-9]+}}/g;
            const urlMatch = this.payment.url ? this.payment.url.match(regex) : null;
            const bodyMatch = this.payment.body ? this.payment.body.match(regex) : null;
            return (urlMatch && urlMatch.length > 0) || (bodyMatch && bodyMatch.length > 0);
        },
        canTest() {
            return this.allParamsFilled;
        }
    }
}
</script>
