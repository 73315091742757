<template>
    <div class="col-12 p-0">
        <WizardForm clazz="form-wizard-white"
                    :hideFooter="true">
            <template v-slot:wizardView>
                <tab-content
                        :title="$t('api-configuration.magento.instructions.step-one.title')"
                        icon="one"
                >
                    <CardSimple :title="$t('api-configuration.magento.instructions.step-one.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.magento.instructions.step-one.instruction')"></p>
                                <img alt="" class="img-fluid d-flex m-0-auto" :src="catalogoModulos"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                        type="button"
                                        class="button xs button-filled button-accent1 m-0-0-0-auto"
                                        @click="nextStep(1)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.magento.instructions.step-two.title')"
                        icon="two"
                >
                    <CardSimple :title="$t('api-configuration.magento.instructions.step-two.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.magento.instructions.step-two.instruction')"></p>
                                <img alt="" class="img-fluid d-flex m-0-auto" :src="busquedaModulos"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                type="button"
                                class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                @click="previousStep(2)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>

                                <button
                                        type="button"
                                        class="button xs button-filled button-accent1 m-0-0-0-auto"
                                        @click="nextStep(2)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.magento.instructions.step-three.title')"
                        icon="three"
                >
                    <CardSimple :title="$t('api-configuration.magento.instructions.step-three.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.magento.instructions.step-three.instruction')"></p>
                                <img alt="" class="img-fluid d-flex m-0-auto" :src="moduloInstalado"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                        type="button"
                                        class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                        @click="previousStep(3)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>

                                <button
                                        type="button"
                                        class="button xs button-filled button-accent1 m-0-0-0-auto"
                                        @click="nextStep(3)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.magento.instructions.step-four.title')"
                        icon="four"
                >
                    <CardSimple :title="$t('api-configuration.magento.instructions.step-four.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.magento.instructions.step-four.instruction')">
                                </p>

                                <img alt="" class="img-fluid d-flex m-0-auto" :src="configuracionModulo"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                        type="button"
                                        class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                        @click="previousStep(4)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>

                                <button
                                        type="button"
                                        class="button xs button-filled button-accent1 m-0-0-0-auto"
                                        @click="nextStep(4)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.magento.instructions.step-five.title')"
                        icon="five"
                >
                    <CardSimple :title="$t('api-configuration.magento.instructions.step-five.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.magento.instructions.step-five.instruction')">
                                </p>

                                <img alt="" class="img-fluid d-flex m-0-auto" :src="tarjeta"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                        type="button"
                                        class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                        @click="previousStep(5)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
            </template>
        </WizardForm>
    </div>
</template>

<script>
    import busquedaModulos from '@/assets/images/magento/catalogo-modulo-1.png';
    import catalogoModulos from '@/assets/images/magento/busqueda-modulo-1.png';
    import configuracionModulo from '@/assets/images/magento/modulo-instalado-2.png';
    import moduloInstalado from '@/assets/images/magento/configuracion-modulo-2.png';
    import tarjeta from '@/assets/images/prestaShop/tarjeta.png';
    import InstructionsMixin from '@/views/apiWeb/checkouts/instructions.mixin.js';

    export default {
        mixins: [InstructionsMixin],
        name: "magentoInstructions",
        data() {
            return {
                busquedaModulos: busquedaModulos,
                catalogoModulos: catalogoModulos,
                configuracionModulo: configuracionModulo,
                moduloInstalado: moduloInstalado,
                tarjeta: tarjeta
            }
        },
    }
</script>
