<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout
                    :msgSmall="$t('pagos.smallMessage')"
                    :title="$t('pagos.title')"
            />
        </div>
        <div class="col-12">
            <LoadingBar v-if="loading"></LoadingBar>
            <CardSimple
                    hideTitle
                    clazz="p-0"
                    clazzTitle="d-none"
                    v-if="!foundValues && !loading && searchText === '' && hasFilters === false"
            >
                <EmptyState
                        title="¡Crea tu primer Pago!"
                        text="Comienza a configurar los tipos de pago que tus clientes podrán realizar en tu organización
                         a través de Pagando. Escoge los que más se adapten a tus necesidades."
                        :imgUrl="EmptyStateGroupImg"
                >
                    <b-dropdown
                            id="dropdown-add"
                            dropright
                            class="dropdown-image-list"
                    >
                        <template v-slot:button-content>
                            <button class="button xs button-filled button-accent1">
                                <i aria-hidden="true" class="banana banana-symbol-plus"></i>Nuevo Pago
                            </button>
                        </template>
                        <b-dropdown-group header="Selecciona el tipo de pago que deseas crear:">
                            <b-dropdown-item-button
                                    v-for="option in buttonDropDownOptions"
                                    v-bind:key="option.name"
                                    @click="clickEventDropDown(option.to)"
                            >
                                <div class="options-list">
                                    <img
                                            class="img-fluid"
                                            :src="option.image"
                                            alt=""
                                    />
                                    <span>
                                        <label>{{option.label}}</label>
                                        <p>{{option.description}}</p>
                                    </span>
                                </div>
                            </b-dropdown-item-button>
                        </b-dropdown-group>
                    </b-dropdown>
                </EmptyState>
            </CardSimple>
        </div>
        <div
                class="col-12"
                v-if="foundValues"
        >
            <SearchBar
                    :buttonText="buttonTextNewElement"
                    @filterSelected="filterChanged"
                    :columValues="filtersText"
                    @routerPush="goTo"
                    @changeListLimit="changeListLimit"
                    :perPage="limit"
                    @searchTextChange="search"
                    :buttonDropDownOptions="buttonDropDownOptions"
                    ref="searchBar"
            ></SearchBar>
        </div>

        <div
                class="col-12"
                v-if="foundValues"
        >
            <LoadingBar v-if="loading"></LoadingBar>
            <CardSimple
                    clazzTitle="d-none"
                    clazz="w-100 title-padding p-0"
            >
                <div
                        class="basic-table"
                        id="tableOne"
                >
                    <b-table-simple
                            responsive
                            hover
                            ref="selectableTable"
                            selectable
                            :select-mode="selectMode"
                            selected-variant="active"
                            table-variant="xl"
                            :busy="!loading"
                    >
                        <b-thead>
                            <b-tr
                                    :class="headTableRowClass"
                                    v-if="(foundValues && !loading && hasFilters ) || (foundValues) || foundValues === false && hasFilters === true "
                            >
                                <b-th sortable>
                                    <div class="align-items-c d-flex">
                                        <BasicCheckbox
                                                ref="selectAll"
                                                v-model="selectAll"
                                                clazz="m-0 w-fit di-flex"
                                        ></BasicCheckbox>
                                        <span
                                                v-show="showColumns[0].value"
                                                class="open-filters"
                                                @click="headerFilters()"
                                        >{{ $t('pagos.columns.name') }}</span>
                                        <SortIcon
                                                @sortUp="sort.name = 1"
                                                @sortDown="sort.name = -1"
                                        ></SortIcon>
                                    </div>
                                    <div
                                            v-show="showColumns[0].value"
                                            class="filters"
                                            :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                                v-model="match.name"
                                                :labelFor="$t('pagos.columns.name') "
                                                :inputId="$t('pagos.columns.name') "
                                                :inputPlaceholder="$t('pagos.columns.name') "
                                                clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>

                                <b-th v-if="showColumns[1].value">
                                    <div class="align-items-c d-flex">
                                        <span
                                                class="open-filters"
                                                @click="headerFilters()"
                                        >{{ $t('pagos.columns.serviceType') }}</span>
                                        <SortIcon
                                                @sortUp="sort.classification = 1"
                                                @sortDown="sort.classification = -1"
                                        ></SortIcon>
                                    </div>
                                    <div
                                            class="filters"
                                            :class="{show: filtersHeader}"
                                    >
                                        <BasicSelect>
                                            <multiselect
                                                    v-model="match.classification"
                                                    :allow-empty="false"
                                                    :options="categories"
                                                    :close-on-select="true"
                                                    :preserve-search="true"
                                                    placeholder="Selecciona..."
                                                    label="name"
                                                    track-by="name"
                                                    :preselect-first="false"
                                            >
                                                <template
                                                        slot="selection"
                                                        slot-scope="{ values, search, isOpen }"
                                                >
                                                    <span
                                                            class="multiselect__single"
                                                            v-if="values.length &amp;&amp; !isOpen"
                                                    >{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>

                                <b-th v-if="showColumns[2].value">
                                    <div class="align-items-c d-flex">
                                        <span
                                                class="open-filters"
                                                @click="headerFilters()"
                                        >{{ $t('pagos.columns.paymentType') }}</span>
                                        <SortIcon
                                                @sortUp="sort.type = 1"
                                                @sortDown="sort.type = -1"
                                        ></SortIcon>
                                    </div>
                                    <div
                                            class="filters"
                                            :class="{show: filtersHeader}"
                                    >
                                        <BasicSelect>
                                            <multiselect
                                                    v-model="match.type"
                                                    :allow-empty="false"
                                                    :options="paymentTypes"
                                                    :close-on-select="true"
                                                    :preserve-search="true"
                                                    placeholder="modelo"
                                                    label="name"
                                                    track-by="name"
                                                    :preselect-first="false"
                                            >
                                                <template
                                                        slot="selection"
                                                        slot-scope="{ values, search, isOpen }"
                                                >
                                                    <span
                                                            class="multiselect__single"
                                                            v-if="values.length &amp;&amp; !isOpen"
                                                    >{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>

                                <b-th
                                        v-if="showColumns[4].value"
                                        class="text-align-c"
                                >
                                    <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                    >{{ $t('pagos.columns.status') }}</span>
                                    <div
                                            class="filters"
                                            :class="{show: filtersHeader}"
                                    >
                                        <BasicSelect>
                                            <multiselect
                                                    v-model="match.status"
                                                    :allow-empty="false"
                                                    :options="statusList"
                                                    :close-on-select="true"
                                                    :preserve-search="true"
                                                    :placeholder="$t('pagos.columns.status')"
                                                    label="name"
                                                    track-by="name"
                                                    :preselect-first="false"
                                            >
                                                <template
                                                        slot="selection"
                                                        slot-scope="{ values, search, isOpen }"
                                                >
                                                    <span
                                                            class="multiselect__single"
                                                            v-if="values.length &amp;&amp; !isOpen"
                                                    >{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>

                                <b-th v-if="showColumns[3].value">
                                    <div class="align-items-c d-flex">
                                        <span
                                                class="open-filters"
                                                @click="headerFilters()"
                                        >{{ $t('pagos.columns.details') }}</span>
                                    </div>
                                </b-th>

                                <b-th class="text-align-c">
                                    <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                    >{{ $t('pagos.columns.actions') }}</span>
                                </b-th>

                                <b-th class="row-options">
                                    <div class="vertical-center">
                                        <BasicCheckbox
                                                ref="selectAllHeader"
                                                v-model="selectAllHeader"
                                                clazz="m-0 w-fit di-flex"
                                                :text="$tc('general.selected-elements', totalSelectedRows)"
                                                textClass="f-14 c-primary_1 principal-font-bold"
                                        ></BasicCheckbox>
                                        <div>
                                            <a
                                                    class="opt-row"
                                                    tabindex
                                                    @click="startDeleteMulti"
                                            >
                                                <i aria-hidden="true" class="banana banana-delete"></i>
                                                {{$t('general.actions.delete.title')}}
                                            </a>
                                            <a
                                                    class="opt-row"
                                                    tabindex
                                                    @click="startCopyMulti"
                                            >
                                                <i aria-hidden="true" class="banana banana-copy"></i> {{$t('general.actions.copy.title')}}
                                            </a>
                                            <a
                                                    @click="unselectAll"
                                                    class="opt-row"
                                                    tabindex
                                            >{{$t('general.actions.cancel.title')}}</a>
                                        </div>
                                    </div>
                                </b-th>

                            </b-tr>
                        </b-thead>

                        <b-tbody v-if="payments.length === 0">
                            <b-tr
                                    role="row"
                                    class="b-table-busy-slot"
                            >
                                <b-td
                                        colspan="6"
                                        role="cell"
                                        class=""
                                >
                                    <EmptyState
                                            emptyStateDefault
                                            size="sm"
                                            title="No se encontraron resultados con la búsqueda actual"
                                    >
                                        <button
                                                @click="resetFilters"
                                                type="button"
                                                class="button xs button-stroke button-accent1 m-0-auto m-t-30"
                                        >Reiniciar filtros
                                        </button>
                                    </EmptyState>
                                </b-td>
                            </b-tr>
                        </b-tbody>

                        <b-tbody v-if="payments.length > 0">
                            <!-- aqui comienza el recorrido de los pagos -->
                            <b-tr
                                    v-for="(payment, index) in payments"
                                    v-bind:key="payment._id + ' - ' + index"
                                    :class="bodyTableRowClass(index)"
                            >
                                <b-td>
                                    <div class="d-flex align-items-c">
                                        <BasicCheckbox
                                                v-model="selectedRows[index]"
                                                clazz="m-0 w-fit di-flex"
                                                :ref="checkboxRefName(index)"
                                        ></BasicCheckbox>
                                        <strong v-if="showColumns[0].value">{{payment.name}}</strong>
                                    </div>
                                </b-td>

                                <b-td v-if="showColumns[1].value">
                                    {{payment.serviceType}}
                                </b-td>
                                <b-td v-if="showColumns[2].value">
                                    {{payment.paymentType}}
                                </b-td>
                                <b-td
                                        v-if="showColumns[4].value"
                                        class="text-align-c"
                                >
                                    <Badge
                                            v-if="payment.payment.activeService"
                                            type="badge-success"
                                            :text="$t('pagos.rows.status.active')"
                                    ></Badge>
                                    <Badge
                                            v-if="payment.payment.comingSoon"
                                            type="badge-warning"
                                            :text="$t('pagos.rows.status.comingSoon')"
                                    ></Badge>
                                    <Badge
                                            v-if="!payment.payment.comingSoon && !payment.payment.activeService"
                                            type="badge-placeholder"
                                            :text="$t('pagos.rows.status.inactive')"
                                    ></Badge>
                                </b-td>
                                <b-td
                                        v-if="showColumns[3].value"
                                        class="whitespace-nowrap"
                                >
                                    {{payment.details}}
                                </b-td>
                                <b-td class="text-align-c">
                                    <b-dropdown
                                            @show="showDropdown"
                                            @hide="hideDropdown"
                                            id="dropdown-table"
                                            right
                                            class="dropdown-table"
                                    >
                                        <template v-slot:button-content>
                                            <button class="button-circle xs button-ghost button-info_text">
                                                <i aria-hidden="true" class="banana banana-options-other-vertical"></i>
                                            </button>
                                        </template>

                                        <b-dropdown-group header="Mas Acciones">

                                            <b-dropdown-item-button>
                                                <router-link
                                                        :to="`payments/edit/${payment.category}/${payment._id}`"
                                                        tabindex
                                                >
                                                    <i aria-hidden="true" class="banana banana-edit"></i> {{$t('pagos.rows.actions.edit')}}
                                                </router-link>
                                            </b-dropdown-item-button>

                                            <b-dropdown-item-button v-if="payment.category === 'D'">
                                                <router-link
                                                        :to="`/client-admin/debtors-list/${payment.payment._id}`"
                                                        tabindex
                                                >
                                                    <i aria-hidden="true" class="banana banana-wrench"></i>
                                                    {{$t('pagos.rows.actions.configureDebts')}}
                                                </router-link>
                                            </b-dropdown-item-button>

                                            <b-dropdown-item-button v-if="payment.category === 'WS'">
                                                <a @click="showTestServiceModal(payment)">
                                                    <i aria-hidden="true" class="banana banana-player-play"></i>
                                                    {{$t('pagos.rows.actions.testService')}}
                                                </a>
                                            </b-dropdown-item-button>

                                            <b-dropdown-item-button>
                                                <a @click="showShareModal(payment)">
                                                    <i aria-hidden="true" class="banana banana-share"></i>
                                                    {{$t('pagos.rows.actions.share')}}
                                                </a>
                                            </b-dropdown-item-button>

                                            <b-dropdown-item-button
                                                    @click="copy([{type: payment.payment.type, id: payment.payment._id}])">
                                                <router-link
                                                        to
                                                        tabindex
                                                >
                                                    <i aria-hidden="true" class="banana banana-copy"></i> {{$t('pagos.rows.actions.copy')}}
                                                </router-link>
                                            </b-dropdown-item-button>
                                            <b-dropdown-divider></b-dropdown-divider>

                                            <b-dropdown-item-button
                                                    variant=" c-error"
                                                    @click="openDeleteModal(payment)"
                                            >
                                                <i aria-hidden="true" class="banana banana-delete"></i> {{$t('pagos.rows.actions.delete')}}
                                            </b-dropdown-item-button>
                                        </b-dropdown-group>
                                    </b-dropdown>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>

                <ModalDefault
                        modalId="modal-compartir"
                        originalFooter
                        :title="$t('organizaciones.contact-info.modal.share.title')"
                        :subtitle="$t('organizaciones.contact-info.modal.share.subtitle')"
                        :buttonClose="$t('general.actions.cancel.title')"
                        :buttonText="$t('general.actions.done.title')"
                        @clickAccepted="saveEmails"
                        @clickClose="clearTags"
                >
                    <div class="row">
                        <div class="col-12 vertical-center m-b-30">
                            <div class="w-90">
                                <BasicInput
                                        labelFor="direct-link"
                                        inputId="direct-link"
                                        inputType="text"
                                        readOnly
                                        disabled
                                        :label="$t('organizaciones.contact-info.modal.direct-link')"
                                        :inputPlaceholder="detailsRoute"
                                        v-model="detailsRoute"
                                ></BasicInput>
                            </div>
                            <div class="w-10 vertical-center justify-content-end m-t--10">
                                <button
                                        class="button-circle sm button-ghost button-accent2"
                                        v-b-tooltip.hover
                                        :title="$t('organizaciones.contact-info.buttons.copy-link')"
                                        @click="copyClipboard"
                                >
                                    <i aria-hidden="true" class="banana banana-copy"></i>
                                </button>
                            </div>
                        </div>
                        <div
                                class="col-12"
                                v-if="copiedToClipboard"
                        >
                            <b-alert
                                    class="alert-note"
                                    show
                                    dismissible
                                    variant="success"
                            >
                                <p class="text-container">
                                    <i aria-hidden="true" class="icon banana banana-check-normal"></i>
                                    <span v-html="$t('share.copied')"></span>
                                </p>
                            </b-alert>
                        </div>
                    </div>
                </ModalDefault>

            </CardSimple>
            <div class="pagination-custom">
                <span class="pagination-count">{{ total }} Pagos</span>
                <b-pagination
                        v-model="page"
                        :per-page="limit"
                        :total-rows="total"
                        align="right"
                        next-text
                        prev-text
                        hide-goto-end-buttons
                        class="w-100"
                ></b-pagination>
            </div>
        </div>

        <ModalAlert
                modalId="modal-danger"
                buttonType="button-error"
                :imgUrl="imageModalDelete"
                :title="$tc('pagos.delete.title', selectedDeletePayments.length)"
                :text="$tc('pagos.delete.text', selectedDeletePayments.length)"
                buttonText="Eliminar"
                @submit="deleteSelected"
        ></ModalAlert>

        <ModalDefault
                :title="$tc('pagos.web-service.config.test.title')"
                :subtitle="llenarSubtitle"
                :size="'lg'"
                modalId="modal-llenar"
                buttonClose="Cancelar"
                originalFooter
                :buttonText="testOrSave"
                @clickAccepted="clickTestService()"
                :disabled="saveOrTestDisabled"
                :closeModalAfterAccept="false"
        >
            <BasicInput
                    :key="i"
                    v-for="(field, i) in fields"
                    required
                    v-model="fields[i].value"
                    inputType="text"
                    :labelFor="field.description"
                    :inputId="field.description"
                    :label="field.description"
                    inputPlaceholder=""
            />
            <div
                    class="p-relative"
                    v-if="true"
            >
                <LoadingBar
                        v-if="testing"
                        :loadingText="$t('pagos.web-service.config.test.testing')"
                ></LoadingBar>
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="m-0 m-b-15 f-14 font-regular-bold c-title">
                            {{$t('pagos.web-service.config.test.results')}}
                        </h3>
                        <div class="dots-table m-b-30">
                            <ul>
                                <li>
                                    <div class="item-row">
                                        <div class="left-side">
                                            Request
                                        </div>
                                        <div class="right-side">
                                            <Badge
                                                    :type="testResultBadgeData.request.class"
                                                    :text="testResultBadgeData.request.text"
                                            ></Badge>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="item-row">
                                        <div class="left-side">
                                            Response
                                        </div>
                                        <div class="right-side">
                                            <Badge
                                                    :type="testResultBadgeData.response.class"
                                                    :text="testResultBadgeData.response.text"
                                            ></Badge>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="item-row">
                                        <div class="left-side">
                                            Data
                                        </div>
                                        <div class="right-side">
                                            <Badge
                                                    :type="testResultBadgeData.data.class"
                                                    :text="testResultBadgeData.data.text"
                                            ></Badge>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <BasicSelect
                                required
                                :label="$t('pagos.web-service.config.test.status.label')"
                                labelFor="status"
                        >
                            <multiselect
                                    :disabled="!payment.testedService"
                                    v-model="status"
                                    :options="statusOptions"
                                    :close-on-select="true"
                                    :preserve-search="true"
                                    :placeholder="$t('pagos.web-service.config.test.status.placeholder')"
                                    label="name"
                                    track-by="name"
                                    :preselect-first="false"
                            >
                                <template
                                        slot="selection"
                                        slot-scope="{ values, search, isOpen }"
                                >
                                    <span
                                            class="multiselect__single"
                                            v-if="values.length &amp;&amp; !isOpen"
                                    >
                                        {{ values.length }} options selected
                                    </span>
                                </template>
                            </multiselect>
                        </BasicSelect>
                        <Badge
                                :type="status ? status.badgeClass : ''"
                                :text="status ? status.name : ''"
                        ></Badge>
                    </div>
                </div>

                <b-alert
                        class="alert-note"
                        show
                        variant="danger"
                        v-if="!testedService"
                >
                    <p class="text-container">
                        <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                alt="error"
                        />
                        {{$t('pagos.web-service.config.test.error')}}
                    </p>
                </b-alert>
            </div>
        </ModalDefault>

    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import EmptyStateGroupImg from '@/assets/images/Emptystates/emptystate-payment.svg';
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import Badge from "@/components/Badge";
    import SearchBar from '@/components/SearchBar.vue';
    import PaymentsCRUDApi from '@/api/payments/crud.api.js';
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import BasicSelect from "@/components/BasicSelect.vue";
    import Multiselect from "vue-multiselect";
    import BasicInput from "@/components/BasicInput.vue";
    import LoadingBar from '@/components/LoadingBar.vue';
    import CommonUtils from '@/common/utils';
    import ExpressPaymentIcon from '@/assets/images/Illustrations/illustration-pago-rapido.svg';
    import RegularPaymentIcon from '@/assets/images/Illustrations/illustration-pago-regular.svg';
    import DebtPaymentIcon from '@/assets/images/Illustrations/illustration-pago-requisitos.svg';
    import WebServicePaymentIcon from '@/assets/images/Illustrations/illustration-pago-servicios.svg';
    import categories from '@/enums/categories';
    import ModalDefault from '@/components/ModalDefault.vue';
    import {utils} from '@/api/utils';
    import {shareFacebook, shareTwitter} from '@/common/SocialNetworks';
    import {email, required} from 'vuelidate/lib/validators';
    import config from '@/config';
    import imageModalDelete from "@/assets/images/Illustrations/illustration-modal-delete.svg";
    import ModalAlert from "@/components/ModalAlert.vue";
    import SortIcon from '@/components/SortIcon.vue';
    import PaymentsApi from '@/api/payments/payment.api.js';

    const STATUS_INACTIVE_KEY = 'pagos.general.servicio.status.inactive';
    const BADGE_ACCENT_2_CLASS = 'badge-accent_2';
    const SUCCESS_TITLE_KEY = 'general.actions.success.title';
    const SUCCESS_TEXT_KEY = 'general.actions.success.text';
    const ERROR_TITLE_KEY = 'general.actions.error.title';
    const ERROR_TEXT_KEY = 'general.actions.error.text';
    const BADGE_SUCCESS_CLASS = 'badge-success';
    const BADGE_ERROR_CLASS = 'badge-error';
    const MODAL_DANGER_ID = 'modal-danger';

    export default {
        name: "PaymentsType",
        mixins: [ToastSnotify],
        components: {
            FloatingTitleLayout,
            EmptyState,
            CardSimple,
            BasicCheckbox,
            Badge,
            SearchBar,
            BasicSelect,
            Multiselect,
            BasicInput,
            LoadingBar,
            ModalDefault,
            SortIcon,
            ModalAlert
        },
        data() {
            return {
                selectedDeletePayments: [],
                imageModalDelete,
                copiedToClipboard: false,
                categories: categories.getAllCategories(),
                paymentTypes: [
                    {value: 'P', name: this.$t('pagos.types.P')},
                    {value: 'D', name: this.$t('pagos.types.D')},
                    {value: 'EP', name: this.$t('pagos.types.EP')},
                    {value: 'WS', name: this.$t('pagos.types.WS')}
                ],
                statusList: [
                    {value: 'ACTIVE', name: this.$t('pagos.general.servicio.status.active')},
                    {value: 'INACTIVE', name: this.$t('pagos.general.servicio.status.inactive')},
                    {value: 'COMING_SOON', name: this.$t('pagos.general.servicio.status.comingSoon')},
                ],
                foundValues: false,
                options: [],
                value: null,
                filtersHeader: false,
                buttonTextNewElement: this.$t('general.search-bar.newElementButton'),
                filtersText: [
                    {
                        "label": this.$t('pagos.columns.name'),
                        "name": "service_name",
                        "value": true
                    },
                    {
                        "label": this.$t('pagos.columns.serviceType'),
                        "name": "service_type",
                        "value": true
                    },
                    {
                        "label": this.$t('pagos.columns.paymentType'),
                        "name": "payment_type",
                        "value": true
                    },
                    {
                        "label": this.$t('pagos.columns.details'),
                        "name": "details",
                        "value": true
                    },
                    {
                        "label": this.$t('pagos.columns.status'),
                        "name": "status",
                        "value": true
                    },
                ],
                // TODO cuanto es el limite?
                showColumns: [
                    {column: "name", value: true},
                    {column: "serviceType", value: true},
                    {column: "paymentType", value: true},
                    {column: "Details", value: true},
                    {column: "Status", value: true},
                    {column: "Actions", value: true}
                ],
                buttonDropDownOptions: [
                    {
                        "to": "/client-admin/payments/new/EP",
                        "label": this.$i18n.t("pagos.rapido.name"),
                        "description": this.$i18n.t("pagos.rapido.description"),
                        "image": ExpressPaymentIcon,
                        "name": "pago_rapido"
                    },
                    {
                        "to": "/client-admin/payments/new/P",
                        "label": this.$i18n.t("pagos.regular.name"),
                        "description": this.$i18n.t("pagos.regular.description"),
                        "image": RegularPaymentIcon,
                        "name": "pago_regular"
                    },
                    {
                        "to": "/client-admin/payments/new/D",
                        "label": this.$i18n.t("pagos.requisitos.name"),
                        "description": this.$i18n.t("pagos.requisitos.description"),
                        "image": DebtPaymentIcon,
                        "name": "pago_requisitos"
                    },
                    {
                        "to": "/client-admin/payments/new/WS",
                        "label": this.$i18n.t("pagos.web-service.name"),
                        "description": this.$i18n.t("pagos.web-service.description"),
                        "image": WebServicePaymentIcon,
                        "name": "pago_servicio_web"
                    }
                ],
                searchText: '',
                selectedRows: [],
                selectAll: false,
                selectAllHeader: false,
                badProgramingFlagForSelectAll: false,
                badProgramingFlagForSelectAllHeader: false,
                pages: 0,
                page: 1,
                limit: 10,
                total: 0,
                EmptyStateGroupImg: EmptyStateGroupImg,
                loading: false,
                selectMode: '', // TODO que es esto?
                payments: [],
                match: {
                    name: '',
                    classification: {},
                    type: {},
                    status: {}
                },
                sort: {
                    name: null,
                    classification: null,
                    type: null,
                },
                valueTags: [],
                optionTags: [],
                searchTag: '',
                selectedPayment: {},
                fields: [],
                testedService: true,
                testResults: {
                    request: false,
                    response: false,
                    data: false
                },
                methods: [
                    {label: 'Método GET', name: 'GET'},
                    {label: 'Método POST', name: 'POST'}
                ],
                contentTypes: [
                    {name: 'application/json', value: 'application/json'},
                    {name: 'application/x-www-form-urlencoded', value: 'application/x-www-form-urlencoded'},
                    {name: 'text/plain', value: 'text/plain'},
                ],
                statusOptions: [
                    {
                        name: this.$t('pagos.general.servicio.status.active'),
                        active: true,
                        sandBoxMode: false,
                        comingSoon: false,
                        badgeClass: BADGE_ACCENT_2_CLASS
                    },
                    {
                        name: this.$t(STATUS_INACTIVE_KEY),
                        active: false,
                        sandBoxMode: true,
                        comingSoon: false,
                        badgeClass: BADGE_ACCENT_2_CLASS
                    },
                    {
                        name: this.$t('pagos.general.servicio.status.comingSoon'),
                        active: false,
                        sandBoxMode: true,
                        comingSoon: true,
                        badgeClass: BADGE_ACCENT_2_CLASS
                    }
                ],
                status: "",
                contentType: "",
                method: "",
                payment: {
                    name: '',
                    body: '',
                    url_final: '',
                    content_type: '',
                    url: '',
                    method: '',
                    organization: '',
                    externalIdentifier: '',
                    additionalNotesConfig: {
                        isEnabled: false,
                        isRequired: false,
                        label: '',
                        placeholder: ''
                    },
                    clasification: '',
                    params: [],
                    type: 'WS',
                },
                testOrSave: "Probar",
                testing: false,
                wantToSave: false,
                llenarSubtitle: ""
            }
        },
        created() {
            this.getPayments(this.page);
        },
        methods: {
            convertFieldsMapToObjects(params) {
                return params.map(({name, value}) => {
                    return {name, value}
                });
            },
            clickTestService() {
                if (this.testOrSave === "Probar") {
                    this.testServiceRequest();
                } else {
                    this.save();
                }
            },
            save() {
                PaymentsCRUDApi.save(this.payment.type, this.payment).then(() => {
                    this.$bvModal.hide('modal-llenar');
                    this.testOrSave = "Probar";
                    this.wantToSave = false;
                    utils.toast(this.$snotify, 'success', this.$t(SUCCESS_TEXT_KEY), this.$t(SUCCESS_TITLE_KEY));
                }).catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                })
            },
            testServiceRequest() {
                this.payment.tested = true;
                this.testing = true;
                const body = {...this.payment};

                body.fields = this.convertFieldsMapToObjects(body.params);

                PaymentsApi.testWSPaymentRequest(body).then((data) => {
                    this.testing = false;
                    this.$set(this, 'testResults', data.data.object);
                    this.$set(this, 'testedAtLeastOnce', true);
                    if (data.data.object.request && data.data.object.response && data.data.object.data) {
                        this.$set(this.payment, 'testedService', true);
                        this.$set(this.payment, 'lastTest', new Date());
                    } else {
                        this.$set(this.payment, 'testedService', false);
                        this.$set(this, 'testedService', false);
                        this.$set(this.payment, 'lastTest', null);
                        this.$set(this, 'status', {
                            name: this.$t(STATUS_INACTIVE_KEY),
                            active: false,
                            sandBoxMode: true,
                            comingSoon: false,
                            badgeClass: BADGE_ACCENT_2_CLASS
                        });
                    }
                    this.wantToSave = false;
                }).catch((error) => {
                    this.testing = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            },
            showTestServiceModal(payment) {
                this.payment = payment.payment;
                this.fields = payment.payment.params;
                this.testedService = payment.payment.testedService;
                const {activeService, sandBoxMode, comingSoon} = this.payment;
                this.status = this.statusOptions.find(o => o.active === activeService && o.sandBoxMode === sandBoxMode && o.comingSoon === comingSoon);
                this.method = this.methods.find(m => m.name === this.payment.method);
                this.contentType = this.contentTypes.find(m => m.name === this.payment.content_type);
                if (payment.payment.params.length > 0) {
                    this.llenarSubtitle = "Antes de proceder a tu pago, te pedimos ingreses la siguiente información."
                } else {
                    this.llenarSubtitle = "Tu servicio web esta listo para realizar una prueba."
                }
                this.$bvModal.show('modal-llenar');
            },
            resetFilters() {
                this.searchText = '';
                this.match = {
                    name: '',
                    classification: {},
                    type: {},
                    status: {}
                };
                this.$refs.searchBar.resetInput();
                this.page = 1;
                this.getPayments(this.page)
            },
            unselectAll() {
                this.selectedRows = [];
                this.selectAllHeader = false;
                this.selectAll = false;
                let i;
                for (i = 0; i < this.payments.length; i++) {
                    this.selectedRows[i] = false;
                    this.$refs[this.checkboxRefName(i)][0].checkStoreValue(false);
                }
                if (this.$refs.selectAll) {
                    if (this.$refs.selectAll.checkStoreValue) {
                        this.$refs.selectAll.checkStoreValue(false);
                    } else if (this.$refs.selectAll.length) {
                        this.$refs.selectAll[0].checkStoreValue(false);
                    }
                }
                if (this.$refs.selectAllHeader) {
                    if (this.$refs.selectAllHeader.checkStoreValue) {
                        this.$refs.selectAllHeader.checkStoreValue(false);
                    } else if (this.$refs.selectAllHeader.length) {
                        this.$refs.selectAllHeader[0].checkStoreValue(false);
                    }
                }
            },
            showShareModal(payment) {
                this.$bvModal.show('modal-compartir');
                this.selectedPayment = payment;
            },
            search(text) {
                CommonUtils.debounceFixed(() => {
                    this.searchText = text;
                    this.getPayments(this.page);
                });
            },
            changeListLimit(limit) {
                CommonUtils.debounceFixed(() => {
                    this.limit = limit;
                    if (this.page > 1) {
                        this.page = 1;
                    } else {
                        this.getPayments(this.page);
                    }
                });
            },
            bodyTableRowClass(index) {
                if (this.selectedRows[index]) {
                    return 'b-table-row-selected table-active';
                }
                return '';
            },
            showDropdown() {
                document
                    .getElementById("tableOne")
                    .classList.add("dropdown-table-overflow");
            },
            hideDropdown() {
                document
                    .getElementById("tableOne")
                    .classList.remove("dropdown-table-overflow");
            },
            headerFilters() {
                this.filtersHeader = !this.filtersHeader;
            },
            filterChanged(val) {
                this.showColumns[val.index].value = val.checkValue;
            },
            createMatchForQuery() {
                const match = {
                    name: this.match.name,
                    classification: this.match.classification.value,
                    type: this.match.type.value,
                };

                if (this.match.status.value) {
                    match.activeService = this.match.status.value === 'ACTIVE';
                    match.comingSoon = this.match.status.value === 'COMING_SOON';
                }
                return match;
            },
            clickEventDropDown(to) {
                localStorage.removeItem('editingPayment');
                this.$router.push(to);
            },
            getPayments(page) {
                this.payments = [];
                this.selectedDeletePayments = [];
                this.selectedRows = [];
                this.loading = true;
                PaymentsCRUDApi.all({
                    page: page - 1,
                    perPage: this.limit,
                    search: this.searchText,
                    match: this.createMatchForQuery(),
                    sort: this.sort
                }).then((response) => {
                    this.loading = false;
                    response.data.object.payments.forEach(payment => {
                        const obj = {};

                        let total = 0;
                        if (payment.items) {
                            payment.items.forEach(item => {
                                total += item.total;
                            });
                        }

                        obj.payment = payment;
                        obj.category = payment.type;
                        obj.type = payment.type;
                        obj._id = payment._id;
                        obj.name = payment.name;
                        obj.serviceType = this.$t(`organizaciones.category.options.${payment.classification}`);
                        obj.paymentType = this.$t(`pagos.rows.types.${payment.type}`);

                        switch (payment.type) {
                            case 'P':
                                obj.details = `$ ${total.toLocaleString('en-IN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} MXN`;
                                break;
                            case 'D':
                                obj.details = this.getSingularOrPluralString(payment.debtors.length, "Deudor", "Deudores");
                                break;
                            case 'EP':
                                obj.details = this.getSingularOrPluralString(payment.concepts.length, "Concepto", "Conceptos");
                                break;
                            case 'WS':
                                obj.details = payment.url_final;
                                break;
                            default:
                        }

                        if (payment.comingSoon) {
                            obj.status = `PROXIMAMENTE`;
                        } else if (payment.activeService) {
                            obj.status = `ACTIVO`;
                        } else {
                            obj.status = `INACTIVO`;
                        }

                        this.payments.push(obj);
                    });

                    this.total = response.data.object.total;
                    this.pages = Math.ceil(response.data.object.total / this.limit);
                    this.foundValues = this.foundValues || this.total > 0; // Es para asegurarse que si la priemara vez trae valores, no se oculten las tarjetas de búsqueda
                }).catch((error) => {
                    this.loading = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            },
            getSingularOrPluralString(count, singular, plural) {
                if (count === 1) {
                    return `${count} ${singular}`;
                }
                return `${count} ${plural}`;
            },
            goTo(route) {
                localStorage.removeItem('editingPayment');
                this.$router.push(route);
            },
            checkboxRefName(index) {
                return `checkbox_${index}`;
            },
            openDeleteModal(payment) {
                this.selectedDeletePayments = [payment];
                this.$bvModal.show(MODAL_DANGER_ID);
            },
            startDeleteMulti() {
                const ids = [];
                this.selectedRows.forEach((selected, index) => {
                    if (selected) {
                        ids.push(this.payments[index]._id);
                    }
                });

                this.selectedDeletePayments = ids;
                this.$bvModal.show(MODAL_DANGER_ID);
            },
            deletePayment(_id, paymentType) {
                this.loading = true;
                PaymentsCRUDApi.delete(paymentType, _id)
                    .then(() => {
                        this.getPayments(1);
                        this.loading = false;
                        this.$bvModal.hide(MODAL_DANGER_ID);
                        utils.toast(this.$snotify, 'success', this.$t(SUCCESS_TEXT_KEY), this.$t(SUCCESS_TITLE_KEY));
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$bvModal.hide(MODAL_DANGER_ID);
                        if (error && error.response && error.response.data && error.response.data.message) {
                            this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                        } else {
                            this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                        }
                    })
            },
            deleteSelected() {
                if (this.selectedDeletePayments.length === 1) {
                    this.deletePayment(this.selectedDeletePayments[0]._id, this.selectedDeletePayments[0].category);
                } else {
                    this.loading = true;
                    PaymentsCRUDApi.deleteMulti(this.selectedDeletePayments)
                        .then(() => {
                            this.getPayments(1);
                            this.loading = false;
                            this.$bvModal.hide(MODAL_DANGER_ID);
                            utils.toast(this.$snotify, 'success', this.$t('general.actions.copy-clipboard.success'), this.$t(SUCCESS_TITLE_KEY));
                            // eslint-disable-next-line sonarjs/no-identical-functions
                        }).catch((error) => {
                        this.loading = false;
                        this.$bvModal.hide(MODAL_DANGER_ID);
                        if (error && error.response && error.response.data && error.response.data.message) {
                            this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                        } else {
                            this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                        }
                    })
                }
            },
            startCopyMulti() {
                this.loading = true;
                const payments = [];
                this.selectedRows.forEach((r, i) => {
                    if (r) {
                        const payment = this.payments[i];
                        payments.push({type: payment.payment.type, id: payment.payment._id});
                    }
                });
                this.copy(payments);
            },
            copy(payments) {
                this.loading = true;
                PaymentsCRUDApi.copy(payments).then(() => {
                    this.displayNotificationSuccess(this.$t(SUCCESS_TITLE_KEY), this.$t(SUCCESS_TEXT_KEY));
                    this.getPayments(this.page);
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    this.selectAll = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            },
            copyClipboard() {
                this.copiedToClipboard = true;
                navigator.clipboard.writeText(this.detailsRoute).then(() => {
                    utils.toast(this.$snotify, 'success', this.$t('general.actions.copy-clipboard.success'), this.$t(SUCCESS_TITLE_KEY));
                }, () => {
                    utils.toast(this.$snotify, 'error', this.$t('general.actions.copy-clipboard.error'), this.$t(ERROR_TITLE_KEY));
                });
            },
            shareFB() {
                shareFacebook(this.detailsRoute);
            },
            shareTW() {
                shareTwitter(this.detailsRoute);
            },
            tagAdded(query) {
                if (query.length > 0) {
                    this.searchTag = query;
                } else if (this.searchTag.length > 0 && !this.valueTags.find(t => t.email === this.searchTag)) {
                    const emails = this.searchTag.split(',');
                    emails.forEach(e => this.valueTags.push({email: e.split(' ').join('')}));
                    this.searchTag = '';
                }
            },
            saveEmails() {
                // Send email
            },
            clearTags() {
                this.valueTags.length = 0;
            },
            indexFromType(type) {
                switch (type) {
                    case 'P':
                        return 'payment';
                    case 'EP':
                        return 'expresspayment';
                    case 'D':
                        return 'debt';
                    case 'WS':
                        return 'webservicepayment';
                    default:
                        return null;
                }
            }
        },
        computed: {
            saveOrTestDisabled() {
                return !this.isAValidForm && !this.wantToSave;
            },
            testResultBadgeData() {
                const approvedKeyi18n = 'general.approved';
                return {
                    request: {
                        class: this.testResults.request || this.payment.testedService ? BADGE_SUCCESS_CLASS : BADGE_ERROR_CLASS,
                        text: this.testResults.request || this.payment.testedService ? this.$t(approvedKeyi18n) : 'Error'
                    },
                    response: {
                        class: this.testResults.response || this.payment.testedService ? BADGE_SUCCESS_CLASS : BADGE_ERROR_CLASS,
                        text: this.testResults.response || this.payment.testedService ? this.$t(approvedKeyi18n) : 'Error'
                    },
                    data: {
                        class: this.testResults.data || this.payment.testedService ? BADGE_SUCCESS_CLASS : BADGE_ERROR_CLASS,
                        text: this.testResults.data || this.payment.testedService ? this.$t(approvedKeyi18n) : 'Error'
                    }
                }
            },
            isAValidForm() {
                if (this.fields.length > 0) {
                    return !this.fields.find(p => !p.value || p.value.length === 0);
                }
                return true;
            },
            hasFilters() {
                let hasFilters = false;
                if (this.match.name !== '') {
                    hasFilters = true;
                }
                if (Object.keys(this.match.classification).length > 0) {
                    hasFilters = true;
                }
                if (Object.keys(this.match.type).length > 0) {
                    hasFilters = true;
                }
                if (Object.keys(this.match.status).length > 0) {
                    hasFilters = true;
                }
                return hasFilters;
            },
            detailsRoute() {
                return config.users.host === "http://localhost" ?
                    `${config.users.host}:${config.users.port}/landing/service-details/${this.indexFromType(this.selectedPayment.type)}/${this.selectedPayment._id}` :
                    `${config.users.host}/landing/service-details/${this.indexFromType(this.selectedPayment.type)}/${this.selectedPayment._id}`;
            },
            headTableRowClass() {
                if (this.totalSelectedRows > 1) {
                    return 'header-options-full';
                }
                return '';
            },
            totalSelectedRows() {
                return this.selectedRows.filter(r => r).length;
            },
            isValid() {
                return this.valueTags.length > 0;
            }
        },
        watch: {
            isAValidForm(val) {
                if (val) {
                    this.testOrSave = "Probar";
                }
            },
            'status': function (status, oldStatus) {
                if (oldStatus === "") {
                    this.testOrSave = "Probar";
                } else if (oldStatus.name !== status.name) {
                    this.testOrSave = "Guardar";
                    this.wantToSave = true;
                } else {
                    this.testOrSave = "Probar";
                }

                const {active, sandBoxMode, comingSoon} = (status || {});
                this.payment.activeService = active;
                this.payment.sandBoxMode = sandBoxMode;
                this.payment.comingSoon = comingSoon;

            },
            'method': function (method) {
                if (method) {
                    this.payment.method = method.name;
                }
            },
            'contentType': function (contentType) {
                if (contentType) {
                    this.payment.content_type = contentType.value;
                }
            },
            page(val) {
                this.getPayments(val);
            },
            selectAll(val) {
                if (!this.badProgramingFlagForSelectAll) {
                    this.selectedRows = [];
                    this.selectAllHeader = val;
                    let i;
                    for (i = 0; i < this.payments.length; i++) {
                        this.selectedRows[i] = val;
                        this.$refs[this.checkboxRefName(i)][0].checkStoreValue(val);
                    }
                }
                this.badProgramingFlagForSelectAll = false;
                if (this.$refs.selectAll) {
                    if (this.$refs.selectAll.checkStoreValue) {
                        this.$refs.selectAll.checkStoreValue(val);
                    } else if (this.$refs.selectAll.length) {
                        this.$refs.selectAll[0].checkStoreValue(val);
                    }
                }
                if (this.$refs.selectAllHeader) {
                    if (this.$refs.selectAllHeader.checkStoreValue) {
                        this.$refs.selectAllHeader.checkStoreValue(val);
                    } else if (this.$refs.selectAllHeader.length) {
                        this.$refs.selectAllHeader[0].checkStoreValue(val);
                    }
                }
            },
            selectAllHeader(val) {
                if (!this.badProgramingFlagForSelectAllHeader) {
                    this.selectedRows = [];
                    this.selectAll = val;
                    let i;
                    for (i = 0; i < this.payments.length; i++) {
                        this.selectedRows[i] = val;
                        this.$refs[this.checkboxRefName(i)][0].checkStoreValue(val);
                    }
                }
                this.badProgramingFlagForSelectAllHeader = false;
                if (this.$refs.selectAll) {
                    if (this.$refs.selectAll.checkStoreValue) {
                        this.$refs.selectAll.checkStoreValue(val);
                    } else if (this.$refs.selectAll.length) {
                        this.$refs.selectAll[0].checkStoreValue(val);
                    }

                }
                if (this.$refs.selectAllHeader) {
                    if (this.$refs.selectAllHeader.checkStoreValue) {
                        this.$refs.selectAllHeader.checkStoreValue(val);
                    } else if (this.$refs.selectAllHeader.length) {
                        this.$refs.selectAllHeader[0].checkStoreValue(val);
                    }

                }
            },
            match: {
                handler() {
                    CommonUtils.debounceFixed(() => {
                        this.getPayments(this.page);
                    });
                },
                deep: true
            },
            sort: {
                // eslint-disable-next-line sonarjs/no-identical-functions
                handler() {
                    CommonUtils.debounceFixed(() => {
                        this.getPayments(this.page);
                    });
                },
                deep: true
            }
        },
        validations: {
            valueTags: {
                required,
                $each: {
                    email
                }
            }
        }
    };
</script>
