<template>
    <CardSimple
        :title="$t('organizaciones.datos-fiscales.title')"
        :subtitle="$t('organizaciones.datos-fiscales.subtitle')"
    >
        <div class="row">
            <div class="col-12">
                <b-alert
                    class="alert-note"
                    show
                    variant="warning"
                >
                    <p class="text-container">
                        <img
                            class="img-fluid"
                            src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                            alt="warning"
                        />
                        <span v-html="$t('organizaciones.datos-fiscales.warning')"></span>
                    </p>
                </b-alert>
            </div>
            <div class="col-12">
                <BasicInput
                    inputId="valueSitTrib"
                    v-model="valueSitTrib"
                    required
                    readOnly
                    disabled
                    inputType="text"
                    labelFor="valueSitTrib"
                    :label="$t('fiscal-data.tributary-situation')"
                    :inputPlaceholder="$t('fiscal-data.tributary-situation')"
                >
                </BasicInput>
            </div>
            <div class="col-12 col-md-12">
                <BasicInput
                    inputId="name"
                    v-model="razonSocial"
                    required
                    readOnly
                    disabled
                    inputType="text"
                    labelFor="name"
                    :label="$t('fiscal-data.name')"
                    :inputPlaceholder="$t('fiscal-data.name-placeholder')"
                >
                </BasicInput>
            </div>
            <div class="col-12">
                <BasicInput
                    inputId="rfc"
                    v-model="rfc"
                    required
                    readOnly
                    disabled
                    inputType="text"
                    labelFor="rfc"
                    :label="$t('fiscal-data.rfc')"
                    :inputPlaceholder="$t('fiscal-data.rfc-placeholder')"
                >
                </BasicInput>
            </div>

            <div class="col-12 m-t-80">
                <div class="title-card">
                    <h1>
                        {{$t('fiscal-data.address')}}
                        <label>{{$t('fiscal-data.address-capture-info')}}</label>
                    </h1>
                </div>
            </div>
            <div class="col-12">
                <BasicInput
                    inputId="streetAddress"
                    v-model="invoiceData.streetAddress"
                    required
                    readOnly
                    disabled
                    inputType="text"
                    labelFor="streetAddress"
                    :label="$t('fiscal-data.street')"
                    :inputPlaceholder="$t('fiscal-data.street-placeholder')"
                >
                </BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    inputId="exteriorNumber"
                    v-model="invoiceData.exteriorNumber"
                    required
                    readOnly
                    disabled
                    inputType="number"
                    labelFor="exteriorNumber"
                    :label="$t('fiscal-data.exterior-number')"
                    :inputPlaceholder="$t('fiscal-data.exterior-number-placeholder')"
                >
                </BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    inputId="interiorNumber"
                    v-model="invoiceData.interiorNumber"
                    required
                    readOnly
                    disabled
                    inputType="number"
                    labelFor="interiorNumber"
                    :label="$t('fiscal-data.interior-number')"
                    :inputPlaceholder="$t('fiscal-data.interior-number-placeholder')"
                >
                </BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    inputId="district"
                    v-model="invoiceData.district"
                    required
                    readOnly
                    disabled
                    inputType="text"
                    labelFor="district"
                    :label="$t('fiscal-data.district')"
                    :inputPlaceholder="$t('fiscal-data.district')"
                >
                </BasicInput>
            </div>
            <div class="col-12 col-md-6">
                  <BasicInput
                    inputId="state"
                    v-model="invoiceData.state"
                    required
                    readOnly
                    disabled
                    inputType="text"
                    labelFor="state"
                    :label="$t('fiscal-data.state')"
                    :inputPlaceholder="$t('fiscal-data.state')"
                >
                </BasicInput>
            </div>
            <div class="col-12 col-md-6">
                  <BasicInput
                    inputId="city"
                    v-model="invoiceData.city"
                    required
                    readOnly
                    disabled
                    inputType="text"
                    labelFor="city"
                    :label="$t('fiscal-data.city')"
                    :inputPlaceholder="$t('fiscal-data.city')"
                >
                </BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    inputId="zipCode"
                    v-model="invoiceData.zipCode"
                    required
                    readOnly
                    disabled
                    inputType="number"
                    labelFor="zipCode"
                    :label="$t('fiscal-data.zip-code')"
                    :inputPlaceholder="$t('fiscal-data.zip-code')"
                >
                </BasicInput>
            </div>
            <div class="col-12">
                <InputFile
                    inputId="fileife"
                    required
                    readOnly
                    disabled
                    :downloadFile="downloadOfficialId()"
                    labelFor="fileife"
                    :label="$t('fiscal-data.official-id')"
                >
                    <b-form-file
                        id="fileife"
                        disabled
                        :placeholder="oficialIdName"
                        :drop-placeholder="$t('fiscal-data.drag-file-here')"
                        name
                    />
                </InputFile>
            </div>
        </div>
    </CardSimple>
</template>
<script>
import { GET_BANK_INFO, STORE, FETCH_BILLING_DATA, GET_BILLING_DATA, GET_CAN_UPDATE_BANK_INFO } from "@/store/modules/organization/organization.types";
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import { mapGetters } from 'vuex';
import CardSimple from '@/components/CardSimple.vue';
import BasicInput from '@/components/BasicInput.vue';
import InputFile from '@/components/InputFile.vue';
import {utils} from '@/api/utils.js';

export default {
    components: {
        CardSimple,
        BasicInput,
        InputFile
    },
    mounted() {
        this.fetchDatosFiscales();
    },
    data() {
        return {
            optionsSitTrib: [
                { name: this.$i18n.t('fiscal-data.physical-person'), isMoralPerson: false },
                { name: this.$i18n.t('fiscal-data.moral-person'), isMoralPerson: true }
            ],
            valueEdo: {},
            optionsEdo: [],
            valueCd: [],
            optionsCd: [],
            rfc: "",
            razonSocial: ""
        }
    },
    methods: {
        fetchDatosFiscales () {
            this.$store.dispatch(`${STORE}/${FETCH_BILLING_DATA}`, this.currentOrganization._id).then(() => {
                this.prepareData();
            });
        },
        downloadOfficialId() {
            return this.invoiceData.officialId ? utils.imageDownloadLink(this.invoiceData.officialId) : null;
        },
        prepareData() {
            if (this.invoiceData.state) {
                this.valueEdo = { value: this.invoiceData.state};
                this.optionsEdo.push({ value: this.invoiceData.state});
            }
            if (this.invoiceData.city) {
                this.valueEdo = { value: this.invoiceData.city};
                this.optionsEdo.push({ value: this.invoiceData.city});
            }
            this.rfc = this.invoiceData.rfc !== undefined ? this.invoiceData.rfc : this.getBillingData.RFC
            if(this.getBillingData.legalName !== undefined && this.getBillingData.legalName !== ""){
                this.razonSocial = this.getBillingData.legalName
            } else if (this.invoiceData.isMoralPerson) {
                this.razonSocial = this.invoiceData.moralLegalName;
            } else {
                this.razonSocial = `${this.invoiceData.name} ${this.invoiceData.lastName} ${this.invoiceData.secondLastName}`
            }
        }
    },
    computed: {
        valueSitTrib() {
            if (this.getBillingData === "MORAL" || this.invoiceData.isMoralPerson) {
                return this.$i18n.t('fiscal-data.moral-person');
            }
            return this.$i18n.t('fiscal-data.physical-person');
        },
        invoiceData () {
            return (this.getBillingData && this.getBillingData.invoiceData) ? this.getBillingData.invoiceData || {} : {};
        },
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
        ...mapGetters(STORE, {
            actualBankData: GET_BANK_INFO,
            getBillingData: GET_BILLING_DATA,
            canUpdateBankInfo: GET_CAN_UPDATE_BANK_INFO
        }),
        oficialIdName() {
            if (this.invoiceData && this.invoiceData.officialId && this.invoiceData.officialId.name) {
                return this.invoiceData.officialId.name;
            }
            return this.$i18n.t('fiscal-data.official-id');
        }
    },
    watch: {
        currentOrganization () {
            if (this.currentOrganization) {
                this.loading = true;
                this.$store.dispatch(`${STORE}/${FETCH_BILLING_DATA}`, this.currentOrganization._id).then(() => {
                    this.prepareData();
                });
            }
        }
    }
}
</script>
