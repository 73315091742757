<template>
    <div>
        <CardSimple v-if="!hideInfo" hideTitle>
            <LoadingBar
                    v-if="loading"
                    :loadingText="$t('general.info.loading')"
            ></LoadingBar>
            <EmptyState
                    :title="$t('organizaciones.webhooks-config.empty.title')"
                    :text="$t('organizaciones.webhooks-config.empty.text')"
                    :imgUrlHorizontal="EmptyStateImage">
                <button class="button xs button-filled button-accent1" @click="$bvModal.show('modal-addWebhook')">
                    <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{ $t('organizaciones.webhooks-config.empty.add') }}
                </button>
            </EmptyState>
        </CardSimple>
        <CardSimple v-if="hideInfo" :title="$t('organizaciones.webhooks-config.title')"
                    :subtitle="$t('organizaciones.webhooks-config.title')"
                    clazz="p-30">
                    <div class="row">
                        <div class="col-12" v-if="currentOrganization && currentOrganization.webhooksErrorsEmail">
                            <b-alert class="alert-note m-b-40" show variant="warning">
                                <p class="text-container">
                                    <img class="img-fluid" src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                        alt="warning"/>
                                    <span v-html="$t('organizaciones.webhooks-config.alert', {email: currentOrganization.webhooksErrorsEmail})"></span>
                                </p>
                            </b-alert>
                        </div>
                        <div class="col-12 vertical-center justify-content-end m-b-20">
                            <button type="button" name="" class="button xs button-filled button-accent1"
                                    @click="$bvModal.show('modal-addWebhook')"> {{ $t('organizaciones.webhooks-config.add') }}
                            </button>
                        </div>
                        <div class="col-12" v-for="webhook in webhooks" :key="webhook._id">
                            <WebhooksConfig
                                    :title="webhook.name"
                                    :link="webhook.url"
                                    :clazzStatus="!webhook.errorLogs.length ? 'c-success' : 'c-error' "
                                    iconStatus="banana-check-circle1"
                                    :textStatus="!webhook.errorLogs.length ? $t('organizaciones.webhooks-config.no-errors') : $t('organizaciones.webhooks-config.errors')"
                                    @delete-wh="openDeleteModal(webhook)"
                                    @edit-wh="getWebhookById(webhook._id)"
                                    @show-wh="getWebhookById(webhook._id)"
                            >
                                <template v-slot:badgeSandbox>
                                    <Badge v-if="webhook.applySandBoxMode" type="badge-accent_2"
                                        :text="$t('organizaciones.webhooks-config.active')"></Badge>
                                    <Badge v-else-if="!webhook.applySandBoxMode" type="badge-error"
                                        :text="$t('organizaciones.webhooks-config.inactive')"></Badge>
                                </template>
                                <template v-slot:badgeProd>
                                    <Badge v-if="webhook.applyProdMode" type="badge-accent_2"
                                        :text="$t('organizaciones.webhooks-config.active')"></Badge>
                                    <Badge v-else-if="!webhook.applyProdMode" type="badge-error"
                                        :text="$t('organizaciones.webhooks-config.inactive')"></Badge>
                                </template>
                            </WebhooksConfig>
                        </div>
                    </div>
        </CardSimple>
        <ModalAlert
                modalId="modal-delete"
                buttonType="button-error"
                :imgUrl="imageModalDelete"
                :title="$t('organizaciones.webhooks-config.modal-delete.title')"
                :text="$t('organizaciones.webhooks-config.modal-delete.text')"
                :buttonText="$t('general.actions.delete.title2')"
                @submit="deleteWebhook(webhookToDelete._id)"
        ></ModalAlert>
        <ModalDefault
                modalId="modal-addWebhook"
                customRef="modal-addWebhook"
                originalFooter
                :title="!editMode ? $t('organizaciones.webhooks-config.modal.title')
                : $t('organizaciones.webhooks-config.modal-edit.title')"
                :subtitle="!editMode ? $t('organizaciones.webhooks-config.modal.subtitle')
                : $t('organizaciones.webhooks-config.modal-edit.subtitle')"
                :buttonText="!editMode ? $t('organizaciones.webhooks-config.modal.buttons.add')
                : $t('general.actions.save-changes')"
                :buttonClose="$t('organizaciones.webhooks-config.modal.buttons.close')"
                :disabled="hasError"
                @clickAccepted="!editMode ? addWebhook() : editWebhook()"
                @clickClose="cleanWebhook"
        >
            <div class="row">
                <div class="col-12">
                    <BasicInput
                            required
                            inputType="text"
                            labelFor="nameWH"
                            inputId="nameWH"
                            v-model="webhookForm.name"
                            :validations="$v.webhookForm.name"
                            validationsNamespace="general.validations"
                            :label="$t('organizaciones.webhooks-config.modal.inputs.name.label')"
                            :inputPlaceholder="$t('organizaciones.webhooks-config.modal.inputs.name.placeholder')"></BasicInput>
                </div>
                <div class="col-12">
                    <BasicInput
                            required
                            inputType="text"
                            labelFor="urlWH"
                            inputId="urlWH"
                            v-model="webhookForm.url"
                            :validations="$v.webhookForm.url"
                            validationsNamespace="general.validations"
                            :label="$t('organizaciones.webhooks-config.modal.inputs.url.label')"
                            :inputPlaceholder="$t('organizaciones.webhooks-config.modal.inputs.url.placeholder')"></BasicInput>
                </div>
                <div class="col-12 vertical-center m-b-40">
                    <span class="m-r-40">
                        <label class="f-14 c-title body-font-regular d-block m-b-5"> {{ $t('organizaciones.webhooks-config.modal.inputs.sandbox.lower') }} </label>
                        <BasicSwitch
                                id="ts4"
                                labelFor="ts4"
                                useVModel
                                v-model="webhookForm.sandBox"
                        ></BasicSwitch>
                    </span>
                    <span>
                        <label class="f-14 c-title body-font-regular d-block m-b-5"> {{ $t('organizaciones.webhooks-config.modal.inputs.production.lower') }} </label>
                        <BasicSwitch
                                id="ts5"
                                labelFor="ts5"
                                useVModel
                                v-model="webhookForm.production"
                        ></BasicSwitch>
                    </span>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
    import CardSimple from '@/components/CardSimple.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import EmptyStateImage from '@/assets/images/Emptystates/emptystate-add.svg';
    import ModalDefault from '@/components/ModalDefault.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import BasicSwitch from '@/components/BasicSwitch.vue';
    import Badge from '@/components/Badge.vue';
    import LoadingBar from '@/components/LoadingBar.vue';
    import WebhooksConfig from '@/components/WebhooksConfig.vue';
    import WebHooksApi from '@/api/webhooks/webhook.api.js';
    import {utils} from '@/api/utils';
    import {url, required, minLength, maxLength} from 'vuelidate/lib/validators';
    import {
        STORE as ORGANIZATION_STORE,
        GET_CONTACT_INFO,
        FETCH_CONTACT_INFO
    } from "@/store/modules/organization/organization.types";
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {mapGetters} from 'vuex';
    import imageModalDelete from "@/assets/images/Illustrations/illustration-modal-delete.svg";
    import ModalAlert from "@/components/ModalAlert.vue";

    const ERROR_TITLE_KEY = "general.actions.error.title";
    const ERROR_TEXT_KEY = "general.actions.error.text";
    const SUCCESS_TEXT_KEY = 'general.actions.success.text';
    const SUCCESS_TITLE_KEY = 'general.actions.success.title';
    const MODAL_ADD_WEBHOOK_ID = 'modal-addWebhook';
    const MODAL_DELETE_WEBHOOK_ID = 'modal-delete';

    export default {
        name: 'webhooks-configuration',
        components: {
            CardSimple,
            EmptyState,
            ModalDefault,
            BasicInput,
            BasicSwitch,
            Badge,
            LoadingBar,
            WebhooksConfig,
            ModalAlert
        },
        data() {
            return {
                EmptyStateImage: EmptyStateImage,
                webhooks: [],
                webhook: [],
                webhookForm: {
                    name: '',
                    url: '',
                    sandBox: false,
                    production: false
                },
                page: 1,
                size: 10,
                hideInfo: false,
                loading: false,
                editMode: false,
                webhookId: '',
                email: {},
                webhookToDelete: null,
                imageModalDelete
            }
        },
        mounted() {
            this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_CONTACT_INFO}`, this.currentOrganization._id);
            this.getWebhooks();
        },
        validations: {
            webhookForm: {
                name: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                },
                url: {
                    required,
                    url
                }
            }
        },
        methods: {
            deleteWebhook(id) {
                this.loading = true;
                WebHooksApi.delete(id).then(() => {
                    utils.toast(this.$snotify, 'success', this.$t(SUCCESS_TEXT_KEY), this.$t(SUCCESS_TITLE_KEY));
                    this.getWebhooks();
                    this.loading = false;
                    this.$bvModal.hide(MODAL_DELETE_WEBHOOK_ID);
                }).catch(() => {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    this.$bvModal.hide(MODAL_DELETE_WEBHOOK_ID);
                });
            },
            addWebhook() {
                WebHooksApi.create(this.webhookForm).then(() => {
                    this.$bvModal.hide(MODAL_ADD_WEBHOOK_ID);
                    this.loading = true;
                    utils.toast(this.$snotify, 'success', this.$t(SUCCESS_TEXT_KEY), this.$t(SUCCESS_TITLE_KEY));
                    this.getWebhooks();
                    this.loading = false;
                    this.cleanWebhook();
                }).catch(() => {
                    this.cleanWebhook();
                    this.$bvModal.hide(MODAL_ADD_WEBHOOK_ID);
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                })
            },
            getWebhooks() {
                this.loading = true;
                WebHooksApi.search({page: this.page, size: this.size}).then((response) => {
                    this.loading = false;
                    this.webhooks = response.data.object;
                    if (this.webhooks.length < 1) {
                        this.hideInfo = false;
                        return this.hideInfo;
                    }
                    this.hideInfo = true;
                    return this.hideInfo;
                }).catch(() => {
                    this.loading = false;
                    this.hideInfo = false;
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), 'Hubo un problema al cargar la información');
                });
            },
            cancel() {
                this.cleanWebhook();
                utils.toast(this.$snotify, 'info', this.$t('general.actions.cancel.title'), this.$t('general.actions.cancel.text'));
            },
            cleanWebhook() {
                this.webhookForm.name = '';
                this.webhookForm.url = '';
                this.webhookForm.sandBox = false;
                this.webhookForm.production = false;
                this.editMode = false;
            },
            getWebhookById(id) {
                WebHooksApi.searchById(id).then((response) => {
                    this.webhook = response.data.object;
                    this.editMode = true;
                    this.webhookId = this.webhook[0]._id;

                    this.webhookForm.name = this.webhook[0].name;
                    this.webhookForm.url = this.webhook[0].url;
                    this.webhookForm.sandBox = this.webhook[0].applySandBoxMode;
                    this.webhookForm.production = this.webhook[0].applyProdMode;

                    this.$bvModal.show(MODAL_ADD_WEBHOOK_ID);
                }).catch(() => {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                })
            },
            editWebhook() {
                // eslint-disable-next-line sonarjs/no-identical-functions
                WebHooksApi.edit(this.webhookId, this.webhookForm).then(() => {
                    this.$bvModal.hide(MODAL_ADD_WEBHOOK_ID);
                    this.loading = true;
                    utils.toast(this.$snotify, 'success', this.$t(SUCCESS_TEXT_KEY), this.$t(SUCCESS_TITLE_KEY));
                    this.getWebhooks();
                    this.loading = false;
                    this.cleanWebhook();
                }).catch(() => {
                    this.$bvModal.hide(MODAL_ADD_WEBHOOK_ID);
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                })
            },
            openDeleteModal(webhook) {
                this.$bvModal.show(MODAL_DELETE_WEBHOOK_ID);
                this.webhookToDelete = webhook;
            }
        },
        computed: {
            hasError() {
                return this.$v.webhookForm.$invalid
            },
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            ...mapGetters(ORGANIZATION_STORE, {
                contactInfoWH: GET_CONTACT_INFO
            }),
            contactInfoComputed() {
                return this.contactInfoWH || {};
            }
        },
        watch: {
            currentOrganization() {
                if (this.currentOrganization) {
                    this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_CONTACT_INFO}`, this.currentOrganization._id);
                }
            }
        }
    }
</script>
