<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/apiWeb/api-list"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                    tabindex="">
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i> Regresar a API para sitio web
                </router-link>
                <FloatingTitleLayout title="Crear formulario"/>
            </div>

            <div class="col-12">
                <CardSimple hideTitle>
                    <div class="row">
                        <div class="col-12">
                            <TitleNumber num="1"
                                         title="Personaliza tu formulario"
                                         subtitle="Personaliza el formulario de pagos por Pagando dando clic en el botón “Editar”."></TitleNumber>
                        </div>
                        <div class="col-12 vertical-center m-b-20">
                            <Badge type="badge-accent_2 m-0-auto" text="Vista previa"></Badge>
                        </div>
                        <div class="col-12">
                            INSERT PREVIEW HERE
                        </div>
                        <div class="col-12 m-t-40">
                            <button type="button" class="button xs button-filled button-accent1 m-0-0-0-auto"> PERSONALIZAR FORMULARIO </button>
                        </div>
                    </div>
                </CardSimple>
            </div>

            <div class="col-12">
                <CardSimple hideTitle>
                    <div class="row">
                        <div class="col-12">
                            <TitleNumber num="2"
                                         title="Agrega el formulario a tu sitio web"
                                         subtitle="Una vez personalizado el formulario,
                                         genera el código e insértalo dentro del código de tu sitio web,
                                         como te mostramos a continuación:"></TitleNumber>
                        </div>
                        <div class="col-12 m-b-30">
                            <div class="row">
                                <div class="col-12 col-lg-9">
                                    <BasicInput
                                        clazz="m-b-0"
                                        required
                                        labelFor="codeBtn"
                                        inputId="codeBtn"
                                        label="Código de formulario"
                                        inputPlaceholder="Da clic en consultar para generar tu código..."
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-codeBtn"
                                        inputClazz="icon-right"
                                        iconInput="banana-copy">
                                        <template v-slot:tooltipHelp>
                                            <b-tooltip
                                                target="tooltip-codeBtn"
                                                placement="top"
                                                custom-class="white-tooltip">
                                                <div>
                                                    <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                        tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </b-tooltip>
                                        </template>
                                    </BasicInput>
                                </div>
                                <div class="col-12 col-lg-3 vertical-center justify-content-end">
                                    <button type="button" class="button sm button-filled button-accent1 p-l-10 p-r-10 m-t-15 w-100"> GENERAR CÓDIGO </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <b-alert
                                class="alert-note"
                                show
                                variant="warning">
                                <p class="text-container">
                                    <img
                                        class="img-fluid"
                                        src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                        alt="warning"
                                    />
                                    Es importante que una persona con conocimientos técnicos sea la
                                    que se encargue de configurar los datos de esta sección para evitar futuros errores.
                                </p>
                            </b-alert>

                            <p class="f-14 c-plain_text body-font-regular d-block m-b-40">
                                Copia y pega el código en el archivo HTML de tu sitio web después de la etiqueta “HEAD”,
                                como se muestra a continuación. Si no estás seguro de poder realizar este proceso,
                                te recomendamos seguir las instrucciones en nuestra
                                 <a href="" class="c-accent_2 body-font-bold hover" tabindex="">librería para desarrolladores</a>,
                                o <a href="" class="c-accent_2 body-font-bold hover" tabindex="">contactar al equipo de soporte</a>
                                de Pagando.
                            </p>
                        </div>
                        <div class="col-12">
                            <codemirror :value="codeExample"></codemirror>
                        </div>
                    </div>
                </CardSimple>
            </div>

            <div class="col-12">
                <CardSimple title="Personaliza tu formulario"
                            subtitle="Personaliza el formulario de pagos por Pagando
                            utilizando los campos editables que se muestran debajo.">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="f-20 c-title principal-font-bold d-block m-b-30"> Configuración de los campos de texto </h1>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                labelFor="colorCampo"
                                inputId="colorCampo"
                                label="Color campos"
                                inputPlaceholder="#000000"
                                help
                                helpIcon="banana banana-question-circle1"
                                idHelpTooltip="tooltip-colorCampo"
                                inputClazz="icon-right"
                                iconInput="banana-copy">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                        target="tooltip-colorCampo"
                                        placement="top"
                                        custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                labelFor="colorBorde"
                                inputId="colorBorde"
                                label="Color del borde"
                                inputPlaceholder="#000000"
                                help
                                helpIcon="banana banana-question-circle1"
                                idHelpTooltip="tooltip-colorBorde"
                                inputClazz="icon-right"
                                iconInput="banana-copy">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                        target="tooltip-colorBorde"
                                        placement="top"
                                        custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                labelFor="radioBorde"
                                inputId="radioBorde"
                                label="Radio del borde"
                                inputPlaceholder="0px"
                                help
                                helpIcon="banana banana-question-circle1"
                                idHelpTooltip="tooltip-radioBorde"
                                inputClazz="icon-right"
                                iconInput="banana-copy">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                        target="tooltip-radioBorde"
                                        placement="top"
                                        custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>
                        <div class="col-12">
                            <div class="divider-disable m-t-0 m-b-30"></div>
                        </div>
                        <div class="col-12">
                            <h1 class="f-20 c-title principal-font-bold d-block m-b-30"> Configuración del botón </h1>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                labelFor="colorBtn"
                                inputId="colorBtn"
                                label="Color del botón"
                                inputPlaceholder="#000000"
                                help
                                helpIcon="banana banana-question-circle1"
                                idHelpTooltip="tooltip-colorBtn"
                                inputClazz="icon-right"
                                iconInput="banana-copy">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                        target="tooltip-colorBtn"
                                        placement="top"
                                        custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                labelFor="radioBtn"
                                inputId="radioBtn"
                                label="Radio del botón"
                                inputPlaceholder="0px"
                                help
                                helpIcon="banana banana-question-circle1"
                                idHelpTooltip="tooltip-radioBtn"
                                inputClazz="icon-right"
                                iconInput="banana-copy">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip
                                        target="tooltip-radioBtn"
                                        placement="top"
                                        custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>
                            </BasicInput>
                        </div>
                        <div class="col-12">
                            <div class="divider-disable m-t-0 m-b-30"></div>
                        </div>
                        <div class="col-12 vertical-center m-b-20">
                            <Badge type="badge-accent_2 m-0-auto" text="Vista previa"></Badge>
                        </div>
                        <div class="col-12">
                            INSERT PREVIEW HERE
                        </div>
                        <div class="col-12 m-t-40">
                            <button type="button" class="button xs button-filled button-accent1 m-0-0-0-auto"> Crear formulario </button>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue'
    import CardSimple from '@/components/CardSimple.vue'
    import TitleNumber from '@/components/TitleNumber.vue'
    import BasicInput from '@/components/BasicInput.vue'
    import Badge from '@/components/Badge.vue'
    import { codemirror } from 'vue-codemirror-lite'

    export default {
        name: "ApiForm",
        components: {
            FloatingTitleLayout,
            CardSimple,
            TitleNumber,
            BasicInput,
            Badge,
            codemirror
        },
        data() {
            return {
                // Codemirror
                codeExample: 'const str = "hello world"'
            }
        }
    }
</script>

