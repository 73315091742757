<template>
    <div class="landingpage-content">
        <LoadingBar v-if=loading></LoadingBar>
        <div v-else class="width-responsive">
            <div class="row">
                <div class="col-12 m-t-80">
                    <small class="f-12 c-primary_1 principal-font-bold text-upper op-04 d-block m-b-15">{{$t('terms-conditions.services-conditions')}}</small>
                    <div class="vertical-tabs">
                        <b-tabs vertical nav-wrapper-class="navigation-tabs">
                            <b-tab :title="$t('terms-conditions.privacy-agreement')" active>
                                <CardSimple hideTitle>
                                    <div class="row">
                                        <div class="col-12 fix-text-terms-privacy" v-html="privacy.content"></div>
                                    </div>
                                </CardSimple>
                                <router-link to="/" class="button sm button-stroke button-accent2 m-t-60 m-0-auto">
                                    <i aria-hidden="true" class="banana banana-arrow-left"></i>
                                    {{$t('terms-conditions.return-to-pagando')}}
                                </router-link>
                            </b-tab>
                            <b-tab :title="$t('terms-conditions.terms-conditions')" v-if="termsExists">
                                <CardSimple hideTitle>
                                    <div class="row">
                                        <div class="col-12 fix-text-terms-privacy"
                                             v-html="terms.documentAcceptedContent"></div>
                                    </div>
                                </CardSimple>
                                <router-link to="/" class="button sm button-stroke button-accent2 m-t-60 m-0-auto">
                                    <i aria-hidden="true" class="banana banana-arrow-left"></i>
                                    {{$t('terms-conditions.return-to-pagando')}}
                                </router-link>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {
        STORE as DocumentsStore,
        GET_TERMS_CONDITIONS,
        GET_PRIVACY_AGREEMENT,
        GET_CONTRACT,
        FIND_TERMS_CONDITIONS,
        FIND_PRIVACY_AGREEMENT,
        GET_LOADING_AGGREMENT,
        GET_TERMS_EXISTS
    } from "@/store/modules/documents/documents.types";
    import {
        STORE as LandingStore,
        SET_TAB_ACTIVE
    } from "@/store/modules/landing/landing.types";
    import {
        STORE as SessionStore,
        GET_USER
    } from "@/store/modules/session/session.types";

    // @ is an alias to /src
    // import LandingTitle from '@/components/LandingTitle.vue';
    import LoadingBar from '@/components/LoadingBar.vue';
    import CardSimple from '@/components/CardSimple.vue';

    export default {
        name: "TermsConditions",
        components: {
            LoadingBar,
            CardSimple
        },
        data() {
            return {}
        },
        beforeMount() {
            this.$store.commit(`${LandingStore}/${SET_TAB_ACTIVE}`, 'TERMS')
            this.$store.dispatch(`${DocumentsStore}/${FIND_PRIVACY_AGREEMENT}`)
            if (this.user !== undefined && this.user._id !== undefined) {
                this.$store.dispatch(`${DocumentsStore}/${FIND_TERMS_CONDITIONS}`, this.user._id)
            }
        },
        computed: {
            ...mapGetters(SessionStore, {
                user: GET_USER
            }),
            ...mapGetters(DocumentsStore, {
                terms: GET_TERMS_CONDITIONS,
                privacy: GET_PRIVACY_AGREEMENT,
                contract: GET_CONTRACT,
                loading: GET_LOADING_AGGREMENT,
                termsExists: GET_TERMS_EXISTS
            })
        }
    };
</script>
