import axios from 'axios'
import base from '@/api/base.api';
const namespace = 'refund-reasons';
export default {
    list: (params = {}) => {
        return axios
            .get(`${base.baseUrl}/${namespace}/active`, params)
            .then((response) => response.data.object);
    }
}
