<template>
    <div>
        <GenericTitle clazz="p-t-20" :title="$t('personal-information-form.title')"
                      :titleStrong="$t('personal-information-form.title-strong')"></GenericTitle>
        <b-alert class="alert-note" show dismissible variant="danger" v-if="getErrorNextStep">
            <p class="text-container"><img class="img-fluid"
                                           src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg" alt=""/>
                {{getErrorNextStepMessage}}
            </p>
        </b-alert>
        <!-- NACIONAL -->
        <form>
            <div class="row">
                <p class="col-12 f-18 c-plain_text f-300 font-body-light d-block m-b-20">
                    {{$t('personal-information-form.fill-information')}} </p>
                <div class="col-12" v-if="getCountries || []">
                    <BasicSelect
                            required
                            :label="$t('personal-information-form.nationality-label')"
                            labelFor="nacionalidad"
                            :clazz="getErrorClass('country')"
                            id="comboSelectNationality"
                    >
                        <multiselect v-model="country" @select="updateNationalitie" :options="getCountries || []"
                                     :close-on-select="true" :preserve-search="true"
                                     :placeholder="$t('personal-information-form.nationality-placeholder')"
                                     id="selectNationality"
                                     label="name" track-by="name" :preselect-first="false">
                            <template slot="selection" slot-scope="{ values, isOpen }">
                                <span class="multiselect__single"
                                      v-if="values.length &amp;&amp; !isOpen">{{ values.length
                                    }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>
                </div>


                <div class="col-12 col-sm-6 col-md-6">
                    {{$v.name.error}}
                    <BasicInput
                            required
                            id="inputErrorNames"
                            :inputValue="getActualUser.name"
                            inputType="text"
                            labelFor="names"
                            inputId="names"
                            :label="$t('personal-information-form.name-label')"
                            :clazz="getErrorClass('name')"
                            v-on:basicInput="updateName"
                            :warningText="$t('personal-information-form.name-warning')"
                            :inputPlaceholder="$t('personal-information-form.name-placeholder')"/>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <BasicInput
                            required
                            :inputValue="getActualUser.lastName"
                            inputType="text"
                            labelFor="apellidoPat"
                            inputId="apellidoPat"
                            :label="$t('personal-information-form.lastname-label')"
                            v-on:basicInput="updateLastName"
                            :clazz="getErrorClass('lastName')"
                            :warningText="$t('personal-information-form.lastname-warning')"
                            :inputPlaceholder="$t('personal-information-form.lastname-placeholder')"/>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <BasicInput
                            required
                            inputType="text"
                            :inputValue="getActualUser.secondLastName"
                            labelFor="apellidoMat"
                            inputId="apellidoMat"
                            :label="$t('personal-information-form.second-lastname-label')"
                            :clazz="getErrorClass('secondLastName')"
                            v-on:basicInput="updateLastNameMother"
                            :warningText="$t('personal-information-form.second-lastname-warning')"
                            :inputPlaceholder="$t('personal-information-form.second-lastname-placeholder')"/>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <BasicInput
                            required
                            inputType="date"
                            :inputValue="getActualUser.birthDay"
                            labelFor="date"
                            inputId="FechaNac"
                            :label="$t('personal-information-form.birthdate-label')"

                            v-on:basicInput="updateBirthDay"
                            :inputPlaceholder="'DD/MM/AAAA'"/>
                </div>
                <div class="col-12 col-md-12">
                    <BasicSelect
                        required
                        :label="$t('personal-information-form.state-label-levelone-birthState')"
                        labelFor="state"
                    >
                        <multiselect
                            v-model="birthState"
                            :validations="$v.birthState"
                            validationsNamespace="general.validations"
                            :options="getStates"
                            :placeholder="$t('cards.profile.state-placeholder-birthState')"
                            tag-placeholder="Agregar Estado"
                            label="name"
                            track-by="name"
                            :preselect-first="true"
                            class="select-up"
                            :multiple="false"
                            :taggable="true"
                            @input="updateBirthState"
                        ><span slot="noOptions">{{$t('cards.profile.state-empty-list')}}</span>
                        </multiselect>
                    </BasicSelect>
                </div>
                <div class="col-12 col-md-12">
                    <BasicSelect
                        required
                        :label="$t('personal-information-form.state-label-levelone')"
                        labelFor="state"
                    >
                        <multiselect
                            v-model="state"
                            :validations="$v.state"
                            validationsNamespace="general.validations"
                            :options="getStates || []"
                            :placeholder="$t('personal-information-form.state-placeholder-levelone')"
                            tag-placeholder="Agregar Estado"
                            label="name"
                            track-by="name"
                            :preselect-first="true"
                            class="select-up"
                            :multiple="false"
                            :taggable="true"
                            @input="updateState"
                        ><span slot="noOptions">{{$t('personal-information-form.state-empty-levelone')}}</span>
                        </multiselect>
                    </BasicSelect>
                </div>

                <div class="col-12 col-sm-6 col-md-6">
                    <BasicSelect
                            required
                            :label="$t('personal-information-form.genero-label')"
                            labelFor="genero"
                            id="selectGender"
                    >
                        <multiselect v-model="gender" @select="updateGender" :options="generos" :close-on-select="true"
                                     :preserve-search="true" placeholder="Elige tu género" label="name" track-by="name"
                                     :preselect-first="false">
                            <template slot="selection" slot-scope="{ values, isOpen }">
                                <span class="multiselect__single"
                                      v-if="values.length &amp;&amp; !isOpen">{{ values.length
                                    }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>
                </div>

                <div class="col-12">
                    <BasicSelect
                            required
                            :label="$t('personal-information-form.occupation-label')"
                            labelFor="ocupacion"
                            :clazz="getErrorClass('ocupacion')"
                            id="comboSelectOccupation"
                    >
                        <multiselect v-model="ocupacion" @select="updateOcupacion" :options="occupationsForSelect"
                                     :close-on-select="true" :preserve-search="true"
                                     :placeholder="$t('personal-information-form.occupation-placeholder')"
                                     id="selectOccupation"
                                     label="name" track-by="name" :preselect-first="false">
                            <template slot="selection" slot-scope="{ values, isOpen }">
                                <span class="multiselect__single"
                                      v-if="values.length &amp;&amp; !isOpen">{{ values.length
                                    }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>
                </div>


                <div class="col-12">
                    <BasicSelect
                            required
                            :label="$t('personal-information-form.profession-label')"
                            labelFor="profession"
                            :clazz="getErrorClass('profession')"
                            id="comboSelectProfession"
                    >
                        <multiselect v-model="profession" @select="updateProfession" :options="professionsForSelect"
                                     :close-on-select="true" :preserve-search="true"
                                     :placeholder="$t('personal-information-form.profession-placeholder')"
                                     id="selectProfession"
                                     label="name" track-by="name" :preselect-first="false">
                            <template slot="selection" slot-scope="{ values, isOpen }">
                                <span class="multiselect__single"
                                      v-if="values.length &amp;&amp; !isOpen">{{ values.length
                                    }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import GenericTitle from '@/components/GenericTitle.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import BasicSelect from '@/components/BasicSelect.vue';
    import Multiselect from 'vue-multiselect';
    import {mapGetters} from 'vuex';
    import moment from 'moment';
    import {required, minLength, helpers} from 'vuelidate/lib/validators';
    import {EventBus} from '@/main';

    const alphaDiacritic = helpers.regex('alphaDiacritic', /^[a-zA-ZÀ-ž]*(?: [a-zA-ZÀ-ž]+)*/);

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    const storeModule = "signUpOrganization";

    export default {
        name: "RegisterWizard",
        components: {
            GenericTitle,
            BasicInput,
            BasicSelect,
            Multiselect
        },
        created() {
            this.$store.commit(`${storeModule}/SET_ACTIVE_COMPONENT_STEP_ONE`, "PERSONAL_INFORMATION");
            this.$store.commit(`SIGNIN_UP/SET_RIGHT_SIDE_BODY`, 'STEP_TWO_FORM');
        },
        data() {
            return {
                actualNationalitie: "",
                actualUser: {},
                generos: [{name: "Masculino", value: "MALE"}, {name: "Femenino", value: "FEMALE"}],
                birthDay: new Date().toDateString(),
                name: "",
                lastName: "",
                secondLastName: "",
                gender: null,
                state: {},
                birthState: {},
                country: {},
                profession: {},
                ocupacion: {},
                actualUserImg: null,
                occupations: [],
                profesions: [],
                occupationsForSelect: [],
                professionsForSelect: [],
            }
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                alphaDiacritic
            },
            lastName: {
                required,
                minLength: minLength(3),
                alphaDiacritic
            },
            secondLastName: {
                required,
                minLength: minLength(3),
                alphaDiacritic
            },
            country: {
                required,
            },
            gender: {
                required
            },
            state: {
                required,
                minLength: minLength(2),
                alphaDiacritic
            },
            birthState: {
                required
            },
            birthDay: {
                required
            },
            ocupacion: {
                required
            },
            profession: {
                required
            }
        },
        methods: {
            getErrorClass(propertie) {
                return this.$v[propertie].$error ? "has-error" : "";
            },
            updateNationalitie: debounce(function (event) {
                this.country = event;
                this.$v.country.$touch();
                this.validateUserStep();
                this.$store.dispatch(`${storeModule}/FETCH_STATES`, event);
                this.$store.commit(`${storeModule}/UPDATE_COUNTRY_ACTUAL_USER`, event);
            }, 800, false),
            updateGender: debounce(function (event) {
                this.gender = event;
                this.$v.gender.$touch();
                this.validateUserStep();
                this.$store.commit(`${storeModule}/UPDATE_GENDER_ACTUAL_USER`, event.value);
            }, 800, false),
            updateOcupacion: debounce(function (event) {
                if (event) {
                    this.ocupacion = event;
                    this.$v.ocupacion.$touch();
                    this.validateUserStep();
                    this.$store.commit(`${storeModule}/UPDATE_OCCUPATION_POTENTIAL_ORGANIZATION`, event);
                    this.$store.commit(`${storeModule}/UPDATE_OCCUPATION_ACTUAL_USER`, event.name);
                }
            }, 800, false),
            updateProfession: debounce(function (event) {
                if (event) {
                    this.profession = event;
                    this.$v.profession.$touch();
                    this.validateUserStep();
                    this.$store.commit(`${storeModule}/UPDATE_PROFESSION_POTENTIAL_ORGANIZATION`, event);
                    this.$store.commit(`${storeModule}/UPDATE_PROFESSION_ACTUAL_USER`, event.name);
                }
            }, 800, false),
            updateName: debounce(function (name) {
                this.name = name;
                this.$v.name.$touch();
                this.validateUserStep();
                this.$store.commit(`${storeModule}/UPDATE_NAME_ACTUAL_USER`, name);
            }, 800, false),
            updateLastName: debounce(function (lastName) {
                this.lastName = lastName;
                this.$v.lastName.$touch();
                this.validateUserStep();
                this.$store.commit(`${storeModule}/UPDATE_LAST_NAME_ACTUAL_USER`, lastName);
            }, 800, false),
            updateLastNameMother: debounce(function (secondLastName) {
                this.secondLastName = secondLastName;
                this.$v.secondLastName.$touch();
                this.validateUserStep();
                this.$store.commit(`${storeModule}/UPDATE_LAST_NAME_MOTHER_ACTUAL_USER`, secondLastName);
            }, 800, false),
            updateBirthDay: debounce(function (birthDay) {
                this.userBirthDay = birthDay;
                this.$store.commit(`${storeModule}/UPDATE_BIRTHDAY_ACTUAL_USER`, birthDay);
            }, 800, false),
            updateState: debounce(function (state) {
                this.state = state;
                this.$v.state.$touch();
                this.validateUserStep();
                this.$store.commit(`${storeModule}/UPDATE_STATE_ACTUAL_USER`, state);
            }, 800, false),
            updateBirthState: debounce(function (state) {
                this.birthState = state;
                this.$v.birthState.$touch();
                this.validateUserStep();
                this.$store.commit(`${storeModule}/UPDATE_BIRTH_STATE_ACTUAL_USER`, state);
            }, 800, false),
            readFile(imgFile) {
                return new Promise((resolve) => {
                    const fr = new FileReader();
                    fr.onload = (e) => {
                        const actualUserImg = {};
                        const dataUrl = e.target.result;
                        actualUserImg.name = imgFile.name;
                        actualUserImg.type = imgFile.type;
                        actualUserImg.size = imgFile.size;
                        actualUserImg.image = dataUrl;
                        resolve(actualUserImg)
                    };
                    fr.readAsDataURL(imgFile);
                });
            },
            validateUserStep() {
                this.$v.$touch();
                const isValid = !this.$v.name.$error &&
                    !this.$v.lastName.$error &&
                    !this.$v.secondLastName.$error &&
                    !this.$v.country.$error &&
                    !this.$v.ocupacion.$error &&
                    !this.$v.profession.$error &&
                    !this.$v.state.$error &&
                    !this.$v.birthDay.$error &&
                    !this.$v.gender.$error;

                this.$v.$reset();
                this.$store.commit(`${storeModule}/SET_IS_VALID_USER_SAVE_FORM`, isValid)
                this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                    step: 'personalInformation',
                    prop: 'isReady',
                    value: isValid
                });
                EventBus.$emit('REGISTER_USER_READY_STEP');
            },
            initializeUser() {
                this.name = this.getActualUser.name;
                if (this.name !== undefined && this.name !== "") {
                    this.$v.name.$touch();
                }

                this.gender = this.generos.find(g => g.value === this.getActualUser.gender) || this.generos[0];
                this.$v.gender.$touch();
                this.lastName = this.getActualUser.lastName;
                if (this.lastName !== undefined && this.lastName !== "") {
                    this.$v.lastName.$touch();
                }
                this.secondLastName = this.getActualUser.secondLastName;
                if (this.secondLastName !== undefined && this.secondLastName !== "") {
                    this.$v.secondLastName.$touch();
                }
                this.state = this.getActualUser.state;
                if (this.state !== undefined && this.state !== "") {
                    this.$v.state.$touch();
                }
                this.birthDay = this.getActualUser.birthDay ? this.formatDate(this.getActualUser.birthDay) : this.formatDate();
            },
            formatDate(date) {
                return moment(date).format('YYYY-MM-DD');
            },
            initializeSelectValue({key, array, searchKey, defaultValue}) {
                if (key) {

                    if (this.getActualUser[key] !== undefined) {
                        const value = array.filter(val => val[searchKey] === this.getActualUser[key]);

                        this[key] = value[0];
                    } else {
                        const value = array.filter(val => val[searchKey] === defaultValue);
                        this[key] = value.length ? value[0] : array[0];
                        this.$v[key].$touch();
                    }
                }
            },
            getProfessionsForSelect() {
                for (let i = 0; i < this.professions.length; i++) {
                    const newProfession = {
                        name: this.professions[i].description,
                        value: this.professions[i]._id,
                        disabled: false
                    };
                    this.professionsForSelect.push(newProfession);
                }
            },
            getOccupationsForSelect() {
                for (let i = 0; i < this.occupations.length; i++) {
                    const newOccupation = {
                        name: this.occupations[i].name,
                        value: this.occupations[i]._id,
                        disabled: false,
                    };
                    this.occupationsForSelect.push(newOccupation);
                }
            },
        },
        mounted() {
            this.$store.dispatch(`${storeModule}/fetchCountries`);
            this.$store.dispatch(`${storeModule}/getProfessions`).then((result) => {
                this.professions = result.docs;
                this.getProfessionsForSelect();
                if (this.getPotentialOrganization.profession !== undefined) {
                    const value = this.professionsForSelect.filter(val => val.value === this.getPotentialOrganization.profession);
                    this.profession = value[0];
                } else {
                    this.profession = this.professionsForSelect[0];
                    this.$v.profession.$touch();
                }
                this.updateProfession(this.profession);
                this.$store.dispatch(`${storeModule}/getOccupations`).then((r) => {
                    this.occupations = r.docs;
                    this.getOccupationsForSelect();
                    this.initializeSelectValue({
                        key: "ocupacion",
                        array: this.occupationsForSelect,
                        searchKey: "value"
                    });
                    this.updateOcupacion(this.ocupacion);
                    this.initializeUser();
                    this.updateGender(this.gender);
                    this.updateBirthDay(this.birthDay);
                    this.validateUserStep();
                });
            });
        },
        computed: {
            ...mapGetters(storeModule, ['getActualUser',
                'getErrorNextStep',
                'getErrorNextStepMessage',
                'getPotentialOrganization',
                'getCountries',
                'getStates',
                'getCountry',
                'getState',
            ]),
            ...mapGetters('SIGNIN_UP', ['getRightSide'])
        },
        watch: {
            'getCountry': {
                handler(val){
                    this.country = this.getCountries.find(c => c._id === val._id);
                },
            },
            'getState': {
                handler(val){
                    this.state = val;
                },
            },
        },
    };
</script>
