<template>
	<input :id="inputId" class="edit-input" :class="clazz" v-bind:value="actualValue" :type="inputType"
			:placeholder="inputPlaceholder" @input="changeValue" v-on:keydown.enter.prevent='changeValue'
			aria-label="edit" :disabled="disabled"/>
</template>

<script>
export default {
	name: 'InputEdit',
	props: {
		inputId: String,
		inputPlaceholder: String,
		inputType: String,
		inputValue: {type: [String, Number], default: null},
		value: {type: [String, Number], default: null},
		clazz: String,
		disabled: {type: Boolean, default: false}
    },
    mounted() {
        if (this.value && !this.inputValue) {
            this.actualValue = this.value;
        }
        if (this.inputValue && !this.value) {
            this.actualValue = this.inputValue;
        }
    },
    data() {
        return {
            actualValue: 0
        };
    },
	methods: {
		changeValue(event) {
			this.$emit('input', event.target.value);
		}
	}
}
</script>
