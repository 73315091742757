<template>
   <section id="footer-section" class="footer-section-landing" :class="clazz">
       <div class="width-responsive">
           <div class="top-info">
               <span class="questions">
                   <h1 class="m-b-30" v-html="$t('landing.footer-have-questions')"></h1>
                   <a class="goto" :href="toBusinessHref('contact')">{{$t('landing.footer-contactus-for-help')}}</a>
               </span>
               <span class="meet">
                   <h1 class="m-b-20" v-html="$t('landing.footer-what-is')"></h1>
                   <router-link to="/about" class="goto" alt=""> {{$t('landing.know-more')}} </router-link>
               </span>
           </div>
           <div class="bottom-info">
               <span class="principal-section">
                    <img class="img-fluid" src="@/assets/images/Logos/PagandoChek-LogoWhite.svg" alt="Pagando"/>
                    <p>
                        {{$t('landing.footer-desc')}}
                        <br /><br />
                        <p v-html="$t('general.copyright', {year: currentYear})">
                    </p>
                    <div class="social-links">
                        <a href="https://www.facebook.com/pagandomx/" class="social-link" tabindex="">
                            <img class="img-fluid" src="@/assets/images/LandingPage/Icons/facebook.svg" alt=""/>
                        </a>
                        <a href="https://twitter.com/pagandomx" class="social-link" tabindex="">
                            <img class="img-fluid" src="@/assets/images/LandingPage/Icons/twitter.svg" alt=""/>
                        </a>
                        <a href="https://www.instagram.com/pagandomexico/" class="social-link" tabindex="">
                            <img class="img-fluid" src="@/assets/images/LandingPage/Icons/instagram.svg" alt=""/>
                        </a>
                    </div>
               </span>
               <span>
                    <h1 class="title-links"> {{$t('landing.footer-product')}} </h1>
                    <router-link to="/features" class="links-to-page" tabindex=""> {{$t('landing.footer-benefits')}} </router-link>
                    <a href="#comision-section" class="links-to-page" tabindex=""> {{$t('landing.footer-comissions')}} </a>
                    <router-link to="/features" class="links-to-page" tabindex=""> {{$t('landing.footer-features')}} </router-link>
                    <router-link to="" class="links-to-page" tabindex=""> {{$t('landing.footer-dep-date')}} </router-link>
               </span>
               <span>
                    <h1 class="title-links"> {{$t('landing.footer-support')}} </h1>
                    <a class="links-to-page" href="" tabindex=""> {{$t('landing.footer-support-client')}} </a>
                    <a class="links-to-page" href="https://pagando.zendesk.com/hc/es-419/categories/360003013732-Preguntas-Frecuentes" tabindex="">
                        {{$t('landing.footer-faq')}}
                    </a>
                    <a class="links-to-page" :href="toBusinessHref('contact')" tabindex="">{{$t('landing.footer-contact')}}</a>
               </span>
               <span>
                    <h1 class="title-links"> {{$t('landing.footer-enterprise')}} </h1>
                    <router-link to="/terms-conditions" class="links-to-page" tabindex=""> {{$t('landing.footer-privacy')}} </router-link>
                    <router-link to="/terms-conditions" class="links-to-page" tabindex=""> {{$t('landing.footer-terms')}} </router-link>
                    <router-link to="/" class="links-to-page" tabindex=""> {{$t('landing.footer-web')}} </router-link>
                    <a href="https://medium.com/@pagando" class="links-to-page" tabindex=""> {{$t('landing.footer-blog')}} </a>
               </span>
           </div>

            <b-alert class="alert-note m-t-0 m-b-0 p-r-20" show variant="footer-landing">
                <p class="text-container">
                    <i aria-hidden="true" class="icon banana banana-info-circle1 f-35"></i>
                    {{$t('landing.footer-leyend')}}
                    <br/><br/>
                    {{$t('landing.footer-leyend-2')}}
                </p>
            </b-alert>
       </div>
   </section>
</template>

<script>
    export default {
        name: 'FooterLanding',
        props: {
            clazz: String
        },
        data () {
            return {
                currentYear: 0
            }
        },
        methods: {
            toBusinessHref(to){
                return `https://business.pagando.mx/${to}`;
            },
        },
        mounted(){
            this.currentYear = new Date().getFullYear();
        }
    }
</script>

<style lang="scss">
    .footer-section-landing {
        position: relative;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 50px 0;

        .top-info {
            margin: 0 auto;
            padding-bottom: 150px;
            display: flex;
            width: 100%;
            justify-content: center;
            position: relative;

            &::before,
            &::after {
                content: "";
                background-repeat: no-repeat;
                background-size: contain;
                background-position: top;
                position: absolute;
                width: 100%;
                height: 100%;
                max-width: 350px;
                max-height: 350px;
                top: -20px;
                z-index: 0;
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }

            .questions,
            .meet {
                z-index: 1;
                padding-top: 30px;
                padding-bottom: 30px;
                width: 50%;

                > h1 {
                    font-size: 40px;
                    font-weight: normal;
                    line-height: normal;
                    display: block;
                }

                .goto {
                    transition: 0.3s ease-in-out;

                    &:hover {
                        animation: 1s pulse ease-in-out;
                        opacity: 0.8;
                    }
                }
            }

            .questions {
                padding-right: 7%;

                > h1 {
                    text-align: right;
                }

                > .goto {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.67;
                    text-align: left;
                    padding-left: 40px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: fit-content;
                    margin: 0 0 0 auto;

                    &::before {
                        content: "";
                        width: 30px;
                        height: 30px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        position: absolute;
                        left: 0;
                    }
                }
            }

            .meet {
                padding-left: 7%;

                > h1 {
                    text-align: left;
                }

                > .goto {
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 1.67;
                    text-align: left;
                    padding-right: 45px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    max-width: calc(185px + 35px);

                    &::after {
                        content: "";
                        width: 35px;
                        height: 45px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }

        .bottom-info {
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: stretch;

            > span {
                margin-bottom: 40px;
                padding: 0 10px;
            }

            .principal-section {
                > img {
                    display: block;
                    max-height: 30px;
                    object-fit: contain;
                    margin-bottom: 30px;
                }

                > p {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.71;
                    display: block;
                    margin-bottom: 5px;
                    max-width: 420px;
                }

                .social-links {
                    display: flex;
                    align-items: center;

                    > .social-link {
                        position: relative;
                        display: inline-flex;
                        margin-right: 10px;
                        width: 32px;
                        min-width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        transition: 0.5s;

                        > img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                        > i {
                            font-size: 20px;
                        }

                        &:hover {
                            animation: 1s rubberBand ease-in-out;
                        }
                    }
                }
            }

            .title-links {
                font-size: 16px;
                font-weight: bold;
                line-height: normal;
                text-align: left;
                text-transform: uppercase;
                display: block;
                margin-bottom: 30px;
            }

            .links-to-page {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.57;
                text-align: left;
                display: block;
                margin-bottom: 20px;
                position: relative;
                width: fit-content;
                transition: 0.5s;

                &::after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    border-radius: 4px;
                    display: block;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transition: all .3s ease-in-out 0s;
                    transition: all .3s ease-in-out 0s;
                }

                &:hover {
                    &::after {
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
</style>
