<template>
    <div>
        <ProcessLoadingPage textComplete
                            :title="$t('signup-organization.final-setup.title')"
                            :strongTitle="$t('signup-organization.final-setup.title-strong')"
                            :text="$t('signup-organization.final-setup.sub-title')"
                            :btnText="$t('signup-organization.final-setup.btnText')"
                            :btnContinue="configurationComplete"
                            pagandoBisId="btn-pagandoBusiness"
                            v-on:btnClick="goToDashboard"
        >
            <div :class="createClass"
                 v-if="(finishOrganization.create.inProcess || finishOrganization.create.finished) && !configurationComplete">
                <div class="icon-rotate" v-if="finishOrganization.create.inProcess">
                    <i aria-hidden="true" class="banana banana-loading"></i>
                </div>
                <i aria-hidden="true" v-if="!finishOrganization.create.inProcess" class="banana banana-check-normal c-success"></i>
                <div class="m-l-10"> {{$t('signup-organization.final-setup.creating-organization')}}<span>…</span></div>
            </div>
            <div :class="contractClass"
                 v-if="(finishOrganization.contractCreate.inProcess || finishOrganization.contractCreate.finished) && !configurationComplete">
                <div class="icon-rotate" v-if="finishOrganization.contractCreate.inProcess">
                    <i aria-hidden="true" class="banana banana-loading"></i>
                </div>
                <i aria-hidden="true" v-if="!finishOrganization.contractCreate.inProcess" class="banana banana-check-normal c-success"></i>
                <div class="m-l-10"> {{$t('signup-organization.final-setup.creating-contract')}}<span>…</span></div>
            </div>
            <div :class="configClass"
                 v-if="(finishOrganization.configure.inProcess || finishOrganization.configure.finished) && !configurationComplete">
                <div class="icon-rotate" v-if="finishOrganization.configure.inProcess">
                    <i aria-hidden="true" class="banana banana-loading"></i>
                </div>
                <i aria-hidden="true" v-if="!finishOrganization.configure.inProcess" class="banana banana-check-normal c-success"></i>
                <div class="m-l-10"> {{$t('signup-organization.final-setup.configure-organization')}}<span>…</span>
                </div>
            </div>
            <div :class="collaboratorsClass"
                 v-if="(finishOrganization.collaborators.inProcess || finishOrganization.collaborators.finished) && !configurationComplete">
                <div class="icon-rotate" v-if="finishOrganization.collaborators.inProcess">
                    <i aria-hidden="true" class="banana banana-loading"></i>
                </div>
                <i aria-hidden="true" v-if="!finishOrganization.collaborators.inProcess" class="banana banana-check-normal c-success"></i>
                <div class="m-l-10"> {{$t('signup-organization.final-setup.creating-collaborators')}}<span>…</span>
                </div>
            </div>
        </ProcessLoadingPage>
    </div>

</template>

<script>
    import ProcessLoadingPage from '@/components/ProcessLoadingPage.vue';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import axios from 'axios';
    import {mapGetters} from 'vuex';
    import router from "@/router";
    import abilityBuilder from "@/permissions/abilities";
    import {
        STORE as SessionStore,
        GET_USER,
        GET_TOKEN,
        CHANGE_PROFILE_JWT
    } from '@/store/modules/session/session.types';
    import {
        GET_CURRENT_ORGANIZATION,
        STORE as AccessConfigStore,
        SET_CURRENT_ORGANIZATION
    } from '@/store/modules/accessconfig/accessConfig.types';

    const storeModule = "signUpOrganization";
    const ITEM_PASSED = 'item passed';

    export default {
        data() {
            return {
                finishOrganization: {
                    create: {
                        inProcess: false,
                        finished: false
                    },
                    contractCreate: {
                        inProcess: false,
                        finished: false
                    },
                    configure: {
                        inProcess: false,
                        finished: false
                    },
                    collaborators: {
                        inProcess: false,
                        finished: false
                    }
                }
            }
        },
        computed: {
            ...mapGetters(storeModule, ['getPotentialOrganization']),
            ...mapGetters(SessionStore, {user: GET_USER, token: GET_TOKEN}),
            finishTitle() {
                return this.configurationComplete ? 'Bienvenido a <strong>Pagando Check </strong>' : 'Ajustando últimos detalles ...';
            },
            configurationComplete() {
                return this.finishOrganization.create.finished &&
                    this.finishOrganization.configure.finished
            },
            contractClass() {
                return this.finishOrganization.contractCreate.finished ? ITEM_PASSED : 'item';
            },
            createClass() {
                return this.finishOrganization.create.finished ? ITEM_PASSED : 'item';
            },
            configClass() {
                return this.finishOrganization.configure.finished ? ITEM_PASSED : 'item';
            },
            collaboratorsClass() {
                return this.finishOrganization.collaborators.finished ? ITEM_PASSED : 'item';
            }
        },
        mixins: [
            ToastSnotify
        ],
        methods: {
            goToDashboard() {
                this.$router.push({name: 'dashboard'});
            },
            async startProcess() {
                try {

                    const {errCreate, responseCreate} = await this.createOrganization();

                    if (errCreate) {
                        this.$snotify.clear();
                        this.displayNotificationError(errCreate.message);
                        this.clearProcessState();
                        return;
                    }

                    this.finishOrganization.create.finished = true;
                    this.finishOrganization.create.inProcess = false;

                    const paramsContract = {
                        organization: responseCreate.data._id,
                        idPotentialOrganization: responseCreate.data.potentialOrganization,
                        user: responseCreate.user
                    };

                    const {errContract} = await this.createContract(paramsContract);

                    if (errContract) {
                        this.$snotify.clear();
                        this.displayNotificationError(errContract.message);
                        this.clearProcessState();
                        return;
                    }

                    this.finishOrganization.contractCreate.finished = true;
                    this.finishOrganization.contractCreate.inProcess = false;

                    const paramsConfig = {
                        organization: responseCreate.data._id,
                        idPotentialOrganization: responseCreate.data.potentialOrganization,
                        user: responseCreate.user
                    };

                    const {errConfigure, responseConfigure} = await this.configureOrganization(paramsConfig);


                    if (errConfigure) {
                        this.$snotify.clear();
                        this.displayNotificationError(errConfigure.message);
                        this.clearProcessState();
                        return;
                    }
                    this.finishOrganization.configure.finished = true;
                    this.finishOrganization.configure.inProcess = false;
                    this.$store.dispatch(`${SessionStore}/${CHANGE_PROFILE_JWT}`, responseConfigure.object.profile._id)
                    if (responseConfigure.object.profile) {
                        const builder = abilityBuilder.buildAbilities({
                            profile: responseConfigure.object.profile,
                            permissions: responseConfigure.object.permissions
                        });
                        router.app.$ability.update(builder.rules);
                    }
                    localStorage.setItem(GET_CURRENT_ORGANIZATION, JSON.stringify(responseCreate.data));
                    this.$store.commit(`${AccessConfigStore}/${SET_CURRENT_ORGANIZATION}`, responseCreate.data);
                    const paramsCollaborators = {
                        organization: responseCreate.data._id,
                        idPotentialOrganization: responseCreate.data.potentialOrganization,
                        user: responseCreate.user
                    };

                    const {errCollaborator} = await this.addCollaborators(paramsCollaborators);

                    if (errCollaborator) {
                        this.$snotify.clear();
                        this.displayNotificationError(errCollaborator.message);
                        this.clearProcessState();
                        return;
                    }
                    this.finishOrganization.collaborators.finished = true;
                    this.finishOrganization.collaborators.inProcess = false;
                    this.finishOrganization.create.inProcess = false;
                    this.finishOrganization.configure.inProcess = false;


                } catch (err) {
                    this.$snotify.clear();
                    this.displayNotificationError("Ocurrio un error inesperado, por favor contacte a soporte");
                    this.clearProcessState();

                }

            },
            createOrganization() {
                const _this = this;
                this.finishOrganization.create.inProcess = true;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(_this.$store.dispatch(`${storeModule}/creatingOrganization`))
                    }, 2000);
                });

            },
            createContract(params) {
                const _this = this;
                this.finishOrganization.contractCreate.inProcess = true;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(_this.$store.dispatch(`${storeModule}/creatingContractAccepted`, params))
                    }, 2000);
                });

            },
            configureOrganization(params) {
                const _this = this;
                this.finishOrganization.configure.inProcess = true;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(_this.$store.dispatch(`${storeModule}/configureOrganization`, params));
                    }, 2000);
                });
            },
            addCollaborators(params) {
                const _this = this;
                this.finishOrganization.collaborators.inProcess = true;
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(_this.$store.dispatch(`${storeModule}/saveCollaboratorsInOrganization`, params));
                    }, 2000);
                });
            },
            clearProcessState() {
                this.finishOrganization = {
                    create: {
                        inProcess: false,
                        finished: false
                    },
                    configure: {
                        inProcess: false,
                        finished: false
                    }
                };
            },
            async initProcess() {
                const userId = this.user._id;
                await this.$store.dispatch('signUpOrganization/loadPotentialOrganization', {userId});

                this.startProcess();
            }
        },
        components: {
            ProcessLoadingPage
        },
        mounted() {
            this.initProcess();
        },
        async created() {
            const token = this.token;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        },
    }
</script>
