<template>
    <div>
        <!--  FAVOR DE NO MODIFICAR NINGUN COMPONENTE DE ESTA VISTA, SOLO ES DE EJEMPLO -->
        <div class="register-container">
            <div class="left-side-content">
                <div class="container-left">
                    <img class="img-fluid logo" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" width="166" height="60" alt="Logo Pagando Check"/>
                    <span class="d-block w-100">
                        <p class="info-text">
                            Digitaliza tu negocio aceptando <strong>pagos con terminal</strong>,
                            integrando nuestra <strong>API</strong> a tu sitio web o usando nuestros
                            <strong>plugins de pago</strong> disponibles en las soluciones
                            de e-commerce más populares.
                        </p>
                        <div class="border-container">
                            <p class="info-text m-b-15 text-left">
                                Para realizar la contratación de este servicio, solo
                                es necesario que completes la siguiente información:
                            </p>
                            <ul class="info-text-list">
                                <li>Datos de contacto de tu negocio</li>
                                <li>Subir documentos</li>
                                <li>Ubicación de tu negocio desde un mapa</li>
                            </ul>
                        </div>
                        <p class="info-text m-b-0">
                            Nuestro equipo de soporte se comunicará contigo al correo
                            <strong class="c-info">andreaguerra@gmail.com</strong> para notificarte
                            sobre la aprobación de tu solicitud.
                        </p>
                    </span>
                    <div class="footer-help">
                        <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
                            <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" width="365" height="153" alt="Chat de soporte" />
                        </a>
                        <p class="help-num">
                            También puedes contactarnos a los teléfonos:
                            <br/>
                            <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="right-side-content">
                <div class="container-right">
                    <!-- DATOS DE TU NEGOCIO -->
                    <TitlePrincipal
                        title="Datos de tu negocio"
                        subtitle="Para realizar la contratación de Pagando Check,
                        es necesario que completes los siguientes datos de tu negocio.">
                    </TitlePrincipal>
                    <div class="row">
                        <!-- PERSONA FISICA -->
                        <div class="col-12">
                            <BasicInput
                                required
                                labelFor=""
                                inputId=""
                                label="Dirección de tu negocio"
                                inputPlaceholder="Ingresa tu dirección completa"
                            ></BasicInput>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                required
                                labelFor=""
                                inputId=""
                                label="Teléfono móvil de tu negocio"
                                inputPlaceholder="(000) 000-0000"
                            ></BasicInput>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicInput
                                required
                                labelFor=""
                                inputId=""
                                label="Teléfono fijo de tu negocio"
                                inputPlaceholder="(000) 000-0000"
                            ></BasicInput>
                        </div>
                        <div class="col-12">
                            <BasicInput
                                required
                                labelFor=""
                                inputId=""
                                label="Correo electrónico de tu negocio"
                                inputPlaceholder="Ingresa tu dirección de correo electrónico"
                            ></BasicInput>
                        </div>
                        <div class="col-12">
                            <BasicInput
                                required
                                labelFor=""
                                inputId=""
                                label="RFC del cliente"
                                inputPlaceholder="RFC"
                            ></BasicInput>
                        </div>
                        <div class="col-12">
                            <BasicSelect
                                required
                                label="¿Con qué esquema deseas trabajar?">
                                <multiselect
                                    v-model="valueEsquema"
                                    :options="optionsEsquema"
                                    :close-on-select="true"
                                    :preserve-search="true"
                                    placeholder="Selecciona la forma en que quieres cobrar"
                                    label="name"
                                    track-by="name"
                                    :preselect-first="false"
                                >
                                    <template
                                        slot="selection"
                                        slot-scope="{ values, search, isOpen }"
                                    >
                                        <span
                                            class="multiselect__single"
                                            v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>
                        </div>
                        <!-- PERSONA MORAL -->
                        <div class="col-12">
                            <BasicInput
                                required
                                labelFor=""
                                inputId=""
                                label="Nombre del representante legal"
                                inputPlaceholder="Ingresa el nombre completo"
                            ></BasicInput>
                        </div>
                        <div class="col-12">
                            <BasicSelect
                                required
                                label="¿Con qué esquema deseas trabajar?">
                                <multiselect
                                    v-model="valueEsquema"
                                    :options="optionsEsquema"
                                    :close-on-select="true"
                                    :preserve-search="true"
                                    placeholder="Selecciona la forma en que quieres cobrar"
                                    label="name"
                                    track-by="name"
                                    :preselect-first="false"
                                >
                                    <template
                                        slot="selection"
                                        slot-scope="{ values, search, isOpen }"
                                    >
                                        <span
                                            class="multiselect__single"
                                            v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>
                        </div>
                    </div>

                    <!-- SUBIR DOCUMENTOS -->
                    <TitlePrincipal
                        clazz="m-t-50"
                        title="Subir documentos"
                        subtitle="Reúne y sube cada uno de los documentos
                        solicitados para completar tu perfil de negocio.">
                    </TitlePrincipal>
                    <CardSelection title="Credencial de elector"
                                    subtitle="Vista frontal (Max 15 mb, pdf, jpg, png)"
                                    clazz="border-card icon-bgm-info_text">
                        <template v-slot:icon>
                            <i aria-hidden="true" class="banana banana-profile"></i>
                        </template>
                        <template v-slot:extraMiddleContent>
                            <b-progress
                                :max="maxProgressBar"
                                class="bar-progress percent-color"
                                :class="{'success' : valueProgressBar0 == 100 }"
                            >
                                <b-progress-bar :value="valueProgressBar0">
                                    <div class="label-progress">
                                        <span>{{ valueProgressBar0 }}%</span>
                                    </div>
                                </b-progress-bar>
                            </b-progress>
                        </template>
                        <template v-slot:extraFinalContent>
                            <button type="button" class="button xs button-filled button-primary p-relative">
                                Subir
                                <input type="file" class="input-file" value="" name=""/>
                            </button>
                        </template>
                    </CardSelection>
                    <CardSelection title="Comprobante de domicilio"
                                    subtitle="Agua, Luz, Teléfono (No mayor a 3 meses)"
                                    clazz="border-card icon-bgm-accent2">
                        <template v-slot:icon>
                            <i aria-hidden="true" class="banana banana-document-normal"></i>
                        </template>
                        <template v-slot:extraMiddleContent>
                            <b-progress
                                :max="maxProgressBar"
                                class="bar-progress percent-color"
                                :class="{'success' : valueProgressBar == 100 }"
                            >
                                <b-progress-bar :value="valueProgressBar">
                                    <div class="label-progress">
                                        <span>{{ valueProgressBar }}%</span>
                                    </div>
                                </b-progress-bar>
                            </b-progress>
                        </template>
                        <template v-slot:extraFinalContent>
                            <button type="button" class="button xs button-filled button-primary" disabled>
                                Subiendo...
                            </button>
                        </template>
                    </CardSelection>
                    <CardSelection title="Comprobante de domicilio"
                                    subtitle="Agua, Luz, Teléfono (No mayor a 3 meses)"
                                    clazz="border-card icon-bgm-error">
                        <template v-slot:icon>
                            <i aria-hidden="true" class="banana banana-document-normal"></i>
                        </template>
                        <template v-slot:extraMiddleContent>
                            <p class="f-12 c-error poppins-font-semibold d-block m-0">Hubo un problema al subir el documento</p>
                        </template>
                        <template v-slot:extraFinalContent>
                            <button type="button" class="button xs button-filled button-primary p-relative">
                                Reintentar
                                <input type="file" class="input-file" value="" name=""/>
                            </button>
                        </template>
                    </CardSelection>
                    <CardSelection title="Credencial de elector"
                                    subtitle="Vista trasera (Max 15 mb, pdf, jpg, png)"
                                    clazz="border-card icon-bgm-success">
                        <template v-slot:icon>
                            <i aria-hidden="true" class="banana banana-credit-card"></i>
                        </template>
                        <template v-slot:extraMiddleContent>
                            <b-progress
                                :max="maxProgressBar"
                                class="bar-progress percent-color"
                                :class="{'success' : valueProgressBar100 == 100 }"
                            >
                                <b-progress-bar :value="valueProgressBar100">
                                    <div class="label-progress">
                                        <span>{{ valueProgressBar100 }}%</span>
                                    </div>
                                </b-progress-bar>
                            </b-progress>
                        </template>
                        <template v-slot:extraFinalContent>
                            <i aria-hidden="true" class="banana banana-check-normal f-30 c-success"></i>
                        </template>
                    </CardSelection>

                    <!-- UBICACION -->
                    <TitlePrincipal
                        clazz="m-t-50"
                        title="Ubicación"
                        subtitle="Selecciona la ubicación de tu negocio.">
                    </TitlePrincipal>
                    <GoogleMap
                        clazz="m-b-0"
                        height="height:400px"
                        searchBarButton
                        locationOption
                        tooltipMark
                        tooltipText="Arrastra para cambiar la ubicación o haz clic para obtener información."
                    >
                    </GoogleMap>

                    <div class="footer-buttons right">
                        <button type="button" class="button button-ghost button-plain_text" name="Regresar"> Regresar </button>
                        <button type="button" class="button button-filled button-primary" name="Continuar" disabled> Continuar </button>
                    </div>
                </div>
            </div>
            <div class="footer-help responsive">
                <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
                    <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" width="365" height="153" alt="Chat de soporte" />
                </a>
                <p class="help-num">
                    También puedes contactarnos a los teléfonos:
                    <br/>
                    <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import TitlePrincipal from "@/components/TitlePrincipal.vue";
    import GoogleMap from "@/components/GoogleMap.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import BasicSelect from "@/components/BasicSelect.vue";
    import Multiselect from "vue-multiselect";
    import CardSelection from "@/components/CardSelection.vue";

    export default {
        name: "PagandoCheckHireServiceUx",
        components: {
            TitlePrincipal,
            GoogleMap,
            BasicInput,
            BasicSelect,
            Multiselect,
            CardSelection,
        },
        data() {
            return {
                // Multiselect
                valueEsquema: [],
                optionsEsquema: [
                    { name: "Tarjeta presente (Terminal)"},
                    { name: "Tarjeta no presente (API y Plugins de pago para E-commerce)"},
                    { name: "Ambos"}
                ],
                // Progress Bar
                valueProgressBar: 80,
                maxProgressBar: 100,
                valueProgressBar0: 0,
                valueProgressBar100: 100,
            }
        },
        methods: {
        }
    };
</script>
