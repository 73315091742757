<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <a class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit" tabindex="">
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i> Regresar a Tipos de Pagos</a>
                <FloatingTitleLayout title="Pago Rápido"/>
            </div>

            <WizardForm clazz="col-12">
                <template v-slot:wizardView>
                    <tab-content
                            title="Datos generales"
                            icon="one"
                    >
                        PASO 1

                    </tab-content>
                    <tab-content
                            title="Conceptos"
                            icon="two"
                    >
                        PASO 2
                    </tab-content>
                    <tab-content
                            title="Resumen"
                            icon="three"
                    >
                        PASO 3
                    </tab-content>
                </template>
            </WizardForm>

            <div class="col-12">
                <b-alert class="alert-note" show variant="info">
                    <p class="text-container">
                        <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                alt="info"
                        />
                        El rango permitido para las cantidades a pagar usando el Pago Rápido es de mínimo $4.00 MXN y
                        máximo $500.00 MXN. Si necesitas modificar este rango contáctanos.
                    </p>
                </b-alert>

                <!-- PASO 1 -->
                <CardSimple title="Datos generales del servicio"
                            subtitle="Establece las especificaciones básicas de tu pago.
                            Esta configuración ayudará a que tus clientes encuentren tu servicio e identifiquen la información más relevante.">
                    <div class="row">
                        <div class="col-12">
                            <BasicInput
                                    required
                                    inputType="text"
                                    labelFor="serviceName"
                                    inputId="serviceName"
                                    label="Nombre del servicio"
                                    inputPlaceholder="¿Con qué nombre quieres que la gente vea tu servicio?"></BasicInput>
                        </div>
                        <div class="col-12">
                            <BasicTextarea
                                    required
                                    labelFor="serviceDesc"
                                    textareaId="serviceDesc"
                                    label="Descripción del servicio"
                                    textareaPlaceholder="Escribe de manera breve de qué trata tu servicio y qué es lo que ofrece..."></BasicTextarea>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicSelect
                                    required
                                    label="Estatus"
                                    labelFor=""
                                    help
                                    idHelpTooltip="estatus-Tooltip"
                                    helpText="¿Cuál elegir?"
                                    helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="estatus-Tooltip" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p
                                                    class="m-b-0"
                                            >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>

                                <multiselect
                                        v-model="valueEstatus"
                                        :options="optionsEstatus"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Elige cómo quieres publicar tu servicio"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                >
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span
                                            class="multiselect__single"
                                            v-if="values.length &amp;&amp; !isOpen"
                                    >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>
                        </div>
                        <div class="col-12 col-md-6">
                            <BasicSelect
                                    required
                                    label="Divisa"
                                    labelFor=""
                                    help
                                    idHelpTooltip="quees-Tooltip"
                                    helpText="¿Qué es?"
                                    helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="quees-Tooltip" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p
                                                    class="m-b-0"
                                            >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>

                                <multiselect
                                        v-model="valueQue"
                                        :options="optionsQue"
                                        :close-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Selecciona con cuál divisa quieres cobrar"
                                        label="name"
                                        track-by="name"
                                        :preselect-first="false"
                                >
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span
                                            class="multiselect__single"
                                            v-if="values.length &amp;&amp; !isOpen"
                                    >{{ values.length }} options selected</span>
                                    </template>
                                </multiselect>
                            </BasicSelect>
                        </div>
                        <div class="col-12">
                            <div class="input-label">
                                <label>Agrega imagen(es) del servicio <span>*</span></label>
                                <div id="img-Tooltip" class="help-info">
                                    ¿Dónde se muestran? <i aria-hidden="true" class="banana banana-question-circle1"></i>
                                    <b-tooltip target="img-Tooltip" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p class="m-b-0">??</p>
                                        </div>
                                    </b-tooltip>
                                </div>
                            </div>
                            <UploadArea clazz="m-b-30" clazzArea="col-6">
                                <SimpleNote clazz="info m-b-0 m-l-30">
                                    <p class="m-b-0">
                                        El tamaño de las imágenes debe ser de <strong>1440px por 420px</strong> para que
                                        se visualicen correctamente. Puedes subir hasta 5 imágenes: <strong>.JPG y
                                        .PNG</strong>
                                    </p>
                                </SimpleNote>
                            </UploadArea>
                            <div class="vertical-upload-list">
                                <ListUploadArea>
                                    <UploadArea
                                            imgUploaded
                                            hideButtonClose
                                            numList="1"
                                    ></UploadArea>
                                </ListUploadArea>
                                <ListUploadArea>
                                    <UploadArea
                                            imgUploaded
                                            hideButtonClose
                                            numList="2"
                                    ></UploadArea>
                                </ListUploadArea>
                                <ListUploadArea>
                                    <UploadArea
                                            imgUploaded
                                            hideButtonClose
                                            numList="3"
                                    ></UploadArea>
                                </ListUploadArea>
                                <ListUploadArea>
                                    <UploadArea
                                            imgUploaded
                                            hideButtonClose
                                            numList="4"
                                    ></UploadArea>
                                </ListUploadArea>
                                <ListUploadArea disableButtons>
                                    <UploadArea></UploadArea>
                                </ListUploadArea>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-label m-b-20">
                                <label>Selecciona la categoría del servicio <span>*</span></label>
                            </div>
                            <SimpleCarousel :itemsCarousel="[[0, 2],[600, 3],[991, 4],[1230, 4]]"
                                            clazz="simpleCarousel-container">
                                <slide>
                                    <CardItem clazz="category-card selected">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-food.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Alimentos</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-health.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Belleza y Cuidado Personal</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-sports.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Deportes</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-school.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Educación y Capacitación</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-place.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Espacios y Vivienda</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-party.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Fiestas y Eventos</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-home.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Hogar</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-garden.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Jardinería</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-pets.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Mascotas</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-profesionals.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Profesionales</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Recreación y Turismo</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-tools.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Reparación y Mantenimiento</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Ropa y Accesorios</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-services.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Servicios</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-clothes.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Transporte</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                                <slide>
                                    <CardItem clazz="category-card">
                                        <div class="img-container">
                                            <img
                                                    class="background"
                                                    src="@/assets/images/Default/dots-grid.svg"
                                                    alt
                                            />
                                            <img
                                                    class="icon"
                                                    src="@/assets/images/Illustrations/categories/Illustration-others.svg"
                                                    alt
                                            />
                                        </div>
                                        <div class="text-container">
                                            <h1>Otros</h1>
                                        </div>
                                    </CardItem>
                                </slide>
                            </SimpleCarousel>
                        </div>
                        <div class="col-12">
                            <BasicSelect required
                                         label="Tags"
                                         labelFor=""
                                         help
                                         idHelpTooltip="tags-Tooltip"
                                         helpText="¿Cómo funcionan los tags?"
                                         helpIcon="banana banana-question-circle1">
                                <template v-slot:tooltipHelp>
                                    <b-tooltip target="tags-Tooltip" placement="top" custom-class="white-tooltip">
                                        <div>
                                            <p
                                                    class="m-b-0"
                                            >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </b-tooltip>
                                </template>

                                <multiselect v-model="valueTags" :options="optionsTags"
                                             placeholder="Asignar tags a este servicio"
                                             tag-placeholder="Agregar como nuevo tag" label="name" track-by="name"
                                             :preselect-first="false" class="select-up" :multiple="true"
                                             :taggable="true"></multiselect>
                            </BasicSelect>
                        </div>
                        <div class="col-12">
                            <button id class="collapse-btn" type="button" v-b-toggle.collapse-config>Configuración
                                avanzada <i aria-hidden="true" class="banana banana-chevron-down"></i></button>
                            <b-collapse id="collapse-config" class="m-t-40">
                                <BasicInput
                                        labelFor=""
                                        inputId=""
                                        label="Identificador externo"
                                        inputPlaceholder="Introduce el identificador de este servicio en notificaciones de WebHooks, consultas en la API, etc."
                                        help
                                        helpText="¿Qué es un ID externo?"
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="idExterno-tooltip">
                                    <template v-slot:tooltipHelp>
                                        <b-tooltip target="idExterno-tooltip" placement="top"
                                                   custom-class="white-tooltip">
                                            <div>
                                                <p
                                                        class="m-b-0"
                                                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </template>
                                </BasicInput>
                                <div class="vertical-center m-b-30">
                                    <BasicCheckbox clazz="m-0 w-fit"
                                                   text="Pedir al cliente que ingrese notas adicionales"
                                                   :index="0"></BasicCheckbox>
                                    <div id="checkbox-tooltip" class="help-info m-0-0-0-auto">
                                        ¿Para qué sirve esto? <i aria-hidden="true" class="banana banana-question-circle1"></i>
                                        <b-tooltip target="checkbox-tooltip" placement="top"
                                                   custom-class="white-tooltip">
                                            <div>
                                                <p
                                                        class="m-b-0"
                                                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </b-tooltip>
                                    </div>
                                </div>
                                <BasicCheckbox clazz="m-b-30"
                                               text="Establecer el campo de notas adicionales como obligatorio"
                                               :index="1"></BasicCheckbox>
                                <BasicInput
                                        required
                                        labelFor=""
                                        inputId=""
                                        label="Título del campo de notas adicionales"
                                        inputPlaceholder="P. ej: Notas adicionales, talla, color, comentarios, dirección, etc.">
                                </BasicInput>
                                <BasicInput
                                        required
                                        labelFor=""
                                        inputId=""
                                        label="Instrucciones de cómo deben ser ingresadas las notas adicionales"
                                        inputPlaceholder="P. ej: Ingrese aquí la talla que requiere (XS, S, M, L, XL, XXL)">
                                </BasicInput>
                            </b-collapse>
                        </div>
                        <div class="col-12 m-t-60 vertical-center justify-content-end">
                            <button type="button" class="button xs button-ghost button-plain_text m-r-5">Cancelar
                            </button>
                            <button type="button" class="button xs button-filled button-accent1" disabled>Siguiente
                            </button>
                        </div>
                    </div>
                </CardSimple>

                <!-- PASO 2 -->
                <ExpressPaymentConcepts></ExpressPaymentConcepts>

                <!-- PASO 3 -->
                <CardSimple title="Resumen"
                            subtitle="Has terminado de configurar tu servicio.
                            Aquí se muestra un resumen de la información ingresada para que puedas verificar que sea correcta.
                            Si encuentras algún error puedes editar lo que sea necesario.">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="f-20 c-title principal-font-bold d-block m-b-30">Datos Generales</h1>
                            <div class="row">
                                <div class="col-12 col-md-5 m-b-30">
                                    <b-carousel
                                            id="OneItemCarousel"
                                            fade
                                            indicators
                                            controls
                                            :interval="10000"
                                    >
                                        <b-carousel-slide
                                                img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/A1E74CED-4B97-4F9C-A7DF-D43FEAA54454.png"></b-carousel-slide>
                                        <b-carousel-slide
                                                img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/8A0B51A8-7BAA-487B-9755-E71C709D4C11.png"></b-carousel-slide>
                                        <b-carousel-slide
                                                img-src="https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/39B68F24-72B4-45B1-A25E-FA557AAE8C57.png"></b-carousel-slide>
                                    </b-carousel>
                                </div>
                                <div class="col-12 col-md-7 m-b-30">
                                    <Badge type="badge-accent_2 m-b-10" text="Activo"></Badge>
                                    <h1 class="f-20 c-title body-font-regular d-block m-b-10">Arreglos florales</h1>
                                    <label class="f-14 c-info_text body-font-semibold vertical-center text-upper m-b-15">Fiestas
                                        y Eventos</label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-0">¡Nuevos diseños de
                                        temporada! Tenemos gran variedad de flores, formas y materiales para que las
                                        mesas de su evento luzcan espectaculares.</p>
                                    <p class="f-14 c-placeholder body-font-light f-italic d-block m-b-0">No escribiste
                                        una descripción para tu servicio.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-5 m-b-30">
                                    <label class="f-14 c-title body-font-semibold f-600 d-block m-b-5">Divisa</label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-0">Pesos mexicanos
                                        (MXN)</p>
                                </div>
                                <div class="col-12 col-md-7 m-b-20">
                                    <label class="f-14 c-title body-font-semibold f-600 d-block m-b-5">Tags</label>
                                    <Badge type="badge-accent_2 m-r-10 m-b-10" text="Flores" closeBtnShow></Badge>
                                    <Badge type="badge-accent_2 m-r-10 m-b-10" text="Arreglos" closeBtnShow></Badge>
                                    <Badge type="badge-accent_2 m-r-10 m-b-10" text="Graduación" closeBtnShow></Badge>
                                </div>
                                <div class="col-12 col-md-5 m-b-30">
                                    <label class="f-14 c-title body-font-semibold f-600 d-block m-b-5">ID
                                        externo</label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-0">
                                        tickets-arreglos-graduacion</p>
                                    <p class="f-14 c-placeholder body-font-light f-italic d-block m-b-0">No ingresaste
                                        ID externo.</p>
                                </div>
                                <div class="col-12 col-md-7 m-b-30">
                                    <label class="f-14 c-title body-font-semibold f-600 d-block m-b-5">Nombre del
                                        evento</label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-0">Ingresa aquí el nombre
                                        de tu evento para identificar tu pedido</p>
                                    <p class="f-14 c-placeholder body-font-light f-italic d-block m-b-0">No pediste
                                        notas adicionales a tus clientes.</p>
                                </div>
                            </div>
                            <button type="" class="button xs button-stroke button-accent1 m-t-40 m-0-0-0-auto">Editar
                                sección
                            </button>
                        </div>
                        <div class="col-12 m-t-40">
                            <h1 class="f-20 c-title principal-font-bold d-block m-b-30">Conceptos</h1>
                            <ul class="m-0 p-0">
                                <li class="f-14 c-plain_text body-font-regular d-block m-b-20">- Arreglo de flores
                                    variadas en canasta personalizada.
                                </li>
                                <li class="f-14 c-plain_text body-font-regular d-block m-b-20">- Arreglo de rosas rojas
                                    sobre plato extendido.
                                </li>
                                <li class="f-14 c-plain_text body-font-regular d-block m-b-20">- Arreglo de flores
                                    blancas y amarillas en caja de madera.
                                </li>
                                <li class="f-14 c-plain_text body-font-regular d-block m-b-0">- Arreglo de tulipanes
                                    amarillos en base horizontal.
                                </li>
                            </ul>
                            <button type="" class="button xs button-stroke button-accent1 m-t-40 m-0-0-0-auto">Editar
                                sección
                            </button>
                        </div>
                        <div class="col-12 m-t-60 vertical-center">
                            <button type="button" class="button xs button-ghost button-plain_text">
                                <i aria-hidden="true" class="banana banana-chevron-left"></i> Regresar
                            </button>
                            <div class="vertical-center m-0-0-0-auto">
                                <button type="button" class="button xs button-ghost button-plain_text m-r-5">Cancelar
                                </button>
                                <button type="button" class="button xs button-filled button-accent1">Finalizar</button>
                            </div>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue'
    import CardSimple from '@/components/CardSimple.vue'
    import WizardForm from '@/components/WizardForm.vue'
    import BasicInput from '@/components/BasicInput.vue'
    import BasicTextarea from '@/components/BasicTextarea.vue'
    import BasicSelect from '@/components/BasicSelect.vue'
    import Multiselect from "vue-multiselect"
    import UploadArea from '@/components/UploadArea.vue'
    import ListUploadArea from '@/components/ListUploadArea.vue'
    import SimpleNote from '@/components/SimpleNote.vue'
    import SimpleCarousel from '@/components/SimpleCarousel.vue'
    import CardItem from '@/components/CardItem.vue'
    import BasicCheckbox from '@/components/BasicCheckbox.vue'
    import Badge from '@/components/Badge.vue'
    import ExpressPaymentConcepts from '@/views/services/ExpressPaymentConcepts.vue';

    export default {
        name: "QuickPayment",
        components: {
            FloatingTitleLayout,
            CardSimple,
            WizardForm,
            BasicInput,
            BasicTextarea,
            BasicSelect,
            Multiselect,
            UploadArea,
            ListUploadArea,
            SimpleNote,
            SimpleCarousel,
            CardItem,
            BasicCheckbox,
            Badge,
            ExpressPaymentConcepts
        },
        data() {
            return {
                valueEstatus: [],
                optionsEstatus: [
                    {name: "Activo"},
                    {name: "Inactivo"}
                ],
                valueQue: [],
                optionsQue: [
                    {name: "?"},
                    {name: "?"}
                ],
                valueTags: [],
                optionsTags: [
                    {name: "Flores"},
                    {name: "Arreglos"},
                    {name: "Graduación"}
                ]
            }
        }
    };
</script>
