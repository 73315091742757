    <template>
    <div class="checkbox" v-bind:class="clazz">
        <input
            v-bind:class="inputClass"
            :id="checkId" type="checkbox"
            v-model="checkboxValue"
            :true-value="true"
            :false-value="false"
            :value="checkboxValue"
            :disabled="disabled"
            @change="onCheckboxChange">
        <i aria-hidden="true" class="input-helper"></i>
        <span :class="textClass">{{text}}</span>
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'BasicCheckbox',
        data() {
            return {
                checkboxValue: false
            }
        },
        props: {
            checkId: String,
            text: String,
            clazz: String,
            textClass: String,
            index: {type: Number, default: null},
            value: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            selectAll: {type: Boolean, default: false},
            inputClass: String
        },
        methods: {
            onCheckboxChange() {
                if (this.index == null) {
                    this.$emit('change', this.checkboxValue)
                } else {
                    const index = this.index;
                    const checkValue = this.checkboxValue;
                    this.$emit('change', {index, checkValue})
                }
                this.$emit('input', this.checkboxValue);
            },
            checkStoreValue(value) {
                this.checkboxValue = value;
            },
        },
        mounted() {
            this.checkboxValue = this.value;
        }
    }
</script>
