<template>
    <CardSelection
        :title="fileDocument.title"
        :subtitle="fileDocument.fileSpecs"
        :clazz="cardClass"
    >
        <template v-slot:icon>
            <i
                aria-hidden="true"
                class="banana"
                :class="fileDocument.icon"
            ></i>
        </template>
            <template
                v-slot:extraMiddleContent
                v-if="!error"
            >
                <b-progress
                    :max="maxPercent"
                    class="bar-progress percent-color"
                    :class="{'success' : percent === 100 }"
                >
                    <b-progress-bar :value="percent">
                        <div class="label-progress">
                            <span>{{ percent }}%</span>
                        </div>
                    </b-progress-bar>
                </b-progress>
            </template>
            <template
                v-slot:extraMiddleContent
                v-else
            >
                <p
                    class="f-12 c-error poppins-font-semibold d-block m-0"
                    v-text="errorText"
                ></p>
            </template>
            <template
                v-slot:extraFinalContent
                v-if="!success"
            >
                <button
                    type="button"
                    class="button xs button-filled button-primary p-relative"
                    @click="selectFile"
                    :disabled="uploading"
                    v-text="buttonLabel"
                ></button>
                <input
                    hidden
                    ref="fileInput"
                    type="file"
                    :name="fileDocument.title"
                    :id="fileDocument.title"
                    @change="change"
                    :accept="allowedFormats"
                />
            </template>
            <template
                v-slot:extraFinalContent
                v-else
            >
                <i aria-hidden="true" class="banana banana-check-normal f-30 c-success"></i>
            </template>
        </CardSelection>
</template>
<script>
import CardSelection from "@/components/CardSelection.vue";

const pending = 'border-card icon-bgm-info_text';
const uploading = 'border-card icon-bgm-accent2';
const error = 'border-card icon-bgm-error';
const success = 'border-card icon-bgm-success';

export default{
    name: 'FileDocumentUpload',
    components: {
        CardSelection,
    },
    props: {
        fileDocument: {
            type: Object,
            required: true
        },
        fixedAsSuccess: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            percent: 0,
            maxPercent: 100,
            uploading: false,
            success: false,
            error: false,
            errorTextCustom: null,
        };
    },
    computed: {
        cardClass() {
            if (this.uploading) {
                return uploading;
            } else if(this.success) {
                return success;
            } else if (this.error) {
                return error;
            }
            return pending;
        },
        buttonLabel() {
            if (this.loading) {
                return 'Subiendo...';
            } else if (this.error) {
                return 'Reintentar';
            }
            return 'Subir';
        },
        errorText() {
            if (this.errorTextCustom) {
                return this.errorTextCustom;
            }
            return 'Hubo un problema al subir el documento';
        },
        allowedFormats() {
            if (this.fileDocument?.allowedFormats?.length > 0) {
                return this.fileDocument?.allowedFormats?.reduce((acc, format) => `${acc}, .${format}`, '').substring(2);
            }
            return '*';
        }
    },
    methods: {
        selectFile() {
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.click();
        },
        change(event) {
            const file = event.target ? event.target.files[0] : event;
            this.$emit('change', file)
        },
        setUploading() {
            this.percent = 0;
            this.error = false;
            this.success = false;
            this.uploading = true;
        },
        setSuccess() {
            this.percent = 100;
            this.error = false;
            this.success = true;
            this.uploading = false;
        },
        setError() {
            this.percent = 0;
            this.error = true;
            this.success = false;
            this.uploading = false;
        }
    },
    beforeMount() {
        if (this.fixedAsSuccess) {
            this.setSuccess();
        }
    }
}
</script>
