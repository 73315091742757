<template>
    <div class="radio-input" :class="clazz">
        <input :value="value" type="radio" :name="id" v-bind:id="id" aria-label="radio" @click="selected" :checked="checked" :disabled="disabled" />
        <span class="role" :class="spanClazz" v-bind:for="labelFor">{{text}}</span>
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'RadioInput',
  props: {
    id: String,
    labelFor: String,
    text: String,
    value: [String, Number, Boolean, Object],
    checked: Boolean,
    disabled: {type: Boolean, default: false},
    spanClazz: String,
    clazz: String
  },
  methods: {
    selected(event){
      this.$emit('selected', event.target.value)
    }
  }

}
</script>
