<template>
    <div class="col-12 p-0">
        <WizardForm clazz="form-wizard-white"
                    :hideFooter="true">
            <template v-slot:wizardView>
                <tab-content
                    :title="$t('api-configuration.woocommerce.instructions.step-one.title')"
                    icon="one"
                >
                    <CardSimple :title="$t('api-configuration.woocommerce.instructions.step-one.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.woocommerce.instructions.step-one.instruction')"></p>
                                <img alt="" class="img-fluid d-flex m-0-auto" :src="woocommerceInstalation"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                    type="button"
                                    class="button xs button-filled button-accent1 m-0-0-0-auto"
                                    @click="nextStep(1)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>

                <tab-content
                    :title="$t('api-configuration.woocommerce.instructions.step-two.title')"
                    icon="two"
                >
                    <CardSimple :title="$t('api-configuration.woocommerce.instructions.step-two.subtitle')" clazz="b-shadow-ntwo p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.woocommerce.instructions.step-two.instruction')"></p>
                                <img alt="" class="img-fluid d-flex m-0-auto" :src="activateGateway"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                    type="button"
                                    class="button xs button-filled button-accent1 m-0-0-0-auto"
                                    @click="nextStep(2)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>

                <tab-content
                        :title="$t('api-configuration.woocommerce.instructions.step-three.title')"
                        icon="three"
                >
                    <CardSimple :title="$t('api-configuration.woocommerce.instructions.step-three.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.woocommerce.instructions.step-three.instruction')"></p>
                                <img alt="" class="img-fluid d-flex m-0-auto" :src="catalogoModulos"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                        type="button"
                                        class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                        @click="previousStep(3)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>

                                <button
                                        type="button"
                                        class="button xs button-filled button-accent1 m-0-0-0-auto"
                                        @click="nextStep(3)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.woocommerce.instructions.step-four.title')"
                        icon="four"
                >
                    <CardSimple :title="$t('api-configuration.woocommerce.instructions.step-four.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.woocommerce.instructions.step-four.instruction')">
                                </p>

                                <img alt="" class="img-fluid d-flex m-0-auto" :src="moduloInstalado"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                        type="button"
                                        class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                        @click="previousStep(4)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>

                                <button
                                        type="button"
                                        class="button xs button-filled button-accent1 m-0-0-0-auto"
                                        @click="nextStep(4)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                    :title="$t('api-configuration.woocommerce.instructions.step-five.title')"
                    icon="five"
                >
                    <CardSimple :title="$t('api-configuration.woocommerce.instructions.step-five.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.woocommerce.instructions.step-five.instruction')">
                                </p>

                                <img alt="" class="img-fluid d-flex m-0-auto" :src="configuracionModulo"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                    type="button"
                                    class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                    @click="previousStep(5)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>

                                <button
                                    type="button"
                                    class="button xs button-filled button-accent1 m-0-0-0-auto"
                                    @click="nextStep(5)"
                                > {{ $t('api-configuration.api-test-verification.next-test') }}
                                </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.woocommerce.instructions.step-six.title')"
                        icon="six"
                >
                    <CardSimple :title="$t('api-configuration.woocommerce.instructions.step-six.subtitle')" clazz="b-shadow-none p-0 m-b-0">
                        <div class="row">
                            <div class="col-12">
                                <p class="f-14 c-plain_text body-font-regular d-block m-b-40" v-html="$t('api-configuration.woocommerce.instructions.step-six.instruction')">
                                </p>

                                <img alt="" class="img-fluid d-flex m-0-auto" :src="pago"  width="90%" height="90%"/>
                            </div>

                            <div class="col-12 vertical-center m-t-40">
                                <button
                                        type="button"
                                        class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                        @click="previousStep(5)"
                                > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>
                            </div>
                        </div>
                    </CardSimple>
                </tab-content>
            </template>
        </WizardForm>
    </div>
</template>

<script>
    import WizardForm from '@/components/WizardForm.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import catalogoModulos from '@/assets/images/woocommerce/catalogo-modulos.png';
    import configuracionModulo from '@/assets/images/woocommerce/configuracion-modulo.png';
    import moduloInstalado from '@/assets/images/woocommerce/modulo-instalado.png';
    import woocommerceInstalation from '@/assets/images/woocommerce/woocommerce-install.png';
    import activateGateway from '@/assets/images/woocommerce/woocommerce-settings.png';
    import pago from '@/assets/images/woocommerce/payment-method-select.png';
    import {EventBus} from "@/main";

    export default {
        mixins: [],
        name: "WoocommerceInstructions",
        data() {
            return {
                configuracionModulo: configuracionModulo,
                moduloInstalado: moduloInstalado,
                catalogoModulos,
                pago,
                woocommerceInstalation,
                activateGateway,
            }
        },
        components: {
            WizardForm,
            CardSimple
        },
        computed: {},
        methods: {
            nextStep(currentStep) {
                EventBus.$emit(`wizard#goTo`, {from: currentStep - 1, to: currentStep});
            },
            previousStep(currentStep){
                EventBus.$emit(`wizard#goTo`, { from: currentStep - 1, to: currentStep - 2});
            },
            goToApi(){
                this.$router.replace("/client-admin/apiWeb/api-checkoutbutton/PRESTASHOP");
            }
        }
    }
</script>
