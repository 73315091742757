import {
    mapGetters
} from 'vuex';
let timeout = null;
export default {
    configure: ({storeModule, docName, manualLoad}) => {
        return {
            mixins: [],
            data: function () {
                return {
                    docName: docName,
                    manualLoad: manualLoad !== undefined ? manualLoad : false
                }
            },
            components: {

            },
            computed: {
                currentUser(){
                    return this.$store.state.firebase.currentUser
                },
                currentProfile(){
                    return this.$session.get('profile');
                },
                ...mapGetters(storeModule,[
                    'getDocs',
                    'getDocsLength',
                    'getPaginatorPages',
                    'getSelectedItem',
                    'getSearchText',
                    'getLoading',
                    'getCurrentPage',
                    'getIsErrorLoading'
                ])
            },
            methods: {
                pageChange(page){
                    this.$store.commit(`${storeModule}/SET_LOADING`, true);
                    this.$store.commit(`${storeModule}/SET_CURRENT_PAGE`, page);
                    this.$store.dispatch(`${storeModule}/list`);
                },
                getListOfDocuments() {
                    this.$store.dispatch(`${storeModule}/list`);
                },
                setSearch(value){
                    clearTimeout(timeout);
                    const self = this;
                    timeout = setTimeout(function () {
                        self.$store.commit(`${storeModule}/SET_SEARCH`,value);
                        self.getListOfDocuments();
                    }, 300);
                },
                updateDocument(emit){
                    this.$store.dispatch(`${storeModule}/save`,emit);
                },
                // deleteDocument(){
                //     this.$store.dispatch(`${storeModule}/delete`);
                // },
                hideModal(name){
                    this.$refs[name].hide();
                },
                openModal(name){
                    this.$refs[name].show();
                },

            },
            mounted(){
                if(!this.manualLoad){
                    this.getListOfDocuments();
                }

            }
        }
    }
}
