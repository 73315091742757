<template lang="html">

  <CardSimple
    :title="$t('pagos.resumen.title')"
    :subtitle="$t('pagos.resumen.subtitle')"
  >
    <div class="title-card">
        <h1 class="c-title f-20 principal-font-bold m-b-0">
            {{ $t('pagos.resumen.datos-generales.title') }}
        </h1>
    </div>
    <div class="row">
      <div class="col-md-5 m-b-30">
        <b-carousel
          id="OneItemCarousel"
          fade
          indicators
          controls
          :interval="10000"
        >
          <b-carousel-slide v-for="img in ((payment || {}).images || [])" v-bind:key="img" :img-src="imageUrl(img)"></b-carousel-slide>
        </b-carousel>
      </div>
      <div class="col-md-7 m-b-30">
        <Badge :type="badgeClass()" :text="badgeText()"></Badge>
        <h2 class="c-title f-20 body-font-regular m-b-10">{{payment.name}}</h2>
        <div class="d-flex align-items-c justify-content-between m-b-15">
          <h3 class="c-info_text f-14 body-font-regular f-bold m-0">
            {{ $t(`organizaciones.category.options.${payment.clasification}`) }}
          </h3>
        </div>
        <p class="c-plain_text f-14 body-font-regular m-0">{{payment.description}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 m-b-30">
        <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.datos-generales.divisa.title') }}</h4>
        <p class="c-plain_text f-14 body-font-regular m-0">
          {{ currencyObject().description }} ({{ currencyObject().code }})
        </p>
      </div>
      <div class="col-md-6 m-b-30">
        <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.datos-generales.tags') }}</h4>
        <div>
          <Badge v-for="t in payment.tags" :key="t"
            type="badge-accent_2 m-r-5 m-b-5"
            :text="t"
          ></Badge>
        </div>
      </div>

      <div class="col-md-6 m-b-30">
        <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.datos-generales.id.title') }}</h4>
        <p class="c-placeholder f-italic f-14 body-font-regular m-0" v-if="!payment.externalIdentifier">
          {{ $t('pagos.resumen.datos-generales.id.error') }}
        </p>
        <p class=" f-14 body-font-regular m-0" v-if="payment.externalIdentifier">
          {{ payment.externalIdentifier }}
        </p>
      </div>

      <div class="col-md-6 m-b-30" v-if="showAdditionalNotesConfig()">
        <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">
            {{ payment.additionalNotesConfig.isEnabled ? payment.additionalNotesConfig.label : $t('pagos.resumen.datos-generales.color') }}
            <span class="c-error" v-if="payment.additionalNotesConfig.isRequired">*</span>
        </h4>
        <p class="c-plain_text f-14 body-font-regular m-0">
          {{ payment.additionalNotesConfig.isEnabled ? payment.additionalNotesConfig.placeholder : $t('pagos.resumen.datos-generales.no-notes') }}
        </p>
      </div>

      <div class="col-md-6 m-b-30 d-flex align-items-c" v-if="showAcceptsMultipleUnits()">
        <h4 class="c-plain_text f-14 f-bold body-font-regular m-0" v-if="payment.acceptsMultipleUnits">
            {{ $t('pagos.resumen.datos-generales.multiply.title') }}
        </h4>
        <h4 class="c-plain_text f-14 f-bold body-font-regular m-0" v-if="!payment.acceptsMultipleUnits">
            {{ $t('pagos.resumen.datos-generales.multiply.no') }}
        </h4>
      </div>
      <div class="col-md-6 m-b-30" v-if="showAcceptsMultipleUnits()">
        <div class="d-flex align-items-c">
          <div class="body-font-bold f-bold f-36 c-primary_1 m-r-20">
            {{payment.maxUnitsPerTransaction || 1}}
          </div>
          <p class="c-plain_text f-14 body-font-regular m-0">
            {{ $t('pagos.resumen.datos-generales.multiply.max') }}
          </p>
        </div>
      </div>
    </div>

    <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToGeneral">
      {{ $t('pagos.resumen.general.edit') }}
    </button>

    <template v-if="payment.fieldsInfo && payment.fieldsInfo.length > 0">
        <div class="title-card">
            <h1 class="c-title f-20 principal-font-bold">
                {{$t('pagos.resumen.campos-informativos.title')}}
            </h1>
        </div>
        <p v-for="field in payment.fieldsInfo" :key="field.description" class="c-plain_text f-14 body-font-regular m-b-20">
        - {{ field.description }}
        </p>
        <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToFieldsInfo">
        {{ $t('pagos.resumen.general.edit') }}
        </button>
    </template>

    <template v-if="(payment.fields && payment.fields.length > 0) || (payment.references && payment.references.length > 0)">
        <div class="row">
            <div class="col-md-6 m-b-30" v-if="payment.fields && payment.fields.length > 0">
                <div class="title-card">
                    <h1 class="c-title f-20 principal-font-bold">
                        {{$t('pagos.requisitos.campos-referencias.inputs.fields.title')}}
                    </h1>
                </div>
                <p v-for="field in payment.fields" :key="field.description" class="c-plain_text f-14 body-font-regular m-b-20">
                - {{ field.name }}
                </p>
            </div>
            <div class="col-md-6 m-b-30" v-if="payment.references && payment.references.length > 0">
                <div class="title-card">
                    <h1 class="c-title f-20 principal-font-bold">
                        {{$t('pagos.requisitos.campos-referencias.inputs.references.title')}}
                    </h1>
                </div>
                <p v-for="field in payment.references" :key="field.description" class="c-plain_text f-14 body-font-regular m-b-20">
                - {{ field.name }}
                </p>
            </div>
        </div>
        <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToFields">
        {{ $t('pagos.resumen.general.edit') }}
        </button>
    </template>
    <template v-else-if="payment.type === 'D'">
        <div class="row">
            <div class="col-md-12 m-b-30">
                <div class="title-card">
                    <h1 class="c-info_text f-20">
                        {{$t('pagos.requisitos.campos-referencias.inputs.fields-references.title')}}
                    </h1>
                </div>
                <b-alert
                    class="alert-note"
                    show
                    variant="info"
                >
                    <p class="text-container">
                        <img
                            class="img-fluid icon"
                            src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                            alt="info"
                        />
                        {{$t('pagos.requisitos.campos-referencias.inputs.fields-references.empty')}}
                    </p>
                </b-alert>
            </div>
        </div>
        <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToFields">
        {{ $t('pagos.resumen.general.edit') }}
        </button>
    </template>

    <template v-if="showConceptsAsList()">
        <div class="title-card">
            <h1 class="c-title f-20 principal-font-bold m-b-0">
                {{$t('pagos.resumen.conceptos.title')}}
            </h1>
        </div>
        <p v-for="field in payment.concepts" :key="field.name" class="c-plain_text f-14 body-font-regular m-b-20">
        - {{ field.name }}
        </p>
        <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToConcepts">
        {{ $t('pagos.resumen.general.edit') }}
        </button>
    </template>

    <template v-if="showConceptsAsTable()">
        <div class="title-card">
            <h1 class="c-title f-20 principal-font-bold m-b-0">
                {{$t('pagos.resumen.conceptos.title')}}
            </h1>
        </div>
        <div class="basic-table">
        <b-table
            responsive
            hover
            :fields="conceptsFields"
            :items="conceptsFormatted()"
        ></b-table>
        </div>
        <div class="total-container">
        <div class="total-text body-font-medium">{{$t('pagos.resumen.general.subtotal')}}</div>
        <div class="total-number body-font-medium">{{ conceptsTotal() | currency }} {{currencyObject().code}}</div>
        </div>
        <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToConcepts">
            {{ $t('pagos.resumen.general.edit') }}
        </button>
    </template>

    <template v-if="showDiscounts()">
        <div class="title-card">
            <h1 class="c-title f-20 principal-font-bold m-b-0">
                {{$t('pagos.resumen.descuentos.title')}}
            </h1>
        </div>
        <div class="basic-table" v-if="payment.discounts && payment.discounts.length > 0">
            <b-table
                responsive
                hover
                :fields="discountsFields"
                :items="discountsFormatted()"
            >
                <template v-slot:estatus>
                <Badge
                    type="badge-accent_2"
                    text="Activo"
                ></Badge>
                </template>
            </b-table>
        </div>
        <b-alert
            v-else
            class="alert-note"
            show
            variant="info"
        >
            <p class="text-container">
                <img
                    class="img-fluid icon"
                    src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                    alt="info"
                />
                {{$t('pagos.general.servicio.discounts.no-discounts')}}
            </p>
        </b-alert>
        <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToDiscounts">
            {{ $t('pagos.resumen.general.edit') }}
        </button>
    </template>

    <template v-if="showTotals()">
        <h1 class="c-title f-20 principal-font-bold m-b-30">
            {{ $t('pagos.resumen.total.title') }}
        </h1>
        <div class="total-container m-b-0">
            <div class="total-text body-font-medium">{{$t('pagos.resumen.general.subtotal')}} </div>
            <div class="total-number body-font-medium">{{ conceptsTotal() | currency }} {{currencyObject().code}}</div>
        </div>
        <div class="basic-table">
            <b-table
                responsive
                hover
                :fields="taxesFields"
                :items="taxesFormatted()"
            >
            </b-table>
        </div>
        <div class="total-container">
            <div class="total-text body-font-medium">{{$t('pagos.resumen.general.total')}}</div>
            <div class="total-number body-font-medium">{{ total() | currency }} {{currencyObject().code}}</div>
        </div>
    </template>

    <template v-if="showWebConfig()">
        <div class="title-card">
            <h1 class="c-title f-20 principal-font-bold m-b-0">
                {{$t('pagos.resumen.configuracion-web.title')}}
            </h1>
        </div>
        <div class="row">
            <div class="col-md-6 m-b-30">
                <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.configuracion-web.method.title') }}</h4>
                <p class=" f-14 body-font-regular m-0">
                {{ payment.method }}
                </p>
            </div>
            <div class="col-md-6 m-b-30">
                <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.configuracion-web.test-result') }}</h4>
                <p class=" f-14 body-font-regular m-0">
                    <span v-if="!payment.testedService" class="badge badge-warning"> Pendiente </span>
                    <span v-else class="badge badge-success"> Aprobada </span>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 m-b-30">
                <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.configuracion-web.url') }}</h4>
                <p class=" f-14 body-font-regular m-0">
                {{ payment.url }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 m-b-30">
                <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.configuracion-web.params-list') }}</h4>
                <table class="table table-borderless" aria-hidden="true">
                    <caption>Parámetros de pago</caption>
                    <tbody>
                        <tr v-for="param in payment.params" :key="param.name" class=" f-14 body-font-regular m-0">
                            <td>- {{param.name}}</td>
                            <td>{{param.description}}</td>
                            <td v-if="param.auto">{{`${param.name}=\{\{${param.name}\}\}`}}</td>
                            <td v-else>{{`\{\{${param.name}\}\}`}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 m-b-30" v-if="payment.method == 'POST'">
                <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.configuracion-web.content-type') }}</h4>
                <p class=" f-14 body-font-regular m-0">
                {{ payment.content_type}}
                </p>
            </div>
        </div>
        <div class="row"  v-if="payment.method == 'POST'">
            <div class="col-md-6 m-b-30">
                <h4 class="m-b-10 c-plain_text f-14 f-bold body-font-regular m-0">{{ $t('pagos.resumen.configuracion-web.body') }}</h4>
                <p class=" f-14 body-font-regular m-0">
                {{ payment.body }}
                </p>
            </div>
        </div>
       <button class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToWebConfig">
            {{ $t('pagos.resumen.general.edit') }}
        </button>
    </template>

    <div class="row m-t-40">
      <div class="col-12 vertical-center justify-content-end">
        <button class="button button-ghost sm button-plain_text m-0-auto-0-0" @click="prev">
          <i aria-hidden="true" class="banana banana-chevron-left"></i>
          {{$t('general.actions.back.title')}}
        </button>

        <div class="vertical-center" v-if="!editMode">
          <button class="button button-ghost sm button-plain_text" @click="cancel">
            {{$t('general.actions.cancel.title')}}
          </button>
          <button class="button button-filled sm button-accent1 m-l-10" @click="finish">
            {{$t('general.actions.save')}}
          </button>
        </div>
      </div>
    </div>
  </CardSimple>

</template>

<script lang="js">
  import Badge from "@/components/Badge";
  import CardSimple from '@/components/CardSimple.vue';
  import moment from 'moment';
  import {utils} from '@/api/utils.js';
  import utils2 from '@/common/utils';
  import OrganizationApi from '@/api/organization/organizations.api';
  import {mapGetters} from 'vuex';
  import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
  const TEXT_ALIGN_C_CLASS = "text-align-c"

  const PAYMENTS_DISCOUNT_AVAILABLE_KEY = 'pagos.descuentos.table.active';

  export default {
    name: 'summary',
    props: {
        payment: {},
        editMode: {type: Boolean, default: false}
    },
    components: {
      Badge,
      CardSimple
    },
    mounted () {
        OrganizationApi.getCommision(this.currentOrganization._id).then((data) => {
            this.commision = data.data.object;
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.message) {
              this.displayNotificationError(this.$t('general.actions.error.title'), error.response.data.message);
            } else {
              this.displayNotificationError(this.$t('general.actions.error.title'), this.$t('general.actions.error.text'));
            }
        });
    },
    data () {
        return {
            commision: {},
            conceptsFields: [
                {
                    key: "name",
                    label: this.$t('pagos.resumen.conceptos.table.name')
                },
                {
                    key: "quantity",
                    label: this.$t('pagos.resumen.conceptos.table.quantity'),
                    class: TEXT_ALIGN_C_CLASS
                },
                {
                    key: "unitPrice",
                    label: this.$t('pagos.resumen.conceptos.table.precio'),
                    class: "text-align-r"
                },
                {
                    key: "total",
                    label: this.$t('pagos.resumen.general.subtotal'),
                    class: "text-align-r f-bold w-150px"
                }
            ],
            discountsFields: [
                {
                    key: "description",
                    label: this.$t('pagos.resumen.descuentos.table.name')
                },
                {
                    key: "from",
                    label: this.$t('pagos.resumen.descuentos.table.inicio'),
                    class: TEXT_ALIGN_C_CLASS
                },
                {
                    key: "to",
                    label: this.$t('pagos.resumen.descuentos.table.fin'),
                    class: TEXT_ALIGN_C_CLASS
                },
                {
                    key: "status",
                    label: this.$t('pagos.resumen.descuentos.table.estatus'),
                    class: "text-align-c w-85px"
                },
                {
                    key: "discount",
                    label: this.$t('pagos.resumen.descuentos.table.descuento'),
                    class: "text-align-r f-bold c-success w-150px"
                }
            ],
            taxesFields: [
                {
                    key: "name",
                    label: "",
                    class: "text-align-r"
                },
                {
                    key: "amount",
                    label: "",
                    class: "text-align-r f-bold  w-150px"
                }
            ],
        }
    },
    methods: {
        goToGeneral() {
            this.$emit('goTo', 0);
        },
        goToFieldsInfo() {
            this.$emit('goTo', 1);
        },
        goToFields() {
            this.$emit('goTo', 2);
        },
        goToConcepts() {
            switch (this.payment.type) {
                case 'EP':
                    this.$emit('goTo', 1);
                    break;
                case 'P':
                default:
                    this.$emit('goTo', 2);
                    break;
            }
        },
        goToDiscounts() {
            this.$emit('goTo', 3);
        },
        goToWebConfig() {
            this.$emit('goTo', 1);
        },
        badgeClass() {
            if (this.payment.activeService) {
                return 'badge-accent_2';
            }
            return 'badge-accent_1';
        },
        badgeText() {
            if (this.payment.activeService) {
                return this.$t('pagos.general.servicio.status.active');
            }
            if (this.payment.comingSoon) {
                return this.$t('pagos.general.servicio.status.comingSoon');
            }
            return this.$t('pagos.general.servicio.status.inactive');
        },
        // Converts a number or a number string to a money formatted string.
        numberToMoneyString(number) {
            if(isNaN(number))
            {
                return '0.00';
            }
            if(typeof number === 'string')
            {
                number = Number.parseFloat(number);
            }
            return `$ ${ number.toLocaleString(
                'en-IN',
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            ) } ${ this.currencyObject().code}`;
        },
         imageUrl(img) {
            return utils.imageDownloadLink(img);
        },
        discountStatus(discount) {
            const now = new Date();

            if (discount.from && typeof discount.from === 'string') {
                discount.from = new Date(discount.from);
            }
            if (discount.to && typeof discount.to === 'string') {
                discount.to = new Date(discount.to);
            }

            if (discount.from && discount.from.getTime() < now.getTime() ) {
                if (discount.to && discount.to.getTime() < now.getTime()) {
                    return {type: 'badge-error m-l-20', text: this.$t('pagos.descuentos.table.expired')};
                }
                return {type: 'badge-info m-l-20', text: this.$t(PAYMENTS_DISCOUNT_AVAILABLE_KEY)};
            }
            return {type: 'badge-disable m-l-20', text: this.$t('pagos.descuentos.table.inactive')};
        },
        discountAmount(discount) {
            if (discount.isPercentage) {
                const factor = utils2.jsNumFix(discount.discount / 100);
                return utils2.jsNumFix(this.conceptsTotal() * factor);
            }
            return discount.discount;
        },
        cancel() {
            this.$emit('cancel');
        },
        prev() {
            this.$emit('prev');
        },
        finish() {
            this.payment.subTotal = this.conceptsTotal();
            this.payment.totalTaxes = 0; // TODO validate
            this.payment.totalDiscounts = this.discountsTotal();
            this.payment.total = this.total();
            this.payment.totalWithoutCommision = utils2.jsNumFix(this.total() - (this.commision.price || 0));
            this.$emit('finish');
        },
        currencyObject() {
            return (this.payment.currency && this.payment.currency._id ? this.payment.currency : null) || this.payment.currencyObject || {};
        },
        summaryTotal() {
            let newTotal = this.conceptsTotal().number;

            const percentageDiscounts = this.$_.filter(this.discounts, (discount) => { return discount.type.name === 'Porcentaje'; });
            const amountDiscounts = this.$_.filter(this.discounts, (discount) => { return discount.type.name === 'Cantidad'; });

            percentageDiscounts.forEach(discount => {
                newTotal *= (1 - (discount.amount/100));
            });

            amountDiscounts.forEach(discount => {
                newTotal -= discount.amount;
            });

            return newTotal;
        },
        // Lists discounts and taxes (added values) in a valid format.
        taxesFormatted() {
            const _this = this;
            const concepts = [];
            const activeDiscounts = this.payment.discounts.filter(d => this.discountStatus(d).text === this.$t(PAYMENTS_DISCOUNT_AVAILABLE_KEY));
            activeDiscounts.forEach(d => concepts.push({
                name: d.description,
                amount: `- ${_this.numberToMoneyString(_this.discountAmount(d))}`,
                amountNumber: (-1 * this.discountAmount(d))
            }));

            if (this.commision && this.commision.price) {
                concepts.push({name: this.$t('pagos.general.commision'), amount: `${this.numberToMoneyString(this.commision.price)}`, amountNumber: this.commision.price});
            }

            return concepts;
        },
        discountsFormatted() {
            return this.$_.map(this.payment.discounts, (oldDiscount) => {
                const discount = JSON.parse(JSON.stringify(oldDiscount));
                discount.from = new moment(discount.from).format('DD/MM/YYYY');
                if(discount.to){
                    discount.to = new moment(discount.to).format('DD/MM/YYYY');
                }
                discount.status = this.discountStatus(oldDiscount).text;
                if (discount.isPercentage) {
                    discount.discount = `- ${discount.discount}%`;
                } else {
                    discount.discount = `- ${ this.numberToMoneyString(discount.discount)}`;
                }
                return discount;
            });
        },
      // Adds a subtotal field and returns a printable json.
        conceptsFormatted() {
            return this.$_.map(this.payment.items, (concept) => {
                const temp = JSON.parse(JSON.stringify(concept));
                temp.unitPrice = `$${parseFloat(temp.unitPrice).toFixed(2)} ${this.currencyObject().code}`;
                temp.total = `$${parseFloat(temp.total).toFixed(2)} ${this.currencyObject().code}`;
                return temp;
            });
      },
        conceptsTotal() {
            let total = 0;
            this.payment.items.forEach(function(i) {total += i.total});
            return total
        },
        discountsTotal() {
            const _this = this;
            let total = 0;
            const activeDiscounts = this.payment.discounts.filter(d => _this.discountStatus(d).text === _this.$t(PAYMENTS_DISCOUNT_AVAILABLE_KEY));
            activeDiscounts.forEach(function (d) {
                total += _this.discountAmount(d)
            });
            return total;
        },
        total() {
            let total = 0;
            this.payment.items.forEach(function (i) {
                total += i.total
            });
            this.taxesFormatted().forEach(function (t) {
                total += t.amountNumber
            });
            return total
        },
        showAdditionalNotesConfig() {
            return this.payment.type === 'P' || this.payment.type === 'EP';
        },
        showAcceptsMultipleUnits() {
            return this.payment.type === 'P';
        },
        showConceptsAsTable() {
            return this.payment.type === 'P';
        },
        showConceptsAsList() {
            return this.payment.type === 'EP';
        },
        showTotals() {
            return this.payment.type === 'P';
        },
        showDiscounts() {
            return this.payment.type === 'P' || this.payment.type === 'D';
        },
        showWebConfig(){
            return this.payment.type === 'WS';
        }
    },
    computed: {
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        })
    }
}
</script>
