<template>
    <div id="dctSearchFilterBar" class="search-filter-bar" v-bind:class="clazz">
        <div class="search-container">
            <input id="txtSearchTable" class="" @input="searchTextChange" type="text" name="" v-model="searchText"
                   value="" :placeholder="$t('general.search-bar.search-text-placeholder')"/>
            <i aria-hidden="true" class="banana banana-search-normal" :class="[searchText.length > 0 ? 'hide': '']"></i>
            <i aria-hidden="true" class="banana banana-cross-normal" :class="[searchText.length > 0 ? 'show': '']"
               v-on:click="resetInput"></i>
        </div>

        <div class="filter-container">
            <b-dropdown id="dropdown-filter" right class="dropdown-filter" :class="clazzFilter" v-if="showDropdown">
                <template v-slot:button-content>
                    {{$t('general.search-bar.filters')}} <i aria-hidden="true" class="banana banana-triangle-down"></i>
                </template>
                <b-dropdown-group :header="$t('general.search-bar.drop-down-header')">
                    <b-dropdown-form>
                        <b-form-group v-for="(column, columnIndex) in columValues" v-bind:key="column.name">
                            <BasicCheckbox
                                    :checkId="`chk-filter-${columnIndex}`"
                                    :index="columnIndex"
                                    clazz="w-fit m-b-0"
                                    :ref="'CHECKBOX_COLUMN_' + columnIndex"
                                    :currentValue="column.value"
                                    v-model="column.value"
                                    @change="changed"
                            ></BasicCheckbox>
                            {{column.label}}
                        </b-form-group>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-form-group>
                            {{$t('general.search-bar.show')}}
                            <InputEdit inputId="txt-numOfRows" v-on:input="changeListLimit"
                                       clazz="m-l-10 m-r-10 text-align-c" inputType="number" inputPlaceholder="0"
                                       v-model="perPageValue"/>
                            {{$t('general.search-bar.registers')}}
                        </b-form-group>

                    </b-dropdown-form>
                </b-dropdown-group>
            </b-dropdown>

            <InputDatepicker v-if="showDateRange" clazz="m-b-0 noLabel m-l-10" :class="clazzAdd">
                <date-range-picker
                        ref="picker"
                        :opens="opens"
                        :showDropdowns="showDropdowns"
                        :autoApply="autoApply"
                        v-model="dateRange"
                        @update="changeDateRange"
                        class="form-daterangepicker">
                    <!-- eslint-disable -->
                    <template v-slot:input="picker">
                        <span class="placeholder" v-if="Object.keys(dateRange).length === 0">Filtrar por fecha</span>
                        <!-- This must be hidden when date selected -->
                        <span v-else>{{getDateRangeToString()}}</span> <!-- Show this when date selected -->
                    </template>
                    <!-- eslint-enable -->
                </date-range-picker>
                <i aria-hidden="true" class="banana banana-calendar icon-rangepicker"></i>
            </InputDatepicker>

            <button class="button sm button-filled button-accent1 m-l-10" v-if="noOptions && !loading && showButton" @click="clickEventDropDown">
                {{buttonText}}
            </button>
            <button class="button sm button-filled button-accent1 m-l-10" v-if="loading">
                <LoadingSimple clazz="small-load no-text" />
            </button>

            <b-dropdown id="dropdown-add" right class="dropdown-image-list m-l-10" v-if="canCreate" :class="clazzAdd">
                <template v-slot:button-content>
                    <button class="button sm button-filled button-accent1" v-if="!noOptions"><i
                            aria-hidden="true" class="banana banana-symbol-plus"></i> {{buttonText}}
                    </button>
                </template>
                <b-dropdown-group :header="buttonDropDownTitle" v-if="!noOptions">
                    <b-dropdown-item-button v-for="option in buttonDropDownOptions" v-bind:key="option.name"
                                            @click="clickEventDropDown(option.to)">
                        <div class="options-list">
                            <img class="img-fluid" :src="option.image" alt=""/>
                            <span>
                            <label>{{option.label}}</label>
                            <p>{{option.description}}</p>
                        </span>
                        </div>
                    </b-dropdown-item-button>
                </b-dropdown-group>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
    import InputEdit from '@/components/InputEdit.vue'
    import BasicCheckbox from '@/components/BasicCheckbox.vue'
    import InputDatepicker from '@/components/InputDatepicker.vue'
    import LoadingSimple from '@/components/LoadingSimple.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import utils from '@/common/utils';
    import ExpressPaymentIcon from '@/assets/images/Illustrations/illustration-pago-rapido.svg';
    import RegularPaymentIcon from '@/assets/images/Illustrations/illustration-pago-regular.svg';
    import DebtPaymentIcon from '@/assets/images/Illustrations/illustration-pago-requisitos.svg';

    export default {
        name: 'SearchBar',
        components: {
            InputEdit,
            BasicCheckbox,
            InputDatepicker,
            DateRangePicker,
            LoadingSimple
        },
        props: {
            clazz: String,
            clazzFilter: String,
            clazzDate: String,
            clazzAdd: String,
            loading: Boolean,
            showButton: {type: Boolean, default: true},
            columValues: {
                type: Array,
                default: () => {
                    return [
                        {
                            "label": "Nombre del servicio",
                            "name": "service_name",
                            "value": true
                        },
                        {
                            "label": "Tipo del servicio",
                            "name": "service_type",
                            "value": true
                        },
                        {
                            "label": "Tipo de pago",
                            "name": "payment_type",
                            "value": true
                        },
                        {
                            "label": "Detalles",
                            "name": "details",
                            "value": true
                        },
                        {
                            "label": "Estatus",
                            "name": "status",
                            "value": true
                        },
                        {
                            "label": "Acciones",
                            "name": "actions",
                            "value": true
                        },
                    ]
                }
            },
            buttonDropDownOptions: {
                type: Array,
                default: () => {
                    return [
                        {
                            "to": "/client-admin/payments/new/EP",
                            "label": "Pago rapido",
                            "description": "Especifica los conceptos, el cliente selecciona e ingresa la cantidad a pagar.",
                            "image": ExpressPaymentIcon,
                            "name": "pago_rapido"
                        },
                        {
                            "to": "/client-admin/payments/new/P",
                            "label": "Pago regular",
                            "description": "Pago ideal para vender productos y servicios con un costo fijo.",
                            "image": RegularPaymentIcon,
                            "name": "pago_regular"
                        },
                        {
                            "to": "/client-admin/payments/new/D",
                            "label": "Pago con Requisitos",
                            "description": "Personaliza el pago para cada cliente y dale acceso para revisar su pago mediante una clave asignada por ti.",
                            "image": DebtPaymentIcon,
                            "name": "pago_requisitos"
                        }
                    ]
                }
            },
            buttonText: {
                type: String,
                default: 'Nuevo elemento'
            },
            buttonDropDownTitle: {
                type: String,
                default: 'Selecciona el tipo de elemento que deseas crear:'
            },
            // searchText: {
            //     type: String,
            //     default: ""
            // }
            canCreate: {
                type: Boolean,
                default: true
            },
            noOptions: {
                type: Boolean,
                default: false
            },
            showDateRange: {
                type: Boolean,
                default: false
            },
            perPage: {
                type: [String, Number],
                default: 10
            },
            showDropdown: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                searchText: "",
                // Date Range Picker
                opens: 'left',
                showDropdowns: true,
                autoApply: true,
                dateRange: {},
                perPageValue: 10
            }
        },
        methods: {
            resetInput: function (e) {
                if (e) {
                    e.preventDefault();
                }
                this.searchText = ""
                this.$emit('searchTextChange', "")
            },
            changeListLimit(limit) {
                this.$emit('changeListLimit', limit);
            },
            searchTextChange(event) {
                this.$emit('searchTextChange', event.target.value)
            },
            clickEventDropDown(to) {
                this.$emit('routerPush', to)
            },
            changed(val) {
                this.$emit('filterSelected', val);
            },
            changeDateRange(value) {
                this.$emit('dateRangeChange', value)
            },
            getDateRangeToString() {
                return `${utils.toFormatDate(this.dateRange.startDate, "DD/MM/YYYY")} - ${utils.toFormatDate(this.dateRange.endDate, "DD/MM/YYYY")}`;
            }
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    .search-filter-bar {
        border-radius: 3px;
        box-shadow: 0 2px 10px 0 rgba(20, 34, 102, 0.15);
        padding: 18px 20px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        min-height: 70px;

        .search-container {
            display: flex;
            align-items: center;
            margin: 0 auto 0 0;
            min-width: 40%;
            // width: calc(100% - 370px);

            input {
                border: none;
                padding: 6px 6px 6px 30px;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.43;
                width: 100%;
                transition: 0.5s;
            }

            i {
                position: absolute;
                float: left;
                left: 20px;
                width: 24px;
                height: 24px;
                font-size: 25px;
                transition: 0.5s;

                &.banana-search-normal {
                    opacity: 0.4;
                    z-index: 1;

                    &.hide {
                        opacity: 0;
                        z-index: -1;
                    }
                }

                &.banana-cross-normal {
                    opacity: 0;
                    z-index: -1;

                    &:hover {
                        transform: scale(1.1);
                        opacity: 0.7;
                    }

                    &.show {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }

            input:focus ~ i {
                &.banana-search-normal {
                    opacity: 0;
                    z-index: -1;
                }

                &.banana-cross-normal {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }

        .filter-container {
            margin: 0 0 0 auto;
            min-width: 370px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > .form-group.noLabel {
                width: 185px;

                > .form-text-info {
                    margin: 0 !important;
                }
            }
        }

        .b-dropdown-form {
            padding: 0 !important;

            .form-group {
                margin-bottom: 0 !important;
            }
        }

        .filter-container > .dropdown-image-list .dropdown-toggle {
            padding: 0;
        }
    }
</style>
