<template>
    <div
        class="graph-half-circle" :class="clazz">
        <div class="circle">
            <div id="chartHalfCircle">
                <apexchart
                    width="100%"
                    height="280px"
                    type="radialBar"
                    :options="chartCircleOptions"
                    :series="[progressPercent]"
                    class="circle-progress-info"
                ></apexchart>
            </div>
        </div>
        <div
            class="progress-info"
            :class="{ 'no-coplete': noComplete, 'half-coplete': halfComplete, 'full-coplete': fullComplete }">
            <div class="img-container">
                <img
                    class="img-fluid error"
                    src="@/assets/images/Illustrations/illustration-face-bad.svg"
                    alt=""
                />
                <img
                    class="img-fluid warning"
                    src="@/assets/images/Illustrations/illustration-face-good.svg"
                    alt=""
                />
                <img
                    class="img-fluid success"
                    src="@/assets/images/Illustrations/illustration-face-excellent.svg"
                    alt=""
                />
            </div>
            <div v-if="customText" class="text-info">
                <span class="custom-status">
                    <label>{{upText}}</label>
                    <p :class="{ 'c-accent_2': noComplete, 'c-warning': halfComplete, 'c-success': fullComplete }">{{downText}}</p>
                </span>
            </div>
            <div v-if="!customText" class="text-info">
                <span
                    v-if="!fullComplete"
                    class="in-progress"
                    v-html="$t('chart-progress-info.count', {progress, doneProgress, label})"
                ></span>

                <span
                    v-if="fullComplete"
                    class="done-progress"
                    v-html="labelComplete"
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChartProgressInfo',
    props: {
        clazz: String,
        label: String,
        labelComplete: String,
        progress: { type: Number, default: 0 },
        doneProgress: { type: Number, default: 100 },
        customText: {type: Boolean, default: false},
        upText: String,
        downText: String
    },
    data () {
        return {};
    },
    computed: {
        progressPercent () {
            try {
                return this.progress * 100 / this.doneProgress;
            } catch (error) {
                return 0;
            }
        },
        noComplete () {
            return this.progressPercent < 50;
        },
        halfComplete () {
            return this.progressPercent >= 50 && this.progressPercent < 99;
        },
        fullComplete () {
            return this.progressPercent >= 99;
        },
        chartCircleOptions () {
            let gradientToColors;
            let colors;

            if (this.noComplete) {
                gradientToColors = ['#557CF1'];
                colors = ['#557CF1'];
            } else if (this.halfComplete) {
                gradientToColors = ['#FCA429'];
                colors = ['#FCA429'];
            } else {
                gradientToColors = ['#37B96D'];
                colors = ['#37B96D'];
            }

            return {
                plotOptions: {
                    radialBar: {
                        offsetY: -20,
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: '#d8dae1'
                        },
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: false,
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors,
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                colors,
                stroke: {
                    lineCap: 'round'
                },
                legend: {
                    show: false
                }
            }
        }
    },
}
</script>
