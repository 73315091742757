import collaboratorsApi from '@/api/permissions/collaborators.api';
import {EventBus} from "@/main";
import {
    GET_COLLABORATORS,
    SET_COLLABORATORS,
    GET_PROFILES,
    SET_PROFILES,
    GET_TOTAL,
    SET_TOTAL,
    GET_CURRENT_PAGE,
    SET_CURRENT_PAGE,
    GET_OBJECT_PAGE,
    GET_ORGANIZATION,
    SET_ORGANIZATION,
    GET_SEARCH_TEXT,
    SET_SEARCH_TEXT,
    GET_LOADING_ITEMS,
    SET_LOADING_ITEMS
} from "./collaborators.types";

import {
    WARNING_EVENT,
    SUCCESS_SAVE
} from '@/store/events';

import {STORE as CollaboratorsStore} from "../permissions/collaborators.types";
import i18n from '@/plugins/i18n';
const storeName= CollaboratorsStore;

const stateGlobal = {
    collaborators: {},
    profiles: {},
    total: 0,
    perPage: 10,
    currentPage: 1,
    organization: "",
    searchText: "",
    loading: true
};

const gettersGlobal = {
    [GET_COLLABORATORS]: (state) => state.collaborators,
    [GET_PROFILES]: (state) => state.profiles,
    [GET_TOTAL]: (state) => state.total,
    [GET_CURRENT_PAGE]: (state) => state.currentPage,
    [GET_OBJECT_PAGE]: (state) => state.perPage,
    [GET_ORGANIZATION]: (state) => state.organization,
    [GET_SEARCH_TEXT]: (state) => state.searchText,
    [GET_LOADING_ITEMS]: (state) => state.loading,
};

const mutations = {
    [SET_COLLABORATORS]: (state, value) =>{
        state.collaborators= value;
    },
    [SET_PROFILES]: (state, value) =>{
        state.profiles= value;
    },
    [SET_TOTAL]: (state, value) =>{
        state.total= value;
    },
    [SET_CURRENT_PAGE]: (state, value) =>{
        state.currentPage= value;
    },
    [SET_ORGANIZATION]: (state, value) =>{
        state.organization= value;
    },
    [SET_SEARCH_TEXT]: (state, value) =>{
        state.searchText= value;
    },
    [SET_LOADING_ITEMS]: (state, loading)=>{
        state.loading = loading;
    },
};

const actions = {

    list({commit, getters}, organizationId){
        collaboratorsApi.list({
            currentPage: getters[GET_CURRENT_PAGE],
            perPage: getters[GET_OBJECT_PAGE],
            searchText: getters[GET_SEARCH_TEXT],
            organizationId: organizationId
        }, (result)=> {
            if(result.data.error === true){
                EventBus.$emit(storeName + WARNING_EVENT, result.data.message);
            }else{
                commit(SET_COLLABORATORS, result.data.object.users);
                commit(SET_PROFILES, result.data.object.profiles);
                commit(SET_TOTAL, result.data.object.users.length);
                commit(SET_LOADING_ITEMS, false);
            }
        }, () => {
            EventBus.$emit(storeName + WARNING_EVENT, i18n.t('collaborators.store.list-error'));
            commit(SET_COLLABORATORS, {});
            commit(SET_PROFILES, {});
            commit(SET_TOTAL, 0);
        });
    },

    addCollaborators({dispatch},params){
        collaboratorsApi.addCollaborators(params, (result)=> {
            if(result.data.error === true){
                EventBus.$emit(storeName + WARNING_EVENT, result.data.message);
            }else{
                EventBus.$emit(storeName + SUCCESS_SAVE, result.data.message);
                dispatch("list", params.organizationId);
            }
        }, () => {
            EventBus.$emit(storeName + WARNING_EVENT, i18n.t('permissions.store.create-profile.error'))
        });
    },

    async saveCollaboratorProfiles({dispatch},params){
        const response = await collaboratorsApi.saveCollaboratorProfiles(params);

        if(response.data.error === true){
            EventBus.$emit(storeName + WARNING_EVENT, response.data.message);
        }else{
            EventBus.$emit(storeName + SUCCESS_SAVE, response.data.message);
            dispatch("list", params.organizationId);
        }
    },

    async deleteCollaborator({dispatch},params){
        const response = await collaboratorsApi.deleteCollaborator(params);

        if(response.data.error === true){
            EventBus.$emit(storeName + WARNING_EVENT, response.data.message);
        }else{
            EventBus.$emit(storeName + SUCCESS_SAVE, response.data.message);
            dispatch("list", params.organizationId);
        }
    },

};

export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
