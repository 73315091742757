<template>
    <CardSimple
        :title="$t('organizaciones.informacion-general.title')"
        :subtitle="$t('organizaciones.informacion-general.subtitle')"
    >
        <div class="row">
            <LoadingBar
                v-if="loading"
                :loadingText="$t('general.info.loading')"
            />
            <div class="col-12">
                <BasicInput
                    required
                    inputType="text"
                    labelFor="orgName"
                    inputId="orgName"
                    :readOnly="!$can('do', 'UPDATE_GENERAL_INFO_ORGANIZATION')"
                    :label="$t('organizaciones.informacion-general.name.label')"
                    v-model="generalInfoForm.name"
                    :validations="$v.generalInfoForm.name"
                    validationsNamespace="general.validations"
                    :inputPlaceholder="$t('organizaciones.informacion-general.name.input-place-holder')"
                >
                </BasicInput>
            </div>
            <div class="col-12">
                <BasicSelect
                    required
                    :label="$t('organizaciones.informacion-general.category.label')"
                    labelFor="orgcat"
                >
                    <multiselect
                        v-model="generalInfoForm.category"
                        :options="options"
                        :close-on-select="true"
                        :preserve-search="true"
                        :disabled="true"
                        :placeholder="$t('organizaciones.informacion-general.category.input-place-holder')"
                        label="name"
                        :custom-label="categoryCustomLabel"
                        track-by="name"
                        :preselect-first="false"
                    >
                    </multiselect>
                </BasicSelect>
            </div>
            <div class="col-12">
                <BasicTextarea
                    required
                    labelFor="desc"
                    textareaId="desc"
                    :readOnly="!$can('do', 'UPDATE_GENERAL_INFO_ORGANIZATION')"
                    v-model="generalInfoForm.description"
                    :validations="$v.generalInfoForm.description"
                    validationsNamespace="general.validations"
                    :label="$t('organizaciones.informacion-general.description.input-place-holder')"
                    :textareaPlaceholder="$t('organizaciones.informacion-general.description.label')"
                >
                </BasicTextarea>
            </div>
            <div class="col-12">
                <BasicTextarea
                    labelFor="desc"
                    textareaId="desc"
                    :readOnly="!$can('do', 'UPDATE_GENERAL_INFO_ORGANIZATION')"
                    v-model="generalInfoForm.additionalInformation"
                    :validations="$v.generalInfoForm.additionalInformation"
                    validationsNamespace="general.validations"
                    :label="$t('organizaciones.informacion-general.additionalInformation.input-place-holder')"
                    :textareaPlaceholder="$t('organizaciones.informacion-general.additionalInformation.label')"
                    :maxCount="500"
                >
                </BasicTextarea>
            </div>
            <div class="col-12 m-t-50">
                <div class="title-card">
                    <h1>
                        Ubicación
                        <label>Ubicación de las sucursales de la organización. Puedes agregar, editar o eliminar ubicaciones.</label>
                    </h1>
                </div>
                <GoogleMap
                    clazz=""
                    height="height:400px"
                    searchBarButton
                    locationOption
                    tooltipMark
                    tooltipText="Arrastra para cambiar la ubicación o haz clic para obtener información."
                ></GoogleMap>
            </div>
            <div class="col-12 vertical-center justify-content-end m-t-40">
                <span class="f-14 c-info_text m-0-auto-0-0">
                    <i aria-hidden="true" class="c-error">*</i>
                    {{$t('general.required-fields')}}
                </span>
                <div class="vertical-center">
                    <button
                        :disabled="loading"
                        @click="cancel"
                        type="button"
                        class="button xs button-ghost button-plain_text"
                    >{{$t('general.actions.cancel.title')}}</button>
                    <button
                        :disabled="loading || hasError"
                        @click="uploadGeneralInfo"
                        type="button"
                        class="button xs button-filled button-accent1 m-l-10"
                    >{{$t('general.actions.save')}}</button>
                </div>
            </div>
        </div>
    </CardSimple>
</template>

<script>
import CardSimple from '@/components/CardSimple.vue';
import BasicInput from '@/components/BasicInput.vue';
import BasicTextarea from '@/components/BasicTextarea.vue';
import BasicSelect from '@/components/BasicSelect.vue';
import Multiselect from 'vue-multiselect';
import LoadingBar from '@/components/LoadingBar.vue';
import GoogleMap from '@/components/GoogleMap.vue'
import { mapGetters } from 'vuex';
import {
    STORE as OrganizationsStore,
    GET_GENERAL_INFO,
    FETCH_GENERAL_INFO,
    UPLOAD_GENERAL_INFO,
} from '@/store/modules/organization/organization.types';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION, FETCH_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { utils } from '@/api/utils';
import Categories from '@/enums/categories.js';

export default {
    name: 'general-information',
    components: {
        CardSimple,
        BasicInput,
        BasicTextarea,
        BasicSelect,
        Multiselect,
        LoadingBar,
        GoogleMap
    },
    props: [],
    mounted () {
        this.load();
    },
    data () {
        return {
            loading: false,
            value: [],
            options: Categories.getAllCategories(),
            generalInfoForm: {
                name: '',
                description: '',
                category: 'OTHER',
                urlAlias: ''
            }
        }
    },
    validations: {
        generalInfoForm: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            description: {
                required,
                maxLength: maxLength(200)
            },
            additionalInformation: {
                maxLength: maxLength(500)
            },
            category: {
                required,
            }
        }
    },
    methods: {
        load () {
            if (this.currentOrganization) {
                this.loading = true;
                this.$store.dispatch(`${OrganizationsStore}/${FETCH_GENERAL_INFO}`, this.currentOrganization._id).then(() => {
                    this.refreshGeneralInfoForm();
                    this.loading = false;
                });
            }
        },
        cancel () {
            this.refreshGeneralInfoForm();
            utils.toast(this.$snotify, 'info', this.$t('general.actions.cancel.title'), this.$t('general.actions.cancel.text'));
        },
        refreshGeneralInfoForm () {
            this.generalInfoForm = JSON.parse(JSON.stringify(this.generalInformation));
            if (this.generalInfoForm.category) {
                this.generalInfoForm.category = { value: this.generalInfoForm.category };
            }
        },
        // Other methods
        uploadGeneralInfo () {
            this.loading = true;
            // Hay que transformar el campo category a ser sólo una string por limitaciones del componente del select
            const infoToSend = JSON.parse(JSON.stringify(this.generalInfoForm));
            if (infoToSend.category && infoToSend.category.value) {
                infoToSend.category = infoToSend.category.value;
            }
            this.$store.dispatch(`${OrganizationsStore}/${UPLOAD_GENERAL_INFO}`, { organization: this.currentOrganization._id, data: infoToSend })
                .then(() => {
                    utils.toast(this.$snotify, 'success', this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                    this.$store.dispatch(`${ACCESS_STORE}/${FETCH_CURRENT_ORGANIZATION}`);
                }).catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        utils.toast(this.$snotify, 'error', this.$t('general.actions.error.title'), error.response.data.message);
                    } else {
                        utils.toast(this.$snotify, 'error', this.$t('general.actions.error.title'), this.$t('general.actions.error.text'));
                    }
                    this.loading = false;
                });
        },
        categoryCustomLabel ({ value }) {
            return this.$t(`organizaciones.category.options.${value}`);
        }
    },
    computed: {
        hasError () { return this.$v.$invalid },
        ...mapGetters(OrganizationsStore, {
            generalInformation: GET_GENERAL_INFO
        }),
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
    },
    watch: {
        currentOrganization () {
            this.load();
        }
    }
}
</script>
