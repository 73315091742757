<template>
    <div :class="`${divClass} ${loadingClass}`">
        <button :id="id"
                :type="type"
                :class="`${shape} ${kind} ${getColor} ${size} ${_class} ${loadingClass}`"
                @click="onClick"
                :data-toggle="dataToggle"
                :data-target="dataTarget"
                :data-dismiss="dataDismiss"
                :disabled="disabled"
        >
            <i aria-hidden="true" :class="`${icon} vertical-center`" v-if="icon && icon.length"></i>
            <div>
                {{text}}
            </div>
            <slot>
            </slot>
        </button>
    </div>
</template>

<script>
    import BUTTONS from '@/common/consts/buttons.consts';
    import propValidators from '@/common/validations/form/propValidators';

    export default {
        data () {
            return {
            }
        },
        components: {
        },
        methods: {
            onClick() {
                if (!this.disabled) {
                    this.$emit('click');
                }
            },
        },
        computed: {
            loadingClass() {
                if (this.loading) {
                    return 'loading-btn';
                }

                return '';
            },
            getColor() {
                return this.color;
            },
        },
        props: {
            text: {
                type: String,
                required: true
            },
            type: {
                type: String,
                default: 'button',
            },
            id: {
                type: String,
                required: true,
                validator: propValidators.id,
            },
            _class: {
                type: String,
                default: '',
            },
            divClass: {
                type: String,
                default: '',
            },
            shape: {
                type: String,
                validator: propValidators.enum(BUTTONS.SHAPES._VALUES),
                required: true,
            },
            kind: {
                type: String,
                validator: propValidators.enum(BUTTONS.KINDS._VALUES),
                required: true,
            },
            color: {
                type: String,
                validator: propValidators.enum(BUTTONS.COLORS._VALUES),
                required: true,
            },
            size: {
                type: String,
                validator: propValidators.enum(BUTTONS.SIZES._VALUES),
                required: true,
            },

            icon: {
                type: String,
                // validator: propValidators.enum(ICONS._VALUES),
                required: false,
                default: null,
            },

            dataToggle: {
                type: String,
            },
            dataTarget: {
                type: String,
            },
            dataDismiss: {
                type: String,
            },

            disabled: {
                type: Boolean,
                default: false
            },

            loading: {
                type: Boolean,
                default: false
            }
        },
        mounted () {
        }
    }
</script>
