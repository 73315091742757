import AccessOrganizationsApi from "@/api/organization/organizations.api";
import {
    GET_BANK_INFO,
    SET_BANK_INFO,
    UPDATE_BANK_DATA,
    GET_GENERAL_INFO,
    SET_GENERAL_INFO,
    FETCH_GENERAL_INFO,
    UPDATE_NAME,
    UPDATE_DESCRIPTION,
    UPLOAD_GENERAL_INFO,
    FETCH_BANK_DATA,
    FETCH_BILLING_DATA,
    GET_BILLING_DATA,
    SET_BILLING_DATA,
    GET_FILE,
    UPLOAD_IMAGE_INFO,
    FETCH_IMAGE_DATA,
    SET_IMAGE_DATA,
    GET_IMAGE_DATA,
    GET_CAN_UPDATE_BANK_INFO,
    FETCH_CAN_UPDATE_BANK_INFO,
    SET_CAN_UPDATE_BANK_INFO,
    UPLOAD_COVER_INFO,
    GET_CONTACT_INFO,
    SET_CONTACT_INFO,
    UPDATE_CONTACT_INFO,
    FETCH_CONTACT_INFO,
    GET_NOTIFICATION_INFO,
    SET_NOTIFICATION_INFO,
    UPDATE_NOTIFICATION_INFO,
    FETCH_NOTIFICATION_INFO,
    GET_BILLING_INFO,
    SET_BILLING_INFO,
    UPDATE_BILLING_INFO,
    FETCH_BILLING_INFO,
    GET_PAYMENTS_CONFIGURATION,
    SET_PAYMENTS_CONFIGURATION,
    UPDATE_PAYMENTS_CONFIGURATION,
    FETCH_PAYMENTS_CONFIGURATION,
} from "./organization.types";

const NO_ORG_RECEIVED_MSG = "No organization received";
// Objetos
const stateGlobal = {
    bankDetails: {
        bankId: {},
        accountNumber: "",
        clabe: "",
        bankCover: "",
        unfairChargebackPayment: null,
    },
    billingInfoOld: {
        RFC: "",
        billingData: {}
    },
    generalInformation: {
        name: "",
        description: "",
        category: "",
        urlAlias: ""
    },
    imageInformation: {
        image: "",
        cover: ""
    },
    canUpdateBankInfo: false,
    contactInfo: {
        address: "",
        phone: "",
        contactPhone: "",
        email: "",
        urlAlias: "",
        organizationPhone: "",
        site: "",
        facebook: "",
        instragram: "",
        twitter: "",
        private: false
    },
    notificationInfo: {
        cco: "",
        webhooksErrorsEmail: "",
        notificationEmails: []
    },
    billingInfo: {
        phone: "",
        email: "",
        url: "",
        description: "",
        streetAddress: "",
        exteriorNumber: "",
        interiorNumber: "",
        district: "",
        state: "",
        city: "",
        zipCode: ""
    },
    paymentsConfiguration: {
        currency: {},
        activeService: false,
        comingSoon: false,
        sandBoxMode: false,
        enableInvoicing: false,
    }
};

// Métodos para obtener valores del state. Se les llama con getters
const getters = {
    [GET_BANK_INFO]: state => state.bankDetails,
    [GET_GENERAL_INFO]: state => state.generalInformation,
    [GET_BILLING_DATA]: state => state.billingInfo,
    [GET_IMAGE_DATA]: state => state.imageInformation,
    [GET_FILE]: state => state.fileDetails,
    [GET_CAN_UPDATE_BANK_INFO]: state => state.canUpdateBankInfo,
    [GET_CONTACT_INFO]: state => state.contactInfo,
    [GET_NOTIFICATION_INFO]: state => state.notificationInfo,
    [GET_BILLING_INFO]: state => state.billingInfo,
    [GET_PAYMENTS_CONFIGURATION]: state => state.paymentsConfiguration,
};

// Métodos síntronos que afectan el state. Se llaman con commit
const mutations = {
    [SET_BILLING_DATA]: (state, payload) => {
        state.billingInfo = payload;
    },
    [SET_BANK_INFO]: (state, payload) => {
        state.bankDetails = payload;
    },
    [SET_IMAGE_DATA]: (state, payload) => {
        state.imageInformation = payload;
    },
    [SET_GENERAL_INFO]: (state, payload) => {
        state.generalInformation = payload;
    },
    [UPDATE_NAME]: (state, payload) => {
        state.generalInformation.name = payload;
    },
    [UPDATE_DESCRIPTION]: (state, payload) => {
        state.generalInformation.description = payload;
    },
    [SET_CAN_UPDATE_BANK_INFO]: (state, payload) => {
        state.canUpdateBankInfo = payload;
    },
    [SET_CONTACT_INFO]: (state, payload) => {
        state.contactInfo = payload;
    },
    [SET_NOTIFICATION_INFO]: (state, payload) => {
        state.notificationInfo = payload;
    },
    [SET_BILLING_INFO]: (state, payload) => {
        state.billingInfo = payload;
    },
    [SET_PAYMENTS_CONFIGURATION]: (state, payload) => {
        state.paymentsConfiguration = payload;
    }
};

// Métodos asíncronos que hacen cosas y luego hacen commit. Se llaman con dispatch
const actions = {
    [FETCH_BILLING_DATA]: ({ commit }, data) => {
        if (data) {
            return AccessOrganizationsApi.organizationBillingInfo(data).then(d => {
                commit(SET_BILLING_DATA, d.data.object);
            });
        }
        return Promise.reject(new Error("No billing data received"));
    },
    [FETCH_BANK_DATA]: ({ commit }, data) => {
        if (data) {
            return AccessOrganizationsApi.getBankInfo(data, d => {
                commit(SET_BANK_INFO, d.data.object);
            });
        }
        return Promise.reject(new Error("No bank data received"));
    },
    [UPDATE_BANK_DATA]: ({ commit }, params) => {
        if (params) {
            return AccessOrganizationsApi.updateBankInfo(params).then(data =>
                commit(SET_BANK_INFO, data.data.object)
            );
        }
        return Promise.reject(new Error("params must be defined to update bank data"));
    },
    [FETCH_GENERAL_INFO]: ({ commit }, organizationId) => {
        if (organizationId) {
            return AccessOrganizationsApi.organizationGeneralInfo(
                organizationId,
                data => commit(SET_GENERAL_INFO, data.data.object)
            );
        }
        return Promise.reject(new Error("Organization id was not received"));
    },
    [UPLOAD_GENERAL_INFO]: (context, { organization, data }) => {
        if (organization && data) {
            return AccessOrganizationsApi.updateOrganizationGeneralInfo(
                organization._id || organization,
                data
            );
        }
        return Promise.reject(new Error("All params must be defined to upload general info"));
    },
    [FETCH_IMAGE_DATA]: ({ commit }, data) => {
        if (data) {
            return AccessOrganizationsApi.getOrganizationImage(data._id).then(d => {
                commit(SET_IMAGE_DATA, d.data.object);
            });
        }
        return Promise.reject(new Error("No image data received"));
    },
    [UPLOAD_IMAGE_INFO]: (context, { organization, logoCropped, logoFullSize, coverCropped, coverFullSize }) => {
        if (organization && (logoCropped || coverCropped)) {
            return AccessOrganizationsApi.updateOrganizationImage(
                organization._id || organization,
                logoCropped,
                logoFullSize,
                coverCropped,
                coverFullSize
            );
        }
        return Promise.reject(new Error("Image must be defined to could upload it"));
    },
    [UPLOAD_COVER_INFO]: (context, data) => {
        if (data) {
            return AccessOrganizationsApi.updateOrganizationCover(data._id, data.body);
        }
        return Promise.reject(new Error("data must be defined to upload cover"));
    },
    [FETCH_CAN_UPDATE_BANK_INFO]: ({ commit }, organization) => {
        if (organization) {
            return AccessOrganizationsApi.getCanUpdateBankInfo(
                organization
            ).then(data => commit(SET_CAN_UPDATE_BANK_INFO, data.data.object));
        }
        return Promise.reject(new Error(NO_ORG_RECEIVED_MSG));
    },
    [FETCH_CONTACT_INFO]: ({ commit }, organization) => {
        if (organization) {
            return AccessOrganizationsApi.getContactInfo(
                organization
            ).then(data => commit(SET_CONTACT_INFO, data.data.object));
        }
        return Promise.reject(new Error(NO_ORG_RECEIVED_MSG));
    },
    [UPDATE_CONTACT_INFO]: (context, data) => {
        if (data) {
            return AccessOrganizationsApi.updateContactInfo(data._id, data.body);
        }
        return Promise.reject(new Error("data must be defined to save contact info"));
    },
    [FETCH_NOTIFICATION_INFO]: ({ commit }, organization) => {
        if (organization) {
            return AccessOrganizationsApi.getNotificationInfo(
                organization
            ).then(data => commit(SET_NOTIFICATION_INFO, data.data.object));
        }
        return Promise.reject(new Error(NO_ORG_RECEIVED_MSG));
    },
    [UPDATE_NOTIFICATION_INFO]: (context, data) => {
        if (data) {
            return AccessOrganizationsApi.updateNotificationInfo(data._id, data.body);
        }
        return Promise.reject(new Error("data must be defined to save notification info"));
    },
    [FETCH_BILLING_INFO]: ({ commit }, organization) => {
        if (organization) {
            return AccessOrganizationsApi.getBillingInfo(
                organization
            ).then(data => commit(SET_BILLING_INFO, data.data.object));
        }
        return Promise.reject(new Error(NO_ORG_RECEIVED_MSG));
    },
    [UPDATE_BILLING_INFO]: (context, data) => {
        if (data) {
            return AccessOrganizationsApi.updateBillingInfo(data._id, data.body);
        }
        return Promise.reject(new Error("data must be defined to save billing info"));
    },
    [FETCH_PAYMENTS_CONFIGURATION]: ({ commit }, organization) => {
        if (organization) {
            return AccessOrganizationsApi.getPaymentsConfiguration(
                organization
            ).then(data => commit(SET_PAYMENTS_CONFIGURATION, data.data.object));
        }
        return Promise.reject(new Error(NO_ORG_RECEIVED_MSG));
    },
    [UPDATE_PAYMENTS_CONFIGURATION]: (context, data) => {
        if (data) {
            return AccessOrganizationsApi.updatePaymentsConfiguration(data._id, data.body);
        }
        return Promise.reject(new Error("data must be defined to save billing info"));
    },
};

const namespaced = true;
export default { namespaced, state: stateGlobal, getters, actions, mutations };
