<template>
<div :id="idForm" class="form-group" :class="`${clazz} ${errorClass}`">
    <textarea v-if="!readOnly"
                  class="form-control"
                  @input="onBasicInput"
                  :class="textAreaClazz"
                  rows="5"
                  :id="textareaId"
                  :placeholder="textareaPlaceholder"
                  v-on:keyup="countdown"
                  :value="value || inputValue"></textarea>
    <textarea v-else readonly
                  class="form-control"
                  @input="onBasicInput"
                  :class="textAreaClazz"
                  rows="5"
                  :id="textareaId"
                  :placeholder="textareaPlaceholder"
                  v-on:keyup="countdown"
                  :value="value || inputValue"></textarea>
    <div v-if="!hideCounter" class="word-counter"><small :class="{'c-error': countError }">{{(value || inputValue || '').length}}</small> / <small>{{maxCount}}</small></div>

    <div class="input-label">
        <label class="" :class="labelClass" :for="labelFor">{{label}} <span v-if="required">*</span></label>
        <div v-if="help" :id="idHelpTooltip" class="help-info">
            {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
            <slot name="tooltipHelp"></slot>
        </div>
    </div>

    <small :id="smallId" class="form-text-info">
        <i aria-hidden="true" :class="warningIcon || warningIconComputed"></i>
        {{warningText || warningTextComputed}}
    </small>

</div>

</template>

<script>
        // TODO meter en utils
    const index = [];
    const deboundeTime = 800;
    const debounce = function (func, ...params) {
        const id = func.toString() + JSON.stringify(params || []);
        if (!index[id]) {
            index[id] = true;
            setTimeout(function () {
                index[id] = false
            }, deboundeTime);
            return func(...params);
        }
            return null;

    }

    export default {
        name: 'BasicTextarea',
        props: {
            id: String,
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            required: {type: Boolean, default: false},
            label: String,
            labelClass: String,
            helpText: String,
            warningText: String,
            textareaPlaceholder: String,
            helpIcon: String,
            warningIcon: String,
            labelFor: String,
            textareaId: String,
            clazz: String,
            textAreaClazz: String,
            inputValue: {type: String, default: null},
            value: {type: String, default: null},
            validations: {},
            smallId: String,
            validationsNamespace: String,
            idForm: String,
            readOnly: {type: Boolean, default: false},
            hideCounter: {type: Boolean, default: false},
            maxCount: {type: Number, default: 200},
            remainingCount: {type: Number, default: 200},
        },
        data () {
            return {
                initialCount: 0,
                message: '',
                countError: false
            }
        },
        methods: {
            countdown: function() {
                this.remainingCount = this.maxCount - this.message.length;
                this.initialCount = this.remainingCount;
                this.countError = this.remainingCount < 0;
            },
            onBasicInput(event) {
                this.$emit('basicInput', event.target.value)
                this.$emit('input', event.target.value);
                if (this.validations) {
                    debounce(() => {
                        this.validations.$touch();
                    });
                }
            }
        },
        computed: {
            errorClass() {
                return this.validations && this.validations.$error ? 'has-error' : ''
            },
            warningTextComputed() {
                if (this.validations) {
                    const errorKey = Object
                        .keys(this.validations)
                        .find(k => this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
                    if (errorKey) {
                        const params = this.validations.$params[errorKey];
                        return this.$t(`${this.validationsNamespace}${this.validationsNamespace ? '.' : ''}${errorKey}`, params);
                    }
                        return null;

                }
                    return null;

            },
            warningIconComputed() {
                if (this.validations) {
                    const errorKey = Object
                        .keys(this.validations)
                        .find(k => this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
                    return errorKey ? 'banana banana-warning-circle1' : null;
                }
                    return null;

            }
        }
    }
</script>
