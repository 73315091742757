<template>
    <div class="">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/payments"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit p-0"
                    tabindex=""
                >
                    <i aria-hidden="true" class="banana banana-home c-accent_2"></i> {{$t('paymentLinks.subtitle')}}
                </router-link>
                <FloatingTitleLayout :title="$t('paymentLinks.title')" />
            </div>


            <div class="col-12"  v-if="links.length === 0 && searchText === null && !loading && !hasFilters">
                <CardSimple hideTitle>
                    <EmptyState
                        :imgUrlHorizontal="EmptystateImage"
                        :title="$t('paymentLinks.createTitle')"
                        :text="$t('paymentLinks.createSubtitle')"
                    >
                        <button class="button xs button-filled button-accent1" @click="goToNew()" v-if="$can('do', 'PAYMENT_LINK_CREATE')">
                            <i
                                aria-hidden="true"
                                class="banana banana-symbol-plus"
                            ></i> {{$t('paymentLinks.newLink')}}
                        </button>
                    </EmptyState>
                </CardSimple>
            </div>

            <div class="col-12"  v-if="links.length > 0 || searchText !== null || hasFilters">
                <SearchBar
                    :columValues="fields"
                    clazzFilter="m-r-0"
                    :noOptions="true"
                    :buttonText="$t('paymentLinks.newLink')"
                    @changeListLimit="changeListLimit"
                    :showButton="$can('do', 'PAYMENT_LINK_CREATE')"
                    @searchTextChange="searchTextChange"
                    @routerPush="goToNew()"
                ></SearchBar>
            </div>

            <div class="col-12" v-if="loading">
                 <CardSimple hideTitle clazz="p-0" clazzTitle="d-none">
                    <EmptyState emptyStateSearching :text="$t('paymentLinks.searching')"></EmptyState>
                </CardSimple>
            </div>

            <!-- <LoadingSimple v-if="loading" clazz="big-load no-text"/> -->


            <div class="col-12" v-if="links.length === 0 && (searchText !== null || hasFilters) && !loading">
                <CardSimple hideTitle>
                    <EmptyState
                        :imgUrlHorizontal="EmptystateImage"
                        :title="$t('paymentLinks.emptyTitle')"
                        :text="$t('paymentLinks.emptySubtitle')"
                    >
                        <button class="button xs button-filled button-accent1" @click="restartFilter()">
                            <i
                                aria-hidden="true"
                                class="banana banana-symbol-plus"
                            ></i> {{$t('paymentLinks.restartFilter')}}
                        </button>
                    </EmptyState>
                </CardSimple>
            </div>

            <div class="col-12" v-if="links.length > 0 && !loading">
                <CardSimple
                    clazz="w-100 p-0"
                    hideTitle
                >
                    <div class="basic-table menu-overflow-fix" id="menuOverflowFix">
                        <b-table-simple
                            responsive
                            selectable
                            :select-mode="selectMode"
                            selected-variant="active"
                            table-variant="xl"
                        >
                            <b-thead>
                                <b-tr :class="multiSelectDocuments ? 'header-options-full' : ''">
                                    <b-th v-show="fields[0].value">
                                        <div class="d-flex align-items-c">
                                            <BasicCheckbox
                                                clazz="m-0 w-fit di-flex"
                                                @change="selectAll"
                                                ref="CHECKBOX_CATALOG_ALL"
                                                :currentValue="multiSelectDocuments"
                                            ></BasicCheckbox>
                                            <span
                                                class="open-filters"
                                                @click="headerFilters()"
                                            > {{$t('paymentLinks.fields.folio')}} </span>
                                            <SortIcon
                                                @sortUp="setSort('fullKey', 'asc')"
                                                @sortDown="setSort('fullKey', 'desc')"
                                            ></SortIcon>
                                        </div>
                                        <div
                                            class="filters w-100px"
                                            :class="[filtersHeader ? 'show' : '']"
                                        >
                                            <BasicInput
                                                labelFor=""
                                                inputId=""
                                                inputPlaceholder="Folio"
                                                v-model="filters.fullKey"
                                                @input="setGenericFilter"
                                                clazz="noLabel"
                                            ></BasicInput>
                                        </div>
                                    </b-th>
                                    <b-th v-show="fields[1].value">
                                        <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                        > {{$t('paymentLinks.fields.concept')}} </span>
                                        <div
                                            class="filters"
                                            :class="[filtersHeader ? 'show' : '']"
                                        >
                                            <BasicInput
                                                labelFor=""
                                                inputId=""
                                                inputPlaceholder="Concepto"
                                                clazz="noLabel"
                                            ></BasicInput>
                                        </div>
                                    </b-th>
                                    <b-th v-show="fields[2].value">
                                        <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                        > {{$t('paymentLinks.fields.availability')}} </span>
                                        <div
                                            class="filters"
                                            :class="[filtersHeader ? 'show' : '']"
                                        >
                                            <BasicInput
                                                labelFor=""
                                                inputId=""
                                                inputPlaceholder="Disponibilidad"
                                                clazz="noLabel"
                                            ></BasicInput>
                                        </div>
                                    </b-th>
                                    <b-th class="text-right" v-show="fields[3].value">
                                        <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                        > {{$t('paymentLinks.fields.amount')}} </span>
                                        <SortIcon
                                            @sortUp="setSort('amount', 'asc')"
                                            @sortDown="setSort('amount', 'desc')"
                                        ></SortIcon>
                                        <div
                                            class="filters w-100px ml-auto"
                                            :class="[filtersHeader ? 'show' : '']"
                                        >
                                            <BasicInput
                                                labelFor=""
                                                inputId=""
                                                inputPlaceholder="Monto"
                                                clazz="noLabel"
                                            ></BasicInput>
                                        </div>
                                    </b-th>
                                    <b-th class="text-center" v-show="fields[4].value">
                                        <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                        > {{$t('paymentLinks.fields.status')}} </span>
                                        <SortIcon
                                            @sortUp="setSort('status', 'asc')"
                                            @sortDown="setSort('status', 'desc')"
                                        ></SortIcon>
                                        <div
                                            class="filters w-100px mr-auto ml-auto"
                                            :class="[filtersHeader ? 'show' : '']"
                                        >
                                            <BasicInput
                                                labelFor=""
                                                inputId=""
                                                inputPlaceholder="Estatus"
                                                clazz="noLabel"
                                            ></BasicInput>
                                        </div>
                                    </b-th>
                                    <b-th class="text-center">
                                        <span
                                            class="open-filters"
                                        > {{$t('paymentLinks.share')}} </span>
                                    </b-th>
                                    <b-th class="w-5 text-align-c"> {{$t('paymentLinks.fields.actions')}}</b-th>
                                    <b-th class="row-options">
                                        <div class="vertical-center">
                                            <BasicCheckbox
                                                clazz="m-0 w-fit di-flex"
                                                :currentValue="multiSelectDocumentsAfterSelect"
                                                @change="selectAll"
                                                :text="this.selectedRowsIndex.length + ' ' + $t('paymentLinks.selectedDocuments')"
                                                ref="CHECKBOX_CATALOG_ALL_AFTER"
                                                textClass="f-14 c-primary_1 principal-font-bold"
                                            ></BasicCheckbox>
                                            <div>
                                                <a v-if="$can('do', 'PAYMENT_LINK_DELETE')"
                                                    @click="deleteMany()"
                                                    class="opt-row"
                                                    tabindex=""
                                                ><i aria-hidden="true" class="banana banana-delete"></i>
                                                    {{$t('paymentLinks.delete')}} </a>
                                                <a
                                                    @click="resetMultipleDelete()"
                                                    class="opt-row"
                                                    tabindex=""
                                                > {{$t('paymentLinks.cancel')}} </a>
                                            </div>
                                        </div>
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(item, index) in links" :key="index"
                                :class="item.selectedItem ? 'b-table-row-selected table-active' : ''">
                                    <b-td v-show="fields[0].value">
                                        <div class="vertical-center">
                                            <BasicCheckbox
                                                clazz="m-0 w-fit di-flex"
                                                :index="index"
                                                :currentValue="item.selectedItem"
                                                @change="selectIndex"
                                                :ref="'CHECKBOX_CATALOG_' + index"
                                            ></BasicCheckbox>
                                            <router-link
                                                to=""
                                                class="c-primary_1"
                                            >
                                                <strong>{{item.fullKey}}</strong></router-link>
                                        </div>
                                    </b-td>
                                    <b-td v-show="fields[1].value">{{item.concept}}</b-td>
                                    <b-td v-show="fields[2].value">{{getAvailability(item.expDate)}}</b-td>
                                    <b-td v-show="fields[3].value" class="text-right f-bold c-accent_2">{{item.amount | currency}}</b-td>
                                    <b-td v-show="fields[4].value" class="text-align-c">
                                        <Badge
                                            :type="`${getClassStatus(item.status)} w-100px`"
                                            :text="getTextStatus(item.status)"
                                        ></Badge>
                                    </b-td>
                                    <b-td class="text-align-c">
                                        <button class="button xs button-ghost button-accent2 m-auto p-0" :disabled="!checkLink(item)" @click="showModalShare(item)">
                                            {{$t('paymentLinks.share')}}
                                        </button>
                                    </b-td>
                                    <b-td class="text-align-c w-5">
                                        <b-dropdown
                                            @show="showDropdown"
                                            @hide="hideDropdown"
                                            right
                                            class="dropdown-table"
                                        >
                                            <template v-slot:button-content>
                                                <button class="button-circle xs button-ghost button-info_text">
                                                    <i
                                                        aria-hidden="true"
                                                        class="banana banana-options-other-vertical"
                                                    ></i>
                                                </button>
                                            </template>
                                            <b-dropdown-group header="Mas Acciones">
                                                <b-dropdown-item-button @click="activeDeactiveLink(item)"
                                                    v-if="(item.status === 'ACTIVE' || item.status === 'DISABLED') && $can('do', 'PAYMENT_LINK_ACTIVE')">
                                                    <router-link
                                                        to=""
                                                        tabindex=""
                                                    ><i aria-hidden="true" class="banana banana-check-double-check"></i>
                                                    {{item.active ? 'Desactivar' : 'Activar'}}
                                                    </router-link>
                                                </b-dropdown-item-button>
                                                <b-dropdown-item-button @click="goToDetail(item._id)" v-if="$can('do', 'PAYMENT_LINK_READ')">
                                                    <router-link
                                                        to=""
                                                        tabindex=""
                                                    ><i aria-hidden="true" class="banana banana-visibility-on"></i>
                                                    {{$t('paymentLinks.seeDetail')}}
                                                    </router-link>
                                                </b-dropdown-item-button>
                                                <b-dropdown-item-button @click="goToEdit(item._id)" v-if="$can('do', 'PAYMENT_LINK_EDIT')">
                                                    <router-link
                                                        to=""
                                                        tabindex=""
                                                    ><i aria-hidden="true" class="banana banana-edit"></i>
                                                    {{$t('paymentLinks.edit')}}
                                                    </router-link>
                                                </b-dropdown-item-button>
                                                <b-dropdown-divider></b-dropdown-divider>
                                                <b-dropdown-item-button variant=" c-error" @click="showModalDeleteLink(item)" v-if="$can('do', 'PAYMENT_LINK_DELETE')">
                                                    <router-link
                                                        to=""
                                                        class="c-error"
                                                        tabindex=""
                                                    ><i aria-hidden="true" class="banana banana-delete"></i>
                                                        {{$t('paymentLinks.delete')}}
                                                    </router-link>
                                                </b-dropdown-item-button>
                                            </b-dropdown-group>
                                        </b-dropdown>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </CardSimple>
                <div class="pagination-custom">
                    <span class="pagination-count">{{totalLinks}} {{$tc('paymentLinks.links', totalLinks)}}</span>
                    <b-pagination
                        aria-controls="my-table"
                        next-text
                        prev-text
                        :total-rows="totalLinks"
                        :per-page="limit"
                        v-model="page"
                        hide-goto-end-buttons
                        @change="pageChange"
                    ></b-pagination>
                </div>
            </div>
            <ModalAlert
                modalId="modal-deleteMultipleLink"
                buttonType="button-error"
                :imgUrl="imageModalDelete"
                :title="$t('paymentLinks.deleteTitle')"
                :text="$t('paymentLinks.deleteSubtitle')"
                :buttonText="$t('paymentLinks.deleteLinksButton')"
                @submit="deleteMultipleLink()"
                @cancel="resetMultipleDelete()"
            ></ModalAlert>
            <ModalAlert
                modalId="modal-deleteLink"
                buttonType="button-error"
                :imgUrl="imageModalDelete"
                :title="$t('paymentLinks.deleteTitle2')"
                :text="$t('paymentLinks.deleteSubtitle2', {concept: linkToDelete.concept})"
                :buttonText="$t('paymentLinks.deleteLinkButton')"
                @submit="deleteLink()"
            ></ModalAlert>
            <ModalDefault
                hideCancelButton
                modalId="modal-comparte"
                originalFooter
                :title="$t('paymentLinks.shareTitle')"
                :subtitle="$t('paymentLinks.shareSubtitle')"
                :buttonText="$t('paymentLinks.shareButton')"
                @clickAccepted="shareLink"
            >
                <div>
                    <BasicInput
                        clazz="noLabel"
                        class="search-type"
                        inputType="text"
                        :value="linkToShare.shortLink"
                        @focus="$event.target.select()"
                        :readOnly="true"
                        customRef="linkToCopy"
                        inputId="linkToCopy"
                        inputPlaceholder="Buscar...">
                        <button type="button" class="button xs button-filled button-accent1" @click="copy()">
                            {{$t('paymentLinks.copy')}}
                        </button>
                    </BasicInput>
                </div>
                <b-tabs
                    class="simple-tabs group-tabs"
                    content-class="simple-tabs-body p-t-20"
                >
                    <b-tab
                        title="SMS"
                        active
                    >
                        <BasicInput
                            clazz="noLabel"
                            class="search-type"
                            inputType="text"
                            v-model="sms"
                            inputPlaceholder="Buscar...">
                            <button type="button" class="button xs button-filled button-accent1" :disabled="sms === null" @click="addSMS()">
                                {{$t('paymentLinks.add')}}
                            </button>
                        </BasicInput>
                        <IconList v-for="(item, index) in smsArray" :key="index" :text="getPhoneFormat(item)" icon="banana-phone-call-normal" @delete="deleteSMS(index)"></IconList>
                    </b-tab>
                    <b-tab title="Email">
                        <BasicInput
                            clazz="noLabel"
                            class="search-type"
                            inputType="text"
                            v-model="email"
                            inputPlaceholder="Buscar...">
                            <button type="button" class="button xs button-filled button-accent1" :disabled="email === null" @click="addEmail()">
                                {{$t('paymentLinks.add')}}
                            </button>
                        </BasicInput>
                        <IconList v-for="(item, index) in emailArray" :key="index" :text="item" icon="banana-message" @delete="deleteEmail(index)"></IconList>
                    </b-tab>
                </b-tabs>
            </ModalDefault>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import EmptyState from "@/components/EmptyState.vue";
    import SearchBar from "@/components/SearchBar.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import Badge from "@/components/Badge";
    import CardSimple from '@/components/CardSimple.vue'
    import SortIcon from '@/components/SortIcon.vue'
    import ModalAlert from '@/components/ModalAlert.vue'
    // import LoadingBar from '@/components/LoadingBar.vue';
    import ModalDefault from '@/components/ModalDefault.vue'
    import IconList from '@/components/IconList.vue'
    import EmptystateImage from '@/assets/images/Emptystates/emptystate-payment-person.svg';
    import imageModalDelete from "@/assets/images/Illustrations/illustration-modal-delete.svg";
    import PaymentLinksApi from "@/api/paymentLinks/paymentLinks.api";
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {mapGetters} from 'vuex';
    import utils from '@/common/utils';
    import moment from 'moment';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';

    export default {
        name: "PaymentLinkList",
        components: {
            FloatingTitleLayout,
            EmptyState,
            BasicInput,
            BasicCheckbox,
            Badge,
            CardSimple,
            SearchBar,
            SortIcon,
            ModalAlert,
            ModalDefault,
            IconList,
            // LoadingBar
        },
        mixins: [
            ToastSnotify
        ],
        data() {
            return {
                multiSelectDocuments: false,
                multiSelectDocumentsAfterSelect: false,
                selectedRowsIndex: [],
                selectMode: 'multi',
                EmptystateImage: EmptystateImage,
                imageModalDelete: imageModalDelete,
                filtersHeader: false,
                loading: false,
                links: [],
                page: 1,
                totalLinks: 0,
                limit: 10,
                searchText: null,
                fields: [
                    {
                        key: 'fullKey',
                        label: this.$i18n.t('paymentLinks.fields.folio'),
                        sortable: true,
                        value: true
                    },
                    {
                        key: 'concept',
                        label: this.$i18n.t('paymentLinks.fields.concept'),
                        sortable: true,
                        value: true
                    },
                    {
                        key: 'expDate',
                        label: this.$i18n.t('paymentLinks.fields.availability'),
                        sortable: true,
                        value: true
                    },
                    {
                        key: 'amount',
                        label: this.$i18n.t('paymentLinks.fields.amount'),
                        sortable: false,
                        value: true
                    },
                    {
                        key: 'status',
                        label: this.$i18n.t('paymentLinks.fields.status'),
                        sortable: false,
                        value: true
                    }
                ],
                linkToDelete: {
                    concept: null
                },
                linkToShare: {
                    link: null
                },
                smsArray: [],
                emailArray: [],
                sms: null,
                email: null,
                sort: {},
                filters: {
                    fullKey: null,
                    concept: null,
                    expDate: null,
                    amount: null,
                    status: null
                },
                hasFilters: false,
                timeoutFilters: null,
                timeoutSearch: null,
                timeoutLimit: null,
                selectedLinksId: []
            };
        },
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            checkIfActiveSelected () {
                return this.links.filter(d => d.selectedItem).length > 0;
            }
        },
        methods: {
            getPhoneFormat(phone) {
                return utils.formatPhone(phone);
            },
            resetMultipleDelete() {
                this.selectedLinksId = [];
                this.selectAll(false);
            },
            async deleteMultipleLink() {
                const response = await PaymentLinksApi.deleteMany({ids: this.selectedLinksId});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.deleteLinks'));
                } else {
                    this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.deleteLinks'));
                    await this.getList();
                }
            },
            deleteMany() {
                this.$bvModal.show('modal-deleteMultipleLink');
            },
            setGenericFilter(field, value) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.filters[field] = value;
                    this.hasFilters = true;
                    await this.getList();
                }, 1500);
            },
            async setSort(field, value) {
                this.sort = {};
                this.sort[field] = value;
                await this.getList();
            },
            selectAll (value) {
                for (let i = 0; i < this.links.length; i++) {
                    const data = {
                        index: i,
                        checkValue: value
                    }
                    this.selectIndex(data);
                    const ref = `CHECKBOX_CATALOG_${i}`;
                    this.$refs[ref][0].checkStoreValue(value);
                }
                this.$refs.CHECKBOX_CATALOG_ALL.checkStoreValue(value);
                this.$refs.CHECKBOX_CATALOG_ALL_AFTER.checkStoreValue(value);
            },
            selectIndex (data) {
                if (data.checkValue === true) {
                    if (this.selectedRowsIndex.indexOf(data.index) === -1) {
                        this.selectedRowsIndex.push(data.index);
                        this.selectedLinksId.push(this.links[data.index]._id);
                    }
                } else {
                    this.selectedRowsIndex.splice(this.selectedRowsIndex.indexOf(data.index), 1);
                }
                if (this.selectedRowsIndex.length > 1) {
                    this.multiSelectDocuments = true;
                    this.multiSelectDocumentsAfterSelect = true;
                } else {
                    this.multiSelectDocuments = false;
                    this.multiSelectDocumentsAfterSelect = false;
                }

                this.links[data.index].selectedItem = data.checkValue;

            },
            checkLink(item) {
                if(item.status !== 'ACTIVE' && !item.active) {
                    return false;
                }

                return true;
            },
            async restartFilter() {
                this.searchText = null;
                this.filters = {};
                this.filtersHeader = false;
                await this.getList();
            },
            showDropdown () {
                document.getElementById("menuOverflowFix").classList.add('dropdown-table-overflow');
            },
            hideDropdown () {
                document.getElementById("menuOverflowFix").classList.remove('dropdown-table-overflow');
            },
            headerFilters () {
                this.filtersHeader = !this.filtersHeader;
            },
            async pageChange(evt) {
                this.page = evt;
                await this.getList();
            },
            async getList() {
                this.loading = true;
                const response = await PaymentLinksApi.list({id: this.currentOrganization,
                    perPage: this.limit, page: this.page, search: this.searchText, sort: this.sort, filters: this.filters});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.getLinks'));
                } else {
                    this.links = response.data.object.docs;
                    this.totalLinks = response.data.object.totalDocs;
                    this.page = response.data.object.page;
                }
                this.loading = false;
            },
            getClassStatus(status) {
                switch(status) {
                    case 'ACTIVE':
                        return 'badge-accent_2';
                    case 'DEACTIVE':
                        return 'badge-warning';
                    case 'PAID':
                        return 'badge-success';
                    case 'EXPIRED':
                        return 'badge-error';
                    default:
                        return 'badge-warning'
                }
            },
            getTextStatus(status) {
                switch(status) {
                    case 'ACTIVE':
                        return this.$i18n.t('paymentLinks.status.active');
                    case 'DEACTIVE':
                        return this.$i18n.t('paymentLinks.status.deactive');
                    case 'PAID':
                        return this.$i18n.t('paymentLinks.status.paid');
                    case 'EXPIRED':
                        return this.$i18n.t('paymentLinks.status.expired');
                    default:
                        return this.$i18n.t('paymentLinks.status.deactive');
                }
            },
            changeListLimit(limit) {
                clearTimeout(this.timeoutLimit);
                this.timeoutLimit = setTimeout(async () => {
                    this.limit = limit;
                    if (limit <= 0) {
                        this.displayNotificationWarning(this.$i18n.t('general.list-limit-warn'));
                        // this.$store.commit(`${PaymentTemplateStore}/${SET_PER_PAGE}`, 10)
                        // this.$store.dispatch(`${PaymentTemplateStore}/listPaymentsTemplate`)
                    }
                    await this.getList();
                }, 1500);
            },
            searchTextChange(searchText) {
                clearTimeout(this.timeoutSearch);
                this.timeoutSearch = setTimeout(async () => {
                    this.searchText = searchText;
                    await this.getList();
                }, 1500);
            },
            getAvailability(expDate) {
                if(expDate) {
                    return `${moment(expDate.startDate).format('DD/MM/YYYY')} - ${moment(expDate.endDate).format('DD/MM/YYYY')}`;
                }

                return 'Indefinida';
            },
            async activeDeactiveLink(link) {
                const response = await PaymentLinksApi.activeDeactive({id: link._id});

                if(response.data.error) {
                    if(link.active) {
                        this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.deactive'));
                    } else {
                        this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.active'));

                    }
                } else {
                    if(link.active) {
                        this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.deactive'));
                    } else {
                        this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.active'));

                    }
                }

                await this.getList();
            },
            showModalDeleteLink(link) {
                this.$bvModal.show('modal-deleteLink');
                this.linkToDelete = link;
            },
            showModalShare(link) {
                this.linkToShare = link;
                this.$bvModal.show('modal-comparte');
            },
            async shareLink() {
                const response = await PaymentLinksApi.share({id: this.linkToShare._id, smsArray: this.smsArray, emailArray: this.emailArray});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.share'));
                } else {
                    this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.share'));
                    await this.getList();
                }
                this.linkToShare.link = null;
                this.$bvModal.hide('modal-comparte');
            },
            async deleteLink() {
                const response = await PaymentLinksApi.delete({id: this.linkToDelete._id});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.deleteLink'));
                } else {
                    this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.deleteLink'));
                    await this.getList();
                }
                this.linkToDelete = {
                    concept: null
                };
                this.$bvModal.hide('modal-deleteLink');
            },
            addSMS() {
                if(utils.verifyValidPhone(this.sms)) {
                    this.smsArray.push(this.sms);
                } else if(utils.verifyValidPhoneWithoutLada(this.sms)) {
                    this.smsArray.push(`+52${this.sms}`);
                } else {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.phone'));
                }
                this.sms = null;
            },
            addEmail() {
                if(utils.verifyValidEmail(this.email)) {
                    this.emailArray.push(this.email);
                } else {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.email'));
                }

                this.email = null;
            },
            deleteSMS(index) {
                this.smsArray.splice(index, 1);
            },
            deleteEmail(index) {
                this.emailArray.splice(index, 1);
            },
            async copy() {
                try {
                    await navigator.clipboard.writeText(this.linkToShare.shortLink);
                    this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.copy'));
                } catch($e) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.copy'));
                }
            },
            goToNew() {
                this.$router.push({name: 'PaymentLinkNew'});
            },
            goToEdit(id) {
                this.$router.push({name: 'PaymentLinkNew', query: { id: id }});
            },
            goToDetail(id) {
                this.$router.push({name: 'PaymentLinkDetail', params: { id: id }});
            }
        },
        async mounted() {
            await this.getList();
        }
    };
</script>
