<template>
    <CardSimple
        :title="$t('pagos.requisitos.campos-referencias.title')"
        :subtitle="$t('pagos.requisitos.campos-referencias.subtitle')"
    >

        <div class="row m-b-20">
            <div class="col-12">
                <div>
                    <h1 class="m-0 c-info_text f-20 principal-font-medium">{{ $t('pagos.requisitos.campos-referencias.inputs.fields.title') }}</h1>
                </div>
                <div class="justify-content-end d-flex">
                </div>
            </div>
        </div>

        <div
            v-for="(field, index) in payment.fields"
            :key="index+'f'"
        >
            <div class="d-flex align-items-c m-b-30">
                <div class="w-100 p-r-30">
                    <BasicInput
                        clazz="m-b-0"
                        required
                        v-model="field.name"
                        :labelFor="index+'f'"
                        :inputId="index+'f'"
                        @input="changeField"
                        :label="$t('pagos.requisitos.campos-referencias.inputs.fields.subtitle')"
                        :inputPlaceholder="$t('pagos.requisitos.campos-referencias.inputs.fields.placeholder')"
                        :disabled="hasDebtors"
                    >
                    </BasicInput>

                </div>
                <button
                    class="button-circle button-ghost button-error sm f-20 m-t-15"
                    @click="deleteField(index)"
                >
                    <i aria-hidden="true" class="banana banana-delete"></i>
                </button>
            </div>
        </div>

        <a
            href
            class="button-square-add"
            tabindex
            @click="addField"
            v-if="!hasDebtors"
        >
            <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{ $t('pagos.requisitos.campos-referencias.add.fields') }}
        </a>

        <b-alert
            class="alert-note m-t-30"
            show
            variant="warning"
        >
            <p class="text-container">
                <img
                    class="img-fluid icon"
                    src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                    alt="warning"
                />
                {{ $t('pagos.requisitos.campos-referencias.warning') }}
            </p>
        </b-alert>

        <div class="row m-b-20">
            <div class="col-12">
                <div>
                    <h1 class="m-0 c-info_text f-20 principal-font-medium">{{ $t('pagos.requisitos.campos-referencias.inputs.references.title') }}</h1>
                </div>
                <div class=" justify-content-end d-flex">
                </div>
            </div>
        </div>

        <div
            v-for="(reference, index) in payment.references"
            :key="index+'r'"
        >
            <div class="d-flex align-items-c m-b-30">
                <div class="w-100 p-r-30">
                    <BasicInput
                        clazz="m-b-0"
                        required
                        v-model="reference.name"
                        @input="changeReference"
                        :labelFor="index+'r'"
                        :inputId="index+'r'"
                        :label="$t('pagos.requisitos.campos-referencias.inputs.references.subtitle')"
                        :inputPlaceholder="$t('pagos.requisitos.campos-referencias.inputs.fields.placeholder')"
                        :disabled="hasDebtors"
                    >
                    </BasicInput>

                </div>
                <button
                    class="button-circle button-ghost button-error sm f-20 m-t-15"
                    @click="deleteReference(index)"
                >
                    <i aria-hidden="true" class="banana banana-delete"></i>
                </button>
            </div>
        </div>

        <a
            href
            class="button-square-add"
            tabindex
            @click="addReference"
            v-if="!hasDebtors"
        >
            <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{ $t('pagos.requisitos.campos-referencias.add.references') }}
        </a>

        <div class="row m-t-40">
            <div class="col-12 vertical-center justify-content-end">
                <button
                    v-if="!editMode"
                    class="button button-ghost sm button-plain_text m-0-auto-0-0"
                    @click="prev"
                >
                    <i aria-hidden="true" class="banana banana-chevron-left"></i>
                    {{ $t('general.actions.back.title') }}
                </button>
                <div class="vertical-center">
                    <button
                        class="button button-ghost sm button-plain_text"
                        @click="cancel"
                    >
                        {{ $t('general.actions.cancel.title') }}
                    </button>

                    <button
                        v-if="!editMode"
                        class="button button-filled sm button-accent1 m-l-10"
                        :class="{disabled: !isValid}"
                        @click="next"
                    >
                        {{ $t('general.actions.next.title') }}
                    </button>
                    <button
                        v-if="editMode"
                        class="button button-filled sm button-accent1 m-l-10"
                        :class="{disabled: !isValid}"
                        @click="finish"
                    >
                        {{ $t('general.actions.save') }}
                    </button>

                </div>
            </div>
        </div>

    </CardSimple>
</template>

<script>
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from '@/components/CardSimple.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import DebtorsApi from '@/api/debt/debt.api.js';

export default {
    name: 'fields-references',
    components: {
        CardSimple,
        BasicInput,
    },
    props: {
        editMode: { type: Boolean, default: false },
        payment: {
            type: Object,
            default: function () {
                return {
                    fields: [],
                    references: []
                };
            }
        }
    },
    data () {
        return {
            hasDebtors: false,
        }
    },
    mounted () {
        if (this.payment && this.payment._id) {
            DebtorsApi.getPaymentsForDebt({
                _id: this.payment._id,
            }).then((debtsList) => {
                if (
                    debtsList.data.object &&
                    debtsList.data.object.debts &&
                    debtsList.data.object.debts.length > 0 &&
                    (this.payment.fields && this.payment.fields.length > 0)
                ){
                    this.hasDebtors = true;
                }
            }).catch((error) => {
                this.loading = false;
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t('general.actions.error.title'), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t('general.actions.error.title'), this.$t('general.actions.error.text'));
                }
            });
        }
    },
    methods: {
        cancel () {
            this.$emit('cancel');
        },
        next () {
            this.$emit('next');
        },
        prev () {
            this.$emit('prev');
        },
        finish () {
            this.$emit('finish');
        },
        deleteField (i) {
            this.payment.fields.splice(i, 1);
            this.changeField();
            this.$forceUpdate();
        },
        deleteReference (i) {
            this.payment.references.splice(i, 1);
            this.changeReference();
            this.$forceUpdate();
        },
        addField (e) {
            const newField = {
                id: '',
                name: ''
            };
            this.payment.fields.push(newField);
            e.preventDefault();
            this.changeField();
            this.$forceUpdate();
        },
        addReference (e) {
            const newField = {
                id: '',
                name: ''
            };
            this.payment.references.push(newField);
            e.preventDefault();
            this.changeReference();
            this.$forceUpdate();
        },
        changeField () {
            this.$forceUpdate();
        },
        changeReference () {
            this.$forceUpdate();
        }
    },
    computed: {
        isValid() {
            return this.payment.fields &&
                this.payment.fields.find(f => f.name && f.name.length > 0) &&
                this.payment.references &&
                this.payment.references.find(f => f.name && f.name.length > 0)
        }
    },
    validations: {
        payment: {
            fields: {
                $each: {
                    description: {
                        required,
                        minLength: minLength(2)
                    }
                }
            },
            references: {
                $each: {
                    description: {
                        required,
                        minLength: minLength(2)
                    }
                }
            }
        }
    }
}
</script>
