<template>
    <div class="full-page-content">
        <LoadingBar
                v-if="getLoadingTerms"
                :loadingText="$t('terms.loading')"
        ></LoadingBar>
        <div v-if="!getLoadingTerms" class="d-block">
            <vue-snotify></vue-snotify>
            <div class="top-bar-single-logo">
                <img class="img-fluid" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" alt="Pagando" />
            </div>

            <div class="max-w-920">
                <h1 class="title m-b-50">
                    {{$t('terms.title')}}
                    <strong>{{$t('terms.title-strong')}}</strong>
                </h1>

                <span class="date-update">
                  <small>{{$t('terms.last-update')}}</small>
                  {{formatDate}}
                </span>
                <div class="text-container" v-if="getTerms !== undefined && getTerms.document !== undefined" v-html="getTerms.document.content">
                    <p class="m-b-0">
                        CONTRATO DE SERVICIO DE FONDOS DE PAGO ELECTRÓNICO (EL
                        <strong>“CONTRATO”</strong>), QUE CELEBRAN POR UNA PARTE
                        <strong>“XC NETWORKS, S.A. DE C.V.”</strong> REPRESENTADA EN ESTE ACTO POR
                        <strong>C. HUGO BENITO NAVA BERNAL</strong> (EN LO SUCESIVO “PAGANDO”) Y POR LA OTRA PARTE BAZZARI REPRESENTADA EN ESTE ACTO POR EL
                        <strong>C. MIGUEL GÓMEZ HERNÁNDEZ</strong> (EN LO SUCESIVO EL “USUARIO”) QUIENES EN CONJUNTO SERÁN REFERIDAS COMO
                        <strong>“LAS PARTES”</strong>, LAS CUALES SE OBLIGAN AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:
                        <br />
                        <br />
                        <u>
                            <strong>DECLARACIONES</strong>
                        </u>
                        <br />
                        <br />
                        <strong>I. Declara Pagando, por conducto de su apoderado:</strong>
                        <br />
                        <br />a. Que su representada es una sociedad debidamente constituida y existente de conformidad
                         con las leyes de los Estados Unidos Mexicanos según se hace constar en la escritura pública número 51,
                         otorgada el 11 de junio de 1997, ante la fe del Licenciado Oscar Cayetano Becerra Tucker,
                         titular de la Notaría Pública número 28 del Distrito de Bravos, Ciudad Juárez, Chihuahua,
                         misma que quedó inscrita en el Registro Público de la Propiedad y del Comercio el 21 de julio de 1997,
                         bajo el número 71 a folios 117, del libro primero, volumen 24.
                        <br />
                        <br />b. Que sus facultades se desprenden de la escritura pública número 16,757 de fecha 30 de enero de 2019, otorgada
                        ante la fe de la Lic. Josefina Sosa Ramírez, notario público número 32 de Ciudad Juárez, Estado de Chihuahua, e inscrita
                        en el registro público de comercio de Ciudad Juárez bajo el folio mercantil electrónico número 9386 de fecha 20 de febrero de 2019,
                        mismas que no le han sido limitadas ni revocadas en forma alguna.atos personales facilitados en el formulario mencionado y todos los datos
                        que se generen en relación con su participación o uso de los diferentes eventos que se ofrecen en esta página web.
                        <br />
                        <br />
                    </p>
                </div>
                <BasicCheckbox
                        v-if="getTerms._id"
                        checkId="chk-acceptTerms"
                        ref="CHECKBOX_ACCEPT_CONTRACT"
                        :text="$t('terms.checkbox-accept')"
                        :currentValue="acceptTermsAndConditions"
                        @change="checkAcceptTerms"
                ></BasicCheckbox>

                <div class="vertical-center justify-content-end m-t-40" v-if="getTerms._id">
                    <a
                            id="lnk-printTerms"
                            :href="pathFileToTermsFile"
                            class="button xs button-stroke button-accent1"
                            type="button"
                    >
                        <i aria-hidden="true" class="banana banana-document-normal"></i>
                        {{$t('general.actions.print.title')}}
                    </a>
                    <button
                            id="btn-acceptTerms"
                            v-if="acceptTermsAndConditions && !isAcceptingTerms"
                            @click="acceptPreTerms"
                            class="button xs button-filled button-accent1 m-l-40"
                            type="button"
                    >{{$t('general.actions.finish.title')}}</button>
                    <button
                            id
                            v-if="isAcceptingTerms"
                            class="button xs button-filled button-accent1 m-l-40"
                            type="button"
                    >
                        <LoadingSimple clazz="small-load no-text" />
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    // @ is an alias to /src
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import LoadingSimple from "@/components/LoadingSimple.vue";
    import utils from "@/common/utils";
    import { mapGetters } from "vuex";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";

    const storeModule = "users";

    export default {
        name: "AcceptTerms",
        components: {
            BasicCheckbox,
            LoadingBar,
            LoadingSimple
        },
        props: {
            contractHidden: { type: Boolean, default: false }
        },
        mixins: [ToastSnotify],
        data() {
            return {
                acceptTermsAndConditions: false,
                isAcceptingTerms: false
            };
        },
        methods: {
            back() {
                this.$router.back();
            },
            checkAcceptTerms(value) {
                this.acceptTermsAndConditions = value;
            },
            getPathFile(file) {
                return utils.fileDownloadLinkSigned(file);
            },
            async acceptPreTerms() {
                this.isAcceptingTerms = true;
                const response = await this.$store.dispatch(`${storeModule}/acceptPreTerms`);
                 if (response.data.error) {
                     this.displayNotificationError(response.data.message);
                     this.isAcceptingTerms = false;
                 } else {
                     this.displayNotificationSuccess(response.data.message);
                     this.$router.push('/client-admin/dashboard');
                 }
            },
        },
        created() {

         },
        async mounted() {
            this.$store.commit(`${storeModule}/SET_LOADING_TERMS`, true);
            await this.$store.dispatch(`${storeModule}/loadTerms`).then((result) => {
                if (result.data.error) {
                    if (result.data.message) {
                        this.displayNotificationError(result.data.message);
                    } else {
                        this.displayNotificationError(this.errorMessageDefault);
                    }
                }
            });
        },
        computed: {
            ...mapGetters(storeModule, [
                "getTerms",
                "getLoadingTerms"
            ]),
            pathFileToTermsFile() {
                if (this.getTerms !== undefined && this.getTerms.documentPDF) {
                    return utils.fileDownloadLinkSigned(this.getTerms.documentPDF);
                }
                return "#";
            },
            formatDate() {
                if (this.getTerms !== undefined) {
                    return utils.toFormatDate(
                        this.getTerms.updatedAt,
                        "DD/MM/YYYY"
                    );
                }
                return utils.toFormatDate(new Date(), "DD/MM/YYYY");
            }
        }
    };
</script>
