<template>
    <div class="container-right">
        <TitlePrincipal
            :title="$t('hire.processing.title')"
            clazz="text-center"
        >
            <p
                class="subtitle"
                v-html="$t('hire.processing.subtitle-html')"
            ></p>
        </TitlePrincipal>
        <img class="img-fluid max-h-300px d-flex m-0-auto"
            src="@/assets/images/Illustrations/illustration-procesando.svg"
            width="520"
            height="300"
            alt="Procesando tu solicitud"/>
        <span>
            <label
                class="f-14 c-text_title poppins-font-medium f-500 text-center d-block m-t-20 m-b-5"
                v-text="$t('hire.processing.current-status')"
            ></label>
            <div class="vertical-center justify-content-center">
                <p
                    class="f-24 c-primary poppins-font-bold f-700 text-center m-b-0"
                    v-text="$t('hire.processing.status-pending')"
                ></p>
                <span class="loading-note no-cpu"></span>
            </div>
        </span>

        <div class="footer-buttons center">
            <button
                type="button"
                class="button sm button-filled button-primary"
                name="Ir a Pagando Check"
                @click="goToCheck"
            >
                <i aria-hidden="true" class="banana banana-arrow-right m-r-10"></i>
                {{$t('hire.processing.go-to-check')}}
            </button>
        </div>
        <LoadingBar
            clazz="fullcontent-loading"
            v-if="loading"
        />
    </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import OrganizationApi from "@/api/organization/organizations.api.js";
import {STORE as SignInStore} from '@/store/modules/signin/signin.types';
import {
    STORE as SessionStore,
    GET_USER
} from '@/store/modules/session/session.types';
import {mapGetters} from 'vuex';

export default {
    name: "WalletHireProcessing",
    components: {
        TitlePrincipal,
        LoadingBar,
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters(SessionStore, {
            user: GET_USER
        })
    },
    methods: {
        async goToCheck() {
            try {
                this.loading = true;
                await OrganizationApi.hireFinish();
                const _session = this.$session;
                const credentials = {
                    email: this.user.email,
                    password: this.password
                };
                await this.$store.dispatch(`${SignInStore}/login`, {credentials, _session});
                this.$router.push({name: 'dashboard'});
            } catch (error) {
                console.error(error);
                this.toastErrorDefault(error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
