<template>
    <div
        class="form-group"
        :class="`${clazz} ${errorClass}`"
    >
        <div
            id="form-inputPhone"
            @click="activeLabel($event)"
            v-if="!refresh"
        >
            <vue-tel-input
                v-model="inputValue"
                :inputId="idInput"
                :defaultCountry="defaultCountry"
                enabledCountryCode
                @country-changed="countryChanged"
                inputClasses="form-control"
                :placeholder="inputPlaceholder"
            />
        </div>

        <div
            v-if="badge"
            class="badge"
            :class="badgeType"
        >{{badgeText}}</div>

        <div
            class="input-label"
            :class="{ 'active': labelActive }"
        >
            <label
                class=""
                :for="labelFor"
            >{{label}} <span v-if="required">*</span></label>
            <div
                v-if="help"
                :id="idHelpTooltip"
                class="help-info"
            >
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i aria-hidden="true" v-bind:class="warningIcon || warningIconComputed"></i>
            {{warningText || warningTextComputed}}
        </small>
    </div>
</template>

<script>

export default {
    name: 'TelInput',
    props: {
        clazz: String,
        labelFor: String,
        label: String,
        required: { type: Boolean, default: false },
        help: { type: Boolean, default: false },
        helpText: String,
        helpIcon: String,
        idHelpTooltip: String,
        idInput: String,
        inputPlaceholder: String,
        badge: { type: Boolean, default: false },
        badgeType: String,
        badgeText: String,
        warningIcon: String,
        warningText: String,
        verified: { type: Boolean, default: false },
        defaultCountry: String,
        openModal: { type: String },
        validations: {},
        validationsNamespace: {
            type: String,
            default: "general.validations"
        },
        value: {
            type: String,
            default: '',
        }
    },
    data () {
        return {
            labelActive: false,
            refresh: false,
            inputValue: '',
            currentCountry: {},
        }
    },
    methods: {
        countryChanged (value) {
            this.$emit('country-changed', value);
            this.currentCountry = value;
            this.inputValue = this.trimPhone(this.inputValue);
        },
        activeLabel: function () {
            this.labelActive = !this.labelActive;
        },
        changeActive (event) {
            const header = document.querySelector("#form-inputPhone");
            if (header) {
                const isClickInside = header.contains(event.target);

                if (!isClickInside) {
                    this.labelActive = false;
                } else {
                    this.labelActive = true;
                }
            }
        },
        trimPhone(phone) {
            let phoneSimple;

            if (phone.indexOf(this.fullCountryCode) === 0) {
                phoneSimple = phone.substring(this.fullCountryCode.length).replace(/[^0-9]/g, '');
            } else if (phone.indexOf(this.countryCode) === 0) {
                phoneSimple = phone.substring(this.countryCode.length).replace(/[^0-9]/g, '');
            } else {
                phoneSimple = phone.replace(/[^0-9]/g, '');
            }

            // Trim the mexican numbers to the 10 digits
            if (this.countryCode === '52') {
                phoneSimple = phoneSimple.substring(phoneSimple.length - 10);
            }

            return phoneSimple;
        }
    },
    created () {
        window.addEventListener('click', this.changeActive);
    },
    beforeMount() {
        if (this.value && this.value.length > 0) {
            this.inputValue = this.trimPhone(this.value);
        }
    },
    watch: {
        'defaultCountry': {
            handler() {
                this.refresh = !this.refresh;
                this.$nextTick(() => {
                    this.refresh = !this.refresh;
                });
            },
        },
        'value': {
            handler() {
                if ((!this.inputValue || this.inputValue.length === 0) && this.value && this.value.length > 0) {
                    this.inputValue = this.trimPhone(this.value);
                }
            }
        },
        'inputValue': {
            handler() {
                const fullPhone = `${this.fullCountryCode}${this.trimPhone(this.inputValue)}`;
                this.$emit('input', fullPhone);
            }
        }
    },
    computed: {
        fullCountryCode () {
            return this.countryCode && this.countryCode.length > 0 ? `+${this.countryCode}` : '';
        },
        countryCode () {
            return this.currentCountry.dialCode ? this.currentCountry.dialCode : '';
        },
        errorClass () {
            return this.validations && this.validations.$error ? 'has-error' : '';
        },
        warningTextComputed () {
            if (this.validations) {
                const errorKey = Object
                    .keys(this.validations)
                    .find(k => this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
                if (errorKey) {
                    const params = this.validations.$params[errorKey];
                    return this.$t(`${this.validationsNamespace}${this.validationsNamespace ? '.' : ''}${errorKey}`, params);
                }
                return null;
            }
            return null;
        },
        warningIconComputed () {
            if (this.validations) {
                const errorKey = Object
                    .keys(this.validations)
                    .find(k => this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
                return errorKey ? 'banana banana-warning-circle1' : null;
            }
            return null;

        }
    }
}
</script>
