<template>
    <div
        class="panel-pay-avg"
        v-bind:class="clazz"
    >
        <div>
            <h1 class="title"> {{title}} </h1>
            <p
                class="total"
                v-resize-text="{ ratio:1, minFontSize: '12px', maxFontSize: '38px', delay: 100 }"
            > {{total | currency}} </p>
            <label class="text-avg"> {{textAvg}} </label>
            <div class="bottom">
                <small> {{totalAvg | currency}} </small>
                <small v-if="numAvg === 0 || numAvg === '0'"><i
                        aria-hidden="true"
                        class="banana banana-triangle-right"
                    ></i> {{numAvg}} % </small>
                <small
                    v-else
                    :class="[errorAvg ? 'error' : 'success']"
                ><i
                        aria-hidden="true"
                        class="banana"
                        :class="[errorAvg ? 'banana-triangle-down' : 'banana-triangle-up']"
                    ></i> {{numAvg}} % </small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PanelMoneyAvg',
    props: {
        clazz: String,
        title: String,
        total: Number,
        textAvg: String,
        totalAvg: Number,
        numAvg: String,
        errorAvg: { type: Boolean, default: false }
    }
}
</script>

<style lang="scss">
.panel-pay-avg {
    border-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(20, 34, 102, 0.15);
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: 100%;

    > div {
        display: block;
        width: 100%;

        .title {
            font-size: 16px;
            white-space: nowrap;
            font-weight: bold;
            line-height: 1.25;
            text-align: center;
            display: block;
            margin-bottom: 10px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        .total {
            white-space: nowrap;
            font-weight: bold;
            line-height: 1.25;
            text-align: center;
            display: block;
            margin-bottom: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            transition: 0.5s;
        }

        .text-avg {
            font-size: 14px;
            white-space: nowrap;
            font-weight: normal;
            line-height: 1.21;
            text-align: center;
            display: block;
            margin-bottom: 2px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        .bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 200px;
            margin: 0 auto;

            > small {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.21;
                display: flex;

                i {
                    display: inline-flex;
                    align-items: center;
                    font-size: 18px;
                    width: 18px;
                    height: 10px;
                }

                &:first-of-type {
                    margin: 0 auto 0 0;
                }

                &:last-of-type {
                    margin: 0 0 0 auto;
                }
            }
        }
    }
}
</style>
