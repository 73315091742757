<template>
    <div class="form-group" :class="clazz">
        <div id="form-file" v-on:click="activeLabel($event)">
            <slot></slot>
            <button v-if="downloadFile" @click="doFileDownload" type="" class="download-file-button" v-b-tooltip.hover
                    title="Descargar archivo"><i aria-hidden="true" class="banana banana-download"></i></button>
        </div>

        <div class="input-label" :class="{ 'active': labelActive }">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i aria-hidden="true" :class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>
</template>

<script>
    export default {
        name: 'InputFile',
        props: {
            clazz: String,
            required: {type: Boolean, default: false},
            label: String,
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            helpText: String,
            helpIcon: String,
            warningText: String,
            warningIcon: String,
            labelFor: String,
            downloadFile: {type: [Boolean, String], default: false}
        },
        data() {
            return {
                labelActive: false
            }
        },
        methods: {
            activeLabel: function () {
                this.labelActive = !this.labelActive;
            },
            changeActive(event) {
                const header = document.querySelector("#form-file");
                if (header) {
                    const isClickInside = header.contains(event.target);

                    this.labelActive = isClickInside;
                }
            },
            doFileDownload() {
                if (this.downloadFile) {
                    window.location = this.downloadFile;
                }
            }
        },
        created() {
            window.addEventListener('click', this.changeActive);
        }
    }
</script>
