export const GET_DOCS = "GET_DOCS";
export const GET_DOCS_LENGTH = "GET_DOCS_LENGTH";
export const GET_OBJECT_PAGE = "GET_OBJECT_PAGE";
export const GET_SEARCH_TEXT = "GET_SEARCH_TEXT";
export const GET_SELECTED_ITEM = "GET_SELECTED_ITEM";
export const GET_LOADING_ITEMS = "GET_LOADING_ITEMS";
export const GET_CURRENT_PAGE = "GET_CURRENT_PAGE";
export const GET_ERROR_LOADING = "GET_ERROR_LOADING";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_DOCS = "SET_DOCS";
export const SET_DOCS_LENGTH = "SET_DOCS_LENGTH";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
export const SET_SELECTED_ITEM_VALUE = "SET_SELECTED_ITEM_VALUE";
export const SET_LOADING_ITEMS = "SET_LOADING_ITEMS";
export const SET_ERROR_LOADING = "SET_LOADINSET_ERROR_LOADINGG_ITEMS";
export const SET_PER_PAGE = "SET_PER_PAGE";
export const SET_SELECTED_ITEM_VALUE_ON_INDEX = "SET_SELECTED_ITEM_VALUE_ON_INDEX";
export const REMOVE_FROM_SELECTED_IDS = "REMOVE_FROM_SELECTED_IDS";
export const SET_ADD_TO_SELECTED_IDS = "SET_ADD_TO_SELECTED_IDS";
export const GET_SELECTED_IDS = "GET_SELECTED_IDS";
export const SET_FILTERS = "SET_FILTERS";
export const GET_FILTERS = "GET_FILTERS";
export const SET_SORT = "SET_SORT";
export const SET_TYPE_SORT = "SET_TYPE_SORT";
export const GET_SORT = "GET_SORT";
export const GET_TYPE_SORT = "GET_TYPE_SORT";
export const GET_FATHER_FILTER = "GET_FATHER_FILTER";
export const SET_FATHER_FILTER = "SET_FATHER_FILTER";
export const GET_FILTERS_COUNT = "GET_FILTERS_COUNT";
