<template>
    <div>
        <SignInBody :rightSide="getRightSide" hideRightWizard>
            <template v-slot:formSectionBody>
                <router-view/>
            </template>
        </SignInBody>

        <!-- Modal for showing 'info-section' on responsive -->
        <b-modal id="modal-info-responsive" hide-header hide-footer>
            <button id="" class="button-circle sm button-stroke button-info_text m-0-0-0-auto"
                    @click="$bvModal.hide('modal-info-responsive')" type="button"><i aria-hidden="true"
                    class="banana banana-cross-normal"></i></button>
            <SignInBody :rightSide="getRightSide" hideRightWizard />
        </b-modal>
    </div>
</template>

<script>
    // @ is an alias to /src
    import SignInBody from '@/components/SignInBody.vue'
    import {mapGetters} from 'vuex';
    import {
        STORE as UserSignUpStore
    } from '@/store/modules/signin/signin.types'


    export default {
        name: "SignIn",
        props: {
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false}
        },
        components: {
            SignInBody
        },
        computed: {
            ...mapGetters(UserSignUpStore, ['getRightSide'])
        }
    };
</script>

