<template lang="html">

  <CardSimple
    title="Pago Web Service"
  >
    <BasicInput
      required
      v-model="payment.name"
      labelFor="name"
      inputId="name"
      :label="$t('pagos.general.servicio.name.title')"
      :inputPlaceholder="$t('pagos.general.servicio.name.placeholder')"
    >
    </BasicInput>
                  <BasicTextarea
                  v-model="payment.description"
            labelFor="examplet2"
            textareaId="examplet2"
            label="Descripción del servicio"
            textareaPlaceholder="Escribe de manera breve de qué trata tu servicio y qué es lo que ofrece…"
          >
          </BasicTextarea>

           <div class="row">
            <div class="col-md-6 col-12">
              <BasicSelect
              v-model="payment.status"
                required
                label="Estatus"
                labelFor
                help
                idHelpTooltip="ejGenerico4"
                helpText="  ¿Cuál elegir?"
                helpIcon="banana banana-question-circle1"
              >
                <template v-slot:tooltipHelp>
                  <b-tooltip
                    target="ejGenerico4"
                    placement="top"
                    custom-class="white-tooltip"
                  >
                    <div>
                      <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </b-tooltip>
                </template>

                <multiselect
                  v-model="value"
                  :options="statusOptions"
                  :close-on-select="true"
                  :preserve-search="true"
                  placeholder="Elige cómo quieres publicar tu servicio"
                  label="name"
                  track-by="name"
                  :preselect-first="false"
                >
                  <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                  >
                    <span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span>
                  </template>
                </multiselect>
              </BasicSelect>

            </div>
            <div class="col-md-6 col-12">
              <BasicSelect
              v-model="payment.currency"
                required
                label="Divisa"
                labelFor
                help
                idHelpTooltip="ejGenerico5"
                helpText="¿Qué es?"
                helpIcon="banana banana-question-circle1"
              >
                <template v-slot:tooltipHelp>
                  <b-tooltip
                    target="ejGenerico5"
                    placement="top"
                    custom-class="white-tooltip"
                  >
                    <div>
                      <p class="m-b-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </b-tooltip>
                </template>

                <multiselect
                  v-model="value"
                  :options="currencies"
                  :close-on-select="true"
                  :preserve-search="true"
                  placeholder="Selecciona con cuál divisa quieres cobrar"
                  label="name"
                  track-by="name"
                  :preselect-first="false"
                >
                  <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                  >
                    <span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span>
                  </template>
                </multiselect>
              </BasicSelect>

            </div>
          </div>


    <div class="row m-b-10">
      <div class="col-md-6 col-6">
        <h1 class="f-14 c-title body-font-regular m-0">{{ $t('pagos.general.servicio.image.title') }} <span class="c-error">*</span></h1>
      </div>
      <div class="col-md-6 col-6">
        <div class=" justify-content-end d-flex">
        </div>
      </div>
    </div>
              <b-alert
            class="alert-note"
            show
            variant="warning"
          >
            <p class="text-container">
              <img
                class="img-fluid"
                src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                alt="info"
              />
              Para que tus clientes puedan visualizar correctamente tu servicio es necesario que cargues al menos una imagen con las características especificadas.
            </p>
          </b-alert>
    <UploadArea
        clazz="m-b-30"
        clazzArea="col-6"
        @change="fileSelected"
    >
      <SimpleNote clazz="info m-b-0 m-l-30">
        <p class="m-b-0" v-html="$t('pagos.general.servicio.image.subtitle', { 'interpolation': {'escapeValue': false} })"></p>
      </SimpleNote>
    </UploadArea>

    <div class="vertical-upload-list">
      <ListUploadArea v-for="(img, index) in payment.images" v-bind:key="img._id" @clickDelete="deleteImage(img)">
        <UploadArea :imgUploaded="imageUrl(img)" hideButtonClose :numList="index + 1"></UploadArea>
      </ListUploadArea>
    </div>



     <div class="row">
      <div class="col-12">
        <h1 class="f-14 c-title body-font-regular m-0">{{ $t('pagos.general.servicio.category.title') }} <span class="c-error">*</span></h1>
      </div>
    </div>

    <SimpleCarousel :itemsCarousel="[[0, 2],[600, 3],[991, 4],[1230, 4]]" clazz="simpleCarousel-container" :startPage="startPage()">
      <slide v-for="category in categories" v-bind:key="category.name">
        <CardItem :clazz="categoryCarouselClass(category)" @click.native="changeCategory(category)">
          <div class="img-container">
            <img class="background" src="@/assets/images/Default/dots-grid.svg" :alt="category.name"/>
            <img class="icon" :src="category.icon" :alt="category.name"/>
          </div>
          <div class="text-container">
            <h1>{{$t(category.label)}}</h1>
          </div>
        </CardItem>
      </slide>
    </SimpleCarousel>

        <div class="m-b-30">
      <BasicSelect
        required
        :label="$t('pagos.general.servicio.tags.title')"
        labelFor="bannerNum"
      >
        <multiselect
          v-model="valueTags"
          :options="optionsTags"
          :placeholder="$t('pagos.general.servicio.tags.placeholder')"
          tag-placeholder="Agregar como nuevo tag"
          label="name"
          track-by="name"
          :preselect-first="false"
          class="select-up"
          :multiple="true"
          :taggable="true"
          @search-change="tagAdded"
        ></multiselect>
      </BasicSelect>
    </div>
<div>
      <button
        id
        class="collapse-btn"
        type="button"
        v-b-toggle.collapse-config
      >
        Configuración avanzada
        <i aria-hidden="true" class="banana banana-chevron-down"></i></button>
      <b-collapse
        id="collapse-config"
        class="m-t-40"
      >
        <slot name="advancedSettings"></slot>
      </b-collapse>
      <div class="row m-t-40">
        <div class="col-12 vertical-center justify-content-end">
          <button class="button button-ghost sm button-plain_text" @click="cancel">
            {{$t('general.actions.cancel.title')}}
          </button>

          <button v-if="!editMode && blackMagic" class="button button-filled sm button-accent1 m-l-10" :class="{disabled: !isValid()}" @click="next">
            {{$t('general.actions.next.title')}}
          </button>

          <button v-if="editMode && blackMagic" class="button button-filled sm button-accent1 m-l-10" :class="{disabled: !isValid()}" @click="finish">
            {{$t('general.actions.save')}}
          </button>
        </div>
      </div>
    </div>
  </CardSimple>

</template>

<script lang="js">
    import BasicInput from "@/components/BasicInput.vue";
    import CardSimple from '@/components/CardSimple.vue';
    import CardItem from "@/components/CardItem.vue";
    import BasicSelect from "@/components/BasicSelect.vue";
    import multiselect from "vue-multiselect";
    import UploadArea from '@/components/UploadArea.vue';
    import SimpleNote from "@/components/SimpleNote.vue";
    import ListUploadArea from '@/components/ListUploadArea.vue';
    import SimpleCarousel from "@/components/SimpleCarousel.vue";
    import CurrenciesApi from '@/api/currencies/currency.api';
    import ImageApi from '@/api/images/image.api';
    import {utils} from '@/api/utils.js';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {values as Categories} from '@/common/consts/categories.consts';
    import BasicTextarea from "@/components/BasicTextarea.vue";
    import axios from 'axios';

    const PAYMENT_INACTIVE_STATUS_KEY = 'pagos.general.servicio.status.inactive';
    const PAYMENT_ACTIVE_STATUS_KEY = 'pagos.general.servicio.status.active';
    const ERROR_TITLE_KEY = 'general.actions.error.title';
    const ERROR_TEXT_KEY = 'general.actions.error.text';
    const PAYMENT_COMING_STATUS_KEY = 'pagos.general.servicio.status.comingSoon';
    const TEXT_ALIGN_C_CLASS = "text-align-c";

  export default {
    name: 'general',
    mixins: [ToastSnotify],
    props: {
        editMode: {type: Boolean, default: false},
        slotValid: {type: Boolean, default: true},
        payment: {
            type: Object,
            default: function () {
                return {
                    name: '',
                    body: '',
                    url_final: '',
                    content_type: '',
                    url: '',
                    method: '',
                    organization: '',
                    externalIdentifier: '',
                    additionalNotesConfig: {
                        isEnabled: false,
                        isRequired: false,
                        label: '',
                        placeholder: ''
                    },
                    clasification: '',
                    testedService: true,
                    params: [],
                    type: 'WS'
                }
            }
        }
    },
    components: {
        CardSimple,
        CardItem,
        BasicInput,
        BasicSelect,
        multiselect,
        UploadArea,
        SimpleNote,
        ListUploadArea,
        SimpleCarousel,
        BasicTextarea
    },
    mounted () {
        CurrenciesApi.get().then((data) => {
            this.currencies = data.data.object;
            this.setCurrencyFromPayment();
            const inLocalStorage = JSON.parse(localStorage.getItem('valueTags') || '[]');
            this.optionsTags = inLocalStorage;

            this.setStatusFromPayment();
            this.setTagsFromPayment();
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.message) {
              this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
            } else {
              this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
            }
        });
    },
    watch: {
        "payment.allowMultiplication": function (newValue) {
            if(!newValue) {
                this.payment.multiplication = 1;
            }
        },
        "payment.allowClientAdditionalNotes": function (newValue) {
            if(!newValue) {
                this.payment.additionalNotesConfig.title = '';
                this.payment.additionalNotesConfig.instructions = '';
            }
        },
        "payment.activeService": function() {
            this.setStatusFromPayment();
        },
        "payment.tags": function() {
            this.setTagsFromPayment();
        },
        "payment.sandBoxMode": function() {
            this.setStatusFromPayment();
        },
        "payment.comingSoon": function() {
            this.setStatusFromPayment();
        },
        "payment.currency": function() {
            this.setCurrencyFromPayment();
        },
        "currency": function (newValue) {
            this.payment.currency = newValue._id;
            this.payment.currencyObject = newValue; // Valor dummy para poder obtener en otros componentes el código de divisa
        },
        "status": function (newValue) {
            if (newValue && newValue.name) {
                switch(newValue.name) {
                    case this.$t(PAYMENT_ACTIVE_STATUS_KEY):
                        this.$set(this.payment, 'activeService', true);
                        this.$set(this.payment, 'comingSoon', false);
                        this.$set(this.payment, 'sanBoxMode', false);
                        break;
                    case this.$t(PAYMENT_INACTIVE_STATUS_KEY):
                        this.$set(this.payment, 'activeService', false);
                        this.$set(this.payment, 'comingSoon', false);
                        this.$set(this.payment, 'sanBoxMode', true);
                        break;
                    case this.$t(PAYMENT_COMING_STATUS_KEY):
                    default:
                        this.$set(this.payment, 'activeService', false);
                        this.$set(this.payment, 'comingSoon', true);
                        this.$set(this.payment, 'sanBoxMode', true);
                        break;
                }
            }
            this.$forceUpdate();
        },
        "valueTags": function(newValue) {
            let inLocalStorage = JSON.parse(localStorage.getItem('valueTags') || '[]');
            const newTags = newValue.filter(n => !inLocalStorage.find(l => l.name === n.name));
            inLocalStorage = inLocalStorage.concat(newTags);
            this.optionsTags = inLocalStorage;
            localStorage.setItem('valueTags', JSON.stringify(inLocalStorage));
            this.payment.tags = newValue.map(t => t.name);
            this.$forceUpdate();
      }
    },
    data () {
      return {
        params: '',
        // Date Range Picker
        blackMagic: true,
        currency: '',
        opens: 'left',
        showDropdowns: true,
        autoApply: true,
        dateRange: [],
        valid: false,
        checkedSwitch: false,
        value: [],
        status: this.$t(PAYMENT_ACTIVE_STATUS_KEY),
        statusOptions: [
          { name: this.$t(PAYMENT_ACTIVE_STATUS_KEY) },
          { name: this.$t(PAYMENT_INACTIVE_STATUS_KEY) },
          { name: this.$t(PAYMENT_COMING_STATUS_KEY) }
        ],
        categories: Categories,
        currencies: [],
        typeSelect: [
          { name: 'Cantidad' },
          { name: 'Porcentaje' }
        ],
        valueTags: [],
        optionsTags: [],
        searchTag: '',
        fields: [
          {
            key: "nombre",
            label: "Nombre"
          },
          {
            key: "cantidad",
            label: "Cantidad",
            class: TEXT_ALIGN_C_CLASS
          },
          {
            key: "precioUnitario",
            label: "Precio Unitario",
            class: "text-align-r"
          },
          {
            key: "subtotal",
            label: "Subtotal",
            class: "text-align-r f-bold w-150px"
          }
        ],
        items: [
          {
            nombre: "Tijeras para cortar césped",
            cantidad: "14",
            // eslint-disable-next-line sonarjs/no-duplicate-string
            precioUnitario: "$ 450.00 MXN",
            // eslint-disable-next-line sonarjs/no-duplicate-string
            subtotal: "$ 450.00 MX"
          },
          {
            nombre: "Tijeras para cortar el césped",
            cantidad: "14",
            precioUnitario: "$ 450.00 MXN",
            subtotal: "$ 450.00 MX"
          },
          {
            nombre: "Tijeras para cortar el césped",
            cantidad: "14",
            precioUnitario: "$ 450.00 MXN",
            subtotal: "$ 450.00 MX"
          },
        ],
        fields2: [
          {
            key: "nombre",
            label: "Nombre"
          },
          {
            key: "inicio",
            label: "Inicio",
            class: TEXT_ALIGN_C_CLASS
          },
          {
            key: "fin",
            label: "Fin",
            class: TEXT_ALIGN_C_CLASS
          },
          {
            key: "estatus",
            label: "Estatus",
            class: "text-align-c w-85px"
          },
          {
            key: "descuento",
            label: "Descuento",
            class: "text-align-r f-bold c-success w-150px"
          }
        ],
        items2: [
          {
            nombre: "Buen fin",
            inicio: "10/11/19",
            fin: "15/11/19",
            descuento: "- 30%",
          },
          {
            nombre: "Buen fin",
            inicio: "10/11/19",
            fin: "-",
            descuento: "- $ 50.00 MXN",
          },
        ],
        fields3: [
          {
            key: "text",
            label: "",
            class: "text-align-r"
          },
          {
            key: "number",
            label: "",
            class: "text-align-r f-bold  w-150px"
          }
        ],
        items3: [
          {
            text: "Descuento Buen Fin",
            number: "- 30%",
          },
          {
            text: "Descuento Usuarios Pagando",
            number: "- $ 50.00 MXN",
          },
          {
            text: "Impuestos",
            number: "$ 0.00 MXN",
          },
          {
            text: "Comisión Pagando (Inc. IVA)",
            number: "$ 9.00 MXN",
          },
        ],
      }
    },
    methods: {
        saveWebPayment(payment){
            payment.params = [{name: "Fernando", description: "UUID"}];
            payment.activeService = this.status;
            payment.currency = this.currency;

            axios.post('http://localhost:3000/api-v2/payments-crud/WS', payment)
            .then(function(){})
            .catch(function(){})

        },
        setTagsFromPayment() {
            if (this.payment.tags && this.valueTags && this.payment.tags.length > 0 && this.valueTags.length === 0) {
                this.valueTags = this.payment.tags.map((t) => {return {name: t}});
            }
        },
        setCurrencyFromPayment() {
            if (this.payment.currency) {
                const currentCurrency = this.currencies.find(c => c._id === (this.payment.currency._id || this.payment.currency));
                if (currentCurrency) {
                    this.currency = currentCurrency;
                    this.payment.currencyObject = currentCurrency;
                    this.$forceUpdate();
                }
            }
        },
        setStatusFromPayment() {
            if (this.payment.activeService && !this.payment.comingSoon && !this.payment.sandBoxMode) {
                this.status = {name: this.$t(PAYMENT_ACTIVE_STATUS_KEY)};
            } else if (this.payment.comingSoon || this.payment.sandBoxMode) {
                this.status = {name: this.$t(PAYMENT_COMING_STATUS_KEY)};
            } else {
                this.status = {name: this.$t(PAYMENT_INACTIVE_STATUS_KEY)};
            }
        },
        cancel() {
            this.$emit('cancel');
        },
        next() {
            this.$emit('next');
        },
        finish() {
            this.$emit('finish');
        },
        currencyLabel(currency) {
            return `${currency.description} (${currency.code})`;
        },
        fileSelected(files) {
            if (!this.payment.images){
              this.payment.images = [];
            }
            ImageApi.upload(files[0], {temp: true}).then((data) => {
                this.payment.images.push(data.data.object._id);
                localStorage.setItem('editingPayment', JSON.stringify(this.payment));
                this.$forceUpdate();
              // eslint-disable-next-line sonarjs/no-identical-functions
            }).catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        imageUrl(img) {
            return utils.imageDownloadLink(img);
        },
        deleteImage(img) {
             ImageApi.delete(img).then(() => {
                const index = this.payment.images.indexOf(img);
                if (index >= 0) {
                    this.payment.images.splice(index, 1);
                    localStorage.setItem('editingPayment', JSON.stringify(this.payment));
                }
                this.$forceUpdate();
               // eslint-disable-next-line sonarjs/no-identical-functions
            }).catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        changeCategory(category) {
            this.payment.clasification = category.name;
            this.$forceUpdate();
        },
        categoryCarouselClass(category) {
            return `category-card ${category.name === this.payment.clasification ? 'selected' : ''}`;
        },
        tagAdded(query) {
            if (query.length > 0) {
                this.searchTag = query;
            } else if (this.searchTag.length > 0 && !this.valueTags.find(t => t.name === this.searchTag)) {
                let name = this.searchTag;
                if (name.indexOf('#') !== 0) {
                    name = `#${ name}`;
                }
                this.valueTags.push({name});
                this.searchTag = '';
            }
            this.$forceUpdate();
        },
        isValid() {
            if (!this.payment.name || this.payment.name.length <= 0) {
                return false;
            }
            if(!this.payment.description || this.payment.description.length <= 0) {
              return false;
            }
            if (!this.payment.images || this.payment.images.length <= 0) {
              return false;
            }
            if (!this.payment.clasification) {
              return false;
            }
            if (!this.payment.tags || this.payment.tags.length <= 0) {
              return false;
            }
            return this.slotValid;
        },
        startPage() {
            if (this.payment && this.payment.clasification) {
                const category = this.categories.find(c => c.name === this.payment.clasification);
                if (category){
                    const index = this.categories.indexOf(category);
                    if (index >= 0 && index < 4) {
                        return 0;
                    } else if (index >= 4 && index < 8) {
                        return 1;
                    } else if (index >= 8 && index < 12) {
                        return 2;
                    } else if (index >= 0 && index < 3) {
                        return 3;
                    }
                }
            }
            return 0;
        }
    },
}
</script>
