import img1 from '@/assets/images/Backgrounds/Security/img1.png';
import img2 from '@/assets/images/Backgrounds/Security/img2.png';
import img3 from '@/assets/images/Backgrounds/Security/img3.png';
import img4 from '@/assets/images/Backgrounds/Security/img4.png';
import img5 from '@/assets/images/Backgrounds/Security/img5.png';
import img6 from '@/assets/images/Backgrounds/Security/img6.png';
import img7 from '@/assets/images/Backgrounds/Security/img7.png';
import img8 from '@/assets/images/Backgrounds/Security/img8.png';
import img9 from '@/assets/images/Backgrounds/Security/img9.jpeg';

const securityImages = [
    {
        "id": "LAKE_HOME",
        "src": img1,
        "selected": false
    },
    {
        "id": "TOUCAN",
        "src": img2,
        "selected": false
    },
    {
        "id": "BLACK_CAR",
        "src": img3,
        "selected": false
    },
    {
        "id": "COUP_GLASS",
        "src": img4,
        "selected": false
    },
    {
        "id": "SHOW_HOME",
        "src": img5,
        "selected": false
    },
    {
        "id": "CASSET_GUARDIANS",
        "src": img6,
        "selected": false
    },
    {
        "id": "NATURE_WATCH",
        "src": img7,
        "selected": false
    },
    {
        "id": "BEAUTIFUL_DOG",
        "src": img8,
        "selected": false
    },
    {
        "id": "RUBIK_CUBE",
        "src": img9,
        "selected": false
    }
];

export {
    securityImages
}
