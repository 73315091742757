<template>
  <div>
    <vue-snotify></vue-snotify>
    <WizardFormOrganizations activeVertical clazz="full-view form-wizard-white">
      <template v-slot:wizardView>
        <tab-content title="Paso 1" icon="one">
          <StepUserRegistration></StepUserRegistration>
        </tab-content>
        <tab-content title="Paso 2" icon="two">
          <StepOnboarding v-if="currentUserLayout._id !== undefined && getOnboardingFlag"></StepOnboarding>
          <StepStart v-if="currentUserLayout._id !== undefined && !getOnboardingFlag"></StepStart>
        </tab-content>

        <tab-content title="Paso 3" icon="three">
          <StepOperationScheme v-if="currentUserLayout._id !== undefined"></StepOperationScheme>
        </tab-content>

        <tab-content title="Paso 4" icon="four" >
          <StepLogo v-if="getIsLoadActualOrganization && currentUserLayout._id !== undefined"></StepLogo>
        </tab-content>

        <tab-content title="Paso 5" icon="five">
          <StepBanking v-if="currentUserLayout._id !== undefined"></StepBanking>
        </tab-content>

        <tab-content title="Paso 6" icon="six" >
          <StepRequestedDocuments v-if="currentUserLayout._id !== undefined"></StepRequestedDocuments>
        </tab-content>
        <tab-content title="Paso 7" icon="seven">
          <StepLocation v-if="currentUserLayout._id !== undefined"></StepLocation>
        </tab-content>
        <tab-content title="Paso 8" icon="eight">
          <StepSummary></StepSummary>
        </tab-content>
      </template>
    </WizardFormOrganizations>
    <!-- Modal for showing 'info-section' on responsive -->
    <b-modal id="modal-info-responsive" hide-header hide-footer>
      <button
        id
        class="button-circle sm button-stroke button-info_text m-0-0-0-auto"
        @click="$bvModal.hide('modal-info-responsive')"
        type="button"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <SignUpOrganizationBody stepSeven />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import WizardFormOrganizations from "@/components/WizardFormOrganizations.vue";
import SignUpOrganizationBody from "@/components/SignUpOrganizationBody.vue";
import StepStart from "@/views/signUpOrganization/StepStart";
import StepLogo from "@/views/signUpOrganization/StepLogo";
import StepOperationScheme from "@/views/signUpOrganization/StepOperationScheme";
import StepBanking from "@/views/signUpOrganization/StepBanking";
import StepSummary from "@/views/signUpOrganization/StepSummary";
import StepUserRegistration from "@/views/signUpOrganization/StepUserRegistration.vue";
import StepRequestedDocuments from "@/views/signUpOrganization/StepRequestedDocuments.vue";
import StepLocation from "@/views/signUpOrganization/StepLocation.vue";
import StepOnboarding from "@/views/signUpOrganization/StepOnboarding.vue";
import { mapGetters } from "vuex";
import {
    STORE as SESSION_STORE,
    GET_USER
} from '@/store/modules/session/session.types';

const storeModule = "signUpOrganization";

export default {
  name: "RegisterWizard",
  props: {
    loginLeftFooter: { type: Boolean, default: false },
    registerLeftFooter: { type: Boolean, default: false }
  },
  data(){
    return{
        hasSession: false
    }
  },
  components: {
    WizardFormOrganizations,
    SignUpOrganizationBody,
    StepStart,
    StepLogo,
    StepOperationScheme,
    StepBanking,
    StepSummary,
    StepUserRegistration,
    StepRequestedDocuments,
    StepLocation,
    StepOnboarding
  },
  created() {
      const userId = this.currentUserLayout._id;
      if(userId){
        this.hasSession = true;
        this.$store.dispatch(`${storeModule}/getCurrentUserSession`, {userId});
      } else {
        this.hasSession = false;
      }
  },
  computed: {
    ...mapGetters(storeModule, [
      "getPotentialOrganization",
      "getActualUser",
      "getFormStep",
      "getIsLoadActualOrganization",
      "hasNewRegisterFinish",
      "getOnboardingFlag"
    ]),
    ...mapGetters(SESSION_STORE, { currentUserLayout: GET_USER})
  }
};
</script>
