<template>
	<div class="icon-list">
        <button class="button button-ghost button-error sm" @click="deleteValue()">
            <i aria-hidden="true" class="banana banana-delete"></i>
        </button>
        <div class="inner-container">
            <div class="m-r-15 square-bg-accent2" >
                <i aria-hidden="true" class="banana" :class="icon"></i>
            </div>
            <strong class="">
                {{text}}
            </strong>
        </div>
    </div>
</template>

<script>
export default {
	name: 'IconList',
	props: {
		text: String,
		icon: String,
    },
    methods: {
        deleteValue() {
            this.$emit('delete');
        }
    }
}
</script>
