<template>
  <div class="row">
    <div class="col-12">
      <FloatingTitleLayout
        :msgSmall="$t('collaborators.msg-small')"
        :title="$t('collaborators.title')"
      ></FloatingTitleLayout>
    </div>

    <div class="col-12">
      <SearchBar
              ref="searchBar"
              :canCreate="true"
              clazzFilter="m-r-0"
              noOptions
              @routerPush="$bvModal.show('modal-default')"
              :showDropdown="false"
              :buttonText="$t('collaborators.add-collaborator.button')"
              v-on:searchTextChange="searchTextChange"
      ></SearchBar>
    </div>

    <!-- EMPTYSTATE SEARCHING DOCUMENTS AT BEGINING -->
    <div class="col-12" v-if="isLoading && total === 0">
      <CardSimple hideTitle clazz="p-0" clazzTitle="d-none">
        <EmptyState emptyStateSearching :text="$t('collaborators.searching')"></EmptyState>
      </CardSimple>
    </div>
    <div class="col-12" v-if="collaborators.length > 0">
      <b-tabs
        pills
        vertical
        class="vertical-tabs-info"
      >

        <b-tab active v-for="(collaborator,index) in collaborators" v-bind:key="index" :id="`tab-${collaborator._id}`"
               @click="setCollaboratorSelected(collaborator)" ref="COLLABORATOR_ACTIVE">
          <template v-slot:title>
            <div class="vertical-tab-info">
              <span>
                <label class="wb-break-all p-r-5 cut-1-line"> {{collaborator.name}} {{collaborator.lastName}}</label>
                <p class="wb-break-all p-r-5 cut-1-line"> {{collaborator.email}} </p>
                <Badge
                  :type="isUserWithAccount(collaborator) ? 'badge-accent_2' : 'badge-placeholder'"
                  :text="isUserWithAccount(collaborator) ? $t('collaborators.user-with-account') : $t('collaborators.user-without-account')"
                ></Badge>
              </span>
              <span class="ml-auto">
                <button
                  type="button"
                  class="button-circle xs button-ghost button-error"
                  @click="$bvModal.show('modal-danger')"
                ><i aria-hidden="true" class="banana banana-delete"></i></button>
              </span>
            </div>
          </template>

          <!-- EMPTY STATE MIENTRAS SE CARGAN LOS COLABORADORES -->
          <div class="col-12" v-if="isLoading == true">
            <CardSimple hideTitle clazz="p-0" clazzTitle="d-none">
              <EmptyState emptyStateSearching :text="$t('permissions.searching-permissions-profile')"></EmptyState>
            </CardSimple>
          </div>

          <CardSimple
            :title="$t('collaborators.card.title')"
            :subtitle="$t('collaborators.card.subtitle')"
            clazz="min-w-300px"
            v-if="isLoading === false">
            <div class="row">
              <div class="col-12 p-0" v-for="(profile,iProfile) in profiles" v-bind:key="iProfile">
                <div class="vertical-center p-15 p-t-20 p-b-20 hover-bg-accent_2">
                  <div class="p-r-10 w-90">
                    <h1 class="c-primary_1 f-16 body-font-bold m-b-10">
                      {{profile.name}}
                    </h1>
                    <p class="m-0 c-plain_text f-13 body-font-regular">
                      {{profile.description}}
                    </p>
                  </div>
                  <BasicCheckbox :index="iProfile"
                                 :value="isUserProfile(collaborator,profile._id)"
                                 @change="selectIndex"
                                 :ref="`PROFILES-${collaborator._id}-${iProfile}`"
                                 inputClass="m-r-0"
                                 clazz="m-b-0 w-20px ml-auto"></BasicCheckbox>
                </div>
              </div>
              <div class="col-12 vertical-center justify-content-end m-t-40">
                  <button
                    type="button"
                    class="button sm button-ghost button-plain_text"
                    v-if="isChanged" @click="cancel()"
                  >{{$t('collaborators.button.cancel')}}</button>
                  <button
                    type="button"
                    class="button sm button-filled button-accent1 m-l-10"
                    @click="saveProfiles" :disabled="!isChanged"
                  > {{$t('collaborators.button.save')}} </button>
              </div>
            </div>
          </CardSimple>
        </b-tab>
      </b-tabs>
      <div class="pagination-custom" v-if="isLoading === false">
        <span class="pagination-count">{{total}} {{ total  > 1 ? $tc('collaborators.collaborators', 2) : $tc('collaborators.collaborators', 1) }}</span>
        <b-pagination
          v-model="actualPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="my-table"
          next-text=""
          prev-text=""
          hide-goto-end-buttons
        >
        </b-pagination>
      </div>
    </div>

    <div class="col-12" v-if="collaborators.length === 0">
        <CardSimple>
            <EmptyState emptyStateDefault
            :title="$t('collaborators.empty-state.title')"
            :text="searchText"
        ></EmptyState>
        </CardSimple>
    </div>

    <!-- Modal Danger -->
    <ModalAlert modalId="modal-danger" buttonType="button-error"
      :imgUrl="imageModalDelete"
      :title="$t('collaborators.modal.delete.title')"
      :text="$t('collaborators.modal.delete.text')"
      :buttonText="$t('collaborators.modal.delete.button')"
      @submit="deleteCollaborator"></ModalAlert>

    <!-- Default modal -->
    <ModalDefault
        size="lg"
        modalId="modal-default"
        originalFooter
        icon="banana-user-group"
        :title="$t('collaborators.modal.add-collaborator.title')"
        :subtitle="$t('collaborators.modal.add-collaborator.subtitle')"
        :buttonText="$t('collaborators.modal.add-collaborator.button')"
        :hideOriginalButton="!moreThanOneCollaborator"
        @clickAccepted="inviteCollaborators">
            <InputTags
                idForm="CollaboratorsNew"
                inputType="text"
                labelFor="collab"
                inputId="collab"
                label="Colaboradores"
                :inputPlaceholder="$t('collaborators.modal.add-collaborator.placeholder')"
                @input="setNewCollaborators"></InputTags>
    </ModalDefault>
  </div>
</template>

<script>
import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
import CardSimple from '@/components/CardSimple.vue';
import EmptyState from '@/components/EmptyState.vue';
import SearchBar from '@/components/SearchBar.vue';
import Badge from "@/components/Badge.vue"
import BasicCheckbox from '@/components/BasicCheckbox.vue'
import ModalAlert from '@/components/ModalAlert.vue'
import imageModalDelete from '@/assets/images/Illustrations/illustration-modal-delete.svg'
import InputTags from '@/components/InputTags.vue'
import ModalDefault from '@/components/ModalDefault.vue'
import {mapGetters} from 'vuex';
import {EventBus} from "@/main";
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import utils from '@/common/utils';
import ToastSnotify from '@/mixins/toastSnotify.mixin';
import {
  STORE as CollaboratorsStore,
  GET_COLLABORATORS,
  GET_PROFILES,
  GET_TOTAL,
  GET_CURRENT_PAGE,
  SET_CURRENT_PAGE,
  GET_OBJECT_PAGE,
  SET_SEARCH_TEXT,
  GET_LOADING_ITEMS,
  SET_LOADING_ITEMS
} from "@/store/modules/permissions/collaborators.types";
import {
  WARNING_EVENT,
  SUCCESS_SAVE
} from '@/store/events';

export default {
  name: "CollaboratorsList",
  components: {
    FloatingTitleLayout,
    CardSimple,
    EmptyState,
    SearchBar,
    Badge,
    BasicCheckbox,
    ModalAlert,
    InputTags,
    ModalDefault
  },
  mixins: [ToastSnotify],
  data () {
    return {
        imageModalDelete: imageModalDelete,
        collaboratorSelected: {},
        isChanged: false,
        searchText: "",
        actualPage: 1,
        newCollaborators: []
    }
  },
  computed: {
    ...mapGetters(CollaboratorsStore, {
      collaborators: GET_COLLABORATORS,
      profiles: GET_PROFILES,
      total: GET_TOTAL,
      currentPage: GET_CURRENT_PAGE,
      perPage: GET_OBJECT_PAGE,
      isLoading: GET_LOADING_ITEMS
    }),
    ...mapGetters(ACCESS_STORE, {
      currentOrganization: GET_CURRENT_ORGANIZATION
    }),
    moreThanOneCollaborator(){
      return this.newCollaborators.length > 0;
    }
  },
  methods: {
    setCollaboratorSelected(collaborator){
      this.cancel();
      this.collaboratorSelected= collaborator;
      this.$store.commit(`${CollaboratorsStore}/${SET_LOADING_ITEMS}`, true);
    },
    isUserWithAccount(user){
      return user.hasNewRegisterFinish
    },
    isUserProfile(collaborator, profile){
      return collaborator.profiles.includes(profile)
    },
    selectIndex () {
      this.isChanged= true;
    },
    pageChange(page) {
      this.actualPage= page;
      this.$store.commit(`${CollaboratorsStore}/${SET_CURRENT_PAGE}`, this.actualPage);
      this.$store.dispatch(`${CollaboratorsStore}/list`,this.currentOrganization.id);
    },
    searchTextChange(searchText) {
      utils.debounceFixed(() => {
        this.searchText = searchText;
        this.$store.commit(`${CollaboratorsStore}/${SET_SEARCH_TEXT}`, searchText)
        this.$store.dispatch(`${CollaboratorsStore}/list`, this.currentOrganization.id)
      })
    },
    setNewCollaborators(collaborators){
      this.newCollaborators= collaborators;
    },
    inviteCollaborators(){
      const emailsCollaborators= [];
      if(this.newCollaborators.length > 0){
        // Eliminar elementos repetidos
        this.newCollaborators.forEach((collaborator)=> {
          if(!emailsCollaborators.includes(collaborator)){
            emailsCollaborators.push(collaborator);
          }
        });
        const params={
          organizationId: this.currentOrganization.id,
          emailsArray: emailsCollaborators
        }

        this.$store.dispatch(`${CollaboratorsStore}/addCollaborators`, params);

      }

    },
    cancel(){
      this.isChanged= false;
      this.$store.dispatch(`${CollaboratorsStore}/list`, this.currentOrganization.id);

      this.getCollaboratorSelected();
      // Se obtienen todos los checkbox de todos los permisos, estan identificados segun el id del colaborador y el id del indice del perfil.
      for(const item in this.profiles){
        if(item){
          const checkboxObjects= this.$refs[`PROFILES-${this.collaboratorSelected._id}-${item}`];

          checkboxObjects.forEach((object)=>{
            const profile= this.profiles[object.index];
            if(this.isUserProfile(this.collaboratorSelected,profile._id)){
              object.checkboxValue= true;
            }else{
              object.checkboxValue= false;
            }
          })
        }
      }
    },
    saveProfiles(){
      this.getCollaboratorSelected();
      let checkboxObjects= [];
      // Se obtienen todos los checkbox de todos los permisos, estan identificados segun el id del colaborador y el id del indice del perfil.
      for(const item in this.profiles){
        if(item){
          const checkboxRefs= this.$refs[`PROFILES-${this.collaboratorSelected._id}-${item}`];
          checkboxObjects= [...checkboxObjects,...checkboxRefs];
        }
      }

      // Se obtienen los que estan seleccionados.
      const checkboxSelected= [];
      const profilesInPage = [];
      checkboxObjects.forEach((object)=>{
        const profile= this.profiles[object.index];
        if(object.checkboxValue === true){
          checkboxSelected.push(profile._id);
        }
        profilesInPage.push(profile._id);
      })

      const params={
        profilesArray: checkboxSelected,
        userId: this.collaboratorSelected._id,
        profilesInPage: profilesInPage,
        organizationId: this.currentOrganization.id
      }

      this.$store.dispatch(`${CollaboratorsStore}/saveCollaboratorProfiles`, params);
    },
    getCollaboratorSelected(){
      const tabsCollaborators= this.$refs.COLLABORATOR_ACTIVE;
      tabsCollaborators.forEach((tab) =>{
        if(tab.localActive === true){
          const collaboratorId= tab.id.split("-")[1];
          this.collaborators.forEach((collaborator)=> {
            if(collaborator._id === collaboratorId){
              this.collaboratorSelected= collaborator;
            }
          })
        }
      })
    },
    deleteCollaborator(){
      const params={
        userId: this.collaboratorSelected._id,
        organizationId: this.currentOrganization.id
      }

      this.$store.dispatch(`${CollaboratorsStore}/deleteCollaborator`, params);
      this.$bvModal.hide('modal-danger');
    },
  },
  mounted(){
    this.$store.dispatch(`${CollaboratorsStore}/list`, this.currentOrganization.id);
  },
  beforeDestroy() {
    EventBus.$off(CollaboratorsStore + WARNING_EVENT);
    EventBus.$off(CollaboratorsStore + SUCCESS_SAVE);

  },
  created() {
    EventBus.$on(CollaboratorsStore + WARNING_EVENT, (message) => {
      this.displayNotificationWarning(message);
    });
    EventBus.$on(CollaboratorsStore + SUCCESS_SAVE, (message) => {
      this.isChanged= false;
      this.displayNotificationSuccess(message);
    });
  }
}
</script>
