<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/api-rest"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                    tabindex="">
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i>
                    {{ $t('api-configuration.api-test-verification.return') }}
                </router-link>
                <FloatingTitleLayout :title="$t('api-configuration.api-test-verification.title')"/>
            </div>
            <div class="col-12">
                <LoadingBar :clazz="loadingGeneralInfo !== true ? 'd-none' : ''" loadingText="Cargando..."></LoadingBar>
                <WizardForm
                        @step-has-changed="currentStep"
                        :hideFooter="true"
                >
                    <template v-slot:wizardView>
                        <tab-content
                                v-for="test in getTestsInfo"
                                :key="test.testNumber"
                                :title="$t(test.name)"
                                :icon="getBadgeNumber(test.testNumber)"
                        >
                            <CardSimple
                                    :title="$t(test.name)"
                                    :subtitle="$t('api-configuration.api-test-verification.general-instructions')"
                                    :key="keyValueArray[test.testNumber - 1]">

                                    <div class="row">
                                        <div class="col-12">
                                            <p
                                                    class="instructions"
                                                    v-for="(index, instruction) in test.previousInstructions"
                                                    :key="index"
                                                    v-html="$t(test.previousInstructions[instruction])">
                                            </p>
                                            <codemirror v-if="test.testNumber===4" :value="tokenScript" class="textDisabled"></codemirror>
                                        </div>

                                        <div class="col-12">
                                            <BankCardPreview
                                                    clazz="m-b-20"
                                                    :cardNum="test.cardNumber"
                                                    :cardName="test.cardName"
                                                    :cardDate="test.cardExpiration"
                                                    cardBrand="visa"
                                                    v-if="test.requiredCardInformation"
                                            ></BankCardPreview>
                                        </div>

                                        <LoadingBar :clazz="loadingTestInfo !== true ? 'd-none' : ''" loadingText="Cargando..."></LoadingBar>

                                        <div class="col-12" v-if="test.testStatus !== 'PENDENT'">
                                            <b-alert
                                                    class="alert-note m-b-20"
                                                    show
                                                    :variant="test.testStatus === 'APPROVED' ? 'success' : 'danger'">
                                                <p class="text-container p-r-0">
                                                    <img
                                                            class="img-fluid"
                                                            src="@/assets/images/Icons/Alerts-icons/icon-alert-success.svg"
                                                            alt="success"
                                                            v-if="test.testStatus === 'APPROVED'"
                                                    />
                                                    <img
                                                            class="img-fluid"
                                                            src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                            alt="danger"
                                                            v-if="test.testStatus === 'ERROR'"
                                                    />
                                                    <span v-html="$t(test.dashboardMessage)"></span>
                                                </p>
                                            </b-alert>

                                            <label class="f-13 c-title principal-font-bold d-block m-b-10">
                                                {{ $t('api-configuration.api-test-verification.detail-title') }}
                                            </label>

                                            <codemirror :value="test.jsonResponse" class="textDisabled max-w-680px m-0-auto"></codemirror>
                                        </div>

                                        <div class="col-12 vertical-center m-t-40">
                                            <button
                                                    type="button"
                                                    class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                                    v-if="test.testNumber !== 1"
                                                    @click="previousStep(test.testNumber)"
                                            > {{ $t('api-configuration.api-test-verification.previous-test') }} </button>

                                            <button
                                                    v-if="test.testNumber===4 && test.testStatus !== 'APPROVED'"
                                                    type="button"
                                                    class="button xs button-stroke button-accent1"
                                                    @click="ignoreStep(test.testNumber)"
                                            > Omitir Prueba </button>

                                            <button
                                                    type="button"
                                                    class="button xs button-filled button-accent1"
                                                    :class="test.testNumber===4 && test.testStatus !== 'APPROVED'? 'm-0-0-0-auto' : 'm-0-auto-0-0'"
                                                    @click="verifyStep(test.testNumber)"
                                                    v-if="test.testStatus !== 'APPROVED'"
                                            > {{ $t('api-configuration.api-test-verification.verify-test') }} </button>

                                            <button
                                                    type="button"
                                                    class="button xs button-filled button-accent1 m-0-0-0-auto"
                                                    @click="nextStep(test.testNumber)"
                                                    v-if="test.testStatus === 'APPROVED' && test.testNumber !== getTestsInfo.length"
                                            > {{ $t('api-configuration.api-test-verification.next-test') }} </button>

                                            <router-link
                                                    to="/client-admin/api-rest"
                                                    class="button xs button-filled button-accent1 m-0-0-0-auto"
                                                    tabindex=""
                                                    v-if="test.testStatus === 'APPROVED' && test.testNumber === getTestsInfo.length"
                                            >
                                                {{ $t('api-configuration.api-test-verification.finish-test') }}
                                            </router-link>
                                        </div>
                                    </div>
                            </CardSimple>
                        </tab-content>
                    </template>
                </WizardForm>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "@/main";
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import WizardForm from '@/components/WizardForm.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import BankCardPreview from '@/components/BankCardPreview.vue';
    import LoadingBar from '@/components/LoadingBar.vue';
    import { codemirror } from 'vue-codemirror-lite';

    import {mapGetters} from 'vuex';
    import axios from 'axios'
    import {utils} from '@/api/utils';
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {
        STORE,
        GET_USER,
        GET_TOKEN
    } from '@/store/modules/session/session.types';

    const storeModule = `apiRest`;

    export default {
        name: "ApiProcessSimulator",
        components: {
            FloatingTitleLayout,
            WizardForm,
            CardSimple,
            BankCardPreview,
            LoadingBar,
            codemirror
        },
        data () {
            return {
                testValue: 0,
                keyValueArray: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                ],
                loadingTestInfo: true,
                loadingGeneralInfo: true,
                errorNotifications: 0,
                // eslint-disable-next-line no-useless-escape
                tokenScript:
                    '<script>\n\t(window.pagando = window.pagando || function(pa,gan,d0,api){\n' +
                    '\t\tpa._pAFTS={uid: \'<Identificador_de_usuario>\', cbf: api}\n' +
                    '\t\tvar z = gan.createElement("script");z.async=1;\n' +
                    '\t\tz.type="text/javascript";z.src=d0;\n' +
                    '\t\tvar y=gan.getElementsByTagName("script")[0];\n' +
                    '\t\ty.parentNode.insertBefore(z,y);\n' +
                    '\t}),pagando(window, document, \' https://static.pagando.mx/pagando-aft.js\',\'getAFT\');\n\n' +
                    '\tfunction getAFT(response) {\n' +
                    '\t\tconsole.log("User AFT token: " + response.aftToken);\n' +
                    '\t\twindow.localStorage.setItem(\'aftT\', response.aftToken);\n}\n<\/script>'
            }
        },
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            ...mapGetters(STORE, {user: GET_USER, token: GET_TOKEN}),
            ...mapGetters(storeModule,[
                'getConfigurationStep',
                'getDomainName',
                'getOriginRoute',
                'getResponseRoute',
                'getDomainsList',
                'getApiTestKey',
                'getApiLiveKey',
                'getApiUserLive',
                'getApiUserTest',
                'getTestsInfo',
                'getKeyValueArray'
            ]),
        },
        methods: {
            /*
            Get the general test information
             */
            getTestInfo() {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Organizacion seleccionada
                    user: this.user._id
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.loadingGeneralInfo = true;
                this.$store.dispatch(`${storeModule}/getTestInformation`, params);
            },
            /*
            Get the complete information of a test
             */
            getCurrentTestInfo(step) {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Organizacion seleccionada
                    user: this.user._id,
                    currentStep: step
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.loadingTestInfo = true;

                this.$store.dispatch(`${storeModule}/getCurrentTestInformation`, params);
            },
            /*
            Validate if the test case was performed
             */
            verifyTestInfo(step) {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Organizacion seleccionada
                    user: this.user._id,
                    currentStep: step
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.loadingTestInfo = true;

                this.$store.dispatch(`${storeModule}/verifyTestInformation`, params);
            },
            /*
            Validate if the test case was performed
             */
            ignoreTest(step) {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Organizacion seleccionada
                    user: this.user._id,
                    currentStep: step,
                    ignore: true
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.loadingTestInfo = true;

                this.$store.dispatch(`${storeModule}/verifyTestInformation`, params);
            },
            /*
            Auxiliary function to get the badges icon
            */
            getBadgeNumber(testNumber) {
                const BadgeNumber = {
                    1: "one",
                    2: "two",
                    3: "three",
                    4: "four",
                    5: "five",
                    6: "six",
                    7: "seven"
                };

                return BadgeNumber[testNumber];
            },
            /*
            Go to the next step
             */
            nextStep(currentStep){
                EventBus.$emit(`wizard#goTo`, { from: currentStep - 1, to: currentStep });
            },
            /*
            Go to the next step
             */
            ignoreStep(currentStep){
                this.ignoreTest(currentStep);
            },
            /*
            Button function that calls verifyTestInfo (Do not touch)
             */
            verifyStep(currentStep){
                this.verifyTestInfo(currentStep);
            },
            /*
            Go to the previous step
             */
            previousStep(currentStep){
                EventBus.$emit(`wizard#goTo`, { from: currentStep - 1, to: currentStep - 2});
            },
            /*
            Function thats is call when wizard step has changed
             */
            currentStep(previousStep, currentStep){
                this.getCurrentTestInfo(currentStep + 1);

            }
        },
        created() {
            this.getTestInfo();

            EventBus.$on(`render-wizard`, ()=>{
                this.testValue += 1;
            });

            EventBus.$on(`current-step`, ()=>{
                this.getCurrentTestInfo();
            });

            EventBus.$on(`test`, ()=>{
                const newKeyValueArray = this.$store.getters[`${storeModule}/getKeyValueArray`];
                if (newKeyValueArray?.length > 0){
                    this.keyValueArray = newKeyValueArray.slice();
                }
            });

            EventBus.$on('verify-status', (newStatus) => {
                if(newStatus === 'APPROVED'){
                    utils.toast(this.$snotify, 'success', "PRUEBA APROBADA", 'Tu prueba ha sido aprobada con éxito!');
                } else if(newStatus === 'ERROR'){
                    utils.toast(this.$snotify, 'error', 'PRUEBA NO APROBADA', 'Tu prueba ha tenido un error');
                } else {
                    utils.toast(this.$snotify, 'warning', 'PRUEBA PENDIENTE', 'No hemos recibido ninguna petición de esta prueba');
                }
            });

            EventBus.$on(`change-step`, (step)=>{
                EventBus.$emit(`wizard#goTo`, { from: 0, to: step - 1});
            });

            EventBus.$on(`testInfoLoadedAV`, () =>{
                this.loadingGeneralInfo = false;
            });

            EventBus.$on(`currentTestInfoLoaded`, ()=>{
                this.loadingTestInfo = false;
            });

            EventBus.$on("apiErrorTest", () => {
                const vueScope = this;

                // Avoid multiple toast error at same time
                vueScope.errorNotifications += 1;

                if (vueScope.errorNotifications === 1) {
                    utils.toast(
                        this.$snotify,
                        'error',
                        "Error al obtener información",
                        "Ups! ha ocurrido un error al obtener información, por favor recargue la página o comuníquese con un asesor."
                    );
                }

                // Enable to receive toast errors
                setTimeout(function() {
                    vueScope.errorNotifications = 0;
                }, 1000);
            });
        },
        beforeDestroy() {
            EventBus.$off(`render-wizard`);
            EventBus.$off(`current-step`);
            EventBus.$off(`test`);
            EventBus.$off('verify-status');
            EventBus.$off(`testInfoLoadedAV`);
            EventBus.$off(`currentTestInfoLoaded`);
            EventBus.$off("apiErrorTest");
        }
    }
</script>


