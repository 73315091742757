import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'payments-template';

export default {
    list: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list`, params)
            .then(onSuccess)
            .catch(onError);
    },
    uploadXlsFile: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments/process`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmPayment: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirm-payment`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmListPayments: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments/confirm`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmAllPayments: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirm-all`, params)
            .then(onSuccess)
            .catch(onError);
    },
    delete: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete`, params)
            .then(onSuccess)
            .catch(onError);
    },
    deleteAll: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete/all`, params)
            .then(onSuccess)
            .catch(onError);
    },
    downloadTemplate: (params, onSuccess, onError) => {
        return axios({
            method: 'GET',
            url: `${base.baseUrl}/${namespace}/download-template`,
            params: params,
            responseType: 'blob'
        })
            .then(onSuccess)
            .catch(onError);
    },
    getDetailRegularPayment: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/detail/regular-payment`, params)
            .then(onSuccess)
            .catch(onError);
    }
};
