import axios from 'axios'
import base from "@/api/base.api";

const namespace = "organizations";
const contentType = "multipart/form-data";

export default {
    updateBankInfo: ({ organization, params }, onSuccess, onError) => {
        const formData = new FormData();
        formData.append("file", params.bankCover);
        formData.append("clabe", params.clabe);
        formData.append("accountNumber", params.accountNumber);
        formData.append("bankId", params.bankId);

        return axios
            .post(`${namespace}/bank/update/${organization._id || organization}`, formData, {
                headers: { "Content-Type": contentType },
            })
            .then(onSuccess)
            .catch(onError);
    },
    organizationBillingInfo: (id, onSuccess, onError) => {
        return axios
            .get(`${namespace}/billing/show/${id}`)
            .then(onSuccess)
            .catch(onError);
    },
    getBankInfo: (id, onSuccess, onError) => {
        return axios
            .get(`${namespace}/bank/show/${id}`)
            .then(onSuccess)
            .catch(onError);
    },
    organizationGeneralInfo: (organizationId, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/show/${organizationId}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    updateOrganizationGeneralInfo: (organizationId, params, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/update/general/${organizationId}`;
        return axios
            .post(url, params)
            .then(onSuccess)
            .catch(onError);
    },
    updateOrganizationImage: (organizationId, logoCropped, logoFullSize, coverCropped, coverFullSize, onSuccess, onError) => {
        var formData = new FormData();
        if (logoCropped) {
            formData.append("logoCropped", logoCropped);
        }
        if (logoFullSize) {
            formData.append("logoFullSize", logoFullSize);
        }
        if (coverCropped) {
            formData.append("coverCropped", coverCropped);
        }
        if (coverFullSize) {
            formData.append("coverFullSize", coverFullSize);
        }

        return axios
            .post(`${base.baseUrl}/${namespace}/image/update/${organizationId}`, formData, { headers: { "Content-Type": contentType } })
            .then(onSuccess)
            .catch(onError);
    },
    updateOrganizationCover: (organizationId, params, onSuccess, onError) => {
        var formData = new FormData();
        formData.append("fileImage", params.fileImage);

        return axios
            .post(`${base.baseUrl}/${namespace}/cover/update/${organizationId}`, formData, { headers: { "Content-Type": contentType } })
            .then(onSuccess)
            .catch(onError);
    },
    getOrganizationImage: (organizationId, onSuccess, onError) => {
        if (organizationId) {
            return axios
                .get(`${base.baseUrl}/${namespace}/image/show/${organizationId}`)
                .then(onSuccess)
                .catch(onError);
        }
        return Promise.reject(new Error("No organizationId"));
    },
    getCanUpdateBankInfo: (organizationId, onSuccess, onError) => {
        return axios
            .get(`${base.baseUrl}/${namespace}/bank/can-update/${organizationId}`)
            .then(onSuccess)
            .catch(onError);
    },
    getById: (organizationId, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/get/${organizationId}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    getContactInfo: (organizationId, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/contact/${organizationId}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    updateContactInfo: (organization, data, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/contact/${organization._id || organization}`;
        return axios
            .post(url, data)
            .then(onSuccess)
            .catch(onError);
    },
    getNotificationInfo: (organization, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/notification/${organization._id || organization}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    updateNotificationInfo: (organization, data, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/notification/${organization._id || organization}`;
        return axios
            .post(url, data)
            .then(onSuccess)
            .catch(onError);
    },
    getBillingInfo: (organization, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/billing/${organization._id || organization}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    updateBillingInfo: (organization, data, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/billing/${organization._id || organization}`;
        return axios
            .post(url, data)
            .then(onSuccess)
            .catch(onError);
    },
    getPaymentsConfiguration: (organization, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/payments-configuration/${organization._id || organization}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    updatePaymentsConfiguration: (organization, data, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/payments-configuration/${organization._id || organization}`;
        return axios
            .post(url, data)
            .then(onSuccess)
            .catch(onError);
    },
    getCommision: (organization, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/commision/${organization._id || organization}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    share: (emails, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/share`;
        return axios
            .post(url, { emails })
            .then(onSuccess)
            .catch(onError);
    },
    searchUrlAlias: (params, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/url-alias/check/${params.id}/${params.urlAlias}`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    requestedDocuments: (params, onSuccess, onError) => {
        const url = `${base.baseUrl}/${namespace}/requested-documents`;
        return axios
            .get(url)
            .then(onSuccess)
            .catch(onError);
    },
    uploadDocument(document, file) {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post(`${base.baseUrl}/${namespace}/save-document/${document._id}`, formData);
    },
    deleteDocument(params) {
        return axios.post(`${base.baseUrl}/${namespace}/delete-document`, params);
    },
    getOrganizationSavedFiles(onSuccess, onError) { // This retrieves an organization object only  with requestedFiles array
        return axios.get(`${base.baseUrl}/${namespace}/saved-files`)
            .then(onSuccess)
            .catch(onError);
    },
    submitForReview: () => {
        return axios.post(`${base.baseUrl}/organizations-activation-flow/submit-changes`)
    },
    hireData() {
        return axios.get(`${namespace}/hire/data`).then(({data: {object}}) => object);
    },
    hireDataSave(payload) {
        return axios.post(`${namespace}/hire/data`, payload).then(({data: {object}}) => object);
    },
    hireDocument(body, file) {
        const formData = new FormData();
        formData.append("document", file);
        Object.entries(body).forEach(([k, v]) => formData.append(k, v));
        return axios.post(`${namespace}/hire/document`, formData).then(({data: {object}}) => object);
    },
    hirePassword(payload) {
        return axios.post(`${namespace}/hire/password`, payload).then(({data: {object}}) => object);
    },
    hireFinish() {
        return axios.post(`${namespace}/hire/finish`).then(({data: {object}}) => object);
    }
};
