<template>
    <div class="empty-state" v-bind:class="size">
        <div v-if="!emptyStateDefault && !emptyStateSearching" class="content-horizontal">
            <span>
                <label> {{title}} </label>
                <p> {{text}} </p>
                <slot></slot>
            </span>
            <img class="img-fluid" :src="imgUrlHorizontal" alt="" />
        </div>

        <div v-if="!emptyStateDefault && emptyStateSearching" class="content-vertical">
            <img class="img-fluid" src="@/assets/images/Emptystates/emptystate-searching.svg" alt="" />
            <span>
                <p class="loading-note no-cpu"> “{{text}}” </p>
                <slot></slot>
            </span>
        </div>

        <div v-if="emptyStateDefault && !emptyStateSearching" class="content-vertical">
            <img class="img-fluid" :src="imgUrl" alt="" />
            <span>
                <label> {{title}} </label>
                <p>{{text}}</p>
                <slot></slot>
            </span>
        </div>
    </div>
</template>

<script>
    import Imagen from '@/assets/images/Emptystates/emptystate-search.svg';
    import ImageHorizontal from '@/assets/images/Emptystates/emptystate-group.svg';

    export default {
        name: 'EmptyState',
        props: {
            size: String,
            title: String,
            text: String,
            emptyStateDefault: {type: Boolean, default: false},
            emptyStateSearching: {type: Boolean, default: false},
            imgUrl: {
                type: String,
                default: Imagen
            },
            imgUrlHorizontal: {
                type: String,
                default: ImageHorizontal
            }
        },
        data() {
            return {
                defaultUrl: Imagen,
                defaultUrlHorizontal: ImageHorizontal
            }
        }
    }
</script>

<style lang="scss">
    .empty-state {
        padding: 30px 0;

        > .content-horizontal {
            display: flex;
            align-items: center;
            max-width: 930px;
            margin: 0 auto;
            padding: 20px;

            > span {
                padding-right: 60px;
                min-width: 50%;

                > label {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1.3;
                    display: block;
                    margin-bottom: 20px;
                }

                > p {
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 1.5;
                    display: block;
                    margin-bottom: 40px;
                }
            }

            > img {
                max-height: 360px;
                max-width: 50%;// 470px;
                object-fit: contain;
            }
        }

        > .content-vertical {
            > img {
                max-height: 286px;
                margin: 0 auto 30px;
                display: flex;
            }

            > span {
                label {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.5;
                    text-align: center;
                    display: block;
                    margin-bottom: 0;
                }

                p {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    text-align: center;
                    display: block;
                    margin-bottom: 0;
                }
            }
        }

        &.xs {
            padding: 10px 0;

            .content-vertical {
                > img {
                    max-height: 180px;
                }

                > span {
                    label,
                    p {
                        font-size: 12px;
                    }
                }
            }
        }

        &.sm {
            padding: 20px 0;

            .content-vertical {
                > img {
                    max-height: 220px;
                }
            }
        }
    }
</style>
