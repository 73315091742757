
<template>
    <div>
        <SignInBody clazz="full-page hideFooterRights">
            <template v-slot:formSectionBody>

                <div class="fullpage-container-body">
                        <img class="img-fluid d-flex m-0-auto" src="@/assets/images/Illustrations/illustration-blocked.svg" alt="illustration" />
                        <h1 class="f-20 c-primary_1 principal-font-bold text-align-c d-block m-t-20 m-b-20"> {{$t('organizaciones.all-deactivated.title')}} </h1>
                        <p class="f-14 c-plain_text body-font-regular text-align-c d-block m-b-40">
                                {{$t('organizaciones.all-deactivated.instructions')}}
                                <br/><br/>
                                {{$t('organizaciones.all-deactivated.contact-support')}}
                        </p>
                        <div class="vertical-center m-b-40">
                                <a @click="openZendesk" class="button xs button-stroke button-accent1 w-fit" tabindex=""> Contactar soporte </a>
                                <router-link to="/" class="button xs button-filled button-accent1 w-fit" tabindex=""> Salir de Pagando </router-link>
                        </div>
                </div>
            </template>
        </SignInBody>
    </div>
</template>


<script>
    // @ is an alias to /src
    import SignInBody from "@/components/SignInBody.vue";

    export default {
        name: "SignInErrorOrganizationsDeactivated",
        components: {
            SignInBody
        },
        methods: {
            openZendesk () {
                const launcher = window.document.getElementById('launcher')
                const buttons = launcher.contentDocument.getElementsByClassName('wrapper-AtBcr');
                if (buttons !== undefined && buttons.length > 0) {
                    buttons[0].click();
                }
            }
        }

    };
</script>
