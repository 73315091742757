<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout :msgSmall="$t('permissions.msg-small')" :title="$t('permissions.title')"></FloatingTitleLayout>
        </div>
        <div class="col-12">
            <SearchBar
                    ref="searchBar"
                    :canCreate="$can('do', 'PERMISSIONS_CREATE')"
                    clazzFilter="m-r-0"
                    noOptions
                    :hasFilters="false"
                    @routerPush="newProfile"
                    :showDropdown="false"
                    :buttonText="$t('permissions.new-profile.button')"
                    v-on:searchTextChange="searchTextChange"
            ></SearchBar>
        </div>

        <!-- EMPTYSTATE SEARCHING DOCUMENTS AT BEGINING -->
        <div class="col-12" v-if="isLoading && total === 0">
            <CardSimple hideTitle clazz="p-0" clazzTitle="d-none">
                <EmptyState emptyStateSearching :text="$t('permissions.searching')"></EmptyState>
            </CardSimple>
        </div>

        <div class="col-12" v-if="profiles.length === 0">
            <CardSimple hideTitle>
                <EmptyState emptyStateDefault
                            :title="$t('permissions.empty-state.title')"
                            :text="searchText"
                ></EmptyState>
            </CardSimple>
        </div>

        <div class="col-12" v-if="profiles.length > 0">
            <b-tabs
                    pills
                    vertical
                    class="vertical-tabs-info">
                <b-tab active v-for="(profile,index) in profiles" v-bind:key="index" :id="`tab-${profile._id}`" @click="setProfileSelected(profile)" ref="PROFILE_ACTIVE">
                    <template v-slot:title>
                        <div class="vertical-tab-info">
                        <span>
                            <label> {{profile.name}} </label>
                            <p> {{profile.description}} </p>
                        </span>
                            <span>
                            <button v-if="$can('do', 'PERMISSIONS_DELETE')"
                                    type="button" class="button-circle xs button-ghost button-error"
                                    @click="$bvModal.show('modal-danger')">
                                <i aria-hidden="true" class="banana banana-delete"></i></button>
                        </span>
                        </div>
                    </template>

                    <!-- EMPTY STATE DE PERMISOS DE LOS PERFILES -->
                    <div class="col-12" v-if="isLoading == true">
                        <CardSimple hideTitle clazz="p-0" clazzTitle="d-none">
                            <EmptyState emptyStateSearching :text="$t('permissions.searching-permissions-profile')"></EmptyState>
                        </CardSimple>
                    </div>

                    <CardSimple clazzTitle="noLabel m-b-20" centerContent :title="$t('permissions.card.title')"
                                v-if="isLoading === false">
                        <template v-slot:titleButtons>
                            <button
                                type=""
                                class="button xs button-stroke button-accent1"
                                @click="editProfile"
                            >
                                {{$t('permissions.edit-profile.button')}}
                            </button>
                        </template>
                        <p class="c-plain_text f-14 m-b-20 body-font-regular">{{$t('permissions.card.subtitle')}}</p>
                        <div class="row">
                            <div class="col-12 x-scroll">
                                <div class="collapse-info-content" v-for="(permissionParent,iParent) in permissions" v-bind:key="iParent">
                                    <CollapseInfo :collapseId="`collapse-permissions-${iParent}`" :permissionName="permissionParent.name"
                                                  :permissionNumber="permissionNumber(permissionParent._id,5)" :permission="permissionParent"
                                                  :indexCheckbox="permissionParent._id" :currentValue="checkIsSelected(permissionParent, profile)"
                                                  @change="selectIndex" :ref="`PARENTS-${profile._id}-${permissionParent._id}`">
                                        <div class="body-info" v-for="(permission,iPermission) in permissionParent.permissions" v-bind:key="iPermission">
                                        <span>
                                            <label> {{permission.name}} </label>
                                        </span>
                                            <span>
                                            <p> {{permissionNumber(permission._id,5)}} </p>
                                        </span>
                                            <span>
                                            <BasicCheckbox :index="permission._id"
                                                           v-if="$can('do', 'PERMISSIONS_EDIT')"
                                                           :value="isOrganizationPermission(profile,permission._id)"
                                                           @change="selectIndex"
                                                           :ref="`PERMISSIONS-${profile._id}-${permissionParent._id}`"
                                                           clazz="m-b-0"></BasicCheckbox>
                                        </span>
                                        </div>
                                    </CollapseInfo>
                                </div>
                            </div>
                            <div class="col-12 vertical-center justify-content-end m-t-40">
                                <div class="vertical-center m-0-auto-0-0">
                                    <BasicSwitch :id="profile._id"
                                                    :labelFor="profile._id"
                                                    :ref="`PROFILE-ACTIVE-${profile._id}`"
                                                    :switchValue="profile.active"
                                                    @onBasicSwitchChange="showModalActive"
                                    ></BasicSwitch>
                                    <small class="f-14 c-plain_text body-font-regular m-l-15"> {{$t('permissions.switch.activate')}} </small>
                                </div>
                                <div class="vertical-center">
                                    <button type="button"
                                            class="button sm button-ghost button-plain_text"
                                            v-if="isChanged && $can('do', 'PERMISSIONS_EDIT')"
                                            @click="cancel()">
                                        {{$t('permissions.button.cancel')}}
                                    </button>
                                    <button type="button"
                                            class="button sm button-filled button-accent1 m-l-10"
                                            @click="savePermissions" :disabled="!isChanged"> {{ $t('permissions.button.save')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </CardSimple>

                    <div class="pagination-custom" v-if="isLoading === false">
                        <span class="pagination-count">{{total}} {{ total  > 1 ? $tc('permissions.permissions', 2) : $tc('permissions.permissions', 1) }}</span>
                        <b-pagination
                                v-model="actualPage"
                                :per-page="perPage"
                                :total-rows="total"
                                aria-controls="my-table"
                                next-text=""
                                prev-text=""
                                hide-goto-end-buttons
                                @change="pageChange"
                        ></b-pagination>
                    </div>
                </b-tab>
            </b-tabs>
        </div>

        <ModalDefault
                modalId="modal-newRol"
                :originalFooter="false"
                icon="banana-credit-card-generic"
                :title="isEdit ? $t('permissions.new-profile.modal.title-edit') : $t('permissions.new-profile.modal.title')"
                :subtitle="isEdit ? $t('permissions.new-profile.modal.subtitle-edit') : $t('permissions.new-profile.modal.subtitle')"
        >
            <BasicInput
                    required
                    inputType="text"
                    labelFor=""
                    inputId=""
                    :label="$t('permissions.new-profile.modal.name.label')"
                    v-model="profileName"
                    :inputPlaceholder="$t('permissions.new-profile.modal.name.placeholder')"></BasicInput>

            <BasicTextarea
                    required
                    labelFor=""
                    textareaId=""
                    :label="$t('permissions.new-profile.modal.description.label')"
                    v-model="profileDescription"
                    :textareaPlaceholder="$t('permissions.new-profile.modal.description.placeholder')"/>

            <template v-slot:customFooter>
                <button class="button xs button-ghost button-plain_text" @click="$bvModal.hide('modal-newRol')">{{$t('permissions.new-profile.modal.cancel.button')}}</button>
                <button class="button xs button-filled button-accent1" @click="createProfile()"
                        :disabled="$v.$touch && !!$v.$invalid">{{$t('permissions.new-profile.modal.save.button')}}</button>
            </template>
        </ModalDefault>

        <ModalAlert modalId="modal-unableRol"
                    buttonType="button-accent1"
                    :imgUrl="imageModalUnableRol"
                    :title="$t('permissions.modal.disable-rol.title')"
                    :text="$t('permissions.modal.disable-rol.text')"
                    :buttonText="$t('permissions.modal.disable-rol.button.accept')"
                    @close="setSwitchValue(true)"
                    @cancel="setSwitchValue(true)"
                    @submit="activeProfile(false)"></ModalAlert>

        <ModalAlert modalId="modal-ableRol"
                    buttonType="button-accent1"
                    :imgUrl="imageModalAbleRol"
                    :title="$t('permissions.modal.able-rol.title')"
                    :text="$t('permissions.modal.able-rol.text')"
                    :buttonText="$t('permissions.modal.able-rol.button.accept')"
                    @close="setSwitchValue(false)"
                    @cancel="setSwitchValue(false)"
                    @submit="activeProfile(true)"></ModalAlert>

        <ModalAlert modalId="modal-danger"
                    buttonType="button-error"
                    :imgUrl="imageModalDelete"
                    :title="$t('permissions.modal.delete.title')"
                    :text="$t('permissions.modal.delete.text')"
                    :buttonText="$t('permissions.modal.delete.button.delete')"
                    @submit="deleteProfile"></ModalAlert>

    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import SearchBar from '@/components/SearchBar.vue';
    import BasicSwitch from '@/components/BasicSwitch.vue';
    import CollapseInfo from '@/components/CollapseInfo.vue';
    import ModalDefault from '@/components/ModalDefault.vue';
    import ModalAlert from '@/components/ModalAlert.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import BasicTextarea from '@/components/BasicTextarea.vue';
    import BasicCheckbox from '@/components/BasicCheckbox.vue';
    import imageModalDelete from '@/assets/images/Illustrations/illustration-modal-delete.svg';
    import imageModalUnableRol from '@/assets/images/Illustrations/illustration-unable-error.svg';
    import imageModalAbleRol from '@/assets/images/Illustrations/illustration-able-success.svg';
    import {mapGetters} from 'vuex';
    import {EventBus} from "@/main";
    import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import utils from '@/common/utils';
    import {
        STORE as PermissionsStore,
        GET_PERMISSIONS,
        GET_PROFILES,
        GET_TOTAL,
        GET_CURRENT_PAGE,
        SET_CURRENT_PAGE,
        GET_OBJECT_PAGE,
        SET_SEARCH_TEXT,
        GET_LOADING_ITEMS,
        SET_LOADING_ITEMS
    } from "@/store/modules/permissions/permissions.types";
    import {
        WARNING_EVENT,
        SUCCESS_SAVE
    } from '@/store/events';
    const { required, maxLength } = require('vuelidate/lib/validators');


    export default {
        name: "PermissionsList",
        components: {
            FloatingTitleLayout,
            CardSimple,
            EmptyState,
            SearchBar,
            BasicSwitch,
            ModalDefault,
            ModalAlert,
            BasicInput,
            BasicTextarea,
            CollapseInfo,
            BasicCheckbox
        },
        mixins: [ToastSnotify],
        data() {
            return {
                imageModalDelete: imageModalDelete,
                imageModalUnableRol: imageModalUnableRol,
                imageModalAbleRol: imageModalAbleRol,
                profileName: "",
                profileDescription: "",
                profileId: "",
                profileSelected: {},
                searchText: "",
                actualPage: 1,
                isChanged: false,
                isEdit: false,
                modalNewRol: "modal-newRol"
            }
        },
        computed: {
            ...mapGetters(PermissionsStore, {
                permissions: GET_PERMISSIONS,
                profiles: GET_PROFILES,
                total: GET_TOTAL,
                currentPage: GET_CURRENT_PAGE,
                perPage: GET_OBJECT_PAGE,
                isLoading: GET_LOADING_ITEMS
            }),
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
        },
        validations: {
            profileName: {
                required
            },
            profileDescription: {
                required,
                maxLength: maxLength(200)
            }

        },
        methods: {
            pageChange(page) {
                this.actualPage= page;
                this.$store.commit(`${PermissionsStore}/${SET_CURRENT_PAGE}`, this.actualPage);
                this.$store.dispatch(`${PermissionsStore}/list`,this.currentOrganization._id);
            },
            searchTextChange(searchText) {
                utils.debounceFixed(() => {
                    this.searchText = searchText;
                    this.$store.commit(`${PermissionsStore}/${SET_SEARCH_TEXT}`, searchText)
                    this.$store.dispatch(`${PermissionsStore}/list`, this.currentOrganization._id)
                })
            },
            newProfile(){
                this.isEdit = false;
                this.clearProfileProperties();
                this.$bvModal.show(this.modalNewRol);
            },
            clearProfileProperties(){
                this.profileName = "";
                this.profileDescription = "";
                this.profileId = "";
            },
            editProfile(){
                this.getProfileSelected();
                this.isEdit = true;
                this.profileName = this.profileSelected.name;
                this.profileDescription = this.profileSelected.description;
                this.profileId = this.profileSelected._id;
                this.$bvModal.show(this.modalNewRol);
            },
            createProfile(){
                const params= {
                    _id: this.profileId,
                    profileName: this.profileName,
                    profileDescription: this.profileDescription,
                    organizationId: this.currentOrganization._id,
                    profileType: "OWNER"
                }

                this.$store.dispatch(`${PermissionsStore}/createProfile`,params)
                    .then(() => {
                        this.clearProfileProperties();
                        this.$bvModal.hide(this.modalNewRol);
                    })

            },
            isOrganizationPermission(profile,permissionId){
                return profile.permissions.includes(permissionId)
            },
            selectIndex (data) {
                this.isChanged = true;
                this.getProfileSelected();
                if(data.parent){
                    const children= this.$refs[`PERMISSIONS-${this.profileSelected._id}-${data.parent._id}`];
                    children.forEach((child)=> {
                        child.checkboxValue= data.checkValue;
                    })
                }
            },
            // Para verificar si deberia estar seleccionado el checkbox padre
            checkIsSelected(parent, profile){
                const permission= this.permissions[parent.key];
                const permissionsChecked= [];
                    permission.permissions.forEach((p) => {
                        profile.permissions.forEach((permissionProfile)=> {
                            if(permissionProfile === p._id){
                                permissionsChecked.push(permissionProfile._id);
                            }
                        })
                    })

                return permissionsChecked.length === permission.permissions.length
            },
            // Select the profile
            setProfileSelected(profile){
                this.cancel();
                this.profileSelected = profile;
                this.$store.commit(`${PermissionsStore}/${SET_LOADING_ITEMS}`, true);
            },
            getProfileSelected(){
                const tabsProfiles= this.$refs.PROFILE_ACTIVE;
                tabsProfiles.forEach((tab) =>{
                    if(tab.localActive === true){
                        const profileId= tab.id.split("-")[1];
                        this.profiles.forEach((profile)=> {
                            if(profile._id === profileId){
                                this.profileSelected= profile;
                            }
                        })
                    }
                })
            },
            savePermissions(){
                this.getProfileSelected();
                let checkboxObjects= [];
                // Se obtienen todos los checkbox de todos los permisos, estan identificados segun el perfil y el id del padre.
                for(const item in this.permissions){
                    if(item){
                        const parent= this.permissions[item];
                        const checkboxParent= this.$refs[`PERMISSIONS-${this.profileSelected._id}-${parent._id}`];
                        checkboxObjects= [...checkboxObjects,...checkboxParent];
                    }
                }
                // Se obtienen los que estan seleccionados.
                const checkboxSelected= [];
                const permissionsList = [];
                checkboxObjects.forEach((object)=>{
                    if(object.checkboxValue === true){
                        checkboxSelected.push(object.index);
                    }
                    permissionsList.push(object.index);
                })

                const params={
                    permissions: checkboxSelected,
                    profileId: this.profileSelected._id,
                    organizationId: this.currentOrganization._id,
                    allPermissionsPage: permissionsList
                }

                this.$store.dispatch(`${PermissionsStore}/addPermissions`, params);
            },
            permissionNumber(num, size){
                return (`000000000${num}`).substr(-size);
            },
            showModalActive(active){
                if(active === true){
                    this.$bvModal.show('modal-ableRol');
                }else{
                    this.$bvModal.show('modal-unableRol');
                }
            },
            activeProfile(active){
                this.getProfileSelected();
                const params={
                    profileId: this.profileSelected._id,
                    active: active,
                    organizationId: this.currentOrganization._id
                }

                this.$store.dispatch(`${PermissionsStore}/activeProfile`, params);
                if(active === true){
                    this.$bvModal.hide('modal-ableRol');
                }else{
                    this.$bvModal.hide('modal-unableRol');
                }
            },
            setSwitchValue(value){
                this.getProfileSelected();
                const switchObject= this.$refs[`PROFILE-ACTIVE-${this.profileSelected._id}`];
                switchObject[0].switchValueComp= value;
            },
            deleteProfile(){
                const params={
                    profileId: this.profileSelected._id,
                    organizationId: this.currentOrganization._id
                }

                this.$store.dispatch(`${PermissionsStore}/deleteProfile`, params);
                this.$bvModal.hide('modal-danger');
            },
            cancel(){
                this.isChanged= false;
                this.$store.dispatch(`${PermissionsStore}/list`, this.currentOrganization._id);

                this.getProfileSelected();
                // Se obtienen todos los checkbox de todos los permisos, estan identificados segun el perfil y el id del padre.
                for(const item in this.permissions){
                    if(item){
                        const parent= this.permissions[item];
                        const checkboxObjects= this.$refs[`PERMISSIONS-${this.profileSelected._id}-${parent._id}`];
                        const parentRef= this.$refs[`PARENTS-${this.profileSelected._id}-${parent._id}`];

                        checkboxObjects.forEach((object)=>{
                            if(this.isOrganizationPermission(this.profileSelected,object.index)){
                                object.checkboxValue= true;
                                if(this.checkIsSelected(parent,this.profileSelected)){
                                    parentRef[0].$children[0].checkboxValue= true;
                                }
                            }else{
                                object.checkboxValue= false;
                                parentRef[0].$children[0].checkboxValue= false;
                            }
                        })
                    }
                }
            }
        },
        mounted(){
            this.$store.dispatch(`${PermissionsStore}/list`, this.currentOrganization._id);
        },
        beforeDestroy() {
            EventBus.$off(PermissionsStore + WARNING_EVENT);
            EventBus.$off(PermissionsStore + SUCCESS_SAVE);
        },
        created() {
            EventBus.$on(PermissionsStore + WARNING_EVENT, (message) => {
                this.displayNotificationWarning(message);
            });
            EventBus.$on(PermissionsStore + SUCCESS_SAVE, (message) => {
                this.displayNotificationSuccess(message);
            });
        }
    }
</script>
