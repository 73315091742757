<template>
    <div :id="idClientType" class="square-opt" :class="clazz">
      <input type="radio" name="role" :id="radioId" :aria-label="radioId" :checked="checked">
      <div>
        <img v-if="fisica" class="img-fluid" src="@/assets/images/Illustrations/ilustracion-persona-fisica.svg" alt="fisica" />
        <img v-if="!fisica" class="img-fluid" src="@/assets/images/Illustrations/ilustracion-persona-moral.svg" alt="moral" />
        <span class="title-card"> {{titleList}} </span>
        <ul class="list-type">
            <slot></slot>
        </ul>
      </div>
    </div>
</template>

<script>
    export default {
        name: 'ClientType',
        props: {
            radioId: String,
            clazz: String,
            fisica: {type: Boolean, default: false},
            titleList: String,
            idClientType: String,
            checked: {type: Boolean, default: false}
        }
    }
</script>

<style lang="scss">
    .persona-fisica-moral {
        margin: 0 auto 30px;
        display: flex;
        max-width: 100%;
        width: 100%;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .square-opt {
            display: inline-flex;
            height: 100%;
            float: left;
            position: relative;
            transition: 0.5s;

            &:first-of-type > div::before {
                content: "";
                height: 100%;
                width: 1px;
                position: absolute;
                top: 0;
                right: -25px;
            }

            &:first-of-type > div::after {
                content: "ó";
                height: 40px;
                width: 9px;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                right: -29px;
            }

            &:first-of-type > div {
                margin-left: 0;
            }

            &:last-of-type > div {
                margin-right: 0;
            }

            > input[type="radio"] {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0;
                &:checked + div {
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
                }
            }

            > div {
                padding: 10px;
                margin: 0 25px;
                height: 100%;
                position: relative;
                border-radius: 6px;
                transition: 0.5s;
            }

            > div > img {
                display: flex;
                margin: 0 auto 10px;
                height: 120px;
            }

            > div > .title-card {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.39;
                display: block;
                margin-bottom: 10px;
            }

            > div > .list-type {
                margin: 0;
                padding: 0;

                li {
                    position: relative;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 1.73;
                    display: block;
                    margin-bottom: 5px;
                    padding-left: 15px;

                    &::before {
                        content: "";
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 6px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>
