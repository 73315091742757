<template>
    <CardSimple
        :title="$t('pagos.campos-informativos.title')"
        :subtitle="$t('pagos.campos-informativos.subtitle')"
    >

        <div class="row m-b-20">
            <div class="col-12">
                <div class=" justify-content-end d-flex">
                </div>
            </div>
        </div>

        <div
            v-for="id in payment.fieldsInfo.length"
            :key="id"
        >
            <div class="d-flex align-items-c m-b-30">
                <div class="w-100 p-r-30">
                    <BasicInput
                        clazz="m-b-0"
                        required
                        v-model="payment.fieldsInfo[id - 1].description"
                        @input="change"
                        labelFor="servicio"
                        inputId="servicio"
                        :label="$t('pagos.campos-informativos.input.title')"
                        :inputPlaceholder="$t('pagos.campos-informativos.input.placeholder')"
                    >
                    </BasicInput>

                </div>
                <button
                    class="button-circle button-ghost button-error sm f-20 m-t-15"
                    @click="deleteField(id - 1)"
                >
                    <i aria-hidden="true" class="banana banana-delete"></i>
                </button>
            </div>
        </div>

        <a
            href
            class="button-square-add"
            tabindex
            @click="addField"
        >
            <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{ $t('pagos.campos-informativos.add-field') }}
        </a>

        <div class="row m-t-40">
            <div class="col-12 vertical-center justify-content-end">
                <button
                    v-if="!editMode"
                    class="button button-ghost sm button-plain_text m-0-auto-0-0"
                    @click="prev"
                >
                    <i aria-hidden="true" class="banana banana-chevron-left"></i>
                    {{ $t('general.actions.back.title') }}
                </button>

                <div class="vertical-center">
                    <button
                        class="button button-ghost sm button-plain_text"
                        @click="cancel"
                    >
                        {{ $t('general.actions.cancel.title') }}
                    </button>

                    <button
                        v-if="!editMode"
                        class="button button-filled sm button-accent1 m-l-10"
                        :class="{disabled: !isValid}"
                        @click="next"
                    >
                        {{ $t('general.actions.next.title') }}
                    </button>

                    <button
                        v-if="editMode"
                        class="button button-filled sm button-accent1 m-l-10"
                        :class="{disabled: !isValid}"
                        @click="finish"
                    >
                        {{ $t('general.actions.save') }}
                    </button>
                </div>
            </div>
        </div>

    </CardSimple>
</template>

<script>
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from '@/components/CardSimple.vue';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
    name: 'info-fields',
    components: {
        CardSimple,
        BasicInput,
    },
    props: {
        editMode: {type: Boolean, default: false},
        payment: {
            type: Object,
            default: function () {
                return {
                    fields: []
                };
            }
        }
    },
    data () {
        return {
            isValid: true
        }
    },
    methods: {
        cancel () {
            this.$emit('cancel');
        },
        next() {
            this.$emit('next');
        },
        prev() {
            this.$emit('prev');
        },
        finish() {
            this.$emit('finish');
        },
        deleteField (i) {
            this.payment.fieldsInfo.splice(i, 1);
            this.change();
            this.$forceUpdate();
        },
        addField (e) {
            const newField = {
                id: '',
                description: ''
            };
            this.payment.fieldsInfo.push(newField);
            e.preventDefault();
            this.change();
            this.$forceUpdate();
        },
        change() {
            this.isValid = !this.payment.fieldsInfo.find(f => !f.description || f.description.length <= 0);
            this.$forceUpdate();
        }
    },
    validations: {
        payment: {
            fields: {
                $each: {
                    description: {
                        required,
                        minLength: minLength(2)
                    }
                }
            }
        }
    }
}
</script>
