<template>
    <div class="collapse-info-list" :class="clazz">
        <div class="head-info">
            <span :class="visible ? null : 'collapsed'" :aria-expanded="visible ? 'true' : 'false'" :aria-controls="collapseId" @click="visible = !visible">
                <i aria-hidden="true" class="banana banana-chevron-down"></i>
                <label><strong> {{permissionName}} </strong></label>
            </span>
            <span>
                <p> {{permissionNumber}} </p>
            </span>
            <span v-if="checkboxRight">
                <BasicCheckbox :index="indexCheckbox" :value="currentValue" @change="selectIndex" clazz="m-b-0"></BasicCheckbox>
            </span>
        </div>
        <b-collapse :id="collapseId" class="collapse-body" v-model="visible">
            <slot></slot>
        </b-collapse>
    </div>
</template>

<script>
    import BasicCheckbox from '@/components/BasicCheckbox.vue';

    export default {
        name: 'CollapseInfo',
        props: {
            clazz: String,
            collapseId: String,
            permissionName: String,
            permissionNumber: String,
            permission: {},
            indexCheckbox: {type: Number, default: null},
            currentValue: {type: Boolean, default: false},
            checkboxRight: {type: Boolean, default: true}
        },
        components: {
            BasicCheckbox,
        },
        data() {
            return {
                visible: false
            }
        },
        methods: {
            selectIndex(data){
                data.parent= this.permission;
                this.$emit('change', data);
            }
        }
    }
</script>
