export const STORE = 'SESSION';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_TOKEN_EXPIRE_DATE = 'SET_TOKEN_EXPIRE_DATE';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_USER = 'GET_USER';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_TOKEN_EXPIRE_DATE = 'GET_TOKEN_EXPIRE_DATE';
export const CHANGE_PROFILE_JWT = "CHANGE_PROFILE_JWT";
export const CHANGE_OTHER_PROFILE_JWT = 'CHANGE_OTHER_PROFILE_JWT';
export const GET_LOCATION = 'GET_LOCATION';
export const SET_LOCATION = 'SET_LOCATION';
