import userApi from "../../../api/user/users.api";
import base from "../../../api/base.api";
import imageCard from '@/assets/images/Illustrations/illustration-card-id.svg'
import imageCardBack from '@/assets/images/Illustrations/illustration-card-id-back.svg'

const stateGlobal = {
    user: {},
    professions: [],
    ocupations: [],
    actualUser: {},
    isPhotoStepTwo: true,
    isMexican: true,
    formStep: 1,
    showBackButtonForm: false,
    showNextButtonForm: false,
    isValidStepFormSave: false,
    isValidStepFourWizard: false,
    isLoadUser: false,
    errorNextStepMessage: '',
    errorNextStep: false,
};

const gettersGlobal = {
    getActualUser: (state) => state.actualUser,
    getFormStep: (state) => state.formStep,
    getIsLoadUser: (state) => state.isLoadUser,
    getShowBackButtonForm: (state) => state.showBackButtonForm,
    getShowNextButtonForm: (state) => state.showNextButtonForm,
    getIsValidStepFormSave: (state) => state.isValidStepFormSave,
    getIsValidStepFourWizard: (state) => state.isValidStepFourWizard,
    getErrorNextStep: (state) => state.errorNextStep,
    getErrorNextStepMessage: (state) => state.errorNextStepMessage,
};

const actions = {
    async makeActionForStep({dispatch, getters, commit}, {actualStep, paramsStep}){
        let response = null;
        switch (actualStep) {
            case 0:
                commit("IS_PHOTO", paramsStep.isPhoto);
                commit('SET_FORM_STEP', 1);
                return {
                    data: {
                        error: false,
                        message: "Primer paso finalizado"
                    }
                }
            case 1:
                if(paramsStep.isPhoto){
                    if(paramsStep.validate){
                        dispatch('retrieveDataFromINE', {root: true})
                    } else if(stateGlobal.ineValidationStatus.ineValid) {
                        commit('SET_UPLOAD_BOX_STATUS',{
                            showLoadBox: true,
                            showLoadProgress: false,
                            showLoadStatus: false,
                            retry: false,
                            success: false,
                            loadText: "Asegúrate de tomar la foto de frente, procurando que tu cabello no tape tu cara",
                            statusTitle: "Tomar fotografía",
                            statusText: "Ahora una foto tuya",
                            titleBox: "Fotografía:"
                        });
                        commit('SET_SELFIE_PHOTO_TAKEN',false);
                    }
                } else {
                    commit("IS_PHOTO", false);
                    commit('SET_BACK_FORM_BUTTON', false);
                    commit('SET_NEXT_FORM_BUTTON', false);
                }
                return {
                    data: {
                        error: false,
                        message: "Primer paso finalizado"
                    }
                }
            case 2:
                dispatch('getFullActualUser', {
                    userId: paramsStep.userId,
                    root: true
                });
                return {
                    data: {
                        error: false,
                        message: "Segundo paso finalizado"
                    }
                }
            case 3:
                response = await userApi.saveStepFourWizard(getters.getActualUser);
                dispatch('getFullActualUser', {
                    userId: paramsStep.userId,
                    root: true
                });
                return response;
            case 4:
                return userApi.confirmWizardRegister(getters.getActualUser._id);
            default:
                return {
                    data: {
                        error: false,
                        message: "Segundo paso finalizado"
                    }
                }
        }
    },
    async makeBack({dispatch, commit}, {actualStep, paramsStep}){
        switch (actualStep) {
            case 0:
                break;
            case 1:
                commit("IS_PHOTO", false);
                commit('SET_BACK_FORM_BUTTON', false);
                commit('SET_NEXT_FORM_BUTTON', false);
                dispatch('turnOffCamera', {root: true});
                commit('SET_CAMERA_STATUS', false);
                break;
            case 2:
                commit("IS_PHOTO", paramsStep.isPhoto);
                if(!paramsStep.isPhoto){
                    commit('SET_FORM_STEP', 3);
                }
                dispatch('turnOffCamera', {root: true});
                commit('SET_CAMERA_STATUS', false);
                break;
            default:
                break;

        }
    },
    async saveFormStep({getters, commit}){
        const params = {
            stepForm: getters.getFormStep,
            actualUser: getters.getActualUser
        };
        const response = await userApi.saveCurrentStepForm(params);
        if(response.data.error){
            return {
                error: true,
                message: response.data.message
            }
        }
            const newStep = getters.getFormStep + 1;
            commit('SET_FORM_STEP', newStep);
            return {
                error: false,
                message: response.data.message
            }
    },
    // ------------------------------------ Step One Actions --------------
    async getCurrentUserSession({commit}) {
        const response = await userApi.getCurrentUserSession();
        if (response.data.error) {
            // TODO: Alert message
            commit(`SET_ALERT_MESSAGE`, {
                message: response.data.message
            });
            commit('SET_LOADING_CONFIRM', false);
        } else {
            commit('ACTUAL_USER', response.data.user);

        }
    },
    getCurrentUserSessionAsync({commit}, {userId}) {
        return new Promise(function(resolve, reject) {
            userApi.getCurrentUserSession(userId , (data)=>{
                if (data.data.error) {
                    // TODO: Alert message
                    commit(`SET_ALERT_MESSAGE`, {
                        message: data.data.message
                    });
                    commit('SET_LOADING_CONFIRM', false);
                    resolve();
                } else {
                    commit('ACTUAL_USER', data.data.user);
                    reject();
                }
            })
        })
    },
    async getFullActualUser({commit}) {
        const response = await userApi.getFullActualUser();
        if (response.data.error) {
            // TODO: Alert message
            commit(`SET_ALERT_MESSAGE`, {
                message: response.data.message
            });
            commit('SET_LOADING_CONFIRM', false);
        } else {
            commit('ACTUAL_USER', response.data.user);

        }
    },
    updateUploadBoxStatus({commit}, status){
        commit("SET_UPLOAD_BOX_STATUS", status);
    },

    /**
     * Envia el código de verificación al teléfono registrado por el usuario.
     **/
    // eslint-disable-next-line no-empty-pattern
    sendVerificationCode(context,params){
        return new Promise((resolve, reject) => {
            userApi.sendVerificationCode(params,
                function(result) {
                    if(result.data.error){
                        reject(result.data);
                    } else {
                        resolve(result.data);
                    }
                })
        });

    },

    /**
     *  Verifica el código recibido por sms.
     **/
    // eslint-disable-next-line no-empty-pattern
    verifyCode(context, params){
        return new Promise((resolve) => {
            userApi.verifyCode(params,
                function(result){
                    if(result.data.error){
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
        });
    },
    async redirectToHome({token}) {
        window.location.href= `${base.baseUrl }/users/redirect-home/session-transform/${ token}`
    },
};

const mutations = {
    SET_TEST_USER(state, data) {
        state.ineInfo = data;
    },
    // Step one mutations
    ACTUAL_USER(state, value){
        state.actualUser = value;
        state.isLoadUser = true;
    },
    IS_PHOTO(state, value){
        state.isPhotoStepTwo = value;
    },
    // Step two mutations
    SELFILE_UPLOADED(state, saved) {
        state.selfieSaved = saved;
    },
    SET_LOADING(state, value){
        state.isLoadingConfirm = value;
    },
    SET_CAMERA_STATUS(state, value){
        state.isCameraOn = value;
        state.cameraTimer = 3;
    },
    SET_CAMERA_TIMER(state, time) {
        state.cameraTimer = time;
    },
    SET_CAMERA_COUNTDOWN_STATUS(state, status) {
        state.cameraCountdownStatus = status;
    },
    SET_INE_IMAGES_STATUS(state, status) {
        state.ineImagesStatus = {...status};
        if(state.ineImagesStatus.frontalIneCropped){
            state.uploadBoxImageUrl = imageCardBack;
        } else {
            state.uploadBoxImageUrl = imageCard;
        }
    },
    SET_SELFIE_STATUS(state, status) {
        state.selfieStatus = {...status};
    },
    SET_UPLOAD_BOX_STATUS(state, status){
        state.uploadBoxStatus = {...status};
    },
    SET_INE_VALIDATION_STATUS(state, status){
        state.ineValidationStatus.showValidateIneButton = status.showValidateIneButton;
        state.ineValidationStatus.ineValidated = status.ineValidated;
        state.ineValidationStatus.ineValid = status.ineValid;
    },
    SET_LOADING_INE_VALIDATION(state, status){
        state.loadingIneData = status;
    },
    SET_INE_PHOTOS(state, {front, back }){
        state.frontalInePhoto.name = front.name;
        state.frontalInePhoto.size = front.size;
        state.frontalInePhoto.image = front.image;
        state.frontalInePhoto.type = front.type;
        state.frontalInePhoto.classification = front.classification;

        state.backInePhoto.name = back.name;
        state.backInePhoto.size = back.size;
        state.backInePhoto.image = back.image;
        state.backInePhoto.type = back.type;
        state.backInePhoto.classification = back.classification;

        state.backInePhoto = back;
    },
    UPDATE_NAME_ACTUAL_USER(state, value){
        state.actualUser.name = value
    },
    UPDATE_LAST_NAME_ACTUAL_USER(state, value){
        state.actualUser.lastName = value
    },
    UPDATE_LAST_NAME_MOTHER_ACTUAL_USER(state, value){
        state.actualUser.secondLastName = value
    },
    UPDATE_NATIONALITIE_ACTUAL_USER(state, value){
        state.actualUser.nationalitie = value
        if(value === "MEXICANA"){
            state.isMexican = true;
        } else {
            state.isMexican = false;
        }
    },
    UPDATE_OCCUPACION_ACTUAL_USER(state, value){
        state.actualUser.ocupacion = value
    },
    UPDATE_GENDER_ACTUAL_USER(state, value){
        state.actualUser.gender = value
    },
    UPDATE_BIRTHDAY_ACTUAL_USER(state, value){
        state.actualUser.birthDay = value
    },
    UPDATE_OCCUPATION_ACTUAL_USER(state, value){
        state.actualUser.ocupacion = value
    },
    UPDATE_PROFESSION_ACTUAL_USER(state, value){
        state.actualUser.profession = value
    },
    UPDATE_STATE_ACTUAL_USER(state, value){
        state.actualUser.state = value;
    },
    UPDATE_OFICIAL_ID_ACTUAL_USER(state, value){
        state.actualUser.oficialIdTemp = value;
    },
    SET_FORM_STEP(state, value){
        state.formStep = value;
        switch (state.formStep) {
            case 1:
                state.showBackButtonForm = false;
                state.showNextButtonForm = true;
                break;
            case 2:
                state.showBackButtonForm = true;
                state.showNextButtonForm = true;
                break;
            case 3:
                state.showBackButtonForm = true;
                state.showNextButtonForm = true;
                if(!state.actualUser.oficialId){
                    state.isValidStepFormSave = false;
                }
                break;
            default:
                state.showBackButtonForm = false;
                state.showNextButtonForm = false;
        }

    },
    SET_BACK_FORM_BUTTON(state, value){
        state.showBackButtonForm = value;
    },
    SET_NEXT_FORM_BUTTON(state, value){
        state.showNextButtonForm = value;
    },
    SET_IS_VALID_USER_SAVE_FORM(state, value){
        state.isValidStepFormSave = value;
    },
    UPDATE_TAKE_SELFIE_LATER_CHECKBOX(state, value){
        state.takeSelfieLaterCheckbox = value;
    },
    SET_IS_VALID_STEP_FOUR(state, value){
        state.isValidStepFourWizard = value;
    },
    UPDATE_PHONE_USER(state, value){
        state.actualUser.phone = value;
    },
    SET_ERROR_NEXT_STEP(state, value){
        state.errorNextStep = value
    },
    SET_ERROR_MESSAGE_NEXT(state, message){
        state.errorNextStepMessage = message
    },
    SET_INE_PHOTO_TAKEN(state, taken){
        state.ineImagesStatus.photoTaken = taken;
    },
    SET_SELFIE_PHOTO_TAKEN(state, taken){
        state.selfieStatus.selfieTaken = taken;
    },
    SET_CAMERA_AVAILABLENESS(state, taken){
        state.cameraAvailableness = taken;
    },
    UPDATE_UPLOAD_BOX_STATUS(state,value){
        state.uploadBoxStatus.success = value.uploadBoxStatusSuccess;
        state.uploadBoxStatus.showLoadStatus = value.uploadBoxStatusShowLoadStatus;
        state.uploadBoxStatus.titleBox = value.uploadBoxStatusTitleBox;
        state.uploadBoxStatus.statusTitle = value.uploadBoxStatusStatusText;
        state.uploadBoxStatus.loadText = value.uploadBoxStatusStatusTitle;
        state.uploadBoxStatus.showLoadBox = value.uploadBoxStatusShowLoadBox;
    }
};


export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    }
};
