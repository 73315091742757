<template>
    <div>
        <form>
            <StepValidation :text="getErrorNextStepMessage" icon="banana-cross-normal" status="error" v-if="getErrorNextStep"></StepValidation>
            <div class="row">
                <div class="col-12">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.rfc"
                            inputType="text"
                            labelFor="rfc"
                            inputId="rfcOther"
                            :label="$t('signup-organization.step-two.invoice-form.rfc')"
                            :clazz="getErrorClass('rfc')"
                            v-on:basicInput="updateRfc"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.rfc-placeholder')"
                            :warningIcon="$v.rfc.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.rfc.$error ? $v.rfc.$params.required.message : ''" />
                </div>
                <div class="col-12">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.curp"
                            inputType="text"
                            labelFor="curp"
                            inputId="curpOther"
                            :label="$t('signup-organization.step-two.invoice-form.curp')"
                            :clazz="getErrorClass('curp')"
                            v-on:basicInput="updateCurp"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.curp-placeholder')"
                            :warningIcon="$v.curp.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.curp.$error ? $v.curp.$params.required.message : ''" />
                </div>

                <div class="col-12">
                    <div class="text-divider"><label>{{$t('signup-organization.step-two.direccion')}}</label></div>
                </div>

                <div class="col-12">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.streetAddress"
                            inputType="text"
                            labelFor="streetAddress"
                            inputId="streetAddress"
                            :label="$t('signup-organization.step-two.invoice-form.streetAddress')"
                            :clazz="getErrorClass('streetAddress')"
                            v-on:basicInput="updateStreetAddress"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.streetAddress-placeholder')"
                            :warningIcon="$v.streetAddress.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.streetAddress.$error ? $v.streetAddress.$params.required.message : ''"
                            help
                            idHelpTooltip="streetAddressHelp"
                            helpIcon="banana banana-question-circle1">
                            <template v-slot:tooltipHelp>
                                <b-tooltip target="streetAddressHelp" placement="top" custom-class="white-tooltip">
                                    <div>
                                        <p class="m-b-0">
                                            {{$t('signup-organization.step-two.invoice-form.streetAddress-info')}}
                                        </p>
                                    </div>
                                </b-tooltip>
                            </template>
                    </BasicInput>
                </div>

                <div class="col-12 col-md-6">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.exteriorNumber"
                            inputType="text"
                            labelFor="exteriorNumber"
                            inputId="exteriorNumber"
                            :label="$t('signup-organization.step-two.invoice-form.noExt')"
                            :clazz="getErrorClass('exteriorNumber')"
                            v-on:basicInput="updateExteriorNumber"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.noExt-placeholder')"
                            :warningIcon="$v.exteriorNumber.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.exteriorNumber.$error ? $v.exteriorNumber.$params.required.message : ''"
                            help
                            idHelpTooltip="exteriorNumberHelp"
                            helpIcon="banana banana-question-circle1">
                            <template v-slot:tooltipHelp>
                                <b-tooltip target="exteriorNumberHelp" placement="top" custom-class="white-tooltip">
                                    <div>
                                        <p class="m-b-0">
                                            {{$t('signup-organization.step-two.invoice-form.noExt-info')}}
                                        </p>
                                    </div>
                                </b-tooltip>
                            </template>
                    </BasicInput>
                </div>

                <div class="col-12 col-md-6">
                    <BasicInput
                            :inputValue="getPotentialOrganization.invoiceData.interiorNumber"
                            inputType="text"
                            labelFor="interiorNumber"
                            inputId="interiorNumber"
                            :label="$t('signup-organization.step-two.invoice-form.noInt')"
                            v-on:basicInput="updateInteriorNumber"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.noInt-placeholder')"
                            help
                            idHelpTooltip="interiorNumberHelp"
                            helpIcon="banana banana-question-circle1">
                        <template v-slot:tooltipHelp>
                            <b-tooltip target="interiorNumberHelp" placement="top" custom-class="white-tooltip">
                                <div>
                                    <p class="m-b-0">
                                        {{$t('signup-organization.step-two.invoice-form.noInt-info')}}
                                    </p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicInput>
                </div>

                <div class="col-12 col-md-6">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.district"
                            inputType="text"
                            labelFor="district"
                            inputId="district"
                            :label="$t('signup-organization.step-two.invoice-form.district')"
                            :clazz="getErrorClass('district')"
                            v-on:basicInput="updateDistrict"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.district-placeholder')"
                            :warningIcon="$v.district.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.district.$error ? $v.district.$params.required.message : ''"
                            help
                            idHelpTooltip="districtHelp"
                            helpIcon="banana banana-question-circle1">
                        <template v-slot:tooltipHelp>
                            <b-tooltip target="districtHelp" placement="top" custom-class="white-tooltip">
                                <div>
                                    <p class="m-b-0">
                                        {{$t('signup-organization.step-two.invoice-form.district-info')}}
                                    </p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicInput>
                </div>

                <div class="col-12 col-md-6">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.city"
                            inputType="text"
                            labelFor="city"
                            inputId="city"
                            :label="$t('signup-organization.step-two.invoice-form.city')"
                            :clazz="getErrorClass('city')"
                            v-on:basicInput="updateCity"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.city-placeholder')"
                            :warningIcon="$v.city.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.city.$error ? $v.city.$params.required.message : ''"
                            help
                            idHelpTooltip="cityHelp"
                            helpIcon="banana banana-question-circle1">
                        <template v-slot:tooltipHelp>
                            <b-tooltip target="cityHelp" placement="top" custom-class="white-tooltip">
                                <div>
                                    <p class="m-b-0">
                                        {{$t('signup-organization.step-two.invoice-form.city-info')}}
                                    </p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicInput>
                </div>

                <div class="col-12 col-md-6">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.state"
                            inputType="text"
                            labelFor="state"
                            inputId="state"
                            :label="$t('signup-organization.step-two.invoice-form.state')"
                            :clazz="getErrorClass('state')"
                            v-on:basicInput="updateState"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.state-placeholder')"
                            :warningIcon="$v.state.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.state.$error ? $v.state.$params.required.message : ''"
                            help
                            idHelpTooltip="stateHelp"
                            helpIcon="banana banana-question-circle1">
                        <template v-slot:tooltipHelp>
                            <b-tooltip target="stateHelp" placement="top" custom-class="white-tooltip">
                                <div>
                                    <p class="m-b-0">
                                        {{$t('signup-organization.step-two.invoice-form.state-info')}}
                                    </p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicInput>
                </div>

                <div class="col-12 col-md-6">
                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.invoiceData.zipCode"
                            inputType="number"
                            labelFor="zipCode"
                            inputId="zipCode"
                            :label="$t('signup-organization.step-two.invoice-form.zipCode')"
                            :clazz="getErrorClass('zipCode')"
                            v-on:basicInput="updateZipCode"
                            :inputPlaceholder="$t('signup-organization.step-two.invoice-form.zipCode-placeholder')"
                            :warningIcon="$v.zipCode.$error ? 'banana banana-warning-circle1' : ''"
                            :warningText="$v.zipCode.$error ? $v.zipCode.$params.required.message : ''"
                            help
                            idHelpTooltip="zipCodeHelp"
                            helpIcon="banana banana-question-circle1">
                        <template v-slot:tooltipHelp>
                            <b-tooltip target="zipCodeHelp" placement="top" custom-class="white-tooltip">
                                <div>
                                    <p class="m-b-0">
                                        {{$t('signup-organization.step-two.invoice-form.zipCode-info')}}
                                    </p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicInput>
                </div>

                <div class="col-12 m-b-10">
                    <BaseFormAnnotation :text="$t('general.required-fields')"></BaseFormAnnotation>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import BasicInput from '@/components/BasicInput.vue';
    import StepValidation from '@/components/StepValidation.vue';
    import BaseFormAnnotation from '@/components/signUpOrganizations/base/form/BaseFormAnnotation.vue';
    import {mapGetters} from 'vuex';
    import { required, minLength, helpers, numeric } from 'vuelidate/lib/validators';
    import i18n from '@/plugins/i18n';
    const RFC = helpers.regex('RFC',/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/)
    // eslint-disable-next-line max-len
    const CURP = helpers.regex('CURP', /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
)

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    const storeModule = "signUpOrganization";
    export default {
        name: "InvoiceDataForm",
        data () {
            return {
                rfc: '',
                curp: '',
                streetAddress: '',
                interiorNumber: '',
                exteriorNumber: '',
                district: '',
                city: '',
                state: '',
                zipCode: '',
                officialId: null,
            }
        },
        components: {
            BasicInput,
            StepValidation,
            BaseFormAnnotation
        },
        methods: {
            getErrorClass(propertie) {
                return this.$v[propertie].$error ? "has-error" : "";
            },
            updateRfc: debounce(function (rfc) {
                this.rfc = rfc;
                this.rfc = this.rfc.trim();
                this.clearNotificationError();
                this.$v.rfc.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_RFC`, this.rfc);
            }, 800, false),
            updateCurp: debounce(function (curp) {
                this.curp = curp;
                this.curp = this.curp.trim();
                this.clearNotificationError();
                this.$v.curp.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_CURP`, this.curp);
            }, 800, false),
            updateStreetAddress: debounce(function (streetAddress) {
                this.streetAddress = streetAddress;
                this.streetAddress = this.streetAddress.trim();
                this.clearNotificationError();
                this.$v.streetAddress.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_ADDRESS`, this.streetAddress);
            }, 800, false),
            updateExteriorNumber: debounce(function (exteriorNumber) {
                this.exteriorNumber = exteriorNumber;
                this.exteriorNumber = this.exteriorNumber.trim();
                this.clearNotificationError();
                this.$v.exteriorNumber.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_EXTERIOR_NUMBER`, this.exteriorNumber);
            }, 800, false),
            updateInteriorNumber: debounce(function (interiorNumber) {
                this.interiorNumber = interiorNumber;
                this.interiorNumber = this.interiorNumber.trim();
                this.clearNotificationError();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_INTERIOR_NUMBER`, this.interiorNumber);
            }, 800, false),
            updateDistrict: debounce(function (district) {
                this.district = district;
                this.district = this.district.trim();
                this.clearNotificationError();
                this.$v.district.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_DISTRICT`, this.district);
            }, 800, false),
            updateCity: debounce(function (city) {
                this.city = city;
                this.city = this.city.trim();
                this.clearNotificationError();
                this.$v.city.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_CITY`, this.city);
            }, 800, false),
            updateState: debounce(function (state) {
                this.state = state;
                this.state = this.state.trim();
                this.clearNotificationError();
                this.$v.state.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_STATE`, this.state);
            }, 800, false),
            updateZipCode: debounce(function (zipCode) {
                this.zipCode = zipCode;
                this.zipCode = this.zipCode.trim();
                this.clearNotificationError();
                this.$v.zipCode.$touch();
                this.validateStep();
                this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_ZIP_CODE`, this.zipCode);
            }, 800, false),
            updateOficialId: debounce(async function (imgFile) {
                this.clearNotificationError();
                if(imgFile){
                    this.officialId = await this.readFile(imgFile);
                    this.$v.officialId.$touch();
                    this.validateStep();
                    this.$store.commit(`${storeModule}/UPDATE_INVOICE_DATA_OFFICIAL_ID`, this.officialId);
                }
            }, 50, false),
            readFile(imgFile){
                return new Promise((resolve) => {
                    const fr = new FileReader();
                    fr.onload = (e) => {
                        const actualUserImg = {};
                        const dataUrl = e.target.result;
                        actualUserImg.name = imgFile.name;
                        actualUserImg.type = imgFile.type;
                        actualUserImg.size = imgFile.size;
                        actualUserImg.image = dataUrl;
                        resolve(actualUserImg)
                    };
                    fr.readAsDataURL(imgFile);
                });
            },
            async initializeValues() {
                if(!this.getPotentialOrganization.name){
                    await this.$store.dispatch('signUpOrganization/loadPotentialOrganization');
                }

                if (this.getPotentialOrganization.invoiceData) {
                    this.invoiceData = this.getPotentialOrganization.invoiceData;
                    if (this.getPotentialOrganization.invoiceData.streetAddress) {
                        this.streetAddress = this.getPotentialOrganization.invoiceData.streetAddress;
                    }
                    if (this.getPotentialOrganization.invoiceData.exteriorNumber) {
                        this.exteriorNumber = this.getPotentialOrganization.invoiceData.exteriorNumber;
                    }
                    if (this.getPotentialOrganization.invoiceData.district) {
                        this.district = this.getPotentialOrganization.invoiceData.district;
                    }
                    if (this.getPotentialOrganization.invoiceData.city) {
                        this.city = this.getPotentialOrganization.invoiceData.city;
                    }
                    if (this.getPotentialOrganization.invoiceData.state) {
                        this.state = this.getPotentialOrganization.invoiceData.state;
                    }
                    if (this.getPotentialOrganization.invoiceData.zipCode) {
                        this.zipCode = this.getPotentialOrganization.invoiceData.zipCode;
                    }
                }

                this.validateStep();
            },
            clearNotificationError(){
                this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, false);
                this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, '');
            },
            validateStep(){
                let isValid = true;
                isValid = !this.$v.streetAddress.$error &&
                    !this.$v.exteriorNumber.$error &&
                    !this.$v.district.$error &&
                    !this.$v.city.$error &&
                    !this.$v.state.$error &&
                    !this.$v.zipCode.$error &&
                    !this.$v.rfc.$error &&
                    !this.$v.curp.$error;
                // Se verifican los campos en lugar de hacerles touch para que no muestre en un inicio todos los errores de validacion pero que valide el Step.
                isValid = isValid &&
                    this.streetAddress !== '' &&
                    this.exteriorNumber !== '' &&
                    this.district !== '' &&
                    this.city !== '' &&
                    this.state !== '' &&
                    this.zipCode !== '';

                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_TWO`, isValid);
            },

        },
        validations: {
            rfc: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.rfc-invalid')},
                    required
                ),
                rfc: RFC
            },
            curp: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.curp-invalid')},
                    required
                ),
                curp: CURP
            },
            streetAddress: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.street-invalid')},
                    required
                ),
                minLength: minLength(3)
            },
            exteriorNumber: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.extNum-invalid')},
                    required
                ),
                minLength: minLength(1),
                numeric: numeric
            },
            interiorNumber: {
                minLength: minLength(1)
            },
            district: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.district-invalid')},
                    required
                ),
                minLength: minLength(3)
            },
            city: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.city-invalid')},
                    required
                ),
                minLength: minLength(3)
            },
            state: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.state-invalid')},
                    required
                ),
                minLength: minLength(3)
            },
            zipCode: {
                required: helpers.withParams(
                    {message: i18n.t('signup-organization.step-two.invoice-form.zipcode-invalid')},
                    required
                ),
                minLength: minLength(5),
                numeric: numeric
            },
        },
        computed: {
            ...mapGetters(storeModule, [
                'getPotentialOrganization',
                'getFormStep',
                'getErrorNextStep',
                'getErrorNextStepMessage'
            ])
        },
        mounted() {
            this.initializeValues();
        }
    }
</script>
