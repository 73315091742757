<template>
    <div class="title-principal-container" :class="clazz">
        <h1 class="title" :class="titleClazz">
            {{title}}
            <slot name="specialTitle"></slot>
        </h1>
        <p v-if="subtitle" class="subtitle" :class="subtitleClazz">
            {{subtitle}}
        </p>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'TitlePrincipal',
        props: {
            clazz: String,
            titleClazz: String,
            title: String,
            subtitleClazz: String,
            subtitle: String
        }
    }
</script>
