import permissionsApi from '@/api/permissions/permissions.api';
import {EventBus} from "@/main";
import {
    GET_PERMISSIONS,
    SET_PERMISSIONS,
    GET_PROFILES,
    SET_PROFILES,
    GET_TOTAL,
    SET_TOTAL,
    GET_CURRENT_PAGE,
    SET_CURRENT_PAGE,
    GET_OBJECT_PAGE,
    GET_ORGANIZATION,
    SET_ORGANIZATION,
    GET_SEARCH_TEXT,
    SET_SEARCH_TEXT,
    GET_LOADING_ITEMS,
    SET_LOADING_ITEMS
} from "./permissions.types";

import {
    WARNING_EVENT,
    SUCCESS_SAVE
} from '@/store/events';

import {STORE as PermissionsStore} from "../permissions/permissions.types";
import i18n from '@/plugins/i18n';
const storeName = PermissionsStore;

const stateGlobal = {
    permissions: {},
    profiles: {},
    total: 0,
    perPage: 10,
    currentPage: 1,
    selectedIndex: [],
    organization: "",
    searchText: "",
    loading: true
};

const gettersGlobal = {
    [GET_PERMISSIONS]: (state) => state.permissions,
    [GET_PROFILES]: (state) => state.profiles,
    [GET_TOTAL]: (state) => state.total,
    [GET_CURRENT_PAGE]: (state) => state.currentPage,
    [GET_OBJECT_PAGE]: (state) => state.perPage,
    [GET_ORGANIZATION]: (state) => state.organization,
    [GET_SEARCH_TEXT]: (state) => state.searchText,
    [GET_LOADING_ITEMS]: (state) => state.loading,
};

const mutations = {
    [SET_PERMISSIONS]: (state, value) =>{
        state.permissions= value;
    },
    [SET_PROFILES]: (state, value) =>{
        state.profiles= value;
    },
    [SET_TOTAL]: (state, value) =>{
        state.total= value;
    },
    [SET_CURRENT_PAGE]: (state, value) =>{
        state.currentPage= value;
    },
    [SET_ORGANIZATION]: (state, value) =>{
        state.organization= value;
    },
    [SET_SEARCH_TEXT]: (state, value) =>{
        state.searchText= value;
    },
    [SET_LOADING_ITEMS]: (state, loading)=>{
        state.loading = loading;
    },
};

const actions = {


    list({commit, getters}, organizationId){
        permissionsApi.list({
            currentPage: getters[GET_CURRENT_PAGE],
            perPage: getters[GET_OBJECT_PAGE],
            searchText: getters[GET_SEARCH_TEXT],
            organizationId: organizationId
        }, (result)=> {
            if(result.data.error === true){
                EventBus.$emit(storeName + WARNING_EVENT, result.data.message);
            }else{
                commit(SET_PERMISSIONS, result.data.object.docs);
                commit(SET_PROFILES, result.data.object.profiles);
                commit(SET_TOTAL, result.data.object.totalDocs);
                commit(SET_LOADING_ITEMS, false);
            }
        }, () => {
            EventBus.$emit(storeName + WARNING_EVENT, i18n.t('permissions.store.list-error'));
            commit(SET_PERMISSIONS, {});
            commit(SET_PROFILES, {});
            commit(SET_TOTAL, 0);
        });
    },

    createProfile({dispatch},params){
        return new Promise((resolve, reject) => {
            permissionsApi.createProfile(params, (result)=> {
                if(result.data.error === true){
                    EventBus.$emit(storeName + WARNING_EVENT, result.data.message);
                    return reject(result.data.message);
                }
                EventBus.$emit(storeName + SUCCESS_SAVE, i18n.t('permissions.store.create-profile.success'));
                dispatch("list", params.organizationId);
                return resolve(result.data.message);
            }, () => {
                EventBus.$emit(storeName + WARNING_EVENT, i18n.t('permissions.store.create-profile.error'))
                return reject();
            });
        })
    },

    async addPermissions({dispatch},params){
        const response = await permissionsApi.addPermissions(params);

        if(response.data.error === true){
            EventBus.$emit(storeName + WARNING_EVENT, response.data.message);
        }else{
            EventBus.$emit(storeName + SUCCESS_SAVE, response.data.message);
             dispatch("list", params.organizationId);
        }
    },

    activeProfile({dispatch},params){
        permissionsApi.activeProfile(params, (result)=> {
            if(result.data.error === true){
                EventBus.$emit(storeName + WARNING_EVENT, result.data.message);
            }else{
                EventBus.$emit(storeName + SUCCESS_SAVE, result.data.message);
                dispatch("list", params.organizationId);
            }
        }, () => {
            EventBus.$emit(storeName + WARNING_EVENT, i18n.t('permissions.store.active-profile.error'))
        });
    },

    async deleteProfile({dispatch},params){
        const response = await permissionsApi.deleteProfile(params);

        if(response.data.error === true){
            EventBus.$emit(storeName + WARNING_EVENT, response.data.message);
        }else{
            EventBus.$emit(storeName + SUCCESS_SAVE, response.data.message);
            dispatch("list", params.organizationId);
        }
    },

};

export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
