<template lang="html">
    <div class="content-centered" v-if="payment && payment.element">
        <router-link
                to="/client-admin/payment-template"
                class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                tabindex="">
            <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i>
            {{$t('detail-payment-template.return-payment-template')}}
        </router-link>
        <FloatingTitleLayout :title="isDebtPayment() ? payment.element.debt.name : payment.element.name"/>

        <b-alert class="alert-note" show dismissible variant="info">
            <p class="text-container">
                <img class="img-fluid" src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg" alt="info"/>
                <span> {{ getDetailInfo() }} <router-link
                        :to="{name: 'DetailPaymentTemplate', params: { idPaymentPending: payment._id.toString() }}"
                        class="c-accent2 text-underline" tabindex="" v-if="isDebtPayment()"> {{$t('detail-payment-template.general.enter-here')}} </router-link></span>
            </p>
        </b-alert>

        <CardSimple
                :title="$t('detail-payment-template.resumen.title')"
                :subtitle="$t('detail-payment-template.resumen.subtitle')">
            <div class="row">
                <div class="col-md-5 col-12 m-b-30">
                    <b-carousel>
                        <b-carousel-slide>
                            <template v-slot:img>
                                <img class="d-block img-fluid brd-3" src="https://api.pagando.mx/img/default/cover.svg"
                                     alt="image default">
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                </div>
                <div class="col-md-7 col-12 m-b-30">
                    <Badge :type="getClassStatusService()" :text="$t(getTextStatusService())"></Badge>
                    <Badge :type="isValid ? 'badge-success m-b-10' : 'badge-error m-b-10'"
                           :text="isValid ? $t('detail-payment-template.payment-status.valid') : $t('detail-payment-template.payment-status.error')"></Badge>

                    <h2 class="c-title f-20 body-font-regular m-b-10"> {{isDebtPayment() ? payment.element.debt.name :
                        payment.element.name}} </h2>
                    <h3 class="c-info_text f-14 body-font-regular f-bold text-upper  m-0 m-b-15"> {{
                        $t(`organizaciones.category.options.${payment.element.clasification}`) }} </h3>
                    <p class="c-plain_text f-14 body-font-regular m-0"> {{payment.element.description}} </p>
                </div>
            </div>

            <div class="row m-b-40">
                <div class="col-md-5 col-12 m-b-30">
                    <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0"> {{
                        $t('detail-payment-template.table.payment-type') }} </h4>
                    <p class="c-plain_text f-14 body-font-regular m-0"> {{ isDebtPayment() ?
                        $t('detail-payment-template.table.debt-payment') :
                        $t('detail-payment-template.table.regular-payment') }} </p>
                </div>
                <div class="col-md-7 col-12 m-b-30">
                    <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0"> {{
                        $t('detail-payment-template.table.additional-information') }} </h4>

                    <p class="c-placeholder f-italic f-14 body-font-regular m-0"
                       v-for="info in payment.element.fieldsInfo" v-bind:key="info.description">
                        {{ info.description }}
                    </p>

                    <p class="c-placeholder f-italic f-14 body-font-regular m-0"
                       v-if="payment.element.fieldsInfo.length <= 0">
                        {{ $t('detail-payment-template.table.not-additional-info') }}
                    </p>

                </div>
            </div>

            <div class="row m-t-40 m-b-40" v-if="isDebtPayment()">
                <div class="col-md-5 col-12 m-b-30">
                    <h1 class="c-title f-20 principal-font-bold m-b-20"> Campos </h1>
                    <ul class="m-0 p-0" v-for="field in payment.element.debt.fields" v-bind:key="field.name">
                        <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- {{ field.name }}</li>
                    </ul>
                </div>
                <div class="col-md-7 col-12 m-b-30">
                    <h1 class="c-title f-20 principal-font-bold m-b-20"> Referencias </h1>
                    <ul class="m-0 p-0" v-for="ref in payment.element.debt.references" v-bind:key="ref.name">
                        <li class="f-14 c-plain_text body-font-regular d-block m-b-10">- {{ ref.name }}</li>
                    </ul>
                </div>
            </div>

            <div class="row m-t-40" v-if="!isDebtPayment()">
                <div class="col-12">
                    <h1 class="c-title f-20 principal-font-bold m-b-20">
                        {{$t('detail-payment-template.table.concepts.title')}} </h1>
                    <div class="table-dashboard-services">
                        <div class="basic-table">
                            <b-table
                                    responsive
                                    hover
                                    :fields="conceptsFields"
                                    :items="conceptsFormatted()">
                                <template v-slot:cell(status)>
                                    <Badge
                                            type="badge-accent_2"
                                            text="Activo"
                                    ></Badge>
                                </template>
                            </b-table>
                        </div>
                        <div class="total-container">
                            <div class="total-text body-font-medium">{{$t('pagos.resumen.general.subtotal')}}</div>
                            <div class="total-number body-font-medium">{{ payment.element.subTotal | currency }}
                                {{getCurrencyCode()}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-t-40" v-if="!isDebtPayment() && payment.element.discounts.length > 0">
                <div class="col-12">
                    <h1 class="c-title f-20 principal-font-bold m-b-20">
                        {{$t('detail-payment-template.table.discounts.title')}} </h1>
                    <div class="table-dashboard-services">
                        <div class="basic-table">
                            <b-table
                                    responsive
                                    hover
                                    :fields="discountsFields"
                                    :items="discountsFormatted()">
                                <template v-slot:cell(status)>
                                    <Badge type="badge-accent_2" text="Activo"></Badge>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-t-40" v-if="!isDebtPayment()">
                <div class="col-12">
                    <h1 class="c-title f-20 principal-font-bold m-b-20"> {{
                        $t('detail-payment-template.table.total.title') }} </h1>
                    <div class="total-container m-b-0">
                        <div class="total-text body-font-medium">{{$t('pagos.resumen.general.subtotal')}}</div>
                        <div class="total-number body-font-medium">{{ payment.element.subTotal | currency }}
                            {{getCurrencyCode()}}
                        </div>
                    </div>
                    <div class="basic-table">
                        <b-table
                                responsive
                                hover
                                :fields="taxesFields"
                                :items="taxesFormatted()"
                        >
                        </b-table>
                    </div>
                    <div class="total-container">
                        <div class="total-text body-font-medium">{{$t('pagos.resumen.general.total')}}</div>
                        <div class="total-number body-font-medium">{{ payment.element.total | currency }}
                            {{getCurrencyCode()}}
                        </div>
                    </div>

                </div>
            </div>


            <div class="row m-t-40" v-if="isDebtPayment()">
                <div class="col-12 vertical-center">
                    <router-link
                            to="/client-admin/payment-template"
                            class="button button-ghost sm button-plain_text m-0-auto-0-0"
                            tabindex=""
                    >
                        <i aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('detail-payment-template.general.return')}}
                    </router-link>
                    <button class="button button-filled sm button-accent1 m-0-0-0-auto" @click="showDebtors()">
                        {{$t('detail-payment-template.show-debtors-list')}}
                    </button>
                </div>
            </div>

        </CardSimple>
    </div>
</template>

<script lang="js">
    import CardSimple from '@/components/CardSimple.vue';
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import DefaultBlue from '@/assets/images/Backgrounds/Default-blue.svg';
    import Badge from "@/components/Badge";
    import {mapGetters} from 'vuex';
    import moment from 'moment';
    import utils from '@/common/utils';
    import OrganizationApi from '@/api/organization/organizations.api';
    import {
        STORE as PaymentTemplateStore,
        SET_PAYMENT_SELECTED,
        GET_PAYMENT_INFO,
        SET_PAYMENT_VALID,
        GET_PAYMENT_VALID
    } from '@/store/modules/paymentstemplate/paymentsTemplate.types';

    const TEXT_ALIGN_C_CLASS = "text-align-c";
    const TEXT_ALIGN_R_CLASS = "text-align-r";

    export default {
        name: "DetailPaymentTemplate",
        data() {
            return {
                commision: {},
                DefaultBlue: DefaultBlue,
                conceptsFields: [
                    {
                        key: "name",
                        label: this.$t('detail-payment-template.table.concepts.name'),
                        class: "text-align-l"
                    },
                    {
                        key: "quantity",
                        label: this.$t('detail-payment-template.table.concepts.quantity'),
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "unitPrice",
                        label: this.$t('detail-payment-template.table.concepts.unit-price'),
                        class: TEXT_ALIGN_R_CLASS
                    },
                    {
                        key: "total",
                        label: this.$t('detail-payment-template.table.concepts.subtotal'),
                        class: "text-align-r f-bold"
                    }
                ],
                discountsFields: [
                    {
                        key: "description",
                        label: this.$t('pagos.resumen.descuentos.table.name')
                    },
                    {
                        key: "from",
                        label: this.$t('pagos.resumen.descuentos.table.inicio'),
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "to",
                        label: this.$t('pagos.resumen.descuentos.table.fin'),
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "status",
                        label: this.$t('pagos.resumen.descuentos.table.estatus'),
                        class: "text-align-c w-85px"
                    },
                    {
                        key: "discount",
                        label: this.$t('pagos.resumen.descuentos.table.descuento'),
                        class: "text-align-r f-bold c-success"
                    }
                ],
                taxesFields: [
                    {
                        key: "name",
                        label: "",
                        class: TEXT_ALIGN_R_CLASS
                    },
                    {
                        key: "amount",
                        label: "",
                        class: "text-align-r f-bold"
                    }
                ],
                findObject: {
                    idPayment: '',
                    currentPage: 1,
                    perPage: 10,
                    search: '',
                    match: {},
                    sort: '',
                    typeSort: ''
                },
            }
        },
        components: {
            CardSimple,
            FloatingTitleLayout,
            Badge
        },
        mixins: [ToastSnotify],
        computed: {
            ...mapGetters(PaymentTemplateStore, {
                payment: GET_PAYMENT_INFO,
                isValid: GET_PAYMENT_VALID
            })
        },
        watch: {
            payment() {
                if (this.payment) {
                    OrganizationApi.getCommision(this.payment.organization.toString()).then((data) => {
                        this.commision = data.data.object;
                    }).catch((error) => {
                        if (error && error.response && error.response.data && error.response.data.message) {
                            this.displayNotificationError(this.$t('general.actions.error.title'), error.response.data.message);
                        } else {
                            this.displayNotificationError(this.$t('general.actions.error.title'), this.$t('general.actions.error.text'));
                        }
                    });
                }
            }
        },
        methods: {
            getDetailPayment() {
                this.findObject.idPayment = this.$route.params.idPaymentPending;
                this.$store.dispatch(`${PaymentTemplateStore}/getDetailRegularPayment`, this.findObject).then((result) => {
                    if (!result.error) {
                        this.$store.commit(`${PaymentTemplateStore}/${SET_PAYMENT_SELECTED}`, result.object);
                        this.$store.commit(`${PaymentTemplateStore}/${SET_PAYMENT_VALID}`, result.object.isValid);
                    } else {
                        this.displayNotificationWarning(result.message);
                    }
                }).catch((err) => {
                    if (err) {
                        this.displayNotificationWarning(this.$i18n.t('detail-payment-template.get-info.error'));
                    }
                });
            },
            getClassStatusService() {
                if (this.payment.element && this.payment.element.activeService === '1') { // Activo
                    return 'badge-accent_2 m-b-10 m-r-10'
                } else if (this.payment.element && this.payment.element.activeService === '2') { // Inactivo
                    return 'badge-placeholder m-b-10 m-r-10'
                }
                // proximamente
                return 'badge-accent_1 m-b-10 m-r-10'
            },
            getTextStatusService() {
                if (this.payment.element && this.payment.element.activeService === '1') { // Activo
                    return 'payment-template.service-status.active'
                } else if (this.payment.element && this.payment.element.activeService === '2') { // Inactivo
                    return 'payment-template.service-status.inactive'
                }

                // proximamente
                return 'payment-template.service-status.cooming-soon'
            },
            getCurrencyCode() {
                if (this.payment.element.currency) {
                    return this.payment.element.currency.code
                }
                this.getDetailRegularPayment();
                return this.payment.element.currency.code
            },
            conceptsFormatted() {
                const conceptsFormatted = [];
                this.payment.element.items.forEach(oldConcept => {
                    const concept = JSON.parse(JSON.stringify(oldConcept));
                    concept.unitPrice = `${utils.toCurrency(concept.unitPrice)} ${this.getCurrencyCode()}`;
                    concept.total = `${utils.toCurrency(concept.total)} ${this.getCurrencyCode()}`;

                    conceptsFormatted.push(concept);
                })

                return conceptsFormatted;
            },
            conceptsTotal() {
                let total = 0;
                this.payment.element.items.forEach(function (i) {
                    total += i.total
                });
                return total
            },
            discountsFormatted() {
                const discountsFormatted = [];
                this.payment.element.discounts.forEach(oldDiscount => {
                    const discountFormatted = JSON.parse(JSON.stringify(oldDiscount));
                    discountFormatted.from = new moment(discountFormatted.from).format('DD/MM/YY');
                    if (discountFormatted.to) {
                        discountFormatted.to = new moment(discountFormatted.to).format('DD/MM/YY');
                    }
                    discountFormatted.status = this.discountStatus(oldDiscount).text;
                    if (discountFormatted.isPercentage) {
                        discountFormatted.discount = `- ${discountFormatted.discount}%`;
                    } else {
                        discountFormatted.discount = `- ${this.numberToMoneyString(discountFormatted.discount)}`;
                    }

                    discountsFormatted.push(discountFormatted);
                })

                return discountsFormatted;
            },
            discountStatus(discount) {
                const now = new Date();
                let discountFrom = '';
                let discountTo = '';

                if (discount.from && typeof discount.from === 'string') {
                    discountFrom = new Date(discount.from);
                }
                if (discount.to && typeof discount.to === 'string') {
                    discountTo = new Date(discount.to);
                }

                if (discountFrom && discountFrom.getTime() < now.getTime()) {
                    if (discountTo && discountTo.getTime() < now.getTime()) {
                        return {
                            type: 'badge-error m-l-20',
                            text: this.$t('detail-payment-template.table.discounts.expired')
                        };
                    }
                    return {type: 'badge-info m-l-20', text: this.$t('detail-payment-template.table.discounts.active')};
                }
                return {
                    type: 'badge-disable m-l-20',
                    text: this.$t('detail-payment-template.table.discounts.inactive')
                };
            },
            discountAmount(discount) {
                if (discount.isPercentage) {
                    const factor = utils.jsNumFix(discount.discount / 100);
                    return utils.jsNumFix(this.conceptsTotal() * factor);
                }
                return discount.discount;
            },
            // Converts a number or a number string to a money formatted string.
            numberToMoneyString(number) {
                if (isNaN(number)) {
                    return '0.00';
                }
                if (typeof number === 'string') {
                    number = Number.parseFloat(number);
                }
                return `$ ${number.toLocaleString(
                    'en-IN',
                    {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }
                )} ${this.getCurrencyCode()}`;
            },
            taxesFormatted() {
                const concepts = [];
                const activeDiscounts = this.payment.element.discounts.filter(d => this.discountStatus(d).text === this.$t('detail-payment-template.table.discounts.active'));
                activeDiscounts.forEach(d => concepts.push({
                    name: d.description,
                    amount: `- ${this.numberToMoneyString(this.discountAmount(d))}`,
                    amountNumber: (-1 * this.discountAmount(d))
                }));

                if (this.commision && this.commision.price) {
                    concepts.push({
                        name: this.$t('detail-payment-template.general.commision'),
                        amount: `${this.numberToMoneyString(this.commision.price)}`,
                        amountNumber: this.commision.price
                    });
                }

                return concepts;
            },
            isDebtPayment() {
                return !!this.payment.element.debt
            },
            getDetailInfo() {
                return this.isDebtPayment() ?
                    this.$t('detail-payment-template.debt-payment.info', {'name': this.payment.element.debt.name}) :
                    this.$t('detail-payment-template.regular-payment.info')
            },
            showDebtors() {
                this.$router.push({name: 'DebtorsPaymentTemplate', params: {id: this.payment._id.toString()}});
            }
        },
        created() {
            if (!this.payment.length) {
                this.getDetailPayment();
            }
        }
    }
</script>
