export const STORE = 'CHARGEBACKS';
// Getters
export const GET_CURRENT_TAB = 'currentTab';
export const GET_CHARGEBACK = 'chargeback';
export const GET_STATS = 'getStats';
export const GET_FILE_DOCUMENTS = 'getFileDocuments';
export const GET_REQUESTED_FILES = 'getRequestedFiles';
export const GET_FILE_BY_KEY = 'getFileByKey';
// Mutations
export const SET_TAB_STATUS = 'SET_TAB_STATUS';
export const SET_STATS = 'SET_STATS';
export const SET_CHARGEBACK = 'SET_CHARGEBACK';
export const CLEAR_CHARGEBACK = 'CLEAR_CHARGEBACK';
export const SET_FILE_DOCUMENTS = 'SET_FILE_DOCUMENTS';
export const SET_FILE_DOCUMENTS_CHARGEBACK = 'SET_FILE_DOCUMENTS_CHARGEBACK';
export const UPDATE_FILES = 'UPDATE_FILES';
export const UPDATE_EXTRA_COMMENT = 'UPDATE_EXTRA_COMMENT';
