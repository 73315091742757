<template lang="html">
  <div class="debt-general-advanced">
    <div class="m-b-20">
        <div class="row m-b-30">
            <div class="col-md-7 col-7">
                <h1 class="c-title f-14 body-font-regular m-0">
                    {{ $t('pagos.general.advanced.privacy.title') }}
                </h1>
            </div>
            <div class="col-md-5 col-5">
                <div class=" justify-content-end d-flex">
                    <h1
                        class="m-0 c-info f-13 body-font-regular c-help d-flex align-middle "
                        id="helpMult"
                    >
                        {{ $t('pagos.general.advanced.privacy.help') }}
                        <i aria-hidden="true" class="banana banana-question-circle1 f-16 m-l-5"></i>
                    </h1>
                    <b-tooltip
                        target="helpMult"
                        placement="top"
                        custom-class="white-tooltip"
                    >
                        <p class="m-b-0">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Ab quasi maiores nam voluptas dolorem distinctio voluptates veniam quod quis
                            fugit commodi facilis alias accusamus nemo reiciendis, culpa sit inventore illum.</p>
                    </b-tooltip>
                </div>
            </div>
        </div>
        <div class="row">
            <RadioInputGroup
                    wrapperClass="col-md-12 col-12"
                    :options="[{value: false, label: $t('pagos.general.advanced.privacy.public')}, {value: true, label: $t('pagos.general.advanced.privacy.private')}]"
                    v-model="payment.private"
                >
            </RadioInputGroup>
        </div>
    </div>
    <BasicInput
      v-model="payment.externalIdentifier"
      labelFor="identificador"
      inputId="identificador"
      :label="$t('pagos.general.advanced.externalIdentifier.label')"
      :inputPlaceholder="$t('pagos.general.advanced.externalIdentifier.placeholder')"
      help
      @change="refresh"
    >
    </BasicInput>
  </div>

</template>

<script lang="js">
  import BasicInput from "@/components/BasicInput.vue";
  import RadioInputGroup from "@/components/RadioInputGroup.vue";

  export default {
    name: 'debt-general-advanced',
    props: {
        slotValid: {type: Boolean, default: true},
        payment: {
            type: Object,
            default: function () {
                return {
                    private: false,
                    externalIdentifier: '',
                    maxUnitsPerTransaction: 1,
                    additionalNotesConfig: {
                        isEnabled: false,
                        label: '',
                        placeholder: '',
                        isRequired: false,
                    }
                }
            }
        }
    },
    components: {
      BasicInput,
      RadioInputGroup
    },
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {
        refresh() {
            this.$forceUpdate();
            this.$nextTick(() => this.validityChange());
        },
        validityChange() {
            this.$emit('validityChange', this.isValid());
        },
        isValid() {
            return true;
        },
        checkPrivacy(){
            this.payment.private = true;
        }
    },
    computed: {

    }
}
</script>
