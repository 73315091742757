<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout :msgSmall="$t('dashboard.subtitle')" :title="$t('dashboard.title')"/>
        </div>

        <div class="col-12" v-show="$can('do', 'CARDS_DASHBOARD')">
            <div class="row" v-if="!getIsLoadingCards">
                <div class="col-12 col-md-6 col-lg-3 m-b-40">
                    <PanelMoneyAvg :title="$t('dashboard.average-pay')"
                                   :total="cards.averageLast30Days ? cards.averageLast30Days.now : 0"
                                   :textAvg="$t('dashboard.last-average-pay')"
                                   :totalAvg="cards.averageLast30Days ? cards.averageLast30Days.then : 0 "
                                   :numAvg="`${Math.abs(getPercentage(cards.averageLast30Days))}`"
                                   :errorAvg="getErrorAvg(cards.averageLast30Days)"></PanelMoneyAvg>
                </div>
                <div class="col-12 col-md-6 col-lg-3 m-b-40">
                    <PanelMoneyAvg :title="$t('dashboard.incomming-money')" :total="cards.day ? cards.day.now : 0"
                                   :textAvg="$t('dashboard.last-incomming-money')"
                                   :totalAvg="cards.day ? cards.day.then : 0"
                                   :numAvg="`${Math.abs(getPercentage(cards.day))}`"
                                   :errorAvg="getErrorAvg(cards.day)"></PanelMoneyAvg>
                </div>
                <div class="col-12 col-md-6 col-lg-3 m-b-40">
                    <PanelMoneyAvg :title="$t('dashboard.week-income')" :total="cards.week ? cards.week.now : 0"
                                   :textAvg="$t('dashboard.last-week-income')"
                                   :totalAvg="cards.week ? cards.week.then : 0"
                                   :numAvg="`${Math.abs(getPercentage(cards.week))}`"
                                   :errorAvg="getErrorAvg(cards.week)"></PanelMoneyAvg>
                </div>
                <div class="col-12 col-md-6 col-lg-3 m-b-40">
                    <PanelMoneyAvg :title="$t('dashboard.month-income')" :total="cards.month ? cards.month.now : 0"
                                   :textAvg="$t('dashboard.last-month-income')"
                                   :totalAvg="cards.month ? cards.month.then : 0"
                                   :numAvg="`${Math.abs(getPercentage(cards.month))}`"
                                   :errorAvg="getErrorAvg(cards.month)"></PanelMoneyAvg>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12 col-md-6 col-lg-12 m-b-40">
                <LoadingBar></LoadingBar>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-8 m-b-40" v-show="$can('do', 'RECEIVED_PAYMENTS')">
                    <CardSimple clazz="h-100" v-if="!getIsLoadingGraf" :title="$t('dashboard.recived-payments')"
                                :subtitle="$t('dashboard.recived-payments-desc')" clazzTitle="m-b-10">
                        <template v-slot:titleButtons>
                            <b-dropdown id="dropdown-add" right class="dropdown-user">
                                <template v-slot:button-content>
                                    <button class="button xs button-ghost button-accent2 p-l-5 p-r-0 text-inherit">
                                        {{$t('dashboard.select-range')}} <i aria-hidden="true"
                                            class="banana banana-triangle-down m-r-0"></i></button>
                                </template>
                                <b-dropdown-group>
                                    <b-dropdown-item-button @click="setFilterGraph('D')"> {{$t('dashboard.day')}}
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="setFilterGraph('M')"> {{$t('dashboard.month')}}
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="setFilterGraph('Y')"> {{$t('dashboard.year')}}
                                    </b-dropdown-item-button>
                                </b-dropdown-group>
                            </b-dropdown>
                        </template>

                        <div class="graph-data">
                            <span class="data grad">
                                <i aria-hidden="true"></i> {{$t('dashboard.success-transactions')}}
                            </span>

                            <span class="total">
                                <label>{{amountText}}</label>
                                <p>{{ total |  currency }}</p>
                            </span>
                        </div>
                        <div class="graph-container">
                            <svg style="width:0;height:0;position:absolute;" aria-hidden="true" focusable="false">
                                <linearGradient id="gradient-fill" x2="1" y2="1">
                                    <stop offset="20%" stop-color="#6992ea"/>
                                    <stop offset="100%" stop-color="#c16bf0"/>
                                </linearGradient>
                            </svg>
                            <apexchart width="100%" height="300px" type="bar" :options="chartPagosBar"
                                       :series="seriesPagosBar" class="gradient-error-color"></apexchart>
                        </div>
                    </CardSimple>
                    <CardSimple clazz="h-100" v-else :title="$t('dashboard.recived-payments')"
                                :subtitle="$t('dashboard.recived-payments-desc')" clazzTitle="m-b-10">
                        <div class="basic-table">
                            <LoadingSimple ></LoadingSimple>
                        </div>
                    </CardSimple>
                </div>
                <div class="col-12 col-md-12 col-lg-4 m-b-40" v-show="$can('do', 'CONGRATS_CARD')">
                    <div class="card p-0 h-100 card-dashboard-congrats">
                        <div v-if="congratsCard.greaterThanLastWeek">
                            <img class="img-fluid" src="@/assets/images/Illustrations/illustration-icon-party.svg"
                                 alt="Congratulations"/>
                            <label>{{$t('dashboard.congrats')}} {{user.name}} {{$t('dashboard.congrats-!')}}</label>
                        </div>
                        <div v-else>
                            <img class="img-fluid" src="@/assets/images/Illustrations/illustration-announcement.svg"
                                 alt="Congratulations"/>
                            <label>{{$t('dashboard.keep-the-effort')}}
                                {{user.name}}{{$t('dashboard.congrats-!')}}</label>
                        </div>
                        <ul>
                            <li class="m-b-20" v-if="congratsCard.greaterThanLastWeek">
                                <i aria-hidden="true" class="banana banana-triangle-up"></i>
                                <p>{{$t('dashboard.congrats-desc')}} <strong class="c-success">15% más de
                                    ingresos</strong> {{$t('dashboard.congrats-desc')}}</p>
                            </li>
                            <li class="m-b-20" v-else>
                                <i aria-hidden="true" class="banana banana-triangle-right"></i>
                                <p>{{$t('dashboard.keep-the-effort-desc')}}</p>
                            </li>
                            <li v-if="congratsCard.greaterThanLastWeek">
                                <i aria-hidden="true" class="banana banana-trophy"></i>
                                <p><strong>{{$t('dashboard.good-work')}}</strong> Tienes una racha de 3 semanas
                                    consecutivas superando tu porcentaje de ingresos.</p>
                            </li>
                            <li v-else>
                                <i aria-hidden="true" class="banana banana-info-circle2"></i>
                                <p><strong>{{$t('dashboard.tip')}}</strong> {{$t('dashboard.tip-desc')}} </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-7 m-b-40" v-show="$can('do', 'COLLECTED_BY_SERVICE')">
                    <CardSimple clazz="" :title="$t('dashboard.the-most-buyed-services-title')"
                                :subtitle="$t('dashboard.the-most-buyed-services-subtitle')"
                                clazzBody="table-dashboard-services" v-if="!getIsLoadingMostSelled">
                        <div class="basic-table">
                            <!-- DO NOT SHOW MORE THAN 5 ROWS -->
                            <b-table responsive :fields="fields" :items="items" :busy="isLoading">
                                <template v-slot:table-busy>
                                    <LoadingSimple clazz="big-load m-t-35 m-b-35"
                                                   :label="$t('dashboard.loading')"></LoadingSimple>
                                </template>

                                <template v-slot:cell(noVentas)="data">
                                    {{ data.item.noVentas }} {{$t('dashboard.payments')}}
                                </template>

                                <template v-slot:cell(total)="data">
                                    <strong class="c-primary_1 body-font-bold"> {{ data.item.total | currency}} MXN </strong>
                                </template>
                            </b-table>
                        </div>
                    </CardSimple>
                    <CardSimple clazz="" :title="$t('dashboard.the-most-buyed-services-title')"
                                :subtitle="$t('dashboard.the-most-buyed-services-subtitle')"
                                clazzBody="table-dashboard-services" v-else>
                        <div class="basic-table">
                            <LoadingSimple ></LoadingSimple>
                        </div>
                    </CardSimple>
                </div>

                <div class="col-12 col-md-12 col-lg-5 m-b-40" v-show="$can('do', 'DOWNLOAD_INVOICE')">
                    <div class="card p-0 card-dashboard-img-right" v-if="getInvoiceExistence">
                        <div>
                            <h1>
                                {{$t('dashboard.download-invoice')}}
                                <br>
                                <strong>{{$t('dashboard.invoice')}}</strong>
                            </h1>
                            <p>{{$t('dashboard.period')}} <strong>{{getNameOfMonthByNumner(getCurrentInvoice.month)}}
                                2019</strong></p>
                            <button @click="downLoadMultipleDocuments" class="button xs button-filled button-white"
                                    type="button"><i aria-hidden="true" class="banana banana-download"></i>
                                {{$t('dashboard.download-invoice-button')}}
                            </button>
                        </div>
                    </div>
                    <div class="card p-0 card-dashboard-img-right white" v-if="!getInvoiceExistence">
                        <div>
                            <h1>
                                {{$t('dashboard.invoice-in')}} <strong>{{$t('dashboard.progress')}}</strong>
                            </h1>
                            <p>{{$t('dashboard.invoice-in-progress-desc')}}</p>
                            <router-link to="/client-admin/organization-info" type="button"
                                         class="button xs button-stroke button-accent1" tabindex="">
                                {{$t('dashboard.config-invoice-data')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue'
    import CardSimple from '@/components/CardSimple.vue'
    import PanelMoneyAvg from '@/components/PanelMoneyAvg.vue'
    import LoadingSimple from '@/components/LoadingSimple.vue'
    import LoadingBar from '@/components/LoadingBar.vue'
    import axios from 'axios'
    import fileApi from "@/api/files/files.api";
    import momentTimezone from 'moment-timezone';
    import { utils } from '@/api/utils';

    const storeModule = "adminDashboardStore";
    const PRINT_ERROR_KEY = "signup-organization.step-contract.print-error"
    import {mapState, mapGetters} from 'vuex';
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types"
    import {
        STORE,
        GET_USER,
        GET_TOKEN
    } from '@/store/modules/session/session.types';

    export default {
        name: "Dashboard",
        components: {
            FloatingTitleLayout,
            CardSimple,
            PanelMoneyAvg,
            LoadingSimple,
            LoadingBar
        },
        data() {
            return {
                isLoading: false,
                filterby: 'D',
                amountText: this.$i18n.t('dashboard.week-income'),
                total: '',
                congratsCard: {},
                fields: [
                    {
                        key: 'servicio',
                        label: this.$i18n.t('dashboard.service')
                    },
                    {
                        key: 'noVentas',
                        label: this.$i18n.t('dashboard.no-ventas'),
                        class: 'text-align-c'
                    },
                    {
                        key: 'total',
                        label: this.$i18n.t('dashboard.total'),
                        class: 'text-align-r',
                        sortable: true,
                        sortDirection: 'desc'
                    }
                ],
                items: [],
                chartPagosBar: {
                    chart: {
                        id: 'vuechart-pagos',
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '20px'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 4,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            style: {
                                colors: ['#547286', '#547286', '#547286', '#547286', '#547286', '#547286', '#547286']
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: function (val) {
                                return val === 0 || val < 1000 ? val : `${val / 1000} mil`
                            },
                            style: {
                                color: '#547286',
                                fontSize: '13px',
                                fontFamily: 'sans-serif'
                            },

                        },
                    },
                    tooltip: {
                        custom: function ({series, seriesIndex, dataPointIndex, w}) {
                            const amount = series[seriesIndex][dataPointIndex] != null ? series[seriesIndex][dataPointIndex] : 0;

                            return `<div class="apexcharts-tooltip-title">${w.globals.labels[dataPointIndex]}</div>` +
                                `<div class="apexcharts-tooltip-series-group active" style="display: flex;">` +
                                `<span class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251);"></span>` +
                                `<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">` +
                                `<div class="apexcharts-tooltip-y-group">` +
                                `<span class="apexcharts-tooltip-text-label"> Total </span>` +
                                `<span class="apexcharts-tooltip-text-value">$ ${amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>` +
                                `</div>` +
                                `<div class="apexcharts-tooltip-z-group">` +
                                `<span class="apexcharts-tooltip-text-z-label"></span>` +
                                `<span class="apexcharts-tooltip-text-z-value"></span>` +
                                `</div>` +
                                `</div>` +
                                `</div>`
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    markers: {
                        size: 2
                    }
                },
                seriesPagosBar: [
                    {
                        name: this.$i18n.t('dashboard.success-transactions'),
                        data: []
                    }
                ],
                defaultTimezone: 'America/Chihuahua'
            }
        },
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            ...mapGetters(STORE, {user: GET_USER, token: GET_TOKEN}),
            ...mapGetters(storeModule, [
                'getActualUser',
                'getCurrentInvoice',
                'getInvoiceExistence',
                "getIsLoadingCards",
                "getIsLoadingGraf",
                "getIsLoadingMostSelled"
                ]),
            ...mapState({
                cards: state => state[storeModule].cards || {},
                customChartData: state => state[storeModule].data
            })


        },
        methods: {
            getCardsInfo() {

                const params = {
                    dayStartDate: momentTimezone().tz(this.defaultTimezone).startOf('day'),
                    organizationId: this.currentOrganization?._id?.toString(), // Organizacion seleccionada
                    timezone: this.defaultTimezone
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.$store.dispatch(`${storeModule}/retrieveDataForDashboard`, params);

            },
            downLoadMultipleDocuments() {
                fileApi.getSignedUrlFile({id: this.getCurrentInvoice.pdf}, (response) => {
                    if (response.data.error) {
                        this.displayNotificationInfo(this.$i18n.t(PRINT_ERROR_KEY));
                    } else {
                        if (response.data.object !== undefined) {
                            const link = document.createElement("a");
                            const url = response.data.object;
                            link.setAttribute("href", url);
                            link.setAttribute("download", name);
                            link.setAttribute("_target", "_blank");
                            link.click();
                        }
                        fileApi.getSignedUrlFile({id: this.getCurrentInvoice.xml}, (r) => {
                            if (r.data.error) {
                                this.displayNotificationInfo(this.$i18n.t(PRINT_ERROR_KEY));
                            } else {
                                if (r.data.object !== undefined) {
                                    const link = document.createElement("a");
                                    const url = r.data.object;
                                    link.setAttribute("href", url);
                                    link.setAttribute("download", name);
                                    link.click();
                                }
                            }
                        }, () => {
                            this.displayNotificationInfo(this.$i18n.t(PRINT_ERROR_KEY));
                        });
                    }
                }, () => {
                    this.displayNotificationInfo(this.$i18n.t(PRINT_ERROR_KEY));
                });
            },
            getNameOfMonthByNumner(iVal) {
                switch (iVal) {
                    case 0:
                        return this.$i18n.t('dashboard.january');
                    case 1:
                        return this.$i18n.t('dashboard.february');
                    case 2:
                        return this.$i18n.t('dashboard.march');
                    case 3:
                        return this.$i18n.t('dashboard.april');
                    case 4:
                        return this.$i18n.t('dashboard.may');
                    case 5:
                        return this.$i18n.t('dashboard.june');
                    case 6:
                        return this.$i18n.t('dashboard.july');
                    case 7:
                        return this.$i18n.t('dashboard.august');
                    case 8:
                        return this.$i18n.t('dashboard.september');
                    case 9:
                        return this.$i18n.t('dashboard.october');
                    case 10:
                        return this.$i18n.t('dashboard.november');
                    case 11:
                        return this.$i18n.t('dashboard.december');
                    default:
                        return this.$i18n.t('invoice-month');
                }
            },
            getDayStart() {
                const now = new Date();
                now.setHours(0, 0, 0);
                return now;
            },
            getWeekStart() {
                const now = new Date();
                now.setHours(0, 0, 0);
                now.setDate(now.getDate() - now.getDay());
                return now;
            },
            getMonthStart() {
                const now = new Date();
                now.setDate(1);
                now.setHours(0, 0, 0);
                return now;
            },
            getMonthTo() {
                const now = new Date();
                now.setHours(23, 59, 59);
                now.setDate(1);
                now.setMonth(now.getMonth() + 1);
                now.setDate(0);
                return now;
            },
            getMonthThenFrom() {
                const now = new Date();
                now.setHours(0, 0, 0);
                now.setDate(1);
                now.setMonth(now.getMonth() - 1);
                return now;
            },
            getPercentage({now, then}) {
                let percentage = 0;
                if (now !== undefined && then !== undefined && then !== 0) {
                    if (now > then) {
                        percentage = now / then * 100;
                    } else {
                        percentage = -(100 - (now / then * 100));
                    }
                } else if (then === 0) {
                    percentage = 100;
                }
                return percentage.toFixed(2);
            },
            getErrorAvg({now, then}) {
                if (now !== undefined && then !== undefined && then !== 0) {
                    if (now > then) {
                        return false;
                    }
                    return true;
                } else if (then === 0) {
                    return false;
                }
                return false;

            },
            setFilterGraph(filterBy) {
                this.filterBy = filterBy;

                if (filterBy === 'D') {
                    this.amountText = this.$i18n.t('dashboard.week-income')
                } else {
                    if (filterBy === 'M') {
                        this.amountText = this.$i18n.t('dashboard.year-income')
                    } else {
                        this.amountText = this.$i18n.t('dashboard.last-five-years-income')
                    }
                }
                this.getInfoChart();
            },
            getLastClosingTransaction() {
                const params = {
                    dayStartDate: this.getDayStart(),
                    organizationId: this.currentOrganization?._id?.toString() // Organizacion seleccionada
                };
                this.$store.dispatch(`${storeModule}/getLastClosingTransaction`, params).then((res, err) => {
                    if (err) {
                        console.error("Error", err);
                    } else {
                        if (res.error) {
                            console.error("Error", res.error);
                        } else {
                            if (res.object === 0) {
                                this.congratsCard = {
                                    greaterThanLastWeek: false
                                }
                            } else {
                                this.congratsCard = res.object;
                            }
                        }
                    }
                });
            },
            async getInfoChart() {
                const params = {
                    dayStartDate: momentTimezone().tz(this.defaultTimezone).startOf('day'),
                    filterBy: this.filterBy,
                    organizationId: this.currentOrganization?._id?.toString(),
                    timezone: this.defaultTimezone,
                }
                this.$store.dispatch(`${storeModule}/getInfoForChart`, params);
            },
            getBestServiceInfo() {
                const params = {
                    dayStartDate: this.getDayStart(),
                    organizationId: this.currentOrganization?._id?.toString() // Organizacion seleccionada
                };
                this.$store.dispatch(`${storeModule}/getBestServiceInfo`, params).then((res) => {
                    if (res.data.object) {
                        this.items = res.data.object;
                    }
                });
            },
            async populateInvoiceStore() {
                const organizationId = this.currentOrganization?._id?.toString();
                await this.$store.dispatch(`${storeModule}/retrieveDataForInvoice`, {organizationId});
            },
            roundNumberTwoDecimals(number){
                return utils.roundNumber(number,2);
            }
        },
        created() {

        },
        watch: {
            customChartData: function (val) {
                this.chartPagosBar = {
                    xaxis: {
                        categories: val?.labels || []
                    }
                }
                const datasets = val?.datasets || [];
                for (let i = 0; i < datasets.length; i++) {
                    this.seriesPagosBar[i].name = datasets[i].name;
                    const days = datasets[i].data.map((day) => {
                        return this.roundNumberTwoDecimals(day)
                    });
                    this.seriesPagosBar[i].data = days;
                }
                this.total = val?.total || 0;
            }
        },
        mounted() {
            this.getInfoChart();
            this.populateInvoiceStore();
            this.getBestServiceInfo();
            this.getLastClosingTransaction();
            this.getCardsInfo();
        }

    }
</script>

