<template>
  <div>
    <SignInBody clazz="full-page">
      <template v-slot:formSectionBody>
        <router-view></router-view>
      </template>
    </SignInBody>
  </div>
</template>

<script>
// @ is an alias to /src
import SignInBody from '@/components/SignInBody.vue'

export default {
  name: "SignInFullPage",
  components: {
    SignInBody
  },
  props: {
    errorApi: {type: Boolean, default: false},
    emailSuccess: {type: Boolean, default: false},
    mediaSuccess: {type: Boolean, default: false},
    wizardFullSuccess: {type: Boolean, default: false}
  }
};
</script>
