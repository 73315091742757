export default {
    KINDS: [
        "pattern one",
        "pattern two",
        "pattern three",
        "pattern four",
    ],
    COLORS: [
        "green",
        "brown",
        "purple",
        "blue",
        "orange",
        "red",
        "bluegray",
        "gray"
    ],
};
