import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'invoices';

export default {
    list: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list`, params)
            .then(onSuccess)
            .catch(onError);
    },
};
