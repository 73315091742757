<template>
    <div class="card-campain" :class="clazz">
        <img class="img-fluid" :src="imgUrl" alt="illustration" />

        <div class="info">
            <h1>{{title}}</h1>
            <p>{{text}}</p>
            <button class="button xs button-filled button-accent2" @click="clickButton" :disabled="disabled">{{btnText}}</button>
        </div>
    </div>
</template>

<script>
    import imgDefault from '@/assets/images/Icons/icon-lista.svg';

    export default {
        name: 'CardCampaign',
        props: {
            clazz: String,
            title: String,
            text: String,
            btnText: String,
            disabled: {type: Boolean, default: false},
            imgUrl: {type: String, default: imgDefault}
        },
        data () {
            return {
                imgDefault: imgDefault
            }
        },
        methods: {
            clickButton(){
                this.$emit("clickButton");
            }
        }
    }
</script>

<style lang="scss">
    .card-campain {
        width: 100%;
        max-width: 364px;
        height: 100%;
        border-radius: 3px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 20px;
        margin: 0 auto;
        transition: 0.5s ease-in-out;

        > img {
            max-height: 55px;
            margin-right: 5%;
        }

        > .info {
            width: 100%;

            > h1 {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.22;
                display: block;
                margin-bottom: 4px;
            }

            > p {
                font-size: 13px;
                font-weight: normal;
                line-height: 1.31;
                display: block;
                margin-bottom: 20px;
            }

            > button {
                white-space: nowrap;
            }
        }

        &:hover {
            box-shadow: 0 2px 20px 0 rgba(20, 34, 102, 0.2);
        }
    }
</style>
