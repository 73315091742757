<template>
    <div>
        <GenericTitle clazz="p-t-20" :title="$t('security-measures.title')"
                      :titleStrong="$t('security-measures.title-strong')"
                      :subtitle="$t('security-measures.subtitle')"></GenericTitle>

        <div class="col-12">
            <h6 class="m-t-20"><strong>{{$t('security-measures.select-image')}}</strong></h6>
            <div class="security-measures">
                <div :class="image.selected ? 'img-card active' : 'img-card'" v-for="(image, imageIndex) in images"
                     :key="image.id" @click="selectImage(image.id, imageIndex)">
                    <div class="dark-cover"></div>
                    <div class="selector">
                        <i aria-hidden="true" class="banana banana-check-normal"></i>
                    </div>
                    <img class="img-cover" :src="image.src" alt="">
                </div>
            </div>
        </div>
        <div class="col-12">
            <h6 class="m-t-10 m-b-10"><strong>{{$t('security-measures.now-phrase')}}</strong></h6>
            <BasicInput
                    required
                    :inputValue="getActualUser.securityPhrase"
                    inputType="text"
                    labelFor="securityPhrase"
                    inputId="securityPhrase"
                    :label="$t('security-measures.phrase-label')"
                    :clazz="getErrorClass('securityPhrase')"
                    v-on:basicInput="updateSecurity"
                    :warningText="$t('security-measures.phrase-warning')"
                    :inputPlaceholder="$t('security-measures.phrase-placeholder')"/>
            <b-alert class="alert-note" show dismissible variant="info">
                <p class="text-container">
                    <i aria-hidden="true" class="icon banana banana-info-circle2"></i> {{$t('security-measures.info-message')}}
                </p>
            </b-alert>
        </div>
    </div>
</template>
<script>
    import GenericTitle from '@/components/GenericTitle.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import {mapGetters} from 'vuex';
    import {required, minLength, helpers} from 'vuelidate/lib/validators';
    import {securityImages} from '@/enums/securityImages';

    const alphaDiacritic = helpers.regex('alphaDiacritic', /^[a-zA-ZÀ-ž]*(?: [a-zA-ZÀ-ž]+)*/);
    import {EventBus} from '@/main';

    const storeModule = "signUpOrganization";

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };
    export default {
        name: "SecurityMeasures",
        components: {
            GenericTitle,
            BasicInput
        },
        data() {
            return {
                securityPhrase: "",
                images: securityImages,
                imageId: ""
            }
        },
        created() {
            this.$store.commit(`${storeModule}/SET_ACTIVE_COMPONENT_STEP_ONE`, "SECURITY_MEASURES");
            this.$store.commit(`SIGNIN_UP/SET_RIGHT_SIDE_BODY`, 'STEP_FIVE');
        },
        mounted() {
            this.initializeUser();
        },
        validations: {
            securityPhrase: {
                required,
                minLength: minLength(3),
                alphaDiacritic
            },
            imageId: {
                required,
                minLength: minLength(3),
                alphaDiacritic
            },
        },
        methods: {
            getErrorClass(propertie) {
                return this.$v[propertie].$error ? "has-error" : "";
            },
            initializeUser() {
                this.securityPhrase = this.getActualUser.securityPhrase;
                if (this.securityPhrase !== undefined && this.securityPhrase !== "") {
                    this.$v.securityPhrase.$touch();
                }
                for (let i = 0; i < this.images.length; i++) {
                    if (this.images[i].id === this.getActualUser.securityImageId) {
                        this.images[i].selected = true;
                        this.imageId = this.images[i].id;
                    }
                }
                this.validateStep();
            },
            updateSecurity: debounce(function (securityPhrase) {
                this.securityPhrase = securityPhrase;
                this.$v.securityPhrase.$touch();
                this.$store.commit(`${storeModule}/SET_SECURITY_PHRASE`, securityPhrase);
                this.validateStep();
            }, 800, false),
            selectImage(id, index) {
                this.imageId = id;
                this.$v.imageId.$touch();
                for (let i = 0; i < this.images.length; i++) {
                    this.images[i].selected = false;
                }
                this.images[index].selected = true;
                this.$store.commit(`${storeModule}/SET_ID_IMAGE_SECURITY`, id);
                this.validateStep();
            },
            validateStep() {
                this.$v.$touch();
                const isValid = !this.$v.imageId.$error && !this.$v.securityPhrase.$error;
                this.$store.commit(`${storeModule}/SET_REGISTER_USER_STEPS`, {
                    step: 'securityMeasures',
                    prop: 'isReady',
                    value: isValid
                });

                EventBus.$emit('REGISTER_USER_READY_STEP');

                this.$v.$reset();
            },
        },
        computed: {
            ...mapGetters(storeModule, ['getActualUser']),
            ...mapGetters('SIGNIN_UP', ['getRightSide'])
        }
    }
</script>
