import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'file';


export default {
    getSignedUrlFile: (params, onSuccess, onError) => {
        return axios
            .post(`${base.baseUrl}/${namespace}/download/signed-url`, params)
            .then(onSuccess)
            .catch(onError);
    },
    download: (file, params, onSuccess, onError) => {
        return axios
            .post(`${base.baseUrl}/${namespace}/download/${file._id || file}`, params)
            .then(onSuccess)
            .catch(onError);
    },
};
