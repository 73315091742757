import api from "../../api/user/users.api";
import ToastSnotify from "@/components/ToastSnotify.vue";
import store from '@/store/index'
import {
    STORE as SESSION_STORE,
    SET_USER,
    SET_TOKEN,
    SET_TOKEN_EXPIRE_DATE
} from '@/store/modules/session/session.types';

const stateGlobal = {
    contract: {},
    terms: {},
    loadingContract: false,
    loadingTerms: false
};


const getters = {
    getContract: (state) => state.contract,
    getLoadingContract: (state) => state.loadingContract,
    getTerms: (state) => state.terms,
    getLoadingTerms: (state) => state.loadingTerms
};

const actions = {

    async loadContract({commit}, params){
        const actualContract = await api.getActualContractPreviewClient(params);
        if(!actualContract.data.error){
            commit('SET_ACTUAL_CONTRACT', actualContract.data.object);
        }
        commit('SET_LOADING_CONTRACT', false);
        return actualContract;
    },

    // eslint-disable-next-line no-empty-pattern
    async acceptContract(context, params){
        const response = await api.acceptPreContractClient(params);

        if (response.data && !response.data.error) {
            // Se expira la sesion anterior
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentToken');
            localStorage.removeItem('currentPermissions');
            localStorage.removeItem('profile');
            localStorage.removeItem('expireDate');
            // Se actualizan los valores
            const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
            localStorage.setItem('currentUser', JSON.stringify(response.data.object.user));
            localStorage.setItem('currentToken', JSON.stringify(response.data.object.token));
            localStorage.setItem('currentPermissions', JSON.stringify(response.data.object.permissions));
            localStorage.setItem('profile', JSON.stringify(response.data.object.profile));
            localStorage.setItem('expireDate', JSON.stringify(expireDate));
        }
        return response;

    },

    async loadTerms({commit}){
        const actualTerms = await api.getActualTermsPreview();
        if(actualTerms.data.error){
            // TODO:ERROR
        } else {
            commit('SET_ACTUAL_TERMS', actualTerms.data.object);
        }
        commit('SET_LOADING_TERMS', false);
        return actualTerms;
    },

    async acceptPreTerms() {
        const response = await api.acceptPreTerms();
        if (response.data && !response.data.error) {
            // Se expira la sesion anterior
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentToken');
            localStorage.removeItem('currentPermissions');
            localStorage.removeItem('profile');
            localStorage.removeItem('expireDate');
            // Se actualizan los valores
            const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
            localStorage.setItem('currentUser', JSON.stringify(response.data.object.user));
            store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.object.user);
            localStorage.setItem('currentToken', JSON.stringify(response.data.object.token));
            const validToken = response.data.object.token.replace(/"/g, "");
            store.commit(`${SESSION_STORE}/${SET_TOKEN}`, validToken);
            store.commit(`${SESSION_STORE}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
            localStorage.setItem('currentPermissions', JSON.stringify(response.data.object.permissions));
            localStorage.setItem('profile', JSON.stringify(response.data.object.profile));
            localStorage.setItem('expireDate', JSON.stringify(expireDate));
        }
        return response;
    },

    // Obtiene el perfil de tipo USER
    async getProfileTypeUser(){
        await api.profileTypeUser();
    }

};

const mutations = {

    SET_ACTUAL_CONTRACT(state, contract){
        state.contract = contract;
    },
    SET_LOADING_CONTRACT(state, value){
        state.loadingContract = value;
    },
    SET_ACTUAL_TERMS(state, terms){
        state.terms = terms;
    },
    SET_LOADING_TERMS(state, value){
        state.loadingTerms = value;
    },
};


export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    },
    mixins: [
        ToastSnotify
    ]
};
