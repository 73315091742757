<template>
    <!-- CONFIRM NEW PASSWORD Before save -->
    <div>
        <router-link
            to="/signin"
            class="button xs button-ghost button-primary1 m-b-40 p-l-0 p-r-5"
            tabindex
        >
            <i
                aria-hidden="true"
                class="banana banana-chevron-left"
            ></i> {{$t('new-password.return-login')}}
        </router-link>
        <GenericTitle
            :title="$t('new-password.title')"
            :titleStrong="$t('new-password.title-strong')"
            :subtitle="$t('new-password.subtitle')"
        ></GenericTitle>

        <!-- This b-alert is shown in case the password eneted had been used previously-->
        <b-alert
            class="alert-note"
            show
            variant="danger"
            v-if="getRecoveryAlert"
        >
            <p class="text-container">
                <img
                    class="img-fluid"
                    src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                    alt="Imagen alerta"
                />
                {{getAlertMessage}}
            </p>
        </b-alert>

        <b-alert
            class="alert-note"
            show
            variant="danger"
            v-if="passwordMatchAlert"
        >
            <p
                class="text-container"
                id="nonMatchPassword"
            >
                <img
                    class="img-fluid"
                    src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                    alt="Imagen alerta"
                />
                {{$t('new-password.not-same-passwords')}}
            </p>
        </b-alert>

        <PasswordInput
            :inputType="passwordInputType"
            labelFor="password"
            inputId="password"
            :required="true"
            :label="$t('new-password.password-label')"
            :inputPlaceholder="$t('new-password.password-placeholder')"
            :level="true"
            :clazzPassword="clazzPasswordStatus"
            :status="labelPasswordStatus"
            v-on:passwordInput="onPasswordChangeRegister"
            v-on:passwordInputType="changeTypePassword"
        ></PasswordInput>

        <SimpleNote
            icon="banana-info-circle1"
            clazz="info m-t--10"
        >
            <p class="m-b-0">{{$t('new-password.password-conditions')}}</p>
        </SimpleNote>

        <PasswordInput
            :inputType="passwordInputType"
            required
            labelFor="password"
            inputId="passwordConfirm"
            :hasViewPasswordIcon="true"
            :hasLevelBar="false"
            :label="$t('new-password.confirm-password-label')"
            :inputPlaceholder="$t('new-password.confirm-password-label')"
            v-on:passwordInput="onPasswordChangeLogin"
            v-on:passwordInputType="changeTypePassword"
        ></PasswordInput>

        <button
            id="restorePassword"
            class="button xs button-filled button-accent1 m-b-40 w-100"
            type="submit"
            @click.prevent="restorePassword"
        >{{$t('new-password.restore-password-button')}}</button>
    </div>
</template>

<script>
import PasswordPackMixin from "@/mixins/passwordPack.mixin.js";
import { mapGetters } from "vuex";
import {
    STORE as UserSignUpStore,
} from '@/store/modules/signin/signin.types'
const storeModule = "recoveryPassword";

export default {
    name: "NewPassoword",
    mixins: [PasswordPackMixin],
    mounted () {
        window.onload = function() {
            window.scrollTo(0, -100);
        };
    },
    methods: {
        restorePassword () {
            const token = this.$route.params.token;
            const password = this.registerPassword;
            const confirmPassword = this.loginPassword;

            if (password.length >= 6 && confirmPassword === password) {
                const params = {
                    token: token,
                    password: this.registerPassword,
                    confirmPassword: this.loginPassword
                };

                this.passwordMatchAlert = false
                return this.$store.dispatch(`${storeModule}/confirmRestorePassword`, params);
            }
            this.passwordMatchAlert = true;
            return this.passwordMatchAlert;
        }
    },

    computed: {
        ...mapGetters(storeModule, [
            "getRecoveryAlert",
            "getAlertMessage"
        ])
    },
    created () {
        this.$store.commit(`${UserSignUpStore}/SET_RIGHT_SIDE_BODY`, 'DONT_WORRY');
    }
};
</script>
