<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                        class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                        to="/client-admin/payments"
                >
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i> {{$t('pagos.return')}}
                </router-link>
                <FloatingTitleLayout :title="$t(`pagos.edit-types.${payment.type}`)"/>

            </div>

            <div class="col-12">
                <b-alert
                        class="alert-note"
                        show
                        variant="info"
                >
                    <p class="text-container">
                        <img
                                class="img-fluid icon"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                alt="info"
                        />
                        {{$t(`pagos.general.servicio.info.${payment.type}`, valuesForAlert)}}
                    </p>
                </b-alert>
            </div>

            <div class="col-12">
                <ServicesSummary editMode :payment="payment" @goTo="goTo" @finish="finish" @cancel="cancel"
                                v-if="!editing && payment" @prev="prev"></ServicesSummary>

                <ServicesGeneral editMode :payment="payment" v-if="editing && stepEmited == 0" @finish="finish"
                                @cancel="cancel" :slotValid="slotValid">
                    <component
                            v-if="steps[0].slot && steps[0].slotName"
                            :is="steps[0].slot"
                            :slot="steps[0].slotName"
                            :payment="payment"
                            @validityChange="slotValidityChange(0, $event)"
                    />
                </ServicesGeneral>

                <InformationalFields editMode :payment="payment" v-if="editing && stepEmited == 1 && payment.type == 'D'"
                                    @finish="finish" @cancel="cancel"></InformationalFields>
                <InformationalFields editMode :payment="payment" v-if="editing && stepEmited == 1 && payment.type == 'P'"
                                    @finish="finish" @cancel="cancel"></InformationalFields>
                <ServicesExpressConcepts editMode :payment="payment"
                                        v-if="editing && stepEmited == 1 && payment.type == 'EP'" @finish="finish"
                                        @cancel="cancel"></ServicesExpressConcepts>
                <WebConfig editMode :payment="payment" v-if="editing && stepEmited == 1 && payment.type == 'WS'"
                        @finish="finish" @cancel="cancel"></WebConfig>

                <Concepts editMode :payment="payment" v-if="editing && stepEmited == 2 && payment.type == 'P'"
                        @finish="finish" @cancel="cancel"></Concepts>
                <ServicesDebtFieldsReferences editMode :payment="payment"
                                            v-if="editing && stepEmited == 2 && payment.type == 'D'" @finish="finish"
                                            @cancel="cancel"></ServicesDebtFieldsReferences>

                <ServicesDiscounts editMode :payment="payment" v-if="editing && stepEmited == 3" @finish="finish"
                                @cancel="cancel"></ServicesDiscounts>
                <LoadingBar v-if="loading"></LoadingBar>
            </div>
        </div>
    </div>
</template>

<script>
    import ServicesGeneral from '@/views/services/General.vue';
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import InformationalFields from "@/views/services/InformationalFields.vue";
    import Concepts from "@/views/services/Concepts.vue";
    import ServicesDiscounts from '@/views/services/Discounts.vue';
    import ServicesSummary from '@/views/services/Summary.vue';
    import ServicesExpressConcepts from '@/views/services/ExpressPaymentConcepts.vue';
    import ServicesDebtFieldsReferences from '@/views/services/FieldsAndReferences.vue';
    import {mapGetters} from 'vuex';
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import PaymentsCRUDApi from '@/api/payments/crud.api.js';
    import ServicesGeneralAdvanced from "@/views/services/GeneralAdvanced.vue";
    import ServicesExpressGeneralAdvanced from '@/views/services/ExpressGeneralAdvanced.vue';
    import ServicesDebtGeneralAdvanced from '@/views/services/DebtGeneralAdvanced.vue';
    import ServicesWebGeneralAdvanced from '@/views/services/ServicesWebGeneralAdvanced.vue';
    import ServicesWebPayment from '@/views/services/WebServicePayment.vue';
    import WebConfig from '@/views/services/webPaymentConfig.vue';
    import LoadingBar from "@/components/LoadingBar.vue";

    const ERROR_TITLE_KEY = 'general.actions.error.title';
    const ERROR_TEXT_KEY = 'general.actions.error.text';
    const PAYMENTS_STEPS_GENERAL_KEY = 'pagos.steps.general';
    const PAYMENTS_STEPS_SUMMARY_KEY = 'pagos.steps.summary';
    const PAYMENTS_STEPS_CONCEPTS_KEY = 'pagos.steps.concepts';

    const PaymentTypes = {
        RegularPayment: 'P',
        ExpressPayment: 'EP',
        Debt: 'D',
        WebServicePayment: 'WS'
    };
    export default {
        mixins: [ToastSnotify],
        components: {
            ServicesGeneral,
            FloatingTitleLayout,
            InformationalFields,
            ServicesDiscounts,
            ServicesSummary,
            ServicesExpressConcepts,
            Concepts,
            ServicesGeneralAdvanced,
            ServicesExpressGeneralAdvanced,
            ServicesDebtGeneralAdvanced,
            ServicesDebtFieldsReferences,
            ServicesWebPayment,
            WebConfig,
            ServicesWebGeneralAdvanced,
            LoadingBar
        },
        mounted() {
            if (this.$route.params.type && this.$route.params.id) {
                this.loading = true;
                PaymentsCRUDApi.get(this.$route.params.type, this.$route.params.id).then((data) => {
                    this.loading = false;
                    this.payment = data.data.object;
                    this.paymentType = this.payment.type;
                    this.$forceUpdate();
                    this.setSteps();
                }).catch((error) => {
                    this.loading = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            } else {
                const editingPaymentJson = localStorage.getItem('editingPayment');
                let editingPayment;
                if (editingPaymentJson) {
                    editingPayment = JSON.parse(editingPaymentJson);
                    this.payment = editingPayment;
                }

                this.paymentType = (editingPayment ? editingPayment.type : null) || this.$route.params.type || PaymentTypes.RegularPayment;
                this.payment.type = this.paymentType;
                this.setSteps();
            }
        },
        data() {
            return {
                paymentType: PaymentTypes.ExpressPayment,
                payment: {
                    private: false,
                    acceptsMultipleUnits: false,
                    maxUnitsPerTransaction: 1,
                    tags: [],
                    images: [],
                    fieldsInfo: [],
                    fields: [],
                    references: [],
                    items: [],
                    concepts: [],
                    discounts: [],
                    additionalNotesConfig: {
                        isEnabled: false,
                        label: '',
                        placeholder: '',
                        isRequired: false,
                    }
                },
                steps: [],
                editing: false,
                stepEmited: 0,
                slotValid: true,
                loading: false,
            };
        },
        methods: {
            setSteps() {
                switch (this.paymentType) {
                    case PaymentTypes.ExpressPayment:
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_GENERAL_KEY),
                            icon: 'one',
                            component: 'ServicesGeneral',
                            slot: 'ServicesExpressGeneralAdvanced',
                            slotName: 'advancedSettings'
                        });
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_CONCEPTS_KEY),
                            icon: 'two',
                            component: 'ServicesExpressConcepts'
                        });
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY),
                            icon: 'three',
                            component: 'ServicesSummary'
                        });
                        break;
                    case PaymentTypes.Debt:
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_GENERAL_KEY),
                            icon: 'one',
                            component: 'ServicesGeneral',
                            slot: 'ServicesDebtGeneralAdvanced',
                            slotName: 'advancedSettings'
                        });
                        this.steps.push({
                            title: this.$t('pagos.steps.fields-references'),
                            icon: 'two',
                            component: 'ServicesDebtFieldsReferences'
                        });
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_CONCEPTS_KEY),
                            icon: 'three',
                            component: 'ServicesExpressConcepts'
                        });
                        this.steps.push({
                            title: this.$t('pagos.steps.discounts'),
                            icon: 'four',
                            component: 'ServicesDiscounts'
                        });
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY),
                            icon: 'five',
                            component: 'ServicesSummary'
                        });
                        break;
                    case PaymentTypes.WebServicePayment:
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_GENERAL_KEY),
                            icon: 'one',
                            component: 'ServicesGeneral',
                            slot: 'ServicesWebGeneralAdvanced',
                            slotName: 'advancedSettings'
                        });
                        this.steps.push({
                            title: this.$t('pagos.steps.web-config'),
                            icon: 'two',
                            component: 'WebConfig'
                        });
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY),
                            icon: 'three',
                            component: 'ServicesSummary'
                        });
                        break;
                    case PaymentTypes.RegularPayment:
                    default:
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_GENERAL_KEY),
                            icon: 'one',
                            component: 'ServicesGeneral',
                            slot: 'ServicesGeneralAdvanced',
                            slotName: 'advancedSettings'
                        });
                        this.steps.push({
                            title: this.$t('pagos.steps.fields'),
                            icon: 'two',
                            component: 'InformationalFields'
                        });
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_CONCEPTS_KEY),
                            icon: 'three',
                            component: 'ServicesConcepts'
                        });
                        this.steps.push({
                            title: this.$t('pagos.steps.discounts'),
                            icon: 'four',
                            component: 'ServicesDiscounts'
                        });
                        this.steps.push({
                            title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY),
                            icon: 'five',
                            component: 'ServicesSummary'
                        });
                        break;
                }
            },
            goTo(step) {
                this.stepEmited = step;
                this.editing = true;
                this.$forceUpdate();
            },
            cancel() {
                this.editing = false;
                this.loading = true;
                PaymentsCRUDApi.get(this.$route.params.type, this.$route.params.id).then((data) => {
                    this.payment = data.data.object;
                    this.loading = false;
                    this.$forceUpdate();
                    // eslint-disable-next-line sonarjs/no-identical-functions
                }).catch((error) => {
                    this.loading = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            },
            finish() {
                this.loading = true;
                PaymentsCRUDApi.save(this.payment.type, this.payment).then(() => {
                    this.displayNotificationSuccess(this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                    this.editing = false;
                    this.loading = false;
                    // eslint-disable-next-line sonarjs/no-identical-functions
                }).catch((error) => {
                    this.loading = false;
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            },
            prev() {
                this.$router.push('/client-admin/payments')
            },
            slotValidityChange(step, value) {
                this.$forceUpdate();
                this.slotValid = value;
            },
            numberToMoneyString(number) {
                if (isNaN(number)) {
                    return '0.00';
                }
                if (typeof number === 'string') {
                    number = Number.parseFloat(number);
                }
                return `$${number.toLocaleString(
                    'en-IN',
                    {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }
                )} ${this.currencyObject.code || ''}`;
            }
        },
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            valuesForAlert() {
                return {
                    expressPaymentMaxAmount: this.numberToMoneyString(this.currentOrganization.expressPaymentMaxAmount),
                    expressPaymentMinAmount: this.numberToMoneyString(this.currentOrganization.expressPaymentMinAmount),
                }
            },
            currencyObject() {
                return (this.currentOrganization ? this.currentOrganization.defaultCurrency : null) || {};
            }
        }
    }
</script>
