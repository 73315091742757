<template>
    <div class="form-group password-input" :class="clazz">
        <input :id="inputId" :type="inputType" class="form-control" :placeholder="inputPlaceholder" @input="onInputPassword" @keyup.enter="onEnterKey">
        <button class="show-btn" @click="onChangePasswordType" type="button">
            <i aria-hidden="true" class="banana" :class="[isVisibleOn ? 'banana-visibility-on': 'banana-visibility-off']"></i>
        </button>

        <div class="input-label">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <StrongLevel v-if="hasLevelBar" level :clazz="clazzPasswordStatus" :levelText="labelPasswordStatus"></StrongLevel>
    </div>
</template>

<script>
import StrongLevel from '@/components/StrongLevel.vue';
import { passwordStrength } from 'check-password-strength'

export default {
    name: 'PasswordInput',
    data() {
        return {
            isVisibleOn: true,
            pasword: '',
            passwordStrengthValue: {
                id: 0,
                value: 'too weak',
                contains: [],
                length: 0,
            },
        }
    },
    components: {
        StrongLevel
    },
    props: {
        help: {type: Boolean, default: false},
        idHelpTooltip: String,
        showlevel: {type: Boolean, default: false},
        clazzPassword: String,
        status: String,
        required: {type: Boolean, default: false},
        label: String,
        helpText: String,
        inputPlaceholder: String,
        helpIcon: String,
        labelFor: String,
        inputId: String,
        clazz: String,
        inputType: String,
        hasLevelBar: {
            type: Boolean,
            default: true
        },
        value: {
            type: String,
            required: false,
        },
    },
    computed: {
        clazzPasswordStatus() {
            if (this.clazzPassword) {
                return this.clazzPassword;
            }
            if (this.pasword.length === 0) {
                return "";
            }
            switch(this.passwordStrengthValue.id) {
                case 0: return "weak";
                case 1: return "regular";
                case 2: return "good";
                case 3: return "excellent";
                default: return "";
            }
        },
        labelPasswordStatus() {
            if (this.status) {
                return this.status
            }
            if (this.pasword.length === 0) {
                return "";
            }
            switch(this.passwordStrengthValue.id) {
                case 0: return "Muy débl";
                case 1: return "Regular";
                case 2: return "Buena";
                case 3: return "Excellente";
                default: return "";
            }
        },
        showStatusBar() {
            if (this.hasLevelBar === true || this.hasLevelBar === false) {
                return this.hasLevelBar
            }
            if (this.passwordStrengthValue.id === 0) {
                return false;
            }
            return true;
        }
    },
    methods: {
        onInputPassword(event) {
            this.pasword = event.target.value;
            this.passwordStrengthValue = passwordStrength(this.pasword);
            this.$emit('passwordInput', this.pasword);
            this.$emit('input', this.pasword);
            this.$emit('passwordStrengthChange', this.passwordStrengthValue);
        },
        onEnterKey(){
            this.$emit('enterKey');
        },
        onChangePasswordType() {
            this.isVisibleOn = !this.isVisibleOn;
            this.$emit('passwordInputType', this.isVisibleOn)
        },
    },
    beforeMount() {
        this.password = this.value || '';
    }
}
</script>
