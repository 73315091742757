<template>
    <div class="row p-b-100">
        <div class="col-12">
            <section id="main-section" class="main-section-landingpage about">
                <div class="width-responsive">
                    <span class="about-main-text">
                        <h2 class="about-title" v-html="$t('landing.about.title')"></h2>
                    </span>
                    <img class="img-fluid about-img" src="@/assets/images/LandingPage/about-bgm.png" alt="About"/>
                </div>
            </section>
        </div>
        <div class="col-12">
            <ButtonScrollBottom idScroll="#text-section" :btnText="$t('landing.about.know-more')"></ButtonScrollBottom>
        </div>
        <div class="col-12">
            <section id="text-section" class="text-section-landingpage">
                <div class="width-responsive">
                    <p>{{$t('landing.about.about-desc')}}</p>
                </div>
            </section>
        </div>
        <div class="col-12">
            <section id="numbers-section" class="numbers-section-landingpage">
                <div class="width-responsive">
                    <div class="numbers-container">
                        <div class="number-item">
                            <div class="number-side">

                            </div>
                            <div class="info-side">
                                <h1 v-html="$t('landing.about.ous-descriptions')"></h1>
                            </div>
                        </div>
                        <div class="number-item">
                            <div class="number-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Numbers/01.svg" alt="number">
                            </div>
                            <div class="info-side">
                                <h2>{{$t('landing.about.desc-1-title')}}</h2>
                                <p>{{$t('landing.about.desc-1-subtitle')}}</p>
                            </div>
                        </div>
                        <div class="number-item">
                            <div class="number-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Numbers/02.svg" alt="number">
                            </div>
                            <div class="info-side">
                                <h2>{{$t('landing.about.desc-2-title')}}</h2>
                                <p>{{$t('landing.about.desc-2-subtitle')}}</p>
                            </div>
                        </div>
                        <div class="number-item">
                            <div class="number-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Numbers/03.svg" alt="number">
                            </div>
                            <div class="info-side">
                                <h2>{{$t('landing.about.desc-3-title')}}</h2>
                                <p>{{$t('landing.about.desc-3-subtitle')}}</p>
                            </div>
                        </div>
                        <div class="number-item">
                            <div class="number-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Numbers/04.svg" alt="number">
                            </div>
                            <div class="info-side">
                                <h2>{{$t('landing.about.desc-4-title')}}</h2>
                                <p>{{$t('landing.about.desc-4-subtitle')}}</p>
                            </div>
                        </div>
                        <div class="number-item">
                            <div class="number-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Numbers/05.svg" alt="number">
                            </div>
                            <div class="info-side">
                                <h2>{{$t('landing.about.desc-5-title')}}</h2>
                                <p>{{$t('landing.about.desc-5-subtitle')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-12">
            <section id="features-section" class="features-section-landingpage">
                <div class="width-responsive">
                    <div class="row landing-feature max-w-1015px m-auto">
                        <div class="col-lg-6 col-12 d-flex align-items-c">
                            <div class="m-auto">
                                <TitleLandingpage clazz="left-text m-b-0 max-w-450px" showSmallText
                                                  :textSmall="$t('landing.about.comming-soon')"
                                                  :textTitle="$t('landing.about.pay-express-title')"
                                                  :textSubtitle="$t('landing.about.pay-express-subtitle')"></TitleLandingpage>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 d-flex align-items-c justify-content-end">
                            <div class="max-w-400px image-side">
                                <img class="img-fluid" src="@/assets/images/LandingPage/Recibepagosexpress.jpg"
                                     alt="feature">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import TitleLandingpage from "@/components/TitleLandingpage.vue";
    import ButtonScrollBottom from "@/components/ButtonScrollBottom.vue";
    import {
        STORE as LandingStore,
        SET_TAB_ACTIVE
    } from "@/store/modules/landing/landing.types";

    export default {
        name: "LandingAbout",
        components: {
            TitleLandingpage,
            ButtonScrollBottom
        },
        created() {
            this.$store.commit(`${LandingStore}/${SET_TAB_ACTIVE}`, 'OUS');
        }
    }
</script>
