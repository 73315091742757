<template>
    <CardSimple
        :title="$t('organizaciones.payments-configuration.title')"
        :subtitle="$t('organizaciones.payments-configuration.subtitle')"
    >
        <div class="row">
            <LoadingBar
                v-if="loading"
                :loadingText="$t('general.info.loading')"
            ></LoadingBar>
            <div class="col-12">
                <BasicSelect
                    required
                    :label="$t('organizaciones.payments-configuration.default-currency')"
                >
                    <multiselect
                        v-model="infoToEdit.defaultCurrency"
                        :options="currencies"
                        :close-on-select="true"
                        :preserve-search="true"
                        :placeholder="$t('organizaciones.payments-configuration.default-currency-select')"
                        label="description"
                        track-by="_id"
                        :custom-label="customCurrencyLabel"
                        :preselect-first="false"
                    >
                        <template
                            slot="selection"
                            slot-scope="{ values, search, isOpen }"
                        >
                            <span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} options selected</span>
                        </template>
                    </multiselect>
                </BasicSelect>
            </div>
            <div class="col-12">
                <div class="row" v-if="!loading && !currentOrganization.comingSoon">
                    <div class="col-12 col-md-4 m-b-30">
                        <label class="f-14 c-title body-font-regular d-block m-b-5">{{$t('organizaciones.payments-configuration.activeService')}}</label>
                        <BasicSwitch
                            id="ts2"
                            labelFor="ts2"
                            useVModel
                            v-model="infoToEdit.activeService"
                        ></BasicSwitch>
                    </div>
                </div>
            </div>
            <div class="col-12 m-t-40 vertical-center justify-content-end">
                <span class="f-14 c-info_text m-0-auto-0-0"><i aria-hidden="true" class="c-error">*</i> {{$t('general.required-fields')}}</span>
                <div class="vertical-center">
                    <button
                        type=""
                        class="button xs button-ghost button-plain_text"
                        @click="cancel"
                    >{{$t('general.actions.cancel.title')}}</button>
                    <button
                        type=""
                        class="button xs button-filled button-accent1 m-l-10"
                        @click="updateInfo"
                        :disabled="loading || hasError"
                    >{{$t('general.actions.save-changes')}}</button>
                </div>
            </div>
        </div>
    </CardSimple>
</template>
<script>
import CardSimple from '@/components/CardSimple.vue';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION, FETCH_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import {
    STORE as ORGANIZATION_STORE,
    GET_PAYMENTS_CONFIGURATION,
    FETCH_PAYMENTS_CONFIGURATION,
    UPDATE_PAYMENTS_CONFIGURATION
} from "@/store/modules/organization/organization.types";
import { mapGetters } from 'vuex';
import { utils } from '@/api/utils';
import { required } from 'vuelidate/lib/validators';
import LoadingBar from '@/components/LoadingBar.vue';
import BasicSelect from '@/components/BasicSelect.vue';
import Multiselect from 'vue-multiselect';
import BasicSwitch from '@/components/BasicSwitch.vue';
import CurrenciesApi from '@/api/currencies/currency.api';
import ToastSnotify from '@/mixins/toastSnotify.mixin';

const ERROR_TITLE_KEY = 'general.actions.error.title';
const ERROR_TEXT_KEY = 'general.actions.error.text';

export default {
    components: {
        CardSimple,
        LoadingBar,
        BasicSelect,
        Multiselect,
        BasicSwitch
    },
    mixins: [ToastSnotify],
    data() {
        return {
            loading: false,
            currencies: [],
            infoToEdit: {
                enableInvoicing: false,
                comingSoon: false,
                sandBoxMode: false,
                activeService: false
            }
        };
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_PAYMENTS_CONFIGURATION}`, this.currentOrganization._id).then(() => {
            this.prepareData();
            this.loading = false;
        });
        CurrenciesApi.get().then((data) => {
            this.currencies = data.data.object;
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.message) {
              this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
            } else {
              this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
            }
        });
    },
    methods: {
        prepareData() {
            this.infoToEdit = JSON.parse(JSON.stringify(this.infoComputed));
            this.loading = false;
        },
        cancel() {
            this.prepareData();
            utils.toast(this.$snotify, 'info', this.$t('general.actions.cancel.title'), this.$t('general.actions.cancel.text'));
        },
        updateInfo() {
            this.loading = true;
            this.$store.dispatch(`${ORGANIZATION_STORE}/${UPDATE_PAYMENTS_CONFIGURATION}`, {_id: this.currentOrganization._id, body: this.infoToEdit}).then(() => {
                utils.toast(this.$snotify, 'success', this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                this.$store.dispatch(`${ACCESS_STORE}/${FETCH_CURRENT_ORGANIZATION}`);
            }).catch((error) => {
                 if (error.response && error.response.data && error.response.data.message) {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    utils.toast(this.$snotify, 'error', this.$t(ERROR_TITLE_KEY), this.$t('general.actions.error.text'));
                }
                this.loading = false;
            });
        },
        deleteAddress(index) {
            this.infoToEdit.notificationEmails.splice(index, 1);
        },
        addAddress() {
            this.infoToEdit.notificationEmails.push({address: ""});
        },
        customCurrencyLabel(currency) {
            return `${currency.description} (${currency.code})`;
        }
    },
    computed: {
        hasError () { return this.$v.$invalid },
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
        ...mapGetters(ORGANIZATION_STORE, {
            paymentsConfiguration: GET_PAYMENTS_CONFIGURATION
        }),
        infoComputed() {
            return this.paymentsConfiguration || {};
        }
    },
    watch: {
        currentOrganization () {
            if (this.currentOrganization) {
                this.loading = true;
                this.$store.dispatch(`${ORGANIZATION_STORE}/${FETCH_PAYMENTS_CONFIGURATION}`, this.currentOrganization._id).then(() => {
                    this.prepareData();
                });
            }
        }
    },
    validations: {
        infoToEdit: {
            defaultCurrency: { required }
        }
    }
}
</script>
