<template>
    <div class="step-validation" v-bind:class="status" v-show="text">
        <div class="circle">
        <i aria-hidden="true" class="banana" v-bind:class="icon"></i>
        </div>
        <p>{{text}}</p>
    </div>
</template>

<script>
    export default {
        name: 'StepValidation',
        props: {
            status: String,
            icon: String,
            text: String
        }

    }
</script>
<style lang="scss">
    .step-validation {
        margin-bottom: 15px;
        width: 100%;
        border-radius: 4px;
        padding: 15px 17px;
        display: flex;
        align-items: center;
        cursor: not-allowed;

        .circle {
            width: 18px;
            height: 18px;
            min-width: 18px;
            border-radius: 50%;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }

        p {
            margin: 0px;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
        }
    }
</style>
