<template>
  <div :id="id" class="title-generic" v-bind:class="clazz">
    <h1>
      {{ title }}
      <strong>{{ titleStrong }}</strong>
    </h1>
    <label>{{ subtitle }}</label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "GenericTitle",
  props: {
    id: String,
    title: String,
    titleStrong: String,
    subtitle: String,
    clazz: String
  }
};
</script>


// Add "scoped" attribute to limit CSS to this component only
<style lang="scss">
.title-generic {
  display: block;
  margin: 0 0 40px;

  h1 {
    font-size: 34px;
    font-weight: bold;
    line-height: normal;
    display: block;
    margin: 0 0 10px;
  }

  label {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.39;
    display: block;
    margin: 0;
  }
}
</style>
