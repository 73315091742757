<template>
  <div class="card-item" v-bind:class="clazz">
    <a  v-if="isLink" class="link-card" :href="link"></a>

    <slot></slot>

    <div v-if="serviceCard">
      <div v-if="serviceDiscount">
        <div class="header-card">
          <img :src="imgBackground"  alt="background"/>
        </div>
        <div class="body-card">
          <div class="logo">
            <img :src="imgLogo"  alt="logo"/>
          </div>
          <div v-if="discountPrice" class="discount-tag">Descuento</div>
          <h1 class="type">{{category}}</h1>
          <h2 class="service-name">{{serviceName}}</h2>
          <div class="footer-price">
            <div class="discount">
              <div v-if="discountPrice" class="prev-price">${{formatNumberToString(prevPrive)}}</div>
              <div v-if="discountPrice" class="discount-price">- ${{formatNumberToString(discountPrice)}}</div>
            </div>
            <div class="final-price">${{formatNumberToString(finalPrice)}}</div>
          </div>
        </div>
      </div>
      <div v-if="serviceFinalPrice">
        <div class="header-card">
          <img :src="imgBackground"  alt="background"/>
        </div>
        <div class="body-card">
          <div class="logo">
            <img :src="imgLogo"  alt="logo"/>
          </div>
          <h1 class="type">{{category}}</h1>
          <h2 class="service-name">{{serviceName}}</h2>
          <div class="footer-price">
            <div class="final-price">${{finalPrice}}</div>
          </div>
        </div>
      </div>
      <div v-if="serviceDefault">
        <div class="header-card">
            <img :src="imgBackground"  alt="background">
        </div>
        <div class="body-card">
          <div class="logo">
            <img :src="imgLogo"  alt="logo"/>
          </div>
          <h1 class="type">{{category}}</h1>
          <h2 class="service-name">{{serviceName}}</h2>
          <div class="footer-price">
            <span class="see-more">Ver mas</span>
          </div>
        </div>
      </div>
    </div>

    <div class="h-100" v-if="organizationCard">
        <div class="background-card">
            <img :src="imgBackground"  alt="background">
        </div>
        <div class="layer">

        </div>
        <div class="body-card">
            <div class="logo">
            <img :src="imgLogo"  alt="logo"/>
            </div>
            <div class="footer-info">

                <h2 class="service-name">{{orgnizationName}}</h2>

                <span>{{servicesNum}}</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardItem",
  props: {
    clazz: String,
    serviceCard: { type: Boolean, default: false },
    serviceDiscount: { type: Boolean, default: false },
    serviceFinalPrice: { type: Boolean, default: false },
    serviceDefault: { type: Boolean, default: false },
    organizationCard: { type: Boolean, default: false },
    isLink: { type: Boolean, default: false },
    category: String,
    serviceName: String,
    prevPrive: Number,
    discountPrice: Number,
    finalPrice: { type: Number, default: 0 },
    orgnizationName: String,
    servicesNum: String,
    link: String,
    imgBackground: {
        type: String,
    },
    imgLogo: {
        type: String,
    }
  },
  methods: {
    formatNumberToString(number) {
      if(!number)
        {return '0.00';}
      if(isNaN(number))
        {return number;}

      return number.toLocaleString(
        'en-IN',
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      );
    }
  }
};
</script>
