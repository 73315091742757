<template>
    <div id="topPage" class="landingpage-full-content">
        <!-- Top Bar  -->
        <TopBarLanding idTopbar=""></TopBarLanding>

        <!-- Body Content -->
        <router-view/>

        <!-- Footer -->
        <FooterLanding></FooterLanding>

        <!-- Button scroll top page -->
        <ButtonScrollTop idScroll="#topPage"></ButtonScrollTop>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TopBarLanding from "@/components/TopBarLanding.vue";
    // import AppStore from "@/components/AppStore.vue";
    import FooterLanding from "@/components/FooterLanding.vue";
    import ButtonScrollTop from "@/components/ButtonScrollTop.vue";

    export default {
        name: "LandingPageContent",
        components: {
            TopBarLanding,
            // AppStore,
            FooterLanding,
            ButtonScrollTop
        },
        props: {},
        data() {
            return {}
        },
        mounted() {
        },
        destroyed() {
        },
        methods: {}
    };
</script>
