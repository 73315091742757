import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'payment';

export default {
    refund: (orderNumber, reasonId, amount) => {
        return axios.post(
            `${base.baseUrlPayment}/${namespace}/refund-order-organization`,
            {orderNumber, reasonId, amount}
        ).then((response) => response.data.object);
    },
}
