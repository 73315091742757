<template>
    <CardSimple
        :title="$t('pagos.rapido.conceptos.title')"
        :subtitle="$t('pagos.rapido.conceptos.subtitle')"
    >
        <div class="row">
            <div class="col-12">
            </div>
            <div class="col-12">
                <div
                    v-for="id in payment.concepts.length"
                    :key="id"
                >
                    <div class="vertical-center m-b-30">
                        <div class="w-90">
                            <BasicInput
                                clazz="m-b-0"
                                required
                                v-model="payment.concepts[id - 1].name"
                                @input="change"
                                labelFor="concepto"
                                inputId="concepto"
                                :label="$t('pagos.rapido.conceptos.input.title')"
                                :inputPlaceholder="$t('pagos.rapido.conceptos.input.placeholder')"
                            >
                            </BasicInput>
                        </div>
                        <div class="w-10 vertical-center justify-content-end">
                            <button
                                class="button-circle sm button-ghost button-error m-t-15"
                                @click="deleteField(id - 1)"
                            >
                                <i aria-hidden="true" class="banana banana-delete"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <a
                    href
                    class="button-square-add"
                    tabindex=""
                    @click="addField"
                >
                    <i aria-hidden="true" class="banana banana-symbol-plus"></i> {{ $t('pagos.rapido.conceptos.add-concept') }}
                </a>
            </div>
            <div class="col-12 vertical-center justify-content-end m-t-40">
                <button
                    v-if="!editMode"
                    type="button"
                    class="button xs button-ghost button-plain_text m-0-auto-0-0"
                    @click="prev"
                >
                    <i aria-hidden="true" class="banana banana-chevron-left"></i>
                    {{ $t('general.actions.back.title') }}
                </button>

                <div class="vertical-center">
                    <button
                        type="button"
                        class="button xs button-ghost button-plain_text"
                        @click="cancel"
                    >
                        {{ $t('general.actions.cancel.title') }}
                    </button>

                    <button
                        v-if="!editMode"
                        type="button"
                        class="button xs button-filled button-accent1"
                        :class="{disabled: !isValid}"
                        @click="next"
                    >
                        {{ $t('general.actions.next.title') }}
                    </button>
                    <button
                        v-if="editMode"
                        class="button button-filled sm button-accent1 m-l-10"
                        :class="{disabled: !isValid}"
                        @click="finish"
                    >
                        {{$t('general.actions.save')}}
                    </button>
                </div>
            </div>
        </div>
    </CardSimple>
</template>

<script>
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from '@/components/CardSimple.vue';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'express-concepts',
    components: {
        CardSimple,
        BasicInput,
    },
    props: {
        editMode: {type: Boolean, default: false},
        payment: {
            type: Object,
            default: function () {
                return {
                    concepts: []
                };
            }
        }
    },
    data () {
        return {
            isValid: true
        }
    },
    methods: {
        cancel () {
            this.$emit('cancel');
        },
        next () {
            this.$emit('next');
        },
        finish () {
            this.$emit('finish');
        },
        prev () {
            this.$emit('prev');
        },
        deleteField (i) {
            this.payment.concepts.splice(i, 1);
            this.change();
            this.$forceUpdate();
        },
        addField (e) {
            const newField = {
                name: ''
            };
            this.payment.concepts.push(newField);
            // this.payment.concepts.push('');
            e.preventDefault();
            this.change();
            this.$forceUpdate();
        },
        change () {
            this.isValid = !this.payment.concepts.find(f => !f.name || f.name.length <= 0);
            this.$forceUpdate();
        }
    },
    validations: {
        payment: {
            concepts: {
                $each: {
                    name: {
                        required,
                        minLength: minLength(2),
                        maxLength: maxLength(2),
                    }
                }
            }
        }
    }
}
</script>
