export default {
    data() {
        return {
            inputValue: this.value,
        };
    },
    watch: {
        value(val) {
            this.inputValue = val;
        },
    },
    props: {
        value: {},
    },
}
