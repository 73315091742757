<template>
    <div>
        <!-- DOCUMENTATION (example from Avocado) - IntroJs https://introjs.com/docs/intro/api/

            activeOnboarding: To show onboarding modal, default 'true' (Boolean).
            refs:             To hide onboarding modal when intro starts.
            title:            Text title for modal (String).
            subtitle:         Text subtitle for modal (String).
            closeModal:       Text for button close modal (String).
            beginOnboarding:  text for button start onboarding (String).
        -->
        <b-modal id="modal-onboarding" ref="modalOnboarding" hide-header hide-footer no-close-on-backdrop>
            <div>
                <h1 class="f-24 c-primary_1 principal-font-bold text-align-c d-block m-b-20">{{title}}</h1>
                <p class="f-16 c-plain_text body-font-regular text-align-c d-block m-b-40">{{subtitle}}</p>
                <div class="horizontal-center max-w-260px m-0-auto">
                    <button class="button xs button-ghost button-plain_text m-0-auto-0-0"
                            @click="$bvModal.hide('modal-onboarding')">{{closeModal}}
                    </button>
                    <button class="button xs button-filled button-accent1 m-0-0-0-auto" @click="startOnboarding">
                        {{beginOnboarding}}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "OnboardingComponent",
        props: {
            activeOnboarding: {type: Boolean, default: true},
            title: String,
            subtitle: String,
            closeModal: String,
            beginOnboarding: String
        },
        data: function () {
            return {
                introJs: require("intro.js").introJs(),
                window: {
                    width: 0
                }
            }
        },
        methods: {
            // Shows modal
            showModal() {
                this.$refs.modalOnboarding.show();
            },
            // Init Introjs configuration
            initOnboarding() {
                this.introJs.setOptions({
                    'skipLabel': '<i aria-hidden="true" class="banana banana-cross-normal"></i>',
                    'nextLabel': '<label> Siguiente </label>',
                    'prevLabel': '<label> Anterior </label>',
                    'doneLabel': '<label> Terminar </label>',
                    tooltipPosition: 'right',
                    exitOnEsc: true,
                    exitOnOverlayClick: false,
                    showButtons: true,
                    showBullets: true,
                    steps: [
                        {
                            element: '#onboardingStep1',
                            intro: "<img class='img-fluid' src='/img/Illustrations/illustration-step-1.svg'/>" +
                                "<div><strong>Saldo Pendiente</strong><p>Es el dinero que Pagando te depositará por los pagos de tus clientes. Pagando te deposita solo los días " +
                                "<strong>lunes, miércoles y viernes.</strong.></p></div>"
                        },
                        {
                            element: '#onboardingStep2',
                            intro: "<img class='img-fluid' src='/img/Illustrations/illustration-step-2.svg'/><div>" +
                                "<strong>Tu Organización</strong><p>Como administrador de tu Organización, " +
                                "tienes a disposición diferentes secciones con las cuales puedes gestionar " +
                                "tus servicios, los pagos de tus clientes, entre otras cosas de interés.</p></div>"
                        },
                        {
                            element: '#onboardingStep3',
                            intro: "<img class='img-fluid' src='/img/Illustrations/illustration-step-3.svg'/><div><strong>Cambiar Organización</strong>" +
                                "<p>Aquí puedes cambiar entre Organizaciones a las que tienes acceso, además de poder editar su configuración si tienes los " +
                                "<strong>permisos adecuados.</strong></p></div>"
                        },
                        {
                            element: '#onboardingStep4',
                            intro: "<img class='img-fluid' src='/img/Illustrations/illustration-step-4.svg'/><div><strong>Agregar Servicios</strong>" +
                                "<p>Desde aquí podrás agregar de manera fácil y rápida nuevos servicios como: <strong>Pago Regular, Pago con Requisitos, " +
                                "Pagos con Servicios Web y Pago Rápido.</strong></p></div>"
                        }
                    ]
                });
            },
            // Start Introjs and hide modal
            startOnboarding() {
                this.initOnboarding();
                this.introJs.start();
                this.$refs.modalOnboarding.hide();
            },
            // Change 'activeOnboarding' to False, to never show modal on window < 768.
            handleResize() {
                this.window.width = window.innerWidth;
                if (window.innerWidth < 768) {
                    this.activeOnboarding = false;
                }
            }
        },
        mounted() {
            // Init 'handleResize'
            this.handleResize();
            // Check if moadl can be show
            if (this.activeOnboarding) {
                this.showModal();
            }
            // Always watch 'handleResize' on resize
            window.addEventListener('resize', this.handleResize);
        }
    }
</script>
