import utils from '@/common/utils';
import PATTERNS from '@/common/consts/patterns.consts';

export default {
    getImageUrl(image) {
        if (!image) {
            return '';
        }
        if (image.url) {
            return `https://static.pagando.mx/${image.url}`;
        }
        if (image._id) {
            return `/images/${image._id}`;
        }
        return `/images/${image}`;
    },
    getRandomImagePattern(text) {
        return {
            kind: utils.getRandomElement(PATTERNS.KINDS),
            color: utils.getRandomElement(PATTERNS.COLORS),
            text: text,
        };
    },

};
