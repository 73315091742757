<template>
    <div class="row" v-if="payment.element">
        <router-link
                :to="{name: 'DetailPaymentTemplate', params: { idPaymentPending: payment._id.toString() }}"
                class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                tabindex=""
        >
            <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i> {{$t('detail-payment-template.return-to-service',
            {'name': payment.element.debt.name })}}
        </router-link>


        <div class="col-12">
            <CardSimple clazzTitle="d-none" clazz="m-b-20">
                <div class="row">
                    <div class="col-md-6 col-12 m-b-20">
                        <h1 class="f-20 c-primary_1 principal-font-bold m-0"> {{
                            $t('detail-payment-template.table.data-of-service') }} </h1>
                    </div>
                    <div class="col-md-6 col-12 m-b-20">
                        <div class="row">
                            <div class="col-lg-5 col-12 d-flex mb-lg-0 mb-2">
                                <span class="m-r-20 f-14 body-font-bold c-title"> {{ $t('detail-payment-template.table.status-service') }} </span>
                                <Badge :type="getClassStatusService()" :text="$t(getTextStatusService())"></Badge>
                            </div>
                            <div class="col-lg-7 col-12 d-flex">
                                <span class="m-r-20 f-14 body-font-bold c-title"> {{ $t('detail-payment-template.table.payment-type') }} </span>
                                <span class="f-14 body-font-regular c-plain_text"> {{$t('detail-payment-template.table.debt-payment')}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 ">
                        <h1 class="f-20 c-title body-font-regular m-0 m-b-10"> {{ payment.element ?
                            payment.element.debt.name : '' }} </h1>
                        <h2 class="f-14 body-font-regular f-600 c-info_text text-upper mb-lg-0 mb-2">
                            {{ $t(`organizaciones.category.options.${payment.element.clasification}`) }}
                        </h2>
                    </div>
                    <div class="col-md-6 col-12 ">
                        <p class="c-plain_text f-14 body-font-regular m-0"> {{payment.element.description}} </p>
                    </div>
                </div>
            </CardSimple>
        </div>

        <div class="col-12">
            <SearchBar
                    ref="searchBar"
                    :columValues="columnValues"
                    :canCreate="showCreate"
                    clazzFilter="m-r-0"
                    v-on:searchTextChange="searchTextChange"
            ></SearchBar>
        </div>

        <div class="col-12">
            <CardSimple clazz="w-100 p-0" hideTitle>
                <div class="basic-table">
                    <b-table-simple
                            responsive
                            table-variant="xl">
                        <b-thead>
                            <b-tr class="">
                                <template v-for="(col, cIndex) in tableColumns">
                                    <b-th v-show="columnValue(cIndex).value" sortable :key="col.name">

                                        <div class="align-items-c d-flex">
                                            <span
                                                    class="open-filters"
                                                    @click="headerFilters()"
                                            >{{ tableColumns[cIndex].name }}</span>

                                            <strong class="float-r m-l-5">
                                                <div
                                                        @click="updateSort(tableColumns[cIndex].nameForMatch, 1)"
                                                        class="h-10px align-items-c d-flex c-pointer"
                                                >
                                                    <i aria-hidden="true" class="banana banana-triangle-up f-14"></i>
                                                </div>
                                                <div
                                                        @click="updateSort(tableColumns[cIndex].nameForMatch, - 1)"
                                                        class="h-10px align-items-c d-flex c-pointer"
                                                >
                                                    <i aria-hidden="true" class="banana banana-triangle-down f-14"></i>
                                                </div>
                                            </strong>
                                        </div>
                                        <div
                                                class="filters"
                                                :class="[filtersHeader ? 'show' : '']"
                                        >
                                            <BasicInput
                                                    @input="headFilterChange"
                                                    v-model="match[tableColumns[cIndex].nameForMatch]"
                                                    :labelFor="tableColumns[cIndex].name"
                                                    :inputId="tableColumns[cIndex].name"
                                                    :inputPlaceholder="tableColumns[cIndex].name"
                                                    clazz="noLabel"
                                            ></BasicInput>
                                        </div>
                                    </b-th>
                                </template>

                                <b-th class="text-align-c w-5"> {{$t('general.actions.actions')}}</b-th>

                            </b-tr>
                        </b-thead>

                        <b-tbody v-if="debtorsFormatted.length === 0">
                            <b-tr role="row" class="b-table-busy-slot">
                                <b-td :colspan="tableColumns.length + 3" role="cell" class="">
                                    <EmptyState
                                            emptyStateDefault size="sm"
                                            :title="$t('detail-payment-template.general.query.title')"
                                    >
                                        <button @click="resetFilters" type="button"
                                                class="button xs button-stroke button-accent1 m-0-auto m-t-30">{{
                                            $t('detail-payment-template.general.query.clear-filters') }}
                                        </button>
                                    </EmptyState>
                                </b-td>
                            </b-tr>
                        </b-tbody>

                        <b-tbody v-if="debtorsFormatted.length > 0">
                            <b-tr
                                    v-for="(debtor, index) in debtorsFormatted"
                                    :class="bodyTableRowClass(index)"
                                    :key="index"
                            >
                                <template v-for="(col, cIndex) in tableColumns">
                                    <b-td
                                            v-show="columnValue(cIndex).value"
                                            :key="cIndex"
                                            class
                                    >
                                        <div v-if="cIndex === 0" class="d-flex align-items-c">
                                            <router-link
                                                    to
                                                    class="c-primary_1"
                                                    v-show="columnValue(cIndex).value"
                                            >
                                                <strong>{{(debtor[col.name] || {}) || ''}}</strong>
                                            </router-link>
                                        </div>
                                        <div v-show="cIndex > 0 && columnValue(cIndex)">
                                            {{(debtor[col.name] || {}) || ''}}
                                        </div>
                                    </b-td>

                                </template>
                                <b-td
                                        v-show="debtorsFormatted.length > 0"
                                        class="text-align-c w-5">
                                    <a tabindex="" @click="showDetailsDebt(debtor)"
                                       class="button-circle xs button-ghost button-info_text m-0-auto">
                                       <i aria-hidden="true" class="banana banana-visibility-on"></i>
                                    </a>
                                </b-td>

                            </b-tr>
                        </b-tbody>


                    </b-table-simple>
                </div>
            </CardSimple>

            <div class="pagination-custom">
                <span class="pagination-count"> {{ total }} {{ total  > 1 ? $tc('detail-payment-template.debtors', 2) : $tc('detail-payment-template.debtors', 1) }} </span>
                <b-pagination
                        v-if="!loading && total > 0"
                        v-model="currentPageSelected"
                        :per-page="perPageSelected"
                        :total-rows="total"
                        aria-controls="my-table"
                        next-text
                        prev-text
                        hide-goto-end-buttons
                        @change="pageChange"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import CardSimple from '@/components/CardSimple.vue';
    import Badge from "@/components/Badge";
    import SearchBar from '@/components/SearchBar.vue';
    import BasicInput from "@/components/BasicInput.vue";
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import EmptyState from '@/components/EmptyState.vue';
    import utils from '@/common/utils';
    import {mapGetters} from 'vuex';
    import {
        STORE as PaymentTemplateStore,
        SET_PAYMENT_SELECTED,
        GET_PAYMENT_INFO,
        GET_PAYMENT_VALID,
        GET_PAYMENT_DEBTS,
        GET_DEBTS_LENGTH,
        GET_DEBTS_CURRENT_PAGE,
        GET_DEBTS_PER_PAGE,
        GET_LOADING_DETAIL,
        SET_LOADING_DETAIL
    } from '@/store/modules/paymentstemplate/paymentsTemplate.types';

    export default {
        name: "DebtorsPaymentTemplate",
        components: {
            CardSimple,
            SearchBar,
            BasicInput,
            Badge,
            EmptyState
        },
        mixins: [ToastSnotify],
        data() {
            return {
                columnValues: [],
                tableColumns: [],
                match: {},
                filtersHeader: false,
                selectedRows: [],
                debtorsFormatted: [],
                findObject: {
                    idPayment: '',
                    currentPage: 1,
                    perPage: 10,
                    search: '',
                    match: {},
                    sort: '',
                    typeSort: ''
                },
                searchText: "",
                showCreate: false,
            }
        },
        watch: {
            payment() {
                this.tableColumns = this.getHeadersFields();
                this.columnValues = this.tableColumns.map(c => {
                    return {
                        name: c.name,
                        label: c.name,
                        value: true,
                    }
                });
            },
            debtsPayments() {
                this.debtorsFormatted = this.convertedDebtors();
            }
        },
        computed: {
            ...mapGetters(PaymentTemplateStore, {
                payment: GET_PAYMENT_INFO,
                isValid: GET_PAYMENT_VALID,
                debtsPayments: GET_PAYMENT_DEBTS,
                total: GET_DEBTS_LENGTH,
                currentPageSelected: GET_DEBTS_CURRENT_PAGE,
                perPageSelected: GET_DEBTS_PER_PAGE,
                loading: GET_LOADING_DETAIL
            }),
        },
        methods: {
            getDetailPayment() {
                this.findObject.idPayment = this.$route.params.idPaymentPending;
                this.$store.commit(`${PaymentTemplateStore}/${SET_LOADING_DETAIL}`, true);

                this.$store.dispatch(`${PaymentTemplateStore}/getDetailRegularPayment`, this.findObject).then((result) => {
                    if (!result.error) {
                        this.$store.commit(`${PaymentTemplateStore}/${SET_PAYMENT_SELECTED}`, result.object);
                    } else {
                        this.displayNotificationWarning(result.message);
                    }
                    this.$store.commit(`${PaymentTemplateStore}/${SET_LOADING_DETAIL}`, false);
                }).catch((err) => {
                    this.$store.commit(`${PaymentTemplateStore}/${SET_LOADING_DETAIL}`, false);
                    if (err) {
                        this.displayNotificationWarning(this.$i18n.t('detail-payment-template.get-info.error'));
                    }

                });
            },
            getClassStatusService() {
                if (this.payment.element && this.payment.element.activeService === '1') { // Activo
                    return 'badge-accent_2 m-b-10 m-r-10'
                } else if (this.payment.element && this.payment.element.activeService === '2') { // Inactivo
                    return 'badge-placeholder m-b-10 m-r-10'
                }
                // proximamente
                return 'badge-accent_1 m-b-10 m-r-10'
            },
            getTextStatusService() {
                if (this.payment.element && this.payment.element.activeService === '1') { // Activo
                    return 'payment-template.service-status.active'
                } else if (this.payment.element && this.payment.element.activeService === '2') { // Inactivo
                    return 'payment-template.service-status.inactive'
                }

                // proximamente
                return 'payment-template.service-status.cooming-soon'
            },
            getHeadersFields() {
                if (this.payment && this.payment.element) {
                    this.payment.element.debt.fields.forEach((f, i) => {
                        f.nameForMatch = `field${i}`;
                    });
                    this.payment.element.debt.references.forEach((r, i) => {
                        r.nameForMatch = `reference${i}`;
                    });
                }

                return this.payment && this.payment.element ? [...this.payment.element.debt.fields, ...this.payment.element.debt.references] : [];
            },
            convertedDebtors() {
                const debtors = [];

                if (this.debtsPayments) {
                    this.debtsPayments.forEach((debt) => {
                        const tempDebt = {
                            _id: debt._id
                        };
                        debt.element.fields.forEach((f) => {
                            tempDebt[f.name] = f.value;
                        });
                        debt.element.references.forEach((r) => {
                            tempDebt[r.name] = r.value;
                        });
                        debtors.push(tempDebt);
                    })
                }

                return debtors;
            },
            columnValue(index) {
                if (this.columnValues && this.columnValues[index]) {
                    return this.columnValues[index];
                }
                return {};
            },
            headFilterChange() {
                this.findObject.match = this.match;
                this.getDetailPayment();
            },
            headerFilters() {
                this.filtersHeader = !this.filtersHeader;
            },
            bodyTableRowClass(index) {
                if (this.selectedRows[index]) {
                    return 'b-table-row-selected table-active';
                }
                return '';
            },
            checkboxRefName(index) {
                return `checkbox_${index}`;
            },
            resetFilters() {
                this.searchQuery = '';
                this.match = {};
                this.$refs.searchBar.resetInput();
            },
            showDetailsDebt(paymentDebt) {
                this.$router.push({name: 'DebtorDetail', params: {idPaymentPending: paymentDebt._id.toString()}});
            },
            pageChange(page) {
                this.findObject.currentPage = page;
                this.getDetailPayment();
            },
            searchTextChange(searchText) {
                utils.debounceFixed(() => {
                    this.searchText = searchText;
                    this.findObject.searchText = searchText;
                    this.getDetailPayment();
                })
            },
            updateSort(field, order) {
                this.findObject.sort = {};
                this.findObject.sort[field] = order;
                this.getDetailPayment();
            },

        },
        created() {
            if (!this.payment.length) {
                this.getDetailPayment();
            }
        }
    }
</script>
