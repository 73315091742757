<template>
    <CardSimple>
        <LoadingBar v-if="loading"/>
        <div v-else>
            <div v-for="(el, k, index) in businessConfig" :key="k" class="row">
                <div :class="index === 0 ? 'col-12' : 'col-12 m-t-30'">
                    <div class="title-card">
                        <h1>
                            {{$t(`organizaciones.config-details.${k}.title`)}}
                            <label>{{$t(`organizaciones.config-details.${k}.desc`)}}</label>
                        </h1>
                    </div>
                </div>
                <div class="col-12">
                    <ul class="simple-list">
                        <li v-for="(opt, i) in el" :key="i">{{opt.description}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </CardSimple>
</template>

<script>
import CardSimple from '@/components/CardSimple.vue';
import LoadingBar from '@/components/LoadingBar.vue';
import PagandoConfigApi from '@/api/pagandoConfig.api.js';

export default {
    name: 'AccountLimits',
    components: {
        CardSimple,
        LoadingBar
    },
    data () {
        return {
            businessConfig: [],
            loading: true
        }
    },
    mounted () {
        this.getTabsInfo();
    },
    methods: {
        getTabsInfo(){
            PagandoConfigApi.getBusinessConfig(true).then(response => {
                this.businessConfig = response.data.object;
                this.loading = false;
            }).catch(error => this.toastErrorDefault(error));
        }
    }
}
</script>
