// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/Logos/Cards/card-bgm.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/images/Logos/Cards/credit-card-back.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bank-card-preview{width:100%;max-width:300px;height:180px;border-radius:10px;display:block;position:relative;padding:20px;margin:0 auto;border:none;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:cover}.bank-card-preview .type-card{position:absolute;top:30px;left:20px}.bank-card-preview .bgm-type-card,.bank-card-preview .info-card{position:absolute;bottom:0;left:0}.bank-card-preview .info-card{width:100%;padding:20px}.bank-card-preview .info-card>span{font-size:17px;font-weight:300;line-height:normal;letter-spacing:3.4px;display:block;margin-bottom:26px;width:100%}.bank-card-preview .info-card>label{margin:0 auto 0 0;float:left}.bank-card-preview .info-card>label,.bank-card-preview .info-card>small{font-size:12px;font-weight:300;line-height:normal;letter-spacing:-.09px;text-transform:uppercase;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex}.bank-card-preview .info-card>small{margin:0 0 0 auto;float:right}.bank-card-preview.empty-info .info-card>label,.bank-card-preview.empty-info .info-card>small,.bank-card-preview.empty-info .info-card>span{opacity:.4;font-weight:300}.bank-card-preview .back-card{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;position:absolute;width:100%;height:100%;top:0;left:0;border-radius:10px;z-index:2}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
