<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/payment-link-list"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit p-0"
                    tabindex=""
                >
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i> {{$t('paymentLinks.return')}}
                </router-link>
                <FloatingTitleLayout :title="$t('paymentLinks.created')" />
            </div>

            <div class="col-12" v-if="loading">
                <LoadingBar ></LoadingBar>
            </div>

            <div class="col-12" v-if="!loading">
                <CardSimple clazzTitle="m-b-10" :title="$t('paymentLinks.shareTitle2')">
                    <template v-slot:titleButtons>
                        <button @click="goToEdit()" v-if="$can('do', 'PAYMENT_LINK_EDIT')"
                            type=""
                            class="button xs button-stroke button-accent1"
                        >
                            {{$t('paymentLinks.edit')}}
                        </button>
                    </template>
                    <div class="row">
                        <div class="col-12">
                            <div class="text-divider line-primary2">
                                <label class="c-primary_2 f-18 principal-font-bold"> {{$t('paymentLinks.share')}} </label>
                            </div>
                        </div>
                        <div class="col-md-9 col-sm-8 col-12">
                            <BasicInput
                                disabled
                                inputClazz="c-title"
                                clazz="noLabel"
                                class="search-type"
                                inputType="text"
                                :value="link"
                                :readOnly="true"
                                inputPlaceholder="Buscar...">
                                <button type="button" class="button xs button-filled button-accent1" @click="copy()">
                                    {{$t('paymentLinks.copy')}}
                                </button>
                            </BasicInput>
                        </div>
                        <div class="col-md-3 col-sm-4 col-12 p-t-5">
                            <button v-if="active && status === 'ACTIVE'"
                                class="button xs button-filled button-accent1 ml-auto w-100 m-b-30"
                                @click="$bvModal.show('modal-comparte')"
                                type="button"
                            >
                                {{$t('paymentLinks.share')}}
                            </button>
                            <button v-if="!active && status === 'DISABLED' && $can('do', 'PAYMENT_LINK_ACTIVE')"
                                class="button xs button-filled button-accent1 ml-auto w-100 m-b-30"
                                @click="activeDeactiveLink()"
                                type="button"
                            >
                                {{$t('paymentLinks.active')}}
                            </button>
                        </div>
                        <div class="col-sm-2 col-4 m-b-30">
                            <div class="img-preview-sm">
                                <img
                                    :src="getImageUrl()"
                                    alt="Logo"
                                >
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-7 col-8">
                            <ReadOnlyInfo
                                textClazz="f-20 c-accent_2 f-bold"
                                :title="concept"
                                :text="amount | currency"
                            ></ReadOnlyInfo>
                        </div>
                        <div class="col-lg-2 col-sm-3 col-4">
                            <ReadOnlyInfo
                                :title="$t('paymentLinks.paymentType')"
                                :text="getPromoTitle()"
                            ></ReadOnlyInfo>
                        </div>
                        <div class="col-lg-4 col-sm-9 col-8">
                            <ReadOnlyInfo
                                clazz="text-lg-right"
                                :title="$t('paymentLinks.availabilityDate')"
                                v-if="expDate.startDate && expDate.endDate"
                            >
                                <p>{{getDate(expDate.startDate)}} <br>
                                {{getDate(expDate.endDate)}}</p>
                            </ReadOnlyInfo>
                            <ReadOnlyInfo
                                clazz="text-lg-right"
                                :title="$t('paymentLinks.availabilityDate')"
                                v-else
                            >
                                <p>{{$t('paymentLinks.undefined')}}</p>
                            </ReadOnlyInfo>
                        </div>
                        <div class="col-12 m-t-40">
                            <CollapseInfo
                            clazz="form-collapsible"
                            :checkboxRight="false"
                            collapseId="info"
                            :permissionName="$t('paymentLinks.moreOptions')"
                            >
                                <div class="p-t-30">
                                    <div class="row">
                                        <div class="col-12" v-if="checkPaymentMethods('CREDIT_DEBIT_CARD')">
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <ReadOnlyInfo
                                                        :title="$t('paymentLinks.creditDebitCard')"
                                                        :text="$t('paymentLinks.creditDebitCardSubtitle')"
                                                    >
                                                        <div class="select-checkbox-readOnly p-t-10" v-if="valuePromo.length > 0">
                                                            {{$t('paymentLinks.promosAvailablesSelected')}}
                                                            <ul>
                                                                <li v-for="(item, index) in valuePromo" :key="index">{{item.name}}</li>
                                                            </ul>
                                                        </div>
                                                    </ReadOnlyInfo>
                                                </div>
                                                <div class="col-12 col-md-4 text-md-right text-center p-t-15">
                                                    <img
                                                        height="25"
                                                        width="180"
                                                        class="img-fluid"
                                                        src="@/assets/images/Logos/logos-payments.svg"
                                                        alt="Logo tarjetas debito y credito"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="checkPaymentMethods('PAGANDO_ACCOUNT')">
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <ReadOnlyInfo
                                                        :title="$t('paymentLinks.pagandoAccount')"
                                                        :text="$t('paymentLinks.pagandoAccountSubtitle')"
                                                    >
                                                    </ReadOnlyInfo>
                                                </div>
                                                <div class="col-12 col-md-4 text-md-right text-center p-t-15">
                                                    <img
                                                        height="27"
                                                        width="128"
                                                        class="img-fluid"
                                                        src="@/assets/images/Logos/logo-color.svg"
                                                        alt="Logo pagando"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="checkPaymentMethods('SPEI')">
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <ReadOnlyInfo
                                                        :title="$t('paymentLinks.spei')"
                                                        :text="$t('paymentLinks.speiSubtitle')"
                                                    ></ReadOnlyInfo>
                                                </div>
                                                <div class="col-12 col-md-4 text-md-right text-center p-t-15">
                                                    <img
                                                        height="20"
                                                        width="65"
                                                        class="img-fluid"
                                                        src="@/assets/images/Logos/logo-spei.svg"
                                                        alt="Logo SPEI"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CollapseInfo>
                        </div>
                        <div class="col-12">
                            <CollapseInfo
                            clazz="form-collapsible"
                            :checkboxRight="false"
                            collapseId="payments"
                            permissionName="Pagos"
                            >
                                <div class="p-t-30">
                                    <div class="row">
                                        <div class="col-12" v-if="payments.length === 0">
                                            <EmptyState
                                                size="sm"
                                                :imgUrl="EmptystateImage"
                                                emptyStateDefault
                                                :title="$t('paymentLinks.notFoundPay')"
                                                :text="$t('paymentLinks.shareToPay')"
                                            ></EmptyState>
                                        </div>
                                        <div class="col-12 p-0" v-else>
                                            <div class="basic-table menu-overflow-fix">
                                                <b-table-simple
                                                    responsive
                                                    selectable
                                                    selected-variant="active"
                                                    table-variant="xl"
                                                >
                                                    <b-thead>
                                                        <b-tr class="">
                                                            <b-th class="">
                                                                {{$t('paymentLinks.fields.folio')}}
                                                            </b-th>
                                                            <b-th class="">
                                                                {{$t('paymentLinks.fields.date')}}
                                                            </b-th>
                                                            <b-th class="">
                                                                {{$t('paymentLinks.fields.emailClient')}}
                                                            </b-th>
                                                            <b-th class="text-right">
                                                                {{$t('paymentLinks.fields.import')}}
                                                            </b-th>
                                                            <b-th class="">
                                                                {{$t('paymentLinks.fields.card')}}
                                                            </b-th>
                                                        </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                        <b-tr class="" v-for="(item, index) in payments" :key="index">
                                                            <b-td>
                                                                <strong>{{item.folio}}</strong>
                                                            </b-td>
                                                            <b-td>{{getDate(item.date)}}</b-td>
                                                            <b-td>{{item.email}}</b-td>
                                                            <b-td class="text-right f-bold c-accent_2">{{item.amount | currency}}</b-td>
                                                            <b-td class="d-flex">
                                                                <img
                                                                    height="20"
                                                                    width="29"
                                                                    class="img-fluid m-r-5"
                                                                    :src="getLogo(item.cardType)"
                                                                    alt="Logo tarjeta"
                                                                />
                                                                <span class="text-right c-plain_text whitespace-nowrap">•••• {{item.last4Digits}}</span>
                                                            </b-td>
                                                        </b-tr>
                                                    </b-tbody>
                                                </b-table-simple>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CollapseInfo>
                        </div>
                        <div class="col-12 m-t-40 vertical-center justify-content-end">
                            <button type="button" @click="goToList()" v-if="$can('do', 'PAYMENT_LINK_READ')"
                                    class="button xs button-ghost button-plain_text">
                                    {{$t('paymentLinks.goToList')}}
                            </button>
                            <button type="button" @click="goToNew()" v-if="$can('do', 'PAYMENT_LINK_CREATE')"
                                    class="button xs button-filled button-accent1 m-l-10">
                                    {{$t('paymentLinks.createNewLink')}}
                            </button>
                        </div>
                    </div>
                </CardSimple>
            </div>
            <ModalDefault
                hideCancelButton
                modalId="modal-comparte"
                originalFooter
                :title="$t('paymentLinks.shareTitle')"
                :subtitle="$t('paymentLinks.shareSubtitle')"
                :buttonText="$t('paymentLinks.shareButton')"
                @clickAccepted="shareLink"
            >
                <b-tabs
                    class="simple-tabs group-tabs"
                    content-class="simple-tabs-body p-t-20"
                >
                    <b-tab
                        title="SMS"
                        active
                    >
                        <BasicInput
                            clazz="noLabel"
                            class="search-type"
                            inputType="text"
                            v-model="sms"
                            inputPlaceholder="Buscar...">
                            <button type="button" class="button xs button-filled button-accent1" :disabled="sms === null" @click="addSMS()">
                                {{$t('paymentLinks.add')}}
                            </button>
                        </BasicInput>
                        <IconList v-for="(item, index) in smsArray" :key="index" :text="getPhoneFormat(item)" icon="banana-phone-call-normal" @delete="deleteSMS(index)"></IconList>
                    </b-tab>
                    <b-tab title="Email">
                        <BasicInput
                            clazz="noLabel"
                            class="search-type"
                            inputType="text"
                            v-model="email"
                            inputPlaceholder="Buscar...">
                            <button type="button" class="button xs button-filled button-accent1" :disabled="email === null" @click="addEmail()">
                                {{$t('paymentLinks.add')}}
                            </button>
                        </BasicInput>
                        <IconList v-for="(item, index) in emailArray" :key="index" :text="item" icon="banana-message" @delete="deleteEmail(index)"></IconList>

                    </b-tab>
                </b-tabs>
            </ModalDefault>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import EmptyState from "@/components/EmptyState.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import CardSimple from '@/components/CardSimple.vue'
    import ModalDefault from '@/components/ModalDefault.vue'
    import IconList from '@/components/IconList.vue'
    import CollapseInfo from '@/components/CollapseInfo.vue'
    import ReadOnlyInfo from '@/components/ReadOnlyInfo.vue'
    import EmptystateImage from '@/assets/images/Emptystates/emptystate-payment-person.svg';
    import LogoImage from '@/assets/images/Logos/pagandochecklogo-fullcolor.svg';
    import ToastSnotify from '@/mixins/toastSnotify.mixin'
    import PaymentLinksApi from "@/api/paymentLinks/paymentLinks.api";
    import utils from '@/common/utils';
    import moment from 'moment';
    import images from '@/common/images';
    import LoadingBar from '@/components/LoadingBar.vue';
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {mapGetters} from 'vuex';
    import VisaLogo from '@/assets/images/Logos/Cards/visa.svg';
    import AmExLogo from '@/assets/images/Logos/Cards/american-express.svg';
    import MCLogo from '@/assets/images/Logos/Cards/mastercard.svg';
    import CarnetLogo from '@/assets/images/Logos/Cards/carnet.svg';
    import GenericCardLogo from '@/assets/images/Logos/Cards/generic.svg';

    export default {
        name: "PaymentLinkDetail",
        components: {
            FloatingTitleLayout,
            EmptyState,
            BasicInput,
            CardSimple,
            ModalDefault,
            IconList,
            CollapseInfo,
            ReadOnlyInfo,
            LoadingBar
        },
        mixins: [
            ToastSnotify
        ],
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
        },
        data() {
            return {
                EmptystateImage: EmptystateImage,
                LogoImage: LogoImage,
                id: null,
                concept: null,
                amount: null,
                link: null,
                valuePaymentType: null,
                expDate: {
                    startDate: null,
                    endDate: null
                },
                imageToUpload: null,
                valuePromo: [],
                paymentMethods: [],
                smsArray: [],
                emailArray: [],
                sms: null,
                email: null,
                status: null,
                active: false,
                loading: false,
                payments: []
            };
        },
        methods: {
            getPromoTitle() {
                if(this.valuePaymentType === 'UNIQUE') {
                    return this.$i18n.t('paymentLinks.promos.unique.title');
                }

                return this.$i18n.t('paymentLinks.promos.multiple.title');
            },
            getPhoneFormat(phone) {
                return utils.formatPhone(phone);
            },
            getLogo(cardType) {
                const type = cardType.toUpperCase();

                switch(type) {
                    case 'VISA':
                        return VisaLogo;
                    case 'AMEX':
                        return AmExLogo;
                    case 'MASTERCARD':
                        return MCLogo;
                    case 'CARNET':
                        return CarnetLogo;
                    default:
                        return GenericCardLogo;
                }
            },
            async activeDeactiveLink() {
                const response = await PaymentLinksApi.activeDeactive({id: this.id});

                if(response.data.error) {
                    if(this.active) {
                        this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.deactive'));
                    } else {
                        this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.active'));

                    }
                } else {
                    if(this.active) {
                        this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.deactive'));
                    } else {
                        this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.active'));

                    }
                    await this.getLink(this.id);
                }
            },
            getImageUrl() {
                let url = this.LogoImage;
                if(this.imageToUpload) {
                    url = images.getImageUrl(this.imageToUpload);
                }

                return url;
            },
            checkPaymentMethods(type) {
                return this.paymentMethods.includes(type);
            },
            getDate(date) {
                return moment(date).format('DD/MM/YYYY HH:mm a');
            },
            async shareLink() {
                const response = await PaymentLinksApi.share({id: this.id, smsArray: this.smsArray, emailArray: this.emailArray});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.share'));
                } else {
                    this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.share'));
                    this.smsArray = [];
                    this.emailArray = [];
                }
                this.$bvModal.hide('modal-comparte');
            },
            addSMS() {
                if(utils.verifyValidPhone(this.sms)) {
                    this.smsArray.push(this.sms);
                } else if(utils.verifyValidPhoneWithoutLada(this.sms)) {
                    this.smsArray.push(`+52${this.sms}`);
                } else {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.phone'));
                }
                this.sms = null;
            },
            addEmail() {
                if(utils.verifyValidEmail(this.email)) {
                    this.emailArray.push(this.email);
                } else {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.email'));
                }

                this.email = null;
            },
            deleteSMS(index) {
                this.smsArray.splice(index, 1);
            },
            deleteEmail(index) {
                this.emailArray.splice(index, 1);
            },
            async copy() {
                try {
                    await navigator.clipboard.writeText(this.link);
                    this.displayNotificationSuccess(this.$i18n.t('paymentLinks.successMessages.copy'));
                } catch($e) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.copy'));
                }
            },
            goToEdit() {
                this.$router.push({name: 'PaymentLinkNew', query: { id: this.id }});
            },
            goToList() {
                this.$router.push({name: 'PaymentLinkList'});
            },
            goToNew() {
                this.$router.push({name: 'PaymentLinkNew'});
            },
            async getLink(id) {
                this.loading = true;
                const response = await PaymentLinksApi.byId({id: id});

                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.get'));
                    this.goToList();
                } else {
                    this.concept = response.data.object.concept;
                    this.link = response.data.object.shortLink;
                    this.amount = response.data.object.amount;
                    this.valuePaymentType = response.data.object.type;
                    this.paymentMethods = response.data.object.paymentMethod;
                    this.status = response.data.object.status;
                    this.active = response.data.object.active;

                    if(response.data.object.image) {
                        this.imageToUpload = response.data.object.image;
                    }

                    if(response.data.object.expDate) {
                        const startDate = new Date(response.data.object.expDate.startDate);
                        const endDate = new Date(response.data.object.expDate.endDate);
                        this.expDate.startDate = startDate;
                        this.expDate.endDate = endDate;
                    }

                    const promos = response.data.object.promosAvailable;
                    this.getPromos(promos);

                }

                this.loading = false;
            },
            async getPayments() {
                const response = await PaymentLinksApi.payments({link: this.id, organization: this.currentOrganization._id});
                if(response.data.error) {
                    this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.payments'));
                    this.goToList();
                } else {
                    this.payments = response.data.object;
                }
            },
            getPromos(promos) {
                if(promos) {
                    if(promos.msi.length > 0) {

                        for(const pay of promos.msi) {
                                const obj = {
                                name: `${pay} ${this.$i18n.t('paymentLinks.msi')}`,
                                value: {
                                    time: pay,
                                    type: 'MSI'
                                }
                            };

                            this.valuePromo.push(obj);
                        }

                    }

                    if(promos.fixedPayments.length > 0) {

                        for(const pay of promos.fixedPayments) {
                                const obj = {
                                name: `${pay} ${this.$i18n.t('paymentLinks.fixPay')}`,
                                value: {
                                    time: pay,
                                    type: 'FIX_PAY'
                                }
                            };

                            this.valuePromo.push(obj);
                        }

                    }
                }

            }
        },
        async created() {
            this.id = this.$route.params.id;
            this.loading = true;
            if(this.id) {
                await this.getLink(this.id);
                await this.getPayments();
            } else {
                this.displayNotificationError(this.$i18n.t('paymentLinks.errorMessages.get'));
                this.goToList();
            }
            this.loading = false;
        }
    };
</script>
