<template>
    <div class="upload-file-container">
        <div
            id="dct-uploadFile"
            class="upload-file-component"
            :class="{'loading' : loadingFile, 'loaded' : loadedFile, clazz}"
        >
            <span class="square">
                <i
                    v-if="!loadingFile && !refreshing"
                    class="defaultIcon banana"
                    :class="icon"
                    aria-hidden="true"
                ></i>
                <LoadingSimple
                    v-if="(loadingFile || loading) && !refreshing"
                    clazz="small-load"
                ></LoadingSimple>
                <i
                    class="iconAdd banana banana-symbol-plus"
                    aria-hidden="true"
                ></i>
                <input
                    :id="id"
                    type="file"
                    class="input-file"
                    name=""
                    @input="$emit('input', $event.target.files[0])"
                    :accept="accept"
                    :disabled="disabled"
                />
            </span>
            <span class="info">
                <label
                    class="title"
                > {{title}} </label>
                <small class="type"> {{text}} </small>
                <p
                    v-if="!loadedFile"
                    class="note"
                > {{note}} </p>
                <a
                    v-if="loadedFile && !showDownload"
                    @click="openModal"
                    tabindex=""
                >
                    <Badge
                            v-if="!showDownload"
                        type="badge-accent_2"
                        :text="$t('general.see-file')"
                    ></Badge>
                </a>
                <a
                    v-if="showDownload"
                    @click="download()"
                    tabindex=""
                >
                    <Badge
                        v-if="showDownload"
                        type="badge-accent_2"
                        :text="$t('general.download-file')"
                    ></Badge>
                </a>
            </span>
            <span>
                <button
                    v-if="loadedFile && !hideDelete"
                    type="button"
                    class="button-circle xs button-ghost button-placeholder"
                    @click="$emit('delete')"
                ><i aria-hidden="true" class="banana banana-delete"></i></button>
                <div
                    v-if="!loadedFile"
                    class="tooltipHelp"
                >
                    <i
                        aria-hidden="true"
                        :id="tooltipId"
                        class="banana banana-question-circle1"
                    ></i>
                    <b-tooltip
                        :target="tooltipId"
                        triggers="hover"
                        placement="top"
                        custom-class="white-tooltip"
                    >
                        <slot name="tooltipContent"></slot>
                    </b-tooltip>
                </div>
            </span>
        </div>
        <ModalDefault
            :modalId="modalId"
            :customRef="modalId"
            :ref="`${modalId}_here`"
            size="lg"
            bodyClass="p-0"
            title="Visor de archivos">

            <div class="bgm-gray p-r-15 border-top-10 border-bottom-10">
                <LoadingSimple
                    v-if="loading"
                    clazz="small-load"
                ></LoadingSimple>

                <div class="col-12 y-scroll o-auto" style="min-height: 540px;">
                    <div>
                        <embed
                            class="img-fluid w-100"
                            :src="actualFileUrl"
                            :type="actualMimetype"
                            style="min-height: 540px;"
                        >
                    </div>
                </div>
            </div>

            <template v-slot:customFooter>
                <div class="d-flex w-100 justify-space-between align-item-c">
                    <div class="d-flex align-items-c">
                    </div>

                    <div class="vertical-center">
                        <button
                            type="button"
                            class="button xs button-ghost button-plain_text"
                            @click="$bvModal.hide(modalId)"
                        >{{$t('general.close')}}</button>
                        <button
                            type="button"
                            class="button xs button-filled button-accent1 m-l-10"
                            @click="download"
                        >{{$t('general.download')}}</button>
                    </div>
                </div>
            </template>
        </ModalDefault>
    </div>
</template>

<script>
import LoadingSimple from "@/components/LoadingSimple.vue";
import Badge from "@/components/Badge.vue";
import ModalDefault from "@/components/ModalDefault.vue";
import ToastSnotify from '@/mixins/toastSnotify.mixin';

export default {
    name: 'UploadFile',
    mixins: [ToastSnotify],
    props: {
        refreshing: { type: Boolean, default: false},
        id: String,
        clazz: String,
        loadingFile: { type: Boolean, default: false },
        loadedFile: { type: Boolean, default: false },
        icon: String,
        title: String,
        text: String,
        note: String,
        tooltipId: String,
        hideDelete: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        downloadUrl: {
            type: [String, Promise, Function]
        },
        acceptedFormats: {
            type: [String, Array],
        },
        mimetype: String,
        showDownload: { type: Boolean, default: false}
    },
    components: {
        LoadingSimple,
        Badge,
        ModalDefault
    },
    computed: {
        modalId() {
            return `modal_${this.id}`;
        },
        accept() {
            if (this.acceptedFormats) {
                if (typeof this.acceptedFormats === 'string') {
                    return this.acceptedFormats;
                }
                let accept = '';
                this.acceptedFormats.forEach(f => {
                    const hasDot = f.indexOf('.') === 0;
                    accept += `${hasDot ? '' : '.'}${f},`;
                });
                return accept;
            }
            return null;
        }
    },
    // async beforeMount() {
    //     if (typeof this.downloadUrl !== 'string') {
    //         this.actualFileUrl = await this.downloadUrl;
    //     } else {
    //         this.actualFileUrl = this.downloadUrl;
    //     }
    //     this.actualMimetype = this.mimetype;
    // },
    data () {
        return {
            actualFileUrl: '',
            actualMimetype: '',
            // Progress Bar
            valueProgressBar: 80,
            maxProgressBar: 100,
            loading: false,
        }
    },
    methods: {
        updateActualFileUrl(url, mimetype) {
            let promise;
            if ({}.toString.call(url) === '[object Function]') {
                promise = Promise.resolve(url());
            } else {
                promise = Promise.resolve(url);
            }
            this.actualMimetype = mimetype;
            return promise
                .then((actualFileUrl) => fetch(actualFileUrl, {cache: 'no-cache'}))
                .then((res) => res.blob())
                .then((blob) => {
                    this.actualFileUrl = window.URL.createObjectURL(blob);
                })
                .catch((error) => this.toastErrorDefault(error));
        },
        download() {
            fetch(this.actualFileUrl, {cache: 'no-cache'}).then(res => {
                return res.blob();
            }).then(blob => {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = 'download';
                a.click();
            }).catch((error) => {
                this.toastErrorDefault(error);
            });
        },
        async openModal() {
            this.loading = true;
            if (!this.actualFileUrl) {
                await this.updateActualFileUrl(this.downloadUrl, this.mimetype);
            }
            this.$refs[`${this.modalId}_here`].showModal();
            this.loading = false;
        }
    }
}
</script>

<style lang="scss">
.upload-file-container {
    width: 100%;
}

.upload-file-component {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .square {
        position: relative;
        width: 70px;
        min-width: 70px;
        height: 70px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
        transition: all 0.5s ease-in-out;

        &:hover {
            .defaultIcon {
                opacity: 0;
            }

            .iconAdd {
                opacity: 1;
                animation: spin 1s ease-in-out;
            }
        }
    }

    .info {
        width: 100%;
        padding-right: 40px;
    }

    .defaultIcon,
    .iconAdd {
        font-size: 40px;
        max-width: fit-content;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: fit-content;
        transition: 0.3s ease-in-out;
    }

    .loading-info {
        > .loading {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .small-load {
            #load {
                width: 40px;
            }
        }
    }

    .defaultIcon {
        opacity: 1;
    }

    .iconAdd {
        opacity: 0;
    }

    .title {
        font-size: 17px;
        font-weight: 600;
        line-height: 1.18;
        display: block;
        margin-bottom: 4px;
    }

    .type {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.14;
        display: block;
        margin-bottom: 4px;
    }

    .note {
        font-size: 11px;
        font-weight: normal;
        line-height: 1.64;
        display: block;
        margin-bottom: 0;
    }

    .tooltipHelp {
        font-size: 14px;
        position: absolute;
        top: 0;
        right: 0;
    }

    &.loading {
        .square {
            cursor: not-allowed;

            .iconAdd {
                display: none !important;
                opacity: 0 !important;
            }

            .input-file {
                z-index: -1;
            }
        }
    }

    &.loaded {
        .square {
            cursor: not-allowed;

            .iconAdd {
                display: none !important;
                opacity: 0 !important;
            }

            .defaultIcon {
                opacity: 1;
            }

            .input-file {
                z-index: -1;
            }
        }
    }
}
</style>
