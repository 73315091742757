<template>
    <div>
        <SignUpOrganizationBody stepSix>
            <template v-slot:formSectionBody>
                <GenericTitle id="dct-orgStepOne" clazz="p-t-20" :title="$t('requested-documents.title')"
                              :titleStrong="$t('requested-documents.title-strong')"
                              :subtitle="$t('requested-documents.subtitle')"></GenericTitle>
                <StepValidation :text="getErrorNextStepMessage" icon="banana-cross-normal" status="error"
                                v-if="getErrorNextStep"></StepValidation>
                <div>
                    <div class="text-divider"><label>
                        {{$t('requested-documents.label-divider', {
                        documentsLoaded,
                        numOfDocuments: getFileDocuments.length
                    })}} </label>
                    </div>

                    <UploadFile v-for="(file, fileIndex) in getFileDocuments" :key="fileIndex" :ref="`fileDocument-${file.key}`"
                                :icon="getIcon(file.fileDocumentType)"
                                :title="file.title"
                                :text="file.description"
                                :note="file.fileSpecs"
                                :tooltipId="`tooltip${fileIndex}`"
                                @loadFile="updateFile"
                                :fileKey="file.key"
                                @deleteFile="deleteFile"
                                :file-by-key="getFileByKey(file.key)"
                    >
                        <template v-slot:tooltipContent>
                            <div>
                                <p class="f-13 body-font-regular c-info_text m-b-0">
                                    {{file.toolTipText ? file.toolTipText : file.description}}</p>
                            </div>
                        </template>

                    </UploadFile>
                </div>
            </template>
        </SignUpOrganizationBody>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {EventBus} from '@/main';

    import UploadFile from "@/components/UploadFile.vue";
    import SignUpOrganizationBody from "@/components/SignUpOrganizationBody.vue";
    import GenericTitle from "@/components/GenericTitle.vue";
    import StepValidation from '@/components/StepValidation.vue'

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    const storeModule = "signUpOrganization";
    const uploadBoxRef= 'upload-file-input';

    export default {
        name: 'StepRequestedDocuments',
        data() {
            return {
                documentsLoaded: 0
            }
        },
        components: {
            UploadFile,
            SignUpOrganizationBody,
            GenericTitle,
            StepValidation
        },
        computed: {
            ...mapGetters(storeModule, [
                "getFileDocuments",
                "getRequestedFiles",
                'getErrorNextStep',
                'getErrorNextStepMessage',
                'getFileByKey'
            ]),
        },
        methods: {
            updateFile: debounce(async function (file, fileKey) {
                this.fileInProgress(fileKey, true);
                if (file) {
                    const tempFile = await this.readFile(file);
                    this.fileInProgress(fileKey, false);
                    this.$store.commit(`${storeModule}/UPDATE_FILES`, {tempFile, fileKey});
                    this.fileIsLoaded(fileKey, true);
                }
            }, 50, false),
            readFile(file) {
                return new Promise((resolve) => {
                    const fr = new FileReader();
                    fr.onload = (e) => {
                        const actualUserImg = {};
                        const dataUrl = e.target.result;
                        actualUserImg.name = file.name;
                        actualUserImg.type = file.type;
                        actualUserImg.size = file.size;
                        actualUserImg.data = dataUrl;
                        resolve(actualUserImg)
                    };
                    fr.readAsDataURL(file);
                });
            },
            fileInProgress(fileKey, value) {
                EventBus.$emit(`loadingFile${fileKey}`, value);
            },
            fileIsLoaded(fileKey, value) {
                EventBus.$emit(`loadedFile${fileKey}`, value);
                this.validateStep();
            },
            deleteFile(fileKey) {
                this.$refs[`fileDocument-${fileKey}`][0].$refs[uploadBoxRef].files = null;
                this.$refs[`fileDocument-${fileKey}`][0].$refs[uploadBoxRef].value = null;
                this.$store.commit(`${storeModule}/UPDATE_FILES`, {tempFile: null, fileKey});
                this.fileIsLoaded(fileKey, false);
            },
            getIcon(fileDocumentType) {
                switch (fileDocumentType) {
                    case "IDENTIFICATION":
                        return "banana-profile";
                    case "LEGAL":
                    case "BANKING":
                        return "banana-credit-card";
                    case "RESIDENCY":
                        return "banana-home";
                    default:
                        return "banana-profile";
                }
            },
            validateStep() {
                let stepSixValid = true;
                let documentsLoaded = 0;
                this.getRequestedFiles.every(function (element) {
                    stepSixValid = element.isLoaded || (element.file && element._id ) ? true : false;
                    if (stepSixValid) {
                        documentsLoaded++
                    }
                    return stepSixValid;

                });
                this.documentsLoaded = documentsLoaded;
                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_SIX`, stepSixValid);
            },
            initializeStep() {
                let stepSixValid = false;
                let documentsLoaded = 0;
                this.getRequestedFiles.every(function (element) {
                    stepSixValid = element.isLoaded || (element.file && element._id ) ? true : false;
                    if (stepSixValid) {
                        documentsLoaded++;
                        EventBus.$emit(`loadedFile${element.key}`, stepSixValid);
                    }
                    return stepSixValid;

                });
                this.documentsLoaded = documentsLoaded;
                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_SIX`, stepSixValid);
            }
        },
        mounted() {
            const _this = this;
            EventBus.$on("INIT_REQUESTED_FILES_STEP", function () {
                _this.initializeStep();
            });
        }
    }
</script>
