<template>
    <div>
        <SignUpOrganizationBody stepSeven>
            <template v-slot:formSectionBody>
                <GenericTitle
                        id="dct-orgStepOne" clazz="p-t-20"
                        :titleStrong="$t('step-location.title')"
                        :subtitle="$t('step-location.subtitle')"
                ></GenericTitle>
                <StepValidation :text="getErrorNextStepMessage" icon="banana-cross-normal" status="error"
                                v-if="getErrorNextStep"></StepValidation>

                <GoogleMap
                        :id="id"
                        ref="GoogleMap"
                        clazz="m-b-30"
                        height="height:350px"
                        width="width:540px"
                        v-model="markers"
                        locationOption
                        @input="updateMarkers"
                        @markerLocated="markerLocated"
                        :markerClick="showMarker"
                        :markerAdded="showMarkerAndEdit"
                        :markerDragged="showMarkerAndEdit"
                        searchBar
                        stepLocation
                ></GoogleMap>

                <div class="col-12">
                    <BasicTextarea
                            idForm="dct-emptyOrgDesc"
                            textareaId="txa-orgWhatDo"
                            inputType="text"
                            labelFor="description"
                            inputId="description"
                            :label="$t('step-location.additional-notes')"
                            :inputValue="directions"
                            v-on:basicInput="updateDirections"
                            :textareaPlaceholder="$t('step-location.placeholder')"
                            help
                            idHelpTooltip="descriptionHelp"
                            smallId="warn-emptyOrgDesc"
                            helpIcon="banana banana-question-circle1">
                        <template v-slot:tooltipHelp>
                            <b-tooltip target="descriptionHelp" placement="top" custom-class="white-tooltip">
                                <div>
                                    <p class="m-b-0">
                                        {{$t('step-location.tooltip')}}
                                    </p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicTextarea>
                </div>

            </template>
        </SignUpOrganizationBody>
    </div>
</template>

<script>
    import SignUpOrganizationBody from "@/components/SignUpOrganizationBody.vue";
    import GenericTitle from "@/components/GenericTitle.vue";
    import StepValidation from '@/components/StepValidation.vue';
    import GoogleMap from '@/components/GoogleMap.vue';
    import BasicTextarea from '@/components/BasicTextarea.vue';
    import utils from '@/common/utils';
    import {mapGetters} from "vuex";

    const storeModule = "signUpOrganization";

    const defaultMarker = () => {
        return {
            lat: 0,
            lng: 0,
            address: '',
            name: '',
        }
    }
    export default {
        name: 'StepLocation',
        data(){
            return{
                id: "map-location",
                markers: [],
                marker: {},
                editingMarkerActive: false,
                editingMarkerBackUp: defaultMarker(),
                editingMarkerIndex: null,
                editingMarker: defaultMarker(),
                hidebranchFormToForceVueToWork: false,
                directions: ""
            }
        },
        components: {
            SignUpOrganizationBody,
            GenericTitle,
            StepValidation,
            GoogleMap,
            BasicTextarea
        },
        computed: {
            ...mapGetters(storeModule, [
                "getBranch",
                "getErrorNextStep",
                "getErrorNextStepMessage",
            ]),
        },
        methods: {
            organizationBranchToGoogleMapMarker(branch) {
                return { ...branch, ...branch.location}; // Location should be the last property to be assigned to prevent overriding it
            },
            googleMapMarkerToOrganizationBranch(marker) {
                return { ...marker, location: {asArray: [marker.lng, marker.lat],...marker}}; // Location should be the last property to be assigned to prevent overriding it
            },
            updateMarkers(markers){
                this.marker = this.googleMapMarkerToOrganizationBranch(markers[0]);
                this.$store.commit(`${storeModule}/UPDATE_BRANCH`, this.marker);
                this.validateStep();
            },

            markerLocated({marker, index}){
                if (!this.editingMarkerActive) {
                    this.showMarker(marker, index);
                }
            },
            showMarker(marker, index){
                this.editingMarker = marker;
                this.$set(this, 'editingMarker', marker);
                this.editingMarkerIndex = index;
                this.editingMarkerBackUp = JSON.parse(JSON.stringify(marker));
                this.$refs.GoogleMap.toggleLocationOption(true);
            },
            updateDirections(input){
                this.directions = input;
                utils.debounceFixed(()=>{
                    this.$store.commit(`${storeModule}/UPDATE_BRANCH_DIRECTIONS`, input);
                })
            },
            showMarkerAndEdit(marker){
                this.marker = this.googleMapMarkerToOrganizationBranch(marker);
                this.$store.commit(`${storeModule}/UPDATE_BRANCH`, this.marker);
                this.validateStep();
            },
            validateStep(){
                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_SEVEN`, true);
            }
        },
        mounted(){
            setTimeout(()=>{
                if(this.getBranch){
                    this.directions = this.getBranch.directions;
                    this.$refs.GoogleMap.updateMarker(this.organizationBranchToGoogleMapMarker(this.getBranch), 0);
                }
            },3000);
        }
    }
</script>
