<template>
    <div>
        <div class="add-user-container" :class="clazz">
          <div>
            <button id="btn-addUser" type="button" @click="$bvModal.show('modal-addUser')">
              <i aria-hidden="true" class="banana" :class="icon"></i>
            </button>
            <span>
                <label>{{title}}</label>
                <p>{{subtitle}}</p>
            </span>
          </div>
          <ul>
           <slot></slot>
          </ul>
        </div>

        <ModalDefault modalId="modal-addUser" customRef="modal-addUser" size="ms" :originalFooter="bVariableModalAddUser" icon="banana-user-add-user"
                      :title="addUserModalTitle"
                      :subtitle="addUserModalSubTitle"
                      :buttonText="$t('signup-organization.step-summary.add-collaborator-modal.button')"
                      actionModalDef="btn-saveCollaborators"
                      closeModalDef="btn-closeAddCollaborators"
                      v-on:clickAccepted="onClickAccepted()">
            <div v-if="bVariableModalAddUser">
                <BasicInput
                        labelFor="email"
                        inputId="email"
                        extraInputId="btn-extraInput"
                        label="Correo electrónico"
                        :inputPlaceholder="$t('signup-organization.step-summary.add-collaborator-modal.input-placeholder')"
                        inputClazz="icon-right"
                        iconInput="banana-symbol-plus"
                        v-model="email"
                        v-on:onIconClick="onIconClick"
                        ></BasicInput>
                <label class="f-14 c-title body-font-bold f-bold d-block m-b-5" v-if="usersList.length > 0">
                    {{$t('signup-organization.step-summary.add-collaborator-modal.persons-invited')}}</label>
                <ul class="m-0 p-0 list-style-none" v-if="collaborators.length > 0">
                    <li class="f-14 c-plain_text body-font-regular vertical-center m-b-15 hover-error" v-for="(user, userIndex) in this.collaborators" v-bind:key="user"> {{user}}
                        <i aria-hidden="true" class="banana banana-delete f-18 c-disable m-l-20" v-on:click="deleteEmailFromArray(userIndex)"></i> </li>
                </ul>
            </div>
            <div v-if="!bVariableModalAddUser">
                <label class="f-14 c-title body-font-bold f-bold d-block m-b-5">{{$t('signup-organization.step-summary.add-collaborator-modal.persons-invited-success')}}</label>
                <ul class="m-0 p-0 list-style-none">
                    <li class="f-14 c-success body-font-regular vertical-center m-b-5" v-for="user in usersList" v-bind:key="user"> {{user}} </li>
                </ul>
            </div>
            <template v-slot:customFooter v-if="!bVariableModalAddUser">
                <button id="btn-successCollab" class="button xs button-filled button-accent1 m-0-0-0-auto" @click="hideModal()" type="button">
                    {{$t('signup-organization.step-summary.add-collaborator-modal.button-success')}} </button>
            </template>
        </ModalDefault>

    </div>
</template>

<script>
    import ModalDefault from '@/components/ModalDefault.vue'
    import BasicInput from '@/components/BasicInput.vue'
    import ToastSnotify from '@/mixins/toastSnotify.mixin'

    export default {
        name: 'AddUser',
        components: {
            ModalDefault,
            BasicInput
        },
        props: {
            clazz: String,
            icon: {type: String, default: 'banana-user-add-user'},
            title: String,
            subtitle: String,
            usersList: Array,
            storeModule: {
                type: String,
                required: true
            },

        },
        data(){
            return{
                bVariableModalAddUser: true,
                email: '',
                collaborators: []
            }
        },
        mixins: [
            ToastSnotify
        ],
        mounted(){
            this.collaborators = this.usersList;
        },
        computed: {
            addUserModalTitle: function(){
                if(this.bVariableModalAddUser) {
                    return this.$t('signup-organization.step-summary.add-collaborator-modal.title')
                }
                return this.$t('signup-organization.step-summary.add-collaborator-modal.title-success')
            },
            addUserModalSubTitle: function(){
                if(this.bVariableModalAddUser) {
                    return this.$t('signup-organization.step-summary.add-collaborator-modal.text')
                }
                return this.$t('signup-organization.step-summary.add-collaborator-modal.text-success')
            }
        },
        methods: {
            onClickAccepted(){
                if(this.collaborators.length > 0){
                    this.$emit('clickAccepted', this.collaborators);
                    this.bVariableModalAddUser = false;
                }else{
                    // Insert message here
                    this.displayNotificationWarning("Tiene que ingresar por lo menos un email.")
                }
            },
            hideModal(){
                this.bVariableModalAddUser = true;
                this.$bvModal.hide('modal-addUser');
            },
            reloadUserList(collaborators){
                this.collaborators = collaborators;
            },
            onIconClick(){
                if(this.validateEmail(this.email)){
                    // Here we check if the written email is in the current array
                    if(!this.isEmailInArray(this.email)){
                        // If the email isn´t in the array, we add it calling the mutation to add emails to the collaborators array
                        // this.$store.commit(`${this.storeModule}/ADD_COLLABORATOR`, this.email);
                        this.collaborators.push(this.email);
                        this.email = ''
                    }else{
                        // Here we send a message in case that the email is in the actual array
                        this.displayNotificationWarning("El email que estas ingresando ya se encuentra agregado.")
                    }
                } else {
                    // Here we send a message in case that the email is not a valid email
                    this.displayNotificationWarning("El email ingresado no es un email válido.")
                }
            },
            validateEmail(email) {
                // eslint-disable-next-line max-len,no-useless-escape
                var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return emailRegex.test(String(email).toLowerCase());
            },
            isEmailInArray(email) {
                return this.usersList.includes(email)
            },
            deleteEmailFromArray(emailIndex){
                this.collaborators.splice(emailIndex, 1);
                // this.$store.dispatch(`${this.storeModule}/deleteCollaborator`, emailIndex)
            }
        }
    }
</script>

<style lang="scss">
    .add-user-container {
        > div {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            > button {
                width: 40px;
                height: 40px;
                margin-right: 15px;
                border-radius: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.5s;

                i {
                    font-size: 25px;
                }

                &:hover {
                    animation: 1s pulse ease-in-out;
                }
            }

            label {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.43;
                display: block;
                margin: 0;
            }

            p {
                font-size: 13px;
                font-weight: normal;
                line-height: 1.38;
                display: block;
                margin: 0;
            }
        }

        > ul {
            margin: 0;
            padding: 0;

            li {
                font-size: 14px;
                font-weight: normal;
                line-height: 2.14;
                display: block;
                margin-bottom: 5px;
            }
        }
    }
</style>
