import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
const config = require('../config.js');

/**
 * Indica si el servicio de Sentry está activo. Esto depende del parámetro de configuración config.sentry.enabled.
 *
 * @returns {boolean}
 * @private
 */
const _isSentryEnabled = () => {
    return config && config.sentry && config.sentry.enabled;
};

/**
 * Inicializa sentry
 */
function init() {
    Sentry.init({
        dsn: config.default.sentry.dsn,
        integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})]
    });

    refreshCurrentUser();
}

// If no user specified, an attempt to read the data from localstorage will be made.
function refreshCurrentUser(userData) {
    if(!userData)
    {
        let loggedUser = localStorage.getItem('currentUser');
        if(loggedUser) {
            loggedUser = JSON.parse(loggedUser);
        }
        Sentry.setUser(loggedUser);
    } else {
        Sentry.setUser(userData);
    }
}

export default { init, refreshCurrentUser, _isSentryEnabled }
