<template>
  <div>
    <h1>Access Denied 403</h1>
  </div>
</template>

<script>
    export default {
        data: function () {
            return {

            }
        }
    }
</script>
