<template>
    <div>
        <SearchBar
                :buttonText="$t('chargebacks.table.button-text')"
                :canCreate="false"
                @searchTextChange="searchTextChange"
                :columValues="columnValues"
        ></SearchBar>
        <CardSimple
                hideTitle
                clazzTitle="w-100 m-0 p-0 d-none"
                v-if="loading || (!loading && total <= 0)"
        >
            <LoadingSimple
                    v-if="loading"
                    clazz="big-load m-t-35 m-b-35"
                    :label="$t('chargebacks.table.searching-chargeback')"
            ></LoadingSimple>

            <EmptyState
                    v-if="!loading && total <= 0"
                    :imgUrlHorizontal="ImagenEmptyStateDown"
                    emptyStateDefault
                    :title="$t('chargebacks.empty-state-title')"
                    :text="$t('chargebacks.empty-state-desc')"
            >
            </EmptyState>
        </CardSimple>
        <CardSimple clazz="w-100 p-0" hideTitle v-if="!loading && total > 0">
            <div
                    id="menuOverflowFix"
                    class="basic-table">
                <b-table-simple
                        responsive
                        selectable
                        :select-mode="selectMode"
                        selected-variant="active"
                        table-variant="xl">
                    <!-- Add classes 'b-table-row-selected table-active' to 'b-tbody > b-tr' when a row checkbox selected.
                        If MORE THAN ONE row CHECKBOX is selected, add to 'b-thead > b-tr' class 'header-options-full'. -->
                    <b-thead>
                        <b-tr class="">
                            <b-th v-for="(column, columnIndex) in columnValuesToShow" :key="columnIndex">
                                <div class="d-flex align-items-c">
                                    <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                    > {{$t(column.label)}} </span>
                                    <SortIcon
                                            @sortUp="setSortDocument(column.property, 'asc')"
                                            @sortDown="setSortDocument(column.property, 'desc')"
                                    ></SortIcon>
                                </div>
                                <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']">
                                    <BasicInput
                                            labelFor=""
                                            inputId=""
                                            :inputPlaceholder="$t('chargebacks.table.search-placeholder')"
                                            clazz="noLabel"
                                    ></BasicInput>
                                </div>
                            </b-th>
                            <b-th class="text-align-c w-5"> {{$t('chargebacks.table.actions')}} </b-th>
                            <b-th class="row-options">
                                <div class="vertical-center">
                                    <BasicCheckbox
                                            clazz="m-0 w-fit di-flex"
                                            text="2 elementos seleccionados"
                                            textClass="f-14 c-primary_1 principal-font-bold"
                                    ></BasicCheckbox>
                                    <div>
                                        <a
                                                class="opt-row"
                                                tabindex=""
                                        ><i aria-hidden="true" class="banana banana-delete"></i>
                                            {{$t('chargebacks.table.delete')}} </a>
                                        <a
                                                class="opt-row"
                                                tabindex=""
                                        > {{$t('chargebacks.table.cancel')}} </a>
                                    </div>
                                </div>
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="chargeback in chargebacksList" :key="chargeback._id">
                            <b-td v-show="columnValues[0].value">
                                <div class="vertical-center">
                                    <strong class="c-primary_1">
                                        {{formatNumber(4, 'number', chargeback.number)}} </strong>
                                </div>
                            </b-td>
                            <b-td v-show="columnValues[1].value">
                                {{formatDate(chargeback.transaction.date, "DD / MM / YYYY")}}
                            </b-td>
                            <b-td v-show="columnValues[2].value">
                                 <strong>{{chargabackTotal(chargeback) | money}}</strong>
                                 </b-td>
                            <b-td v-show="columnValues[3].value"> {{chargeback.transaction.clientFullName}} </b-td>
                            <b-td v-show="columnValues[4].value">
                                <Badge
                                        :type="getBadgeClass(chargeback.status)"
                                        :text="$t(`chargebacks.badge.${chargeback.status}`)"
                                ></Badge>
                            </b-td>
                            <b-td v-show="columnValues[5].value">
                                {{formatDate(chargeback.notificationDate, "DD / MM / YYYY")}}
                            </b-td>
                            <b-td v-show="columnValues[6].value" class="text-align-c">
                                <RemainingTime
                                        :evidence="chargeback.evidence"
                                        :limit-date="chargeback.uploadEvidenceLimitDate"
                                        :status="chargeback.status"
                                        :max-bar="getMaxBar(chargeback.uploadEvidenceLimitDate, chargeback.chargebackLogs)"
                                ></RemainingTime>
                            </b-td>
                            <b-td class="text-align-c w-5">
                                <b-dropdown
                                        @show="showDropdown"
                                        @hide="hideDropdown"
                                        id="dropdown-table"
                                        right
                                        class="dropdown-table"
                                >
                                    <template v-slot:button-content>
                                        <button class="button-circle xs button-ghost button-info_text">
                                            <i
                                                    aria-hidden="true"
                                                    class="banana banana-options-other-vertical"
                                            ></i>
                                        </button>
                                    </template>
                                    <b-dropdown-group header="Mas Acciones">
                                        <b-dropdown-item-button v-if="$can('do', 'CHARGEBACKS_CLIENT_READ')">
                                            <router-link
                                                    :to="`/client-admin/chargebacks/show/${chargeback._id}`"
                                                    tabindex=""
                                            ><i aria-hidden="true" class="banana banana-visibility-on"></i>
                                                {{$t('chargebacks.table.see-details')}}
                                            </router-link>
                                        </b-dropdown-item-button>
                                        <b-dropdown-item-button v-if="$can('do', 'CHARGEBACKS_CLIENT_UPLOAD_EVIDENCE')
                                        && new Date(chargeback.uploadEvidenceLimitDate).getTime() > Date.now() && chargeback.status === 'APPLIED'">
                                            <router-link
                                                    :to="`/client-admin/chargebacks/show/${chargeback._id}`"
                                                    tabindex=""
                                            ><i aria-hidden="true" class="banana banana-document-normal"></i>
                                                {{$t('chargebacks.table.upload')}}
                                            </router-link>
                                        </b-dropdown-item-button>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item-button v-if="$can('do', 'CHARGEBACKS_CLIENT_GRANT')
                                            && (chargeback.status === 'REVISION' || chargeback.status === 'APPLIED')"
                                                                @click="setChargebackToGrant(chargeback._id)">
                                            <router-link

                                                    to=""
                                                    tabindex=""
                                            ><i aria-hidden="true" class="banana banana-do-undo"></i>
                                                {{$t('chargebacks.table.give')}}
                                            </router-link>
                                        </b-dropdown-item-button>
                                    </b-dropdown-group>
                                </b-dropdown>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </CardSimple>

        <div class="pagination-custom">
            <span class="pagination-count"> {{paginationMessage}}</span>
            <b-pagination
                    v-if="!loading && total > 0"
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="total"
                    aria-controls="my-table"
                    next-text=""
                    prev-text=""
                    @change="pageChange"
                    hide-goto-end-buttons>
            </b-pagination>
        </div>

        <ModalAlert
                v-if="activeTable"
                modalId="modal-send-grant"
                buttonType="button-warning"
                title="Otorgar el contracargo"
                text="Si deseas otorgar el contracargo solo haz clic en 'Continuar'. Al hacer esto el contracargo aparecerá como PERDIDO en el listado de contracargos."
                buttonText="Continuar"
                buttonCancel="Regresar"
                @submit="grantChargeback()"
        ></ModalAlert>
    </div>
</template>

<script>
    import CardSimple from '@/components/CardSimple.vue';
    import SearchBar from '@/components/SearchBar.vue';
    import ModalAlert from '@/components/ModalAlert.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import SortIcon from '@/components/SortIcon.vue';
    import BasicCheckbox from '@/components/BasicCheckbox.vue';
    import RemainingTime from '@/components/RemainingTime.vue';
    import Badge from '@/components/Badge.vue';
    import LoadingSimple from '@/components/LoadingSimple';
    import EmptyState from '@/components/EmptyState.vue';
    import utils from '@/common/utils';
    import api from '@/api/chargebacks/chargebacks.api';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';

    import ImagenEmptyStateDown from '@/assets/images/Emptystates/emptystate-search.svg';

    import moment from "moment";

    import {
        STORE as ChargebackStore,
        CLEAR_CHARGEBACK
    } from '@/store/modules/chargebacks/chargebacks.types';

    import {
        SET_SEARCH,
        SET_CURRENT_PAGE
    } from '@/store/modules/base/catalog.types'

    const modalId = "modal-send-grant"
    moment.locale('es');

    export default {
        name: 'ChargebackTable',
        data() {
            return {
                selectMode: 'multi',
                filtersHeader: false,
                ImagenEmptyStateDown,
                currentPage: 1,
                columnValues: [
                    {
                        "label": this.$t("chargebacks.table.number"),
                        "property": "number",
                        "value": true
                    },
                    {
                        "label": this.$t("chargebacks.table.payment-day"),
                        "property": "transaction.date",
                        "value": true
                    },
                    {
                        "label": this.$t("chargebacks.table.total"),
                        "property": "transaction.total",
                        "value": true
                    },
                    {
                        "label": this.$t("chargebacks.table.client"),
                        "property": "transaction.user.fullName",
                        "value": true
                    },
                    {
                        "label": this.$t("chargebacks.table.status"),
                        "property": "status",
                        "value": true
                    },
                    {
                        "label": this.$t("chargebacks.table.notification-date"),
                        "property": "notificationDate",
                        "value": true
                    },
                    {
                        "label": this.$t("chargebacks.table.remaining-time"),
                        "property": "updatedAt",
                        "value": true
                    }
                ],
                valueProgressBar: 41,
                maxProgress: 100,
                chargebackIdToGrant: ""
            }
        },
        mixins: [ToastSnotify],
        props: {
            chargebacksList: {
                type: Array, default: function () {
                    return [];
                }
            },
            status: {
                type: String, default: ""
            },
            loading: {
                type: Boolean, default: true
            },
            total: {
                type: Number
            },
            perPage: {
                type: Number
            },
            currentOrganization: {
                type: Object
            },
            activeTable: {type: Boolean, default: true}
        },
        components: {
            BasicInput,
            CardSimple,
            SearchBar,
            SortIcon,
            BasicCheckbox,
            Badge,
            EmptyState,
            LoadingSimple,
            RemainingTime,
            ModalAlert
        },
        computed: {
            paginationMessage() {
                const status = `chargebacks.pagination.${this.status}`;
                return `${this.total} ${this.$tc("chargebacks.pagination.title", this.total)} ${this.$tc(status, this.total)}`
            },
            columnValuesToShow() {
                return this.columnValues.filter(c => c.value);
            }
        },
        methods: {
            getAppliedDate(chargebackLogs) {
                if (!chargebackLogs) {
                    return Date.now();
                }
                const appliedDate = chargebackLogs.find(log => log.status === "APPLIED");
                return appliedDate ? new Date(appliedDate.date).getTime() : Date.now();
            },
            getMaxBar(limitDate, chargebackLogs) {
                if (!limitDate) {
                    return 0;
                }
                const appliedDate = this.getAppliedDate(chargebackLogs);
                return new Date(limitDate).getTime() - appliedDate;
            },

            showDropdown() {
                document.getElementById("menuOverflowFix").classList.add('dropdown-table-overflow');
            },
            hideDropdown() {
                document.getElementById("menuOverflowFix").classList.remove('dropdown-table-overflow');
            },
            headerFilters() {
                this.filtersHeader = !this.filtersHeader;
            },
            formatPercent(number) {
                return `${Number(number).toFixed(2)}%`
            },
            formatNumber(totalLength, type, property) {
                if (type === 'string') {
                    return property + new Array(totalLength - (String(property).length - 1)).join(' ');
                } else if (type === 'number') {
                    return new Array(totalLength - (String(property).length - 1 )).join("0") + String(property);
                }
                return "";
            },
            formatDate(date, format) {
                return moment(date).format(format);
            },
            formatLastUpdate(date) {
                const diff = Date.now() - new Date(date).getTime();
                if (diff < 86400000) {
                    return moment(date).startOf('hour').fromNow();
                }
                return moment(date).startOf('day').fromNow();
            },
            pageChange(page) {
                this.currentPage = page;
                this.$store.commit(`${ChargebackStore}/${SET_CURRENT_PAGE}`, page);
                this.$store.dispatch(`${ChargebackStore}/list`, this.currentOrganization._id);
            },
            getBadgeClass(status) {
                switch (status) {
                    case "CREATED":
                        return "badge-placeholder";
                    case "APPLIED":
                        return "badge-accent_2";
                    case "REVISION":
                        return "badge-warning";
                    case "WIN":
                        return "badge-success m-0-auto";
                    case "LOST":
                        return "badge-error m-r-20 m-b-5";
                    default:
                        return "badge-placeholder";
                }
            },
            createChargeback() {
                this.$store.commit(`${ChargebackStore}/${CLEAR_CHARGEBACK}`);
                this.$router.push({name: 'ChargebackNew'});
            },
            searchTextChange(searchText) {
                utils.debounceFixed(() => {
                    if((searchText && searchText.length > 2) || searchText === "") {
                        this.searchText = searchText;
                        this.$store.commit(`${ChargebackStore}/${SET_SEARCH}`, searchText);
                        this.$store.dispatch(`${ChargebackStore}/list`, this.currentOrganization._id);
                    }
                })
            },
            setSortDocument(sort, typeSort) {
                this.$store.dispatch(`${ChargebackStore}/setSortDocument`, {
                    sort,
                    typeSort
                });
                this.$store.dispatch(`${ChargebackStore}/list`, this.currentOrganization._id);
            },
            setChargebackToGrant(chargebackId){
                if(chargebackId){
                    this.chargebackIdToGrant = chargebackId;
                    this.$bvModal.show(modalId);
                }
            },
            grantChargeback() {
                api.grantChargeback({id: this.chargebackIdToGrant},
                    (result) => {
                        this.$bvModal.hide(modalId);
                        if (result.data.error) {
                            this.displayNotificationError("Ocurrio un error al otorgar el contracargo");
                        }
                        this.$store.dispatch(`${ChargebackStore}/list`, this.currentOrganization._id);
                        this.$store.dispatch(`${ChargebackStore}/statsChargebacks`, this.currentOrganization._id);
                        this.displayNotificationSuccess("Contracargo actualizado con éxito!");
                    }, () => {
                        this.$bvModal.hide(modalId);
                        this.displayNotificationError("Ocurrio un error al otorgar el contracargo");
                    })

            },
            paymentDetail(chargeback) {
                if (chargeback && chargeback.transaction && chargeback.transaction.transactionId && chargeback.transaction.transactionId.paymentDetail) {
                    return chargeback.transaction.transactionId.paymentDetail;
                }
                return {};
            },
            chargabackTotal(chargeback) {
                return this.paymentDetail(chargeback).totalWithoutCommision || chargeback.transaction.total;
            }
        },
        filters: {
            money: function (value) {
                if (!value) {
                    return "$0.00";
                }
                return `${new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(Number(value))}`;
            }
        },
    }
</script>
