import axios from 'axios'
const namespace = "currencies";

export default {
  get: (onSuccess, onError) => {
    return axios
      .get(`${namespace}/get`)
      .then(onSuccess)
      .catch(onError);
  }
};
