<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout :msgSmall="$t('terminals.title-sm')" :title="$t('terminals.title')"/>
        </div>

        <div class="col-12">
            <SearchBar
                    v-if="terminals.totalDocs >= 0"
                    :columValues="fields"
                    :canCreate="false"
                    clazzFilter="m-r-0"
                    v-on:changeListLimit="changeListLimit"
                    v-on:searchTextChange="searchTextChange"
            ></SearchBar>
        </div>

        <div class="col-12">
            <CardSimple v-if="terminals.totalDocs <= 0">
                <EmptyState
                        emptyStateDefault
                        :imgUrl="ImagenEmptyState"
                        :title="$t('terminals.empty-list')"
                ></EmptyState>
            </CardSimple>
            <CardSimple v-if="loading">
                <EmptyState
                        emptyStateDefault
                        :imgUrl="ImagenEmptyState"
                        :title="$t('terminals.loading')"
                ></EmptyState>
            </CardSimple>
        </div>

        <div class="col-12">
            <CardSimple clazz="p-0" hideTitle clazzTitle="m-0 d-none" v-if="!loading && terminals.totalDocs > 0">
                <div id="comprobantesFiscales" class="basic-table">
                    <b-table-simple
                            responsive
                            ref="selectableTable"
                            selectable
                            selected-variant="active"
                            table-variant="xl">

                        <b-thead>
                            <b-tr class="">
                                <b-th v-show="fields[0].value">
                                    {{$t('terminals.serialNo')}}
                                </b-th>
                                <b-th v-show="fields[1].value">
                                    {{$t('terminals.brand')}}
                                </b-th>
                                <b-th v-show="fields[2].value">
                                    {{$t('terminals.softwareVersion')}}
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="terminal in terminals.docs" v-bind:key="terminal._id">
                                <b-td v-show="fields[0].value">{{terminal.serialNo}}</b-td>
                                <b-td v-show="fields[1].value">{{terminal.brand}}</b-td>
                                <b-td v-show="fields[2].value">{{terminal.softwareVersion}}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </CardSimple>
        </div>

        <div class="col-12">
            <div v-if="!loading && terminals.totalDocs > 0" class="pagination-custom">
                <span class="pagination-count"> {{ terminals.totalDocs }}
                    {{ $tc('terminals.terminals', terminals.totalDocs) }} </span>
                <b-pagination
                    aria-controls="my-table"
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="terminals.totalDocs"
                    next-text=""
                    prev-text=""
                    hide-goto-end-buttons
                    @change="pageChange"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import ImagenEmptyState from '@/assets/images/Emptystates/emptystate-payment-cash.svg';
    import SearchBar from '@/components/SearchBar.vue';
    import {mapGetters} from 'vuex';
    import utils from '@/common/utils';
    import catalog from './../../mixins/catalog.mixin';
    import ToastSnotify from '@/mixins/toastSnotify.mixin'
    import {EventBus} from "@/main";

    import {
        STORE as TerminalsStore,
        GET_TERMINALS,
        ERROR_LOAD_TERMINALS,
        GET_LOADING_TERMINALS
    } from '@/store/modules/terminals/terminals.types';

    import {
    STORE as AccessConfigStore,
    GET_CURRENT_ORGANIZATION,
} from '@/store/modules/accessconfig/accessConfig.types';


    const catalogTerminals = catalog.configure({
        storeModule: TerminalsStore
    });

    const TEXT_ALIGN_C_CLASS = "text-align-c";

    export default {
        name: "TerminalList",
        components: {
            FloatingTitleLayout,
            CardSimple,
            EmptyState,
            SearchBar,
        },
        data() {
            return {
                ImagenEmptyState: ImagenEmptyState,
                fields: [
                    {
                        key: 'serialNo',
                        label: this.$i18n.t('terminals.serialNo'),
                        sortable: true,
                        class: 'text-align-l c-primary_1 principal-font-bold',
                        value: true
                    },
                    {
                        key: 'brand',
                        label: this.$i18n.t('terminals.brand'),
                        sortable: true,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                    {
                        key: 'softwareVersion',
                        label: this.$i18n.t('terminals.softwareVersion'),
                        sortable: true,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                ],
                searchText: "",
                currentPage: 1,
                perPage: 10
            }
        },
        mixins: [catalogTerminals, ToastSnotify],
        methods: {
            pageChange(page) {
                this.currentPage = page;
                this.loadTerminals();
            },
            changeListLimit(limit) {
                utils.debounceFixed(() => {
                    if (limit <= 0) {
                        this.displayNotificationWarning(this.$i18n.t('general.list-limit-warn'));
                        this.perPage = 10;
                    } else {
                        this.perPage = limit;
                    }
                    this.loadTerminals();
                })
            },
            searchTextChange(searchText) {
                utils.debounceFixed(() => {
                    this.searchText = searchText;
                    this.loadTerminals();
                })
            },
            async loadTerminals() {
                await this.$store.dispatch(`${TerminalsStore}/listTerminals`, {
                    organization: this.organization._id,
                    currentPage: this.currentPage,
                    perPage: this.perPage,
                    searchText: this.searchText,
                });
            },
        },
        beforeMount() {
            this.loadTerminals();
            EventBus.$on(`${TerminalsStore}${ERROR_LOAD_TERMINALS}`, (message) => {
                this.displayNotificationError(message, this.$i18n.t('terminals.error-loading'))
            });
        },
        beforeDestroy(){
            EventBus.$off(`${TerminalsStore}${ERROR_LOAD_TERMINALS}`);
        },
        computed: {
            ...mapGetters(AccessConfigStore, {
                organization: GET_CURRENT_ORGANIZATION,
            }),
            ...mapGetters(TerminalsStore, {
                terminals: GET_TERMINALS,
                loading: GET_LOADING_TERMINALS
            }),
        }
    }
</script>

