<template>
    <div class="form-group read-only" v-bind:class="clazz">
        <label :class="titleClazz"> {{title}} </label>
        <p v-if="currency" :class="textClazz"> {{text | currency}} </p>
        <p v-else :class="textClazz"> {{text}} </p>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ReadOnlyInfo',
        props: {
            clazz: String,
            title: String,
            text: [String, Number],
            titleClazz: String,
            textClazz: String,
            currency: Boolean,
        }
    }
</script>

<style lang="scss">
    .read-only {
        padding-top: 0;

        label {
            font-size: 13px;
            font-weight: bold;
            line-height: 1.31;
            display: block;
            margin-bottom: 4px;
        }

        p {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.29;
            display: block;
            margin-bottom: 0px;
            word-break: break-word;
        }
    }
</style>
