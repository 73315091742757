<template>
    <div>
        <div class="register-container">
            <div class="left-side-content">
                <div class="container-left">
                    <img
                        class="img-fluid logo"
                        src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg"
                        width="166"
                        height="60"
                        alt="Logo Pagando Check"
                    />
                    <span class="d-block w-100">
                        <p
                            class="info-text"
                            v-html="$t('hire.layout.p1-html')"
                        ></p>
                        <div class="border-container">
                            <p
                                class="info-text m-b-15 text-left"
                                v-text="$t('hire.layout.p2')"
                            ></p>
                            <ul class="info-text-list">
                                <li v-text="$t('hire.layout.li1')"></li>
                                <li v-text="$t('hire.layout.li2')"></li>
                                <li v-text="$t('hire.layout.li3')"></li>
                            </ul>
                        </div>
                        <p
                            class="info-text m-b-0"
                            v-html="$t('hire.layout.p3-html', user)"
                        ></p>
                    </span>
                    <div class="footer-help">
                        <a
                            href
                            class="help-chat"
                            tabindex=""
                            aria-label="Chat de soporte"
                        >
                            <img
                                class="img-fluid"
                                src="@/assets/images/Illustrations/illustration-chat-help.svg"
                                width="365"
                                height="153"
                                alt="Chat de soporte"
                            />
                        </a>
                        <p class="help-num">
                            {{$t('hire.layout.support1')}}
                            <br/>
                            <strong v-text="$t('hire.layout.support2')"></strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="right-side-content">
                <WalletHireData
                    v-if="showData"
                    @finished="setDataSaved"
                ></WalletHireData>
                <WalletHirePassword
                    v-if="showPassword"
                    @returnClicked="returnFromPassword"
                    @finished="setPasswordSaved"
                ></WalletHirePassword>
                <WalletHireProcessing
                    v-if="showSuccess"
                ></WalletHireProcessing>
            </div>
            <div class="footer-help responsive">
                <a
                    href
                    class="help-chat"
                    tabindex=""
                    aria-label="Chat de soporte"
                >
                    <img
                        class="img-fluid"
                        src="@/assets/images/Illustrations/illustration-chat-help.svg"
                        width="365"
                        height="153"
                        alt="Chat de soporte"
                    />
                </a>
                <p class="help-num">
                    {{$t('hire.layout.support1')}}
                    <br/>
                    <strong v-text="$t('hire.layout.support2')"></strong>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import usersApi from '@/api/user/users.api';
import WalletHireData from "@/pages/WalletHire/data.vue";
import WalletHirePassword from "@/pages/WalletHire/password.vue";
import WalletHireProcessing from "@/pages/WalletHire/processing.vue";
import OrganizationApi from "@/api/organization/organizations.api.js";
import {
    STORE as AccessConfigStore,
    SET_CURRENT_ORGANIZATION
} from '@/store/modules/accessconfig/accessConfig.types';

import store from "@/store/index";
import {
    STORE as SessionStore,
    SET_USER,
} from '@/store/modules/session/session.types';

export default {
    name: "PagandoCheckHireLayout",
    components: {
        WalletHireData,
        WalletHirePassword,
        WalletHireProcessing,
    },
    data() {
        return {
            dataSaved: false,
            passwordSaved: false,
            user: {email: ''},
        }
    },
    computed: {
        showData() {
            return !this.dataSaved && !this.passwordSaved;
        },
        showPassword() {
            return this.dataSaved && !this.passwordSaved;
        },
        showSuccess() {
            return this.dataSaved && this.passwordSaved;
        }
    },
    methods: {
        setDataSaved() {
            this.dataSaved = true;
        },
        setPasswordSaved() {
            this.dataSaved = true;
            this.passwordSaved = true;
        },
        returnFromPassword() {
            this.dataSaved = false;
        }
    },
    async beforeMount() {
        try {
            const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
            localStorage.setItem('currentToken', `"${this.$route.query.token}"`);
            localStorage.setItem('expireDate', JSON.stringify(expireDate));

            this.user = await usersApi.getCurrentUserSession({}).then(({data: {user}}) => user);
            localStorage.setItem('currentUser', JSON.stringify(this.user));
            store.commit(`${SessionStore}/${SET_USER}`, this.user);

            const {organization} = await OrganizationApi.hireData();
            this.$store.commit(`${AccessConfigStore}/${SET_CURRENT_ORGANIZATION}`, JSON.parse(organization));
        } catch(error) {
            this.toastErrorDefault(error);
        }
    }
};
</script>
