import base from '@/api/base.api';
import moment from 'moment';

export const utils = {
    toast: function (snotify, type, title, message) {
        const content = `
        <span class="icon">
          <i aria-hidden="true" class="banana banana-check-normal"></i>
        </span>
        <span>
          <div class="snotifyToast__title">${title}</div>
          <div class="snotifyToast__body">
            <p>${message}</p>
          </div>
        </span>
        <a class="close">
          <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </a>
    `;

        snotify.html(content, {
            timeout: 2000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightTop',
            type: type
        });
    },
    formatDate: function(date, format = 'YYYY-MM-DD'){
        if (date) {
            return moment(date).format(format);
        }
        return '-';
    },
    numberToMoneyString: function (number) {
        if (typeof number === 'string') {
            number = Number.parseFloat(number);
        }
        if (isNaN(number) || number === null) {
            return '$0.00';
        }
        return `$${number.toLocaleString(
            'en-IN',
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        )}`;
    },
    imageDownloadLink: function (file) {
        return `${base.baseUrl}/image/download/${file._id || file}`;
    },
    fileDownloadLinkSigned: function (file) {
        return `${base.baseUrl}/file/download/${file._id || file}`;
    },
    toFormatDate(date, format) {
        return moment(date).format(format);
    },
    roundNumber(number, decimals){
        return parseFloat(number).toFixed(decimals);
    }
}
