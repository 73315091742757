<template>
    <div id="dct-listArea" class="list-area" :class="clazz">
        <button id="bin-drag" type="button" class="button-circle xs button-ghost button-title"
                :disabled="disableButtons"><i aria-hidden="true" class="banana banana-options-other-vertical"></i></button>
        <slot></slot>
        <button id="bin-deleteListArea" class="button-circle xs button-ghost button-error" :disabled="disableButtons"
                @click="clickDelete"><i aria-hidden="true" class="banana banana-delete"></i></button>
    </div>
</template>

<script>
    export default {
        name: 'ListUploadArea',
        props: {
            clazz: String,
            disableButtons: {type: Boolean, default: false}
        },
        methods: {
            clickDelete() {
                this.$emit('clickDelete');
            }
        }
    }
</script>

<style lang="scss">
    .list-area {
        display: flex;
        align-items: center;
        width: 100%; //calc(100% + 20px);
        margin: 0 0 30px; //0 -10px 30px;

        .upload-area > span {
            width: 50px;
            height: 50px;
            font-size: 20px;
            min-width: 50px;
            border-width: 1.5px;
        }

        .input-url {
            border-radius: 2px;
            padding: 6px 14px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.29;
            margin: 0 2px 0 10px;
            width: 100%;
            height: 38px;
            transition: 0.5s;
        }
    }

    .vertical-upload-list {
        width: calc(100% + 20px);
        margin: 0 -10px 10px;

        > .list-area {
            display: inline-flex;
            width: fit-content;
            margin: 0 10px 20px;
        }
    }
</style>
