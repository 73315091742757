<template lang="html">

  <CardSimple
    :title="$t('pagos.resumen.title')"
    :subtitle="$t('pagos.resumen.subtitle')"
  >
    <div class="">
      <h1 class="c-title f-20 principal-font-bold m-b-30 m-t-40">
        {{ $t('debtors.general.title2') }}
      </h1>

      <div class="row">
        <div class="col-md-6 col-12 m-b-30">
          <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">{{ $t('debtors.general.status.title') }}</h4>
          <div>
            <Badge v-if="payment.activeService"
              type="badge-accent_2"
              text="Activo"
            ></Badge>
            <Badge v-else-if="payment.comingSoon"
              type="badge-warning"
              text="Próximamente"
            ></Badge>
            <Badge v-else
              type="badge-placeholder"
              text="inactivo"
            ></Badge>
          </div>
        </div>
        <div class="col-md-6 col-12 m-b-30">
          <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">{{ $t('debtors.general.id') }}</h4>
          <p class="c-plain_text f-14 body-font-regular m-0">
            {{payment.externalIdentifier}}
          </p>
        </div>
      </div>
      <h1 class="c-title f-20 principal-font-bold m-b-30 m-t-10">
        {{ $t('debtors.fields.title') }}
      </h1>

      <div class="row">
        <div class="col-md-6 col-12 m-b-30" v-for="field in payment.fields" :key="field.name">
          <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">{{field.name}}</h4>
          <p class="c-plain_text f-14 body-font-regular m-0">
            {{ field.value }}
          </p>
        </div>
      </div>

      <h1 class="c-title f-20 principal-font-bold m-b-30 m-t-10">
        {{ $t('debtors.references.title') }}
      </h1>

      <div class="row">
        <div class="col-md-6 col-12 m-b-30" v-for="reference in payment.references" :key="reference.name">
          <h4 class="m-b-10 c-title f-14 f-bold body-font-regular m-0">{{reference.name}}</h4>
          <p class="c-plain_text f-14 body-font-regular m-0">
            {{ reference.value }}
          </p>
        </div>
      </div>
    </div>

    <button v-if="!editVersion" @click="goToGeneral" class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-10">
      {{ $t('pagos.resumen.general.edit') }}
    </button>
    <h1 class="c-title f-20 principal-font-bold m-b-30 m-t-40">
      {{$t('pagos.resumen.conceptos.title')}}
    </h1>

    <div class="basic-table">
      <b-table
        responsive
        hover
        :fields="conceptsFields"
        :items="conceptsFormatted()"
      ></b-table>
    </div>

    <div class="total-container">
      <div class="total-text body-font-medium">{{$t('pagos.resumen.general.subtotal')}}</div>
      <div class="total-number body-font-medium">{{ conceptsTotal() | currency }} {{currencyObject().code}}</div>
    </div>

    <button v-if="!editVersion" class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToConcepts">
      {{ $t('pagos.resumen.general.edit') }}
    </button>

    <h1 class="c-title f-20 principal-font-bold m-b-30 m-t-40">
      {{$t('pagos.resumen.descuentos.title')}}
    </h1>

    <div class="basic-table" v-if="payment.discounts && payment.discounts.length > 0">
      <b-table
        responsive
        hover
        :fields="discountsFields"
        :items="discountsFormatted()"
      >
        <template v-slot:estatus>
          <Badge
            type="badge-accent_2"
            text="Activo"
          ></Badge>
        </template>
      </b-table>
    </div>
    <button v-if="!editVersion" class="button button-stroke button-accent1 xs ml-auto m-b-40 m-t-40" @click="goToDiscounts">
      {{ $t('pagos.resumen.general.edit') }}
    </button>

    <h1 class="c-title f-20 principal-font-bold m-b-30 m-t-40">
      {{ $t('pagos.resumen.total.title') }}
    </h1>
    <div class="total-container m-b-0">
      <div class="total-text body-font-medium">{{$t('pagos.resumen.general.subtotal')}} </div>
      <div class="total-number body-font-medium">{{ conceptsTotal() | currency }} {{currencyObject().code}}</div>
    </div>
    <div class="basic-table">
      <b-table
        responsive
        hover
        :fields="taxesFields"
        :items="taxesFormatted()"
      >
      </b-table>
    </div>
    <div class="total-container">
      <div class="total-text body-font-medium">{{$t('pagos.resumen.general.total')}}</div>
      <div class="total-number body-font-medium">{{ total() | currency }} {{currencyObject().code}}</div>
    </div>

    <div class="row m-t-40">
      <div class="col-12 vertical-center justify-content-end" >
        <button class="button button-ghost sm button-plain_text m-0-auto-0-0" @click="prev" v-if="!editMode">
          <i aria-hidden="true" class="banana banana-chevron-left"></i>
          {{$t('general.actions.back.title')}}
        </button>
        <div class="vertical-center">
          <button v-if="!editVersion" class="button button-ghost sm button-plain_text" @click="cancel">
            {{$t('general.actions.cancel.title')}}
          </button>
          <button v-if="!editVersion" class="button button-filled sm button-accent1 m-l-10" @click="finish">
            {{$t('general.actions.save')}}
          </button>
        </div>
      </div>
    </div>
  </CardSimple>

</template>

<script lang="js">
  import Badge from "@/components/Badge";
  import CardSimple from '@/components/CardSimple.vue';
  import moment from 'moment';
  import {utils} from '@/api/utils.js';
  import utils2 from '@/common/utils';
  import OrganizationApi from '@/api/organization/organizations.api';
  import {mapGetters} from 'vuex';
  import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
  const TEXT_ALIGN_CLASS = "text-align-c";
  const DISCOUNT_PAYMENTS_ACTIVE_BADGE_KEY = 'pagos.descuentos.table.active';

  export default {
    name: 'summary-debtors',
    props: {
        editVersion: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        },
        debt: {
            type: Object,
            default: () => {}
        },
        payment: {
            type: Object,
            default: function() {
                return {
                    items: [],
                    discounts: []
                };
            }
        }
    },
    components: {
      Badge,
      CardSimple
    },
    mounted () {
        OrganizationApi.getCommision(this.currentOrganization._id).then((data) => {
            this.commision = data.data.object;
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.message) {
              this.displayNotificationError(this.$t('general.actions.error.title'), error.response.data.message);
            } else {
              this.displayNotificationError(this.$t('general.actions.error.title'), this.$t('general.actions.error.text'));
            }
        });
    },
    data () {
      return {
            commision: {},
            conceptsFields: [
                {
                    key: "name",
                    label: this.$t('pagos.resumen.conceptos.table.name')
                },
                {
                    key: "quantity",
                    label: this.$t('pagos.resumen.conceptos.table.quantity'),
                    class: TEXT_ALIGN_CLASS
                },
                {
                    key: "unitPrice",
                    label: this.$t('pagos.resumen.conceptos.table.precio'),
                    class: "text-align-r"
                },
                {
                    key: "total",
                    label: this.$t('pagos.resumen.general.subtotal'),
                    class: "text-align-r f-bold w-150px"
                }
            ],
            discountsFields: [
                {
                    key: "description",
                    label: this.$t('pagos.resumen.descuentos.table.name')
                },
                {
                    key: "from",
                    label: this.$t('pagos.resumen.descuentos.table.inicio'),
                    class: TEXT_ALIGN_CLASS
                },
                {
                    key: "to",
                    label: this.$t('pagos.resumen.descuentos.table.fin'),
                    class: TEXT_ALIGN_CLASS
                },
                {
                    key: "status",
                    label: this.$t('pagos.resumen.descuentos.table.estatus'),
                    class: "text-align-c w-85px"
                },
                {
                    key: "discount",
                    label: this.$t('pagos.resumen.descuentos.table.descuento'),
                    class: "text-align-r f-bold c-success w-150px"
                }
            ],
            taxesFields: [
                {
                    key: "name",
                    label: "",
                    class: "text-align-r"
                },
                {
                    key: "amount",
                    label: "",
                    class: "text-align-r f-bold  w-150px"
                }
            ],
        }
    },
    methods: {
        goToGeneral() {
            this.$emit('goTo', 0);
        },
        goToConcepts() {
            this.$emit('goTo', 1);
        },
        goToDiscounts() {
            this.$emit('goTo', 2);
        },
        badgeClass() {
            if (this.payment.activeService) {
                return 'badge-accent_2';
            }
            return 'badge-accent_1';
        },
        badgeText() {
            if (this.payment.activeService) {
                return this.$t('pagos.general.servicio.status.active');
            }
            if (this.payment.comingSoon) {
                return this.$t('pagos.general.servicio.status.comingSoon');
            }
            return this.$t('pagos.general.servicio.status.inactive');
        },
        // Converts a number or a number string to a money formatted string.
        numberToMoneyString(number) {
            if(isNaN(number))
            {
                return '0.00';
            }
            if(typeof number === 'string')
            {
                number = Number.parseFloat(number);
            }
            return `$ ${ number.toLocaleString(
                'en-IN',
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            ) } ${ this.currencyObject().code}`;
        },
         imageUrl(img) {
            return utils.imageDownloadLink(img);
        },
        discountStatus(discount) {
            const now = new Date();

            if (discount.from && typeof discount.from === 'string') {
                discount.from = new Date(discount.from);
            }
            if (discount.to && typeof discount.to === 'string') {
                discount.to = new Date(discount.to);
            }

            if (discount.from && discount.from.getTime() < now.getTime() ) {
                if (discount.to && discount.to.getTime() < now.getTime()) {
                    return {type: 'badge-error m-l-20', text: this.$t('pagos.descuentos.table.expired')};
                }
              return {type: 'badge-info m-l-20', text: this.$t(DISCOUNT_PAYMENTS_ACTIVE_BADGE_KEY)};
            }
            return {type: 'badge-disable m-l-20', text: this.$t('pagos.descuentos.table.inactive')};
        },
        discountAmount(discount) {
            if (discount.isPercentage) {
                const factor = utils2.jsNumFix(discount.discount / 100);
                return utils2.jsNumFix(this.conceptsTotal() * factor);
            }
            return discount.discount;
        },
        cancel() {
            this.$emit('cancel');
        },
        prev() {
            this.$emit('prev');
        },
        finish() {
            this.payment.subTotal = this.conceptsTotal();
            this.payment.totalTaxes = 0; // TODO validate
            this.payment.totalDiscounts = this.discountsTotal();
            this.payment.total = this.total();
            this.payment.totalWithoutCommision = utils2.jsNumFix(this.total() - (this.commision.price || 0));
            this.$emit('finish');
        },
        currencyObject() {
            return (this.debt || {}).currency || {};
        },
        summaryTotal() {
            let newTotal = this.conceptsTotal().number;

            const percentageDiscounts = this.$_.filter(this.discounts, (discount) => { return discount.type.name === 'Porcentaje'; });
            const amountDiscounts = this.$_.filter(this.discounts, (discount) => { return discount.type.name === 'Cantidad'; });

            percentageDiscounts.forEach(discount => {
                newTotal *= (1 - (discount.amount/100));
            });

            amountDiscounts.forEach(discount => {
                newTotal -= discount.amount;
            });

            return newTotal;
        },
        // Lists discounts and taxes (added values) in a valid format.
        taxesFormatted() {
            const context = this;
            const concepts = [];
            const activeDiscounts = this.payment.discounts.filter(d => this.discountStatus(d).text === this.$t(DISCOUNT_PAYMENTS_ACTIVE_BADGE_KEY));
          activeDiscounts.forEach(d => concepts.push({
            name: d.description,
            amount: `- ${context.numberToMoneyString(context.discountAmount(d))}`,
            amountNumber: (-1 * context.discountAmount(d))
          }));

            if (this.commision && this.commision.price) {
                concepts.push({name: this.$t('pagos.general.commision'), amount: `${this.numberToMoneyString(this.commision.price)}`, amountNumber: this.commision.price});
            }

            return concepts;
        },
        discountsFormatted() {
            return this.$_.map(this.payment.discounts, (oldDiscount) => {
                const discount = JSON.parse(JSON.stringify(oldDiscount));
                discount.from = new moment(discount.from).format('DD/MM/YYYY');
                if(discount.to){
                    discount.to = new moment(discount.to).format('DD/MM/YYYY');
                }
                discount.status = this.discountStatus(oldDiscount).text;
                if (discount.isPercentage) {
                    discount.discount = `- ${discount.discount}%`;
                } else {
                    discount.discount = `- ${ this.numberToMoneyString(discount.discount)}`;
                }
                return discount;
            });
        },
      // Adds a subtotal field and returns a printable json.
        conceptsFormatted() {
            return this.$_.map(this.payment.items, (concept) => {
                const temp = JSON.parse(JSON.stringify(concept));
                temp.unitPrice = `$${parseFloat(temp.unitPrice).toFixed(2)} ${this.currencyObject().code}`;
                temp.total = `$${parseFloat(temp.total).toFixed(2)} ${this.currencyObject().code}`;
                return temp;
            });
      },
        conceptsTotal() {
          let total = 0;
          this.payment.items.forEach(function (i) {
            total += i.total
          });
          return total
        },
        discountsTotal() {
          let total = 0;
          const context = this;
          const activeDiscounts = this.payment.discounts.filter(d => this.discountStatus(d).text === this.$t(DISCOUNT_PAYMENTS_ACTIVE_BADGE_KEY));
          activeDiscounts.forEach(function (d) {
            total += context.discountAmount(d)
          });
          return total;
        },
        total() {
            let total = 0;
          this.payment.items.forEach(function (i) {
            total += i.total
          });
          this.taxesFormatted().forEach(function (t) {
            total += t.amountNumber
          });
            return total
        },
    },
    computed: {
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        })
    }
}
</script>
