<template>
    <b-modal :id="modalId" ref="modalAlert" hide-header hide-footer :no-close-on-esc="closeEsc" :no-close-on-backdrop="closeBackdrop">
      <button v-if="!closeCross" class="close-modal" @click="hideModal()"><i aria-hidden="true" class="banana banana-cross-normal"></i></button>
      <div class="content-alert" :class="clazz">
            <img class="img-fluid" :src="imgUrl" alt="Image Alert"/>
            <h1>{{title}}</h1>
            <p>{{text}}</p>

            <slot></slot>

            <div class="buttons">
                <button v-if="!noCancelButton" class="button xs button-ghost button-plain_text" @click="cancel()">{{buttonCancel}}</button>
                <button v-if="!localLoading" class="button xs button-filled" @click="submit" :class="buttonType">{{buttonText}}</button>
                <button :id="'Loading button'" v-if="localLoading" class="button xs button-filled" :class="buttonType"><LoadingSimple clazz="small-load no-text" /></button>
            </div>
      </div>
    </b-modal>
</template>

<script>
    import Imagen from '@/assets/images/Illustrations/illustration-modal-generic.svg';
    import LoadingSimple from '@/components/LoadingSimple.vue';

    export default {
        name: 'ModalAlert',
        components: {
            LoadingSimple
        },
        props: {
            modalId: String,
            clazz: String,
            title: String,
            text: String,
            buttonCancel: {type: String, default: 'Cancelar'},
            buttonType: String,
            buttonText: String,
            imgUrl: {
                type: String,
                default: Imagen
            },
            withAutomaticLoad: {
                type: Boolean,
                default: false
            },
            closeCross: {
                type: Boolean,
                default: false
            },
            closeEsc: {
                type: Boolean,
                default: false
            },
            closeBackdrop: {
                type: Boolean,
                default: false
            },
            noCancelButton: {
                type: Boolean,
                defautl: false
            }
        },
        data() {
            return {
                defaultUrl: Imagen,
                localLoading: false
            }
        },
        methods: {
            hideModal() {
                this.$refs.modalAlert.hide();
                this.$emit('close')
            },
            cancel() {
                this.$refs.modalAlert.hide();
                if(this.withAutomaticLoad){
                    this.localLoading = false;
                }
                this.$emit('cancel')
            },
            submit() {
                if(this.withAutomaticLoad){
                    this.localLoading = true;
                }
                this.$emit('submit')
            }
        }
    }
</script>
