<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout
                    clazz="m-b-30"
                    :msgSmall="$t('pagos-recaudados.control-panel')"
                    :title="$tc('pagos-recaudados.title', 2)"
            />
            <div>
                <SearchBar
                        ref="searchBar"
                        showDateRange
                        :columValues="columValues"
                        :canCreate="true"
                        :noOptions="true"
                        :loading="loadingReport"
                        v-on:changeListLimit="changeListLimit"
                        v-on:searchTextChange="searchTextChange"
                        v-on:dateRangeChange="dateRangeChange"
                        v-on:routerPush="excelReport"
                        :buttonText="$t('pagos-recaudados.download-recipie')"
                ></SearchBar>
            </div>
        </div>
        <div class="col-12" v-if="loadingCollectedPayments">
            <CardSimple hideTitle clazz="p-0" clazzTitle="d-none">
                <EmptyState emptyStateSearching :text="$t('pagos-recaudados.searching')"></EmptyState>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple clazzTitle="d-none" clazz="w-100 title-padding p-0"
                        :class="multiSelectDocuments ? 'header-options-full' : ''" v-if="!loadingCollectedPayments">
                <div class="col-12"
                     v-if="!loadingCollectedPayments && totalDocs <= 0 && searchText === '' && getFiltersCount === 0">
                    <EmptyState
                            :title="$t('collected-payments.no-results-found')"
                            :text="searchText"
                            emptyStateDefault
                            :imgUrl="EmptyStateBox"
                    ></EmptyState>
                </div>
                <div class="basic-table" id="tableOne">
                    <b-table-simple
                            responsive
                            hover
                            ref="selectableTable"
                            selectable
                            :select-mode="selectMode"
                            selected-variant="active"
                            table-variant="xl">
                        <!-- Add classes 'b-table-row-selected table-active' to 'b-tbody > b-tr' when a row checkbox selected.
                                      If MORE THAN ONE row CHECKBOX is selected, add to 'b-thead > b-tr' class 'header-options-full'.-->
                        <b-thead>
                            <b-tr v-if="(totalDocs === 0 && !loadingCollectedPayments && getFiltersCount > 0) || totalDocs > 0"
                                  :class="multiSelectDocuments ? 'header-options-full' : ''">
                                <b-th class="max-w-150px" sortable>
                                    <div class="align-items-c d-flex">
                                        <!-- this v-show hides the folio name-->
                                        <span v-show="columValues[0].value" class="open-filters"
                                              @click="headerFilters()"> {{$t('pagos-recaudados.folio')}} </span>
                                        <!-- this v-show hides the sort keys-->
                                        <strong class="float-r m-l-5" v-show="columValues[0].value">
                                            <SortIcon
                                                    @sortUp="setSortCollectedPayment('number', 'asc')"
                                                    @sortDown="setSortCollectedPayment('number', 'desc')"
                                            ></SortIcon>
                                        </strong>
                                    </div>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentFolio"
                                                labelFor="Folio"
                                                inputId="Folio"
                                                @input="setFilterFolio"
                                                :inputPlaceholder="$t('pagos-recaudados.folio')"
                                                clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th sortable v-show="columValues[1].value">
                                    <div class="align-items-c d-flex">
                                        <span class="open-filters" @click="headerFilters()"> {{$t('pagos-recaudados.user')}} </span>
                                        <strong class="float-r m-l-5">
                                            <SortIcon
                                                    @sortUp="setSortCollectedPayment('user.name', 'asc')"
                                                    @sortDown="setSortCollectedPayment('user.name', 'desc')"
                                            ></SortIcon>
                                        </strong>
                                    </div>
                                    <div
                                            class="filters"
                                            :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentUser"
                                                labelFor="Usuario"
                                                inputId="Usuario"
                                                @input="setFilterUser"
                                                :inputPlaceholder="$t('pagos-recaudados.user')"
                                                clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="" v-show="columValues[2].value">
                                    <div class="align-items-c d-flex">
                                        <span class="open-filters" @click="headerFilters()"> {{$t('pagos-recaudados.pay-concept')}} </span>
                                        <strong class="float-r m-l-5">
                                            <SortIcon
                                                    @sortUp="setSortCollectedPayment('paymentDetail.name', 'asc')"
                                                    @sortDown="setSortCollectedPayment('paymentDetail.name', 'desc')"
                                            ></SortIcon>
                                        </strong>
                                    </div>
                                    <div
                                            class="filters"
                                            :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentConceptName"
                                                labelFor="Concepto"
                                                inputId="Concepto"
                                                @input="setFilterConceptName"
                                                :inputPlaceholder="$t('pagos-recaudados.pay-concept')"
                                                clazz="noLabel "
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="" v-show="columValues[3].value">
                                    <div class="align-items-c d-flex">
                                        <span class="open-filters" @click="headerFilters()"> {{$t('pagos-recaudados.date')}} </span>
                                        <strong class="float-r m-l-5">
                                            <SortIcon
                                                    @sortUp="setSortCollectedPayment('date', 'asc')"
                                                    @sortDown="setSortCollectedPayment('date', 'desc')"
                                            ></SortIcon>
                                        </strong>
                                    </div>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                id="dpk-payDate"
                                                v-model="collectedPaymentFrom"
                                                labelFor="Fecha"
                                                inputId="Fecha"
                                                @input="setFilterDate"
                                                :inputPlaceholder="$t('Ingrese fecha')"
                                                clazz="noLabel "
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="" v-show="columValues[4].value">
                                    <div class="align-items-c d-flex justify-content-center">
                                        <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.status')}} </span>
                                        <strong class="float-r m-l-5">
                                            <SortIcon
                                                    @sortUp="setSortCollectedPayment('paymentDetail.paymentQuantity', 'asc')"
                                                    @sortDown="setSortCollectedPayment('paymentDetail.paymentQuantity', 'desc')"
                                            ></SortIcon>
                                        </strong>
                                    </div>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentUnits"
                                                labelFor="Estatus"
                                                inputId="Estatus"
                                                :inputPlaceholder="$t('pagos-recaudados.status')"
                                                clazz="noLabel "
                                                @input="setFilterUnits"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="" v-show="columValues[5].value">
                                    <div class="align-items-c d-flex justify-content-end">
                                        <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.amount')}} </span>
                                        <strong class="float-r m-l-5">
                                            <SortIcon
                                                    @sortUp="setSortCollectedPayment('dispersionDetail.totalWithoutCommision', 'asc')"
                                                    @sortDown="setSortCollectedPayment('dispersionDetail.totalWithoutCommision', 'desc')"
                                            ></SortIcon>
                                        </strong>
                                    </div>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentAmount"
                                                labelFor="Cantidad"
                                                inputId="Cantidad"
                                                :inputPlaceholder="$t('pagos-recaudados.amount')"
                                                clazz="noLabel "
                                                @input="setFilterAmount"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="columValues[6].value">
                                    <div class="align-items-c d-flex justify-content-end">
                                        <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.pay-type')}} </span>
                                        <strong class="float-r m-l-5">
                                            <SortIcon
                                                    @sortUp="setSortCollectedPayment('payType', 'asc')"
                                                    @sortDown="setSortCollectedPayment('payType', 'desc')"
                                            ></SortIcon>
                                        </strong>
                                    </div>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentAmount"
                                                labelFor="Tipo de Pago"
                                                inputId="Tipo de pago"
                                                :inputPlaceholder="$t('pagos-recaudados.pay-type')"
                                                clazz="noLabel "
                                                @input="setFilterPayType"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="columValues[7].value">
                                    <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.card')}} </span>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentLast4"
                                                labelFor="Tarjeta"
                                                inputId="Tarjeta"
                                                :inputPlaceholder="$t('pagos-recaudados.card')"
                                                clazz="noLabel "
                                                @input="setFilterLast4"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="columValues[8].value">
                                    <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.device')}} </span>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentSN"
                                                labelFor="Terminal Id"
                                                inputId="Terminal Id"
                                                :inputPlaceholder="$t('pagos-recaudados.device')"
                                                clazz="noLabel "
                                                @input="setFilterSerialNumber"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="columValues[9].value">
                                    <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.authcode')}} </span>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput
                                                v-model="collectedPaymentAuthCode"
                                                labelFor="Código Aut"
                                                inputId="Código Aut"
                                                :inputPlaceholder="$t('pagos-recaudados.authcode')"
                                                clazz="noLabel "
                                                @input="setFilterAuthCode"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="columValues[10].value">
                                    <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.tip')}} </span>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                    </div>
                                </b-th>
                                <b-th class="text-align-c" v-show="columValues[11].value">
                                    <span class="open-filters text-center" @click="headerFilters()"> {{$t('pagos-recaudados.see')}} </span>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                    </div>
                                </b-th>
                                <b-th class="row-options">
                                    <div class="vertical-center">
                                        <div>
                                            <a id="lnk-downloadPayments" class="opt-row" tabindex
                                               @click="downLoadMultipleDocuments">
                                                <i aria-hidden="true" class="banana banana-download"></i>
                                                {{$tc('pagos-recaudados.download-recipies', 1)}}
                                            </a>
                                            <a id="lnk-cancelDownPay" class="opt-row" tabindex
                                               @click="selectAll(false)"> Cancelar </a>
                                        </div>
                                    </div>
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="transactions.length === 0 && !loadingCollectedPayments && (getFiltersCount > 0 || searchText !== '')">
                            <b-tr role="row" class="b-table-busy-slot">
                                <b-td colspan="8" role="cell" class="">
                                    <EmptyState
                                            emptyStateDefault size="sm"
                                            title="No se encontraron resultados con la búsqueda actual">
                                        <button @click="resetAllFilters" type="button"
                                                class="button xs button-stroke button-accent1 m-0-auto m-t-30">Reiniciar
                                            filtros
                                        </button>
                                    </EmptyState>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if="transactions.length > 0">
                            <b-tr
                                v-for="(transaction) in transactions"
                                :class="transaction.selectedItem ? 'b-table-row-selected table-active' : ''"
                                v-bind:key="transaction._id"
                            >
                                <b-td class>
                                    <div class="d-flex align-items-c">
                                        <router-link to class="c-primary_1">
                                            <!-- this v-show hides the folio rows-->
                                            <strong v-show="columValues[0].value">{{transaction.number ? transaction.number : "2000"}}</strong>
                                        </router-link>
                                    </div>
                                </b-td>
                                <b-td v-show="columValues[1].value" class="max-w-150px">
                                    {{username(transaction.user)}}
                                </b-td>
                                <b-td v-show="columValues[2].value" class="">
                                    {{getPaymentName(transaction)}}
                                </b-td>
                                <b-td v-show="columValues[3].value" class="">
                                    {{formatDate(transaction.date, "DD/MM/YYYY - HH:mm")}}
                                </b-td>
                                <b-td v-show="columValues[4].value" class="text-center">
                                    <Badge v-if="transaction.status === 'SUCCESSFUL'"
                                            type="badge-success"
                                            :text="$t('pagos-recaudados.SUCCESSFUL')"
                                    ></Badge>
                                    <div class="d-flex justify-content-center">
                                        <Badge v-if="transaction.status === 'NOT_SUCCESSFUL'"
                                                type="badge-accent_1"
                                                :text="$t('pagos-recaudados.NOT_SUCCESSFUL')"
                                        >
                                        </Badge>
                                        <span v-if="transaction.status === 'NOT_SUCCESSFUL'" :id="`${transaction._id}-tooltip`" class="help-info m-l-5">
                                            <i aria-hidden="true" class="banana banana-question-circle1"></i>
                                            <b-tooltip
                                                :target="`${transaction._id}-tooltip`"
                                                placement="top"
                                                custom-class="white-tooltip"
                                            >
                                                <div>
                                                    <p class="m-b-0">{{getMessageErrorDescription(transaction.apiDetail.transaction.responseCode)}}</p>
                                                </div>
                                            </b-tooltip>
                                        </span>
                                    </div>
                                    <Badge v-if="transaction.status === 'REFUND'"
                                            type="badge-accent_1"
                                            :text="$t('pagos-recaudados.REFUND')"
                                    ></Badge>
                                    <Badge v-if="transaction.status === 'FRAUD'"
                                            type="badge-error"
                                            :text="$t('pagos-recaudados.FRAUD')"
                                    ></Badge>
                                    <Badge v-if="transaction.status === 'CHARGEBACK'"
                                            type="badge-error"
                                            :text="$t('pagos-recaudados.CHARGEBACK')"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-right c-accent_2 f-14 f-bold" v-show="columValues[5].value" v-if="transaction.transaction && transaction.transaction.apiDetail">
                                    {{transaction.transaction.dispersionDetail.totalWithoutCommision | currency}}
                                    (MXN)
                                </b-td>
                                <b-td class="text-right c-accent_2 f-14 f-bold" v-show="columValues[5].value" v-else>
                                    {{transaction.total | currency}}
                                    ({{transaction.orderParams ? transaction.orderParams.currency : "MXN"}})
                                </b-td>
                                <b-td class="d-flex justify-content-center" v-show="columValues[6].value">
                                    <i
                                        v-if="transaction.payType === 'T'"
                                        aria-hidden="true"
                                        class="banana banana-device-smartphone banana-2x"
                                    ></i>
                                    <i
                                        v-else
                                        aria-hidden="true"
                                        class="banana banana-foul banana-2x"
                                    ></i>
                                </b-td>
                                <b-td class="text-right c-accent_2 f-14 f-bold" v-show="columValues[7].value">
                                    {{transaction.apiDetail && transaction.apiDetail.transaction && transaction.apiDetail.transaction.last4 ?
                                    transaction.apiDetail.transaction.last4 : "N/A"}}
                                </b-td>
                                <b-td class="text-right c-accent_2 f-14 f-bold" v-show="columValues[8].value">
                                    {{transaction.apiDetail && transaction.apiDetail.transaction && transaction.apiDetail.transaction.deviceSerialNumber ?
                                    transaction.apiDetail.transaction.deviceSerialNumber : "N/A"}}
                                </b-td>
                                <b-td class="text-right c-accent_2 f-14 f-bold" v-show="columValues[9].value">
                                    {{transaction.apiDetail && transaction.apiDetail.transaction && transaction.apiDetail.transaction.authCode ?
                                    transaction.apiDetail.transaction.authCode : "N/A"}}
                                </b-td>
                                <b-td class="text-right c-accent_2 f-14 f-bold" v-show="columValues[10].value"
                                v-if="transaction.apiDetail && transaction.apiDetail.transaction && transaction.apiDetail.transaction.tipAmount">
                                    {{transaction.apiDetail.transaction.tipAmount | currency}}
                                </b-td>
                                <b-td class="text-right c-accent_2 f-14 f-bold" v-show="columValues[10].value" v-else>
                                    {{0 | currency}}
                                </b-td>
                                <b-td class=" text-center" v-show="columValues[11].value">
                                    <button
                                        type="button"
                                        class="button-circle button-ghost button-info_text m-auto sm"
                                        @click="openTransactionInfoModal(transaction)"
                                    >
                                        <i aria-hidden="true" class="banana banana-visibility-on"></i>
                                    </button>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </CardSimple>

            <div class="pagination-custom" v-if="!loadingCollectedPayments && totalDocs > 0">
                <span id="sct-payPagCnt" class="pagination-count">
                    {{totalDocs}} {{transactions.length > 1 ? $tc('pagos-recaudados.title', 2) : $tc('pagos-recaudados.title', 1)}}
                </span>
                <b-pagination
                        id="ul-payColPag"
                        v-model="localPage"
                        :total-rows="totalDocs"
                        :per-page="perPage"
                        aria-controls="my-table"
                        next-text=""
                        prev-text=""
                        hide-goto-end-buttons
                        @change="pageChange"
                ></b-pagination>
            </div>
        </div>

        <ModalTransactionInfo
            modalId="TransactionsListModalTransactionInfo"
            ref="ModalTransactionInfo"
            @refreshList="refundFinished"
        ></ModalTransactionInfo>

    </div>
</template>

<script>
    import catalog from '@/mixins/catalog.mixin';
    import {
        STORE as TransactionStore,
        GET_ACTUAL_TRANSACTION,
        SET_ACTUAL_TRANSACTION,
    } from '@/store/modules/payments/transactionHistory.types';
    import {
        GET_DOCS,
        GET_DOCS_LENGTH,
        GET_CURRENT_PAGE,
        GET_OBJECT_PAGE,
        SET_CURRENT_PAGE,
        SET_SELECTED_ITEM_VALUE_ON_INDEX,
        SET_ADD_TO_SELECTED_IDS,
        REMOVE_FROM_SELECTED_IDS,
        SET_PER_PAGE,
        SET_SEARCH,
        GET_LOADING_ITEMS,
        SET_LOADING_ITEMS,
        SET_FATHER_FILTER,
        GET_FILTERS_COUNT,
        SET_DOCS
    } from '@/store/modules/base/catalog.types';
    import {
        STORE as AccessConfigStore,
        GET_CURRENT_ORGANIZATION,
    } from '@/store/modules/accessconfig/accessConfig.types';
    import transactionHistoryApi from "@/api/payments/transactionHistory.api";
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import EmptyStateGroupImg from '@/assets/images/Emptystates/emptystate-collectedPayment.svg';
    import EmptyStateBox from '@/assets/images/Emptystates/emptystate-search.svg';
    import Badge from "@/components/Badge";
    import BasicInput from "@/components/BasicInput.vue";
    import SearchBar from '@/components/SearchBar.vue';
    import {mapGetters} from 'vuex';
    import utils from '@/common/utils';
    import base from '@/api/base.api';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import SortIcon from '@/components/SortIcon.vue';
    import async from 'async';
    import ModalTransactionInfo from '@/components/ModalTransactionInfo.vue';
    import {getCode} from '@/enums/errorsAgregador.enum';

    const PAGOS_RECAUDADOS_RECEIPT_FAIL_KEY = 'pagos-recaudados.reciept-report-fail';

    const catalogTransactions = catalog.configure({
        storeModule: TransactionStore,
        docName: "Transactions",
        manualLoad: true
    });
    const unknowCardString = 'pagos-recaudados.unknow-card';

    export default {
        name: "CollectedPayment",
        components: {
            FloatingTitleLayout,
            EmptyState,
            CardSimple,
            Badge,
            BasicInput,
            SearchBar,
            ModalTransactionInfo,
            SortIcon,
        },
        mixins: [catalogTransactions, ToastSnotify],
        data() {
            return {
                localPage: 1,
                filtersHeader: false,
                selectMode: 'multi',
                loadingReport: false,
                EmptyStateGroupImg: EmptyStateGroupImg,
                EmptyStateBox: EmptyStateBox,
                multiSelectDocuments: false,
                multiSelectDocumentsAfterSelect: false,
                selectedRowsIndex: [],
                collectedPaymentFolio: '',
                collectedPaymentLast4: '',
                collectedPaymentSN: '',
                collectedPaymentAuthCode: '',
                collectedPaymentUser: '',
                collectedPaymentConceptName: '',
                collectedPaymentFrom: '',
                collectedPaymentUnits: '',
                collectedPaymentAmount: '',
                searchText: "",
                baseUrl: base.baseUrl,
                columValues: [
                    {
                        "label": this.$i18n.t('pagos-recaudados.folio'),
                        "name": "folio",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.user'),
                        "name": "user",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.pay-concept'),
                        "name": "payment-concept",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.date'),
                        "name": "date",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.units'),
                        "name": "units",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.amount'),
                        "name": "totalAmount",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.pay-type'),
                        "name": "payType",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.card'),
                        "name": "card",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.device'),
                        "name": "device",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.device'),
                        "name": "authcode",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.tip'),
                        "name": "tip",
                        "value": true
                    },
                    {
                        "label": this.$i18n.t('pagos-recaudados.see'),
                        "name": "detail",
                        "value": true
                    },
                ],
                currentTransactionWrapperThingy: {},
                timeoutFilters: null,
            }
        },
        methods: {
            getMessageErrorDescription(responseCode) {
                const error = getCode(responseCode);
                if(error) {
                    return error.message;
                }

                return 'Error en transacción, intente de nuevo o bien utilice otra tarjeta.';
            },
            setSortCollectedPayment(sort, typeSort) {
                this.$store.dispatch(`${TransactionStore}/setSortCollectedPayment`, {
                    sort,
                    typeSort
                });
            },
            openTransactionInfoModal(order){
                const showButtons = order.transaction && (order.status === 'SUCCESSFUL' || order.status === 'REFUND');
                let transaction;
                if(order.status === "REFUND") {
                  transaction = this._formatTransactionRefund(order)
                } else {
                  transaction = order.transaction || this._formatTransaction(order);
                }

                transaction.originalOrder = order._id;
                try {
                    this.$refs.ModalTransactionInfo.show(transaction, order.status, !showButtons);
                } catch(error) {
                    setTimeout(() =>{
                        this.$refs.ModalTransactionInfo.show(transaction, order.status, !showButtons);
                    },1000);
                }
            },
            _formatTransactionRefund(order) {
                return {
                    _id: order.refunds.transaction.transactionId,
                    status: order.status,
                    number: order.number,
                    total: parseFloat(order.refunds?.transaction.amount),
                    payHolder: order.payHolder,
                    date: Date(order.refunds?.transaction.transactionTime),
                    dispersionDetail: {},
                    paymentDetail: {
                        name: `Devolución ${order.paymentElement?.name}`,
                        subTotal: parseFloat(order.refunds?.transaction.amount),
                        additionalNotes: order.paymentElement?.additionalNotes,
                        additionalNotesConfig: order.paymentElement?.additionalNotesConfig,
                        totalDiscounts: order.paymentElement?.totalDiscounts,
                        totalWithoutCommision: parseFloat(order.refunds?.transaction.amount),
                        clasification: order.paymentElement?.clasification,
                        organizationBankCommision: order.paymentElement?.organizationBankCommision,
                        commision: order.paymentElement?.commision,
                        totalTaxes: order.paymentElement?.totalTaxes,
                        type: order.paymentElement?.type,
                    },
                    user: {
                        email: order.user.email,
                        name: order.user.name,
                        lastName: order.user.lastName,
                        secondLastName: order.user.secondLastName,
                        phone: order.user.phone,
                    },
                    apiDetail: {
                        totalWithoutCommision: parseFloat(order.refunds?.transaction.amount),
                        transaction: {
                            last4: order.refunds?.transaction?.last4,
                            first6: order.refunds?.transaction?.first6,
                            authCode: order.refunds?.transaction?.authCode,
                            affiliation: order.refunds?.transaction?.affiliation,
                            paymentType: order.refunds?.transaction?.paymentType,
                            accountType: order.refunds?.transaction?.accountType,
                            cardType: order.refunds?.transaction?.cardType,
                            operationType: order.refunds?.transaction?.operationType
                        },
                    }
                };
            },
             _formatTransaction(order) {
                return {
                    number: order.number,
                    total: order.paymentElement?.total,
                    payHolder: order.payHolder,
                    date: order.date,
                    dispersionDetail: {},
                    status: order.status,
                    paymentDetail: {
                        name: order.paymentElement?.name,
                        subTotal: order.paymentElement?.subTotal,
                        additionalNotes: order.paymentElement?.additionalNotes,
                        additionalNotesConfig: order.paymentElement?.additionalNotesConfig,
                        totalDiscounts: order.paymentElement?.totalDiscounts,
                        totalWithoutCommision: order.paymentElement?.totalWithoutCommision,
                        clasification: order.paymentElement?.clasification,
                        organizationBankCommision: order.paymentElement?.organizationBankCommision,
                        commision: order.paymentElement?.commision,
                        totalTaxes: order.paymentElement?.totalTaxes,
                        type: order.paymentElement?.type,
                    },
                    user: {
                        email: order.user.email,
                        name: order.user.name,
                        lastName: order.user.lastName,
                        secondLastName: order.user.secondLastName,
                        phone: order.user.phone,
                    },
                    apiDetail: {
                        totalWithoutCommision: order.paymentElement?.total,
                        transaction: {
                            last4: order.apiDetail?.transaction?.last4,
                            first6: order.apiDetail?.transaction?.first6,
                            authCode: order.apiDetail?.transaction?.authCode,
                            affiliation: order.apiDetail?.transaction?.affiliation,
                            paymentType: order.apiDetail?.transaction?.paymentType,
                            accountType: order.apiDetail?.transaction?.accountType,
                            cardType: order.apiDetail?.transaction?.cardType,
                            operationType: order.apiDetail?.transaction?.operationType
                        },
                    }
                };
            },
            showDropdown() {
                document
                    .getElementById("tableOne")
                    .classList.add("dropdown-table-overflow");
            },
            hideDropdown() {
                document
                    .getElementById("tableOne")
                    .classList.remove("dropdown-table-overflow");
            },
            headerFilters() {
                if (this.filtersHeader) {
                    this.$store.dispatch(`${TransactionStore}/resetFiltersCollectedPayment`);
                }
                this.filtersHeader = !this.filtersHeader;
            },
            resetAllFilters() {
                this.$refs.searchBar.resetInput();
                this.collectedPaymentFolio = '';
                this.collectedPaymentLast4 = '';
                this.collectedPaymentSN = '';
                this.collectedPaymentAuthCode = '';
                this.collectedPaymentUser = '';
                this.collectedPaymentConceptName = '';
                this.collectedPaymentFrom = '';
                this.collectedPaymentUnits = '';
                this.collectedPaymentAmount = '';
                this.$store.dispatch(`${TransactionStore}/resetFiltersCollectedPayment`);
            },
            pageChange(page) {
                this.$store.commit(`${TransactionStore}/${SET_LOADING_ITEMS}`, true);
                this.$store.commit(`${TransactionStore}/${SET_DOCS}`, []);
                this.localPage = page;
                this.$store.commit(`${TransactionStore}/${SET_CURRENT_PAGE}`, page);
                this.getListOfDocuments();
            },
            changeListLimit: utils.debounce(function (limit) {
                if (limit <= 0) {
                    this.displayNotificationWarning(this.$i18n.t('general.list-limit-warn'));
                    this.$store.commit(`${TransactionStore}/${SET_PER_PAGE}`, 10);
                    this.getListOfDocuments();
                } else {
                    this.$store.commit(`${TransactionStore}/${SET_PER_PAGE}`, limit);
                    this.getListOfDocuments();
                }
            }, 200, false),
            searchTextChange(searchText) {
                this.$store.commit(`${TransactionStore}/${SET_LOADING_ITEMS}`, true);
                utils.debounceFixed(() => {
                    this.searchText = searchText;
                    this.$store.commit(`${TransactionStore}/${SET_SEARCH}`, searchText);
                    this.getListOfDocuments();
                })
            },
            setFilterFolio(folio) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    await this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        folio: folio
                    });
                }, 1500);
            },
            setFilterUser(user) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        user: user
                    });
                }, 1500);
            },
            setFilterConceptName(conceptName) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        conceptName: conceptName
                    });
                }, 1500);
            },
            setFilterUnits(units) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        status: units
                    });
                }, 1500);
            },
            setFilterAmount(amount) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        amount: amount
                    });
                }, 1500);
            },
            setFilterPayType(payType) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        payType
                    });
                }, 1500);
            },
            setFilterLast4(last4) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        last4
                    });
                }, 1500);
            },
            setFilterSerialNumber(deviceSerialNumber) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        deviceSerialNumber
                    });
                }, 1500);
            },
            setFilterAuthCode(authCode) {
                clearTimeout(this.timeoutFilters);
                this.timeoutFilters = setTimeout(async () => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        authCode
                    });
                }, 1500);
            },
            setFilterDate(date) {
                utils.debounceFixed(() => {
                    this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                        date
                    });
                })
            },
            formatDate(date, format) {
                return utils.toFormatDate(date, format);
            },
            checkIfBravoCard(first6){
                if(first6){
                    return first6.indexOf('9001') !== -1;
                }
                return false;
            },
            selectActualPago(transactionWrapperThingy) {
                this.currentTransactionWrapperThingy = transactionWrapperThingy;
                const transaction = transactionWrapperThingy.transaction;
                let cardType;
                let brand
                if(transaction){
                    switch(transaction.payHolder){
                    case "CONEKTA":
                    case undefined:
                        cardType = this.loadCardTypeAndBrandToConekta(transaction);
                        brand = transaction.apiDetail.charges._json.data[0].payment_method.brand.toLowerCase();
                        break;
                    case "BZPAY":
                    case "BL":
                    case "PAGANDO_GIFT":
                    case "BANREGIO":
                        cardType = transaction.apiDetail.transaction.paymentType.toLowerCase();
                        brand = this.checkIfBravoCard(transaction.apiDetail.transaction.first6) ? 'bravos' : transaction.apiDetail.transaction.cardType.toLowerCase();
                        // Translate card type
                        if (cardType === "cc" || cardType === "CREDIT") {
                            cardType = this.$i18n.t('pagos-recaudados.credit-card');
                        } else if(cardType === 'pagando_electronic_gift'){
                            cardType = this.$i18n.t('pagos-recaudados.pagando-electronic-card');
                        } else if(cardType === 'DEBIT'){
                            cardType = "TARJETA DE DEBITO";
                        } else {
                            cardType = this.$i18n.t(unknowCardString);
                        }
                        break;
                    default:
                        cardType = this.$i18n.t(unknowCardString);
                    }
                }
                switch (brand) {
                    case 'visa':
                        brand = 'visa';
                        break;
                    case 'mastercard':
                        brand = 'mastercard';
                        break;
                    case 'american_express':
                        brand = 'amex';
                        break;
                    case 'pagando_electronic_gift':
                        brand = 'pagando_electronic_gift';
                        break;
                    case 'bravos':
                        brand = 'bravo-card';
                        break;
                    default:
                        brand = undefined;
                }
                transaction.cardType = cardType;
                transaction.brandType = brand;
                this.$store.commit(`${TransactionStore}/${SET_ACTUAL_TRANSACTION}`, transaction);
            },
            loadCardTypeAndBrandToConekta(transaction){
                let cardType;
                if(transaction.apiDetail
                    && transaction.apiDetail.charges
                    && transaction.apiDetail.charges._json
                    && transaction.apiDetail.charges._json.data
                    && transaction.apiDetail.charges._json.data[0].payment_method
                    && transaction.apiDetail.charges._json.data[0].payment_method.type) {
                    cardType = transaction.apiDetail.charges._json.data[0].payment_method.type.toLowerCase();
                    // Translate card type
                    switch (cardType) {
                        case 'credit':
                            cardType = this.$i18n.t('pagos-recaudados.credit-card');
                            break;
                        case 'debit':
                            cardType = this.$i18n.t('pagos-recaudados.debit-card');
                            break;
                        default:
                            cardType = this.$i18n.t(unknowCardString);
                    }
                }
                return cardType;
            },
            clearActualTransaction() {
                this.$store.commit(`${TransactionStore}/${SET_ACTUAL_TRANSACTION}`, null);
                this.$refs['modal-show-payment'].hideModal();
            },
            /**
             * Obtiene el tipo de pago como texto legible
             * @param {Transaction} transaction Transacción
             */
            getPaymentTypeAsStr(transaction) {
                if (transaction && transaction.paymentType) {
                    if (transaction.paymentType === "DEBT") {
                        return this.$i18n.t('payments.select-debt');
                    } else if (transaction.paymentType === "WS_PAYMENT") {
                        return this.$i18n.t('payments.select-webservice');
                    }
                }
                return this.$i18n.tc('payments.long-title', 2);
            },
            getPaymentName({transaction, paymentElement, status}) {
                if(transaction?.paymentType === 'DEBT') {
                    return transaction.paymentDetail.debt.name;
                }
                if(transaction?.paymentType === 'EXPRESS_PAYMENT') {
                    return `${ transaction?.paymentDetail?.name} ${transaction?.paymentDetail?.concept?.name || ''}`;
                }
                if(transaction?.paymentDetail?.name) {
                    return transaction?.paymentDetail?.name;
                }
                if(paymentElement?.name) {
                    return paymentElement?.name;
                }

                // Retorna un concepto default solo si su traducción existe
                if(status && this.$i18n.te(`pagos-recaudados.default-concepts.${status}`)) {
                    return this.$i18n.t(`pagos-recaudados.default-concepts.${status}`);
                }

                return '-';
            },
            /**
             * Obtiene los campos de requeridos del pago correspondiente de la transaction
             * @param {Transaction} transaction Transacción
             */
            getPaymentFields(transaction) {
                // "Cache" to avoid overflow
                if (transaction.fieldsToShow) {
                    return transaction.fieldsToShow;
                }
                let fields = [];
                if (transaction && transaction.paymentDetail && transaction.paymentDetail.fields && transaction.paymentDetail.fields.length > 0) {
                    fields = transaction.paymentDetail.fields;
                }
                if (transaction.paymentType === 'WS_PAYMENT' && transaction.fields && transaction.fields.length > 0) {
                    fields = transaction.fields;
                }
                transaction.fieldsToShow = fields;
                const arrayFields = [];
                let arrayObject = [];

                transaction.fieldsToShow.forEach((fieldToShow) => {
                    if (arrayObject.length > 1) {
                        arrayObject = [];
                    }
                    arrayObject.push({name: fieldToShow.name, value: fieldToShow.value})
                    if (arrayObject.length === 2) {
                        arrayFields.push(arrayObject)
                    }
                })
                if (arrayObject.length === 1) {
                    arrayFields.push(arrayObject)
                }
                return arrayFields;
            },
            /**
             * Regresa las referencias del servicio pagado de la transacción
             * @param {Transaction} transaction Transacción
             */
            getPaymentReferences(transaction) {
                let references = [];
                if (transaction && transaction.paymentDetail && transaction.paymentDetail.references && transaction.paymentDetail.references.length > 0) {
                    references = transaction.paymentDetail.references;
                }
                const arrayFields = [];
                let arrayObject = [];

                // eslint-disable-next-line sonarjs/no-identical-functions
                references.forEach((fieldToShow) => {
                    if (arrayObject.length > 1) {
                        arrayObject = [];
                    }
                    arrayObject.push({name: fieldToShow.name, value: fieldToShow.value})
                    if (arrayObject.length === 2) {
                        arrayFields.push(arrayObject)
                    }
                })
                if (arrayObject.length === 1) {
                    arrayFields.push(arrayObject)
                }
                return arrayFields;
            },
            dateRangeChange(dateRange) {
                this.$store.dispatch(`${TransactionStore}/setFilterList`, {
                    from: dateRange.startDate,
                    to: dateRange.endDate
                });
                this.getListOfDocuments();
            },
            excelReport() {
                //  TODO: Ver por que se hacia esto en la otra vista
                //  if(paginate.dateRange){
                //     let fromFormatted = $filter('date')(new Date(paginate.dateRange.substring(0, 10)).valueOf(), 'MM-dd-yyyy');
                //     params += `&from=${fromFormatted}`;
                //     let plusOneDay = new Date(paginate.dateRange.substring(14, 24).valueOf());
                //     plusOneDay.setDate(plusOneDay.getDate() + 1);
                //     let toFormatted = $filter('date')(plusOneDay, 'MM-dd-yyyy');
                //     params += `&to=${toFormatted}`;
                // }
                this.loadingReport = true;
                const _this = this;
                const functionSuccess = function (response) {
                    if (response && response.data) {
                        const blob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                        const a = document.createElement("a");
                        a.href = URL.createObjectURL(blob);
                        a.download = `pagando-pagos-recaudados-${new Date()}.xlsx`;
                        document.body.appendChild(a);
                        a.click();
                        _this.loadingReport = false;
                    } else {
                        _this.displayNotificationWarning(this.$i18n.t("pagos-recaudados.excel-report-fail"));
                        _this.loadingReport = false;
                    }
                }

                const functionError = function () {
                    _this.loadingReport = false;
                    _this.displayNotificationWarning(this.$i18n.t("pagos-recaudados.excel-report-fail"));
                }

                const functions = {
                    success: functionSuccess,
                    error: functionError
                }

                this.$store.dispatch(`${TransactionStore}/downLoadExcel`, functions)
            },
            selectAll(value) {
                for (let i = 0; i < this.transactions.length; i++) {
                    const data = {
                        index: i,
                        checkValue: value
                    }
                    this.selectIndex(data);
                    const ref = `CHECKBOX_CATALOG_${i}`;
                    this.$refs[ref][0].checkStoreValue(value);
                }
                this.$refs.CHECKBOX_CATALOG_ALL.checkStoreValue(value);
                this.$refs.CHECKBOX_CATALOG_ALL_AFTER.checkStoreValue(value);
            },
            selectIndex(data) {
                if (data.checkValue === true) {
                    if (this.selectedRowsIndex.indexOf(data.index) === -1) {
                        this.selectedRowsIndex.push(data.index);
                        this.$store.commit(`${TransactionStore}/${SET_ADD_TO_SELECTED_IDS}`, this.transactions[data.index]._id.toString());
                    }
                } else {
                    this.selectedRowsIndex.splice(this.selectedRowsIndex.indexOf(data.index), 1);

                    this.$store.commit(`${TransactionStore}/${REMOVE_FROM_SELECTED_IDS}`, this.transactions[data.index]._id.toString());
                }
                if (this.selectedRowsIndex.length > 1) {
                    this.multiSelectDocuments = true;
                    this.multiSelectDocumentsAfterSelect = true;
                } else {
                    this.multiSelectDocuments = false;
                    this.multiSelectDocumentsAfterSelect = false;
                }
                const object = {
                    selectedItem: data.checkValue
                }
                const finalData = {
                    object: object,
                    index: data.index
                }
                this.$store.commit(`${TransactionStore}/${SET_SELECTED_ITEM_VALUE_ON_INDEX}`, finalData);
            },
            downLoadMultipleDocuments() {
                const receiptsToDonwload = []
                for (const index of this.selectedRowsIndex) {
                    const recipieToDownload = this.transactions[index];
                    if (recipieToDownload !== undefined) {
                        receiptsToDonwload.push(function (_callback) {
                            transactionHistoryApi.download(recipieToDownload).then(data => {
                                _callback(null, data.data);
                            }).catch((error) => {
                                _callback(error, null)
                            });
                        });
                    } else {
                        this.displayNotificationWarning(this.$i18n.t(PAGOS_RECAUDADOS_RECEIPT_FAIL_KEY));
                    }
                }
                async.parallel(
                    receiptsToDonwload,
                    (error, results) => {
                        if (error) {
                            this.displayNotificationWarning(this.$i18n.t(PAGOS_RECAUDADOS_RECEIPT_FAIL_KEY));
                        } else {
                            if (results) {
                                for (let i = 0; i < results.length; i++) {
                                    setTimeout(function () {
                                        const wnd = window.open("about:blank", `receipt${i}`, "_blank");
                                        wnd.document.write(results[i]);
                                    }, 500)
                                }
                            } else {
                                this.displayNotificationWarning(this.$i18n.t(PAGOS_RECAUDADOS_RECEIPT_FAIL_KEY));
                            }
                        }
                    });
            },
            downloadReceipt(order) {
                transactionHistoryApi.downloadPDF(order._id).then((data) => {
                    if(!data.error){
                        window.open(data.object.renderUrl, "_blank");
                    }
                }).catch(() => {
                    this.displayNotificationWarning(this.$i18n.t(PAGOS_RECAUDADOS_RECEIPT_FAIL_KEY));
                });
            },
            startRefund(wrapperThingy) {
                if (wrapperThingy.status === 'SUCCESSFUL') {
                    this.$bvModal.hide('modal-default')
                    this.$refs.modalRefund.showRefundModal(wrapperThingy.transaction);
                    this.clearActualTransaction();
                }
            },
            refundFinished() {
                this.getListOfDocuments();
            },
            transactionUser(transaction) {
                if (transaction.user) {
                    return `${transaction.user.name || ''} ${transaction.user.lastName || ''}`;
                }
                return 'Usuario de terminal';
            },
            transactionAmount(transaction) {
                if (
                    transaction.transaction
                    && transaction.transaction.dispersionDetail
                    && transaction.transaction.dispersionDetail.totalWithoutCommision
                ) {
                    return transaction.transaction.dispersionDetail.totalWithoutCommision;
                } else if (
                    transaction.total
                ) {
                    return transaction.total;
                }
                return 0;
            },
            transactionCurrency(transaction) {
                if (
                    transaction.transaction
                    && transaction.transaction.apiDetail
                    && transaction.transaction.apiDetail.transaction
                    && transaction.transaction.apiDetail.transaction.currency
                ) {
                    return transaction.transaction.apiDetail.transaction.currency;
                } else if (
                    transaction.transaction
                    && transaction.transaction.apiDetail
                    && transaction.transaction.apiDetail._json
                    && transaction.transaction.apiDetail._json.currency
                ) {
                    return transaction.transaction.apiDetail._json.currency;
                } else if (
                    transaction.orderParams
                    && transaction.orderParams.currency
                ) {
                    return transaction.orderParams.currency;
                }
                return 'MXN';
            },
            transactionTip(transaction) {
                if (
                    transaction.apiDetail
                    && transaction.apiDetail.transaction
                    && transaction.apiDetail.transaction.tipAmount
                ) {
                    return transaction.apiDetail.transaction.tipAmount;
                }
                return 0;
            },
            username(user) {
                if (user?.name) {
                    return `${user.name} ${user.lastName || ''}`;
                }
                return '-';
            }
        },
        mounted() {
            this.$store.commit(`${TransactionStore}/${SET_FATHER_FILTER}`, this.currentOrganization._id);
            this.$store.commit(`${TransactionStore}/${SET_CURRENT_PAGE}`, 1);
            this.getListOfDocuments();
        },
        computed: {
            ...mapGetters(TransactionStore, {
                transactions: GET_DOCS,
                totalDocs: GET_DOCS_LENGTH,
                currentPage: GET_CURRENT_PAGE,
                perPage: GET_OBJECT_PAGE,
                loadingCollectedPayments: GET_LOADING_ITEMS,
                actualTransaction: GET_ACTUAL_TRANSACTION,
                getFiltersCount: GET_FILTERS_COUNT
            }),
            ...mapGetters(AccessConfigStore, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
        },
        watch: {
            currentOrganization: {
                handler() {
                    this.$store.commit(`${TransactionStore}/${SET_FATHER_FILTER}`, this.currentOrganization._id);
                    this.getListOfDocuments();
                }
            }
        }
    };
</script>
