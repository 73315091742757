<template>
    <div class="row">
        <div class="col-12">
            <FloatingTitleLayout :msgSmall="$t('payment-template.control-panel')" :title="$t('payment-template.title')">
                <template v-slot:buttons v-if="!loading && total > 0">
                    <button type="button" class="button xs button-stroke button-accent1"
                            @click="$bvModal.show(modalHelpId)"> {{$t('payment-template.button.help')}}
                    </button>
                    <button type="button" class="button xs button-stroke button-accent1 m-l-20"
                            @click="$bvModal.show(modalDeleteAllId)"> {{$t('payment-template.button.cancel')}}
                    </button>
                    <button type="button" class="button xs button-filled button-accent1 m-l-20"
                            @click="showModalConfirmAllPayments"> {{$t('payment-template.button.confirm-all')}}
                    </button>
                </template>
            </FloatingTitleLayout>
        </div>

        <div class="col-12 col-md-5 col-lg-4 m-b-40">
            <CardSimple clazz="p-30 h-100 m-b-0" hideTitle>
                <div class="row">
                    <div class="col-12">
                        <label class="f-14 c-title body-font-regular d-block m-b-15">
                            {{$t('payment-template.load-template-payment')}} </label>
                        <UploadBox clazzContainer="m-b-0" ref="uBox" :disableUploading="templateProcessed"
                                   @click="showModalUpload" showLoadBox fileType="no-img-back"
                                   inputId="uploadTemplateFile" :imgUrlUpload="ImageBoxUpload" @change="fileUploadXls"
                                   inputFileAccept=".xlsx" v-if="!xlsFile.length || templateProcessed">
                            <template v-slot:noteText>
                                <SimpleNote clazz="info m-t--10 m-b-0">
                                    <p class="m-b-0"> {{$t('payment-template.upload-box.note')}} </p>
                                </SimpleNote>
                            </template>
                        </UploadBox>
                        <FileUploadedInfo icon="banana-document-normal" :fileName="fileName" :fileSize="fileSize"
                                          v-if="xlsFile.length && !templateProcessed"
                                          @click="deleteFileXls"></FileUploadedInfo>
                        <button type="button" class="button xs button-filled button-accent1 w-100 m-t-60"
                                @click="startProcessTemplate" v-if="xlsFile.length && !templateProcessed">
                            {{$t('payment-template.button.process-template')}}
                        </button>
                    </div>
                </div>

            </CardSimple>
        </div>

        <div class="col-12 col-md-7 col-lg-8 m-b-40">
            <CardSimple clazz="p-30 h-100 m-b-0" hideTitle clazzBody="h-100">
                <div class="row">
                    <div class="col-12">
                        <EmptyState
                                emptyStateDefault
                                :imgUrl="ImagenEmptyStateDefault"
                                size="xs"
                                v-if="!loadingProcessTemplate && total === 0 && !templateProcessed">
                            <label>{{$t('payment-template.empty-state.load-your-template.label')}}</label>
                            <p>{{$t('payment-template.empty-state.load-your-template.p')}}</p>
                        </EmptyState>
                    </div>

                    <LoadingBar v-if="xlsFile.length && loadingProcessTemplate">
                        <p><strong v-html="$t('payment-template.loading-process-template')"></strong></p>
                    </LoadingBar>

                    <div class="pagos-plantilla" v-if="!loadingProcessTemplate && total > 0 || templateProcessed">
                        <span class="default">
                            <label> {{$t('payment-template.charged-payments')}} </label>
                            <p> {{records}}  </p>
                            <i aria-hidden="true" class="banana banana-arrow-up"></i>
                        </span>
                        <span class="success">
                            <label> {{$t('payment-template.valid-payments')}} </label>
                            <p> {{valids}} </p>
                            <i aria-hidden="true" class="banana banana-check-normal"></i>
                        </span>
                        <span class="error">
                            <label> {{$t('payment-template.error-payments')}} </label>
                            <p> {{records - valids}} </p>
                            <i aria-hidden="true" class="banana banana-cross-normal"></i>
                        </span>
                    </div>
                </div>
            </CardSimple>
        </div>

        <div class="col-12" v-if="(!loading && records === 0) || (!templateProcessed && records === 0 && !loading)">
            <CardSimple>
                <EmptyState
                        :imgUrlHorizontal="ImagenEmptyStateNew"
                        :title="$t('payment-template.empty-state.config-template.title')"
                        :text="$t('payment-template.empty-state.config-template.text')">
                    <a :href="`${baseUrl}/files/layout-pagando-pagos.xlsx`" type="button"
                       class="button sm button-filled button-accent1"> <i aria-hidden="true" class="banana banana-download"></i>{{$t('payment-template.download-pdf')}}
                    </a>
                </EmptyState>
            </CardSimple>
        </div>

        <div class="col-12" v-if="loading">
            <CardSimple>
                <EmptyState
                        emptyStateDefault
                        :imgUrl="ImagenEmptyState"
                ></EmptyState>
                <LoadingBar>
                    <p><strong v-html="$t('payment-template.loading-info')"></strong></p>
                </LoadingBar>
            </CardSimple>
        </div>

        <div class="col-12" v-if="templateProcessed || records > 0">
            <SearchBar
                    ref="searchBar"
                    :columValues="fields"
                    :canCreate="hideCreate"
                    clazzFilter="m-r-0"
                    noOptions
                    @routerPush="downloadTemplate"
                    :buttonText="$t('payment-template.button.download-template-processed')"
                    v-on:changeListLimit="changeListLimit"
                    v-on:searchTextChange="searchTextChange"
            ></SearchBar>
        </div>

        <div class="col-12" v-if="templateProcessed || records > 0">
            <CardSimple clazz="w-100 p-0" hideTitle>
                <div id="menuOverflowFix" class="basic-table">
                    <b-table-simple
                            responsive
                            hover
                            ref="selectableTable"
                            selectable
                            :select-mode="selectMode"
                            selected-variant="active"
                            table-variant="xl"
                            :items="paymentsTemplate"
                    >
                        <!-- Add classes 'b-table-row-selected table-active' to 'b-tbody > b-tr' when a row checkbox selected.
                            If MORE THAN ONE row CHECKBOX is selected, add to 'b-thead > b-tr' class 'header-options-full'. -->
                        <b-thead>
                            <b-tr :class="multiSelectDocuments ? 'header-options-full' : ''"
                                  v-if="(records === 0 && !loading && getFiltersCount > 0) || records > 0">
                                <b-th sortable v-show="fields[0].value">
                                    <div class="d-flex align-items-c">
                                        <BasicCheckbox
                                                clazz="m-0 w-fit di-flex"
                                                @change="selectAll"
                                                ref="CHECKBOX_CATALOG_ALL"
                                                :currentValue="multiSelectDocuments"
                                        ></BasicCheckbox>

                                        <span class="open-filters" @click="headerFilters()"> {{$t('payment-template.table.headers.name')}} </span>
                                        <SortIcon
                                                @sortUp="setSortPaymentTemplate('_id', 'asc')"
                                                @sortDown="setSortPaymentTemplate('_id', 'desc')"
                                        ></SortIcon>
                                    </div>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicInput labelFor=""
                                                    inputId=""
                                                    inputPlaceholder="Ej: Corte de Cabello"
                                                    clazz="noLabel"
                                                    v-model="paymentName"
                                                    @input="setFilterName"></BasicInput>
                                    </div>
                                </b-th>
                                <b-th v-show="fields[1].value">
                                    <span class="open-filters" @click="headerFilters()"> {{$t('payment-template.table.headers.payment-type')}} </span>
                                    <SortIcon
                                            @sortUp="setSortPaymentTemplate('type', 'asc')"
                                            @sortDown="setSortPaymentTemplate('type', 'desc')"
                                    ></SortIcon>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicSelect>
                                            <multiselect v-model="valuePaymentType" :options="optionsPaymentType"
                                                         :close-on-select="true"
                                                         :preserve-search="true"
                                                         placeholder="Ej: Regular" label="name"
                                                         track-by="name"
                                                         :preselect-first="false"
                                                         @input="setFilterType">
                                                <template slot="selection"
                                                          slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single"
                                                          v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>
                                <b-th v-show="fields[2].value">
                                    <span class="open-filters" @click="headerFilters()"> {{$t('payment-template.table.headers.details')}} </span>


                                </b-th>
                                <b-th class=TEXT_ALIGN_C_CLASS v-show="fields[3].value">
                                    <span class="open-filters" @click="headerFilters()"> {{$t('payment-template.table.headers.service-status')}} </span>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicSelect>
                                            <multiselect v-model="valueEstatusServicio"
                                                         :options="optionsEstatusServicio"
                                                         :close-on-select="true"
                                                         :preserve-search="true"
                                                         placeholder="Ej: Activo" label="name"
                                                         track-by="name"
                                                         :preselect-first="false"
                                                         @input="setFilterStatusService">
                                                <template slot="selection"
                                                          slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single"
                                                          v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>
                                <b-th class=TEXT_ALIGN_C_CLASS v-show="fields[4].value">
                                    <span class="open-filters" @click="headerFilters()"> {{$t('payment-template.table.headers.payment-status')}}</span>
                                    <div class="filters" :class="[filtersHeader ? 'show' : '']">
                                        <BasicSelect>
                                            <multiselect v-model="valueEstatusPago" :options="optionsEstatusPago"
                                                         :close-on-select="true"
                                                         :preserve-search="true"
                                                         placeholder="Ej: Validado" label="name"
                                                         track-by="name"
                                                         :preselect-first="false"
                                                         @input="setFilterStatusPayment">
                                                <template slot="selection"
                                                          slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single"
                                                          v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span>
                                                </template>
                                            </multiselect>
                                        </BasicSelect>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c w-5"> {{$t('payment-template.table.headers.actions')}}</b-th>
                                <b-th class="row-options">
                                    <div class="vertical-center">
                                        <BasicCheckbox
                                                clazz="m-0 w-fit di-flex"
                                                :currentValue="multiSelectDocumentsAfterSelect"
                                                @change="selectAll"
                                                :text="this.selectedRowsIndex.length + ' ' + $t('payment-template.all-selected')"
                                                textClass="f-14 c-primary_1 principal-font-bold"
                                                ref="CHECKBOX_CATALOG_ALL_AFTER"
                                        ></BasicCheckbox>
                                        <div>
                                            <a class="opt-row c-error" tabindex=""
                                               @click="$bvModal.show(modalDeleteManyId)">
                                                <i aria-hidden="true" class="banana banana-delete"></i>
                                                {{$t('payment-template.button.delete')}} </a>
                                            <a class="opt-row" tabindex="" @click="confirmManyPayments">
                                                <i aria-hidden="true" class="banana banana-check-double-check"></i>
                                                {{$t('payment-template.button.confirm')}} </a>
                                            <a class="opt-row" @click="selectAll(false)">
                                                {{$t('payment-template.button.cancel')}} </a>
                                        </div>
                                    </div>
                                </b-th>
                            </b-tr>
                        </b-thead>

                        <b-tbody
                                v-if="paymentsTemplate.length === 0 && !loading && (getFiltersCount > 0 || searchText !== '')">

                            <b-tr role="row" class="b-table-busy-slot">
                                <b-td colspan="8" role="cell" class="">
                                    <EmptyState
                                            emptyStateDefault size="sm"
                                            :title="$t('payment-template.empty-state.not-found-results.title')">
                                        <button @click="resetAllFilters" type="button"
                                                class="button xs button-stroke button-accent1 m-0-auto m-t-30">
                                            {{$t('payment-template.empty-state.not-found-results.button')}}
                                        </button>
                                    </EmptyState>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if="!loading && records > 0">
                            <b-tr class="" v-for="(payment, index) in paymentsTemplate" v-bind:key="payment._id"
                                  :class="payment.selectedItem ? 'b-table-row-selected table-active' : ''">
                                <b-td v-show="fields[0].value">
                                    <div class="vertical-center">
                                        <BasicCheckbox clazz="m-0 w-fit di-flex"
                                                       :index="index"
                                                       :currentValue="payment.selectedItem"
                                                       @change="selectIndex"
                                                       :ref="'CHECKBOX_CATALOG_' + index"
                                        ></BasicCheckbox>
                                        <router-link to="" class="c-primary_1"><strong> {{payment._id}} </strong>
                                        </router-link>
                                    </div>
                                </b-td>
                                <b-td v-show="fields[1].value"> {{
                                    $t(`payment-template.type.options.${payment.payments[0].type}`) }}
                                </b-td>
                                <b-td v-show="fields[2].value"> {{getDetails(payment)}}</b-td>
                                <b-td class=TEXT_ALIGN_C_CLASS v-show="fields[3].value">
                                    <Badge :type="getClassStatusService(payment)"
                                           :text="$t(getTextStatusService(payment))"></Badge>
                                </b-td>
                                <b-td class=TEXT_ALIGN_C_CLASS v-show="fields[4].value">
                                    <Badge :type="isPaymentValid(payment) ? 'badge-success' : 'badge-error'"
                                           :text="isPaymentValid(payment) ? $t('payment-template.payment-status.valid') : $t('payment-template.payment-status.error')"></Badge>
                                </b-td>
                                <b-td class="text-align-c w-5">
                                    <b-dropdown @show="showDropdown" @hide="hideDropdown" id="dropdown-table" right
                                                class="dropdown-table">
                                        <template v-slot:button-content>
                                            <button class="button-circle xs button-ghost button-info_text">
                                                <i aria-hidden="true" class="banana banana-options-other-vertical"></i>
                                            </button>
                                        </template>
                                        <b-dropdown-group header="Mas Acciones">
                                            <b-dropdown-item-button>
                                                <a tabindex="" @click="showDetailsPayment(payment)">
                                                    <i aria-hidden="true" class="banana banana-visibility-on"></i>
                                                    {{$t('payment-template.table.dropdown.show-details')}} </a>
                                            </b-dropdown-item-button>
                                            <b-dropdown-item-button v-if="isPaymentValid(payment)">
                                                <a tabindex="" @click="confirmPayment(payment)">
                                                    <i aria-hidden="true" class="banana banana-check-double-check"></i>
                                                    {{$t('payment-template.table.dropdown.confirm')}} </a>
                                            </b-dropdown-item-button>
                                            <b-dropdown-divider></b-dropdown-divider>
                                            <b-dropdown-item-button variant=" c-error">
                                                <a tabindex=""
                                                   @click="tempDelete(payment); $bvModal.show(modalDeleteId)"
                                                   class="c-error"><i aria-hidden="true" class="banana banana-delete"></i>{{$t('payment-template.table.dropdown.delete')}}</a>
                                            </b-dropdown-item-button>
                                        </b-dropdown-group>
                                    </b-dropdown>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </CardSimple>
            <div class="col-12">
                <div class="pagination-custom">
                    <span class="pagination-count"> {{ total }} {{ total  > 1 ? $tc('payment-template.payments', 2) : $tc('payment-template.payments', 1) }} </span>
                    <b-pagination
                            v-if="!loading && total > 0"
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="total"
                            aria-controls="my-table"
                            next-text
                            prev-text
                            hide-goto-end-buttons
                            @change="pageChange"
                    ></b-pagination>
                </div>
            </div>
        </div>

        <ModalAlert
                modalId="modal-delete"
                ref="modal-delete"
                buttonType="button-error"
                :imgUrl="imageModalDelete"
                :title="$t('payment-template.modal.modal-delete.title')"
                :text="$t('payment-template.modal.modal-delete.text')"
                :buttonCancel="$t('payment-template.cancel')"
                :buttonText="$t('payment-template.delete-payment')"
                v-on:submit="deletePayment"
                v-on:cancel="cancelDeletePayment"
        ></ModalAlert>

        <ModalAlert
                modalId="modal-delete-many"
                ref="modal-delete-many"
                buttonType="button-error"
                :imgUrl="imageModalDelete"
                :title="$t('payment-template.modal.modal-delete-many.title')"
                :text="$t('payment-template.modal.modal-delete-many.text')"
                :buttonCancel="$t('payment-template.cancel')"
                :buttonText="$t('payment-template.delete-many-payments')"
                v-on:submit="deleteManyPayment"
        ></ModalAlert>

        <ModalAlert
                modalId="modal-delete-all"
                ref="modal-delete-all"
                buttonType="button-accent1"
                :imgUrl="imageModalWarning"
                :title="$t('payment-template.modal.modal-delete-all.title')"
                :text="$t('payment-template.modal.modal-delete-all.text')"
                :buttonText="$t('payment-template.button.accept')"
                :buttonCancel="$t('payment-template.cancel')"
                v-on:submit="deleteAllPayments(modalDeleteAllId)"
        ></ModalAlert>

        <ModalAlert
                modalId="modal-confirm-all"
                ref="modal-confirm-all"
                buttonType="button-accent1"
                :imgUrl="imageModalSuccess"
                :title="$t('payment-template.modal.modal-confirm-all.title')"
                :text="$t('payment-template.modal.modal-confirm-all.text')"
                :buttonCancel="$t('payment-template.cancel')"
                :buttonText="$t('payment-template.confirm-all')"
                v-on:submit="confirmAllPayments"
                v-on:cancel="cancelConfirmAllPayments"
        ></ModalAlert>

        <ModalAlert
                modalId="modal-info"
                buttonType="button-accent1"
                :imgUrl="imageModalUpload"
                :title="$t('payment-template.modal.modal-info.title')"
                :text="$t('payment-template.modal.modal-info.text')"
                :buttonText="$t('payment-template.button.load-template')"
                v-on:submit="loadNewTemplate"
        ></ModalAlert>

        <ModalAlert
                modalId="modal-help"
                buttonType="button-accent1"
                :imgUrl="imageModalHelp"
                :title="$t('payment-template.modal.modal-help.title')"
                :buttonText="$t('payment-template.button.understood')"
                v-on:submit="$bvModal.hide(modalHelpId)"
        >
            <p class="d-block m-b-20" v-html="$t('payment-template.modal.modal-help.p1')"></p>

            <p class="d-block m-b-20" v-html="$t('payment-template.modal.modal-help.p2')"></p>

            <p class="d-block m-b-20" v-html="$t('payment-template.modal.modal-help.p3')"></p>

            <p class="d-block m-b-20" v-html="$t('payment-template.modal.modal-help.p4')"></p>
        </ModalAlert>

    </div>
</template>

<script>
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import SearchBar from '@/components/SearchBar.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import ImagenEmptyStateDefault from '@/assets/images/Emptystates/illustration-upload.svg';
    import ImagenEmptyStateNew from '@/assets/images/Emptystates/illustration-download.svg';
    import SimpleNote from '@/components/SimpleNote.vue';
    import UploadBox from '@/components/UploadBox.vue';
    import ImageBoxUpload from '@/assets/images/Icons/icon-plus.svg';
    import FileUploadedInfo from '@/components/FileUploadedInfo.vue';
    import LoadingBar from "@/components/LoadingBar.vue";
    import SortIcon from "@/components/SortIcon.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import BasicSelect from "@/components/BasicSelect.vue";
    import Badge from "@/components/Badge.vue";
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    import ModalAlert from "@/components/ModalAlert.vue";
    import imageModalDelete from "@/assets/images/Illustrations/illustration-modal-delete.svg";
    import imageModalWarning from "@/assets/images/Illustrations/illustration-cancel-plantilla.svg";
    import imageModalSuccess from "@/assets/images/Illustrations/illustration-confirm-plantilla.svg";
    import imageModalUpload from "@/assets/images/Illustrations/illustration-uploadl-plantilla.svg";
    import imageModalHelp from "@/assets/images/Illustrations/illustration-question-plantilla.svg";
    import ImagenEmptyState from '@/assets/images/Emptystates/emptystate-payment-cash.svg';
    import Multiselect from 'vue-multiselect'
    import {mapGetters} from 'vuex';
    import catalog from '../../../mixins/catalog.mixin';
    import {EventBus} from "@/main";
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import utils from '@/common/utils';
    import base from '@/api/base.api';

    import {
        GET_DOCS,
        GET_DOCS_LENGTH,
        GET_CURRENT_PAGE,
        GET_OBJECT_PAGE,
        SET_CURRENT_PAGE,
        SET_PER_PAGE,
        SET_SEARCH,
        GET_LOADING_ITEMS,
        SET_LOADING_ITEMS,
        GET_SELECTED_IDS,
        SET_SORT,
        SET_TYPE_SORT
    } from '@/store/modules/base/catalog.types';

    import {
        WARNING_EVENT,
        ERROR_DELETING,
        SUCCESS_DELETE,
        ERROR_LOADING
    } from '@/store/events';

    import {
        STORE as PaymentTemplateStore,
        GET_VALIDS,
        GET_FILTERS_COUNT,
        GET_RECORDS,
        CLEAR_SELECTED_IDS,
        XLS_SUCCESS,
        GET_LOADING_PROCESS_TEMPLATE,
        SET_PAYMENT_SELECTED
    } from '@/store/modules/paymentstemplate/paymentsTemplate.types';

    const catalogPaymentsTemplate = catalog.configure({
        storeModule: PaymentTemplateStore,
        manualLoad: true
    });

    const FILE_MAX_SIZE = 25000000; // 25MB
    const TEXT_ALIGN_C_CLASS = "text-align-c";

    export default {
        name: "PaymentTemplate",
        components: {
            FloatingTitleLayout,
            CardSimple,
            SearchBar,
            EmptyState,
            SimpleNote,
            UploadBox,
            FileUploadedInfo,
            LoadingBar,
            SortIcon,
            BasicInput,
            BasicSelect,
            Badge,
            BasicCheckbox,
            ModalAlert,
            Multiselect
        },
        mixins: [catalogPaymentsTemplate, ToastSnotify],
        data() {
            return {
                baseUrl: base.baseUrlWithoutApi,
                ImagenEmptyStateDefault: ImagenEmptyStateDefault,
                ImagenEmptyStateNew: ImagenEmptyStateNew,
                ImageBoxUpload: ImageBoxUpload,
                imageModalDelete: imageModalDelete,
                imageModalWarning: imageModalWarning,
                imageModalSuccess: imageModalSuccess,
                imageModalUpload: imageModalUpload,
                imageModalHelp: imageModalHelp,
                ImagenEmptyState: ImagenEmptyState,
                modalConfirmAllPaymentsId: "modal-confirm-all",
                modalDeleteId: "modal-delete",
                tempDeletePayment: "",
                modalDeleteManyId: "modal-delete-many",
                modalDeleteAllId: "modal-delete-all",
                modalHelpId: "modal-help",
                modalInfoId: "modal-info",
                selectMode: "multi",
                paymentName: "",
                valueEstatusServicio: [],
                optionsEstatusServicio: [
                    {name: this.$i18n.t('payment-template.service-status.no-filter'), value: 'NO_FILTER'},
                    {name: this.$i18n.t('payment-template.service-status.inactive'), value: '2'},
                    {name: this.$i18n.t('payment-template.service-status.active'), value: '1'}
                ],
                valueEstatusPago: [],
                optionsEstatusPago: [
                    {name: this.$i18n.t('payment-template.payment-status.no-filter'), value: 'NO_FILTER'},
                    {name: this.$i18n.t('payment-template.payment-status.valid'), value: true},
                    {name: this.$i18n.t('payment-template.payment-status.error'), value: false}
                ],
                optionsPaymentType: [
                    {name: this.$i18n.t('payment-template.type.no-filter'), value: 'NO_FILTER'},
                    {name: this.$i18n.t('payment-template.type.options.PAYMENT'), value: 'PAYMENT'},
                    {name: this.$i18n.t('payment-template.type.options.DEBTPAYMENT'), value: 'DEBTPAYMENT'}
                ],
                valuePaymentType: [],
                filtersHeader: false,
                hideCreate: true,
                fields: [
                    {
                        key: 'nombre',
                        label: this.$i18n.t('payment-template.title-column.name'),
                        sortable: true,
                        class: 'text-align-l c-primary_1 principal-font-bold',
                        value: true
                    },
                    {
                        key: 'tipo',
                        label: this.$i18n.t('payment-template.title-column.type'),
                        sortable: true,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                    {
                        key: 'detalle',
                        label: this.$i18n.t('payment-template.title-column.detail'),
                        sortable: true,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                    {
                        key: 'estatus_servicio',
                        label: this.$i18n.t('payment-template.title-column.service-status'),
                        sortable: false,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    },
                    {
                        key: 'estatus_pago',
                        label: this.$i18n.t('payment-template.title-column.service-payment'),
                        sortable: false,
                        class: TEXT_ALIGN_C_CLASS,
                        value: true
                    }
                ],
                searchText: "",
                selectedRowsIndex: [],
                multiSelectDocuments: false,
                multiSelectDocumentsAfterSelect: false,
                xlsFile: {},
                fileName: "",
                fileSize: "",
                disableUploading: false,
                fields2: [
                    {
                        key: "nombre",
                        label: "Nombre"
                    },
                    {
                        key: "inicio",
                        label: "Inicio",
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "fin",
                        label: "Fin",
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "estatus",
                        label: "Estatus",
                        class: TEXT_ALIGN_C_CLASS
                    },
                    {
                        key: "descuento",
                        label: "Descuento",
                        class: "text-align-r f-bold c-success"
                    }
                ],
                items2: [
                    {
                        nombre: "Bienvenido 2020",
                        inicio: "10/11/19",
                        fin: "15/11/19",
                        descuento: "- 10%",
                    },
                    {
                        nombre: "Usuarios Pagando",
                        inicio: "10/11/19",
                        fin: " - ",
                        descuento: "- $50.00 MXN",
                    }
                ]
            }
        },
        methods: {
            showDropdown() {
                document.getElementById("menuOverflowFix").classList.add('dropdown-table-overflow');
            },
            hideDropdown() {
                document.getElementById("menuOverflowFix").classList.remove('dropdown-table-overflow');
            },
            headerFilters() {
                this.filtersHeader = !this.filtersHeader;
            },
            getNameSort(type) {
                if (type === 'DEBTPAYMENT') {
                    return 'element.debt.name'
                }
                return 'element.name'

            },
            setSortPaymentTemplate(sort, typeSort) {
                this.$store.dispatch(`${PaymentTemplateStore}/setSortPaymentTemplate`, {
                    sort,
                    typeSort
                });
            },
            setFilterName(name) {
                if (this.searchTimeout){
                    clearTimeout(this.searchTimeout);
                }
                const that = this;
                this.searchTimeout = setTimeout(function () {
                    that.$store.dispatch(`${PaymentTemplateStore}/setFilterPaymentTemplate`, {
                        name: name
                    });
                }, 500);
            },
            setFilterType(type) {
                if (type.value === "NO_FILTER") {
                    this.$store.dispatch(`${PaymentTemplateStore}/clearFilterPaymentTemplate`, {
                        type: true
                    });
                } else {
                    this.$store.dispatch(`${PaymentTemplateStore}/setFilterPaymentTemplate`, {
                        type: type.value
                    });
                }
            },
            setFilterStatusService(statusService) {
                if (statusService.value === "NO_FILTER") {
                    this.$store.dispatch(`${PaymentTemplateStore}/clearFilterPaymentTemplate`, {
                        statusService: true
                    });
                } else {
                    this.$store.dispatch(`${PaymentTemplateStore}/setFilterPaymentTemplate`, {
                        statusService: statusService.value
                    });
                }
            },
            setFilterStatusPayment(statusPayment) {
                if (statusPayment.value === "NO_FILTER") {
                    this.$store.dispatch(`${PaymentTemplateStore}/clearFilterPaymentTemplate`, {
                        statusPayment: true
                    });
                } else {
                    this.$store.dispatch(`${PaymentTemplateStore}/setFilterPaymentTemplate`, {
                        statusPayment: statusPayment.value
                    });
                }
            },
            resetAllFilters() {
                this.$store.commit(`${PaymentTemplateStore}/${SET_LOADING_ITEMS}`, true);
                this.paymentName = '';
                this.valuePaymentType = this.optionsPaymentType[0];
                this.valueEstatusServicio = this.optionsEstatusServicio[0];
                this.valueEstatusPago = this.optionsEstatusPago[0];
                this.$store.dispatch(`${PaymentTemplateStore}/resetFilterPaymentTemplate`);
                if (this.searchText !== '') {
                    this.$refs.searchBar.resetInput();
                }
            },
            selectAll(value) {
                for (let i = 0; i < this.paymentsTemplate.length; i++) {
                    const data = {
                        index: i,
                        checkValue: value
                    }
                    this.selectIndex(data);
                    const ref = `CHECKBOX_CATALOG_${i}`;
                    this.$refs[ref][0].checkStoreValue(value);
                }
                this.$refs.CHECKBOX_CATALOG_ALL.checkStoreValue(value);
                this.$refs.CHECKBOX_CATALOG_ALL_AFTER.checkStoreValue(value);
            },
            changeListLimit(limit) {
                utils.debounceFixed(() => {
                    if (limit <= 0) {
                        this.displayNotificationWarning(this.$i18n.t('general.list-limit-warn'));
                        this.$store.commit(`${PaymentTemplateStore}/${SET_PER_PAGE}`, 10)
                        this.$store.dispatch(`${PaymentTemplateStore}/listPaymentsTemplate`)
                    } else {
                        this.$store.commit(`${PaymentTemplateStore}/${SET_PER_PAGE}`, limit)
                        this.$store.dispatch(`${PaymentTemplateStore}/listPaymentsTemplate`)
                    }
                })
            },
            searchTextChange(searchText) {
                utils.debounceFixed(() => {
                    this.searchText = searchText;
                    this.$store.commit(`${PaymentTemplateStore}/${SET_SEARCH}`, searchText)
                    this.$store.dispatch(`${PaymentTemplateStore}/listPaymentsTemplate`)
                })
            },
            pageChange(page) {
                this.$store.commit(`${PaymentTemplateStore}/${SET_CURRENT_PAGE}`, page);
                this.$store.dispatch(`${PaymentTemplateStore}/listPaymentsTemplate`);
            },
            isPaymentValid(payment) {
                const firstPayment = payment.payments[0];
                if (firstPayment.type === 'PAYMENT') {
                    return firstPayment.valid;
                }
                let isValid = true;
                if (payment.payments) {
                    let debtsInvalid = 0;
                    for (let i = 0; i < payment.payments.length; i++) {
                        if (!payment.payments[i].valid) {
                            debtsInvalid++;
                        }
                    }

                    if (debtsInvalid > 0) {
                        isValid = false;
                    }
                }

                return isValid;

            },
            async fileUploadXls(files) {
                const file = files[0];
                if (file.size <= FILE_MAX_SIZE) {
                    this.xlsFile = await this.readFileXls(files);
                    this.fileName = files[0].name;
                    this.fileSize = `${files[0].size / 1000} KB`;
                } else {
                    this.displayNotificationWarning(this.$i18n.t('payment-template.upload-box.note'));
                }
            },
            readFileXls(files) {
                return new Promise((resolve) => {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        const data = new Uint8Array(e.target.result);
                        utils.xlsxToJsonExcelJS(data).then(resolve);
                    };
                    reader.readAsArrayBuffer(files[0]);
                });
            },
            deleteFileXls() {
                this.xlsFile = {};
                this.fileName = "";
                this.fileSize = "";
            },
            async startProcessTemplate() {
                const params = {
                    excelData: this.xlsFile,
                    xlsFile: this.fileToSave
                }
                await this.$store.dispatch(`${PaymentTemplateStore}/uploadXlsFile`, params);

            },
            getClassStatusService(payment) {
                const firstPayment = payment.payments[0];
                if (firstPayment.element.activeService === '1') { // Activo
                    return 'badge-accent_2'
                } else if (firstPayment.element.activeService === '2') { // Inactivo
                    return 'badge-placeholder'
                }
                // proximamente
                return 'badge-accent_1'
            },
            getTextStatusService(payment) {
                const firstPayment = payment.payments[0];
                if (firstPayment.element.activeService === '1') { // Activo
                    return 'payment-template.service-status.active'
                } else if (firstPayment.element.activeService === '2') { // Inactivo
                    return 'payment-template.service-status.inactive'
                }
                // proximamente
                return 'payment-template.service-status.cooming-soon'
            },
            getDetails(payment) {
                /* Todos los pagos estan agrupados por nombre y dentro de cada uno viene el arreglo de payments,
                    se obtiene el primero para ver el tipo de pago si es PAYMENT solo habra un registro si no habrán todos los DEBTPAYMENT de ese nombre.
                 */
                const firstPayment = payment.payments[0];
                if (firstPayment.type === "DEBTPAYMENT") {
                    return `${payment.payments.length} ${this.$i18n.t('payment-template.debts')}`
                }

                return `${utils.toCurrency(firstPayment.element.total)} ${payment.currencyObject.code}`
            },
            confirmPayment(payment) {
                const firstPayment = payment.payments[0];
                if (firstPayment.type === 'DEBTPAYMENT' && payment.payments) {
                    const listPayments = [];
                    for (let i = 0; i < payment.payments.length; i++) {
                        listPayments.push(payment.payments[i]._id);
                    }

                    this.$store.dispatch(`${PaymentTemplateStore}/confirmPaymentList`, listPayments).then((result) => {
                        if (result.errorConfirmPayment) {
                            this.displayNotificationWarning(result.errorConfirmPayment.message);
                        }
                    })
                } else {
                    const params = {
                        _id: firstPayment._id
                    }
                    // eslint-disable-next-line sonarjs/no-identical-functions
                    this.$store.dispatch(`${PaymentTemplateStore}/confirmPayment`, params).then((result) => {
                        if (result.errorConfirmPayment) {
                            this.displayNotificationWarning(result.errorConfirmPayment.message);
                        }
                    })
                }

            },
            confirmManyPayments() {

                const paymentsSelected = this.getSelectedPayments();
                this.$store.dispatch(`${PaymentTemplateStore}/confirmPaymentList`, paymentsSelected);
                this.$store.commit(`${PaymentTemplateStore}/${CLEAR_SELECTED_IDS}`);
                this.selectedRowsIndex = [];
                this.multiSelectDocuments = false;
                this.multiSelectDocumentsAfterSelect = false;
            },
            getSelectedPayments() {

                const paymentsSelected = [];
                for (let i = 0; i < this.selectedRowsIndex.length; i++) {
                    const element = this.paymentsTemplate[this.selectedRowsIndex[i]];

                    for (let j = 0; j < element.payments.length; j++) {
                        const payment = element.payments[j];
                        paymentsSelected.push(payment._id);
                    }

                }

                return paymentsSelected
            },
            showModalConfirmAllPayments() {
                this.$bvModal.show('modal-confirm-all');
            },
            confirmAllPayments() {
                this.$store.dispatch(`${PaymentTemplateStore}/confirmAllPayments`);
                this.$bvModal.hide(this.modalConfirmAllPaymentsId);
            },
            cancelConfirmAllPayments() {
                this.$bvModal.hide(this.modalConfirmAllPaymentsId);
            },
            tempDelete(payment) {
                this.tempDeletePayment = payment;
            },
            deletePayment() {
                this.$store.dispatch(`${PaymentTemplateStore}/deletePaymentPending`, this.tempDeletePayment);
                this.$bvModal.hide(this.modalDeleteId);
            },
            cancelDeletePayment() {
                this.tempDeleteId = "";
                this.$bvModal.hide(this.modalDeleteId);
            },
            deleteManyPayment() {
                const paymentsSelected = this.getSelectedPayments();

                this.$store.dispatch(`${PaymentTemplateStore}/deleteManyPayments`, paymentsSelected);
                this.$store.commit(`${PaymentTemplateStore}/${CLEAR_SELECTED_IDS}`);
                this.selectedRowsIndex = [];
                this.multiSelectDocuments = false;
                this.multiSelectDocumentsAfterSelect = false;
                this.$bvModal.hide(this.modalDeleteManyId);
            },
            deleteAllPayments(idModal) {
                this.$store.dispatch(`${PaymentTemplateStore}/deleteAllPayments`);
                this.$bvModal.hide(idModal);
                this.deleteFileXls();
            },
            downloadTemplate() {
                this.$store.dispatch(`${PaymentTemplateStore}/downloadTemplate`);
            },
            uploadAgain() {
                this.$refs.uBox.$refs.uploadTemplateFile.click()
            },
            showModalUpload() {
                if (this.total > 0) {
                    this.$bvModal.show(this.modalInfoId);
                } else {
                    this.uploadAgain();
                }
            },
            loadNewTemplate() {
                this.deleteAllPayments(this.modalInfoId);
                const _this = this;

                setTimeout(function () { // Hay que esperar un momento para que el file se active ya que se deshabilita cuando hay una plantilla procesada.
                    _this.uploadAgain();
                }, 100);

            },
            showDetailsPayment(payment) {
                const isValid = this.isPaymentValid(payment);
                const data = {
                    payment: payment.payments[0],
                    isValid: isValid,
                    debts: payment.payments
                }
                this.$store.commit(`${PaymentTemplateStore}/${SET_PAYMENT_SELECTED}`, data);
                this.$router.push({
                    name: 'DetailPaymentTemplate',
                    params: {idPaymentPending: payment.payments[0]._id.toString()}
                });
            }


        },
        computed: {
            ...mapGetters(PaymentTemplateStore, {
                paymentsTemplate: GET_DOCS,
                total: GET_DOCS_LENGTH,
                currentPage: GET_CURRENT_PAGE,
                perPage: GET_OBJECT_PAGE,
                loading: GET_LOADING_ITEMS,
                valids: GET_VALIDS,
                getFiltersCount: GET_FILTERS_COUNT,
                records: GET_RECORDS,
                selectedIds: GET_SELECTED_IDS,
                loadingProcessTemplate: GET_LOADING_PROCESS_TEMPLATE
            }),
            templateProcessed() {
                return this.total > 0
            }
        },
        mounted() {
            this.$store.commit(`${PaymentTemplateStore}/${SET_SORT}`, '_id');
            this.$store.commit(`${PaymentTemplateStore}/${SET_TYPE_SORT}`, 'asc');
            this.$store.dispatch(`${PaymentTemplateStore}/listPaymentsTemplate`);
        },
        beforeDestroy() {
            EventBus.$off('PAYMENT_TEMPLATE_WARNING');
            EventBus.$off(PaymentTemplateStore + ERROR_LOADING);
            EventBus.$off(PaymentTemplateStore + XLS_SUCCESS);

            EventBus.$off(PaymentTemplateStore + ERROR_DELETING)
            EventBus.$off(PaymentTemplateStore + SUCCESS_DELETE);
        },
        created() {
            const _this = this;
            EventBus.$on(PaymentTemplateStore + WARNING_EVENT, (message) => {
                this.displayNotificationWarning(message);
            });

            EventBus.$on(PaymentTemplateStore + ERROR_LOADING, (message) => {
                this.displayNotificationWarning(message);
            });

            EventBus.$on(PaymentTemplateStore + XLS_SUCCESS, (message) => {
                this.displayNotificationSuccess(message);
            });

            EventBus.$on(PaymentTemplateStore + SUCCESS_DELETE, (message) => {
                _this.displayNotificationSuccess(message);
                _this.cancelDeletePayment();
                if (this.$refs.CHECKBOX_CATALOG_ALL.checkStoreValue !== undefined) {
                    this.$refs.CHECKBOX_CATALOG_ALL.checkStoreValue(false);
                }

                if (this.$refs.CHECKBOX_CATALOG_ALL_AFTER.checkStoreValue !== undefined) {
                    this.$refs.CHECKBOX_CATALOG_ALL_AFTER.checkStoreValue(false);
                }

                this.multiSelectDocuments = false;
                this.multiSelectDocumentsAfterSelect = false;
            });

            EventBus.$on(PaymentTemplateStore + ERROR_DELETING, (message) => {
                _this.displayNotificationError(message);
                _this.cancelDeletePayment();
            });
        }
    }
</script>
