import {
    SET_TOKEN,
    SET_USER,
    SET_PERMISSIONS,
    SET_TOKEN_EXPIRE_DATE,
    GET_TOKEN,
    GET_USER,
    GET_PERMISSIONS,
    GET_TOKEN_EXPIRE_DATE,
    CHANGE_PROFILE_JWT,
    CHANGE_OTHER_PROFILE_JWT,
    GET_LOCATION,
    SET_LOCATION,
} from './session.types';
import usersApi from "@/api/user/users.api";
import sentryManager from '@/plugins/sentry';
import axios from 'axios';
import base from '@/api/base.api';

// Objetos
const stateGlobal = {
    token: '',
    tokenExpireDate: new Date(),
    permissions: {},
    user: {},
    location: {
        lat: 0,
        lon: 0,
        error: false
    }
};

// Métodos para obtener valores del state. Se les llama con getters
const getters = {
    // Obtiene el Token JW
    [GET_TOKEN]: (state) => state.token,
    [GET_USER]: (state) => state.user,
    [GET_PERMISSIONS]: (state) => state.permissions,
    [GET_TOKEN_EXPIRE_DATE]: (state) => state.tokenExpireDate,
    [GET_LOCATION]: (state) => state.location,
};

// Métodos síntronos que afectan el state. Se llaman con commit
const mutations = {
    [SET_TOKEN]: (state, payload) => {state.token = payload},
    [SET_USER]: (state, payload) => {
        state.user = payload;
        sentryManager.refreshCurrentUser(payload);
    },
    [SET_PERMISSIONS]: (state, payload) => {state.permissions = payload},
    [SET_TOKEN_EXPIRE_DATE]: (state, payload) => {state.permissions = payload},
    [SET_LOCATION]: (state, {lat, lon, error}) => {state.location = {lat, lon, error}},
};

// Métodos asíncronos que hacen cosas y luego hacen commit. Se llaman con dispatch
const actions = {
    // eslint-disable-next-line no-empty-pattern
    [CHANGE_PROFILE_JWT]: ({commit}, params) => {
        return usersApi.changeProfileSettings({profile: params}).then(data => {
            localStorage.setItem('currentUser', JSON.stringify(data.data.data.user));
            commit(`${SET_USER}`, data.data.data.user);
            localStorage.setItem('currentToken', JSON.stringify(data.data.data.token));
            commit(`${SET_TOKEN}`, data.data.data.token);
            localStorage.setItem('currentPermissions', JSON.stringify(data.data.data.permissions));
            localStorage.setItem('profile', JSON.stringify(data.data.data.profile));
            const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
            localStorage.setItem('expireDate', JSON.stringify(expireDate));
            commit(`${SET_TOKEN_EXPIRE_DATE}`, expireDate);
        });
      },

    // Igual al otro metodo pero recibe como parametro el perfil al cual se va a cambiar.
    // eslint-disable-next-line no-empty-pattern
    [CHANGE_OTHER_PROFILE_JWT]: (context, params) => {
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
            const actualToken = localStorage.getItem("currentToken");
            const currentPermissions = localStorage.getItem("currentPermissions");
            const profile = localStorage.getItem("profile");
            usersApi.changeProfileSettings({profile: params.idProfile}).then(data => {
                localStorage.setItem('currentToken', JSON.stringify(data.data.data.token));
                localStorage.setItem('currentPermissions', JSON.stringify(data.data.data.permissions));
                localStorage.setItem('profile', JSON.stringify(data.data.data.profile));
                const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
                localStorage.setItem('expireDate', JSON.stringify(expireDate));
                const resultData = {
                    token: JSON.stringify(data.data.data.token),
                    expireDate: JSON.stringify(expireDate),
                    profile: data.data.data.profile,
                    permissions: data.data.data.permissions
                }
                axios.defaults.baseURL = base.baseUrl;
                axios.defaults.headers.common.Authorization = `Bearer ${data.data.data.token}`;
                return resolve(resultData);
            }).catch(() => {
                localStorage.setItem('currentToken', actualToken);
                localStorage.setItem('currentPermissions', currentPermissions);
                localStorage.setItem('profile', profile);
                const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
                localStorage.setItem('expireDate', expireDate);
                const resultData = {
                    token: actualToken,
                    expireDate: expireDate
                }
                return reject(resultData)
            });

        })
    },
};

const namespaced = true;
export default {namespaced, state: stateGlobal, getters, actions, mutations};
