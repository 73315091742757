export const STORE = "ACCESS_CONFIG";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const GET_CURRENT_ORGANIZATION = "GET_CURRENT_ORGANIZATION";
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const GET_ACTIVE_ORGANIZATIONS = "GET_ACTIVE_ORGANIZATIONS";
export const GET_COMING_SOON_ORGANIZATIONS = "GET_COMING_SOON_ORGANIZATIONS";
export const GET_INACTIVE_ORGANIZATIONS = "GET_INACTIVE_ORGANIZATIONS";
export const GET_BLOCKED_ORGANIZATIONS = "GET_BLOCKED_ORGANIZATIONS";
export const FETCH_CURRENT_ORGANIZATION = "FETCH_CURRENT_ORGANIZATION";
export const SET_SORT_COLLECTED_PAYMENT = "SET_SORT_COLLECTED_PAYMENT";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const GET_CURRENT_ORGANIZATION_WITH_DATA = "GET_CURRENT_ORGANIZATION_WITH_DATA";
