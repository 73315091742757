<template>
    <input
        type="file"
        :class="_class"
        :id="id"
        :ref="_ref"
        :accept="accept"
        @change="onChange($event.target.value)"
    >
</template>

<script>
    import propValidators from '@/common/validations/form/propValidators';

    export default {
        data () {
            return {
            }
        },
        components: {
        },
        methods: {
            onChange() {
                this.$emit('change', this.$refs);
            },
            clear(){
                this.$refs[this._ref].value = '';
            }
        },
        props: {
            value: {},
            id: {
                type: String,
                required: true,
                validator: propValidators.id,
            },
            _class: {
                type: String,
            },
            _ref: {
                type: String,
                required: true,
            },
            accept: {
                type: String,
                required: true,
            },
        },
    }
</script>
