import {EventBus} from "@/main";
import api from '@/api/chargebacks/chargebacks.api';
import signUpOrganizationApi from '@/api/signUpOrganization.api';
import catalog from '@/store/modules/base/catalog.store';

import {
    STORE as ChargebackStore,
    GET_CURRENT_TAB,
    GET_STATS,
    GET_CHARGEBACK,
    SET_TAB_STATUS,
    SET_CHARGEBACK,
    SET_STATS,
    CLEAR_CHARGEBACK,
    SET_FILE_DOCUMENTS_CHARGEBACK,
    SET_FILE_DOCUMENTS,
    GET_REQUESTED_FILES,
    GET_FILE_DOCUMENTS,
    UPDATE_FILES,
    GET_FILE_BY_KEY,
    UPDATE_EXTRA_COMMENT
} from '@/store/modules/chargebacks/chargebacks.types';

import {
    SET_DOCS, SET_DOCS_LENGTH,
    SET_ERROR_LOADING,
    SET_LOADING_ITEMS
} from "@/store/modules/base/catalog.types";
import {
    ERROR_LOADING,
    ERROR_SAVING
} from '@/store/events';

const chargebacksCatalog = catalog(api, ChargebackStore);



const stateGlobal = {
    currentTab: "ACTIVE",
    stats: {
        activeCount: "0",
        inactiveCount: "0",
        loading: true
    },
    chargeback: {
        chargebackLogs: [],
        evidence: {
            extraComment: "",
            files: []
        },
    },
    fileDocuments: []
}

const getters = {
    [GET_CURRENT_TAB]: (state) => state.currentTab,
    [GET_STATS]: (state) => state.stats,
    [GET_CHARGEBACK]: (state) => state.chargeback,
    [GET_FILE_DOCUMENTS]: (state) => state.fileDocuments,
    [GET_REQUESTED_FILES]: (state) => state.chargeback.evidence.files,
    [GET_FILE_BY_KEY]: (state) => (fileIndex) => {
        const index = state.chargeback.evidence.files.findIndex(element => element.key === fileIndex);
        if(index !== -1){
            return state.chargeback.evidence.files[index].file
        }
        return null
    }

};

const actions = {
    async list({state, commit}, organizationId) {
        commit(SET_LOADING_ITEMS, true);
        api.list({
            currentPage: state.currentPage,
            perPage: state.perPage,
            search: state.searchText,
            filters: state.filters,
            sort: state.sort === 'min' ? 'createdAt' : state.sort,
            typeSort: state.typeSort,
            status: state.currentTab,
            organizationId: organizationId
        }, (result) => {
            commit(SET_LOADING_ITEMS, false);
            if (result.data.error === true) {
                EventBus.$emit(ChargebackStore + ERROR_LOADING, "Ocurrio un problema al obtener el listado de Contracargos")
            } else {
                const docs = result.data.object.docs;
                const finalDocs = docs.map(doc => {
                    doc.selectedItem = false;
                    return doc;
                })
                const lengthDocs = result.data.object.totalDocs;
                commit(SET_DOCS, finalDocs);
                commit(SET_DOCS_LENGTH, lengthDocs);
            }
        }, () => {
            commit(SET_DOCS, []);
            commit(SET_DOCS_LENGTH, 0);
            commit(SET_ERROR_LOADING, true);
        });

    },
    selectTab({commit, dispatch}, {status, organizationId}) {
        commit(SET_TAB_STATUS, status)
        dispatch('list', organizationId);
    },
    findChargeback({commit}, params) {
        return new Promise((resolve, reject) => {
            api.findChargeback(params,
                (result) => {
                    if (result.data.error) {
                        EventBus.$emit(ChargebackStore + ERROR_LOADING, "Ocurrio un problema al obtener el Contracargo seleccionado")
                        return resolve();
                    }
                    EventBus.$emit(`${ChargebackStore}_SUCCESS_LOADING_CHARGEBACK`);
                    commit(SET_CHARGEBACK, result.data.object);
                    return resolve(result.data.object);
                }, (err) => {
                    return reject(err)
                })

        })
    },
    statsChargebacks({commit}, organizationId) {
        api.stats({organizationId}, (result) => {
            if (result.data.error) {
                EventBus.$emit(ChargebackStore + ERROR_LOADING, "Ocurrio un problema al obtener el Contracargo seleccionado")
            } else {
                commit(SET_STATS, result.data.object && result.data.object.length ? result.data.object[0] : {});
            }
        })
    },
    uploadChargebackEvidence({commit, state}){
        return new Promise((resolve, reject) => {
            const params = {
                idChargeback: state.chargeback._id,
                evidence: state.chargeback.evidence
            }
            api.uploadEvidence(params, (result) => {
                if(result.data.error){
                    EventBus.$emit(ChargebackStore + ERROR_SAVING, result.data.message);
                }
                commit(SET_CHARGEBACK, result.data.object);
                resolve("La evidencia del contracargo fue subida exitosamente!");
            }, () => {
                reject("Ocurrio un problema al intentar subir la evidencia. Por favor, intentarlo nuevamente. Si el problema persiste contacte a soporte");
            })
        });
    },
    setSortDocument({commit}, params) {
        if (typeof params !== "undefined") {
            const sort = params.sort;
            const typeSort = params.typeSort;
            commit("SET_SORT", sort);
            commit("SET_TYPE_SORT", typeSort);
        }
    },

    setFilterDocument({state, commit}, params) {
        if (typeof params !== "undefined") {
            const filters = JSON.parse(JSON.stringify(state.filters))
            commit("SET_FILTERS", filters)
        }
    },
    clearFilterDocument({state, commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const filters = JSON.parse(JSON.stringify(state.filters))
            commit("SET_FILTERS", filters)
            dispatch(`list`, state.organization._id);
        }
    },
    clearAllFilters({state, commit, dispatch}) {
        const filters = JSON.parse(JSON.stringify(state.filters))
        commit("SET_FILTERS", filters);
        dispatch(`list`, state.organization._id);

    },
    fetchFileEvidenceRequested: ({commit}, category) => {
        return new Promise((resolve, reject) => {
            signUpOrganizationApi.fetchFileDocuments({
                processOrCategory: category || 'ALL',
                applyFor: "CHARGEBACK_EVIDENCE"
            }, (result) => {
                if (result.data.error) {
                    return reject(result.data);
                }
                commit("SET_FILE_DOCUMENTS", result.data.object);
                commit("SET_FILE_DOCUMENTS_CHARGEBACK", result.data.object);
                return resolve(result.data);
            }, (err) => {
                return reject(err);
            });
        });
    }
    // TODO: Acciones para eliminar
};

const mutations = {
    [SET_TAB_STATUS]: (state, status) => {
        state.currentTab = status;
    },
    [SET_STATS]: (state, stats) => {
        state.stats = stats;
        state.stats.loading = false;
    },
    [SET_CHARGEBACK]: (state, result) => {
        if(result.evidence && result.evidence.files && result.evidence.files.filter){
            result.evidence.files = result.evidence.files.filter(f => f);
        } else {
            result.evidence = {files: []};
        }
        state.chargeback = result;
    },
    [CLEAR_CHARGEBACK]: (state) => {
        state.chargeback = {
            _id: null,
            organization: null,
            transaction: null,
            additionalInfo: null,
            notificationDate: null,
            reason: null
        }
    },
    [SET_FILE_DOCUMENTS]: (state, value) => {
        state.fileDocuments = value;
    },
    [SET_FILE_DOCUMENTS_CHARGEBACK]: (state, values) => {
        if(!state.chargeback.evidence.files || state.chargeback.evidence.files.length === 0 ){
            state.chargeback.evidence.files = [];
            let requestedDocument;
            for(const value of values) {
                requestedDocument = {};
                requestedDocument.name = value.title;
                requestedDocument.key = value.key;
                requestedDocument.fileDocument = value._id;
                requestedDocument.isRequired = value.isRequired;
                state.chargeback.evidence.files.push(requestedDocument);
            }
        }
    },
    [UPDATE_FILES]: (state, values) => {
        // Y recuerden amiguitos si actualizan un valor que no se encuentra declarado en el state no se actualizarán los getters que hayan definido de ese valor :)
        const tempArray = state.chargeback.evidence.files;
        const index = state.chargeback.evidence.files.findIndex(element => element.key === values.fileKey);
        state.chargeback.evidence.files[index].file = values.tempFile;
        state.chargeback.evidence.files[index].isLoaded = values.tempFile ? true : false ;
        const tempObj = state.chargeback.evidence.files[index];
        tempArray.splice(index, 1, tempObj);
        state.chargeback.evidence.files = tempArray;
    },
    [UPDATE_EXTRA_COMMENT]: (state, comment) => {
        state.chargeback.evidence.extraComment = comment
    }
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal,
        ...chargebacksCatalog.state
    },
    getters: {
        ...getters,
        ...chargebacksCatalog.getters
    },
    actions: {
        ...chargebacksCatalog.actions,
        ...actions

    },
    mutations: {
        ...mutations,
        ...chargebacksCatalog.mutations
    }
};
