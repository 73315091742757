<template>
    <div>
        <b-tabs class="simple-tabs" content-class="simple-tabs-body" v-model="tabIndex">
            <b-tab :title="$t('sign-in.login-tab')">
                <GenericTitle :title="getUserFound ? $t('sign-in.main-title-after-user') : $t('sign-in.main-title')"
                              :titleStrong="getUserFound ?
                               (getUserFullName !== undefined &&
                                getUserFullName !== 'undefined' ? getUserFullName : '') : $t('sign-in.main-title-strong')"
                              :subtitle="getUserFound ? $t('sign-in.subtitle-after-user') : $t('sign-in.subtitle')"></GenericTitle>

                <b-alert id="loginBlocked" class="alert-note" show variant="danger" v-if="getLoginBlocked">
                    <p class="text-container"><img class="img-fluid"
                                                   src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                   alt=""/>{{$t('sign-in.login-blocked')}}</p>
                </b-alert>

                <b-alert id="dct-signInDataWrong" class="alert-note" show variant="danger" v-if="getLoginAlert">
                    <p class="text-container"><img class="img-fluid"
                                                   src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                   alt=""/>{{getLoginAlertMessage !== "" ? getLoginAlertMessage :
                        $t('sign-in.login-incorrect-data')}}
                    </p>
                </b-alert>
                <BasicInput
                        v-show="!getUserFound"
                        required
                        inputType="text"
                        labelFor="txt-emailSignIn"
                        inputId="txt-emailSignIn"
                        :label="$t('sign-in.email-label')"
                        :inputPlaceholder="$t('sign-in.email-placeholder')"
                        v-on:basicInput="onBasicInputLogin"
                ></BasicInput>
                <UploadProfileImage
                        v-show="getUserFound"
                        :imgUser="getImageById(getUserImageId)"
                        imgUploaded
                        showInfoUser
                        clazz="horizontal-info sm user-verification-login m-b-30"
                        :userName="getUserPhrase">
                </UploadProfileImage>
                <PasswordInput
                        required
                        v-if="getUserFound && !getIsSocialMediaUser"
                        :inputType="passwordInputType"
                        labelFor="txt-passwordSignIn"
                        inputId="txt-passwordSignIn"
                        :label="$t('sign-in.password-label')"
                        :hasLevelBar="false"
                        :isVisibleText="isVisibleTextPasswordSignIn"
                        :inputPlaceholder="$t('sign-in.password-placeholder')"
                        v-on:passwordInput="onPasswordChangeLogin"
                        v-on:passwordInputType="changeTypePassword">
                </PasswordInput>
                <button id="btn-signInDefault" v-if="!getIsLoadingLogin && !getUserFound"
                        class="button xs button-filled button-accent1 m-t-40 m-b-20 w-100"
                        :class="{'button-disable':disabledUserFound}" type="submit" :disabled="disabledUserFound"
                        @click.prevent="getUserByEmail"> {{$t('sign-in.login-button')}}
                </button>

                <button type="button" v-if="getIsLoadingLogin"
                        class="button xs button-filled button-accent1 m-b-40 w-100">
                    <LoadingSimple clazz="small-load no-text"/>
                </button>

                <router-link v-if="!getIsSocialMediaUser" id="recoveryPassword" :to="{name : 'SigninPasswordRecovery'}"
                             class="f-16 c-accent_2 body-font-bold text-underline d-block text-align-c m-b-40 hover"
                             tabindex=""> {{$t('sign-in.forgot-password')}}
                </router-link>

                <div class="d-flex justify-content-between m-t-150 m-b-40"
                     v-if="!getIsLoadingLogin && getUserFound && !getIsSocialMediaUser">
                    <button id="btn-comeBack" class="button xs button-ghost button-plain_text m-r-10"
                            @click.prevent="comeBackUserSearch"><i aria-hidden="true" class="banana banana-chevron-left"></i> REGRESAR
                    </button>
                    <button id="btn-loginDefault" :class="{'button-disable':disabledSignIn}"
                            class="button sm button-filled button-accent1 m-r-10" :disabled="disabledSignIn"
                            @click.prevent="login">CONTINUAR
                    </button>
                </div>

                <!-- <SocialButtons :span="$t('sign-in.want-save-time')" v-if="!getUserFound">
                    <a id="lnk-signInFacebook" class="social-btn fb" tabindex=""
                       :href="`${ localUrl }/auth/facebook/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/facebook-icon.svg" alt="facebook"/>
                        <strong> {{$t('sign-in.facebook')}} </strong>
                    </a>
                    <a id="lnk-signInTwitter" class="social-btn tw" tabindex=""
                       :href="`${ localUrl }/auth/twitter/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/twitter-icon.svg" alt="twitter"/>
                        <strong> {{$t('sign-in.twitter')}} </strong>
                    </a>
                    <a id="lnk-signInGoogle" class="social-btn goo" tabindex=""
                       :href="`${ localUrl }/auth/google/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/google-icon.svg" alt="google"/>
                        <strong> {{$t('sign-in.google')}} </strong>
                    </a>
                    <a id="link-appleId" class="social-btn apple" tabindex="" :href="`${ localUrl }/auth/apple/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/apple-icon.svg" alt="google"/>
                        <strong> Apple Id </strong>
                    </a>
                </SocialButtons> -->


                <div v-if="getUserFound" class="social-container"></div>
                <p class="link-to m-t-60 m-b-40"> {{$t('sign-in.no-pagando-account-yet')}} <a @click="tabIndex++"
                                                                                              class="hover" tabindex="">
                    {{$t('sign-in.no-pagando-account-yet-register')}} </a></p>
            </b-tab>

            <b-tab :title="$t('sign-in.register-tab')">
                <GenericTitle :titleStrong="$t('sign-in.register-title')"
                              :subtitle="$t('sign-in.register-subtitle')"></GenericTitle>

                <b-alert v-if="getShowAlert" class="alert-note" show :variant="getAlertVariant">
                    <p class="text-container"><img class="img-fluid"
                                                   src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                   alt=""/> {{getAlertMessage}} </p>
                </b-alert>

                <BasicInput
                        required
                        inputType="text"
                        labelFor="txt-emailSignUp"
                        inputId="txt-emailSignUp"
                        :label="$t('sign-in.email-label')"
                        :inputPlaceholder="$t('sign-in.email-placeholder')"
                        v-on:basicInput="onBasicInputRegister"
                ></BasicInput>

                <PasswordInput
                        :inputType="passwordInputTypeRegister"
                        labelFor="txt-passwordSignUp"
                        inputId="txt-passwordSignUp"
                        :required="true"
                        :label="$t('sign-in.password-label')"
                        :inputPlaceholder="$t('sign-in.password-placeholder')"
                        :level="true"
                        :clazzPassword="clazzPasswordStatus"
                        :status="labelPasswordStatus"
                        v-on:passwordInput="onPasswordChangeRegister"
                        v-on:passwordInputType="changeTypePasswordRegister">
                </PasswordInput>
                <SimpleNote icon="banana-info-circle1" clazz="info m-t--30 m-b-20">
                    <p class="m-b-0">
                        {{$t('sign-in.password-conditions')}}
                    </p>
                </SimpleNote>

                <button id="btn-submitSignUp" v-if="!getIsLoadingRegister"
                        class="button xs button-filled button-accent1 m-b-40 w-100"
                        :class="{'button-disable':disabledSignUp}" :disabled="disabledSignUp" @click.prevent="signUp">
                    {{$t('sign-in.register-button')}}
                </button>
                <button type="button" v-if="getIsLoadingRegister"
                        class="button xs button-filled button-accent1 m-b-40 w-100">
                    <LoadingSimple clazz="small-load no-text"/>
                </button>

                <!-- <SocialButtons :span="$t('sign-in.want-save-time-register')">
                    <a id="link-signUpFacebook" class="social-btn fb" tabindex=""
                       :href="`${ localUrl }/auth/facebook/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/facebook-icon.svg" alt="facebook"/>
                        <strong> {{$t('sign-in.facebook')}} </strong>
                    </a>
                    <a id="lnk-signUpTwitter" class="social-btn tw" tabindex=""
                       :href="`${ localUrl }/auth/twitter/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/twitter-icon.svg" alt="twitter"/>
                        <strong> {{$t('sign-in.twitter')}} </strong>
                    </a>
                    <a id="lnk-signUpGoogle" class="social-btn goo" tabindex=""
                       :href="`${ localUrl }/auth/google/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/google-icon.svg" alt="google"/>
                        <strong> {{$t('sign-in.google')}} </strong>
                    </a>
                    <a id="link-signUpappleId" class="social-btn apple" tabindex=""
                       :href="`${ localUrl }/auth/apple/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/apple-icon.svg" alt="google"/>
                        <strong> Apple Id </strong>
                    </a>
                </SocialButtons> -->

                <p class="link-to m-t-60 m-b-40">{{$t('sign-in.already-have-account')}} <a @click="tabIndex--"
                                                                                           class="hover" tabindex="">
                    {{$t('sign-in.login-link')}}</a></p>
            </b-tab>
        </b-tabs>
        <!-- Modal for showing 'info-section' on responsive -->
        <b-modal id="modal-info-responsive" hide-header hide-footer>
            <button
                    id
                    class="button-circle sm button-stroke button-info_text m-0-0-0-auto"
                    @click="$bvModal.hide('modal-info-responsive')"
                    type="button"
            >
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <SignInBody :rightSide="getRightSide" hideRightWizard/>
        </b-modal>

        <!-- Default modal -->
        <ModalDefault
                ref="modal-social-media"
                :customRef="'modal-social-media-default'"
                modalId="modal-social-media-default"
                originalFooter
                title="Autoriza tu acceso"
                subtitle="Por la seguridad de tu cuenta, te pediremos que verifiques tu número telefónico con un Código que te enviaremos vía SMS."
                :buttonText="verified ? 'Entrar a pagando' : 'VERIFICAR'"
                v-on:clickAccepted="verified ? goToLanding() :verifyCode()"
                v-on:clickClose="comeBackUserSearch"
        >
            <BasicInput v-if="getUserPhone !== null && !verified"
                        badge
                        inputType="text"
                        labelFor="celular"
                        inputId="txt-celular"
                        label="Número de celular"
                        :inputValue="maskedPhoneUser !== null ? maskedPhoneUser : 'Número con terminación: ****6948'"
                        :inputPlaceholder="`Número con terminación: ${maskedPhoneUser}`"
                        badgeType="badge badge-success"
                        badgeText="Verificado"
            />
            <h2 class="f-18 c-title body-font-regular text-center f-700 m-b-10">
                {{$t('sign-in.add-security-code')}}
            </h2>
            <p class="f-14 c-plain_text body-font-regular text-center m-0 m-b-20">
                {{$t('sign-in.add-security-code-desc')}} <strong>{{maskedPhoneUser}}</strong>.
            </p>
            <CodeInput v-on:verificationCode="getVerificationCode" :showWarning="verificationError"
                       :warningText="verificationError ? verificationErrorText : '' " clazz="center"></CodeInput>
            <div class="verified-check justify-content-center m-b-25" v-if="verified">
                <i aria-hidden="true" class="banana banana-check-circle1"></i>
                <span>
                    {{$t('sign-in.security-code-valid')}}
                </span>
            </div>

            <p class="f-14 c-plain_text body-font-regular text-center m-b-30" v-if="!verified">
                {{$t('sign-in.your-code')}}
                {{$t('sign-in.no-message')}} <a id="lnk-resendSecCode" class="c-accent_2 text-underline f-bold"
                                                @click="sendVerificationCode" href="#">{{$t('sign-in.resend-code')}}</a>
            </p>
            <p class="f-14 c-plain_text body-font-regular text-center m-b-30" v-if="verified">
                {{$t('sign-in.congrats-your-phone')}} {{maskedPhoneUser}} {{$t('sign-in.phone-verified')}}
            </p>
            <p class="f-14 c-info_text body-font-regular text-center m-0">
                {{$t('local-verification.technical-assistance1')}} <strong>{{$t('local-verification.technical-assistance2')}}</strong>
                {{$t('local-verification.technical-assistance3')}} <strong>{{$t('local-verification.technical-assistance4')}}</strong>
                {{$t('local-verification.technical-assistance5')}}.
            </p>
        </ModalDefault>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import SignInBody from "@/components/SignInBody.vue";
    import GenericTitle from "@/components/GenericTitle.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import PasswordInput from "@/components/PasswordInput.vue";
    // import SocialButtons from "@/components/SocialButtons.vue";
    import SimpleNote from "@/components/SimpleNote.vue";
    import LoadingSimple from "@/components/LoadingSimple.vue";
    import UploadProfileImage from "@/components/UploadProfileImage.vue";
    import config from "@/config";
    import ModalDefault from "@/components/ModalDefault.vue";
    import CodeInput from "@/components/CodeInput.vue";
    import {securityImages} from '@/enums/securityImages';
    import {
        STORE as SESSION_STORE,
        GET_USER
    } from '@/store/modules/session/session.types';
    import {
        STORE as UserSignUpStore,
        VERIFY_CODE,
        SEND_VERIFICATION_CODE
    } from '@/store/modules/users/usersSignupWizardLevelOne.types'
    import usersApi from '@/api/user/users.api';

    const storeModule = 'SIGNIN_UP';
    const signUpOrganizationStore = "signUpOrganization";

    const localUrl = `${config.server.host}:${config.server.port}/api-v2`

    export default {
        name: "SignIn",
        data() {
            return {
                tabIndex: 1,
                registerEmail: '',
                loginEmail: '',
                correctEmail: false,
                correctEmailLogin: false,
                registerPassword: '',
                loginPassword: '',
                error: '',
                clazzPasswordStatus: '',
                labelPasswordStatus: '',
                passwordInputType: 'password',
                passwordInputTypeRegister: 'password',
                checkedTerms: false,
                showStatusBar: false,
                hideButtonLoading: true,
                localUrl: localUrl,
                isVisibleTextPasswordSignIn: false,
                isVisibleTextPasswordRegister: false,
                securityImages: securityImages,
                verificationCode: '',
                verificationError: false,
                verificationErrorText: '',
                verified: false
            }
        },
        props: {
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false}
        },
        created() {
            this.$store.commit(`${signUpOrganizationStore}/SET_ACTIVE_COMPONENT_STEP_ONE`, "SIGN_IN");
            if (this.$route.name === "SigninTab") {
                this.tabIndex = 1;
            } else {
                this.tabIndex = 0;
            }
            this.$store.commit(`${storeModule}/SET_RIGHT_SIDE_BODY`, 'EASY_PAY');
            // Guarda en la sesión los datos para redirigir después del login
            if (this.$route.query.app) {
                this.$session.set('app', this.$route.query.app);
            }
            if (this.$route.query.path) {
                this.$session.set('path', this.$route.query.path);
            }
            if (this.$route.query.message) {
                this.$store.commit(`${storeModule}/SET_LOGIN_ALERT`, true);
                this.$store.commit(`${storeModule}/SET_LOGIN_ALERT_MESSAGE`, this.$route.query.message);
            }
        },
        mounted() {
            if (this.getIsSocialMediaUser) {
                this.$refs['modal-social-media'].showModal();
            }
        },
        components: {
            SignInBody,
            GenericTitle,
            BasicInput,
            PasswordInput,
            // SocialButtons,
            LoadingSimple,
            SimpleNote,
            ModalDefault,
            CodeInput,
            UploadProfileImage
        },
        methods: {
            /**
             * Obtiene el valor ingresado del código mediante el componente CodeInput ya que son varios input y los agrupa en una sola cadena.
             * @param value con el código de verificación.
             */
            getVerificationCode(value) {
                this.verificationCode = value;
            },
            goToLanding() {
                this.$store.commit(`${storeModule}/CLEAR_STORE`);
                this.$router.push({name: "HomeLanding"})
            },
            comeBackUserSearch() {
                localStorage.removeItem('currentUser');
                localStorage.removeItem('currentToken');
                localStorage.removeItem('currentPermissions');
                localStorage.removeItem('profile');
                localStorage.removeItem('expireDate');
                usersApi.logout({});
                this.$store.commit(`${storeModule}/CLEAR_STORE`);
            },
            /**
             *  Verifica que el código de seguridad ingresado coincida con el que se le envio por sms al usuario.
             */
            verifyCode() {
                this.smsSendShowMsg = false;
                this.showErrorCode = false;
                if (this.verificationCode.length < 5) {
                    this.verificationError = true;
                    this.verificationErrorText = 'El código debe llevar 5 dígitos.';
                } else {
                    this.loadingText = "Validando código de seguridad...";
                    this.loading = true;
                    this.verificationError = false;
                    const payload = {
                        idUser: this.actualUser._id,
                        code: this.verificationCode
                    };
                    this.$store.dispatch(`${UserSignUpStore}/${VERIFY_CODE}`, payload).then((result) => {

                        if (!result) {
                            this.verificationError = true;
                            this.verificationErrorText = 'El código de seguridad que escribiste no es válido, escríbelo nuevamente para intentarlo otra vez.';
                        } else {
                            this.verificationError = false;
                            this.verified = true;
                        }
                        this.loading = false;
                    }).catch((err) => {
                        this.showErrorCode = true;
                        this.errorCodeText = err.message;
                        this.loading = false;
                    });
                }
            },
            /**
             * Envia el código de verificación por sms.
             */
            async sendVerificationCode() {
                this.loadingText = "Enviando código de seguridad...";
                this.loading = true;
                this.smsSendShowMsg = false;
                this.showErrorCode = false;

                const payload = {
                    contactPhone: this.getUserPhone
                };
                await this.$store.dispatch(`${UserSignUpStore}/${SEND_VERIFICATION_CODE}`, payload).then((result) => {
                    if (result.error) {
                        this.showErrorCode = true;
                        this.errorCodeText = result.message;
                    } else {
                        this.smsSend = true;
                        this.smsSendShowMsg = true;
                    }
                }).catch((err) => {
                    this.showErrorCode = true;
                    this.errorCodeText = err.message;
                });
                this.loading = false;
            },
            signUp() {
                const credentials = {
                    email: this.registerEmail,
                    password: this.registerPassword
                };
                this.hideButtonLoading = false;
                this.$store.dispatch(`${storeModule}/signUp`, {credentials});
            },
            onBasicInputRegister(email) {
                const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm;
                this.correctEmail = regexEmail.test(email);
                this.registerEmail = email;
            },
            onBasicInputLogin(email) {
                const regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm;
                this.correctEmailLogin = regexEmail.test(email);
                this.loginEmail = email;
            },
            onPasswordChangeLogin(password) {
                this.loginPassword = password;
            },
            changeTypePassword(isVisibleOn) {
                if (isVisibleOn) {
                    this.passwordInputType = 'password';
                } else {
                    this.passwordInputType = 'text';
                }
            },
            changeTypePasswordRegister(isVisibleOn) {
                if (isVisibleOn) {
                    this.passwordInputTypeRegister = 'password';
                } else {
                    this.passwordInputTypeRegister = 'text';
                }
            },
            clientRef() {
                return config.clients.host === "http://localhost" ? `${config.clients.host}:${config.clients.port}/client-admin` : `https://${config.clients.host}/client-admin`;
            },
            getPositivePoints(password) {
                const numberOfCharacters = password.length;
                const uppercaseLetters = (password.match(/[A-Z]/g) || []).length;
                const lowercaseLetters = (password.match(/[a-z]/g) || []).length;
                const numberOnPassword = (password.match(/[0-9]/g, "") || []).length;
                const symbols =
                    numberOfCharacters -
                    uppercaseLetters -
                    lowercaseLetters -
                    numberOnPassword;

                let middleSymbolsNumbers = symbols + numberOnPassword;
                if (numberOfCharacters > 2 && (symbols > 0 || numberOnPassword > 0)) {
                    if (password[0].match(/[a-zA-Z]/g) == null) {
                        middleSymbolsNumbers -= 1;
                    }

                    if (password[numberOfCharacters - 1].match(/[a-zA-Z]/g) == null) {
                        middleSymbolsNumbers -= 1;
                    }
                    if (middleSymbolsNumbers < 0) {
                        middleSymbolsNumbers = 0;
                    }
                }

                let requirements = 0;
                if (
                    numberOfCharacters > 6 &&
                    uppercaseLetters > 0 &&
                    lowercaseLetters > 0 &&
                    numberOnPassword > 0 &&
                    symbols > 0
                ) {
                    requirements = 10;
                }

                return (
                    numberOfCharacters * 4 +
                    (numberOfCharacters - uppercaseLetters) * 2 +
                    (numberOfCharacters - lowercaseLetters) * 2 +
                    numberOnPassword * 4 +
                    symbols * 6 +
                    middleSymbolsNumbers * 2 +
                    requirements
                );
            },

            onPasswordChangeRegister(password) {
                this.registerPassword = password;
                let positivePoints = this.getPositivePoints(password);
                if (/^[a-zA-Z]+$/.test(password)) {
                    positivePoints = positivePoints - password.length;
                }

                if (/^\d+$/.test(password)) {
                    positivePoints = positivePoints - password.length;
                }

                positivePoints += this.getRepeatCharacter(password);

                const groups = password.match(/[A-Z]+|[a-z]+|[0-9]+/g);
                if (groups) {
                    const groupsFilter = groups.filter(function (element) {
                        return element.length >= 2;
                    });

                    let consecutiveCh = 0;
                    for (const group of groupsFilter) {
                        consecutiveCh += (group.length - 1) * 2;
                    }

                    positivePoints -= consecutiveCh;
                }

                positivePoints -= this.sequential(password);

                positivePoints -= this.sequentialSymbols(password);

                if (positivePoints <= 0 && password.length > 0) {
                    positivePoints = 1;
                }

                if (positivePoints === 0) {
                    this.clazzPasswordStatus = "";
                    this.labelPasswordStatus = "";
                    this.showStatusBar = false;
                } else if (positivePoints < 40) {
                    this.clazzPasswordStatus = "weak";
                    this.labelPasswordStatus = "Muy debíl";
                    this.showStatusBar = true;
                } else if (positivePoints < 60) {
                    this.clazzPasswordStatus = "regular";
                    this.labelPasswordStatus = "Regular";
                    this.showStatusBar = true;
                } else if (positivePoints < 80) {
                    this.clazzPasswordStatus = "good";
                    this.labelPasswordStatus = "Buena";
                    this.showStatusBar = true;
                } else {
                    this.clazzPasswordStatus = "excellent";
                    this.labelPasswordStatus = "Excellente";
                    this.showStatusBar = true;
                }
            },
            getRepeatCharacter(password) {
                const pattern = /(.+)(?=.*?\1)/g;
                let score = 0;
                if (pattern.test(password)) {
                    const matches = password.match(pattern);
                    const maxResultLength = this.sortByLength(matches)[0].length;
                    let ratio = 0;
                    if (maxResultLength >= 1 && maxResultLength <= 5){
                        ratio = -8;
                    }
                    if (maxResultLength >= 6 && maxResultLength <= 10){
                        ratio = -5;
                    }
                    if (maxResultLength >= 11){
                        ratio = -2;
                    }
                    score = ratio * maxResultLength + (password.length - maxResultLength * 2);
                    if (score > 0) {
                        score = 0;
                    }
                }
                return score;
            },
            nextChar(c) {
                return String.fromCharCode(c.charCodeAt(0) + 1);
            },
            prevChar(c) {
                return String.fromCharCode(c.charCodeAt(0) - 1);
            },

            // eslint-disable-next-line sonarjs/cognitive-complexity
            sequential(password) {
                const passwordLowercase = password.toLowerCase();
                let groups = passwordLowercase.match(/[A-Z]+|[a-z]+|[0-9]+/g);
                let score = 0;
                if (groups) {
                    groups = groups.filter(function (element) {
                        return element.length >= 3;
                    });

                    for (const group of groups) {
                        let splitGroup = [];
                        for (let index = 0; index < group.split("").length - 2; index++) {
                            splitGroup.push(group.substring(index, index + 3));
                        }

                        splitGroup = [...new Set(splitGroup)];

                        for (const splitGroupElement of splitGroup) {
                            let point = 0;
                            for (
                                let index = 0;
                                index < splitGroupElement.split("").length - 1;
                                index++
                            ) {
                                if (splitGroupElement[index] < splitGroupElement[index + 1]) {
                                    if (
                                        this.nextChar(splitGroupElement[index]) ===
                                        splitGroupElement[index + 1]
                                    ) {
                                        point += 0.5;
                                    } else {
                                        break;
                                    }
                                } else {
                                    break;
                                }
                            }

                            if (point === 1) {
                                score += 1;
                            }
                        }

                        for (const splitGroupElement of splitGroup) {
                            let point = 0;
                            for (
                                let index = 0;
                                index < splitGroupElement.split("").length - 1;
                                index++
                            ) {
                                if (splitGroupElement[index] > splitGroupElement[index + 1]) {
                                    if (
                                        this.prevChar(splitGroupElement[index]) ===
                                        splitGroupElement[index + 1]
                                    ) {
                                        point += 0.5;
                                    } else {
                                        break;
                                    }
                                } else {
                                    break;
                                }
                            }

                            if (point === 1) {
                                score += 1;
                            }
                        }
                    }
                }
                return score * 3;
            },
            sequentialSymbols(password) {
                const groups = password.match(/[^a-zA-z0-9]+/g);
                let score = 0;
                if (groups) {
                    let triGroups = [];

                    for (const group of groups) {
                        triGroups = triGroups.concat(group.match(/.{1,3}/g));
                    }

                    triGroups = [...new Set(triGroups)];
                    triGroups = triGroups.filter(function (element) {
                        return element.length === 3;
                    });

                    score = triGroups.length;
                }

                return score * 3;
            },
            sortByLength(arr, limit) {
                arr.sort(function (a, b) {
                    return b.length - a.length;
                });
                const list = [];
                for (let index = 0; index < arr.length; index++) {
                    if (limit) {
                        if (arr[index].length >= limit) {
                            list.push(arr[index]);
                        }
                    } else {
                        list.push(arr[index]);
                    }
                }
                return list;
            },
            login() {
                const _session = this.$session;
                const _store = this.$store;
                const credentials = {
                    email: this.loginEmail,
                    password: this.loginPassword
                }
                this.hideButtonLoading = false;
                this.$store.dispatch(`${storeModule}/login`, {credentials, _session, _store});
            },
            getUserByEmail() {
                const data = {
                    email: this.loginEmail
                }
                this.hideButtonLoading = false;
                this.$store.dispatch(`${storeModule}/getUserByEmail`, data);
            },
            getImageById(id) {
                let src = undefined;
                this.securityImages.forEach(i => {
                    if (i.id === id) {
                        src = i.src
                    }
                })
                return src;
            }
        },
        computed: {
            disabledSignUp() {
                return !(this.registerPassword.length >= 6 && this.correctEmail);
            },
            disabledUserFound() {
                return !(this.correctEmailLogin);
            },
            disabledSignIn() {
                return !(this.loginPassword.length >= 6 && this.correctEmailLogin);
            },
            ...mapGetters(storeModule, [
                "getShowAlert",
                "getAlertMessage",
                "getIsLoadingRegister",
                "getAlertVariant",
                "getLoginBlocked",
                "getLoginAlert",
                "getIsLoadingLogin",
                "getRightSide",
                "getUserFound",
                "getUserPhrase",
                "getUserImageId",
                "getUserFullName",
                "getIsSocialMediaUser",
                "getUserPhone",
                "getLoginAlertMessage"
            ]),
            maskedPhoneUser() {
                if (this.getUserPhone !== undefined && this.getUserPhone !== null) {
                    const lastFour = this.getUserPhone.substr(this.getUserPhone.length - 4);
                    return `******${lastFour}`
                }
                return "**********"
            },
            ...mapGetters(SESSION_STORE, {
                actualUser: GET_USER
            }),
        }
    }
</script>
