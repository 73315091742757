import {
    // STORE as LandingStore,
    GET_TAB_ACTIVE,
    SET_TAB_ACTIVE
} from "./landing.types";
/**
 *
 * Store del Sign Up de una organización
 */
const stateGlobal = {
    activeTab: "HOME"
};

const getters = {
    [GET_TAB_ACTIVE]: (state) => state.activeTab,

};

const actions = {};

const mutations = {
    [SET_TAB_ACTIVE](state, value){
        state.activeTab = value
    }
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    }
};
