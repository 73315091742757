<template>
    <div class="row">
        <div class="col-12">
            <router-link
                to="/client-admin/api-rest"
                class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                tabindex="">
                <i aria-hidden="true" class="banana banana-home c-accent_2"></i>
                Panel de Control
            </router-link>
            <FloatingTitleLayout title="Transacciones"/>
        </div>
        <div class="col-12">
            <SearchBar showDateRange></SearchBar>
        </div>
        <div class="col-12">
            <CardSimple
            clazz="w-100 p-0"
            hideTitle>
                <EmptyState
                    title="¡Aún no cuentas con transacciones!"
                    text="Si aún no te aparecen transacciones te invitamos a crear nuevos
                    servicios que puedas ofrecer a tus clientes para que empieces a ver aquí tus pagos recaudados."
                    :imgUrlHorizontal="ImagenEmptyStateNew"
                >
                    <button class="button xs button-filled button-accent1">
                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                        IR A TIPOS DE PAGOS
                    </button>
                </EmptyState>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple
                clazz="w-100 p-0"
                hideTitle
            >
                <div class="basic-table menu-overflow-fix">
                    <b-table-simple
                        responsive
                        selectable
                        :select-mode="selectMode"
                        selected-variant="active"
                        table-variant="xl"
                    >
                        <!-- Add classes 'b-table-row-selected table-active' to 'b-tbody > b-tr' when a row checkbox selected.
                    If MORE THAN ONE row CHECKBOX is selected, add to 'b-thead > b-tr' class 'header-options-full'. -->
                        <b-thead>
                            <b-tr class="">
                                <b-th>
                                    <div class="d-flex align-items-c">
                                        <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                        <span
                                            class="open-filters"
                                            @click="headerFilters()"
                                        > Folio </span>
                                        <SortIcon></SortIcon>
                                    </div>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            labelFor=""
                                            inputId=""
                                            inputPlaceholder="ej. 212200"
                                            clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th>
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    > Usuario </span>
                                    <SortIcon></SortIcon>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            labelFor=""
                                            inputId=""
                                            inputPlaceholder="Ej: Luis Pérez"
                                            clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th>
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    > Concepto de Pago </span>
                                    <SortIcon></SortIcon>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            labelFor=""
                                            inputId=""
                                            inputPlaceholder="Ej: Paquete de..."
                                            clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th>
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    > Fecha </span>
                                    <SortIcon></SortIcon>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            labelFor=""
                                            inputId=""
                                            inputPlaceholder="Buscar..."
                                            clazz="noLabel m-b-0"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c">
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    > Unidades </span>
                                    <SortIcon></SortIcon>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            labelFor=""
                                            inputId=""
                                            inputType="number"
                                            inputPlaceholder="Ej: 1..."
                                            clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-c">
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    > Status </span>
                                    <SortIcon></SortIcon>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            labelFor=""
                                            inputId=""
                                            inputType="number"
                                            inputPlaceholder="Ej: 1..."
                                            clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="text-align-r">
                                    <span
                                        class="open-filters"
                                        @click="headerFilters()"
                                    >Cantidad</span>
                                    <div
                                        class="filters"
                                        :class="[filtersHeader ? 'show' : '']"
                                    >
                                        <BasicInput
                                            labelFor=""
                                            inputId=""
                                            inputType="number"
                                            inputPlaceholder="Ej: $2,300.00"
                                            clazz="noLabel"
                                        ></BasicInput>
                                    </div>
                                </b-th>
                                <b-th class="w-5 text-align-c"> Detalle</b-th>
                                <b-th class="row-options">
                                    <div class="vertical-center">
                                        <BasicCheckbox
                                            clazz="m-0 w-fit di-flex"
                                            text="2 elementos seleccionados"
                                            textClass="f-14 c-primary_1 principal-font-bold"
                                        ></BasicCheckbox>
                                        <div>
                                            <a
                                                class="opt-row"
                                                tabindex=""
                                            ><i aria-hidden="true" class="banana banana-delete"></i>
                                                Eliminar </a>
                                            <a
                                                class="opt-row"
                                                tabindex=""
                                            ><i aria-hidden="true" class="banana banana-download"></i>
                                                Descargar documentos </a>
                                            <a
                                                class="opt-row"
                                                tabindex=""
                                            > Cancelar </a>
                                        </div>
                                    </div>
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr class="b-table-row-selected table-active">
                                <b-td>
                                    <div class="vertical-center">
                                        <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                        <router-link
                                            to=""
                                            class="c-primary_1"
                                        >
                                            <strong>23164</strong></router-link>
                                    </div>
                                </b-td>
                                <b-td> Carlos Sanchez</b-td>
                                <b-td> Paquete Amigo Sin Limite Telcel</b-td>
                                <b-td> 24/04/2019 - 08:45 a.m.</b-td>
                                <b-td class="text-align-c">
                                    <Badge
                                        type="badge-accent_2"
                                        text="1"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-c">
                                    <Badge
                                        type="badge-success"
                                        text="aceptada"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-r"><strong class="c-accent_2"> $2,800.00
                                        (MXN) </strong></b-td>
                                <b-td class="w-5 text-align-c">
                                    <button class="button-circle xs button-ghost button-info_text m-0-auto">
                                        <i
                                            aria-hidden="true"
                                            class="banana banana-visibility-on"
                                        ></i></button>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="vertical-center">
                                        <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                        <router-link
                                            to=""
                                            class="c-primary_1"
                                        >
                                            <strong>23156</strong></router-link>
                                    </div>
                                </b-td>
                                <b-td> Edgar Iram Gonzalez</b-td>
                                <b-td> Inscripcion escolar 2020 Montesori</b-td>
                                <b-td> 09/03/2019 - 20:26 p.m.</b-td>
                                <b-td class="text-align-c">
                                    <Badge
                                        type="badge-accent_2"
                                        text="100"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-c">
                                    <Badge
                                        type="badge-error"
                                        text="fraudulenta"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-r"><strong class="c-accent_2"> $9,700.00
                                        (MXN) </strong></b-td>
                                <b-td class="w-5 text-align-c">
                                    <button class="button-circle xs button-ghost button-info_text m-0-auto">
                                        <i
                                            aria-hidden="true"
                                            class="banana banana-visibility-on"
                                        ></i></button>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    <div class="vertical-center">
                                        <BasicCheckbox clazz="m-0 w-fit di-flex"></BasicCheckbox>
                                        <router-link
                                            to=""
                                            class="c-primary_1"
                                        >
                                            <strong>23156</strong></router-link>
                                    </div>
                                </b-td>
                                <b-td> Edgar Iram Gonzalez</b-td>
                                <b-td> Inscripcion escolar 2020 Montesori</b-td>
                                <b-td> 09/03/2019 - 20:26 p.m.</b-td>
                                <b-td class="text-align-c">
                                    <Badge
                                        type="badge-accent_2"
                                        text="100"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-c">
                                    <Badge
                                        type="badge-warning"
                                        text="rechazada"
                                    ></Badge>
                                </b-td>
                                <b-td class="text-align-r"><strong class="c-accent_2"> $9,700.00
                                        (MXN) </strong></b-td>
                                <b-td class="w-5 text-align-c">
                                    <button class="button-circle xs button-ghost button-info_text m-0-auto">
                                        <i
                                            aria-hidden="true"
                                            class="banana banana-visibility-on"
                                        ></i></button>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </CardSimple>
            <div class="pagination-custom">
                <span class="pagination-count">58 Transacciones</span>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    next-text
                    prev-text
                    hide-goto-end-buttons
                ></b-pagination>
            </div>
        </div>
        <div class="col-12">
            <button
                id=""
                class="button xs button-filled button-warning m-r-10 m-b-10"
                @click="$bvModal.show('modal-transaccion')"
                type="button"
            >
                Modal ver transaccion
            </button>
            <button
                id=""
                class="button xs button-filled button-warning m-r-10 m-b-10"
                @click="$bvModal.show('modal-dev')"
                type="button"
            >
                Modal Devolucion
            </button>
        </div>
        <ModalDefault
            modalId="modal-transaccion"
            size="lg">
            <PaymentInfoRow
                organization="Pago con Requisitos"
                paymentName="Inscripción escolar Montessori"
                date="7 Marzo, 2019 - 11:36 a.m."
                cant="$3,000.00"
                clazz="m-b-40 m-t--10"
                showFolio
                number="13129"
                :showCoin="false"
            >
            </PaymentInfoRow>

            <b-tabs
                class="simple-tabs group-tabs"
                content-class="simple-tabs-body p-t-30 min-height-300px"
                active-tab-class="primary-bg">
                <b-tab
                    title="Datos del Usuario"
                    active
                >

                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Usuario
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                Carlos Alfredo Perez
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Correo electrónico
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                carlos_pe@gmail.com
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Teléfono
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                <strong>+52</strong> (614)-198-8471
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Datos del Pago">
                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Matrícula
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                288287
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                CURP
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                CGSA150487HCHNMS05
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Notas Adicionales">
                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Ingresa el taller de tu preferencia
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                Danza contemporánea
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Detalles de Transacción">
                    <div class="dots-table m-b-30">
                        <div class="item-row">
                            <div class="left-side">
                                Tipo de Tarjeta
                            </div>
                            <div class="right-side">
                                Tarjeta de Crédito
                                <img class="w-30px" src="@/assets/images/Logos/Cards/visa.svg" alt="">
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                Subtotal
                            </div>
                            <div class="right-side f-bold">
                                $3,000.00
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                Descuentos
                            </div>
                            <div class="right-side c-success f-bold">
                                - $500.00
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                Total
                            </div>
                            <div class="right-side f-bold">
                                $2,500.00
                            </div>
                        </div>
                        <div class="item-row">
                            <div class="left-side">
                                Comisión bancaria
                            </div>
                            <div class="right-side f-bold">
                                $87.00
                            </div>
                        </div>
                    </div>
                    <div class="d-lg-flex align-items-c justify-space-between">
                        <div class="d-flex align-items-c ">
                            <div class="c-primary_1 principal-font-bold f-13">
                                Estatus Transacción
                            </div>
                            <Badge
                                type="badge-error m-l-10"
                                text="fraudulenta"
                            ></Badge>
                        </div>
                        <div class="d-flex align-items-c ">
                            <div class="principal-font-bold f-13 c-primary_1 m-r-20">
                                TOTAL A DEPOSITAR
                            </div>
                            <div class="body-font-bold f-28 c-accent_2">
                                $2,413.00
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab
                    title="Devolución"
                >

                    <div class="row">
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Fecha de la devolución
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                10 Marzo, 2019
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Monto devuelto
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                $2,000.00 MXN
                            </div>
                        </div>
                        <div class="col-sm-6 col-12 m-b-30">
                            <h1 class="c-primary_1 f-13 principal-font-bold m-b-5">
                                Motivo de la devolución
                            </h1>
                            <div class="m-0 f-14 f-14 c-plain_text body-font-regular">
                                El comprador fue víctima de fraude
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>

            <template v-slot:customFooter>
                <div class="w-100">
                    <div class="row">
                        <div class="col-12 m-t-0 vertical-center justify-content-end">
                            <button type="button"
                                    class="button xs button-stroke button-accent1 m-0-auto-0-0">
                                    HACER DEVOLUCIÓN
                            </button>
                            <div class="vertical-center">
                                <button type="button"
                                        class="button xs button-ghost button-plain_text">
                                        Cerrar
                                </button>
                                <button type="button"
                                        class="button xs button-filled button-accent1 m-l-10">
                                        <i aria-hidden="true" class="banana banana-download"></i>
                                        DESCARGAR COMPROBANTE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ModalDefault>
        <ModalDefault
            modalId="modal-dev"
            size="lg"
            buttonText="Aceptar"
            buttonClose="cancelar"
            originalFooter
            title="Hacer devolución"
            subtitle="Para realizar una devolución por esta compra es necesario que ingreses el motivo y el monto a devolver.
            Recuerda no ingresar un monto mayor al monto pagado por el cliente."
        >
            <div class="row">
                <div class="col-12">
                    <BasicSelect
                        required
                        label="Motivo de la devolución"
                        labelFor
                    >

                        <multiselect
                            v-model="value"
                            :options="options"
                            :close-on-select="true"
                            :preserve-search="true"
                            placeholder="Selecciona un motivo..."
                            label="name"
                            track-by="name"
                            :preselect-first="false"
                        >
                            <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                            >
                                <span
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>
                </div>
                <div class="col-12 m-b-20">
                    <RadioInput
                        clazz="m-b-30"
                        id="one"
                        for="one"
                    >
                        <div class="c-plain_text f-14 body-font-regular">
                            <strong>Devolución total:</strong>
                            Devuelve el monto total al comprador
                        </div>
                    </RadioInput>
                    <RadioInput
                        id="two"
                        for="two"
                    >
                        <div class="c-plain_text f-14 body-font-regular">
                            <strong>Devolución parcial:</strong>
                            Devuelve al comprador solo una parte del monto total
                        </div>
                    </RadioInput>
                </div>
                <div class="col-md-9 col-6">
                    <BasicInput
                        labelFor=""
                        label="Monto"
                        required
                        inputId=""
                        inputType="number"
                        inputPlaceholder="$0.00"
                        clazz="noLabel"
                        disabled
                    ></BasicInput>
                </div>
                <div class="col-md-3 col-6">
                    <BasicSelect
                        required
                        label="Moneda"
                        labelFor
                    >

                        <multiselect
                            v-model="value"
                            :options="options"
                            :close-on-select="true"
                            :preserve-search="true"
                            placeholder="MXN"
                            label="name"
                            track-by="name"
                            :preselect-first="false"
                            disabled
                        >
                            <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                            >
                                <span
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} options selected</span>
                            </template>
                        </multiselect>
                    </BasicSelect>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
    import ImagenEmptyStateNew from '@/assets/images/Emptystates/emptystate-collectedPayment.svg';
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue';
    import EmptyState from '@/components/EmptyState.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import SearchBar from '@/components/SearchBar.vue';
    import BasicCheckbox from '@/components/BasicCheckbox.vue';
    import Badge from '@/components/Badge.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import SortIcon from '@/components/SortIcon.vue';
    import ModalDefault from '@/components/ModalDefault.vue';
    import PaymentInfoRow from '@/components/PaymentInfoRow.vue';
    import BasicSelect from '@/components/BasicSelect.vue';
    import RadioInput from '@/components/RadioInput.vue';
    import Multiselect from "vue-multiselect";

    export default {
        name: "TransactionsList",
        components: {
            FloatingTitleLayout,
            EmptyState,
            CardSimple,
            SearchBar,
            BasicCheckbox,
            Badge,
            BasicInput,
            SortIcon,
            ModalDefault,
            PaymentInfoRow,
            BasicSelect,
            Multiselect,
            RadioInput
        },
        data() {
            return {
                ImagenEmptyStateNew: ImagenEmptyStateNew,
                value: [],
                options: [
                    { name: "Option 1" },
                    { name: "Option 2" },
                    { name: "Option 3" }
                ],
            }
        },
        methods: {
        },
        mounted() {
        }
    };
</script>
