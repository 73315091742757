<template>
    <ul class="simple-timeline-info" v-bind:class="clazz">
        <slot></slot>
    </ul>
</template>

<script>
    export default {
        name: 'SimpleTimelineInfo',
        props: {
            clazz: String
        }
    }
</script>

<style lang="scss">
    .simple-timeline-info {
        position: relative;
        display: block;
        padding: 0 0 0 5px;
        margin: 0;
        list-style: none;
        max-height: 274px;

        > p {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.29;
            display: block;
            margin-bottom: 0px;
        }
    }
</style>
