import BankApi from "@/api/bank/bank.api";
import {
    GET_BANKS,
    SET_BANKS,
    FETCH_BANKS
  } from "./banks.types";

  const stateGlobal = {
    banks: []
  };

  const getters = {
    [GET_BANKS]: state => state.banks,
  };

  const mutations = {
    [SET_BANKS]: (state, payload) => {
      state.banks = payload;
    }
};

const actions = {
    /**
     * Fetches all the banks currently available in the database
     */
    [FETCH_BANKS]: ({ commit }) => {
      return BankApi.all().then(data => commit(SET_BANKS, data.data.object));
    }
};

const namespaced = true;
export default { namespaced, state: stateGlobal, getters, actions, mutations };
