<template>
  <div class="row" v-bind:class="clazz">
    <div class="col-lg-7 col-12">
      <div class="b-left-3px border-accent_2 p-l-10">
        <h2 class="f-14 c-plain_text m-b-0 body-font-regular">
          {{ organization }}
        </h2>
        <h1 class="principal-font-bold f-22 c-primary_1 m-b-5">
          {{ paymentName }}
        </h1>
        <div class="vertical-center">
          <Badge
            v-if="showCategory"
            type="badge-accent_2 m-r-5"
            v-bind:text="$t(`organizaciones.category.options.${category}`)"
          >
            <i aria-hidden="true" class="banana banana-tag m-r-5"></i>
          </Badge>
          <Badge
            v-if="showCoin"
            type="badge-accent_2 m-r-5"
            v-bind:text="exchange"
          ></Badge>
          <div
            v-if="showFolio"
            class="f-14 c-plain_text m-b-0 body-font-regular m-r-20"
          >
            Folio: <strong>{{ number }}</strong>
          </div>
          <div class="f-14 c-plain_text m-b-0 body-font-regular m-r-20">
            <strong>{{ date }}</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-12">
      <div class="height-20">
        <slot name="details-link"></slot>
      </div>
      <h2 class="f-14 c-plain_text m-b-0 body-font-regular text-right f-bold">
        Precio del servicio
      </h2>
      <h1 class="f-28 c-accent_2 m-b-0 body-font-bold text-right">
        {{ cant }}
      </h1>
    </div>
  </div>
</template>

<script>
import Badge from "@/components/Badge.vue";
export default {
  name: "PaymentInfoRow",
  components: {
    Badge,
  },
  props: {
    clazz: String,
    organization: String,
    paymentName: String,
    category: String,
    date: String,
    exchange: String,
    cant: String,
    number: String,
    showFolio: {
      type: Boolean,
      default: false,
    },
    showCategory: {
      type: Boolean,
      default: false,
    },
    showCoin: { type: Boolean, default: true },
  },
};
</script>
