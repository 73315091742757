import axios from 'axios'
const namespace = "image";

export default {
    upload: (file, params, onSuccess, onError) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("temp", !!params.temp);
        formData.append("classification", params.organizations || 'organizations');

        return axios
            .post(`${namespace}/upload`, formData, {headers: { "Content-Type": "multipart/form-data" }})
            .then(onSuccess)
            .catch(onError);
    },
    delete: (file, onSuccess, onError) => {
        return axios
            .post(`${namespace}/delete/${file._id || file}`)
            .then(onSuccess)
            .catch(onError);
    }
};
