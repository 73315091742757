const BUTTONS = {
    SHAPES: {
        BUTTON: 'button',
        CIRCLE: 'button-circle',
    },
    SIZES: {
        EXTRA_SMALL: 'xs',
        SMALL: 'sm',
    },
    KINDS: {
        FILLED: 'button-filled',
        STROKE: 'button-stroke',
        GHOST: 'button-ghost',
    },
    COLORS: {
        PRIMARY1: 'button-primary1',
        PRIMARY2: 'button-primary2',
        ACCENT1: 'button-accent1',
        ACCENT2: 'button-accent2',
        TITLE: 'button-title',
        PLAIN_TEXT: 'button-plain_text',
        INFO_TEXT: 'button-info_text',
        PLACEHOLDER: 'button-placeholder',
        BLACK: 'button-black',
        GRAY: 'button-gray',
        DISABLE: 'button-disable',
        LIGHT_GRAY: 'button-light_gray',
        WHITE: 'button-white',
        ERROR: 'button-error',
        SUCCESS: 'button-success',
        WARNING: 'button-warning',
        INFO: 'button-info',
    },
};

BUTTONS.SHAPES._VALUES = [
    BUTTONS.SHAPES.BUTTON,
    BUTTONS.SHAPES.CIRCLE,
];

BUTTONS.SIZES._VALUES = [
    BUTTONS.SIZES.EXTRA_SMALL,
    BUTTONS.SIZES.SMALL,
];

BUTTONS.KINDS._VALUES = [
    BUTTONS.KINDS.FILLED,
    BUTTONS.KINDS.STROKE,
    BUTTONS.KINDS.GHOST,
];

BUTTONS.COLORS._VALUES = [
    BUTTONS.COLORS.PRIMARY1,
    BUTTONS.COLORS.PRIMARY2,
    BUTTONS.COLORS.ACCENT1,
    BUTTONS.COLORS.ACCENT2,
    BUTTONS.COLORS.TITLE,
    BUTTONS.COLORS.PLAIN_TEXT,
    BUTTONS.COLORS.INFO_TEXT,
    BUTTONS.COLORS.PLACEHOLDER,
    BUTTONS.COLORS.BLACK,
    BUTTONS.COLORS.GRAY,
    BUTTONS.COLORS.DISABLE,
    BUTTONS.COLORS.LIGHT_GRAY,
    BUTTONS.COLORS.WHITE,
    BUTTONS.COLORS.ERROR,
    BUTTONS.COLORS.SUCCESS,
    BUTTONS.COLORS.WARNING,
    BUTTONS.COLORS.INFO,
];

export default BUTTONS;
