<template>
    <div class="file-uploaded-info" :class="clazz">
        <i aria-hidden="true" class="banana" :class="icon"></i>
        <span>
            <label v-if="!hideFileName"> {{fileName}} </label>

            <label v-if="hideFileName">
                <slot name="loadingBar"></slot>
            </label>

            <div>
                <slot name="badgeInfo"></slot>

                <small>
                    {{fileSize}}
                    <slot name="loadingInfo"></slot>
                </small>
            </div>
        </span>
        <button
            v-if="!hideDeleteButton"
            type="button"
            name=""
            class="button-circle sm button-ghost button-error"
            @click="onClick"
        >
            <i
                aria-hidden="true"
                class="banana banana-delete"
            >
            </i>
        </button>
        <button
            v-if="hideDeleteButton"
            type="button"
            name=""
            class="button-circle sm button-ghost button-error"
        >
            <i
                aria-hidden="true"
                class="banana banana-cross-normal"
            >
            </i>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'FileUploadedInfo',
        props: {
            clazz: String,
            icon: String,
            fileName: String,
            fileSize: String,
            hideFileName: {type: Boolean, default: false},
            hideDeleteButton: {type: Boolean, default: false}
        },
        methods: {
            onClick() {
                this.$emit('click');
            },
        },
    }
</script>

<style lang="scss">
    .file-uploaded-info {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        > i {
            width: 40px;
            height: 40px;
            min-width: 40px;
            border-radius: 4px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            margin-right: 15px;
        }

        > span {
            margin-right: 15px;
            display: inline-block;
            position: relative;
            overflow: hidden;
            width: 100%;

            > label {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.29;
                display: block;
                margin-bottom: 2px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            > div {
                display: flex;
                align-items: center;
                width: 100%;
            }

            > div > small {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.5;
                display: block;
                margin-bottom: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
</style>
