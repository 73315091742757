<template>
    <div id="dct-uploadArea" class="upload-area" :class="clazz">
        <span v-if="!imgUploaded" :class="clazzArea">
            <i aria-hidden="true" class="banana banana-symbol-plus"></i>
            <input type="file" multiple class="input-file" value="" :id="inputId" :ref="inputId" :name="inputId" @change="fileUpload()"/>
        </span>
        <div v-if="imgUploaded" class="item-img-uploaded">
            <img class="img-fluid" :src="imgUploaded" alt="" />
            <button v-if="!hideButtonClose" class="delete-img" tabindex=""><i aria-hidden="true" class="banana banana-cross-normal"></i></button>
            <span v-if="hideButtonClose" class="num-list">{{numList}}</span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'UploadArea',
        props: {
            inputId: {type: String, default: 'file-upload'},
            clazz: String,
            clazzArea: String,
            imgUploaded: {type: String, default: null},
            numList: Number,
            hideButtonClose: {
                type: Boolean, default: false
            }
        },
        data() {
            return {

            }
        },
        methods: {
            fileUpload() {
                this.$emit('change', this.$refs[this.inputId].files);
            }
        }
    }
</script>

<style lang="scss">
.upload-area {
    position: relative;
    display: flex;
    align-items: center;

    > span {
        position: relative;
        width: 70px;
        min-width: 70px;
        height: 70px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        cursor: pointer;
        overflow: hidden;
        transition: 0.5s;

        >  i {
            max-width: 100% !important;
        }

        &:hover > i {
            animation: 1s spin ease-in-out;
        }
    }

    .item-img-uploaded {
        width: 50px;
        height: 50px;
        min-width: 50px;
        max-width: 50px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        &:hover > .delete-img {
            opacity: 1;
            z-index: 2;
        }

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }

        > .delete-img,
        > .num-list {
            width: 25px;
            height: 25px;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            z-index: -1;
            opacity: 0;
            transition: 0.5s;
        }

        > .num-list {
            border-top-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            top: inherit;
            bottom: 0;
            opacity: 1;
            z-index: 2;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
        }
    }

    &.max-area span,
    &.max-area .item-img-uploaded {
        width: 100%;
        max-width: 100%;
        min-height: 120px;
    }
}
</style>
