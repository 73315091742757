import router from '../../router'
import userApi from '../../api/user/users.api';

const stateGlobal = {
    currentEmail: '',
    recoveryAlert: false,
    isLoadingRecovery: false,
    alertMessage: {
        variant: '',
        message: ''
    }
};

const getters = {
    getCurrentEmail: (state) => state.currentEmail,
    getRecoveryAlert: (state) => state.recoveryAlert,
    getAlertMessage: (state) => state.alertMessage
};

const actions = {

    async recoveryPassword({ commit }, data) {
        commit('SET_RECOVERY_ALERT', false);
        commit('SET_LOADING_RECOVERY', true)
        const response = await userApi.recoveryPassword({ email: data.email, token: data.token });
        if (response.data.error) {
            commit('SET_RECOVERY_ALERT', true);
            commit(`REGISTER_ERROR`, response.data.message);
            router.push('/signin')
        } else {
            commit(`SET_ALERT_MESSAGE`, {
                variant: "success",
                message: response.data.message
            });
            const email = response.data.email;
            commit('SET_EMAIL_SUCCESS_REGISTER', email);
            router.push({ name: "SigninRecoveryEmail", params: { email: email } })
        }
    },

    async resendRestorePassword({ commit }, email) {
        commit('SET_RECOVERY_ALERT', false);
        const response = await userApi.resendRestorePasswordEmail({ email: email });
        commit('SET_RECOVERY_ALERT', true);

        if (response.data.error) {
            commit(`REGISTER_ERROR`, response.data.message);
            commit(`SET_ALERT_MESSAGE`, {
                variant: "error",
                 message: 'Ocurrió un error al reenviar correo'
             });
        } else {
            commit(`SET_ALERT_MESSAGE`, {
               variant: "success",
                message: response.data.message
            });
        }
    },

    async confirmRestorePassword({ commit }, data) {
        const response = await userApi.confirmPasswordRestore({token: data.token, password: data.password, confirmPassword: data.confirmPassword });
        if (response.data.error) {
            commit(`REGISTER_ERROR`, response.data.message);
            commit('SET_RECOVERY_ALERT', true);
            const email = response.data.email;
            if (response.data.passwordError) {
                router.push({ name: "SigninNewPassword", params: { email: email }})
            }
            /**
             * This line of code is commented because if there is an error with the password, the user should
             * not continue until the password is OK.
             */
            // router.push({ name: "SigninPasswordRecovery", params: { email: email }})
        } else {
            commit(`SET_ALERT_MESSAGE`, {
                variant: "success",
                message: response.data.message
            });
            const email = response.data.email;
            router.push({ name: "PasswordRecoverySuccess", params: { email: email }})
        }
    },

    // confirmPasswordCollaborator({ commit }, data) {
    //     return new Promise((resolve)=>{
    //         userApi.confirmPasswordCollaborator({token: data.token, password: data.password, confirmPassword: data.confirmPassword }, function(response){
    //             if (response.data.error) {
    //                 resolve(response.data);
    //             } else {
    //                 commit(`SET_ALERT_MESSAGE`, {
    //                     variant: "success",
    //                     message: response.data.message
    //                 });
    //                 const email = response.data.email;
    //                 router.push({ name: "PasswordRecoverySuccess", params: { email: email }})
    //             }
    //         });
    //     });
    // },
};
const mutations = {
    SET_ALERT_MESSAGE(state, data) {
        state.alertMessage = data;
    },
    SET_EMAIL_SUCCESS_REGISTER(state, email) {
        state.currentEmail = email;
    },
    SET_RECOVERY_ALERT(state, value){
        state.recoveryAlert = value;
        state.isLoadingRecovery = false;
    },
    SET_LOADING_RECOVERY(state, value){
        state.isLoadingRecovery = value;
    },
    REGISTER_ERROR(state, value){
        state.alertMessage = value;
    }
};
export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    }
};
