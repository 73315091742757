<template>
    <span class="badge" :class="type">
        <i aria-hidden="true" class="badge-icon banana" :class="icon"></i>
        <slot></slot>
        {{text}}
        <a v-if="closeBtnShow" @click="deleteValue" class="close-badge" tabindex="">
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </a>
    </span>
</template>

<script>
    export default {
        name: 'Badge',
        props: {
            type: String,
            closeBtnShow: {type: Boolean, default: false},
            text: String,
            icon: String
        },
        methods: {
            deleteValue() {
                this.$emit('deleteValue');
            },
        }
    }
</script>
