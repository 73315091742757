<template>
    <div :id="idTopbar" class="top-bar-landing" :class="activeTab === 'PAGANDOAPI' || activeTab === 'OUS' || activeTab === 'TERMS' ? 'colorBar' : ''" @scroll="handleScrollBar">
        <div class="width-responsive">
            <router-link to="/" class="link-to-home" tabindex="">
                <img class="img-fluid white" src="@/assets/images/Logos/PagandoChek-LogoWhite.svg" alt="Pagando" />
                <img class="img-fluid color" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" alt="Pagando" />
            </router-link>
            <ul class="opts-home">
                <!-- Add class 'active' to 'link-to', when option selected -->
                <li>
                    <router-link to="/" :class="activeTab === 'HOME' ? 'link-to active' : 'link-to'" tabindex=""> {{$t('landing.top-bar.welcome')}} </router-link>
                </li>
                <li>
                    <router-link to="/about" :class="activeTab === 'OUS' ? 'link-to active' : 'link-to'" tabindex=""> {{$t('landing.top-bar.ous')}} </router-link>
                </li>
                <li>
                    <router-link to="/features" :class="activeTab === 'FEATURES' ? 'link-to active' : 'link-to'" tabindex=""> {{$t('landing.top-bar.features')}} </router-link>
                </li>
                <li>
                    <router-link to="/signin" class="link-to" tabindex=""> {{$t('landing.top-bar.login')}} </router-link>
                </li>
            </ul>

            <b-dropdown id="dropdown-landingpage-menu" right class="landingpage-menu-dropdown" toggle-class="">
                <template v-slot:button-content>
                  <i aria-hidden="true" class="banana banana-menu"></i>
                </template>
                <b-dropdown-group>
                    <b-dropdown-item-button>
                        <router-link to="/" class="link-to active" tabindex=""> {{$t('landing.top-bar.welcome')}} </router-link>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <router-link to="/about" class="link-to" tabindex=""> {{$t('landing.top-bar.ous')}} </router-link>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <router-link to="/features" class="link-to" tabindex=""> {{$t('landing.top-bar.features')}} </router-link>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <router-link to="" class="link-to" tabindex=""> {{$t('landing.top-bar.help')}}  </router-link>
                    </b-dropdown-item-button>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button>
                        <router-link to="/signin" class="link-to c-accent_1" tabindex=""> {{$t('landing.top-bar.login')}} </router-link>
                    </b-dropdown-item-button>
                </b-dropdown-group>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
    import {
        STORE as LandingStore,
        GET_TAB_ACTIVE
    } from "@/store/modules/landing/landing.types";
    import {mapGetters} from 'vuex'
    export default {
        name: "TopBarLanding",
        props: {
            clazz: String,
            idTopbar: String
        },
        components: {},
        data() {
            return {
                window: {
                    width: 0
                }
            }
        },
        mounted() {
          // Always watch window scroll
          window.addEventListener('scroll', this.handleScrollBar);
        },
        destroyed() {
          // Always remove window watch scroll
          window.addEventListener('scroll', this.handleScrollBar);
        },
        computed: {
          ...mapGetters(LandingStore, {activeTab: GET_TAB_ACTIVE}),
        },
        methods: {
          handleScrollBar () {
            const header = document.querySelector(".top-bar-landing");
            if (header) {
                if (window.scrollY > 30 && !header.className.includes('fixedBar')) {
                    header.classList.add('fixedBar');
                } else if (window.scrollY < 30) {
                    header.classList.remove('fixedBar');
                }
            }
          }
        }
    };
</script>

<style lang="scss">
    .top-bar-landing {
        width: 100%;
        height: 80px;
        padding: 22px 0;
        display: flex;
        align-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        z-index: 10;
        transition: all 0.3s ease-in-out;

        > div {
            display: flex;
            align-content: center;
            justify-content: flex-end;
        }

        .link-to-home {
            width: fit-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 auto 0 0;

            .color {
                display: none;
            }

            img {
                max-height: 30px;
            }
        }

        .opts-home {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-content: center;
            justify-content: flex-end;

            li {
                padding: 0 15px;
                display: flex;
                align-items: center;

                &:nth-last-of-type(2) {
                    padding: 0 30px;
                    opacity: 0.8;
                    transition: 0.3s;

                    &:hover {
                        opacity: 1;
                    }
                }

                &:last-of-type {
                    padding: 0;
                }
            }

            .link-to {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.14;
                text-align: center;
                text-transform: uppercase;
                position: relative;

                &::after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    border-radius: 4px;
                    margin-top: 2px;
                    display: block;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transition: all .3s ease-in-out 0s;
                    transition: all .3s ease-in-out 0s;
                }

                &:hover::after {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }

                &.active::after {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }

        &.fixedBar {
            box-shadow: 0 2px 6px 0 rgba(36,36,36,.15);
            transition: all 0.3s ease-in-out;
        }

        &.colorBar,
        &.fixedBar {
            .link-to-home {
                .white {
                    display: none;
                }

                .color {
                    display: block;
                }
            }
        }

        .landingpage-menu-dropdown {
            display: none;

            .dropdown-toggle {
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 4px;
                display: flex;
                align-content: center;
                justify-content: center;
                font-size: 22px;

                &:hover {
                    opacity: 0.7;
                }
            }

            .dropdown-menu {
                margin-top: 4px;

                .link-to {
                    justify-content: flex-start;
                }
            }
        }
    }
</style>
