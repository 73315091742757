import transactionHistoryApi from "@/api/payments/transactionHistory.api";
import catalog from '@/store/modules/base/catalog.store';
import utils from '@/common/utils';
import {
  STORE as TransactionHistoryStore,
  SET_ACTUAL_TRANSACTION,
  GET_ACTUAL_TRANSACTION,
  GET_FROM,
  SET_FROM,
  GET_TO,
  SET_TO
} from "./transactionHistory.types";
import {
    GET_OBJECT_PAGE,
    GET_SEARCH_TEXT,
    GET_CURRENT_PAGE,
    GET_FILTERS,
    GET_SORT,
    GET_TYPE_SORT,
    GET_FATHER_FILTER
} from '@/store/modules/base/catalog.types';


const collectedPaymentsCatalog = catalog(transactionHistoryApi, TransactionHistoryStore);

// Objetos
const stateGlobal = {
    actualTransaction: null,
    from: null,
    to: null
};

// Métodos para obtener valores del state. Se les llama con getters
const gettersGlobal = {
    [GET_ACTUAL_TRANSACTION]: (state) => state.actualTransaction,
    [GET_FROM]: (state) => state.from,
    [GET_TO]: (state) => state.to
};

// Métodos síntronos que afectan el state. Se llaman con commit
const mutations = {
    [SET_ACTUAL_TRANSACTION]: (state, actualTransaction) => {state.actualTransaction = actualTransaction},
    [SET_FROM]: (state, from) => {state.from = from},
    [SET_TO]: (state, to) => {state.to = to},
};

// Métodos asíncronos que hacen cosas y luego hacen commit. Se llaman con dispatch
const actions = {
    downLoadExcel({getters}, functions){
        transactionHistoryApi.exportExcel({
            currentPage: getters[GET_CURRENT_PAGE],
            perPage: getters[GET_OBJECT_PAGE],
            search: getters[GET_SEARCH_TEXT],
            filters: getters[GET_FILTERS],
            sort: getters[GET_SORT],
            typeSort: getters[GET_TYPE_SORT],
            fatherFilter: getters[GET_FATHER_FILTER],
            from: getters[GET_FROM],
            to: getters[GET_TO],
        }, functions.success, functions.error);
    },
    setFilterList({state, commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            let filters = JSON.parse(JSON.stringify(state.filters));
            
            const paramKeys = Object.keys(params);
            paramKeys.forEach(function(key){
                console.log("key", key);
                if(utils.isNotDefined(params[key]) || params[key] === "" ){
                    delete filters[key]
                } else {
                    filters[key] = params[key];
                }                
            })
            
            commit("SET_LOADING_ITEMS", true)
            commit("SET_FILTERS", filters)
            dispatch("list", filters)
        }
    },
    clearFilterList({state, commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const filters = JSON.parse(JSON.stringify(state.filters))
            if (typeof params.folio !== "undefined"){
                delete filters.folio;
            }
            if (typeof params.user !== "undefined"){
                delete filters.user;
            }
            if (typeof params.conceptName !== "undefined"){
                delete filters.conceptName;
            }
            if (typeof params.from !== "undefined"){
                delete filters.from;
            }
            if (typeof params.to !== "undefined"){
                delete filters.to;
            }
            if (typeof params.units !== "undefined"){
                delete filters.units;
            }
            if (typeof params.amount !== "undefined"){
                delete filters.amount;
            }
            commit("SET_FILTERS", filters)
            dispatch("list", filters)
        }
    },
    setSortCollectedPayment({commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const sort = `${ params.sort}`;
            const typeSort = `${ params.typeSort}`;
            commit("SET_SORT", sort);
            commit("SET_TYPE_SORT", typeSort);
            dispatch("list");
        }
    },
    resetFiltersCollectedPayment({state, commit, dispatch}) {
        if ((Object.keys(state.filters)).length > 0) {
            commit("SET_FILTERS", {})
            dispatch("list")
        }
    },
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal,
        ...collectedPaymentsCatalog.state
    },
    getters: {
        ...gettersGlobal,
        ...collectedPaymentsCatalog.getters
    },
    actions: {
        ...collectedPaymentsCatalog.actions,
        ...actions

    },
    mutations: {
        ...mutations,
        ...collectedPaymentsCatalog.mutations
    }
};
