import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'permissions';

export default {
    list: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list`, params)
            .then(onSuccess)
            .catch(onError);
    },
    createProfile: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/create-profile`, params)
            .then(onSuccess)
            .catch(onError);
    },
    deleteProfile: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete-profile`, params)
            .then(onSuccess)
            .catch(onError);
    },
    addPermissions: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/add-permissions`, params)
            .then(onSuccess)
            .catch(onError);
    },
    activeProfile: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/active-profile`, params)
            .then(onSuccess)
            .catch(onError);
    }
};
