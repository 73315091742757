<template>
    <div v-cloak>
        <div v-if="showPersonalInformationStep && !allRegisterComplete && showComponents">
            <PersonalInformationForm></PersonalInformationForm>
        </div>

        <div v-if="showSecurityMeasureStep && !allRegisterComplete && showComponents">
            <SecurityMeasure></SecurityMeasure>
        </div>

        <div v-if="showPhoneVerificationStep && !allRegisterComplete && showComponents">
            <PhoneVerification></PhoneVerification>
        </div>
        <LoadingBar :loadingText="loadingText" v-if="loading"></LoadingBar>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PersonalInformationForm from "@/views/signUpOrganization/userRegistration/PersonaInformationForm.vue";
    import PhoneVerification from "@/views/signUpOrganization/userRegistration/PhoneVerification.vue";
    import SecurityMeasure from "@/views/signUpOrganization/userRegistration/SecurityMeasures.vue";
    import LoadingBar from '@/components/LoadingBar.vue';
    import { EventBus } from '@/main';
    import {
        STORE as SESSION_STORE,
        GET_USER
    } from '@/store/modules/session/session.types';

    const storeModule = "signUpOrganization";

    export default {
        name: 'RegisterUser',
        data(){
            return {
                showComponents: false,
                loadingText: this.$i18n.t('loading-register-wizard'),
                loading: true
            }
        },
        components: {
            PersonalInformationForm,
            PhoneVerification,
            SecurityMeasure,
            LoadingBar
        },
        computed: {
            ...mapGetters(SESSION_STORE, [
                [GET_USER]
            ]),
            ...mapGetters(storeModule, [
                "getActualUser",
                "registerUserStepsStatus"
            ]),
            securityMeasureComplete(){
                return this.registerUserStepsStatus.securityMeasures.isValid;
            },
            phoneVerificationComplete(){
                return this.registerUserStepsStatus.phoneVerification.isValid;
            },
            allRegisterComplete(){
                const isComplete = this.getActualUser.hasNewRegisterFinish ||
                    (this.registerUserStepsStatus.personalInformation.isValid &&
                        this.securityMeasureComplete && this.phoneVerificationComplete);
                this.goToNextStep(isComplete);
                return isComplete
            },
            showPersonalInformationStep(){
                return !this.registerUserStepsStatus.personalInformation.isValid
            },
            showPhoneVerificationStep(){
                return this.registerUserStepsStatus.personalInformation.isValid && !this.phoneVerificationComplete
            },
            showSecurityMeasureStep(){
                return this.registerUserStepsStatus.personalInformation.isValid && this.phoneVerificationComplete
                && !this.securityMeasureComplete
            }
        },
        beforeCreate(){
          this.loading = true;
        },
        mounted(){
            setTimeout(()=>{
                this.loading = false;
            },3000);
            this.showComponents = true;
        },
        methods: {
            goToNextStep(value){
                if(value){
                    EventBus.$emit('REGISTER_COMPLETE')
                }
            }
        }
    }
</script>
