<template>
    <div>
        <SignUpOrganizationBody summaryStep>
            <template v-slot:formSectionBody>
                <GenericTitle id="dct-orgStepFive" clazz="p-t-20" title=""
                              :titleStrong="$t('signup-organization.step-summary.see-my-organization.title')"
                              :subtitle="$t('signup-organization.step-summary.sub-title')"></GenericTitle>
                <StepValidation :text="getErrorNextStepMessage" icon="banana-cross-normal" status="error"
                                v-if="getErrorNextStep"></StepValidation>
                <form>

                    <div>
                        <AddUser ref="add-collaborator-modal" v-if="getIsLoadActualOrganization"
                                 :storeModule="storeModuleView"
                                 :title="$t('signup-organization.step-summary.add-collaborator-button.title')"
                                 :usersList="getPotentialOrganization.collaborators"
                                 :subtitle="$t('signup-organization.step-summary.add-collaborator-button.sub-title')"
                                 v-on:clickAccepted="saveCollaborators">
                            <li v-for="(collaborator, collaboratorIndex) in collaborators"
                                v-bind:key="collaboratorIndex">
                                {{collaborator}} - <strong class="c-success">{{$t('signup-organization.step-summary.add-collaborator-button.email-saved')}}</strong>
                            </li>
                        </AddUser>
                    </div>

                    <button id="btn-orgSummary"
                            class="button btn-lg btn-block button-stroke button-accent1 m-t-30 w-100"
                            @click="reloadCollaboratorsInModal(); $bvModal.show('modal-resumeSteps')" type="button">
                        <strong>{{$t('signup-organization.step-summary.see-my-organization.button-text')}}</strong>
                    </button>

                    <BasicInput
                            required
                            :inputValue="getPotentialOrganization.salesManCode"
                            inputType="text"
                            labelFor="salesman"
                            inputId="salesman"
                            :label="$t('signup-organization.step-summary.sales-man-code')"
                            :clazz="'m-t-45'"
                            v-on:basicInput="updateCode"
                            :inputPlaceholder="$t('signup-organization.step-summary.sales-man-code-place-holder')"
                            idForm="dct-salesMan"
                            help
                            idHelpTooltip="salesManHelp"
                            helpIcon="banana banana-question-circle1">
                        <template v-slot:tooltipHelp>
                            <b-tooltip target="salesManHelp" placement="top" custom-class="white-tooltip">
                                <div>
                                    <p class="m-b-0">
                                        {{$t('signup-organization.step-summary.sales-man-tooltip')}}
                                    </p>
                                </div>
                            </b-tooltip>
                        </template>
                    </BasicInput>


                    <b-alert class="alert-note" show dismissible variant="info">
                        <p class="text-container">
                            <i aria-hidden="true" class="icon banana banana-info-circle2"></i>
                            {{$t('signup-organization.step-summary.sales-man-alert')}}
                        </p>
                    </b-alert>

                    <b-modal id="modal-resumeSteps" size="lg" hide-header hide-footer>
                        <div class="p-40">
                            <h1 class="f-35 c-accent_2 principal-font-bold d-block m-b-20">
                                {{$t('signup-organization.step-summary.see-my-organization.title')}}</h1>
                            <b-tabs class="simple-tabs group-tabs" content-class="simple-tabs-body p-t-20">
                                <b-tab title="Datos Generales" active>
                                    <SimpleTimelineInfo>
                                        <SimpleTimelineInfoDot idPgh="p-orgName"
                                                               :label="$t('signup-organization.step-summary.see-my-organization.organization-name')"
                                                               :content="getPotentialOrganization.name"></SimpleTimelineInfoDot>
                                        <SimpleTimelineInfoDot idPgh="p-orgType"
                                                               :label="$t('signup-organization.step-summary.see-my-organization.organization-type')"
                                                               :content="getCategorie.name"></SimpleTimelineInfoDot>
                                        <SimpleTimelineInfoDot idPgh="p-orgDesc"
                                                               :label="$t('signup-organization.step-summary.see-my-organization.organization-description')"
                                                               :content="getPotentialOrganization.description"></SimpleTimelineInfoDot>
                                    </SimpleTimelineInfo>
                                </b-tab>
                                <b-tab title="Datos Fiscales">

                                    <SimpleTimelineInfo>
                                        <SimpleTimelineInfoDot
                                                :label="$t('signup-organization.step-summary.see-my-organization.ref')"
                                                :content="invoiceDataRfc"></SimpleTimelineInfoDot>
                                        <SimpleTimelineInfoDot
                                                :label="$t('signup-organization.step-summary.see-my-organization.billing-info')"
                                                content="">
                                            <div class="simple-timeline-info">
                                                <p id="p-invoiceDataName">{{invoiceDataName}}</p>
                                                <p id="p-invoiceDataAddress">{{invoiceDataAddress}}</p>
                                                <p id="p-invoiceDataState">{{invoiceDataState}}</p>
                                                <p id="p-invoiceDataPhone">{{invoiceDataPhone}}</p>
                                                <p id="p-invoiceDataEmail">{{invoiceDataEmail}}</p>
                                            </div>
                                        </SimpleTimelineInfoDot>
                                    </SimpleTimelineInfo>
                                </b-tab>
                                <b-tab :title="$t('signup-organization.step-summary.see-my-organization.logo-cover')">
                                    <PreviewLogoPortada
                                            :title="$t('signup-organization.step-summary.see-my-organization.preview-cover.title')"
                                            :mnsj="$t('signup-organization.step-summary.see-my-organization.preview-cover.text')"
                                            :coverSrc="coverImg" :logoSrc="logoImg"
                                            :nombreEmp="getPotentialOrganization.name"
                                            numServ="10"></PreviewLogoPortada>
                                </b-tab>
                                <b-tab :title="$t('signup-organization.step-summary.see-my-organization.bank-account')">
                                    <SimpleTimelineInfo>
                                        <SimpleTimelineInfoDot idPgh="p-orgBank"
                                                               :label="$t('signup-organization.step-summary.see-my-organization.bank')"
                                                               :content="getPotentialOrganization.bankName"></SimpleTimelineInfoDot>
                                        <SimpleTimelineInfoDot idPgh="p-orgAccountNumber"
                                                               :label="$t('signup-organization.step-summary.see-my-organization.account-number')"
                                                               :content="cuentaHidden()"></SimpleTimelineInfoDot>
                                        <SimpleTimelineInfoDot idPgh="p-orgClabe"
                                                               :label="$t('signup-organization.step-summary.see-my-organization.CLABE')"
                                                               :content="clabeHidden()"></SimpleTimelineInfoDot>
                                    </SimpleTimelineInfo>
                                </b-tab>
                            </b-tabs>
                            <button id="btn-summaryOrgClose"
                                    class="button xs button-filled button-accent1 m-t-30 m-0-0-0-auto" type="button"
                                    @click="$bvModal.hide('modal-resumeSteps')">{{$t('general.close')}}
                            </button>
                        </div>
                    </b-modal>
                </form>
            </template>
        </SignUpOrganizationBody>

    </div>
</template>

<script>
    import SignUpOrganizationBody from '@/components/SignUpOrganizationBody.vue';
    import GenericTitle from '@/components/GenericTitle.vue';
    import BasicInput from '@/components/BasicInput.vue';
    import PreviewLogoPortada from '@/components/PreviewLogoPortada.vue';
    import SimpleTimelineInfo from '@/components/SimpleTimelineInfo.vue';
    import SimpleTimelineInfoDot from '@/components/SimpleTimelineInfoDot.vue';
    import StepValidation from '@/components/StepValidation.vue';
    import {required} from 'vuelidate/lib/validators';
    import AddUser from '@/components/AddUser.vue';
    import {mapGetters} from 'vuex';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {
        STORE as SESSION_STORE,
        GET_USER
    } from '@/store/modules/session/session.types';

    const debounce = function debounce(func, wait, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate){
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow){
                func.apply(context, args);
            }
        }
    };

    const storeModule = "signUpOrganization"

    export default {
        name: "StepSummary",
        data() {
            return {
                occupation: '',
                profession: '',
                code: "",
                occupations: [],
                professions: [],
                occupationsForSelect: [],
                professionsForSelect: [],
                collaborators: [],
                storeModuleView: storeModule
            }
        },
        mixins: [
            ToastSnotify
        ],
        components: {
            SignUpOrganizationBody,
            GenericTitle,
            BasicInput,
            SimpleTimelineInfo,
            PreviewLogoPortada,
            StepValidation,
            AddUser,
            SimpleTimelineInfoDot
        },
        computed: {
            ...mapGetters(storeModule,
                    ['getPotentialOrganization',
                    'getCategorie',
                    'getActualUser',
                    'getIsLoadActualOrganization',
                    'getErrorNextStep',
                    'getErrorNextStepMessage',
                    'coverImg',
                    'logoImg',
                    'getIsValidStepFormSave']),
            ...mapGetters(SESSION_STORE, {currentUserLayout: GET_USER}),
            fullNameUser() {
                return `${this.getActualUser.name} ${this.getActualUser.lastName} ${this.getActualUser.secondLastName}`
            },
            fullNamePotential() {
                const {name, lastName, secondLastName} = this.getPotentialOrganization.invoiceData;
                return `${name} ${lastName} ${secondLastName}`;
            },
            addressUser() {
                return `${this.getActualUser.street} #${this.getActualUser.noExt} ${this.getActualUser.district}`
            },
            addressPotential() {
                const {streetAddress, exteriorNumber, district} = this.getPotentialOrganization.invoiceData;
                return `${streetAddress} ${exteriorNumber} ${district}`
            },
            stateUser() {
                return `${this.getActualUser.city}, ${this.getActualUser.state} ${this.getActualUser.zipCode}`
            },
            statePotential() {
                const {city, state, zipCode} = this.getPotentialOrganization.invoiceData;
                return `${city} ${state} ${zipCode}`
            },
            invoiceDataName() {
                return this.getPotentialOrganization.isPhysicalPerson && this.getPotentialOrganization.hasInvoiceData ? this.fullNamePotential : this.fullNameUser;
            },
            invoiceDataAddress() {
                return this.getPotentialOrganization.isPhysicalPerson && this.getPotentialOrganization.hasInvoiceData ? this.addressPotential : this.addressUser;
            },
            invoiceDataState() {
                return this.getPotentialOrganization.isPhysicalPerson && this.getPotentialOrganization.hasInvoiceData ? this.statePotential : this.stateUser;
            },
            invoiceDataPhone() {
                return this.getActualUser.phone;
            },
            invoiceDataEmail() {
                return this.getActualUser.email;
            },
            invoiceDataRfc() {
                return this.getPotentialOrganization.isPhysicalPerson && this.getPotentialOrganization.hasInvoiceData ?
                    this.getPotentialOrganization.invoiceData.rfc :
                    this.getPotentialOrganization.rfc;
            }
        },
        methods: {
            getProfessionsForSelect() {
                for (let i = 0; i < this.professions.length; i++) {
                    const newProfession = {
                        name: this.professions[i].description,
                        value: this.professions[i]._id,
                        disabled: false
                    };
                    this.professionsForSelect.push(newProfession);
                }
            },
            getOccupationsForSelect() {
                for (let i = 0; i < this.occupations.length; i++) {
                    const newOccupation = {
                        name: this.occupations[i].description,
                        value: this.occupations[i]._id,
                        disabled: false
                    };
                    this.occupationsForSelect.push(newOccupation);
                }
            },
            updateCode: debounce(function (code) {
                this.code = code;
                this.code = this.code.trim()
                this.$store.commit(`${storeModule}/UPDATE_SALESMAN_CODE`, this.code);
            }, 800, false),
            getErrorClass(propertie) {
                return this.$v[propertie].$error ? "has-error" : "";
            },// "hipsy enterprises"
            updateOccupation: debounce(function (event) {
                this.occupation = event;
                this.$v.occupation.$touch();
                this.$store.commit(`${storeModule}/UPDATE_OCCUPATION_POTENTIAL_ORGANIZATION`, event);
                this.validateStep();
            }, 800, false),
            saveCollaborators(collaborators) {
                this.$store.dispatch(`${storeModule}/saveCollaborators`, collaborators).then((result) => {
                    if (result.error) {
                        this.displayNotificationError(result.message);
                    } else {
                        this.collaborators = this.getPotentialOrganization.collaborators;
                        this.displayNotificationSuccess(result.message);
                    }
                }).catch(() => {
                    this.displayNotificationError("No se han podido agregar los emails de los colaboradores.");
                });
            },
            reloadCollaboratorsInModal() {
                this.$refs['add-collaborator-modal'].reloadUserList(this.getPotentialOrganization.collaborators)
            },
            updateProfession: debounce(function (event) {
                this.profession = event;
                this.$v.profession.$touch();
                this.$store.commit(`${storeModule}/UPDATE_PROFESSION_POTENTIAL_ORGANIZATION`, event);
                this.validateStep();
            }, 800, false),
            async initializeValues() {
                const userId = this.currentUserLayout._id;
                await this.$store.dispatch('signUpOrganization/loadPotentialOrganization', {userId});
                this.collaborators = this.getPotentialOrganization.collaborators ? this.getPotentialOrganization.collaborators : [];
                this.validateStep();
            },
            cuentaHidden() {
                let accountHidden = "";
                if (this.getPotentialOrganization.accountNumber) {
                    const account = this.getPotentialOrganization.accountNumber;
                    const firstChars = account.substring(0, 6);
                    accountHidden = account.replace(firstChars, '******');
                }
                return accountHidden;
            },
            clabeHidden() {
                let clabeHidden = "";
                if (this.getPotentialOrganization.clabe) {
                    const clabe = this.getPotentialOrganization.clabe;
                    const firstChars = clabe.substring(0, 14);
                    clabeHidden = clabe.replace(firstChars, '**************');
                }
                return clabeHidden;
            },
            validateStep() {
                const isValid = true;
                this.$store.commit(`${storeModule}/SET_IS_VALID_STEP_FIVE`, isValid);
            },
        },
        validations: {
            occupation: {
                required
            },
            profession: {
                required
            }
        },
        mounted() {
            this.initializeValues();
        }
    }
</script>
