import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'access-config';

export default {
    userOrganizations: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/organizations`, params)
            .then(onSuccess)
            .catch(onError);
    },
};
