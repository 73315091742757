<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                    tabindex=""
                    :to="`/client-admin/debtors-list/${service._id}`"
                ><i aria-hidden="true" class="banana banana-arrow-left"></i> {{ $t('debtors.return-config') }}</router-link>
                <FloatingTitleLayout :title="$t('debtors.new')" />
            </div>

            <WizardForm
                clazz="col-12"
                :hideFooter="true"
            >
                <template v-slot:wizardView>
                    <tab-content
                        v-for="(step, index) in steps"
                        v-bind:key="step.title"
                        :title="step.title"
                        :icon="step.icon"
                        :active="step.icon === 'one'"
                    >

                        <b-alert
                            class="alert-note"
                            show
                            variant="info"
                        >
                            <p class="text-container">
                                <img
                                    class="img-fluid icon"
                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                    alt="info"
                                />
                                {{ $t('debtors.new') }}}
                            </p>
                        </b-alert>

                        <component
                            :is="step.component"
                            :payment="payment"
                            :debt="service"
                            @next="next()"
                            @prev="prev()"
                            @cancel="cancel()"
                            @finish="finish()"
                            @goTo="goTo(index, $event)"
                        >
                            <component
                                v-if="step.slot && step.slotName"
                                :is="step.slot"
                                :slot="step.slotName"
                                :payment="payment"
                                @validityChange="slotValidityChange(step, $event)"
                            />
                        </component>
                    </tab-content>
                </template>
            </WizardForm>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/main';
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import Badge from "@/components/Badge";
import CardSimple from '@/components/CardSimple.vue'
import WizardForm from "@/components/WizardForm.vue";
import DebtorsGeneral from '@/views/payments/debtors/General.vue';
import DebtorsConcepts from '@/views/payments/debtors/Concepts.vue';
import DebtorsDiscounts from '@/views/payments/debtors/Discounts.vue';
import DebtorsSummary from '@/views/payments/debtors/Summary.vue';
import ServicesDebtGeneralAdvanced from '@/views/services/DebtGeneralAdvanced.vue';
import { mapGetters } from 'vuex';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import PaymentsCRUDApi from '@/api/payments/crud.api.js';
import DebtorsApi from '@/api/debt/debt.api.js';
import ToastSnotify from '@/mixins/toastSnotify.mixin';

export default {
    name: "Debtors",
    mixins: [ToastSnotify],
    components: {
        FloatingTitleLayout,
        Badge,
        WizardForm,
        CardSimple,
        // Views
        DebtorsGeneral,
        DebtorsConcepts,
        DebtorsDiscounts,
        DebtorsSummary,
        ServicesDebtGeneralAdvanced
    },
    mounted () {
        PaymentsCRUDApi.get('D', this.$route.params.id).then((data) => {
            this.service = data.data.object;
            this.steps.push({ title: this.$t('pagos.steps.general'), icon: 'one', component: 'DebtorsGeneral', slot: 'ServicesDebtGeneralAdvanced', slotName: 'advancedSettings' });
            this.steps.push({ title: this.$t('pagos.steps.concepts'), icon: 'two', component: 'DebtorsConcepts' });
            this.steps.push({ title: this.$t('pagos.steps.discounts'), icon: 'three', component: 'DebtorsDiscounts' });
            this.steps.push({ title: this.$t('pagos.steps.summary'), icon: 'four', component: 'DebtorsSummary' });
            this.$forceUpdate();
        });
    },
    data () {
        return {
            steps: [],
            // Date Range Picker
            opens: 'left',
            showDropdowns: true,
            autoApply: true,
            dateRange: [],
            // Multiselect
            checkedSwitch: false,
            value: [],
            service: {
                type: 'D',
                fields: [],
                references: [],
            },
            payment: {
                fields: [],
                references: [],
                items: [],
                discounts: [],
            }
        };
    },
    methods: {
        goTo (step, target) {
            EventBus.$emit(`wizard#goTo`, { from: step, to: target });
            this.$forceUpdate();
        },
        cancel () {
            localStorage.removeItem('editingPayment');
            this.$router.push({ name: 'Payments' });
        },
        prev () {
            EventBus.$emit(`wizard#prev`);
            this.$forceUpdate();
        },
        next () {
            EventBus.$emit(`wizard#next`);
            localStorage.setItem('editingPayment', JSON.stringify(this.payment));
            this.$forceUpdate();
        },
        finish () {
            DebtorsApi.createDebtPayment(this.service, this.payment).then(() => {
                this.displayNotificationSuccess(this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                localStorage.removeItem('editingPayment');
                this.$router.push(`/client-admin/debtors-list/${this.service._id}`);
            }).catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t('general.actions.error.title'), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t('general.actions.error.title'), this.$t('general.actions.error.text'));
                }
            });
        },
        slotValidityChange (step, value) {
            this.$forceUpdate();
            this.slotValid = value;
        }
    },
    computed: {
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
    }
};
</script>
