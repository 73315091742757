<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/api-list"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-inherit"
                    tabindex="">
                    <i aria-hidden="true" class="banana banana-arrow-left c-accent_2"></i>
                    {{ $t('api-configuration.api-keys.return') }}
                </router-link>
                <FloatingTitleLayout
                        :title="$t('api-configuration.api-keys.title')"
                />
            </div>
            <div class="col-12">
                <CardSimple hideTitle>
                    <div v-if="loadingDomainsInfo" class="progress-loading">
                        <LoadingBar :loadingText="$t('api-configuration.api-keys.loading')"></LoadingBar>
                    </div>

                    <div class="row">
                        <div class="col-12">
                             <TitleNumber num="1"
                                :title="$t('api-configuration.api-keys.first-step.title')"
                                :subtitle="$t('api-configuration.api-keys.first-step.subtitle')"
                             ></TitleNumber>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12" v-for="(domain, i) in [...domains, null]" :key="i">
                                    <BasicInput
                                        v-if="i !== domains.length"
                                        clazz="m-b-0"
                                        :value="domain"
                                        :label="$t('api-configuration.api-keys.first-step.domains.label', {index: i+1})"
                                        @input="domainsModified = true; $set(domains, i, $event)"
                                        :inputValue="showTestKey ? getApiTestKey : ''"
                                        :inputPlaceholder="$t('api-configuration.api-keys.first-step.input-placeholder')"
                                        inputClazz="icon-right"
                                        iconInput="banana-delete"
                                        @onIconClick="domainsModified = true; domains.splice(i, 1)">
                                    </BasicInput>
                                    <BasicInput
                                        v-else
                                        clazz="m-b-0"
                                        :value="domain"
                                        :label="$t('api-configuration.api-keys.first-step.domains.add')"
                                        @input="domainsModified = true; domains.push($event)"
                                        :inputValue="showTestKey ? getApiTestKey : ''"
                                        :inputPlaceholder="$t('api-configuration.api-keys.first-step.input-placeholder')">
                                    </BasicInput>
                                </div>
                                <div class="col-12 vertical-center justify-content-end">
                                    <button
                                            type="button"
                                            class="button sm p-l-10 p-r-10 m-t-15 m-r-10 button-filled button-accent1"
                                            @click="saveDomains"
                                            :disabled="!domainsModified"
                                    >
                                        {{$t('api-configuration.api-keys.first-step.save')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardSimple>
                <CardSimple hideTitle>
                    <div v-if="loadingTestKeyInfo === true || loadingTestUserInfo === true" class="progress-loading">
                        <LoadingBar :loadingText="$t('api-configuration.api-keys.loading')"></LoadingBar>
                    </div>
                    <div class="layer-disabled" v-if="stepNumber < 2"></div>

                    <div class="row">
                        <div class="col-12">
                             <TitleNumber num="2"
                                          :title="$t('api-configuration.api-keys.second-step.title')"
                                          :subtitle="$t('api-configuration.api-keys.second-step.subtitle')"
                             ></TitleNumber>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <label class="f-14 c-title body-font-bold d-block m-b-10">
                                        {{ $t('api-configuration.api-keys.second-step.user') }}
                                    </label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-30"> {{ getApiUserTest }} </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <BasicInput
                                        clazz="m-b-0"
                                        required
                                        :disabled="stepNumber < 2"
                                        labelFor="testPublicKey"
                                        inputId="testPublicKey"
                                        :label="$t('api-configuration.api-keys.second-step.input-title')"
                                        :inputValue="showTestKey ? getApiTestKey : ''"
                                        :inputPlaceholder="$t('api-configuration.api-keys.second-step.input-placeholder')"
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-testKey"
                                        inputClazz="icon-right"
                                        iconInput="banana-copy"
                                        v-on:onIconClick="copyClipboard(getApiTestKey)">
                                        <template v-slot:tooltipHelp>
                                            <b-tooltip
                                                target="tooltip-testKey"
                                                placement="top"
                                                custom-class="white-tooltip">
                                                <div>
                                                    <p class="m-b-0">{{$t('api-configuration.api-keys.second-step.tooltip')}}</p>
                                                </div>
                                            </b-tooltip>
                                        </template>
                                    </BasicInput>
                                    <BasicInput
                                        clazz="m-b-0"
                                        required
                                        :disabled="stepNumber < 2"
                                        labelFor="cypherKey"
                                        inputId="cypherKey"
                                        :label="$t('api-configuration.api-keys.second-step.cypher-title')"
                                        :inputValue="getCypherKey ? getCypherKey : ''"
                                        :inputPlaceholder="$t('api-configuration.api-keys.second-step.input-cypher')"
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-testKey"
                                        inputClazz="icon-right"
                                        iconInput="banana-copy"
                                        v-on:onIconClick="copyClipboard(getCypherKey)">
                                    </BasicInput>
                                    <BasicInput
                                        clazz="m-b-0"
                                        required
                                        :disabled="stepNumber < 2"
                                        labelFor="aesKey"
                                        inputId="aesKey"
                                        :label="$t('api-configuration.api-keys.second-step.cypher-aes-title')"
                                        :inputValue="getAesKey ? getAesKey : ''"
                                        :inputPlaceholder="$t('api-configuration.api-keys.second-step.input-aes')"
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-testKey"
                                        inputClazz="icon-right"
                                        iconInput="banana-copy"
                                        v-on:onIconClick="copyClipboard(getAesKey)">
                                    </BasicInput>
                                    <BasicInput
                                        clazz="m-b-0"
                                        required
                                        :disabled="stepNumber < 2"
                                        labelFor="IV"
                                        inputId="IV"
                                        :label="$t('api-configuration.api-keys.second-step.cypher-iv')"
                                        :inputValue="getIV ? getIV : ''"
                                        :inputPlaceholder="$t('api-configuration.api-keys.second-step.input-iv')"
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-testKey"
                                        inputClazz="icon-right"
                                        iconInput="banana-copy"
                                        v-on:onIconClick="copyClipboard(getIV)">
                                    </BasicInput>
                                </div>
                                <div class="col-12 col-lg-4 p-l-0 vertical-center justify-content-end">
                                    <button
                                            type="button"
                                            class="button sm p-l-10 p-r-10 m-t-15 m-r-10"
                                            :class="stepNumber < 2 ? 'button-disable button-filled' : 'button-filled button-accent1'"
                                            @click="getApiTestKey === '' ? getKey('Test', true, true) : getKey('Test', false, true)"
                                            :disabled="showTestKey === true"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.primary-button')}}
                                    </button>
                                    <button
                                            type="button"
                                            class="button sm  p-l-10 p-r-10 m-t-15"
                                            :class="stepNumber < 2 ? 'button-stroke button-disable' : 'button-stroke button-accent1'"
                                            @click="getKey('Test', true, true)"
                                            :disabled="getApiTestKey === ''"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.secondary-button')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardSimple>
                <CardSimple hideTitle>
                    <div v-if="loadingTestInfo === true" class="progress-loading">
                        <LoadingBar :loadingText="$t('api-configuration.api-keys.loading')"></LoadingBar>
                    </div>
                    <div class="layer-disabled" v-if="stepNumber < 3"></div>

                    <div class="row">
                        <div class="col-12">
                            <TitleNumber num="3"
                                         :title="$t('api-configuration.api-keys.third-step.title')"
                                         :subtitle="$t('api-configuration.api-keys.third-step.subtitle')">
                            </TitleNumber>
                        </div>
                        <div class="col-12 m-b-40">
                            <div class="list-testing-results">
                                <label> {{ $t('api-configuration.api-keys.third-step.test-title') }} </label>
                                <ul>
                                    <li v-for="apiTest in getTestsInfo" :key="apiTest.testId">
                                        {{ $t(apiTest.name)}} ....................
                                        <Badge :type="getBadgeColor(apiTest.testStatus)" :text="getApiText(apiTest.testStatus)"></Badge>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12">
                            <router-link
                                    to="api-rest/api-verification"
                                    class="button xs m-0-0-0-auto "
                                    :class="stepNumber < 3 ? 'button-filled button-disable' : 'button-filled button-accent1'"
                            >
                                {{$t('api-configuration.api-keys.third-step.primary-button')}}
                            </router-link>
                        </div>
                    </div>
                </CardSimple>

                <CardSimple hideTitle v-if="getApiKeysEnabled">
                    <div v-if="loadingLiveKeyInfo === true || loadingLiveUserInfo === true" class="progress-loading">
                        <LoadingBar :loadingText="$t('api-configuration.api-keys.loading')"></LoadingBar>
                    </div>
                    <b-alert
                        class="alert-note"
                        show
                        variant="warning">
                        <p class="text-container p-r-0">
                            <img
                                class="img-fluid"
                                src="@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg"
                                alt="warning"
                            />
                            {{$t('api-configuration.api-keys.fourth-step.warning')}}
                        </p>
                    </b-alert>
                    <div class="row" :class="'p-relative' ? stepNumber < 4 : ''"> <!-- PLEASE REMOVE CLASS 'p-relative' WHEN 'layer-disabled' HIDDEN -->
                        <div class="layer-disabled" v-if="stepNumber < 4"></div>

                        <div class="col-12">
                            <TitleNumber num="4"
                                         :title="$t('api-configuration.api-keys.fourth-step.title')"
                                         :subtitle="$t('api-configuration.api-keys.fourth-step.subtitle')"
                            ></TitleNumber>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <label class="f-14 c-title body-font-bold d-block m-b-10">{{$t('api-configuration.api-keys.fourth-step.user')}}</label>
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-30"> {{ getApiUserLive }} </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <BasicInput
                                        clazz="m-b-0"
                                        required
                                        :disabled="stepNumber < 4"
                                        labelFor="publicKey"
                                        inputId="publicKey"
                                        :label="$t('api-configuration.api-keys.fourth-step.input-title')"
                                        :inputValue="showLiveKey ? getApiLiveKey: ''"
                                        :inputPlaceholder="$t('api-configuration.api-keys.fourth-step.input-placeholder')"
                                        help
                                        helpIcon="banana banana-question-circle1"
                                        idHelpTooltip="tooltip-liveKey"
                                        inputClazz="icon-right"
                                        iconInput="banana-copy"
                                        v-on:onIconClick="copyClipboard(getApiLiveKey)">
                                        <template v-slot:tooltipHelp>
                                            <b-tooltip
                                                target="tooltip-liveKey"
                                                placement="top"
                                                custom-class="white-tooltip">
                                                <div>
                                                    <p class="m-b-0">{{$t('api-configuration.api-keys.fourth-step.tooltip')}}</p>
                                                </div>
                                            </b-tooltip>
                                        </template>
                                    </BasicInput>
                                </div>
                                <div class="col-12 col-lg-4 p-l-0 vertical-center justify-content-end">
                                    <button type="button" class="button sm p-l-10 p-r-10 m-t-15 m-r-10"
                                            :class="stepNumber < 4 ? 'button-disable button-filled' : 'button-filled button-accent1'"
                                            @click="getApiLiveKey === '' ? getKey('Live', true, true) : getKey('Live', false, true)"
                                            :disabled="showLiveKey === true || !getApiKeysEnabled"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.primary-button')}}
                                    </button>

                                    <button type="button"
                                            class="button sm  p-l-10 p-r-10 m-t-15"
                                            :class="stepNumber < 4 ? 'button-stroke button-disable' : 'button-stroke button-accent1'"
                                            @click="$bvModal.show('modal-danger')"
                                            :disabled="getApiLiveKey === '' || !getApiKeysEnabled"
                                    >
                                        {{$t('api-configuration.api-keys.second-step.secondary-button')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 m-t-30">
                            <p class="f-14 c-plain_text body-font-regular d-block m-b-0">
                                {{$t('api-configuration.api-keys.fourth-step.instructions-first-part')}}
                                <a href="" class="c-accent_2 body-font-bold f-bold text-underline hover" tabindex="">
                                    {{$t('api-configuration.api-keys.fourth-step.instructions-second-part')}}
                                </a>
                                {{$t('api-configuration.api-keys.fourth-step.instructions-third-part')}}
                            </p>
                        </div>
                    </div>
                </CardSimple>
            </div>
        </div>

        <ModalAlert
                modalId="modal-danger"
                buttonType="button-error"
                :imgUrl="imageModalWarning"
                :title="$t('api-configuration.api-keys.fourth-step.modal-alert.title')"
                :text="$t('api-configuration.api-keys.fourth-step.modal-alert.text')"
                :buttonText="$t('api-configuration.api-keys.fourth-step.modal-alert.button-accept')"
                @submit="getLiveKey()"
        ></ModalAlert>
    </div>
</template>

<script>
    import {EventBus} from "@/main";
    // @ is an alias to /src
    import FloatingTitleLayout from '@/components/FloatingTitleLayout.vue'
    import CardSimple from '@/components/CardSimple.vue'
    import TitleNumber from '@/components/TitleNumber.vue'
    import BasicInput from '@/components/BasicInput.vue'
    import Badge from '@/components/Badge.vue'
    import LoadingBar from '@/components/LoadingBar'
    import ModalAlert from '@/components/ModalAlert.vue'
    import imageModalWarning from "@/assets/images/Icons/Alerts-icons/icon-alert-warning.svg";

    import {mapGetters} from 'vuex';
    import axios from 'axios'
    import {STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";
    import {
        STORE,
        GET_USER,
        GET_TOKEN
    } from '@/store/modules/session/session.types';
    import {utils} from '@/api/utils';
    import apiConfigurationApi from '@/api/apiWeb/apiConfigurationApi.api';
    const SUCCESS_TITLE_KEY = 'general.actions.success.title';
    const ERROR_TITLE_KEY = 'general.actions.error.title';

    const storeModule = `apiRest`;

    export default {
        name: "ApiNew",
        components: {
            FloatingTitleLayout,
            CardSimple,
            TitleNumber,
            BasicInput,
            Badge,
            LoadingBar,
            ModalAlert
        },
        data() {
            return {
                loadingDomainsInfo: true,
                loadingTestKeyInfo: true,
                loadingTestUserInfo: true,
                loadingTestInfo: true,
                loadingLiveKeyInfo: true,
                loadingLiveUserInfo: true,
                showTestKey: false,
                showLiveKey: false,
                errorNotifications: 0,
                imageModalWarning: imageModalWarning,
                domains: [],
                domainsModified: false,
            }
        },
        computed: {
            ...mapGetters(ACCESS_STORE, {
                currentOrganization: GET_CURRENT_ORGANIZATION
            }),
            ...mapGetters(STORE, {user: GET_USER, token: GET_TOKEN}),
            ...mapGetters(storeModule,[
                'getConfigurationStep',
                'getOriginRoute',
                'getResponseRoute',
                'getDomainsList',
                'getApiTestKey',
                'getApiLiveKey',
                'getApiUserLive',
                'getApiUserTest',
                'getTestsInfo',
                'getApiKeysEnabled',
                'getCypherKey',
                'getAesKey',
                'getIV',
            ]),
            /*
            This function is use to control which sections are disable
            base on process step
             */
            stepNumber(){
                const stepDictionary = {
                    "DOMAINS_CONFIGURATION": 1,
                    "TEST_KEY_CONFIGURATION": 2,
                    "VERIFICATION_TESTS": 3,
                    "PRODUCTION_KEY_GENERATION": 4
                };

                return stepDictionary[this.getConfigurationStep];
            }
        },
        methods: {
            /*
            This function gets all general info for api configuration as process step and domains info
             */
            getApiConfigurationInfo() {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Selected organization
                    user: this.user._id
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.$store.dispatch(`${storeModule}/getOrganizationApiInfo`, params);
            },
            /*
            This function gets a summary of test information as:
            - Test name
            - Test status
             */
            getTestInfo() {
                const params = {
                    organizationId: this.currentOrganization._id.toString(), // Selected organization
                    user: this.user._id
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;

                this.$store.dispatch(`${storeModule}/getTestInformation`, params);
            },
            /*
            This function is used to gets key information and regenerate if it is needed

            NOTE: This key is used for jwt authentication
             */
            getKey(type, generate, ifFromButton) {
                const params = {
                    id: this.currentOrganization._id.toString(), // Selected organization
                    type: type,
                    generate: generate,
                    category: this.currentOrganization.category
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                this.$store.dispatch(`${storeModule}/getApiKey`, params);

                if (ifFromButton && type === 'Test') {
                    this.showTestKey = true;
                } else if(ifFromButton && type === 'Live') {
                    this.showLiveKey = true;
                }
            },
            /*
            This function is used to gets user identifier used in jwt authentication
             */
            getUser(type) {
                const params = {
                    id: this.currentOrganization._id.toString(), // Selected organization
                    type: type
                };

                const token = this.token;
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                this.$store.dispatch(`${storeModule}/getApiUser`, params);
            },

            /*
            This function send the key text to clipboard
             */
            copyClipboard(text) {
                // this.copiedToClipboard = true;
                navigator.clipboard.writeText(text).then(() => {
                    utils.toast(this.$snotify, 'success', this.$t('general.actions.copy-clipboard.success'), this.$t(SUCCESS_TITLE_KEY));
                }, () => {
                    utils.toast(this.$snotify, 'error', this.$t('general.actions.copy-clipboard.error'), this.$t(ERROR_TITLE_KEY));
                });
            },
            /*
            This function gets the correct color for test status
             */
            getBadgeColor(testStatus) {
                const statusBadges = {
                    "PENDENT": "badge-placeholder",
                    "APPROVED": "badge-success",
                    "ERROR": "badge-error"
                };

                return statusBadges[testStatus];
            },
            /*
            This function gets the correct text for test status
             */
            getApiText(testStatus) {
                const statusBadges = {
                    "PENDENT": this.$i18n.t('api-configuration.api-keys.third-step.pendent-test-status'),
                    "APPROVED": this.$i18n.t('api-configuration.api-keys.third-step.approved-test-status'),
                    "ERROR": this.$i18n.t('api-configuration.api-keys.third-step.error-test-status')
                };

                return statusBadges[testStatus];
            },
            async getLiveKey() {

                const params= {
                    previousKey: this.getApiLiveKey,
                    user: this.user._id,
                    organization: this.currentOrganization._id.toString()
                }

                await this.$store.dispatch(`${storeModule}/saveKeyProductionHistory`, params);
                this.getKey('Live', true, true);
                this.$bvModal.hide('modal-danger');
            },
            async checkApiKeysEnabled() {
                const params= {organizationId: this.currentOrganization._id.toString()};

                await this.$store.dispatch(`${storeModule}/checkApiKeysEnabled`, params);
            },
            async saveDomains() {
                if(this.loadingDomainsInfo) {
                    return;
                }
                this.loadingDomainsInfo = true;
                try {
                    const data = {
                        organizationId: this.currentOrganization._id.toString(), // Selected organization
                        domains: this.domains.filter(Boolean),
                    };

                    await apiConfigurationApi.saveDomainInformation(data);
                    this.domainsModified = false;
                    utils.toast(
                        this.$snotify,
                        'success',
                        this.$t('api-configuration.api-keys.first-step.domains.success.title'),
                        this.$t('api-configuration.api-keys.first-step.domains.success.text'),
                    );
                } catch (error) {
                    utils.toast(
                        this.$snotify,
                        'error',
                        this.$t('api-configuration.api-keys.first-step.domains.error.title'),
                        this.$t('api-configuration.api-keys.first-step.domains.error.text'),
                    );
                }
                this.loadingDomainsInfo = false;
            }
        },
        beforeMount() {
            this.getKey("Live", false, false);
            this.getUser("Live");
        },
        created() {
            this.getApiConfigurationInfo();
            EventBus.$on('apiConfigurationInfoLoaded', () =>{
                this.domains = this.getDomainsList ? [...this.getDomainsList] : [];
                this.loadingDomainsInfo = false;
            });

            this.getKey("Test", false, false);
            this.getUser("Test");
            EventBus.$on("testKeyLoaded", () => {
                this.loadingTestKeyInfo = false;
            });

            EventBus.$on("testUserLoaded", () => {
                this.loadingTestUserInfo = false;
            });

            this.getTestInfo();
            EventBus.$on("testInfoLoadedAR", () => {
                this.loadingTestInfo = false;
            });

            EventBus.$on("liveKeyLoaded", () => {
                this.loadingLiveKeyInfo = false;
            });

            EventBus.$on("liveUserLoaded", () => {
                this.loadingLiveUserInfo = false;
            });

            EventBus.$on("ApiError", (problemName) => {
                const vueScope = this;

                // Avoid multiple toast error at same time
                vueScope.errorNotifications += 1;

                if (vueScope.errorNotifications === 1) {
                    if (problemName){
                        utils.toast(
                            this.$snotify,
                            'error',
                            vueScope.$i18n.t('api-configuration.toast-error-title'),
                            vueScope.$i18n.t('api-configuration.toast-error-text', [vueScope.$i18n.t(problemName)])
                        );
                    } else {
                        utils.toast(this.$snotify, 'error', vueScope.$i18n.t('api-configuration.toast-error-title'), vueScope.$i18n.t('api-configuration.toast-error-text'));
                    }
                }

                // Enable to receive toast errors
                setTimeout(function() {
                    vueScope.errorNotifications = 0;
                }, 1000);
            });

            this.checkApiKeysEnabled();

        },
        beforeDestroy() {
            EventBus.$off('apiConfigurationInfoLoaded');
            EventBus.$off("testKeyLoaded");
            EventBus.$off("testUserLoaded");
            EventBus.$off("testInfoLoadedAR");
            EventBus.$off("liveKeyLoaded");
            EventBus.$off("liveUserLoaded");
            EventBus.$off("ApiError");
        }
    }
</script>
