export const STORE = 'PAYMENT_TEMPLATE';
export const GET_PAYMENTS_TEMPLATE = 'GET_PAYMENTS_TEMPLATE';
export const GET_ACTUAL_ITEM = 'GET_ACTUAL_ITEM';
export const SET_PAYMENTS_TEMPLATE = 'SET_PAYMENTS_TEMPLATE';
export const SET_LOADING_PAYMENTS_TEMPLATE = 'SET_LOADING_PAYMENTS_TEMPLATE';
export const SET_TAGS = 'SET_TAGS';
export const SET_VALIDS = 'SET_VALIDS';
export const GET_VALIDS = 'GET_VALIDS';
export const GET_FILTERS_COUNT = 'GET_FILTERS_COUNT';
export const SET_RECORDS = 'SET_RECORDS';
export const GET_RECORDS = 'GET_RECORDS';
export const CLEAR_SELECTED_IDS = 'CLEAR_SELECTED_IDS';
export const XLS_SUCCESS = 'XLS_SUCCESS';
export const SET_LOADING_PROCESS_TEMPLATE = 'SET_LOADING_PROCESS_TEMPLATE';
export const GET_LOADING_PROCESS_TEMPLATE = 'GET_LOADING_PROCESS_TEMPLATE';
export const SET_PAYMENT_SELECTED = 'SET_PAYMENT_SELECTED';
export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';
export const SET_PAYMENT_VALID = 'SET_PAYMENT_VALID';
export const GET_PAYMENT_VALID = 'GET_PAYMENT_VALID';
export const SET_PAYMENT_DEBTS = 'SET_PAYMENT_DEBTS';
export const GET_PAYMENT_DEBTS = 'GET_PAYMENT_DEBTS';
export const SET_DEBT_SELECTED = 'SET_DEBT_SELECTED';
export const GET_DEBT_SELECTED = 'GET_DEBT_SELECTED';
export const GET_DEBTS_LENGTH = 'GET_DEBTS_LENGTH';
export const GET_DEBTS_CURRENT_PAGE = 'GET_DEBTS_CURRENT_PAGE';
export const GET_DEBTS_PER_PAGE = 'GET_DEBTS_PER_PAGE';
export const GET_LOADING_DETAIL = 'GET_LOADING_DETAIL';
export const SET_LOADING_DETAIL = 'SET_LOADING_DETAIL';
