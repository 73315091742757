<template>
    <BasicSelect
        :clazz="clazz"
        :required="required"
        :label="label"
        :labelFor="labelFor"
        :help="help"
        :idHelpTooltip="idHelpTooltip"
        :helpText="helpText"
        :helpIcon="helpIcon"
        :warningText="warningText"
        :warningIcon="warningIcon"
        :disabled="disabled"
    >
        <multiselect
            v-model="localValue"
            :options="options"
            :close-on-select="true"
            :preserve-search="true"
            :placeholder="placeholder"
            :label="multiselectOptionLabel"
            :preselect-first="selectFirst"
            :track-by="trackBy"
            :customLabel="multiselectCustomLabel"
            :disabled="disabled"
            :allowEmpty="false"
            class="bgm-white"
        >
            <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
            >
                <span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span>
            </template>
        </multiselect>
    </BasicSelect>
</template>
<script>
import BasicSelect from '@/components/BasicSelect.vue';
import Multiselect from "vue-multiselect";

export default {
    name: 'BasicSelectComplete',
    components: {
        BasicSelect,
        Multiselect
    },
    props: {
        // For BasicSelect
        clazz: String,
        required: {type: Boolean, default: false},
        label: String,
        labelFor: String,
        help: {type: Boolean, default: false},
        idHelpTooltip: String,
        helpText: String,
        helpIcon: String,
        warningText: String,
        warningIcon: String,
        disabled: {type: Boolean, default: false},
        // For multiselect
        selectFirst: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number, Boolean, Object],
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        trackBy: {
            type: String,
            default: null
        },
        optionLabel: {
            type: [String, Function],
            default: null
        },
        actualValue: {
            type: Function,
            default: (o) => o
        },
        loadValue: {
            type: Function,
            default: (o) => o
        }
    },
    computed: {
        multiselectOptionLabel() {
            if (typeof this.optionLabel === 'string') {
                return this.optionLabel;
            }
            return this.trackBy;
        },
        multiselectCustomLabel() {
            if (this.optionLabel instanceof Function) {
                return this.optionLabel;
            }
            // MUST be undefined to prevent multiselect from exploding
            return undefined;
        }
    },
    methods: {
        /* onSelect(){
            this.$emit('select', event.target.value);
        } */
    },
    data() {
        return {
            localValue: null
        };
    },
    beforeMount() {
        this.localValue = this.loadValue(this.value);
    },
    watch: {
        'localValue': {
            handler() {
                if (this.localValue) {
                    this.$emit('input', this.actualValue(this.localValue));
                }
            }
        }
    }
}
</script>
