import axios from 'axios'
const namespace = "payments-crud";

export default {
    all: ({page = 0, perPage = 10, search = '', match = {}, sort = {} }, onSuccess, onError) => {
        return axios
            .post(`${namespace}/all`, {page, perPage, search, match, sort})
            .then(onSuccess)
            .catch(onError);
    },
    get: (type, payment, onSuccess, onError) => {
        return axios
            .get(`${namespace}/${type}/${payment._id || payment}`)
            .then(onSuccess)
            .catch(onError);
    },
    save: (type, payment, onSuccess, onError) => {
        return axios
            .post(`${namespace}/${type}`, payment)
            .then(onSuccess)
            .catch(onError);
    },
    delete: (type, payment, onSuccess, onError) => {
        return axios
            .delete(`${namespace}/${type}/${payment._id || payment}`)
            .then(onSuccess)
            .catch(onError);
    },
    deleteMulti: (payments, onSuccess, onError) => {
        return axios
            .post(`${namespace}/delete-multi`, {payments})
            .then(onSuccess)
            .catch(onError);
    },
    saveApi: (type, payment) => {
        return axios
            .post(`${namespace}/${type}/api`, payment);
    },
    /**
     * payments: ([{type: string, id: strinng}]) Lista de pagos a borrar, deben de ser objetos con el tipo de pago y el id
     */
    copy: (payments, onSuccess, onError) => {
        return axios
            .post(`${namespace}/copy`, {payments})
            .then(onSuccess)
            .catch(onError);
    },
    share: (type, payment, emails, onSuccess, onError) => {
        return axios.post(`${namespace}/share/${type}/${payment._id || payment}`, {emails})
            .then(onSuccess)
            .catch(onError);
    }
};
