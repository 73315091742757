export const STORE = 'PERMISSIONS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const GET_TOTAL = 'GET_TOTAL';
export const SET_TOTAL = 'SET_TOTAL';
export const GET_PROFILES = 'GET_PROFILES';
export const SET_PROFILES = 'SET_PROFILES';
export const GET_CURRENT_PAGE = 'GET_CURRENT_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const GET_OBJECT_PAGE = 'GET_OBJECT_PAGE';
export const SET_OBJECT_PAGE = 'SET_OBJECT_PAGE';
export const GET_SELECTED_INDEX = 'GET_SELECTED_INDEX';
export const SET_SELECTED_INDEX = 'SET_SELECTED_INDEX';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const GET_SEARCH_TEXT = 'GET_SEARCH_TEXT';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const GET_LOADING_ITEMS = 'GET_LOADING_ITEMS';
export const SET_LOADING_ITEMS = 'SET_LOADING_ITEMS';
