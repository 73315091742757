export const IMAGES = "IMAGES";
export const BANK = "BANK";
export const GENERAL = "GENERAL";
export const CONTACT = "CONTACT";
export const NOTIFICATION = "NOTIFICATION";
export const FISCAL = "FISCAL";
export const BILLING = "BILLING";
export const PAYMENTS_CONFIG = "PAYMENTS_CONFIG";
export const WEBHOOK = "WEBHOOK";
export const DOCUMENTS = "DOCUMENTS";
export const LOCATION = "LOCATION";
export const CONFIG_DETAILS = "CONFIG_DETAILS";

export const values = [
    IMAGES,
    BANK,
    GENERAL,
    CONTACT,
    NOTIFICATION,
    FISCAL,
    BILLING,
    PAYMENTS_CONFIG,
    WEBHOOK,
    DOCUMENTS,
    LOCATION,
    CONFIG_DETAILS
];
