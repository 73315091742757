import axios from 'axios'
import base from './../base.api';

const namespace = 'dashboards';

export default {

    retrieveDashboardCardsInfo: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/cards-info`, params)
            .then(onSuccess)
            .catch(onError);
    },
    dataForChart: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/received-payments`,params)
            .then(onSuccess)
            .catch(onError);
    },
    bestServiceInfo: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/collected-by-service`, params)
            .then(onSuccess)
            .catch(onError);
    },
    lastClosingTransaction: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/closing-transaction`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getCurrentInvoice: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/invoices/getCurrentInvoice/${params.organizationId}`, params)
            .then(onSuccess)
            .catch(onError)
    }
}
