import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'transaction-history';

export default {
    list: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list/organization-orders`, params)
            .then(onSuccess)
            .catch(onError);
    },
    listTransactions: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/list/organization-transactions`, params)
            .then(onSuccess)
            .catch(onError);
    },
    exportExcel: (params, onSuccess, onError) => {
        return axios({
            url: `${base.baseUrl}/${namespace}/api/excel-report/`,
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            responseType: 'arraybuffer',
            data: params
        }).then(onSuccess).catch(onError);
    },
    download: (transaction, onSuccess, onError) => {
        return axios
            .get(`${base.baseUrl}/${namespace}/receipts/${transaction._id}`)
            .then(onSuccess)
            .catch(onError);
    },
    downloadPDF: (orderId, onSuccess, onError) => {
        return axios
            .get(`${base.baseUrl}/${namespace}/get-recipt/${orderId}`)
            .then(onSuccess)
            .catch(onError);
    },
}
