import taxReceipts from '@/api/taxreceipts/taxReceipts.api';
import catalog from '@/store/modules/base/catalog.store';

import {
    STORE as TaxReceiptsStore,
    SET_NAME_TAX_RECEIPT,
    SET_CONTENT,
    SET_ACTIVE,
    GET_ACTUAL_ITEM,
    SET_DISCONTINUED
} from './taxReceipts.types';
const taxReceiptsCatalog = catalog(taxReceipts, TaxReceiptsStore);

// Objetos
const stateGlobal = {
    actualTaxReceipt: {}
};

// Métodos para obtener valores del state. Se les llama con getters
const getters = {
    [GET_ACTUAL_ITEM]: (state) => state.actualTaxReceipt,
};

// Métodos síntronos que afectan el state. Se llaman con commit
const mutations = {
    [SET_NAME_TAX_RECEIPT]: (state, name) => {state.actualTaxReceipt.name = name},
    [SET_CONTENT]: (state, content) => {state.actualTaxReceipt.content = content},
    [SET_ACTIVE]: (state, active) => {state.actualTaxReceipt.active = active},
    [SET_DISCONTINUED]: (state, discontinued) => {state.actualTaxReceipt.discontinued = discontinued}
};

// Métodos asíncronos que hacen cosas y luego hacen commit. Se llaman con dispatch
const actions = {
    resetFilterTaxReceipt({state, commit, dispatch}) {
        if ((Object.keys(state.filters)).length > 0) {
            commit("SET_FILTERS", {})
            dispatch("list")
        }
    },
    setFilterTaxReceipt({state, commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const filters = JSON.parse(JSON.stringify(state.filters));
            if (typeof params.month !== "undefined"){
                filters.month = params.month
            }
            if (typeof params.year !== "undefined"){
                filters.year = params.year
            }
            if (typeof params.fromDate !== "undefined"){
                filters.fromDate = params.fromDate
            }
            commit("SET_FILTERS", filters);
            dispatch("list", filters);
        }
    },
    clearFilterTaxReceipt({state, commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const filters = JSON.parse(JSON.stringify(state.filters))
            if (typeof params.month !== "undefined"){
                delete filters.month;
            }
            if (typeof params.year !== "undefined"){
                delete filters.year;
            }
            if (typeof params.fromDate !== "undefined"){
                delete filters.fromDate;
            }
            commit("SET_FILTERS", filters);
            dispatch("list", filters);
        }
    },
    setSortTaxReceipt({commit, dispatch}, params) {
        if (typeof params !== "undefined") {
            const sort = `${ params.sort}`;
            const typeSort = `${ params.typeSort}`;
            commit("SET_SORT", sort);
            commit("SET_TYPE_SORT", typeSort);
            dispatch("list");
        }
    },
    /* eslint-enable */
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal,
        ...taxReceiptsCatalog.state
    },
    getters: {
        ...getters,
        ...taxReceiptsCatalog.getters
    },
    actions: {
        ...actions,
        ...taxReceiptsCatalog.actions
    },
    mutations: {
        ...mutations,
        ...taxReceiptsCatalog.mutations
    }
};
