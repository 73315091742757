<template lang="html">

    <CardSimple
        :title="$t('debtors.general.title')"
        :subtitle="$t('debtors.general.subtitle')"
    >
        <BasicSelect
            required
            :label="$t('debtors.general.status.title')"
            labelFor
            help
            idHelpTooltip="statusTooltip"
            :helpText="$t('debtors.general.help.text')"
            helpIcon="banana banana-question-circle1"
        >

            <multiselect
                v-model="status"
                :options="options"
                :close-on-select="true"
                :preserve-search="true"
                :placeholder="$t('debtors.general.status.placeholder')"
                label="name"
                track-by="name"
                :preselect-first="false"
            >
                <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                >
                    <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span>
                </template>
            </multiselect>
        </BasicSelect>
        <h1 class="c-info_text f-20 principal-font-regular m-b-30 m-t-40">
            {{ $t('debtors.fields.title') }}
        </h1>

        <BasicInput
            v-for="(field, index) in payment.fields" :key="`genral-${field.name}`"
            required
            v-model="payment.fields[index].value"
            :labelFor="field.name"
            :inputId="field.name"
            :label="field.name"
            :inputPlaceholder="$t('debtors.fields.placeholder')"
        >
        </BasicInput>

        <h1 class="c-info_text f-20 principal-font-regular m-b-30 m-t-40">
            {{ $t('debtors.references.title') }}
        </h1>

        <BasicInput
            v-for="(reference, index) in payment.references" :key="`general-${reference.name}`"
            required
            v-model="payment.references[index].value"
            :labelFor="reference.name"
            :inputId="reference.name"
            :label="reference.name"
            :inputPlaceholder="$t('debtors.references.placeholder')"
        >
        </BasicInput>

        <div>
        <button
            id
            class="collapse-btn"
            type="button"
            v-b-toggle.collapse-config
        >
            {{ $t('debtors.advanced-config') }}
            <i aria-hidden="true" class="banana banana-chevron-down"></i>
        </button>
        <b-collapse
            id="collapse-config"
            class="m-t-40"
        >
            <slot name="advancedSettings"></slot>
        </b-collapse>
        <div class="row m-t-40">
            <div class="col-12 vertical-center justify-content-end">
                <button class="button button-ghost sm button-plain_text" @click="cancel">
                    {{$t('general.actions.cancel.title')}}
                </button>
                <button class="button button-filled sm button-accent1" :class="{disabled: !isValid}" @click="next" v-if="!editMode">
                    {{$t('general.actions.next.title')}}
                </button>
                <button class="button button-filled sm button-accent1 m-l-10" :class="{disabled: !isValid}" @click="finish" v-if="editMode">
                    {{$t('general.actions.save')}}
                </button>
            </div>
        </div>
    </div>
  </CardSimple>

</template>

<script lang="js">
  import BasicInput from "@/components/BasicInput.vue";
  import BasicSelect from "@/components/BasicSelect.vue";
  import CardSimple from '@/components/CardSimple.vue'
  import Multiselect from "vue-multiselect";

  export default {
    name: 'general-debtors',
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        slotValid: {type: Boolean, default: true},
        debt: {
            type: Object,
            default: () => {
                return {
                    fields: [],
                    references: [],
                }
            }
        },
        payment: {
            type: Object,
            default: () => {
                return {
                    fields: [],
                    references: [],
                    activeService: false,
                    comingSoon: false
                }
            }
        }
    },
    components: {
        BasicInput,
        BasicSelect,
        CardSimple,
        Multiselect
    },
    mounted () {
            if (this.debt.references && (!this.editMode || this.payment.references.length === 0)) {
                this.debt.references.forEach(r => this.payment.references.push(JSON.parse(JSON.stringify(r))))
            }
            if (this.debt.fields && (!this.editMode || this.payment.fields.length === 0)) {
                this.debt.fields.forEach(r => this.payment.fields.push(JSON.parse(JSON.stringify(r))))
            }
        if (this.payment) {
            if (this.payment.activeService) {
                this.status = this.options[0];
            } else if (this.payment.comingSoon) {
                this.status = this.options[2];
            } else {
                this.status = this.options[1];
            }
        }
    },
    data () {
      return {
        value: [],
        status: { name: "Inactivo" },
        options: [
          { name: "Activo" },
          { name: "Inactivo" },
          { name: "Próximamente" }
        ],
      }
    },
    methods: {
      cancel() {
          this.$emit('cancel');
      },
      next() {
          this.$emit('next');
      },
      finish() {
          this.$emit('finish');
      },
    },
    computed: {
        isValid() {
            let isValid = true;
            this.debt.fields.forEach(df => {
                const field = this.payment.fields.find(pf => pf.name === df.name);
                if (!field || !field.value || field.value.length === 0) {
                    isValid = false;
                }
            });
            this.debt.references.forEach(dr => {
                const reference = this.payment.references.find(pr => pr.name === dr.name);
                if (!reference || !reference.value || reference.value.length === 0) {
                    isValid = false;
                }
            });
            return isValid;
        }
    },
    watch: {
        status(value) {
            if (value.name === 'Activo') {
                this.payment.activeService = true;
                this.payment.comingSoon = false;
            } else if (value.name === 'Próximamente') {
                this.payment.activeService = false;
                this.payment.comingSoon = true;
            } else {
                this.payment.activeService = false;
                this.payment.comingSoon = false;
            }
            this.$forceUpdate();
        }
    }
}
</script>
