<template>
    <div class="col-12 p-0">
        <WizardForm clazz="form-wizard-white"
                    :hideFooter="true">
            <template v-slot:wizardView>
                <tab-content
                        :title="$t('api-configuration.checkout.selected.javascript.Instructions.step1.title')"
                        icon="one"
                >
                    <CardSimple
                            :title="$t('api-configuration.checkout.selected.javascript.Instructions.step1.title-number')"
                            clazz="b-shadow-none p-0 m-b-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                    v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step1.instruction')"></p>
                                </div>

                                <div class="col-12">
                                    <codemirror :options="options" v-model="codeSDK"
                                                @ready="onCmReady('1', $event)"></codemirror>
                                </div>

                                <div class="col-12 vertical-center m-t-40">
                                    <button
                                            type="button"
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            @click="nextStep(1)"
                                    > {{ $t('api-configuration.api-test-verification.next-test') }}
                                    </button>
                                </div>
                            </div>
                    </CardSimple>

                </tab-content>
                <tab-content
                        :title="$t('api-configuration.checkout.selected.javascript.Instructions.step2.title')"
                        icon="two"
                >
                    <CardSimple
                            :title="$t('api-configuration.checkout.selected.javascript.Instructions.step2.title-number')"
                            clazz="b-shadow-none p-0 m-b-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                    v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step2.instruction')"></p>
                                </div>

                                <div class="col-12">
                                    <codemirror id="codeMirror2" :options="options" v-model="codeDiv"
                                                @ready="onCmReady('2', $event)"></codemirror>
                                </div>

                                <div class="col-12 vertical-center m-t-40">
                                    <button
                                            type="button"
                                            class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                            @click="previousStep(2)"
                                    > {{ $t('api-configuration.api-test-verification.previous-test') }}
                                    </button>

                                    <button
                                            type="button"
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            @click="nextStep(2)"
                                    > {{ $t('api-configuration.api-test-verification.next-test') }}
                                    </button>
                                </div>
                            </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.checkout.selected.javascript.Instructions.step3.title')"
                        icon="three"
                >
                    <CardSimple
                            :title="$t('api-configuration.checkout.selected.javascript.Instructions.step3.title-number')"
                            clazz="b-shadow-none p-0 m-b-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                    v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step3.instruction')"></p>
                                </div>

                                <div class="col-12">
                                    <codemirror :options="options" v-model="codeCredential"
                                                @ready="onCmReady('3', $event)"></codemirror>
                                </div>

                                <div class="col-12 vertical-center m-t-40">
                                    <button
                                            type="button"
                                            class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                            @click="previousStep(3)"
                                    > {{ $t('api-configuration.api-test-verification.previous-test') }}
                                    </button>

                                    <button
                                            type="button"
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            @click="nextStep(3)"
                                    > {{ $t('api-configuration.api-test-verification.next-test') }}
                                    </button>
                                </div>
                            </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.checkout.selected.javascript.Instructions.step4.title')"
                        icon="four"
                >
                    <CardSimple
                            :title="$t('api-configuration.checkout.selected.javascript.Instructions.step4.title-number')"
                            clazz="b-shadow-none p-0 m-b-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                    v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step4.instruction')"></p>
                                </div>

                                <div class="col-12">
                                    <codemirror :options="options" v-model="codeOrder"
                                                @ready="onCmReady('4', $event)"></codemirror>
                                </div>

                                <div class="col-12 vertical-center m-t-40">
                                    <button
                                            type="button"
                                            class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                            @click="previousStep(4)"
                                    > {{ $t('api-configuration.api-test-verification.previous-test') }}
                                    </button>

                                    <button
                                            type="button"
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            @click="nextStep(4)"
                                    > {{ $t('api-configuration.api-test-verification.next-test') }}
                                    </button>
                                </div>
                            </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.checkout.selected.javascript.Instructions.step5.title')"
                        icon="five"
                >
                    <CardSimple
                            :title="$t('api-configuration.checkout.selected.javascript.Instructions.step5.subtitle')"
                            clazz="b-shadow-none p-0 m-b-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                    v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step5.instruction')">
                                    </p>

                                </div>

                                <div class="center-item">
                                    <img :src="button" width="40%" height="90%" alt=""/>
                                </div>

                                <div class="col-12 vertical-center m-t-40">
                                    <button
                                            type="button"
                                            class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                            @click="previousStep(5)"
                                    > {{ $t('api-configuration.api-test-verification.previous-test') }}
                                    </button>

                                    <button
                                            type="button"
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            @click="nextStep(5)"
                                    > {{ $t('api-configuration.api-test-verification.next-test') }}
                                    </button>
                                </div>
                            </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.checkout.selected.javascript.Instructions.step6.title')"
                        icon="six"
                >
                    <CardSimple
                            :title="$t('api-configuration.checkout.selected.javascript.Instructions.step6.subtitle')"
                            clazz="b-shadow-none p-0 m-b-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                    v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step6.instruction')"></p>
                                </div>

                                <div class="col-12 m-b-25">
                                    <codemirror :options="options" v-model="codePayment"
                                                @ready="onCmReady('6', $event)"></codemirror>
                                </div>

                                <div class="col-12">
                                    <b-alert
                                            class="alert-note"
                                            show
                                            variant="info"
                                    >
                                        <p class="text-container">
                                            <img
                                                    class="img-fluid icon"
                                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                                    alt="info"
                                            />
                                            <span v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step6.help')"></span>
                                        </p>
                                    </b-alert>
                                </div>

                                <div class="col-12 vertical-center m-t-40">
                                    <button
                                            type="button"
                                            class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                            @click="previousStep(6)"
                                    > {{ $t('api-configuration.api-test-verification.previous-test') }}
                                    </button>

                                    <button
                                            type="button"
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            @click="nextStep(6)"
                                    > {{ $t('api-configuration.api-test-verification.next-test') }}
                                    </button>
                                </div>
                            </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.checkout.selected.javascript.Instructions.step7.title')"
                        icon="seven"
                >
                    <CardSimple
                            :title="$t('api-configuration.checkout.selected.javascript.Instructions.step7.subtitle')"
                            clazz="b-shadow-none p-0 m-b-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                    v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step7.instruction')"></p>
                                </div>

                                <div class="col-12 m-b-25">
                                    <codemirror :options="options" v-model="codeError"
                                                @ready="onCmReady('7', $event)"></codemirror>
                                </div>

                                <div class="col-12">
                                    <b-alert
                                            class="alert-note"
                                            show
                                            variant="info"
                                    >
                                        <p class="text-container">
                                            <img
                                                    class="img-fluid icon"
                                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                                    alt="info"
                                            />
                                            <span v-html="$t('api-configuration.checkout.selected.javascript.Instructions.step7.help')"></span>
                                        </p>
                                    </b-alert>
                                </div>

                                <div class="col-12 vertical-center m-t-40">
                                    <button
                                            type="button"
                                            class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                            @click="previousStep(7)"
                                    > {{ $t('api-configuration.api-test-verification.previous-test') }}
                                    </button>

                                    <button
                                            type="button"
                                            class="button xs button-filled button-accent1 m-0-0-0-auto"
                                            @click="nextStep(7)"
                                    > {{ $t('api-configuration.api-test-verification.next-test') }}
                                    </button>
                                </div>
                            </div>
                    </CardSimple>
                </tab-content>
                <tab-content
                        :title="$t('api-configuration.prestashop.instructions.step-five.title')"
                        icon="eight"
                >
                    <CardSimple :title="$t('api-configuration.prestashop.instructions.step-five.subtitle')"
                                clazz="b-shadow-none p-0 m-b-0">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="f-14 c-plain_text body-font-regular d-block m-b-40"
                                        v-html="$t('api-configuration.prestashop.instructions.step-five.instruction')">
                                        </p>

                                        <div class="center-item">
                                            <img :src="tarjeta" width="90%" height="90%" alt=""/>
                                        </div>

                                        <div class="col-12 vertical-center m-t-40">
                                            <button
                                                    type="button"
                                                    class="button xs button-ghost button-plain_text m-0-auto-0-0"
                                                    @click="previousStep(8)"
                                            > {{ $t('api-configuration.api-test-verification.previous-test') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                    </CardSimple>
                </tab-content>
            </template>
        </WizardForm>
    </div>
</template>

<script>
    import WizardForm from '@/components/WizardForm.vue';
    import CardSimple from '@/components/CardSimple.vue';
    import {codemirror} from 'vue-codemirror';
    import 'codemirror/lib/codemirror.css';
    import 'codemirror/mode/xml/xml.js';
    import 'codemirror/addon/edit/closetag.js';
    import 'codemirror/addon/selection/active-line.js';
    import {EventBus} from "@/main";

    import tarjeta from '@/assets/images/prestaShop/tarjeta.png';
    import button from '@/assets/images/checkout/boton_checkout.png';

    const CREDENTIALS = "PagandoCheckout.setCredentials({\n\t\t\tuser: 'USUARIO_PAGANDO',\n\t\t\tpublicKey: 'Test LLAVE_PUBLICA'\n\t\t})";

    export default {
        mixins: [],
        name: "JavaScriptInstructions",
        data() {
            return {
                tarjeta: tarjeta,
                button: button,
                codeSDK: "<!DOCTYPE html>\n" +
                    "<html lang=\"en\">\n" +
                    "  <head>\n" +
                    "    <script type=\"text/javascript\" src=\"https://api.pagando.mx/pagando_checkout.min.js\">\n" +
                    "    <\/script>\n" +
                    "    <!-- Agrega la siguiente etiqueta si requieres soporte para Internet Explorer -->\n" +
                    "    <script>\n" +
                    "      if(/MSIE|Trident/.test(window.navigator.userAgent))\n" +
                    "        document.write('<script src=\"https://api.pagando.mx/pagando_checkout_es5.min.js\"><\/script>');\n" +
                    "    <\/script>\n" +
                    "  </head>\n" +
                    " <body>\n" +
                    "   <div></div>\n" +
                    " </body>\n" +
                    "</html>",
                codeCredential: `<body>\n\t<div id="pagando-button-container"></div>\n\n\n\t<script>\n\t\t${
                        CREDENTIALS
                        }\n\t<` +
                    `/script>\n</body>`,
                codeDiv: `<body>\n\t<div id="pagando-button-container"></div>\n\n\n\t<script>\n\t\t${
                        CREDENTIALS
                        }\n\n\t\tPagandoCheckout.button('pagando-button-container')\n\t<` +
                    `/script>\n</body>`,
                codeOrder: `<body>\n\t<div id="pagando-button-container"></div>\n\n\n\t<script>\n\t\t${
                        CREDENTIALS
                        }\n\n\t\tPagandoCheckout.button('pagando-button-container',` +
                    ` {\n\t\t\tcreateOrder: function () {\n\t\t\t\treturn {\n\t\t\t\t\tamount: 'TOTAL',\n\t\t\t\t\t` +
                    `orderId: 'IDENTIFICADOR ÚNICO'\n\t\t\t\t}\n\t\t\t}\n\t\t})\n\t<` +
                    `/script>\n</body>`,
                codePayment: `<body>\n\n\t<script>\n\t\t${
                        CREDENTIALS
                        }\n\n\t\t` +
                    "var queryString = window.location.search;\n" +
                    "\t\tvar urlParams = new URLSearchParams(queryString);\n" +
                    "\t\tvar orderId = urlParams.get('orderId')\n" +
                    "\n" +
                    "\t\tPagandoCheckout.getStatusOrder(orderId ? orderId : '').then((info) => {\n" +
                    "\t\t\tconsole.log('info: ', info)\n" +
                    "\t\t})\n\t<" +
                    `/script>\n</body>`,
                codeError: `<body>\n\t<div id="pagando-button-container"></div>\n\n\n\t<script>\n\t\t${
                        CREDENTIALS
                        }\n\n\t\t` +
                    "PagandoCheckout.onError((err) => {\n" +
                    "\t\t\tconsole.log('err: ', err.message)\n" +
                    "\t\t\tconsole.log('err: ', err.code)\n" +
                    "\t\t})" +
                    `\n\n\t\t` +
                    `PagandoCheckout.button('pagando-button-container',` +
                    ` {\n\t\t\tcreateOrder: function () {\n\t\t\t\treturn {\n\t\t\t\t\tamount: 'TOTAL',\n\t\t\t\t\t` +
                    `orderId: 'IDENTIFICADOR ÚNICO'\n\t\t\t\t}\n\t\t\t}\n\t\t})` +
                    `\n\t<` +
                    `/script>\n</body>`,
                options: {
                    tabSize: 4,
                    mode: 'text/html',
                    lineNumbers: true,
                    line: true,
                    styleActiveLine: true,
                    autoCloseTags: true,
                    autoRefresh: true,
                    readOnly: true
                },
                codeMirrorsKey: {}
            }
        },
        components: {
            WizardForm,
            CardSimple,
            codemirror
        },
        computed: {},
        methods: {
            nextStep(currentStep) {
                EventBus.$emit(`wizard#goTo`, {from: currentStep - 1, to: currentStep});
                this.refreshCode(currentStep + 1);
            },

            previousStep(currentStep) {
                EventBus.$emit(`wizard#goTo`, {from: currentStep - 1, to: currentStep - 2});
                this.refreshCode(currentStep - 1);
            },

            goToApi() {
                this.$router.replace("/client-admin/apiWeb/api-checkoutbutton/PRESTASHOP");
            },
            onCmReady(key, cm) {
                this.codeMirrorsKey[key] = cm;
            },
            refreshCode(key) {
                const self = this;
                setTimeout(function () {
                    self.codeMirrorsKey[key].refresh();
                }, 10);
            }
        }
    }
</script>
