<template>
    <div>
        <SearchBar
            ref="searchBar"
            showDateRange
            :columValues="columValues"
            :canCreate="true"
            :noOptions="true"
            :loading="loading"
            @changeListLimit="changeListLimit"
            @searchTextChange="searchTextChange"
            @dateRangeChange="dateRangeChange"
            @routerPush="downloadPolizaMovements"
            :buttonText="$t('statements.accounting.download')"
        ></SearchBar>

        <CardSimple hideTitle clazz="p-l-20 p-r-20" v-if="!existInfo">
            <div class="row">
                <div class="col-12">
                    <EmptyState
                        emptyStateDefault
                        :title="$t('general.empty')"
                    >
                        <button
                            id="resetBtn"
                            class="button xs button-stroke button-accent1 m-0-auto m-t-30"
                            type="button"
                            @click="resetForm"
                        >{{$t('general.reset-filters')}}</button>
                    </EmptyState>
                </div>
            </div>
        </CardSimple>

        <template v-if="existInfo">
            <CardSimple clazz="p-0" hideTitle>
                <div id="polizasTable" class="basic-table">
                    <b-table-simple responsive table-variant="xl">
                        <b-thead>
                            <b-tr>
                                <b-th class="w-10">
                                    <span>{{$t('general.fields.number.label')}}</span>
                                </b-th>
                                <b-th class="w-10">
                                    <span>{{$t('general.fields.date.label')}}</span>
                                </b-th>
                                <b-th class="w-10">
                                    <span>{{$t('general.fields.hour.label')}}</span>
                                </b-th>
                                <b-th class="min-w-260px">
                                    <span>{{$t('general.fields.concept.label')}}</span>
                                </b-th>
                                <b-th class="text-align-r w-10 border-bottom-1px">
                                    <span>{{$t('general.fields.credit.label2')}}</span>
                                </b-th>
                                <b-th class="text-align-r w-10 border-bottom-1px">
                                    <span>{{$t('general.fields.debit.label2')}}</span>
                                </b-th>
                                <b-th class="text-align-r w-10 border-bottom-1px">
                                    <span>{{$t('general.fields.balance')}}</span>
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="pm in polizaMovements" :key="pm.id">
                                <b-td class="w-10">
                                    <strong
                                        :id="`id_${pm.poliza}`"
                                        class="hover f-13 c-accent_2 text-underline"
                                        @click="copyClipboard(pm.poliza)"
                                    >{{maskUUID(pm.poliza)}}</strong>
                                    <b-tooltip
                                        :target="`id_${pm.poliza}`"
                                        placement="top"
                                        custom-class="tooltip-menu"
                                    >{{pm.poliza}}</b-tooltip>
                                </b-td>
                                <b-td class="w-10">{{formatDate(pm.createdAt, 'DD/MM/YYYY')}}</b-td>
                                <b-td class="w-10">{{formatDate(pm.createdAt, 'LT')}}</b-td>
                                <b-td class="min-w-260px">{{pm.observations || '-'}}</b-td>
                                <b-td class="text-align-r w-10 border-right-1px">{{returnAmount(pm.debit)}}</b-td>
                                <b-td class="text-align-r w-10">{{returnAmount(pm.credit)}}</b-td>
                                <b-td class="text-align-r w-10">{{returnAmount(pm.auxiliary_balance)}}</b-td>
                            </b-tr>
                            <b-tr class="b-table-row-selected table-active" v-if="totals">
                                <b-td class="w-10"></b-td>
                                <b-td class="w-10"></b-td>
                                <b-td class="w-10"></b-td>
                                <b-td class="min-w-260px text-align-r">
                                    <strong class="f-18 c-primary_1 principal-font-bold f-700 text-upper">{{$t('general.fields.total.label')}}</strong>
                                </b-td>
                                <b-td class="text-align-r w-10 border-right-1px">
                                    <strong class="f-18 c-accent_2 body-font-bold f-700">{{returnAmount(totals.total_debit)}}</strong>
                                </b-td>
                                <b-td class="text-align-r w-10">
                                    <strong class="f-18 c-accent_2 body-font-bold f-700">{{returnAmount(totals.total_credit)}}</strong>
                                </b-td>
                                <b-td class="text-align-r w-10">
                                    <strong class="f-18 c-accent_2 body-font-bold f-700">{{returnAmount(totals.ssscBalance)}}</strong>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </CardSimple>

            <LoadingBar v-if="loading"></LoadingBar>
            <div class="pagination-custom">
                <span class="pagination-count">
                    {{ $tc('statements.accounting.pagination', total, {total: total}) }}
                </span>
                <b-pagination
                    v-model="page"
                    :total-rows="total"
                    :per-page="limit"
                    aria-controls="my-table"
                    next-text
                    prev-text
                    hide-goto-end-buttons
                ></b-pagination>
            </div>
        </template>
    </div>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import CardSimple from '@/components/CardSimple.vue';
import EmptyState from '@/components/EmptyState.vue';
import GeneralLedgerApi from '@/api/generalLedger.api.js';
import {utils} from '@/api/utils.js';
import utils2 from '@/common/utils.js';
import LoadingBar from '@/components/LoadingBar.vue';

export default {
    name: 'SatementsAStatementsAccountingccounting',
    components: {
        SearchBar,
        CardSimple,
        EmptyState,
        LoadingBar,
    },
    data() {
        return {
            loading: false,
            existInfo: false,
            columValues: [],
            polizaMovements: [],
            total: 0,
            page: 1,
            limit: 10,
            searchText: null,
            timezone: {name: 'America/Mexico_city'}
        };
    },
    methods: {
         getPolizaMovements() {
            this.activeFileds = true;
            this.polizaMovements = [];
            this.loading = true;
            GeneralLedgerApi.getPolizaMovementsOrganization({
                dates: this.dates?.dateFrom && this.dates?.dateTo ? this.dates : null,
                page: this.page || 1,
                limit: this.limit || 1,
                search: this.searchText,
                timezone: null,
            }).then(response => {
                this.polizaMovements = response.data.object.polizasMovements;
                this.total = response.data.object.total;
                this.totals = response.data.object.totals;
                this.loading = false;
                if (this.total < 1) {
                    this.existInfo = false;
                    return this.existInfo;
                }
                this.existInfo = true;
                return this.existInfo;
            }).catch(error => {
                this.loading = false;
                this.toastErrorDefault(error);
            });
        },
        downloadPolizaMovements() {
            this.loading = true;
            GeneralLedgerApi.csvOrganization({
                dates: this.dates?.dateFrom && this.dates?.dateTo ? this.dates : null,
                page: this.page || 1,
                limit: this.limit || 1,
                search: this.searchText,
                timezone: null,
            }).then(response => {
                const hiddenElement = document.createElement('a');
                hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(response)}`;
                hiddenElement.target = '_blank';
                hiddenElement.download = 'reporte.csv';
                hiddenElement.click();
            }).catch(error => {
                this.toastErrorDefault(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        maskUUID(id) {
            return id.substring(24).toUpperCase();
        },
        formatDate(date, format){
            return utils.formatDate(date, format, this.timezone.name);
        },
        resetForm() {
            this.total = 0;
            this.limit = 10;
            this.searchText = null;
            this.page = 1;
            this.$refs.searchBar.resetInput();
            this.getPolizaMovements();
        },
        changeListLimit(event) {
            utils2.debounceFixed(() => {
                this.limit = event;
                this.page = 1;
                this.getPolizaMovements();
            });
        },
        searchTextChange(event) {
            utils2.debounceFixed(() => {
                this.page = 1;
                this.searchText = event;
                this.getPolizaMovements();
            });
        },
        dateRangeChange(event) {
            this.page = 1;
            this.dates = {
                dateFrom: new Date(new Date(event.startDate).setHours(0)),
                dateTo: new Date(new Date(new Date(event.endDate).setDate(new Date(event.endDate).getDate()+1)).setHours(0))
            };
            this.getPolizaMovements();
        },
        returnAmount(amount){
            const number = Number.parseFloat(amount);
            if (Number.isNaN(number)) {
                return '-'
            }
            return utils.numberToMoneyString(number);
        },
        copyClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => this.displayNotificationInfo(this.$t('general.actions.copy-clipboard.success')))
                .catch((error) => this.toastErrorDefault(error));
        },
    },
    watch: {
        "page": {
            handler() {
                this.getPolizaMovements();
            }
        }
    },
    mounted() {
        this.getPolizaMovements();
    }
}
</script>
