<template>
    <!-- DOCUMENTATION - Sidebar Menu

        isSidebarSmall:     Toggle class 'small' and check if window < 1281 on function handleResize() (Boolean).
        noSmall:            To check if window < 1281 on function handleResize() (Boolean).
        isSidebarLeftOpen:  To toggle class 'small' on funcion openSidebarLeft(), when component 'SidebarSide' Left is open/close (Boolean).
        v-slot:closeBtn:    Slot to add button, for closing sidebar menu.
        v-slot:footerBtn:   Slot to add footer content, for open/close component 'SidebarSide' Left.
    -->
    <aside id="sidebar-menu" class="sidebar-menu" :class="{ 'small' : isSidebarSmall || noSmall || isSidebarLeftOpen}">
        <div class="menu-head">
            <div class="logo">
                <img class="img-fluid lg" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" width="108" height="40" alt="Pagando Check" />
                <img class="img-fluid xs" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" width="108" height="40" alt="Pagando Check" />
                <div class="close-button">
                    <slot name="closeBtn"></slot>
                </div>
            </div>
        </div>
        <div id="onboardingStep2" class="menu-body">
            <ul class="menu-list">
                <li>
                    <label class="group-title">{{$t('side-bar-menu.home')}}</label>
                </li>
                <li>
                    <router-link to="/client-admin/dashboard" class="group-option" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-home"></i>
                            {{$t('side-bar-menu.control-panel')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-home" class="banana banana-home single"></i>
                        <b-tooltip target="tooltip-home" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.control-panel')}}</b-tooltip>
                    </router-link>
                </li>
            </ul>
            <ul class="menu-list" v-if="$can('do', 'READ_TRANSACTION_LIST_ORGANIZATION') || $can('do', 'CHARGEBACKS_CLIENT_READ')">
                <li>
                    <label class="group-title">{{$t('side-bar-menu.movements')}}</label>
                </li>
                <li v-if="$can('do', 'READ_TRANSACTION_LIST_ORGANIZATION')">
                    <router-link to="/client-admin/collected-payment" class="group-option" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-special-payments"></i>
                            {{$t('side-bar-menu.sales')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-ventas" class="banana banana-special-payments single"></i>
                        <b-tooltip target="tooltip-ventas" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.sales')}}</b-tooltip>
                    </router-link>
                </li>
                <li v-if="$can('do', 'CHARGEBACKS_CLIENT_READ')">
                    <router-link to="/client-admin/chargebacks" class="group-option" :class="activeRouteClass('ChargebacksList')" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-payment-conciliation"></i>
                            {{$t('side-bar-menu.chargebacks')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-chargeback" class="banana banana-payment-conciliation single"></i>
                        <b-tooltip target="tooltip-taxes" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.chargebacks')}}</b-tooltip>
                    </router-link>
                </li>
                <li v-if="$can('do', 'STATEMENTS_READ')">
                    <router-link to="/client-admin/statements" class="group-option" :class="activeRouteClass('ChargebacksList')" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-document-normal"></i>
                            {{$t('side-bar-menu.statements')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-chargeback" class="banana banana-document-normal single"></i>
                        <b-tooltip target="tooltip-taxes" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.statements')}}</b-tooltip>
                    </router-link>
                </li>
            </ul>
            <ul class="menu-list" v-if="$can('do', 'PAYMENT_LINK_READ') || $can('do', 'API_PAYMENTS_ACTIVE')">
                <li>
                    <label class="group-title">{{$t('side-bar-menu.payments')}}</label>
                </li>
                <li>
                    <router-link v-if="$can('do', 'PAYMENT_LINK_READ')"
                        to="/client-admin/payment-link-list" class="group-option" tabindex="" :class="activeRouteClass('Payments')">
                        <span>
                            <i aria-hidden="true" class="banana banana-dispersions"></i>
                            {{$t('paymentLinks.title')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-prod" class="banana banana-dispersions single"></i>
                        <b-tooltip target="tooltip-prod" placement="right" custom-class="tooltip-menu">{{$t('paymentLinks.title')}}</b-tooltip>
                    </router-link>
                    <router-link v-if="$can('do', 'PAYMENTS_READ')" to="/client-admin/payments" class="group-option" tabindex="" :class="activeRouteClass('Payments')">
                        <span>
                            <i aria-hidden="true" class="banana banana-credit-card"></i>
                            {{$t('side-bar-menu.payments-types')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-prod" class="banana banana-credit-card single"></i>
                        <b-tooltip target="tooltip-prod" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.payments-types')}}</b-tooltip>
                    </router-link>
                    <router-link
                            v-if="$can('do', 'API_PAYMENTS_ACTIVE')"
                            to="/client-admin/api-list"
                            class="group-option"
                            tabindex=""
                            :class="activeRouteClass('ApiConfiguration')"
                    >
                        <span>
                            <i aria-hidden="true" class="banana banana-internet"></i>
                            {{$t('side-bar-menu.web-api')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-api" class="banana banana banana-internet single"></i>
                        <b-tooltip target="tooltip-prod" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.web-api')}}</b-tooltip>
                    </router-link>
                </li>
                <li v-if="$can('do', 'PAYMENTS_TEMPLATE_READ')">
                    <router-link to="/client-admin/payment-template" class="group-option" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-download"></i>
                            Plantilla de Pagos
                        </span>
                        <i aria-hidden="true" id="tooltip-plantilla-pagos" class="banana banana-download single"></i>
                        <b-tooltip target="tooltip-plantilla-pagos" placement="right" custom-class="tooltip-menu"> Plantilla de Pagos </b-tooltip>
                    </router-link>
                </li>
            </ul>
            <ul class="menu-list" v-if="$can('do', 'INFO_ORGANIZATION_READ')
            || $can('do', 'PERMISSIONS_READ')
            ">
                <li>
                    <label class="group-title">{{$t('side-bar-menu.admin')}}</label>
                </li>
                <li v-if="$can('do', 'PERMISSIONS_READ')">
                    <router-link to="/client-admin/permissions-list" class="group-option" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-credit-card-generic"></i>
                            Permisos
                        </span>
                        <i aria-hidden="true" id="tooltip-documents" class="banana banana-credit-card-generic single"></i>
                        <b-tooltip target="tooltip-documents" placement="right" custom-class="tooltip-menu"> Permisos </b-tooltip>
                    </router-link>
                </li>
                <li v-if="$can('do', 'PERMISSIONS_READ')">
                    <router-link to="/client-admin/collaborators-list" class="group-option" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-credit-card-generic"></i>
                            Colaboradores
                        </span>
                        <i aria-hidden="true" id="tooltip-collaborators" class="banana banana-credit-card-generic single"></i>
                        <b-tooltip target="tooltip-collaborators" placement="right" custom-class="tooltip-menu"> Collaboradores </b-tooltip>
                    </router-link>
                </li>
                <li v-if="$can('do', 'INFO_ORGANIZATION_READ')">
                    <router-link to="/client-admin/organization-info" class="group-option" :class="activeRouteClass('OrganizationInfo')" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-building"></i>
                            {{$t('side-bar-menu.my-organization')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-org" class="banana banana-building single"></i>
                        <b-tooltip target="tooltip-org" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.my-organization')}}</b-tooltip>
                    </router-link>
                </li>
                <li v-if="$can('do', 'TAX_RECEIPTS_READ')">
                    <router-link to="/client-admin/tax-receipts" class="group-option" :class="activeRouteClass('TaxReceipts')" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-piggy-bank"></i>
                            {{$t('side-bar-menu.tax-receipts')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-taxes" class="banana banana-piggy-bank single"></i>
                        <b-tooltip target="tooltip-taxes" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.tax-receipts')}}</b-tooltip>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link to="/client-admin/terminal-list" class="group-option" :class="activeRouteClass('TerminalList')" tabindex="">
                        <span>
                            <i aria-hidden="true" class="banana banana-device-smartphone"></i>
                            {{$t('side-bar-menu.terminal-list')}}
                        </span>
                        <i aria-hidden="true" id="tooltip-taxes" class="banana banana-piggy-bank single"></i>
                        <b-tooltip target="tooltip-taxes" placement="right" custom-class="tooltip-menu">{{$t('side-bar-menu.terminal-list')}}</b-tooltip>
                    </router-link>
                </li> -->
            </ul>
        </div>
        <div class="last-session">
            <small>{{$t('side-bar-menu.last-seen')}}</small>
            <small>{{formatDate(actualUser.lastLogin)}}</small>
            <i aria-hidden="true" id="lastSession" class="banana banana-info-circle2"></i>
            <b-tooltip target="lastSession" placement="right" custom-class="tooltip-menu">
                <small class="f-13 principal-font-bold d-block">{{$t('side-bar-menu.last-seen')}}</small>
                <small class="f-14 body-font-bold d-block">{{formatDate(actualUser.lastLogin)}}</small>
            </b-tooltip>
        </div>
        <div id="onboardingStep3" class="menu-footer">
            <slot name="footerBtn"></slot>
        </div>
    </aside>
</template>

<script>
    import {
        STORE as SessionStore,
        GET_USER
    } from '@/store/modules/session/session.types';
    import {mapGetters} from 'vuex';
    import utils from '@/common/utils'
    export default {
        name: 'SidebarMenu',
        components: {},
        props: {
            clazz: String,
            isSidebarSmall: {type: Boolean, default: false},
            noSmall: {type: Boolean, default: false},
            isSidebarLeftOpen: {type: Boolean, default: false}
        },
        data() {
            return {
                currentRoute: ''
            }
        },
        mounted() {
            this.currentRoute = this.$router.currentRoute.name;
        },
        methods: {
            activeRouteClass(routeName) {
                return this.currentRoute === routeName ? 'active' : '';
            },
            formatDate(){
                if(utils.isDefined(this.actualUser)){
                    return utils.toFormatDate(this.actualUser.lastLogin, "DD/MM/YYYY, HH:mm a");
                }

                    return utils.toFormatDate(new Date(), "DD/MM/YYYY, HH:mm a");


            }
        },
        watch: {
            '$route' (to) {
                this.currentRoute = to.name;
            }
        },
        computed: {
            ...mapGetters(SessionStore, {
                actualUser: GET_USER
            })
        }
    }
</script>

<style lang="scss">
    .sidebar-menu {
        width: 250px;
        height: 100%;
        max-height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        box-shadow: 0 0 4px 0 rgba(56, 73, 99, 0.2);
        z-index: 3;
        transition: all 0.5s;

        .close-button {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: -16px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .menu-head {
            height: 112px;

            .logo {
                position: relative;
                padding: 18px 30px;
                height: 70px;

                img {
                    max-height: 40px;
                    object-fit: contain;
                    margin: 0;
                }

                .lg {
                    display: flex;
                }

                .xs {
                    display: none;
                }
            }

            .balance-pending {
                padding: 40px 30px;
                overflow: hidden;
                width: 100%;

                > label {
                    font-size: 32px;
                    font-weight: bold;
                    line-height: 1.13;
                    display: block;
                    margin-bottom: 0;
                }

                > p {
                    font-size: 13px;
                    font-weight: bold;
                    line-height: 1.31;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;

                    i {
                        font-size: 18px;
                        margin-left: 6px;
                    }
                }

                > small {
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 1.64;
                    display: none;
                }
            }
        }

        .menu-body {
            height: calc(100% - 262px);
            min-height: calc(100vh - 262px);
            max-height: calc(100vh - 262px);
            max-width: 250px;
            overflow-x: hidden;
            overflow-y: overlay;//auto;
            border-right: 3px solid transparent;


            &::-webkit-scrollbar,
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
            }
            &::-webkit-scrollbar {
                width: 4px;
                height: 100%;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(20, 34, 102, 0.4);
            }

            .menu-list {
                position: relative;
                display: block;
                list-style: none;
                margin: 0 0 40px;
                padding: 0;

                > li {
                    .group-title {
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 1.33;
                        text-transform: uppercase;
                        display: block;
                        margin-bottom: 0;
                        padding: 8px 30px;
                    }

                    .group-option {
                        display: block;
                        position: relative;
                        padding: 12px 30px;
                        border-left: 6px solid transparent;
                        transition: all 0.5s;

                        > span {
                            font-size: 13px;
                            font-weight: bold;
                            line-height: 1.31;
                            display: flex;
                            align-items: center;
                            // white-space: nowrap;
                            transition: all 0.5s;

                            > i {
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 24px;
                                margin-right: 20px;
                                transition: all 0.5s;
                            }
                        }

                        > i.single {
                            position: relative;
                            display: none;
                            width: 50px;
                            min-width: 50px;
                            height: 40px;
                            max-height: 40px;
                            margin: auto;
                            align-items: center;
                            justify-content: center;
                            border-radius: 3px;
                            font-size: 28px;
                            background-color: transparent;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }

        .last-session {
            padding: 15px 0;
            position: absolute;
            height: 60px;
            left: 0;
            bottom: 90px;
            width: 100%;
            max-width: 250px;
            -webkit-box-shadow: 0px -16px 12px -4px #ffffff;
            box-shadow: 0px -16px 12px -4px #ffffff;

            small {
                &:first-of-type {
                    opacity: 0.4;
                    font-size: 13px;
                    font-weight: bold;
                    line-height: 1.23;
                    text-align: center;
                    display: block;
                    margin-bottom: 2px;
                }

                &:last-of-type {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.21;
                    text-align: center;
                    display: block;
                }
            }

            i {
                font-size: 28px;
                transition: 0.5s;
                position: relative;
                display: none;
                width: 50px;
                min-width: 50px;
                height: 40px;
                max-height: 40px;
                margin: auto;
                align-items: center;
                justify-content: center;
            }
        }

        .menu-footer {
            height: 90px;
            width: 100%;
            max-width: 250px;
            padding: 20px 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s;

            .actual-org {
                width: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;

               .img-org {
                    position: relative;
                    width: 50px;
                    min-width: 50px;
                    height: 50px;
                    max-height: 50px;
                    padding: 2px;
                    border-radius: 6px;
                    margin-right: 12px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        min-width: 50px;
                        min-height: 50px;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        position: absolute;
                        margin: auto;
                        display: flex;
                        transition: all 0.5s;
                    }
                }

                > span {
                    display: block;
                    overflow: hidden;

                    label {
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 1.33;
                        display: block;
                        margin-bottom: 0;
                        text-transform: uppercase;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    p {
                        font-size: 13px;
                        font-weight: bold;
                        line-height: 1.31;
                        // display: block;
                        margin-bottom: 0;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                    }
                }

                > i {
                    font-size: 25px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &:hover {
                .actual-org .img-org img {
                    transform: rotate(-10deg) scale(1.1);
                }
            }
        }

        &.small {
            width: 80px;

            .menu-head {
                .logo {
                    padding: 18px 10px;
                    .lg {
                        display: none;
                    }

                    .xs {
                        display: flex;
                    }
                }

                .close-button {
                    right: -50px;
                    .banana-menu {
                        display: flex;
                    }
                    .banana-arrow-left {
                        display: none;
                    }
                }

                .balance-pending {
                    padding: 40px 10px;
                    > label,
                    > p {
                        display: none;
                    }

                    > small {
                        display: block;
                    }
                }
            }

            .menu-body {
                .menu-list {
                    > li {
                        .group-title {
                            display: none;
                        }

                        .group-option {
                            padding: 18px 10px;
                            border: none;
                            background-color: transparent;

                            > span {
                                display: none;
                            }

                            > i.single {
                                display: flex;
                            }

                            &:hover {
                                border: none;
                                background-color: transparent;
                            }

                            &.active {
                                border: none;
                                background-color: transparent;
                            }
                        }
                    }

                    &::after {
                        position: absolute;
                        content: " ";
                        width: 30px;
                        height: 1px;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }

                    &:first-of-type::after {
                        display: none;
                    }
                }
            }

            .last-session {
                padding: 15px 0;
                position: absolute;
                height: 60px;
                left: 0;
                bottom: 90px;
                width: 100%;
                max-width: 250px;

                small {
                    display: none;
                }

                i {
                    display: block;
                }
            }

            .menu-footer {
                max-width: 80px;
                padding: 10px;

                .actual-org {
                    padding: 0px;
                }

                .actual-org > span,
                .actual-org > i {
                    display: none;
                }

                .actual-org .img-org {
                    margin: auto;
                }
            }
        }
    }
</style>
