<template>
    <div>
        <b-progress
                :max="maxBar"
                class="bar-progress percent-color"
                :class="classTime"
        >
            <b-progress-bar :value="proggresBarValue">
                <div class="label-progress">
                                            <span :class="classTime">
                                                        {{ formatTime }}
                                                    </span>
                </div>
            </b-progress-bar>
        </b-progress>
    </div>
</template>

<script>
    import moment from "moment";

    moment.locale('es');
    export default {
        name: 'RemainingTime',
        data() {
            return {
                proggresBarValue: 10,
                formatTime: "",
                classTime: "success"
            }
        },
        props: {
            limitDate: {type: String},
            status: {type: String},
            maxBar: {type: Number},
            evidence: {}
        },
        created(){
            this.formatLeftTime();
            this.getValueProgress();
        },
        methods: {
            setDynimicCount() {
                setTimeout(() => {
                    const diff = moment(this.limitDate).diff(Date.now(), 'hours', true);
                    const diffMinutes = diff % 1 * 60;
                    this.formatTime = `${Math.trunc(diff)} hrs ${Math.trunc(diffMinutes)} mins`;
                    this.setDynimicCount();
                }, 1000)
            },
            setDynimicValue() {
                setTimeout(() => {
                    const limitDateTime = new Date(this.limitDate).getTime();
                    const remainingTime = limitDateTime - Date.now();
                    if (remainingTime < 0 ) {
                        this.proggresBarValue = limitDateTime;
                    } else {
                        this.proggresBarValue = remainingTime;
                        this.setDynimicValue();
                        this.getProgressClass();
                    }
                }, 1000)
            },
            formatLeftTime() {
                if (!this.limitDate) {
                    this.formatTime = 0;
                    return;
                }
                if(this.evidence && this.evidence.uploadedAt){
                    this.formatTime = 'Entregado';
                    return;
                }
                const diff = moment(this.limitDate).diff(Date.now(), 'hours', true);
                if (diff < 0) {
                    this.formatTime = 'Tiempo excedido';
                } else {
                    if(this.status === 'LOST'){
                        this.formatTime = 'No enviado';
                        return;
                    }
                    this.setDynimicCount();
                }
            },
            getProgressClass() {
                const limitDateTime = new Date(this.limitDate).getTime();
                const totalBar = this.maxBar;
                const remainingTime = limitDateTime - Date.now();
                this.classTime = 'placeholder'
                if (!this.limitDate) {
                    this.classTime = 'placeholder'
                } else if (this.status === 'REVISION') {
                    this.classTime = 'info'
                } else if (this.status === 'WIN') {
                    this.classTime = 'info'
                } else if (this.status === 'LOST') {
                    this.classTime = 'info'
                } else if (remainingTime < totalBar * 0.33) {
                    this.classTime = 'error'
                } else if (remainingTime > totalBar * 0.33 && remainingTime < totalBar * 0.66) {
                    this.classTime = 'warning'
                } else if (remainingTime > totalBar * 0.66) {
                    this.classTime = 'success'
                }
            },
            getValueProgress() {
                if (!this.limitDate) {
                    this.proggresBarValue = limitDateTime;
                    this.getProgressClass()
                }
                const limitDateTime = new Date(this.limitDate).getTime();
                if (this.status !== 'APPLIED') {
                    this.proggresBarValue = limitDateTime;
                    this.getProgressClass()
                }
                if(this.status === "REVISION" || this.status === "WIN" || this.status === "LOST"){
                    this.proggresBarValue = limitDateTime;
                    this.getProgressClass()
                } else {
                    const remainingTime = limitDateTime - Date.now();
                    if (remainingTime < 0 ) {
                        this.proggresBarValue = limitDateTime;
                        this.getProgressClass()
                    } else {
                        this.setDynimicValue();
                    }
                }
            },
        }
    }
</script>
