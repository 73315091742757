import axios from 'axios'
import base from './../base.api';

const namespace = 'pagando';

export default {
    /*
    This function gets all general info for api configuration as process step and domains info
     */
    getApiConfigurationInfo: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/api-configuration-info`, {
            params: params
        })
            .then(onSuccess)
            .catch(onError);
    },
    /*
    This function gets a summary of test information as:
    - Test name
    - Test status
     */
    getApiTestInfo: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/api-test-info`, {
            params: params
        })
            .then(onSuccess)
            .catch(onError);
    },
    /*
    This function is used to gets key information and regenerate if it is needed

    NOTE: This key is used for jwt authentication
     */
    getApiKey: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-api`, {
            params: params
        })
            .then(onSuccess)
            .catch(onError);
    },
    /*
    This function is used to gets user identifier used in jwt authentication
     */
    getApiUser: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-user`, {
            params: params
        })
            .then(onSuccess)
            .catch(onError);
    },
    /*
    Function that save all form information about domains info
     */
    saveDomainInformation: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-domain-information`, params)
            .then(onSuccess)
            .catch(onError);
    },
    checkVerificationTests: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/check-verification-tests`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getIpFromDomain: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-ip-domain`, {
            params: params
        })
            .then(onSuccess)
            .catch(onError);
    },
    getApiCurrentTestInfo: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/api-current-test-info`, {
            params: params
        })
            .then(onSuccess)
            .catch(onError);
    },

    saveCheckoutData: (body = {}) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-checkout-button`, body);
    },

    getCheckoutData: (params = '') => {
        return axios.get(`${base.baseUrl}/${namespace}/get-checkout-button`, {params: params});
    },

    getUserTest: (params = '') => {
        return axios.get(`${base.baseUrl}/${namespace}/users/userTest`, {params: params});
    },
    saveKeyProductionHistory: (params = "", onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-key-history`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getApiKeysEnabled: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/api-keys-enabled`, params)
            .then(onSuccess)
            .catch(onError);
    },
}
