<template>
    <div>
        <router-link
            to="/signin"
            class="button xs button-ghost button-primary1 m-b-40 p-l-0 p-r-5"
            tabindex=""
        > <i aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('recovery-email.return-to-login')}} </router-link>

        <GenericTitle
            :title="$t('recovery-email.title')"
            :titleStrong="$t('recovery-email.title-strong')"
        >
            <p class="f-14 c-plain_text body-font-light d-block m-b-40">
                {{$t('recovery-email.check-your-email1')}}<strong class="c-accent_2">{{ email }}</strong> {{$t('recovery-email.check-your-email2')}}
            </p>
        </GenericTitle>
        <img
            class="img-fluid d-flex m-0-auto m-b-40"
            src="@/assets/images/Illustrations/mail-lock.svg"
            alt="Imagen email"
        />
        <button
            id="btn-helpGoesButton"
            class="button xs button-filled button-accent1 w-100 m-b-30"
            type=""
            @click.prevent="resendRestore"
        > {{$t('recovery-email.resend-email')}} </button>

        <small class="f-14 c-info_text body-font-regular text-align-c d-block">
            {{$t('recovery-email.resend-email-instructions')}} <strong> {{$t('recovery-email.resend-email-instructions-strong')}} </strong>
        </small>

        <b-alert
            v-if="getRecoveryAlert"
            class="alert-note"
            show
            :variant="getAlertMessage.variant"
        >
            <p class="text-container">
                <img
                    class="img-fluid"
                    :src=" getAlertMessage.variant === 'error' ? imgError : imgSuccess"
                    alt="Imagen alerta"
                />
                {{getAlertMessage.message}}
            </p>
        </b-alert>

    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import {
    STORE as UserSignUpStore,
} from '@/store/modules/signin/signin.types'
import imgError from '@/assets/images/Icons/Alerts-icons/icon-alert-error.svg';
import imgSuccess from '@/assets/images/Icons/Alerts-icons/icon-alert-success.svg';

import GenericTitle from '@/components/GenericTitle.vue'

const storeModule = 'recoveryPassword'

export default {
    name: "SignIn",
    props: {
        email: {
            type: String
        }
    },
    data() {
        return {
            imgError: imgError,
            imgSuccess: imgSuccess
        }
    },
    components: {
        GenericTitle,
    },
    methods: {
        resendRestore () {
            if (this.email !== undefined) {
                this.$store.dispatch(`${storeModule}/resendRestorePassword`, this.email);
            }
        }
    },
    computed: {
        ...mapGetters(storeModule, ['getCurrentEmail', 'getAlertMessage', 'getRecoveryAlert']),
        currentEmail () {
            return this.email;
        }
    },
    created () {
        this.$store.commit(`${UserSignUpStore}/SET_RIGHT_SIDE_BODY`, 'DONT_WORRY');
    }
};
</script>
