<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                    tabindex=""
                    :to="`/client-admin/debtors-list/${service._id}`"
                ><i aria-hidden="true" class="banana banana-arrow-left"></i> {{ $t('debtors.return-config') }}</router-link>
                <FloatingTitleLayout :title="$t('debtors.edit')" />
            </div>

            <DebtorsSummary
                v-if="!editing"
                :payment="payment"
                :debt="service"
                @cancel="cancel()"
                @finish="finish()"
                @goTo="goTo"
                editMode
                :editVersion="editVersion"
            >
            </DebtorsSummary>

            <DebtorsGeneral
                v-if="currentStep === 0"
                :payment="payment"
                :debt="service"
                @cancel="cancel()"
                @finish="finish()"
                @goTo="goTo"
                editMode
            >
                  <ServicesDebtGeneralAdvanced
                    slot="advancedSettings"
                    :payment="payment"
                    @validityChange="slotValidityChange(step, $event)"
                />
            </DebtorsGeneral>

            <DebtorsConcepts
                v-if="currentStep === 1"
                :payment="payment"
                :debt="service"
                @cancel="cancel()"
                @finish="finish()"
                @goTo="goTo"
                editMode
            ></DebtorsConcepts>

            <DebtorsDiscounts
                v-if="currentStep === 2"
                :payment="payment"
                :debt="service"
                @cancel="cancel()"
                @finish="finish()"
                @goTo="goTo"
                editMode
            ></DebtorsDiscounts>

        </div>
    </div>
</template>

<script>
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import DebtorsGeneral from '@/views/payments/debtors/General.vue';
import ServicesDebtGeneralAdvanced from '@/views/services/DebtGeneralAdvanced.vue';
import { mapGetters } from 'vuex';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION } from "@/store/modules/accessconfig/accessConfig.types";
import PaymentsCRUDApi from '@/api/payments/crud.api.js';
import DebtorsApi from '@/api/debt/debt.api.js';
import ToastSnotify from '@/mixins/toastSnotify.mixin';
import DebtorsConcepts from '@/views/payments/debtors/Concepts.vue';
import DebtorsDiscounts from '@/views/payments/debtors/Discounts.vue';
import DebtorsSummary from '@/views/payments/debtors/Summary.vue';
const ERROR_TITLE_KEY = 'general.actions.error.title';
const ERROR_TEXT_KEY = 'general.actions.error.text';

export default {
    name: "Debtors",
    mixins: [ToastSnotify],
    components: {
        FloatingTitleLayout,
        DebtorsGeneral,
        DebtorsConcepts,
        DebtorsDiscounts,
        DebtorsSummary,
        ServicesDebtGeneralAdvanced
    },
    mounted () {
        this.loading = true;
        PaymentsCRUDApi.get('D', this.$route.params.id).then((data) => {
            return PaymentsCRUDApi.get('D', this.$route.params.debtPayment).then((dataDebtPayment) => {
                this.service = data.data.object;
                this.payment = dataDebtPayment.data.object;
                this.loading = false;
                this.$forceUpdate();
            });
        }).catch((error) => {
            if (error && error.response && error.response.data && error.response.data.message) {
                this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
            } else {
                this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
            }
        });
        this.editVersion = !!this.$route.query.editVersion;
    },
    data () {
        return {
            editVersion: false,
            loading: null,
            currentStep: null,
            editing: false,
            // Date Range Picker
            opens: 'left',
            showDropdowns: true,
            autoApply: true,
            dateRange: [],
            // Multiselect
            checkedSwitch: false,
            value: [],
            service: {
                type: 'D',
                fields: [],
                references: [],
            },
            payment: {
                fields: [],
                references: [],
                items: [],
                discounts: [],
            }
        };
    },
    methods: {
        goTo (target) {
            this.currentStep = target;
            this.editing = true;
        },
        cancel () {
            if (this.editing) {
                this.loading = true;
                this.editing = false;
                this.currentStep = null;
                PaymentsCRUDApi.get('D', this.$route.params.debtPayment).then((dataDebtPayment) => {
                    this.loading = false;
                    this.payment = dataDebtPayment.data.object;
                    this.$forceUpdate();
                    // eslint-disable-next-line sonarjs/no-identical-functions
                }).catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.message) {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                    } else {
                        this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                    }
                });
            } else {
                this.$router.push(`/client-admin/debtors-list/${this.service._id}`);
            }
        },
        finish () {
            this.loading = true;
            DebtorsApi.createDebtPayment(this.service, this.payment).then(() => {
                this.loading = false;
                this.displayNotificationSuccess(this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                this.$router.push(`/client-admin/debtors-list/${this.service._id}`);
                // eslint-disable-next-line sonarjs/no-identical-functions
            }).catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        slotValidityChange (step, value) {
            this.$forceUpdate();
            this.slotValid = value;
        }
    },
    computed: {
        ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION
        }),
    }
};
</script>
