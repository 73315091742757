import AccessConfigApi from "@/api/accessconfig/accessConfig.api";
import OrganizationsApi from "@/api/organization/organizations.api";
import {
    STORE,
    SET_ORGANIZATIONS,
    SET_CURRENT_ORGANIZATION,
    GET_ORGANIZATIONS,
    GET_CURRENT_ORGANIZATION,
    GET_CURRENT_ORGANIZATION_WITH_DATA,
    FETCH_ORGANIZATIONS,
    FETCH_CURRENT_ORGANIZATION,
    GET_ACTIVE_ORGANIZATIONS,
    GET_COMING_SOON_ORGANIZATIONS,
    GET_INACTIVE_ORGANIZATIONS,
    GET_BLOCKED_ORGANIZATIONS,
    SET_SEARCH_TEXT
} from "./accessConfig.types";

function removeRepeatedOrganizations(organizations){
    const organizationsIds= [];
    const organizationsList= [];
    organizations.forEach((org)=> {
        if(!organizationsIds.includes(org._id)){
            organizationsIds.push(org._id);
            organizationsList.push(org);
        }
    });
    return organizationsList
}

// Objetos
const stateGlobal = {
    organizations: [],
    currentOrganization: {},
    actualSearch: ""
};

const getters = {
    [GET_ORGANIZATIONS]: state => state.organizations,
    [GET_CURRENT_ORGANIZATION]: state => state.currentOrganization,
    [GET_ACTIVE_ORGANIZATIONS]: state => {
        const organizations = state.organizations.filter(
            o =>
                o.organization.activeService === true &&
                o.organization.comingSoon !== true &&
                o.organization.adminSwitch !== false &&
                (state.actualSearch !== "" ? (o.organization.name.toLowerCase().indexOf(state.actualSearch.toLowerCase()) !== -1) : true)
        ).map(o => o.organization)
        return removeRepeatedOrganizations(organizations)
    },
    [GET_COMING_SOON_ORGANIZATIONS]: state => {
        const organizations = state.organizations.filter(
            o => {
                return o.organization.comingSoon === true &&
                    o.organization.adminSwitch !== false &&
                    (state.actualSearch !== "" ? (o.organization.name.toLowerCase().indexOf(state.actualSearch.toLowerCase()) !== -1) : true)
            }
        ).map(o => o.organization);
        return removeRepeatedOrganizations(organizations)
    },
    [GET_INACTIVE_ORGANIZATIONS]: state => {
        const organizations = state.organizations.filter(
            o =>
                o.organization.activeService === false &&
                o.organization.comingSoon !== true &&
                o.organization.adminSwitch !== false &&
                (state.actualSearch !== "" ? (o.organization.name.toLowerCase().indexOf(state.actualSearch.toLowerCase()) !== -1) : true)
        ).map(o => o.organization)
        return removeRepeatedOrganizations(organizations)
    },
    [GET_BLOCKED_ORGANIZATIONS]: state => {
        const organizations = state.organizations.filter(
            o =>
                o.organization.adminSwitch === false &&
                (state.actualSearch !== "" ? (o.organization.name.toLowerCase().indexOf(state.actualSearch.toLowerCase()) !== -1) : true)
        ).map(o => o.organization)
        return removeRepeatedOrganizations(organizations)
    },
    [GET_CURRENT_ORGANIZATION_WITH_DATA]: state => state.organizations.find( o => o.organization._id === state.currentOrganization._id)
};

const mutations = {
    [SET_ORGANIZATIONS]: (state, payload) => {
        state.organizations = payload;
    },
    [SET_CURRENT_ORGANIZATION]: (state, payload) => {
        state.currentOrganization = payload;
        if (!state.currentOrganization.id) {
            state.currentOrganization.id = state.currentOrganization._id;
        }
        if (!state.currentOrganization._id) {
            state.currentOrganization._id = state.currentOrganization.id;
        }
        localStorage.setItem(GET_CURRENT_ORGANIZATION, JSON.stringify(payload));
    },
    [SET_SEARCH_TEXT]: (state, payload) => {
        state.actualSearch = payload;
    }
};

const actions = {
    /**
     * Fetches the organizations to wich the current user has access
     */
    [FETCH_ORGANIZATIONS]: ({ commit, dispatch, state }) => {
        return AccessConfigApi.userOrganizations().then(data => {
            commit(SET_ORGANIZATIONS, data.data.object);
            if (!state.currentOrganization._id && data.data.object.length > 0) {
                commit(SET_CURRENT_ORGANIZATION, data.data.object[0]);
                dispatch(FETCH_CURRENT_ORGANIZATION)
            }
        });
    },

    /**
     * Fetches from the server the currently selected organization and commis SET_CURRENT_ORGANIZATION
     */
    [FETCH_CURRENT_ORGANIZATION]: ({ commit, rootGetters }) => {
        const currentOrganization =
            rootGetters[`${STORE}/${GET_CURRENT_ORGANIZATION}`];
        if (currentOrganization) {
            return OrganizationsApi.getById(currentOrganization._id).then(data => {
                commit(SET_CURRENT_ORGANIZATION, data.data.object);
            });
        }
        return Promise.reject(new Error("No current organization selected"));
    }
};

const namespaced = true;
export default { namespaced, state: stateGlobal, getters, actions, mutations };
