<template>
    <div class="content-centered">
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/client-admin/payments"
                    class="button xs button-ghost button-placeholder p-l-5 p-r-5 text-capit"
                    tabindex=""
                >
                    <i aria-hidden="true" class="banana banana-arrow-left"></i> {{$t('pagos.return')}}
                </router-link>
                <FloatingTitleLayout :title="$t(`pagos.types.${paymentType}`)" />
            </div>
            <WizardForm
                clazz="col-12"
                :hideFooter="true"
            >
                <template v-slot:wizardView>
                    <tab-content
                        v-for="(step, index) in steps"
                        v-bind:key="step.title"
                        :title="step.title"
                        :icon="step.icon"
                        :active="step.icon === 'one'"
                    >
                        <b-alert
                            class="alert-note"
                            show
                            variant="info"
                        >
                            <p class="text-container">
                                <img
                                    class="img-fluid icon"
                                    src="@/assets/images/Icons/Alerts-icons/icon-alert-info.svg"
                                    alt="info"
                                />
                                {{$t(`pagos.general.servicio.info.${paymentType}`, valuesForAlert)}}
                            </p>
                        </b-alert>
                        <component
                            :is="step.component"
                            :payment="payment"
                            :slotValid="slotValid"
                            @next="next()"
                            @prev="prev()"
                            @cancel="cancel()"
                            @finish="finish()"
                            @goTo="goTo(index, $event)"
                        >
                            <component
                                v-if="step.slot && step.slotName"
                                :is="step.slot"
                                :slot="step.slotName"
                                :payment="payment"
                                @validityChange="slotValidityChange(step, $event)"
                            />
                        </component>
                    </tab-content>
                </template>
            </WizardForm>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/main';
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import WizardForm from "@/components/WizardForm.vue";
import ServicesGeneral from '@/views/services/General.vue';
import ServicesWebPayment from '@/views/services/WebServicePayment.vue';
import webPaymentConfig from '@/views/services/webPaymentConfig.vue';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import InformationalFields from "@/views/services/InformationalFields.vue";
import ServicesConcepts from '@/views/services/Concepts.vue';
import ServicesDiscounts from '@/views/services/Discounts.vue';
import ServicesSummary from '@/views/services/Summary.vue';
import ServicesExpressConcepts from '@/views/services/ExpressPaymentConcepts.vue';
import ServicesGeneralAdvanced from "@/views/services/GeneralAdvanced.vue";
import ServicesExpressGeneralAdvanced from '@/views/services/ExpressGeneralAdvanced.vue';
import ServicesWebGeneralAdvanced from '@/views/services/ServicesWebGeneralAdvanced.vue';
import ServicesDebtGeneralAdvanced from '@/views/services/DebtGeneralAdvanced.vue';
import ServicesDebtFieldsReferences from '@/views/services/FieldsAndReferences.vue';

import PaymentsCRUDApi from '@/api/payments/crud.api.js';
import {mapGetters} from 'vuex';
import { STORE as ACCESS_STORE, GET_CURRENT_ORGANIZATION, FETCH_CURRENT_ORGANIZATION} from "@/store/modules/accessconfig/accessConfig.types";

const PaymentTypes = {
    RegularPayment: 'P',
    ExpressPayment: 'EP',
    Debt: 'D',
    WebService: "WS"
};
const EDITING_PAYMENT_KEY = 'editingPayment';
const EDITING_PAYMENT_SAVE_DATE_KEY = 'editingPaymentSaveDate';
const PAYMENTS_STEPS_GENERAL_KEY = 'pagos.steps.general';
const PAYMENTS_STEPS_SUMMARY_KEY = 'pagos.steps.summary';
const PAYMENTS_STEPS_CONCEPTS_KEY = 'pagos.steps.concepts';
const ERROR_TITLE_KEY = 'general.actions.error.title';
const ERROR_TEXT_KEY = 'general.actions.error.text';

export default {
    mixins: [ToastSnotify],
    components: {
        FloatingTitleLayout,
        WizardForm,
        ServicesGeneral,
        InformationalFields,
        ServicesConcepts,
        ServicesDiscounts,
        ServicesSummary,
        ServicesExpressConcepts,
        ServicesGeneralAdvanced,
        ServicesExpressGeneralAdvanced,
        ServicesDebtGeneralAdvanced,
        ServicesDebtFieldsReferences,
        ServicesWebPayment,
        webPaymentConfig,
        ServicesWebGeneralAdvanced
    },
    mounted () {
        this.$store.dispatch(`${ACCESS_STORE}/${FETCH_CURRENT_ORGANIZATION}`);

        if (this.$route.params.type && this.$route.params.id) {
            this.findPayment();
        } else {
            const editingPaymentJson = localStorage.getItem(EDITING_PAYMENT_KEY);
            const editingPaymentSaveDateString = localStorage.getItem(EDITING_PAYMENT_SAVE_DATE_KEY);

            let editingPayment;
            let editingPaymentSaveDate;

            if (editingPaymentSaveDateString) {
                editingPaymentSaveDate = new Date(editingPaymentSaveDateString);
                const oneHourAgo = new Date();
                oneHourAgo.setHours(oneHourAgo.getHours() - 2);

                if (editingPaymentJson && oneHourAgo.getTime() < editingPaymentSaveDate.getTime()) {
                    editingPayment = JSON.parse(editingPaymentJson);
                    this.payment = editingPayment;
                } else {
                    localStorage.removeItem(EDITING_PAYMENT_KEY);
                    localStorage.removeItem(EDITING_PAYMENT_SAVE_DATE_KEY);
                }
            }

            this.paymentType = (editingPayment ? editingPayment.type : null) || this.$route.params.type || PaymentTypes.RegularPayment;
            this.payment.type = this.paymentType;
            this.payment.organization = this.currentOrganization;
        }

        switch (this.paymentType) {
            case PaymentTypes.WebService:
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_GENERAL_KEY), icon: 'one', component: 'ServicesGeneral',
                    slot: 'ServicesWebGeneralAdvanced', slotName: 'advancedSettings' });
                this.steps.push({ title: "Configuracion Web", icon: 'two', component: 'webPaymentConfig' });
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY), icon: 'three', component: 'ServicesSummary' });
                break;
            case PaymentTypes.ExpressPayment:
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_GENERAL_KEY), icon: 'one', component: 'ServicesGeneral',
                    slot: 'ServicesExpressGeneralAdvanced', slotName: 'advancedSettings' });
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_CONCEPTS_KEY), icon: 'two', component: 'ServicesExpressConcepts' });
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY), icon: 'three', component: 'ServicesSummary' });
                break;
            case PaymentTypes.Debt:
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_GENERAL_KEY), icon: 'one', component: 'ServicesGeneral',
                    slot: 'ServicesDebtGeneralAdvanced', slotName: 'advancedSettings' });
                this.steps.push({ title: this.$t('pagos.steps.fields'), icon: 'two', component: 'InformationalFields' });
                this.steps.push({ title: this.$t('pagos.steps.fields-references'), icon: 'three', component: 'ServicesDebtFieldsReferences' });
                this.steps.push({ title: this.$t('pagos.steps.discounts'), icon: 'four', component: 'ServicesDiscounts' });
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY), icon: 'five', component: 'ServicesSummary' });
                break;
            case PaymentTypes.RegularPayment:
            default:
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_GENERAL_KEY), icon: 'one', component: 'ServicesGeneral', slot: 'ServicesGeneralAdvanced',
                    slotName: 'advancedSettings' });
                this.steps.push({ title: this.$t('pagos.steps.fields'), icon: 'two', component: 'InformationalFields' });
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_CONCEPTS_KEY), icon: 'three', component: 'ServicesConcepts' });
                this.steps.push({ title: this.$t('pagos.steps.discounts'), icon: 'four', component: 'ServicesDiscounts' });
                this.steps.push({ title: this.$t(PAYMENTS_STEPS_SUMMARY_KEY), icon: 'five', component: 'ServicesSummary' });
                break;
        }
    },
    data () {
        return {
            paymentType: PaymentTypes.RegularPayment,
            payment: {
                private: false,
                acceptsMultipleUnits: false,
                maxUnitsPerTransaction: 1,
                tags: [],
                images: [],
                fieldsInfo: [],
                fields: [],
                references: [],
                items: [],
                concepts: [],
                discounts: [],
                params: [],
                additionalNotesConfig: {
                    isEnabled: false,
                    label: '',
                    placeholder: '',
                    isRequired: false,
                },
                lastTestWasSuccessful: false
            },
            steps: [],
            currencies: [],
            slotValid: true,
        };
    },
    methods: {
        goTo(step, target) {
            EventBus.$emit(`wizard#goTo`, {from: step, to: target});
            this.$forceUpdate();
        },
        cancel() {
            localStorage.removeItem(EDITING_PAYMENT_KEY);
            localStorage.removeItem(EDITING_PAYMENT_SAVE_DATE_KEY);
            this.$router.push({name: 'Payments'});
        },
        prev() {
            EventBus.$emit(`wizard#prev`);
            this.$forceUpdate();
        },
        next() {
            EventBus.$emit(`wizard#next`);
            localStorage.setItem(EDITING_PAYMENT_KEY, JSON.stringify(this.payment));
            localStorage.setItem(EDITING_PAYMENT_SAVE_DATE_KEY, (new Date()).toISOString());
            this.$forceUpdate();
        },
        findPayment() {
            this.paymentType = this.$route.params.type || PaymentTypes.RegularPayment;
            PaymentsCRUDApi.get(this.paymentType, this.$route.params.id).then((data) => {
                this.payment = data.data.object;
                this.$forceUpdate();
            }).catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        finish() {
            PaymentsCRUDApi.save(this.payment.type, this.payment).then(() => {
                this.displayNotificationSuccess(this.$t('general.actions.success.title'), this.$t('general.actions.success.text'));
                localStorage.removeItem(EDITING_PAYMENT_KEY);
                localStorage.removeItem(EDITING_PAYMENT_SAVE_DATE_KEY);
                this.$router.push({name: 'Payments'});
                // eslint-disable-next-line sonarjs/no-identical-functions
            }).catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), error.response.data.message);
                } else {
                    this.displayNotificationError(this.$t(ERROR_TITLE_KEY), this.$t(ERROR_TEXT_KEY));
                }
            });
        },
        slotValidityChange(step, value) {
            this.$forceUpdate();
            this.slotValid = value;
        },
        numberToMoneyString(number) {
            if(isNaN(number))
            {
                return '0.00';
            }
            if(typeof number === 'string')
            {
                number = Number.parseFloat(number);
            }
            return `$${ number.toLocaleString(
                'en-IN',
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            ) } ${ this.currencyObject.code || ''}`;
        }
    },
    computed: {
         ...mapGetters(ACCESS_STORE, {
            currentOrganization: GET_CURRENT_ORGANIZATION,
        }),
         valuesForAlert() {
            return {
                expressPaymentMaxAmount: this.numberToMoneyString(this.currentOrganization.expressPaymentMaxAmount),
                expressPaymentMinAmount: this.numberToMoneyString(this.currentOrganization.expressPaymentMinAmount),
            }
        },
        currencyObject() {
            return (this.currentOrganization ? this.currentOrganization.defaultCurrency : null) || {};
        }
    }
}
</script>
