/* eslint-disable max-len,sonarjs/no-duplicate-string */
/* --------------Guía de contribución al i18n---------------
La estructura general es simple:
{{clasificación}}.{{sub-clasificación}}[.{{llave-del-mensaje}}][.{{llave-del-mensaje}}]

Reglas generales:
    - No se permiten mayúsculas.
    - Todos los segmentos de la llave se separan con punto (.)
    - No se permiten mensajes sin clasificación:
        ej.
            x incorrecto:
                "nombre": "Nombre"
                "email": "Email"
            ✓ correcto:
                "usuarios.nombre": "Nombre"
                "usuarios.email": "Email"

    - Si un segmento tiene más de una palabra, deben separarse con guión (-).
    - No duplicar mensajes para plurales. Se debe usar la pluralización de vue-i18n (http://kazupon.github.io/vue-i18n/guide/pluralization.html)
        ej.
            x incorrecto:
                "usuarios.usuario": "Usuario"
                "usuarios.usuarios": "Usuarios"

            ✓ correcto:
                "usuarios.usuario": "Usuario | Usuarios"

    - No se permiten acentos, así que de preferencia utiliza "hacks" para palabras que no suenen bien:
        ej. año => anio

    - Si se usa inglés, por favor verifica que lo que escribas es correcto
        ej.
            x incorrecto:
                clasification
            ✓ correcto:
                classification (doble 's')

            x incorrecto:
                wizzard
            ✓ correcto:
                wizard (una sola 'z')

    - Si ya existe una internacionalización que corresponde a lo que deseas usar, verifica que tenga sentido reutilizarla.
        ej.
            Si quieres colocar "Email" para usuario.email y ya existe la siguiente internacionalización:
                "proveedor.email": "Email"

            Es tentador usarla, ¿pero que pasa si en un futuro se cambia el valor de la siguiente manera?
                "proveedor.email": "Email del Proveedor"

            Este uso es incorrecto, por reutilizar el i18n de una llave existente, pero que no corresponde a la misma propiedad,

            Aunque tenga el mismo valor, conceptualmente la i18n puede ser distinta

    - Mantén las clasificaciones juntas y ordenadas. Es tentador agregar tus i18ns al final, pero ayuda a mantener el orden de la aplicación.
        ej.
            x incorrecto:
                usuarios.foo.1
                proveedores.foo
                usuarios.bar
                usuarios.foo.2
                comparar.baz
                usuarios.baz
                proveedores.bar
                usuarios.foo.3
                comparar.foo
                comparar.bar


            ✓ correcto:
                usuarios.foo.1
                usuarios.foo.2
                usuarios.foo.3
                usuarios.bar
                usuarios.baz
                proveedores.foo
                proveedores.bar
                comparar.foo
                comparar.bar
                comparar.baz

¿Inglés o español?
    Se prefiere español para términos de los procesos y los datos de la plataforma.
        Ej. proveedor, calculo, tipo-rfc

    Se prefiere inglés para términos de sistemas.
        Ej. error, info, validations, warning, required

    Si tienes dudas, o ambos lenguajes son viables, puedes basarte en un uso similar que ya exista, o confiar en tu corazón.


--------Clasificaciones (procesos)-------
general
usuarios
proveedores
unidades-administrativas
recursos
contratos
calculos
comparar

--------Subclasificaciones (partes de los procesos anteriores)-------
error
success
info
warning
[nombre-de-propiedad]
[nombre-de-accion] (ejemplos: "save", "delete")

--------- Sub-sub-clasificaciones de [nombre-de-propiedad] ---------
validations
status
[sub-propiedades-de-la-propiedad]

--------Ejemplos-------
"general.app-name": "Monitor Karewa"
"general.copyright": "Black Labs 2019"
"general.error.cargar-registros": "No fue posible cargar los registros. Por favor recarga la página e intenta nuevamente."
"general.info.loading": "Cargando..."

"usuarios.usuario": "Usuario"
"usuarios.email": "Email"
"usuarios.email.description": "El email que utilizará el usuario para acceder a la plataforma."
"usuarios.email.placeholder": "Introduce el email del usuario."
"usuarios.email.validations.required": "El email del Usuario es requerido."
"usuarios.email.validations.invalid": "Por favor introduce un email válido para el Usuario."
"usuarios.email.status": "Estatus del email"
"usuarios.email.status.pendiente-validacion": "Pendiente de validar"
"usuarios.email.status.validado": "Validado"

"proveedores.error.duplicado": "El Proveedor ya se encuentra registrado."
"proveedores.warning.tipo-rfc-inconsistente": "El RFC registrado para este Proveedor corresponde a una persona moral, pero el Proveedor es persona física."
"proveedores.rfc": "RFC del Proveedor"
"proveedores.rfc.validations.required": "El RFC del Proveedor es requerido."
 */

import config from '@/config.js';
import hire from './es/hire.json';
import statements from './es/statements';

export default {
    "general.close": "Cerrar",
    "general.search-bar.drop-down-header": "Selecciona las columnas que deseas mostrar en la tabla.",
    "general.search-bar.filters": "Filtros",
    "general.search-bar.search-text-placeholder": "Escribe que deseas buscar",
    "general.search-bar.show": "Mostrar",
    "general.search-bar.registers": "registros",
    "general.search-bar.newElementButton": "Nuevo elemento",
    "general.paymentAmountInvalid": "El monto total no puede ser menor a {transactionMinAmount} ni mayor a {transactionMaxAmount}",
    "general": {
        "delete.error": "Ocurrió un problema al eliminar.",
        "selected-elements": "{count} elementos seleccionados | {count} elemento seleccionado | {count} elementos seleccionados",
        "required-fields": "Campos requeridos",
        "list-limit-warn": "El límite tiene que ser un número mayor a 0.",
        "actions": {
            "more": "Más acciones",
            "actions": "Acciones",
            "accept": "Aceptar",
            "cancel": {
                "title": "Cancelar",
                "text": "Se ha cancelado la acción",
            },
            "next": {
                "title": "Siguiente"
            },
            "edit": {
                "title": "Editar"
            },
            "share": {
                "title": "Compartir"
            },
            "copy": {
                "title": "Copiar"
            },
            "delete": {
                "title": "Borrar",
                "title2": "Eliminar"
            },
            "save-changes": "Guardar Cambios",
            "save": "Guardar",
            "success": {
                "title": "Éxito",
                "text": "La acción se ha ejecutado exitosamente."
            },
            "error": {
                "title": "Error",
                "text": "Hubo un problema al ejecutar la acción"
            },
            "back": {
                "title": "Regresar"
            },
            "continue": {
                "title": "Continuar"
            },
            "done": {
                "title": "Listo"
            },
            "finish": {
                "title": "Terminar"
            },
            "print": {
                "title": "Imprimir"
            },
            "select": {
                "title": "Seleccionar"
            },
            "copy-clipboard": {
                "success": "Copiado al portapapeles",
                "error": "No se pudo copiar al portapapeles",
            },
            "people-emails": {
                "success": "Se guardaron los correos",
                "error": "No se pudieron guardar los correos"
            },
            "logout": {
                "title": "Cerrar sesión"
            },
            "url-alias": {
                "success": "El Alias de URL está disponible",
                "error": "El Alias de URL se encuentra usado por otra organización o es una palabra reservada"
            },
            "query": {
                "title": "No se encontraron resultados con la búsqueda actual"
            },
            "see-details": {
                "title": "Ver detalles",
            }
        },
        "gender": {
            "MALE": "Masculino",
            "FEMALE": "Femenino",
        },
        "see-file": "Ver Archivo",
        "download-file": "Descargar Archivo",
        "download": "Descargar",
        "close": "Cerrar",
        "upload": "Cargar",
        "cancel": "Cancelar",
        "accept": "Aceptar",
        "social-networks": {
            "facebook": "Facebook",
            "twitter": "Twitter",
            "instagram": "Instagram",
            "share": "Compartir en"
        },
        "validations": {
            "required": "Este campo es requerido",
            "minLength": "{min} caracteres mínimo",
            "maxLength": "{max} caracteres máximo",
            "numeric": "Sólo valores numéricos",
            "email": "No es una dirección de correo electrónico válido",
            "alphaNum": "Sólo letras o números",
            "url": "No es una dirección válida",
            "billingInfoDescriptionValidation": "Falta agregar alguno de los siguientes elementos: #email, #phone",
            "minValue": "El valor mínimo es {min}",
            "maxValue": "El valor máximo es {max}",
            "max": "Se ha excedido el valor máximo",
        },
        "drop-down": {
            "opt-selected": "options selected"
        },
        "info": {
            "loading": "Cargando, por favor espere...",
        },
        "copyright": "Copyright {year}, <strong>Pagando Check</strong>",
        "support-if-you-have": "Si tienes problemas subiendo los archivos de identificación contáctanos a los teléfonos",
        "support-MXN-number": "800 323 0709",
        "support-MXN-number-for-mexico": "para México.",
        "support-US-number": "",
        "support-US-number-for-US": "",
        "approved": "Aprovado",
        "reset-filters": "Reiniciar filtros",
        "empty": "No hay datos disponibles",
        "fields": {
            "number": {
                "label": "Número",
                "placeholder": "Ingresa el número",
            },
            "date": {
                "label": "Fecha",
                "placeholder": "Ingresa la fecha"
            },
            "hour": {
                "label": "Hora",
            },
            "concept": {
                "label": "Concepto"
            },
            "total": {
                "label": "Total",
            },
            "sssc": {
                "label": "Sub Sub Sub Cuenta",
                "placeholder": "Busca o ingresa una sub sub sub cuenta"
            },
            "auxiliary": {
                "label": "Auxiliar",
                "placeholder": "Busca un auxiliar"
            },
            "balance": "Saldo",
            "debit": {
                "label1": "Débito",
                "label2": "Haber"
            },
            "credit": {
                "label1": "Crédito",
                "label2": "Debe"
            },
        }
    },
    "verification-code.error-code.length": "El código debe llevar 5 dígitos.",
    "verification-code.error-code.invalid": "El código ingresado no es válido o ha expirado",
    "fiscal-data": {
        "tributary-situation": "Situación tributraria",
        "physical-person": "Persona Física",
        "moral-person": "Persona Moral",
        "name": "Razón social",
        "name-placeholder": "Escribe tu nombre aquí...",
        "last-name": "Apellidos",
        "last-name-placeholder": "Escribe tus apellidos aquí...",
        "rfc": "RFC",
        "rfc-placeholder": "Escriube tu RFC...",
        "address": "Dirección Fiscal",
        "address-capture-info": "Para poder facturar las comisiones de Pagando, es necesario que ingreses tu información fiscal.",
        "street": "Calle",
        "street-placeholder": "Escribe aquí el nombre de la calle...",
        "exterior-number": "Número exterior",
        "exterior-number-placeholder": "Escribe aquí el número exterior...",
        "interior-number": "Número interior",
        "interior-number-placeholder": "Escribe aquí el número interior...",
        "district": "Colonia",
        "district-placeholder": "Escribe aquí el nombre de tu colonia...",
        "state": "Estado",
        "state-placeholder": "Escribe aquí el nombre de tu estado...",
        "city": "Ciudad",
        "city-placeholder": "Escribe aquí el nombre de tu ciudad...",
        "zip-code": "Código postal",
        "zip-code-placeholder": "Escribe tu código postal aquí...",
        "official-id": "Identificación Oficial",
        "drag-file-here": "Arratra tu archivo aquí..."
    },
    "organizaciones": {
        "informacion-general": {
            "title": "Información General",
            "text": "Información básica: nombre, descripción y categoría.",
            "subtitle": "Aquí puedes editar los datos generales de la organización que aparecerán en tu micrositio.",
            "name": {
                "label": "Nombre de la Organización",
                "input-place-holder": "Escribe el nombre de la Organización"
            },
            "category": {
                "label": "Categoría de la Organización",
                "input-place-holder": "Selecciona la Categoría de la Organización"
            },
            "description": {
                "label": "Descripción de la Organización",
                "input-place-holder": "Escribe la descripción de la Organización"
            },
            "additionalInformation": {
                "label": "Información adicional",
                "input-place-holder": "Ingresa información adicional de la organización"
            },
            "url-alias": {
                "label": "Alias de URL",
                "input-place-holder": "pagando.mx/",
                "tooltip": "Es parte de la dirección URL a través de la que se podrá accesder al micrositio de tu organización. Va después de la diagonal de pagando.mx/"
            },
        },
        "datos-fiscales": {
            "title": "Datos fiscales",
            "text": "Actualiza la información fiscal de tu organización.",
            "subtitle": "Los datos fiscales nos ayudan a generar tu factura a final de mes.",
            "warning": "Sólo puedes cambiar los datos fiscales de tu organización contactandote con nosotros al correo <strong>soporte@pagandocheck.com</strong>",
            "rfc": {
                "label": "Registro Federal de Contribuyentes (RFC)"
            },
            "billing-info": {
                "label": "Datos de Facturación"
            },
            "legal-name": {
                "label": "Razón social"
            },
            "phone": {
                "label": "Teléfono"
            },
            "email": {
                "label": "Correo electónico"
            },
            "url": {
                "label": "URL"
            },
            "description": {
                "label": "Descripción"
            },
            "to-edit": "Para cambiar cualquiera de estos datos puedes contactar a Soporte llamando a los números 800-461-0119 desde el interior de México o +1 800-392-6948 para Estados Unidos."
        },
        "general": {
            "error": {
                "blocked-organization-select": "Lo sentimos, la empresa que seleccionaste actualmente está inactiva. Si deseas activar la cuenta de esa empresa, ponte en contacto con nuestro equipo de soporte utilizando el chat."
            }
        },
        "images": {
            "edit-cover": "Editar Portada",
            "see-preview": "Ver vista previa",
            "title": "Logotipo y Portada",
            "text": "Personaliza los elementos gráficos de tu micrositio.",
            "subtitle": "Aquí puedes personalizar el logotipo e imagen de portada que se muestran en el micrositio de tu organización. Puedes subir tus propias imagenes.",
            "logo": {
                "label": "Logotipo",
                "input-place-holder": "Subir logotipo",
                "info": "El tamaño de la imagen debe ser de al menos 400px por 400px y el peso máximo debe ser 300kb. Puedes subir imagenes tipo: JPG, PNG y SVG.",
                "modal": {
                    "title": "Ajusta tu logotipo",
                    "subtitle": "Ajusta el tamaño de la imagen de tu logotipo como más te guste.",
                    "input-place-holder": "Cambiar Logotipo"
                },
                "errors": {
                    "width-height": "El tamaño de la imagen debe de ser de al menos 400px por lado.",
                    "size": "El tamaño de la imagen excede los 300KB",
                    "format": "El formato de la imagen no es aceptado. Formatos permitidos: png, jpg, svg",
                    "image-name": "El nombre de la imagen excede los caracteres permitidos"
                }
            },
            "cover": {
                "label": "Foto de Portada",
                "input-place-holder": "Cambia la imagen de portada",
                "modal": {
                    "title": "Selecciona tu imagen de portada",
                    "subtitle": "Selecciona una imagen de la galería que más se adapte a tu Organización o sube tu imagen propia.",
                    "select": {
                        "cover": "Portada seleccionada",
                        "search-image": "Buscar imagen",
                        "new-image": "Seleccionar nueva imagen"
                    },
                    "upload": {
                        "image": "Subir imagen de portada"
                    },
                    "adjust": {
                        "title": "Ajusta tu imagen de portada",
                        "subtitle": "Ajusta el tamaño de la imagen de tu portada como más te guste."
                    }
                }
            }
        },
        "bank-data": {
            "title": "Datos Bancarios",
            "text": "Configura la cuenta donde recibirás tus pagos.",
            "subtitle": "Aquí se muestran los datos de deposito que configuraste al crear tu organización, se han ocultado parte de los datos por tu seguridad.",
            "edit": "Editar datos bancarios",
            "edit-warning": "Sólo puedes cambiar los datos bancarios de tu organización una vez por año, si necesitas hacerlo más veces, ponte en contacto con nosotros aquí o envía un correo a <strong>soporte@pagandocheck.com</strong>.",
            "bank": {
                "label": "Banco",
                "input-place-holder": "Selecciona el banco",
            },
            "account": {
                "label": "Número de cuenta",
                "input-place-holder": "Ejemplo: 0197844563",
            },
            "clabe": {
                "label": "CLABE Interbancaria",
                "input-place-holder": "Ejemplo: 012184663547723412",
            },
            "bank-cover": {
                "label": "Carátula bancaria",
                "input-place-holder": "Selecciona el archivo",
                "tooltip": "Archivo PDF o de imagen que contiene la primer página de un estado de cuenta correspondiente a la cuenta bancaria que se quiere ingresar."
            },
            "commission": "{iva}% + IVA",
            "VISA": "Visa",
            "MASTERCARD": "Mastercard",
            "AMEX": "American Express",
            "CARNET": "Carnet",
            "PAGANDO_ELECTRONIC_GIFT": "Saldo de regalo",
            "product-type": {
                "PAYMENT": "Comisiones bancarias",
                "API_PAYMENT": "Comisiones bancarias (E-commerce)",
                "QR_PAYMENT": "Comisiones bancarias (QR)",
                "TERMINAL_PAYMENT": "Comisiones bancarias (Tarjeta Presente)",
                "unfairChargebackPayment": "Comisiones por cada contracargo imprecedente",
            },
            "commissions": {
                "header": "Comisiones",
                "sub-header": "Compartimos contigo las comisiones que se cobran según el tipo de operación realizada"
            }
        },
        "category": {
            "options": {
                "TOURISM": "Turismo",
                "GARDENING": "Jardinería",
                "HOME": "Hogar",
                "SPORTS": "Deportes",
                "EDUCATION": "Educación",
                "PETS": "Mascotas",
                "TRANSPORT": "Transporte",
                "MAINTENANCE": "Mantenimiento",
                "PARTIES": "Fiestas",
                "BEAUTY": "Belleza",
                "SPACES": "Espacios",
                "PROFESSIONALS": "Profesionales",
                "FOOD": "Alimentos",
                "CLOTHES": "Ropa",
                "OTHER": "Otros",
                "OTHERS": "Otros",
                "undefined": "Otros",
                "SERVICES": "Servicios",
                "GENERAL_SERVICES": "Servicios",
                "HEALTH": "Salud",
                "QR": "QR"
            }
        },
        "contact-info": {
            "title": "Datos de Contacto",
            "text": "Configura datos como email, teléfono, redes sociales y otros.",
            "description": "Esta información se mostrara en tu micrositio y ayudara a tus clientes a ubicar tu negocio o ponerse en contacto contigo.",
            "address": "Dirección de la Organización",
            "address-placeholder": "Escribe la Dirección de la Organización...",
            "contact-phone": "Teléfono de contacto",
            "contact-phone-placeholder": "Ej. (000) 000 00 00",
            "organization-phone": "Teléfono de la Organización",
            "organization-phone-placeholder": "Ej. (000) 000 00 00",
            "contact-help": "¿Para qué es esto?",
            "email": "Correo electrónico",
            "email-placeholder": "Escribe el correo electrónico...",
            "email-help": "¿Quién verá esto?",
            "site": "Página web de mi Organización",
            "site-placeholder": "Escribe la Pagina web de mi Organización...",
            "private": "Privacidad de la organización",
            "private-help": "¿Porqué ocultar o mostrar mi información?",
            "private-tooltip": "Text",
            "buttons": {
                "copy-link": "Copiar enlace",
                "share": "Compartir"
            },
            "modal": {
                "share": {
                    "title": "Compartir",
                    "subtitle": "¡Comparte este Pago con más personas!"
                },
                "direct-link": "Enlace directo",
                "people": {
                    "title": "Personas",
                    "placeholder": "Ingresa uno o varios correos separados por comas",
                    "placeholder2": "Agregar más personas..."
                }
            },
            "whats-this": "¿Para qué es esto?",
            "who-see-this": "¿Quién verá esto?",
            "contact-phone-help-text": "Este teléfono sera un medio para que tus clientes puedan contactarte, recuerda que los clientes pueden visualizarlo en el micrositio",
            "org-phone-help-text": "Este es el teléfono de la oficina, aparecera como un medio de contacto para tus clientes en el micrositio",
            "contact-email-help-text": "Este será el correo electrónico que tus clientes usaran para comunicarse contigo, te recomendamos poner el correo de tu empresa, este puede ser visto en el micrositio",
            "url-alias-help-text": "Este sera el alias para que tus usuarios puedan encontrar más rápido tu empresa en pagando, esta funcionalidad será activada próximamente",
            "website-help-text": "Escribe la dirección de tu página web externa a pagando, para que tus clientes puedan ubicar más fácil tu empresa",
        },
        "social-networks": {
            "title": "Redes Sociales",
            "description": "Agrega tus redes sociales para mostrarlas en tu micrositio y te ayudara para ampliar la visibilidad de tu negocio.",
            "facebook-placeholder": "Dirección de Facebook",
            "twitter-placeholder": "Dirección de Twitter",
            "instagram-placeholder": "Dirección de Instagram",
        },
        "notifications-info": {
            "title": "Notificaciones",
            "text": "Configura notificaciones cuando recibas pagos.",
            "subtitle": "Configura los diferentes canales por los cuales te notificaremos eventos como los pagos realizados por tus clientes y de errores que puedan surgir en tus webhooks.",
            "cco": "Correo electrónico para copia de correos de pago",
            "cco-placeholder": "Escribe una direeción de correo electrónico aquí...",
            "webhooks-errors-email": "Correo electrónico para envío de errores en webhooks",
            "webhooks-errors-email-placeholder": "Escribe una direeción de correo electrónico aquí...",
            "payment-notifications": "Notificación de Pagos",
            "payment-notifications-placeholder": "Si deseas recibir notificaciones de los pagos recibidos, agrega uno o mas correos.",
            "email": "Correo electrónico",
            "email-placeholder": "Escribe una direeción de correo electrónico aquí...",
            "email-notifications-type": "Tipos de notificaciones",
            "add-email": "Haz clic aquí para agrerar otra dirección de correo",
            "what-means-this": "¿Qué significa esto?",
            "cco-help-text": "Escribe aquí el correo al que quieres que lleguen todos los recibos de pagos que hagan a tu empresa dentro de pagando.",
            "webhook-help-text": "Escribe aquí el correo al que enviaremos reportes en caso de que un pago por servicio web no pueda ser efectuado.",
            "notification-types": {
                "PAYMENT": "Pago",
                "INVOICE": "Facturación",
                "DISPERSION": "Dispersión"
            },
            "notification-types-selected": "{count} opción seleccionada | {count} opciones seleccionadas"
        },
        "billing-info": {
            "title": "Facturación",
            "text": "Brinda una manera de facturar los servicios a tus clientes.",
            "subtitle": "Para proporcionar un mejor servicio a tus clientes, te recomendamos que agregues las distintas maneras de facturación que brinda tu organización.",
            "data": "Datos de facturación",
            "configured": "Configurado",
            "add-url": "Agregar url (#url)",
            "url": "Página Web",
            "url-placeholder": "https://www.",
            "add-email": "Agregar correo electrónico (#email)",
            "email": "Correo electrónico",
            "email-placeholder": "@ejemplo.com",
            "add-phone": "Agregar teléfono (#phone)",
            "phone": "Teléfono",
            "phone-placeholder": "614 545 8948",
            "description": "Instrucciones de facturación",
            "description-placeholder": "Escribe aquí las Instrucciones de facturación...",
            "warning": "Para poder guardar los cambios, todos los campos utilizados deben ser capturados. Por favor actualiza la descripción o agrega el valor de los siguientes elementos: <strong>#url, #email, #phone</strong>"
        },
        "payments-configuration": {
            "title": "Configuración de Pagos",
            "text": "Activa/Desactiva el pago de servicios y otras opciones.",
            "subtitle": "Activa o desactiva desde este panel configuraciones de pagos de servicios, modo de prueba entre otras opciones.",
            "default-currency": "Divisa Predeterminada",
            "default-currency-select": "Selecciona una Divisa",
            "sandBoxMode": "Modo de prueba",
            "activeService": "Habilitar pago de servicios",
            "comingSoon": "Enviar comprobantes de prueba",
        },
        "webhooks-config": {
            "empty": {
                "title": "¡Crea y configura tu primer Webhook!",
                "text": "Los webhooks aportan una solución sencilla para el intercambio de datos entre aplicaciones web de uso diario, o incluso entre nuestro sitio web y esas aplicaciones.",
                "add": "Nuevo webhook"
            },
            "title": "Configuración de WebHooks",
            "subtitle": "Para configurar tus webhooks, su ministra la URL de tus webhooks.",
            "alert": "Los errores de los WebHooks se enviaran a la siguiente dirección <strong>{email}</strong>.<br>Puedes cambiarlo en cualquier momento en la sección de <strong><u>Notificaciones.</u></strong>",
            "add": "Agregar Webhook",
            "no-errors": "Sin errores",
            "errors": "Con errores",
            "active": "Activo",
            "inactive": "Inactivo",
            "modal": {
                "title": "Agregar Webhook",
                "subtitle": "Crea y configura un nuevo webhook.",
                "buttons": {
                    "add": "Agregar Webhook",
                    "close": "Cerrar"
                },
                "inputs": {
                    "name": {
                        "label": "Nombre de Webhook",
                        "placeholder": "Escribe el nombre de Webhook..."
                    },
                    "url": {
                        "label": "URL",
                        "placeholder": "Escribe la URL..."
                    },
                    "sandbox": {
                        "lower": "Activo para sandbox",
                        "upper": "ACTIVO PARA SANDBOX"
                    },
                    "production": {
                        "lower": "Activo para producción",
                        "upper": "ACTIVO PARA PRODUCCIÓN"
                    }
                },
                "select": "Selecciona..."
            },
            "modal-delete": {
                "title": "¿Deseas eliminar este webhook?",
                "text": "Esta acción no se puede deshacer",
            },
            "modal-edit": {
                "title": "Editar Webhook",
                "subtitle": "Edita y configura el webhook."
            }
        },
        "all-deactivated": {
            "title": "Tu empresa se encuentra inactiva por el momento",
            "instructions": "Lo sentimos mucho, tu empresa se encuentra temporalmente inactiva. Es probable que necesites actualizar tu información y finalices el proceso de activación de tu empresa.",
            "contact-support": "Si deseas activar la cuenta de esa empresa, ponte en contacto con nuestro equipo de soporte o hazlo utilizando el chat."
        },
        "config-details": {
            "tab-title": "Límites, riesgos, responsabilidades y beneficios",
            "tab-desc": "Detalles de uso de la cuenta.",
            "transactions": {
                "title": "Límites Operacionales",
                "desc": "Lleva un control de cómo utilizas tu cuenta según los límites de uso establecidos."
            },
            "risks": {
                "title": "Riesgos",
                "desc": "Te informamos sobre los aspectos importantes que debes considerar al utilizar tu cuenta.."
            },
            "responsabilities": {
                "title": "Responsabilidades",
                "desc": "Conoce tus responsabilidades como cliente con una cuenta en Pagando Check."
            },
            "benefits": {
                "title": "Beneficios",
                "desc": "Te presentmos los beneficios de los que disfrutas utilizando tu cuenta de Pagando Check."
            }
        }
    },
    "administration": {
        "title": "Administración",
        "my-organization": "Mi organización",
    },
    "tax-receipts": {
        "title": "Panel de control",
        "tax-receipts-title": "Comprobantes fiscales",
        "tax-receipts": "Comprobante | Comprobantes",
        "no-tax-receipts-title": "¡Ups! Parece que aún no tienes ningún comprobante fiscal.",
        "no-tax-receipts-text": "Cuando el equipo de Pagando Check cargue comprobantes de tu organización podrás consultarlos aquí.",
        "searching-tax-receipts": "Bucando comprobantes fiscales...",
        "month-title-column": "Mes",
        "filter-month": "Selecciona mes...",
        "year-title-column": "Año",
        "filter-year": "Ingrese el año...",
        "period-title-column": "Periodo",
        "filter-period": "Ingrese el periodo...",
        "xml-file": "Archivo xml",
        "pdf-file": "Archivo pdf",
        "download-xml": "Descargar XML",
        "download-pdf": "Descargar PDF",
        "january": "Enero",
        "february": "Febrero",
        "march": "Marzo",
        "april": "Abril",
        "may": "Mayo",
        "june": "Junio",
        "july": "Julio",
        "august": "Agosto",
        "september": "Septiembre",
        "october": "Octubre",
        "november": "Noviembre",
        "december": "Diciembre",
        "no-filter": "Sin filtro",
    },
    "collected-payments": {
        "no-results-found": "No se encontraron pagos recaudados"
    },
    "side-bar-menu": {
        "pending-money": "Saldo pendiente",
        "home": "Inicio",
        "control-panel": "Panel de control",
        "movements": "Movimientos",
        "sales": "Ventas",
        "payments": "Pagos",
        "payments-types": "Tipos de pago",
        "web-api": "API para sitio web",
        "admin": "Administración",
        "my-organization": "Mi organización",
        "last-seen": "Último inicio de sesión",
        "search-placeholder": "Escribe algo para buscar...",
        "tax-receipts": "Comprobantes fiscales",
        "chargebacks": "Contracargos",
        "terminal-list": "Terminales",
        "statements": "Contabilidad",
    },
    "drop-down-user": {
        "my-profile": "Mi cuenta",
        "admin-organization": "Administrar mi organización",
        "change-organization": "Cambiar de Organización",
        "buy-in-pagando": "Comprar en pagando",
        "help": "Ayuda",
        "changing-client": "Por motivos de seguridad debemos de solicitar nuevamente tus credenciales.",
    },
    "pagos": {
        "smallMessage": "Panel de control",
        "title": "Tipos de Pagos",
        "payments": "Pagos",
        "steps": {
            "general": "Datos Generales",
            "fields": "Campos informativos",
            "fields-references": "Campos y referencias",
            "concepts": "Conceptos",
            "discounts": "Descuentos",
            "summary": "Resumen",
            "webservice": "Servicio Web",
            "web-config": "Configuración Web"
        },
        "columns": {
            "name": "Nombre del servicio",
            "serviceType": "Tipo de servicio",
            "paymentType": "Tipo de pago",
            "details": "Detalles",
            "status": "Estatus",
            "actions": "Acciones",
        },
        "rows": {
            "types": {
                "P": "Regular",
                "WS": "Servicios Web",
                "D": "Requisitos",
                "EP": "Rápido"
            },
            "types-long": {
                "P": "Pago Regular",
                "WS": "Pago de Servicios Web",
                "D": "Pago con Requisitos",
                "EP": "Pago Rápido",
                "T": "Venta con terminal",
                "AP": "Venta por comercio electrónico",
            },
            "status": {
                "active": "Activo",
                "inactive": "Inactivo",
                "comingSoon": "Próximamente",
            },
            "actions": {
                "show": "Ver",
                "edit": "Editar",
                "testService": "Probar servicio",
                "share": "Compartir pago",
                "configureDebts": "Configurar deudores",
                "copy": "Copiar",
                "delete": "Eliminar",
            }
        },
        "general": {
            "title": "Datos generales del servicio",
            "subtitle": "Establece las especificaciones básicas de tu pago. Esta configuración ayudará a que tus clientes encuentren tu servicio e identifiquen la información más relevante.",
            "servicio": {
                "info": {
                    "P": "El Pago Regular te permite configurar un pago que puede ser pagado por cualquier persona, cuantas veces sea necesario. En un Pago Regular, los conceptos y el monto del pago son iguales para todos tus clientes.",
                    "D": "Con el Pago con requisitos configura la información general de un servicio y después, en la Configuración de Deudores, registra el detalle de los pagos con los conceptos y montos personalizados para cada deudor.",
                    "WS": "El Pago con Servicios Web se utiliza para consultar los conceptos y montos a pagar desde un servicio web externo. Se recomienda que este tipo de pago lo configure una persona con conocimientos técnicos.",
                    "EP": "El rango permitido para las cantidades a pagar usando el Pago Rápido es de mínimo {expressPaymentMinAmount} y máximo {expressPaymentMaxAmount}. Si necesitas modificar este rango, contáctanos.",
                },
                "name": {
                    "title": "Nombre del servicio",
                    "placeholder": "¿Con qué nombre quieres que la gente vea tu servicio?"
                },
                "description": {
                    "title": "Descripción del servicio",
                    "placeholder": "Escribe de manera breve de qué trata tu servicio y qué es lo que ofrece…"
                },
                "status": {
                    "title": "Estatus",
                    "help": "  ¿Cuál elegir?",
                    "placeholder": "Elige cómo quieres publicar tu servicio",
                    "active": "Activo",
                    "inactive": "Inactivo",
                    "comingSoon": "Próximamente",
                },
                "currency": {
                    "title": "Divisa",
                    "help": "¿Qué es?",
                    "placeholder": "Selecciona con cuál divisa quieres cobrar"
                },
                "image": {
                    "title": "Agrega imagen(es) del servicio",
                    "subtitle": "El tamaño de las imágenes debe ser de <strong>1440px por 420px</strong> para que se visualicen correctamente. Puedes subir hasta 4 imágenes: <strong>.JPG y .PNG</strong>",
                    "help": "¿Dónde se muestran estas imágenes?"
                },
                "category": {
                    "title": "Selecciona la categoría del servicio"
                },
                "tags": {
                    "title": "Tags",
                    "placeholder": "Elige los tags a incluir",
                    "emptyList": "La lista está vacía.",
                    "no-tags": "Aún no has agregado Tags"
                },
                "discounts": {
                    "no-discounts": "No hay descuentos registrados"
                }
            },
            "advanced": {
                "privacy": {
                    "title": "Privacidad del servicio",
                    "help": "¿Cuál se adapta mejor a mi servicio?",
                    "public": "Público",
                    "private": "Privado"
                },
                "additional-info": {
                    "label": "Título del campo de notas adicionales",
                    "placeholder": "Introduce el identificador de este servicio en notificaciones de WebHooks, consultas en la API, etc.",
                    "help": "¿Qué es un ID externo?"
                },
                "externalIdentifier": {
                    "label": "Identificador externo",
                    "placeholder": "Introduce el identificador de este servicio en notificaciones de WebHooks, consultas en la API, etc.",
                    "help": "¿Qué es un ID externo?"
                },
                "multiplicacion": {
                    "permitir": {
                        "title": "Permitir multiplicación del pago en una sola transacción",
                        "help": "¿Cómo es la multiplicación de pago?"
                    },
                    "maximo-permitido": "Máximo permitido de multiplicación"
                },
                "notas-adicionales": {
                    "permitir": {
                        "title": "Pedir al cliente que ingrese notas adicionales",
                        "help": "¿Para qué sirve esto?"
                    },
                    "obligatorio": {
                        "title": "Establecer el campo de notas adicionales como obligatorio"
                    },
                    "campos-adicionales": {
                        "title": "Título del campo de notas adicionales",
                        "placeholder": "P. ej: Notas adicionales, talla, color, comentarios, dirección, etc."
                    },
                    "instructions": {
                        "title": "Instrucciones de cómo deben ser ingresadas las notas adicionales",
                        "placeholder": "P. ej: Ingrese aquí la talla que requiere (XS, S, M, L, XL, XXL)"
                    }
                }
            },
            "commision": "Comisión Pagando Check (Inc. IVA)",
            "actions": {
                "images": {
                    "error": {
                        "limit-exceeded": "EL número máximo de imágenes por servicio es de 4"
                    }
                }
            }
        },
        "rapido": {
            "name": "Pago rápido",
            "description": "Especifica los conceptos, el cliente selecciona e ingresa la cantidad a pagar.",
            "conceptos": {
                "title": "Conceptos",
                "subtitle": "Configura los conceptos que serán incluidos cuando se pague este servicio. Al momento de realizar su compra, tus clientes verán los conceptos que agregues, podrán seleccionar el que mejor se adapte a sus necesidades e ingresar la cantidad que desean pagar.",
                "help": "Ver ejemplo",
                "input": {
                    "title": "Concepto",
                    "placeholder": "Introduce el concepto aplicable al pago"
                },
                "add-concept": "Agregar concepto"
            },
        },
        "requisitos": {
            "name": "Pago con Requisitos",
            "description": "Personaliza el pago para cada cliente y dale acceso para revisar su pago mediante una clave asignada por ti.",
            "campos-referencias": {
                "title": "Campos y referencias",
                "subtitle": "Los campos son la información que se solicitará a cada deudor para consultar los conceptos y montos que puede pagar (cada deudor debe conocer estos datos). Las referencias contienen información adicional del deudor que le será mostrada después de introducir correctamente la información de los campos. Estas son principalmente útiles para que el deudor pueda verificar que está realizando el pago correcto.",
                "examples": {
                    "fields": "Ver ejemplos de campos",
                    "references": "Ver ejemplos de referencias"
                },
                "inputs": {
                    "fields": {
                        "title": "Campos",
                        "subtitle": "Campo",
                        "placeholder": "Introduce el dato que solicitarás al deudor para que pueda consultar su pago"
                    },
                    "references": {
                        "title": "Referencias",
                        "subtitle": "Referencia",
                        "placeholder": "Introduce el dato que se mostrará al dedudor para verificar que sí realizará el pago correcto"
                    },
                    "fields-references": {
                        "title": "Campos y Referencias",
                        "empty": "No hay campos ni eferencias",
                    }
                },
                "add": {
                    "fields": "Agregar campo",
                    "references": "Agregar referencia"
                },
                "warning": "Sólo es posible actualizar los campos del Pago con Requisitos si no hay deudores registrados. Los deudores se administran en Configuración de Deudores del servicio en tu lista de Tipos de Pagos."
            }
        },
        "regular": {
            "name": "Pago regular",
            "description": "Pago ideal para vender productos y servicios con un costo fijo.",
        },
        "web-service": {
            "name": "Pago de Servicio Web",
            "description": "Pagos que consultan el concepto a pagar desde un servicio web externo.",
            "config": {
                "title": "Configuración web",
                "subtitle": "Define los parámetros REST correspondientes para vincular la plataforma de Check con el servicio web de tu organización.",
                "warning": "Es importante que una persona con conocimientos técnicos sea la que se encargue de configurar los datos de esta sección para evitar futuros errores.",
                "url-info": "Recuerda que tu URL debe regresar un objeto tipo JSON como el del ejemplo con los siguientes valores:",
                "doc": {
                    "name": "name (String)",
                    "name-description": "El nombre de tu servicio",
                    "items": "items (object Array)",
                    "items-description": "Un arreglo de artículos con el siguiente formato:",
                    "items-description-2": "{ name: (string), isTax: (boolean), unitPrice: (float), quantity: (integer)}",
                    "references": "references (object Array)",
                    "references-description": "Un arreglo de referencias con el siguiente formato:",
                    "references-description-2": "{ name: (string), value: (string)}",
                },
                "method": {
                    "label": "Método HTTP",
                    "placeholder": "Método HTTP",
                },
                "content-type": {
                    "label": "Content Type",
                    "placeholder": "Selecciona el Content Type",
                },
                "parameter": {
                    "label": "Parámetro",
                    "placeholder": "Ingresa un parámetro",
                    "add": "Agregar parámetro"
                },
                "parameter-description": {
                    "label": "Descripción del Parámetro",
                    "placeholder": "Ingresa la descripción del Parámetro",
                },
                "parameter-type": {
                    "label": "Tipo de Parámetro",
                    "TEXT": "Texto",
                    "NUMBER": "Número",
                    "CURRENCY": "Moneda",
                    "BARCODE": "Código de barras",
                    "PHONE": "Teléfono",
                },
                "query-string": "Query",
                "param-scan": "Scan",
                "url": {
                    "label": "Vista previa del URL",
                    "placeholder": "Introduce tu URL y agrega los parámetros correspondientes en el botón +"
                },
                "body": {
                    "label": "Body",
                    "placeholder": "Introduce el Body y agrega los parámetros correspondientes en el botón +"
                },
                "test": {
                    "empty-title": "Te invitamos a llenar todos los datos de tu servicio web y hacer clic en Guardar,",
                    "empty-text": "al hacerlo podrás probarlo para verificar que funciona correctamente y activarlo",
                    "title": "Prueba tu servicio web",
                    "subtitle": "El servicio web está listo para realizar una prueba antes de ser activado. Puedes hacerlo aquí o en el listado de tipo de pagos después de guardarlo.",
                    "testing": "Tu servicio web está siendo probado. Esto puede tardar unos minutos…",
                    "param-placeholder": "Ingresa valor de {name}",
                    "results": "Resultados de la prueba",
                    "status": {
                        "label": "Estatus",
                        "placeholder": "Escoge uno",
                    },
                    "error": "Se ha detectado un error al probar tu servicio web. Verifica que la información ingresada sea correcta y haz clic en Guardar para probar de nuevo, o corrige el motivo de error marcado.",
                    "later": "Probar después",
                    "now": "Probar ahora",
                }
            }
        },
        "conceptos": {
            "title": "Conceptos",
            "subtitle": "Configura los conceptos que serán incluidos al pagar este servicio. Si el servicio permite múltiples unidades en una sola transacción, las cantidades de los conceptos del servicio serán multiplicadas automáticamente.",
            "help": "Ver ejemplo",
            "table": {
                "name": {
                    "title": "Nombre del concepto",
                    "placeholder": "Identifica el concepto"
                },
                "quantity": "Cantidad",
                "amount": "Monto",
                "subtotal": "Subtotal",
                "add": "Agregar concepto",
                "total": "TOTAL"
            }
        },
        "campos-informativos": {
            "title": "Campos informativos",
            "subtitle": "Agrega información adicional sobre este servicio que pueda ser importante que tus clientes conozcan (vigencias, restricciones, horarios, etc.). Estas notas se mostrarán al cliente en la pantalla del proceso de pago, al igual que en el comprobante.",
            "help": "Ver ejemplo",
            "input": {
                "title": "Campo informativo",
                "placeholder": "Introduce información adicional sobre el servicio"
            },
            "add-field": "Agregar campo informativo"
        },
        "return": "Regresar a Tipos de Pagos",
        "types": {
            "P": "Pago Regular",
            "EP": "Pago Rápido",
            "D": "Pago con Requisitos",
            "WS": "Pago de Servicio Web"
        },
        "edit-types": {
            "P": "Editar Pago Regular",
            "EP": "Editar Pago Rápido",
            "D": "Editar Pago con Requisitos",
            "WS": "Editar Pago de Servicio Web"
        },
        "wizard": {
            "step-1": "Datos generales",
            "step-2": "Campos informativos",
            "step-3": "Conceptos",
            "step-4": "Descuentos",
            "step-5": "Resumen"
        },
        "descuentos": {
            "title": "Descuentos",
            "subtitle": "Puedes agregar descuentos al servicio. Los descuentos pueden ser una cantidad fija o bien, un porcentaje sobre el subtotal del pago. También es posible configurar una vigencia en la cual el descuento será aplicable.",
            "help": "Ver ejemplo",
            "table": {
                "name": {
                    "title": "Nombre del descuento",
                    "placeholder": "¿Cómo quieres identificar este descuento?"
                },
                "type": {
                    "title": "Tipo",
                    "placeholder": "Elige uno"
                },
                "quantity": "Cantidad",
                "rango-fecha": {
                    "title": "Fechas de Vigencia",
                    "placeholder": "DD/MM/AA - DD/MM/AA"
                },
                "fecha": {
                    "title": "Fecha de Creación",
                    "placeholder": "DD / MM / AAAA"
                },
                "vigencia": "Vigencia",
                "status": "Estatus de descuento",
                "add": "Agregar descuento",
                "active": "Activo",
                "inactive": "Inactivo",
                "expired": "Expirado",
            }
        },
        "resumen": {
            "title": "Resumen",
            "subtitle": "Has terminado de configurar tu servicio. Aquí se muestra un resumen de la información ingresada para que puedas verificar que sea correcta. Si encuentras algún error puedes editar lo que sea necesario.",
            "datos-generales": {
                "title": "Datos generales",
                "divisa": {
                    "title": "Divisa",
                    "type": "Pesos mexicanos (MXN)"
                },
                "tags": "Tags",
                "id": {
                    "title": "ID externo",
                    "error": "No ingresaste ID externo."
                },
                "color": "Notas adicionales",
                "no-notes": "No pediste notas adicionales a tus clientes.",
                "multiply": {
                    "title": "Se permite multiplicación de pagos en una sola transacción",
                    "no": "Ne permite multiplicación de pagos",
                    "max": "Máximo permitido de multiplicación"
                }
            },
            "campos-informativos": {
                "title": "Campos informativos",
            },
            "conceptos": {
                "title": "Conceptos",
                "table": {
                    "name": "Nombre",
                    "quantity": "Cantidad",
                    "precio": "Precio unitario",
                }
            },
            "descuentos": {
                "title": "Descuentos",
                "table": {
                    "name": "Nombre",
                    "inicio": "Inicio",
                    "fin": "Fin",
                    "estatus": "Estatus",
                    "descuento": "Descuento"
                }
            },
            "configuracion-web": {
                "title": "Configuración web",
                "method": {
                    "title": "Método HTTP",
                    "subtitle": "Método"
                },
                "test-result": "Resultados de la prueba",
                "url": "URL del servicio web",
                "params-list": "Lista de parámetros",
                "content-type": "Content Type",
                "body": "Body"
            },
            "total": {
                "title": "Total a pagar"
            },
            "general": {
                "subtotal": "Subtotal",
                "total": "Total",
                "edit": "Editar sección"
            }
        },
        "delete": {
            "title": "¿Deseas eliminar este pago? | ¿Deseas estos pagos?",
            "text": "Al dar clic en “Eliminar perderás todo su contenido y tus clientes no podrán visualizarlo para realizar pagos para este servicio. | Al dar clic en “Eliminar perderás todo su contenido y tus clientes no podrán visualizarlo para realizar pagos para estos servicios.",
        }
    },
    "camara-component.not-source-video": "No se encontro fuente de video, por favor concede el permiso de cámara",
    "camara-component.not-video-see": "No se pudo obtener el video, por favor verifica si que se haya aceptado el permiso correspondiente",
    // Signup organizations
    "signup-organization.general.saving.error": "Ocurrio un error al intentar crear la organizacion",
    // Step One
    "signup-organization.step-one.title": "Empecemos por tu ",
    "signup-organization.step-one.title-strong": "Organización",
    "signup-organization.step-one.title-label": "Llena los siguientes campos:",
    "signup-organization.step-one.subtitle": "Cuéntanos un poco de tu organización o de tu empresa.",
    "signup-organization.step-one.name.label": "¿Cómo se llama tu Organización?",
    "signup-organization.step-one.name.placeholder": "Escribe el nombre de tu Organización o tu empresa aquí ...",
    "signup-organization.step-one.name.warning-text": "Debes escribir el nombre de tu organización para poder continuar.",
    "signup-organization.step-one.name.help-tooltip": "Escribe el nombre comercial de tu organización. No te preocupes, siempre podrás cambiar estos datos desde la sección Mi Organización.",
    "signup-organization.step-one.category.label": "¿Cúal es la categoría de tu organización?",
    "signup-organization.step-one.category.placeholder": "Selecciona tu el tipo de Organización aqui ...",
    "signup-organization.step-one.category.warning-text": "Debes seleccionar la categoría de tu organización",
    "signup-organization.step-one.category.help-tooltip": "Si no encuentras la categoría de tu organización en nuestro listado selecciona otra y después actualizala en tu perfil.",
    "signup-organization.step-one.description.label": "¿Qué hace tu organización?",
    "signup-organization.step-one.description.placeholder": "Ej. Puedes escribir tu eslogan, lo que vendes o que servicios brindas...",
    "signup-organization.step-one.description.warning-text": "Ingrese una descripción de la organización. Debe ser mayor a 2 caracteres y menor a 200",
    "signup-organization.step-one.description.help-tooltip": "La descripción no debe incluir datos de contacto ni precios de tus productos o servicios. Todo eso lo podrás añadir una vez que termines el registro de tu organización.",
    // Step Two
    "signup-organization.step-two.title": "¿Cómo ",
    "signup-organization.step-two.title-strong": "facturas?",
    "signup-organization.step-two.invoice-data.title": "Datos de ",
    "signup-organization.step-two.subtitle": "Te pedimos que nos ayudes a definir bajo que esquema operas.",
    "signup-organization.step-two.invoice-data.title-strong": "Facturación",
    "signup-organization.step-two.invoice-data.subtitle": "Llena los datos de facturación que usa tu organización",
    "signup-organization.step-two.label": "Selecciona si eres:",
    "signup-organization.step-two.physical-person.title": "Persona Física",
    "signup-organization.step-two.physical-person.content-1": "Soy una persona con derechos y obligaciones.",
    "signup-organization.step-two.physical-person.content-2": "Mi registro es con mi nombre.",
    "signup-organization.step-two.physical-person.content-3": "No necesito presentar contabilidad.",
    "signup-organization.step-two.physical-person.cuestion": "¿Eres la persona que factura en tu empresa?",
    "signup-organization.step-two.physical-person.answer.yes": "Si",
    "signup-organization.step-two.physical-person.answer.no": "No",
    "signup-organization.step-two.physical-person.rfc.label": "RFC (Registro Federal de Contribuyentes)",
    "signup-organization.step-two.physical-person.rfc.placeholder": "Ingresa tu RFC aquí ...",
    "signup-organization.step-two.physical-person.rfc.warning-text": "Ingrese un RFC válido",
    "signup-organization.step-two.moral-person.title": "Persona Moral",
    "signup-organization.step-two.moral-person.content-1": "Soy una empresa o un grupo de personas.",
    "signup-organization.step-two.moral-person.content-2": "Mi registro es con una razón social.",
    "signup-organization.step-two.moral-person.content-3": "Necesito presentar contabilidad electrónica.",
    "signup-organization.step-two.modal.title": "Ayúdanos a contactarte",
    "signup-organization.step-two.modal.title-success": "Tu llamada ha sido agendada",
    "signup-organization.step-two.modal.subtitle": "Selecciona la fecha y hora que tengas disponible para que nos comuniquemos contigo.",
    "signup-organization.step-two.modal.subtitle-success": "Nos pondremos en contacto contigo para terminar tu proceso de registro como empresa en Pagando.",
    "signup-organization.step-two.modal.telinput.label": "¿A qué número quieres que te llamemos?",
    "signup-organization.step-two.modal.telinput.placeholder": "Ej. (000) 000 00 00",
    "signup-organization.step-two.modal.datepicker.label": "Fecha de la llamada",
    "signup-organization.step-two.modal.datepicker.placeholder": "Selecciona una fecha que te convenga",
    "signup-organization.step-two.modal.hour-picker.label": "Hora de la llamada",
    "signup-organization.step-two.modal.hour-picker.sub-title": "Elige a que hora te gustaría recibir la llamada.",
    "signup-organization.step-two.modal.button": "Quiero que se comuniquen conmigo",
    "signup-organization.step-two.modal.button-success": "Regresar a pagando",
    "signup-organization.step-two.invoice-form.name": "Nombre(s)",
    "signup-organization.step-two.invoice-form.name-placeholder": "Escribe el(los) nombre(s)",
    "signup-organization.step-two.invoice-form.name-info": "Escribe el nombre propio de la persona que factura en tu empresa, como está escrito en su identificación oficial.",
    "signup-organization.step-two.invoice-form.first-lastname": "Primer apellido",

    "signup-organization.step-two.invoice-form.name-invalid": "El nombre debe de contener al menos tres caracteres",
    "signup-organization.step-two.invoice-form.first-lastname-invalid": "El primer apellido debe de contener al menos tres caracteres",
    "signup-organization.step-two.invoice-form.rfc-invalid": "Se debe de ingresar un RFC válido",
    "signup-organization.step-two.invoice-form.curp-invalid": "Se debe de ingresar un CURP válido",
    "signup-organization.step-two.invoice-form.street-invalid": "La dirección debe de contener al menos tres caracteres",
    "signup-organization.step-two.invoice-form.district-invalid": "La colonia debe de contener al menos tres caracteres",
    "signup-organization.step-two.invoice-form.city-invalid": "La ciudad debe de contener al menos tres caracteres",
    "signup-organization.step-two.invoice-form.state-invalid": "El estado debe de contener al menos tres caracteres",
    "signup-organization.step-two.invoice-form.zipcode-invalid": "Debes escribir los cinco dígitos del código postal.",
    "signup-organization.step-two.invoice-form.extNum-invalid": "La número exterior debe de contener al menos 1 y solo caracteres númericos",
    "signup-organization.step-two.invoice-form.oficialId-invalid": "Debes de subir un archivo con tu identificación, puede ser pdf o una imagen.",

    "signup-organization.step-two.invoice-form.second-lastname": "Segundo apellido",
    "signup-organization.step-two.invoice-form.first-lastname-placeholder": "Escribe el primer apellido",
    "signup-organization.step-two.invoice-form.second-lastname-placeholder": "Escribe el segundo apellido",
    "signup-organization.step-two.invoice-form.firstLastname-info": "Escribe el primer apellido.",
    "signup-organization.step-two.invoice-form.secondLastname-info": "Escribe el segundo apellido",
    "signup-organization.step-two.invoice-form.lastname-info": "Escribe tu primer y segundo apellido respectivamente en ese orden.",
    "signup-organization.step-two.invoice-form.rfc": "Registro Federal de Contribuyentes",
    "signup-organization.step-two.invoice-form.rfc-placeholder": "Escribe el RFC",
    "signup-organization.step-two.invoice-form.curp": "CURP",
    "signup-organization.step-two.invoice-form.curp-placeholder": "Escribe la CURP",
    "signup-organization.step-two.invoice-form.streetAddress": "Calle",
    "signup-organization.step-two.invoice-form.streetAddress-placeholder": "Escribe el nombre de la calle",
    "signup-organization.step-two.invoice-form.streetAddress-info": "Escribe el nombre completo de la calle sin abreviaciones.",
    "signup-organization.step-two.invoice-form.noExt": "Número exterior",
    "signup-organization.step-two.invoice-form.noExt-placeholder": "Escribe el número exterior",
    "signup-organization.step-two.invoice-form.noExt-info": "Escribe el número exterior de la propiedad, si el domicilio no tiene número escribe S/N o SN.",
    "signup-organization.step-two.invoice-form.district": "Colonia",
    "signup-organization.step-two.invoice-form.district-placeholder": "Escribe la colonia",
    "signup-organization.step-two.invoice-form.district-info": "Escribe el nombre de la colonia, fraccionamiento o asentamiento.",
    "signup-organization.step-two.invoice-form.state": "Estado",
    "signup-organization.step-two.invoice-form.state-placeholder": "Escribe el estado",
    "signup-organization.step-two.invoice-form.state-info": "Selecciona el estado o entidad federativa de la dirección fiscal.",
    "signup-organization.step-two.invoice-form.noInt": "Número interior",
    "signup-organization.step-two.invoice-form.noInt-placeholder": "Escribe el número interior",
    "signup-organization.step-two.invoice-form.noInt-info": "Algunas direcciones tienen número interior si se encuentran dentro de una vecindad o edificio. Ej: Si la dirección es 403-B el número interior es B.",
    "signup-organization.step-two.invoice-form.city": "Municipio",
    "signup-organization.step-two.invoice-form.city-placeholder": "Escribe el municipio",
    "signup-organization.step-two.invoice-form.city-info": "Escribe el nombre del Municipio.",
    "signup-organization.step-two.invoice-form.zipCode": "Codigo postal",
    "signup-organization.step-two.invoice-form.zipCode-placeholder": "Escribe el codigo postal",
    "signup-organization.step-two.invoice-form.zipCode-info": "Escribe el código postal al que pertenece tu colonia.",
    "signup-organization.step-two.invoice-form.oficialId": "Identificación Oficial",
    "signup-organization.step-two.invoice-form.oficialId-info": "Sube una identificación oficial: Credencial de elector (INE/IFE) o Pasaporte Mexicano.",
    "signup-organization.step-two.direccion": "Dirección",
    // Step three
    "signup-organization.step-three.success-step": "Se guardo correctamente la informacion",
    "signup-organization.step-three.empty-state.cover-photos.title": "No se encontraron resultados",
    "signup-organization.step-three.empty-state.cover-photos.text": "Por favor selecciona otra imagen, o sube una propia",
    "signup-organization.step-three.preview-modal-cover": "Selecciona una categoría",
    "signup-organization.step-three.format-image-not-accepted": "El formato de la imagen no es aceptado. Formatos permitidos: png, jpg, svg",
    "signup-organization.step-three.name-to-long": "El nombre de la imagen excede los caracteres permitidos, el nombre no deberá de exceder los 80 caracteres",
    "signup-organization.step-three.cropie-error": "Hubo un problema al recortar la imágen, por favor vuelva a intentarlo",
    "signup-organization.step-three.cropie-success": "Imágen recortada exitosamente",
    "signup-organization.step-three.logo-saved-error": "Hubo un problema al actualizar el logo, por favor vuelve a intentarlo más tarde",
    // Step Four
    "signup-organization.step-four.title": "¿Dónde recibirás tu dinero?",
    "signup-organization.step-four.sub-title": "Para que puedas recibir el dinero recaudado por el pago de los servicios que ofreces, es necesario que nos proporciones una cuenta bancaria.",
    "signup-organization.step-four.bank": "Banco",
    "signup-organization.step-four.bank-validation": "Escribe o selecciona tu banco",
    "signup-organization.step-four.bank-drop-down.placeholder": "Selecciona tu banco aqui ...",
    "signup-organization.step-four.bank-help.if-you": "Si no ves tu banco en la lista contáctanos a los teléfonos:",
    "signup-organization.step-four.account-number": "Número de cuenta",
    "signup-organization.step-four.account-number.placeholder": "Escribe los 10 u 11 dígitos de la cuenta bancaria ...",
    "signup-organization.step-four.account-number.error": "Debes escribir los 10 u 11 dígitos de tu número de cuenta para poder continuar",
    "signup-organization.step-four.account-number.help": "Puedes encontrarlo en un estado de cuenta, en la aplicación móvil de tu banco o en la carátula de depósito de tu cuenta.",
    "signup-organization.step-four.clabe": "CLABE",
    "signup-organization.step-four.clabe.placeholder": "Escribe los 18 dígitos de tu CLABE interbancaria ...",
    "signup-organization.step-four.clabe.error": "Debes escribir los 18 dígitos de tu Clabe Interbancaria para continuar",
    "signup-organization.step-four.clabe.help": "Tu clabe interbancaria es el número que se suele usar para hacer envios por SPEI, puedes encontrarlo en tu banca en línea.",
    "signup-organization.step-four.bankCover": "Cargar carátula bancaria",
    "signup-organization.step-four.bankCover.error": "Selecciona un archivo válido en formato JPG,PNG. o PDF",
    "signup-organization.step-four.bankCover.help": "Sube la primer página de un estado de cuenta de tu banco.",
    "signup-organization.step-four.bankCover.aditional-help": "Sube la primer página de un estado de tu cuenta bancaria, en el que sean visibles los siguientes datos:",
    "signup-organization.step-four.bankCover.aditional-help.name-logo": "Nombre y logotipo del banco",
    "signup-organization.step-four.bankCover.aditional-help.account-number": "Número de cuenta",
    "signup-organization.step-four.bankCover.aditional-help.account-number-holder": "Nombre del titular de la cuenta",
    "signup-organization.step-four.bankCover.aditional-help.CLABE": "Clabe Interbancaria",
    "signup-organization.step-four.error-step": "No se ha podido guardar la informacion bancaria",

    // Step five
    "signup-organization.step-summary.add-collaborator-modal.title": "Añadir colaboradores",
    "signup-organization.step-summary.add-collaborator-modal.title-success": "Colaboradores guardados",
    "signup-organization.step-summary.add-collaborator-modal.text": "Invita personas a colaborar con tu empresa añadiendo sus direcciones a continuación. Al terminar presiona el botón “Guardar colaboradores”.",
    "signup-organization.step-summary.add-collaborator-modal.text-success": "Los colaboradores se han guardado correctamente, una vez finalizado el proceso de creación de organización se enviaran las invitaciones",
    "signup-organization.step-summary.add-collaborator-modal.button": "Enviar Invitaciones",
    "signup-organization.step-summary.add-collaborator-modal.button-success": "Entendido",
    "signup-organization.step-summary.add-collaborator-modal.input-placeholder": "Escribe el correo electrónico que deseas añadir",
    "signup-organization.step-summary.add-collaborator-modal.persons-invited": "Personas a invitar",
    "signup-organization.step-summary.add-collaborator-modal.persons-invited-success": "Colaboradores",
    "signup-organization.step-summary.title": "Ultimos pasos",
    "signup-organization.step-summary.sub-title": "Te presentamos un resumen de todos los datos que has llenado, ésto para que puedas revisar que toda la información sea la correcta.",
    "signup-organization.step-summary.form.ocupation-label": "¿Cúal es tu ocupación?",
    "signup-organization.step-summary.form.ocupation-info": "La ocupación es el grupo de actividades que desempeñas en tu trabajo. Independientemente de lo que hayas estudiado.",
    "signup-organization.step-summary.form.ocupation-drop-down.titles": "Selecciona tu ocupación",
    "signup-organization.step-summary.form.ocupation-drop-down.options-selected": "options selected",
    "signup-organization.step-summary.form.ocupation-label-error": "Debes seleccionar tu ocupación",
    "signup-organization.step-summary.form.profesion-label": "¿Cúal es tu profesión?",
    "signup-organization.step-summary.form.profesion-info": "La profesión es la ocupación que requiere un conocimiento especializado.",
    "signup-organization.step-summary.form.profesion-drop-down.title": "Selecciona tu profesión",
    "signup-organization.step-summary.form.profesion-drop-down.options-selected": "options selected",
    "signup-organization.step-summary.form.profesion-label-error": "Debes seleccionar tu profesión",
    "signup-organization.step-summary.add-collaborator-button.title": "Añadir colaboradores a mi empresa",
    "signup-organization.step-summary.add-collaborator-button.sub-title": "Podrán administrar la cuenta junto a ti.",
    "signup-organization.step-summary.add-collaborator-button.email-saved": "Correo guardado",
    "signup-organization.step-summary.see-my-organization.button-text": "VER RESUMEN DE MI EMPRESA",
    "signup-organization.step-summary.see-my-organization.title": "Resumen",
    "signup-organization.step-summary.see-my-organization.organization-name": "Nombre de la Organización",
    "signup-organization.step-summary.see-my-organization.organization-type": "Tipo de Organización",
    "signup-organization.step-summary.see-my-organization.organization-description": "Descripción de la Organización",
    "signup-organization.step-summary.see-my-organization.ref": "Registro Federal de Contribuyentes (RFC)",
    "signup-organization.step-summary.see-my-organization.billing-info": "Datos de Facturación",
    "signup-organization.step-summary.see-my-organization.logo-cover": "Logotipo y Portada",
    "signup-organization.step-summary.see-my-organization.preview-cover.title": "Vista Previa de Portada y Logotipo",
    "signup-organization.step-summary.see-my-organization.preview-cover.text": "Recuerda que siempre puedes cambiar tu logotipo y foto de portada desde la sección “Mi Organización” en tu perfil.",
    "signup-organization.step-summary.see-my-organization.bank-account": "Cuenta Bancaria",
    "signup-organization.step-summary.see-my-organization.bank": "Banco",
    "signup-organization.step-summary.see-my-organization.account-number": "Número de cuenta",
    "signup-organization.step-summary.see-my-organization.CLABE": "Clabe Interbancaria",
    "signup-organization.step-summary.other-options": "Otras opciones",
    "signup-organization.step-summary.sales-man-code": "Código de Referencia (opcional)",
    "signup-organization.step-summary.sales-man-code-place-holder": "Ingresa el código de referencia del vendedor",
    "signup-organization.step-summary.sales-man-tooltip": "Este es el código de referencia de la persona que te invito a pagando",
    "signup-organization.step-summary.sales-man-alert": "En caso de tenerlo, puedes agregar en este apartado el código de referencia de la persona que te recomendó usar Pagando.",
    "signup-organization.step-contract.title": "Tu Contrato",
    "signup-organization.step-contract.title-strong": "Digital",
    "signup-organization.step-contract.sub-title": "Finalmente, te pedimos que leas y aceptes el Contrato Digital para empresas de Pagando.",
    "signup-organization.step-contract.last-update": "Última actualización:",
    "signup-organization.step-contract.checkbox-contract": "He leido y acepto el presente contrato digital.",
    "signup-organization.wizard-layour.see-my-organization.CLABE": "Clabe Interbancaria",
    "signup-organization.step-contract.loading-contract": "Generando contrato digital",
    "signup-organization.step-contract.print-error": "Por el momento no se pudo descargar el archivo, continua con el proceso y más adelate podras descargarlo",
    // Final setup
    "signup-organization.final-setup.title": "Bienvenido a",
    "signup-organization.final-setup.title-strong": "Pagando Check",
    "signup-organization.final-setup.sub-title": "La manera más sencilla de recibir tus pagos por internet",
    "signup-organization.final-setup.btnText": "ENTRAR A PAGANDO CHECK",
    "signup-organization.final-setup.creating-organization": "1. Creando Organización",
    "signup-organization.final-setup.creating-contract": "2. Guardando contrato digital",
    "signup-organization.final-setup.configure-organization": "3. Configurando Organización",
    "signup-organization.final-setup.creating-collaborators": "4. Invitando a colaboradores",
    "signup-organization.final-setup.starting-organization": "5. Iniciando Organización",
    "digital-contract.generate.error.default": "No se ha podido generar el contrato porfavor contacte a soporte técnico",
    "dashboard": {
        "title": "Panel de control",
        "subtitle": "Inicio",
        "success-transactions": "Monto transaccionado",
        "failed-transactions": "Transacciones fallidas",
        "average-pay": "Pago promedio",
        "last-average-pay": "Pago promedio anterior",
        "incomming-money": "Ingreso del día",
        "last-incomming-money": "Ingreso del día anterior",
        "week-income": "Ingreso de la semana",
        "last-week-income": "Ingreso de la semana anterior",
        "month-income": "Ingreso del mes",
        "year-income": "Ingreso del año",
        "last-five-years-income": "Ingreso del último lustro",
        "last-month-income": "Ingreso del mes anterior",
        "recived-payments": "Pagos recibidos",
        "recived-payments-desc": "Ve la cantidad de pagos recibidos durante la semana.",
        "select-range": "Seleccionar rango",
        "day": "Día",
        "month": "Mes",
        "year": "Año",
        "congrats": "¡Felicidades",
        "congrats-desc": "Esta semana tuviste un",
        "congrats-desc-1": "en comparación con la semana pasada.",
        "keep-the-effort": "¡Sigue esforzandote",
        "keep-the-effort-desc": "Esta semana no tuviste suficientes transacciones, pero sigue esforzandote para superar tus metas",
        "congrats-!": "!",
        "good-work": "¡Excelente trabajo!",
        "tip": "¡Tip!",
        "loading": "Cargando...",
        "tip-desc": "Para tener una racha de semanas consecutivas superando tus ingresos, ofrece descuentos y/o promociones.",
        "the-most-buyed-services-title": "Método de pago más utilizado",
        "the-most-buyed-services-subtitle": "Ve la cantidad de pagos recibidos durante la semana.",
        "payments": "Ventas",
        "download-invoice": "Descarga último",
        "download-invoice-button": "Descargar Estado de cuenta",
        "invoice": "Estado de Cuenta",
        "period": "Período",
        "invoice-in": "Factura en",
        "progress": "proceso",
        "invoice-in-progress-desc": "La factura esta en proceso de creación, por favor verifique que sus datos fiscales esten correctos.",
        "config-invoice-data": "Configurar datos fiscales",
        "amount": "Monto:",
        "january": "Enero",
        "february": "Febrero",
        "march": "Marzo",
        "april": "Abril",
        "may": "Mayo",
        "june": "Junio",
        "july": "Julio",
        "august": "Agosto",
        "september": "Septiembre",
        "october": "Octubre",
        "november": "Noviembre",
        "december": "Diciembre",
        "invoice-month": "la factura",
        "thousand": " Mil",
        "service": "Servicio",
        "no-ventas": "No. Ventas",
        "total": "Total",
        "organization": "Organización",
        "search-organization": "Buscar organización..",
        "admin-another-org": "Administrar otra organización",
        "select-organization": "Selecciona la Organización que deseas administrar:",
        "active-organizations": "Organizaciones activas",
        "inactive-organizations": "Organizaciones Inactivas",
        "coming-organizations": "Esperando Activación",
        "blocked-organizations": "Organizaciones Bloqueadas",
        "create-organization": "Crear organización",
        "unknow-card": "Desconocido",
        "debit-card": "Tarjeta de débito",
        "credit-card": "Tarjeta de crédito"
    },
    "payments": {
        "title": "Pagos | Pago",
        "select-debt": "Deuda",
        "select-webservice": "Servicio web",
    },
    "pagos-recaudados": {
        "title": "Pago Recaudado | Pagos Recaudados",
        "control-panel": "Panel de Control",
        "searching": "Buscando pagos recaudados",
        "empty-state-title": "¡Aun no cuentas con pagos recaudados!",
        "empty-state-description": "Si aun no te aparecen pagos recaudados te invitamos a crear nuevos servicios que puedas ofrecer a tus clientes y empieces a ver aqui tus pagos recaudados.",
        "folio": "Folio",
        "pay-concept": "Concepto de pago",
        "date": "Fecha",
        "status": "Estatus",
        "payment-date": "Fecha de pago",
        "amount": "Cantidad",
        "pay-type": "Tipo de Pago",
        "card": "Tarjeta",
        "tip": "Propina",
        "device": "Terminal Id",
        "authcode": "Código Aut",
        "see": "Detalle",
        "date-pay": "Pago recibido:",
        "service-price": "Precio del servicio",
        "all-selected": "Pagos seleccionados",
        "download-recipies": "Descargar recibos | Descargar recibo",
        "pagando-electronic-card": "Tarjeta de saldo electronico Pagando",
        "SUCCESSFUL": "Aceptado",
        "NOT_SUCCESSFUL": "Rechazada",
        "REFUND": "Reembolsado",
        "FRAUD": "Rechazada por fraude",
        "CHARGEBACK": "Contracargado",
        "default-concepts": {
            "SUCCESSFUL": "Pago aceptado",
            "NOT_SUCCESSFUL": "Pago rechazado",
            "REFUND": "Pago reembolsado",
            "FRAUD": "Pago rechazado por fraude",
            "CHARGEBACK": "Pago contracargado",
        },
        "start-card-fusion": "Fusionar a esta tarjeta",
        "edit-address": "Editar dirección",
        "title-small": "Inicio",
        "select-user": "Selecciona un usuario en la tabla de abajo",
        "select-user-info": "¡Mira su información aquí!",
        "access-history": "Historial Accesos",
        "cards-info": "Información de tarjetas",
        "cards-info-subtitle": "Información básica sobre las tarjetas del usuario",
        "access-history-subtitle": "Consulta los detalles de las sesiones de este usuario.",
        "access-history-search": "Buscar acceso...",
        "account-actions": "Acciones cuenta",
        "verify-account": "Verificar cuenta",
        "resend-email": "Reenviar correo",
        "convert-admin": "Convertir en administrador",
        "block-account": "Bloquear cuenta",
        "unblock-account": "Desbloquear cuenta",
        "user-details": "Detalles de usuario",
        "user-details-subtitle": "Consulta informacion sobre la cuenta de este usuario.",
        "user-details-phone": "Teléfono",
        "user-details-country": "Nacionalidad",
        "user-details-birthday": "Fecha de nacimiento",
        "user-details-gender": "Género",
        "user-details-register-method": "Método de registro",
        "user-details-street": "Domicilio",
        "user-details-email": "Correo electrónico",
        "user-details-age": "Edad",
        "user-details-state": "Estado de nacimiento",
        "user-details-ocupation": "Ocupación",
        "user-details-unknown": "Desconocida",
        "user-details-security-image": "Imagen de seguridad",
        "user-details-security-phrase": "Frase de seguridad",
        "user-details-last-access": "Último acceso",
        "user-details-num-pays": "Número de pagos",
        "user-details-organizations": "Organizaciones",
        "user-details-full-profile": "Perfil completo",
        "user-details-generail-info": "Información general",
        "user-edit-info": "Editar",
        "user-details-security-info": "Información de seguirdad",
        "user-details-organizations-info": "Información de organizaciones",
        "name-title-column": "Nombre",
        "name-title-column-placeholder": "Nombre de usuario...",
        "lastName-title-column": "Apellidos",
        "lastName-title-column-placeholder": "Apellido de usuario...",
        "email-title-column": "Correo",
        "email-title-column-placeholder": "Email de usuario...",
        "phone-title-column": "Celular",
        "phone-title-column-placeholder": "Teléfono de usuario...",
        "userType-title-column": "Tipo de usuario",
        "userType-title-column-placeholder": "Tipo de cuenta...",
        "status-title-column": "Estatus cuenta",
        "status-title-column-placeholder": "Estatus de cuenta...",
        "downloadXls-button": "Descargar .xls",
        "user-user": "Usuario",
        "owner-user": "Cliente",
        "admin-user": "Administrador",
        "level1": "Nivel 1",
        "level2": "Nivel 2",
        "level3": "Nivel 3",
        "admin": "Admin",
        "no-filter": "Sin filtro",
        "inactive": "Inactiva",
        "blocked": "Bloqueada",
        "active": "activa",
        "no-results-found": "No se han encontrado usuarios",
        "no-payment-history-found": "No se han encontrado pagos",
        "no-payment-history-found-search": "No se han encontrado pagos para la busquéda",
        "reset-filters": "Reiniciar filtros",
        "no-data": "No se han encontrado registros",
        "no-access-history-found": "No se han encontrado registros de acceso",
        "no-access-history-found-search": "No se han encontrado registros de acceso para la busquéda",
        "pagination": "Usuario | Usuarios",
        "organization-pagination": "Organización | Organizaciones",
        "pay-history": "Historial de pagos",
        "pay-history-des": "Consulta los pagos realizados por este usuario.",
        "search-pay": "Buscar pago...",
        "user": "Usuario",
        "user-info": "Datos del usuario",
        "email": "Correo electrónico",
        "phone": "Teléfono",
        "pay-detail": "Datos del pago",
        "payment-detail": "Detalles del pago",
        "payment-type": "Tipo de pago",
        "reject-reason": "Razón de rechazo",
        "pay": "Pago",
        "aditional-notes": "Notas Adicionales",
        "card-type": "Tipo de Tarjeta",
        "sub-total": "Subtotal",
        "discounts": "Descuentos",
        "taxes": "Impuestos",
        "total": "Total",
        "bank-commision": "Comisión bancaria",
        "amount-to-user": "Total a depositar",
        "folio-dot": "Folio:",
        "download-recipie": "Descargar comprobante",
        "excel-report-fail": "No se pudo generar correctamente el excel, por favor intentalo más tarde o contacta a soporte.",
        "reciept-report-fail": "No se pudo generar correctamente la vista previa del recibo, por favor intentalo más tarde o contacta a soporte.",
        "credit-card": "Tarjeta de crédito",
        "pagando-electronic": "Monedero electornico de pagando",
        "debit-card": "Tarjeta de debito",
        "unknow-card": "Desconocido",
        "select-webservice": "Servicio web",
        "select-debt": "Deuda",
        "full-history": "Historial completo",
        "month-history": "Historial del mes",
        "new-users-by-type": "Nuevos usuarios por tipo",
        "new-users": "Nuevos usuarios",
        "card-fb": "FB",
        "card-tw": "TW",
        "card-go": "GO",
        "card-ai": "AI",
        "card-cp": "CP",
        "users-blocked": "Usuarios dados de baja",
        "day": "Día",
        "month": "Mes",
        "year": "Año",
        "graph-title": "Nuevos usuarios",
        "graph-subtitle": "Ve la cantidad de usuarios registrados.",
        "select-range": "Seleccionar rango",
        "users-registred": "Usuarios registrados",
        "users-in-week": "Nuevos usuarios en la semana",
        "users-in-month": "Nuevos usuarios en el mes",
        "users-in-year": "Nuevos usuarios en el año",
        "active-users": "Usuarios activos",
        "close": "Cerrar",
        "no-user-organizations-found": "No se han encontrado organizaciones para este usuario",
        "assign-rol": "Asignar Rol",
        "assign-admin-rol": "Asignar Admin Rol",
        "transactional-profile": "Perfil transacc.",
        "add-blacklist": "Agregar a Blacklist",
        "remove-blacklist": "Remover de Blacklist",
        "add-whitelist": "Agregar a Whitelist",
        "remove-whitelist": "Remover de Whitelist",
        "add-blacklist-subtitle": "Indica el mótivo por el que deseas agregar el usuario a la Blacklist",
        "remove-blacklist-subtitle": "Indica el mótivo por el que deseas remover al usuario de Blacklist",
        "add-whitelist-subtitle": "Indica el mótivo por el que deseas agregar el usuario a la Whitelist",
        "remove-whitelist-subtitle": "Indica el mótivo por el que deseas remover al usuario de la Whitelist",
        "refunds": {
            "start": "Hacer devolución",
            "is-refunded": "Devolución realizada",
            "subtitle": "Para realizar una devolución por esta compra es necesario que ingreses el motivo y el monto a devolver. Recuerda no ingresar un monto mayor al monto pagado por el cliente.",
            "reason": "Motivo de la devolución",
            "reason-placeholder": "Selecciona un motivo...",
            "amount": "Monto",
            "amount-placeholder": "$0.00",
            "type": {
                "FULL": "<strong>Devolución total:</strong> Devuelve el monto total al comprador",
                "PARTIAL": "<strong>Devolución parcial:</strong> Devuelve al comprador solo una parte del monto total"
            }
        },
        "cards": {
            "streetAddress": "Calle",
            "streetAddress-placeholder": "Escribe el nombre de la calle",
            "noExt": "Número exterior",
            "noExt-placeholder": "Escribe el número exterior",
            "district": "Colonia",
            "district-placeholder": "Escribe la colonia",
            "state": "Estado",
            "state-placeholder": "Selecciona el estado...",
            "state-empty-list": "No hay una lista de estados. Favor de escribir uno ...",
            "noInt": "Número interior",
            "noInt-placeholder": "Escribe el número interior",
            "city": "Ciudad",
            "city-placeholder": "Escribe la ciudad",
            "zipCode": "Codigo postal",
            "zipCode-placeholder": "Escribe el código postal",
            "country": "País",
            "country-placeholder": "Selecciona el país...",
            "expiration-date": "Vencimiento {expirationDate}",
            "transfer": {
                "start": "Transferir tarjetas",
                "user-modal": {
                    "accept": "Transferir"
                }
            }
        },
    },
    "debtors": {
        "payment": "Pago",
        "return-to-payments": "Regresar a Tipos de Pagos",
        "return-config": "Regresar a Configuración de Deudores",
        "config": "Configuración de Deudores",
        "service-general-data": "Datos generales del servicio",
        "status": "Estatus",
        "coming-soon": "Próximamente",
        "active-service": "Activo",
        "inactive": "Inactivo",
        "currency": "Divisa",
        "new": "Nuevo Deudor",
        "download-documents": "Descargar documentos",
        "paid": "Pagado",
        "pending": "Pendiente",
        "page-count": "{count} Deudores | {count} Deudor | {count} Deudores",
        "delete": "Estás a punto de borrar un deudor",
        "delete-warning": "El deudor seleccionado se borrará de tu catálogo, recuerda que esta operacion no se puede deshacer. ¿Estás seguro?",
        "empty-state": {
            "title": "¡Crea tu primer Deudor!",
            "text": "Inicia tu nueva lista de deudores para este servicio. Asigna conceptos, montos de pago y descuentos personalizados para cada uno de ellos según su situación.",
        },
        "error-state": {
            "title": "A tu servicio le falta configuración",
            "text": "Por favor asegúrate que tu servicio esté bien configurado para poder continuar",
        },
        "payed": "Pago",
        "edit": "Editar Deudor",
        "advanced-config": "Configuración avanzada",
        "general": {
            "title": "Datos generales del deudor",
            "title2": "Datos generales",
            "subtitle": "Establece la configuración básica para este deudor. Asignar los campos y referencias ayudará a que cada deudor pueda acceder a sus montos y conceptos personalizados para después realizar su pago.",
            "status": {
                "title": "Estatus",
                "placeholder": "Selecciona un estatus"
            },
            "help": {
                "text": "¿Cuál elegir?"
            },
            "id": "ID Externo",
        },
        "fields": {
            "title": "Campos",
            "placeholder": "Ingresa la información que identificará a tu deudor en este campo"
        },
        "references": {
            "title": "Referencias",
            "placeholder": "Ingresa la información que identificará a tu deudor en esta referencia"
        }
    },
    "share": {
        "title": "Compartir",
        "subtitle": "Comparte la información de este deudor con más personas.",
        "link": "Enlace directo",
        "copied": "Se ha copiado satisfactoriamente el <strong>enlace directo</strong> de al portapapeles.",
        "add-people-placeholder": "Agregar más personas...",
        "people": "Personas"
    },
    "sign-in": {
        "login-incorrect-data": "Tus datos de usuario o contraseña son incorrectos. Por favor, verificalos y vuelve a intentarlo.",
        "login-blocked": "Has excedido el número de intentos para iniciar sesión. Por favor, inténtalo de nuevo en 30 minutos.",
        "login-tab": "Inicia Sesión",
        "signin-tab": "Regístrate",
        "main-title": "Inicia",
        "main-title-after-user": "Hola",
        "main-title-strong": "Sesión",
        "subtitle": "Ingresa tus datos para iniciar sesión en tu cuenta de Pagando Check.",
        "subtitle-after-user": "Si este no es tu nombre o no reconoces la siguiente imagen y frase, haz clic en el botón de “Regresar” y comienza de nuevo.",
        "email-label": "Correo electrónico o número de teléfono",
        "email-placeholder": "Ej. pablo@correo.com o +526141234567",
        "password-label": "Contraseña",
        "password-placeholder": "Ingresa tu contraseña",
        "login-button": "Iniciar Sesión",
        "forgot-password": "¿Olvidaste tu contraseña?",
        "want-save-time": "¿Quieres ahorrar tiempo? Inicia sesión con",
        "want-save-time-register": "¿Quieres ahorrar tiempo? Crea tu cuenta con",
        "login-with": "Ingresa con",
        "facebook": "Facebook",
        "twitter": "Twitter",
        "google": "Google",
        "no-pagando-account-yet": "¿Aún no tienes cuenta en Pagando?",
        "no-pagando-account-yet-register": "Regístrate",
        "register-tab": "Regístrate",
        "register-title": "Regístrate",
        "register-subtitle": "Ingresa tus datos para crear tu cuenta de Pagando.",
        "password-conditions": `Tu contraseña debe tener al menos 6 caracteres con una combinación entre letras, números y símbolos. Evita usar "contraseña", "123456" o "acbdef".`,
        "register-button": "Registrarme",
        "already-have-account": "¿Ya tienes cuenta en Pagando?",
        "login-link": "Inicia Sesión",
        "modal": {
            "title": "Sesiones activas: {qty}",
            "text": "Para una mejor experiencia de rendimiento y seguridad, es necesario cerrar las sesiones abiertas que no se estén usando."
        },
        "onboarding": {
            "title": "¿Qué obtengo con ",
            "title-strong": "Pagando?",
            "desc": "Para continuar con tu proceso de registro, puedes leer a detalle las comisiones, riesgos, responsabilidades y beneficios que te esperan en Pagando Check.",
            "img": {
                "title": "Pagando Check",
                "desc": "En Pagando Check encontrarás muchos beneficios que te ayudarán a elegir Pagando."
            },
            "transactions": "Límites Operacionales",
            "commissions": "Comisiones",
            "risks": "Riesgos",
            "responsabilities": "Responsabilidades",
            "benefits": "Beneficios"
        }
    },
    "signin-body": {
        "footer-already-have-account1": "¿Ya tienes cuenta en Pagando? ",
        "footer-already-have-account2": "Inicia sesión",
        "footer-no-account-yet1": "¿Aún no tienes cuenta en Pagando? ",
        "footer-no-account-yet2": "Regístrate",
        "first-section-easypay1": "Inicia sesión o regístrate",
        "first-section-easypay2": "Antes de poder iniciar el registro de tu organización debes registrarte como usuario o iniciar sesión si ya tienes cuenta.",
        "first-section-dontworry1": "¡No te preocupes, a todos nos pasa!",
        "first-section-dontworry2": "Sigue las instrucciones para que puedas recuperar de nuevo el acceso a tu cuenta de Pagando.",
        "help1": "Si requieres asistencia técnica, por favor contáctanos a los teléfonos: ",
        "help2": "800 323 0709 ",
        "help3": "para México.",
        "help4": "",
        "help5": "",
        "final-footer1": "Copyright 2023, Pagando Check",
        "pagando": "Pagando Check",
        "final-footer1-not-strong": "Copyright 2023,",
        "final-footer-a-company": "",
        "final-footer2": ""
    },
    "register-success": {
        "return-to-login": "Regresar a Iniciar sesión",
        "title": "¡Revisa tu ",
        "title-strong": "correo!",
        "subtitle": "¿Estás emocionado por comenzar a usar Pagando? Hemos enviado un mensaje a ",
        "subtitle2": " para que confirmes tu correo electrónico.",
        "resend-email-button": "Reenviar Correo electrónico",
        "email-not-received": "El correo electrónico puede tardar unos minutos en llegar, ",
        "email-not-received2": "en caso de no recibirlo haz clic en el botón superior ",
        "email-not-received3": "para reenviarlo."
    },
    "local-verification": {
        "ohno-title": "¡Oh, no! Algo anda ",
        "ohno-title-strong": "mal",
        "im-sorry-subtitle": "¡Lo sentimos mucho!",
        "experiencing-problems": "Probablemente tu link de confirmación se encuentra vencido, recuerda que es por seguridad, por favor contacta a soporte y enseguida te ayudaremos.",
        "button-message": "Enviar correo y notificarme",
        "technical-assistance1": "Si requieres asistencia técnica, por favor contáctanos a los teléfonos: ",
        "technical-assistance2": "800 323 0709 ",
        "technical-assistance3": "para México",
        "technical-assistance4": "",
        "technical-assistance5": " ó enviando un correo a soporte@pagandocheck.com",
        "validating": "Validando",
        "info": "información",
        "validating-info-title": "Estamos validando tu información",
        "validating-info-description": "Estamos asegurandonos de que tu cuenta este protegida, por favor espera un momento.",
        "verifying": "Verificando...",
        "congrats": "¡Felicidades!",
        "continue-register": "Continuar con el registro",
        "login": "Iniciar sesión",
        "congrats-description": "Tu correo electrónico ha sido verificado. Para finalizar tu registro ya solo es necesario que completes un corto formulario. Te prometemos que no te tomará mucho tiempo.",
    },
    "personal-information-form": {
        "title": "Formulario de ",
        "title-strong": "registro",
        "fill-information": "Para comenzar, ingresa la información que se pide en los siguientes campos:",
        "name-label": "Nombre(s)",
        "name-warning": "'El nombre debe de ser mayor a 2 caracteres'",
        "name-placeholder": "Escribe tu(s) nombre(s)",
        "lastname-label": "Primer Apellido",
        "lastname-warning": "'El apellido debe de ser mayor a 2 caracteres'",
        "lastname-placeholder": "Escribe tu primer apellido",
        "second-lastname-label": "Segundo Apellido",
        "second-lastname-warning": "'El apellido debe de ser mayor a 2 caracteres'",
        "second-lastname-placeholder": "Escribe tu segundo apellido",
        "nationality-label": "Nacionalidad",
        "nationality-placeholder": "Selecciona tu nacionalidad",
        "insert-next-information": "Ingresa la información que se pide en los siguientes campos:",
        "consult-curp-clic": "clic aquí",
        "birthdate-label": "Fecha de Nacimiento",
        "birthdate-placeholder": "DD / MM / AAAA",
        "genero-label": "Género",
        "extnumber-warning": "'Mínimo un caracter'",
        "postalcode-warning": "'Mínimo 5 caracteres'",
        "state-label": "Estado",
        "state-label-levelone-birthState": "Entidad federativa de nacimiento",
        "state-label-levelone": "Entidad federativa de recidencia",
        "state-placeholder-levelone": "No hay estados registrados para el país",
        "state-empty-levelone": "No hay estados registrados para el país",
        "email-levelone": "Correo electrónico",
        "state-warning": "Minimo dos caracteres",
        "email-warning": "Tienes que ingresar un correo válido",
        "state-placeholder": "Escribe el estado donde naciste",
        "email-placeholder": "Escribe tu correo electrónico",
        "occupation-label": "Ocupación",
        "occupation-placeholder": "Selecciona de la lista",
        "profession-label": "Profesión",
        "profession-placeholder": "Selecciona de la lista"
    },
    "phone-verification": {
        "title": "Verificación",
        "title-strong": "celular",
        "subtitle": "Ingresa tu número celular, te mandaremos un código para que los verifiques y sirva como medida de seguridad para tu cuenta",
        "options-selected": "Opciones seleccionadas",
        "number-phone": "Número de celular",
        "number-phone-placeholder": "Ej. (000) 000 00 00",
        "code-sent": "¡Código enviado!",
        "send-code-button": "ENVIAR CÓDIGO",
        "verification-success": "Verificado Correctamente",
        "verification-description1": "¡Felicidades! Tu número celular ",
        "verification-description2": " ha sido verificado exitosamente.",
        "enter-security-code": "Ingresa tu código de seguridad",
        "security-code-sent": "Enviamos tu código por mensaje de texto al número celular",
        "resubmit-code1": "Tu código de seguridad puede tardar unos minutos en llegar.",
        "resubmit-code2": "¿No recibiste ningún mensaje?",
        "resubmit-code3": "Haz clic aquí para reenviar el código de seguridad.",
        "sending-code": "Enviando código de seguridad...",
        "validating-code": "Validando código de seguridad...",
        "code-must-be-5": "'El código debe llevar 5 dígitos",
        "code-not-valid": "Validando código de seguridad..."
    },
    "security-measures": {
        "title": "Medidas de",
        "title-strong": "Seguridad",
        "subtitle": "Al iniciar sesión en Pagando Check verás una imagen y una frase para identificar tu perfil. ",
        "select-image": "Selecciona la imagen que más te guste",
        "now-phrase": "Ahora, escribe una frase",
        "phrase-label": "Escribe tu frase de bienvenida",
        "phrase-warning": "La frase debe de ser mayor a 2 caracteres",
        "phrase-placeholder": "Por ej. \"La vida es mejor Pagando\"",
        "info-message": "Cada vez que inicies sesión en Pagando Check verás la frase e imagen seleccionadas y sabrás que estás en tu perfil."
    },
    "payment-template": {
        "payments": "Pago | Pagos",
        "title-column": {
            "name": "Nombre de servicio",
            "type": "Tipo de pago",
            "detail": "Detalles",
            "service-status": "Estatus servicio",
            "service-payment": "Estatus pago",
            "actions": "Acciones"
        },
        "all-selected": "Pagos seleccionados",
        "cancel": "Cancelar",
        "download-pdf": "Descargar plantilla",
        "payment-status": {
            "valid": "Validado",
            "error": "Error",
            "no-filter": "Sin filtro"
        },
        "service-status": {
            "active": "Activo",
            "inactive": "Inactivo",
            "cooming-soon": "Próximamente",
            "no-filter": "Sin filtro"
        },
        "type": {
            "options": {
                "DEBTPAYMENT": "Requisitos",
                "PAYMENT": "Regular"
            },
            "no-filter": "Sin filtro"
        },
        "debts": "Deudores",
        "confirm-all": "Confirmar todos",
        "delete-payment": "Eliminar pago",
        "delete-many-payments": "Eliminar pagos",
        "button-accept": "Aceptar",
        "button": {
            "help": "Ayuda",
            "cancel": "Cancelar",
            "confirm-all": "Confirmar todos",
            "process-template": "Procesar pagos de plantilla",
            "download-template-processed": "Descargar plantilla procesada",
            "confirm": "Confirmar",
            "delete": "Eliminar",
            "accept": "Aceptar",
            "load-template": "Cargar plantilla",
            "understood": "Entendido"
        },
        "control-panel": "Panel de control",
        "title": "Plantilla de pagos",
        "load-template-payment": "Cargar plantilla de pagos",
        "empty-state": {
            "load-your-template": {
                "label": "Sube tu plantilla llena en el panel de la izquierda",
                "p": "y mira aquí el resumen de los pagos cargados."
            },
            "config-template": {
                "title": "¡Configura tu primera plantilla!",
                "text": "Descarga la plantilla en formato .xlsx para cargar una gran cantidad de pagos en una sola exhibición. Las instrucciones de llenado se encuentran en el archivo."
            },
            "not-found-results": {
                "title": "No se encontraron resultados con la búsqueda actual",
                "button": "Reiniciar filtros"
            }
        },
        "loading-process-template": "Tu plantilla está siendo procesada. <br> Esto puede tardar unos minutos...",
        "loading-info": "Cargando información. <br> Esto puede tardar unos minutos...",
        "charged-payments": "Pagos cargados",
        "valid-payments": "Pagos validados",
        "error-payments": "Pagos con error",
        "table": {
            "headers": {
                "name": "Nombre del servicio",
                "payment-type": "Tipo de pago",
                "details": "Detalles",
                "service-status": "Estatus servicio",
                "payment-status": "Estatus de pago",
                "actions": "Acciones"
            },
            "dropdown": {
                "show-details": "Ver detalles",
                "confirm": "Confirmar pago",
                "delete": "Eliminar"
            }
        },
        "modal": {
            "modal-delete": {
                "title": "¿Deseas eliminar este pago?",
                "text": "Al hacer clic en “Eliminar Pago” perderás su información dentro de la plantilla y tendrás que volver a llenar sus datos en una nueva plantilla."
            },
            "modal-delete-many": {
                "title": "¿Deseas eliminar éstos pagos?",
                "text": "Al hacer clic en “Eliminar Pagos” perderás su información dentro de la plantilla y tendrás que volver a llenar los datos de cada uno en una nueva plantilla."
            },
            "modal-delete-all": {
                "title": "¿Deseas cancelar todos los pagos?",
                "text": "Al hacer clic en “Aceptar” se perderán todos los pagos cargados dentro de la plantilla actual y tendrás que cargar una nueva."
            },
            "modal-confirm-all": {
                "title": "¿Deseas confirmar todos los pagos?",
                "text": "Al hacer clic en “Confirmar Todos” los pagos que se muestren con estatus de Validado se darán de alta en el micrositio de tu organización."
            },
            "modal-info": {
                "title": "¿Deseas cargar una nueva plantilla?",
                "text": "Al hacer clic en “Cargar Plantilla” se perderán todos los pagos cargados dentro de la plantilla actual y serán reemplazados con los pagos de la nueva plantilla."
            },
            "modal-help": {
                "title": "Ayuda con el proceso",
                "p1": "El botón de <strong>Confirmar Todos</strong> sirve para confirmar y subir a tu micrositio todos los pagos de tu plantilla. Este botón solamente estará habilitado cuando no se detecte ningún pago con estatus de Error.",
                "p2": "Con el botón de <strong>Cancelar</strong> podrás descartar la plantilla que generó pagos con errores para poder cargar una nueva.",
                "p3": "Al hacer clic en el botón de <strong>Descargar Plantilla Procesada</strong> se descargará en formato .xlsx tu plantilla con las celdas que causaron error marcadas en rojo para facilitar su corrección.",
                "p4": "Los pagos pueden confirmarse o eliminarse de manera individual al hacer clic en el ícono de <strong>Acciones</strong> localizado a la derecha de cada pago o de forma grupal utilizando los <strong>checkbox</strong> de la tabla."
            }
        }


    },
    "payment-template.upload-box.note": "El tamaño del archivo no debe ser mayor a 25 MB. Puedes subir un archivo tipo: .xlsx",
    "detail-payment-template.get-info.error": "No se pudo obtener la información del detalle.",
    "detail-payment-template.resumen.title": "Detalles del servicio.",
    "detail-payment-template.resumen.subtitle": "Aqui se muestra un resumen de la información sobre el servicio agregado desde la plantilla de Excel para que puedas verificar que sea correcta.",
    "detail-payment-template.debt-payment.detail-debt": "Estás viendo información de un deudor de tu servicio '{name}' , si quieres ver los detalles de éste servicio ",
    "detail-payment-template.debt-payment.subtitle": "Aqui se muestra un resumen de la información sobre el deudor agregado desde la plantilla de Excel para que puedas verificar que sea correcta.",
    "detail-payment-template.general.query.title": "No se encontraron elementos",
    "detail-payment-template.general.query.clear-filters": "Reiniciar filtros",
    "detail-payment-template": {
        "debtors": "Deudor | Deudores",
        "show-debtors-list": "Ver lista de deudores",
        "regular-payment": {
            "info": "El Pago Regular te permite configurar un pago que puede ser pagado por cualquier persona, cuantas veces sea necesario. En un Pago Regular, los conceptos y el monto del pago son iguales para todos sus clientes."
        },
        "debt-payment": {
            "info": "Estás viendo la información de tu servicio '{name}'. Si quieres ver la lista de deudores que creaste para éste servicio."
        },
        "return-payment-template": "Regresar a lista de plantilla de pagos.",
        "return-debtors-list": "Regresar a lista de deudores",
        "return-to-service": "Regresar a {name}",
        "payment-status": {
            "valid": "Validado (Servicio sin errores)",
            "error": "Error (Servicio con errores)"
        },
        "table": {
            "payment-type": "Tipo de pago",
            "regular-payment": "Pago regular",
            "debt-payment": "Pago con requisitos",
            "additional-information": "Información adicional",
            "not-additional-info": "Sin información adicional",
            "data-of-service": "Datos generales del servicio",
            "status-service": "Estatus",
            "concepts": {
                "title": "Conceptos",
                "name": "Nombre",
                "quantity": "Cantidad",
                "unit-price": "Precio unitario",
                "subtotal": "Subtotal"
            },
            "discounts": {
                "title": "Descuentos",
                "active": "Activo",
                "inactive": "Inactivo",
                "expired": "Expirado"
            },
            "total": {
                "title": "Total a pagar"
            }
        },
        "general": {
            "commision": "Comisión Pagando Check (Inc. IVA)",
            "return": "Regresar",
            "enter-here": "Ingresa aquí"
        }
    },
    "requested-documents": {
        "title": "Sube tus",
        "title-strong": "documentos",
        "subtitle": "Sube los siguientes documentos para finalizar con el registro de tu organización",
        "label-divider": "Requeridos ({documentsLoaded} de {numOfDocuments})",

    },
    "loading-register-wizard": "Cargando wizard de registro",
    "landing": {
        "footer-desc": "La manera más sencilla de realizar tus pagos por internet. Paga seguro y rápido desde donde quieras 24/7 o desde la comodidad de tu hogar.",
        "footer-product": "Producto",
        "footer-benefits": "Beneficios",
        "footer-comissions": "Comisiones",
        "footer-features": "Funcionalidades",
        "footer-deposit-dates": "Fechas de depósito",
        "footer-support": "Soporte",
        "footer-support-client": "Soporte al cliente",
        "footer-faq": "Preguntas frecuentes",
        "footer-contact": "Contáctanos",
        "footer-enterprise": "Empresa",
        "footer-privacy": "Aviso de Privacidad",
        "footer-terms": "Términos y Condiciones",
        "footer-web": "Sitio Web",
        "footer-blog": "Blog",
        "footer-dep-date": "Fechas de depósito",
        "footer-leyend": "XC Networks, S.A. de C.V. Institución de Fondos de Pago Electrónico (Pagando), es una entidad financiera autorizada, regulada y supervisada por las autoridades financieras en México para operar en términos de lo previsto por la Ley para Regular las Instituciones de Tecnología Financiera.",
        "footer-leyend-2": "Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse o garantizar los recursos de los Clientes que sean utilizados en las Operaciones que celebren con las ITF o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas por las ITF o por algún Cliente frente a otro, en virtud de las Operaciones que celebren.",
        "footer-have-questions": "¿Tienes <strong>dudas</strong>?",
        "footer-contactus-for-help": "Contáctanos para ayudarte",
        "footer-what-is": "¿Qué es <strong>Pagando</strong>?",
        "know-more": "Conoce más sobre nuestra plataforma",
        "top-bar": {
            "welcome": "Inicio",
            "ous": "Nosotros",
            "features": "Funcionalidades",
            "help": "Ayuda",
            "create-account": "Crear cuenta",
            "login": "Iniciar sesión",
        },
        "home": {
            "title": "¿Te interesa llevar tu negocio al <br /> mundo de <strong>los pagos digitales?</strong>",
            "subtitle": "Acepta los pagos de tus servicios con todo tipo de tarjeta sin necesidad de comprar una terminal.",
            "register": "Registra tu negocio",
            "section-1": "Pagando Check es la plataforma que facilitará el cobro de tus servicios. <br> Brinda a tus clientes una forma sencilla de pagar por internet y lleva un control de tus ventas, todo en un solo lugar.",
            "commision-title": "Baja comisión y grandes ganancias",
            "commision-subtitle": "Formar parte de Pagando Check es gratis, además de que tiene una comisión más baja que los bancos y las terminales tradicionales. Usa la calculadora y compruébalo.",
            "commision-amount": "SELECCIONA EL MONTO QUE DESEAS COBRAR",
            "type-cards": "TIPOS DE PAGO QUE ACEPTAMOS",
            "amount-deposit": "MONTO A DEPOSITAR EN MI CUENTA",
            "amount-gain": "DEL DINERO ES TU GANANCIA",
            "amount-commision-bank": "PORCENTAJE DE COMISIÓN BANCARIA",
            "amount-commision-total": "TOTAL DE COMISIÓN BANCARIA",
            "benefits-1-title": "Monitoreo y administración",
            "benefits-1-subtitle": "Monitorea estadísticas de pagos recaudados de tu negocio y descarga reportes de quién, cuándo y cuánto te han pagado.",
            "benefits-2-title": "Recibe pagos express",
            "benefits-2-subtitle": "Comparte un código QR único de tus servicios o negocio con tus clientes y recibe tus pagos al instante.",
            "benefits-3-title": "Sin preocupaciones",
            "benefits-3-subtitle": "Contamos con los mejores estándares de seguridad, sistema antifraude y mantenemos la integridad de todos tus datos.",
            "benefits-4-title": "Accede donde sea",
            "benefits-4-subtitle": "Realiza tareas de consulta y cobro de servicios desde una computadora en tu negocio, o bien, desde tu teléfono celular con la app de Pagando Check.",
            "benefits-5-title": "Recibe pagos en tu plataforma web",
            "benefits-5-subtitle": "Acepta pagos de tarjetas desde tu sitio web o tienda en línea conectando la API de Pagando® con toda la seguridad y protección de datos que ofrecemos.",
            "benefits-5-button": "CONOCE MÁS DE LA API",
            "benefits-comming-soon": "(PRÓXIMAMENTE)",
            "how-it-works": "¿Cómo <strong class='c-accent_2 principal-font-bold f-bold'>funciona</strong>?",
            "join-pagando": "Unirse a <strong class='c-title'>Pagando</strong> es tan sencillo como prepararte un café. <br /> Podemos resumirte el proceso en 4 simples pasos:",
            "steps-1-title": "Crea tu cuenta",
            "steps-1-subtitle": "Ingresa los datos de tu organización y completa tu registro en línea.",
            "steps-2-title": "Añade tus servicios",
            "steps-2-subtitle": "Agrega los servicios o tipos de pagos que podrás recibir por medio de la plataforma.",
            "steps-3-title": "Recibe tus pagos",
            "steps-3-subtitle": "Cobra tu dinero, analiza las tendencias de tus ventas y conoce tu mercado.",
            "steps-4-title": "Agrega a tu equipo",
            "steps-4-subtitle": "Opcionalmente puedes añadir colaboradores para delegar tareas.",
            "try-now": "¡Pruébalo <strong class='c-title principal-font-bold f-bold'>ahora!</strong>",
            "continue-register": "Continuar con el registro",
        },
        "about": {
            "title": "La manera más sencilla de recibir <strong>tus pagos por internet.</strong>",
            "know-more": "Descubre más",
            "about-desc": "Somos una plataforma de pagos por internet. Creamos un vínculo entre tu negocio y tus clientes para que puedan pagar tus servicios con facilidad, rapidez y seguridad.",
            "ous-descriptions": "Aspiramos a mejorar la <strong>experiencia de pagos digitales</strong> con Pagando",
            "desc-1-title": "Pagos para cualquier tipo de servicio",
            "desc-1-subtitle": "No importa cuál sea el giro o categoría de tu negocio. Recibe pagos para productos, servicios, pagos recurrentes, hasta pagos para instituciones educativas o gubernamentales.",
            "desc-2-title": "Es gratis",
            "desc-2-subtitle": "Sí, es gratis, no hay letras pequeñas. No te pediremos pagar una tarifa fija al mes ni hacer un mínimo de transacciones.",
            "desc-3-title": "Recibe más dinero a tu cuenta",
            "desc-3-subtitle": "Disminuye los costos por transacción con nuestra comisión bancaria más baja.",
            "desc-4-title": "Altos estándares de seguridad",
            "desc-4-subtitle": "Olvídate de preocupaciones. Te garantizamos que tu información personal y bancaria estará segura, y que los pagos de tus clientes se realizarán de la manera correcta.",
            "desc-5-title": "Consulta tu ingresos a un clic",
            "desc-5-subtitle": "Recibe al instante notificaciones de los servicios o productos que te paguen. Además, monitorea y descarga  un resumen con las estadísticas de tu negocio.",
            "comming-soon": "(PRÓXIMAMENTE)",
            "pay-express-title": "Recibe pagos express",
            "pay-express-subtitle": "Recibe pagos express",
            "register-now": "REGISTRATE AHORA",
        },
        "features": {
            "title": "Features",
            "subtitle": "Pagando Check está diseñado para potenciar tu negocio y ofrecer una amplia gama de características que permitirán <strong>configurar tus servicios</strong> y proporcionar a tus clientes una forma de pagar por internet.",
            "register": "Registra tu negocio",
            "h1-title": "Funcionalidades que <strong class='c-accent_2 principal-font-bold f-bold'>potenciarán</strong> tu negocio",
            "h1-subtitle": "Conoce las características que podrás usar para hacer",
            "h1-subtitle-1": "crecer tu negocio",
            "h1-subtitle-2": "y llevarlo a un nuevo nivel.",
            "partial-1-title": "Panel de control",
            "partial-1-subtitle": "Ve la información que más te importa desde un panel de control central, y controla tu organización y tus servicios.",
            "partial-1-dot-1": "Dashboard principal",
            "partial-1-dot-2": "Consulta tus pagos recibidos",
            "partial-1-dot-3": "Configuración de la organización",
            "partial-2-title": "Configuración",
            "partial-2-subtitle": "Configura y administra los servicios que tus clientes pueden pagar a través de Pagando. Es sencillo y se adapta a cualquier modelo de negocios.",
            "partial-2-dot-1": "Configuración de pagos",
            "partial-2-dot-2": "Cargar múltiples pagos usando una plantilla",
            "partial-3-title": "Notificaciones",
            "partial-3-subtitle": "Mantente informado sobre los pagos de tus clientes a través de Pagando Check con notificaciones en tiempo real.",
            "partial-3-dot-1": "Notificaciones de pagos en tiempo real",
            "partial-4-title": "Integración con tus sistemas",
            "partial-4-subtitle": "Consulta tus servicios desde sistemas propios. Lanza notificaciones al recibir un pago a tus sistemas, y consulta tu información a través de la API.",
            "partial-4-dot-1": "Configuración de pagos desde servicios web",
            "partial-4-dot-2": "Notificaciones a tus sistemas mediante WebHooks",
            "partial-4-dot-3": "API de consulta",
            "partial-5-title": "Micrositio de tu organización",
            "partial-5-subtitle": "Ofrece a tus clientes un lugar en el cual ver todos tus servicios. Personalízalo con tu identidad gráfica y agrega la información de contacto de tu negocio.",
            "partial-5-dot-1": "Todos tus servicios accesibles a tus clientes",
            "partial-5-dot-2": "Datos de contacto y redes sociales",
            "partial-5-dot-3": "Flexible y personalizable",
        },
        "pagando-api": {
            "title": "Recibe pagos digitales a distancia <strong> en tu sitio web </strong>",
            "subtitle": "Agrega la API de Pagando Check a tu sitio web y lleva un <strong>control</strong> en tiempo real de los <strong>ingresos de tu negocio</strong> desde tu app de Pagando Check.",
            "text": "<strong>Pagando® API</strong> es una herramienta que te permite potencializar las ventas de productos o servicios en tu sitio web o tienda en línea aceptando pagos con tarjetas.",
            "create-account": "Crea tu cuent a y <strong>empieza a impulsar a tu negocio ahora.</strong>",
            "register-now": "Regístrate ahora",
            "contact": "Contactar a ventas",
            "test-api": "Probar Pagando Check API",
            "benefits-1-title": "Más de un modo de recibir dinero",
            "benefits-1-subtitle": "Incrementa los ingresos utilizando las diferentes opciones de pago disponibles para tus sitios web:",
            "partial-1-dot-1": "Pago con tarjetas",
            "partial-1-dot-2": "Pago ingresando desde cuenta Pagando",
            "benefits-2-title": "Integra la API en más de un dominio",
            "benefits-2-subtitle": "Agrega las opciones de pago disponibles en más de un dominio para tu sitio web e incrementa las posibilidades de realizar una venta.",
            "benefits-3-title": "Personalización de diseño",
            "benefits-3-subtitle": "Haz una combinación perfecta con la identidad de tu sitio web, editando propiedades de las opciones de pago.",
            "benefits-4-title": "Pagos con cuentas de Pagando®",
            "benefits-4-subtitle": "Facilita los pagos de tus clientes iniciando sesión en su cuenta Pagando®. No te preocupes, ellos tendrán una protección total de datos personales y de tarjeta.",
            "benefits-5-title": "Fácil de aplicar para desarrolladores",
            "benefits-5-subtitle": "Desarrolla una integración de nuestra API Pagando® fácilmente siguiendo los pasos de nuestra documentación.",
            "benefits-5-btn": "LEER DOCUMENTACIÓN",
            "ous-descriptions": "Conoce por qué debes procesar pagos con la <strong>API de Pagando®.</strong>",
            "desc-1-title": "Aumenta tus ventas",
            "desc-1-subtitle": "Te permitimos incrementar la posibilidad de concretar más ventas  con nuestras diferentes opciones de pago.",
            "desc-2-title": "Recibe pagos de todas las tarjetas",
            "desc-2-subtitle": "Con la API de Pagando® recibe pagos de VISA, MASTERCARD y AMEX (American Express).",
            "desc-3-title": "Controla tus ventas",
            "desc-3-subtitle": "Desde nuestro panel para empresas, recibe y descarga informes sencillos para saber cómo va el día a día de tu negocio.",
            "desc-4-title": "Altos estándares de seguridad",
            "desc-4-subtitle": "Contamos con la certificación PCI DSS de nivel 1. Garantizamos que la información personal y bancaria de tus clientes estará en total seguridad.",
            "desc-5-title": "La comisión más baja",
            "desc-5-subtitle": "Ofrecemos la comisión más baja. Con Pagando® te preocupas menos y ganas más dinero.",
            "join-pagando": "Activar el <strong class='principal-font-bold'>API de Pagando</strong> es tan simple que lo realizas en 4 pasos:",
            "steps-1-title": "Crea tu cuenta",
            "steps-1-subtitle": "Ingresa los datos de tu organización y completa tu registro dentro de Pagando®.",
            "steps-2-title": "Actívalo desde Pagando",
            "steps-2-subtitle": "Ingresa a tu dashboard y activa desde la opción “API para sitio web”.",
            "steps-3-title": "Agrégalo a tu sitio web",
            "steps-3-subtitle": "Genera una llave que podrás agregar al código de tu sitio web.",
            "steps-4-title": "Estás listo para cobrar",
            "steps-4-subtitle": "Podrás recibir de ahora en adelante pagos con seguridad en tu sitio web utilizando Pagando®."
        },
        "home-place-holder-text": "Escribe el nombre de tu organización...",
        "content-apps-aviables": "App Disponible en iOS y Android",
        "content-apps-desc": "<strong>Pagando Check</strong> es la app creada especialmente para los negocios registrados que ofrecen sus servicios en la plataforma."
    },
    "terms-conditions": {
        "services-conditions": "Condiciones de servicio",
        "return-to-pagando": "Regresar a pagando",
        "privacy-agreement": "Aviso de Privacidad",
        "terms-conditions": "Términos y Condiciones",
    },
    "terms.title": "Términos y Condiciones",
    "terms.title-strong": "de uso de Pagando",
    "terms.last-update": "Última actualización:",
    "terms.checkbox-accept": "He leído y acepto los presentes términos y condiciones.",
    "terms.loading": "Cargando",
    "permissions.payment-links.name": "Check Links",
    "permissions.payment-link.create.name": "Creación de Check Links",
    "permissions.payment-link.edit.name": "Edición de Check Links",
    "permissions.payment-link.active.name": "Activación de Check Links",
    "permissions.payment-link.read.name": "Visualización de Check Links",
    "permissions.payment-link.delete.name": "Eliminación de Check Links",
    "permissions.new-profile.button": "Nuevo rol",
    "permissions.edit-profile.button": "Editar",
    "permissions.new-profile.modal.save.button": "Continuar",
    "permissions.new-profile.modal.cancel.button": "Cancelar",
    "permissions.new-profile.modal.title": "Nuevo rol",
    "permissions.new-profile.modal.title-edit": "Editar rol",
    "permissions.new-profile.modal.subtitle": "Asigna un nombre para identificar el nuevo rol y agrega una breve descripción con sus características más importantes.",
    "permissions.new-profile.modal.subtitle-edit": "Asigna un nombre para identificar el rol y agrega una breve descripción con sus características más importantes.",
    "permissions.new-profile.modal.name.label": "Nombre del rol",
    "permissions.new-profile.modal.name.placeholder": "¿Con qué nombre quieres identificar este rol?",
    "permissions.new-profile.modal.description.label": "Descripción del rol",
    "permissions.new-profile.modal.description.placeholder": "Escribe de manera breve las características de este rol...",
    "permissions.store.list-error": "Ha ocurrido un error inesperado al cargar la lista de permisos.",
    "permissions.store.create-profile.save-success": "Se ha guardado correctamente",
    "permissions.store.create-profile.error": "Ha ocurrido un error inesperado al intentar agregar el perfil. Intentalo de nuevo.",
    "permissions.store.create-profile.success": "Se ha guardado el perfil correctamente",
    "permissions.modal.able-rol.title": "¿Deseas activar este rol?",
    "permissions.modal.able-rol.text": "Al hacer clic en “Aceptar” tus cambios se guardarán y todos los usuarios con este rol asignado serán notificados de que cuentan con los permisos del mismo.",
    "permissions.modal.able-rol.button.accept": "Aceptar",
    "permissions.modal.disable-rol.title": "¿Deseas desactivar este rol?",
    "permissions.modal.disable-rol.text": "Al hacer clic en “Aceptar” tus cambios se guardarán y todos los usuarios con este rol asignado serán notificados de que sus permisos fueron retirados.",
    "permissions.modal.disable-rol.button.accept": "Aceptar",
    "permissions.store.active-profile.error": "Ha ocurrido un error inesperado al intentar activar el perfil. Intentalo de nuevo.",
    "permissions.modal.delete.title": "¿Deseas eliminar éste rol?",
    "permissions.modal.delete.text": "Al hacer clic en “Eliminar rol” perderás toda su configuración de permisos y tendrás que volver a crear un rol desde cero.",
    "permissions.modal.delete.button.delete": "Eliminar rol",
    "permissions.title": "Permisos",
    "permissions.msg-small": "Panel de Control",
    "permissions.empty-state.title": "No se encontraron resultados con la busqueda",
    "permissions.card.title": "Lista de permisos",
    "permissions.card.subtitle": "Selecciona los permisos a los que tendrá acceso un usuario al que le sea asignado este rol. Recuerda que no puedes eliminar un rol que actualmente este asignado al menos a un usuario, y que al desactivar un rol se retiran los permisos de todos los usuarios con ese rol asignado.",
    "permissions.switch.activate": "Activado",
    "permissions.button.cancel": "Cancelar",
    "permissions.button.save": "Guardar",
    "permissions.permissions": "Permiso | Permisos",
    "permissions.searching": "Buscando permisos...",
    "permissions.searching-permissions-profile": "Buscando permisos del perfil...",
    "collaborators.user-with-account": "Usuario con cuenta",
    "collaborators.user-without-account": "Usuario sin cuenta",
    "collaborators.store.list-error": "Ha ocurrido un error inesperado al cargar la lista de colaboradores.",
    "collaborators.add-collaborator.button": "Nuevo colaborador",
    "collaborators.store.add-collaborator.error'": "Ha ocurrido un error inesperado al intentar agregar los colaboradores. Intentalo de nuevo.",
    "collaborators.searching": "Buscando colaboradores...",
    "api-configuration": {
        "title": "API para sitio web",
        "smallMessage": "Panel de control",
        "card-title": "Selecciona el método de cobro",
        "card-subtitle": "Conoce los diferentes métodos de cobro de Pagando Check que puedes agregar a tus sitios web.",
        "api-card": {
            "title": "API",
            "subtitle": "Agrega a tu sitio web nuestros métodos de cobro y vende más."
        },
        "formulario-card": {
            "title": "Formulario",
            "subtitle": "Inserta un formulario de pagos personalizado a tu sitio web."
        },
        "checkout-card": {
            "title": "Botón Checkout",
            "subtitle": "Agrega un botón para cobrar a tu sitio web. Nosotros nos encargamos de hacer el proceso por tí."
        },
        "cards-button": "Seleccionar",
        "toast-error-title": "Error al obtener información",
        "toast-error-text": 'Ups! ha ocurrido un error al obtener información del paso {0}, por favor recargue la página o comuníquese con un asesor.',
        "api-keys": {
            "title": "Crea tu API",
            "return": "Regresar a API para sitio web",
            "loading": "Cargando información...",
            "first-step": {
                "title": "Agregar dominios web",
                "subtitle": "Agrega los dominios de los sitios web en los que usarás el API. Puedes utilizar un asterisco para representar cualquier ruta. Por ejemplo: *.tienda.com",
                "primary-button": "Configurar dominios",
                "secondary-button": "Editar",
                "origin-route": "Ruta de origen",
                "response-route": "Ruta de respuesta",
                "domain-list": "Dominios",
                "input-placeholder": "Agrega un dominio",
                "save": "Guardar",
                "domains": {
                    "label": "Dominio {index}",
                    "add": "Agrega un nuevo dominio",
                    "success": {
                        "title": "¡Éxito!",
                        "text": "Se guardaron los dominios exitosamente.",
                    },
                    "error": {
                        "title": "Error",
                        "text": "Ha ocurrido un error actualizando los dominios. Intenta de nuevo más tarde.",
                    },
                },
            },

            "second-step": {
                "title": "Genera tu llave para pruebas",
                "subtitle": "Crea una nueva llave para realizar pruebas en el simulador de procesos de compra.",
                "user": "Usuario",
                "primary-button": "Consultar",
                "secondary-button": "Regenerar",
                "input-title": "Llave pública para pruebas",
                "cypher-title": "Llave de encriptado RSA",
                "cypher-aes-title": "Llave de encriptado AES",
                "cypher-iv": "Vector de inicialización (IV)",
                "input-placeholder": "Haz clic en consultar para generar o mostrar la llave...",
                "input-cypher": "Llave de encriptado RSA",
                "input-aes": "Llave de encriptado AES",
                "input-iv": "IV",
                "tooltip": "Esta llave corresponde al password utilizado en el servicio de generación de token de autenticación ubicado en la ruta: /v1/pagando/get-token"
            },
            "third-step": {
                "title": "Verifica pruebas",
                "subtitle": "Te pedimos que pruebes la llave pública utilizando el siguiente simulador de procesos y verificando cada paso.",
                "primary-button": "Realizar pruebas",
                "secondary-button": "Verificar pruebas",
                "test-title": "Resultados de la prueba",
                "pendent-test-status": "Sin verificar",
                "error-test-status": "Error",
                "approved-test-status": "Aprobada"
            },
            "third-step-prestashop": {
                "title": "Verifica pruebas",
                "subtitle": "Para poder otorgarle sus llaves de producción es necesario validar la instalación del módulo en su tienda, para ello es necesario realizar una compra de prueba dentro de su tienda, la cual no le generará ningún costo con nosotros. Para ello existen dos modos, el primero es realizar una compra como usuario invitado, con la cual le pedimos que utilice los siguientes datos: <br><br> Nombre: FRANCISCO TORRES BENAVIDES <br> Número de tarjeta: 1111 1111 1111 1111 <br> Fecha de expiración: 02/23 <br><br> El otro modo es realizar un pago con una cuenta de prueba dentro de Pagando, para ello es necesario activar el usuario de prueba dando clic en el botón <strong>GENERAR USUARIO DE PRUEBAS</strong>."
            },
            "fourth-step": {
                "title": "Genera tu llave para producción",
                "subtitle": "Haz clic en el botón ‘Consultar’ para revisar tu llave pública o haz clic en el botón ‘Regenerar’ para crear una nueva.",
                "user": "Usuario",
                "primary-button": "Consultar",
                "secondary-button": "Regenerar",
                "input-title": "Llave pública para producción",
                "input-placeholder": "Haz clic en consultar para generar o mostrar la llave...",
                "warning": "Antes de continuar para generar la llave pública, es necesario validar los procesos de la llave para pruebas. Una vez realizado esto, se desbloqueará la sección para crear una llave pública.",
                "instructions-first-part": "Para consultar información más detallada sobre este proceso puedes consultar",
                "instructions-second-part": "la documentación oficial para desarrolladores",
                "instructions-third-part": " de Pagando.",
                "tooltip": "Esta llave corresponde al password utilizado en el servicio de generación de token de autenticación ubicado en la ruta: /v1/pagando/get-token",
                "modal-alert": {
                    "title": "¿Estás seguro de realizar éste cambio?",
                    "text": "Cambiar la llave de producción podría repercutir en la tienda de Pagando. Asegurate de realizar este cambio también en tu tienda para evitar fallas.",
                    "button-accept": "Aceptar"
                }
            }
        },
        "api-domains": {
            "return": "Regresar a API REST",
            "title": "Agregar dominios web",
            "second-title": "Editar dominios web",
            "card-title": "Configuración de dominios",
            "card-subtitle": "Configura la información de tus dominios que se te piden a continuación.",
            "first-section": "Información",
            "name-input-label": "Nombre",
            "name-input-placeholder": "Escribe el nombre de la organización...",
            "name-tootlip-text": "Nombre para identificar el sitio en el que se implementará la API de pagos de Pagando",
            "origin-input-label": "Ruta de origen",
            "origin-input-placeholder": "Ingresa la ruta de origen de tu dominio...",
            "answer-input-label": "Ruta de respuesta",
            "answer-input-placeholder": "Ingresa la ruta de respuesta de tu dominio...",
            "answer-tootlip-text": "Ruta a la cual se mandará la información del pago una vez que este haya sido efectuado, debe de ser una ruta POST",
            "domain-section-title": "Dominios",
            "domain-input-label": "Dominio",
            "domain-input-placeholder": "Ingresa un enlace de dominio de tu sitio web...",
            "add-domain-button": "Agregar dominio",
            "cancel-button": "Cancelar",
            "save-button": "Guardar información",
            "delete-domain-question": "¿Deseas eliminar este dominio?",
            "delete-domain-instructions": "Al dar clic en “Eliminar dominio” perderás la información guardada del dominio.",
            "delete-domain-button": "Eliminar dominio",
            "missing-fields": "Faltan campos por llenar"
        },
        "api-test-verification": {
            "return": "Regresar a Crear tu API",
            "title": "Simulador de procesos",
            "general-instructions": "Haz clic en el botón “Verificar” para iniciar con la verificación del servicio.",
            "previous-test": "Regresar",
            "next-test": "Siguiente",
            "verify-test": "Verificar",
            "finish-test": "Terminar proceso",
            "test-validation": "Validando proceso...",
            "detail-title": "Detalles del procesamiento"
        },
        "verification-tests": {
            "auth-test": {
                "test-name": "Autenticación de credenciales",
                "first-instruction": "En Pagando Check estamos comprometidos con tu seguridad y la de tus clientes, es por ello que todas las peticiones se encuentran protegidas por medio de tokens de autenticación.",
                "second-instruction": `Para poder obtener el token correspondiente, es necesario realizar una petición al servicio de autenticación contenido en la siguiente ruta: <strong class="html-markup">${config.server.paymentHost}/v1/pagando/get-token</strong>`,
                "third-instruction": "Utiliza el Usuario y la Llave pública de prueba que generaste anteriormente para poder obtener tu token de autorización.",
                "fourth-instruction": "Para información más detallada sobre este proceso y los parámetros necesarios para esta petición puedes consultar la documentación oficial para desarrolladores de Pagando.",
                "dashboard-success": "La información se ha consultado con éxito",
                "dashboard-error": "No se pudo autenticar, datos erróneos"
            },
            "client-registration": {
                "test-name": "Registro de información de cliente",
                "first-instruction": "Para una mayor seguridad en las peticiones, es necesario enviar la información segmentada en varios servicios, los cuales se proporcionan en cada paso.",
                "second-instruction": `En este paso en el servicio ubicado en la siguiente ruta:<strong class="html-markup">${config.server.paymentHost}/v1/pagando/users/user</strong> se enviará la información del cliente para darla de alta y se retornará como respuesta el identificador de usuario.`,
                "third-instruction": "No olvides consultar nuestra documentación para obtener más información de este servicio.",
                "dashboard-success": "Servicio aprobado con éxito",
                "dashboard-error-email": "The email address is invalid",
                "dashboard-error-data": "Data was omitted when creating the user",
            },
            "card-registration": {
                "test-name": "Registro de información de tarjeta",
                "first-instruction": "Para una mayor seguridad en las peticiones, es necesario enviar la información segmentada en varios servicios, los cuales se proporcionan en cada paso.",
                "second-instruction": `En este paso en el servicio ubicado en la siguiente ruta: <strong class="html-markup">${config.server.paymentHost}/v1/pagando/payment_methods/add_card</strong> se enviará la información de la tarjeta para darla de alta y se retornará como respuesta el identificador de dicha tarjeta.`,
                "third-instruction": "No olvides consultar nuestra documentación para obtener más información de este servicio.",
                "dashboard-success": "La información se ha consultado con éxito",
                "dashboard-error-name": "El nombre de la tarjeta no coincide con el nombre del registro proporcionado.",
                "dashboard-error-data": "La información de la tarjeta no es correcta.",
                "dashboard-error-cvv": "El código de seguridad de la tarjeta no es válido. Verifique el código de seguridad de la tarjeta o use una tarjeta diferente.",
                "dashboard-error-number": "El número de tarjeta no es válido. Verifique los detalles de la tarjeta o use una tarjeta diferente.",
            },
            "anti-fraud": {
                "test-name": "Obtención de token antifraude",
                "first-instruction": "Para una mayor seguridad en las transacciones, Pagando Check hace uso de tecnología antifraude por medio del uso de un token de identificación de cliente. Con este token nuestros clientes se encuentran protegidos de usuarios maliciosos que busquen afectar sus transacciones.",
                "second-instruction": `Para hacer uso de este token antifraude, es necesario instalar el siguiente script en su página. Al obtener el token por medio de la función  <strong class="html-markup">getAFT</strong>, es necesario enviarlo en cada una de las transacciones de pago`,
                "third-instruction": "El uso del token antifraude es opcional."
            },
            "success-transaction": {
                "test-name": "Pago exitoso",
                "first-instruction": "Es necesario probar el servicio de pago, para ello primero se deberá de dar de alta una tarjeta con el servicio del Registro de información de tarjeta del Paso 3.",
                "second-instruction": "En este caso la tarjeta es la misma que se utilizó en el Paso 3, así que se puede utilizar el mismo identificador obtenido por el servicio de dicho paso.",
                "third-instruction": `Para realizar un pago es necesario mandar el identificador de la tarjeta y el usuario a la siguiente ruta: <strong class="html-markup">${config.server.paymentHost}/v1/pagando/orders/create-order</strong>`,
                "dashboard-success": "Servicio aprobado con éxito.",
                "dashboard-error-anti": "No se encontró token antifraude",
                "dashboard-error-fondos": "Tarjeta sin fondos",
                "dashboard-error-setting": "No se encontraron configuraciones de pago, verifique su clave pública",
                "dashboard-error-user": "No hay usuario para el userId proporcionado",
            },
            "error-transaction": {
                "test-name": "Tarjeta sin fondos",
                "first-instruction": "Es necesario probar el servicio de pago, para ello primero se deberá de dar de alta una tarjeta con el servicio del Registro de información de tarjeta del Paso 3. ",
                "second-instruction": "En este caso demostrativo se probará con un servicio en donde el pago devolverá un error, esto con la intención de mostrar cualquier evento de esta índole. ",
                "third-instruction": `Para realizar un pago es necesario mandar el identificador de la tarjeta y el usuario a la siguiente ruta: <strong class="html-markup">${config.server.paymentHost}/v1/pagando/orders/create-order</strong>`
            },
            "create-ecommerce-order": {
                "test-name": "Crear una orden de e-commerce",
                "first-instruction": "",
                "second-instruction": "",
                "third-instruction": ""
            },
            "confirm-ecommerce-order": {
                "test-name": "Confirmar el pago de una orden realizada",
                "first-instruction": "",
                "second-instruction": "",
                "third-instruction": ""
            },
            "success-msi-transaction": {
                "test-name": "Pago exitoso a MSI",
                "first-instruction": "Es necesario probar el servicio de pago a MSI, para ello primero se deberá de dar de alta una tarjeta con el servicio del Registro de información de tarjeta del Paso 3.",
                "second-instruction": "",
                "third-instruction": `Para realizar un pago es necesario mandar el identificador de la tarjeta y el usuario a la siguiente ruta: <strong class="html-markup">${config.server.paymentHost}/v1/pagando/orders/create-order</strong>`,
                "dashboard-success": "Servicio aprobado con éxito.",
                "dashboard-error-anti": "No se encontró token antifraude",
                "dashboard-error-fondos": "Tarjeta sin fondos",
                "dashboard-error-setting": "No se encontraron configuraciones de pago, verifique su clave pública",
                "dashboard-error-user": "No hay usuario para el userId proporcionado",
            },
        },
        "checkout": {
            "tittle": "Checkout para plataforma",
            "select": {
                "tittle": "Selecciona tu plataforma",
                "subtittle": "Selecciona el entorno de desarrollo de tu plataforma",
                "coming-soon": "Próximamente",
            },
            "selected": {
                "return-api": "Regresar a API para sitio web",
                "general": {
                    "info-developer": "Es importante que una persona con conocimientos técnicos sea la que se encargue de configurar los datos de esta sección para evitar futuros errores.",
                    "return-link": "Ruta de retorno",
                    "return-link-holder": "Ingresa la ruta de retorno de tu dominio...",
                    "return-link-help": "La ruta de retorno, ayuda a tus clientes a regresar a tu pagina web, en caso de que deseen modificar la orden o no quisiera continuar con el pago.",
                    "confirmation-link": "Ruta de confirmación",
                    "confirmation-link-holder": "Ingresa la ruta de confirmación de tu dominio...",
                    "confirmation-link-help": "La ruta de confirmación, redirigida a tus clientes del checkout, una vez que el pago se haya efectuado correctamente.",
                    "success": {
                        "updating-checkout": "Se ha actualizado correctamente su configuración"
                    },
                    "error": {
                        "get-info": "Hubo un error tratando de cargar su configuración de API",
                        "save-checkout": "Hubo un error al tratar de guardar su configuración",
                        "update-config": "Hubo un error tratando de actualizar su configuración de API"
                    },
                    "allowed-payment": {
                        "client": "Permitir pagos de clientes con cuenta en Pagando",
                        "no-client": "Permitir pagos de clientes sin cuenta en Pagando",
                        "email": "Permitir que Pagando Check envíe recibos de la compra"
                    },
                    "btnConfirm": "GUARDAR CAMBIOS",
                },
                "prestaShop": {
                    "title": "Agrega tu módulo para cobrar con Pagando",
                    "second-step-title": "Configuración de módulo Checkout",
                    "subtitle": "En esta sección se realizarán las configuraciones necesarias para que tu módulo funcione correctamente.",
                    "installation": {
                        "title": "Instalación de módulo en Prestashop",
                        "subtitle": "En este paso lo guiaremos en la instalación del módulo de Pagando Check en su tienda",
                    },
                    "verification": {
                        "success": "Las pruebas se verificaron correctamente",
                        "error": "Error al intentar verificar las pruebas, por favor intentalo más tarde",
                        "incomplete": "Faltan pruebas por completar, vuelve a realizar la compra de prueba e intenta de nuevo",
                    }
                },
                "woocommerce": {
                    "title": "Agrega tu módulo para cobrar con Pagando",
                    "second-step-title": "Configuración de módulo Checkout",
                    "subtitle": "En esta sección se realizarán las configuraciones necesarias para que tu módulo funcione correctamente.",
                    "installation": {
                        "title": "Instalación de módulo en Woocommerce",
                        "subtitle": "En este paso lo guiaremos en la instalación del módulo de Pagando Check en su tienda",
                    },
                    "verification": {
                        "success": "Las pruebas se verificaron correctamente",
                        "error": "Error al intentar verificar las pruebas, por favor intentalo más tarde",
                        "incomplete": "Faltan pruebas por completar, vuelve a realizar la compra de prueba e intenta de nuevo",
                    }
                },
                "magento": {
                    "title": "Agrega tu modulo para cobrar con Pagando",
                    "second-step-title": "Configuración de módulo Checkout",
                    "subtitle": "En esta sección se realizarán las configuraciones necesarias para que tu módulo funcione correctamente.",
                    "installation": {
                        "title": "Instalación de módulo en Magento",
                        "subtitle": "En este paso lo guiaremos en la instalación del módulo de Pagando Check en su tienda",
                    },
                    "verification": {
                        "success": "Las pruebas se verificaron correctamente",
                        "error": "Error al intentar verificar las pruebas, por favor intentalo más tarde",
                        "incomplete": "Faltan pruebas por completar, vuelve a realizar la compra de prueba e intenta de nuevo",
                    }
                },
                "javascript": {
                    "second-step-title": "Configuración del botón Checkout",
                    "subtitle": "En esta sección se realizarán las configuraciones necesarias para que tu script funcione correctamente.",
                    "title-botton-checkout": "Código de botón checkout",
                    "placeholder-checkout": "Da clic en consultar para generar tu código...",
                    "help-button": "Agregue el script de pagando a su pagina web",
                    "installation": {
                        "title": "Instalación Botón Checkout",
                        "subtitle": "En este paso lo guiaremos en la integración del script de pagando en su plataformas",
                    },
                    "Instructions": {
                        "step1": {
                            "title": "Instalar SDK",
                            "title-number": "Instalación SDK de Pagando Check en plataforma de usuario",
                            "instruction": "Copia y pega el código en el archivo HTML de tu sitio web después de la etiqueta “HEAD”, como se muestra a continuación. "
                        },
                        "step2": {
                            "title": "Instalando el Botón",
                            "title-number": "Instalación del botón dentro de su plataforma",
                            "instruction": "Para poder tener acceso al botón <strong>Pagando Checkout </strong> como forma de pago  en su plataforma, es necesario tener una etiqueta div en su pagina web con el siguiente identificador único: <strong>pagando-button-container</strong>. Dicha etiqueta puede encontrarse en la sección que usted guste y en la cual usted desee que se muestra esta forma de pago."
                        },
                        "step3": {
                            "title": "Configuración de Credenciales",
                            "title-number": "Configuración de Credenciales",
                            "instruction": "En este paso, se deberá proporcionar el usuario y la llave publica/producción que le fueron otorgadas, para ello, utilize la función que se muestra a continuación dentro de una etiqueta script en la vista que deseé utilizar este método de pago."
                        },
                        "step4": {
                            "title": "Configuración de botón",
                            "title-number": "Configuración de botón de pago",
                            "instruction": "Para poder visualizar el botón como opción de pago y conectarse con la plataforma Pagando, implemente la función <strong>createOrder</strong> como se muestra a continuación. Dicha función será ejecutada cuando su cliente de clic en el botón <strong>Pagando Checkout </strong>. El objetivo de esta función es la de configurar los detalles de una orden única para <strong>Pagando</strong>, la cual debe estar constituida por un monto y un identificador único de la compra." +
                                " Además inicia el proceso de redirección para que el comprador pueda iniciar sesión y aprobar la transacción en <strong>pagando.mx.</strong>"
                        },
                        "step5": {
                            "title": "Visualización del método de pago",
                            "subtitle": "Visualización del método de pago",
                            "instruction": `Después de finalizar con la configuración podrá visualizar su nuevo método de pago en su carrito de compras.`,
                        },
                        "step6": {
                            "title": "Pago exitoso",
                            "subtitle": "Captura de pago exitoso",
                            "instruction": `En este paso, se requiere que implemente la recepción de la orden, que fue aprobada por el comprador en <strong>pagando.mx</strong>. Para ello, utilize la función que se muestra a continuación, dentro de la <strong>ruta de confirmación </strong> que definió anteriormente.`,
                            "help": "La función <strong>PagandoCheckout.getStatusOrder</strong> regresa una promesa, por lo cual, si usted así lo desea, pude llamarla de la forma <strong>Async/Await</strong>"
                        },
                        "step7": {
                            "title": "Manejo de errores",
                            "subtitle": "Manejo de errores",
                            "instruction": "Para manejar los errores que pudieran ocurrir dentro del proceso de pago, utilize la función que se muestra a continuación.",
                            "help": "Para conocer los errores que maneja <strong>Pagando Checkout</strong>, por favor utilize la función <strong>PagandoCheckout.describeErrors()</strong>, la cual regresa una lista de los posibles errores que pudiera recibir"
                        },
                        "step8": {
                            "title": "Verificación de instalación",
                            "subtitle": "Verificación de instalación",
                            "instruction": `Para poder corroborar que la instalación y la configuración de su módulo se han realizado de forma correcta, realize un pago en su plataforma utilizando los datos de tarjeta que se muestran en la siguiente sección de esta página.`,
                        },
                    }
                }
            },
            "user-test": {
                "makeUserTest": "GENERAR USUARIOS DE PRUEBA",
                "user-success": "Usuario Pago Exitoso",
                "user-success-help": "Usuario con el cual podrá realizar pruebas donde se simula un pago exitoso",
                "user-reject": "Usuario Pago Rechazado",
                "user-reject-help": "Usuario con el cual podrá realizar pruebas donde se simula un pago rechazado",
                "password": "Contraseña",
            }
        },
        "prestashop": {
            "instructions": {
                "step-one": {
                    "title": "Instalación de módulo",
                    "subtitle": "Instalación del Módulo Pagando Payment",
                    "instruction": `Dentro de tu panel de administrador en PrestaShop, dirígete al apartado de <strong>MÓDULOS</strong> dentro de la sección de <strong>PERSONALIZAR</strong>. una véz dentro da clic en el botón <strong>SUBIR UN MÓDULO</strong> y elige el archivo comprimido que te hemos proporcionado.`
                },
                "step-two": {
                    "title": "Configuración de módulo",
                    "subtitle": "Configuración del Módulo Pagando Payment",
                    "instruction": `Una véz instalado nos dírijimos al <strong>GESTOR DE MÓDULOS,</strong> y en su buscador escribimos la palabra <strong>Pagando</strong>. Una vez que aparezca el módulo, damos clic en <strong>CONFIGURAR</strong> .`
                },
                "step-three": {
                    "title": "Uso de credenciales",
                    "subtitle": "Uso de credenciales dentro del módulo",
                    "instruction": `En la ventana de <strong>CONFIGURACIÓN</strong> rellenamos los campos de <strong>USUARIO</strong> y <strong>CONTRASEÑA</strong> con las credenciales que hemos obtenido. Adicional a esto, elegimos la modalidad tipo <strong>EXTERNAL</strong>.`
                },
                "step-four": {
                    "title": "Visualización del método de pago",
                    "subtitle": "Visualización del método de pago",
                    "instruction": `Después de finalizar con la configuración podrá visualizar su nuevo método de pago en su carrito de compras.`,
                },
                "step-five": {
                    "title": "Verificación de instalación",
                    "subtitle": "Verificación de instalación",
                    "instruction": `Para poder corroborar que la instalación y la configuración de su módulo se han realizado de forma correcta, realize un pago en su plataforma utilizando los datos de tarjeta que se muestran en la siguiente sección de esta página.`,
                },
            }
        },
        "woocommerce": {
            "instructions": {
                "step-one": {
                    "title": "Instalación de woocommerce",
                    "subtitle": "El módulo de Pagando Check es una añadidura al reconocido plugin de woocommerce",
                    "instruction": "Es requerido que se asegure de tener instalado el plugin de woocommerce para poder recibir pagos por Pagando"
                },
                "step-two": {
                    "title": "Activación de método de pago",
                    "subtitle": "Seleccione la o las formas de pago deseadas",
                    "instruction": "Dentro de la configuración de WooCommerce se necesita activar los método de pago de Pagando Check correspondientes"
                },
                "step-three": {
                    "title": "Instalación y búsqueda del módulo",
                    "subtitle": "Instalación del Módulo Pagando Payment",
                    "instruction": "Entrar al catálogo de plugins y utilizar el campo de búsqueda para encontrar e instalar el plugin Pagando"
                },
                "step-four": {
                    "title": "Configuración de módulo",
                    "subtitle": "Configuración del Módulo Pagando Payment",
                    "instruction": "Una vez instalado el módulo este aparecerá junto con los demás y se deberá activar. IMPORTANTE: La página deberá de tener confdigurados los 'enlaces permanentes' en una opción diferente a 'Simple'"
                },
                "step-five": {
                    "title": "Uso de credenciales",
                    "subtitle": "Uso de credenciales dentro del módulo",
                    "instruction": "Aquí se debe configurar las respectivas llaves privadas del usuario para hacer la conexión con el api de Pagando"
                },
                "step-six": {
                    "title": "Visualización del método de pago",
                    "subtitle": "Visualización del método de pago",
                    "instruction": "Así es como se verá en la página de procesamiento de Pago (puede variar dependiendo del tema de wordpress)",
                }
            },
        },
        "magento": {
            "instructions": {
                "step-one": {
                    "title": "Instalación de módulo",
                    "subtitle": "Instalación del Módulo Pagando Payment",
                    "instruction": `Dentro de tu panel de administrador en Magento, dirígete al apartado de <strong>STORES</strong>. Una véz que se abrá el menù, damos clic en <strong>Configuration</strong>.`
                },
                "step-two": {
                    "title": "Configuración de módulo",
                    "subtitle": "Configuración del Módulo Pagando Payment",
                    "instruction": `Una véz abierta la sección de <strong>Configuration</strong>, en el menú lateral damos clic en <strong>SALES</strong> y se abrirá un submenú y damos clic en la opción de <strong>Payment methods</strong>.`
                },
                "step-three": {
                    "title": "Uso de credenciales",
                    "subtitle": "Uso de credenciales dentro del módulo",
                    "instruction": `Al momento que damos clic en <strong>Payment methods</strong> se mostrarán todas las opciones disponibles de métodos de pago, buscamos la opcion llamada <strong>Pagando Check</strong> y hacemos clic sobre ella, para que nuestro método sea visible en el campo <strong>Enabled</strong> seleccionamos la opción <strong>Yes</strong> y rellenamos los campos de <strong>User</strong> y <strong>Public key</strong> con las credenciales que hemos obtenido. Adicional a esto, el unico país disponible para nuestro método de pago es México.`
                },
                "step-four": {
                    "title": "Visualización del método de pago",
                    "subtitle": "Visualización del método de pago",
                    "instruction": `Después de finalizar con la configuración podrá visualizar su nuevo método de pago en su carrito de compras.`,
                },
                "step-five": {
                    "title": "Verificación de instalación",
                    "subtitle": "Verificación de instalación",
                    "instruction": `Para poder corroborar que la instalación y la configuración de su módulo se han realizado de forma correcta, realize un pago en su plataforma utilizando los datos de tarjeta que se muestran en la siguiente sección de esta página.`,
                },
            }
        }
    },
    "cards": {
        "preview": {
            "name": "NOMBRE(S) Y APELLIDOS",
            "date": "MM/AA"
        }
    },
    "collaborators.title": "Colaboradores",
    "collaborators.msg-small": "Panel de Control",
    "collaborators.card.title": "Lista de roles",
    "collaborators.card.subtitle": "Asigna uno o más roles a este colaborador para que pueda realizar las actividades correspondientes a sus permisos dentro de la plataforma.",
    "collaborators.collaborators": "Colaborador | Colaboradores",
    "collaborators.empty-state.title": "No se encontraron resultados con la busqueda",
    "collaborators.modal.delete.title": "¿Deseas eliminar este colaborador?",
    "collaborators.modal.delete.text": "Al hacer clic en “Eliminar Colaborador” este usuario perderá todos sus permisos y será necesario darlo de alta desde cero si se desea integrarlo de nuevo.",
    "collaborators.modal.delete.button": "ELIMINAR COLABORADOR",
    "collaborators.modal.add-collaborator.title": "Nuevo colaborador",
    "collaborators.modal.add-collaborator.subtitle": "Invita a uno o más de tus compañeros a ser colaboradores de Pagando. Después asígnales un rol con sus permisos correspondientes.",
    "collaborators.modal.add-collaborator.button": "invitar",
    "collaborators.modal.add-collaborator.placeholder": "Ingresa uno o varios correos separados por comas",
    "collaborators.button.cancel": "Cancelar",
    "collaborators.button.save": "Guardar",
    "collaborators.store.delete-collaborator.error": "Ha ocurrido un error inesperado al intentar eliminar el colaborador. Intentalo de nuevo.",
    "chargebacks": {
        "return": "Regresar",
        "list": {
            "control-panel": "Panel de Control",
            "chargebacks": "Contracargos",
            "loading": "Cargando...",
            "active": "Activos",
            "inactive": "Inactivos",
        },
        "table": {
            "searching-chargeback": "Buscando contracargos",
            "number": "Folio",
            "search-placeholder": "Buscar...",
            "payment-day": "Fecha de Pago",
            "total": "Monto",
            "client": "Cliente",
            "status": "Estatus",
            "notification-date": "Fecha de notificación",
            "remaining-time": "Tiempo Restante",
            "actions": "Acciones",
            "delete": "eliminar",
            "cancel": "cancelar",
            "see-details": "Ver",
            "upload": "Subir Documentos",
            "give": "Otorgar"
        },
        "pagination": {
            "title": "Contracargo | Contracargos",
            "ACTIVE": "activo | activos",
            "INACTIVE": "inactivo | inactivos",
        },
        "badge": {
            "REVISION": "En revisión",
            "APPLIED": "Activo",
            "WIN": "Ganado",
            "LOST": "Perdido",
            "NOT_PROCEED": "Retirado"
        },
        "show": {
            "return": "Regresar a Contracargos",
            "see": "Ver Contracargo",
            "description-active": "Este contracargo esta activo, tienes un límite de 36 horas a partir de la fecha de notificación para presentar evidencia de la venta y entrega del servicio al cliente. Puedes hacerlo en el panel de evidencia.",
            "description-revision-desc": "Este contracargo esta en revisión, si tuviste problemas con las evidencias o consideras que hay algo más que puedas hacer para ayudar en tu dictaminación",
            "description-revision-link": "contácta nuestro servicio de soporte haciendo clic aquí.",
            "description-inactive-desc": "Este contracargo esta inactivo, no puedes editar su información, reenviar documentos ni añadir observaciones.",
            "description-inactive-link": "Si crees que la dictaminación fué incorrecta contácta nuestro servicio de soporte haciendo clic aquí.",
            "status": "Estatus",
            "remaining-time": "Tiempo Restante",
            "email-evidence": "Correo de evidencia",
            "original-charge": "Cargo original",
            "payment-number": "Folio de pago",
            "user": "Usuario",
            "date": "Fecha",
            "quantity": "Cantidad",
            "see-detail": "Ver detalle",
            "reason-notification": "Motivo y notificación",
            "reason": "Motivo",
            "notification-date": "Fecha de notificación",
            "evidence": "Evidencias",
            "evidence-description": "Sube los documentos que se te piden para impugnar el contracargo.",
            "evidence-empty": "No se entregaron evidencias para este contracargo",
            "evidence-date": "Documentos entregados el día: ",
            "label-divider": "Requeridos ({documentsLoaded} de {numOfDocuments})",
            "email": "Correo electrónico",
            "phone": "Teléfono",
            "payment-type": "Tipo de pago",
            "curp": "CURP",
            "additional-notes": "Notas adicionales",
            "card-type": "Tipo de tarjeta",
            "subtotal": "Subtotal",
            "discounts": "Descuentos",
            "total": "Total",
            "bank-commision": "Comisión bancaria",
            "amount-to-user": "TOTAL A DEPOSITAR",
            "number": "Folio",
            "close": "CERRAR",
            "P": "Pago Regular",
            "D": "Pago con Requisitos",
            "EP": "Pago Rápido",
            "WS": "Servicio Web",
            "debit": "Débito",
            "credit": "Crédito",
            "observation-title": "Observaciones",
            "observation-subtitle": "Aquí puedes añadir observaciones a considerar con el procesador de pagos",
            "observation-placeholder": "Escribe una observación para enviar junto con las evidencias del servicio.",
            "reply-chargeback": "Contestar Contracargo",
            "label-win": "El contracargo se ha dictaminado a tu favor",
            "label-lost": "El contracargo se ha dictaminado en tu contra",

        },
        "empty-state-title": "Listado de contracargos",
        "empty-state-desc": "No hay registro de contracargos asignados a tu organización",
    },
    "layout": {
        "organization-status-alert": {
            "coming-soon": "¡Bienvenido, {userName}! Nuestro equipo se está encargando de revisar que toda la documentación de tu negocio esté en orden. Este mensaje se desaparecerá una vez que se haya completado la verificación. ",
            "info-request": "Hemos encontrado que faltan un par de datos en el expediente de tu negocio para completar la verificación dentro de Pagando. ",
            "link": " Da clic aquí para recibir más información."
        },
        "organization-activation-message": {
            "COMING_SOON": "Nuestro equipo se está encargando de revisar que toda la documentación de tu negocio esté en orden.",
            "REVIEW": "Nuestro equipo se está encargando de revisar que toda la documentación de tu negocio esté en orden.",
            "CREATED": "Nuestro equipo se está encargando de revisar que toda la documentación de tu negocio esté en orden.",
            "INFO_REQUEST": "Para que completes el proceso de verificación de tu empresa, te pedimos que actualices la información que te solicitamos a continuación.",
            "ACTIVE": "¡Felicidades! Terminaste de configurar al 100% tu Organización. Ahora estás totalmente listo para poder iniciar tus ventas por Pagando.",
            "REJECTED": "",
            "comment": "Comentarios generales:",
            "link-edit": "Edita este campo dando",
            "link-contact": "Para editar este campo contacta a soporte dando",
            "link-click": "clic aquí.",
            "accept": "Aceptar y cerrar panel",
            "submit": "Enviar a revisión",
            "undefined": "",
        },
        "organization-services-count": "No cuenta con ningún Servicio | {count} Servicio | {count} Servicios",
        "chart": {
            "label": "Elementos del expediente",
            "label-complete": "<strong>¡FELICIDADES!</strong><label>Activación <br> finalizada</label>",
        },
        "organization-status": {
            "COMING_SOON": "Activación de empresa",
            "REVIEW": "Activación de empresa",
            "INFO_REQUEST": "Solicitamos más datos",
            "ACTIVE": "Activación de emresa finalizada",
            "REJECTED": "Organización rechazada",
            "CREATED": "Activación de empresa",
            "undefined": "",
        },
        "files-modal": {
            "title": "Archivos Solicitados"
        }
    },
    "organization-status": {
        "COMING_SOON": "Por implementar",
        "CREATED": "Por implementar",
        "REVIEW": "En revisión",
        "INFO_REQUEST": "Solicitamos más datos",
        "ACTIVE": "Organización activa",
        "REJECTED": "Organización rechazada",
    },
    "chart-progress-info": {
        "count": "<strong>{progress}</strong> de <strong>{doneProgress}</strong><label>{label}</label>"
    },
    "organization": {
        "sections": {
            "IMAGES": "Imágenes",
            "BANK": "Datos Bancarios",
            "GENERAL": "Información General",
            "CONTACT": "Datos de Contacto",
            "NOTIFICATION": "Notificaciones",
            "BILLING": "Facturación",
            "FISCAL": "Datos Fiscales",
            "PAYMENTS_CONFIG": "Configuración de Pagos",
            "WEBHOOK": "Webhooks",
            "DOCUMENTS": "Documentos",
            "LOCATION": "Localización"
        },
        "fields": {
            "GENERAL": {
                "name": "Nombre",
                "category": "Categoría",
                "description": "Descripción",
            },
            "CONTACT": {
                "address": "Dirección",
                "email": "Correo electrónico",
                "phone": "Teléfono",
                "organizationPhone": "Teléfono de contacto",
            },
            "IMAGES": {
                "logo": "Logotipo",
                "cover": "Portada",
            },
            "FISCAL": {
                "fiscalSituation": "Situación fiscal",
                "legalName": "Razón social",
                "rfc": "RFC",
                "streetAddress": "Calle",
                "exteriorNumber": "Número exterior",
                "interiorNumber": "Número interior",
                "district": "Colonia",
                "state": "Estado",
                "city": "Ciudad",
                "zipCode": "Código postal",
                "curp": "CURP",
                "electronicSignatureSerialNumber": "No. de Serie de la Firma Electrónica Avanzada",
                "constitutionDate": "Fecha de constitución",
                "nationality": "Nacionalidad",
                "giro": "Giro",
            },
            "BANK": {
                "bankName": "Banco",
                "accountNumber": "Número de cuenta",
                "clabe": "CLABE interbancaria",
            }
        }
    },
    "step-location": {
        "title": "Ubicación",
        "subtitle": "Ayúdanos a hacer más visible tu organización para tus clientes ingresando tu dirección o ubicándola en el mapa.",
        "additional-notes": "Notas adicionales",
        "placeholder": "Puedes agregar una nota adicional que ayude a encontrar tu negocio.",
        "tooltip": "Agrega indicaciones para identificar facilmente la ubicación de tu organización"
    },
    "refunds": {
        "start": "Hacer devolución",
        "is-refunded": "Devolución realizada",
        "subtitle": "Para realizar una devolución por esta compra es necesario que ingreses el motivo y el monto a devolver. Recuerda no ingresar un monto mayor al monto pagado por el cliente.",
        "reason": "Motivo de la devolución",
        "reason-placeholder": "Selecciona un motivo...",
        "amount": "Monto",
        "amount-placeholder": "$0.00",
        "type": {
            "FULL": "<strong>Devolución total:</strong> Devuelve el monto total al comprador",
            "PARTIAL": "<strong>Devolución parcial:</strong> Devuelve al comprador solo una parte del monto total"
        }
    },
    "terminals": {
        "title-sm": "Panel de control",
        "title": "Terminales",
        "terminals": "Terminal | Terminales",
        "empty-list": "¡Ups! Parece que aún no tienes ninguna terminal.",
        "loading": "Cargando terminales...",
        "serialNo": " Número de serie",
        "brand": "Marca",
        "softwareVersion": "Versión del Software",
        "error-loading": "Ocurrió un error al cargar la lista de terminales",
    },
    "paymentLinks": {
        "title": "Check Links",
        "subtitle": "Tipo de pago",
        "createTitle": "Crea tu primer Check Link",
        "createSubtitle": "Genera Check Links de manera fácil y rápida, y vende desde cualquier lugar, no es necesario que tengas una pagina web, tan solo tienes que compartir el link a tus clientes por teléfono o email.",
        "newLink": "NUEVO CHECK LINK",
        "searching": "Buscando Check Links...",
        "emptyTile": "No se encontraron Check Links",
        "emptySubtitle": "No se encontraron Check Links con los parámetros especificados",
        "restartFilter": "Reiniciar filtros",
        "fields": {
            "folio": "Folio",
            "date": "Fecha",
            "concept": "Concepto",
            "emailClient": "Correo del cliente",
            "import": "Importe",
            "card": "Tarjeta",
            "conceptTitle": "Concepto de pago o título de tu servicio/producto",
            "conceptPlaceholder": "Escribe el nombre como aparecerá en la app",
            "availability": "Disponibilidad",
            "amount": "Monto",
            "amountTitle": "Precio del producto o servicio",
            "status": "Estatus",
            "actions": "Acciones",
            "paymentType": "Tipo de Pago",
            "selectPaymentType": "Selecciona el tipo de pago",
            "addPhoto": "Agregar foto (Opcional)",
            "uploadImage": "Subir imagen",
            "imageType": "Puedes subir imagenes tipo: JPG o PNG.",
            "expDateTitle": "Fecha de expiración",
            "expDateSubtitle": "Configura la disponibilidad de tu Check Link, puedes configurar un link con fecha de expiracion, o un link que este siempre vigente.",
            "startDate": "Fecha de inicio",
            "endDate": "Fecha de fin o vencimiento"
        },
        "paymentMethodTitle": "Metodos de pago que quieres ofrecer a tus clientes",
        "paymentMethodSubtitle": "Selecciona los medios de pago disponibles que deseas activar para que el cliente realice el pago.",
        "creditDebitCard": "Tarjeta de débito o crédito",
        "creditDebitCardSubtitle": "El cliente realizara su pago mediante tarjeta de débito o crédito. El pago se acredita inmediatamente.",
        "promosAvailables": "Promociones disponibles",
        "promosAvailablesSelected": "Promociones disponibles seleccionadas",
        "promosAvailablesWarning": "Esta compra cuenta con promociones para realizar el pago",
        "selectPromo": "Selecciona una promoción",
        "pagandoAccount": "Cuenta Pagando",
        "pagandoAccountSubtitle": "El cliente iniciara sesión en su cuenta Pagando y realizara el pago de forma segura con alguna de sus cuentas. El pago se acredita inmediatamente.",
        "spei": "Transferencia electrónica",
        "speiSubtitle": "Al cliente se le proporciona una CLABE y una referencia para que realice una transferencia desde el banco de su preferencia. El pago puede tardar unas horas en reflejarse.",
        "promos": {
            "unique": {
                "title": "Pago único",
                "subtitle": "Es un pago que solo se puede realizar una vez"
            },
            "multiple": {
                "title": "Pago multiple",
                "subtitle": "Es un pago que permite realizar multiples pagos"
            }
        },
        "share": "Compartir",
        "delete": "Eliminar",
        "shareSave": "Guardar y compartir",
        "edit": "Editar",
        "cancel": "Cancelar",
        "seeDetail": "Ver detalle",
        "links": "Check Link | Check Links",
        "deleteTitle": "¿Deseas eliminar los Check Links seleccionados?",
        "deleteSubtitle": "Al eliminar los lins ya no podras seguir compartiendolo más y dejaras de recibir pagos desde los links.",
        "deleteLinksButton": "Eliminar links",
        "deleteTitle2": "¿Deseas eliminar este Check Link?",
        "deleteSubtitle2": "Al eliminar el link “{concept}”, ya no podras seguir compartiendolo más y dejaras de recibir pagos desde este link.",
        "deleteLinkButton": "Eliminar link",
        "shareTitle": "Comparte tu Check Link",
        "shareSubtitle": "Puedes compartir tu Check Link mediante un mensaje SMS o por correo electrónico.",
        "shareButton": "ENVIAR CHECK LINK",
        "copy": "Copiar",
        "add": "Agregar",
        "active": "Activar",
        "editTitle": "Editar Check Link",
        "new": "Nuevo Check Link",
        "createTitle2": "Unos pocos datos para crear tu Check Link",
        "createSubtitle2": "Configura tu Check Link llenando los siguientes datos.",
        "status": {
            "active": "Activo",
            "deactive": "Por activar",
            "paid": "Pagado",
            "expired": "Expirado"
        },
        "msi": "meses sin intereses",
        "fixPay": "pagos fijos",
        "moreOptions": "Opciones avanzadas",
        "return": "Regresar al listado de Check Links",
        "created": "Check Link creado",
        "shareTitle2": "Comparte tu link para aceptar pagos",
        "paymentType": "Tipo de pago:",
        "availabilityDate": "Fecha de disponibilidad:",
        "undefined": "Indefinido",
        "notFoundPay": "Aun no se han realizado cobros con este link",
        "shareToPay": "Compartelo para recibir tu pago",
        "goToList": "Ir al listado",
        "createNewLink": "Crear nuevo Check Link",
        "selectedDocuments": "Documentos seleccionados",
        "errorMessages": {
            "deleteLinks": "Ocurrió un error al eliminar Check Links",
            "getLinks": "Ocurrió un error al obtener Check Links",
            "active": "Ocurrió un error al activar link",
            "deactive": "Ocurrió un error al desactivar link",
            "share": "Ocurrió un error al compartir link",
            "deleteLink": "Ocurrió un error al eliminar link",
            "phone": "Formato de número de celular no válido",
            "email": "Formato de correo electrónico no válido",
            "copy": "Ocurrió un error al copiar el link",
            "promos": "Ocurrió un error al obtener las promociones disponibles",
            "save": "Ocurrió un error al guardar Check Link",
            "image": "Ocurrió un error al subir la imagen",
            "get": "Ocurrió un error al obtener Check Link",
            "payments": "Ocurrió un error al obtener los pagos"
        },
        "successMessages": {
            "deleteLinks": "Check Links eliminados exitosamente",
            "active": "Link activado exitosamente",
            "deactive": "Link desactivado exitosamente",
            "share": "Link compartido exitosamente",
            "deleteLink": "Link eliminado exitosamente",
            "copy": "Link copiado exitosamente",
            "save": "Check Link guardado exitosamente",
            "image": "Imagen subida exitosamente"
        }
    },
    "recovery-email": {
        "return-to-login": "Regresar a Iniciar sesión",
        "title": "¡La ayuda ",
        "title-strong": "va en camino!" ,
        "check-your-email1": "Revisa tu bandeja de entrada de tu correo ",
        "check-your-email2": " y sigue las instrucciones para recupear tu contraseña.",
        "resend-email": "Reenviar Correo electrónico",
        "resend-email-instructions": "El correo electrónico puede tardar unos minutos en llegar, ",
        "resend-email-instructions-strong": `en caso de no recibirlo haz clic en "Reenviar correo".`
    },
    "recovery-password": {
        "return-login": "Regresar a Iniciar sesión",
        "title": "Recupera tu ",
        "title-strong": "contraseña",
        "instructions": "Ingresa el correo electrónico con el cual creaste tu cuenta y te enviaremos un correo para que puedas recuperar tu contraseña.",
        "confirmation": "Por favor confirma que no eres un robot, para continuar.",
        "email-label": "Correo electrónico",
        "email-placeholder": "Ej. pablo@correo.com",
        "recover-password-button": "Recuperar contraseña",
    },
    "recovery-password-success": {
        "return-login": "Regresar a Iniciar sesión",
        "title": "¡Ya puedes usar tu ",
        "title-strong": "cuenta de nuevo!",
        "subtitle": "A partir de ahora puedes entrar a pagando usando tu nueva contraseña.",
        "return-login-button": "Regresar a inicio de sesión"
    },
    "new-password": {
        "return-login": "Regresar a Iniciar sesión",
        "title": "Restablece tu ",
        "title-strong": "contraseña",
        "subtitle": "¡Último paso para poder regresar a Pagando! Ingresa una nueva contraseña para recuperar tu cuenta.",
        "not-same-passwords": "Las contraseñas no coinciden, por favor verifica que las contraseñas sean iguales y contengan mínimo 6 caracteres.",
        "password-label": "Contraseña",
        "password-placeholder": "Ingresa tu contraseña",
        "password-conditions": `Tu contraseña debe tener al menos 6 caracteres con una combinación entre letras, números y símbolos. Evita usar "contraseña", "123456" o "acbdef".`,
        "confirm-password-label": "Confirma tu contraseña",
        "restore-password-button": "REESTABLECER CONTRASEÑA"
    },
    "user-recovery": {
        "return-login": "Regresar a Iniciar Sesión",
        "title": "Recupera tu ",
        "title-strong": "usuario",
        "instructions": "Ingresa el número telefónico con el cual creaste tu \n cuenta. Te enviaremos un mensaje SMS para \n recuperar tu correo electrónico",
        "recover-button": "Recuperar Cuenta",
        "take-a-while": "El mensaje puede tardar unos minutos en llegar",
        "resend": "Haz clic aquí para reenviarlo",
        "sended-sucess": "MENSAJE ENVIADO CORRECTAMENTE",
        "warning-attempts": "Has reenviado 3 veces el mensaje. Si aún no lo has recibido verifica que el número sea correcto."
    },
    "step-four": {
        "number-phone": "Número de celular",
        "number-phone-placeholder": "Ej. (000) 000 00 00"
    },
    "hire-schema": {
        "PRESENT_CARD": "Tarjeta Presente",
        "NON_PRESENT_CARD": "Tarjeta no Presente",
        "BOTH": "Ambas"
    },
    hire,
    statements,
}
