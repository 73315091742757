import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'debt';

export default {
    getPaymentsForDebt: ({ _id, page, perPage = 10, search = '', match = {}, sort = null, paid = null }, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments/${_id}/list`, {page, perPage, search, match, sort, paid})
            .then(onSuccess)
            .catch(onError);
    },
    createDebtPayment: (debt, data, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments/${debt._id || debt}/create`, data)
            .then(onSuccess)
            .catch(onError);
    },
    copyDebtPayment: (debtPayment, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments/copy/${debtPayment._id || debtPayment}`)
            .then(onSuccess)
            .catch(onError);
    },
    deleteDebtPayment: (debt, ids, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments/${debt._id || debt}/delete`, {_id: ids})
            .then(onSuccess)
            .catch(onError);
    },
    share: (debt, emails, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/payments/${debt._id || debt}/share`, {emails})
            .then(onSuccess)
            .catch(onError);
    }
}
