<template>
    <a :href="idScroll" @scroll="handleScroll" class="btn-scroll-top" :class="clazz" tabindex="">
        <i aria-hidden="true" class="banana banana-chevron-up"></i>
    </a>
</template>

<script>
    export default {
        name: 'ButtonScrollTop',
        props: {
            clazz: String,
            idScroll: String
        },
        data () {
          return {
            window: {
              width: 0
            }
          }
        },
        mounted() {
          // Always watch window scroll
          window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
          // Always remove window watch scroll
          window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
          // Show/hide button scroll top 'btn-scroll-top'
          handleScroll () {
            const header = document.querySelector(".btn-scroll-top");
            if (window.scrollY > 70 && !header.className.includes('show')) {
            header.classList.add('show');
            } else if (window.scrollY < 70) {
              header.classList.remove('show');
            }
          }
        }
    }
</script>


<style lang="scss">
//BUTTON SCROLL TOP ADMIN
.btn-scroll-top {
  position: fixed;
  right: 30px;
  bottom: 80px;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
  z-index: 2000;
  opacity: 0;
  transition: 0.5s;

  &:hover {
    animation: rubberBand 1s ease-in-out;
  }

  &.show {
    opacity: 1;
  }
}
</style>
