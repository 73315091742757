<template>
    <div class="col-12">
        <div class="w-100 m-t-30">
            <UploadBox :titleBox="$t('organizaciones.images.cover.label') + ':'"
                       clazzContainer="box-portada-container m-b-0"
                       :showLoadBox="!hasCover"
                       fileType="no-img-back" @change="coverUploaded($event)">
                <template v-slot:imagePreview>
                    <div class="img-card" v-if="hasCover">
                        <img class="img-fluid portada" :src="imageToDisplay" alt="Logotipo"/>
                    </div>
                </template>

                <template v-slot:button>
                    <div class="col-12">
                        <ButtonModalOpen
                                id="cropopener"
                                _class="button xs button-stroke button-accent2 w-100"
                                :text="$t('organizaciones.images.cover.input-place-holder')"
                                modal-id="coverpage"
                                :icon="'banana banana-image'"
                                v-if="!readOnly"
                                @click="openCover()"
                        >
                        </ButtonModalOpen>
                    </div>
                </template>
            </UploadBox>
        </div>

        <ModalDefault modalId="modal-selectPortada" :customRef="'coverpage'" icon="banana-image" :originalFooter="false"
                      size="xl" :title="$t('organizaciones.images.cover.modal.title')"
                      :subtitle="$t('organizaciones.images.cover.modal.subtitle')">
            <PortadaSelectPreview
                    :store-module="storeModule"
                    :options="getCoverOptions ? getCoverOptions.options : []"
                    src-prop-name="fullUrl"
                    id-prop-name="_id"
                    :select-text="$t('general.actions.select.title')"
                    :selected-text="$t('organizaciones.images.cover.modal.select.cover')"
                    @select="onSelect($event)"
                    :search-placeholder="$t('organizaciones.images.cover.modal.select.search-image')"
                    @search="onSearch($event)"
                    :temporalCoverUser="getTemporalCover.authorNameThirdPartyCover"
                    :temporalCoverSrc="getTemporalCover.thirdPartyCoverUrl"
                    :loading="loadingCoverPhoto"
                    :percentage="loadingCoverPhotoPercentage"
                    :namePhoto="loadingCoverPhotoName"
                    :sizePhoto="loadingCoverPhotoSize"
            >
            </PortadaSelectPreview>
            <template v-slot:customFooter>
                <FileUploadButton
                        id="modal_upload_cover"
                        :text="$t('organizaciones.images.cover.modal.upload.image')"
                        _ref="uploadCoverRef"
                        :_class="'button xs button-stroke button-accent1 m-0-auto-0-0'"
                        :accept="FILES.MIME_TYPES.ACCEPT_IMAGE"
                        @change="coverUploaded($event)"
                        :shape="BUTTONS.SHAPES.BUTTON"
                        :kind="BUTTONS.KINDS.STROKE"
                        :color="BUTTONS.COLORS.ACCENT1"
                        :size="BUTTONS.SIZES.EXTRA_SMALL"
                        :isCoverUpload="true"
                        @click="coverSuccessUpload=false"
                >
                </FileUploadButton>
                <button id="btn-cancelCover" class="button xs button-ghost button-plain_text m-r-20" type="button"
                        @click="closeModal('coverpage')"> {{ $t('general.actions.cancel.title') }}
                </button>
                <button id="btn-selectCover" class="button xs button-filled button-accent1 m-r-20" type="button"
                        @click="onSave()"> {{ $t('general.actions.select.title') }}
                </button>
            </template>
        </ModalDefault>

        <ModalDefault :modalId="modalCropImageId" :customRef="modalCropImageId" icon="banana-move"
                      :originalFooter="false" size="lg" :title="$t('organizaciones.images.cover.modal.adjust.title')"
                      :subtitle="$t('organizaciones.images.cover.modal.adjust.subtitle')">
            <div v-if="savingCropped">
                <LoadingSimple clazz="big-load" label="Recortado imagen"></LoadingSimple>
            </div>
            <div v-else>
                <CropImage clazz="container-lg portada m-b-0" :ref="imageCroppieRef" @crop="croppieResult"></CropImage>
            </div>
            <template v-slot:customFooter>
                <BaseButton
                        type="button"
                        id="modal_crop_image_cancel"
                        _class="m-0-auto-0-0"
                        divClass="m-0-auto-0-0"
                        :shape="BUTTONS.SHAPES.BUTTON"
                        :kind="BUTTONS.KINDS.STROKE"
                        :color="BUTTONS.COLORS.ACCENT1"
                        :size="BUTTONS.SIZES.EXTRA_SMALL"
                        :text="$t('organizaciones.images.cover.modal.select.new-image')"
                        v-if="!savingCropped"
                        @click="returnToSelector()"
                >
                </BaseButton>
                <BaseButton
                        type="button"
                        id="modal_crop_image_cancel"
                        _class="m-r-20"
                        :shape="BUTTONS.SHAPES.BUTTON"
                        :kind="BUTTONS.KINDS.GHOST"
                        :color="BUTTONS.COLORS.PLAIN_TEXT"
                        :size="BUTTONS.SIZES.EXTRA_SMALL"
                        v-if="!savingCropped"
                        :text="$t('general.actions.cancel.title')"
                        @click="closeModal(modalCropImageId)"
                >
                </BaseButton>
                <BaseButton
                        type="button"
                        id="modal_crop_image_save"
                        :shape="BUTTONS.SHAPES.BUTTON"
                        :kind="BUTTONS.KINDS.FILLED"
                        :color="BUTTONS.COLORS.ACCENT1"
                        :size="BUTTONS.SIZES.EXTRA_SMALL"
                        :text="$t('general.actions.save')"
                        @click="cropImage"
                        v-if="!savingCropped"
                >
                </BaseButton>
            </template>
        </ModalDefault>
    </div>
</template>

<script>
    import ButtonModalOpen from '@/components/signUpOrganizations/buttons/ButtonModalOpen';
    import UploadBox from '@/components/UploadBox.vue';
    import LoadingSimple from '@/components/LoadingSimple.vue';
    import CropImage from '@/components/CropImage.vue';
    import PortadaSelectPreview from '@/components/PortadaSelectPreview.vue';
    import ModalDefault from '@/components/ModalDefault.vue';
    import BaseButton from '@/components/signUpOrganizations/base/BaseButton.vue';
    import FileUploadButton from '@/components/signUpOrganizations/file/FileUploadButton.vue';
    import FILES from '@/common/consts/files.consts';
    import ICONS from '@/common/consts/icons.consts';
    import BUTTONS from '@/common/consts/buttons.consts';
    import {mapGetters, mapState} from 'vuex';
    import {EventBus} from "@/main";
    import ToastSnotify from '@/mixins/toastSnotify.mixin';

    const storeModule = 'signUpOrganization';
    const imageCroppieRef = "image-croppie-portada";
    const async = require('async');
    const imageClassification = "organization/cover";

    export default {
        name: "UploadCover",
        data() {
            return {
                storeModule: storeModule,
                FILES,
                ICONS,
                BUTTONS,
                modalCropImageId: "modal_crop_portada",
                modalCropImageImgId: "modal_crop_portada_img",
                coverUrl: "",
                loadingCoverOptions: true,
                coverMinWidth: 1280,
                coverMinHeight: 400,
                coverMaxSize: 300000,
                loadingSubmit: false,
                coverFile: null,
                imageCroppieRef: imageCroppieRef,
                imageClassification: imageClassification,
                imageNamePrefix: "cover",
                savingCropped: false,
                coverImage: {
                    name: "",
                    size: "",
                    image: "",
                    type: "",
                },
                temporalCoverSelected: null,
                loadingCoverPhoto: false,
                loadingCoverPhotoPercentage: 0,
                loadingCoverPhotoName: "",
                loadingCoverPhotoSize: "0kb",
                uploadFailed: false,
                loadingCropingCover: false
            }
        },
        components: {
            FileUploadButton,
            ButtonModalOpen,
            UploadBox,
            ModalDefault,
            PortadaSelectPreview,
            BaseButton,
            CropImage,
            LoadingSimple,
        },
        props: {
            useVuex: {type: Boolean, default: true},
            organization: {},
            urlImagePreview: {type: String, default: null},
            readOnly: {type: Boolean, default: false}
        },
        mixins: [
            ToastSnotify
        ],
        computed: {
            hasCustomCover() {
                return this.$store.getters[`${this.storeModule}/hasCustomCover`];
            },
            hasCover() {
                return !!this.urlImagePreview || ((this.getPotentialOrganization.cover !== undefined) ? this.getPotentialOrganization.cover.fullSize : false);
            },
            hasCoverOptions() {
                return this.$store.getters[`${this.storeModule}/getHasCoverOptions`];
            },
            coverNote() {
                return this.hasCustomCover ? 'sign-up.organizations.cover.note.custom-cover' : 'sign-up.organizations.cover.note.default-cover'
            },
            ...mapState({
                cover: function (state) {
                    return state[this.storeModule].actualPotentialOrganization.cover;
                }
            }),
            ...mapGetters(storeModule, [
                'getPotentialOrganization',
                'getCoverOptions',
                'getTemporalCover',
                'coverImg'
            ]),
            imageToDisplay() {
                return this.urlImagePreview || this.coverImg;
            }
        },
        mounted() {
            setTimeout(() => {
                if (!this.hasCoverOptions) {
                    this.loadImageOptions(this.organization, null);
                }
            }, 300);
        },
        methods: {
            onSelect(option) {
                this.$store.commit(`${this.storeModule}/SET_TEMPORAL_THIRD_PARTY_COVER_URL`, option.option.fullUrl);
                this.temporalCoverSelected = option.option;
                setTimeout(() => {
                    EventBus.$emit('SET-IS-LOADING-PREVIEW-IMAGE', false)
                }, 500);
            },
            coverSelected(option) {
                fetch(option.fullUrl, {
                    cache: 'no-cache'
                })
                    .then(res => {
                        res.blob().then(blob => {
                            let coverFile = null
                            const coverUrl = URL.createObjectURL(blob);
                            const img = new Image();
                            const _this = this;
                            img.onload = () => {
                                coverFile = blob;
                                _this.coverImage.size = coverFile.size;
                                _this.coverImage.type = coverFile.type;
                                _this.coverImage.name = `${this.imageNamePrefix}-${Math.floor(Math.random() * 100000)}`;
                                _this.coverImage.classification = this.imageClassification;
                                _this.coverFile = coverFile;
                                _this.coverFile.name = _this.coverFile.name ? _this.coverFile.name : _this.coverImage.name;
                                _this.coverUrl = coverUrl;

                                _this.initCropImage(coverUrl);
                            };
                            img.src = coverUrl;
                        })
                    });
            },
            onSearch(search) {
                this.loadImageOptions(null, search);
            },
            onSave() {
                this.coverSelected(this.temporalCoverSelected);
            },
            loadImageOptions(organization, search) {
                const _this = this;
                this.$store.dispatch(`${this.storeModule}/getCoverImageOptions`, {
                    organization: organization,
                    search: search
                }).then(() => {
                    _this.loadingCoverOptions = false;
                });
            },
            openCover() {
                EventBus.$emit(`MODAL-OPEN-coverpage`)
            },
            restoreCoverBackup() {
                this.$store.commit(`${this.storeModule}/SET_COVER`, this.coverBackup);
            },
            // eslint-disable-next-line sonarjs/cognitive-complexity
            coverUploaded(files) {
                if (!files || !files[0]) {
                    return;
                }
                this.$store.dispatch(`${this.storeModule}/uploadingCoverImg`, true);

                const coverFile = files[0];
                this.loadingCoverPhotoName = coverFile.name;
                this.loadingCoverPhotoSize = `${coverFile.size / 1000} KB`;
                const coverUrl = URL.createObjectURL(coverFile);

                const img = new Image();
                const _this = this;

                img.onload = () => {
                    const fileType = coverFile.type;

                    this.loadingCoverPhoto = true;
                    setTimeout(() => {
                        this.loadingCoverPhotoPercentage = 25;
                        setTimeout(() => {
                            if (!FILES.MIME_TYPES.IMAGE.includes(fileType)) {
                                this.loadingCoverPhoto = false;
                                this.uploadFailed = true;
                                this.displayNotificationWarning(this.$i18n.t('signup-organization.step-three.format-image-not-accepted'));
                                return;
                            }
                            this.loadingCoverPhotoPercentage = 30;
                            setTimeout(() => {
                                _this.coverImage.size = coverFile.size;
                                _this.coverImage.type = coverFile.type;

                                _this.coverImage.name = `${this.imageNamePrefix}-${Math.floor(Math.random() * 100000)}`;
                                _this.coverImage.classification = this.imageClassification;

                                _this.coverFile = coverFile;
                                if (!_this.coverFile.name) {
                                    _this.coverFile.name = _this.coverImage.name;
                                }
                                _this.coverUrl = coverUrl;
                                this.loadingCoverPhotoPercentage = 60;
                                setTimeout(() => {
                                    if (this.loadingCoverPhotoName.length > 80) {
                                        this.loadingCoverPhoto = false;
                                        this.uploadFailed = true;
                                        this.displayNotificationWarning(this.$i18n.t('signup-organization.step-three.name-to-long'));
                                        return;
                                    }
                                    this.loadingCoverPhotoPercentage = 95;
                                    _this.initCropImage(coverUrl);
                                }, 1300);
                            }, 1100);
                        }, 900);
                    }, 500);
                };
                img.src = coverUrl;
            },
            closeModal(id) {
                EventBus.$emit(`MODAL-CLOSE-${id}`)
            },
            returnToSelector() {
                this.closeModal(this.modalCropImageId)
                EventBus.$emit(`MODAL-OPEN-coverpage`)
            },
            initCropImage(url) {
                this.loadingCoverPhotoPercentage = 100;
                this.loadingCoverPhoto = false;
                EventBus.$emit(`MODAL-OPEN-${this.modalCropImageId}`)
                EventBus.$emit(`MODAL-CLOSE-coverpage`)
                // Esperar a que aparezca el modal para que muestre la referencia del componente CropImage
                setTimeout(() => {
                    this.$refs[imageCroppieRef].initCroppie(url);
                }, 100);
            },
            cropImage() {
                this.savingCropped = true;
                this.$refs[imageCroppieRef].crop();
            },
            croppieResult(result) {
                const _this = this;
                _this.coverImage.image = result;
                _this.imageStatus = true;
                _this.updateStoreAndPrepareToFinish()
            },
            updateStoreAndPrepareToFinish() {
                const _this = this;
                let urlMode = false;
                let coverFullSize;

                const reader = new FileReader();
                const readerCropped = new FileReader();

                if (!_this.coverFile) {
                    urlMode = true;
                    coverFullSize = {};
                    coverFullSize.name = this.logo.fullSize.name;
                    coverFullSize.type = this.logo.fullSize.type;
                    coverFullSize.size = this.logo.fullSize.size;
                } else {
                    coverFullSize = _this.coverFile;
                }

                const coverCropped = new File([_this.coverImage.image], `cropped_${coverFullSize.name}`, {
                    lastModified: new Date().getTime(),
                    type: _this.coverImage.type,
                });

                const tasks = {};

                if (!urlMode) {
                    reader.readAsDataURL(coverFullSize);
                    tasks.fullSize = function (callback) {
                        reader.onload = () => {
                            callback(null, reader.result);
                        };
                    };
                }

                readerCropped.readAsDataURL(coverCropped);
                tasks.cropped = function (callback) {
                    readerCropped.onload = () => {
                        callback(null, readerCropped.result);
                    };
                };

                async.parallel(
                    tasks,
                    function (error, results) {
                        const params = {};
                        if (!urlMode) {
                            params.coverFullSize = {
                                name: coverFullSize.name,
                                type: coverFullSize.type,
                                size: coverFullSize.size,
                                image: results.fullSize,
                            };
                        }
                        params.coverCropped = {
                            name: coverCropped.name,
                            type: coverCropped.type,
                            size: coverCropped.size,
                            image: _this.coverImage.image
                        };
                        if (_this.useVuex) {
                            _this.$store.dispatch(`${storeModule}/stepCoverUpdateCover`, params).then((response) => {
                                setTimeout(() => {
                                    _this.savingCropped = false;
                                    if (response.error) {
                                        _this.displayNotificationWarning(_this.$i18n.t('signup-organization.step-three.cropie-error'));
                                    } else {
                                        _this.displayNotificationSuccess(_this.$i18n.t('signup-organization.step-three.cropie-success'));
                                    }
                                    _this.closeModal(_this.modalCropImageId);
                                }, 1000);
                            }).catch(()=>{
                                _this.displayNotificationWarning(_this.$i18n.t('signup-organization.step-three.cropie-error'));
                                _this.closeModal(_this.modalCropImageId);
                                _this.savingCropped = false;
                            });
                        } else {
                            _this.savingCropped = false;
                            _this.closeModal(_this.modalCropImageId);
                        }
                        _this.$emit('finish', params);
                    });
            },
        },
    }
</script>
