<template>
	<div id="dct-quantity-input" class="quantity-input" :class="clazz">
        <label> {{title}} </label>
        <span>
            <button id="bin-decrement" type="button" class="btn-minus" @click="decrement()"><i aria-hidden="true" class="banana banana-symbol-minus"></i></button>
            <input id="num-quantity" type="number" class="input-cant" :value="quantity" placeholder="0" min="1" />
            <button id="bin-increment" type="button" class="btn-plus" @click="increment()"><i aria-hidden="true" class="banana banana-symbol-plus"></i></button>
        </span>
    </div>
</template>

<script>
	export default {
		name: 'Quantity',
		props: {
            clazz: String,
            title: String,
            value: Number
        },
        data() {
            return {
                quantity: this.value || 1
            }
        },
        methods: {
            increment () {
                this.quantity++;
                this.$emit('input', this.quantity);
            },
            decrement () {
                if (this.quantity > 1) {
                    this.quantity--;
                }
                this.$emit('input', this.quantity);
            },
            mounted() {
                this.quantity = this.value;
            }
        }
	}
</script>

<style lang="scss">
.quantity-input {
    display: flex;
    align-items: center;

    > label {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.25;
        margin: 0 30px 0 0;
    }

    > span {
        display: flex;
        align-items: center;
        width: fit-content;
    }

    .btn-minus,
    .btn-plus {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        border: none;
        margin: 0 5px;
        transition: 0.5s;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            animation: rubberBand 1s ease-in-out;
        }
    }

    .input-cant {
        font-size: 20px;
        font-weight: 900;
        line-height: 1.2;
        border: none;
        text-align: center;
        max-width: 50px;
        position: relative;

        &[type=number]::-webkit-inner-spin-button,
        &[type=number]::-webkit-outer-spin-button {
            display: none;
        }
    }

    &.xs {
        .btn-minus,
        .btn-plus {
            width: 24px;
            height: 24px;
            font-size: 18px;
            margin: 0 5px;
        }

        .input-cant {
            max-width: 40px;
        }
    }

    &.float-left {
        > label {
            margin: 0;
        }

        > span {
            margin: 0 0 0 20px;
        }
    }
}
</style>
