import Vue from "vue";
import Router from "vue-router";
import UxComponents from "@/pages/UxComponents.vue";
import PagandoCheckHireServiceUx from "@/pages/PagandoCheckHireServiceUx.vue";
import PagandoCheckPswUx from "@/pages/PagandoCheckPswUx.vue";
import PagandoCheckProcessingUx from "@/pages/PagandoCheckProcessingUx.vue";
import RegisterWizard from "@/pages/RegisterWizard.vue";
import SignUpOrganizationWizard from "@/pages/SignUpOrganizationWizard.vue";
import LoginRoutes from "@/routes/register/signin.routes";
import DigitalContract from "@/views/DigitalContract.vue"
import LayoutClient from "@/pages/LayoutClient.vue";
import Dashboard from "@/views/Dashboard.vue";
import SigninLayout from "@/views/signin/SigninLayout"
import Payments from '@/views/payments/Payments.vue';
import RegularPayment from '@/views/payments/RegularPayment.vue';
import QuickPayment from '@/views/payments/QuickPayment.vue';
import RequirementsPayment from '@/views/payments/RequirementsPayment.vue';
import WebServicePayment from '@/views/payments/WebServicePayment.vue';
import DebtorsList from '@/views/payments/debtors/DebtorsList.vue';
import Debtors from '@/views/payments/debtors/Debtors.vue';
import CollectedPayment from '@/views/payments/CollectedPayment.vue';
import FinishSignUpOrganization from "@/views/signUpOrganization/FinishWizard.vue";
import DigitalContractClient from "@/views/DigitalContractClient.vue"
import OrganizationInfo from "@/views/OrganizationInfo.vue";
import GeneralPayment from "@/views/services/General.vue";
import RegisterSuccess from "@/views/signUpOrganization/RegisterSuccess.vue";
import FullPageTemplate from "@/pages/FullPageTemplate.vue";
import LocalVerification from "@/views/signUpOrganization/LocalVerification.vue";
import SocialMediaVerification from "@/views/signUpOrganization/SocialMediaVerification.vue";
import abilityBuilder from "@/permissions/abilities";
import TaxReceipts from '@/views/TaxReceipts.vue';
import PaymentTemplate from '@/views/payments/paymentsTemplate/PaymentTemplate.vue';
import DetailPaymentTemplate from '@/views/payments/paymentsTemplate/DetailPaymentTemplate.vue';
import DebtorsPaymentTemplate from '@/views/payments/paymentsTemplate/DebtorsPaymentTemplate.vue';
import DebtorDetail from '@/views/payments/paymentsTemplate/DebtorDetail.vue';
import LandingPageContent from '@/pages/LandingPageContent.vue';
import LandingHome from '@/views/landingPage/LandingHome.vue';
import LandingFeatures from '@/views/landingPage/LandingFeatures.vue';
import LandingAbout from '@/views/landingPage/LandingAbout.vue';
import TermsConditions from '@/views/landingPage/TermsConditions';
import AcceptTerms from '@/views/AcceptTerms';
import PermissionsList from "@/views/permissions/PermissionsList.vue";
import CollaboratorsList from "@/views/collaborators/CollaboratorsList.vue";
import ApiList from '@/views/apiWeb/ApiList.vue';
import ApiRest from '@/views/apiWeb/ApiRest.vue';
import ApiDomain from '@/views/apiWeb/ApiDomain.vue';
import ApiVerification from '@/views/apiWeb/ApiVerification.vue';
import ApiCheckoutButton from '@/views/apiWeb//checkouts/ApiCheckoutButton.vue';
import ApiSelectCheckout from '@/views/apiWeb//checkouts/SelectCheckout.vue';
import ApiForm from '@/views/apiWeb/ApiForm.vue';
import ChargebacksList from '@/views/chargebacks/ChargebacksList.vue';
import ChargebackShow from '@/views/chargebacks/ChargebackShow.vue';
import {
    GET_CURRENT_ORGANIZATION,
} from '@/store/modules/accessconfig/accessConfig.types';
import usersApi from '@/api/user/users.api';
import axios from 'axios';
import NewPayment from '@/views/payments/NewPayment';
import EditPayment from '@/views/payments/EditPayment';
import EditDebtor from '@/views/payments/debtors/EditDebtor.vue';
import TransactionsList from '@/views/transactions/TransactionsList.vue';
import TerminalList from '@/views/terminals/TerminalList.vue';
import PaymentLinkList from '@/views/payments/PaymentLinkList.vue';
import PaymentLinkNew from '@/views/payments/PaymentLinkNew.vue';
import PaymentLinkDetail from '@/views/payments/PaymentLinkDetail.vue';
import PagandoCheckHireLayout from '@/pages/WalletHire/layout.vue';
import AccessDenied403Status from '@/views/403.vue';
import StatementsRoutes from '@/routes/statements.routes';
import StatementsLayout from '@/views/statements/StatementsLayout';

Vue.use(Router);

function dynamicPropsSuccessRegister(route) {
    return {
        email: route.params.email
    }
}

const UX_COMPONENTS_PATH = "/uxcomponents";

const ACCEPT_TERMS_PATH = "/accept-terms";
const REGISTER_ORG_PATH = '/register/organizations';
const walletHirePath = '/wallet-hire';

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/offline',
            name: 'GeneralPayment',
            component: GeneralPayment
        },
        {
            path: "/signin",
            component: SigninLayout,
            children: LoginRoutes
        },
        {
            path: UX_COMPONENTS_PATH,
            name: "uxcomponents",
            component: UxComponents
        },
        {
            path: "/pagandocheck-hire-service-ux",
            name: "PagandoCheckHireServiceUx",
            component: PagandoCheckHireServiceUx
        },
        {
            path: "/pagandocheck-password-ux",
            name: "PagandoCheckPswUx",
            component: PagandoCheckPswUx
        },
        {
            path: "/pagandocheck-processing-ux",
            name: "PagandoCheckProcessingUx",
            component: PagandoCheckProcessingUx
        },
        {
            path: "/register",
            name: "registerwizard",
            component: RegisterWizard
        },
        {
            path: '/digitalcontract',
            name: 'DigitalContract',
            component: DigitalContract
        },
        {
            path: ACCEPT_TERMS_PATH,
            name: "acceptterms",
            component: AcceptTerms
        },
        {
            path: '/digitalcontractclient',
            name: 'DigitalContractClient',
            component: DigitalContractClient
        },
        {
            path: "/client-admin",
            name: "ClientAdmin",
            component: LayoutClient,
            children: [
                {
                    path: '',
                    name: 'HomeDashboard',
                    component: Dashboard
                },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: Dashboard
                },
                {
                    path: 'organization-info',
                    name: 'OrganizationInfo',
                    component: OrganizationInfo
                },
                {
                    path: 'payments',
                    name: 'Payments',
                    component: Payments
                },
                {
                    path: 'payments/new/:type',
                    name: 'NewPayment',
                    component: NewPayment
                },
                {
                    path: 'payments/edit/:type/:id',
                    name: 'EditPayment',
                    component: EditPayment
                },
                {
                    path: 'regular-payment',
                    name: 'RegularPayment',
                    component: RegularPayment
                },
                {
                    path: 'quick-payment',
                    name: 'QuickPayment',
                    component: QuickPayment
                },
                {
                    path: 'requirements-payment',
                    name: 'RequirementsPayment',
                    component: RequirementsPayment
                },
                {
                    path: 'web-service-payment',
                    name: 'WebServicePayment',
                    component: WebServicePayment
                },
                {
                    path: 'collected-payment',
                    name: 'CollectedPayment',
                    component: CollectedPayment
                },
                {
                    path: 'debtors-list/:id',
                    name: 'DebtorsList',
                    component: DebtorsList
                },
                {
                    path: 'debtors/:id',
                    name: 'Debtors',
                    component: Debtors
                },
                {
                    path: 'debtors/:id/:debtPayment',
                    name: 'EditDebtor',
                    component: EditDebtor
                },
                {
                    path: 'tax-receipts',
                    name: 'TaxReceipts',
                    component: TaxReceipts
                },
                {
                    path: 'payment-template',
                    name: 'PaymentTemplate',
                    component: PaymentTemplate
                },
                {
                    path: 'detail-payment-template/:idPaymentPending',
                    name: 'DetailPaymentTemplate',
                    component: DetailPaymentTemplate
                },
                {
                    path: 'debtors-payment-template/:idPaymentPending',
                    name: 'DebtorsPaymentTemplate',
                    component: DebtorsPaymentTemplate
                },
                {
                    path: 'debtor-detail/:idPaymentPending',
                    name: 'DebtorDetail',
                    component: DebtorDetail
                },
                {
                    path: 'permissions-list',
                    name: 'PermissionsList',
                    component: PermissionsList
                },
                {
                    path: 'collaborators-list',
                    name: 'CollaboratorsList',
                    component: CollaboratorsList
                },
                {
                    path: 'api-list',
                    name: 'ApiList',
                    component: ApiList
                },
                {
                    path: 'api-rest',
                    name: 'ApiRest',
                    component: ApiRest
                },
                {
                    path: 'api-rest/api-domain',
                    name: 'ApiDomain',
                    component: ApiDomain
                },
                {
                    path: 'api-rest/api-verification',
                    name: 'ApiVerification',
                    component: ApiVerification
                },
                {
                    path: 'apiWeb/api-select-checkout',
                    name: 'ApiCheckoutButton',
                    component: ApiSelectCheckout
                },
                {
                    path: 'apiWeb/api-checkoutbutton/:type',
                    name: 'ApiCheckoutButton',
                    component: ApiCheckoutButton
                },
                {
                    path: 'apiWeb/api-form',
                    name: 'ApiForm',
                    component: ApiForm
                },
                {
                    path: 'chargebacks/',
                    name: "ChargebacksList",
                    component: ChargebacksList
                },
                {
                    path: 'chargebacks/show/:id',
                    name: 'ChargebackShow',
                    component: ChargebackShow
                },
                {
                    path: 'transacciones/list',
                    name: 'TransactionsList',
                    component: TransactionsList
                },
                {
                    path: 'terminal-list',
                    name: 'TerminalList',
                    component: TerminalList
                },
                {
                    path: 'payment-link-list',
                    name: 'PaymentLinkList',
                    component: PaymentLinkList,
                    meta: {
                        permission: 'PAYMENT_LINK_READ'
                    }
                },
                {
                    path: 'payment-link-new',
                    name: 'PaymentLinkNew',
                    component: PaymentLinkNew,
                    meta: {
                        permission: 'PAYMENT_LINK_CREATE'
                    }
                },
                {
                    path: 'payment-link-detail/:id',
                    name: 'PaymentLinkDetail',
                    component: PaymentLinkDetail,
                    meta: {
                        permission: 'PAYMENT_LINK_READ'
                    }
                },
                { path: 'statements', name: 'Statements', component: StatementsLayout, children: StatementsRoutes}
            ]
        },
        {
            path: REGISTER_ORG_PATH,
            name: 'SignUpOrganizations',
            component: SignUpOrganizationWizard
        },
        // {
        //     path: '/register/organizations-client/',
        //     name: 'SigninTab',
        //     component: SignUpOrganizationWizard
        // },
        {
            path: 'register-success',
            name: 'SigninRegisterSuccess',
            component: RegisterSuccess,
            props: dynamicPropsSuccessRegister
        },
        {
            path: '/finish',
            name: 'SignUpOrganizationFinish',
            component: FinishSignUpOrganization
        },
        {
            path: "/email-verification",
            name: "VerificationEmail",
            component: FullPageTemplate,
            children: [
                {
                    path: 'local-verification/:token',
                    name: 'local-verification',
                    component: LocalVerification
                },
                {
                    path: 'social-verification',
                    name: 'social-verification',
                    component: SocialMediaVerification
                }
            ]
        },
        {
            path: '/',
            name: 'LandingPageContent',
            component: LandingPageContent,
            children: [
                {
                    path: '',
                    name: 'LandingHome',
                    component: LandingHome
                },
                {
                    path: 'about',
                    name: 'LandingAbout',
                    component: LandingAbout
                },
                {
                    path: 'terms-conditions',
                    name: 'TermsConditions',
                    component: TermsConditions
                },
                {
                    path: 'features',
                    name: 'LandingFeatures',
                    component: LandingFeatures
                }
                // {
                //     path: 'pagandoApi',
                //     name: 'LandingPagandoApi',
                //     component: LandingPagandoApi
                // }
            ]
        },
        {
            path: walletHirePath,
            name: 'PagandoCheckHireLayout',
            component: PagandoCheckHireLayout,
        },
        {
            path: "/403",
            name: "403",
            component: AccessDenied403Status
        },
    ]
});

router.beforeEach((to, from, next) => {
    const openRoutes = [
        "/",
        "/about",
        ACCEPT_TERMS_PATH,
        "/features",
        "/pagandoApi",
        "/terms-conditions",
        walletHirePath
    ];

    if(openRoutes.includes(to.path)){
        return next();
    }
    // Revisa si hay token y, si hay, que siga vigente
    // Excluded paths
    if(to.path.indexOf("register-success") !== -1 || to.path.indexOf(REGISTER_ORG_PATH) !== -1
        || to.path.indexOf("/signin") !== -1
        || to.path === UX_COMPONENTS_PATH
        || to.path.indexOf("/email-verification") !== -1){
        return next();
    }
    let validSession = false;
    const token = localStorage.getItem('currentToken');
    const expireTime = localStorage.getItem('expireDate');

    if (token && expireTime) {
        const today = new Date()
        const expireDate = new Date(0);
        expireDate.setUTCSeconds(parseInt(JSON.parse(expireTime), 10));
        if (expireDate > today) {
            validSession = true;
        }
    }
    // Logout route
    if(to.path === "/logout"){
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentToken');
        localStorage.removeItem('currentPermissions');
        localStorage.removeItem('profile');
        localStorage.removeItem('expireDate');
        localStorage.removeItem(GET_CURRENT_ORGANIZATION)
        usersApi.logout({});
        axios.defaults.headers.common.Authorization = undefined;
        return next("/signin");
    }

    if (!validSession) {
        return next("/signin")
    }

    const profile = localStorage.profile;
    const permissions = localStorage.currentPermissions;
    if (profile && (!router.app.$ability || !router.app.$ability.rules || !router.app.$ability.rules.length)) {
        const builder = abilityBuilder.buildAbilities({
            profile: JSON.parse(profile),
            permissions: JSON.parse(permissions)
        });
        router.app.$ability.update(builder);
    }

    if (!to.meta.permission) {
        return next();
    }
    if (router.app.$ability.can("do", to.meta.permission)) {
        return next()
    }
    return next({name: "403"})
});

router.beforeEach((to, from, next) => {
    const currentUser = localStorage.currentUser !== undefined ? JSON.parse(localStorage.currentUser) : undefined;
    const profile = localStorage.profile !== undefined ? JSON.parse(localStorage.profile) : undefined;
    if (to.path === "/"
        || to.path === "/about"
        || to.path === ACCEPT_TERMS_PATH
        || to.path === "/features"
        || to.path === "/pagandoApi"
        || to.path === "/terms-conditions"
        || to.path.indexOf(REGISTER_ORG_PATH) !== -1
        || to.path.indexOf("/auth") !== -1
        || to.path.indexOf("/digitalcontract") !== -1
        || to.path.indexOf("/finish") !== -1
        || to.path.indexOf("/digitalcontractclient") !== -1
        || to.path === UX_COMPONENTS_PATH
        || to.path.indexOf(walletHirePath) !== -1
    ) {
        return next();
    }
    if (to.path.indexOf("/signin") !== -1 && currentUser && profile && profile.type === "USER") {
        return next(REGISTER_ORG_PATH);
    }

    // if(currentUser){
    //     if(profile && profile.type === "USER"){
    //         return next(REGISTER_ORG_PATH);
    //     } else if(currentUser.contract === 0){
    //         return next(`/digitalcontractclient`);
    //     } else if(currentUser.terms === 0){
    //         return next(ACCEPT_TERMS_PATH);
    //     }
    // }
    return next();
});

export default router;
