<template>
    <div class="collapse-info-org m-b-15">
        <button v-bind:class="clazz" type="button">
            <Badge v-if="showBadge" :type="badgeType" :text="badgeText"></Badge>
            <label>{{title}}</label>
            <i aria-hidden="true" class="banana banana-chevron-right"></i>
            <i aria-hidden="true" class="banana banana-chevron-down"></i>
        </button>
        <b-collapse :id="idCollapse" class="m-t-15">
          <slot></slot>
        </b-collapse>
    </div>
</template>

<script>
    import Badge from '@/components/Badge.vue';


    export default {
        name: 'SimpleCollapse',
        props: {
            clazz: String,
            title: String,
            idCollapse: String,
            showBadge: {type: Boolean, default: false},
            badgeType: String,
            badgeText: String
        },
        components: {
            Badge
        }
    }
</script>

<style lang="scss">
    .collapse-info-org > button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0;

        &::before {
            content: "";
            height: 1px;
            width: 100%;
            margin: auto;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 0;
        }

        > label {
            font-size: 13px;
            font-weight: bold;
            line-height: 1.31;
            width: fit-content;
            padding-right: 10px;
            margin-bottom: 0;
            white-space: nowrap;
            z-index: 1;
        }

        > i {
            font-weight: bold;
            font-size: 18px;
            width: 15px;
            height: 15px;
            z-index: 1;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 auto;
            transition: 0.5s;

            &.banana-chevron-right {
                display: none;
            }

            &.banana-chevron-down {
                display: flex;
            }
        }

        > .badge {
            z-index: 1;

            & + label {
                padding-left: 5px;
            }
        }
    }

    .collapse-info-org.collapsed > button i {
        &.banana-chevron-right {
            display: flex;
        }

        &.banana-chevron-down {
            display: none;
        }
    }
</style>
