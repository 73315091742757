import momentTimezone from 'moment-timezone';
import moment from 'moment';
import * as Excel from 'exceljs';

const {FormatMoney} = require('format-money-js');
const fm = new FormatMoney({
    decimals: 2
})
import base from '@/api/base.api';

const _concat = (x,y) => {
    return x.concat(y);
};

const _A_ACCENT = "[AÀÁÂÃÄÅÆaàáâãäå]";
const _E_ACCENT = "[EÈÉÊËẼeèéêëẽ]";
const _I_ACCENT = "[IÌÍÎÏĨiìíîïĩ]";
const _O_ACCENT = "[OÒÓÔÕÖØðoòóôõöø]";
const _U_ACCENT = "[UÙÚÛÜuùúûü]";
const _S_ACCENT = "[SŠšßs]";
const _Z_ACCENT = "[ZzŽž]";
const _D_ACCENT = "[DdÐ]";
const _C_ACCENT = "[cCÇç]";

const index = {};
const stack = {};
const debounceTime = 1700;

const rePhone = /^\+?([0-9]{1,2})([0-9]{3})([0-9]{3})([0-9]{4})$/;
const rePhoneWithout = /^([0-9]{3})([0-9]{3})([0-9]{4})$/;
const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const utils = {

    numberToMoneyString(number) {
        if(isNaN(number))
        {
            return '$0.00';
        }
        if(typeof number === 'string'){
            number = Number.parseFloat(number);
        }
        return `$${ number.toLocaleString(
            'en-IN',
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        )}`;
    },

    // -------------------Object-------------------

    /**
     * Revisa si un objeto está definido (que no sea undefined ni null).
     * @param obj {object} objeto a revisar
     * @returns {boolean} indicando si el objeto se encuentra definido
     */
    isDefined(obj) {
        return obj !== undefined && obj !== null;
    },

    /**
     * Revisa si un objeto no está definido (que sea undefined o null).
     * @param obj {object} objeto a revisar
     * @returns {boolean} indicando si el objeto se encuentra indefinido
     */
    isNotDefined(obj) {
        return !utils.isDefined(obj);
    },


    /**
     * Verifica que un email tenga e formato correcto
     * @param {string} email - cadena de texto a verificar
     * @returns {boolean} si el email es o no válido
     */
    validateEmail(email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(String(email).toLowerCase());
    },

    /**
     * Intenta transformar un modelo a un objeto plano. Si no es un modelo válido, devuelve el valor original.
     *
     * @param obj {Object} objeto a transformar
     * @returns {*} objeto transformado en objeto plano
     */
    modelToObject(obj) {
        if (utils.isDefined(obj.toObject)) {
            return obj.toObject();
        }
        return obj;
    },

    /**
     * Revisa si un objeto está vacío; es decir, que no tiene ninguna llave, por lo que se es equivalente a {}.
     * Si el objeto no está definido, esta función devuelve false.
     *
     * @param obj {object} objeto a revisar
     * @returns {boolean} true cuando el objeto se encuentra definido y no tiene ninguna llave
     */
    isObjectEmpty(obj) {
        if (utils.isDefined(obj)) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        }
        return false;
    },

    // -------------------Array-------------------


    /**
     * Revisa si un objeto corresponde a un array.
     * @param obj {object} objeto a revisar
     * @returns {boolean} indicando si el objeto corresponde o no a un array
     */
    isArray(obj) {
        return utils.isDefined(obj) && obj.constructor === Array;
    },

    /**
     * Realiza un flatmap de arreglos con arreglos de un solo nivel.
     *
     * @param arr {Array} a actualizar
     * @param mapFunction {Function} función para mapear cada valor del arreglo a otro arreglo
     */
    flatMap(arr, mapFunction) {
        return arr.map(mapFunction).reduce(_concat, [])
    },


    /**
     * Revisa si un elemento existe en un arreglo determinado
     * @param element elemento a consultar
     * @param array conjunto de elementos donde se realizará la búsqueda
     * @param limit (opcional) indica que deje de buscar a partir de cierta cantidad de elementos encontrados. Default 1
     * @returns {boolean} si se encontró el elemento las veces esperadas
     */
    containsElement(element, array, limit){
        if (!limit){
            limit = 1;
        }
        let count = 0;
        for (let i = 0; i < array.length; i++) {
            ++count;
            if (array[i] === element && count >= limit) {
                return true;
            }
        }
        return false;
    },

    /**
     * Formatea valores de tipo number o string. En el caso de las propiedades numericas llena con 0 a la izquierda
     * hasta completar la longitud especificada {@code totalLength}, en el caso de las propiedades string llena
     * con espacios vacios a la derecha hasta completar la longitud especificada {@code totalLength}
     * @param totalLength - Longitud de caracteres deseada
     * @param type - Tipo de la propiedad a formatear(number/string)
     * @param property - Valor de la propiedad que será formateada
     * @return {string}
     */
    getFormatedProperty(totalLength, type, property){
        if(type === 'string'){
            const array = new Array(totalLength - (String(property).length - 1));
            return property + array.join(' ');
        } else if (type === 'number') {
            const array = new Array(totalLength - (String(property).length - 1));
            return array.join("0") + String(property);
        }
        return '';
    },



    // -------------------Function-----------------

    /**
     * Indica si el objeto recibido corresponde a una function de Javascript.
     * @param {*} fn objeto a revisar
     * @returns {boolean} true si corresponde a una función o false en caso contrario
     */
    isFunction(fn) {
        return utils.isDefined(fn) && {}.toString.call(fn) === '[object Function]'
    },


    // -------------------String-------------------


    /**
     * Revisa si un objeto corresponde a un String.
     * @param obj {object} objeto a revisar
     * @returns {boolean} indicando si corresponde o no a un String
     */
    isString(obj) {
        return obj !== undefined && obj !== null && typeof(obj) === 'string';
    },

    /**
     * Transforma el string recibido a base 64.
     * @param str {string} a transformar
     */
    writeBase64(str) {
        let result = "";
        try {
            result = new Buffer(str).toString('base64');
        } catch (err) {
            // TODO: Handle err
        }
        return result;
    },

    /**
     * Obtiene un string ascii desde un string recibido de base 64.
     * @param base64 {string} string con base64 a transformar
     */
    readBase64(base64) {
        let result = "";
        try {
            result = new Buffer(base64, 'base64').toString('ascii');
        } catch (err) {
            // TODO: Handle err
        }
        return result;
    },

    /**
     * Revisa si el String indicado contiene al otro String.
     *
     * @param str {string} sobre el cual se revisa
     * @param strToCheck {string} a buscar dentro de str
     * @returns {*|boolean} indicando si contiene o no al segundo parámetro
     */
    contains(str, strToCheck) {
        return utils.isDefined(str) && str.indexOf(strToCheck) !== -1;
    },

    /**
     * Transforma un string en una expresión regular que hace match para cualquier acento.
     *
     * @param str {string} a transformar
     * @param flags {String|null} flags opcionales a agregar al regex generado
     * @returns {*} RegExp creada
     */
    toAccentsRegex(str, flags) {
        if (utils.isNotDefined(str)) {
            str = '';
        }
        let regexStr = str;
        regexStr = regexStr.replace(new RegExp(_A_ACCENT, 'g'), _A_ACCENT);
        regexStr = regexStr.replace(new RegExp(_E_ACCENT, 'g'), _E_ACCENT);
        regexStr = regexStr.replace(new RegExp(_I_ACCENT, 'g'), _I_ACCENT);
        regexStr = regexStr.replace(new RegExp(_O_ACCENT, 'g'), _O_ACCENT);
        regexStr = regexStr.replace(new RegExp(_U_ACCENT, 'g'), _U_ACCENT);
        regexStr = regexStr.replace(new RegExp(_S_ACCENT, 'g'), _S_ACCENT);
        regexStr = regexStr.replace(new RegExp(_Z_ACCENT, 'g'), _Z_ACCENT);
        regexStr = regexStr.replace(new RegExp(_D_ACCENT, 'g'), _D_ACCENT);
        regexStr = regexStr.replace(new RegExp(_C_ACCENT, 'g'), _C_ACCENT);
        return new RegExp(regexStr, flags);
    },

    /**
     * Limita un String a un cierto número de caracteres, cortando los caracteres excedentes y agregando ellispsis (...)
     * en caso de que el String exceda el número de caracteres solicitados. Nótese que el sufijo (...) cuenta para el
     * número de caracteres, por lo que siempre se mostrarán charNum - 3 caracteres de los String cortados.
     *
     * @param str {string} texto a cortar
     * @param [charNum=20] {Number} con el número de caracteres máximo, incluyendo las elipsis en caso de cortar el String.
     * @param [suffix='...'] {string} opcional con el sufijo a usar.
     * @returns {string} texto cortado
     */
    ellipsify(str, charNum, suffix) {
        if (utils.isNotDefined(str)) {
            str = '';
        }

        if (utils.isNotDefined(charNum)) {
            charNum = 20;
        }

        if (utils.isNotDefined(suffix)) {
            suffix = '...';
        }

        if (str.length > charNum) {
            return str.substr(0, charNum - suffix.length) + suffix;
        }
        return str;
    },

    // -------------------Date-------------------

    /**
     * Revisa si un objeto corresponde a una Date.
     * @param obj {object} objeto a revisar
     * @returns {boolean} indicando si corresponde o no a una Date
     */
    isDate(obj) {
        return obj !== undefined && obj !== null && Object.prototype.toString.call(obj) === '[object Date]';
    },

    /**
     * Intenta parsear una fecha a partir de un timestamp. Si el timestamp recibido no es válido, entonces esta función
     * devuelve el valor original recibido.
     * @param timestamp {number|string} a parsear
     * @returns {Date|null} fecha resultado del parsing o null si no es posible leer el timestamp
     */
    dateFromTimestamp(timestamp) {
        let momentDate = null;
        if (utils.isDefined(timestamp)) {
            // We have to ensure it's a valid timestamp (numbers only)
            if (utils.isNumber(timestamp)) {
                let timestampAsStr = timestamp.toString();

                // Ignore numbers after decimal point, to check if millis or seconds
                const hasDecimals = timestampAsStr.match("\\.");
                if (hasDecimals) {
                    timestampAsStr = timestampAsStr.substring(0, timestampAsStr.indexOf("."));
                }
                const digitsCount = timestampAsStr.length;


                // If more than 12 digits, the timestamp is read as millis. Else it's read as seconds
                if (digitsCount > 12) {
                    // Read as millis
                    momentDate = moment(timestamp);
                } else {
                    // Read as seconds
                    momentDate = moment.unix(timestamp);
                }
            } else if (utils.isString(timestamp)) {
                try {
                    const timestampAsNumber = Number(timestamp);
                    return utils.dateFromTimestamp(timestampAsNumber);
                } catch (err) {
                    // Could not convert to number
                    // WARNING: Circular dependency error if logger is used
                    // logger.error(null, null, 'utils#dateFromTimestamp', 'Could not parse timestamp as String: %j', timestamp);
                    console.error(`utils#hydrateIfNeeded: Could not parse timestamp as String: ${ timestamp}`);
                }
            }
        }
        if (utils.isDefined(momentDate)) {
            return momentDate._d;
        }
        return null;
    },

    /**
     * Intenta realizar un parsing de un string como fecha. Si no se reconoce la estructura de la fecha, se devolverá
     * null. Esta función intenta parsear las siguientes estructuras de fecha:
     * DD/MM/YY
     * DD/MM/YYYY
     * DD-MM-YY
     * DD-MM-YYYY
     * @param {string} str string a intentar parsear
     * @returns {Date|null} fecha parseada o null si no se reconoce el formato
     */
    parseDate(str) {
        let momentDate = null;
        if (utils.isDefined(str)) {
            if (str.match(/^[0123]?[0-9]\/[01]?[0-9]\/[0-9]{2}$/)) {
                // Try DD/MM/YY
                momentDate = moment(str, "DD/MM/YY");
            } else if (str.match(/^[0123]?[0-9]\/[01]?[0-9]\/[0-9]{4}$/)) {
                // Try DD/MM/YYYY
                momentDate = moment(str, "DD/MM/YYYY");
            } else if (str.match(/^[0123]?[0-9]-[01]?[0-9]-[0-9]{2}$/)) {
                // Try DD-MM-YY
                momentDate = moment(str, "DD-MM-YY");
            } else if (str.match(/^[0123]?[0-9]-[01]?[0-9]-[0-9]{4}$/)) {
                // Try DD-MM-YYYY
                momentDate = moment(str, "DD-MM-YYYY");
            }
        }
        if (utils.isDefined(momentDate) && momentDate.isValid()) {
            return momentDate.toDate();
        }
        return null;
    },

    /**
     * Función que recibe un string date, si format está definido regresa un objeto moment.Moment.
     * @param {string} date Fecha.
     * @param {string} offset Cantidad de minutos (+/-) de diferencia con respecto a UTC.
     * @param {Number} defaultOffset Especifica el offset por defecto en caso de que el parámetro offset sea nulo.
     * @returns {moment.Moment} Regresa un objeto moment.Moment ya modificado.
     */
    toLocalClientDate(date, offset, defaultOffset) {
        const result = moment(date).utc();
        if (offset){
            result.add(offset, 'minutes');
        } else if (defaultOffset){
            result.add(defaultOffset, 'minutes');
        }
        return result;
    },

    /**
     * Método para formatear una fecha utilizando moment
     * @param date - Fecha a formatear
     * @param format - Formato deseado ('YYYYMMDD')
     */
    toFormatDate(date, format){
        return moment(date).format(format);
    },

    /**
     * Método para convertir una fecha a una zona horaria en especifico y darle el formato deseado
     * @param date - Fecha a formatear
     * @param timezone - Zona horaria a la que se transofrmara la fecha
     */
    toTimeZone(date, timezone){
        const originalDate = momentTimezone(date);
        return originalDate.tz(timezone);
    },
    // -------------------Boolean-------------------

    /**
     * Revisa si un objeto corresponde a un Boolean.
     * @param obj {object} objeto a revisar
     * @returns {boolean} indicando si corresponde o no a un Boolean
     */
    isBoolean(obj) {
        return obj !== undefined && obj !== null && typeof(obj) === 'boolean';
    },

    // -------------------Number-------------------

    /**
     * Revisa si un objeto corresponde a un Number.
     * @param obj {object} objeto a revisar
     * @returns {boolean} indicando si corresponde o no a un Number
     */
    isNumber(obj) {
        return obj !== undefined && obj !== null && typeof(obj) === 'number';
    },


    /**
     * Revisa si un objeto corresponde a un Number.
     * @param obj {object} objeto a revisar
     * @returns number - el objeto como número o en caso de error, un valor de 0
     */
    parseNumber(obj) {
        if (obj !== undefined && obj !== null) {
            if (typeof(obj) === 'number') {
                return obj;
            } else if (typeof(obj) === 'string') {
                try {
                    return Number(obj);
                } catch (err) {
                    console.error(err);
                    return 0;
                }
            }
        }
        return 0;
    },

    /**
     * Corta los decimales de un número al número de decimales indicado. Si no se indica el número de decimales,
     * entonces se quitan todos los decimales.
     *
     * @param num {number} a cortar
     * @param decimals {number} decimales a definir (default 0)
     * @returns {number} resultado; si el número recibido no es un número válido, esta función solamente devuelve su
     * valor original
     */
    truncateNumber(num, decimals) {
        if (!utils.isDefined(decimals) || !utils.isNumber(decimals) || decimals < 0) {
            decimals = 0;
        }
        if (utils.isNumber(num)) {
            const roundedNum = utils.jsNumFix(num);
            if (decimals === 0) {
                return Math.trunc(roundedNum);
            }
            const powerOfTen = 10 ** decimals;
            return Math.trunc(utils.jsNumFix(roundedNum * (powerOfTen))) / powerOfTen;
        }
        return num;
    },

    /**
     * Redondea un número para evitar numeros raros de jaascript. Esto se hace debido a que en javascript es muy común
     * terminar con números de la forma 115.999999999
     *
     * @param num {number} a redondear
     * @returns {number} número redondeado
     */
    jsNumFix(num) {
        return Math.round( num * 100 + Number.EPSILON ) / 100;
    },


    // -------------------Currency-------------------

    /**
     * Devuelve la cantidad indicada como cadena de texto con formato de dinero.
     *
     * @param currency {number} al cual se dará formato
     * @returns {*}
     */
    toCurrency(currency) {
        const opts = {symbol: '$' };
        return utils.isDefined(currency) && utils.isNumber(currency) ? fm.from(currency, opts) : fm.from(0,opts);
    },

    /**
     * Realiza una suma de dos cantidades, limitando las cantidades y operaciones a 2 decimales.
     *
     * @param first {number} primer número para la operación
     * @param second {number} segundo número para la operación
     * @returns {number} con el resultado de la operación
     */
    moneySum(first, second) {
        return utils.moneyOperation(first, second, (a, b) => {
            return a + b;
        });
    },

    /**
     * Realiza una resta de dos cantidades, limitando las cantidades y operaciones a 2 decimales.
     *
     * @param first {number} primer número para la operación
     * @param second {number} segundo número para la operación
     * @returns {number} con el resultado de la operación
     */
    moneySubstract(first, second) {
        return utils.moneyOperation(first, second, (a, b) => {
            return a - b;
        })
    },

    /**
     * Realiza una multiplicación de dos cantidades, limitando las cantidades y operaciones a 2 decimales.
     *
     * @param first {number} primer número para la operación
     * @param second {number} segundo número para la operación
     * @returns {number} con el resultado de la operación
     */
    moneyMultiply(first, second) {
        return utils.moneyOperation(first, second, (a, b) => {
            return a * b;
        })
    },

    /**
     * Realiza una división de dos cantidades, limitando las cantidades y operaciones a 2 decimales.
     *
     * @param first {number} primer número para la operación
     * @param second {number} segundo número para la operación
     * @returns {number} con el resultado de la operación
     */
    moneyDivide(first, second) {
        return utils.moneyOperation(first, second, (a, b) => {
            return a / b;
        })
    },

    /**
     * Realiza una operación de dos cantidades, limitando las cantidades y operaciones a 2 decimales, de acuerdo a la
     * función indicada. Si no se indica una operación, esta función realizará una suma por defecto.
     *
     * @param first {number} primer número para la operación
     * @param second {number} segundo número para la operación
     * @returns {number} con el resultado de la operación
     */
    moneyOperation(first, second, fn) {
        if (!first) {
            first = 0;
        }
        if (!second) {
            second = 0;
        }

        if (utils.isNotDefined(fn)) {
            // Sum as default
            fn = ((a, b) => a + b);
        }

        const firstWithTwoDecimals = utils.truncateNumber(utils.jsNumFix(first), 2);
        const secondWithTwoDecimals = utils.truncateNumber(utils.jsNumFix(second), 2);

        const resultTemp = fn.call(this, firstWithTwoDecimals, secondWithTwoDecimals);

        // let result = utils.truncateNumber(resultTemp, 2);
        // Round using third digit
        /* For example:

         10.344 is rounded to 10.34
         10.345 is rounded to 10.35
         10.346 is rounded to 10.35

         */
        return utils.jsNumFix(Math.round(resultTemp * 100) / 100);
    },

    /**
     * Crea un MoneyUtil a partir de un número, para realizar operaciones de moneda fácilmente.
     *
     * @param num {number} número a transformar
     * @returns {MoneyUtil} creado
     */
    money(num) {
        return new MoneyUtil(num);
    },

    /**
     * Evalúa si el objeto / variable ingresado es un booleano o no y lo convierte.
     * @param {*} val Valor a ser evaluado y convertido.
     */
    toBoolean(val) {
        const string = val.toString();
        return Boolean(val) === val || string === 'true' || string === 'false';
    },


    /**
     * Accede a una propiedad de un objeto, incluso si la propiedad se encuentra anidada con varios ".".
     * @param {object} obj
     * @param {string} propName
     */
    getNestedProperty(obj, propName) {
        if (utils.isNotDefined(obj) || utils.isNotDefined(propName)) {
            return obj;
        }

        const propNameTree = propName.split('.');

        if (propNameTree.length <= 1) {
            return obj[propName];
        }

        // Obtener el primer valor del árbol de la propiedad "anidada"
        let tempProp = obj[propNameTree[0]];

        if (utils.isNotDefined(tempProp)) {
            return tempProp;
        }

        // Para cada propiedad "anidada", obtener el siguiente valor
        for (let i = 1; i < propNameTree.length; i++) {
            tempProp = tempProp[propNameTree[i]];

            // End loop on undefined/null value; can't go deeper in the prop tree
            if (utils.isNotDefined(tempProp)) {
                break;
            }
        }

        return tempProp;
    },

    /**
     * Obtiene un elemento aleatorio de un arreglo.
     * @param [elements=[]] {array} arreglo de elementos
     * @returns {*|null} uno de los elementos del arreglo o null si no hay alguno disponible
     */
    getRandomElement(elements = []) {
        if (!elements.length) {
            return null;
        }
        return elements[Math.floor(Math.random()*elements.length)];
    },


    /**
     * Devuelve una función que, mientras se siga invocando, no se activará.
     * La función se llamará después de que deje de llamarse durante N milisegundos.
     * Si se pasa immediate, ejecuta la función al comienzo sin esperar.
     * @param {Function} func Función a ejecutar
     * @param {Number} wait Tiempo a esperar en milisegundos
     * @param {Boolean} immediate Indica si se inicia sin espera o con espera.
     * @returns {Function} Función con retraso.
     */

    debounce (func) {
        let args, context;
        const utilsContext = this;
        return function () {
            context = this;
            args = arguments;
            utilsContext.debounceFixed(func, context, ...args);
        };
    },

    debounceFixed (func, context, ...params) {
        const id = func.toString() + JSON.stringify(params || []);
        if (!index[id]) {
            index[id] = true;
            setTimeout(() => {
                index[id] = false;
                if (stack[id]) {
                    if (context) {
                        stack[id].func.apply(context, stack[id].params);
                    } else {
                        stack[id].func.apply(stack[id].params);
                    }
                    stack[id] = null;
                }
            }, debounceTime);
            if (context) {
                return func.apply(context, params);
            }
            return func(...params);
        }
        stack[id] = {func, params};
        return null;
    },


    /**
     * Limita que una función sea llamada más de una vez en cierta cantidad de tiempo.
     * @function
     * @param func Función que se llamará de forma controlada
     * @param wait el tiempo que
     * @param immediate Indica si la función va a ser llamada hasta que el tiempo termine (false)
     *          o si va a ser llamada y luego la va a dejar de llamar mientras el tiempo/timeout no termine
     * @returns {Function} Función con retraso
     */
    classicDebounce (func, wait, immediate) {
        let timeout, args, context, timestamp, result;
        const later = function () {
            const last = Date.now() - timestamp;
            if (last < wait && last >= 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout){
                        context = args = null;
                    }
                }
            }
        };
        return function () {
            context = this;
            args = arguments;
            timestamp = Date.now();
            const callNow = immediate && !timeout;
            if (!timeout){
                timeout = setTimeout(later, wait);
            }
            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }
            return result;
        };
    },
    /*
    * Obtiene el valor de una cookie
    * @returns {string}
    */

   getCookie(name){
        var match = window.document.cookie.match(new RegExp(`(^| )${ name }=([^;]+)`));
        return match ? match[2] : '';
    },

    fileDownloadLinkSigned(file) {
        return `${base.baseUrl}/file/download/${file._id || file}`;
    },

    verifyValidPhone(phone){
        return rePhone.test(phone);
    },

    verifyValidPhoneWithoutLada (phone) {
        return rePhoneWithout.test(phone);
    },

    verifyValidEmail(email) {
        return re.test(email);
    },

    formatPhone(phone) {
        const cleaned = (`${phone}`).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[2]}) ${match[3]}-${match[4]}`;
        }
        return null;
    },

    /**
     * Convierte buffer de un archivo excel a un arreglo de objetos con los datos de cada fila.
     * @param {Buffer} fileBase64 Archivo excel en base64
     * @returns {Promise<Array<*>}
     */
    async xlsxToJsonExcelJS(fileBuffer) {
        const wb = await new Excel.Workbook().xlsx.load(fileBuffer);
        const data = [];
        wb.eachSheet((sheet) => {
            const keys = [];
            sheet.eachRow((row, rowNumber) => {
                if(rowNumber === 1) {
                    row.eachCell((cell) => keys.push(cell.value));
                } else {
                    data.push(keys.reduce((obj, k, i) => ({...obj, [k]: row.getCell(i + 1).value}), {}));
                }
            });
        });
        return data;
    },
};

/**
 * Clase para realizar operaciones de dinero.
 */
class MoneyUtil {
    /**
     * Crea una nueva instancia de MoneyUtil para un número.
     *
     * @param num {number} número a manipular como dinero
     */
    constructor(num) {
        this.num = num;
        if (!this.num) {
            this.num = 0;
        }
        this.ops = [];
        this.roundLast = false;
    }

    /**
     * Agrega una operación a la lista de operaciones de esta instancia. Las operaciones se realizan hasta que se llame
     * la función {@link MoneyUtil.result}.
     *
     * @param value {number} con el número a usar para la operación deseada
     * @param operation {string} con el tipo de operación: ['sum', 'substract', 'multiply', 'divide']
     * @returns {MoneyUtil} actual después de agregar la operación
     */
    addOp(value, operation) {
        this.ops.push({
            value: value,
            operation: operation
        });
        return this;
    }

    /**
     * Agrega una cantidad. Alias de {@link MoneyUtil.sum}.
     *
     * @param value {number} la cantidad a agregar
     * @returns {MoneyUtil} instancia actual
     */
    plus(value) {
        return this.sum(value);
    }

    /**
     * Agrega una cantidad.
     *
     * @param value {number} la cantidad a agregar
     * @returns {MoneyUtil} instancia actual
     */
    sum(value) {
        return this.addOp(value, 'sum');
    }

    /**
     * Resta una cantidad. Alias de {@link MoneyUtil.substract}.
     *
     * @param value {number} la cantidad a restar
     * @returns {MoneyUtil} instancia actual
     */
    minus(value) {
        return this.substract(value);
    }

    /**
     * Resta una cantidad.
     *
     * @param value {number} la cantidad a restar
     * @returns {MoneyUtil} instancia actual
     */
    substract(value) {
        return this.addOp(value, 'substract');
    }

    /**
     * Multiplica por una cantidad. Alias de {@link MoneyUtil.multiply}.
     *
     * @param value {number} la cantidad del multiplicando
     * @returns {MoneyUtil} instancia actual
     */
    times(value) {
        return this.multiply(value);
    }

    /**
     * Multiplica una cantidad.
     * @param value {number} la cantidad del multiplicando
     * @returns {MoneyUtil} instancia actual
     */
    multiply(value) {
        return this.addOp(value, 'multiply');
    }

    /**
     * Divide por una cantidad. Alias de {@link MoneyUtil.divide}.
     *
     * @param value {number} la cantidad del divisor
     * @returns {MoneyUtil} instancia actual
     */
    by(value) {
        return this.divide(value);
    }

    /**
     * Divide por una cantidad.
     *
     * @param value {number} la cantidad del divisor
     * @returns {MoneyUtil} instancia actual
     */
    divide(value) {
        return this.addOp(value, 'divide');
    }

    /**
     * Calcula el resultado de las operaciones realizadas sobre esta instancia de MoneyUtil. Si no se realiza ninguna
     * operación, esta función solamente redondea la cantidad inicial del MoneyUtil con {@link utils.jsNumFix}
     *
     * @returns {number} resultado de las operaciones.
     */
    result() {
        let _result = utils.jsNumFix(this.num);
        if (this.ops.length > 0) {
            for (let i = 0; i < this.ops.length; i++) {
                const op = this.ops[i];
                let opFn;
                switch (op.operation) {
                    case 'sum':
                        opFn = utils.moneySum;
                        break;
                    case 'substract':
                        opFn = utils.moneySubstract;
                        break;
                    case 'multiply':
                        opFn = utils.moneyMultiply;
                        break;
                    case 'divide':
                        opFn = utils.moneyDivide;
                        break;
                    default:
                        opFn = utils.moneySum;
                }
                _result = opFn.call(this, _result, op.value/* , needsRounding*/);
            }
        }
        return _result;
    }
}

export default utils;
