export const comingSoon = "COMING_SOON";
export const review = "REVIEW";
export const infoRequest = "INFO_REQUEST";
export const active = "ACTIVE";
export const rejected = "REJECTED";

export const values = [
    comingSoon,
    review,
    infoRequest,
    active,
    rejected,
];
