<template>
    <div :class="clazz">
        <form-wizard
                ref="wizard"
                shape="circle"
                stepSize="xs"
                color="#557cf1"
                v-bind:class="[activeVertical && verticalWizard ? 'vertical': 'horizontal']"
                @on-change="changeStep"
        >
            <slot name="wizardView"></slot>

            <!-- To comunicate with the original lib, this slot needs to be here, inside our component 'WizardForm' -->
            <template slot="footer" slot-scope="props">
                <div class="footer-content">
                    <div class="wizard-footer-left" v-if="!hideFooter">
                        <div v-if="props.activeTabIndex == 0">
                            <wizard-button
                                    @click.native="makeActionForStepWithParams(props.activeTabIndex, {isPhoto:false}); props.nextTab()"
                                    class="button sm button-ghost button-plain_text"> Llenar formulario
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 1 && getIsPhoto">
                            <wizard-button @click.native="makeBack(props.activeTabIndex); props.prevTab()"
                                           class="button sm button-ghost button-plain_text"><i
                                    aria-hidden="true" class="banana banana-chevron-left"></i> Regresar
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 1 && !getIsPhoto">
                            <wizard-button v-if="!getShowBackButtonForm"
                                           @click.native="makeBack(props.activeTabIndex); props.prevTab()"
                                           class="button sm button-ghost button-plain_text"><i
                                    aria-hidden="true" class="banana banana-chevron-left"></i> Regresar
                            </wizard-button>
                            <wizard-button v-if="getShowBackButtonForm && !getIsPhoto" @click.native="makeBackFormStep"
                                           class="button sm button-ghost button-plain_text"><i
                                    aria-hidden="true" class="banana banana-chevron-left"></i> Regresar
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 2">
                            <wizard-button v-if="props.activeTabIndex > 0 && !getShowBackButtonForm"
                                           @click.native="makeBack(props.activeTabIndex); props.prevTab()"
                                           class="button sm button-ghost button-plain_text"><i
                                    aria-hidden="true" class="banana banana-chevron-left"></i> Regresar
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 3">
                            <wizard-button v-if="props.activeTabIndex > 0 && !getShowBackButtonForm"
                                           @click.native="makeBack(props.activeTabIndex); props.prevTab()"
                                           class="button sm button-ghost button-plain_text"><i
                                    aria-hidden="true" class="banana banana-chevron-left"></i> Regresar
                            </wizard-button>
                        </div>
                        <div v-if="props.activeTabIndex == 4">
                            <wizard-button v-if="props.activeTabIndex > 0 && !getShowBackButtonForm"
                                           @click.native="makeBack(props.activeTabIndex); props.prevTab()"
                                           class="button sm button-ghost button-plain_text"><i
                                    aria-hidden="true" class="banana banana-chevron-left"></i> Regresar
                            </wizard-button>
                            <wizard-button v-if="props.activeTabIndex > 0" @click.native="editInfo()"
                                           class="button sm button-ghost button-plain_text"> Editar
                            </wizard-button>
                        </div>
                    </div>
                    <div class="wizard-footer-right" v-if="!hideFooter">
                        <div v-if="loading">
                            <button type="button" class="button sm button-filled button-accent1">
                                <LoadingSimple clazz="small-load no-text"/>
                            </button>
                        </div>

                        <div v-if="!loading">
                            <div v-if="props.activeTabIndex == 0">
                                <wizard-button
                                        @click.native="makeActionForStepWithParams(props.activeTabIndex, {isPhoto:true}); props.nextTab()"
                                        class="button sm button-filled button-accent1"> Autocompletar con mi ine/ife
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 1 && getIsPhoto">
                                <wizard-button
                                        v-if="props.activeTabIndex == 1 && getShowValidateIneButton"
                                        @click.native="makeActionForStepWithParams(props.activeTabIndex, {isPhoto:true, validate:true})"
                                        class="button sm button-filled button-accent1"
                                        :class="{'button-disable': getloadingIneData}"
                                > Validar INE
                                </wizard-button>
                                <wizard-button v-if="(getIsPhoto && getIneValid)"
                                               @click.native="makeActionForStepWithParams(props.activeTabIndex, {isPhoto:true}); props.nextTab()"
                                               class="button sm button-filled button-accent1"> Siguiente
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 1 && !getIsPhoto">
                                <wizard-button
                                        v-if="(getFormStep !== 3 && getShowNextButtonForm && !getIsPhoto) && getIsValidStepFormSave"
                                        @click.native="makeSaveFormStepWithParams(props)"
                                        class="button sm button-filled button-accent1"
                                        :class="{'button-disable': !getIsValidStepFormSave}"> Siguiente form
                                </wizard-button>
                                <wizard-button
                                        v-if="(getFormStep === 3 && getShowNextButtonForm && !getIsPhoto) && getIsValidStepFormSave"
                                        @click.native="makeSaveFormStepWithParams(props)"
                                        class="button sm button-filled button-accent1"
                                        :class="{'button-disable': !getIsValidStepFormSave}"> Siguiente final
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 2">
                                <wizard-button
                                        v-show="getSelfieCropped || getTakeSelfieLaterCheckbox"
                                        @click.native="makeActionForStep(props.activeTabIndex, props);"
                                        class="button sm button-filled button-accent1"
                                >
                                    Siguiente
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 3">
                                <wizard-button
                                        v-if="getIsValidStepFourWizard"
                                        @click.native.prevent="makeActionForStep(props.activeTabIndex, props)"
                                        class="button sm button-filled button-accent1"
                                >
                                    Siguiente
                                </wizard-button>
                            </div>
                            <div v-if="props.activeTabIndex == 4">
                                <wizard-button v-if="props.isLastStep"
                                               @click.native="confirmWizard(props.activeTabIndex);"
                                               class="button sm button-filled button-accent1">{{props.isLastStep ?
                                    'Terminar' : 'Siguiente'}}
                                </wizard-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- THIS PART SHOWS ON WIZARD + REGISTER -->
                <div class="footer-container"><small class="text-footer">Copyright 2021, <strong>Pagando Check</strong></small></div>
            </template>
        </form-wizard>
    </div>
</template>

<script>
    const storeModule = "usersSignupWizard";
    import {mapGetters} from 'vuex';
    import {EventBus} from '@/main';
    import LoadingSimple from '@/components/LoadingSimple.vue';

    export default {
        name: 'WizardForm',
        props: {
            clazz: String,
            activeVertical: {type: Boolean, default: false},
            originalFooter: {type: Boolean, default: false},
            hideFooter: {type: Boolean, default: false},
            id: {type: String, default: 'wizard'}
        },
        components: {
            LoadingSimple
        },
        mounted() {
            // Listen for event bus
            EventBus.$on(`${this.id}#next`, () => {
                if (this.$refs.wizard && this.$refs.wizard.nextTab) {
                    this.$refs.wizard.nextTab();
                }
            });
            EventBus.$on(`${this.id}#prev`, () => this.$refs.wizard.prevTab());
            EventBus.$on(`${this.id}#goTo`, ({from, to}) => this.$refs.wizard? this.$refs.wizard.changeTab(from, to): null);
        },
        data() {
            return {
                verticalWizard: false,
                window: {
                    width: 0
                },
                loading: false
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            EventBus.$on("NEXT_STEP_WIZARD", (actualStep, paramsStep) => {
                this.$store.dispatch(`${storeModule}/makeActionForStep`, {actualStep, paramsStep});
            });

        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
                if (window.innerWidth < 992) {
                    this.verticalWizard = false;
                } else {
                    this.verticalWizard = true;
                }
            },
            async makeActionForStep(actualStep, props) {
                this.loading = true;

                const paramsStep = {
                    isPhoto: true,
                    userId: this.$session.get('userId')
                };
                const response = await this.$store.dispatch(`${storeModule}/makeActionForStep`, {
                    actualStep,
                    paramsStep
                });
                if (response.data.error) {
                    this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, true);
                    this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, response.data.message);
                } else {
                    this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, false);
                    this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, '');
                    this.loading = false;
                    props.nextTab();
                }

                this.loading = false;
            },
            async confirmWizard(actualStep) {

                this.loading = true;

                const paramsStep = {
                    isPhoto: true,
                    userId: this.$session.get('userId')
                };
                const response = await this.$store.dispatch(`${storeModule}/makeActionForStep`, {
                    actualStep,
                    paramsStep
                });
                if (response.data.error) {
                    this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, true);
                    this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, response.data.message);
                } else {
                    this.$router.push("/wizard-success");
                    this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, false);
                    this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, '');
                }

                this.loading = false;

            },
            makeActionForStepWithParams(actualStep, paramsStep) {

                this.loading = true;

                this.$store.dispatch(`${storeModule}/makeActionForStep`, {actualStep, paramsStep});

                this.loading = false;

            },
            async makeSaveFormStepWithParams(props) {
                if (this.getIsValidStepFormSave) {
                    this.loading = true;
                    const response = await this.$store.dispatch(`${storeModule}/saveFormStep`, {actualStepForm: this.getFormStep});
                    if (response.error) {
                        this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, true);
                        this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, response.message);
                    } else {
                        this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, false);
                        this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, '');
                        if (this.getFormStep === 4) {
                            const actualStep = 1;
                            this.makeActionForStep(actualStep, props);
                        }
                    }
                }
                this.loading = false;
            },
            makeBackFormStep() {
                const newStep = this.getFormStep - 1;
                this.$store.commit(`${storeModule}/SET_FORM_STEP`, newStep);
            },
            makeBack(actualStep) {
                const paramsStep = {
                    isPhoto: this.getIsPhoto
                }
                this.$store.dispatch(`${storeModule}/makeBack`, {actualStep, paramsStep});
                this.$store.commit(`${storeModule}/SET_ERROR_NEXT_STEP`, false);
                this.$store.commit(`${storeModule}/SET_ERROR_MESSAGE_NEXT`, '');
            },
            editInfo() {
                this.$refs.wizard.maxStep = 1;
                this.$refs.wizard.navigateToTab(1);
                this.$store.commit(`${storeModule}/IS_PHOTO`, false);
                this.$store.commit(`${storeModule}/SET_FORM_STEP`, 1);
            },
            changeStep(prevIndex, nextIndex) {
                this.$emit('step-has-changed', prevIndex, nextIndex);
            }
        },
        computed: {
            ...mapGetters(storeModule, ['getFormStep',
                'getIsPhoto',
                'getShowValidateIneButton',
                'getIneValidated',
                'getIneValid',
                'getActualUser',
                'getFormStep',
                'getShowBackButtonForm',
                'getShowNextButtonForm',
                'getIsValidStepFormSave',
                'getloadingIneData',
                'getSelfieCropped',
                'getTakeSelfieLaterCheckbox',
                'getIsValidStepFormSave',
                'getIsValidStepFourWizard'
            ])
        },
    }
</script>
