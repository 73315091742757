<template>
    <div class="login-container" v-bind:class="clazz">
        <div class="body-container">
            <div class="left-section">
                <div class="form-elements">
                    <div class="header-content" v-show="clazz !== 'full-page'">
                        <div class="w-100 d-flex">
                            <img class="img-fluid" src="@/assets/images/Logos/pagandochecklogo-fullcolor.svg" alt="Pagando Logo"/>

                            <button
                                    id
                                    class="button-circle sm button-filled button-info m-0-0-0-auto"
                                    v-b-modal.modal-info-responsive
                                    type="button"
                            >
                                <i aria-hidden="true" class="banana banana-question-circle1"></i>
                            </button>
                        </div>
                    </div>

                    <div class="body-content">
                        <slot name="formSectionBody"></slot>
                    </div>
                    <!-- //Quitar d-none y generar slot para footer  -->
                    <div class="footer-content d-none"></div>
                </div>
                <!-- //O aquí puede ir el footer.  -->
            </div>

            <div class="info-section">

                <!--Inicio de sesión y registro-->
                <div class="top-info" v-if="signInRegisterStep">
                    <label>Paso 1</label>
                    <h1>
                        {{ signInRegisterTitle }} </h1>
                    <p>
                        {{ signInRegisterInstructions}}</p>
                    <!--Imagen de panel de control-->
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-login.png"
                            alt="Illustration"
                    />
                </div>

                <!--Verificación de correo-->
                <div class="top-info" v-if="confirmEmailStep">
                    <label>Paso 1</label>
                    <h1>Inicia sesión o registrate</h1>
                    <p>
                        Pedimos la verificación de tu correo electrónico para poderte ofrecer una mayor seguridad y protección a tu cuenta y toda tu información</p>
                    <!--Imagen de mujer sentada contemplando su existencia-->
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-login.svg"
                            alt="Illustration"
                    />
                </div>


                <!--Datos personales-->
                <div class="top-info" v-if="personalInformationStep">
                    <label>Paso 1</label>
                    <h1>Inicia sesión o registrate</h1>
                    <p>
                        Antes de poder iniciar el registro de tu organización debes registrarte como usuario o iniciar sesión si ya tienes cuenta.</p>
                    <!--Imagen de persona viendo monitor gigante-->
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-login.svg"
                            alt="Illustration"
                    />
                </div>

                <!--Medidas de seguridad-->
                <div class="top-info" v-if="securityMeasuresStep">
                    <label>Paso 1</label>
                    <h1>Inicia sesión o registrate</h1>
                    <p>
                        Antes de poder iniciar el registro de tu organización debes registrarte como usuario o iniciar sesión si ya tienes cuenta.</p>
                    <!--Imagen de candados super seguros-->
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-login.svg"
                            alt="Illustration"
                    />
                </div>


                <!-- LOGIN / REGISTER SECTION -->
                <div class="top-info" v-if="easyPay">
                    <h1>Paga servicios fácil y rápido</h1>
                    <p>Entra a tu cuenta Pagando y haz el pago de tus servicios favoritos desde cualquier lugar.</p>
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-login.svg"
                            alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="emailVerify">
                    <h1>Verificación de correo electrónico</h1>
                    <p>
                        Pedimos la verificación de tu correo electrónico para poderte ofrecer una mayor seguridad y proteccion a tu cuenta y toda tu información.</p>
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-mailverify.svg"
                            alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="doNotWorry">
                    <h1>¡No te preocupes, a todos nos pasa!</h1>
                    <p>Sigue las instrucciones para que puedas recuperar de nuevo el acceso a tu cuenta de Pagando.</p>
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-mailverify.svg"
                            alt="Illustration"
                    />
                </div>

                <!-- WIZARD SECTION -->
                <div class="top-info" v-if="stepTwo && first">
                    <label>Paso 2</label>
                    <h1>{{$t('sign-in.onboarding.img.title')}}</h1>
                    <p>{{$t('sign-in.onboarding.img.desc')}}</p>
                    <img
                        class="img-fluid m-0-auto m-b-30"
                        src="@/assets/images/Illustrations/illustration-summary.svg"
                        alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="stepTwo && !first">
                    <label>Paso 2</label>
                    <h1>Datos Generales</h1>
                    <p>
                        Estos datos seran visibles en el micrositio de tu empresa y ayudarán a tus clientes actuales y potenciales a entender a qué se dedica tu organización</p>
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/ilustracion-datos-generales.svg"
                            alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="stepThree">
                    <label>Paso 3</label>
                    <h1>Datos fiscales</h1>
                    <p>
                        Los datos fiscales son imprescindibles para poder facturar a tus clientes, ya que son tu acreditación frente a hacienda.</p>
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/ilustracion-datos-fiscales.svg"
                            alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="stepFour">
                    <label>Paso 4</label>
                    <h1>Tu toque personal</h1>
                    <p>
                        La imagen de tu sitio es fundamental para incrementar las ventas y para aumentar la confianza que depositan los clientes en ti.</p>
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/ilustracion-personalizacion.svg"
                            alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="stepFive">
                    <label>Paso 5</label>
                    <h1>Datos Bancarios</h1>
                    <p>
                        En ésta sección nos proporcionarás los datos de la cuenta a la cual depositaremos
                         lo recaudado por el pago de tus clientes. ¡Tus datos están seguros con nosotros!</p>
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/ilustracion-datos-bancarios.svg"
                            alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="stepSix">
                    <label>Paso 6</label>
                    <h1>Documentación</h1>
                    <p>
                        Sube la documentación completa y correcta que se te pide para agilizar tu proceso de validación</p>
                    <!--Imagen de mujer con su examen de mate listo para ponerlo en el refri-->
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/ilustracion-datos-adicionales.svg"
                            alt="Illustration"
                    />
                </div>

                <div class="top-info" v-if="stepSeven">
                    <label>Paso 7</label>
                    <h1>Ubicación</h1>
                    <p>
                        Ubica tu organización en el mapa para poder ofrecer este dato a tus clientes</p>
                    <!--Imagen de mujer con su examen de mate listo para ponerlo en el refri-->
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/ilustracion-datos-adicionales.svg"
                            alt="Illustration"
                    />
                </div>

                <!--Resumen -->
                <div class="top-info" v-if="summaryStep">
                    <h1>¡Todo Listo!</h1>
                    <p>
                        Revisa que toda la información esté en orden, si es necesario, puedes regresar a editarla. Opcionalmente puedes añadir colaboradores a la organización</p>
                    <!--Imagen de checklist en camión repartidor de pan sin el osito bimbo porque pues ya murio T.T-->
                    <img
                            class="img-fluid m-0-auto m-b-30"
                            src="@/assets/images/Illustrations/illustration-resumen.svg"
                            alt="Illustration"
                    />
                </div>


                <div class="bottom-info">
                    <p class="bottom-info-text">
                        Si requieres asistencia técnica, por favor contáctanos a los teléfonos:
                        <strong>800 461 0119</strong> para México.
                    </p>
                </div>
            </div>
        </div>
        <div class="footer-container">
            <small class="text-footer" v-html="$t('general.copyright', {year: currentYear})"></small>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SignInBody",
        data() {
            return {
                securityMeasuresStep: false,
                emailConfirmed: false,
                signInRegisterStep: false,
                personalInformationStep: false,
                currentYear: 0
            }
        },
        props: {
            clazz: String,
            // LOGIN / REGISTER SECTION
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false},
            easyPay: {type: Boolean, default: false},
            emailVerify: {type: Boolean, default: false},
            doNotWorry: {type: Boolean, default: false},
            // WIZARD SECTION
            stepOne: {type: Boolean, default: false},
            first: {type: Boolean, default: false},
            stepTwo: {type: Boolean, default: false},
            stepTwoForm: {type: Boolean, default: false},
            stepThree: {type: Boolean, default: false},
            stepFour: {type: Boolean, default: false},
            stepFive: {type: Boolean, default: false},
            stepSix: {type: Boolean, default: false},
            stepSeven: {type: Boolean, default: false},
            summaryStep: {type: Boolean, default: false},
            confirmEmailStep: {type: Boolean, default: false},
            bodyProp: {type: String, default: ""}
        },
        computed: {
            signInRegisterInstructions: function () {
                if (this.signInRegisterStep && this.emailConfirmed) {
                    return "Ahora inicia sesión con tu cuenta para continuar con tu registro de organización."
                }
                return "Antes de poder iniciar el registro de tu organización debes registrarte como usuario o iniciar sesión si ya tienes cuenta."
            },
            signInRegisterTitle: function () {
                if (this.signInRegisterStep && this.emailConfirmed) {
                    return "Inicia sesión si ya validaste tu correo";
                }
                return "Inicia sesión o regístrate";
            }
        },
        mounted() {
            this.currentYear = new Date().getFullYear();
            if (this.$props && this.$props.bodyProp !== "") {
                this.securityMeasuresStep = false;
                this.emailConfirmed = false;
                this.signInRegisterStep = false;
                this.personalInformationStep = false;

                this[this.$props.bodyProp] = true;
            }
        },
        watch: {
            bodyProp(value){
                if(value && value !== ""){
                    this.securityMeasuresStep = false;
                    this.emailConfirmed = false;
                    this.signInRegisterStep = false;
                    this.personalInformationStep = false;
                    this[value] = true;
                }
            }
        }
    };
</script>
