import axios from 'axios'
import base from "@/api/base.api";
const namespace = "general-ledger";

export default {
    getPolizaMovementsOrganization: (params) => {
        return axios
            .post(`${base.baseUrl}/${namespace}/retrieve-poliza-movements-organization/`, params)
    },
    csvOrganization: (params) => {
        return axios
            .post(`${base.baseUrl}/${namespace}/csv-organization`, params)
            .then((response) => response.data.object);
    },
};
