<template>
    <div class="card" v-bind:class="clazz">
        <div class="title-card" :class="[centerContent ? 'center-content': '', clazzTitle]" v-if="!hideTitle">
            <h1>
                {{title}}
                <label v-if="subtitle">{{subtitle}}</label>
            </h1>
            <span class="buttons"><slot name="titleButtons"></slot></span>
        </div>
        <div class="body-card" :class="clazzBody">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardSimple',
        props: {
            title: String,
            subtitle: String,
            clazz: String,
            clazzTitle: String,
            clazzBody: String,
            centerContent: {type: Boolean, default: false},
            hideTitle: {type: Boolean, default: false}
        }
    }
</script>
