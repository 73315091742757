import router from '../../router'
import userApi from '../../api/user/users.api';
import store from '@/store/index'
import axios from 'axios'

const stateGlobal = {
    currentUser: null,
    codeEmail: null,
    currentToken: null,
    userDashboard: null,
    loadingDashboardUser: true,
    showAlert: false,
    registerErrorMessage: "",
    isLoadingRegister: false,
    isLoadingLogin: false,
    currentEmail: "correo@correo.com",
    isLoadingVerification: false,
    variantAlert: "danger",
    alertMessage: "",
    loginBlocked: false,
    loginAlert: false,
    loginAlertMessage: "",
    rightSide: "STEP_ONE",
    userFound: false,
    userPhrase: "",
    userImageId: "",
    userFullName: "",
    query: null,
    isSocialMediaUser: false,
    userPhone: null,
    isOwner: false,
    hasValidSession: false,
    sessionModal: false,
    activeSessions: 1,
    userProfile: '',
    organization: null
};

import {
    STORE as SESSION_STORE,
    SET_USER,
    SET_TOKEN,
    SET_TOKEN_EXPIRE_DATE
} from '@/store/modules/session/session.types';

import {
    STORE as ACCESS_CONFIG,
    SET_CURRENT_ORGANIZATION,
    GET_CURRENT_ORGANIZATION,
} from '@/store/modules/accessconfig/accessConfig.types';


const gettersGlobal = {
    getCurrentUser: (state) => state.currentUser,
    getShowAlert: (state) => state.showAlert,
    getAlertMessage: (state) => state.alertMessage,
    getAlertVariant: (state) => state.variantAlert,
    getIsLoadingRegister: (state) => state.isLoadingRegister,
    getIsLoadingLogin: (state) => state.isLoadingLogin,
    getIsLoadingVerification: (state) => state.isLoadingVerification,
    getCurrentEmail: (state) => state.currentEmail,
    getLoginBlocked: (state) => state.loginBlocked,
    getLoginAlert: (state) => state.loginAlert,
    getLoginAlertMessage: (state) => state.loginAlertMessage,
    getRightSide: (state) => state.rightSide,
    getUserFound: (state) => state.userFound,
    getUserPhrase: (state) => state.userPhrase,
    getUserImageId: (state) => state.userImageId,
    getUserFullName: (state) => state.userFullName,
    getQuerySocialMedia: (state) => state.query,
    getIsSocialMediaUser: (state) => state.isSocialMediaUser,
    getUserPhone: (state) => state.userPhone,
    getIsOwner: (state) => state.isOwner,
    getHasValidSession: (state) => state.hasValidSession,
    getSessionModal: (state) => state.sessionModal,
    getActiveSessions: (state) => state.activeSessions,
    getUserProfile: (state) => state.userProfile,
    getOrganization: (state) => state.organization
};

const actions = {
    async signUp({ dispatch, commit }, { credentials }) {
        commit('SET_LOADING_REGISTER', true);
        const response = await dispatch(`SIGNIN_UP/save`, {
            email: credentials.email,
            password: credentials.password,
            isRegisterOrganization: true
        }, { root: true });
        if (response.data.error) {
            commit(`SET_ALERT_MESSAGE`, {
                variant: "danger",
                message: response.data.message
            });
            commit('SET_LOADING_REGISTER', false);
        } else {
            const email = response.data.data.email;
            router.push({ name: "SigninRegisterSuccess", params: { email: email } })
        }
    },
    async save(hold, data) {
        try {
            return await userApi.save(data);
        } catch (err) {
            return {
                data: {
                    error: true,
                    message: err
                }
            }
        }
    },

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async login({ commit }, { credentials}) {
        commit('setLoginAlert', false)
        commit('SET_LOADING_LOGIN', true);
        const response = await userApi.login({ email: credentials.email, password: credentials.password, userType: "OWNER" });
        if (response.data) {
            if (response.data.error) {
                if(response.data.errorCode === "login:organizations_deactivated"){
                    router.push({ name: "OrganizationsDeactivated"})
                }
                if (response.data.data && response.data.data.user) {
                    if (response.data.data.user.loginBlocked || response.data.attempts >= 6) {
                        commit('setLoginBlocked', true)
                    } else {
                        commit('setLoginAlert', true)
                    }
                } else {
                    commit('setLoginAlert', true)
                }
                if (response.data.message && response.data.message.length > 0) {
                    commit('setLoginAlertMessage', response.data.message);
                }
            } else {
                // Logged in correctly
                const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
                localStorage.setItem('currentUser', JSON.stringify(response.data.data.user));
                store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.data.user);
                const user = response.data.data.user;
                localStorage.setItem('currentToken', JSON.stringify(response.data.data.token));
                const validToken = response.data.data.token.replace(/"/g, "");
                store.commit(`${SESSION_STORE}/${SET_TOKEN}`, validToken);
                axios.defaults.headers.common.Authorization = `Bearer ${validToken}`;
                store.commit(`${SESSION_STORE}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
                localStorage.setItem('currentPermissions', JSON.stringify(response.data.data.permissions));
                localStorage.setItem('profile', JSON.stringify(response.data.data.profile));
                localStorage.setItem('expireDate', JSON.stringify(expireDate));
                commit('SET_HAS_VALID_SESSION',true);
                commit('setUserProfile', user.profile);
                commit('setOrganization', response.data.data.organization);
                const checkActiveSessions = await userApi.checkActiveSessions();
                commit('setActiveSessions', checkActiveSessions.data.object);
                if(checkActiveSessions.data.object > 1){
                    commit('showSessionsModal', true);
                } else {
                    if(user.profile === "OWNER"){
                        if(response.data.data.organization){
                            localStorage.setItem(GET_CURRENT_ORGANIZATION, JSON.stringify(response.data.data.organization));
                            store.commit(`${ACCESS_CONFIG}/${SET_CURRENT_ORGANIZATION}`, response.data.data.organization);
                        }
                        router.push({ name: "dashboard" })
                    } else {
                        router.push({ name: "SignUpOrganizations"})
                    }
                    commit('CLEAR_STORE');
                }
            }
        } else {
            commit('setLoginAlert', true)
        }
    },
    agreeCloseSessions({ commit, getters }) {
        if(getters.getUserProfile === "OWNER"){
            if(getters.getOrganization){
                localStorage.setItem(GET_CURRENT_ORGANIZATION, JSON.stringify(getters.getOrganization));
                store.commit(`${ACCESS_CONFIG}/${SET_CURRENT_ORGANIZATION}`, getters.getOrganization);
            }
            router.push({ name: "dashboard" })
        } else {
            router.push({ name: "SignUpOrganizations"})
        }
        commit('CLEAR_STORE');
        commit('clearAfterLogin');
    },
    async loginSocialMediaGoogle({ commit, getters }) {
        commit('setLoginAlert', false)
        commit('SET_LOADING_LOGIN', true);
        const params = getters.getQuerySocialMedia;
        params.userType = "OWNER"
        const response = await userApi.loginSocialMedia(params);
        if (response.data.error) {
            commit('setLoginAlert', true)
            commit('setLoginAlertMessage', response.data.message)
            router.push('/signin')
        } else {
            const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
            localStorage.setItem('currentUser', JSON.stringify(response.data.object.user));
            store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.object.user);
            const user = response.data.object.user;
            localStorage.setItem('currentToken', JSON.stringify(response.data.object.token));
            const validToken = response.data.object.token.replace(/"/g, "");
            store.commit(`${SESSION_STORE}/${SET_TOKEN}`, validToken);
            axios.defaults.headers.common.Authorization = `Bearer ${validToken}`;
            store.commit(`${SESSION_STORE}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
            localStorage.setItem('currentPermissions', JSON.stringify(response.data.object.permissions));
            localStorage.setItem('profile', JSON.stringify(response.data.object.profile));
            localStorage.setItem('expireDate', JSON.stringify(expireDate));
            commit('SET_USER_FOUND', true)
            commit('SET_LOADING_LOGIN', false);
            commit('SET_USER_PHRASE', response.data.object.user.securityPhrase)
            commit('SET_USER_IMAGE_ID', response.data.object.user.securityImageId)
            commit('SET_USER_FULL_NAME', response.data.object.user.name)
            commit('SET_IS_SOCIAL_MEDIA_USER', true)
            commit('SET_USER_PHONE', response.data.object.userPhone)

            commit('setUserProfile', user.profile);
            commit('setOrganization', response.data.data.organization);
            const checkActiveSessions = await userApi.checkActiveSessions();
            commit('setActiveSessions', checkActiveSessions.data.object);
            if(checkActiveSessions.data.object > 1){
                commit('showSessionsModal', true);
            } else {
                if(user.profile === "OWNER"){
                    commit('SET_IS_OWNER', true)
                    if(response.data.object.organization){
                        localStorage.setItem(GET_CURRENT_ORGANIZATION, JSON.stringify(response.data.object.organization));
                        store.commit(`${ACCESS_CONFIG}/${SET_CURRENT_ORGANIZATION}`, response.data.object.organization);
                    }
                } else {
                    commit('SET_IS_OWNER', false)
                    router.push({ name: "SignUpOrganizations"})
                }
                router.push('/signin')
            }
        }
    },
    async loadUserForSocialMedia({ commit}, id) {
        commit('setLoginAlert', false)
        commit('SET_LOADING_LOGIN', true);
        const response = await userApi.confirmFaceookLogin({id, userType: "OWNER"});
        if (response.data.error) {
            commit('CLEAR_STORE');
            commit('setLoginAlert', true)
            commit('setLoginAlertMessage', response.data.message)
            router.push('/signin')
        } else {
            const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
            localStorage.setItem('currentUser', JSON.stringify(response.data.object.user));
            store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.object.user);
            const user = response.data.object.user;
            localStorage.setItem('currentToken', JSON.stringify(response.data.object.token));
            const validToken = response.data.object.token.replace(/"/g, "");
            store.commit(`${SESSION_STORE}/${SET_TOKEN}`, validToken);
            axios.defaults.headers.common.Authorization = `Bearer ${validToken}`;
            store.commit(`${SESSION_STORE}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
            localStorage.setItem('currentPermissions', JSON.stringify(response.data.object.permissions));
            localStorage.setItem('profile', JSON.stringify(response.data.object.profile));
            localStorage.setItem('expireDate', JSON.stringify(expireDate));
            commit('SET_USER_FOUND', true)
            commit('SET_LOADING_LOGIN', false);
            commit('SET_USER_PHRASE', response.data.object.user.securityPhrase)
            commit('SET_USER_IMAGE_ID', response.data.object.user.securityImageId)
            commit('SET_USER_FULL_NAME', response.data.object.user.name)
            commit('SET_IS_SOCIAL_MEDIA_USER', true)
            commit('SET_USER_PHONE', response.data.object.userPhone)

            commit('setUserProfile', user.profile);
            commit('setOrganization', response.data.data.organization);
            const checkActiveSessions = await userApi.checkActiveSessions();
            commit('setActiveSessions', checkActiveSessions.data.object);
            if(checkActiveSessions.data.object > 1){
                commit('showSessionsModal', true);
            } else {
                if(user.profile === "OWNER"){
                    commit('SET_IS_OWNER', true)
                    if(response.data.object.organization){
                        localStorage.setItem(GET_CURRENT_ORGANIZATION, JSON.stringify(response.data.object.organization));
                        store.commit(`${ACCESS_CONFIG}/${SET_CURRENT_ORGANIZATION}`, response.data.object.organization);
                    }
                } else {
                    commit('SET_IS_OWNER', false)
                    router.push({ name: "SignUpOrganizations"})
                }
                router.push('/signin')
            }
        }
    },
    agreeCloseSocialSessions({ commit, getters }) {
        if(getters.getUserProfile === "OWNER"){
            commit('SET_IS_OWNER', true)
            if(getters.getOrganization){
                localStorage.setItem(GET_CURRENT_ORGANIZATION, JSON.stringify(getters.getOrganization));
                store.commit(`${ACCESS_CONFIG}/${SET_CURRENT_ORGANIZATION}`, getters.getOrganization);
            }
        } else {
            commit('SET_IS_OWNER', false)
            router.push({ name: "SignUpOrganizations"})
        }
        router.push('/signin')
        commit('clearAfterLogin');
    },
    async getUserByEmail({ commit }, { email}){
        const response = await userApi.getUserByEmail({ email: email});
        if(response.data.error){
            commit('setLoginAlert', true)
        } else {
            commit('SET_USER_FOUND', true)
            commit('SET_USER_PHRASE', response.data.object.securityPhrase)
            commit('SET_USER_IMAGE_ID', response.data.object.securityImageId)
            const fullName = `${response.data.object.name}`;
            commit('SET_USER_FULL_NAME', fullName)
        }
    },
    /**
     * Envia el código de verificación al teléfono registrado por el usuario.
     **/
    // eslint-disable-next-line no-empty-pattern
    SEND_VERIFICATION_CODE(context, params){
        return new Promise((resolve, reject) => {
            userApi.sendVerificationCode(params,
                function(result) {
                    if(result.data.error){
                        reject(result.data);
                    } else {
                        resolve(result.data);
                    }
                })
        });

    },

    /**
     *  Verifica el código recibido por sms.
     **/
    // eslint-disable-next-line no-empty-pattern
    VERIFY_CODE(context, params){
        return new Promise((resolve) => {
            userApi.verifyCode(params,
                function(result){
                    if(result.data.error){
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
        });
    },

    setHasValidSession({commit}, validSession){
        commit('SET_HAS_VALID_SESSION',validSession);
    },
    async resendVerificationEmail({ commit }, email) {
        commit('SET_LOADING_VERIFICATION', true);
        const response = await userApi.resendVerificationEmail({ email: email, isRegisterOrganization: true });
        if (response.data.error) {
            commit(`REGISTER_ERROR`, response.data.message);
            commit('SET_LOADING_VERIFICATION', false);
        } else {
            commit(`SET_ALERT_MESSAGE`, {
                variant: "success",
                message: response.data.message
            });
            commit('SET_LOADING_VERIFICATION', false);
        }
    },
};

const mutations = {
    SET_CURRENT_USER(state, payload) {
        if (payload) {
            const name = payload.displayName;
            const email = payload.email;
            const photoUrl = payload.photoURL;
            const emailVerified = payload.emailVerified;
            const uid = payload.uid;
            // TODO vale la pena guardar el usuario en el store
            state.currentUser = {
                name: name,
                email: email,
                photoUrl: photoUrl,
                emailVerified: emailVerified,
                uid: uid
            };
            if (this.$vue) {
                if (this.$vue.$session.has('user')) {
                    this.$vue.$session.remove('user')
                }
                this.$vue.$session.set('user', {
                    name: name,
                    email: email,
                    photoUrl: photoUrl,
                    emailVerified: emailVerified,
                    uid: uid
                });
            }
        } else {
            state.currentUser = null;
        }
    },

    SET_DASHBOARD_USER(state, user) {
        state.userDashboard = user;
    },

    SET_ALERT_MESSAGE(state, data) {
        state.showAlert = true;
        state.alertMessage = data.message;
        state.variantAlert = data.variant
    },
    SET_LOADING_REGISTER(state, value) {
        state.isLoadingRegister = value;
    },
    SET_LOADING_LOGIN(state, value) {
        state.isLoadingLogin = value;
    },
    SET_LOADING_VERIFICATION(state, value) {
        state.isLoadingVerification = value;
    },
    SET_EMAIL_SUCCESS_REGISTER(state, email) {
        state.currentEmail = email;
    },

    SET_LOADING_DASHBOARD_USER(state, value) {
        state.loadingDashboardUser = value;
    },

    setCurrentToken(state, code) {
        state.currentToken = code;
    },

    setLoginBlocked(state, value) {
        state.loginBlocked = value
        state.isLoadingLogin = false
    },

    setLoginAlert(state, value) {
        state.loginAlert = value
        state.isLoadingLogin = false
    },
    setLoginAlertMessage(state, value) {
        state.loginAlertMessage = value
    },
    SET_RIGHT_SIDE_BODY(state, value) {
        state.rightSide = value
    },
    SET_USER_FOUND(state, value) {
        state.userFound = value
    },
    SET_IS_OWNER(state, value) {
        state.isOwner = value
    },
    SET_USER_PHRASE(state, value) {
        state.userPhrase = value
    },
    SET_USER_IMAGE_ID(state, value) {
        state.userImageId = value
    },
    SET_USER_FULL_NAME(state, value) {
        state.userFullName = value
    },
    SET_QUERY_RED_SOCIAL(state, value){
        state.query = value;
    },
    SET_IS_SOCIAL_MEDIA_USER(state, value){
        state.isSocialMediaUser = value;
    },
    SET_USER_PHONE(state, value){
        state.userPhone = value;
    },
    SET_HAS_VALID_SESSION(state, value){
        state.hasValidSession = value;
    },
    CLEAR_STORE(state){
        state.currentUser = null;
        state.codeEmail = null;
        state.currentToken = null;
        state.userDashboard = null;
        state.loadingDashboardUser = true;
        state.showAlert = false;
        state.registerErrorMessage = "";
        state.isLoadingRegister = false;
        state.isLoadingLogin = false;
        state.currentEmail = "correo@correo.com";
        state.isLoadingVerification = false;
        state.variantAlert = "danger";
        state.alertMessage = "";
        state.loginBlocked = false;
        state.loginAlert = false;
        if(state.rightSide !== 'DONT_WORRY') {
            state.rightSide = "EASY_PAY";
        }
        state.userFound = false;
        state.userPhrase = "";
        state.userImageId = "";
        state.userFullName = "";
        state.query = null;
        state.isSocialMediaUser= false;
        state.userPhone= null;
        state.isOwner= false;
    },
    showSessionsModal(state, value) {
        state.sessionModal = value;
    },
    setActiveSessions(state, value) {
        state.activeSessions = value;
    },
    setUserProfile(state, value) {
        state.userProfile = value;
    },
    setOrganization(state, value) {
        state.organization = value;
    },
    clearAfterLogin (state){
        state.sessionModal = false;
        state.activeSessions = 1;
        state.userProfile = '';
        state.organization = null;
    }
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    }
};
