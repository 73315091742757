import {EventBus} from "@/main";
import {
    SUCCESS_DELETE,
    WARNING_EVENT,
    ERROR_SAVING,
    ERROR_DELETING,
    SUCCESS_SAVE,
    ERROR_LOADING
  } from '@/store/events';
import {
    GET_DOCS,
    GET_DOCS_LENGTH,
    GET_OBJECT_PAGE,
    GET_SEARCH_TEXT,
    GET_SELECTED_ITEM,
    GET_LOADING_ITEMS,
    GET_CURRENT_PAGE,
    GET_ERROR_LOADING,
    SET_CURRENT_PAGE,
    SET_DOCS,
    SET_DOCS_LENGTH,
    SET_SEARCH,
    SET_SELECTED_ITEM,
    SET_SELECTED_ITEM_VALUE,
    SET_LOADING_ITEMS,
    SET_ERROR_LOADING,
    SET_PER_PAGE,
    SET_SELECTED_ITEM_VALUE_ON_INDEX,
    SET_ADD_TO_SELECTED_IDS,
    REMOVE_FROM_SELECTED_IDS,
    GET_SELECTED_IDS,
    SET_FILTERS,
    GET_FILTERS,
    SET_SORT,
    SET_TYPE_SORT,
    GET_SORT,
    GET_TYPE_SORT,
    GET_FATHER_FILTER,
    SET_FATHER_FILTER,
    GET_FILTERS_COUNT
} from './catalog.types';
import i18n from '@/plugins/i18n';

const DELETE_ERROR_I18N_KEY = "general.delete.error";

export default function (api, storeName) {
    const stateGlobal = {
        docs: [],
        docsLength: 0,
        itemsPerPage: 10,
        searchText: "",
        platform: "",
        selectedItem: {},
        filters: {},
        loading: true,
        isErrorLoading: false,
        currentPage: 1,
        id: null,
        selectedIds: [],
        newItem: {},
        sort: "createdAt",
        typeSort: "desc",
        fatherFilter: ""
    };

    const gettersGlobal = {
        [GET_DOCS]: (state) => state.docs,
        [GET_DOCS_LENGTH]: (state) => state.docsLength,
        [GET_OBJECT_PAGE]: (state) => state.itemsPerPage,
        [GET_SEARCH_TEXT]: (state) => state.searchText,
        [GET_SELECTED_ITEM]: (state) => state.selectedItem,
        [GET_LOADING_ITEMS]: (state) => state.loading,
        [GET_CURRENT_PAGE]: (state) => state.currentPage,
        [GET_SELECTED_IDS]: (state) => state.selectedIds,
        [GET_ERROR_LOADING]: (state) => state.isErrorLoading,
        [GET_FILTERS]: (state) => state.filters,
        [GET_SORT]: (state) => state.sort,
        [GET_TYPE_SORT]: (state) => state.typeSort,
        [GET_FATHER_FILTER]: (state) => state.fatherFilter,
        [GET_FILTERS_COUNT]: (state) => {
            let filtersCounted = 0;
            let iterator;
            for (iterator in state.filters) {
                if (state.filters.hasOwnProperty(iterator)) {
                    filtersCounted++;
                }
            }
            return filtersCounted;
        }
    };

    const actions = {
        list({commit, getters}){
            if (getters.getIsErrorLoading) {
                commit(SET_LOADING_ITEMS, true);
            }
            commit(SET_ERROR_LOADING,false);
            api.list({
                currentPage: getters[GET_CURRENT_PAGE],
                perPage: getters[GET_OBJECT_PAGE],
                search: getters[GET_SEARCH_TEXT],
                filters: getters[GET_FILTERS],
                sort: getters[GET_SORT],
                typeSort: getters[GET_TYPE_SORT],
                fatherFilter: getters[GET_FATHER_FILTER]
            }, (result)=>{
                if(result.data.error === true){
                    EventBus.$emit(storeName + ERROR_LOADING, i18n.t(DELETE_ERROR_I18N_KEY))
                } else {
                    const docs = result.data.object.docs;
                    const finalDocs = docs.map(doc =>{
                        doc.selectedItem = false;
                        return doc;
                    })
                    const lengthDocs = result.data.object.totalDocs;
                    commit(SET_DOCS, finalDocs);
                    commit(SET_DOCS_LENGTH, lengthDocs);
                    commit(SET_LOADING_ITEMS, false);
                }
            }, () => {
                commit(SET_DOCS, []);
                commit(SET_DOCS_LENGTH, 0);
                commit(SET_ERROR_LOADING,true);
                commit(SET_LOADING_ITEMS, false);
            });
        },

        delete({dispatch}, objectId){
            api.delete({
                id: objectId
            }, (result)=>{
                if(!result.data.error){
                    EventBus.$emit(storeName + SUCCESS_DELETE, result.data.message)
                    dispatch(`list`, {});
                } else{
                    EventBus.$emit(storeName + WARNING_EVENT, result.data.message)
                }
            }, () => {
                EventBus.$emit(storeName + ERROR_DELETING, i18n.t(DELETE_ERROR_I18N_KEY))
            });
        },
        deleteMany({dispatch, getters}){
            api.deleteMany({
                ids: getters[GET_SELECTED_IDS]
            }, (result)=>{
                if(result.data.error === true){
                    EventBus.$emit(storeName + WARNING_EVENT, result.data.message)
                } else{
                    EventBus.$emit(storeName + SUCCESS_DELETE, result.data.message)
                    dispatch(`list`, {});
                }
            }, () => {
                EventBus.$emit(storeName + ERROR_DELETING, i18n.t(DELETE_ERROR_I18N_KEY))
            });
        },

        save ({dispatch}, object) {
            api.save(
                object,
                (result) => {
                    if(result.data.error === true){
                        EventBus.$emit(storeName + ERROR_SAVING, result.data.message)
                    } else{
                        if(result.data.message){
                            EventBus.$emit(storeName + SUCCESS_SAVE, i18n.t(result.data.message))
                        } else {
                            EventBus.$emit(storeName + SUCCESS_SAVE, "Ha ocurrido un error al salvar")
                        }
                        dispatch(`list`, {});
                    }
                },
                () => {
                    EventBus.$emit(storeName + ERROR_SAVING, i18n.t("general.error.request"))
                }
            )
        }
    };

    const mutations = {
        [SET_CURRENT_PAGE]: (state, data) =>{
            state.currentPage = data;
        },
        [SET_DOCS]: (state, docs)=>{
            state.docs = docs;
        },
        [SET_DOCS_LENGTH]: (state, docsLength)=>{
            state.docsLength = docsLength;
        },
        [SET_SEARCH]: (state, searchText)=>{
            state.searchText = searchText;
        },
        [SET_SELECTED_ITEM]: (state, item)=>{
            state.selectedItem = item;
        },
        [SET_SELECTED_ITEM_VALUE]: (state, data)=>{
            const key = Object.keys(data)[0];
            state.selectedItem[key]=data[key];
        },
        [SET_SELECTED_ITEM_VALUE_ON_INDEX]: (state, data)=>{
            const key = Object.keys(data.object)[0];
            state.docs[data.index][key]=data.object[key];
        },
        [SET_LOADING_ITEMS]: (state, loading)=>{
            state.loading = loading;
        },
        [SET_ERROR_LOADING]: (state, value)=>{
            state.isErrorLoading = value;
        },
        [SET_PER_PAGE]: (state, limit)=>{
            state.itemsPerPage = limit;
        },
        [SET_ADD_TO_SELECTED_IDS]: (state, value)=>{
            state.selectedIds.push(value);
        },
        [REMOVE_FROM_SELECTED_IDS]: (state, value)=>{
            state.selectedIds.splice(state.selectedIds.indexOf(value.toString()), 1);
        },
        [SET_FILTERS]: (state, filters)=>{
            state.filters = filters;
        },
        [SET_SORT]: (state, sort)=>{
            state.sort = sort;
        },
        [SET_TYPE_SORT]: (state, typeSort)=>{
            state.typeSort = typeSort;
        },
        [SET_FATHER_FILTER]: (state, fatherFilter)=>{
            state.fatherFilter = fatherFilter;
        },
    };

    return {
        state: stateGlobal,
        getters: gettersGlobal,
        actions,
        mutations
    }
}
