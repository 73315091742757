<template>
    <div :id="idForm" class="form-group" :class="clazz">
        <div class="form-control input-tags-container">
            <Badge
                type="badge-accent_2"
                v-for="value in values" :key="value"
                :text="value"
                closeBtnShow
                @deleteValue="deleteValue(value)"
                ></Badge>

            <input :id="inputId" :type="inputType" class="input-tags" :class="inputClazz"
                   :placeholder="inputPlaceholder" :value="inputValue" aria-label="" :disabled="disabled"
                   :name="name" @input="onBasicInput"/>
        </div>

        <div class="input-label">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i aria-hidden="true" :class="warningIcon || warningIconComputed"></i>
            {{warningText || warningTextComputed}}
        </small>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Badge from '@/components/Badge.vue';
    import utils from '@/common/utils';
    export default {
        name: 'InputTags',
        props: {
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            required: {type: Boolean, default: false},
            label: String,
            helpText: String,
            warningText: String,
            warningTextComputed: String,
            idForm: String,
            inputPlaceholder: String,
            helpIcon: String,
            warningIcon: String,
            warningIconComputed: String,
            labelFor: String,
            inputId: String,
            clazz: String,
            inputType: String,
            disabled: {type: Boolean, default: false},
            inputClazz: String,
            name: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                values: [],
                inputValue: ""
            }
        },
        components: {
            Badge
        },
        methods: {
            onBasicInput(event) {
                this.inputValue = event.target.value;
                utils.debounceFixed(() => {
                    const value= event.target.value;
                    const valuesSplit= value.split(',');
                    valuesSplit.forEach((val)=> {
                        if(this.validateEmail(val)){
                            this.values.push(val);
                            this.inputValue = this.inputValue.replace(val, "");
                        }
                    });
                    if(this.values.length > 0){
                        this.$emit('input', this.values);
                    }
                });
            },
            deleteValue(value) {
                this.values.splice(this.inputValue.indexOf(value), 1);
            },
            validateEmail(email) {
                var re = /\S+@\S+\.\S+/;
                return re.test(email);
            }
        }
    }
</script>
