<template>
    <div>
        <b-tabs class="simple-tabs" content-class="simple-tabs-body" v-model="tabIndex">
            <b-tab :title="$t('sign-in.login-tab')" active>
                <GenericTitle :title="getUserFound ? $t('sign-in.main-title-after-user') : $t('sign-in.main-title')"
                              :titleStrong="getUserFound ? (getUserFullName !== undefined ? getUserFullName : '') : $t('sign-in.main-title-strong')"
                              :subtitle="getUserFound ? $t('sign-in.subtitle-after-user') : $t('sign-in.subtitle')"></GenericTitle>

                <b-alert id="loginBlocked" class="alert-note" show variant="danger" v-if="getLoginBlocked">
                    <p class="text-container"><img class="img-fluid"
                                                   src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                   alt=""/>{{$t('sign-in.login-blocked')}}</p>
                </b-alert>

                <b-alert id="signinDataWrong" class="alert-note" show variant="danger" v-if="getLoginAlert">
                    <p class="text-container"><img class="img-fluid"
                                                   src="@/assets/images/Icons/Alerts-icons/icon-alert-error.svg"
                                                   alt=""/>{{getLoginAlertMessage !== "" ? getLoginAlertMessage :
                        $t('sign-in.login-incorrect-data')}}</p>
                </b-alert>
                <BasicInput
                        v-show="!getUserFound"
                        required
                        inputType="text"
                        labelFor="txt-emailSignIn"
                        inputId="txt-emailSignIn"
                        :label="$t('sign-in.email-label')"
                        :inputPlaceholder="$t('sign-in.email-placeholder')"
                        v-on:basicInput="onBasicInputLogin"
                ></BasicInput>
                <UploadProfileImage
                        v-show="getUserFound"
                        :imgUser="getImageById(getUserImageId)"
                        imgUploaded
                        showInfoUser
                        clazz="horizontal-info sm user-verification-login m-b-30"
                        :userName="getUserPhrase">
                </UploadProfileImage>
                <PasswordInput
                        required
                        v-if="getUserFound && !getIsSocialMediaUser"
                        :inputType="passwordInputType"
                        labelFor="txt-passwordSignIn"
                        inputId="txt-passwordSignIn"
                        :label="$t('sign-in.password-label')"
                        :hasLevelBar="false"
                        :isVisibleText="isVisibleTextPasswordSignIn"
                        :inputPlaceholder="$t('sign-in.password-placeholder')"
                        @enterKey="login"
                        v-on:passwordInput="onPasswordChangeLogin"
                        v-on:passwordInputType="changeTypePassword">
                </PasswordInput>
                <button id="btn-signInDefault" v-if="!getIsLoadingLogin && !getUserFound"
                        class="button xs button-filled button-accent1 m-t-40 m-b-20 w-100"
                        :class="{'button-disable':disabledUserFound}" type="submit" :disabled="disabledUserFound"
                        @click.prevent="getUserByEmail"> {{$t('sign-in.login-button')}}
                </button>
                <a v-if="!getIsSocialMediaUser" id="recoveryPassword" @click="goRecoveryPassword()"
                   class="f-16 c-accent_2 body-font-bold text-underline d-block text-align-c m-b-40 hover" tabindex="">
                    {{$t('sign-in.forgot-password')}} </a>

                <div class="d-flex justify-content-between m-t-150 m-b-40"
                     v-if="!getIsLoadingLogin && getUserFound && !getIsSocialMediaUser">
                    <button id="btn-comeBack" class="button xs button-ghost button-plain_text m-r-10"
                            @click.prevent="comeBackUserSearch"><i aria-hidden="true" class="banana banana-chevron-left"></i> REGRESAR
                    </button>
                    <button id="btn-loginDefault" :class="{'button-disable':disabledSignIn}"
                            class="button sm button-filled button-accent1" :disabled="disabledSignIn"
                            @click.prevent="login">CONTINUAR
                    </button>
                </div>
                <button type="button" v-if="getIsLoadingLogin"
                        class="button xs button-filled button-accent1 m-b-40 w-100">
                    <LoadingSimple clazz="small-load no-text"/>
                </button>

                <!-- <SocialButtons :span="$t('sign-in.want-save-time')" v-if="!getUserFound">
                    <a id="link-signInFacebook" class="social-btn fb" tabindex=""
                       :href="`${ localUrl }/auth/facebook/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/facebook-icon.svg" alt="facebook"/>
                        <strong> {{$t('sign-in.facebook')}} </strong>
                    </a>
                    <a id="link-signInTwitter" class="social-btn tw" tabindex=""
                       :href="`${ localUrl }/auth/twitter/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/twitter-icon.svg" alt="twitter"/>
                        <strong> {{$t('sign-in.twitter')}} </strong>
                    </a>
                    <a id="link-signInGoogle" class="social-btn goo" tabindex=""
                       :href="`${ localUrl }/auth/google/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/google-icon.svg" alt="google"/>
                        <strong> {{$t('sign-in.google')}} </strong>
                    </a>
                    <a id="link-signUpappleId" class="social-btn apple" tabindex=""
                       :href="`${ localUrl }/auth/apple/client`">
                        <img class="img-fluid" src="@/assets/images/Icons/apple-icon.svg" alt="google"/>
                        <strong> Apple Id </strong>
                    </a>
                </SocialButtons> -->

                <div v-if="getUserFound" class="social-container"></div>
                <!-- <p class="link-to m-t-60 m-b-40"> {{$t('sign-in.no-pagando-account-yet')}}
                    <router-link :to="{name: 'SigninTab'}" class="hover" tabindex="">
                        {{$t('sign-in.no-pagando-account-yet-register')}}
                    </router-link>
                </p> -->
            </b-tab>
            <!-- <b-tab :title="$t('sign-in.signin-tab')" @click="goToRegisterClient"> -->
            <!-- </b-tab> -->
        </b-tabs>
        <!-- Modal for showing 'info-section' on responsive -->
        <b-modal id="modal-info-responsive" hide-header hide-footer>
            <button
                    id
                    class="button-circle sm button-stroke button-info_text m-0-0-0-auto"
                    @click="$bvModal.hide('modal-info-responsive')"
                    type="button"
            >
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <SignInBody :rightSide="getRightSide" hideRightWizard/>
        </b-modal>
        <!-- Default modal -->
        <ModalDefault
                ref="modal-social-media"
                :customRef="'modal-social-media-default'"
                modalId="modal-social-media-default"
                originalFooter
                :closeModalAfterAccept="false"
                title="Autoriza tu acceso"
                subtitle="Por la seguridad de tu cuenta, te pediremos que verifiques tu número telefónico con un Código que te enviaremos vía SMS."
                :buttonText="verified ? 'Entrar a pagando' : 'VERIFICAR'"
                v-on:clickAccepted="verified ? goToLanding() :verifyCode()"
                v-on:clickClose="comeBackUserSearch"
        >
            <BasicInput v-if="getUserPhone !== null && !verified"
                        badge
                        inputType="text"
                        labelFor="celular"
                        inputId="celular"
                        label="Número de celular"
                        :inputValue="maskedPhoneUser !== null ? maskedPhoneUser : 'Número con terminación: ****6948'"
                        :inputPlaceholder="`Número con terminación: ${maskedPhoneUser}`"
                        badgeType="badge badge-success"
                        badgeText="Verificado"
            />
            <h2 class="f-18 c-title body-font-regular text-center f-700 m-b-10">
                Ingresa tu código de seguridad
            </h2>
            <p class="f-14 c-plain_text body-font-regular text-center m-0 m-b-20">
                Te enviamos tu código por mensaje de texto al número celular con terminación: <strong>{{maskedPhoneUser}}</strong>.
            </p>
            <CodeInput v-on:verificationCode="getVerificationCode" :showWarning="verificationError"
                       :warningText="verificationError ? verificationErrorText : '' " clazz="center"></CodeInput>
            <div class="verified-check justify-content-center m-b-25" v-if="verified">
                <i aria-hidden="true" class="banana banana-check-circle1"></i>
                <span>
                    Verificado Correctamente
                </span>
            </div>

            <p class="f-14 c-plain_text body-font-regular text-center m-b-30" v-if="!verified">
                Tu código de seguridad puede tardar unos minutos en llegar.
                ¿No recibiste ningún mensaje? <a class="c-accent_2 text-underline f-bold" @click="sendVerificationCode"
                                                 href="#">Haz clic aquí para reenviar el código de seguridad.</a>
            </p>
            <p class="f-14 c-plain_text body-font-regular text-center m-b-30" v-if="verified">
                ¡Felicidades! Tu número celular con terminación: ****6948 ha sido verificado exitosamente.
            </p>
            <p class="f-14 c-info_text body-font-regular text-center m-0">
                Si requieres asistencia técnica, por favor contáctanos a los teléfonos: <strong>800 461 0119</strong>
                para México. O bien, envía un correo a <strong>soporte@pagandocheck.com</strong>.
            </p>
        </ModalDefault>

        <ModalAlert
            modalId="modal-sessions"
            buttonType="button-accent1"
            :title="$t('sign-in.modal.title', {qty: getActiveSessions})"
            :text="$t('sign-in.modal.text')"
            :buttonText="$t('general.actions.accept')"
            closeCross
            closeEsc
            closeBackdrop
            noCancelButton
            withAutomaticLoad
            @submit="agreeCloseSessions"
        ></ModalAlert>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import SignInBody from "@/components/SignInBody.vue";
    import GenericTitle from "@/components/GenericTitle.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import PasswordInput from "@/components/PasswordInput.vue";
    // import SocialButtons from "@/components/SocialButtons.vue";
    import LoadingSimple from "@/components/LoadingSimple.vue";
    import UploadProfileImage from "@/components/UploadProfileImage.vue";
    import config from "@/config";
    import ModalDefault from "@/components/ModalDefault.vue";
    import ModalAlert from '@/components/ModalAlert.vue';
    import CodeInput from "@/components/CodeInput.vue";
    import {securityImages} from '@/enums/securityImages';
    import {
        STORE as SESSION_STORE,
        GET_USER
    } from '@/store/modules/session/session.types';
    import {
        STORE as UserSignUpStore,
        VERIFY_CODE,
        SEND_VERIFICATION_CODE
    } from '@/store/modules/signin/signin.types'
    import usersApi from '@/api/user/users.api';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';

    const localUrl = `${config.server.host}:${config.server.port}/api-v2`;
    //  const regexEmailPhone = /^([a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?(?:\.[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?)*)|([+]?\d{10,12})$/;
    // eslint-disable-next-line max-len
    const regexEmailPhone = /^(?:\+?\d{10,12}$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

    export default {
        name: "SignIn",
        mixins: [ToastSnotify],
        data() {
            return {
                tabIndex: 1,
                registerEmail: '',
                loginEmail: '',
                correctEmail: false,
                correctEmailLogin: false,
                registerPassword: '',
                loginPassword: '',
                error: '',
                clazzPasswordStatus: '',
                labelPasswordStatus: '',
                passwordInputType: 'password',
                passwordInputTypeRegister: 'password',
                checkedTerms: false,
                showStatusBar: false,
                hideButtonLoading: true,
                localUrl: localUrl,
                isVisibleTextPasswordSignIn: false,
                isVisibleTextPasswordRegister: false,
                securityImages: securityImages,
                verificationCode: '',
                verificationError: false,
                verificationErrorText: '',
                verified: false
            }
        },
        props: {
            loginLeftFooter: {type: Boolean, default: false},
            registerLeftFooter: {type: Boolean, default: false}
        },
        created() {
            this.$store.commit(`${UserSignUpStore}/SET_RIGHT_SIDE_BODY`, 'EASY_PAY');
            // Guarda en la sesión los datos para redirigir después del login
            if (this.$route.query.app) {
                this.$session.set('app', this.$route.query.app);
            }
            if (this.$route.query.path) {
                this.$session.set('path', this.$route.query.path);
            }
            if (this.$route.query.message) {
                this.$store.commit(`${UserSignUpStore}/setLoginAlert`, true)
                this.$store.commit(`${UserSignUpStore}/setLoginAlertMessage`, this.$route.query.message)
            }
        },
        mounted() {
            if (this.getIsSocialMediaUser) {
                if(this.actualUser.twoFactorAuthentication){
                    this.$refs['modal-social-media'].showModal();
                } else {
                    this.goToLanding();
                }
            }
        },
        components: {
            SignInBody,
            GenericTitle,
            BasicInput,
            PasswordInput,
            // SocialButtons,
            LoadingSimple,
            ModalDefault,
            CodeInput,
            UploadProfileImage,
            ModalAlert
        },
        methods: {
            /**
             * Obtiene el valor ingresado del código mediante el componente CodeInput ya que son varios input y los agrupa en una sola cadena.
             * @param value con el código de verificación.
             */
            getVerificationCode(value) {
                this.verificationCode = value;
            },
            goToRegisterClient() {
                this.$router.push({name: "SigninTab"})
            },
            goToLanding() {

                if (this.getIsOwner) {
                    this.$store.commit(`${UserSignUpStore}/CLEAR_STORE`);
                    this.$router.push({name: "dashboard"})
                } else {
                    this.$store.commit(`${UserSignUpStore}/CLEAR_STORE`);
                    this.$router.push({name: "SignUpOrganizations"})
                }

            },
            comeBackUserSearch() {
                localStorage.removeItem('currentUser');
                localStorage.removeItem('currentToken');
                localStorage.removeItem('currentPermissions');
                localStorage.removeItem('profile');
                localStorage.removeItem('expireDate');
                usersApi.logout({});
                this.$store.commit(`${UserSignUpStore}/CLEAR_STORE`);
            },
            /**
             *  Verifica que el código de seguridad ingresado coincida con el que se le envio por sms al usuario.
             */
            verifyCode() {
                this.smsSendShowMsg = false;
                this.showErrorCode = false;
                if (this.verificationCode.length < 5) {
                    this.verificationError = true;
                    this.verificationErrorText = 'El código debe llevar 5 dígitos.';
                } else {
                    this.loadingText = "Validando código de seguridad...";
                    this.loading = true;
                    this.verificationError = false;
                    const payload = {
                        idUser: this.actualUser._id,
                        code: this.verificationCode
                    };
                    this.$store.dispatch(`${UserSignUpStore}/${VERIFY_CODE}`, payload).then((result) => {

                        if (!result) {
                            this.verificationError = true;
                            this.verificationErrorText = 'El código de seguridad que escribiste no es válido, escríbelo nuevamente para intentarlo otra vez.';
                        } else {
                            this.verificationError = false;
                            this.verified = true;
                        }
                        this.loading = false;
                    }).catch((err) => {
                        this.showErrorCode = true;
                        this.errorCodeText = err.message;
                        this.loading = false;
                    });
                }
            },
            /**
             * Envia el código de verificación por sms.
             */
            async sendVerificationCode() {
                this.loadingText = "Enviando código de seguridad...";
                this.loading = true;
                this.smsSendShowMsg = false;
                this.showErrorCode = false;

                const payload = {
                    contactPhone: this.getUserPhone
                };
                await this.$store.dispatch(`${UserSignUpStore}/${SEND_VERIFICATION_CODE}`, payload).then((result) => {
                    if (result.error) {
                        this.showErrorCode = true;
                        this.errorCodeText = result.message;
                    } else {
                        this.smsSend = true;
                        this.smsSendShowMsg = true;
                    }
                }).catch((err) => {
                    this.showErrorCode = true;
                    this.errorCodeText = err.message;
                });
                this.loading = false;
            },
            onBasicInputRegister(email) {
                this.correctEmail = regexEmailPhone.test(email);
                this.registerEmail = email;
            },

            onBasicInputLogin(email) {
                this.correctEmailLogin = regexEmailPhone.test(email);
                this.loginEmail = email;
            },

            onPasswordChangeLogin(password) {
                this.loginPassword = password;
            },

            changeTypePassword(isVisibleOn) {
                if (isVisibleOn) {
                    this.passwordInputType = 'password';
                } else {
                    this.passwordInputType = 'text';
                }
            },
            login() {
                const credentials = {
                    email: this.loginEmail,
                    password: this.loginPassword
                }
                this.hideButtonLoading = false;
                this.$store.dispatch(`${UserSignUpStore}/login`, {credentials});
            },
            getUserByEmail() {
                const data = {
                    email: this.loginEmail
                }
                this.hideButtonLoading = false;
                this.$store.dispatch(`${UserSignUpStore}/getUserByEmail`, data);
            },
            getImageById(id) {
                let src = undefined;
                this.securityImages.forEach(i => {
                    if (i.id === id) {
                        src = i.src
                    }
                })
                return src;
            },
            goUsersSignin() {
                return config.users.host === "http://localhost" ? `${config.users.host}:${config.users.port}/signin/register` : `${config.users.host}/signin/register`;
            },
            goRecoveryPassword() {
                this.$router.push({name: 'SigninPasswordRecovery'});
                // return config.users.host === "http://localhost" ?
                //     `${config.users.host}:${config.users.port}/signin/password-recovery` :
                //     `${config.users.host}/signin/password-recovery`;
            },
            agreeCloseSessions(){
                usersApi.disableAccess().then(() => {
                    this.$store.dispatch(`${UserSignUpStore}/agreeCloseSessions`);
                }).catch(error => this.toastErrorDefault(error));
            }
        },
        computed: {
            disabledSignUp() {
                return !(this.registerPassword.length >= 6 && this.correctEmail);
            },

            disabledUserFound() {
                return !(this.correctEmailLogin);
            },
            disabledSignIn() {
                return !(this.loginPassword.length >= 6 && this.correctEmailLogin);
            },
            ...mapGetters(UserSignUpStore, [
                "getShowAlert",
                "getAlertMessage",
                "getIsLoadingRegister",
                "getAlertVariant",
                "getLoginBlocked",
                "getLoginAlert",
                "getLoginAlertMessage",
                "getIsLoadingLogin",
                "getRightSide",
                "getUserFound",
                "getUserPhrase",
                "getUserImageId",
                "getUserFullName",
                "getIsSocialMediaUser",
                "getUserPhone",
                "getIsOwner",
                "getSessionModal",
                "getActiveSessions"
            ]),
            maskedPhoneUser() {
                if (this.getUserPhone !== undefined && this.getUserPhone !== null) {
                    const lastFour = this.getUserPhone.substr(this.getUserPhone.length - 4);
                    return `******${lastFour}`
                }
                return "**********"
            },
            ...mapGetters(SESSION_STORE, {
                actualUser: GET_USER
            }),
        },
        beforeDestroy() {
            this.$store.commit(`${UserSignUpStore}/CLEAR_STORE`);
        },
        watch: {
            getSessionModal(){
                if(this.getSessionModal){
                    this.$bvModal.show('modal-sessions');
                } else {
                    this.$bvModal.hide('modal-sessions');
                }
            }
        }
    }
</script>
