<template>
    <div
        class="bank-card-preview"
        :class="[emptyInfo ? 'empty-info': '', clazz]"
    >
        <img
            v-if="cardBrandComputed.toUpperCase() === expectedBrands.visa.toUpperCase() && !emptyInfo"
            class="img-fluid type-card"
            src="@/assets/images/Logos/Cards/visa-white.svg"
            alt
        />
        <img
            v-else-if="cardBrandComputed.toUpperCase() === expectedBrands.mastercard.toUpperCase() && !emptyInfo"
            class="img-fluid type-card"
            src="@/assets/images/Logos/Cards/mastercard-no-bgm.svg"
            alt
        />
        <img
            v-else-if="isBrandAMEX(cardBrandComputed) && !emptyInfo"
            class="img-fluid type-card"
            src="@/assets/images/Logos/Cards/amex-white.svg"
            alt
        />

        <div
            v-if="emptyInfo"
            class="info-card"
        >
            <span>**** **** **** ****</span>
            <label>{{ $t('cards.preview.name') }}</label>
            <small>{{ $t('cards.preview.date') }}</small>
        </div>

        <div
            v-if="!emptyInfo"
            class="info-card"
        >
            <span>{{cardNum}}</span>
            <label>{{cardName}}</label>
            <small>{{cardDate}}</small>
        </div>

        <div
            v-if="!emptyInfo && backCardInfo"
            class="back-card"
        ></div>
    </div>
</template>

<script>
export default {
    name: "BankCardPreview",
    props: {
        clazz: String,
        emptyInfo: { type: Boolean, default: false },
        cardNum: String,
        cardName: String,
        cardDate: String,
        cardBrand: String,
        backCardInfo: { type: Boolean, default: false }
    },
    data () {
        return {
            expectedBrands: {
                visa: 'visa',
                amex: 'amex',
                american_express: 'american_express',
                mastercard: 'mastercard',
                unknown: 'unknown'
            }
        }
    },
    methods: {
        changeCard () {
            if (this.pan === "" && this.name === "" && this.expirationDate === "" && this.cvv === "") {
                this.emptyCard = true;
            } else {
                this.emptyCard = false;
            }
        },
        isBrandAMEX(brand) {
            return brand.toUpperCase() === this.expectedBrands.amex.toUpperCase() || brand.toUpperCase() === this.expectedBrands.american_express.toUpperCase();
        }
    },
    computed: {
        cardBrandComputed () {
            return this.cardBrand || this.expectedBrands.visa;
        }
    }
};
</script>

<style lang="scss">
.bank-card-preview {
    width: 100%;
    max-width: 300px;
    height: 180px;
    border-radius: 10px;
    display: block;
    position: relative;
    padding: 20px;
    margin: 0 auto;
    border: none;
    background-image: url("../assets/images/Logos/Cards/card-bgm.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .type-card {
        position: absolute;
        top: 30px;
        left: 20px;
    }

    .bgm-type-card {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .info-card {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 20px;

        > span {
            font-size: 17px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 3.4px;
            display: block;
            margin-bottom: 26px;
            width: 100%;
        }

        > label {
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.09px;
            text-transform: uppercase;
            display: inline-flex;
            margin: 0 auto 0 0;
            float: left;
        }

        > small {
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.09px;
            text-transform: uppercase;
            display: inline-flex;
            margin: 0 0 0 auto;
            float: right;
        }
    }

    &.empty-info {
        .info-card {
            > span,
            > label,
            > small {
                opacity: 0.4;
                font-weight: 300;
            }
        }
    }

    .back-card {
        background-image: url("../assets/images/Logos/Cards/credit-card-back.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: 2;
    }
}
</style>
